import React from "react";
import './SurveyBuilderChoiceCell.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import LabeledSwitch from "../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropdown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {KeyForTranslation} from "../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class SurveyBuilderChoiceCell extends React.Component{
  
  // Render
  
  render(){
    const {index, choice, question, requirements, updateChoice, updateQuestion, selectedLanguage, menuButtonClicked,
      errorForIdTypeAndKey, showExclusive = true} = this.props;
    
    const labelKey = KeyForTranslation('label', selectedLanguage);
    const labelErrorKey = KeyForTranslation('choice_label', selectedLanguage) + '_';
    
    let validationChecked = false;
    
    if(requirements){
      validationChecked = (choice.type === 'xor');
    }
    else{
      if(question && question.validation){
        for(let validation of question.validation){
          if(validation.exclusive === choice.value){
            validationChecked = true;
            break;
          }
        }
      }
    }
    
    let shouldShowExclusive = showExclusive;
    let requirement = 'or';
    
    if(choice.type === 'and'){
      requirement = 'and';
      shouldShowExclusive = false;
    }
    
    return(
      <div className="survey-builder-choices-cell">
        {requirements && requirements.length > 0 ?
          <LabeledDropdown className="survey-builder-choice-cell-dropdown"
                           label={i18next.t('REQUIREMENT')}
                           value={requirements.filter(function(option){
                             return option.value === requirement;
                           })}
                           options={requirements}
                           infoBlurb={i18next.t('REQUIREMENT_EXPLANATION')}
                           handleSave={(aOption) => {
                             if((aOption.value === 'and' && choice.type !== 'and') ||
                               (aOption.value !== 'and' && choice.type === 'and')){
                               updateChoice(aOption.value, 'type')
                             }
                           }}
          />
          :
          null
        }
        
        {shouldShowExclusive ?
          <LabeledSwitch className="survey-builder-choice-cell-switch-input"
                         label={i18next.t('EXCLUSIVE')}
                         checked={validationChecked}
                         onChange={(isChecked) => {
                           if(requirements){
                             updateChoice(isChecked ? 'xor' : 'or', 'type');
                           }
                           else{
                             let updatedQuestion = cloneDeep(question);
                             
                             if(isChecked){
                               if(updatedQuestion.validation === undefined){
                                 updatedQuestion.validation = [];
                               }
                               updatedQuestion.validation.push({exclusive:choice.value});
                             }
                             else{
                               for(let index = 0; index < updatedQuestion.validation.length; index += 1){
                                 let validation = updatedQuestion.validation[index];
                                 
                                 if(validation.exclusive === choice.value){
                                   updatedQuestion.validation.splice(index, 1);
                                   break;
                                 }
                               }
                               if(updatedQuestion.validation.length === 0){
                                 delete updatedQuestion['validation'];
                               }
                             }
                             updateQuestion(updatedQuestion);
                           }
                         }}
                         infoBlurb={i18next.t('EXCLUSIVE_EXPLANATION')}
                         switchPosition={'bottom'}
                         checkedChildren={i18next.t('YES_PROPER_CAPITALIZED')}
                         popoverPlacement={"top"}
                         unCheckedChildren={i18next.t('NO_PROPER_CAPITALIZED')}
          />
          :
          null
        }
        
        <LabeledDelayedInput className="survey-builder-choices-input-label"
                             label={i18next.t('CHOICE_LABEL') + ' - ' + selectedLanguage.locale}
                             value={choice[labelKey]}
                             infoBlurb={i18next.t('CHOICE_LABEL_EXPLANATION')}
                             minLength={1}
                             handleSave={(aEvent) => updateChoice(aEvent.target.value, labelKey)}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', labelErrorKey + index).message}
        />
        
        <LabeledDelayedInput className="survey-builder-choices-input"
                             label={i18next.t('CHOICE_VALUE') }
                             value={choice.value}
                             infoBlurb={i18next.t('CHOICE_VALUE_EXPLANATION')}
                             minLength={1}
                             handleSave={(aEvent) => updateChoice(aEvent.target.value, 'value')}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'choice_value_' + index).message}
        />
        
        {question && question.db_write ?
          <LabeledDelayedInput className="survey-builder-choices-input-db-write"
                               label={i18next.t('DB_WRITE_VALUE')}
                               value={choice.db_write_value}
                               infoBlurb={i18next.t('DB_WRITE_VALUE_EXPLANATION')}
                               minLength={1}
                               handleSave={(aEvent) => updateChoice(aEvent.target.value, 'db_write_value')}
          />
          :
          null
        }
        
        {menuButtonClicked ?
          <BiDotsVerticalRounded style={{fontSize: '21px', marginLeft: 'auto', marginTop: '25px', flex: '0 0 20px'}}
                                 className="survey-builder-choices-menu-button"
                                 onClick={(aEvent) => menuButtonClicked(aEvent)}
          />
          :
          null
        }
      </div>
    )
  }
}
