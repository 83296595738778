import React from 'react'
import { debounce } from 'lodash'
import axios from 'axios'
import ManagePatientsUsersListItem from 'Cells/ManagePatientsUsersCell/ManagePatientsUsersCell'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../ManagePatientsList/ManagePatientsList.scss'
import {Translation, withTranslation} from "react-i18next";
import i18n from "../../i18n";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import i18next from "i18next";
import {formatUserName} from "../../Helpers/AdminHelpers";

const notifyResentSuccessful = () =>  toast(<Translation>{ (t, { i18n }) => t('INVITE_RESENT_SUCCESSFULLY') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyResentUnsuccessful = () => toast(<Translation>{ (t, { i18n }) => t('INVITE_RESEND_UNSUCCESSFUL') }</Translation>, NOTIFY_OPTS.autoClose);

class ManagePatientsList extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      searching: false,
      foundUsers: [],
      foundInvites: [],
      tabKey: 1,
      searchQuery: "",
      selectedPatientListItem: null,
      menuAnchorElement:null,
      selectedPatientListInvite: null,
      confirmationModalOpen:false,
      inviteMenuAnchorElement:null,
    };
    this.userSearch = debounce(this.userSearch, 500);
    this.companyInviteSearch = debounce(this.companyInviteSearch, 500);
    this.removeUser = this.removeUser.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
    this.handleUserSearch = this.handleUserSearch.bind(this);
    let UserSearchCancelToken = axios.CancelToken;
    this.userSearchSource = UserSearchCancelToken.source();
    let InviteSearchCancelToken = axios.CancelToken;
    this.inviteSearchSource = InviteSearchCancelToken.source();
    this.closeMenu = this.closeMenu.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.inviteMenuButtonClicked = this.inviteMenuButtonClicked.bind(this);
  }

  componentDidMount(){
    this.userSearch("");
    this.companyInviteSearch();
  }

  componentWillUnmount(){
    this.userSearchSource.cancel();
    this.inviteSearchSource.cancel();
  }

  // Methods

  removeUser = () => {
    let userId = this.state.selectedPatientListItem.id;
    this.props.removeUserFromCompany(userId);
    this.closeMenu();
    this.userSearch("");
  };

  resendInvite = () => {
    let companyInviteId = this.state.selectedPatientListInvite.id;
    this.closeMenu();
    this.props.resendInviteToUser(companyInviteId).then(result => {
      notifyResentSuccessful();
    }).catch((error) => {
      notifyResentUnsuccessful();
    })
  }

  userSearch(query) {
    let graphQLQuery = `
      query findUsers($query: String!, $company_id: ID!) {
        users(query: $query, company_id: $company_id, roles: ["patient"]) {
          id
          first_name
          last_name
          email
          birth_year
          image_id
        }
      }
    `;
    let variables = {query, company_id: this.props.company.id}

    global.api.post("/queries", {
      query: graphQLQuery, variables,
      cancelToken: this.userSearchSource.token
    }).then((result) => {
      this.setState({searching: false})
      let users = result.data.data.users
      if (users) {
        this.setState({foundUsers: users})
      }
    }).catch((error) => {
      this.setState({userFound: false})
    })
  }

  companyInviteSearch(query){
    this.props.companyInviteSearch(query, this.props.company.id).then((result) => {
      let invites = result.data.data.company_invites;

      if(invites){
        this.setState({foundInvites: invites});
      }
    }).catch((error) => {
    })
  }

  handleUserSearch(e){
    let value = e.target.value;

    if(this.state.searching === false){
      this.setState({searching: true});
    }
    this.userSearch(value);
    this.companyInviteSearch(value);
    this.setState({searchQuery: value});
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedPatientListItem:null});
    this.setState({inviteMenuAnchorElement: null, selectedPatientListInvite: null})
  }

  menuButtonClicked(aEvent, aPatient){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedPatientListItem:aPatient});
  };

  inviteMenuButtonClicked(aEvent, aInvite){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({inviteMenuAnchorElement:aEvent.currentTarget, selectedPatientListInvite:aInvite});
  };

  render() {
    const {t, type, company} = this.props;
    const {confirmationModalOpen, selectedPatientListItem} = this.state;
    let patientList;
    let patients = this.state.foundUsers;

    if(patients && patients.length > 0){
      patientList = patients.map((user, index) => {
        let patientLink = '/patients/' + user.id;
        return (
          <ManagePatientsUsersListItem 
            key={user.id + 'index:' + index}
            user={user}
            company={company}
            patientLink={patientLink}
            removeFunc={this.removeUser}
            menuButtonClicked={(aEvent, aPatient) => this.menuButtonClicked(aEvent, aPatient)}
        />
        )
      })
    }

    let totalPatients = 0;
    let totalPatientsHeader = "";
    let subText = "";
    
    if(type === 'active'){
      totalPatients = patients.length;
      totalPatientsHeader = <Translation>{ (t, { i18n }) => t('TOTAL_ACTIVE_PATIENTS') }</Translation>;
      subText = <Translation>{ (t, { i18n }) => t('DISPLAY_ENROLLED_PATIENTS') }</Translation>
    }

    let pendingList = (
        <div className='user-prompt-msg'>
          <Translation>{(t, {i18n}) => t('NO_PENDING_INVITES')}</Translation>
        </div>
    );
    let rejectedList = (
        <div className='user-prompt-msg'>
          <Translation>{(t, {i18n}) => t('NO_REJECTED_INVITES')}</Translation>
        </div>
    );

    let invites = this.state.foundInvites;

    if(invites){
      let pendingInvites = invites.filter((invite) => invite.accepted === null);

      if(pendingInvites.length > 0){
        pendingList = pendingInvites.map((invite) => {
          return (
              <div key={invite.id} className="patient-list-item-invite-cell">
                <div>{invite.email}</div>
                <BiDotsVerticalRounded fill="#A8A8A8"
                                       style={{fontSize: '21px', marginLeft: 'auto'}}
                                       onClick={(aEvent) => this.inviteMenuButtonClicked(aEvent, invite)}
                />

                <Menu id="simple-menu"
                      anchorEl={this.state.inviteMenuAnchorElement}
                      keepMounted
                      open={Boolean(this.state.inviteMenuAnchorElement)}
                      onClose={() => this.closeMenu()}>
                  <MenuItem style={{position: 'relative'}} onClick={() => this.resendInvite()}>
                    <Translation>{ (t, { i18n }) => t('RESEND')}</Translation>
                  </MenuItem>
                </Menu>

              </div>

          )
        })
      }
      let rejectedInvites = invites.filter((invite) => invite.accepted === false);

      if(rejectedInvites.length > 0){
        rejectedList = rejectedInvites.map((invite) => {
          return (
              <div key={invite.id} className="patient-list-item-invite-cell">
                {invite.email}
              </div>
          )
        })
      }
      if(type === 'pending'){
        totalPatients = pendingInvites.length;
        totalPatientsHeader = <Translation>{ (t, { i18n }) => t('TOTAL_PENDING_PATIENTS') }</Translation>;
        subText = <Translation>{ (t, { i18n }) => t('PENDING_EXPLANATION') }</Translation>
      }
      else if(type === 'rejected'){
        totalPatients = rejectedInvites.length;
        totalPatientsHeader = <Translation>{ (t, { i18n }) => t('TOTAL_REJECTED_PATIENTS') }</Translation>;
        subText = <Translation>{ (t, { i18n }) => t('REJECTED_EXPLANATION') }</Translation>
      }
    }
    return (
      <div>
          <div style={{marginLeft : 20}}  className="manage-patient-container-text">
            {subText}
          </div>

          <div className="manage-patient-status-title">
            {/*TODO: Add in proper labels and count.*/}
              <div>
                {totalPatientsHeader} - {totalPatients}
              </div>
              <LabeledDelayedInput className="patient-list-input-small"
                                   showSearchIcon={true}
                                   debounceTimeout={300}
                                   value={this.state.searchQuery}
                                   placeholder={t('EMAIL_OR_NAME', {i18n})}
                                   handleSave={this.handleUserSearch}
              />
            {/*  <span>*/}
            {/*    <Translation>{ (t, { i18n }) => t('CSV_IMPORT') }</Translation>*/}
            {/*        - 333*/}
            {/*  </span>*/}
            {/*<LabeledDelayedInput className="manage-patient-input-large"*/}
            {/*                     showSearchIcon={true}*/}
            {/*/>*/}
          </div>


          {/*  <div className="search-patients">*/}
          {/*    <Translation>{(t, {i18n}) => t('SEARCH_USERS')}</Translation>*/}
          {/*  </div>*/}
          {/*  <div className="tool-search subsection-element no-padding">*/}
          {/*    */}
          {/*    */}
          {/*    */}
          {/*    */}
          {/*  <input type='text'*/}
          {/*         className="text-input-fields search-users-input"*/}
          {/*         placeholder={t('EMAIL_OR_NAME', {i18n})}*/}
          {/*         name='searchQuery'*/}
          {/*         value={this.state.searchQuery}*/}
          {/*         onChange={this.handleUserSearch}/>*/}
          {/*  {this.state.searching === true && <img src="/imgs/app/loading.gif" width={25} height={25} alt='Loading...'/> }*/}
          {/*</div>*/}

          {/*TODO*/}
          {/*  <div id="manage-patients-nav">*/}
          {/*    <div className="fix-width">*/}
          {/*      <NavLink exact activeClassName='selected-nav-item' to={`${path}`}>*/}
          {/*        <Translation>{(t, {i18n}) => t('ACTIVE')}</Translation>*/}
          {/*      </NavLink>*/}
          {/*      */}
          {/*      <NavLink exact activeClassName='selected-nav-item' to={`${path}/pending`}>*/}
          {/*        <Translation>{(t, {i18n}) => t('PENDING')}</Translation>*/}
          {/*      </NavLink>*/}
          {/*      <NavLink exact activeClassName='selected-nav-item' to={`${path}/rejected`}>*/}
          {/*        <Translation>{(t, {i18n}) => t('REJECTED')}</Translation>*/}
          {/*      </NavLink>*/}
          {/*    </div>*/}
          {/*  </div>*/}

            <div id="active-pending-patients-tabs" className="panel-scroll-patient-list">
              <PerfectScrollbar>

                {type === 'active' ?
                  <div style={{width: '100%'}}>
                    <div>
                      {patientList}
                    </div>
                  </div>
                  :
                  null
                }

                {type === 'pending' ?
                  pendingList
                  :
                  null
                }

                {type === 'rejected' ?
                  rejectedList
                  :
                  null
                }

              </PerfectScrollbar>

              <Menu id="simple-menu"
                    anchorEl={this.state.menuAnchorElement}
                    keepMounted
                    open={Boolean(this.state.menuAnchorElement)}
                    onClose={() => this.closeMenu()}>

                <MenuItem style={{position: 'relative', color:'red'}}  onClick={() => {this.setState({confirmationModalOpen:true})}}>
                  <Translation>{ (t, { i18n }) => t('DELETE')}</Translation>
                </MenuItem>
              </Menu>
  
              <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:formatUserName(selectedPatientListItem)})}
                                 isOpen={confirmationModalOpen}
                                 reject={() => this.setState({confirmationModalOpen:false, selectedPatientListItem:null})}
                                 confirm={() => this.removeUser()}
              />
            </div>
        </div>
    );
  }
}
export default withTranslation()(ManagePatientsList)
