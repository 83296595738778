import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchReportTemplate(aReportTemplateId){
  EventTracker.track(EventTypes.FETCH_REPORT_TEMPLATE);
  let query = `
    query getTemplate ($company_id: ID!, $template_id: ID!) {
      template (company_id: $company_id, template_id: $template_id){
        id
        name
        created_at
        updated_at
        last_generated_at
        last_generate_arguments
        model {
          id
          model_configuration_name
          columns {
            id
            date_format
            description
            custom_header_name
            column_configuration_name
            relationship_arguments {
              name
              arguments
              model_configuration_name
              model_configuration_display_name
            }
          }
          filters {
            id
            keyword
            rhs_value
            arel_comparator
            filter_configuration_name
            relationship_arguments {
              name
              arguments
              model_configuration_name
              model_configuration_display_name
            }
          }
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['template_id'] = aReportTemplateId;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchReportTemplates(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_REPORT_TEMPLATES);
  let query = `
    query getTemplates ($company_id: ID!, $limit: Int, $offset: Int, $search_string: String) {
      templates (company_id: $company_id, limit: $limit, offset: $offset, search_string: $search_string){
        templates {
          id
          name
          created_at
          updated_at
          last_generated_at
          last_generate_arguments
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function saveReportTemplate(aReportTemplate){
  if(aReportTemplate && aReportTemplate.id){
    EventTracker.track(EventTypes.UPDATE_REPORT_TEMPLATE);
  }
  else{
    EventTracker.track(EventTypes.CREATE_REPORT_TEMPLATE);
  }
  let query = `
    mutation SaveTemplateMutation ($saveTemplateMutationInput: SaveTemplateMutationInput!) {
      saveTemplate (input: $saveTemplateMutationInput){
        template {
          id
          name
          created_at
          updated_at
          last_generated_at
          last_generate_arguments
          model {
            model_configuration_name
            columns {
              date_format
              description
              custom_header_name
              column_configuration_name
              relationship_arguments {
                name
                arguments
                model_configuration_name
                model_configuration_display_name
              }
            }
            filters {
              id
              keyword
              rhs_value
              arel_comparator
              filter_configuration_name
              relationship_arguments {
                name
                arguments
                model_configuration_name
                model_configuration_display_name
              }
            }
          }
        }
      }
    }
  `;
  let params = {};
  let operationName = `SaveTemplateMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['name'] = aReportTemplate.name;
    
    let model = aReportTemplate.model;
    
    if(model.hasOwnProperty('id')){
      delete model['id'];
    }
    for(let key of ['columns', 'filters']){
      if(model[key] && Array.isArray(model[key])){
        for(let columnOrFilter of model[key]){
          if(columnOrFilter.relationship_arguments){
            for(let relationshipArguments of columnOrFilter.relationship_arguments){
              if(relationshipArguments.hasOwnProperty('model_configuration_name')){
                delete relationshipArguments['model_configuration_name'];
              }
              if(relationshipArguments.hasOwnProperty('model_configuration_display_name')){
                delete relationshipArguments['model_configuration_display_name'];
              }
            }
          }
        }
      }
    }
    params['model'] = model;
    
    if(aReportTemplate.id){
      params['template_id'] = aReportTemplate.id;
    }
    let variables = {
      saveTemplateMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function removeReportTemplate(aReportTemplateId){
  EventTracker.track(EventTypes.REMOVE_REPORT_TEMPLATE);
  let query = `
    mutation RemoveTemplateMutation($removeTemplateMutationInput: RemoveTemplateMutationInput!) {
        removeTemplate(input: $removeTemplateMutationInput) {
          template {
            id
          }
        }
      }
  `;
  let params = {};
  let operationName = `RemoveTemplateMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['template_id'] = aReportTemplateId;
    
    let variables = {
      removeTemplateMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  }
}

export function duplicateReportTemplate(aReportTemplateId){
  EventTracker.track(EventTypes.DUPLICATE_REPORT_TEMPLATE);
  let query = `
    mutation DuplicateTemplateMutation ($duplicateTemplateMutationInput: DuplicateTemplateMutationInput!) {
      duplicateTemplate (input: $duplicateTemplateMutationInput){
        template {
          id
          name
          created_at
          updated_at
          last_generated_at
          last_generate_arguments
          model {
            model_configuration_name
            columns {
              date_format
              description
              custom_header_name
              column_configuration_name
              relationship_arguments {
                name
                arguments
                model_configuration_name
                model_configuration_display_name
              }
            }
            filters {
              id
              keyword
              rhs_value
              arel_comparator
              filter_configuration_name
              relationship_arguments {
                name
                arguments
                model_configuration_name
                model_configuration_display_name
              }
            }
          }
        }
      }
    }
  `;
  let params = {};
  let operationName = `DuplicateTemplateMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['template_id'] = aReportTemplateId;
    
    let variables = {
      duplicateTemplateMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function generateReportFromTemplate(aReportTemplateId, aName, aFilters){
  EventTracker.track(EventTypes.GENERATE_REPORT_TEMPLATE);
  let variables = {generate_in_background:'true'};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['name'] = aName;
    variables['template_id'] = aReportTemplateId;
    
    if(aFilters){
      variables['filter_values'] = aFilters;
    }
    return global.api.post("/api/reports/reports", variables);
  };
}

export function fetchModelConfigurations(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_MODEL_CONFIGURATIONS);
  let query = `
    query getModelConfigurations ($company_id: ID!, $limit: Int, $offset: Int, $search_string: String) {
      model_configurations (company_id: $company_id, limit: $limit, offset: $offset, search_string: $search_string){
        model_configurations {
          name
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchColumnConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName){
  EventTracker.track(EventTypes.FETCH_COLUMN_CONFIGURATIONS);
  let query = `
    query getColumnConfigurations ($company_id: ID!, $limit: Int, $offset: Int, $model_configuration_name: String!, $search_string: String) {
      column_configurations (company_id: $company_id, limit: $limit, offset: $offset, model_configuration_name: $model_configuration_name, search_string: $search_string){
        column_configurations {
          name
          data_type
          display_name
          multiple_columns
          argument_configurations {
            name
            data_type
            description
            display_name
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['model_configuration_name'] = aModelConfigurationName;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchFilterConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName){
  EventTracker.track(EventTypes.FETCH_FILTER_CONFIGURATIONS);
  let query = `
    query getFilterConfigurations ($company_id: ID!, $limit: Int, $offset: Int, $model_configuration_name: String!, $search_string: String) {
      filter_configurations (company_id: $company_id, limit: $limit, offset: $offset, model_configuration_name: $model_configuration_name, search_string: $search_string){
        filter_configurations {
          name
          data_type
          display_name
          allowed_arel_comparators
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['model_configuration_name'] = aModelConfigurationName;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchPreMadeRelationships(aLimit, aOffset, aSearchString, aModelConfigurationName){
  EventTracker.track(EventTypes.FETCH_PRE_MADE_RELATIONSHIPS);
  let query = `
    query getPreMadeRelationships ($company_id: ID!, $limit: Int, $offset: Int, $model_configuration_name: String!, $search_string: String) {
        pre_made_relationships (company_id: $company_id, limit: $limit, offset: $offset, model_configuration_name: $model_configuration_name, search_string: $search_string){
            pre_made_relationships {
              name
              relationship_configuration_names
            }
            total
          }
      }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['model_configuration_name'] = aModelConfigurationName;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchRelationshipConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName){
  EventTracker.track(EventTypes.FETCH_RELATIONSHIP_CONFIGURATIONS);
  let query = `
    query getRelationshipConfigurations ($company_id: ID!, $limit: Int, $offset: Int, $model_configuration_name: String!, $search_string: String) {
      relationship_configurations (company_id: $company_id, limit: $limit, offset: $offset, model_configuration_name: $model_configuration_name, search_string: $search_string){
        relationship_configurations {
          name
          has_many
          display_name
          is_filterable
          related_model_configuration_name
          argument_configurations {
            name
            data_type
            description
            display_name
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['model_configuration_name'] = aModelConfigurationName;
  
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}
