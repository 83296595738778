import React from 'react';
import './MedicalRecordsList.scss';
import {toast} from 'react-toastify';
import i18next from "i18next";
import DynamicList from "../DynamicList/DynamicList";
import {NOTIFY_OPTS} from 'constants/Notifiers';
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import MedicalRecordModal from "../../Modals/MedicalRecordModal/MedicalRecordModal";
import {downloadMedicalRecord, medicalRecordAttachmentOfType, medicalRecordContainsAttachmentOfType} from "../../Helpers/MedicalRecordHelpers";

const notifyConsentRemovedError = () => toast(i18next.t('MEDICAL_RECORDS_CONSENT_REVOKED'), NOTIFY_OPTS.autoCloseFiveSeconds);
export default class MedicalRecordsList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      selectedType:null,
      selectedMedicalRecord:null,
      showMedicalRecordModal:false,
      consentNotProvidedError:false
    };
    this.getMedicalRecords = this.getMedicalRecords.bind(this);
  }
  
  // Methods
  getMedicalRecords(aPatientId, aLimit, aOffset, aSearchString) {
    return this.props.fetchMedicalRecords(aPatientId, aLimit, aOffset, aSearchString)
    .then((response) => response)
    .catch((err) => {
      if(err.data && err.data.error === 'Patient has not provided consent') {
        this.setState({consentNotProvidedError:true});
        notifyConsentRemovedError()
      }
    })
  }
  
  // Render
  
  render() {
    const {user, company, patient, className} = this.props;
    const {selectedType, selectedMedicalRecord, showMedicalRecordModal, consentNotProvidedError} = this.state;
    
    const currentRole = roleForCompany(company);
    const medicalRecordsPermissionsArray = ['admin','admin_level_2' ,'admin_level_3', 'admin_level_4'];
    
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:1, columnNameKey:'FILE_NAME', propertiesArray:['report_name'], description: i18next.t('MEDICAL_RECORD_FEATURE_DESCRIPTION')});
    columnsArray.push({key:'created_at', dateFormat:'MMM D YYYY, h:mma', columnWeight:1, columnNameKey:'REQUESTED_ON', propertiesArray:['created_at']});
    columnsArray.push({key:'provider_name', columnWeight:1, columnNameKey:'PROVIDER_NAME', propertiesArray:['organization', 'name']});
    
    let menuItemsArray = [];
    
    // TODO: Uncomment once native CSV rendering support has been added.
    // menuItemsArray.push({title:'OPEN_AS_CSV', clickAction:(aMedicalRecord) => {
    //     this.setState({selectedType:'json', selectedMedicalRecord:aMedicalRecord, showMedicalRecordModal:true});
    //   }, isValid:(aMedicalRecord) => aMedicalRecord && aMedicalRecord.json_data !== null});
    
    menuItemsArray.push({title:'OPEN_AS_PDF', clickAction:(aMedicalRecord) => {
        let attachment = medicalRecordAttachmentOfType(aMedicalRecord, 'pdf');
        
        if(attachment && attachment.file_url && attachment.file_url.length > 0){
          window.open(attachment.file_url, '_blank');
        }
      }, isValid:(aMedicalRecord) => medicalRecordContainsAttachmentOfType(aMedicalRecord, 'pdf')});
    menuItemsArray.push({title:'OPEN_AS_HTML', clickAction:(aMedicalRecord) => {
        this.setState({selectedType:'html', selectedMedicalRecord:aMedicalRecord, showMedicalRecordModal:true});
      }, isValid:(aMedicalRecord) => medicalRecordContainsAttachmentOfType(aMedicalRecord, 'html')});
    menuItemsArray.push({title:'DOWNLOAD_AS_CSV', clickAction:(aMedicalRecord) => {
        downloadMedicalRecord(aMedicalRecord, 'csv', user, patient);
      }, isValid:(aMedicalRecord) => aMedicalRecord && aMedicalRecord.json_data !== null});
    menuItemsArray.push({title:'DOWNLOAD_AS_JSON', clickAction:(aMedicalRecord) => {
        downloadMedicalRecord(aMedicalRecord, 'json', user, patient);
      }, isValid:(aMedicalRecord) => aMedicalRecord && aMedicalRecord.json_data !== null});
    menuItemsArray.push({title:'DOWNLOAD_AS_PDF', clickAction:(aMedicalRecord) => {
        downloadMedicalRecord(aMedicalRecord, 'pdf', user, patient);
      }, isValid:(aMedicalRecord) => medicalRecordContainsAttachmentOfType(aMedicalRecord, 'pdf')});
    
    const hasProvidedConsent = !consentNotProvidedError && patient.human_api_user && patient.human_api_user.hsc_reports_access_consent
    const hasSyncedMedicalRecords =  patient.human_api_user && patient.human_api_user.last_reports_data_sync_requested_at

    return (
      <div className={'medical-records-list-container ' + className}>
        {medicalRecordsPermissionsArray.includes(currentRole) ?
            hasSyncedMedicalRecords ?
              hasProvidedConsent ?
              <>
                <DynamicList id="medical-records-list"
                            error={(aError) => {
                              let returnValue = null;
                  
                              if(aError === 'Human api not integrated'){
                                returnValue = i18next.t('MEDICAL_RECORDS_NOT_SYNCED');
                              }
                              return returnValue;
                            }}
                            menuCancel={() => this.setState({selectedMedicalRecord:null})}
                            columnsArray={columnsArray}
                            fetchObjects={(aLimit, aOffset, aSearchString) => this.getMedicalRecords(patient.id, aLimit, aOffset, aSearchString)}
                            menuItemsArray={menuItemsArray}
                            totalObjectsKey='TOTAL_MEDICAL_RECORDS'
                            responseTotalKeysArray={['data', 'data', 'medical_records', 'total']}
                            responseObjectKeysArray={['data', 'data', 'medical_records', 'medical_records']}
                />
                
                <MedicalRecordModal open={showMedicalRecordModal}
                                    type={selectedType}
                                    close={() => this.setState({selectedType:null, selectedMedicalRecord:null, showMedicalRecordModal:false})}
                                    company={company}
                                    medicalRecord={selectedMedicalRecord}
                />
              </>
              :
              <div className="no-information-text">
                {i18next.t('CONSENT_NOT_PROVIDED_BY_USER')}
              </div>
            :
            <div className="no-information-text">
              {i18next.t('MEDICAL_RECORDS_NOT_SYNCED')}
            </div>
          :
          <div className="no-information-text">
            {i18next.t('MEDICAL_RECORDS_BLOCKED')}
          </div>
        }
      </div>
    )
  }
}
