import React from 'react';
import './AlertIndicator.scss'

const AlertIndicator = ({ number }) => {
    if(number) {
        return (
            <div className="alert-indicator">
                {number > 99 ? "99+" : number}
            </div>
        );
    }

    return null;
};

export default AlertIndicator;
