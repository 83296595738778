import React from 'react';
import './GroupsModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";

const GroupsModal = ({open, close, groups, presentedInsideParentModal = false}) => {
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               close={close}
    //               title={i18next.t('GROUPS')}
    //               width={'500px'}
    //               height={'500px'}
    //               presentedInsideParentModal={presentedInsideParentModal}>
    <GeneralModal title={i18next.t('GROUPS')}
                  width={'500px'}
                  height={'400px'}
                  isOpen={open}
                  maxWidth={500}
                  maxHeight={400}
                  showFooter={false}
                  noTitleHeading={true}
                  showSaveButton={false}
                  handleCloseModal={() => close()}
                  presentedInsideParentModal={presentedInsideParentModal}>
      <div className="groups-modal-container">
        {groups && groups.length > 0 ?
          groups.map((group) => (
            <div className="groups-modal-cell"
                 key={'groups-modal-cell-' + group.name}>
              {group.name && group.name.length > 50 ? group.name.substring(0, 50) : group.name}
            </div>
          ))
          :
          null
        }
      </div>
    </GeneralModal>
  )
};
export default GroupsModal;
