import {allAdminRolesArray} from "./AdminHelpers";

export function roleForCompany(aCompany){
  let returnValue = '';
  
  if(aCompany && aCompany.role && (typeof aCompany.role === 'string') && allAdminRolesArray.includes(aCompany.role)){
    returnValue = aCompany.role;
  }
  return returnValue;
}

export function groupInCompanyById(aCompany, aGroupId){
  let returnValue = {};
  
  if(aCompany){
    if(aCompany.core_group && aGroupId === aCompany.core_group.id){
      returnValue = aCompany.core_group;
    }
    else if(aCompany.groups){
      for(let group of aCompany.groups){
        if(group.id === aGroupId){
          returnValue = group;
          break;
        }
      }
    }
  }
  return returnValue;
}

export function hsFeatureAvailableForCompany(aCompany, aHsFeatureName){
  let returnValue = false;
  
  if(aCompany && aCompany.hs_features){
    for(let hsFeature of aCompany.hs_features){
      if(hsFeature.title === aHsFeatureName){
        returnValue = hsFeature.enabled;
        break;
      }
    }
  }
  return returnValue;
}
