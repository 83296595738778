import {Component} from 'react';
import React from 'react';
import {Line} from "react-chartjs-2";

export default class MultiLineChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: ''
    };
  }

  render() {
    const{entries, activityName, deviceName, onClickListener}= this.props;
    let dataSeries = [];
    let colorsArray = ['#EBBDB4', '#F3F2F1', '#D0CECD', '#D2C9C1', '#F4F2F0', '#F8D5B3', '#BFCDDB', '#FAEDEB', 
    '#E7E3E0', '#F3F2F2', '#F4F2F1', '#DDD8D8', '#FDF2E8', '#EFF2F6'];
    if(entries && entries.data){
      entries.data.forEach(function(dataset, datasetIndex){
        dataSeries.push({
          label: entries.series[datasetIndex],
          data: dataset,
          fill: true,
          rawEntries: entries.raw_entry[datasetIndex],
          borderColor: colorsArray[datasetIndex],
          backgroundColor: colorsArray[datasetIndex + 7]
        });
      });
    }
    const state = {
      labels: entries && entries.labels,
      datasets: dataSeries
    };
    return (
      <div>
        <Line
          data= {state}
          height={200}
          options = {{
            onClick: (e, element) => {
              if (element.length > 0) {
                var index = element[0]._index;
                if(index >= 0 && index < entries.raw_entry.length){
                  const entry = entries.raw_entry[index];
                  onClickListener({
                    rawEntry: entry,
                    activityName: activityName,
                    deviceName: deviceName
                  });
                }
              }
            },
            spanGaps: true,
            maintainAspectRatio: false,
            title: {
              display: false
            },
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                ticks: {
                  maxTicksLimit: 15
                }
              }],
              yAxes: [{
                ticks: {
                  maxTicksLimit: 6
                }
              }]
            }
          }}
        />
      </div>
    );
  }
}
