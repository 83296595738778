import React from 'react';
import './BarChart.scss'
import BaseChart from "../BaseChart/BaseChart";
import ReactToolTip from "react-tooltip";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import {Translation} from "react-i18next";

export default class BarChart extends BaseChart {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: ''
    };
  }

  generateChartData(entries, startDate, endDate, onClickListener) {
    let minYValue = this.props.minYValue;
    let maxYValue = this.props.maxYValue;
    let objectiveId = this.props.objectiveId;
    let dataPoints = [];
    let barColor = [];
    let xAxisTickLabels = [];
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    let yAxisTicks = {
      suggestedMin: 0,
      stepSize: 5
    };

    if(minYValue){
      yAxisTicks.suggestedMin = minYValue;
    }

    if(maxYValue){
      yAxisTicks.suggestedMax = maxYValue;
    }

    while(currentDate < endDate){
      let entriesOnCurrentDate = entries.filter((entry) => {
        let measuredAt = new Date(entry.response.measured_at);
        return entry.value && measuredAt.getDate() === currentDate.getDate()
          && measuredAt.getMonth() === currentDate.getMonth()
          && measuredAt.getFullYear() === currentDate.getFullYear();
      });

      if(entriesOnCurrentDate.length > 0){
        let totalValue = entriesOnCurrentDate.reduce((result, entry) => result + parseInt(entry.value), 0);
        let maxValue = entriesOnCurrentDate.reduce((prev, entry) => {
          let value = parseInt(entry.value);
          return value > prev ? value : prev;
        }, 0);

        if(maxValue < 4){
          barColor.push('#24895b');
        }
        else if (maxValue < 8) {
          barColor.push('#ffcc00');
        }
        else {
          barColor.push('#f34d33');
        }

        dataPoints.push({
          value: maxValue,
          numberOfEntries: entriesOnCurrentDate.length,
          responseIds: entriesOnCurrentDate.map(entry => entry.response.id),
          objectiveId: objectiveId,
          averageValue: totalValue / entriesOnCurrentDate.length
        });
      }
      else{
        barColor.push('');
        dataPoints.push({
          value: yAxisTicks.suggestedMin,
          numberOfEntries: 0
        });
      }
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.setState({ dataPoints: dataPoints });
    return {
      type: 'bar',
      plugins: [{
        ChartAnnotation
      }],
      data: {
        labels: xAxisTickLabels,
        datasets: [{
          backgroundColor: barColor,
          data: dataPoints.map(dataPoint => dataPoint.value)
        }]
      },
      options: {
        onClick: (event) => {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseIds[0],
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            const chart = this.chartRef.current;

            if (!chart) {
              return;
            }

            if (tooltipModel.opacity === 0) {
              this.setState({
                showTooltip: false
              });
              return;
            }

            if (!this.state.showTooltip) {
              let { numberOfEntries, value, averageValue } = dataPoints[tooltipModel.dataPoints[0].index];
              let text = '';

              if (numberOfEntries === 0) {
                text = 'No Entry';
              } else if(numberOfEntries === 1){
                text = `Highest ${value}\nAvg ${averageValue.toString().substr(0,3)}\n(${numberOfEntries} Entry)`;
              }
              else{
                text = `Highest ${value}\nAvg ${averageValue.toString().substr(0,3)}\n(${numberOfEntries} Entries)`;
              }
              this.setState({
                showTooltip: true,
                text: text,
                date: xAxisTickLabels[tooltipModel.dataPoints[0].index]
              });
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              padding: 15,
              autoSkip: false
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              ...yAxisTicks,
              padding: 10
            }
          }]
        }
      }
    }
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.chart.update();
      }
    });
    const {objectiveId} = this.props;
    const {showTooltip, date, text} = this.state;
    const footer = text === 'No Entry' ? null : 'click to view details';

    return (
      <div>
        <canvas ref={this.chartRef}
                data-tip data-for={`${objectiveId}`}
        />
        {
          showTooltip &&
          <ReactToolTip id={`${objectiveId}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>{date}</div>
              <div className='tooltip-body flex-center'>
                { text }
              </div>
              <div className='tooltip-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
