import 'react-app-polyfill/stable';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux';
import './_variables.scss';
import './index.scss';
import { getRoutes } from './routes';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger';
import history from "./history";
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import './i18n';
import axios from "axios";

const logger = createLogger();

const persistConfig = {
  key:'root',
  storage
};

const localReducer = persistReducer(persistConfig, reducers);

const persistedReducer = (state, action) => {
    if(action.type === 'LOGOUT'){
        const {routing} = state;
        state = {routing};
    }
    return localReducer(state, action)
};

export const store = createStore(
  connectRouter(history)(persistedReducer), 
  undefined, 
  compose(
    applyMiddleware(
      routerMiddleware(history),
      logger,
      thunk
    ),
  )
);

const persistor = persistStore(store);

axios.defaults.withCredentials = true;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<div style={{backgroundColor:'white'}}/>}>
          {getRoutes(store)}
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
