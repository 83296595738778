import React, { useState } from 'react';
import "antd/dist/antd.css";
import 'react-toastify/dist/ReactToastify.css'
import {Translation} from "react-i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import '../GeneralModal/GeneralModal.scss';
import UserPermissionCell from "../../Cells/UserPermissionCell/UserPermissionCell";

export default function ChangeAdminRoleModal({isOpen, closeModal, adminRolesArray, updateUser, updateAdminRole, user}) {

  const [newRole, setNewRole] = useState(user.role);

  const handleRoleChange = (aRole) => {
    setNewRole(aRole);
  }

  const handleAdminRoleChangeSubmit = () => {
    updateAdminRole(user.id, newRole);
    closeModal();
  };
  
  return (
    <GeneralModal title={<Translation>{(t, {i18n}) => t('ADMIN')}</Translation>}
                  isOpen={isOpen}
                  isEditing={true}
                  handleCloseModal={closeModal}
                  handleSave={handleAdminRoleChangeSubmit}
                  showSaveButton={true}
                  showCancelButton={true}
                  maxHeight={900}>
      <div className="manage-users-title">
        <Translation>{ (t, { i18n }) => t('PERMISSION') }</Translation>
      </div>

      <div className="manage-users-permission-outer-container">
        <div className="manage-users-permission-container">
          {adminRolesArray.map((adminRole, index) => (

              <div className="manage-permission-cell">
                <UserPermissionCell title={adminRole.title}
                                    value={adminRole.value}
                                    checked={newRole === adminRole.value}
                                    description={adminRole.description}
                                    key={adminRole.title + index}
                                    onChange={handleRoleChange}
                />
              </div>
          ))}
        </div>
      </div>
    </GeneralModal>
  )
}
