import React from 'react';
import '../PatientProfileToolViz/PatientProfileToolViz.scss'
import {DEFAULT_COLORS} from 'constants/Colors'
import MultiLineChart from "../charts/LineChart/MultiLineChart";
const collapseUpButton = '/imgs/app/white-collapse-up-button.svg';
const expandDownButton = '/imgs/app/white-expand-down-button.svg';

export default class PatientSyncedDevicesViz extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      collapsed: this.props.emptyData
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  toggleCollapsed(){
    let newState = this.state;
    newState.collapsed = !this.state.collapsed;
    this.setState(newState);
  }

  render(){
    let {device, deviceData} = this.props;
    let {collapsed} = this.state;

    let chart
    if(deviceData){
      chart = this.buildChartsComponent(device, deviceData);
        return (
          <div className="quest-container">
            <div className="graph-section-container">
              <div className='patient-panel-headings-container clickable'
                  style={{backgroundColor: DEFAULT_COLORS.PANEL_HEAD_BG}}
                  onClick={() => this.toggleCollapsed()}>
                <div className='section-control-button'>
                  <img src={this.state.collapsed ? expandDownButton : collapseUpButton} alt="collapseUpButton"/>
                  <h4>{device}</h4>
                </div>
              </div>
              {
                !collapsed &&
                (
                  <div style={{paddingBottom: 30, paddingTop: 30}}>
                    <div key={device + '-0-' }
                        style={{padding: "0 30px 0 30px"}}>
                      {chart}
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    }

  buildChartsComponent(deviceName, deviceData){
    let component = null;
    if(deviceData){
      if(deviceName === 'dexcom'){         
        return (
          <div key={deviceName}> 
            <h4>{'ESTIMATED GLUCOSE VALUE - (mg/dL)'}</h4>
            <MultiLineChart
              entries={deviceData}
              activityName={"egvs"}
              deviceName={deviceName}
              title={"ESTIMATED GLUCOSE VALUE"}
              onClickListener={this.onChartClickedListener.bind(this)}
            >
            </MultiLineChart>
          </div>
        );          
      }
      component = Object.keys(deviceData).map(function(activityName){
        let activityDisplayName = activityName.split("_").join(" ").toUpperCase();
        
        if (activityName === 'activities_summary'){
          activityDisplayName = 'Steps';
        }
        return (
          <div key={deviceName + activityName}>
            <h4>{activityDisplayName + '- (' + deviceData[activityName].unit.toUpperCase() + ')'}</h4>
            <MultiLineChart
              width={"100%"}
              entries={deviceData[activityName]}
              activityName={activityName}
              deviceName={deviceName}
              title={activityName.split("_").join(" ").toUpperCase()}
              onClickListener={this.onChartClickedListener.bind(this)}
            >
            </MultiLineChart>
          </div>
        );
      }, this);
    }  
    return component;
  }

  onChartClickedListener(clickEvent){
    const {toggleDataPointsListCollapsed} = this.props;
    let deviceName = clickEvent.deviceName;
    let activityName = clickEvent.activityName;
    let dataPoint = clickEvent.rawEntry;
    
    if(dataPoint){
      toggleDataPointsListCollapsed(deviceName, activityName, dataPoint);
    }
  }
}
