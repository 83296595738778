import React from 'react';
import './LabeledErrors.scss';
import Info from "../../Info/Info";
import {toast} from "react-toastify";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import {Translation} from "react-i18next";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";

const notifyCopied = (aMessage) => toast(<Translation>{ (t, { i18n }) => t('COPIED_MESSAGE_TO_CLIPBOARD', {aMessage:aMessage}) }</Translation>, NOTIFY_OPTS.autoClose);

const LabeledErrors = ({id, className, errorMessage, warningMessage}) => {
  
  // Render
  
  return (
    (errorMessage || warningMessage ?
        <div className={'labeled-errors ' + className}
             id={id}>
          <div className="labeled-errors-warning-container">
            {errorMessage ?
              <span className="labeled-errors-message-cell"
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      navigator.clipboard.writeText(errorMessage).then(() => {
                        notifyCopied(errorMessage);
                      })}}>
                <Info message={errorMessage}>
                  <ErrorIcon className="labeled-errors-error-icon"/>
                  
                  <div className="labeled-errors-error-message">
                    {errorMessage}
                  </div>
                </Info>
              </span>
              :
              null
            }
            
            {errorMessage && warningMessage ?
              <div className="labeled-errors-spacer"/>
              :
              null
            }
            
            {warningMessage ?
              <span className="labeled-errors-warning-message-cell"
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      navigator.clipboard.writeText(warningMessage).then(() => {
                        notifyCopied(warningMessage);
                      })}}>
                <Info message={warningMessage}>
                  <WarningIcon className="labeled-errors-warning-icon"/>
                  
                  <div className="labeled-errors-warning-message">
                    {warningMessage}
                  </div>
                </Info>
            </span>
              :
              null
            }
          </div>
        </div>
        :
        null
    )
  )
};
export default LabeledErrors;
