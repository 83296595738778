import React from "react";
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import {Translation} from "react-i18next";

export default class SurveyRankQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      hidden: false,
      answer: "",
      values: {}
    };
    
    if(props.answer != null){
      this.state.answer = props.answer;
    }
    if(props.answer != null && props.answer.prettyValues != null){
      props.answer.prettyValues.forEach(value => {
        let key = Object.keys(value)[0];
        this.state.values[key] = value[key];
      });
    }
  }
  
  // Methods
  
  saveAnswer(choice, rank){
    let values = this.state.values;
    
    if(values == null){
      values = {};
    }
    values[choice] = rank;
    this.setState({values: values});
    this.props.onAnswerChange(this.props.question, null, values);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {hidden, values} = this.state;
    const {key, type, choices} = question;
    
    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        {!hidden ?
          <div>
            <hr />
            
            <em>
              <Translation>{ (t, { i18n }) => t('SURVEY_RANKING_INSTRUCTION_TEXT')}</Translation>
            </em>
            
            <hr />
  
            {choices && choices.length > 0 ?
              choices.map((choice, index) => {
                return (
                  <label className={'survey-input-cell'} htmlFor={`rank_${index}`} key={index}>
                    <input id={`rank_${index}`}
                           className={'survey-string-input'}
                           type={'number'}
                           disabled={disabled}
                           key={index}
                           onChange={(aEvent) => this.saveAnswer(choice[0], aEvent.target.value)}
                           defaultValue={values[choice[0]]}
                    />
                    
                    {choice[1]}
                    
                    <br/>
                  </label>
                )
              })
              :
              null
            }
          </div>
          :
          null
        }
      </div>
    )
  }
}
