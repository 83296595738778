import React from 'react';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import {Translation} from "react-i18next";
import LabeledTextInput from "../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {Switch} from "antd";
import i18next from "i18next";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

export default class ToolBlockConsentCell extends React.Component{
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {
      selectedTab: "write",
      includeConsentingName: '',
      consentingNameRequired: '',
      includeConsentingSignature: '',
      consentingSignatureRequired: '',
      includeConsentCheck: '',
      consentCheckRequired: ''
    };
    if(props.block){
      this.state.includeConsentingName = props.block.include_consenting_name;
      this.state.consentingNameRequired = props.block.consenting_name_required;
      this.state.includeConsentingSignature = props.block.include_consenting_signature;
      this.state.consentingSignatureRequired = props.block.consenting_signature_required;
      this.state.includeConsentCheck = props.block.include_consent_check;
      this.state.consentCheckRequired = props.block.consent_check_required;
    }
  }

  handleEditorChange(value) {
    this.props.block.translations[this.props.selectedTranslationIndex].consent_check_text = value;
    this.props.updateBlock(this.props.block, this.props.index);
  }

  render() {
    const { block, handleBlockChange, selectedTranslationIndex, handleSwitchChange } = this.props
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex'}}>
          <div style={{display: 'flex', flex: '3', flexDirection: 'column'}}>


            <LabeledTextInput label={<Translation>{ (t, { i18n }) => t('BUTTON_TITLE') }</Translation>}
                              name={'consent_button_title'}
                              value={block.translations[selectedTranslationIndex].consent_button_title || ""}
                              style={{width: '100%'}}
                              handleSave={handleBlockChange}
                              placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
            />

            {/*<p style={{fontSize: 14, fontWeight: 500, margin: 0}}></p>*/}
            {/*<textarea className="rounded-input"*/}
            {/*          style={{width: '100%'}}*/}
            {/*          name="consent_button_title"*/}
            {/*          value={block.translations[selectedTranslationIndex].consent_button_title || ""}*/}
            {/*          onChange={handleBlockChange}/>*/}

          </div>
        </div>

          {/*<div style={{display: 'flex', flexDirection: 'column'}}>*/}
          {/*  <p style={{fontSize: 14, fontWeight: 500, margin: 0}}>*/}
          {/*    <Translation>{ (t, { i18n }) => t('NAME') }</Translation>*/}
          {/*  </p>*/}
          {/*</div>*/}

          <div style={{display: 'flex', flexDirection: 'column', marginRight: '20px'}}>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontWeight: '500'}}>
              {/*<input name="include_consenting_name" type="checkbox" checked={block.include_consenting_name || false} onChange={handleBlockChange} />*/}
              <Translation>{ (t, { i18n }) => t('INCLUDE_NAME') }</Translation>
              <Switch defaultChecked={this.state.includeConsentingName || false} onChange={(aValue) => handleSwitchChange('include_consenting_name',aValue)}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontWeight: '500'}}>
              {/*<input name="consenting_name_required" type="checkbox" checked={block.consenting_name_required || false} onChange={handleBlockChange} />*/}
              <Translation>{ (t, { i18n }) =>  t('NAME') + ' ' + t('REQUIRED_FIELD') }</Translation>
              <Switch defaultChecked={this.state.consentingNameRequired || false} onChange={(aValue) => handleSwitchChange('consenting_name_required',aValue)}/>
            </div>

            {/*<p style={{fontSize: 14, fontWeight: 500, margin: 0}}>*/}
            {/*  <Translation>{ (t, { i18n }) => t('SIGNATURE') }</Translation>*/}
            {/*</p>*/}

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontWeight: '500'}}>
              {/*<input name="include_consenting_signature" type="checkbox" checked={block.include_consenting_signature || false} onChange={handleBlockChange} />*/}
              <Translation>{ (t, { i18n }) => t('INCLUDE_SIGNATURE') }</Translation>
              <Switch defaultChecked={this.state.includeConsentingSignature || false} onChange={(aValue) => handleSwitchChange('include_consenting_signature',aValue)}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontWeight: '500'}}>
              {/*<input name="consenting_signature_required" type="checkbox" checked={block.consenting_signature_required || false} onChange={handleBlockChange} />*/}
              <Translation>{ (t, { i18n }) => t('SIGNATURE') + ' ' + t('REQUIRED_FIELD') }</Translation>
              <Switch defaultChecked={this.state.consentingSignatureRequired || false} onChange={(aValue) => handleSwitchChange('consenting_signature_required',aValue)}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontWeight: '500'}}>
              {/*<input name="include_consent_check" type="checkbox" checked={block.include_consent_check || false} onChange={handleBlockChange} />*/}
              <Translation>{ (t, { i18n }) => t('INCLUDE_CONSENT_CHECK') }</Translation>
              <Switch defaultChecked={this.state.includeConsentCheck || false} onChange={(aValue) => handleSwitchChange('include_consent_check',aValue)}/>
            </div>

          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginRight: '20px', marginBottom: '20px'}}>
            <p style={{fontSize: 14, fontWeight: 500, margin: 0}}>
              <Translation>{ (t, { i18n }) => t('CONSENT_CHECK') }</Translation>
            </p>
            <ReactMde
              value={this.props.block.translations[this.props.selectedTranslationIndex].consent_check_text}
              onChange={this.handleEditorChange}
              selectedTab={this.state.selectedTab}
              onTabChange={(value) => this.setState({selectedTab: value})}
              generateMarkdownPreview={markdown =>
                Promise.resolve(converter.makeHtml(markdown))
              }
            />
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '20px', fontWeight: '500'}}>
          {/*<input name="consent_check_required" type="checkbox" checked={block.consent_check_required || false} onChange={handleBlockChange} />*/}
          <Translation>{ (t, { i18n }) => t('CONSENT_CHECK') + ' ' + t('REQUIRED_FIELD') }</Translation>
          <Switch defaultChecked={this.state.consentCheckRequired || false} onChange={(aValue) => handleSwitchChange('consent_check_required',aValue)}/>
        </div>
      </div>
    )
  }
}
