import React from 'react';
import ToolPreview from '../../Components/Tools/ToolPreview/ToolPreview'
import './ToolPreviewModal.scss'
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import '../../Modals/GeneralModal/GeneralModal.scss';

const ToolPreviewModal = ({tool, isOpen, getQuestById, handleCloseModal}) => {
  
  // Render
  
  return (
    <GeneralModal title={<Translation>{ (t, { i18n }) => t('TOOL_PREVIEW') }</Translation>}
                  isOpen={isOpen}
                  onRequestClose={handleCloseModal}
                  isEditing={false}
                  showSaveButton={false}
                  handleSave={() => this.handleSave()}
                  handleCloseModal={handleCloseModal}
                  maxHeight={600}
                  noTitleHeading={true}>
      <ToolPreview tool={tool}
                   getQuestById={getQuestById}
                   selectedTranslation={tool && tool.translations && tool.translations.length > 0 ? tool.translations[0] : {title:'English', locale:'en', display_name:'English (US'}}
                   selectedTranslationIndex={0}
      />
    </GeneralModal>
  )
};
export default ToolPreviewModal
