import {
  LOAD_LOCALES
} from '../constants/ActionTypes';

function locales(state = [], action) {
  switch(action.type) {
    case LOAD_LOCALES:
      return {
        ...state,
        locales: action.result
      }
    default:
      return state;
  }
}

export default locales
