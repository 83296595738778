import React from 'react';
import App from './containers/App';
import axios from 'axios'
import history from "./history";
import {endPoint} from "./Helpers/EndPointHelpers";
import {loadCompany} from 'actions/company';
import LoginContainer from './containers/LoginContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import {Redirect, Route, Switch} from 'react-router-dom';
// import SignUpContainer from './containers/SignUpContainer';

const GraphQLEndpoint = endPoint();

export const getRoutes = (store) => {
  global.api = axios.create({
    baseURL: GraphQLEndpoint
  });
  global.api.interceptors.response.use((newResult) => {return newResult;}, (newError) => {
    if(newError && newError.response && newError.response.status === 401){
      let path = '/login';
      if(window.location.search) {
        path += window.location.search;
      }
      history.push(path)
    }
    else if(newError?.response?.status === 422){
      store.dispatch(loadCompany());
    }
    return Promise.reject(newError.response)
  });
  
  return (
    <div style={{height: '100%'}}>
      <Switch>
      <Route exact path='/' render={(props) => (
          <Redirect to={{pathname: '/patients', search: props.location.search}}/>
        )} />
        <Route path='/patients' component={App} />
        <Route path='/manage' component={App} />
        <Route path='/payments' component={App} />
        <Route path='/groups' component={App} />
        <Route path='/login' component={LoginContainer} />
        <Route path='/reset_password' component={ResetPasswordContainer} />
        <Route path='/forgot' component={ForgotPasswordContainer} />
        {/* <Route path='/signup' component={SignUpContainer}/> TODO: This will be reintroduced later  */}
        <Route path='/articles' component={App}/>
      </Switch>
    </div>
  )
}
