import React from 'react';
import "./ReminderScheduleInfo.scss"
import i18next from "i18next";

export default class ReminderScheduleInfo extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      collapsed: false
    };
  }

  render(){
    return (<div className="reminder-schedule">
      {this.renderMedicationSchedule()}
    </div>)
  }

  toggleCollapsedState(){
    this.setState({collapsed: !this.state.collapsed})
  }

  renderMedicationSchedule(){
    const {reminders} = this.props;

    if(reminders.length === 0){
      return i18next.t('AS_NEEDED');
    }
    const {collapsed} = this.state;

    if(collapsed){
      return (<div className="collapsed-container">
        <div className="clickable collapse-button-up" onClick={() => this.toggleCollapsedState()}/>
        <div className="reminder-schedule-text-container">
          <div className="reminder-schedule-title-collapsed">
            {this.buildScheduleTitle(reminders)}
          </div>
          {this.buildScheduleBody(reminders)}
        </div>
      </div>);
    } else{
      return (<div className="container">
        <div className="clickable collapse-button-down" onClick={() => this.toggleCollapsedState()}/>
        <div className="reminder-schedule-text-container">
          {this.buildScheduleTitle(reminders)}
        </div>
      </div>);
    }
  }

  buildScheduleTitle(reminders){
    let translatedType = reminders[0].repeat_type;

    if(translatedType === 'as needed'){
      translatedType = i18next.t('AS_NEEDED');
    }
    else{
      translatedType = i18next.t('MedicationFrequency.' + translatedType);
    }
    return translatedType;
  }

  buildScheduleBody(reminders){
    const reminderType = reminders[0].repeat_type;
    let body = null;

    switch(reminderType){
      case "daily":
        body = this.formatDailyScheduleBody(reminders);
        break;
      case "weekly":
        body = this.formatWeeklyScheduleBody(reminders);
        break;
      case "monthly":
        body = this.formatMonthlyScheduleBody(reminders);
        break;
      case "interval":
        body = this.formatIntervalScheduleBody(reminders);
        break;
      default:
    }
    return (<div className="reminder-schedule-text">
      {body}
    </div>);
  }

  formatIntervalScheduleBody(reminders){
    if(reminders.length === 1){
      const reminder = reminders[0];
      return i18next.t('TIME_A_INTERVAL') +
          `${reminder.day_number} ` +
          i18next.t('DAY_OF_THE_INTERVAL') +
       `@ ${this.formatTime(reminder.time)}`;
    } else if(reminders.length === 2){
      const [firstReminder, secondReminder] = reminders;
      return i18next.t('TWO_TIMES_A_INTERVAL') +
          `${firstReminder.day_number} ` +
          i18next.t('DAY_OF_THE_INTERVAL') +
       `@ ${this.formatTime(firstReminder.time)} ` +
          i18next.t('AND') +
          ` ${secondReminder.day_number} ` +
          i18next.t('DAY_OF_THE_INTERVAL') +
          ` @ ${this.formatTime(secondReminder.time)}`;
    } else{
      let returnValue = `${reminders.length} ` + i18next.t('TIMES_A_INTERVAL');

      reminders.forEach((reminder, index) => {
        let reminderString = `${reminder.day_number} ` +
            i18next.t('DAY_OF_THE_INTERVAL') +
         ` @ ${this.formatTime(reminder.time)}`;

        if(index < reminders.length - 2){
          reminderString += ", ";
        } else if(index === reminders.length - 2){
          reminderString += i18next.t('AND_TWO')
        }
        returnValue += reminderString;
      });
      return returnValue;
    }
  }

  formatMonthlyScheduleBody(reminders){
    if(reminders.length === 1){
      const reminder = reminders[0];
      return i18next.t('TIME_A_MONTH') +
          `${this.formatDateInMonth(reminder.day_number)} ` +
          i18next.t('DAY_OF_THE_MONTH') +
       `@ ${this.formatTime(reminder.time)}`;
    } else if(reminders.length === 2){
      const [firstReminder, secondReminder] = reminders;
      return i18next.t('TWO_TIMES_A_MONTH') +
          `${this.formatDateInMonth(firstReminder.day_number)} ` +
          i18next.t('DAY_OF_THE_MONTH') +
       `@ ${this.formatTime(firstReminder.time)} ` +
          i18next.t('AND') +
          ` ${this.formatDateInMonth(secondReminder.day_number)} ` +
          i18next.t('DAY_OF_THE_MONTH') +
          ` @ ${this.formatTime(secondReminder.time)}`;
    } else{
      let returnValue = `${reminders.length} ` + i18next.t('TIMES_A_MONTH');

      reminders.forEach((reminder, index) => {
        let reminderString = `${this.formatDateInMonth(reminder.day_number)} ` +
            i18next.t('DAY_OF_THE_MONTH') +
         ` @ ${this.formatTime(reminder.time)}`;

        if(index < reminders.length - 2){
          reminderString += ", ";
        } else if(index === reminders.length - 2){
          reminderString += i18next.t('AND_TWO')
        }
        returnValue += reminderString;
      });
      return returnValue;
    }
  }

  formatWeeklyScheduleBody(reminders){
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    if(reminders.length === 1){
      const reminder = reminders[0];
      return i18next.t('TIME_A_WEEK') +
          `${daysOfWeek[reminder.day_number]} @ ${this.formatTime(reminder.time)}`;
    } else if(reminders.length === 2){
      const [firstReminder, secondReminder] = reminders;
      return i18next.t('TWO_TIMES_A_WEEK') +
          `${daysOfWeek[firstReminder.day_number]} @ ${this.formatTime(firstReminder.time)} ` +
          i18next.t('AND') +
          ` ${daysOfWeek[secondReminder.day_number]} @ ${this.formatTime(secondReminder.time)}`;
    } else{
      let returnValue = `${reminders.length} ` +
          i18next.t('TIMES_A_WEEK');

      reminders.forEach((reminder, index) => {
        let reminderString = `${daysOfWeek[reminder.day_number]} @ ${this.formatTime(reminder.time)}`;

        if(index < reminders.length - 2){
          reminderString += ", ";
        } else if(index === reminders.length - 2){
          reminderString += i18next.t('AND_TWO')
        }
        returnValue += reminderString;
      });
      return returnValue;
    }
  }

  formatDailyScheduleBody(reminders){
    if(reminders.length === 1){
      const reminder = reminders[0];
      return i18next.t('TIME_A_DAY') + `${this.formatTime(reminder.time)}`;
    } else{
      let returnValue = `${reminders.length} ` + i18next.t('TIMES_A_DAY');

      reminders.forEach((reminder, index) => {
        let reminderString = `${this.formatTime(reminder.time)}`;

        if(index < reminders.length - 1){
          reminderString += ", ";
        }
        returnValue += reminderString;
      });
      return returnValue;
    }
  }

  formatTime(reminderTime){
    let hour = parseInt(reminderTime.substring(0, reminderTime.length - 2));
    let minute = parseInt(reminderTime.substring(reminderTime.length - 2));
    let time = new Date();
    time.setHours(hour);
    time.setMinutes(minute);
    return time.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
  }

  formatDateInMonth(day_number){
    switch(day_number){
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      default:
        return `${day_number}th`;
    }
  }
}
