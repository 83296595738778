import React from "react";
import './SurveyBuilderRadioQuestionCell.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import SurveyBuilderChoices from "../../../../Components/SurveyBuilder/SurveyBuilderChoices/SurveyBuilderChoices";

export default class SurveyBuilderRadioQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, translationsArray, errorForIdTypeAndKey} = this.props;
    
    const labelPositionOptionsArray = [{value:'top', label:i18next.t('TOP')}, {value:'left', label:i18next.t('LEFT')}];
    const answerLabelPositionOptionsArray = [{value:'left', label:i18next.t('LEFT')}, {value:'bottom', label:i18next.t('BOTTOM')}];
    
    let numberOfColumnsOptionsArray = [];
    
    for(let number = 1; number <= 5; number += 1){
      numberOfColumnsOptionsArray.push({value:number, label:number.toString()});
    }
    let labelPosition = 'top';
    
    if(question && question.label_position){
      labelPosition = question.label_position;
    }
    let answerLabelPosition = 'left';
  
    if(question && question.answer_label_position){
      answerLabelPosition = question.answer_label_position;
    }
    
    return(
      <div className="survey-builder-radio-question-cell">
        <div className="survey-builder-radio-question-input-container">
          <LabeledDropdown className="survey-builder-radio-question-input"
                           label={<Translation>{ (t, { i18n }) => t('LABEL_POSITION') }</Translation>}
                           value={labelPositionOptionsArray.filter(function(option){
                             return option.value === labelPosition;
                           })}
                           options={labelPositionOptionsArray}
                           infoBlurb={<Translation>{(t, { i18n }) => t('LABEL_POSITION_EXPLANATION')}</Translation>}
                           handleSave={(aOption) => {
                             let updatedQuestion = cloneDeep(question);
                             updatedQuestion.label_position = aOption.value;
                             updateQuestion(updatedQuestion);
                           }}
          />
          
          <LabeledDropdown className="survey-builder-radio-question-input"
                           label={<Translation>{ (t, { i18n }) => t('ANSWER_LABEL_POSITION') }</Translation>}
                           value={answerLabelPositionOptionsArray.filter(function(option){
                             return option.value === answerLabelPosition;
                           })}
                           options={answerLabelPositionOptionsArray}
                           infoBlurb={<Translation>{(t, { i18n }) => t('ANSWER_LABEL_POSITION_EXPLANATION')}</Translation>}
                           handleSave={(aOption) => {
                             let updatedQuestion = cloneDeep(question);
                             updatedQuestion.answer_label_position = aOption.value;
                             updateQuestion(updatedQuestion);
                           }}
          />
          
          <LabeledDropdown className="survey-builder-radio-question-input"
                           label={<Translation>{ (t, { i18n }) => t('NUMBER_OF_COLUMNS') }</Translation>}
                           value={numberOfColumnsOptionsArray.filter(function(option){
                             return option.value === question.number_of_columns;
                           })}
                           options={numberOfColumnsOptionsArray}
                           infoBlurb={<Translation>{(t, { i18n }) => t('NUMBER_OF_COLUMNS_EXPLANATION')}</Translation>}
                           handleSave={(aOption) => {
                             let updatedQuestion = cloneDeep(question);
                             updatedQuestion.number_of_columns = aOption.value;
                             updateQuestion(updatedQuestion);
                           }}
          />
        </div>
        
        <SurveyBuilderChoices question={question}
                              showExclusive={false}
                              updateQuestion={updateQuestion}
                              selectedLanguage={selectedLanguage}
                              translationsArray={translationsArray}
                              errorForIdTypeAndKey={errorForIdTypeAndKey}
        />
      </div>
    )
  }
}
