import React from 'react';
import ToolBlockAnswers from '../ToolBlockAnswers/ToolBlockAnswers'
import ToolBlockSlider from '../../../../Cells/ToolCells/ToolBlockSliderCell/ToolBlockSliderCell'
import { orderBy } from 'lodash'
import ToolBlockConsent from "../../../../Cells/ToolCells/ToolBlockConsentCell/ToolBlockConsentCell";
import {Translation} from "react-i18next";
import LabeledTextarea from "../../../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {Switch} from "antd";
import i18next from "i18next";

const quillConfig = {
  modules: {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      [{ 'font': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline'],
      [{ 'align': [] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true
    }
  },

  formats: [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background', 'font', 'align'
  ],
}

export default class ToolBlockDetails extends React.Component {
  constructor(props) {
    super(props);
    if (document) {
      this.quill = require('react-quill')
    }
    this.state = {
      instructions: "",
      numbers_only: ''
    }
    if(props.block){
      this.state.numbers_only = props.block.numbers_only;
    }
    this.handleBlockChange = this.handleBlockChange.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  handleBlockChange(e) {
    let key = e.target.name
    let value = null

    if(['description', 'minimum_value_string', 'maximum_value_string', 'consent_button_title', 'consent_check_text'].includes(key)){
      this.props.block.translations[this.props.selectedTranslationIndex][key] = e.target.value;
      this.props.updateBlock(this.props.block, this.props.index);
      return;
    }
    if (['minimum_value','maximum_value'].includes(key)) {
      value = parseFloat(e.target.value)
    }
    else {
      value = e.target.value
    }
    let index = this.props.index
    this.props.updateBlock({[key]: value}, index)
  }

  handleSwitchChange(aKey, aValue){
    let index = this.props.index;
    this.props.updateBlock({[aKey]: aValue}, index);
  }

  handleQuillChange(value) {
    this.props.block.translations[this.props.selectedTranslationIndex].description = value;
    this.props.updateBlock(this.props.block, this.props.index)
  }

  render() {
    const { block, index, updateBlock, selectedTranslationIndex } = this.props;
    const Quill = this.quill;
    let answers = block.answers;
    answers = orderBy(answers, 'order', 'asc')
    return (
      <div style={{paddingLeft: '20px', paddingTop: '20px'}}>
        <div>

          {block.type !== 'ContentObjective' &&

            <div>
                <LabeledTextarea style={{width: '100%'}}
                                 name={'description'}
                                 value={block.translations[selectedTranslationIndex].description || ""}
                                 handleSave={this.handleBlockChange}
                                 label={<Translation>{ (t, { i18n }) => t('INSTRUCTIONS') }</Translation>}
                                 placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />

                {/*<textarea className="rounded-input"*/}
                {/*          style={{width: '100%'}}*/}
                {/*          name="description"/>*/}



              {block.type === 'TextObjective' &&
                  <div style={{display: 'flex',justifyContent: 'space-between', marginRight: '20px', marginTop: '20px'}}>
                    <div style={{fontSize: '14px', color: '#4a4a4a', maxWidth: 'calc(100% - 20px', display: 'flex', flexDirection: 'column'}}>
                      <div style={{fontWeight: 600}}>
                        <Translation>{ (t, { i18n }) => t('NUMBER_ENTRY') }</Translation>
                      </div>
                      <div style={{fontSize: '12px'}}>
                        <Translation>{ (t, { i18n }) => t('NUMBER_ENTRY_TEXT') }</Translation>
                      </div>
                    </div>
                    <Switch defaultChecked={this.state.numbers_only || false} onChange={(aValue) => this.handleSwitchChange("numbers_only", aValue)}/>
                  </div>

              }
            </div>

          }

          {block.type === 'ContentObjective' && Quill &&
          <div style={{marginRight: '20px', height: '400px'}}>
            <Quill
              value={block.translations[selectedTranslationIndex].description || ""}
              onChange={this.handleQuillChange}
              modules={quillConfig.modules}
              formats={quillConfig.formats}
              style={{height: '300px'}}
            />
          </div>

          }

          {block.type === 'SliderObjective' &&
              <ToolBlockSlider block={block} handleBlockChange={this.handleBlockChange} selectedTranslationIndex={selectedTranslationIndex}/>
          }

          {block.type === 'MultipleChoiceObjective' &&
              <ToolBlockAnswers
                block={block}
                answers={answers}
                updateBlock={updateBlock}
                index={index}
                handleBlockChange={this.handleBlockChange}
                selectedTranslationIndex={selectedTranslationIndex}
              />
          }

          {block.type === 'ConsentObjective' &&
            <ToolBlockConsent
              block={block}
              handleBlockChange={this.handleBlockChange}
              selectedTranslationIndex={selectedTranslationIndex}
              updateBlock={updateBlock}
              index={index}
              quillConfig={quillConfig}
              handleSwitchChange={this.handleSwitchChange}
            >
            </ToolBlockConsent>
          }
        </div>
      </div>
    )
  }
}
