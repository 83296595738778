import {
  FETCH_PATIENTS,
  LOAD_PATIENTS
} from 'constants/ActionTypes';
import {loadCompany} from './company'

export function uploadPatientsCSV(aFile) {
  return (dispatch, getState) => {
    let state = getState();
    let companyId = state.company && state.company.company && state.company.company.id;

    const file = new FormData();
    file.append('csv', aFile, aFile.name);

    return global.api.post("api/companies/import_users?id=" + companyId, file, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${file._boundary}`
      },
      timeout: 30000,
    });
  };
}

export function fetchCompanyAndPatients({groupName} = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_PATIENTS
    });

    dispatch(loadCompany()).then(() => {
      dispatch(searchPatients({query: "", groupName}))
    })
  };
}

export function fetchPatients({groupName} = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_PATIENTS
    });

    dispatch(searchPatients({query: "", groupName}))
  };
}

export function fetchPatientsScroll(aQuery, aGroupName, aLimit, aOffset, aOrderBy = null, aOrderByAscending = null, aPreferredLanguage = null, aAlertType = null, aVirtualAppointmentDate = null, aDispositionCode = null, aSearchByIdType = null, aSurveyIdForSorting = null, aQuestionKeyForSorting = null, aDynamicFields = null, aAnswerKeyForSorting = null, aIncludeAll = null, aGroupIdsToExclude = null){
  let graphQLQuery = `
    query findUsers($query: String!, $company_id: ID!, $group_id: ID, $offset: Int, $limit: Int, $preferred_language: String, $alert_type: String, $virtual_appointment_date: String, $disposition_code: String, $order_by_ascending: Boolean, $search_by_id_type: String, $survey_id_for_sorting: Int, $question_key_for_sorting: String, $dynamic_fields: JSON, $include_all: Boolean, $answer_key_for_sorting: String, $order_by: String, $group_ids_to_exclude: [ID]) {
      user_page(query: $query, company_id: $company_id, roles: ["patient"], group_id: $group_id, offset: $offset, limit: $limit, preferred_language: $preferred_language, alert_type: $alert_type, virtual_appointment_date: $virtual_appointment_date, disposition_code: $disposition_code, order_by_ascending: $order_by_ascending, search_by_id_type: $search_by_id_type, survey_id_for_sorting: $survey_id_for_sorting, question_key_for_sorting: $question_key_for_sorting, dynamic_fields: $dynamic_fields, include_all: $include_all, answer_key_for_sorting: $answer_key_for_sorting, order_by: $order_by, group_ids_to_exclude: $group_ids_to_exclude){
        users{
          id
          first_name
          last_name
          email
          edc_id
          avatar_id
          gender
          image_id
          last_name
          birth_year
          first_name
          test_account
          subject_status
          last_sign_in_at
          disposition_code
          can_trigger_alerts
          number_of_uncleared_company_notification_receipts
          groups (group_type: "company", core: false, company_id: $company_id) {
            id
            name
            core
            company_id
            group_type
          }
          number_of_unverified_fraud_events
          fraud_statistic {
            id
            verified_comments
            verification_status
            probability
            verified_by_user {
              first_name
              last_name
            }
          }
          site_id
          email_verified
          invite_status
          completed_surveys_count
          incomplete_survey_count
          active_surveys_count
          survey_responses{
            survey{
              name
            }
            complete
          }
          dynamic_fields{
            survey_id
            question_key
            complete
            answer
          }
        }
        progress_break_down{
          complete
          incomplete
          verified
          unverified
          active
          pending
          rejected
          matched
          unmatched
        }
        total
      }
    }
  `;

  let variables = {
    query:aQuery ? aQuery : '',
    limit:aLimit,
    offset:aOffset,
    order_by:aOrderBy,
    order_by_ascending:aOrderByAscending,
    preferred_language:aPreferredLanguage,
    alert_type:aAlertType,
    virtual_appointment_date:aVirtualAppointmentDate,
    disposition_code:aDispositionCode,
    search_by_id_type:aSearchByIdType,
    survey_id_for_sorting:aSurveyIdForSorting,
    question_key_for_sorting:aQuestionKeyForSorting,
    dynamic_fields:aDynamicFields,
    answer_key_for_sorting:aAnswerKeyForSorting,
    include_all:aIncludeAll,
    roles:["patient"],
    group_ids_to_exclude:aGroupIdsToExclude
  };

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    let returnValue;

    if(variables['company_id']){
      let group = state.company && state.company.company && state.company.company.groups && state.company.company.groups.find((group) => group.name === aGroupName);
      variables['group_id'] = group && group.id;
      returnValue = global.api.post("/queries", { query: graphQLQuery, variables });
    }
    else{
      returnValue = dispatch(loadCompany()).then(() => {
        let group = state.company && state.company.company && state.company.company.groups && state.company.company.groups.find((group) => group.name === aGroupName);
        variables['group_id'] = group && group.id;
        global.api.post("/queries", { query: graphQLQuery, variables });
      })
    }
    return returnValue;
  };
}

export function searchPatients({query, groupName}) {
  let graphQLQuery = `
      query findUsers($query: String!, $company_id: ID!, $group_id: ID) {
        users(query: $query, company_id: $company_id, roles: ["patient"], group_id: $group_id) {
          id
          first_name
          last_name
          email
          birth_year
          gender
          image_id
          test_account
          can_trigger_alerts
          last_sign_in_at
          edc_id
          number_of_uncleared_company_notification_receipts
          groups (group_type: "company", core: false, company_id: $company_id) {
            id
            name
            core
            company_id
            group_type
          }
        }
      }
    `;
  let variables = { query };

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    let group = state.company && state.company.company && state.company.company.groups.find(group => group.name === groupName);
    variables['group_id'] = group && group.id;
    return global.api.post("/queries", { query: graphQLQuery, variables }).then((result) => {
      let patients = result.data.data.users;
      dispatch({
        type: LOAD_PATIENTS,
        result: patients
      });

    }).catch((error) => {
      dispatch({
        type: LOAD_PATIENTS,
        error,
      });
    });
  };
}
