import React from 'react';
import { withRouter } from "react-router";
import PatientInfoListItem from '../../../Cells/PatientInfoCell/PatientInfoCell'
import {flow} from "lodash";
import {Translation, withTranslation} from "react-i18next";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import '../../../Pages/PatientProfile/PatientProfile.scss';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Loading from "../../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import i18next from "i18next";


const notifyRemoveGroupError = (aError) => toast(<Translation>{ (t, {i18n}) => t('ERROR_REMOVING_GROUP', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyRemoveGroupSuccess = () => toast(<Translation>{ (t, {i18n}) => t('SUCCESS_REMOVING_GROUP') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

class Groups extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      groupsArray:[],
      selectedGroup:null,
      groupToAddToUser:'',
      menuAnchorElement:null,
      eConsentConfirmationModalOpen:false,
      toolConfirmationModalOpen:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addUserToGroup = this.addUserToGroup.bind(this);
    this.removeUserFromGroup = this.removeUserFromGroup.bind(this);
    this.handleGroupSelectSave = this.handleGroupSelectSave.bind(this);
    
    if(this.props.patient && this.props.patient.groups){
      this.state.groupsArray = this.props.patient.groups;
    }
  }
  
  componentDidMount(){
    this.loadGroups();
  }
  
  // Methods
  
  loadGroups(){
    this.setState({ loading:true });
    this.props.loadPatientGroups(this.props.patient.id).then((newResult) => {
      this.setState({ loading:false, loadFailed:false });
    }, (newError) => {
      this.setState({ loading:false, loadFailed:true });
    });
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.groups){
      if(this.state.groupsArray !== this.props.patient.groups){
        this.setState({groupsArray:this.props.patient.groups});
      }
    }
  }
  
  addUserToGroup(){
    this.props.addUserToGroup({groupId: this.state.groupToAddToUser, userId: this.props.patient.id}).then(newResult => {
      this.props.loadPatientGroups(this.props.patient.id);
      this.props.fetchCompanyAndPatients({groupName: this.props.match.params.group})
    });
    this.setState({ groupToAddToUser:'' })
  }
  
  removeUserFromGroup = (groupId) => {
    const { groupsArray } = this.state;
    const userId = this.props.patient.id;
    const index = groupsArray.map((aGroup) => {return aGroup.id}).indexOf(groupId);
    
    if(index > -1){
      groupsArray.splice(index, 1);
    }
    this.props.removeUserFromGroup({groupId, userId: userId}).then(newResult => {
      this.props.loadPatientGroups(userId);
      this.props.fetchCompanyAndPatients({groupName:this.props.match.params.group})
      notifyRemoveGroupSuccess();
    }, newError => {
      let error = '';
      
      if(newError && newError.data && newError.data && newError.data.error){
        error = newError.data.error;
      }
      notifyRemoveGroupError(error);
      this.props.loadPatientGroups(userId);
      this.props.fetchCompanyAndPatients({groupName:this.props.match.params.group})
    });
    this.closeMenu();
    this.setState({ groupToAddToUser:'' })
  };

  handleChange(aEvent){
    this.setState({ [aEvent.target.name]:aEvent.target.value })
  }
  
  handleGroupSelectSave(aValue){
    this.setState({ groupToAddToUser:aValue.value });
  }
  
  closeMenu(){
    this.setState({ menuAnchorElement:null, selectedGroup:null });
  }
  
  // Render
  
  render(){
    let {patient, groups, company} = this.props;
    let {menuAnchorElement, selectedGroup, groupToAddToUser, groupsArray, loading, loadFailed, eConsentConfirmationModalOpen} = this.state;
    
    const addPermissions = ["admin", "admin_level_2", "admin_level_4", "admin_level_10"];
    let currentRole = roleForCompany(company);
    let groupOptions = [];
    
    if(patient && patient.groups){
      let currentGroupIds = patient.groups.map((group) => {
        return group.id
      });
      if(groups){
        let availableGroups = groups.filter((obj, pos, arr) => {
          return currentGroupIds.indexOf(obj.id) < 0
        });
        for(let group of availableGroups){
          groupOptions.push({value:group.id, label:group.name});
        }
      }
    }
    let totalGroups = 0;
    
    if(patient && patient.groups){
      totalGroups = patient.groups.length;
    }
    return (
      <div className="groups-outer-container">
        <div className="groups-inner-container">
          <div className="group-list-title-cell">
            <div>
              <Translation>{ (t, { i18n }) => t('TOTAL_GROUPS') }</Translation> - {totalGroups}
            </div>
            
            {addPermissions.includes(currentRole) ?
              <LabeledDropDown className="patient-list-input-large"
                               value={groupOptions && groupOptions.filter(function(option) {
                                 return option.value === groupToAddToUser;
                               })}
                               options={groupOptions}
                               handleSave={this.handleGroupSelectSave}
                               showSearchIcon={true}
                               addButtonClicked={this.addUserToGroup}/>
              :
              null
            }
          </div>
        </div>
        
        <div className='list-outer-container'>
          <div className='list-inner-container'>
            {loading ?
              <Loading/>
              :
              (loadFailed ?
                  <LoadFailed retry={() => this.loadGroups()}/>
                  :
                  (patient && groupsArray.length > 0 ?
                      <>
                        {patient.groups.map((group, index) => (
                          <PatientInfoListItem key={'group-' + index + '-' + group.id}
                                               data={group}
                                               group={group}
                                               menuButtonClicked={addPermissions.includes(currentRole) ?
                                                 (aEvent) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedGroup:group})
                                                 :
                                                 null
                                               }
                          />
                        ))}
                      </>
                      :
                      <div className="group-no-groups-found">
                        <Translation>{ (t, { i18n }) => t('NO_GROUPS_FOUND') }</Translation>
                      </div>
                  )
              )}
          </div>
        </div>
        
        {menuAnchorElement ?
          <Menu id="simple-menu"
                open={Boolean(menuAnchorElement)}
                onClose={() => this.closeMenu()}
                anchorEl={menuAnchorElement}
                keepMounted>
            <MenuItem style={{position:'relative'}}
                      onClick={(aEvent) => {
                        aEvent.preventDefault();
                        aEvent.stopPropagation();
                        this.setState({eConsentConfirmationModalOpen:true});
                      }}>
              <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
            </MenuItem>
  
            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:selectedGroup ? selectedGroup.name : ''})}
                               isOpen={eConsentConfirmationModalOpen}
                               reject={() => this.setState({eConsentConfirmationModalOpen:false})}
                               confirm={() => this.removeUserFromGroup(selectedGroup.id)}
            />
          </Menu>
          :
          null
        }
      </div>
    )
  }
}
export default flow(withRouter, withTranslation())(Groups)
