import React from 'react';
import './StepLevel.scss'

const createElements = (currentStep, totalSteps) =>{
  let elements = [];

  for(let i = 0; i < totalSteps; i++){
    elements.push(<div key={i} className={i <= currentStep ? 'filled' : 'unfilled'}/>);
  }
  return elements;
};
const StepLevel = ({currentStep, totalSteps}) => (
  <div className='step-level'>
    {
      createElements(currentStep, totalSteps)
    }
  </div>
)

export default StepLevel;
