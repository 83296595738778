import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchAppAdditionalColumns(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_APP_ADDITIONAL_COLUMNS);
  let query = `
    query additional_app_columns ($search_string: String, $limit: Int, $offset: Int, $company_id: ID) {
      additional_app_columns (search_string: $search_string, limit: $limit, offset: $offset, company_id: $company_id) {
        total
        additional_app_columns {
          id
          name
          name_type
          created_at
          updated_at
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}

export function validateRubularRegex(aRegex){
  // Note: Must be of a valid Rubular type ( https://rubular.com/ ).
  EventTracker.track(EventTypes.VALIDATE_RUBULAR_REGEX);
  let query = `
    query validate_regex ($regex: String!) {
      validate_regex (regex: $regex){
        is_valid
        js_regex
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['regex'] = aRegex;
    return global.api.post("/queries", { query, variables })
  };
}

export function addQuestForSurveyVersionCollection(aSurveyVersionCollectionId, aTitle, aHSCConsent = false, aOnboardingQuest = false){
  EventTracker.track(EventTypes.ADD_QUEST_FOR_SURVEY_VERSION_COLLECTION);
  let query = `
    mutation AddQuestForSurveyVersionCollectionMutation ($addQuestForSurveyVersionCollectionMutationInput: AddQuestForSurveyVersionCollectionMutationInput!) {
      addQuestForSurveyVersionCollectionMutation (input: $addQuestForSurveyVersionCollectionMutationInput){
        quest {
          id
        }
      }
    }
  `;
  
  let operationName = `AddQuestForSurveyVersionCollectionMutation`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['survey_version_collection_id'] = aSurveyVersionCollectionId;
    params['title'] = aTitle;
    
    if(aOnboardingQuest){
      params['onboarding_quest'] = true;
    }
    if(aHSCConsent){
      params['hsc_consent'] = true;
    }
    let variables = {
      addQuestForSurveyVersionCollectionMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function archiveSurveyVersionCollection(aSurveyVersionCollectionId, aIsArchived){
  if(aIsArchived){
    EventTracker.track(EventTypes.ARCHIVE_SURVEY_VERSION_COLLECTION);
  }
  else{
    EventTracker.track(EventTypes.UNARCHIVE_SURVEY_VERSION_COLLECTION);
  }
  let query = `
    mutation UpdateSurveyVersionCollectionIsArchivedMutation ($updateSurveyVersionCollectionIsArchivedMutationInput: UpdateSurveyVersionCollectionIsArchivedMutationInput!) {
      updateSurveyVersionCollectionIsArchivedMutation (input: $updateSurveyVersionCollectionIsArchivedMutationInput){
        survey_version_collection {
          id
          name
          reference
          created_at
          updated_at
          is_editable
          is_archived
          current_survey {
            id
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          draft_survey {
            id
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
        }
      }
    }
  `;
  
  let operationName = `UpdateSurveyVersionCollectionIsArchivedMutation`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['id'] = aSurveyVersionCollectionId;
    params['is_archived'] = aIsArchived;
    
    let variables = {
      updateSurveyVersionCollectionIsArchivedMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function fetchQuestionsForSurveyById(aId){
  EventTracker.track(EventTypes.FETCH_QUESTIONS_FOR_SURVEY);
  let query = `
    query survey_questions_for_survey_version_collection ($company_id: ID!, $id: ID, $reference: String, $use_draft_survey: Boolean) {
      survey_questions_for_survey_version_collection (company_id: $company_id, id: $id, reference: $reference, use_draft_survey: $use_draft_survey) {
        key
        type
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['id'] = aId;
    variables['use_draft_survey'] = false;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchQuestionsForSurveyByReference(aReference){
  EventTracker.track(EventTypes.FETCH_QUESTIONS_FOR_SURVEY);
  let query = `
    query survey_questions_for_survey_version_collection ($company_id: ID!, $id: ID, $reference: String, $use_draft_survey: Boolean) {
      survey_questions_for_survey_version_collection (company_id: $company_id, id: $id, reference: $reference, use_draft_survey: $use_draft_survey) {
        key
        type
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['reference'] = aReference;
    variables['use_draft_survey'] = false;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSurveysForSurveyVersionCollection(aId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_SURVEYS_FOR_SURVEY_VERSION_COLLECTION);
  let query = `
    query surveys_in_survey_version_collection ($company_id: ID!, $survey_version_collection_id: ID!, $search_string: String, $limit: Int, $offset: Int) {
      surveys_in_survey_version_collection (company_id: $company_id, survey_version_collection_id: $survey_version_collection_id, search_string: $search_string, limit: $limit, offset: $offset) {
        surveys {
          id
          name
          reference
          created_at
          updated_at
          version_number
          published_by_user {
            id
            last_name
            first_name
          }
          updated_by_user {
            id
            last_name
            first_name
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    variables['survey_version_collection_id'] = aId;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSurveyVersionCollection(aId, aReference){
  EventTracker.track(EventTypes.FETCH_SURVEY_VERSION_COLLECTION);
  let query = `
    query survey_version_collection ($company_id: ID!, $reference: String, $id: ID) {
      survey_version_collection (company_id: $company_id, reference: $reference, id: $id) {
        id
        name
        reference
        created_at
        updated_at
        is_editable
        is_archived
        current_survey {
          id
          data
          name
          reference
          admin_only
          is_repeatable
          version_number
          onboarding_survey
          terms_and_privacy
          published_by_user {
            id
            last_name
            first_name
          }
          updated_by_user {
            id
            last_name
            first_name
          }
        }
        draft_survey {
          id
          data
          name
          reference
          admin_only
          is_repeatable
          version_number
          onboarding_survey
          terms_and_privacy
          published_by_user {
            id
            last_name
            first_name
          }
          updated_by_user {
            id
            last_name
            first_name
          }
        }
        dependent_survey_version_collections {
          id
          name
          reference
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aId){
      variables['id'] = aId;
    }
    else if(aReference){
      variables['reference'] = aReference;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSurveyVersionCollectionById(aId){
  return fetchSurveyVersionCollection(aId, null);
}

export function fetchSurveyVersionCollectionByReference(aReference){
  return fetchSurveyVersionCollection(null, aReference);
}

export function fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField){
  EventTracker.track(EventTypes.FETCH_SURVEY_VERSION_COLLECTIONS);
  let query = `
    query survey_version_collections ($company_id: ID!, $search_string: String, $search_by_field: String, $limit: Int, $offset: Int, $is_archived: Boolean, $reference_required: Boolean) {
      survey_version_collections (company_id: $company_id, search_string: $search_string, search_by_field: $search_by_field, limit: $limit, offset: $offset, is_archived: $is_archived, reference_required: $reference_required) {
        survey_version_collections {
          id
          name
          reference
          created_at
          updated_at
          is_editable
          is_archived
          current_survey {
            id
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          draft_survey {
            id
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['is_archived'] = aIsArchived;
    variables['search_string'] = aSearchString;
    variables['search_by_field'] = aSearchByField;
    variables['reference_required'] = aReferenceRequired;
    return global.api.post("/queries", { query, variables })
  };
}

export function saveSurveyVersionCollection(aSurveyVersionCollectionId, aName, aYAML, aKeyword, aIsRepeatable, aAdminOnly, aTermsAndPrivacy){
  EventTracker.track(EventTypes.SAVE_SURVEY_VERSION_COLLECTION);
  let query = `
    mutation SaveSurveyVersionCollectionMutation ($saveSurveyVersionCollectionMutationInput: SaveSurveyVersionCollectionMutationInput!) {
      saveSurveyVersionCollectionMutation (input: $saveSurveyVersionCollectionMutationInput){
        survey_version_collection {
          id
          name
          reference
          created_at
          updated_at
          is_editable
          is_archived
          current_survey {
            id
            data
            name
            reference
            admin_only
            updated_at
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          draft_survey {
            id
            data
            name
            reference
            admin_only
            updated_at
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          dependent_survey_version_collections {
            id
            name
            reference
          }
        }
      }
    }
  `;
  
  let operationName = `SaveSurveyVersionCollection`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['data'] = aYAML;
    params['admin_only'] = aAdminOnly;
    params['is_repeatable'] = aIsRepeatable;
    params['terms_and_privacy'] = aTermsAndPrivacy;
    
    if(aName){
      params['name'] = aName;
    }
    if(aKeyword){
      params['reference'] = aKeyword;
    }
    if(aSurveyVersionCollectionId){
      params['id'] = aSurveyVersionCollectionId;
    }
    let variables = {
      saveSurveyVersionCollectionMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function publishSurveyVersionCollection(aSurveyVersionCollectionId){
  EventTracker.track(EventTypes.PUBLISH_SURVEY_VERSION_COLLECTION);
  let query = `
    mutation PublishDraftSurveyMutation ($publishDraftSurveyMutationInput: PublishDraftSurveyMutationInput!) {
      publishDraftSurveyMutation (input: $publishDraftSurveyMutationInput){
        survey_version_collection {
          id
          name
          reference
          created_at
          updated_at
          is_editable
          is_archived
          current_survey {
            id
            data
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          draft_survey {
            id
            data
            name
            reference
            admin_only
            is_repeatable
            version_number
            onboarding_survey
            terms_and_privacy
            published_by_user {
              id
              last_name
              first_name
            }
            updated_by_user {
              id
              last_name
              first_name
            }
          }
          dependent_survey_version_collections {
            id
            name
            reference
          }
        }
      }
    }
  `;
  
  let operationName = `PublishDraftSurvey`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['survey_version_collection_id'] = aSurveyVersionCollectionId;
    
    let variables = {
      publishDraftSurveyMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}
