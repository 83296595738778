import React from 'react';
import './SurveyVersionCollectionsList.scss';
import Icon from "antd/lib/icon";
import Info from "../../Custom UI/Info/Info";
import {toast} from "react-toastify";
import i18next from "i18next";
import DynamicList from "../DynamicList/DynamicList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import {formatUserName} from "../../Helpers/AdminHelpers";
import SurveyToToolModal from "../../Modals/SurveyToToolModal/SurveyToToolModal";
import {momentFromDateTime} from "../../Helpers/DateHelpers";
import SurveyVersionCollectionModal from "../../Modals/SurveyVersionCollectionModal/SurveyVersionCollectionModal";
import {surveyVersionCollectionIsLive, surveyVersionCollectionIsLocked, surveyVersionCollectionLastUpdatedByUser, surveyVersionCollectionVersionNumber} from "../../Helpers/SurveyBuilderHelpers";

const notifyArchiveError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('ARCHIVE_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyUnarchiveError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('UNARCHIVE_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyArchiveSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SURVEY_ARCHIVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCreateQuestError = (aName, aError) =>  toast(<Translation>{ (t, { i18n }) => t('CREATE_QUEST_ERROR', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyUnarchiveSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SURVEY_UNARCHIVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCreateQuestSuccess = (aName) =>  toast(<Translation>{ (t, { i18n }) => t('CREATE_QUEST_SUCCESS', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);

export default class SurveyVersionCollectionsList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      archivedOnly:false,
      shouldReload:false,
      showEditSurveyModal:false,
      showSurveyToToolModal:false,
      selectedSurveyVersionCollection:null
    };
    this.renderSurveyStatus = this.renderSurveyStatus.bind(this);
    this.archiveSurveyVersionCollection = this.archiveSurveyVersionCollection.bind(this);
    this.createQuestFromSurveyVersionCollection = this.createQuestFromSurveyVersionCollection.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(!prevProps.company && prevProps.company !== this.props.company){
      this.setState({selectedSurveyVersionCollection:null, shouldReload:true});
    }
  }
  
  // Methods
  archiveSurveyVersionCollection(aSurveyVersionCollection){
    if(aSurveyVersionCollection){
      this.props.archiveSurveyVersionCollection(aSurveyVersionCollection.id, !aSurveyVersionCollection.is_archived).then((newResponse) => {
        if(newResponse.data.data.updateSurveyVersionCollectionIsArchivedMutation.survey_version_collection.is_archived){
          notifyArchiveSuccess();
        }
        else{
          notifyUnarchiveSuccess();
        }
        this.setState({selectedSurveyVersionCollection:null, shouldReload:true});
      }, (newError) => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        if(aSurveyVersionCollection.is_archived){
          notifyArchiveError(error);
        }
        else{
          notifyUnarchiveError(error);
        }
        this.setState({selectedSurveyVersionCollection:null});
      });
    }
  }
  
  createQuestFromSurveyVersionCollection(aTitle, aHSCConsent, aOnboardingQuest){
    const { fetchTools, addQuestForSurveyVersionCollection } = this.props;
    const { selectedSurveyVersionCollection } = this.state;
    
    if(selectedSurveyVersionCollection){
      addQuestForSurveyVersionCollection(selectedSurveyVersionCollection.id, aTitle, aHSCConsent, aOnboardingQuest).then((newResponse) => {
        notifyCreateQuestSuccess(aTitle);
        fetchTools();
      }, (newError) => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyCreateQuestError(aTitle, error);
      });
      this.setState({showSurveyToToolModal:false, selectedSurveyVersionCollection:null});
    }
  }
  
  renderSurveyStatus(aSurveyVersionCollection){
    let locked = surveyVersionCollectionIsLocked(aSurveyVersionCollection, this.props.user);
    let status = i18next.t('DRAFT');
    let lastUpdatedByUser = surveyVersionCollectionLastUpdatedByUser(aSurveyVersionCollection);
  
    if(surveyVersionCollectionIsLive(aSurveyVersionCollection)){
      status = i18next.t('LIVE');
    }
    return (
      <div className="survey-version-collection-cell-status">
        {locked ?
          <div className="survey-version-collection-cell-locked">
            <img alt='adminLocked'
                 src='/imgs/app/admin-locked.svg'
                 className="survey-version-collection-cell-locked-image"
                 style={{width:'30px', height:'30px'}}
            />
            
            <Info message={i18next.t('LOCKED_BY_ADMIN', {aName:formatUserName(lastUpdatedByUser)})}>
              <div className="patient-tools-notes-popover-button">
                <Icon type="ellipsis"/>
              </div>
            </Info>
          </div>
          :
          <div>
            {status}
          </div>
        }
      </div>
    );
  }
  
  // Render
  
  render() {
    const {client, archivedOnly, showEditSurveyModal, showSurveyToToolModal, selectedSurveyVersionCollection,
      shouldReload} = this.state;
    const {user, company, className, languages, fetchDynamicLists, explanationKey, fetchQuestionsForSurveyByReference,
      saveSurveyVersionCollection, fetchSurveyVersionCollections, publishSurveyVersionCollection, fetchSurveyLists,
      fetchSurveyVersionCollectionById, fetchSurveyVersionCollectionByReference, fetchSurveyListById, fetchSurveyById,
      fetchSurveysForSurveyVersionCollection, addSurveyList, fetchSurveyListByReference, fetchAppAdditionalColumns,
      canEditSurvey, validateRubularRegex, fetchDynamicListById, fetchDynamicListByReference} = this.props;
    
    let readOnly = !canEditSurvey || (selectedSurveyVersionCollection && (!selectedSurveyVersionCollection.is_editable || selectedSurveyVersionCollection.is_archived));
  
    let columnsArray = [];

    columnsArray.push({key:'name', columnWeight:1.5, columnNameKey:'SURVEY_NAME', propertiesArray:['name']});
    columnsArray.push({key:'reference', columnWeight:2, columnNameKey:'REFERENCE', propertiesArray:['reference']});
    columnsArray.push({key:'updated_at', columnWeight:1, columnNameKey:'SURVEY_DATE', propertiesArray:['updated_at'], valueFormatter:(aDate) => momentFromDateTime(aDate)});
    columnsArray.push({key:'version', columnWeight:1, columnNameKey:'VERSION', templateCell:(aSurveyVersionCollection) => surveyVersionCollectionVersionNumber(aSurveyVersionCollection)});
    columnsArray.push({key:'status', columnWeight:1, columnNameKey:'SURVEY_STATUS', templateCell:(aSurveyVersionCollection) => this.renderSurveyStatus(aSurveyVersionCollection)});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'MAKE_A_TOOL', clickAction:(aSurveyVersionCollection) => this.setState({selectedSurveyVersionCollection:aSurveyVersionCollection, showSurveyToToolModal:true}), isValid:(aSurveyVersionCollection) => {
        return aSurveyVersionCollection && !surveyVersionCollectionIsLocked(aSurveyVersionCollection, user) && canEditSurvey && !(aSurveyVersionCollection && aSurveyVersionCollection.is_archived);
      }});
    menuItemsArray.push({title:'EDIT', clickAction:(aSurveyVersionCollection) => this.setState({selectedSurveyVersionCollection:aSurveyVersionCollection, showEditSurveyModal:true}), isValid:(aSurveyVersionCollection) => aSurveyVersionCollection && !surveyVersionCollectionIsLocked(aSurveyVersionCollection, user) && !readOnly});
    menuItemsArray.push({title:'UNARCHIVE', clickAction:(aSurveyVersionCollection) => {
        this.setState({selectedSurveyVersionCollection:aSurveyVersionCollection});
        this.archiveSurveyVersionCollection(aSurveyVersionCollection);
      }, isValid:(aSurveyVersionCollection) => aSurveyVersionCollection && !surveyVersionCollectionIsLocked(aSurveyVersionCollection, user) && aSurveyVersionCollection.is_editable && aSurveyVersionCollection.is_archived});
    menuItemsArray.push({title:'ARCHIVE', clickAction:(aSurveyVersionCollection) => {
        this.setState({selectedSurveyVersionCollection:aSurveyVersionCollection});
        this.archiveSurveyVersionCollection(aSurveyVersionCollection);
      }, isValid:(aSurveyVersionCollection) => aSurveyVersionCollection && !surveyVersionCollectionIsLocked(aSurveyVersionCollection, user) && aSurveyVersionCollection.is_editable && !aSurveyVersionCollection.is_archived});
  
// TODO: Add once disabling the controls is working.
//     <Translation>{(t, {i18n}) => t('VIEW')}</Translation>
    
    return (
      <div className={'survey-version-collections-list-container ' + className}>
        <div className="survey-version-collections-list-outer-scroll-container"
             id="survey-version-collection-list">
          <DynamicList id="survey-version-collections-list"
                       didReload={() => this.setState({shouldReload:false})}
                       menuCancel={() => this.setState({selectedSurveyVersionCollection:null})}
                       headerView={
                         <div className="survey-version-collections-list-switch-input">
                           <LabeledSwitch label={<Translation>{ (t, { i18n }) => t('ARCHIVED_ONLY') }</Translation>}
                                          checked={archivedOnly}
                                          onChange={(isChecked) => this.setState({archivedOnly:isChecked, shouldReload:true})}
                                          infoBlurb={<Translation>{ (t, { i18n }) => t('ARCHIVED_ONLY_EXPLANATION') }</Translation>}
                                          checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                          popoverPlacement={"top"}
                                          unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                           />
                         </div>
                       }
                       shouldReload={shouldReload}
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset, aSearchString) => this.props.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, archivedOnly, false, null)}
                       menuItemsArray={menuItemsArray}
                       totalObjectsKey='TOTAL_SURVEYS'
                       explanationKey={explanationKey}
                       responseTotalKeysArray={['data', 'data', 'survey_version_collections', 'total']}
                       responseObjectKeysArray={['data', 'data', 'survey_version_collections', 'survey_version_collections']}
          />
        </div>
        
        {showEditSurveyModal ?
          <SurveyVersionCollectionModal user={user}
                                        client={client}
                                        isOpen={showEditSurveyModal}
                                        company={company}
                                        readOnly={readOnly}
                                        languages={languages}
                                        addSurveyList={addSurveyList}
                                        fetchSurveyById={fetchSurveyById}
                                        fetchSurveyLists={fetchSurveyLists}
                                        handleCloseModal={() => this.setState({showEditSurveyModal:false, selectedSurveyVersionCollection:null, shouldReload:true})}
                                        fetchDynamicLists={fetchDynamicLists}
                                        fetchSurveyListById={fetchSurveyListById}
                                        fetchDynamicListById={fetchDynamicListById}
                                        validateRubularRegex={validateRubularRegex}
                                        fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                                        surveyVersionCollectionId={selectedSurveyVersionCollection.id}
                                        fetchSurveyListByReference={fetchSurveyListByReference}
                                        fetchDynamicListByReference={fetchDynamicListByReference}
                                        saveSurveyVersionCollection={saveSurveyVersionCollection}
                                        fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                        publishSurveyVersionCollection={publishSurveyVersionCollection}
                                        fetchSurveyVersionCollectionById={fetchSurveyVersionCollectionById}
                                        fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                                        fetchSurveysForSurveyVersionCollection={fetchSurveysForSurveyVersionCollection}
                                        fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
          />
          :
          null
        }
        
        {showSurveyToToolModal ?
          <SurveyToToolModal isOpen={showSurveyToToolModal}
                             handleFinish={(aTitle, aHSCConsent, aOnboardingQuest) => this.createQuestFromSurveyVersionCollection(aTitle, aHSCConsent, aOnboardingQuest)}
                             initialTitle={selectedSurveyVersionCollection.name}
                             handleCloseModal={() => this.setState({showSurveyToToolModal:false, selectedSurveyVersionCollection:null})}
          />
          :
          null
        }
      </div>
    )
  }
}
