import React from 'react';
import {endPoint} from "../../../../Helpers/EndPointHelpers";

const MultipleChoiceObjective = ({ objective, selectedTranslationIndex }) => (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <label>
      {objective.translations[selectedTranslationIndex].description}
    </label>
    { objective.answers && objective.answers.length > 0 && 
        (
          <form style={{display: 'flex', flexDirection: 'column'}}>
            {objective.answers.map((answer,index) => {
              return (
                <label key={index}>
                  <input style={{marginRight: '1em'}} type={objective.multiselect ? 'checkbox' : 'radio'} name={objective.id} value={answer.id}/>
                  {!answer.has_image && answer.translations[selectedTranslationIndex].answer_text}
                  {answer.has_image && answer.image_data &&
                      <img src={"data:image/jpeg;base64,"+answer.image_data} alt={answer.translations[selectedTranslationIndex].answer_text} />
                  }
                  {answer.has_image && !answer.image_data &&
                      <img src={endPoint() + '/api/images/answer/' + answer.id + '.jpg'} alt={'Answer ' + answer.id} />
                  }
                </label>
              )
            })
            }
          </form>
        )
    }
  </div>
);

export default MultipleChoiceObjective
