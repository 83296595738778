import React from "react";
import {Translation} from "react-i18next";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyTimeQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      hour:'00',
      answer:'',
      minute:'00'
    };
    if(props.answer !== null && props.answer.value !== null && props.answer.value !== undefined){
      this.state.hour = props.answer.value.split(':')[0];
      this.state.answer = props.answer.value;
      this.state.minute = props.answer.value.split(':')[1];
    }
    this.saveAnswer = this.saveAnswer.bind(this);
  }
  
  // Methods
  
  saveAnswer(aMinute, aHour){
    let answer = aHour.concat(':').concat(aMinute);
    this.setState({ minute:aMinute, hour:aHour, answer:answer });
    this.props.onAnswerChange(this.props.question, answer);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {hour, minute} = this.state;
    const {key, type} = question;
    
    let hourOptions = [];
    let minuteOptions = [];
    
    for(let index = 0; index < 24; index += 1){
      hourOptions.push({value:index.toString().padStart(2, '0'), label:index.toString().padStart(2, '0')});
    }
    for(let index = 0; index < 60; index += 1){
      minuteOptions.push({value:index.toString().padStart(2, '0'), label:index.toString().padStart(2, '0')});
    }
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr/>
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_TIME_INSTRUCTION_TEXT')}</Translation>
        </em>
        
        <hr/>
        
        <div className={'side-by-side-dropdowns'}>
          <LabeledDropDown className="survey-time-dropdown"
                           label={<Translation>{(t, {i18n}) => t('HOUR')}</Translation>}
                           value={hour && hourOptions.filter(function(option){
                             return option.value === hour;
                           })}
                           options={hourOptions}
                           disabled={disabled}
                           handleSave={(aOption) => this.saveAnswer(minute, aOption.value)}
                           placeholder={'0'}
          />
          
          <LabeledDropDown className="survey-time-dropdown-minute"
                           label={<Translation>{(t, {i18n}) => t('MINUTE')}</Translation>}
                           value={minute && minuteOptions.filter(function(option){
                             return option.value === minute;
                           })}
                           options={minuteOptions}
                           disabled={disabled}
                           handleSave={(aOption) => this.saveAnswer(aOption.value, hour)}
                           placeholder={'0'}
          />
        </div>
      </div>
    )
  }
}
