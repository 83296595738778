// current
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const FORGOT = 'FORGOT';
export const FORGOT_ERROR = 'FORGOT_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const LOGOUT = 'LOGOUT';

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const PERMITTED_ITEMS = 'PERMITTED_ITEMS';

export const UPDATE_CURRENT_USER_ERROR = 'UPDATE_CURRENT_USER_ERROR';
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const FETCH_PATIENT = 'FETCH_PATIENT';
export const LOAD_PATIENT = 'LOAD_PATIENT';
export const LOAD_PATIENT_ERROR = 'LOAD_PATIENT_ERROR';
export const FETCH_PATIENT_DATA = 'FETCH_PATIENT_DATA';
export const LOAD_PATIENT_DATA = 'LOAD_PATIENT_DATA';
export const LOAD_SYNCED_DEVICE_DATA = 'LOAD_SYNCED_DEVICE_DATA';

// Patient Details
export const LOAD_PATIENT_DATA_FOR_KEY = 'LOAD_PATIENT_DATA_FOR_KEY';
export const LOAD_MINIFIED_PATIENT_DATA_FOR_KEY = 'LOAD_MINIFIED_PATIENT_DATA_FOR_KEY';

// Company
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const LOAD_COMPANY_ERROR = 'LOAD_COMPANY_ERROR';
export const UPDATE_COMPANY_LOCALE = 'UPDATE_COMPANY_LOCALE';
export const UPDATE_COMPANY_HEADLINE = 'UPDATE_COMPANY_HEADLINE';
export const UPDATE_COMPANY_PAYMENT_TYPE = 'UPDATE_COMPANY_PAYMENT_TYPE';
export const UPDATE_COMPANY_HSC_TIMEOUT_MINUTES = 'UPDATE_COMPANY_HSC_TIMEOUT_MINUTES';
export const UPDATE_COMPANY_LAST_ENROLMENT_DATE = 'UPDATE_COMPANY_LAST_ENROLMENT_DATE';
export const LOAD_COMPANY_DATA_FOR_KEY = 'LOAD_COMPANY_DATA_FOR_KEY';
export const LOAD_COMPANY_DATA_ARRAY_FOR_KEY = 'LOAD_COMPANY_DATA_ARRAY_FOR_KEY';
export const LOAD_MINIFIED_COMPANY_DATA_FOR_KEY = 'LOAD_MINIFIED_COMPANY_DATA_FOR_KEY';
export const LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY = 'LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY';

// Conversations
export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS';
export const LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const SET_CURRENT_MESSAGES = 'SET_CURRENT_MESSAGES';

// groups
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const FETCH_GROUP_FEATURES = 'FETCH_GROUP_FEATURES';

// patients
export const FETCH_PATIENTS = 'FETCH_PATIENTS';
export const LOAD_PATIENTS = 'LOAD_PATIENTS';

// payments
export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const REJECT_PAYMENT = 'REJECT_PAYMENT';
export const APPROVE_PAYMENT = 'APPROVE_PAYMENT';

// payment credentials
export const FETCH_HYPER_WALLET_CREDENTIALS = 'FETCH_HYPER_WALLET_CREDENTIALS';
export const UPDATE_HYPER_WALLET_CREDENTIALS = 'UPDATE_HYPER_WALLET_CREDENTIALS';
export const FETCH_TREMENDOUS_CREDENTIALS = 'FETCH_TREMENDOUS_CREDENTIALS';
export const UPDATE_TREMENDOUS_CREDENTIALS = 'UPDATE_TREMENDOUS_CREDENTIALS';

// payment triggers
export const FETCH_PAYMENT_TRIGGERS = 'FETCH_PAYMENT_TRIGGERS';
export const CREATE_PAYMENT_TRIGGER = 'CREATE_PAYMENT_TRIGGER';
export const REMOVE_PAYMENT_TRIGGER = 'REMOVE_PAYMENT_TRIGGER';
export const UPDATE_PAYMENT_TRIGGER = 'UPDATE_PAYMENT_TRIGGER';

// users
export const FETCH_USERS = 'FETCH_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_PATIENT_ERROR = 'ADD_PATIENT_ERROR';

// Tools
export const FETCH_TOOLS = 'FETCH_TOOLS';
export const LOAD_TOOLS = 'LOAD_TOOLS';

// company notification receipts
export const FETCH_RECEIPTS = 'FETCH_RECEIPTS';
export const LOAD_RECEIPTS = 'LOAD_RECEIPTS';
export const CLEAR_RECEIPT = 'CLEAR_RECEIPT';

// locales
export const FETCH_LOCALES = 'FETCH_LOCALES';
export const LOAD_LOCALES = 'LOAD_LOCALES';

// patient data
export const FETCH_PATIENT_FEATURES = 'FETCH_PATIENT_FEATURES';
