import i18next from "i18next";

export function genderOtherError(aGender, aGenderOther){
  let returnValue = null;
  
  if(aGender === 'other'){
    if(!aGenderOther){
      returnValue = i18next.t('PLEASE_FILL_OUT_THE_OTHER_FIELD');
    }
    else if(!aGenderOther.match(/^[a-zA-ZÀ-ÿ0-9]+$/)){
      returnValue = i18next.t('PLEASE_ENTER_ALPHANUMERIC_CHARACTERS_ONLY');
    }
  }
  return returnValue;
}
