import React from 'react';
import './SmallGroupCell.scss';
import 'antd/dist/antd.css';
import {Translation, withTranslation} from "react-i18next";
import groupColorPicker from 'utils/ColorPicker';
import {connect} from "react-redux";
import {DEFAULT_COLORS} from "../../constants/Colors";
import {Switch} from "antd";

class SmallGroupCell extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      checked:true
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const { group, isEnabled, enableAction } = this.props;
  
    let groupColour = DEFAULT_COLORS.MAIN_BLUE;
  
    if(group && group.core === false){
      groupColour = groupColorPicker(group.id);
    }
    return (
      <div className="small-group-user-cell">
        <div className="small-group-user-title-container">
          <div className="small-group-user-title-info">
            <div className="small-group-user-title-circle" style={{color:groupColour}}>
              {group && group.id && group.name && group.name.length > 0 ? group.name.charAt(0).toUpperCase() : ''}
            </div>
            <div className="small-group-user-title-text">{group.name}</div>
          </div>
      
          <Switch defaultChecked={true}
                  checked={isEnabled}
                  className="payment-modal-blue-switch"
                  onChange={enableAction ? enableAction : null}
                  checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                  unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(){
  return{};
}
export default connect (mapStateToProps) (withTranslation()(SmallGroupCell))
