import React from "react";
import './SurveyBuilderMedicationQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import {csvStringFromOptionsArray} from "../../../../Helpers/SurveyBuilderHelpers";
import LabeledMultipleSelectDropDown from "../../../../Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";

export default class SurveyBuilderMedicationQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, errorForIdTypeAndKey} = this.props;
    
    let fieldsArray = ['indication', 'dose', 'unit', 'frequency', 'route', 'start_date', 'ongoing', 'end_date', 'reason'];
    let fieldOptionsArray = [];
    
    for(let field of fieldsArray){
      fieldOptionsArray.push({value:field, label:field});
    }
    let extraOptionsArray = [];
    let requiredOptionsArray = [];
    
    if(question && question.extra_questions && question.extra_questions.length > 0){
      for(let field of question.extra_questions.split(',')){
        extraOptionsArray.push({value:field, label:field});
      }
    }
    if(question && question.required_questions && question.required_questions.length > 0){
      for(let field of question.required_questions.split(',')){
        requiredOptionsArray.push({value:field, label:field});
      }
    }
    
    return(
      <div className="survey-builder-year-question-cell">
        <LabeledMultipleSelectDropDown className="survey-builder-medication-input"
                                       label={<Translation>{ (t, { i18n }) => t('REQUIRED_QUESTIONS') }</Translation>}
                                       value={requiredOptionsArray}
                                       options={fieldOptionsArray}
                                       infoBlurb={<Translation>{(t, { i18n }) => t('REQUIRED_QUESTIONS_EXPLANATION')}</Translation>}
                                       handleSave={(aOptionsList) => {
                                         let updatedQuestion = cloneDeep(question);
                                         
                                         if(aOptionsList && aOptionsList.length > 0){
                                           updatedQuestion.required_questions = csvStringFromOptionsArray(aOptionsList);
                                         }
                                         else{
                                           delete updatedQuestion['required_questions'];
                                         }
                                         updateQuestion(updatedQuestion);
                                       }}
                                       errorMessage={errorForIdTypeAndKey(question.id, 'error', 'required_questions').message}
        />
  
        <LabeledMultipleSelectDropDown className="survey-builder-medication-input"
                                       label={<Translation>{ (t, { i18n }) => t('EXTRA_QUESTIONS') }</Translation>}
                                       value={extraOptionsArray}
                                       options={fieldOptionsArray}
                                       infoBlurb={<Translation>{(t, { i18n }) => t('EXTRA_QUESTIONS_EXPLANATION')}</Translation>}
                                       handleSave={(aOptionsList) => {
                                         let updatedQuestion = cloneDeep(question);
  
                                         if(aOptionsList && aOptionsList.length > 0){
                                           updatedQuestion.extra_questions = csvStringFromOptionsArray(aOptionsList);
                                         }
                                         else{
                                           delete updatedQuestion['extra_questions'];
                                         }
                                         updateQuestion(updatedQuestion);
                                       }}
                                       errorMessage={errorForIdTypeAndKey(question.id, 'error', 'extra_questions').message}
        />
      </div>
    )
  }
}
