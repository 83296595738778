import React from 'react';
import AllergyListItem from '../../../Cells/AllergyCell/AllergyCell';
import PatientPanelSubSectionHeadingWithButton from '../PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton';
import {Translation} from 'react-i18next';
import i18next from 'i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {toast} from 'react-toastify';
import {NOTIFY_OPTS} from '../../../constants/Notifiers';
import Loading from "../../../Custom UI/Loading/Loading";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import AssignAllergyModal from "../../../Modals/AssignAllergyModal/AssignAllergyModal";

const notifyAddAllergyFail = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_ADDING_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyRemoveAllergyError = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_REMOVING_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyAllergyAddedSuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_ADDED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyRemoveAllergySuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_REMOVED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyAllergyUpdatedSuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_UPDATED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class Allergies extends React.Component {
  
  // Instance Variables
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      assignAllergies:false,
      selectedAllergy:null,
      menuAnchorElement:null,
      selectedAllergyId:null,
      allergiesUsersArray:[],
      selectedPatientListItem:null,
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.editAllergy = this.editAllergy.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.assignAllergyCallback = this.assignAllergyCallback.bind(this);
    this.removeAllergyFromUser = this.removeAllergyFromUser.bind(this);
    
    if(this.props.patient && this.props.patient.allergies_users){
      this.state.allergiesUsersArray = this.props.patient.allergies_users;
    }
  }
  
  componentDidMount() {
    this.loadAllergies();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.allergies_users){
      if(this.state.allergiesUsersArray !== this.props.patient.allergies_users){
        this.setState({allergiesUsersArray:this.props.patient.allergies_users});
      }
    }
  }
  
  // Methods
  
  loadAllergies(){
    this.setState({ loading:true });
    this.props.loadPatientAllergiesUsers(this.props.patient.id).then((newResult) => {
      this.setState({ loading:false, loadFailed:false });
    }, (newError) => {
      this.setState({ loading:false, loadFailed:true });
    });
  }
  
  removeAllergyFromUser = (aAllergyId) => {
    const {allergiesUsersArray} = this.state;
    if(this.props.patient && allergiesUsersArray){
      const userId = this.props.patient.id;
      const index = allergiesUsersArray.map((aAllergyUser) => {return aAllergyUser.allergy_id}).indexOf(aAllergyId);
  
      if(index > -1){
        allergiesUsersArray.splice(index, 1);
      }
      this.props.removeAllergyFromUser({aAllergyId:aAllergyId, aUserId:userId}).then(((newResult) => {
          this.props.loadPatientAllergiesUsers(this.props.patient.id);
          notifyRemoveAllergySuccess();
        }),
        newError => {
          let error = '';
      
          if(newError && newError.data && newError.data.error){
            error = newError.data.error;
          }
          notifyRemoveAllergyError(error);
          this.props.loadPatientAllergiesUsers(this.props.patient.id);
        });
      this.closeMenu();
    }
  }
  
  editAllergy(){
    this.setState({assignAllergies:true});
    this.closeMenu();
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedAllergyId:null, selectedPatientListItem:null});
  }
  
  menuButtonClicked(aEvent, aAllergyId, aPatient, aAllergy){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedAllergyId:aAllergyId, selectedPatientListItem:aPatient, selectedAllergy:aAllergy});
  }
  
  assignAllergyCallback(aAllergen, aSeverity, aAllergyId, aId){
    this.props.assignAllergyCallback(this.props.patient && this.props.patient.id, aAllergen, aSeverity, aAllergyId, aId).then((newResult) => {
      if(newResult && newResult.error){
        notifyAddAllergyFail(newResult.error);
      }
      else{
        this.setState({assignAllergies:false});
        
        if(aId){
          notifyAllergyUpdatedSuccess();
        }
        else{
          notifyAllergyAddedSuccess();
        }
      }
    });
  }
  
  // Render
  
  render(){
    const {assignAllergiesModalOpen, editSymptomsModalOpen, company, fetchAllergies} = this.props;
    const {loading, allergiesUsersArray, loadFailed} = this.state;
    const allergiesPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_10', 'admin_level_11'];
    let currentRole = roleForCompany(company);
    
    return (
      <div>
        {allergiesPermissions.includes(currentRole) ?
          <div>
            <PatientPanelSubSectionHeadingWithButton addFunc={assignAllergiesModalOpen}
                                                     heading={i18next.t('PATIENT_ALLERGIES')}
            />
            <div className="subsection-element"
                 style={{display:'block'}}>
              {loading ?
                <Loading/>
                :
                (loadFailed ?
                    <LoadFailed retry={() => this.loadAllergies()}/>
                    :
                    (allergiesUsersArray.length > 0 ?
                        <>
                          {allergiesUsersArray.map((allergy, index) => (
                            <div key={'allergy-' + index + '-' + allergy.id}
                                 style={{display:'flex', flexDirection:'column'}}>
                              <AllergyListItem data={allergy}
                                               removeId={allergy.id}
                                               menuButtonClicked={(aEvent, aPatient) => this.menuButtonClicked(aEvent, allergy.allergy_id, aPatient, allergy)}
                                               editSymptomsModalOpen={editSymptomsModalOpen}
                              />
                            </div>
                          ))}
                        </>
                        :
                        <div className="allergy-no-allergies-found">
                          <Translation>{ (t, { i18n }) => t('NO_ALLERGIES_FOUND') }</Translation>
                        </div>
                    )
                )}
            </div>
            
            <Menu id="simple-menu"
                  open={Boolean(this.state.menuAnchorElement)}
                  onClose={() => this.closeMenu()}
                  anchorEl={this.state.menuAnchorElement}
                  keepMounted>
              <MenuItem style={{position:'relative'}} onClick={() => this.editAllergy()}>
                <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
              </MenuItem>
              <MenuItem style={{position:'relative'}} onClick={() => this.removeAllergyFromUser(this.state.selectedAllergyId)}>
                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
  
            {this.state.assignAllergies ?
              <AssignAllergyModal isOpen={this.state.assignAllergies}
                                  allergy={this.state.selectedAllergy}
                                  patient={this.props.patient}
                                  fetchAllergies={fetchAllergies}
                                  handleCloseModal={() => this.setState({assignAllergies:false, selectedAllergy:null})}
                                  assignAllergiesError={this.props.assignAllergiesError}
                                  assignAllergyCallback={this.assignAllergyCallback}
              />
              :
              null
            }
          </div>
          :
          <div className="alternative-contact-section-title">
            <Translation>{ (t, { i18n }) => t('PATIENT_ALLERGIES_BLOCKED') }</Translation>
          </div>
        }
      </div>
    );
  }
}
