import moment from "moment";
import mixpanel from 'mixpanel-browser';
import Settings from './settings';
import {endPoint, environmentName} from "./Helpers/EndPointHelpers";

// Instance Variables

const url = endPoint();
const environment = environmentName();
const mixpanelApiKey = Settings.mixpanelApiKey;

let userId = '';
let companyId = '';
let hsFeatures = [];
let geoLocation = null;
let eventTrackerTokenId = '162ab302-2208-4e91-bc4d-d482b622c77c';
let eventTrackerEncodedJWT = '';
let hasInitializedMixPanel = false;

// Methods

function logToConsole(aMessage){
  if(!environment.includes('Production')){
    console.log(aMessage);
  }
}

function shouldTrackEvent(){
  return eventTrackerTokenId !== null && eventTrackerTokenId !== undefined && eventTrackerTokenId.length > 0;
}

function hsFeatureAvailable(aHsFeatureName){
  let returnValue = false;
  
  for(let hsFeature of hsFeatures){
    if(hsFeature.title === aHsFeatureName){
      returnValue = hsFeature.enabled;
      break;
    }
  }
  return returnValue;
}

function getLocation(){
  if(navigator.geolocation){
    navigator.geolocation.getCurrentPosition(showPosition);
  }
  else{
    geoLocation = null;
  }
}

function showPosition(aPosition){
  geoLocation = {latitude:aPosition.coords.latitude, longitude:aPosition.coords.longitude};
}

function initializeMixPanel(){
  if(!hasInitializedMixPanel && shouldTrackEvent && hsFeatureAvailable('SendToMixPanel')){
    hasInitializedMixPanel = true;
    mixpanel.init(mixpanelApiKey);
    mixpanel.identify(userId);
  }
}

getLocation();

let EventTracker = {
  identify:(aId, aHsFeatures) => {
    userId = aId;
    EventTracker.updateHsFeatures(aHsFeatures);
  },
  updateHsFeatures:(aHsFeatures) => {
    if(aHsFeatures){
      hsFeatures = aHsFeatures;
      initializeMixPanel();
    }
  },
  setTokenAndJWT:(aToken, aJWT) => {
    eventTrackerTokenId = aToken;
    eventTrackerEncodedJWT = aJWT;
  },
  setCompanyId:(aCompanyId) => {
    companyId = aCompanyId;
  },
  track:(aName, aAdditionalParameters) => {
    if(shouldTrackEvent && aName && aName.length > 0){
      logToConsole('Analytics Event (' + aName + '): ', aAdditionalParameters);
      
      // Note: Device info is hard to fetch from the browser. It is meant more for the mobile apps.
      let params = {
        token_id:eventTrackerTokenId,
        encoded_jwt:eventTrackerEncodedJWT,
        data:{
          name:aName,
          location:geoLocation,
          parameters:aAdditionalParameters,
          server_url:url,
          device_info:{},
          environment:environment,
          client_user_id:userId,
          client_company_id:companyId,
          client_created_at:moment().locale('en').format('YYYY-MM-DDTHH:mm:ssZ')
        }
      };
      global.api.post( 'https://event-tracker.healthstorylines.com/event_logs', params, {withCredentials: false}).then(() => {
        logToConsole('Analytics Event Success!');
      })
      .catch((newError) => {
        logToConsole('Analytics Event Failure: ' + newError);
      });
    }
    if(hsFeatureAvailable('SendToMixPanel')){
      mixpanel.track(aName, aAdditionalParameters);
    }
  },
  error:(aName, aError, aDescription = null, aMethodIdentifier = null, aAdditionalParameters = {}) => {
    if(shouldTrackEvent && aName && aName.length > 0 && aError){
      console.log('Analytics Error (' + aName + '): ' + aError, aDescription, aMethodIdentifier, aAdditionalParameters);
      
      // Note: Device info is hard to fetch from the browser. It is meant more for the mobile apps.
      let params = {
        token_id:eventTrackerTokenId,
        encoded_jwt:eventTrackerEncodedJWT,
        data:{
          name:aName,
          error:aError,
          location:geoLocation,
          parameters:aAdditionalParameters,
          server_url:url,
          description:aDescription,
          device_info:{},
          environment:environment,
          stack_trace:console.trace(),
          client_user_id:userId,
          client_company_id:companyId,
          client_created_at:moment().locale('en').format('YYYY-MM-DDTHH:mm:ssZ'),
          method_identifier:aMethodIdentifier
        }
      };
      global.api.post('https://event-tracker.healthstorylines.com/error_logs', params, {withCredentials: false}).then((newResponse) => {
        logToConsole('Analytics Error Success: ' + newResponse);
      })
      .catch((newError) => {
        logToConsole('Analytics Error Failure: ' + newError);
      });
    }
    if(hsFeatureAvailable('SendToMixPanel')){
      mixpanel.track(aName, aAdditionalParameters);
    }
  }
};

export default EventTracker
