import React from 'react';
import 'antd/dist/antd.css';
import './TriggerCell.scss'
import {BiDotsVerticalRounded} from "react-icons/bi";
import {withRouter} from "react-router-dom";
import Loading from "../../Custom UI/Loading/Loading";
import {Translation} from "react-i18next";
import {TriggerTypeLabelForValue} from "../../constants/TriggerTypes";
import {AlertTypeLabelForValue} from "../../constants/AlertTypes";
import {momentFromDate} from "../../Helpers/DateHelpers";

class TriggerCell extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const { trigger, role, loading, menuButtonClicked } = this.props;
    
    let expandedWidthClass = '';
    
    if(role === 'admin'){
      expandedWidthClass = 'trigger-cell-expanded';
    }
    return (
      <div className="trigger-cell">
        
        {role === 'admin' ?
          <div className="trigger-cell-date">
            <div className="trigger-cell-date-id">
              <b>
                <Translation>{(t, {i18n}) => t('ID')}</Translation>&nbsp;
              </b>
              {trigger.id}
            </div>
            
            <div className="trigger-cell-date-created">
              <b>
                <Translation>{(t, {i18n}) => t('CREATED_AT_COLON')}</Translation>&nbsp;
              </b>
              {momentFromDate(trigger.created_at, ' MMM D YYYY, h:mma')}
            </div>
            
            <div className="trigger-cell-date-updated">
              <b>
                <Translation>{(t, {i18n}) => t('UPDATED_AT')}</Translation>&nbsp;
              </b>
              {momentFromDate(trigger.updated_at, ' MMM D YYYY, h:mma')}
            </div>
          </div>
          :
          null
        }
  
        <div className={'trigger-cell-typing-container ' + expandedWidthClass}>
          <div className="trigger-cell-typing-container-keyword">
            <b>
              <Translation>{(t, {i18n}) => t('KEYWORD')}</Translation>:&nbsp;
            </b>
            {trigger.keyword}
          </div>
  
          <div className="trigger-cell-typing-container-type">
            <b>
              <Translation>{(t, {i18n}) => t('TYPE_PROPER_CAPITALIZED_COLON')}</Translation>&nbsp;
            </b>
            {TriggerTypeLabelForValue(trigger.type)}
          </div>
  
          <div className="trigger-cell-typing-container-alert">
            <b>
              <Translation>{(t, {i18n}) => t('ALERT_TYPE_COLON')}</Translation>&nbsp;
            </b>
            {AlertTypeLabelForValue(trigger.alert_type)}
          </div>
  
          <div className="trigger-cell-typing-container-score">
            <b>
              <Translation>{(t, {i18n}) => t('SCORE')}</Translation>&nbsp;
            </b>
            {trigger.score_name}
          </div>
  
          <div className="trigger-cell-typing-container-country">
            <b>
              <Translation>{(t, {i18n}) => t('USER_COUNTRY_NAME')}</Translation>&nbsp;
            </b>
            {trigger.user_country_name}
          </div>
        </div>
  
        <div className={'trigger-cell-timing-container ' + expandedWidthClass}>
          <div className="trigger-cell-timing-container-start-offset">
            <b>
              <Translation>{(t, {i18n}) => t('START_OFFSET')}</Translation>&nbsp;
            </b>
            {trigger.start_offset}&nbsp;{trigger.start_offset_unit}
          </div>
  
          <div className="trigger-cell-timing-container-start-offset">
            <b>
              <Translation>{(t, {i18n}) => t('END_OFFSET')}</Translation>&nbsp;
            </b>
            {trigger.end_offset}&nbsp;{trigger.end_offset_unit}
          </div>
    
          <div className="trigger-cell-timing-container-min">
            <b>
              <Translation>{(t, {i18n}) => t('MIN_DURATION_BETWEEN_EVENTS')}</Translation>&nbsp;
            </b>
            {trigger.minimum_duration_between_events}&nbsp;{trigger.minimum_duration_between_events_unit}
          </div>
  
          <div className="trigger-cell-timing-container-max">
            <b>
              <Translation>{(t, {i18n}) => t('HOUR_OF_DAY')}</Translation>&nbsp;
            </b>
            {trigger.hour_of_day}
          </div>
    
          <div className="trigger-cell-timing-container-max">
            <b>
              <Translation>{(t, {i18n}) => t('MAX_HOUR_DAY')}</Translation>&nbsp;
            </b>
            {trigger.maximum_hour_of_day}
          </div>
        </div>
  
        <div className={'trigger-cell-flags-container ' + expandedWidthClass}>
          <div className="trigger-cell-flags-container-value">
            <b>
              <Translation>{(t, {i18n}) => t('MIN_VALUE_PROPER_CAPITALIZED')}</Translation>&nbsp;
            </b>
            {trigger.minimum_value}
          </div>
          <div className="trigger-cell-flags-container-value">
            <b>
              <Translation>{(t, {i18n}) => t('MAX_VALUE_PROPER_CAPITALIZED_COLON')}</Translation>&nbsp;
            </b>
            {trigger.maximum_value}
          </div>
    
          <div className="trigger-cell-flags-container-repeat">
            <b>
              <Translation>{(t, {i18n}) => t('IS_REPEATABLE')}</Translation>&nbsp;
            </b>
            {trigger.is_repeatable}
          </div>
    
          <div className="trigger-cell-flags-container-reminder">
            <b>
              <Translation>{(t, {i18n}) => t('IS_REMINDER')}</Translation>&nbsp;
            </b>
            {trigger.is_reminder}
          </div>
    
          <div className="trigger-cell-flags-container-admins">
            <b>
              <Translation>{(t, {i18n}) => t('SEND_TO_COMPANY_ADMINS')}</Translation>&nbsp;
            </b>
            {trigger.send_to_company_admins}
          </div>
        </div>
  
        <div className={'trigger-cell-targets-container ' + expandedWidthClass}>
          <div className="trigger-cell-targets-container-target">
            <b>
              <Translation>{(t, {i18n}) => t('PRIMARY_TARGET')}</Translation>&nbsp;
            </b>
            {trigger.target_id}&nbsp;{trigger.target_type}&nbsp;{trigger.target_name}
          </div>
    
          <div className="trigger-cell-targets-container-secondary">
            <b>
              <Translation>{(t, {i18n}) => t('SECONDARY_TARGET')}</Translation>&nbsp;
            </b>
            {trigger.secondary_target_id}&nbsp;{trigger.secondary_target_type}&nbsp;{trigger.secondary_target_name}
          </div>
    
          <div className="trigger-cell-targets-container-survey-consent">
            <b>
              <Translation>{(t, {i18n}) => t('SURVEY_CONSENT_KEYWORD')}</Translation>&nbsp;
            </b>
            {trigger.survey_consent_question_keyword}
          </div>
    
          <div className="trigger-cell-targets-container-additional">
            <b>
              <Translation>{(t, {i18n}) => t('ADDITIONAL_APP_COLUMN_NAME')}</Translation>&nbsp;
            </b>
            {trigger.additional_app_column_name}
          </div>
        </div>
  
        {menuButtonClicked ?
          (loading ?
              <div className="trigger-cell-loading-container">
                <Loading size={'tiny'}
                         showLabel={false}
                />
              </div>
              :
              <BiDotsVerticalRounded style={{fontSize: '21px', marginLeft: 'auto'}}
                                     onClick={(aEvent) => {aEvent.stopPropagation(); menuButtonClicked(aEvent)}}
              />
          )
          :
          null
        }
      </div>
    )
  }
}
export default withRouter(TriggerCell)
