import React, {useRef, useState} from 'react';
import './GroupSummary.scss';
import TabBar from "../../Custom UI/TabBar/TabBar";
import Symptoms from '../../Components/Symptoms/Symptoms'
import PatientList from "../../Lists/PatientList/PatientList";
import PaymentsList from "../../Lists/PaymentsList/PaymentsList";
import SurveyDrawer from "../../Components/SurveyDrawer/SurveyDrawer";
import {Translation} from "react-i18next";
import GroupCodesList from "../../Lists/GroupCodesList/GroupCodesList";
import DataQueriesList from "../../Lists/DataQueriesList/DataQueriesList";
import FraudEventsList from "../../Lists/FraudEventsList/FraudEventsList";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import {DEFAULT_COLORS} from 'constants/Colors'
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import groupColorPicker from 'utils/ColorPicker'
import PerfectScrollBar from 'react-perfect-scrollbar';
import AddDataQueryModal from "../../Modals/AddDataQueryModal/AddDataQueryModal";
import AssignSymptomsModal from '../../Modals/AssignSymptomsModal/AssignSymptomsModal'
import ReportRequestDropDown from '../../Custom UI/DropDowns/ReportRequestDropDown/ReportRequestDropDown'
import VirtualAppointmentsList from "../../Lists/VirtualAppointmentsList/VirtualAppointmentsList";
import GroupSummaryToolSelection from '../../Components/GroupSummaryToolSelection/GroupSummaryToolSelection'
import CompanyNotificationReceipts from "../../Components/CompanyNotificationReceipts/CompanyNotificationReceipts";
import SchedulesList from "../../Lists/SchedulesList/SchedulesList";
import {surveyFilterOptionsArray} from 'constants/SurveyFilterOptions';

const GroupSummary = ({ group = { id: null, core: null, name: null },
                        company,
                        fetchCompanyAndPatients,
                        addToolsToGroup,
                        removeToolFromGroup,
                        user,
                        updateSearchPreferences,
                        fetchSymptoms,
                        fetchQuests,
                        addSymptomToGroup,
                        removeSymptomFromGroup,
                        loadCompanyNotificationReceipts,
                        clearCompanyNotificationReceipt,
                        patients,
                        groupName,
                        getVirtualAppointments,
                        retryPayment,
                        createPayment,
                        rejectPayment,
                        approvePayment,
                        removePayment,
                        fetchPayments,
                        updateFeatureToGroup,
                        fetchPatientsScroll,
                        saveVirtualAppointment,
                        searchPatients,
                        removeVirtualAppointment,
                        createDataQueryNote,
                        createDataQueryComment,
                        getDataQueryNote,
                        getDataQueryNotes,
                        getDataQueryNoteComments,
                        resolveDataQueryNote,
                        getQuestionForSurveyResponseDetail,
                        fetchFraudEvents,
                        fetchFraudStatistics,
                        markFraudEventAsVerified,
                        markFraudEventAsFraudulent,
                        markFraudStatisticAsVerified,
                        markFraudStatisticAsFraudulent,
                        loadCompanySymptoms,
                        fetchCodesForGroup,
                        createGroupCode,
                        removeGroupCode,
                        downloadGroupCodeHistory,
                        getQuestById,
                        getVideoForVirtualAppointment,
                        saveSchedule, fetchSchedule, fetchSchedules, removeSchedule, publishSchedule,
                        saveScheduleTrigger, createPaymentTrigger, fetchScheduleVersion, removePaymentTrigger,
                        updatePaymentTrigger, removeScheduleTrigger, fetchPatientsInSchedule, fetchQuestionsForSurveyById,
                        fetchSurveyVersionCollections, fetchScheduleVersionsForSchedule
                      }) => {

  const [topTabBarKey, setTopTabBarKey] = useState('PATIENT_LIST');
  const scrollRef = useRef();
  const [offset, setOffset] = useState(0);
  const [bottomTabBarKey, setBottomTabBarKey] = useState('FEATURES');
  const [reportReqDrop, setReportReqDrop] = useState(false);
  const [currentSurveyId, setCurrentSurveyId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentSurveyResponseId, setCurrentSurveyResponseId] = useState(null);
  const [currentSurveyResponseDetailId, setCurrentSurveyResponseDetailId] = useState(null);
  const [currentSurveyToolTitle, setCurrentSurveyToolTitle] = useState('');
  const [assignDataQuery, setAssignDataQuery] = useState(false);
  const [assignDataQueryError, setAssignDataQueryError] = useState('');
  const [selectedDataQuery, setSelectedDataQuery] = useState(null);
  const [onDataQueryCreate, setOnDataQueryCreate] = useState(null);
  const [dataQuerySurveyResponseDetailsId, setDataQuerySurveyResponseDetailsId] = useState(null);
  
  const [toolDropDown, setToolDropDown] = useState({
    openAddGroupToolDropDown: false,
    openAddGroupSurveyDropDown: false,
    openAddGroupSurveySecondDropDown: false,
    openAddGroupEConsentDropDown: false,
  });

  const removeSymptomFromGroupHelper = removeId => removeSymptomFromGroup({ symptomId: removeId, groupId: group.id });

  group = group ? group : { id: null, core: null, name: null };
  let groupColor = group.core === true ? DEFAULT_COLORS.PANEL_HEAD_BG : groupColorPicker(group.id);
  
  const onCloseDrawer = () => {
    setPatientId(null);
    setIsDrawerOpen(false);
    setCurrentSurveyId(null);
    setCurrentSurveyToolTitle(null);
    setCurrentSurveyResponseId(null);
    setCurrentSurveyResponseDetailId(null);
  };
  
  const resetDataQueryModal = (status, onCreate, aSurveyResponseDetailsId) => {
    setAssignDataQuery(status);
    setAssignDataQueryError('');
    setOnDataQueryCreate(onCreate);
    setDataQuerySurveyResponseDetailsId(aSurveyResponseDetailsId);
    
    if(status === false){
      setSelectedDataQuery(null);
    }
    if(aSurveyResponseDetailsId !== null){
      onCloseDrawer();
    }
  };
  
  const showQuestionForAlert = (aAlert) => {
    setIsDrawerOpen(true);
    setPatientId(aAlert.user.id);
    setCurrentSurveyId(aAlert.survey_id);
    setCurrentSurveyToolTitle(aAlert.survey_name);
    setCurrentSurveyResponseId(aAlert.survey_response_id);
    // setCurrentSurveyResponseDetailId(aAlert.target_id);
  };
  
  const showQuestionForDataQuery = (aDataQuery) => {
    setIsDrawerOpen(true);
    setPatientId(aDataQuery.user_id);
    setCurrentSurveyId(aDataQuery.survey_id);
    setCurrentSurveyToolTitle(aDataQuery.survey_name);
    setCurrentSurveyResponseId(aDataQuery.survey_response_id);
    setCurrentSurveyResponseDetailId(aDataQuery.survey_response_detail_id);
  };
  
  const assignPatientDataQuery = ({description, notificationAlert}) => {
    setAssignDataQueryError('');

    createDataQueryNote(patientId, description, dataQuerySurveyResponseDetailsId, notificationAlert).then(aResponse => {
      if(onDataQueryCreate !== null){
        let dataQuery = aResponse.data.data.createDataQueryNoteMutation.data_query_note;
        onDataQueryCreate(dataQuery);
      }
      resetDataQueryModal(false, null, null);
    });
  };

  const currentRole = roleForCompany(company);
  const editPermissions = ['admin', 'admin_level_1', 'admin_level_4', 'admin_level_10'];
  const alertsPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_9", "admin_level_10"];
  const reportPermissions = ["admin_level_3", "admin_level_4", "admin"];
  const paymentProhibitions = ["admin_level_5", "admin_level_6", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11"];
  const patientProhibitions = ["admin_level_8", "admin_level_5"];
  const hasReportDownloadPermissions = reportPermissions.includes(currentRole);
  
  return (
    <PerfectScrollBar>
      <div className='main-panel-inner-container'>
        {group ?
          <div className="group-summary-patient-info-container">
            <SecondaryHeader title={group ? group.name : <Translation>{ (t, { i18n }) => t('ALL_PATIENTS') }</Translation>}
                             group={group}
                             downloadClick={() => (hasReportDownloadPermissions ? setReportReqDrop(!reportReqDrop) : null)}
            />
      
            {reportReqDrop && hasReportDownloadPermissions ?
              <ReportRequestDropDown groupColor={groupColor}
                                     closeReportReqDrop={() => setReportReqDrop(false)}
                                     group={group}
                                     user={user}
                                     company={company}
              />
              :
              null
            }
      
            <div className="patient-list-summary-container"
                 ref={scrollRef}
                 onScroll={(aEvent) => setOffset(scrollRef.current.scrollTop)}>
              <TabBar tabBarTabsArray={[{key:'PATIENT_LIST'}, {key:'GROUP_INVITE_CODE'}, {key:'UPCOMING_APPOINTMENTS'}, {key:'PAYMENTS'}, {key:'ACTIVE_ALERTS'}, {key:'DATA_QUERIES'}, {key:'FRAUD_EVENTS_SPAM_FLAGS'}, {key:'SCHEDULES'}]}
                      initialSelectedTabKey={'PATIENT_LIST'}
                      selectedTabKeyChanged={(aKey) => setTopTabBarKey(aKey)}
              />
        
              {topTabBarKey === 'PATIENT_LIST' ?
                !patientProhibitions.includes(currentRole) ?
                  <div className="patient-dynamic-list-container">
                    <PatientList user={user}
                                 height={220}
                                 offset={offset}
                                 company={company}
                                 showLink={true}
                                 groupName={groupName}
                                 showHeader={true}
                                 showAdvancedSearch={true}
                                 updateSearchPreferences={updateSearchPreferences}
                    />
                  </div>
                  :
                  <div className="patient-list-summary-container">
                    <div className="no-information-text">
                      <Translation>{(t, {i18n}) => t('PATIENTS_LIST_BLOCKED')}</Translation>
                    </div>
                  </div>
                :
                null
              }
        
              {topTabBarKey === 'GROUP_INVITE_CODE' ?
                <div className="patient-invite-codes-container">
                  <div className="patient-invite-codes-container-text">
                    <Translation>{ (t, { i18n }) => t('INVITE_CODE') }</Translation>
                  </div>
            
                  <br/>
                  
                  <GroupCodesList group={group}
                                  company={company}
                                  height={220}
                                  createGroupCode={createGroupCode}
                                  removeGroupCode={removeGroupCode}
                                  fetchCodesForGroup={fetchCodesForGroup}
                                  downloadGroupCodeHistory={downloadGroupCodeHistory}
                  />
                </div>
                :
                null
              }
        
              {topTabBarKey === 'UPCOMING_APPOINTMENTS' ?
                <div className="group-summary-top-container group-summary-appointments">
                  <div className="manage-patient-container-text">
                    <Translation>{ (t, { i18n }) => t('PATIENTS_UPCOMING_VIRTUAL_APPOINTMENTS_EXPLANATION') }</Translation>
                  </div>
            
                  <br/>
            
                  <div>
                    <VirtualAppointmentsList height={'291px'}
                                             company={company}
                                             patients={patients}
                                             searchPatients={searchPatients}
                                             fetchPatientsScroll={fetchPatientsScroll}
                                             getVirtualAppointments={getVirtualAppointments}
                                             saveVirtualAppointment={saveVirtualAppointment}
                                             redirectToPatientProfile={true}
                                             removeVirtualAppointment={removeVirtualAppointment}
                                             scrollContainerClassName={"virtual-appointments-list-group-scroll"}
                                             getVideoForVirtualAppointment={getVideoForVirtualAppointment}
                    />
                  </div>
                </div>
                :
                null
              }
        
              {topTabBarKey === 'PAYMENTS' ?
                <div className="group-summary-top-container">
                  {!paymentProhibitions.includes(currentRole) ?
                    <PaymentsList group={group}
                                  height={'330px'}
                                  company={company}
                                  retryPayment={retryPayment}
                                  patientsArray={group.patients}
                                  rejectPayment={rejectPayment}
                                  createPayment={createPayment}
                                  approvePayment={approvePayment}
                                  removePayment={removePayment}
                                  fetchPayments={fetchPayments}
                                  explanationKey={'PAYMENTS_EXPLANATION'}
                                  redirectToPatientProfile={true}
                                  fetchFraudEvents={fetchFraudEvents}
                                  fetchFraudStatistics={fetchFraudStatistics}
                                  markFraudEventAsVerified={markFraudEventAsVerified}
                                  markFraudEventAsFraudulent={markFraudEventAsFraudulent}
                                  markFraudStatisticAsVerified={markFraudStatisticAsVerified}
                                  markFraudStatisticAsFraudulent={markFraudStatisticAsFraudulent}
                    />
                    :
                    <div className="group-summary-top-container">
                      <div className="no-information-text">
                        <Translation>{(t, {i18n}) => t('PAYMENTS_BLOCKED')}</Translation>
                      </div>
                    </div>
                  }
                </div>
                :
                null
              }
        
              {topTabBarKey === 'ACTIVE_ALERTS' ?
                alertsPermissions.includes(currentRole) ?
                  <div className="group-summary-top-container">
                    <CompanyNotificationReceipts user={user}
                                                 group={group}
                                                 height={310}
                                                 company={company}
                                                 onAlertClick={showQuestionForAlert}
                                                 scrollableClassName={'alert-cell-container-group-scroll'}
                                                 updateSearchPreferences={updateSearchPreferences}
                                                 redirectToPatientProfile={true}
                                                 loadCompanyNotificationReceipts={loadCompanyNotificationReceipts}
                                                 clearCompanyNotificationReceipt={clearCompanyNotificationReceipt}/>
                  </div>
                  :
                  <div className="group-summary-top-container">
                    <div className="no-information-text">
                      <Translation>{(t, {i18n}) => t('ALERTS_BLOCKED')}</Translation>
                    </div>
                  </div>
                :
                null
              }
        
              {topTabBarKey === 'DATA_QUERIES' ?
                <div className="group-summary-top-container-data">
                  <DataQueriesList key={'Data-Query-List-' + (group && group.id)}
                                   user={user}
                                   group={group}
                                   height={340}
                                   company={company}
                                   isDrawerOpen={isDrawerOpen}
                                   explanationKey={'DATA_QUERY_DESCRIPTION'}
                                   showAddDataQuery={false}
                                   addNoteModalOpen={(onCreate) => resetDataQueryModal(true, onCreate, null)}
                                   getDataQueryNote={getDataQueryNote}
                                   getDataQueryNotes={getDataQueryNotes}
                                   createDataQueryNote={createDataQueryNote}
                                   resolveDataQueryNote={resolveDataQueryNote}
                                   createDataQueryComment={createDataQueryComment}
                                   getDataQueryNoteComments={getDataQueryNoteComments}
                                   redirectToPatientProfile={true}
                                   showQuestionForDataQuery={(aDataQuery) => showQuestionForDataQuery(aDataQuery)}
                                   getQuestionForSurveyResponseDetail={getQuestionForSurveyResponseDetail}
                  />
                </div>
                :
                null
              }
        
              {topTabBarKey === 'FRAUD_EVENTS_SPAM_FLAGS' ?
                <div className="group-summary-top-container-spam">
                  <FraudEventsList height={'330px'}
                                   group={group}
                                   company={company}
                                   showName={true}
                                   listContainerHeight={'300px'}
                                   explanationKey={'DATA_QUERY_DESCRIPTION'}
                                   fetchFraudEvents={fetchFraudEvents}
                                   fetchFraudStatistics={fetchFraudStatistics}
                                   markFraudEventAsVerified={markFraudEventAsVerified}
                                   redirectToPatientProfile={true}
                                   markFraudEventAsFraudulent={markFraudEventAsFraudulent}
                                   markFraudStatisticAsVerified={markFraudStatisticAsVerified}
                                   markFraudStatisticAsFraudulent={markFraudStatisticAsFraudulent}
                  />
                </div>
                :
                null
              }
        
              {topTabBarKey === 'SCHEDULES' ?
                <div className="group-summary-top-container-spam">
                  <SchedulesList group={group}
                                 company={company}
                                 showGroups={false}
                                 saveSchedule={saveSchedule}
                                 fetchSchedule={fetchSchedule}
                                 fetchSchedules={fetchSchedules}
                                 removeSchedule={removeSchedule}
                                 publishSchedule={publishSchedule}
                                 saveScheduleTrigger={saveScheduleTrigger}
                                 createPaymentTrigger={createPaymentTrigger}
                                 fetchScheduleVersion={fetchScheduleVersion}
                                 removePaymentTrigger={removePaymentTrigger}
                                 updatePaymentTrigger={updatePaymentTrigger}
                                 removeScheduleTrigger={removeScheduleTrigger}
                                 fetchPatientsInSchedule={fetchPatientsInSchedule}
                                 fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                                 fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                 fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                  />
                </div>
                :
                null
              }
        
              <TabBar tabBarTabsArray={[{key:'FEATURES'}, {key:'ECONSENTS'}, {key:'QUESTIONNAIRES'}, {key:'TOOLS'}, {key:'SYMPTOMS'}]}
                      initialSelectedTabKey={'FEATURES'}
                      selectedTabKeyChanged={(aKey) => setBottomTabBarKey(aKey)}
              />
        
              {['FEATURES', 'ECONSENTS', 'QUESTIONNAIRES', 'TOOLS'].includes(bottomTabBarKey) ?
                <div className="group-summary-bottom-container">
                  <GroupSummaryToolSelection group={group}
                                             company={company}
                                             toolType={surveyFilterOptionsArray[bottomTabBarKey]}
                                             groupColor={groupColor}
                                             fetchQuests={fetchQuests}
                                             getQuestById={getQuestById}
                                             toolDropDown={toolDropDown}
                                             addToolsToGroup={addToolsToGroup}
                                             setToolDropDown={setToolDropDown}
                                             removeToolFromGroup={removeToolFromGroup}
                                             updateFeatureToGroup={updateFeatureToGroup}
                                             fetchCompanyAndPatients={fetchCompanyAndPatients}
                  />
                </div>
                :
                null
              }
        
              {bottomTabBarKey === 'SYMPTOMS' ?
                <div className="group-summary-bottom-container-symptoms">
                  <Symptoms key={'symptoms-' + group ? group.id.toString() : ''}
                            group={group}
                            company={company}
                            groupColor={groupColor}
                            fetchSymptoms={fetchSymptoms}
                            addSymptomToGroup={addSymptomToGroup}
                            loadCompanySymptoms={loadCompanySymptoms}
                            removeSymptomFromGroup={removeSymptomFromGroupHelper}
                            fetchCompanyAndPatients={fetchCompanyAndPatients}
                  />
                </div>
                :
                null
              }
            </div>
      
            {assignDataQuery ?
              <AddDataQueryModal key={Math.floor(Math.random() * 10000)}
                                 name={dataQuerySurveyResponseDetailsId}
                                 isOpen={assignDataQuery}
                                 noteId={selectedDataQuery && selectedDataQuery.id}
                                 createdAt={selectedDataQuery && selectedDataQuery.created_at}
                                 description={selectedDataQuery && selectedDataQuery.description}
                                 assignNoteError={assignDataQueryError}
                                 handleCloseModal={() => resetDataQueryModal(false, null, null)}
                                 assignNoteCallback={assignPatientDataQuery}
                                 updateNoteCallback={assignPatientDataQuery}
              />
              :
              null
            }
  
            <SurveyDrawer open={isDrawerOpen}
                          title={currentSurveyToolTitle}
                          userId={patientId}
                          adminId={user && user.id}
                          company={company}
                          onClose={onCloseDrawer}
                          editable={company && editPermissions.includes(currentRole)}
                          surveyId={currentSurveyId}
                          addDataQuery={currentSurveyResponseDetailId ? (aSurveyResponseDetailsId) => resetDataQueryModal(true, null, aSurveyResponseDetailsId) : null}
                          onFinishedSurvey={() => setIsDrawerOpen(false)}
                          surveyResponseId={currentSurveyResponseId}
                          surveyResponseDetailId={currentSurveyResponseDetailId}
            />
          </div>
          :
          null
        }
      </div>
    </PerfectScrollBar>
  );
};

export default GroupSummary
