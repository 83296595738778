import React from "react";
import './SurveyBuilderDateQuestionCell.scss';
import i18n from "../../../../i18n";
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDatePicker from "../../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {KeyForTranslation, objectIsEmpty} from "../../../../Helpers/SurveyBuilderHelpers";
import {isoDateFormat, isoDateInputFormat, lowerCaseMonthYearFormat, momentFromDate} from "../../../../Helpers/DateHelpers";

export default class SurveyBuilderDateQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, errorForIdTypeAndKey} = this.props;
  
    let labelKey = KeyForTranslation('label', selectedLanguage);
    let maxLabelErrorKey = KeyForTranslation('max_label', selectedLanguage) + '_';
    let minLabelErrorKey = KeyForTranslation('min_label', selectedLanguage) + '_';
    
    const daysLaterSuffix = 'DAYS_LATER';
    const daysBeforeSuffix = 'DAYS_BEFORE';
    const dateTypesArray = ['FIXED', 'RELATIVE'];
    const relativeDateFormatsArray = ['CURRENT_DATE', 'YESTERDAY', 'TOMORROW', 'USER_BIRTH_YEAR', daysLaterSuffix, daysBeforeSuffix];
    
    let maxDate = '';
    let minDate = '';
    let maxDateType = dateTypesArray[0];
    let minDateType = dateTypesArray[0];
    let maxNumberOfDays = 0;
    let minNumberOfDays = 0;
  
    if(question.max){
      if(question.max.value){
        if(question.type !== 'monthyear'){
          maxDate = momentFromDate(question.max.value, isoDateFormat);
        }
        for(let dateFormat of relativeDateFormatsArray){
          if(question.max.value === dateFormat){
            maxDateType = dateTypesArray[1];
            break;
          }
          else if(question.max.value.includes('_' + daysLaterSuffix) || question.max.value.includes('_' + daysBeforeSuffix)){
            maxDateType = dateTypesArray[1];
            maxNumberOfDays = Number(question.max.value.split('_')[0]);
            break;
          }
        }
      }
      if(question.max.year && question.max.year.value && question.max.month && question.max.month.value){
        let month = question.max.month.value.toString();
    
        if(month.length === 1){
          month = '0' + month;
        }
        maxDate = momentFromDate(question.max.year.value + '-' + month, lowerCaseMonthYearFormat);
      }
    }
    if(question.min){
      if(question.min.value){
        if(question.type !== 'monthyear'){
          minDate = momentFromDate(question.min.value, isoDateFormat);
        }
        for(let dateFormat of relativeDateFormatsArray){
          if(question.min.value === dateFormat){
            minDateType = dateTypesArray[1];
            break;
          }
          else if(question.min.value.includes('_' + daysLaterSuffix) || question.min.value.includes('_' + daysBeforeSuffix)){
            minDateType = dateTypesArray[1];
            minNumberOfDays = Number(question.min.value.split('_')[0]);
            break;
          }
        }
      }
      if(question.min.year  && question.min.year.value && question.min.month && question.min.month.value){
        let month = question.min.month.value.toString();
    
        if(month.length === 1){
          month = '0' + month;
        }
        minDate = momentFromDate(question.min.year.value + '-' + month, lowerCaseMonthYearFormat);
      }
    }
    let typeOptionsArray = [];
    let formatOptionsArray = [];
    
    for(let dateType of dateTypesArray){
      typeOptionsArray.push({label:i18next.t('' + dateType), value:dateType});
    }
    for(let dateFormat of relativeDateFormatsArray){
      formatOptionsArray.push({label:i18next.t('' + dateFormat), value:dateFormat});
    }
    return(
      <div className="survey-builder-date-question-cell">
        <div className="survey-builder-date-question-input-container">
          <LabeledDropdown className="survey-builder-date-question-type-min-input"
                           label={<Translation>{ (t, { i18n }) => t('TYPE_LOWERCASE') }</Translation>}
                           value={typeOptionsArray.filter(function(option){
                             return option.value === minDateType;
                           })}
                           options={typeOptionsArray}
                           handleSave={(aOption) => {
                             if(aOption.value !== minDateType){
                               let updatedQuestion = cloneDeep(question);
                               
                               if(aOption.value === dateTypesArray[0]){
                                 if(question.type === 'monthyear'){
                                   delete updatedQuestion.min['value'];
                                 }
                                 else{
                                   updatedQuestion.min = {value:''};
                                 }
                               }
                               else{
                                 updatedQuestion.min = {value:relativeDateFormatsArray[0]};
                               }
                               updateQuestion(updatedQuestion);
                             }
                           }}
          />
  
          {minDateType === dateTypesArray[0] ?
            <LabeledDatePicker className="survey-builder-date-question-input-min-value"
                               label={<Translation>{(t, {i18n}) => t('MIN_VALUE')}</Translation>}
                               value={minDate}
                               maxDate={maxDate}
                               infoBlurb={<Translation>{(t, {i18n}) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                               dateFormat={isoDateInputFormat}
                               handleSave={(aDate) => {
                                 let updatedQuestion = cloneDeep(question);
      
                                 if(aDate && aDate.isValid()){
                                   if(question.type === 'monthyear'){
                                     if(!updatedQuestion.min){
                                       updatedQuestion.min = {};
                                     }
                                     updatedQuestion.min.year = {value:aDate.year()};
                                     updatedQuestion.min.month = {value:aDate.month() + 1};
                                   }
                                   else{
                                     updatedQuestion.min = {value:aDate.format(isoDateFormat)}; // TODO: Save with timezone.
                                   }
                                 }
                                 else{
                                   if(question.type === 'monthyear'){
                                     delete updatedQuestion.min['year'];
                                     delete updatedQuestion.min['month'];
                                   }
                                   else{
                                     delete updatedQuestion['min'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               isClearable={true}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_value').message}
            />
            :
            <>
              <LabeledDropdown className="survey-builder-date-question-min-input"
                               label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                               value={question.min && question.min.value && formatOptionsArray.filter(function(option){
                                 return question.min.value.includes(option.value);
                               })}
                               options={formatOptionsArray}
                               infoBlurb={<Translation>{(t, {i18n}) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedQuestion = cloneDeep(question);
                                 
                                 if(aOption.value === daysLaterSuffix || aOption.value === daysBeforeSuffix){
                                   updatedQuestion.min = {value:Math.max(minNumberOfDays, 1).toString() + '_' + aOption.value};
                                 }
                                 else{
                                   updatedQuestion.min = {value:aOption.value};
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_value').message}
              />
              
              {minNumberOfDays > 0 ?
                <LabeledDelayedInput className="survey-builder-date-question-min-number-input"
                                     min={1}
                                     type={'number'}
                                     label={'#'}
                                     value={minNumberOfDays}
                                     minLength={0}
                                     handleSave={(aEvent) => {
                                       let updatedQuestion = cloneDeep(question);
                                       let numberOfDays = Math.max(aEvent.target.value, 1).toString();
                                       updatedQuestion.min.value = numberOfDays + '_' + daysLaterSuffix;
                                       
                                       if(question.min.value.includes(daysBeforeSuffix)){
                                         updatedQuestion.min.value = numberOfDays + '_' + daysBeforeSuffix;
                                       }
                                       updateQuestion(updatedQuestion);
                                     }}
                                     errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_number_of_days').message}
                />
                :
                null
              }
            </>
          }
  
          <LabeledDelayedInput className="survey-builder-date-question-label-input"
                               label={i18n.t('MIN_LABEL') + ' - (' + selectedLanguage.locale + ')'}
                               value={question.min && question.min[labelKey]}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MIN_LABEL_EXPLANATION')}</Translation>}
                               minLength={1}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 updatedQuestion.min[labelKey] = aEvent.target.value;
  
                                 if(updatedQuestion.min[labelKey] && updatedQuestion.min[labelKey].length === 0){
                                   delete updatedQuestion.min[labelKey];
                                 }
                                 if(objectIsEmpty(updatedQuestion.max)){
                                   delete updatedQuestion['max'];
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               warningMessage={errorForIdTypeAndKey(question.id, 'warning', minLabelErrorKey).message}
          />
        </div>
        
        <div className="survey-builder-date-question-input-container">
          <LabeledDropdown className="survey-builder-date-question-type-max-input"
                           label={<Translation>{ (t, { i18n }) => t('TYPE_LOWERCASE') }</Translation>}
                           value={typeOptionsArray.filter(function(option){
                             return option.value === maxDateType;
                           })}
                           options={typeOptionsArray}
                           handleSave={(aOption) => {
                             if(aOption.value !== maxDateType){
                               let updatedQuestion = cloneDeep(question);
      
                               if(aOption.value === dateTypesArray[0]){
                                 if(question.type === 'monthyear'){
                                   delete updatedQuestion.max['value'];
                                 }
                                 else{
                                   updatedQuestion.max = {value:''};
                                 }
                               }
                               else{
                                 updatedQuestion.max = {value:relativeDateFormatsArray[0]};
                               }
                               updateQuestion(updatedQuestion);
                             }
                           }}
          />
  
          {maxDateType === dateTypesArray[0] ?
            <LabeledDatePicker className="survey-builder-date-question-input-max-value"
                               label={<Translation>{(t, {i18n}) => t('MAX_VALUE')}</Translation>}
                               value={maxDate}
                               minDate={minDate}
                               infoBlurb={<Translation>{(t, {i18n}) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                               dateFormat={isoDateInputFormat}
                               handleSave={(aDate) => {
                                 let updatedQuestion = cloneDeep(question);
      
                                 if(aDate && aDate.isValid()){
                                   if(question.type === 'monthyear'){
                                     if(!updatedQuestion.max){
                                       updatedQuestion.max = {};
                                     }
                                     updatedQuestion.max.year = {value:aDate.year()};
                                     updatedQuestion.max.month = {value:aDate.month() + 1};
                                   }
                                   else{
                                     updatedQuestion.max = {value:aDate.format(isoDateFormat)}; // TODO: Save with timezone.
                                   }
                                 }
                                 else{
                                   if(question.type === 'monthyear'){
                                     delete updatedQuestion.max['year'];
                                     delete updatedQuestion.max['month'];
                                   }
                                   else{
                                     delete updatedQuestion['max'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               isClearable={true}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_value').message}
            />
            :
            <>
              <LabeledDropdown className="survey-builder-date-question-max-input"
                               label={<Translation>{(t, {i18n}) => t('MAX_VALUE')}</Translation>}
                               value={question.max && question.max.value && formatOptionsArray.filter(function(option){
                                 return question.max.value.includes(option.value);
                               })}
                               options={formatOptionsArray}
                               infoBlurb={<Translation>{(t, {i18n}) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedQuestion = cloneDeep(question);
        
                                 if(aOption.value === daysLaterSuffix || aOption.value === daysBeforeSuffix){
                                   updatedQuestion.max = {value:Math.max(maxNumberOfDays, 1).toString() + '_' + aOption.value};
                                 }
                                 else{
                                   updatedQuestion.max = {value:aOption.value};
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_value').message}
              />
      
              {maxNumberOfDays > 0 ?
                <LabeledDelayedInput className="survey-builder-date-question-max-number-input"
                                     min={1}
                                     type={'number'}
                                     label={'#'}
                                     value={maxNumberOfDays}
                                     minLength={0}
                                     handleSave={(aEvent) => {
                                       let updatedQuestion = cloneDeep(question);
                                       let numberOfDays = Math.max(aEvent.target.value, 1).toString();
                                       updatedQuestion.max.value = numberOfDays + '_' + daysLaterSuffix;
          
                                       if(question.max.value.includes(daysBeforeSuffix)){
                                         updatedQuestion.max.value = numberOfDays + '_' + daysBeforeSuffix;
                                       }
                                       updateQuestion(updatedQuestion);
                                     }}
                                     errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_number_of_days').message}
                />
                :
                null
              }
            </>
          }
  
          <LabeledDelayedInput className="survey-builder-date-question-max-label-input"
                               label={i18n.t('MAX_LABEL') + ' - (' + selectedLanguage.locale + ')'}
                               value={question.max && question.max[labelKey]}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MAX_LABEL_EXPLANATION')}</Translation>}
                               minLength={1}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 updatedQuestion.max[labelKey] = aEvent.target.value;
                                 
                                 if(updatedQuestion.max[labelKey] && updatedQuestion.max[labelKey].length === 0){
                                   delete updatedQuestion.max[labelKey];
                                 }
                                 if(objectIsEmpty(updatedQuestion.max)){
                                   delete updatedQuestion['max'];
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               warningMessage={errorForIdTypeAndKey(question.id, 'warning', maxLabelErrorKey).message}
          />
        </div>
      </div>
    )
  }
}
