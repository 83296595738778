import actions from 'actions';
import { connect } from 'react-redux';
import ManageTriggers from "../Pages/ManageTriggers/ManageTriggers";

const mapStateToProps = (state, ownProps)=> {
  let company = state.company && state.company.company;
  
  return {
    company,
    path: ownProps.match.path,
    groupName: ownProps.match.params.group
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTriggers:(aLimit, aOffset, aSearchString, aGroupId, aPatientId) => {
      return dispatch(actions.fetchTriggers(aLimit, aOffset, aSearchString, aGroupId, aPatientId));
    },
    fetchSurveyTriggers:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchSurveyTriggers(aLimit, aOffset, aSearchString));
    },
    fetchUserTriggers:(aLimit, aOffset, aUserEmail, aType) => {
      return dispatch(actions.fetchUserTriggers(aLimit, aOffset, aUserEmail, aType));
    },
    removeTrigger:(aTriggerId) => {
      return dispatch(actions.removeTrigger(aTriggerId));
    },
    createTrigger:(aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
                  aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
                  aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName,
                  aUserCountryName, aAlertType, aAdditionalAppColumnName, aSurveyConsentQuestionKeyword,
                  aTargetId, aSecondaryTargetId, aNotificationType) => {
      return dispatch(actions.createTrigger(aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset,
        aStartOffsetUnit, aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
        aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName, aUserCountryName, aAlertType,
        aAdditionalAppColumnName, aSurveyConsentQuestionKeyword, aTargetId, aSecondaryTargetId, aNotificationType));
    },
    updateTrigger:(aId, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
                   aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
                   aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName,
                   aUserCountryName, aAlertType, aAdditionalAppColumnName, aSurveyConsentQuestionKeyword,
                   aTargetId, aSecondaryTargetId, aNotificationType) => {
      return dispatch(actions.updateTrigger(aId, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset,
        aStartOffsetUnit, aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
        aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName, aUserCountryName, aAlertType,
        aAdditionalAppColumnName, aSurveyConsentQuestionKeyword, aTargetId, aSecondaryTargetId, aNotificationType));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTriggers)
