import {
  LOAD_COMPANY,
  CLEAR_RECEIPT,
  UPDATE_COMPANY,
  LOAD_COMPANY_ERROR,
  FETCH_GROUP_FEATURES,
  UPDATE_COMPANY_LOCALE,
  LOAD_COMPANY_DATA_FOR_KEY,
  UPDATE_COMPANY_PAYMENT_TYPE,
  UPDATE_TREMENDOUS_CREDENTIALS,
  LOAD_COMPANY_DATA_ARRAY_FOR_KEY,
  UPDATE_HYPER_WALLET_CREDENTIALS,
  LOAD_MINIFIED_COMPANY_DATA_FOR_KEY,
  UPDATE_COMPANY_HSC_TIMEOUT_MINUTES,
  UPDATE_COMPANY_LAST_ENROLMENT_DATE,
  LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY
} from 'constants/ActionTypes';

import cloneDeep from "lodash/cloneDeep";
import {UPDATE_COMPANY_HEADLINE} from "../constants/ActionTypes";

function company(state = {}, action) {
  let minifiedCompany = {};
  
  switch(action.type) {
    case LOAD_COMPANY:
      let currentCompany = state.company;
      
      if(currentCompany && currentCompany.id === action.result.id){
        return {
          ...state,
          company: {
            ...currentCompany,
            ...action.result
          }
        };
      }
      else{
        return {
          ...state,
          company: action.result
        };
      }
    case LOAD_COMPANY_ERROR:
      return {
        ...state,
        company: action.result
      };
    case LOAD_COMPANY_DATA_FOR_KEY:
      minifiedCompany[action.key] = { ...state.company[action.key], ...action.data };
      
      return {
        ...state,
        company:{
          ...state.company,
          ...minifiedCompany
        }
      };
    case LOAD_COMPANY_DATA_ARRAY_FOR_KEY:
      minifiedCompany[action.key] = [];
      
      for(let index = 0; index < action.data.length; index += 1){
        let object = action.data[index];
        let currentArray = state.company[action.key];
        let currentObject = null;
        
        if(currentArray){
          currentObject = currentArray.find(function(aObject){
            return aObject.id === object.id;
          });
        }
        if(currentObject){
          object = { ...currentObject, ...action.data[index] };
        }
        minifiedCompany[action.key].push(object);
      }
      return {
        ...state,
        company:{
          ...state.company,
          ...minifiedCompany
        }
      };
    case LOAD_MINIFIED_COMPANY_DATA_FOR_KEY:
      minifiedCompany[action.key] = { ...state.company[action.key], ...action.data };
      
      return {
        ...state,
        company:{
          ...state.company,
          ...minifiedCompany
        }
      };
    case LOAD_MINIFIED_COMPANY_DATA_ARRAY_FOR_KEY:
      minifiedCompany[action.key] = [];
      
      for(let index = 0; index < action.data.length; index += 1){
        let object = action.data[index];
        let currentArray = state.company[action.key];
        let currentObject = null;
        
        if(currentArray){
          currentObject = currentArray.find(function(aObject){
            return aObject.id === object.id;
          });
        }
        if(currentObject){
          object = { ...currentObject, ...action.data[index] };
        }
        minifiedCompany[action.key].push(object);
      }
      return {
        ...state,
        company:{
          ...state.company,
          ...minifiedCompany
        }
      };
    case CLEAR_RECEIPT:
      const newCompany = cloneDeep(state.company);
      
      if(newCompany && action.result && action.result.user && action.result.user.groups){
        let groupIds = action.result.user.groups.map(group=>group.id);
        
        newCompany.number_of_uncleared_company_notification_receipts--;
        newCompany.groups.forEach(group=>{
          if(groupIds.indexOf(group.id) >= 0){
            group.number_of_uncleared_company_notification_receipts--;
          }
        });
        if(newCompany.core_group && groupIds.indexOf(newCompany.core_group.id) >= 0){
          newCompany.core_group.number_of_uncleared_company_notification_receipts--;
        }
      }
      return {
        ...state,
        company: newCompany
      };
    case UPDATE_COMPANY_HEADLINE:
      return {
        ...state,
        company: {
          ...state.company,
          theme: {
            ...state.company.theme,
            headline: action.result
          }
        }
      };
    case UPDATE_COMPANY_LOCALE:
      return {
        ...state,
        company: {
          ...state.company,
          locale: action.result
        }
      };
    case UPDATE_COMPANY_HSC_TIMEOUT_MINUTES:
      return {
        ...state,
        company: {
          ...state.company,
          hsc_timeout_minutes:action.result
        }
      };
    case UPDATE_COMPANY_LAST_ENROLMENT_DATE:
      return {
        ...state,
        company: {
          ...state.company,
          last_enrolment_date: action.result
        }
      };
    case UPDATE_HYPER_WALLET_CREDENTIALS:
      return {
        ...state,
        company: {
          ...state.company,
          hyperwallet_program_token:action.result.hyperwallet_program_token,
          hyperwallet_api_url:action.result.hyperwallet_api_url,
          hyperwallet_api_username:action.result.hyperwallet_api_username,
          has_hyperwallet_api_password:action.result.has_hyperwallet_api_password,
          has_valid_hyperwallet_credentials:action.result.has_valid_hyperwallet_credentials
        }
      };
    case UPDATE_TREMENDOUS_CREDENTIALS:
      return {
        ...state,
        company: {
          ...state.company,
          tremendous_funding_source_id:action.result.tremendous_funding_source_id,
          tremendous_campaign_id:action.result.tremendous_campaign_id,
          tremendous_api_token:action.result.tremendous_api_token,
          has_valid_tremendous_credentials:action.result.has_valid_tremendous_credentials
        }
      };
    case UPDATE_COMPANY_PAYMENT_TYPE:
      return {
        ...state,
        company: {
          ...state.company,
          payment_type:action.result
        }
      };
    
    case FETCH_GROUP_FEATURES:
      let coreGroup = cloneDeep(state.company.core_group);
      let groups = cloneDeep(state.company.groups);
      let groupId = action.result.groupId.toString();
      let hsFeatureId = action.result.featureId.toString();
      let featureEnabled = action.result.featureEnabled;
      
      if(coreGroup.id === groupId){
        for(let groupIndex = 0; groupIndex < coreGroup.hs_features.length; groupIndex++){
          if(coreGroup.hs_features[groupIndex].id.toString() === hsFeatureId){
            coreGroup.hs_features[groupIndex].enabled = featureEnabled;
          }
        }
      }
      else{
        for(let groupIndex = 0; groupIndex < groups.length; groupIndex++){
          if(groups[groupIndex].id.toString() === groupId && groups[groupIndex].hs_features){
            for(let hsFeatureIndex = 0; hsFeatureIndex < groups[groupIndex].hs_features.length; hsFeatureIndex++){
              if(groups[groupIndex].hs_features[hsFeatureIndex].id.toString() === hsFeatureId){
                groups[groupIndex].hs_features[hsFeatureIndex].enabled = featureEnabled;
              }
            }
          }
        }
      }
      return {
        ...state,
        company: {
          ...state.company,
          core_group: coreGroup,
          groups: groups
        }
      };
    default:
      return state;
  }
}

export default company
