import React  from 'react'
import 'react-toastify/dist/ReactToastify.css'
import './ClearNotificationReceiptDropDown.scss'
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledTextarea from "../../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import BlueButton from "../../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../../Custom UI/Buttons/GrayButton/GrayButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default class ClearNotificationReceiptDropDown extends React.Component {

  // Init

  constructor(props) {
    super(props);
    this.state = {
      note:''
    };
    this.noteChanged = this.noteChanged.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScrollOutside, true);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScrollOutside, true);
  }

  // Methods

  noteChanged(aEvent){
    this.setState({note: aEvent.target.value});
  }

  handleScrollOutside = () => {
    this.props.closeDropdown();
  };

  // Render

  render() {
    const {title, clearReceipt, closeDropdown, addPositioning} = this.props;

    return (
      <ClickAwayListener onClickAway={closeDropdown}>
        <div className={"drop-down-menu clear-receipt-dropdown-modal" + (addPositioning ? " dynamic-list-popper-positioning" : "")}>
          <div className="main-container">
            <div className="drop-down-body">
              {title ?
                <div>
                  {title}
                </div>
                :
                null
              }
          
              <LabeledTextarea className="confirmation-note-textarea"
                               name="description"
                               value={this.state.note}
                               placeholder={i18next.t('NOTE')}
                               handleSave={this.noteChanged}
              />
          
              <div className="confirmation-dropdown-button-container">
                <GrayButton className="confirmation-dropdown-button"
                            onClick={closeDropdown}
                            name={<Translation>{(t, {i18n}) => t('CANCEL')}</Translation>}
                />
                <BlueButton className="confirmation-dropdown-button"
                            onClick={()=>{clearReceipt(this.state.note)}}
                            name={<Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                />
              </div>
        
            </div>
          </div>
        </div>
      </ClickAwayListener>
    )
  }
}
