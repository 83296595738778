import React from 'react';
import "antd/dist/antd.css";
import './ReportTemplateModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import ReportBuilder from "../../Components/ReportBuilder/ReportBuilder";

export default class ReportTemplateModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  // Render
  
  render() {
    const {isOpen, handleCloseModal, company, reportTemplate, saveReportTemplate, fetchReportTemplate, reportTemplateDidSave,
      fetchModelConfigurations, fetchColumnConfigurations, fetchFilterConfigurations, fetchPreMadeRelationships,
      fetchRelationshipConfigurations} = this.props;
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('REPORT') }</Translation>}
                    width={'calc(100vw - 30px)'}
                    height={'calc(100vh - 30px)'}
                    isOpen={isOpen}
                    maxWidth={1300}
                    maxHeight={800}
                    showFooter={false}
                    showHeader={false}
                    childrenHeight={'calc(100vh + 120px)'}
                    handleCloseModal={() => handleCloseModal()}
                    maxChildrenHeight={950}>
        <ReportBuilder company={company}
                       isModal={true}
                       reportTemplate={reportTemplate}
                       saveReportTemplate={saveReportTemplate}
                       fetchReportTemplate={fetchReportTemplate}
                       reportTemplateDidSave={reportTemplateDidSave}
                       fetchModelConfigurations={fetchModelConfigurations}
                       fetchColumnConfigurations={fetchColumnConfigurations}
                       fetchFilterConfigurations={fetchFilterConfigurations}
                       fetchPreMadeRelationships={fetchPreMadeRelationships}
                       fetchRelationshipConfigurations={fetchRelationshipConfigurations}
        />
      </GeneralModal>
    )
  }
}
