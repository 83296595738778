import React from 'react';
import './AssignSymptomsModal.scss'
import "antd/dist/antd.css";
import {Translation, withTranslation} from "react-i18next";
import i18n from "../../i18n";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import GeneralModal from "../GeneralModal/GeneralModal";
import "../../Modals/GeneralModal/GeneralModal.scss"
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import CustomRenderedOption from "../../Custom UI/CustomRenderedOption/CustomRenderedOption";

const notifyFetchSymptomsFailed = (aError) => toast(<Translation>{(t, { i18n }) => t('ERROR_FETCHING_SYMPTOMS', {error:aError})}</Translation>, NOTIFY_OPTS.autoClose);

class AssignSymptomsModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      extraNotes:'',
      objectiveId:props.defaultValue && props.defaultValue.symptom_objective_id,
      goToNextState:false,
      selectedValue:props.defaultValue && props.defaultValue.name
    };
    this.assignSymptom = this.assignSymptom.bind(this);
  }

  componentDidMount(){
    const {selectedValue} = this.state;
    
    if(selectedValue) {
      this.setState({goToNextState:true});
      this.setState({extraNotes:this.props.defaultValue.description});
    }
  }
  
  handleChange = (aOption) => {
    aOption && this.setState({selectedValue:aOption, goToNextState:true});
  };
  
  assignSymptom(){
    const {selectedValue, extraNotes} = this.state;
    const {assignSymptomCallback} = this.props;
    let customSymptom = null;
    
    if(selectedValue.isSearchString){
      customSymptom = selectedValue;
    }
    assignSymptomCallback({selectedValue:selectedValue.id, extraNotes:extraNotes, customSymptom:customSymptom && customSymptom.name});
  }

  handleExtraNotesChange = (aEvent) => this.setState({extraNotes: aEvent.target.value});

  render() {
    const {t, name, isOpen, handleCloseModal, assignSymptomsError, fetchSymptoms, patientId, groupId} = this.props;
    const {selectedValue, goToNextState, extraNotes} = this.state;
    
    return (
      <GeneralModal title={<Translation>{(t, {i18n}) => t('ASSIGN_SYMPTOM_NAME', {name:name})}</Translation>}
                    isOpen={isOpen}
                    isEditing={false}
                    handleSave={() => this.assignSymptom()}
                    onRequestClose={handleCloseModal}
                    noTitleHeading={true}
                    showSaveButton={goToNextState}
                    containsDropDown={true}
                    handleCloseModal={handleCloseModal}>
        {assignSymptomsError ?
          <p className='error-message'>
            {assignSymptomsError}
          </p>
          :
          null
        }
        
        <div>
          <LabeledDropDown className="assign-symptoms-modal-search-dropdown"
                           label={<Translation>{(t, {i18n}) => t('SEARCH_SYMPTOM_HERE')}</Translation>}
                           value={selectedValue}
                           handleSave={this.handleChange}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchSymptoms(aLimit, aOffset, aSearchString, patientId, groupId)}
                           notifyFetchFailed={notifyFetchSymptomsFailed}
                           maxMenuHeight={100}
                           customOptionHeight={55}
                           customOptionRenderer={({focusOption, key, option, selectValue, style}) => {
                             let returnValue;
  
                             if(option.isSearchString){
                               returnValue = (
                                 <CustomRenderedOption style={style}
                                                       option={option}
                                                       innerKey={key}
                                                       labelText={<Translation>{(t, {i18n}) => t('CUSTOM_SYMPTOM', {symptom:option.label})}</Translation>}
                                                       headerText={<Translation>{(t, {i18n}) => t('ADD_CUSTOM_SYMPTOMS')}</Translation>}
                                                       selectValue={selectValue}
                                                       focusOption={focusOption}
                                 />
                               );
                             }
                             else if(option.type === 'hasCustomHeader'){
                               returnValue = (
                                 <CustomRenderedOption style={style}
                                                       option={option}
                                                       innerKey={key}
                                                       headerText={<Translation>{(t, {i18n}) => t('ADD_FROM_SYMPTOM_SEARCH')}</Translation>}
                                                       selectValue={selectValue}
                                                       focusOption={focusOption}
                                 />
                               );
                             }
                             else{
                               returnValue = (
                                 <div key={key}
                                      style={style}
                                      onClick={() => selectValue(option)}
                                      onMouseEnter={() => focusOption(option)}>
                                   {option.label}
                                 </div>
                               );
                             }
                             return returnValue;
                           }}
                           labelPropertiesArray={['name']}
                           valuePropertiesArray={['id']}
                           showSearchStringInList={true}
                           responseTotalKeysArray={['data', 'data', 'symptoms_page', 'total']}
                           responseObjectKeysArray={['data', 'data', 'symptoms_page', 'symptoms']}
          />
        </div>
        
        <LabeledTextarea className="assign-symptoms-modal-search-textarea"
                         label={<Translation>{(t, {i18n}) => t('EXTRA_NOTES')}</Translation>}
                         value={extraNotes}
                         handleSave={this.handleExtraNotesChange}
                         placeholder={t('EXTRA_NOTES_HERE', {i18n})}
        />
      </GeneralModal>
    )

  }
}

export default withTranslation()(AssignSymptomsModal)
