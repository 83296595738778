import {translatedOptionsArray} from "../Helpers/Helpers";

export const genderTypes = {
  male:'MALE',
  female:'FEMALE',
  other:'OTHER_LET_ME_SPECIFY',
  prefer_not_to_say:'PREFER_NOT_TO_SAY',
  non_binary:'NON_BINARY',
  intersex:'INTERSEX'
};

export function genderOptionsArray(){
  return translatedOptionsArray(genderTypes);
}
