import React from 'react';
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledTextInput from "../../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import '../../../../Modals/GeneralModal/GeneralModal.scss';
import LabeledTextarea from "../../../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import { components } from "react-select";
const { Option } = components;

const CustomOption = props => (
    <Option {...props}>
      <i className={"ion-"+props.data.value}/>
    </Option>
);

export default class ToolInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { tool, updateTool, selectedTranslation, updateToolTranslation } = this.props

    const toolColors = [ '#E33966', '#FDC702', '#FAA919', '#D7582B', '#68289B', '#660A3E', '#95B23A', '#096C3E', '#36818E', '#04B2D9', '#1C47AA', '#03396C' ];

    const options = [{value: 'medkit', label: ['medication', 'health', 'hospital', 'doctor']},
      {value: 'alert-circled', label: ['alert']},
      {value: 'android-book', label: ['book']},
      {value: 'android-camera', label: ['camera', 'photo']},
      {value: 'android-contact', label: ['contact', 'user', 'person']},
      {value: 'android-information', label: ['information']},
      {value: 'android-mixer', label: ['settings', 'mixer']},
      {value: 'android-star', label: ['star']},
      {value: 'beaker', label: ['beaker', 'science', 'vitals']},
      {value: 'android-forums', label: ['chat', 'forum', 'talk']},
      {value: 'cube', label: ['cube']},
      {value: 'flash', label: ['flash', 'lightning']},
      {value: 'heart', label: ['heart', 'health']},
      {value: 'ios7-alarm', label: ['alarm', 'notify']},
      {value: 'ios7-eye', label: ['eye', 'watch']},
      {value: 'ios7-musical-notes', label: ['musical']},
      {value: 'ios7-pie', label: ['pie']},
      {value: 'ios7-world', label: ['world', 'earth']},
      {value: 'quote', label: ['quote', 'words']},
      {value: 'ribbon-b', label: ['ribbon', 'perscription', 'medication']},
      {value: 'social-buffer', label: ['social', 'stack']},
      {value: 'stats-bars', label: ['chart', 'bar', 'graph']},
      {value: 'umbrella', label: ['umbrella', 'weather', 'rain']},
      {value: 'thermometer', label: ['temperature', 'thermometer', 'stats']},
      {value: 'waterdrop', label: ['water']},
      {value: 'woman', label: ['woman', 'person']},
      {value: 'man', label: ['man', 'person']},
      {value: 'android-social-user', label: ['person', 'user']}];


    let toolColorsDropdown = [];
    for(let toolColor of toolColors){
      toolColorsDropdown.push({value:toolColor, label:toolColor});
    }
    return (
      <div>

        <div style={{display: 'flex', flexDirection: 'column'}}>

          <LabeledTextInput className="modal-input"
                            type="text"
                            value={selectedTranslation.title}
                            label={<Translation>{ (t, { i18n }) => t('TOOL_NAME_STAR') }</Translation>}
                            placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                            handleSave={(aEvent) => updateToolTranslation('title', aEvent.target.value)}
          />

            {/*<input className="form-field-800-36" style={{maxWidth: '100%'}} type="text"*/}
            {/*       placeholder={i18next.t('TOOL_NAME_STAR')}*/}

          {/* Tool Description, Color, and Icon  */}

          <div className="modal-dual-input-container">
            <LabeledDropDown className="modal-input-small"
                             handleSave={(aOption) => updateTool('icon', aOption.value)}
                             customOption={CustomOption}
                             value={{label: tool.icon}}
                             label={<Translation>{ (t, { i18n }) => t('TOOL_ICON') }</Translation>}
                             options={options}
            />
            <LabeledDropDown className="modal-input-small"
                             name="tool-color-dropdown"
                             handleSave={(aColor) => updateTool('icon_bg_color', aColor.value)}
                             options={toolColorsDropdown}
                             value={{label: tool.icon_bg_color}}
                             label={<Translation>{ (t, { i18n }) => t('TOOL_COLOR') }</Translation>}
            />
          </div>
          <LabeledTextarea className="modal-input-textarea"
                           label={<Translation>{ (t, { i18n }) => t('TOOL_DESCRIPTION_STAR_PROPER_CAPITALIZED') }</Translation>}
                           value={selectedTranslation.description}
                           handleSave={(aEvent) => updateToolTranslation('description', aEvent.target.value)}
                           placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
          />

        </div>

      </div>
    )
  }
}
