import React from 'react';
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {Translation} from "react-i18next";
import i18next from "i18next";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import i18n from "../../i18n";
import LabeledPhoneNumberInput from "../../Custom UI/LabeledInputs/LabeledPhoneNumberInput/LabeledPhoneNumberInput";
import "./AlternativeContactCell.scss";
import {stringContainsNumber} from "../../Helpers/Helpers";

const notifyInvalidEmail = () => toast(<Translation>{(t, {i18n}) => t('INVALID_EMAIL_REQUEST_MESSAGE')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyIncompleteForm = (invalidEmailMessage) => toast(<Translation>{(t, {i18n}) => t('INCOMPLETE_FORM', {field:invalidEmailMessage})}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyAlternativeContactFailed = (message) => toast(<Translation>{(t, {i18n}) => t('ADD_ALTERNATIVE_CONTACT_FAILED', {field:message})}</Translation>, NOTIFY_OPTS.autoClose);
const notifyAlternativeContactSuccessful = (message) => toast(<Translation>{(t, {i18n}) => t('ADD_ALTERNATIVE_CONTACT_SUCCESSFUL', {field:message})}</Translation>, NOTIFY_OPTS.autoClose);

export default class AlternativeContactCell extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      buttonDisabled:false,
      currentContactForm:{},
      confirmationModalOpen:false
    };
    
    if(this.props.alternativeContact != null){
      this.state.currentContactForm = this.props.alternativeContact;
    }
    this.handleSave = this.handleSave.bind(this);
    this.checkValidForm = this.checkValidForm.bind(this);
    this.handleDropDownSave = this.handleDropDownSave.bind(this);
    this.saveAlternativeContactItem = this.saveAlternativeContactItem.bind(this);
    this.removeAlternativeContactItem = this.removeAlternativeContactItem.bind(this);
  }

  // Methods

  handleSave(aKey, aValue){
    let form = this.state.currentContactForm;
    form[aKey] = aValue;
    this.setState({currentContactForm:form, buttonDisabled:false});
  }

  handleDropDownSave(aOption, aKey){
    let form = this.state.currentContactForm;
    form[aKey] = aOption.value;
    this.setState({currentContactForm:form, buttonDisabled:false});
  }
  
  checkValidForm(){
    const form = this.state.currentContactForm;
    const validEmailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/;
    let returnValue = true;
    let invalidEmailMessage = '';
    
    if((form.email && !form.email.match(validEmailFormat))){
      returnValue = false;
      invalidEmailMessage = i18next.t('INVALID_EMAIL_REQUEST_MESSAGE');
    }
    if(!(form.first_name && form.first_name !== '' && form.last_name && form.last_name !== '' &&
      form.email && form.email !== '' && form.primary_phone_number &&
      form.primary_phone_number !== '' && form.relationship_to_patient)){
      returnValue = false;
      notifyIncompleteForm(invalidEmailMessage);
    }
    else if(!returnValue){
      notifyInvalidEmail();
    }
    return returnValue;
  }
  
  saveAlternativeContactItem(){
    if(!this.state.buttonDisabled){
      this.setState({buttonDisabled:true});
      
      if(this.props.patient != null && this.props.patient.id != null){
        if(this.checkValidForm()){
          this.props.saveAlternativeContactItem(this.state.currentContactForm).then(newResult => {
            if(this.props.alternativeContact.id){
              notifyAlternativeContactSuccessful(i18n.t('UPDATED'));
            }
            else{
              notifyAlternativeContactSuccessful(i18n.t('ADDED'));
            }
          }, (newError) => {
            if(this.props.alternativeContact.id){
              notifyAlternativeContactFailed(i18n.t('UPDATE'));
            }
            else{
              notifyAlternativeContactFailed(i18n.t('ADD'));
            }
          });
        }
      }
      else{
        console.error("ERROR: Patient not found");
      }
    }
  }

  removeAlternativeContactItem(){
    this.props.removeAlternativeContact(this.state.currentContactForm);
  }
  
  // Render

  render() {
    const {alternativeContact, patient} = this.props;
    const {buttonDisabled, confirmationModalOpen, alternativePhoneError, primaryPhoneError} = this.state;

    let relationship_to_patient =  [
        {label:<Translation>{(t, {i18n}) => t('AGENT')}</Translation>, value:'Agent'},
        {label:<Translation>{(t, {i18n}) => t('EMERGENCY')}</Translation>, value:'Emergency'},
        {label:<Translation>{(t, {i18n}) => t('FAMILY')}</Translation>, value:'Family'},
        {label:<Translation>{(t, {i18n}) => t('FRIEND')}</Translation>, value:'Friend'},
        {label:<Translation>{(t, {i18n}) => t('GUARDIAN')}</Translation>, value:'Guardian'},
        {label:<Translation>{(t, {i18n}) => t('PARENT')}</Translation>, value:'Parent'},
        {label:<Translation>{(t, {i18n}) => t('PARTNER')}</Translation>, value:'Partner'}];

    let morning = (i18next.t('MORNING'));
    let afternoon = (i18next.t('AFTERNOON'));
    let evening = (i18next.t('EVENING'));

    let contact_time_preference =  [{label:morning, value:'Morning'},
        {label:afternoon, value:'Afternoon'},
        {label:evening, value:'Evening'}];

    let languages =  [
      {label:(i18next.t('ENGLISH_WITH_FLAG')), value:'en'},
      {label:(i18next.t('ITALIAN_WITH_FLAG')), value:'it'},
      {label:(i18next.t('FRENCH_WITH_FLAG')), value:'fr'},
      {label:(i18next.t('GERMAN_WITH_FLAG')), value:'de'},
      {label:(i18next.t('SPANISH_WITH_FLAG')), value:'es'}];

    return (
      <div className='alternative-contact-section alternative-contact-cell-labeled-phone-number-input'>
        <LabeledTextInput className="alternative-contact-cell-input"
                          label={<Translation>{(t, {i18n}) => t('FIRST_NAME_PROPER_CAPITALIZED')}</Translation>}
                          value={alternativeContact.first_name}
                          required={true}
                          handleSave={(aEvent) => this.handleSave('first_name', aEvent.target.value)}
                          errorMessage={stringContainsNumber(alternativeContact.first_name) ? i18n.t('NUMBER_IN_FIRST_NAME_ERROR') : ''}
        />
        <LabeledTextInput className="alternative-contact-cell-input"
                          label={<Translation>{(t, {i18n}) => t('MIDDLE_NAME')}</Translation>}
                          value={alternativeContact.middle_initial}
                          maxLength={1}
                          handleSave={(aEvent) => this.handleSave('middle_initial', aEvent.target.value)}
        />
        
        <LabeledTextInput className="alternative-contact-cell-input"
                          label={<Translation>{(t, {i18n}) => t('LAST_NAME_PROPER_CAPITALIZED')}</Translation>}
                          value={alternativeContact.last_name}
                          required={true}
                          handleSave={(aEvent) => this.handleSave('last_name', aEvent.target.value)}
                          errorMessage={stringContainsNumber(alternativeContact.last_name) ? i18n.t('NUMBER_IN_LAST_NAME_ERROR') : ''}
        />

        <LabeledDropDown className="alternative-contact-cell-input"
                         name="relationship_to_patient"
                         label={<Translation>{(t, {i18n}) => t('RELATIONSHIP')}</Translation>}
                         value={alternativeContact && relationship_to_patient.filter(function(option) {
                           return option.value === alternativeContact.relationship_to_patient;
                         })}
                         options={relationship_to_patient}
                         required={true}
                         handleSave={(aOption) => this.handleDropDownSave(aOption, 'relationship_to_patient')}
        />

        <LabeledPhoneNumberInput className="alternative-contact-cell-input"
                                 label={<Translation>{(t, {i18n}) => t('PRIMARY_PHONE')}</Translation>}
                                 value={alternativeContact.primary_phone_number}
                                 required={true}
                                 handleSave={(aPhoneNumber, aIsValid, aError) => {
                                  if(aIsValid){
                                    this.handleSave('primary_phone_number', aPhoneNumber);
                                  }
                                  this.setState({primaryPhoneError:aError})
                                }}
                                 errorMessage={primaryPhoneError}
                                 countryCode={patient.country_code ? patient.country_code :'US'}
        />

        <LabeledPhoneNumberInput className="alternative-contact-cell-input"
                                 label={<Translation>{(t, {i18n}) => t('ALTERNATIVE_PHONE')}</Translation>}
                                 value={alternativeContact.alternative_phone_number}
                                 required={true}
                                 handleSave={(aPhoneNumber, aIsValid, aError) => {
                                  if(aIsValid){
                                    this.handleSave('alternative_phone_number', aPhoneNumber);
                                  }
                                  this.setState({alternativePhoneError:aError});
                                }}
                                 errorMessage={alternativePhoneError}
                                 countryCode={patient.country_code ? patient.country_code :'US'}
        />

        <LabeledTextInput className="alternative-contact-cell-input"
                          label={<Translation>{(t, {i18n}) => t('EMAIL')}</Translation>}
                          value={alternativeContact.email}
                          required={true}
                          handleSave={(aEvent) => this.handleSave('email', aEvent.target.value)}
        />

        <LabeledDropDown className="alternative-contact-cell-input"
                         name="preferred_language"
                         label={<Translation>{(t, {i18n}) => t('PREFERRED_LANGUAGE')}</Translation>}
                         value={alternativeContact && languages.filter(function(option) {
                           return option.value === alternativeContact.preferred_language;
                         })}
                         options={languages}
                         handleSave={(aOption) => this.handleDropDownSave(aOption, 'preferred_language')}
        />

        <LabeledDropDown className="alternative-contact-cell-input"
                         name="contact_time_preference"
                         label={<Translation>{(t, {i18n}) => t('CONTACT_TIME_PREFERENCE')}</Translation>}
                         value={alternativeContact && contact_time_preference.filter(function(option) {
                           return option.value === alternativeContact.contact_time_preference;
                         })}
                         options={contact_time_preference}
                         handleSave={(aOption) => this.handleDropDownSave(aOption, 'contact_time_preference')}
        />

        <GrayButton className="button-margin"
                    name={<Translation>{(t, {i18n}) => t('REMOVE')}</Translation>}
                    onClick={() => {this.setState({confirmationModalOpen:true})}}
        />
        
        <BlueButton className="button-popover"
                    name={<Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                    onClick={this.saveAlternativeContactItem}
                    disabled={buttonDisabled || stringContainsNumber(alternativeContact.first_name) || stringContainsNumber(alternativeContact.last_name)}
        />
        
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('ALTERNATIVE_CONTACT_INFORMATION')})}
                           isOpen={confirmationModalOpen}
                           reject={() => this.setState({confirmationModalOpen:false})}
                           confirm={() => this.removeAlternativeContactItem()}
        />
      </div>
    )
  }
}
