import React from "react";
import './SurveyBuilderNumberQuestionCell.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import {objectIsEmpty} from "../../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import SurveyBuilderChoices from "../../../../Components/SurveyBuilder/SurveyBuilderChoices/SurveyBuilderChoices";

export default class SurveyBuilderNumberQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      refreshMaxValue:false,
      refreshMinValue:false,
      refreshMaxWarning:false,
      refreshMinWarning:false
    };
    this.validateBoundsForQuestion = this.validateBoundsForQuestion.bind(this);
  }
  
  // Methods
  
  validateBoundsForQuestion(aQuestion, aMinValue, aMinWarning, aMaxWarning, aMaxValue, aChangeMax = false, aChangeMin = false){
    if(!objectIsEmpty(aQuestion)){
      let maxValue = Number(aMaxValue);
      let minValue = Number(aMinValue);
      let maxWarning = Number(aMaxWarning);
      let minWarning = Number(aMinWarning);
      
      if(aMaxValue){
        if(aMinValue && !aChangeMin){
          maxValue = Math.max(Number(aMinValue), Number(aMaxValue))
        }
        if(aMaxWarning){
          maxWarning = Math.min(Number(aMaxWarning), Number(maxValue));
        }
      }
      if(aMinValue){
        if(aMaxValue && !aChangeMax){
          minValue = Math.min(Number(aMinValue), Number(aMaxValue));
        }
        if(aMinWarning){
          minWarning = Math.max(Number(aMinWarning), Number(minValue));
        }
      }
      if(aMaxValue){
        if(aMaxWarning){
          aQuestion.max = {value:maxValue, warning:maxWarning};
        }
        else{
          aQuestion.max = {value:maxValue};
        }
      }
      else if(aMaxWarning){
        aQuestion.max = {warning:maxWarning};
      }
      if(aMinValue){
        if(aMinWarning){
          aQuestion.min = {value:minValue, warning:minWarning};
        }
        else{
          aQuestion.min = {value:minValue};
        }
      }
      else if(aMinWarning){
        aQuestion.min = {warning:minWarning};
      }
    }
  }
  
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, translationsArray, shouldDetectErrors, errorForIdTypeAndKey} = this.props;
    const {refreshMaxValue, refreshMinValue, refreshMaxWarning, refreshMinWarning} = this.state;
    const {max, min, type} = question;
  
    const requirements = [{label:i18next.t('NUMBER_AND_CHOICE'), value:'and'}, {label:i18next.t('NUMBER_OR_CHOICE'), value:'or'}];
    
    let maxValue;
    let minValue;
    let maxWarning;
    let minWarning;
  
    if(question){
      if(max){
        if(max.value !== undefined && max.value !== null){
          maxValue = Number(max.value);
        }
        if(max.warning !== undefined && max.warning !== null){
          maxWarning = Number(max.warning);
        }
      }
      if(min){
        if(min.value !== undefined && min.value !== null){
          minValue = Number(min.value);
        }
        if(min.warning !== undefined && min.warning !== null){
          minWarning = Number(min.warning);
        }
      }
    }
    
    return(
      <div className="survey-builder-number-question-cell">
        <div className="survey-builder-number-input-container">
          <LabeledDelayedInput className="survey-builder-number-input"
                               type={'number'}
                               label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                               value={refreshMinValue ? '' : minValue}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 let value = aEvent.target.value;
            
                                 if(value && value.length > 0){
                                   this.validateBoundsForQuestion(updatedQuestion, value, minWarning, maxWarning, maxValue, false, true);
              
                                   if(question.min && updatedQuestion.min && question.min.value === updatedQuestion.min.value){
                                     this.setState({refreshMinValue:true}, () => {
                                       setTimeout(() => this.setState({refreshMinValue:false}), 10);
                                     });
                                   }
                                 }
                                 else if(updatedQuestion.min){
                                   delete updatedQuestion.min['value'];
              
                                   if(objectIsEmpty(updatedQuestion.min)){
                                     delete updatedQuestion['min'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_value').message}
          />
          
          <LabeledDelayedInput className="survey-builder-number-input"
                               type={'number'}
                               label={<Translation>{ (t, { i18n }) => t('MIN_WARNING') }</Translation>}
                               value={refreshMinWarning ? '' : minWarning}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MIN_WARNING_EXPLANATION')}</Translation>}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 let value = aEvent.target.value;
            
                                 if(value && value.length > 0){
                                   this.validateBoundsForQuestion(updatedQuestion, minValue, value, maxWarning, maxValue, false, true);
              
                                   if(question.min && updatedQuestion.min && question.min.warning === updatedQuestion.min.warning){
                                     this.setState({refreshMinWarning:true}, () => {
                                       setTimeout(() => this.setState({refreshMinWarning:false}), 10);
                                     });
                                   }
                                 }
                                 else if(updatedQuestion.min){
                                   delete updatedQuestion.min['warning'];
              
                                   if(objectIsEmpty(updatedQuestion.min)){
                                     delete updatedQuestion['min'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_warning').message}
          />
          
          <LabeledDelayedInput className="survey-builder-number-input"
                               type={'number'}
                               label={<Translation>{ (t, { i18n }) => t('MAX_VALUE') }</Translation>}
                               value={refreshMaxValue ? '' : maxValue}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 let value = aEvent.target.value;
            
                                 if(value && value.length > 0){
                                   this.validateBoundsForQuestion(updatedQuestion, minValue, minWarning, maxWarning, value, true, false);
              
                                   if(question.max && updatedQuestion.max && question.max.value === updatedQuestion.max.value){
                                     this.setState({refreshMaxValue:true}, () => {
                                       setTimeout(() => this.setState({refreshMaxValue:false}), 10);
                                     });
                                   }
                                 }
                                 else if(updatedQuestion.max){
                                   delete updatedQuestion.max['value'];
              
                                   if(objectIsEmpty(updatedQuestion.min)){
                                     delete updatedQuestion['min'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_value').message}
          />
          
          <LabeledDelayedInput className="survey-builder-number-input"
                               type={'number'}
                               label={<Translation>{ (t, { i18n }) => t('MAX_WARNING') }</Translation>}
                               value={refreshMaxWarning ? '' : maxWarning}
                               infoBlurb={<Translation>{(t, { i18n }) => t('MAX_WARNING_EXPLANATION')}</Translation>}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 let value = aEvent.target.value;
            
                                 if(value && value.length > 0){
                                   this.validateBoundsForQuestion(updatedQuestion, minValue, minWarning, value, maxValue, true, false);
              
                                   if(question.max && updatedQuestion.max && question.max.warning === updatedQuestion.max.warning){
                                     this.setState({refreshMaxWarning:true}, () => {
                                       setTimeout(() => this.setState({refreshMaxWarning:false}), 10);
                                     });
                                   }
                                 }
                                 else if(updatedQuestion.max){
                                   delete updatedQuestion.max['warning'];
              
                                   if(objectIsEmpty(updatedQuestion.max)){
                                     delete updatedQuestion['max'];
                                   }
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_warning').message}
          />
        </div>
  
        {type === 'number_choice' ?
          <SurveyBuilderChoices question={question}
                                requirements={requirements}
                                minimumChoices={1}
                                updateQuestion={updateQuestion}
                                selectedLanguage={selectedLanguage}
                                translationsArray={translationsArray}
                                shouldDetectErrors={shouldDetectErrors}
                                errorForIdTypeAndKey={errorForIdTypeAndKey}
          />
          :
          null
        }
      </div>
    )
  }
}
