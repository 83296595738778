import { connect } from 'react-redux';
import actions from 'actions';
import ManageUsers from 'Pages/ManageUsers/ManageUsers'

const mapStateToProps = (state)=> {
  let users = state.users && state.users.users
  let currentUser = state.current && state.current.user
  let company = state.company && state.company.company
  return {
    users,
    currentUser,
    company
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: () => {
      dispatch(actions.fetchUsers())
    },
    fetchUsersScroll: (aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchUsersScroll(aLimit, aOffset, aSearchString))
    },
    addUser: (newUser) => {
      return dispatch(actions.addUser(newUser)).then(result => {
        return dispatch(actions.fetchUsersScroll(10, 0))
      })
    },
    removeUser: (userId) => {
      return dispatch(actions.removeUser(userId)).then(result => {
        return dispatch(actions.fetchUsersScroll(10, 0))
      })
    },
    requestPasswordChange: (user) => {
      return dispatch(actions.updateUser(user)).then(result => {
        return dispatch(actions.fetchUsersScroll(10, 0))
      })
    },
    resetPatientsPassword:(aUserId, aPassword) => {
      return dispatch(actions.resetPatientsPassword(aUserId, aPassword))
    },
    updateUserRole: ({userId, role}) => {
      return dispatch(actions.updateUserRole({userId, role}))
    },
    updateUserGroups: ({userId, groups}) => {
      return dispatch(actions.updateUserGroups({userId, groups})).then(result => {
        return dispatch(actions.fetchUsersScroll(10, 0))
      })
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ManageUsers)
