import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchDynamicLists(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_DYNAMIC_LISTS);
  let query = `
    query dynamic_list_page($search_string: String, $offset: Int, $limit: Int) {
      dynamic_list_page(search_string: $search_string, offset: $offset, limit: $limit) {
        dynamic_lists {
          id
          name
          reference
          created_at
          updated_at
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}

export function getDynamicListItems(aDynamicListId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_DYNAMIC_LIST_ITEMS);
  let query = `
    query getDynamicListItem ($search_string: String, $dynamic_list_id: Int, $limit: Int, $offset: Int) {
      dynamic_list_items_page(search_string: $search_string, dynamic_list_id: $dynamic_list_id limit: $limit, offset: $offset){
        dynamic_list_items{
          id
          description
        }
        total
      }
    }
  `;
  let variables = {};
  
  return () => {
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    variables['dynamic_list_id'] = aDynamicListId;
    return global.api.post("/queries", { query, variables });
  };
}

export function fetchDynamicList(aId, aReference){
  EventTracker.track(EventTypes.FETCH_DYNAMIC_LIST);
  let query = `
    query dynamic_list ($reference: String, $id: ID) {
      dynamic_list (reference: $reference, id: $id) {
        id
        name
        reference
        created_at
        updated_at
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aId){
      variables['id'] = aId;
    }
    else if(aReference){
      variables['reference'] = aReference;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchDynamicListById(aId){
  return fetchDynamicList(aId, null);
}

export function fetchDynamicListByReference(aReference){
  return fetchDynamicList(null, aReference);
}
