import React from "react";
import './ContactInformation.scss'
import LabeledDropDown from '../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown';
import {Translation, withTranslation} from "react-i18next";
import ChangePasswordModal from '../../../../Modals/ChangePasswordModal/ChangePasswordModal';
import LabeledPhoneNumberInput from "../../../../Custom UI/LabeledInputs/LabeledPhoneNumberInput/LabeledPhoneNumberInput";
import i18next from "i18next";
import CallModal from "../../../../Modals/CallModal/CallModal";
import CallSummaryModal from "../../../../Modals/CallSummaryModal/CallSummaryModal";
import BlueButton from "../../../../Custom UI/Buttons/BlueButton/BlueButton";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import {roleForCompany} from "../../../../Helpers/CompanyHelpers";
import {errorMessageFromServerError} from "../../../../Helpers/Helpers";
import {emailIsValid, formatUserName} from "../../../../Helpers/AdminHelpers";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../../constants/Notifiers";
import {methodsOfContactOptionsArray, notificationTypeOptionsArray, NotificationTypeValues} from "../../../../constants/MethodsOfContact";

const emailUpdatePermissionsArray = ['admin'];

const notifyPatientKeyUpdateError = (aKey, aError) => toast(<Translation>{ (t, { i18n }) => t('PATIENT_KEY_UPDATE_ERROR', {aKey:aKey, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPatientKeyUpdateSuccess = (aKey) => toast(<Translation>{ (t, { i18n }) => t('PATIENT_KEY_UPDATE_SUCCESS', {aKey:aKey}) }</Translation>, NOTIFY_OPTS.autoClose);

class ContactInformation extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      email:'',
      patient:null,
      callModalIsOpen:false,
      callSummaryInfo:{},
      callSummaryIsOpen:false,
      primaryPhoneError:null,
      phoneNumberCalling:null,
      secondaryPhoneError:null,
      changePasswordIsOpen:false,
      primaryContactNumber:props.patient ? props.patient.primary_contact_number : '',
      secondaryContactNumber:props.patient ? props.patient.secondary_contact_number : '',
      currentDropDownLanguage:'en'
    };
    this.handleSave = this.handleSave.bind(this);
    this.closeCallModal = this.closeCallModal.bind(this);
    this.onPhoneNumberError = this.onPhoneNumberError.bind(this);
    this.updateEmailAddress = this.updateEmailAddress.bind(this);
    this.handleTranslatorNeededSave = this.handleTranslatorNeededSave.bind(this);
  }
  
  componentDidMount() {
    const {patient} = this.props;
    this.setState({email:patient.email});
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {patient} = this.props;
    
    if(prevProps.patient && patient && prevProps.patient.id !== patient.id){
      this.setState({email:patient.email});
    }
  }
  
  // Methods
  
  handleSave({key, value}){
    const {company, patient, updateUser, fetchPatient} = this.props;
    const currentRole = roleForCompany(company);
    
    if(patient !== null && patient.id !== null && updateUser){
      if(key !== 'email' || emailUpdatePermissionsArray.includes(currentRole)){
        const propertyTranslations = {email:i18next.t('EMAIL'), address:i18next.t('ADDRESS'), contact_method:i18next.t('CONTACT_METHOD'),
          translator_needed:i18next.t('TRANSLATOR_NEEDED'), preferred_language:i18next.t('PREFERRED_LANGUAGE'),
          primary_contact_number:i18next.t('PRIMARY_NUMBER'), contact_time_preference:i18next.t('CONTACT_TIME_PREFERENCE'),
          secondary_contact_number:i18next.t('SECONDARY_NUMBER')};
        patient[key] = value;
        this.setState({patient:patient});
        updateUser({id:patient.id, [key]:value}).then((newResult) => {
          notifyPatientKeyUpdateSuccess(propertyTranslations[key]);
          fetchPatient({userId:this.props.patient.id});
          this.setState({patient:this.props.patient});
        }, (newError) => {
          notifyPatientKeyUpdateError(propertyTranslations[key], errorMessageFromServerError(newError));
        });
      }
    }
  }
  
  handleTranslatorNeededSave(aTranslatorNeeded){
    this.handleSave({key:'translator_needed', value:aTranslatorNeeded})
  }
  
  updateEmailAddress(aEmailAddress){
    this.setState({email:aEmailAddress}, () => {
      if(emailIsValid(aEmailAddress)){
        this.handleSave({key:'email', value:aEmailAddress});
      }
    });
  }
  
  onPhoneNumberError(phoneKey, errorMessage){
    this.setState({[phoneKey]: errorMessage});
  }
  
  closeCallModal(noteText, callSid){
    const {patient} = this.props;
    const {callSummaryInfo} = this.state;
    
    if(callSid !== null){
      let summaryInfo = callSummaryInfo;
      summaryInfo.noteText = noteText;
      summaryInfo.attendees = formatUserName(patient);
      summaryInfo.lastTwilioCallSid = callSid;
      this.setState({callModalIsOpen:false, callSummaryInfo:summaryInfo, callSummaryIsOpen:true, phoneNumberCalling:null});
    }
    else{
      this.setState({callModalIsOpen:false, callSummaryInfo:{}, callSummaryIsOpen:false, phoneNumberCalling:null});
    }
  }
  
  onCallButtonPressed(aPhoneNumber){
    this.setState({phoneNumberCalling:aPhoneNumber, callModalIsOpen:true});
  }
  
  // Render
  
  render(){
    const {patient, user, company, resetPatientsPassword, fetchPatient, addCallNote} = this.props;
    const {email, callModalIsOpen, callSummaryInfo, callSummaryIsOpen, primaryPhoneError, phoneNumberCalling,
      secondaryPhoneError, changePasswordIsOpen, primaryContactNumber, secondaryContactNumber} = this.state;
    
    const changePasswordPermissions = ["admin", "admin_level_4"];
    const contactInfoPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const currentRole = roleForCompany(company);
    
    // TODO: Move this to a constants file, as it is used in Manage Settings as well.
    let english = (i18next.t('ENGLISH_WITH_FLAG'));
    let italian = (i18next.t('ITALIAN_WITH_FLAG'));
    let french = (i18next.t('FRENCH_WITH_FLAG'));
    let german = (i18next.t('GERMAN_WITH_FLAG'));
    let spanish = (i18next.t('SPANISH_WITH_FLAG'));
    let availableLanguages = [
      {languageString: english, languageCode: 'en'},
      {languageString: italian, languageCode: 'it'},
      {languageString: french, languageCode: 'fr'},
      {languageString: german, languageCode: 'de'},
      {languageString: spanish, languageCode: 'es'}
    ];
    
    let languageOptions = [];
    let contactTimePreference = [
      {value:'Morning', label:(i18next.t('MORNING'))},
      {value:'Afternoon', label:(i18next.t('AFTERNOON'))},
      {value:'Evening', label:(i18next.t('EVENING'))}
    ];
  
    const methodsOfContactOptions = methodsOfContactOptionsArray();
    const notificationTypeOptions = notificationTypeOptionsArray();
    
    let notificationPreference = '';
    
    if(patient.notification_preference){
      if(patient.notification_preference.includes('text')){
        if(patient.notification_preference.includes('email')){
          notificationPreference = 'textAndEmail'
        }
        else{
          notificationPreference = 'text'
        }
      }
      else if(patient.notification_preference.includes('email')){
        notificationPreference = 'email'
      }
    }
    if(availableLanguages){
      availableLanguages.map((aLanguage, index) => (
        languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
      ));
    }
    
    return (
      <div>
        {contactInfoPermissions.includes(currentRole) ? (
            <div className='personal-information-section'>
              <LabeledDelayedInput className="profile-contact-information-input"
                                   label={<Translation>{ (t, { i18n }) => t('EMAIL')}</Translation>}
                                   value={email}
                                   disabled={!emailUpdatePermissionsArray.includes(currentRole)}
                                   handleSave={(aEvent) => this.updateEmailAddress(aEvent.target.value)}
              />
              
              <LabeledPhoneNumberInput className="profile-contact-information-input"
                                       call={(aPhoneNumber) => this.onCallButtonPressed(aPhoneNumber)}
                                       label={<Translation>{ (t, { i18n }) => t('PRIMARY_NUMBER')}</Translation>}
                                       value={primaryContactNumber}
                                       handleSave={(aPhoneNumber, aIsValid, aError) => {
                                         this.setState({primaryPhoneError:aError, primaryContactNumber:aPhoneNumber}, () => {
                                           if(aIsValid){
                                             this.handleSave({key:'primary_contact_number', value:aPhoneNumber});
                                           }
                                         });
                                       }}
                                       callDisabled={!(company && company.twilio_phone_number && company.twilio_phone_number.length > 0)}
                                       errorMessage={primaryPhoneError}
              />
              
              <LabeledDelayedInput className="profile-contact-information-input"
                                   label={<Translation>{ (t, { i18n }) => t('ADDRESS')}</Translation>}
                                   value={patient && patient.address}
                                   handleSave={(aEvent) => this.handleSave({key:'address', value:aEvent.target.value})}
              />
  
              <LabeledDropDown className="profile-section-contact-information-input-small"
                               label={<Translation>{ (t, { i18n }) => t('NOTIFICATION_PREFERENCE')}</Translation>}
                               value={patient && notificationTypeOptions.filter(function(option){
                                 return option.value === notificationPreference;
                               })}
                               options={notificationTypeOptions}
                               handleSave={(aOption) => this.handleSave({key:'notification_preference', value:NotificationTypeValues[aOption.value]})}
              />
              
              <LabeledDropDown className="profile-section-contact-information-input-small"
                               label={<Translation>{ (t, { i18n }) => t('CONTACT_TIME_PREFERENCE')}</Translation>}
                               value={patient && contactTimePreference.filter(function(option){
                                 return option.value === patient.contact_time_preference;
                               })}
                               options={contactTimePreference}
                               handleSave={(aOption) => this.handleSave({key:'contact_time_preference', value:aOption.value})}
              />
              
              <LabeledDropDown className="profile-contact-information-input"
                               label={<Translation>{ (t, { i18n }) => t('PREFERRED_LANGUAGE')}</Translation>}
                               value={patient && languageOptions.filter(function(option){
                                 return option.value === patient.preferred_language;
                               })}
                               options={languageOptions}
                               handleSave={(aOption) => this.handleSave({key:'preferred_language', value:aOption.value})}
              />
              
              <LabeledDropDown className="profile-contact-information-input"
                               label={<Translation>{ (t, { i18n }) => t('CONTACT_METHOD')}</Translation>}
                               value={patient && methodsOfContactOptions.filter(function(option){
                                 return option.value === patient.contact_method;
                               })}
                               options={methodsOfContactOptions}
                               handleSave={(aOption) => this.handleSave({key:'contact_method', value:aOption.value})}
              />
              
              <LabeledPhoneNumberInput className="profile-contact-information-input"
                                       call={(phoneNumber) => this.onCallButtonPressed(phoneNumber)}
                                       label={<Translation>{ (t, { i18n }) => t('SECONDARY_NUMBER')}</Translation>}
                                       value={secondaryContactNumber}
                                       handleSave={(aPhoneNumber, aIsValid, aError) => {
                                         this.setState({secondaryPhoneError:aError, secondaryContactNumber:aPhoneNumber}, () => {
                                           if(aIsValid){
                                             this.handleSave({key:'secondary_contact_number', value:aPhoneNumber});
                                           }
                                         });
                                       }}
                                       callDisabled={!(company && company.twilio_phone_number && company.twilio_phone_number.length > 0)}
                                       errorMessage={secondaryPhoneError}
              />
              
              <LabeledSwitch className="personal-information-section-contact-translator-needed"
                             label={<Translation>{ (t, { i18n }) => t('TRANSLATOR_NEEDED') }</Translation>}
                             checked={patient && patient.translator_needed}
                             onChange={this.handleTranslatorNeededSave}
                             defaultChecked={false}
                             checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                             unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
              />
              
              {changePasswordPermissions.includes(currentRole) ?
                <div className="contact-button-container personal-information-section-contact-change-password">
                  <BlueButton name={<Translation>{ (t, { i18n }) => t('CHANGE_PASSWORD')}</Translation>}
                              onClick={() => this.setState({changePasswordIsOpen:true})}
                  />
                </div>
                :
                null
              }
              
              {changePasswordPermissions.includes(currentRole) ?
                <ChangePasswordModal isOpen={changePasswordIsOpen}
                                     userId={patient && patient.id}
                                     closeModal={() => this.setState({changePasswordIsOpen:false})}
                                     updateCurrentUser={resetPatientsPassword}
                                     requiresCurrentPassword={false}
                />
                :
                null
              }
              
              <CallModal isOpen={callModalIsOpen}
                         adminId={user && user.id}
                         patientId={patient && patient.id}
                         closeModal={this.closeCallModal}
                         phoneNumberFrom={company && company.twilio_phone_number}
                         phoneNumberToCall={phoneNumberCalling}
              />
              
              {callSummaryInfo && callSummaryIsOpen ?
                <CallSummaryModal user={user}
                                  isOpen={callSummaryIsOpen}
                                  callSid={callSummaryInfo.lastTwilioCallSid}
                                  company={company}
                                  patient={patient}
                                  editable={false}
                                  noteText={callSummaryInfo.noteText}
                                  attendees={callSummaryInfo.attendees}
                                  closeModal={() => {fetchPatient({userId:patient.id}); this.setState({callSummaryIsOpen:false})}}
                                  addCallNote={addCallNote}
                                  typeOfVisit={'call'}
                />
                :
                null
              }
            </div>
          )
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('CONTACT_INFORMATION_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
export default withTranslation()(ContactInformation);
