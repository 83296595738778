import React, { useState } from 'react';
import '../Login/SignUpLogin.scss';
import {Translation} from "react-i18next";

const ForgotPassword = ({ user, forgotPassword }) => {
  const [email, setEmail] = useState('');
  const [sendingRequest, setSendingRequest] = useState(false);

  const submit = (aEvent) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();
    setSendingRequest(true);
    forgotPassword({email}).then(newResult => {
      setSendingRequest(false);
    }, newError => {
      setSendingRequest(false);
    });
  }

  //!. The exact same fn is in Login.js
  const toSentenceCase = (unformattedString) => {
    let newString = unformattedString.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function(c){ return c.toUpperCase()});
    return newString;
  }

  const errorMessage = user && user.errors ? user.errors[Object.keys(user.errors)[0]][0] : "";
  const errorText = (errorMessage !== '') ? toSentenceCase(Object.keys(user.errors)[0].split("_").join(" ") + " " + errorMessage) : "";
  const successMessage = user && user.message && user.message.length > 0 ? user.message : '';
  const successText = (successMessage !== '') ? toSentenceCase(successMessage) : "";
  
  const submitButtonClickable = () => {
    return email && !sendingRequest && !successMessage;
  }

  return (
    <div className='login-sign-up-container'>
      <div className='login-signup-container-inner'>
        <div className="login-sign-up-logo-head">
          <img src="https://s3.amazonaws.com/hs-app-images/HSC_Logo_75_percent_opacity.png" alt='HSC Logo'/>
          <span>
            <Translation>{ (t, { i18n }) => t('HEALTH_STORYLINES_CREATOR') }</Translation>
          </span>
        </div>
        <p className='login-heading'>
          <Translation>{ (t, { i18n }) => t('FORGOT_PASSWORD_NO_WORRIES') }</Translation>
          <br/> <Translation>{ (t, { i18n }) => t('SENDING_EMAIL_NOTIFICATION') }</Translation>
        </p>
        <form className="floating-form">
          <div className="floating-label login-input-width">
            <input className="floating-input "
                   name="email"
                   type="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   placeholder=" "/>
            <label>
              <Translation>{ (t, { i18n }) => t('ENTER_EMAIL') }</Translation>
            </label>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a id="forgot-password"
               href="../login" className="forgot-password-button">
              <Translation>{ (t, { i18n }) => t('RETURN_TO_LOGIN') }</Translation>
            </a>
            <button className="login-submit-button clickable"
                    style={{opacity: submitButtonClickable() ? 1 : 0.5}}
                    onClick={submit}
                    disabled={!submitButtonClickable()}>
              <Translation>{ (t, { i18n }) => t('SUBMIT_LOWERCASE') }</Translation>
            </button>
          </div>
        </form>
        {errorText &&
            <ul className="error-msg">
              { errorText }
            </ul>
        }
        {successText &&
            <ul className="success-msg">
              { successText }
            </ul>
        }
      </div>
    </div>
  );
}
export default ForgotPassword
