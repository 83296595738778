import React from 'react';
import MedicationAllergyListItem from '../../../Cells/MedicationAllergyCell/MedicationAllergyCell';
import PatientPanelSubSectionHeadingWithButton from '../PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton';
import {Translation} from 'react-i18next';
import i18next from 'i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {toast} from 'react-toastify';
import {NOTIFY_OPTS} from '../../../constants/Notifiers';
import Loading from "../../../Custom UI/Loading/Loading";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import AssignMedicationsAllergyModal from "../../../Modals/AssignMedicationsAllergyModal/AssignMedicationsAllergyModal";

const notifyAddAllergyFail = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_ADDING_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyAllergyAddedSuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_ADDED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyRemoveAllergySuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_REMOVED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyAllergyUpdatedSuccess = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_ALLERGY_UPDATED_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyRemoveMedicationAllergyError = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_REMOVING_MEDICATION_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class MedicationAllergies extends React.Component {
  
  // Instance Variables
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      menuAnchorElement:null,
      selectedPatientListItem:null,
      assignMedicationAllergies:false,
      selectedMedicationAllergy:null,
      selectedMedicationAllergyId:null,
      medicationAllergiesUsersArray:[]
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.editMedicationAllergy = this.editMedicationAllergy.bind(this);
    this.assignMedicationAllergyCallback = this.assignMedicationAllergyCallback.bind(this);
    this.removeMedicationAllergyFromUser = this.removeMedicationAllergyFromUser.bind(this);
    
    if(this.props.patient && this.props.patient.medication_allergies_users){
      this.state.medicationAllergiesUsersArray = this.props.patient.medication_allergies_users;
    }
  }
  
  componentDidMount() {
    this.loadMedicationAllergies();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.medication_allergies_users){
      if(this.state.medicationAllergiesUsersArray !== this.props.patient.medication_allergies_users){
        this.setState({medicationAllergiesUsersArray:this.props.patient.medication_allergies_users});
      }
    }
  }
  
  // Methods
  
  loadMedicationAllergies(){
    this.setState({loading:true});
    this.props.loadPatientMedicationAllergiesUsers(this.props.patient.id).then((newResult) => {
      this.setState({loading:false, loadFailed:false});
    }, (newError) => {
      this.setState({loading:false, loadFailed:true});
    });
  }
  
  editMedicationAllergy(){
    this.setState({assignMedicationAllergies:true});
    this.closeMenu();
  }
  
  removeMedicationAllergyFromUser = (aMedicationAllergyId) => {
    const userId = this.props.patient.id;
    const {medicationAllergiesUsersArray} = this.state;
    const index = medicationAllergiesUsersArray.map((aMedicationAllergyUser) => {return aMedicationAllergyUser.medication_id}).indexOf(aMedicationAllergyId);
    
    if(index > -1){
      medicationAllergiesUsersArray.splice(index, 1);
    }
    this.props.removeMedicationAllergyFromUser({aUserId:userId, aMedicationAllergyId:aMedicationAllergyId}).then((newResult => {
        this.props.loadPatientMedicationAllergiesUsers(this.props.patient.id);
        notifyRemoveAllergySuccess();
      }),
      (newError) => {
        let error = '';
        
        if(newError && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyRemoveMedicationAllergyError(error);
        this.props.loadPatientMedicationAllergiesUsers(this.props.patient.id);
      });
    this.closeMenu();
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedMedicationAllergyId:null, selectedPatientListItem:null});
  }
  
  menuButtonClicked(aEvent, aMedicationAllergyId, aPatient, aMedicationAllergy){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedMedicationAllergyId:aMedicationAllergyId, selectedPatientListItem:aPatient, selectedMedicationAllergy:aMedicationAllergy});
  }
  
  assignMedicationAllergyCallback(aName, aSeverity, aAllergyId, aId){
    this.props.assignMedicationAllergyCallback(this.props.patient && this.props.patient.id, aName, aSeverity, aAllergyId, aId).then((newResult) => {
      if(newResult && newResult.error){
        notifyAddAllergyFail(newResult.error);
      }
      else{
        this.setState({assignMedicationAllergies:false});
        
        if(aId){
          notifyAllergyUpdatedSuccess();
        }
        else{
          notifyAllergyAddedSuccess();
        }
      }
    });
  }
  
  // Render
  
  render(){
    const {assignMedicationAllergiesModalOpen, editSymptomsModalOpen, company} = this.props;
    const {medicationAllergiesUsersArray, loading, loadFailed} = this.state;
    const medicationAllergiesPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_10', 'admin_level_11'];
    let currentRole = roleForCompany(company);
    
    return (
      <div>
        {medicationAllergiesPermissions.includes(currentRole) ?
          <div>
            <PatientPanelSubSectionHeadingWithButton addFunc={assignMedicationAllergiesModalOpen}
                                                     heading={i18next.t('PATIENT_MEDICATION_ALLERGIES')}
                                                     addButtonAsGear={true}
            />
            <div className="subsection-element" style={{display:'block', maxHeight:'350px', overflowY:'auto'}}>
              {loading ?
                <Loading/>
                :
                (loadFailed ?
                    <LoadFailed retry={() => this.loadMedicationAllergies()}/>
                    :
                    (medicationAllergiesUsersArray.length > 0 ?
                        <>
                          {medicationAllergiesUsersArray.map((medicationAllergy, index) => (
                            <div key={'medication-allergy-' + index + '-' + medicationAllergy.id}
                                 style={{display:'flex', flexDirection:'column'}}>
                              <MedicationAllergyListItem data={medicationAllergy}
                                                         removeId={medicationAllergy.id}
                                                         menuButtonClicked={(aEvent, aPatient) => this.menuButtonClicked(aEvent, medicationAllergy.medication_id, aPatient, medicationAllergy)}
                                                         editSymptomsModalOpen={editSymptomsModalOpen}
                              />
                            </div>
                          ))}
                        </>
                        :
                        <div className="medication-allergy-no-medication-allergies-found">
                          <Translation>{(t, {i18n}) => t('NO_MEDICATION_ALLERGIES_FOUND')}</Translation>
                        </div>
                    )
                )}
            </div>
            
            <Menu id="simple-menu"
                  open={Boolean(this.state.menuAnchorElement)}
                  onClose={() => this.closeMenu()}
                  anchorEl={this.state.menuAnchorElement}
                  keepMounted>
              <MenuItem style={{position:'relative'}} onClick={() => this.editMedicationAllergy()}>
                <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
              </MenuItem>
              
              <MenuItem style={{position:'relative'}} onClick={() => this.removeMedicationAllergyFromUser(this.state.selectedMedicationAllergyId)}>
                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
  
            {this.state.assignMedicationAllergies ?
              <AssignMedicationsAllergyModal key={'assign-medications-allergy-modal-' + Math.floor(Math.random() * 10000)}
                                             isOpen={this.state.assignMedicationAllergies}
                                             patient={this.props.patient}
                                             handleCloseModal={() => this.setState({assignMedicationAllergies:false})}
                                             medicationAllergy={this.state.selectedMedicationAllergy}
                                             fetchMedicationAllergies={this.props.fetchMedicationAllergies}
                                             assignMedicationAllergiesError={this.props.assignMedicationAllergiesError}
                                             assignMedicationAllergyCallback={this.assignMedicationAllergyCallback}
              />
              :
              null
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('PATIENT_MEDICATION_ALLERGIES_BLOCKED')}</Translation>
          </div>
        }
      </div>
    );
  }
}
