import React from 'react';
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './ArticlesList.scss';
import i18next from "i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ArticleCell from "../../Cells/ArticleCell/ArticleCell";
import Loading from "../../Custom UI/Loading/Loading";
import SelectedButton from "../../Custom UI/Buttons/SelectedButton/SelectedButton";
import ArticleModal from "../../Modals/ArticleModal/ArticleModal";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

export default class ArticlesList extends React.Component {

  // Instance Variables

  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getArticles(limit, offset),
    success: () => this.updateList(),
    fail: (error) => console.error("ArticlesList (InfiniteScrollManager): Failed with error:", error)
  });

  // Init

  constructor(props){
    super(props);
    this.state = {
      searchString:'',
      articlesArray:[],
      totalArticles:0,
      selectedArticle:null,
      showArticleModal:false,
      articleTopicsArray:[],
      totalArticleTopics:0,
    };
    this.updateViewedOnForArticle = this.updateViewedOnForArticle.bind(this);
    this.articleTopicButtonClicked = this.articleTopicButtonClicked.bind(this);
  }

  componentDidMount(){
    this.scrollManager.fetch(true);
    this.props.getArticleTopics(this.props.user.id).then(newResponse => {
      let data = newResponse.data.data.article_topics;
      let articleTopicsArray = data.article_topics;
      let totalArticleTopics = data.total;
      this.setState({articleTopicsArray: articleTopicsArray, totalArticleTopics:totalArticleTopics});
      return {objects:articleTopicsArray, total:totalArticleTopics};
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(!prevProps.company && prevProps.company !== this.props.company){
      this.setState({ searchString:'', articlesArray:[], totalArticles:0, showArticleModal:false, selectedArticle:null, articleTopicsArray:[], totalArticleTopics:0});
      this.scrollManager.fetch(true);
    }
  }

  // Methods

  updateList(){
    this.setState({articlesArray:this.scrollManager.getList()});
  }

  getArticles(limit, offset){
    let selectedArticleTopicIdsArray = [];

    for(let articleTopic of this.state.articleTopicsArray){
      if(articleTopic.selected){
        selectedArticleTopicIdsArray.push(articleTopic.id);
      }
    }
    return this.props.getArticles(limit, offset, this.props.completed, this.state.searchString, selectedArticleTopicIdsArray).then(newResponse => {
      let data = newResponse.data.data.articles;
      let articlesArray = data.articles;
      let totalArticles = data.total;
      this.setState({totalArticles:totalArticles});
      return {objects:articlesArray, total:totalArticles};
    });
  }

  updateViewedOnForArticle(aArticle){
    let articlesArray = this.state.articlesArray;

    for(let index = 0; index < articlesArray; index += 1){
      let article = articlesArray[index];

      if(article.id === aArticle.id){
        article.viewed_on = aArticle.viewed_on;
        articlesArray[index] = article;
        break;
      }
    }
    this.setState({articlesArray:articlesArray});
  }

  articleTopicButtonClicked(aIndex, aSelected){
    let articleTopicsArray = this.state.articleTopicsArray;

    if(aIndex < articleTopicsArray.length){
      let articleTopic = articleTopicsArray[aIndex];
      articleTopic.selected = aSelected;
      articleTopicsArray[aIndex] = articleTopic;
      this.setState({articleTopicsArray:articleTopicsArray}, () => {
        this.scrollManager.fetch(true)
      });
    }
  }

  // Render

  render() {
    const { articlesArray, totalArticles, selectedArticle, showArticleModal, articleTopicsArray } = this.state;
    const { user, company, viewArticle, getRelatedArticles } = this.props;

    const articlesBlockedPermissions = [];
    let currentRole = roleForCompany(company);
    
    return (
      <div>
        {!articlesBlockedPermissions.includes(currentRole) ?
          <div className="articles-list-container">
            <div className="articles-list-title">
              <Translation>{(t, {i18n}) => t('TOPICS')}</Translation> - {totalArticles}

              <LabeledDelayedInput className="articles-list-search"
                                   disabled={false}
                                   showSearchIcon={true}
                                   placeholder={i18next.t('TAP_TO_SEARCH_PROPER_CAPITALIZED')}
                                   value={this.state.searchString}
                                   handleSave={(aEvent) => {
                                     this.setState({searchString:aEvent.target.value}, () => {
                                       this.scrollManager.fetch(true)
                                     })
                                   }}/>
            </div>

            <div className="articles-list-article-topics-container">
              {articleTopicsArray && articleTopicsArray.length > 0 ?
                <>
                  {articleTopicsArray.map((aArticleTopic, aIndex) => (
                    <SelectedButton key={'articles-list-selected-button-' + aArticleTopic.id + aIndex}
                                    title={aArticleTopic.title}
                                    selected={aArticleTopic.selected}
                                    onClick={() => this.articleTopicButtonClicked(aIndex, !aArticleTopic.selected)}/>
                  ))}
                </>
                :
                null
              }
            </div>

            <div id="articles-list">
              <InfiniteScroll style={{overflowY: "hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="articles-list">
                {articlesArray && articlesArray.length > 0 ?
                  <div className="articles-list-cell-container"
                       style={{height: 'calc(100vh - 338px)'}}>
                    {articlesArray.map((aArticle, aIndex) => (
                      <ArticleCell article={aArticle}
                                   className="articles-list-cell"
                                   key={'articles-list-article-cell-' + aArticle.id + aIndex}
                                   onClick={() => this.setState({selectedArticle:aArticle, showArticleModal:true})}/>
                    ))}
                  </div>
                  :
                  null
                }
              </InfiniteScroll>
            </div>

            {showArticleModal ?
              <ArticleModal user={user}
                            isOpen={showArticleModal}
                            article={selectedArticle}
                            company={company}
                            viewArticle={viewArticle}
                            handleCloseModal={() => this.setState({selectedArticle:null, showArticleModal:false})}
                            getRelatedArticles={getRelatedArticles}
                            updateViewedOnForArticle={(aArticle) => this.updateViewedOnForArticle(aArticle)}/>
              :
              null
            }
          </div>
          :
          <div className="articles-list-access-denied">
            <Translation>{ (t, { i18n }) => t('ARTICLES_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
