import React from 'react';
import './Sidebar.scss';
import actions from 'actions';
import Loading from "../../Custom UI/Loading/Loading";
import Tooltip from '@material-ui/core/Tooltip';
import LinkIcon from '@material-ui/icons/Link';
import ReactDOM from "react-dom";
import {connect} from 'react-redux';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip'
import {Translation} from "react-i18next";
import ManageNavItem from "./ManageNavItem/ManageNavItem";
import AlertIndicator from "../../Custom UI/AlertIndicator/AlertIndicator";
import ProfilePicture from '../../Custom UI/ProfilePicture/ProfilePicture'
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import groupColorPicker from 'utils/ColorPicker'
import {withRouter, Link} from 'react-router-dom'
import {List, Store, AttachMoney, FaceOutlined, TimerOutlined, LayersOutlined, PeopleOutlined, PersonOutlined,
  DateRangeOutlined, VoiceChatOutlined, DescriptionOutlined, SettingsApplicationsOutlined} from '@material-ui/icons'

const SIDEBAR_STYLE = {
  expanded: {
    justifyContentGroupListItem: 'flex-start',
    displayStyleGroupListItem: 'row',
    groupNavItemMargin: '15px',
    groupNameMargin: '5px'
  },
  collapsed: {
    justifyContentGroupListItem: 'center',
    displayStyleGroupListItem: 'column',
    groupNavItemMargin: '0px',
    groupNameMargin: '0px'
  }
};

const SIDEBAR_CLASS = {
  expanded: {
    manageNavItemClass: 'expanded-nav-item',
    sideBarHeaderClass: 'expanded-nav-item',
    allPatientsNavItemClass: 'expanded-nav-item',
  },
  collapsed: {
    manageNavItemClass: 'collapsed-manage-nav-item',
    sideBarHeaderClass: 'collapsed-sidebar-header',
    allPatientsNavItemClass: 'collapsed-all-patients-nav-item',
  }
};

const MANAGE_SIDEBAR_ITEMS = [
  {
    title: 'GROUPS',
    path: 'groups',
    iconComponent: <PeopleOutlined/>,
    featureName: 'Groups'
  },
  {
    title: 'PATIENTS_PROPER_CAPITALIZED',
    path: 'patients',
    iconComponent: <FaceOutlined/>,
    featureName: 'Patients'
  },
  {
    title: 'ADMINS',
    path: 'admins',
    iconComponent: <PersonOutlined/>,
    featureName: 'Admins'
  },
  {
    title: 'VIRTUAL_APPOINTMENTS',
    path: 'VirtualAppointments',
    iconComponent: <VoiceChatOutlined/>,
    featureName: 'Virtual Appointments'
  },
  {
    title: 'TOOLS',
    path: 'tools',
    iconComponent: <LayersOutlined/>,
    featureName: 'Tools'
  },
  {
    title: 'REPORTS',
    path: 'reports',
    iconComponent: <DescriptionOutlined/>,
    featureName: 'Reports'
  },
  {
    title: 'PAYMENTS',
    path: 'payments',
    iconComponent: <AttachMoney/>,
    featureName: 'Payments'
  },
  {
    title: 'TRIGGERS',
    path: 'triggers',
    iconComponent: <TimerOutlined/>,
    featureName: 'Triggers'
  },
  {
    title: 'AUDIT_LOG',
    path: 'papertrail',
    iconComponent: <List/>,
    featureName: 'Audit Log'
  },
  {
    title: 'CAMPAIGNS',
    path: 'campaigns',
    iconComponent: <Store/>,
    featureName: 'Campaigns'
  },
  {
    title: 'SCHEDULES',
    path: 'schedules',
    iconComponent: <DateRangeOutlined/>,
    featureName: 'Schedules'
  },
  {
    title:'SURVEY_LINKS',
    path:'surveyLinks',
    iconComponent:<LinkIcon />,
    featureName:'Survey Links'
  },
  {
    title: 'SETTINGS',
    path: 'settings',
    iconComponent: <SettingsApplicationsOutlined/>,
    featureName: 'Settings'
  }
];

const groupsTab = MANAGE_SIDEBAR_ITEMS[0].featureName;
const patientsTab = MANAGE_SIDEBAR_ITEMS[1].featureName;
const usersTab = MANAGE_SIDEBAR_ITEMS[2].featureName;
const virtualAppointmentsTab = MANAGE_SIDEBAR_ITEMS[3].featureName;
const toolsTab = MANAGE_SIDEBAR_ITEMS[4].featureName;
const reportsTab = MANAGE_SIDEBAR_ITEMS[5].featureName;
const paymentsTab = MANAGE_SIDEBAR_ITEMS[6].featureName;
const triggerTab = MANAGE_SIDEBAR_ITEMS[7].featureName;
const auditLogTab = MANAGE_SIDEBAR_ITEMS[8].featureName;
const campaignTab = MANAGE_SIDEBAR_ITEMS[9].featureName;
const schedulesTab = MANAGE_SIDEBAR_ITEMS[10].featureName;
const surveyLinksTab = MANAGE_SIDEBAR_ITEMS[11].featureName;
const settingsTab = MANAGE_SIDEBAR_ITEMS[12].featureName;

class Sidebar extends React.Component {
  
  // Initialize
  
  constructor(props){
    super(props);
    this.state = {}
  }
  
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.company && this.props.company){
      if(prevProps.company.id !== this.props.company.id){
        this.updatePermittedRoutes();
      }
    }
    else if(!prevProps.company && this.props.company){
      this.updatePermittedRoutes();
    }
  }
  
  // Methods
  
  updatePermittedRoutes = () => {
    const {company, setPermittedItems} = this.props;
    const permitted_items = this.permittedItemsForCompany(company);
    const permitted_routes = permitted_items?.filter((entry) => this.permissionsForRole(roleForCompany(company)).includes(entry.featureName));
    setPermittedItems(permitted_routes);
  };

  handleClickOutside = (aEvent) => {
    const domNode = ReactDOM.findDOMNode(this);

    if(!domNode || !domNode.contains(aEvent.target)){
      if(this.props.sidebarExpanded && this.props.closeSidebar){
        this.props.closeSidebar(aEvent);
      }
    }
  };

  showSelected(path){
    let currentPath = this.props.location.pathname;
    currentPath = currentPath.replace(/[/][0-9]+/g, '');
    return (path === decodeURIComponent(currentPath));
  }

  filter_items(hsFeatures){
    let featureHash = {};
    hsFeatures && hsFeatures.forEach(function(feature){
      featureHash[feature.title] = (feature.enabled === true || feature.enabled === null);
    });

    let filtered_sidebar_items = [];
    MANAGE_SIDEBAR_ITEMS.forEach(function(item){
      if(featureHash[item.featureName] !== false){
        filtered_sidebar_items.push(item);
      }
    });
    return filtered_sidebar_items;
  }
  
  permittedItemsForCompany = (aCompany) => {
    let returnValue = null;
    
    if(aCompany){
      returnValue = this.filter_items(aCompany.hs_features);
    }
    return returnValue;
  };
  
  permissionsForRole = (aRole) => {
    const {studyClosed = false} = this.props;
    let returnValue = [settingsTab];
    
    if(studyClosed){
      returnValue = [reportsTab, auditLogTab, settingsTab];
    }
    else if(aRole === "admin"){
      returnValue = [groupsTab, patientsTab, usersTab, virtualAppointmentsTab, toolsTab, reportsTab, paymentsTab, auditLogTab, campaignTab, schedulesTab, surveyLinksTab, settingsTab, triggerTab];
    }
    else if(aRole === "provider"){
      returnValue = [patientsTab, settingsTab, virtualAppointmentsTab, toolsTab, reportsTab, paymentsTab];
    }
    else if(aRole === "admin_level_1"){
      returnValue = [settingsTab, reportsTab, paymentsTab]
    }
    else if(aRole === "admin_level_2"){
      returnValue = [patientsTab, settingsTab, virtualAppointmentsTab, toolsTab, reportsTab, paymentsTab, schedulesTab, surveyLinksTab];
    }
    else if(aRole === "admin_level_3"){
      returnValue = [patientsTab, settingsTab, virtualAppointmentsTab, toolsTab, reportsTab, paymentsTab, surveyLinksTab];
    }
    else if(aRole === "admin_level_4"){
      returnValue = [patientsTab, settingsTab, groupsTab, usersTab, virtualAppointmentsTab, toolsTab, reportsTab, paymentsTab, auditLogTab, campaignTab, triggerTab, schedulesTab, surveyLinksTab];
    }
    else if(aRole === "admin_level_5"){
      returnValue = [usersTab, settingsTab];
    }
    else if(aRole === "admin_level_6"){
      returnValue = [reportsTab, settingsTab];
    }
    else if(aRole === "admin_level_8"){
      returnValue = [patientsTab, settingsTab];
    }
    else if(aRole === "admin_level_9"){
      returnValue = [settingsTab];
    }
    else if(aRole === "admin_level_10"){
      returnValue = [settingsTab, patientsTab, reportsTab]
    }
    else if(aRole === "admin_level_11"){
      returnValue = [settingsTab, campaignTab];
    }
    else if(aRole === "physician"){
      returnValue = [settingsTab, surveyLinksTab];
    }
    return returnValue;
  };

  isGroupNameActive = (aGroupName) => {
    const groupName = aGroupName.replace(/\s/g, "_");
    
    const groupUrl = "/groups/" + groupName + "/patients";
    return this.showSelected(groupUrl);
  };

  // Render

  render() {
    const groupDashboardPermissions = ["admin_level_1", "admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_9", "admin_level_10"];
    const { sidebarWidth, sidebarExpanded, logout, groups, currentUser, company, studyClosed } = this.props;
    
    let groupsNav = (
      <div>
        <Loading/>
      </div>);
    const sideBarState = sidebarExpanded ? 'expanded' : 'collapsed';
    const currentRole = roleForCompany(company);
    const tabPermissions = this.permissionsForRole(currentRole);
    const permitted_items = this.permittedItemsForCompany(company);

    const manageSideBarItems =
      permitted_items && permitted_items.map((item, index) =>
        <div key={index}>
          {tabPermissions.includes(item.featureName) ?
            <ManageNavItem key={index}
                           path={item.path}
                           title={item.title}
                           active={this.showSelected(`/manage/${item.path}`)}
                           iconComponent={item.iconComponent}
                           sidebarExpanded={sidebarExpanded}
                           manageNavItemClass={SIDEBAR_CLASS[sideBarState].manageNavItemClass}
            />
            :
            null
          }
        </div>);

    if(groups){
      groupsNav = groups.map((group, index) => {
        if(group && group.id && group.name){
          return (
            <li key={group.id} tabIndex="-1" className="group-nav-item" style={{display: 'block', pointerEvents: 'all', height: 'fit-content'}}>
              <div data-tip data-for={`tool-tip-group-${group.id}`} style={{display: 'flex'}}>
                <Link tabIndex="-1" to={"/groups/" + group.name.replaceAll(' ', '_') + "/patients"} style={{height: 'fit-content', width: '100%'}}>
                <div className={classNames("groups-list-item", {
                    'is-active': this.isGroupNameActive(group.name)
                  })}>
                    <div className="circle-container sidebar-circle" style={{alignSelf: (sideBarState === 'expanded') &&'center'}}>
                      <span style={{color: groupColorPicker(group.id)}}>{group.name[0] && group.name[0].toUpperCase()}</span>
                    </div>
                    { sidebarExpanded && <div className="group-name-container sidebar-group-name" style={{marginLeft: SIDEBAR_STYLE[sideBarState].groupNameMargin}}>
                      <span className="name sidebar-group-name">{group.name}</span>
                    </div> }
                    {
                      group.number_of_uncleared_company_notification_receipts && group.number_of_uncleared_company_notification_receipts > 0 ?
                      <AlertIndicator style={{fontSize: '14px', marginRight: '20px', fontWeight: 400}} number={group.number_of_uncleared_company_notification_receipts}/> : null
                    }
                  </div>
                </Link>
              </div>
              <ReactTooltip id={`tool-tip-group-${group.id}`} place='right' type='dark' disable={sidebarExpanded}>
                <span>{group.name}</span><span>My stuff</span>
              </ReactTooltip>
            </li>
          )
        }
        else{
          return null;
        }
      })
    }
    if(company && company.theme && company.theme.logo_url){
      let companyThemeLogoExpiryIndex = company.theme.logo_url.indexOf('X-Amz-Date=');

      if(companyThemeLogoExpiryIndex !== -1){
        companyThemeLogoExpiryIndex += 11;

        let date = company.theme.logo_url.slice(companyThemeLogoExpiryIndex, companyThemeLogoExpiryIndex + 16);
        date = date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 11) + ':' + date.slice(11, 13) + ':' + date.slice(13, 16);

        let dateTime = new Date(date);
        let hours = (Date.now() - dateTime) / 1000 / 60 / 60;

        if(hours > 1){
          this.props.loadCompany();
        }
      }
    }
    return (
      <div className="sidebar"
           id='sidebar'
           style={{minWidth: sidebarWidth, width: sidebarWidth}}>
        <div className="sidebar-scrollable-container">
          <div className="sidebar-top-container">
            {(company && company.theme && company.theme.logo_url) ?
              <div className="sidebar-logo">
                <ProfilePicture className='profile-picture cell'
                                aImageUrl={company.theme.logo_url}
                                width={40}
                                height={40}
                                style={{border: 'none', width: '40px', height: '40px'}}
                                borderStyle={{border: 'none'}}
                />
              </div>
              :
              <div className="sidebar-logo"/>
            }
            <div className="sidebar-user-info">
              {company ?
                <>
                  <b className="sidebar-user-info-text">
                    {company.name || ''}
                  </b>
      
                  <Tooltip title={company.theme && company.theme.headline}
                           aria-label={company.theme && company.theme.headline}>
                    <div className="sidebar-user-info-text">
                      {(company.theme && company.theme.headline) || ''}
                    </div>
                  </Tooltip>
                </>
                :
                null
              }
            </div>
          </div>
        {!studyClosed ?
          <div className='groups-container-style'>
            {groupDashboardPermissions.includes(currentRole) && (
              <>
                {sidebarExpanded ?
                  <div className={`sidebar-header ${SIDEBAR_CLASS[sideBarState].sideBarHeaderClass}`}>
                    <Translation>{ (t, { i18n }) => t('GROUPS') }</Translation>
                  </div>
                  :
                  null
                }
  
                <div className="groups-list-spacing-all groups-list">
                  <span>
                    <Link tabIndex="-1" to={"/groups/patients"}
                          style={{height: 'fit-content', width: '100%'}}>
                      <div className={classNames("groups-list-item", {
                        'is-active': this.showSelected('/groups/patients')
                      })}>
                        <div className="circle-container sidebar-circle"
                             style={{alignSelf: (sideBarState === 'expanded') &&'center'}}>
                          A
                        </div>
                        
                        <div className="group-name-container sidebar-group-name"
                             style={{marginLeft: SIDEBAR_STYLE[sideBarState].groupNameMargin}}>
                          <span className="groups-list-spacing-name">
                            <Translation>{ (t, { i18n }) => t('ALL_PATIENTS') }</Translation>
                          </span>
                        </div>
                        
                        <AlertIndicator style={{fontSize:'14px', marginRight:'20px', fontWeight:400}}
                                        number={0}/> {/*TODO: Add All Patients Count.*/}
                      </div>
                    </Link>
                  </span>
    
                  <div>
                    {groupsNav}
                  </div>
                </div>
              </>
            )
      
            }
          </div> 
          :
          null
        }
  
          <div className='sidebar-manage-container-outer-style'
               style={{flex: '0 0 auto'}}>
            {sidebarExpanded ?
              <div className={`sidebar-header ${SIDEBAR_CLASS[sideBarState].sideBarHeaderClass}`}>
                <Translation>{(t, {i18n}) => t('MANAGE')}</Translation>
              </div>
              :
              null
            }
            
            <div className='manage-container-list-style'>
              {manageSideBarItems}
            </div>
          </div>
        </div>

        <div className="sidebar-bottom-container">
          <ProfilePicture className="sidebar-profile-picture"
                          key={currentUser && currentUser.image_id}
                          imageId={(currentUser && currentUser.image_id)}
                          width={40}
                          height={40}
                          style={{width: '40px'}}
          />
          <div className="sidebar-user-info">
            <b className="sidebar-user-info-text">{currentUser && currentUser.first_name}</b>
            <div className="sidebar-logout" onClick={logout}><Translation>{(t, {i18n}) => t('LOGOUT')}</Translation></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = dispatch => {
  return {
    setPermittedItems:(data) => {
      dispatch(actions.setPermittedItems(data))
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
