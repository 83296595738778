import {Translation} from "react-i18next";

export const AlertTypes = [
  {value: 'consent_alert', text: <Translation>{ (t, { i18n }) => t('CONSENT_ALERT') }</Translation>},
  {value: 'non_compliance', text: <Translation>{ (t, { i18n }) => t('NON_COMPLIANCE') }</Translation>},
  {value: 'adverse_event', text: <Translation>{ (t, { i18n }) => t('ADVERSE_EVENT') }</Translation>},
  {value: 'health_parameter_alert', text: <Translation>{ (t, { i18n }) => t('HEALTH_PARAMETER_ALERT') }</Translation>},
  {value: 'need_primary_phone', text: <Translation>{ (t, { i18n }) => t('NEED_PRIMARY_PHONE') }</Translation>},
  {value: 'need_secondary_phone', text: <Translation>{ (t, { i18n }) => t('NEED_SECONDARY_PHONE') }</Translation>},
  {value: 'appointment_today', text: <Translation>{ (t, { i18n }) => t('APPOINTMENT_TODAY') }</Translation>},
  {value: 'non_specific', text: <Translation>{ (t, { i18n }) => t('NON_SPECIFIC') }</Translation>},
  {value: 'adverse_event_other', text: <Translation>{ (t, { i18n }) => t('ADVERSE_EVENT_OTHER') }</Translation>},
  {value: 'tool_incomplete', text: <Translation>{ (t, { i18n }) => t('TOOL_INCOMPLETE') }</Translation>},
  {value: 'parameter_alert_exceeds_threshold_too_high', text: <Translation>{ (t, { i18n }) => t('PARAMETER_ALERT_TOO_HIGH') }</Translation>},
  {value: 'parameter_alert_exceeds_threshold_too_low', text: <Translation>{ (t, { i18n }) => t('PARAMETER_ALERT_TOO_LOW') }</Translation>},
  {value: 'appointment_ending', text: <Translation>{ (t, { i18n }) => t('APPOINTMENT_ENDING') }</Translation>},
  {value: 'finished_survey', text: <Translation>{ (t, { i18n }) => t('FINISHED_SURVEY') }</Translation>},
  {value: 'hyperwallet_payment_failure', text: <Translation>{ (t, { i18n }) => t('HYPERWALLET_PAYMENT_FAILURE') }</Translation>},
  {value: 'opened_data_query_note', text: <Translation>{ (t, { i18n }) => t('OPENED_DATA_QUERY_NOTE') }</Translation>},
  {value: 'resolved_data_query_note', text: <Translation>{ (t, { i18n }) => t('RESOLVED_DATA_QUERY_NOTE') }</Translation>},
  {value: 'health_care_provider_missing', text: <Translation>{ (t, { i18n }) => t('MISSING_HEALTH_CARE_PROVIDER') }</Translation>},
  {value: 'mrf_alert', text: <Translation>{ (t, { i18n }) => t('MEDICAL_RELEASE_FORM') }</Translation>},
  {value: 'medication_switch', text: <Translation>{ (t, { i18n }) => t('MEDICATION_SWITCH') }</Translation>}
];

export let AlertOptions = [];

AlertTypes.forEach((alert) => {
  AlertOptions.push({value:alert.value, label:alert.text});
});

export const AlertTypeLabelForValue = (aValue) => {
  let returnValue = '';
  
  if(aValue){
    for(let alertType of AlertOptions){
      if(alertType.value === aValue){
        returnValue = alertType.label;
        break;
      }
    }
  }
  return returnValue;
};
