import React from 'react';
import '../AddPatientNoteModal/AddPatientNoteModal.scss'
import "antd/dist/antd.css";
import {Switch} from "antd";
import {Translation} from "react-i18next";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import '../../Custom UI/modalTitleCell.scss';
import GeneralModal from "../GeneralModal/GeneralModal";
import "../GeneralModal/GeneralModal.scss"
import {fullDateTimeFormat, momentFromDate} from "../../Helpers/DateHelpers";
import i18n from "../../i18n";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyIncompleteForm = () => toast(<Translation>{(t, {i18n}) => t('INCOMPLETE_FORM')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyLoadAllergiesFailed = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_FETCHING_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class AssignMedicationsAllergyModal extends React.Component {
  
  constructor(props){
    super(props)
    this.state = {
      allergen:null,
      severity:null,
      medicationId:null,
      goToNextState:false,
      selectedValue:null,
      showAllergyDetails:false,
      medicationAllergySelected:false
    };
    
    if(this.props.medicationAllergy){
      this.state.allergen = this.props.medicationAllergy.name;
      this.state.severity = this.props.medicationAllergy.severity;
      this.state.medicationId = this.props.medicationAllergy.medication_id;
      this.state.goToNextState = true;
      this.state.selectedValue = this.props.medicationAllergy;
      this.state.showAllergyDetails = true;
      this.state.medicationAllergySelected = true;
    }
    this.showDiv = this.showDiv.bind(this);
    this.filterAlertOptions = this.filterAlertOptions.bind(this);
    this.assignPatientMedicationAllergy = this.assignPatientMedicationAllergy.bind(this);
  }
  
  // Methods
  
  handleSave(){
    if(this.state.medicationAllergySelected){
      this.assignPatientMedicationAllergy();
    }
    else{
      notifyIncompleteForm();
    }
  }
  
  showDiv() {
    if(this.state.showAllergyDetails === true){
      this.setState({showAllergyDetails:false});
    }
    else{
      this.setState({showAllergyDetails:true});
    }
  }
  
  assignPatientMedicationAllergy(){
    const {medicationId, severity, allergen} = this.state;
    const {assignMedicationAllergyCallback, medicationAllergy} = this.props;
    assignMedicationAllergyCallback(allergen, severity, medicationId, medicationAllergy && medicationAllergy.id);
  }
  
  filterAlertOptions(aOption){
    return aOption.value === this.state.severity;
  }
  
  // Render
  
  render() {
    let {goToNextState, showAllergyDetails, severity, selectedValue} = this.state;
    let {isOpen, handleCloseModal, createdAt, medicationAllergy, patient, fetchMedicationAllergies} = this.props;
    
    let severityList = [
      {value:"mild", label:"Mild"},
      {value:"moderate", label:"Moderate"},
      {value:"severe", label:"Severe"}
    ];
    
    return (
      <GeneralModal title={<Translation>{(t, {i18n}) => t('MEDICATION_ALLERGIES')}</Translation>}
                    isOpen={isOpen}
                    maxHeight={700}
                    isEditing={medicationAllergy}
                    handleSave={() => this.handleSave()}
                    showSaveButton={goToNextState}
                    containsDropDown={true}
                    handleCloseModal={handleCloseModal}
      >
        <div className="modal-subtitle-text">
          <Translation>{(t, {i18n}) => t('MEDICATION_ALLERGIES_QUESTION')}</Translation>
        </div>
        
        <div className="modal-switch-container">
          <Switch defaultChecked={!!medicationAllergy}
                  onClick={() => this.showDiv()}
                  checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                  unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
          />
        </div>
        
        {showAllergyDetails === true ?
          <div>
            <LabeledDropDown className="modal-input"
                             label={i18n.t('MEDICATION_ALLERGIES')}
                             value={selectedValue}
                             handleSave={(aOption) => {this.setState({allergen:aOption.name, medicationId:aOption.id, medicationAllergySelected:true, selectedValue:aOption})}}
                             fetchObjects={(aLimit, aOffset, aSearchString) => fetchMedicationAllergies(aLimit, aOffset, aSearchString, patient.id)}
                             notifyFetchFailed={notifyLoadAllergiesFailed}
                             labelPropertiesArray={['name']}
                             valuePropertiesArray={['id']}
                             responseTotalKeysArray={['data', 'data', 'medication_allergies_page', 'total']}
                             responseObjectKeysArray={['data', 'data', 'medication_allergies_page', 'medication_allergies']}
            />
            
            <LabeledDropDown className="modal-input"
                             label={i18n.t('SEVERITY')}
                             value={(medicationAllergy || severity !== '') && severityList.filter((aOption) => this.filterAlertOptions(aOption))}
                             options={severityList}
                             handleSave={(aOption) => {
                               this.setState({severity:aOption.value, goToNextState:true});
                             }}
            />
          </div>
          :
          null
        }
        {createdAt ?
          <span className="user-prompt-msg">
                  <Translation>{(t, {i18n}) => t('CREATED')}</Translation> - {momentFromDate(createdAt, fullDateTimeFormat)}
          </span>
          :
          null
        }
      
      </GeneralModal>
    )
  }
}
