import React from 'react';
import './CallNotesList.scss';
import i18next from 'i18next';
import {toast} from "react-toastify";
import DynamicList from "../DynamicList/DynamicList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import CallSummaryModal from "../../Modals/CallSummaryModal/CallSummaryModal";
import {roleForCompany} from '../../Helpers/CompanyHelpers';
import {fullDateTimeFormat} from "../../Helpers/DateHelpers";
import CallNoteHistoriesModal from "../../Modals/CallNoteHistoriesModal/CallNoteHistoriesModal";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {typesOfCallsOptionsArray, allDispositionCodesOptionsArray} from "../../constants/CallNoteTypes";

const notifyAddCallNoteSuccess = () =>  toast(i18next.t('ADD_CALL_SUMMARY'), NOTIFY_OPTS.autoClose);
const notifyAddCallNoteFailure = (aError) =>  toast(i18next.t('ADD_CALL_SUMMARY_ERROR', {aError:aError}), NOTIFY_OPTS.autoClose);

export default class CallNotesList extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      shouldReload:false,
      selectedCallNote:null,
      callHistoryIsOpen:false,
      callSummaryIsOpen:false
    };
    this.addCallNote = this.addCallNote.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {company} = this.props;
    
    if(!prevProps.company || !company || prevProps.company.id !== company.id){
      this.setState({shouldReload:true});
    }
  }
  
  // Methods
  
  addCallNote(aCallNote){
    const {addCallNote} = this.props;
    
    if(aCallNote && addCallNote){
      this.setState({callSummaryIsOpen:false});
      addCallNote(aCallNote).then((newResult) => {
        if(newResult && newResult.data && newResult.data.error){
          notifyAddCallNoteFailure(errorMessageFromServerError(newResult.data.error));
        }
        else{
          notifyAddCallNoteSuccess();
          this.setState({shouldReload:true});
        }
      }, (newError) => {
        notifyAddCallNoteFailure(errorMessageFromServerError(newError));
      });
    }
  }
  
  // Render
  
  render() {
    const {user, company, patient, className, getCallNotes, explanationKey, getCallNoteHistories} = this.props;
    const {shouldReload, selectedCallNote, callHistoryIsOpen, callSummaryIsOpen} = this.state;
    
    let columnsArray = [];
    columnsArray.push({key:'caller', columnWeight:1, columnNameKey:'CALLER', propertiesArray:['caller']});
    columnsArray.push({key:'type_of_visit', columnWeight:1, columnNameKey:'TYPE_OF_VISIT', propertiesArray:['type_of_visit'],
      valueFormatter:(aValue) => {
        let returnValue = '';
        
        for(let option of typesOfCallsOptionsArray()){
          if(option.value === aValue){
            returnValue = option.label;
            break;
          }
        }
        return returnValue;
      }});
    columnsArray.push({key:'start_time', columnWeight:1, columnNameKey:'CALLER_DATE_AND_TIME', propertiesArray:['start_time'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'CALL_NOTE_CREATED_ON', propertiesArray:['created_at'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'updated_at', columnWeight:1, columnNameKey:'CALL_NOTE_UPDATED_ON', propertiesArray:['updated_at'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'call_duration', columnWeight:1, columnNameKey:'CALL_DURATION_H', propertiesArray:['call_duration']});
    columnsArray.push({key:'purpose_of_visit', columnWeight:1, columnNameKey:'PURPOSE_OF_VISIT', propertiesArray:['purpose_of_visit']});
    columnsArray.push({key:'visit_disposition', columnWeight:1, columnNameKey:'VISIT_DISPOSITION', propertiesArray:['visit_disposition'],
      valueFormatter:(aValue) => {
        let returnValue = '';
        
        for(let option of allDispositionCodesOptionsArray()){
          if(option.value === aValue){
            returnValue = option.label;
            break;
          }
        }
        return returnValue;
      }});
    columnsArray.push({key:'survey_name', columnWeight:1, columnNameKey:'SURVEY_NAME', propertiesArray:['survey_name']});
    columnsArray.push({key:'note_text', columnWeight:2, canShowMore:true, columnNameKey:'EXTRA_NOTE', propertiesArray:['note_text'],
      valueFormatter:(aNote) => {return aNote}});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'HISTORY', clickAction:(aCallNote) => this.setState({selectedCallNote:aCallNote, callHistoryIsOpen:true})});
    menuItemsArray.push({title:'EDIT', clickAction:(aCallNote) => this.setState({selectedCallNote:aCallNote, callSummaryIsOpen:true})});
    
    return (
      ['provider', 'admin', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_10'].includes(roleForCompany(company)) ?
        <div className={'call-notes-list-outer-scroll-container ' + className}
             id="call-notes-list">
          <DynamicList id="call-notes-list"
                       didReload={() => this.setState({shouldReload:false})}
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset) => getCallNotes(patient.id, aLimit, aOffset)}
                       shouldReload={shouldReload}
                       showSearchBar={false}
                       explanationKey={explanationKey}
                       menuItemsArray={menuItemsArray}
                       minColumnWidth={80}
                       totalObjectsKey='CALL_NOTE_HISTORY'
                       addButtonClicked={() => this.setState({callSummaryIsOpen:true})}
                       responseTotalKeysArray={['data', 'data', 'twilio_call_note_page', 'total']}
                       responseObjectKeysArray={['data', 'data', 'twilio_call_note_page', 'call_notes']}
          />
          
          {callSummaryIsOpen ?
            <CallSummaryModal user={user}
                              isOpen={callSummaryIsOpen}
                              company={company}
                              patient={patient}
                              callNote={selectedCallNote}
                              editable={true}
                              canCancel={true}
                              closeModal={() => this.setState({selectedCallNote:null, callSummaryIsOpen:false})}
                              addCallNote={this.addCallNote}
            />
            :
            null
          }
          
          <CallNoteHistoriesModal open={callHistoryIsOpen}
                                  close={() => this.setState({selectedCallNote:null, callHistoryIsOpen:false})}
                                  company={company}
                                  callNote={selectedCallNote}
                                  getCallNoteHistories={getCallNoteHistories}
          />
        </div>
        :
        <div className="call-notes-list-blocked">
          {i18next.t('CALL_NOTE_HISTORY_BLOCKED')}
        </div>
    )
  }
}
