import React from 'react';
import "antd/dist/antd.css";
import './Trigger.scss';
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {questsArrayForCompany} from "../../Helpers/ToolHelpers";
import {TriggerTypes} from "../../constants/TriggerTypes";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

const notifyTriggerCreateError = (aError) => toast(<Translation>{ (t) => t('ERROR_CREATING_TRIGGER', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyTriggerUpdateError = (aError) => toast(<Translation>{ (t) => t('ERROR_UPDATING_TRIGGER', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyTriggerCreatedSuccess = () => toast(<Translation>{ (t) => t('TRIGGER_CREATED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyTriggerUpdatedSuccess = (aName) => toast(<Translation>{ (t) => t('TRIGGER_UPDATED', {name:aName}) }</Translation>, NOTIFY_OPTS.autoClose);

export default class Trigger extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      type:null,
      saving:false,
      keyword:'',
      targetId:null,
      alertType:null,
      endOffset:null,
      hourOfDay:null,
      scoreName:null,
      isReminder:false,
      targetType:'Trigger::QuestUserJoinTrigger',
      startOffset:null,
      isRepeatable:false,
      maximumValue:null,
      minimumValue:null,
      endOffsetUnit:'day',
      startOffsetUnit:'day',
      userCountryName:null,
      maximumHourOfDay:null,
      secondaryTargetId:null,
      secondaryTargetType:null,
      sendToCompanyAdmins:false,
      additionalAppColumnName:null,
      minimumDurationBetweenEvents:null,
      surveyConsentQuestionKeyword:null,
      minimumDurationBetweenEventsUnit:null,
      notificationType:'push_notification'
    };
    if(props.trigger){
      this.state.type = props.trigger.type;
      this.state.keyword = props.trigger.keyword;
      this.state.startOffset = props.trigger.start_offset;
      this.state.endOffset = props.trigger.end_offset;
      this.state.startOffsetUnit = props.trigger.start_offset_unit;
      this.state.endOffsetUnit = props.trigger.endOffsetUnit;
      this.state.notificationType = props.trigger.notification_type;
      this.state.sendToCompanyAdmins = props.trigger.send_to_company_admins ? 'true' : 'false';
      this.state.targetId = props.trigger.target_id;
    }
    this.handleCreateTrigger = this.handleCreateTrigger.bind(this);
    this.handleUpdateTrigger = this.handleUpdateTrigger.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  };
  
  componentDidMount(){
    this.loadQuestsArray();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.company !== this.props.company || (this.props.company && this.state.questsArray.length === 0)){
      this.loadQuestsArray();
    }
    if(!prevProps.updateButtonPressed && this.props.updateButtonPressed && !this.state.saving){
      this.handleUpdateTrigger();
    }
  }
  
  // Methods
  
  reset(){
    this.setState({
      type:null,
      saving:false,
      keyword:'',
      targetId:'',
      alertType:null,
      endOffset:null,
      hourOfDay:null,
      scoreName:null,
      isReminder:false,
      targetType:'Trigger::QuestUserJoinTrigger',
      startOffset:null,
      isRepeatable:false,
      maximumValue:null,
      minimumValue:null,
      endOffsetUnit:'day',
      startOffsetUnit:'day',
      userCountryName:null,
      maximumHourOfDay:null,
      secondaryTargetId:null,
      secondaryTargetType:null,
      sendToCompanyAdmins:false,
      additionalAppColumnName:'',
      minimumDurationBetweenEvents:'',
      surveyConsentQuestionKeyword:'',
      minimumDurationBetweenEventsUnit:'',
      notificationType:'push_notification'
    });
  }
  
  loadQuestsArray(){
    this.setState({questsArray:questsArrayForCompany(this.props.company)});
  }
  
  handleTargetChange = (aOption) => {
    let selectedQuest = this.state.questsArray.filter((quest) => (quest.survey ? quest.survey.id === aOption.value : quest.id === aOption.value))[0];
    
    if(selectedQuest){
      if(selectedQuest.survey && selectedQuest.survey.id){
        let targetType = 'Survey';
        this.setState({targetId:selectedQuest.survey.id, targetType:targetType});
      }
      else if(selectedQuest.quest_type === 'Tool' || selectedQuest.quest_type === 'Consent' || selectedQuest.quest_type === 'Survey'){
        let targetType = 'Quest';
        this.setState({targetId:selectedQuest.id, targetType:targetType});
      }
    }
  };

  handleSelectChange(value, key) {
    this.setState({ key: value });
  }
  
  handleSecondaryTargetChange = (aOption) => {
    let selectedQuest = this.state.questsArray.filter((quest) => (quest.survey ? quest.survey.id === aOption.value : quest.id === aOption.value))[0];
    
    if(selectedQuest){
      if(selectedQuest.survey && selectedQuest.survey.id){
        let targetType = 'Survey';
        this.setState({secondaryTargetId:selectedQuest.survey.id, secondaryTargetType:targetType});
      }
      else if(selectedQuest.quest_type === 'Tool' || selectedQuest.quest_type === 'Consent' || selectedQuest.quest_type === 'Survey'){
        let targetType = 'Quest';
        this.setState({secondaryTargetId:selectedQuest.id, secondaryTargetType:targetType});
      }
    }
  };
  
  formatDate = (aDate) => {
    let returnValue = '';
  
    if(aDate){
      returnValue = aDate.toString();
    }
    return returnValue;
  };
  
  handleCreateTrigger(){
    this.setState({saving:true}, () => {
      const { type, keyword, targetId, alertType, endOffset, hourOfDay, scoreName, isReminder, startOffset, isRepeatable,
        maximumValue, minimumValue, endOffsetUnit, startOffsetUnit, userCountryName, maximumHourOfDay, secondaryTargetId,
        sendToCompanyAdmins, additionalAppColumnName, minimumDurationBetweenEvents, surveyConsentQuestionKeyword,
        minimumDurationBetweenEventsUnit, notificationType } = this.state;
      
      this.props.createTrigger(type, isRepeatable, isReminder, sendToCompanyAdmins, startOffset, startOffsetUnit, endOffset,
        endOffsetUnit, minimumDurationBetweenEvents, minimumDurationBetweenEventsUnit, hourOfDay, maximumHourOfDay, minimumValue,
        maximumValue, keyword, scoreName, userCountryName, alertType, additionalAppColumnName, surveyConsentQuestionKeyword,
        targetId, secondaryTargetId, notificationType).then(() => {
        notifyTriggerCreatedSuccess();
        this.reset();
      }, newError => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyTriggerCreateError(error);
        this.setState({saving: false});
      });
    });
  }
  
  handleUpdateTrigger(){
    this.setState({saving:true}, () => {
      const { type, keyword, targetId, alertType, endOffset, hourOfDay, scoreName, isReminder, startOffset, isRepeatable,
        maximumValue, minimumValue, endOffsetUnit, startOffsetUnit, userCountryName, maximumHourOfDay, secondaryTargetId,
        sendToCompanyAdmins, additionalAppColumnName, minimumDurationBetweenEvents, surveyConsentQuestionKeyword,
        minimumDurationBetweenEventsUnit, notificationType } = this.state;
      
      this.props.updateTrigger(this.props.trigger.id, type, isRepeatable, isReminder, sendToCompanyAdmins, startOffset,
        startOffsetUnit, endOffset, endOffsetUnit, minimumDurationBetweenEvents, minimumDurationBetweenEventsUnit, hourOfDay,
        maximumHourOfDay, minimumValue, maximumValue, keyword, scoreName, userCountryName, alertType, additionalAppColumnName,
        surveyConsentQuestionKeyword, targetId, secondaryTargetId, notificationType).then(() => {
        notifyTriggerUpdatedSuccess(this.props.trigger.id);
        this.reset();
        
        if(this.props.updateFinished){
          this.props.updateFinished();
        }
      }, newError => {
        let error = '';
        
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyTriggerUpdateError(error);
        this.setState({saving: false});
        
        if(this.props.updateFailed){
          this.props.updateFailed();
        }
      });
    });
  }
  
  // Render
  
  render() {
    const { type, saving,keyword, targetId, endOffset, startOffset,
      questsArray, endOffsetUnit, startOffsetUnit, sendToCompanyAdmins, notificationType } = this.state;
    const { company, className, hideCreateButton } = this.props;
    const triggerPermissions = ["admin", "admin_level_4"];
    
    let notificationTypes = [
      {value: "email", label: "Email"},
      {value: "push_notification", label: "Push Notification"},
      {value: "all", label: "Push & Email"}
    ];

    let availableOffsetUnits = [
      { value: "day", label: "day"},
      { value: "hour", label: "hour"},
      { value: "minute", label: "minute"},
      { value: "second", label: "second"}
    ]

    let sendToAdmins = [
      { value: "false", label: "false"},
      { value: "true", label: "true"}
    ]
    
    let questOptionsArray = [];
    
    if(questsArray){
      for(let quest of questsArray){
        // if(quest.survey){
        //   questOptionsArray.push({value:quest.survey.id, label:quest.title + ' - ' + quest.id});
        // }
        // else{
          questOptionsArray.push({value:quest.id, label:quest.title + ' - ' + quest.id});
        // }
      }
    }
    let currentRole = roleForCompany(company);
    
    return (
      <div className={'trigger-container ' + className ? className : ''}>
        {triggerPermissions.includes(currentRole) ?
          <div className="trigger-container-inputs">
            {/*TODO: Uncomment when all fields are editable.*/}
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('TYPE')}</Translation>}
                             value={type && TriggerTypes.filter(function(option){
                               return option.value === type;
                             })}
                             disabled={this.props.trigger}
                             options={TriggerTypes}
                             handleSave={(aOption) => this.setState({type:aOption.value})}
            />
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('ID')}</Translation>}
                             value={targetId && questOptionsArray.filter(function(option) {
                               return option.value === targetId;
                             })}
                             disabled={this.props.trigger}
                             options={questOptionsArray}
                             handleSave={(value) => this.setState({targetId: value.value})}
            />
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('NOTIFICATION_TYPE')}</Translation>}
                             value={notificationType && notificationTypes.filter(function(option) {
                               return option.value === notificationType;
                             })}
                             options={notificationTypes}
                             handleSave={(value) => this.setState({notificationType: value.value})}
            />
            <LabeledTextInput className="trigger-input-small"
                              value={keyword}
                              disabled={this.props.trigger}
                              label={<Translation>{(t) => t('KEYWORD')}</Translation>}
                              placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                              handleSave={(aEvent) => this.setState({keyword:aEvent.target.value})}
            />
  
            <LabeledTextInput className="trigger-input-small"
                              type={'number'}
                              label={<Translation>{(t) => t('START_OFFSET')}</Translation>}
                              value={startOffset}
                              minValue={'0'}
                              handleSave={(aEvent) => this.setState({startOffset:aEvent.target.value})}
                              placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
            />
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('START_OFFSET_UNIT')}</Translation>}
                             value={startOffsetUnit && availableOffsetUnits.filter(function(option) {
                               return option.value === startOffsetUnit;
                             })}
                             options={availableOffsetUnits}
                             handleSave={(value) => this.setState({startOffsetUnit: value.value})}
            />
  
            <LabeledTextInput className="trigger-input-small"
                              type={'number'}
                              label={<Translation>{(t) => t('END_OFFSET')}</Translation>}
                              value={endOffset}
                              minValue={'0'}
                              handleSave={(aEvent) => this.setState({endOffset:aEvent.target.value})}
                              placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
            />
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('END_OFFSET_UNIT')}</Translation>}
                             value={endOffsetUnit && availableOffsetUnits.filter(function(option) {
                               return option.value === endOffsetUnit;
                             })}
                             options={availableOffsetUnits}
                             handleSave={(value) => this.setState({endOffsetUnit: value.value})}
            />
  
            <LabeledDropDown className="trigger-input-small"
                             label={<Translation>{(t) => t('ADMIN_ALERT')}</Translation>}
                             value={sendToCompanyAdmins && sendToAdmins.filter(function(option) {
                               return option.value === sendToCompanyAdmins;
                             })}
                             disabled={this.props.trigger}
                             options={sendToAdmins}
                             handleSave={(value) => this.setState({sendToCompanyAdmins: value.value})}
            />
  
            {/* <LabeledDropDown className="trigger-input-small"
                               label={<Translation>{ (t, { i18n }) => t('ALERT_TYPE_COLON')}</Translation>}
                               value={alertType && AlertOptions.filter(function(option){
                                 return option.value === alertType;
                               })}
                               options={AlertOptions}
                               handleSave={(aOption) => this.setState({alertType:aOption.value})}
              /> */}
              
            

              {/* <LabeledTextInput className="trigger-input-small"
                                label={<Translation>{ (t, { i18n }) => t('SURVEY_CONSENT_KEYWORD')}</Translation>}
                                value={surveyConsentQuestionKeyword}
                                handleSave={(aEvent) => this.setState({surveyConsentQuestionKeyword:aEvent.target.value})}
                                placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
              /> */}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <LabeledDropDown className="trigger-input-small"*/}
            {/*                   label={<Translation>{ (t, { i18n }) => t('PRIMARY_TARGET_PROPER_CAPITALIZED')}</Translation>}*/}
            {/*                   value={targetId && questOptionsArray.filter(option => {*/}
            {/*                     return option.value === targetId*/}
            {/*                   })}*/}
            {/*                   options={questOptionsArray}*/}
            {/*                   handleSave={this.handleTargetChange}*/}
            {/*  />*/}
            
            {/*  <LabeledDropDown className="trigger-input-small"*/}
            {/*                   label={<Translation>{ (t, { i18n }) => t('SECONDARY_TARGET')}</Translation>}*/}
            {/*                   value={secondaryTargetId && questOptionsArray.filter(option => {*/}
            {/*                     return option.value === secondaryTargetId*/}
            {/*                   })}*/}
            {/*                   options={questOptionsArray}*/}
            {/*                   handleSave={this.handleSecondaryTargetChange}*/}
            {/*  />*/}
            {/*</div>*/}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('HOUR_OF_DAY')}</Translation>}*/}
            {/*                    value={hourOfDay}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({hourOfDay:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('MAX_HOUR_DAY')}</Translation>}*/}
            {/*                    value={maximumHourOfDay}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({maxHourDay:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            {/*</div>*/}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('MIN_VALUE_PROPER_CAPITALIZED')}</Translation>}*/}
            {/*                    value={minimumValue}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({minValue:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('MAX_VALUE_PROPER_CAPITALIZED')}</Translation>}*/}
            {/*                    value={maximumValue}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({maxValue:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            {/*</div>*/}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('DURATION_BETWEEN_EVENTS')}</Translation>}*/}
            {/*                    value={minimumDurationBetweenEvents}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({durationBetweenEvents:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('DURATION_BETWEEN_EVENTS_UNIT')}</Translation>}*/}
            {/*                    value={minimumDurationBetweenEventsUnit}*/}
            {/*                    handleSave={(aEvent) => this.setState({minimumDurationBetweenEventsUnit:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            {/*</div>*/}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <div className="trigger-input-cell">*/}
            {/*    <div className="trigger-input-title">*/}
            {/*      <Translation>{ (t, { i18n }) => t('REMINDER_COLON')}</Translation>*/}
            {/*    </div>*/}
            {/*    <Switch defaultChecked={false}*/}
            {/*            className="trigger-blue-switch"*/}
            {/*            checked={isReminder}*/}
            {/*            onChange={(isChecked) => this.setState({isReminder:isChecked})}*/}
            {/*            checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}*/}
            {/*            unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}*/}
            {/*    />*/}
            {/*  </div>*/}
            
            {/*  <div className="trigger-input-cell">*/}
            {/*    <div className="trigger-input-title">*/}
            {/*      <Translation>{ (t, { i18n }) => t('REPEATABLE')}</Translation>*/}
            {/*    </div>*/}
            {/*    <Switch defaultChecked={false}*/}
            {/*            className="trigger-blue-switch"*/}
            {/*            checked={isRepeatable}*/}
            {/*            onChange={(isChecked) => this.setState({isRepeatable:isChecked})}*/}
            {/*            checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}*/}
            {/*            unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}*/}
            {/*    />*/}
            {/*  </div>*/}
            
            {/*</div>*/}
            
            {/*<div className="trigger-dual-input-container">*/}
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    type={'number'}*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('SCORE')}</Translation>}*/}
            {/*                    value={scoreName}*/}
            {/*                    minValue={'0'}*/}
            {/*                    handleSave={(aEvent) => this.setState({scoreName:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('ADDITIONAL_APP_COLUMN_NAME')}</Translation>}*/}
            {/*                    value={additionalAppColumnName}*/}
            {/*                    handleSave={(aEvent) => this.setState({additionalAppColumnName:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            
            {/*  <LabeledTextInput className="trigger-input-small"*/}
            {/*                    label={<Translation>{ (t, { i18n }) => t('USER_COUNTRY_NAME')}</Translation>}*/}
            {/*                    value={userCountryName}*/}
            {/*                    handleSave={(aEvent) => this.setState({userCountryName:aEvent.target.value})}*/}
            {/*                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}*/}
            {/*  />*/}
            {/*</div>*/}
            
            {!hideCreateButton ?
              <div className="trigger-button-container">
                <BlueButton className="trigger-blue-button"
                            name={<Translation>{(t) => t('CREATE_PROPER_CAPITALIZED')}</Translation>}
                            loading={saving}
                            onClick={() => this.handleCreateTrigger()}
                />
              </div>
              :
              null
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t) => t('PAYMENT_TRIGGERS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
