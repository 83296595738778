import React from "react";
import './SurveyBuilderQuestionImportant.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";

export default class SurveyBuilderQuestionImportant extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {disabled, question, updateQuestion, selectedLanguage, errorForIdTypeAndKey} = this.props;
  
    const labelKey = KeyForTranslation('label', selectedLanguage);
    
    return(
      <div className="survey-builder-question-important">
        <LabeledSwitch className="survey-builder-question-important-switch"
                       label={i18next.t('IMPORTANT')}
                       checked={question && Boolean(question.important)}
                       disabled={disabled}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
          
                         if(isChecked){
                           if(!updatedQuestion.important){
                             updatedQuestion.important = {};
                           }
                         }
                         else{
                           delete updatedQuestion['important'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={i18next.t('IMPORTANT_EXPLANATION')}
                       popoverPlacement={'left'}
        />
        
        {question && question.important ?
          <LabeledDelayedInput className="survey-builder-question-important-input"
                               label={i18next.t('LABEL') + ' - ' + selectedLanguage.title}
                               value={question.important[labelKey] && question.important[labelKey].length > 0 ? question.important[labelKey] : ''}
                               infoBlurb={i18next.t('IMPORTANT_LABEL_EXPLANATION')}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 updatedQuestion.important[labelKey] = aEvent.target.value;
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'important_' + labelKey).message}
                               warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'important_' + labelKey).message}
                               popoverPlacement={'left'}
          />
          :
          null
        }
      </div>
    )
  }
}
