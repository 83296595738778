import EventTypes from "../constants/EventTypes";
import EventTracker from "../eventTracker";

export function addCallNote(aNote){
  if(aNote && aNote.id){
    EventTracker.track(EventTypes.UPDATE_CALL_NOTE);
  }
  else{
    EventTracker.track(EventTypes.CREATE_CALL_NOTE);
  }
  let query = `
    mutation SaveCallNoteMutation ($saveCallNoteMutationInput: SaveCallNoteMutationInput!) {
      saveCallNoteMutation (input: $saveCallNoteMutationInput){
        call_note {
          id
          caller
          attendees
          note_text
          created_at
          start_time
          updated_at
          survey_name
          call_duration
          type_of_visit
          twilio_call_sid
          purpose_of_visit
          attendee_timezone
          visit_disposition
        }
      }
    }
  `;
  let params = aNote;
  let operationName = `SaveCallNoteMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    
    let variables = {saveCallNoteMutationInput:params};
    return global.api.post("/queries", {query, variables, operationName});
  };
}
//participant_id
export function getCallNotes(aUserId, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_CALL_NOTES);
  let query = `
    query ($user_id: ID,  $offset: Int, $limit: Int){
      twilio_call_note_page(user_id: $user_id, offset: $offset, limit: $limit ){
       call_notes{
         id
         caller
         attendees
         note_text
         created_at
         start_time
         updated_at
         survey_name
         call_duration
         type_of_visit
         twilio_call_sid
         purpose_of_visit
         attendee_timezone
         visit_disposition
       }
       total
     }
   }
   `;
  
  let variables = {
    limit:aLimit,
    offset:aOffset,
    user_id:aUserId
  };
  
  return (dispatch) => {
    return global.api.post("/queries", {query, variables});
  };
}

export function getCallNoteHistories(aCallNoteId, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_HISTORIES_FOR_CALL_NOTE);
  let query = `
    query getTelehealthCallNoteHistories($company_id: ID!, $call_note_id: ID!, $limit: Int, $offset: Int) {
      telehealth_call_note_histories(company_id: $company_id, call_note_id: $call_note_id, limit: $limit, offset: $offset) {
        admin_histories{
          id
          call_note{
            id
            caller
            attendees
            note_text
            created_at
            start_time
            updated_at
            survey_name
            call_duration
            type_of_visit
            signed_room_id
            twilio_call_sid
            purpose_of_visit
            attendee_timezone
            visit_disposition
            signed_participant_id
          }
          change_list {
            key
            new_value
            old_value
          }
          edited_by {
            first_name
            last_name
            id
          }
        }
        total
      }
    }
   `;
  
  let variables = {
    limit:aLimit,
    offset:aOffset,
    call_note_id:aCallNoteId
  };
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", {query, variables})
  };
}
