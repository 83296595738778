import React from 'react';
import './CallNoteHistoriesModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import CallNoteHistoriesList from "../../Lists/CallNoteHistoriesList/CallNoteHistoriesList";

export default function CallNotesModal({open, close, company, callNote, getCallNoteHistories}){
  
  // Render
  
  return (
    <GeneralModal title={i18next.t('CALL_NOTE_HISTORY')}
                  width={'1000px'}
                  height={'700px'}
                  isOpen={open}
                  maxWidth={'1000px'}
                  maxHeight={'1000px'}
                  showFooter={false}
                  showHeader={true}
                  noTitleHeading={true}
                  showSaveButton={false}
                  handleCloseModal={() => close()}
                  showCancelButton={false}>
      <CallNoteHistoriesList className="call-note-histories-modal-call-notes-list"
                             company={company}
                             callNote={callNote}
                             getCallNoteHistories={getCallNoteHistories}
      />
    </GeneralModal>
  );
};
