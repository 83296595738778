import React from 'react';
import '../../Pages/GroupSummary/GroupSummary.scss';
import i18n from '../../i18n'
import Menu from "@material-ui/core/Menu";
import {find} from 'lodash'
import {toast} from "react-toastify";
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import MenuItem from "@material-ui/core/MenuItem";
import LoadFailed from "../../Custom UI/LoadFailed/LoadFailed";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {getSectionTitle} from 'utils/Titles'
import AssignSymptomsModal from "../../Modals/AssignSymptomsModal/AssignSymptomsModal";
import PatientInfoListItem from '../../Cells/PatientInfoCell/PatientInfoCell'
import PanelSubSectionHeader from "../../Custom UI/Headers/PanelSubSectionHeader/PanelSubSectionHeader";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {Translation, withTranslation} from "react-i18next";
import PanelSubSectionHeadingWithButton from '../PatientProfileComponents/PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton';

const notifyError = (message) => toast(message, NOTIFY_OPTS.autoCloseFiveSeconds)
const notifySymptomRemovedSuccess = () => toast(<Translation>{(t, { i18n }) => t('REMOVE_SYMPTOM_SUCCESS')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

class Symptoms extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      currentGroup:null,
      groupSymptoms:[],
      assignSymptoms:false,
      menuAnchorElement:null,
      assignSymptomsError:'',
      selectedPatientListItem:null
    };
    this.removeSymptom = this.removeSymptom.bind(this);
  }
  
  componentDidMount(){
    this.loadSymptoms();
    this.updateCurrentGroup();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.company && this.props.company && prevProps.company.id !== this.props.company.id){
      this.loadSymptoms();
    }
    if(prevProps?.group?.id !== this.props?.group?.id){
      this.updateCurrentGroup();
    }
  }
  
  // Methods

  updateCurrentGroup = () => {
    const {group, company} = this.props;
    let currentGroup = company?.core_group;

    if(group && company && group?.id !== company?.core_group?.id){
      currentGroup = company.groups.find((aGroup) => 
        aGroup.id === group.id
      );
    }
    let groupSymptoms = currentGroup?.symptoms || [];
    this.setState({currentGroup , groupSymptoms})
  }
  
  loadSymptoms(){
    const {loadCompanySymptoms} = this.props;
    
    if(loadCompanySymptoms){
      this.setState({loading:true});
      loadCompanySymptoms().then((newResult) => {
        setTimeout(() => this.updateCurrentGroup(), 100);
        this.setState({loading:false, loadFailed:false});
      }, (newError) => {
        this.setState({loading:false, loadFailed:true});
      });
    }
  }
  
  removeSymptom(){
    const {selectedPatientListItem , groupSymptoms } = this.state;
    const itemIndex = groupSymptoms.findIndex((item) => item.id === selectedPatientListItem)

    if(itemIndex === -1){
      return;
    }
    groupSymptoms.splice(itemIndex,1);
    
    this.setState({menuAnchorElement:null, selectedPatientListItem:null});
    this.props.removeSymptomFromGroup(selectedPatientListItem).then((newResult) => {
      notifySymptomRemovedSuccess();
    }, (newError) => {
      notifyError(newError.data.error);
    });
  }
  
  assignGroupSymptom = ({selectedValue, extraNotes, customSymptom}) => {
    const {group, addSymptomToGroup} = this.props;
    
    if(group && addSymptomToGroup){
      this.setState({assignSymptomsError:''});
      addSymptomToGroup({symptomId:!customSymptom && selectedValue, groupId:group.id, extraNotes:extraNotes, customSymptom:customSymptom}).then((newResult) => {
        if(newResult && newResult.error){
          this.setState({assignSymptomsError:newResult.error});
        }
        else{
          this.loadSymptoms()
          this.setState({assignSymptoms:false});
        }
      }, (newError) => {
        this.setState({assignSymptoms:false, assignSymptomsError:errorMessageFromServerError(newError)});
      });
    }
  };
  
  // Render
  
  render() {
    const {group, company, groupColor, removeSymptomFromGroup, t, fetchSymptoms} = this.props;
    const {loading, loadFailed, currentGroup, groupSymptoms, assignSymptoms, menuAnchorElement, assignSymptomsError} = this.state;
    
    const groupSymptomsString = t('GROUP_SYMPTOMS', {i18n});
    const assignedSymptomsPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_10'];
    const addPermissions = ['admin', 'admin_level_4'];
    
    const groupSymptomsRef = currentGroup?.group_symptoms || [];
    const currentRole = roleForCompany(company);
    
    // TODO: Rewrite this, as it can just be added directly inside the return.
    let groupSymptomsList = (<div/>);
    
    if(groupSymptoms && groupSymptoms.length > 0){
      groupSymptomsList = groupSymptoms.map((symptom, index) => {
        let groupSymptomRef = find(groupSymptomsRef, (gs) => gs.symptom_id === symptom.id);
        return (
          <span style={{display:'flex', width:'100%'}}
                key={'patient-info-list-item-container-' + symptom.id + '-' + index.toString()}>
            <PatientInfoListItem key={'patient-info-list-item-' + symptom.id + '-' + index.toString()}
                                 data={symptom}
                                 groupId={group.id}
                                 removeId={symptom.id}
                                 removeFunc={removeSymptomFromGroup}
                                 groupSymptom={groupSymptomRef && groupSymptomRef.extra_notes}
                                 menuButtonClicked={(aEvent, aPatient) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedPatientListItem:aPatient})}
            />
          </span>
        )
      });
    }
    else{
      groupSymptomsList = (
        <div className="user-prompt-msg">
          <Translation>{(t, {i18n}) => t('NO_SYMPTOMS_ASSIGNED_YET')}</Translation>
        </div>
      )
    }
    
    return (
      <div>
        {assignedSymptomsPermissions.includes(currentRole) ? (
            <div>
              <PanelSubSectionHeadingWithButton addFunc={addPermissions.includes(currentRole) ? () => this.setState({assignSymptoms:true}) : null}
                                                total={groupSymptoms.length}
                                                heading={groupSymptomsString}
                                                className="panel-subsection-title"
              />
              
              {loading ?
                <Loading/>
                :
                (loadFailed ?
                    <LoadFailed retry={() => this.loadSymptoms()}/>
                    :
                    <div className="group-symptoms-list-cell"
                         style={{flexWrap: 'wrap'}}>
                      {groupSymptomsList}
                    </div>
                )
              }
              
              <Menu id="symptoms-menu"
                    anchorEl={menuAnchorElement}
                    keepMounted
                    className={Boolean(menuAnchorElement) ? 'is-open': ''}
                    open={Boolean(menuAnchorElement)}
                    onClose={() => this.setState({menuAnchorElement:null, selectedPatientListItem:null})}>
                <MenuItem style={{position:'relative'}}
                          onClick={() => this.removeSymptom()}>
                  <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
                </MenuItem>
              </Menu>
            </div>
          )
          :
          <PanelSubSectionHeader title={getSectionTitle(i18next.t('GROUP_SYMPTOMS_BLOCKED'))}
                                 groupColor={groupColor}
          />
        }
  
        {group && assignSymptoms ?
          <AssignSymptomsModal key={'assign-group-symptoms-modal-' + group.name}
                               name={group.name}
                               isOpen={assignSymptoms}
                               fetchSymptoms={fetchSymptoms}
                               groupId={group.id}
                               handleCloseModal={() => this.setState({assignSymptoms:false})}
                               assignSymptomsError={assignSymptomsError}
                               assignSymptomCallback={this.assignGroupSymptom}
          />
          :
          null
        }
      </div>
    )
  }
}

export default withTranslation()(Symptoms)
