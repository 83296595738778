import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import './Header.scss';
import { HamburgerCollapse } from 'react-animated-burgers'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import withRouter from "react-router/es/withRouter";
import {Translation, withTranslation} from "react-i18next";
import {MdLockOutline} from 'react-icons/md';
import Info from 'Custom UI/Info/Info';

const Header = ({ company, history, sidebarExpanded, toggleSidebarWidth, getNewArticlesCount, location, studyClosed }) => {

  const [newArticlesCount, setNewArticlesCount] = useState(0);
  const [showZendesk, setShowZendesk] = useState(false);
  const [addedZendeskListener, setAddedZendeskListener] = useState(false);
  const [updatingNewArticlesCount, setUpdatingNewArticlesCount] = useState(false);

  useEffect(() => {
    if(!updatingNewArticlesCount){
      setUpdatingNewArticlesCount(true);
      getNewArticlesCount().then((newResponse) => {
        setNewArticlesCount(newResponse.data.data.new_articles_count);
        setTimeout(() => {
          setUpdatingNewArticlesCount(false);
        }, 5000);
      });
    }
  }, [company, getNewArticlesCount]);

  const toggleZendesk = ()=> {
    if(showZendesk){
      window.zE('webWidget', 'hide');
      setShowZendesk(false);
    }
    else{
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
      setShowZendesk(true);

      if(!addedZendeskListener){
        setAddedZendeskListener(true);
        window.zE('webWidget:on', 'close', function(){
          setShowZendesk(false);
        });
      }
    }
  };

  return(
    <nav id="header" style={{zIndex: 3, width: '100%'}}>
      <div className="nav-block">
        <HamburgerCollapse 
          className={classnames("header-hamburger-menu", {
            active: sidebarExpanded
          })}
          style={{marginTop:'2px', marginLeft: '5px'}}
          buttonWidth={24}
          isActive={sidebarExpanded}
          toggleButton={toggleSidebarWidth}
          barColor="white"
        />
        <a
          className={classnames({
            active: showZendesk
          })}
        >
          <HelpOutlineIcon 
            style={{
              color: 'white', 
              height: '24px', 
              width: '24px', 
              marginLeft: '8px',
              marginRight: '14px',
              marginTop: '9px'
            }}
            onClick={toggleZendesk}
          />
        </a>

        {studyClosed ?
          <Info content={
              <div className="study-closed-info-popover">
                <Translation>{(t, {i18n}) => t('STUDY_CLOSED_POPOVER_INFO')}</Translation>
              </div>
            }
                placement={'bottom'}
                overlayClassName="study-closed">
            <div className="study-closed-info">
              <MdLockOutline className="study-closed-info-icon" />

              <span>
                <Translation>{(t, {i18n}) => t('STUDY_CLOSED')}</Translation>
              </span>
            </div>
          </Info> 
        : 
        <a 
          className={classnames( {
            active: location?.pathname === '/articles'
          })}
          onClick={() => history.push("/articles")}
        >
          <DescriptionOutlinedIcon
            style={{
              color: 'white',
              height: '24px',
              width: '24px',
              marginTop: '9px'
          }}/>
          {newArticlesCount > 0 ?
            <div className="header-alert-icon">
              {/* {newArticlesCount} */}
            </div>
            :
            null
          }
        </a>
        }
      </div>
      <div className="header-logo"/>
    </nav>
  )
};

export default withTranslation()(withRouter(Header));
