import {
  FETCH_PATIENTS,
  LOAD_PATIENTS,
  ADD_PATIENT_ERROR,
  LOGOUT
} from 'constants/ActionTypes';
import {REHYDRATE} from 'redux-persist'
import {CLEAR_RECEIPT} from "../constants/ActionTypes";
import cloneDeep from 'lodash/cloneDeep';

function patients(state = [], action) {
  switch(action.type) {
    case REHYDRATE:
      let incoming = action.payload && action.payload.patients
      if (incoming && incoming.errors) {
        return {
          ...state,
          errors: null
        }
      }
      return state;
    case FETCH_PATIENTS:
      return {
        ...state,
        patients: [],
        isLoading: true,
      };
    case LOAD_PATIENTS:
      return {
        ...state,
        isLoading: false,
        patients: action.result,
        errors: null
      };
    case ADD_PATIENT_ERROR:
      return {
        ...state,
        errors: action.result
      };
    case CLEAR_RECEIPT:
      const newPatients = cloneDeep(state.patients);

      if(newPatients && action.result && action.result.user){
        let patientId = action.result.user.id;

        for(let i = 0; i < newPatients.length; i++){
          if(newPatients[i].id === patientId){
            newPatients[i].number_of_uncleared_company_notification_receipts--;
            break;
          }
        }
      }
      return {
        ...state,
        patients: newPatients
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default patients
