import React from "react";
import './SurveyBuilderQuestionCell.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import {debounce} from "lodash";
import LabeledTextarea from "../../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {KeyForTranslation} from "../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {BiDotsVerticalRounded} from "react-icons/bi";
import SurveyBuilderDateQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderDateQuestionCell/SurveyBuilderDateQuestionCell";
import SurveyBuilderListQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderListQuestionCell/SurveyBuilderListQuestionCell";
import SurveyBuilderRankQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderRankQuestionCell/SurveyBuilderRankQuestionCell";
import SurveyBuilderYearQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderYearQuestionCell/SurveyBuilderYearQuestionCell";
import SurveyBuilderRadioQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderRadioQuestionCell/SurveyBuilderRadioQuestionCell";
import SurveyBuilderRangeQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderRangeQuestionCell/SurveyBuilderRangeQuestionCell";
import SurveyBuilderNumberQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderNumberQuestionCell/SurveyBuilderNumberQuestionCell";
import SurveyBuilderSliderQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderSliderQuestionCell/SurveyBuilderSliderQuestionCell";
import SurveyBuilderCheckboxQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderCheckboxQuestionCell/SurveyBuilderCheckboxQuestionCell";
import SurveyBuilderDateTimeQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderDateTimeQuestionCell/SurveyBuilderDateTimeQuestionCell";
import SurveyBuilderEConsentQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderEConsentQuestionCell/SurveyBuilderEConsentQuestionCell";
import SurveyBuilderMedicationQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderMedicationQuestionCell/SurveyBuilderMedicationQuestionCell";
import SurveyBuilderDynamicListQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderDynamicListQuestionCell/SurveyBuilderDynamicListQuestionCell";
import SurveyBuilderEConsentHSCQuestionCell from "../SurveyBuilderQuestionCells/SurveyBuilderEConsentHSCQuestionCell/SurveyBuilderEConsentHSCQuestionCell";

const CellComponentsArray = {
  checkbox:SurveyBuilderCheckboxQuestionCell,
  date:SurveyBuilderDateQuestionCell,
  datetime:SurveyBuilderDateTimeQuestionCell,
  dynamic_list:SurveyBuilderDynamicListQuestionCell,
  econsent_hsc:SurveyBuilderEConsentHSCQuestionCell,
  econsent:SurveyBuilderEConsentQuestionCell,
  list:SurveyBuilderListQuestionCell,
  medication:SurveyBuilderMedicationQuestionCell,
  monthyear:SurveyBuilderDateQuestionCell,
  multislider:SurveyBuilderSliderQuestionCell,
  multi_medication:SurveyBuilderMedicationQuestionCell,
  number:SurveyBuilderNumberQuestionCell,
  number_choice:SurveyBuilderNumberQuestionCell,
  radio:SurveyBuilderRadioQuestionCell,
  range:SurveyBuilderRangeQuestionCell,
  rank:SurveyBuilderRankQuestionCell,
  slider:SurveyBuilderSliderQuestionCell,
  year:SurveyBuilderYearQuestionCell
};

export default class SurveyBuilderQuestionCell extends React.Component{
  
  // Instance Variables
  
  containerRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      label:'',
      label_ca:'',
      label_de:'',
      label_es:'',
      label_fi:'',
      label_fr:'',
      label_it:''
    };
    this.updateQuestionLabels = this.updateQuestionLabels.bind(this);
    this.updateQuestionLabels = debounce(this.updateQuestionLabels, 500);
  }
  
  componentDidMount(){
    const {question, translationsArray} = this.props;
    let state = {};
    
    for(let translation of translationsArray){
      let key = KeyForTranslation('label', translation);
      state[key] = question[key];
    }
    this.setState(state);
  }
  
  // Methods
  
  updateQuestionLabels(){
    const {question, updateQuestion} = this.props;
    const {label, label_ca, label_de, label_es, label_fi, label_fr, label_it} = this.state;
    
    if(question && updateQuestion){
      let updatedQuestion = cloneDeep(question);
      updatedQuestion.label = label;
      updatedQuestion.label_ca = label_ca;
      updatedQuestion.label_de = label_de;
      updatedQuestion.label_es = label_es;
      updatedQuestion.label_fi = label_fi;
      updatedQuestion.label_fr = label_fr;
      updatedQuestion.label_it = label_it;
      updateQuestion(updatedQuestion);
    }
  }
  
  // Render
  
  render(){
    const {client, company, errorForIdTypeAndKey, question, updateQuestion, isSelectedQuestion, selectedLanguage,
      menuButtonClicked, fetchDynamicLists, addSurveyList, fetchSurveyLists, fetchDynamicListById,
      fetchSurveyListById, fetchSurveyListByReference, translationsArray, fetchDynamicListByReference} = this.props;
    
    const DynamicCell = CellComponentsArray[question.type];
    const labelKey = KeyForTranslation('label', selectedLanguage);
    
    let enabledSettingsArray = [];
    
    if(question.required){
      enabledSettingsArray.push('REQUIRED_PROPER_CAPITALIZED');
    }
    if(question.default_value !== undefined){
      enabledSettingsArray.push('DEFAULT_ANSWER');
    }
    if(question.exit_to_conditions){
      enabledSettingsArray.push('BRANCHING');
    }
    if(question.conditions){
      enabledSettingsArray.push('VISIBILITY');
    }
    if(question.skip){
      enabledSettingsArray.push('SKIP');
    }
    if(question.other && Boolean(question.other)){
      enabledSettingsArray.push('OTHER');
    }
    if(question.regex){
      enabledSettingsArray.push('REGEX');
    }
    if(question.db_write){
      enabledSettingsArray.push('DB_WRITE');
    }
    if(question.large_text_area){
      enabledSettingsArray.push('LARGE_TEXT_AREA');
    }
  
    let containerHeight = 0;
  
    if(this.containerRef && this.containerRef.current && this.containerRef.current.getBoundingClientRect()){
      containerHeight = this.containerRef.current.getBoundingClientRect().height;
    }
    
    // TODO: Add in DB read editor (https://sandbox.healthstorylines.com/console/#/survey/272).
    
    return(
      <div className={'survey-builder-question-cell ' + (isSelectedQuestion ? 'survey-builder-question-cell-selected' : '')}
           ref={this.containerRef}>
        <span className="survey-builder-page-cell-header">
          <div>
            {i18next.t(question.type.toUpperCase()).toUpperCase()}
            {/*{i18next.t(question.type.toUpperCase()).toUpperCase() + ' y:' + question.y + ' h:' + question.height + ' c:' + containerHeight + ' = ' + (question.height === containerHeight ? 'YES' : 'NO')}*/}
          </div>
          
          <LabeledDelayedInput className="survey-builder-question-cell-input-title"
                               label={i18next.t('QUESTION_KEY')}
                               value={question.key}
                               infoBlurb={i18next.t('QUESTION_KEY_EXPLANATION')}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 let updatedQuestion = cloneDeep(question);
                                 updatedQuestion.key = aEvent.target.value.replaceAll(/\s/g, '');
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'key').message}
          />
  
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize:'21px', alignSelf:'baseline'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
            />
            :
            null
          }
        </span>
        
        <LabeledTextarea className="survey-builder-question-textarea"
                         label={i18next.t('QUESTION_TITLE') + ' - ' + selectedLanguage.display_name}
                         value={this.state[labelKey]}
                         placeholder={i18next.t('QUESTION_TITLE') + ' - ' + selectedLanguage.display_name}
                         infoBlurb={i18next.t('QUESTION_TITLE_EXPLANATION')}
                         handleSave={(aEvent) => this.setState({[KeyForTranslation('label', selectedLanguage)]:aEvent.target.value}, () => {
                           this.updateQuestionLabels();
                         })}
                         errorMessage={errorForIdTypeAndKey(question.id, 'error', labelKey).message}
                         warningMessage={errorForIdTypeAndKey(question.id, 'warning', labelKey).message}
        />
        
        {DynamicCell ?
          <DynamicCell client={client}
                       company={company}
                       question={question}
                       addSurveyList={addSurveyList}
                       updateQuestion={updateQuestion}
                       fetchSurveyLists={fetchSurveyLists}
                       selectedLanguage={selectedLanguage}
                       fetchDynamicLists={fetchDynamicLists}
                       translationsArray={translationsArray}
                       isSelectedQuestion={false}
                       fetchSurveyListById={fetchSurveyListById}
                       errorForIdTypeAndKey={errorForIdTypeAndKey}
                       fetchDynamicListById={fetchDynamicListById}
                       fetchSurveyListByReference={fetchSurveyListByReference}
                       fetchDynamicListByReference={fetchDynamicListByReference}
          />
          :
          null
        }
        
        {enabledSettingsArray.length > 0 ?
          <div className="survey-builder-question-enabled-settings-container">
            <div className="survey-builder-question-enabled-settings-title">
              {i18next.t('ENABLED_SETTINGS')}
            </div>
            
            {enabledSettingsArray.map((key, index) => (
              <div className="survey-builder-question-enabled-settings-array"
                   key={'question-enabled-settings-' + key + '-' + index}>
                • {i18next.t(key)}
              </div>
            ))}
          </div>
          :
          null
        }
      </div>
    )
  }
}
