import React from "react";
import './SurveyBuilderRangeQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyBuilderRangeQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion} = this.props;
    
    let maxNumberOptionsArray = [];
    let minNumberOptionsArray = [];
    
    let maxNumber = 100;
    let minNumber = 0;
    
    // TODO: Make numbers, not dropdowns.
    // TODO: not saving correctly.
    // TODO: Add errors if min and max values are not set.
    
    if(question.max && question.max.value !== undefined){
      maxNumber = Number(question.max.value);
    }
    if(question.min && question.min.value !== undefined){
      minNumber = Number(question.min.value);
    }
    for(let number = minNumber; number <= 50; number += 1){
      maxNumberOptionsArray.push({label:number.toString(), value:number.toString()})
    }
    for(let number = 0; number <= maxNumber; number += 1){
      minNumberOptionsArray.push({label:number.toString(), value:number.toString()})
    }
    
    return(
      <div className="survey-builder-range-question-cell">
        <LabeledDropdown className="survey-builder-range-question-input"
                         label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                         value={question.min && (question.min.value || question.min.value === 0) && minNumberOptionsArray.filter(function(option){
                           return option.value === question.min.value.toString();
                         })}
                         options={minNumberOptionsArray}
                         infoBlurb={<Translation>{(t, { i18n }) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           let updatedQuestion = cloneDeep(question);
                           updatedQuestion.min = {value:aOption.value};
                           updateQuestion(updatedQuestion);
                         }}
        />
        
        <LabeledDropdown className="survey-builder-range-question-input"
                         label={<Translation>{ (t, { i18n }) => t('MAX_VALUE') }</Translation>}
                         value={question.max && (question.max.value || question.max.value === 0) && maxNumberOptionsArray.filter(function(option){
                           return option.value === question.max.value.toString();
                         })}
                         options={maxNumberOptionsArray}
                         infoBlurb={<Translation>{(t, { i18n }) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           let updatedQuestion = cloneDeep(question);
                           updatedQuestion.max = {value:aOption.value};
                           updateQuestion(updatedQuestion);
                         }}
        />
      </div>
    )
  }
}
