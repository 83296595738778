import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {gql} from "@apollo/client";

export function fetchSurveyLists(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_SURVEY_LISTS);
  let query = `
    query survey_lists ($company_id: ID!, $search_string: String, $limit: Int, $offset: Int) {
      survey_lists (company_id: $company_id, search_string: $search_string, limit: $limit, offset: $offset) {
        survey_lists {
          id
          name
          reference
          created_at
          updated_at
          is_editable
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSurveyList(aId, aReference){
  EventTracker.track(EventTypes.FETCH_SURVEY_LIST);
  let query = `
    query survey_list ($company_id: ID!, $reference: String, $id: ID) {
      survey_list (company_id: $company_id, reference: $reference, id: $id) {
        id
        name
        reference
        data
        created_at
        updated_at
        is_editable
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aId){
      variables['id'] = aId;
    }
    else if(aReference){
      variables['reference'] = aReference;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchSurveyListById(aId){
  return fetchSurveyList(aId, null);
}

export function fetchSurveyListByReference(aReference){
  return fetchSurveyList(null, aReference);
}

export function addSurveyList(aFile, aName, aReference, aClient){
  EventTracker.track(EventTypes.ADD_SURVEY_LIST);
  return saveSurveyList(null, aFile, aName, aReference, aClient);
}

export function updateSurveyList(aSurveyListId, aFile, aName, aReference, aClient){
  EventTracker.track(EventTypes.UPDATE_SURVEY_LIST);
  return saveSurveyList(aSurveyListId, aFile, aName, aReference, aClient);
}

export function saveSurveyList(aSurveyListId, aFile, aName, aReference, aClient){
  let query = gql`
    mutation SaveSurveyListMutation ($saveSurveyListMutationInput: SaveSurveyListMutationInput!) {
        saveSurveyListMutation (input: $saveSurveyListMutationInput){
          survey_list {
            id
            data
            name
            reference
            created_at
            updated_at
            is_editable
          }
        }
      }
  `;
  
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aSurveyListId){
      params['id'] = aSurveyListId;
    }
    params['data_csv'] = aFile;
    params['name'] = aName;
    params['reference'] = aReference;
    
    let variables = {
      saveSurveyListMutationInput:params
    };
    return aClient
    .mutate({
      mutation:query,
      variables:variables,
      context:{ hasUpload:true }
    });
  };
}
