import React from "react";
import './SurveyBuilderCheckboxQuestionCell.scss';
import SurveyBuilderChoices from "../../../../Components/SurveyBuilder/SurveyBuilderChoices/SurveyBuilderChoices";

export default class SurveyBuilderCheckboxQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, translationsArray, shouldDetectErrors, errorForIdTypeAndKey} = this.props;
    
    return(
      <div className="survey-builder-checkbox-question-cell">
        <SurveyBuilderChoices question={question}
                              updateQuestion={updateQuestion}
                              selectedLanguage={selectedLanguage}
                              translationsArray={translationsArray}
                              shouldDetectErrors={shouldDetectErrors}
                              errorForIdTypeAndKey={errorForIdTypeAndKey}
        />
      </div>
    )
  }
}
