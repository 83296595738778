import React from 'react';
import './NoReminderChart.scss'
import Chart from "chart.js";
import BaseChart from "../BaseChart/BaseChart";
import {EntryConstants} from "../../../../constants/EntryConstants";
import {ObjectiveConstants} from "../../../../constants/ObjectiveConstants";
import ReactToolTip from "react-tooltip";
import ResponsesList from "../../../../Lists/ResponsesList/ResponsesList";
import {Translation} from "react-i18next";

export default class NoReminderChart extends BaseChart {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: '',
      responseId: ''
    }
  }

  componentDidMount() {
    let { reminderObjective, startDate, endDate, onClickListener } = this.props;
    const chartContext = this.chartRef.current.getContext("2d");
    this.chart = new Chart(chartContext, this.generateChartData(reminderObjective, startDate, endDate, onClickListener));
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const prevEntries = prevProps.reminderObjective.entries;
    let { reminderObjective, startDate, endDate, onClickListener } = this.props;

    if (this.dateRangeChanged(prevProps.startDate, prevProps.endDate, startDate, endDate)
      || this.entriesChanged(prevEntries, reminderObjective.entries)) {
      const chartContext = this.chartRef.current.getContext("2d");
      this.chart.destroy();
      this.chart = new Chart(chartContext, this.generateChartData(reminderObjective, startDate, endDate, onClickListener));
    }
  }

  generateChartData(reminderObjective, startDate, endDate, onClickListener){
    let dataPoints = [];
    let pointStyles = [];
    let xAxisTickLabels = [];
    let currentDateIndex = 0;
    let currentDate = new Date(startDate);
    let maxYValue = 24;
    let minYValue = 0;
    currentDate.setHours(0, 0, 0, 0);
    let tooltipValue = "";
    let skippedImage = new Image();
    let takenImage = new Image();
    takenImage.src = '/imgs/app/taken.svg';
    skippedImage.src = '/imgs/app/skipped.svg';

    while(currentDate < endDate && reminderObjective && reminderObjective.entries){
      let entriesOnCurrentDate = reminderObjective.entries.filter((entry) => {
        let measuredAt = new Date(entry.response.measured_at);
        return measuredAt.getDate() === currentDate.getDate()
          && measuredAt.getMonth() === currentDate.getMonth()
          && measuredAt.getFullYear() === currentDate.getFullYear();
      });
      entriesOnCurrentDate.forEach(entry => {
        let measuredAt = new Date(entry.response.measured_at);

        if(entry.value === EntryConstants.EntryTaken){
          if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeMedication){
            tooltipValue = "Taken";
          }
          else if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeRoutine){
            tooltipValue = "Completed"
          }
          dataPoints.push({
            tooltip: tooltipValue,
            colour: '#24895b',
            x: currentDateIndex + 0.5,
            y: maxYValue - measuredAt.getHours(),
            responseId: entry.response.id,
            objectiveId: reminderObjective.id
          });
          pointStyles.push(takenImage);
        }
        else{
          if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeMedication){
            tooltipValue = "Skipped";
          }
          else if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeRoutine){
            tooltipValue = "Missed";
          }
          dataPoints.push({
            tooltip: tooltipValue,
            colour: '#f34d33',
            x: currentDateIndex + 0.5,
            y: maxYValue - measuredAt.getHours(),
            responseId: entry.response.id,
            objectiveId: reminderObjective.id
          });
          pointStyles.push(skippedImage);
        }
      });
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      currentDateIndex++;
    }
    return {
      type: 'scatter',
      plugins: [{
        afterDraw: function(chartInstance) {
          let meta = chartInstance.getDatasetMeta(0);

          if(meta){
            let context = chartInstance.ctx;
            let xOffset = 0;

            if(meta.controller._xScale._labelItems.length > 1){
              xOffset = (meta.controller._xScale._labelItems[1].x - meta.controller._xScale._labelItems[0].x) * 0.55;
            }

            meta.controller._xScale._labelItems.forEach((labelItem, index) => {
              context.save();
              context.translate(labelItem.x + xOffset, labelItem.y);
              context.rotate(labelItem.rotation);
              context.textAlign = "right";
              context.font = labelItem.font.string;
              context.fillStyle = "#000000";
              context.fillText(labelItem.label, labelItem.textOffset, 0);
              context.restore();
            });
          }
        }
      }],
      data: {
        datasets: [{
          backgroundColor: dataPoints.map(dataPoint => dataPoint.colour),
          pointRadius: 7.5,
          pointHoverRadius: 7.5,
          pointStyle: pointStyles,
          data: dataPoints
        }]
      },
      options: {
        onClick: (event) => {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseId,
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            const chart = this.chartRef.current;

            if (!chart) {
              return;
            }

            if (tooltipModel.opacity === 0) {
              this.setState({
                showTooltip: false
              });
              return;
            }

            if (!this.state.showTooltip) {
              const dataPoint = dataPoints[tooltipModel.dataPoints[0].index];
              this.setState({
                showTooltip: true,
                text: dataPoint.tooltip,
                responseId: dataPoint.responseId,
                date: xAxisTickLabels[dataPoint.x - 0.5]
              });
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: false,
              min: 0,
              max: currentDateIndex,
              stepSize: 1,
              fontColor: 'transparent',
              padding: 20,
              autoSkip: false,
              callback: function(value, index, values) {
                let returnValue = "";

                if(value < xAxisTickLabels.length && value >= 0){
                  returnValue = `${xAxisTickLabels[Math.floor(value)]}`;
                }
                return returnValue;
              }
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: true,
            gridLines: {
              drawBorder: false,
              drawTicks: true,
            },
            ticks: {
              min: minYValue,
              max: maxYValue,
              stepSize: maxYValue / 4,
              padding: 10,
              callback: function(value, index, values) {
                let returnValue = "";
                let invertedValue = maxYValue - value;

                if(invertedValue > 0){
                  if(invertedValue < 12){
                    returnValue = `${invertedValue} am`;
                  }
                  else if(invertedValue === 12){
                    returnValue = `12 pm`;
                  }
                  else if(invertedValue < 24){
                    returnValue = `${invertedValue - 12} pm`;
                  }
                  else{
                    returnValue = `12 am`;
                  }
                }
                return returnValue;
              }
            }
          }]
        }
      }
    }
  }

  render() {
    const takenImage = ResponsesList.TakenImage;
    const skippedImage = ResponsesList.SkippedImage;
    const {reminderObjective} = this.props;
    const {showTooltip, responseId, date, text} = this.state;
    const imageSrc = ['Skipped', 'Missed'].includes(text) ? skippedImage : takenImage;
    const responses = reminderObjective.entries
      .map(entry => entry.response)
      .filter(response => response.id === responseId);
    const formatDate = responses.length === 0 ? date : this.formatResponseDate(responses[0].measured_at);
    const footer = 'click to view details';

    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.resizePoints();
        this.chart.update();
      }
    });
    return (
      <div>
        <div style={{height: "245px"}}>
          <canvas ref={this.chartRef}
                  data-tip data-for={`${reminderObjective.id}`}/>
        </div>
        {
          showTooltip &&
          <ReactToolTip id={`${reminderObjective.id}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>{formatDate}</div>
              <div className='tooltip-body flex-center'>
                <img alt="" src={imageSrc}/>
                { text }
              </div>
              <div className='tooltip-footer match-image no-padding-top-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
