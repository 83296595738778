import React from 'react';
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import { flow } from 'lodash'
import { IoIosReorder } from 'react-icons/io'
import {Translation} from "react-i18next";
import i18next from "i18next";
import '../../../Modals/ToolModal/ToolModal.scss';
import LabeledTextInput from "../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {BiDotsVerticalRounded} from "react-icons/bi";




const answerSource = {
  beginDrag(props) {
    return {
      answer: props.answer,
      index: props.index
    }
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
    // const item = monitor.getItem();
    // const dropResult = monitor.getDropResult();
  }
}

const answerTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveAnswer(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex
  },
}

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class ToolBlockAnswerCell extends React.Component {
  constructor(props) {
    super(props);
    this.inputElement = '';
    this.triggerFileUpload = this.triggerFileUpload.bind(this)
    this.onDropFile = this.onDropFile.bind(this)
  }

  triggerFileUpload() {
    this.inputElement.click();
  }

  onDropFile(e) {
    const file = e.target.files[0]
    const _this = this
    const reader = new FileReader();
    reader.onload = (() => {
      return function (e) {
        let data = e.target.result.split(',')[1]
        _this.props.updateAnswerImage(data, _this.props.index)
      }
    })(file)
    reader.readAsDataURL(file)
  }

  render() {
    const { isDragging, connectDragSource, connectDropTarget, answer, index, handleAnswerChange, selectedTranslationIndex, openMenu} = this.props
    return connectDropTarget(


      <div style={{opacity: isDragging ? 0.5 : 1, border: '1px solid #A8A8A8', borderRadius: '10px', marginRight: '20px', marginTop: '10px'}}>
        <div className="tools-modal-title-answer-cell">
          {connectDragSource(
            <div style={{padding: '5px', cursor: 'move'}}>
              <IoIosReorder style={{fontSize: '21px', color: '#4A4A4A'}} />
            </div>)}
          <div className="tools-modal-title">
            <Translation>{ (t, { i18n }) => t('CHOICE') + (index+1) }</Translation>
          </div>
          <BiDotsVerticalRounded style={{fontSize: '21px', color: '#4A4A4A', marginLeft: 'auto'}} onClick={(aEvent) => openMenu(aEvent, index)}/>
        </div>

        <div style={{display: 'flex', flex: 1, marginLeft: '20px', width: 'calc(100% - 20px)'}}>
          <LabeledTextInput className="tools-block-answer-input"
                            placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                            name={index}
                            value={answer.translations[selectedTranslationIndex].answer_text || ''}
                            handleSave={handleAnswerChange}
          />
          {/*<input className="rounded-input" */}
          {/*       style={{width: '100%'}} */}
          {/*       type="text"*/}
          {/*       onChange={handleAnswerChange} />*/}
        </div>

        {/*{ answer.has_image &&*/}
        {/*    <div style={{display: 'flex', marginRight: '10px'}}>*/}
        {/*      <div style={{padding: '5px', position: 'relative'}}>*/}
        {/*        <div*/}
        {/*          onClick={() => removeAnswerImage(index)}*/}
        {/*          className="clickable"*/}
        {/*          style={{*/}
        {/*            position: 'absolute', top: '-1px', right: '-1px', color: '#fff', background: '#d0011b', width: '1.2em', height: '1.2em', textAlign: 'center', borderRadius: '50%', lineHeight: '1.1em'*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          X*/}
        {/*        </div>*/}
        {/*        { answer.image_data &&*/}
        {/*            <img src={"data:image/jpeg;base64,"+answer.image_data} alt={answer.text} />*/}
        {/*        }*/}
        {/*        { !answer.image_data &&*/}
        {/*            <img src={GraphQLEndpoint + '/api/images/answer/' + answer.id + '.jpg'} alt={answer.text} />*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*}*/}

        {/*{!answer.has_image && */}
        {/*    <div style={{marginRight: '10px'}}>*/}
        {/*      <button */}
        {/*        onClick={this.triggerFileUpload}*/}
        {/*      >*/}
        {/*        <Translation>{ (t, { i18n }) => t('ADD_IMAGE') }</Translation>*/}
        {/*      </button>*/}
        {/*      <input */}
        {/*        ref={input => this.inputElement = input}*/}
        {/*        style={{display: 'none'}}*/}
        {/*        type="file" */}
        {/*        name={"choice"+answer.id+"-image"} */}
        {/*        onChange={this.onDropFile}*/}
        {/*        accept={"accept=image/*"}*/}
        {/*      />*/}
        {/*  </div>*/}
        {/*}*/}

        {/*<div style={{display: 'flex'}}>*/}
        {/*  <RemoveItem*/}
        {/*      title={<Translation>{ (t, { i18n }) => t('REMOVE_CHOICE')}</Translation>}*/}
        {/*      removeFunc={removeAnswer}*/}
        {/*      removeId={index}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    )
  }
}

// export default DropTarget('answer', answerTarget, collect)(DragSource('answer', answerSource, collect)(ToolBlockAnswer))

export default flow(
  DropTarget('answer', answerTarget, collectTarget),
  DragSource('answer', answerSource, collectSource)
)(ToolBlockAnswerCell)
