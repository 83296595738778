import actions from 'actions';
import { connect } from 'react-redux';
import ManagePayments from "../Pages/ManagePayments/ManagePayments";

const mapStateToProps = (state, ownProps)=> {
  let company = state.company && state.company.company;
  
  return {
    company,
    path: ownProps.match.path,
    groupName: ownProps.match.params.group
  }
};

const mapDispatchToProps = dispatch => {
  return {
    retryPayment:(aPayment) => {
      return dispatch(actions.retryPayment(aPayment))
    },
    fetchPayments:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchPayments(aPatientId, aGroupId, aLimit, aOffset, aSearchString))
    },
    createPayment: (aPatientID, aAmount, aCurrency) => {
      return dispatch(actions.createPayment(aPatientID, aAmount, aCurrency))
    },
    approvePayment: (payment) => {
      return dispatch(actions.approvePayment(payment))
    },
    removePayment: (payment) => {
      return dispatch(actions.removePayment(payment));
    },
    fetchPaymentTriggers:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchPaymentTriggers(aLimit, aOffset, aSearchString))
    },
    fetchPaymentTriggersForGroup:(aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchPaymentTriggersForGroup(aGroupId, aLimit, aOffset, aSearchString));
    },
    fetchGroupsForPaymentTrigger: ( hyperwallet_payment_trigger_id, limit, offset ) => {
      return dispatch(actions.fetchGroupsForPaymentTrigger( hyperwallet_payment_trigger_id, limit, offset ));
    },
    updateHyperwalletGroupPaymentTrigger:(isEnabled, groupId, triggerId) => {
      return dispatch(actions.updateHyperwalletGroupPaymentTrigger(isEnabled, groupId, triggerId));
    },
    createPaymentTrigger:(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.createPaymentTrigger(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    removePaymentTrigger:(aPaymentTriggerId) => {
      return dispatch(actions.removePaymentTrigger(aPaymentTriggerId));
    },
    updatePaymentTrigger:(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.updatePaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    fetchQuestionsForSurveyById:(aSurveyId) => {
      return dispatch(actions.fetchQuestionsForSurveyById(aSurveyId));
    },
    updateHyperWalletCredentials: (aProgramToken, aUsername, aPassword, aURL) => {
      return dispatch(actions.updateHyperWalletCredentials(aProgramToken, aUsername, aPassword, aURL));
    },
    updateTremendousCredentials: (aTremendousApiToken, aTremendousCampaignId, aTremendousFundingSourceId) => {
      return dispatch(actions.updateTremendousCredentials(aTremendousApiToken, aTremendousCampaignId, aTremendousFundingSourceId));
    },
    updateCompanyPaymentType:(aCompanyId, aPaymentType) => {
      return dispatch(actions.updateCompanyPaymentType(aCompanyId, aPaymentType));
    },
    fetchFraudEvents:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchFraudEvents(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    fetchFraudStatistics:(aPatientId) => {
      return dispatch(actions.fetchFraudStatistics(aPatientId));
    },
    markFraudEventAsVerified:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsVerified(aFraudEventId, aComment));
    },
    markFraudEventAsFraudulent:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsFraudulent(aFraudEventId, aComment));
    },
    markFraudStatisticAsVerified:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsVerified(aFraudStatisticId, aComment));
    },
    markFraudStatisticAsFraudulent:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsFraudulent(aFraudStatisticId, aComment));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePayments)
