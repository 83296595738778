import React from "react";
import './SurveyBuilderQuestionSkip.scss';
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import cloneDeep from "lodash/cloneDeep";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";

export default class SurveyBuilderQuestionSkip extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const { question, updateQuestion, selectedLanguage, errorForIdTypeAndKey } = this.props;
    
    let labelKey = KeyForTranslation('label', selectedLanguage);
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-skip-input"
                       label={<Translation>{ (t, { i18n }) => t('SKIP') }</Translation>}
                       checked={Boolean(question && question.skip)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
          
                         if(isChecked){
                           updatedQuestion.skip = {label:'', value:'skip'};
                         }
                         else{
                           delete updatedQuestion['skip'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('SKIP_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}/>
        
        {question && question.skip ?
          <span>
            <LabeledDelayedInput className="survey-builder-question-skip-input"
                                 label={<Translation>{(t, { i18n }) => t('SKIP_LABEL')}</Translation>}
                                 value={question.skip[labelKey] && question.skip[labelKey].length > 0 ? question.skip[labelKey] : ''}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('SKIP_LABEL_EXPLANATION')}</Translation>}
                                 minLength={0}
                                 handleSave={(aEvent) => {
                                   let updatedQuestion = cloneDeep(question);
                                   updatedQuestion.skip[labelKey] = aEvent.target.value;
                                   updateQuestion(updatedQuestion);
                                 }}
                                 warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'skip_' + labelKey).message}
                                 popoverPlacement={"left"}/>
                              
            <LabeledDelayedInput className="survey-builder-question-skip-input"
                                 label={<Translation>{(t, { i18n }) => t('VALUE')}</Translation>}
                                 value={question.skip.value && question.skip.value.length > 0 ? question.skip.value : ''}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('SKIP_VALUE_EXPLANATION')}</Translation>}
                                 minLength={0}
                                 handleSave={(aEvent) => {
                                   let updatedQuestion = cloneDeep(question);
                                   updatedQuestion.skip.value = aEvent.target.value;
                                   updateQuestion(updatedQuestion);
                                 }}
                                 warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'skip_value').message}
                                 popoverPlacement={"left"}/>
          </span>
          :
          null
        }
      </div>
    )
  }
}
