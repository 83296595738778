import React, {useState} from 'react';
import './ScheduleVersionsModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {momentFromDateTime} from "../../Helpers/DateHelpers";

const ScheduleVersionsModal = ({open, close, selectScheduleVersion, presentedInsideParentModal, fetchScheduleVersionsForSchedule}) => {
  
  // Instance Variables
  
  const [survey, setSurvey] = useState(null);
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               save={() => selectScheduleVersion(survey)}
    //               close={close}
    //               width={'400px'}
    //               title={i18next.t('SELECT_SCHEDULE_VERSION')}
    //               height={'230px'}
    //               containsDropDown={true}
    //               presentedInsideParentModal={presentedInsideParentModal}>
    <GeneralModal title={i18next.t('SELECT_SCHEDULE_VERSION')}
                  width={'400px'}
                  height={'230px'}
                  isOpen={open}
                  maxWidth={400}
                  maxHeight={230}
                  handleSave={() => {
                    selectScheduleVersion(survey);
                    setSurvey(null);
                  }}
                  noTitleHeading={true}
                  showSaveButton={true}
                  containsDropDown={true}
                  handleCloseModal={() => {
                    close();
                    setSurvey(null);
                  }}
                  maxChildrenHeight={180}
                  presentedInsideParentModal={presentedInsideParentModal}>
      <LabeledDropdown className="schedule-versions-modal-dropdown"
                       value={survey}
                       handleSave={(aSurvey) => setSurvey(aSurvey)}
                       placeholder={i18next.t('SEARCH_SCHEDULE_VERSIONS')}
                       fetchObjects={(aLimit, aOffset) => fetchScheduleVersionsForSchedule(aLimit, aOffset)}
                       labelPropertiesArray={['name']}
                       valuePropertiesArray={['id']}
                       optionsArrayFormatter={(aArray) => {
                         let optionsArray = [];
    
                         for(let scheduleVersion of aArray){
                           optionsArray.push({label:scheduleVersion.version_number + ' - ' + momentFromDateTime(scheduleVersion.created_at), value:scheduleVersion.id, key:scheduleVersion.id});
                         }
                         return optionsArray;
                       }}
                       responseTotalKeysArray={['data', 'data', 'schedule_versions', 'total']}
                       responseObjectKeysArray={['data', 'data', 'schedule_versions']}
      />
    </GeneralModal>
  );
};

export default ScheduleVersionsModal
