import React from "react";
import '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown.scss'
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyListQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answerValue: null,
    };
    
    if(props.answer != null && props.answer.value != null){
      this.state.answerValue = props.answer.value;
    }
    this.saveAnswer = this.saveAnswer.bind(this);
  }
  
  // Methods
  
  saveAnswer(aValue){
    this.setState({answerValue: aValue});
    this.props.onAnswerChange(this.props.question, aValue);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answerValue} = this.state;
    const {key, list, type} = question;
    
    let listOptionsArray = [];
    
    if(list && list.length > 0){
      for(let item of list){
        listOptionsArray.push(item);
      }
    }
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}
           className='survey-condition-question-cell'>
        <LabeledDropDown value={listOptionsArray.filter(function(option){
          return option.value === answerValue;
        })}
                         options={listOptionsArray}
                         disabled={disabled}
                         handleSave={(aOption) => this.saveAnswer(aOption.value)}
                         menuPosition={'portal'}
                         menuPlacement={'bottom'}
        />
      </div>
    )
  }
}
