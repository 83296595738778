import React from 'react';
import 'antd/dist/antd.css';
import './PatientCell.scss'
import ProfilePicture from '../../Custom UI/ProfilePicture/ProfilePicture';
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class PatientCell extends React.Component {

  // TODO: Merge with PatientListItem.
  
  // Init

  constructor(props) {
    super(props);
    this.state = {

    };
    this.formatPatientName = this.formatPatientName.bind(this);
    this.handlePatientCellClick = this.handlePatientCellClick.bind(this);
  }

  // Methods

  formatPatientName(aPatient){
    let returnValue = '';

    if(aPatient){
      if(aPatient.last_name != null){
        returnValue =`${aPatient.first_name} ${aPatient.last_name}`;
      }
      else{
        returnValue = aPatient.first_name;
      }
    }
    return returnValue;
  }
  
  handlePatientCellClick(){
    if(this.props.showLink){
      let patientLink = '#';
      
      if(this.props.groupName){
        patientLink = '/groups/' + this.props.groupName + '/patients/' + this.props.patient.id;
      }
      else{
        patientLink = '/patients/' + this.props.patient.id;
      }
      this.props.history.push(patientLink);
    }
    else if(this.props.selectPatient){
      this.props.selectPatient(this.props.patient);
    }
  }

  // Render

  render() {
    const { patient, checkboxOnChange, checked,  menuButtonClicked, className, canSelect } = this.props;

    return (
      <div className={"patient-cell " + className} onClick={!canSelect ? this.handlePatientCellClick : null}>
        <div className="patient-info">
          <ProfilePicture width={30}
                          height={30}
                          className='profile-pics profile-pic-patient-cell'
                          style={{width: '30px'}}
                          avatarId={patient && patient.avatar_id}
          />
          <div className="patient-cell-name">
            {this.formatPatientName(patient)}
          </div>
        </div>

        <div className="patient-editing">
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
                                   fill="#A8A8A8"
            />
            :
            null
          }
          {checkboxOnChange ?
            <input className="patient-user-input"
                   id={'patient-cell-checkbox-' + patient.id}
                   type='checkbox'
                   value={checked}
                   checked={checked}
                   defaultChecked={false}
                   onClick={checkboxOnChange}
            />
            :
            null
          }
        </div>
      </div>
    )
  }
}
