import React from "react";
import {Translation} from "react-i18next";

export default class SurveySkipCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {answer, disabled, question, skipQuestion} = this.props;
    const {key, skip, type} = question;
    
    return(
      <div className="survey-skip-cell"
           key={'survey-' + type + '-question-skip-cell-' + key}>
        {skip ?
          <div className="survey-text-question-skip-container">
            <label className={'custom-input-cell'}>
              <input type={"checkbox"}
                     name={'checkbox-choice'}
                     disabled={disabled}
                     onChange={() => skipQuestion()}
                     defaultChecked={answer.skipped}
              />
              
              <span className={'custom-checkmark'}/>
              
              <div className={'inline-text'}>
                {skip && skip.length > 0 && skip[0] && skip[0].length > 1 && skip[0][1] ?
                  skip[0][1]
                  :
                  (skip && skip.label && skip.label.length > 0 ?
                      skip.label
                      :
                      <Translation>{ (t, { i18n }) => t('SKIP')}</Translation>
                  )
                }
              </div>
            </label>
          </div>
          :
          null
        }
      </div>
    )
  }
}
