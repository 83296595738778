import React from "react";

export default class CustomRenderedOption extends React.Component{
  
  render(){
    const {innerKey, selectValue, focusOption, labelStyle, headerText, option, style = {}, labelText} = this.props;
    
    const headerStyle = {
      left:'0px',
      color:'rgb(153, 153, 153)',
      width:'100%',
      cursor:'default',
      height:'20px',
      display:'flex',
      fontSize:'11px',
      boxSizing:'border-box',
      alignItems:'center',
      fontWeight:500,
      paddingLeft:'11px',
      marginBottom:'0.25em',
      textTransform:'uppercase',
      backgroundColor:'#FFFFFF'
    };
    
    return (
      <div key={innerKey}
           style={{...style,
             left:'-10px',
             width:'calc(100% + 10px)',
             height:'60px',
             display:'flex',
             alignItems:'flex-start',
             flexDirection:'column'
           }}
           onClick={() => selectValue(option)}
           onMouseEnter={() => focusOption(option)}>
        {headerText ?
          <div style={headerStyle}>
            {headerText}
          </div>
          :
          null
        }
    
        <div style={labelStyle ? labelStyle : {height:'35px', paddingBottom:'3px'}}>
          {labelText ? labelText : option.label}
        </div>
      </div>
    )
  }
}
