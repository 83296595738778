import React from 'react';
import "antd/dist/antd.css";
import './GenerateReportModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import DynamicList from "../../Lists/DynamicList/DynamicList";

export default class GenerateReportModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      filters:{},
      fullReportTemplate:{}
    };
  }
  
  componentDidMount(){
    const {reportTemplate, fetchReportTemplate} = this.props;
    
    if(reportTemplate && fetchReportTemplate){
      fetchReportTemplate(reportTemplate.id).then((newResult) => {
        this.setState({fullReportTemplate:newResult.data.data.template});
      }, (newError) => {
      
      });
    }
  }
  
  // Render
  
  render() {
    const {isOpen, handleSave, handleClose, reportTemplate} = this.props;
    const {filters, fullReportTemplate} = this.state;
  
    let columnsArray = [];
    columnsArray.push({key:'keyword', columnWeight:1, columnNameKey:'KEYWORD', propertiesArray:['keyword']});
    columnsArray.push({key:'rhs_value', columnWeight:2, columnNameKey:'VALUE', propertiesArray:['rhs_value'], handleSave:(aEvent, aObject, aRow) => {
        filters[aObject.keyword] = aEvent.target.value;
        this.setState({filters:filters});
      }});
    
    if(fullReportTemplate && fullReportTemplate.model && fullReportTemplate.model.filters){
      for(let filter of fullReportTemplate.model.filters){
        if(filter.keyword && filter.keyword.length > 0){
          if(!filters.hasOwnProperty(filter.keyword)){
            filters[filter.keyword] = filter.rhs_value;
          }
        }
      }
    }
    let fixedObjectsArray = [];
    
    for(let key of Object.keys(filters)){
      fixedObjectsArray.push({keyword:key, rhs_value:filters[key]});
    }
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('GENERATE_REPORT_TITLE', {aName:reportTemplate.name}) }</Translation>}
                    width={400}
                    height={fixedObjectsArray.length > 0 ? 300 : 150}
                    isOpen={isOpen}
                    handleSave={() => handleSave(reportTemplate.id, reportTemplate.name, filters)}
                    showFooter={true}
                    showHeader={true}
                    noTitleHeading={true}
                    showSaveButton={true}
                    containsDropDown={true}
                    saveButtonTitle={<Translation>{ (t, { i18n }) => t('GENERATE') }</Translation>}
                    handleCloseModal={() => handleClose()}>
        {fixedObjectsArray.length > 0 ?
          <div className="generate-report-modal-dynamic-list">
            <DynamicList id="generate-report-modal-dynamic-list"
                         showTotal={false}
                         columnsArray={columnsArray}
                         showSearchBar={false}
                         minColumnWidth={100}
                         fixedObjectsArray={fixedObjectsArray}
            />
          </div>
          :
          null
        }
      </GeneralModal>
    )
  }
}
