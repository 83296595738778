import React from 'react';
import {Translation} from "react-i18next";
import {BiDotsVerticalRounded} from "react-icons/bi";
import './CampaignCell.scss';

export default class CampaignCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false
    }
  }

  showRemove() {
    this.setState({showRemove: true})
  }

  hideRemove() {
    this.setState({showRemove: false})
  }

  render() {
    const { data, menuButtonClicked} = this.props
    return (
      <div style={{width: '100%', marginBottom: '10px'}} className="list-item" onMouseLeave={() => this.hideRemove()} onMouseEnter={() => this.showRemove()} >
        { data &&
        <div key={data && data.id} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>

          <div className='list-item-half'>
            <div data-testid="campaign-title">{ data.title}</div>
            <div className="patient-notes-data" data-testid="campaign-description">{data.description}</div>
            <div className="patient-notes-data" data-testid="campaign-url">{<Translation>{ (t, { i18n }) => t('LINK') }</Translation>}: <a href="{data.shortened_url}" target="_blank">{data.shortened_url}</a></div>
          </div>
          <div className='list-item-half'>
            <div className="patient-notes-data" data-testid="campaign-utm-source">{<Translation>{ (t, { i18n }) => t('UTM_SOURCE') }</Translation>}:  {data.utm_source}</div>
            <div className="patient-notes-data" data-testid="campaign-utm_campaign">{<Translation>{ (t, { i18n }) => t('UTM_CAMPAIGN') }</Translation>}: {data.utm_campaign}</div>
            <div className="patient-notes-data" data-testid="campaign-utm-medium">{<Translation>{ (t, { i18n }) => t('UTM_MEDIUM') }</Translation>}: {data.utm_medium}</div>
            <div className="patient-notes-data" data-testid="campaign-utm-content">{<Translation>{ (t, { i18n }) => t('UTM_CONTENT') }</Translation>}: {data.utm_content}</div>
          </div>

          <div>
            <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent, data)}
            />
          </div>

        </div> }
      </div>
    )
  }
}
