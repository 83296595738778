import React from 'react';
import {Translation} from "react-i18next";

const RoutineObjective = ({ objective, selectedTranslationIndex }) => (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <label>
      {objective.translations[selectedTranslationIndex].description}
    </label>
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <button style={{width: '33%', marginRight: '1em'}}>
          <Translation>{ (t, { i18n }) => t('COMPLETED') }</Translation>
      </button>
      <button style={{width: '33%'}}>
          <Translation>{ (t, { i18n }) => t('MISSED') }</Translation>
      </button>
    </div>
  </div>
);

export default RoutineObjective
