import React from "react";
import './SurveyBuilderErrors.scss';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import {Translation} from "react-i18next";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

export default class SurveyBuilderQuestionDefaults extends React.Component{
  
  // Instance Variables
  
  containerRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {errors, scrollToRefWithId} = this.props;
    const {menuAnchorElement} = this.state;
    
    let errorCount = 0;
    let warningCount = 0;
    let errorsArray = [];
    let warningsArray = [];
    
    for(let key of Object.keys(errors)){
      for(let error of errors[key]){
        if(error.type === 'error'){
          errorCount += 1;
          errorsArray.push({message:error.title + ': ' + error.message, id:error.id});
        }
        else if(error.type === 'warning'){
          warningCount += 1;
          warningsArray.push({message:error.title + ': ' + error.message, id:error.id});
        }
      }
    }
    
    let errorAndWarningsLabel = i18next.t('NO_ERRORS_DETECTED');

    if(errorCount > 0 || warningCount > 0){
      errorAndWarningsLabel = '';
    }
    
    return(
      <div className="survey-builder-error-container"
           ref={(aRef) => {this.containerRef = aRef}}>
        <span className="survey-builder-error-message"
              onClick={(aEvent) => this.setState({menuAnchorElement:(errorCount + warningCount > 0 ? this.containerRef : null)})}>
          
          <div className="survey-builder-error-text-green">
            {errorAndWarningsLabel}
          </div>
          
          {errorCount > 0 ?
            <div className="survey-builder-error-cell">
              <ErrorIcon className="survey-builder-error-icon-red"/>
              
              <div className="survey-builder-error-text-red">
                <Translation >{ (t, { i18n }) => t('ERRORS_DETECTED', {errorCount:errorCount}) }</Translation>
              </div>
            </div>
            :
            null
          }
          
          {warningCount > 0 ?
            <div className="survey-builder-error-cell">
              <WarningIcon className="survey-builder-error-icon-yellow"/>
              
              <div className="survey-builder-error-text-yellow">
                <Translation>{ (t, { i18n }) => t('WARNINGS_DETECTED', {warningCount:warningCount}) }</Translation>
              </div>
            </div>
            :
            null
          }
        </span>
  
        <Menu id="survey-builder-errors-menu"
              open={Boolean(menuAnchorElement)}
              style={{height:'400px', marginTop:'40px', zIndex:9999}}
              onClose={() => this.setState({menuAnchorElement:null})}
              anchorEl={menuAnchorElement}
              placement={'bottom'}
              keepMounted>
          {errorsArray.map((error, index) => (
            <MenuItem className="survey-builder-error-popover-cell"
                  key={'error-' + index}
                  onClick={() => this.setState({menuAnchorElement:null}, () => scrollToRefWithId(error.id))}>
                <ErrorIcon className="survey-builder-error-popover-icon-red"/>
                
                <span className="survey-builder-error-popover-text">
                  {error.message}
                </span>
              </MenuItem>
          ))}
  
          {warningsArray.map((warning, index) => (
            <MenuItem className="survey-builder-error-popover-cell"
                  key={'warning-' + index}
                      onClick={() => this.setState({menuAnchorElement:null}, () => scrollToRefWithId(warning.id))}>
                <WarningIcon className="survey-builder-error-popover-icon-yellow"/>
                
                <span className="survey-builder-error-popover-text">
                  {warning.message}
                </span>
              </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}
