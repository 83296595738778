import React from 'react';
import './PatientProfile.scss'
import Groups from "../../Components/PatientProfileComponents/Groups/Groups";
import TabBar from "../../Custom UI/TabBar/TabBar";
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import Allergies from "../../Components/PatientProfileComponents/Allergies/Allergies";
import Conditions from '../../Components/PatientProfileComponents/Conditions/Conditions'
import Medications from "../../Components/PatientProfileComponents/Medications/Medications";
import PatientNotes from "../../Components/PatientProfileComponents/PatientNotes/PatientNotes";
import PatientTools from '../../Components/PatientProfileComponents/PatientTools/PatientTools';
import PaymentsList from "../../Lists/PaymentsList/PaymentsList";
import SurveyDrawer from "../../Components/SurveyDrawer/SurveyDrawer";
import CallNotesList from "../../Lists/CallNotesList/CallNotesList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import ResponsesList from "../../Lists/ResponsesList/ResponsesList";
import DataQueriesList from "../../Lists/DataQueriesList/DataQueriesList";
import FraudEventsList from "../../Lists/FraudEventsList/FraudEventsList";
import PatientSymptoms from "../../Components/PatientProfileComponents/PatientSymptoms/PatientSymptoms";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import AddDataQueryModal from "../../Modals/AddDataQueryModal/AddDataQueryModal";
import AssignAllergyModal from "../../Modals/AssignAllergyModal/AssignAllergyModal";
import ContactInformation from "../../Components/PatientProfileComponents/ProfileSections/ContactInformation/ContactInformation";
import MedicalRecordsList from "../../Lists/MedicalRecordsList/MedicalRecordsList";
import PatientProfileData from '../../Components/PatientProfileComponents/PatientProfileData/PatientProfileData'
import MedicationAllergies from "../../Components/PatientProfileComponents/MedicationAllergies/MedicationAllergies";
import ResearchInformation from "../../Components/PatientProfileComponents/ProfileSections/ResearchInformation/ResearchInformation";
import PatientSyncedDevices from '../../Components/PatientProfileComponents/PatientSyncedDevices/PatientSyncedDevices';
import {ObjectiveConstants} from "../../constants/ObjectiveConstants";
import AlternativeContactList from "../../Lists/AlternativeContactList/AlternativeContactList";
import AssignMedicationsModal from "../../Modals/AssignMedicationsModal/AssignMedicationsModal";
import VaccinationEntriesList from "../../Lists/VaccinationEntriesList/VaccinationEntriesList";
import VirtualAppointmentsList from "../../Lists/VirtualAppointmentsList/VirtualAppointmentsList";
import PatientProfileInformation from '../../Components/PatientProfileComponents/ProfileSections/PersonalInformation/PersonalInformation'
import SyncedDeviceDataPointsList from "../../Lists/SyncedDeviceDataPointsList/SyncedDeviceDataPointsList";
import CompanyNotificationReceipts from "../../Components/CompanyNotificationReceipts/CompanyNotificationReceipts";
import AssignMedicationsAllergyModal from "../../Modals/AssignMedicationsAllergyModal/AssignMedicationsAllergyModal";
import HealthCareProviderInformationList from "../../Lists/HealthCareProviderInformationList/HealthCareProviderInformationList";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../Helpers/CompanyHelpers";
import SurveyLinksList from 'Lists/SurveyLinksList/SurveyLinksList';
import SchedulesList from "../../Lists/SchedulesList/SchedulesList";
import AdditionalAppColumnsList from 'Lists/AdditionalAppColumnsList/AdditionalAppColumnsList';

const notifyAddAllergyFail = (aError) => toast(i18next.t('ERROR_ADDING_ALLERGY', {error:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyAllergyAddedSuccess = () => toast(i18next.t('NOTIFY_ALLERGY_ADDED_SUCCESS'), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyAllergyUpdatedSuccess = () => toast(i18next.t('NOTIFY_ALLERGY_UPDATED_SUCCESS'), NOTIFY_OPTS.autoCloseFiveSeconds);

export default class PatientProfile extends React.Component{
  
  // Instance Variables
  
  editProhibitions = ['admin_level_1', 'admin_level_10'];
  
  fifthSectionRef = React.createRef();
  firstSectionRef = React.createRef();
  sixthSectionRef = React.createRef();
  thirdSectionRef = React.createRef();
  fourthSectionRef = React.createRef();
  secondSectionRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      quest:null,
      patient:{},
      userId:null,
      adminId:null,
      paramId:null,
      dataPoint:null,
      deviceName:null,
      dataPointId:null,
      objectiveId:null,
      responsesTo:null,
      activityName:null,
      isDrawerOpen:false,
      paramSection:null,
      totalPayments:0,
      responsesFrom:null,
      firstTabBarKey:'CONTACT_INFO',
      fifthTabBarKey:'CONDITIONS',
      sixthTabBarKey:'TOOL_PATIENT_DATA',
      thirdTabBarKey:'ECONSENTS',
      assignAllergies:false,
      assignDataQuery:false,
      assignNoteError:'',
      currentSurveyId:null,
      fourthTabBarKey:'PATIENTS_UPCOMING_VIRTUAL_APPOINTMENTS',
      secondTabBarKey:'ALERTS',
      assignMedications:false,
      closeDrawerAction:null,
      onDataQueryCreate:null,
      selectedDataQuery:'',
      showSurveyResponses:false,
      assignAllergiesError:'',
      assignDataQueryError:'',
      currentSurveyEditable:true,
      unverifiedFraudEvents:-1,
      assignMedicationsError:'',
      addVirtualAppointments:false,
      currentSurveyToolTitle:null,
      responsesListCollapsed:false,
      surveyResponseDetailsId:null,
      selectedSurveyResponseId:null,
      assignMedicationAllergies:false,
      selectedVirtualAppointment:null,
      upcomingVirtualAppointments:[],
      currentSurveyResponseDetailId:null,
      assignMedicationAllergiesError:'',
      syncedDeviceDataPointsListCollapsed:false
    };
    this.onOpenDrawer = this.onOpenDrawer.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
    this.removeAlternativeContact = this.removeAlternativeContact.bind(this);
    this.showQuestionForDataQuery = this.showQuestionForDataQuery.bind(this);
    this.saveAlternativeContactItem = this.saveAlternativeContactItem.bind(this);
  }
  
  componentDidMount(){
    const {match, loadPatientMinifiedQuests} = this.props;
    
    if(match && loadPatientMinifiedQuests){
      loadPatientMinifiedQuests(match.params.id);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.id && (this.state.patient.id !== this.props.patient.id)){
      this.setState({patient:this.props.patient});
      
      const paramSection = new URLSearchParams(window.location.search).get('section');
      const paramId = new URLSearchParams(window.location.search).get('id');
      
      if(paramSection === 'alert' && this.secondSectionRef){
        this.setState({paramSection:paramSection, paramId:paramId, secondTabBarKey:'ALERTS'}, () => {
          this.secondSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
      else if(paramSection === 'questionnaire' && this.secondSectionRef){
        const surveyId = new URLSearchParams(window.location.search).get('surveyId');
        const surveyResponseId = new URLSearchParams(window.location.search).get('surveyResponseId');
        this.setState({paramSection:paramSection, paramId:paramId, secondTabBarKey:'QUESTIONNAIRE'}, () => {
          if(surveyId !== null && surveyId !== undefined){
            // TODO: Fetch the survey and response with the provided ID's.
            this.onOpenDrawer(surveyId, this.props.patient.id, i18next.t('QUESTIONNAIRE'), true, null, false, surveyResponseId);
          }
          this.secondSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
      else if(paramSection === 'data_query' && this.secondSectionRef){
        this.setState({paramSection:paramSection, paramId:paramId, secondTabBarKey:'DATA_QUERY'}, () => {
          this.secondSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
      else if(paramSection === 'payments' && this.secondSectionRef){
        this.setState({paramSection:paramSection, paramId:paramId, secondTabBarKey:'PAYMENTS'}, () => {
          this.secondSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
      else if(paramSection === 'appointments' && this.fourthSectionRef){
        this.setState({paramSection:paramSection, paramId:paramId, fourthTabBarKey:'PATIENTS_UPCOMING_VIRTUAL_APPOINTMENTS'}, () => {
          this.fourthSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
      else if(paramSection === 'spam' && this.fourthSectionRef){
        this.setState({paramSection:paramSection, paramId:paramId, fourthTabBarKey:'FRAUD_EVENTS_SPAM_TABLE'}, () => {
          this.fourthSectionRef.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
        });
      }
    }
  }
  
  // Methods
  
  updateHandler(){
    this.forceUpdate();
  }
  
  toggleResponsesListCollapsed = (quest, objectiveId, to, from) => {
    this.setState({syncedDeviceDataPointsListCollapsed:false});
    
    if(!this.state.responsesListCollapsed){
      this.setState({quest:quest, objectiveId:objectiveId, responsesTo:to, responsesFrom:from, responsesListCollapsed:true});
    }
    else if(this.state.quest.id === quest.id && this.state.objectiveId === objectiveId){
      this.setState({responsesListCollapsed:false});
    }
    else{
      this.setState({quest:quest, objectiveId:objectiveId, responsesTo:to, responsesFrom:from});
    }
  };
  
  buildResponsesList = (quest, objectiveId, to, from) => {
    let showTitle = false;
    let objective = quest.objectives.find(objective => objective.id === objectiveId);
    let { getFullQuest, patient } = this.props;
    
    if(objective.type === ObjectiveConstants.ObjectiveTypeMedication || objective.type === ObjectiveConstants.ObjectiveTypeRoutine
      || objective.type === ObjectiveConstants.ObjectiveTypeSymptom || objective.type === ObjectiveConstants.ObjectiveTypeVital){
      showTitle = true;
    }
    return (
      <div className='responses-list-container'>
        <ResponsesList quest={quest}
                       showTitle={showTitle}
                       objectiveId={objectiveId}
                       getFullQuest={(questId) => getFullQuest({questId:questId, userId:patient && patient.id, to, from})}
                       rotateObjectives={quest.rotate_objectives}
                       closeEventHandler={this.toggleResponsesListCollapsed}
        />
      </div>
    )
  };
  
  toggleDataPointsListCollapsed = (deviceName, activityName, dataPoint = null) => {
    this.setState({responsesListCollapsed:false});
    
    if(!this.state.syncedDeviceDataPointsListCollapsed){
      this.setState({dataPoint:dataPoint, deviceName:deviceName, activityName:activityName, syncedDeviceDataPointsListCollapsed:true});
    }
    else if(dataPoint && this.state.dataPointId === dataPoint.identifier){
      this.setState({syncedDeviceDataPointsListCollapsed:false});
    }
    else{
      this.setState({dataPoint:dataPoint, deviceName:deviceName, activityName:activityName, dataPointId:dataPoint ? dataPoint.identifier : null});
    }
  };
  
  buildSyncedDeviceDataPointsList = (deviceName, activityName, dataPoint) => {
    return (
      <div className='responses-list-container'>
        <SyncedDeviceDataPointsList dataPoint={dataPoint}
                                    deviceName={deviceName}
                                    activityName={activityName}
                                    closeEventHandler={this.toggleDataPointsListCollapsed}
        />
      </div>
    )
  };
  
  onCloseDrawer() {
    const {currentSurveyId, closeDrawerAction} = this.state;
    
    if(closeDrawerAction){
      closeDrawerAction(currentSurveyId);
    }
    this.setState({isDrawerOpen:false, currentSurveyId:null, currentToolTitle:null, selectedSurveyResponseId:null, currentSurveyResponseDetailId:null, currentSurveyToolTitle:null, closeDrawerAction:null}, () => {
      setTimeout(() => {
        this.setState({showSurveyResponses:false});
      }, 300);
    });
  }
  
  onOpenDrawer(surveyId, userId, toolTitle, editable, aCurrentSurveyResponseDetailId, showSurveyResponses, aSelectedSurveyResponseId, closeDrawerAction){
    this.setState({isDrawerOpen:true, currentSurveyId:surveyId, userId:userId, adminId:this.props.user.id,
      currentSurveyToolTitle:toolTitle, currentSurveyEditable:editable, currentSurveyResponseDetailId:aCurrentSurveyResponseDetailId,
      showSurveyResponses:showSurveyResponses, selectedSurveyResponseId:aSelectedSurveyResponseId, closeDrawerAction:closeDrawerAction});
  }
  
  saveAlternativeContactItem(form){
    return this.props.addAlternativeContactToUser({alternativeContact:form, userId:this.props.patient.id});
  }
  
  removeAlternativeContact(form){
    if(this.props.patient && this.props.patient.id){
      this.props.removeAlternativeContactFromUser({alternativeContact:form, userId:this.props.patient.id});
    }
    else{
      console.error("ERROR: Patient not found");
    }
  }
  
  resetDataQueryModal = (aAssignDataQuery, aOnDataQueryCreate, aSurveyResponseDetailsId) => {
    this.setState({assignDataQueryError:'', assignDataQuery:aAssignDataQuery, onDataQueryCreate:aOnDataQueryCreate, surveyResponseDetailsId:aSurveyResponseDetailsId});
    
    if(aAssignDataQuery === false){
      this.setState({selectedDataQuery:null});
    }
    if(aSurveyResponseDetailsId !== null){
      this.onCloseDrawer();
    }
  };
  
  assignPatientAllergy = (aAllergen, aSeverity, aAllergyId, aId) => {
    this.setState({assignAllergiesError:''});
    this.props.addAllergy(this.props.patient && this.props.patient.id, aAllergen, aSeverity, aAllergyId, aId).then((newResult) => {
      if(newResult && newResult.error){
        this.setState({assignAllergiesError:newResult.error});
        notifyAddAllergyFail(newResult.error);
      }
      else{
        this.setState({assignAllergies:false});
        
        if(aId){
          notifyAllergyUpdatedSuccess();
        }
        else{
          notifyAllergyAddedSuccess();
        }
      }
    })
  };
  
  assignPatientMedicationAllergy = (aName, aSeverity, aAllergyId, aId) => {
    this.setState({assignMedicationAllergiesError:''});
    this.props.addMedicationAllergy(this.props.patient && this.props.patient.id, aName, aSeverity, aAllergyId, aId).then((newResult) => {
      if(newResult && newResult.error){
        this.setState({assignMedicationAllergiesError:newResult.error});
        notifyAddAllergyFail(newResult.error);
      }
      else{
        this.setState({assignMedicationAllergies:false});
        
        if(aId){
          notifyAllergyUpdatedSuccess();
        }
        else{
          notifyAllergyAddedSuccess();
        }
      }
    });
  };
  
  assignPatientDataQuery = ({description, notificationAlert}) => {
    this.setState({assignDataQueryError:''});
    
    this.props.createDataQueryNote(this.props.patient && this.props.patient.id, description, this.state.surveyResponseDetailsId, notificationAlert).then(aResponse => {
      if(this.state.onDataQueryCreate !== null){
        let dataQuery = aResponse.data.data.createDataQueryNoteMutation.data_query_note;
        this.state.onDataQueryCreate(dataQuery);
      }
      this.resetDataQueryModal(false, null, null);
      this.props.fetchPatient({userId:this.props.match.params.id})
    });
  };
  
  showQuestionForDataQuery(aDataQuery){
    let editable = true;
    
    if(this.editProhibitions.includes(roleForCompany(this.props.company))){
      editable = false;
    }
    this.onOpenDrawer(aDataQuery.survey_id, this.props.patient && this.props.patient.id, aDataQuery.survey_name, editable, aDataQuery.survey_response_detail_id, false, aDataQuery.survey_response_id);
  }
  
  // Render
  
  render(){
    const {user, patient, patients, resetPatientsPassword, patientData, updateUser, fetchPatient, fetchPatientData,
      updateUserTool, removeToolFromUser, company, loadCompanyNotificationReceipts, clearCompanyNotificationReceipt,
      syncedDevicesData, fetchSyncedDevicesData, fetchPatientsScroll, addCallNote, getCallNotes,
      updateFeatureToUser, getAndReturnPatientData, getDataQueryNote, getDataQueryNotes, createDataQueryNote,
      resolveDataQueryNote, getDataQueryNoteComments, createDataQueryComment, getQuestionForSurveyResponseDetail,
      updateSurveyResponseLockState, getSurveyResponses, fetchFraudEvents, fetchFraudStatistics,
      markFraudEventAsVerified, markFraudEventAsFraudulent, markFraudStatisticAsVerified, getCallNoteHistories,
      markFraudStatisticAsFraudulent, patientLoadError, removeAllergyFromUser, removeMedicationAllergyFromUser,
      loadPatientContacts, loadPatientHCPContacts, loadPatientAllergiesUsers, loadPatientSymptoms,
      loadPatientMedications, loadPatientConditions, loadPatientGroups, loadPatientMedicationAllergiesUsers,
      groups, addUserToGroup, removeUserFromGroup, fetchCompanyAndPatients, loadPatientMinifiedQuests,
      loadPatientFullQuests, fetchEntries, fetchSurveyVersionCollectionByReference, fetchAllergies,
      fetchMedicationAllergies, fetchQuestsByPatientId, getQuestById, fetchSymptoms, getNotes, removeNote, addNote,
      updateNote, searchPatients, getVirtualAppointments, saveVirtualAppointment, getVideoForVirtualAppointment,
      addHealthCareProviderInformationToUser, removeHealthCareProviderInformationFromUser, removeSymptomFromUser,
      addSymptomToUser, sendSurveyLink, getSurveyLinks, getSurveyLinkById,
      saveSchedule, fetchSchedule, fetchSchedules, removeSchedule, publishSchedule,
      saveScheduleTrigger, createPaymentTrigger, fetchScheduleVersion, removePaymentTrigger,
      updatePaymentTrigger, removeScheduleTrigger, fetchPatientsInSchedule, fetchQuestionsForSurveyById,
      fetchSurveyVersionCollections, fetchScheduleVersionsForSchedule, getUserAdditionalAppColumns, fetchMedicalRecords} = this.props;
    
    const {quest, objectiveId, responsesListCollapsed, responsesTo, responsesFrom, deviceName, activityName, dataPoint,
      syncedDeviceDataPointsListCollapsed, unverifiedFraudEvents, selectedSurveyResponseId, showSurveyResponses,
      isDrawerOpen, currentSurveyId, userId, adminId, currentSurveyEditable, currentSurveyToolTitle, assignDataQuery,
      currentSurveyResponseDetailId, totalPayments, firstTabBarKey, secondTabBarKey, thirdTabBarKey, fourthTabBarKey,
      fifthTabBarKey, sixthTabBarKey} = this.state;
    
    let firstTabBarTabsArray = [{key:'PERSONAL_INFO'}, {key:'CONTACT_INFO'}, {key:'ALTERNATIVE_CONTACT_INFO'}, {key:'HCP_INFO'}, {key:'STUDY_INFO'}];
    
    if(hsFeatureAvailableForCompany(company, 'Covid Vaccinations in Patient Profile')){
      firstTabBarTabsArray.push({key:'COVID_VACCINATIONS'});
    }
    let secondTabBarTabsArray = [{key:'ALERTS', badge:(patient ? patient.number_of_uncleared_company_notification_receipts : 0)},
      {key:'QUESTIONNAIRE'}, {key:'ADMIN_SURVEYS'}, {key:'DATA_QUERY'}, {key:'PAYMENTS', badge:totalPayments}, {key:'CALL_LOG'}, {key:'SURVEY_LINKS'}, {key:'SCHEDULES'}, {key:'ADDITIONAL_APP_COLUMNS'}];
    let thirdTabBarTabsArray = [{key:'FEATURES'}, {key:'ECONSENTS'}, {key:'TOOLS'}, {key:'GROUPS'}];
    
    let fraudEventsCount = 0;
    
    if(patient && patient.number_of_unverified_fraud_events){
      fraudEventsCount = patient.number_of_unverified_fraud_events;
    }
    if(unverifiedFraudEvents !== -1){
      fraudEventsCount = unverifiedFraudEvents;
    }
    let fourthTabBarTabsArray = [{key:'PATIENTS_UPCOMING_VIRTUAL_APPOINTMENTS'}, {key:'CALL_LOG'}, {key:'FRAUD_EVENTS_SPAM_TABLE', badge:fraudEventsCount}];
    let fifthTabBarTabsArray = [{key:'CONDITIONS'}, {key:'MEDICATIONS'}, {key:'SYMPTOMS'}];

    if(hsFeatureAvailableForCompany(company, 'Human API EMR')){
      fifthTabBarTabsArray.push({key:'MEDICAL_RECORDS'});
    }
    fifthTabBarTabsArray.push({key:'ALLERGIES'});
    fifthTabBarTabsArray.push({key:'NOTES'});
    
    let sixthTabBarTabsArray = [{key:'TOOL_PATIENT_DATA'}, {key:'STUDY_SPECIFIC_TOOLS'}, {key:'SYNCED_DEVICES'}];
    
    const currentRole = roleForCompany(company);
    
    const alertsPermissions = ["admin", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_9", "admin_level_10"];
    const firstSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_10", "admin_level_11"];
    const secondSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11"];
    const thirdSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_10", "admin_level_11"];
    const fourthSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_10", "admin_level_11"];
    const fifthSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_10", "admin_level_11"];
    const sixthSectionPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_6", "admin_level_7", "admin_level_8", "admin_level_10", "admin_level_11"];
    const alternativeContactInfoPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const healthCareProviderInformationInfoPermissions = ["admin_level_7", "provider","admin_level_2", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const surveyLinksPermissions = ["admin", "admin_level_2", "admin_level_3", "admin_level_4"];
    const additionalAppColumnsPermissions = ["admin", "admin_level_2", "admin_level_3", "admin_level_4"];
    
    return (
      <div className='patient-profile-main-container' style={{height:'100%'}}>
        <div style={(responsesListCollapsed || syncedDeviceDataPointsListCollapsed) ? {width:'calc(100% - 250px)'} : {width:'100%'}}>
          <SecondaryHeader company={company}
                           patient={patient}
          />
          
          {!(patient && patient.id) ?
            (patientLoadError && patientLoadError.data && patientLoadError.data.error ?
                <div className="patient-profile-loading-spinner-container">
                  {patientLoadError.data.error}
                </div>
                :
                <div className="patient-profile-loading-spinner-container">
                  <Loading/>
                </div>
            )
            :
            <div className="patient-profile-container">
              {firstSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.firstSectionRef = aRef}}>
                  <TabBar tabBarTabsArray={firstTabBarTabsArray}
                          initialSelectedTabKey={firstTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({firstTabBarKey:aKey})}
                  />
                  
                  {firstTabBarKey === 'PERSONAL_INFO' ?
                    <div className="patient-summary-container-short">
                      <PatientProfileInformation patient={patient}
                                                 company={company}
                                                 updateUser={updateUser}
                                                 fetchPatient={fetchPatient}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {firstTabBarKey === 'CONTACT_INFO' ?
                    <div className="patient-summary-container-short">
                      <ContactInformation user={user}
                                          patient={patient}
                                          company={company}
                                          updateUser={updateUser}
                                          addCallNote={addCallNote}
                                          fetchPatient={fetchPatient}
                                          resetPatientsPassword={resetPatientsPassword}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {firstTabBarKey === 'ALTERNATIVE_CONTACT_INFO' ?
                    <div className="patient-summary-container-short-alt">
                      {alternativeContactInfoPermissions.includes(currentRole) ?
                        <div className='personal-information-section-alt'>
                          <AlternativeContactList key={'alternative-contact-list-' + patient && patient.id}
                                                  patient={patient}
                                                  loadPatientContacts={loadPatientContacts}
                                                  fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                  removeAlternativeContact={this.removeAlternativeContact}
                                                  saveAlternativeContactItem={this.saveAlternativeContactItem}
                          />
                        </div>
                        :
                        <div className="no-information-text">
                          {i18next.t('ALTERNATIVE_CONTACT_INFORMATION_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                  
                  {firstTabBarKey === 'HCP_INFO' ?
                    <div className="patient-summary-container-short-alt">
                      {healthCareProviderInformationInfoPermissions.includes(currentRole) ?
                        <div className='personal-information-section-alt'>
                          <HealthCareProviderInformationList key={'health-care-provider-information-list-' + patient && patient.id}
                                                             patient={patient}
                                                             fetchPatient={fetchPatient}
                                                             loadPatientHCPContacts={loadPatientHCPContacts}
                                                             fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                             addHealthCareProviderInformationToUser={addHealthCareProviderInformationToUser}
                                                             removeHealthCareProviderInformationFromUser={removeHealthCareProviderInformationFromUser}
                          />
                        </div>
                        :
                        <div className="no-information-text">
                          {i18next.t('HEALTH_CARE_PROVIDER_INFORMATION_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                  
                  {firstTabBarKey === 'STUDY_INFO' ?
                    <div className="patient-summary-container-short">
                      <ResearchInformation patient={patient}
                                           company={company}
                                           updateUser={updateUser}
                                           fetchPatient={fetchPatient}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {firstTabBarKey === 'COVID_VACCINATIONS' ?
                    <div className="patient-summary-container-vaccination-entries">
                      <VaccinationEntriesList admin={user}
                                              company={company}
                                              patient={patient}
                                              fetchEntries={fetchEntries}
                                              fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
                      />
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
              
              {secondSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.secondSectionRef = aRef}}>
                  <TabBar headingTitle={i18next.t('RESEARCH_IMPLEMENTATION')}
                          tabBarTabsArray={secondTabBarTabsArray}
                          initialSelectedTabKey={secondTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({secondTabBarKey:aKey})}
                  />
                  
                  {secondTabBarKey === 'ALERTS' ?
                    <div className="patient-summary-container-scroll">
                      {alertsPermissions.includes(currentRole) ?
                        <CompanyNotificationReceipts company={company}
                                                     patient={patient}
                                                     isPatientMode={true}
                                                     updateHandler={this.updateHandler}
                                                     explanationKey={'PATIENT_ALERTS_EXPLANATION'}
                                                     scrollableClassName={'alert-cell-container-patient-scroll'}
                                                     showClearedByColumn={true}
                                                     clearCompanyNotificationReceipt={clearCompanyNotificationReceipt}
                                                     loadCompanyNotificationReceipts={loadCompanyNotificationReceipts}
                        />
                        :
                        <div className="no-information-text">
                          {i18next.t('ALERTS_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                  
                  {/* {this.state.secondTabBarIndex === 1 ?
                      <div className="patient-summary-container" style={{paddingLeft: '20px', paddingRight: '20px'}}>
                        <div className='progress-breakdown-title'>Progress Breakdown</div>
                        <div>
                          <span style={{width: `${(patient.active_surveys_count/totalSurveys) * 100}%`, backgroundColor: '#66b033', height: '10px', display: 'inline-block'}}/>
                          <span style={{width: `${(patient.completed_surveys_count/totalSurveys) * 100}%`, backgroundColor: '#2d81c9', height: '10px', display: 'inline-block'}}/>
                          <span style={{width: `${(patient.incomplete_survey_count/totalSurveys) * 100}%`, backgroundColor: '#e02020', height: '10px', display: 'inline-block'}}/>
                        </div>
                        <div style={{marginBottom: '20px'}}>
                          {renderBreakdownTags('Active', patient.active_surveys_count)}
                          {renderBreakdownTags('Completed', patient.completed_surveys_count)}
                          {renderBreakdownTags('Incompleted', patient.incomplete_survey_count)}
                        </div>
                        {[{survey: {name: "Status"}},{complete: patient.email_verified, survey: {name: "Email Validated"}}, ...patient.survey_responses].map(item => (
                            renderTags(item.survey?.name, item?.complete)
                        ))}
                      </div>
                      :
                      null
                    } */}
                  
                  {['QUESTIONNAIRE', 'ADMIN_SURVEYS'].includes(secondTabBarKey) ?
                    <div className="patient-tools-summary-container">
                      <PatientTools className="patient-profile-tools-questionnaire"
                                    company={company}
                                    patient={patient}
                                    toolType={secondTabBarKey === 'QUESTIONNAIRE' ? 'PatientOnly' : 'AdminOnly'}
                                    fetchPatient={fetchPatient}
                                    getQuestById={getQuestById}
                                    onOpenDrawer={this.onOpenDrawer}
                                    explanationKey={secondTabBarKey === 'QUESTIONNAIRE' ? 'QUESTIONNAIRES_EXPLANATION' : 'ADMIN_SURVEYS_LIST'}
                                    sendSurveyLink={sendSurveyLink}
                                    updateUserTool={updateUserTool}
                                    removeToolFromUser={removeToolFromUser}
                                    updateFeatureToUser={updateFeatureToUser}
                                    loadPatientFullQuests={loadPatientFullQuests}
                                    fetchQuestsByPatientId={fetchQuestsByPatientId}
                                    loadPatientHCPContacts={loadPatientHCPContacts}
                                    loadPatientMinifiedQuests={loadPatientMinifiedQuests}
                                    updateSurveyResponseLockState={updateSurveyResponseLockState}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {secondTabBarKey === 'DATA_QUERY' ?
                    <div className="patient-summary-container-data-query-second-tab">
                      <DataQueriesList key={'data-queries-list-' + patient && patient.id}
                                       user={user}
                                       height={'380px'}
                                       company={company}
                                       patient={patient}
                                       isDrawerOpen={isDrawerOpen}
                                       explanationKey={'DATA_QUERY_DESCRIPTION'}
                                       addNoteModalOpen={(onCreate) => {this.resetDataQueryModal(true, onCreate, null)}}
                                       getDataQueryNote={getDataQueryNote}
                                       showAddDataQuery={true}
                                       getDataQueryNotes={getDataQueryNotes}
                                       createDataQueryNote={createDataQueryNote}
                                       resolveDataQueryNote={resolveDataQueryNote}
                                       createDataQueryComment={createDataQueryComment}
                                       getDataQueryNoteComments={getDataQueryNoteComments}
                                       showQuestionForDataQuery={(aDataQuery) => {this.showQuestionForDataQuery(aDataQuery)}}
                                       getQuestionForSurveyResponseDetail={getQuestionForSurveyResponseDetail}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {secondTabBarKey === 'PAYMENTS' ?
                    <div className="patient-summary-container-payments">
                      <PaymentsList height={'350px'}
                                    patient={patient}
                                    explanationKey={'PATIENT_PAYMENTS'}
                                    updateTotalPayments={(aTotalPayments) => this.setState({totalPayments:aTotalPayments})}
                                    {...this.props}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {secondTabBarKey === 'CALL_LOG' ?
                    <CallNotesList className="patient-profile-call-log-container"
                                   user={user}
                                   company={company}
                                   patient={patient}
                                   addCallNote={addCallNote}
                                   getCallNotes={getCallNotes}
                                   explanationKey={'CALL_LOG_DESCRIPTION'}
                                   getCallNoteHistories={getCallNoteHistories}
                    />
                    :
                    null
                  }

                  {secondTabBarKey === 'SURVEY_LINKS' ?
                    <div className="patient-summary-container-payments">
                      {surveyLinksPermissions.includes(currentRole) ?
                        <SurveyLinksList user={patient}
                                         company={company}
                                         patient={patient}
                                         addDataQuery={(aSurveyResponseDetailsId) => this.resetDataQueryModal(true, null, aSurveyResponseDetailsId)}
                                         canMakeEntry={true}
                                         fetchPatient={fetchPatient}
                                         getQuestById={getQuestById}
                                         getSurveyLinks={getSurveyLinks}
                                         getSurveyLinkById={getSurveyLinkById}
                                         getSurveyResponses={getSurveyResponses}
                                         showPatientColumns={false}
                                         updateSurveyResponseLockState={updateSurveyResponseLockState}
                        />
                        :
                        <div className="no-information-text">
                          {i18next.t('SURVEY_LINKS_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                  
                  {secondTabBarKey === 'SCHEDULES' ?
                    <div className="patient-summary-container-payments">
                      <SchedulesList company={company}
                                     patient={patient}
                                     saveSchedule={saveSchedule}
                                     fetchSchedule={fetchSchedule}
                                     fetchSchedules={fetchSchedules}
                                     removeSchedule={removeSchedule}
                                     publishSchedule={publishSchedule}
                                     saveScheduleTrigger={saveScheduleTrigger}
                                     createPaymentTrigger={createPaymentTrigger}
                                     fetchScheduleVersion={fetchScheduleVersion}
                                     removePaymentTrigger={removePaymentTrigger}
                                     updatePaymentTrigger={updatePaymentTrigger}
                                     removeScheduleTrigger={removeScheduleTrigger}
                                     fetchPatientsInSchedule={fetchPatientsInSchedule}
                                     fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                                     fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                     fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {secondTabBarKey === 'ADDITIONAL_APP_COLUMNS' ?
                    <div className="patient-profile-additional-app-columns-container">
                      {additionalAppColumnsPermissions.includes(currentRole) ?
                        <AdditionalAppColumnsList patient={patient} 
                                                  getUserAdditionalAppColumns={getUserAdditionalAppColumns}
                        />
                        :
                        <div className="no-information-text">
                          {i18next.t('ADDITIONAL_APP_COLUMNS_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
              
              {thirdSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.thirdSectionRef = aRef}}>
                  <TabBar headingTitle={i18next.t('APP_CONFIGURATION')}
                          tabBarTabsArray={thirdTabBarTabsArray}
                          initialSelectedTabKey={thirdTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({thirdTabBarKey:aKey})}
                  />
                  
                  {['FEATURES', 'ECONSENTS', 'TOOLS'].includes(thirdTabBarKey) ?
                    <div className="patient-tools-summary-container">
                      <PatientTools className="patient-profile-tools-features-econsents"
                                    company={company}
                                    patient={patient}
                                    toolType={thirdTabBarKey === 'FEATURES' ? 'Features' : (thirdTabBarKey === 'ECONSENTS' ? 'Consent' : 'Survey')}
                                    fetchPatient={fetchPatient}
                                    getQuestById={getQuestById}
                                    onOpenDrawer={this.onOpenDrawer}
                                    updateUserTool={updateUserTool}
                                    removeToolFromUser={removeToolFromUser}
                                    updateFeatureToUser={updateFeatureToUser}
                                    loadPatientFullQuests={loadPatientFullQuests}
                                    fetchQuestsByPatientId={fetchQuestsByPatientId}
                                    loadPatientMinifiedQuests={loadPatientMinifiedQuests}
                                    updateSurveyResponseLockState={updateSurveyResponseLockState}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {thirdTabBarKey === 'GROUPS' ?
                    <div className="patient-summary-container">
                      {alternativeContactInfoPermissions.includes(currentRole) ?
                        <div className='patient-panel-additional-info' style={{height:'auto'}}>
                          <div style={{marginLeft:'20px', marginRight:'20px'}}>
                            <Groups key={'groups-' + patient && patient.id}
                                    groups={groups}
                                    company={company}
                                    patient={patient}
                                    addUserToGroup={addUserToGroup}
                                    loadPatientGroups={loadPatientGroups}
                                    removeUserFromGroup={removeUserFromGroup}
                                    fetchCompanyAndPatients={fetchCompanyAndPatients}
                            />
                          </div>
                        </div>
                        :
                        <div className="no-information-text">
                          {i18next.t('GROUP_INFORMATION_BLOCKED')}
                        </div>
                      }
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
              
              {fourthSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.fourthSectionRef = aRef}}>
                  <TabBar headingTitle={i18next.t('TELECONFERENCING')}
                          tabBarTabsArray={fourthTabBarTabsArray}
                          initialSelectedTabKey={fourthTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({fourthTabBarKey:aKey})}
                  />
                  
                  {fourthTabBarKey === 'PATIENTS_UPCOMING_VIRTUAL_APPOINTMENTS' ?
                    <div className="patient-summary-container-appointments">
                      <VirtualAppointmentsList height={'330px'}
                                               patient={patient}
                                               patients={patients}
                                               searchPatients={searchPatients}
                                               fetchPatientsScroll={fetchPatientsScroll}
                                               getVirtualAppointments={getVirtualAppointments}
                                               saveVirtualAppointment={saveVirtualAppointment}
                                               canAddVirtualAppointment={true}
                                               scrollContainerClassName={"virtual-appointments-list-patient-scroll"}
                                               getVideoForVirtualAppointment={getVideoForVirtualAppointment}
                                               {...this.props}
                      />
                    </div>
                    :
                    null
                  }
  
                  {fourthTabBarKey === 'CALL_LOG' ?
                    <CallNotesList className="patient-profile-call-log-container"
                                   user={user}
                                   company={company}
                                   patient={patient}
                                   addCallNote={addCallNote}
                                   getCallNotes={getCallNotes}
                                   getCallNoteHistories={getCallNoteHistories}
                    />
                    :
                    null
                  }
                  
                  {fourthTabBarKey === 'FRAUD_EVENTS_SPAM_TABLE' ?
                    <div className="patient-summary-container-scroll">
                      <FraudEventsList height={'365px'}
                                       company={company}
                                       patient={patient}
                                       showName={false}
                                       fetchFraudEvents={fetchFraudEvents}
                                       fetchFraudStatistics={fetchFraudStatistics}
                                       markFraudEventAsVerified={markFraudEventAsVerified}
                                       setUnverifiedFraudEvents={(aUnverifiedFraudEvents) => {this.setState({unverifiedFraudEvents:aUnverifiedFraudEvents})}}
                                       markFraudEventAsFraudulent={markFraudEventAsFraudulent}
                                       markFraudStatisticAsVerified={markFraudStatisticAsVerified}
                                       markFraudStatisticAsFraudulent={markFraudStatisticAsFraudulent}
                      />
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
              
              {fifthSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.fifthSectionRef = aRef}}>
                  <TabBar headingTitle={i18next.t('CLINICAL_CARE')}
                          tabBarTabsArray={fifthTabBarTabsArray}
                          initialSelectedTabKey={fifthTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({fifthTabBarKey:aKey})}
                  />
                  
                  {fifthTabBarKey === 'CONDITIONS' ?
                    <div className="patient-summary-container">
                      <Conditions key={'conditions-' + patient && patient.id}
                                  user={this.props.user}
                                  company={company}
                                  patient={patient}
                                  conditions={this.props.conditions}
                                  getConditions={this.props.getConditions}
                                  addConditionToUser={this.props.addConditionToUser}
                                  loadPatientConditions={loadPatientConditions}
                                  removeConditionFromUser={this.props.removeConditionFromUser}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {fifthTabBarKey === 'MEDICATIONS' ?
                    <div className="patient-summary-container">
                      <Medications key={'medications-' + patient && patient.id}
                                   user={user}
                                   company={company}
                                   patient={patient}
                                   loadPatientMedications={loadPatientMedications}
                                   loadPatientMinifiedQuests={loadPatientMinifiedQuests}
                                   removeMedicationFromUser={this.props.removeMedicationFromUser}
                                   assignMedicationsModalOpen={() => this.setState({assignMedications:true})}
                      />
                      
                      {this.state.assignMedications ?
                        <AssignMedicationsModal key={'assign-medications-modal-' + Math.floor(Math.random() * 10000)}
                                                isOpen={this.state.assignMedications}
                                                patient={this.props.patient}
                                                fetchMedicationsWithCountryCode={this.props.fetchMedicationsWithCountryCode}
                                                handleCloseModal={() => this.setState({assignMedications:false})}
                                                addMedicationToUser={this.props.addMedicationToUser}
                                                fetchMedicationMatrix={this.props.fetchMedicationMatrix}
                                                assignMedicationsError={this.state.assignMedicationsError}
                                                addCustomMedicationToUser={this.props.addCustomMedicationToUser}
                        />
                        :
                        null
                      }
                    </div>
                    :
                    null
                  }
                  
                  {fifthTabBarKey === 'SYMPTOMS' ?
                    <div className="patient-summary-container-scroll">
                      <PatientSymptoms key={'patient-symptoms-' + patient ? patient.id.toString() : ''}
                                       user={user}
                                       company={company}
                                       patient={patient}
                                       patientData={patientData}
                                       fetchSymptoms={fetchSymptoms}
                                       addSymptomToUser={addSymptomToUser}
                                       loadPatientSymptoms={loadPatientSymptoms}
                                       removeSymptomFromUser={removeSymptomFromUser}
                      />
                    </div>
                    :
                    null
                  }
  
                  {fifthTabBarKey === 'MEDICAL_RECORDS' ?
                    <div className="patient-summary-container-scroll">
                      <MedicalRecordsList user={user}
                                          company={company}
                                          patient={patient}
                                          fetchMedicalRecords={fetchMedicalRecords}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {fifthTabBarKey === 'ALLERGIES' ?
                    <div className="patient-summary-container">
                      <MedicationAllergies key={'medication-allergies-' + patient && patient.id}
                                           user={this.props.user}
                                           company={company}
                                           patient={patient}
                                           fetchMedicationAllergies={fetchMedicationAllergies}
                                           assignMedicationAllergiesError={this.state.assignMedicationAllergiesError}
                                           assignMedicationAllergyCallback={this.props.addMedicationAllergy}
                                           removeMedicationAllergyFromUser={removeMedicationAllergyFromUser}
                                           assignMedicationAllergiesModalOpen={() => this.setState({assignMedicationAllergies:true})}
                                           loadPatientMedicationAllergiesUsers={loadPatientMedicationAllergiesUsers}
                      />
                      
                      {this.state.assignMedicationAllergies ?
                        <AssignMedicationsAllergyModal key={'assign-medications-allergy-modal-' + Math.floor(Math.random() * 10000)}
                                                       isOpen={this.state.assignMedicationAllergies}
                                                       patient={patient}
                                                       handleCloseModal={() => this.setState({assignMedicationAllergies:false})}
                                                       fetchMedicationAllergies={fetchMedicationAllergies}
                                                       assignMedicationAllergiesError={this.state.assignMedicationAllergiesError}
                                                       assignMedicationAllergyCallback={this.assignPatientMedicationAllergy}
                        />
                        :
                        null
                      }
                      
                      <Allergies key={'allergies-' + patient && patient.id}
                                 user={user}
                                 company={company}
                                 patient={patient}
                                 fetchAllergies={fetchAllergies}
                                 removeAllergyFromUser={removeAllergyFromUser}
                                 assignAllergyCallback={this.props.addAllergy}
                                 assignAllergiesModalOpen={() => this.setState({assignAllergies:true})}
                                 loadPatientAllergiesUsers={loadPatientAllergiesUsers}
                      />
                      
                      {this.state.assignAllergies ?
                        <AssignAllergyModal key={'assign-allergy-modal-' + Math.floor(Math.random() * 10000)}
                                            isOpen={this.state.assignAllergies}
                                            patient={patient}
                                            fetchAllergies={fetchAllergies}
                                            handleCloseModal={() => this.setState({assignAllergies:false})}
                                            assignAllergiesError={this.state.assignAllergiesError}
                                            assignAllergyCallback={this.assignPatientAllergy}
                        />
                        :
                        null
                      }
                    </div>
                    :
                    null
                  }
                  
                  {fifthTabBarKey === 'NOTES' ?
                    <div className="patient-summary-container-scroll-notes">
                      <PatientNotes user={user}
                                    company={company}
                                    patient={patient}
                                    addNote={addNote}
                                    getNotes={getNotes}
                                    removeNote={removeNote}
                                    updateNote={updateNote}
                      />
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
              
              {sixthSectionPermissions.includes(currentRole) ?
                <div ref={(aRef) => {this.sixthSectionRef = aRef}}>
                  <TabBar headingTitle={i18next.t('PATIENT_AND_EXTERNAL_DEVICE_DATA')}
                          tabBarTabsArray={sixthTabBarTabsArray}
                          initialSelectedTabKey={sixthTabBarKey}
                          selectedTabKeyChanged={(aKey) => this.setState({sixthTabBarKey:aKey})}
                  />
                  
                  {sixthTabBarKey === 'TOOL_PATIENT_DATA' ?
                    <div className="patient-summary-container-data">
                      <br/>
                      
                      <div style={{marginLeft:20}} className="manage-patient-container-text">
                        {i18next.t('PATIENT_DATA_EXPLANATION')}
                      </div>
                      
                      <PatientProfileData patient={patient}
                                          company={company}
                                          patientData={patientData}
                                          getFullQuest={(questId, to, from, patientId) => this.props.getFullQuest({questId:questId, userId:patientId, to, from})}
                                          fetchMoreQuests={getAndReturnPatientData}
                                          fetchPatientData={fetchPatientData}
                                          toggleResponsesList={this.toggleResponsesListCollapsed}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {sixthTabBarKey === 'STUDY_SPECIFIC_TOOLS' ?
                    <div className="patient-summary-container-data">
                      <br/>
                      
                      <div style={{marginLeft:20}} className="manage-patient-container-text">
                        {i18next.t('STUDY_SPECIFIC_TOOLS_EXPLANATION')}
                      </div>
                      
                      <PatientProfileData company={company}
                                          patient={patient}
                                          patientData={patientData}
                                          getFullQuest={(questId, to, from, patientId) => this.props.getFullQuest({questId:questId, userId:patientId, to, from})}
                                          fetchMoreQuests={getAndReturnPatientData}
                                          fetchPatientData={fetchPatientData}
                                          toggleResponsesList={this.toggleResponsesListCollapsed}
                      />
                    </div>
                    :
                    null
                  }
                  
                  {sixthTabBarKey === 'SYNCED_DEVICES' ?
                    <div className="patient-summary-container-data">
                      <br/>
                      
                      <div style={{marginLeft:20}} className="manage-patient-container-text">
                        {i18next.t('SYNCED_DEVICES_EXPLANATION')}
                      </div>
                      
                      <PatientSyncedDevices patient={patient}
                                            company={company}
                                            syncedDevicesData={syncedDevicesData}
                                            fetchSyncedDevicesData={fetchSyncedDevicesData}
                                            toggleDataPointsListCollapsed={this.toggleDataPointsListCollapsed}
                      />
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }
            </div>
          }
        </div>
        
        {
          responsesListCollapsed && quest && objectiveId && this.buildResponsesList(quest, objectiveId, responsesTo, responsesFrom)
        }
        
        {
          syncedDeviceDataPointsListCollapsed && dataPoint && this.buildSyncedDeviceDataPointsList(deviceName, activityName, dataPoint)
        }
        
        {assignDataQuery ?
          <AddDataQueryModal key={'add-data-query-modal-' + Math.floor(Math.random() * 10000)}
                             isOpen={assignDataQuery}
                             noteId={this.state.selectedDataQuery && this.state.selectedDataQuery.id}
                             createdAt={this.state.selectedDataQuery && this.state.selectedDataQuery.created_at}
                             description={this.state.selectedDataQuery && this.state.selectedDataQuery.description}
                             assignNoteError={this.state.assignDataQueryError}
                             handleCloseModal={() => this.resetDataQueryModal(false, null, null)}
                             assignNoteCallback={this.assignPatientDataQuery}
                             updateNoteCallback={this.assignPatientDataQuery}
          />
          :
          null
        }
        
        <SurveyDrawer open={isDrawerOpen}
                      title={currentSurveyToolTitle}
                      userId={userId}
                      adminId={adminId}
                      company={company}
                      onClose={this.onCloseDrawer}
                      editable={company && this.editProhibitions.includes(currentRole) ? false : currentSurveyEditable}
                      surveyId={currentSurveyId}
                      addDataQuery={(aSurveyResponseDetailsId) => this.resetDataQueryModal(true, null, aSurveyResponseDetailsId)}
                      fetchPatient={fetchPatient}
                      onFinishedSurvey={this.onCloseDrawer}
                      surveyResponseId={selectedSurveyResponseId}
                      getSurveyResponses={getSurveyResponses}
                      showSurveyResponses={showSurveyResponses}
                      surveyResponseDetailId={currentSurveyResponseDetailId}
                      updateSurveyResponseLockState={updateSurveyResponseLockState}
        />
      </div>
    );
  }
}
