import React from "react";
import {Translation} from "react-i18next";

// NOTE: To add more Rails formatted options, visit: https://apidock.com/ruby/DateTime/strftime

export const railsDatesArray = ['%F', '%d-%b-%Y', '%Y-%m', '%b-%Y', '%Y', '%m', '%d', '%H:%M', '%FT%T%:z', '%FT%R'];

export const railsDateOptionsArray = [
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_YEAR_MONTH_DAY')}</Translation>, value:'%F'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_DAY_MONTH_YEAR')}</Translation>, value:'%d-%b-%Y'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_YEAR_MONTH')}</Translation>, value:'%Y-%m'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_MONTH_YEAR')}</Translation>, value:'%b-%Y'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_YEAR')}</Translation>, value:'%Y'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_MONTH')}</Translation>, value:'%b'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_DAY')}</Translation>, value:'%d'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_TIME')}</Translation>, value:'%H:%M'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_FULL')}</Translation>, value:'%FT%T%:z'},
  {label:<Translation>{(t, {i18n}) => t('DATE_FORMAT_FULL_NO_TIME_ZONE')}</Translation>, value:'%FT%R'}
];
