import actions from 'actions';
import { connect } from 'react-redux';
import ManageSurveyLinks from "../Pages/ManageSurveyLinks/ManageSurveyLinks";

const mapStateToProps = (state, ownProps) => {
  return {
    path:ownProps.match.path,
    user:state.current && state.current.user,
    company:state.company && state.company.company
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getSurveyLinks:(aUserId, aPatientId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getSurveyLinks(aUserId, aPatientId, aLimit, aOffset, aSearchString));
    },
    getSurveyLinkById:(aId) => {
      return dispatch(actions.getSurveyLinkById(aId));
    },
    fetchPatient:({userId}) => {
      dispatch(actions.fetchPatient({userId}));
    },
    getQuestById:(aUserId, aQuestId) => {
      return dispatch(actions.getQuestById(aUserId, aQuestId));
    },
    getSurveyResponses:(aPatientId, aSurveyVersionCollectionId, aLimit, aOffset) => {
      return dispatch(actions.getSurveyResponses(aPatientId, aSurveyVersionCollectionId, aLimit, aOffset));
    },
    updateSurveyResponseLockState:(aSurveyResponseId, aLockedState) => {
      return dispatch(actions.updateSurveyResponseLockState(aSurveyResponseId, aLockedState));
    },
    createDataQueryNote:(patientId, description, surveyResponseDetailId, notificationAlert) => {
      return dispatch(actions.createDataQueryNote(patientId, description, surveyResponseDetailId, notificationAlert));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyLinks)
