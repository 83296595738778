import React from 'react';
import '../../Modals/AssignSymptomsModal/AssignSymptomsModal.scss'
import "antd/dist/antd.css";
import moment from "moment";
import classnames from 'classnames';
import '../../Modals/AddVirtualAppointmentModal/AddVirtualAppointmentModal.scss'
import {Translation} from "react-i18next";
import i18n from "../../i18n";
import i18next from "i18next";
import '../../Custom UI/modalTitleCell.scss';
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Custom UI/Loading/Loading";
import PatientCell from "../../Cells/PatientCell/PatientCell";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {americanDateTimeFormat, momentFromDate} from "../../Helpers/DateHelpers";

const maximumParticipants = 3;
const notifyIncompleteFields = () => toast(<Translation>{ (t, { i18n }) => t('INCOMPLETE_FIELDS_FAILURE') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyAppointmentNotSaved = (alertMessage) => toast(alertMessage, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyMaximumParticipantsReached = () => toast(<Translation>{ (t, { i18n }) => t('MAXIMUM_PARTICIPANTS_REACHED') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifySuccessAddVirtualAppointments = () => toast(<Translation>{ (t, { i18n }) => t('ADD_VIRTUAL_APPOINTMENT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifySuccessEditVirtualAppointments = () => toast(<Translation>{ (t, { i18n }) => t('EDIT_VIRTUAL_APPOINTMENT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class AddVirtualAppointment extends React.Component {
  
  // Instance Variables
  
  scrollManager = new InfiniteScrollManager({
    getItems:(offset, limit) => this.getPatients(offset, limit),
    success:() => this.updateList(null),
    fail:(error) => this.updateList(error),
    limit:22
  });
  
  // Init
  
  constructor(props) {
    super(props);
    const {virtualAppointment} = props;
    
    this.state = {
      id:'',
      user:'',
      notes:virtualAppointment ? virtualAppointment.notes : '',
      title:{
        error:false,
        value:virtualAppointment ? virtualAppointment.title : ''
      },
      end_at:{
        error:false,
        value:virtualAppointment && virtualAppointment.end_at ? momentFromDate(virtualAppointment.end_at) : ''
      },
      start_at:{
        error:false,
        value:virtualAppointment && virtualAppointment.start_at ? momentFromDate(virtualAppointment.start_at) : ''
      },
      patients:[],
      searchString:'',
      totalPatients:0,
      primaryPatientID:'',
      selectedPatientIDs:[],
      addingVirtualAppointment:false
    };

    if(props.primaryPatient && !virtualAppointment){
      this.state.primaryPatientID = props.primaryPatient.id;
      this.selectPatient(props.primaryPatient);
    }
    this.selectPatient = this.selectPatient.bind(this);
    this.saveNewVirtualAppointment = this.saveNewVirtualAppointment.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
  }
  
  componentDidMount(){
    const {virtualAppointment} = this.props;
    this.scrollManager.fetch(true);
    
    if(virtualAppointment){
      const secondaryPatientIDs = [];
      const primaryPatientID = virtualAppointment.primary_participant?.user?.id || '';

      if(virtualAppointment.secondary_participants.length > 0){
        virtualAppointment.secondary_participants.forEach((participant) => {
          secondaryPatientIDs.push(participant?.user?.id);
        });
      }
      const selectedPatientIDs = [primaryPatientID, ...secondaryPatientIDs]

      this.setState({
        id:virtualAppointment.id,
        notes:virtualAppointment.notes,
        title:{
          error:false,
          value:virtualAppointment.title
        },
        end_at:{
          error:false,
          value:momentFromDate(virtualAppointment.end_at)
        },
        start_at:{
          error:false,
          value:momentFromDate(virtualAppointment.start_at)
        },
        primaryPatientID,
        selectedPatientIDs
      });
    }
  }
  
  // Methods

  filterPassedTime = (aTime) => {
        const currentDate = new Date();
        const selectedDate = new Date(aTime);
    
        return currentDate.getTime() < selectedDate.getTime();
      };
  
  updateList(aError){
    this.setState({patients:this.scrollManager.getList()});
    
    if(aError){
      console.error("AddVirtualAppointment (updateList): Failed with error:", aError);
    }
  }
  
  getPatients(offset, limit){
    return this.props.fetchPatientsScroll(this.state.searchString, null, limit, offset).then((newResult) => {
      let usersArray = [];
      let totalPatients = 0;
      
      if(newResult && newResult.data && newResult.data.data && newResult.data.data.user_page){
        usersArray = newResult.data.data.user_page.users;
        totalPatients = newResult.data.data.user_page.total;
      }
      this.setState({totalPatients:totalPatients});
      return {objects:usersArray};
    }, (newError) => {
      this.updateList(newError);
    });
  }
  
  validateAppointmentFields = () => {
    return new Promise((resolve) => {
      const {title, start_at, end_at} = this.state;
      const fields = {title, start_at, end_at};
      const isValid = Object.keys(fields).map((fieldKey) => {
        const valid = !!this.state[fieldKey].value;
        this.setState({
          [fieldKey]: {
            ...this.state[fieldKey],
            error:!valid,
          }
        });
        return valid;
      }).every(isFieldValid => isFieldValid);
      resolve(isValid);
    })
  };
  
  /**
   * Validates the appointment fields. As a side effect,
   * it sets the error states for each corresponding field.
   * @returns {Promise<boolean>} whether the fields are valid
   */
  virtualAppointmentFieldsValid = async () => {
    return await this.validateAppointmentFields();
  };
  
  saveNewVirtualAppointment = async () => {
    const formIsValid = await this.virtualAppointmentFieldsValid();
    
    if(!formIsValid || !this.state.selectedPatientIDs?.length) {
      return notifyIncompleteFields();
    }
    if(!this.state.addingVirtualAppointment){
      this.setState({ addingVirtualAppointment: true });
      
      let start = moment(this.state.start_at.value);
      start.set({second:0, millisecond:0});
      
      let end = moment(this.state.end_at.value);
      end.set({second:0, millisecond:0});
      
      let virtualAppointment = {
        notes:this.state.notes,
        title:this.state.title.value,
        end_at:end.toISOString(),
        start_at:start.toISOString()
      };
      if(this.state.id && this.state.id.length > 0){
        virtualAppointment.id = this.state.id;
      }
      if(this.state.primaryPatientID && this.state.primaryPatientID.length > 0){
        virtualAppointment.primary_participant = {id:this.state.primaryPatientID, user:{id:this.state.primaryPatientID}};
        
        if(this.state.selectedPatientIDs.length > 0){
          let secondaryParticipants = [];
          let primaryPatientID = this.state.primaryPatientID;
          this.state.selectedPatientIDs.forEach(function (patientID){
            if(patientID !== primaryPatientID){
              secondaryParticipants.push({id:patientID, user:{id:patientID}});
            }
          });
          virtualAppointment.secondary_participants = secondaryParticipants;
        }
      }
      else{
        if(this.state.selectedPatientIDs.length > 0){
          let primaryPatientID = this.state.selectedPatientIDs[0];
          virtualAppointment.primary_participant = {id:primaryPatientID, user:{id:primaryPatientID}};
          
          if(this.state.selectedPatientIDs.length > 1){
            let secondaryParticipants = [];
            this.state.selectedPatientIDs.forEach(function (patientID){
              if(patientID !== primaryPatientID){
                secondaryParticipants.push({id:patientID, user:{id:patientID}});
              }
            });
            virtualAppointment.secondary_participants = secondaryParticipants;
          }
        }
      }
      this.props.saveVirtualAppointment(virtualAppointment).then(result => {
        if(this.props.handleCloseModal){
          this.props.handleCloseModal();
        }
        if(this.state.id && this.state.id.length > 0){
          notifySuccessEditVirtualAppointments();
        }
        else{
          notifySuccessAddVirtualAppointments();
        }
        this.setState({
          id:null,
          notes:'',
          title:{value:'', error:false},
          end_at:{value:null, error:false},
          start_at:{value:null, error:false},
          searchString:'',
          selectedPatientIDs:[],
          addingVirtualAppointment:false
        });
        this.getPatients();
        this.scrollManager.fetch(true);
        
        if(this.props.reload){
          this.props.reload();
        }
      }, (errorResponse) => {
        this.handleErrorResponse(errorResponse);
      })
    }
  };
  
  handleErrorResponse(errorResponse){
    let alertMessage = i18next.t('NOT_SAVED');
    
    if(errorResponse.data && errorResponse.data.error){
      alertMessage += errorResponse.data.error;
    }
    this.setState({ addingVirtualAppointment: false });
    notifyAppointmentNotSaved(alertMessage);
  }
  
  handleNotesChange = (aEvent) => this.setState({notes:aEvent.target.value});
  
  handleTitleChange = (aEvent) => this.setState({title:{error:false, value:aEvent.target.value}});
  
  handleEndTimeChange(aEvent){
    if(aEvent.isValid()){
      this.setState({end_at:{error:false, value:aEvent}});
    }
    else{
      this.setState({end_at:{value:''}});
    }
  };
  
  handleStartTimeChange(aEvent){
    if(aEvent.isValid()){
      this.setState({start_at:{error:false,value:aEvent}});
    }
    else{
      this.setState({start_at:{value:''}});
    }
  };
  
  selectPatient(aPatient){
    let selectedPatientIDs = this.state.selectedPatientIDs;
    const patientId = aPatient?.id;

    if(selectedPatientIDs == null){
      selectedPatientIDs = [];
    }
    if(selectedPatientIDs.includes(patientId)){
      const index = selectedPatientIDs.indexOf(patientId);
      
      if(index > -1){
        selectedPatientIDs.splice(index, 1);
      }
      if(patientId === this.state.primaryPatientID){
        this.setState({primaryPatientID : ''})
      }
    }
    else{
      if(selectedPatientIDs.length < maximumParticipants){
        if(this.state.primaryPatientID === ''){
          this.setState({primaryPatientID : patientId})
        }
        selectedPatientIDs.push(patientId);
      }
      else{
        notifyMaximumParticipantsReached();
      }
    }
    this.setState({selectedPatientIDs:selectedPatientIDs});
  }
  
  // Render
  
  render() {
    const {showSaveButton, isEditVirtualAppointment} = this.props;
    const {title, notes, start_at, end_at, totalPatients, selectedPatientIDs} = this.state;
    
    return (
      <div className="add-virtual-appointment-container">
        <LabeledTextInput className="add-virtual-appointment-modal-input"
                          label={<Translation>{ (t, { i18n }) => t('TITLE') }</Translation>}
                          value={title.value}
                          required={true}
                          handleSave={this.handleTitleChange}
                          placeholder={i18n.t('TITLE_HERE')}
                          errorMessage={title.error && i18n.t('SPACED_REQUIRED_FIELD')}
        />
        
        <div className="add-virtual-appointment-modal-input-container">
          <LabeledDatePicker className="add-virtual-appointment-modal-input-small"
                             value={start_at.value ? momentFromDate(start_at.value) : null}
                             label={<Translation>{ (t, { i18n }) => t('START_DATE_PROPER_CAPITALIZED') }</Translation>}
                             maxDate={end_at.value ? end_at.value : moment().add(1, 'year')}
                             minDate={moment()}
                             filterTime={this.filterPassedTime}
                             required={true}
                             dateFormat={americanDateTimeFormat}
                             handleSave={this.handleStartTimeChange}
                             errorMessage={start_at.error && i18n.t('SPACED_REQUIRED_FIELD')}
                             showTimeSelect={true}
                             bold={true}
          />
          
          <LabeledDatePicker className="add-virtual-appointment-modal-input-small"
                             label={<Translation>{ (t, { i18n }) => t('END_DATE_PROPER_CAPITALIZED') }</Translation>}
                             value={end_at.value ? momentFromDate(end_at.value) : null}
                             maxDate={moment().add(1, 'year')}
                             minDate={start_at.value ? start_at.value : moment()}
                             bold={true}
                             required={true}
                             dateFormat={americanDateTimeFormat}
                             handleSave={this.handleEndTimeChange}
                             errorMessage={end_at.error && i18n.t('SPACED_REQUIRED_FIELD')}
                             showTimeSelect={true}
                             popperPlacement={'bottom-end'}
                             filterTime={this.filterPassedTime}
          />
        </div>
        
        <LabeledTextarea className="add-virtual-appointment-modal-input"
                         label={<Translation>{ (t, { i18n }) => t('EXTRA_NOTES') }</Translation>}
                         value={notes}
                         handleSave={this.handleNotesChange}
                         placeholder={i18n.t('NOTES_HERE')}
        />
        
        <div className="add-appointments-title-container">
          <Translation>{ (t, { i18n }) => t('SELECTED_PARTICIPANTS', {aTotal:totalPatients, aMaximum:maximumParticipants, aSelectedTotal:selectedPatientIDs.length}) }</Translation>
          
          <LabeledDelayedInput className="add-virtual-appointment-input-search-patients"
                               value={this.state.searchString}
                               handleSave={(aEvent) => {
                                 this.setState({searchString:aEvent.target.value}, () => {this.scrollManager.fetch(true)})
                               }}
                               showSearchIcon={true}
                               placeholder={i18n.t('SEARCH_PATIENT')}
          />
        </div>
        
        <div className="add-appointment-patient-cell-border"
             id={'add-virtual-appointment-patient-list'}>
          <InfiniteScroll next={() => {this.scrollManager.fetch(false)}}
                          style={{overflowY:"hidden"}}
                          loader={<Loading loading={this.scrollManager.isLoading()}/>}
                          hasMore={!this.scrollManager.hasLoadedAll()}
                          dataLength={() => this.scrollManager.length()}
                          scrollableTarget={'add-virtual-appointment-patient-list'}>
            <div className="add-appointment-patient-cell-container">
              {this.state.patients && this.state.patients.length > 0 ?
                <>
                  {this.state.patients.map((patient) => (
                    <PatientCell className={classnames("add-appointment-patient-cell", {
                        "is-edit-virtual-appointment": isEditVirtualAppointment
                    })}
                                 key={'add-virtual-appointment-patient-cell-' + patient.id}
                                 checked={this.state.selectedPatientIDs.includes((patient.id).toString())}
                                 patient={patient}
                                 checkboxOnChange={() => this.selectPatient(patient)}
                    />
                  ))}
                </>
                :
                <div className="no-information-text">
                  <Translation>{ (t, { i18n }) => t('NO_PATIENTS_FOUND')}</Translation>
                </div>
              }
            </div>
          </InfiniteScroll>
        </div>
        
        <div className="add-virtual-appointment-button-container">
          {showSaveButton ?
            <BlueButton className="modal-button"
                        name={<Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                        onClick={() => this.saveNewVirtualAppointment()}
            />
            :
            null
          }
        </div>
      </div>
    )
  }
}
