import {
  UPDATE_CURRENT_USER_ERROR
} from 'constants/ActionTypes';
import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {fetchUsers} from "./admins";

// TODO: Manage Group button.
export function addUserToGroup({groupId, userId}) {
  EventTracker.track(EventTypes.ADD_USER_TO_GROUP);
  let query = `
  mutation AddUserToGroup($addUserToGroupInput: AddUserToGroupInput!) {
    addUserToGroup(input: $addUserToGroupInput) {
      user {
        id
        email
        number_of_uncleared_company_notification_receipts
        groups {
          id
          name
        }
      }
    }
  }
  `;
  
  let variables = {
    addUserToGroupInput: {
      user_id: userId,
      group_id: groupId
    }
  };
  let operationName = `AddUserToGroup`;
  
  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function removeUserFromGroup({groupId, userId}){
  EventTracker.track(EventTypes.REMOVE_USER_FROM_GROUP);
  let query = `
  mutation RemoveUserFromGroup($removeUserFromGroupInput: RemoveUserFromGroupInput!) {
    removeUserFromGroup(input: $removeUserFromGroupInput) {
      user {
        id
        email
        groups {
          id
          name
        }
      }
    }
  }
  `;
  let variables = { removeUserFromGroupInput: {
      user_id: userId,
      group_id: groupId
    }};
  let operationName = `RemoveUserFromGroup`;
  
  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function addUser(user) {
  EventTracker.track(EventTypes.ADD_USER);
  let query = `
  mutation AddUser($addUserInput: AddUserInput!) {
    addUser(input: $addUserInput) {
      user {
        id
        first_name
        email
      }
    }
  }
  `;
  let variables = { addUserInput: user };
  let operationName = `AddUser`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables['addUserInput']['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries",
      { query, variables, operationName }
    );
  };
}

export function removeUser(id) {
  EventTracker.track(EventTypes.REMOVE_USER);
  let query = `
  mutation RemoveUser($removeUserInput: RemoveUserInput!) {
    removeUser(input: $removeUserInput) {
      user {
        id
        first_name
        email
      }
    }
  }
  `;
  let variables = {
    removeUserInput: {
      id: id
    }
  };
  let operationName = `RemoveUser`;
  
  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((result) => {
      dispatch(fetchUsers())
    }).catch((error) => {
    });
  };
}

export function updateUser(user) {
  EventTracker.track(EventTypes.UPDATE_USER);
  let query = `
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      user {
        id
        first_name
        email
        subject_status
        test_account
        can_trigger_alerts
        password_update_requested
      }
    }
  }
  `;
  let variables = { updateUserInput: user };
  let operationName = `UpdateUser`;
  
  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).catch((error) => {
      dispatch({
        type: UPDATE_CURRENT_USER_ERROR,
        result: error.data.error
      });
      return error;
    })
  };
}

export function updateUserRole({userId, role}){
  let query = `
  mutation UpdateUserRoleMutation($updateUserRoleMutationInput: UpdateUserRoleMutationInput!) {
    updateUserRole(input: $updateUserRoleMutationInput) {
      user {
        id
        first_name
        email
        role
        groups {
          id
          name
        }
      }
    }
  }
  `;
  let variables = { updateUserRoleMutationInput: {
      user_id: userId,
      role: role
    }
  };
  let operationName = `UpdateUserRoleMutation`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables["updateUserRoleMutationInput"]["company_id"] = company && company.company && company.company.id
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  }
}

export function updateUserGroups({userId, groups}){
  let query = `
  mutation UpdateAdminGroupsMutation($updateAdminGroupsMutationInput: UpdateAdminGroupsMutationInput!) {
    updateAdminGroups(input: $updateAdminGroupsMutationInput) {
      user {
        id
        first_name
        email
        role
      }
    }
  }
  `;
  let variables = { updateAdminGroupsMutationInput: {
      user_id: userId,
      groups: groups
    }
  };
  let operationName = `UpdateAdminGroupsMutation`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables["updateAdminGroupsMutationInput"]["company_id"] = company && company.company && company.company.id;
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  }
}

export function inviteUserToCompany(aUserId, aGroupId, aRemoveFromDefaultCompany) {
  EventTracker.track(EventTypes.INVITE_USER);
  let query = `
  mutation AddCompanyInviteToUser($addCompanyInviteToUserInput: AddCompanyInviteToUserInput!) {
    addCompanyInviteToUser(input: $addCompanyInviteToUserInput) {
      company_invite {
        id
        email
        accepted
      }
    }
  }
  `;
  let variables = { addCompanyInviteToUserInput: {
      user_id:aUserId
    }};
  let operationName = `AddCompanyInviteToUser`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables['addCompanyInviteToUserInput']['company_id'] = company && company.company && company.company.id;
    variables['addCompanyInviteToUserInput']['remove_from_default_company'] = aRemoveFromDefaultCompany;
    
    if(aGroupId){
      variables['addCompanyInviteToUserInput']['group_id'] = aGroupId;
    }
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  };
}

export function resendInviteToUser(companyInviteId){
  let query = `
    mutation ResendCompanyInviteMutation($resendCompanyInviteMutationInput: ResendCompanyInviteMutationInput!) {
      resendCompanyInvite(input: $resendCompanyInviteMutationInput) {
        company_invite {
          id
          email
          accepted
        }
      }
    }
    `;
  let variables = { resendCompanyInviteMutationInput: {
      company_invite_id: companyInviteId
    }};
  let operationName = `ResendCompanyInviteMutation`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables['resendCompanyInviteMutationInput']['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  }
}

export function addUserToCompany({userId, role}) {
  EventTracker.track(EventTypes.ADD_USER_TO_COMPANY);
  let query = `
  mutation AddUserToCompany($addUserToCompanyInput: AddUserToCompanyInput!) {
    addUserToCompany(input: $addUserToCompanyInput) {
      user {
        id
        email
        groups {
          id
          name
        }
      }
    }
  }
  `;
  let variables = { addUserToCompanyInput: {
      user_id: userId,
      role
    }};
  let operationName = `AddUserToCompany`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables['addUserToCompanyInput']['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  };
}

export function removeUserFromCompany(userId) {
  EventTracker.track(EventTypes.REMOVE_USER_FROM_COMPANY)
  let query = `
  mutation RemoveUserFromCompany($removeUserFromCompanyInput: RemoveUserFromCompanyInput!) {
    removeUserFromCompany(input: $removeUserFromCompanyInput) {
      user {
        id
        email
        groups {
          id
          name
        }
      }
    }
  }
  `;
  let variables = { removeUserFromCompanyInput: {
      user_id: userId,
    }};
  let operationName = `RemoveUserFromCompany`;
  
  return (dispatch, getState) => {
    const { company } = getState();
    variables['removeUserFromCompanyInput']['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  };
}
