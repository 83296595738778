export class ObjectiveConstants {
  static ObjectiveTypeMedication = "MedicationObjective";
  static ObjectiveTypeRoutine = "RoutineObjective";
  static ObjectiveTypeSymptom = "SymptomObjective";
  static ObjectiveTypeVital = "VitalObjective";
  static ObjectiveTypeMultipleChoice = "MultipleChoiceObjective";
  static ObjectiveTypeText = "TextObjective";
  static ObjectiveAppointment = "AppointmentObjective";
  static ObjectiveTypeImage = "ImageObjective";
  static ObjectiveContent = "ContentObjective";
  static ObjectiveTypeSlider = "SliderObjective";
  static ObjectiveTypeHealthyDose = "HealthyDoseObjective";
}
