import React from 'react';
import './SchedulesList.scss';
import Icon from "antd/lib/icon";
import Info from "../../Custom UI/Info/Info";
import {toast} from "react-toastify";
import i18next from "i18next";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import ScheduleModal from "../../Modals/ScheduleModal/ScheduleModal";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {momentFromDateTime} from "../../Helpers/DateHelpers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {scheduleIsLocked, scheduleLastUpdatedByUser, scheduleVersionNumber} from "../../Helpers/ScheduleHelpers";

const notifyScheduleRemovedError = (aError) => toast(i18next.t('SCHEDULE_REMOVED_ERROR', {aError:aError}), NOTIFY_OPTS.autoClose);
const notifyScheduleRemovedSuccess = () => toast(i18next.t('SCHEDULE_REMOVED'), NOTIFY_OPTS.autoClose);

export default class SchedulesList extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      shouldReload:false,
      shouldRemove:false,
      deactivatedOnly:false,
      scheduleToRemove:null,
      selectedSchedule:null,
      scheduleToReplace:null,
      showScheduleModal:false
    };
  }
  
  renderScheduleStatus(aSchedule){
    let locked = scheduleIsLocked(aSchedule, this.props.user);
    let status = i18next.t('LIVE');
    let version = scheduleVersionNumber(aSchedule, false);
    let lastUpdatedByUser = scheduleLastUpdatedByUser(aSchedule);
    
    if(version === 0){
      status = i18next.t('DRAFT');
    }
    else{
      status += ' (' + version.toString() + ')'
    }
    return (
      <div className="survey-version-collection-cell-status">
        {locked ?
          <div className="survey-version-collection-cell-locked">
            <img alt='adminLocked'
                 src='/imgs/app/admin-locked.svg'
                 className="survey-version-collection-cell-locked-image"
                 style={{width:'30px', height:'30px'}}
            />
            
            <Info message={i18next.t('LOCKED_BY_ADMIN', {aName:formatUserName(lastUpdatedByUser)})}>
              <div className="patient-tools-notes-popover-button">
                <Icon type="ellipsis"/>
              </div>
            </Info>
          </div>
          :
          <div>
            {status}
          </div>
        }
      </div>
    );
  }
  
  // Render
  
  render(){
    const {user, group, company, patient, languages, saveSchedule, fetchSchedule, fetchSchedules, removeSchedule, publishSchedule,
      saveScheduleTrigger, createPaymentTrigger, removePaymentTrigger, updatePaymentTrigger, removeScheduleTrigger,
      fetchPatientsInSchedule, fetchAppAdditionalColumns, fetchQuestionsForSurveyById, fetchSurveyVersionCollections,
      fetchScheduleVersionsForSchedule, fetchQuestionsForSurveyByReference, showGroups = true} = this.props;
    const {shouldReload, shouldRemove, deactivatedOnly, scheduleToRemove, selectedSchedule, scheduleToReplace, showScheduleModal} = this.state;
    
    const role = roleForCompany(company);
    const permissions = ['admin', 'admin_level_2', 'admin_level_4'];
    
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:2, columnNameKey:'TITLE', propertiesArray:['name']});
    
    if(showGroups){
      columnsArray.push({key:'groups', columnWeight:3, columnNameKey:'GROUPS', propertiesArray:['draft_schedule_version', 'groups'], valueFormatter:(aGroups) => {
          let returnValue = '';
      
          if(aGroups && aGroups.length > 0){
            for(let group of aGroups){
              returnValue += group.name + ', ';
            }
            returnValue = returnValue.substring(0, returnValue.length - 2);
          }
          return returnValue;
        }});
    }
    columnsArray.push({key:'updated_at', columnWeight:2, columnNameKey:'SURVEY_DATE', propertiesArray:['updated_at'], valueFormatter:(aDate) => momentFromDateTime(aDate)});
    columnsArray.push({key:'draft_version', columnWeight:1, columnNameKey:'DRAFT_VERSION', templateCell:(aSchedule) => scheduleVersionNumber(aSchedule)});
    columnsArray.push({key:'live_version', columnWeight:1, columnNameKey:'LIVE_VERSION', templateCell:(aSchedule) => this.renderScheduleStatus(aSchedule)});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'EDIT', clickAction:(aObject) => this.setState({selectedSchedule:aObject, showScheduleModal:true})});
    
    if(!deactivatedOnly){
      menuItemsArray.push({title:'DEACTIVATE', clickAction:(aObject) => this.setState({scheduleToRemove:aObject})});
    }
    
    return (
      <div className="schedules-list">
        {permissions.includes(role) ?
          <DynamicList id="schedules-list"
                       didReload={() => this.setState({shouldReload:false})}
                       headerView={
                         <div className="schedules-list-switch">
                           <LabeledSwitch label={<Translation>{ (t, { i18n }) => t('DEACTIVATED_ONLY') }</Translation>}
                                          checked={deactivatedOnly}
                                          onChange={(isChecked) => this.setState({deactivatedOnly:isChecked, shouldReload:true})}
                                          infoBlurb={i18next.t('DEACTIVATED_ONLY_EXPLANATION')}
                                          popoverPlacement={"top"}
                           />
                         </div>
                       }
                       menuCancel={() => this.setState({selectedSchedule:null})}
                       fetchObjects={(aLimit, aOffset, aSearchString) => fetchSchedules(aLimit, aOffset, aSearchString, patient ? patient.id : null, group ? group.id : null, deactivatedOnly)}
                       columnsArray={columnsArray}
                       shouldReload={shouldReload}
                       explanationKey='SCHEDULES_EXPLANATION'
                       menuItemsArray={menuItemsArray}
                       minColumnWidth={100}
                       objectToDelete={shouldRemove ? scheduleToRemove : null}
                       objectToReplace={scheduleToReplace}
                       totalObjectsKey='TOTAL_SCHEDULES'
                       finishedDeletingObject={() => this.setState({shouldRemove:false, scheduleToRemove:null})}
                       responseTotalKeysArray={['data', 'data', 'schedules', 'total']}
                       finishedReplacingObject={() => this.setState({scheduleToReplace:null})}
                       responseObjectKeysArray={['data', 'data', 'schedules', 'schedules']}
          />
          :
          <div className="no-information-text">
            {i18next.t('SCHEDULES_BLOCKED')}
          </div>
        }
        
        {showScheduleModal ?
          <ScheduleModal open={showScheduleModal}
                         user={user}
                         close={() => this.setState({showScheduleModal:false, selectedSchedule:null})}
                         company={company}
                         schedule={selectedSchedule}
                         languages={languages}
                         saveSchedule={saveSchedule}
                         fetchSchedule={fetchSchedule}
                         fetchSchedules={fetchSchedules}
                         publishSchedule={publishSchedule}
                         didUpdateSchedule={(aSchedule) => this.setState({scheduleToReplace:aSchedule})}
                         saveScheduleTrigger={saveScheduleTrigger}
                         createPaymentTrigger={createPaymentTrigger}
                         removePaymentTrigger={removePaymentTrigger}
                         updatePaymentTrigger={updatePaymentTrigger}
                         removeScheduleTrigger={removeScheduleTrigger}
                         fetchPatientsInSchedule={fetchPatientsInSchedule}
                         fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                         fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                         fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                         fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                         fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
          />
          :
          null
        }
        
        <ConfirmationModal title={i18next.t('DEACTIVATED_SCHEDULE')}
                           isOpen={scheduleToRemove !== null}
                           reject={() => this.setState({scheduleToRemove:null})}
                           confirm={() => {
                             removeSchedule(scheduleToRemove.id).then((newResult) => {
                               notifyScheduleRemovedSuccess();
                               this.setState({shouldRemove:true});
                             }, (newError) => {
                               notifyScheduleRemovedError(errorMessageFromServerError(newError));
                               this.setState({scheduleToRemove:null});
                             })
                           }}
                           description={i18next.t('REMOVE_SCHEDULE_DESCRIPTION', {aName:scheduleToRemove ? scheduleToRemove.name : ''})}
        />
      </div>
    )
  }
}
