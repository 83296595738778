import { Component } from "react";
import React from "react";
import { Line } from "react-chartjs-2";
import {momentFromDate} from "../../../Helpers/DateHelpers";

export default class LineGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: "",
      date: "",
    };
  }

  render() {
    let noDataInRange = this.props?.dataPoints?.length === 0;
    if (noDataInRange) {
      console.log("EMPTY");
    }
    let { graphType } = this.props
    return (
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {noDataInRange && (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0",
              top: "0",
              zIndex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "234px",
                height: "72px",
                padding: "20px 20px 20px 21px",
                border: "solid 1px #eeeeee",
                backgroundColor: "#ffffff",
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <b>No data in this date range</b>
              {this.props.latestDataPointDate && (<span className="subsection-heading">Most recent data: {momentFromDate(this.props.latestDataPointDate, 'ddd, MM/DD/YYYY')}</span>)}
            </div>
          </div>
        )}
        <Line
          data={{
            datasets: [
              {
                borderColor: "#2D81C9",
                pointBorderColor: "#2D81C9",
                backgroundColor: "rgba(45, 129, 201, 0.5)",
                fill: true,
                data: this.props.dataPoints,
                pointStyle: "circle",
              },
            ],
          }}
          height={200}
          options={{
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  display: graphType === "health_summary" ? false : true
                },
              ],
              xAxes: [
                {
                  type: "time",
                  time: {
                    unit: "day",
                    displayFormats: {
                      day: "MM/D",
                    },
                    min: this.props.startDate(),
                    max: this.props.endDate(),
                  },
                },
              ],
            },
            spanGaps: true,
            tooltips: {
              callbacks: {
                label: function(tti, data){
                  return graphType === "health_summary" ? data?.datasets[0]?.data[tti?.index]?.health_summary : tti.value
                }
              }
            },
            onClick: (e, element) => {
              element?.length &&
                this.props?.showDataPointDetailView(element[0]?._index);
            },
          }}
        />
      </div>
    );
  }
}
