import React from 'react';
import './DynamicList.scss';
import Menu from "@material-ui/core/Menu";
import {toast} from "react-toastify";
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import MenuItem from "@material-ui/core/MenuItem";
import LoadFailed from "../../Custom UI/LoadFailed/LoadFailed";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import InfiniteScroll from "react-infinite-scroll-component";
import ResizeObserver from "resize-observer-polyfill";
import DynamicListCell from "../../Cells/DynamicListCell/DynamicListCell";
import {momentFromDate} from "../../Helpers/DateHelpers";
import DynamicListHeader from "../../Custom UI/DynamicListHeader/DynamicListHeader";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const menuButtonWidth = 30;

const notifyFetchError = (aError) => toast(<Translation>{ (t, { i18n }) => t('FETCH_FAILED_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class DynamicList extends React.Component {
  
  // Instance Variables
  
  scrollRef = React.createRef();
  containerRef = React.createRef();
  resizeObserver;
  
  scrollManager = new InfiniteScrollManager({
    getItems:(offset, limit) => this.getObjects(limit, offset),
    success:() => this.updateObjectsArray(),
    fail:(aError) => this.getObjectsFailedWithError(aError)
  });
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      total:0,
      width:1024,
      ascending:false,
      searching:false,
      fetchFailed:false,
      selectedRow:-1,
      errorMessage:null,
      objectsArray:[],
      searchString:'',
      selectedColumn:0,
      selectedObject:null,
      menuAnchorElement:null,
      openSectionsArray:[],
      verticalScrollBarVisible:false
    };
    this.menuWidth = this.menuWidth.bind(this);
    this.valueForProperties = this.valueForProperties.bind(this);
    this.applyFiltersToArray = this.applyFiltersToArray.bind(this);
    this.dynamicCellForObject = this.dynamicCellForObject.bind(this);
    this.isShowingVerticalScrollBar = this.isShowingVerticalScrollBar.bind(this);
  }
  
  componentDidMount(){
    const {sectionHeadersArray, limit = 10} = this.props;
    const {openSectionsArray} = this.state;
    
    this.scrollManager.fetchAndReloadWithLimit(true, limit);
    
    if(sectionHeadersArray && sectionHeadersArray.length > 0){
      for(let index = 0; index < sectionHeadersArray.length; index += 1){
        openSectionsArray.push(true);
      }
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      if(Array.isArray(entries) && entries.length > 0){
        const {width} = this.state;
        const entry = entries[0];
        
        if(entry && entry.contentRect && width !== entry.contentRect.width){
          this.setState({width:entry.contentRect.width});
        }
        this.fetchIfNotScrollable();
      }
    });
    this.resizeObserver.observe(this.containerRef.current);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {didReload, downloadCSV, shouldReload, objectToAdd, objectToDelete, objectToReplace, finishedAddingObject,
      finishedDeletingObject, finishedReplacingObject, limit = 10, addObjectToEnd = false, objectToDeleteKey = 'id',
      objectToReplaceKey = 'id'} = this.props;
    const {total} = this.state;
    
    if(shouldReload){
      if(didReload){
        didReload();
      }
      this.setState({errorMessage:null});
      this.scrollManager.fetchAndReloadWithLimit(true, limit);
    }
    if(finishedAddingObject && objectToAdd){
      this.scrollManager.insertItem(objectToAdd, addObjectToEnd ? null : 0);
      finishedAddingObject();
      this.setState({total:total + 1});
      this.updateObjectsArray();
    }
    if(finishedDeletingObject && objectToDelete && objectToDelete[objectToDeleteKey] && objectToDelete[objectToDeleteKey].length > 0){
      this.scrollManager.removeItemMatchingProperty(objectToDelete, objectToDeleteKey);
      finishedDeletingObject();
      this.setState({total:total - 1});
      this.updateObjectsArray();
    }
    if(finishedReplacingObject && objectToReplace && objectToReplace[objectToReplaceKey] && objectToReplace[objectToReplaceKey].length > 0){
      this.scrollManager.replaceItemMatchingProperty(objectToReplace, objectToReplaceKey);
      finishedReplacingObject();
    }
    if(downloadCSV){
      this.downloadToCSV(downloadCSV());
    }
  }
  
  componentWillUnmount(){
    if(this.resizeObserver){
      this.resizeObserver.disconnect();
    }
  }
  
  // Methods
  
  filteredObjectsArray(){
    const {fixedObjectsArray = null, sectionHeadersArray = [], logToConsole = false} = this.props;
    const {objectsArray} = this.state;
    
    let returnValue = [];
  
    if(fixedObjectsArray){
      if(Array.isArray(fixedObjectsArray)){
        returnValue = [];
      
        if(sectionHeadersArray){
          if(sectionHeadersArray.length > 0 && sectionHeadersArray.length === fixedObjectsArray.length){
            for(let index = 0; index < sectionHeadersArray.length; index += 1){
              returnValue.push(this.applyFiltersToArray(fixedObjectsArray[index], index));
            }
          }
          else{
            returnValue = this.applyFiltersToArray(fixedObjectsArray);
          }
        }
        else{
          if(logToConsole){
            console.log('DynamicList (finalObjectsArray): The props sectionHeadersArray has length:', sectionHeadersArray.length, 'is not equal sectioned fixed objects array:', fixedObjectsArray.length, '. Could not render.');
          }
        }
      }
      else{
        returnValue = this.applyFiltersToArray(fixedObjectsArray);
      }
    }
    else{
      returnValue = this.applyFiltersToArray(objectsArray);
    }
    return returnValue;
  }
  
  csvRow(aRow = 0, aObject = {}, aSection = 0){
    const {columnsArray} = this.props;
    let returnValue = '';
    
    for(let index = 0; index < columnsArray.length; index += 1){
      let column = columnsArray[index];
      
      if(column.csvFormatter){
        returnValue += column.csvFormatter(aObject, aRow, aSection);
      }
      else if(column.dateFormat && column.dateFormat.length > 0){
        returnValue += momentFromDate(this.valueForProperties(aObject, column.propertiesArray), column.dateFormat);
      }
      else if(column.valueFormatter){
        returnValue += column.valueFormatter(this.valueForProperties(aObject, column.propertiesArray), aObject, aRow, aSection);
      }
      else{
        returnValue += this.valueForProperties(aObject, column.propertiesArray);
      }
      if(index < columnsArray.length - 1){
        returnValue += ',';
      }
    }
    return returnValue;
  }
  
  downloadToCSV(aName = 'list'){
    const {columnsArray, logToConsole = false, sectionHeadersArray = []} = this.props;
  
    let filteredObjectsArray = this.filteredObjectsArray();
    let csvString = '';
  
    if(Array.isArray(columnsArray)){
      for(let index = 0; index < columnsArray.length; index += 1){
        let column = columnsArray[index];
        
        if(column.customTitle){
          csvString += column.customTitle;
        }
        else{
          csvString += i18next.t(column.key);
        }
        if(index < columnsArray.length - 1){
          csvString += ',';
        }
      }
      csvString += '\n';
      
      if(sectionHeadersArray && sectionHeadersArray.length > 0){
        for(let section = 0; section < sectionHeadersArray.length; section += 1){
          const sectionHeader = sectionHeadersArray[section];
          const objectsArray = filteredObjectsArray[section];
          csvString += i18next.t(sectionHeader.name) + ' - (' + objectsArray.length + ')\n';
          
          for(let row = 0; row < objectsArray.length; row += 1){
            csvString += this.csvRow(row, objectsArray[row], section);
            
            if(row < objectsArray.length - 1){
              csvString += '\n';
            }
          }
          if(section < sectionHeadersArray.length - 1){
            csvString += '\n';
          }
        }
      }
      else{
        for(let row = 0; row < filteredObjectsArray.length; row += 1){
          csvString += this.csvRow(row, filteredObjectsArray[row]);
          
          if(row < filteredObjectsArray.length - 1){
            csvString += '\n';
          }
        }
      }
    }
    else{
      if(logToConsole){
        console.log('DynamicList (downloadToCSV): The props columnsArray:', columnsArray, 'is not an array!');
      }
    }
    const blob = new Blob([csvString], {type:'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = aName + '.csv';
    link.target = "_blank";
    link.style = "display:none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  getObjects(limit, offset){
    const {didFetch, extraObject, fetchObjects, totalObjects, objectsFilter, objectsReduce, fixedObjectsArray,
      responseTotalKeysArray, responseObjectKeysArray, responseExtraObjectKeysArray} = this.props;
    const {ascending, selectedColumn, searchString} = this.state;
    
    if(fetchObjects && responseTotalKeysArray && responseObjectKeysArray){
      return fetchObjects(limit, offset, searchString, selectedColumn, ascending).then((newResponse) => {
        let total = this.valueForProperties(newResponse, responseTotalKeysArray);
        let objectsArray = this.valueForProperties(newResponse, responseObjectKeysArray);
        this.setState({total:total});
        
        if(didFetch){
          didFetch();
        }
        if(totalObjects && !(objectsFilter || objectsReduce)){
          totalObjects(total);
        }
        if(extraObject && responseExtraObjectKeysArray){
          extraObject(this.valueForProperties(newResponse, responseExtraObjectKeysArray));
        }
        this.setState({total:total, searching:false, fetchFailed:false});
        return {objects:objectsArray, total:total};
      }, (newError) => {
        notifyFetchError(errorMessageFromServerError(newError));
        this.setState({searching:false, fetchFailed:true});
        return {objects:[], total:0};
      });
    }
    else{
      if(fixedObjectsArray === undefined){
        console.log('DynamicList (getObjects): Missing props fetchObjects:', fetchObjects, 'and/or responseTotalKeysArray:', responseTotalKeysArray, 'and/or responseObjectKeysArray:', responseObjectKeysArray, '.');
      }
      return Promise.resolve('');
    }
  }
  
  getObjectsFailedWithError(aError){
    const {error} = this.props;
    let errorMessage = null;
    
    if(error){
      errorMessage = error(aError);
    }
    else{
      notifyFetchError(aError);
    }
    console.error('DynamicList (getObjectsFailedWithError): Failed with error:', aError);
    this.setState({fetchFailed:true, errorMessage:errorMessage});
  }
  
  fetchIfNotScrollable(){
    if(!this.isShowingVerticalScrollBar() && !this.scrollManager.hasLoadedAll() && !this.scrollManager.isLoading()){
      const {limit = 10} = this.props;
      this.scrollManager.fetchAndReloadWithLimit(false, limit);
    }
  }
  
  updateObjectsArray(){
    this.setState({errorMessage:null, objectsArray:this.scrollManager.getList()}, () => {
      setTimeout(() => {
        if(!this.isShowingVerticalScrollBar() && !this.scrollManager.hasLoadedAll()){
          this.fetchIfNotScrollable();
        }
      }, 20);
    });
  }
  
  weightSumForColumnsArray(aColumnsArray){
    let returnValue = 0;
    
    if(Array.isArray(aColumnsArray)){
      for(let column of aColumnsArray){
        returnValue += Math.max(column.columnWeight, 1);
      }
    }
    return returnValue;
  }
  
  widthForColumn(aScreenWidth, aColumn, aTotalWeightSum){
    const {maxColumnWidth, minColumnWidth, menuItemsArray} = this.props;
    
    let returnValue = aScreenWidth;
    let weight = 1;
    let useCustomWidth = false;
    
    if(aColumn !== null && aColumn !== undefined){
      weight = Math.max(aColumn.columnWeight, 1);
      
      if(aColumn.width !== null && aColumn.width !== undefined){
        returnValue = Number(aColumn.width);
        useCustomWidth = true;
      }
    }
    if(!useCustomWidth){
      if(Boolean(menuItemsArray)){
        returnValue = returnValue - menuButtonWidth;
      }
      returnValue = returnValue / Math.max(aTotalWeightSum, 1);
      
      if(aColumn && aColumn.minWidth){
        returnValue = Math.max(returnValue, aColumn.minWidth);
      }
      if(minColumnWidth !== null && minColumnWidth !== undefined){
        returnValue = Math.max(returnValue, minColumnWidth);
      }
      returnValue = returnValue * weight;
      
      if(maxColumnWidth !== null && maxColumnWidth !== undefined){
        returnValue = Math.min(returnValue, maxColumnWidth);
      }
    }
    return returnValue;
  }
  
  valueForProperties(aObject, aPropertiesArray){
    const {logToConsole = false} = this.props;
    let returnValue = '';
    
    if(aObject){
      if(Array.isArray(aPropertiesArray)){
        let cascadedObject = aObject;
        
        for(let index = 0; index < aPropertiesArray.length; index += 1){
          let property = aPropertiesArray[index];
          cascadedObject = cascadedObject[property];
          
          if(cascadedObject === null || cascadedObject === undefined){
            if(index < (aPropertiesArray.length - 1)){
              if(logToConsole){
                console.log('DynamicList (valueForProperties): Properties Array:', aPropertiesArray, 'at index:', index, 'has an invalid property:', property, '.');
              }
            }
            cascadedObject = null;
            break;
          }
        }
        if(cascadedObject !== null && cascadedObject !== undefined){
          returnValue = cascadedObject;
        }
      }
      else{
        if(logToConsole){
          console.log('DynamicList (valueForProperties): Properties Array provided:', aPropertiesArray, 'is not an array.');
        }
      }
    }
    else{
      if(logToConsole){
        console.log('DynamicList (valueForProperties): Object provided:', aObject, 'is invalid.');
      }
    }
    return returnValue;
  };
  
  dynamicCellForObject = (aObject, aIndex, aTotalWidth, aShouldClampFirstColumn, aColumnConfigurationsArray, aSection) => {
    const {reloadCell, selectCell, reloadDelay, isCellLoading, menuItemsArray, showMenu = true, showBorder = false, borderWidth = 0.5} = this.props;
    const {selectedRow, selectedColumn} = this.state;
    
    let showMenuIcon = false;
    
    if(menuItemsArray && menuItemsArray.length > 0){
      for(let menuItem of menuItemsArray){
        if(menuItem.isValid === null || menuItem.isValid === undefined || menuItem.isValid(aObject)){
          showMenuIcon = true;
          break;
        }
      }
    }
    return <DynamicListCell className="dynamic-list-dynamic-list-cell"
                            key={'dynamic-list-cell-' + aObject.id ? aObject.id + '-' + aIndex : aIndex}
                            row={aIndex}
                            width={aTotalWidth}
                            object={aObject}
                            reload={reloadCell}
                            section={aSection}
                            showMenu={showMenu}
                            selectCell={selectCell ?
                              () => {
                                this.setState({selectedRow:aIndex, selectedObject:aObject});
                                selectCell(aObject, aIndex);
                              }
                              :
                              null
                            }
                            isLoading={isCellLoading}
                            showBorder={showBorder}
                            borderWidth={borderWidth}
                            reloadDelay={reloadDelay}
                            columnsArray={aColumnConfigurationsArray}
                            isSelectedRow={selectedRow === aIndex}
                            selectedColumn={selectedColumn}
                            clampFirstColumn={aShouldClampFirstColumn}
                            menuButtonClicked={showMenuIcon ?
                              (aEvent) => this.setState({selectedRow:aIndex, selectedObject:aObject, menuAnchorElement:aEvent.currentTarget})
                              :
                              null
                            }
                            valueForProperties={(aPropertiesArray) => this.valueForProperties(aObject, aPropertiesArray)}
    />
  };
  
  applyFiltersToArray(aArray, aSection = 0){
    const {totalObjects = null, objectsFilter = null, objectsReduce = null, fixedObjectsArray = null} = this.props;
    let returnValue = [...aArray];
    
    if(objectsReduce){
      returnValue = Object.values(returnValue.reduce(objectsReduce, {}));
    }
    if(objectsFilter){
      returnValue = returnValue.filter(objectsFilter);
    }
    if(totalObjects && (objectsFilter || objectsReduce || Array.isArray(fixedObjectsArray))){
      totalObjects(returnValue.length, aSection);
    }
    return returnValue;
  }
  
  selectedColumnChanged = (aColumn, aAscending) => {
    const {ascending, selectedColumn} = this.state;
    const {limit = 10, canSortList = false} = this.props;
    
    // TODO: Add prop to disable this.
    if((ascending !== aAscending) || (selectedColumn !== aColumn)){
      this.setState({ascending:aAscending, selectedColumn:aColumn}, () => {
        if(canSortList){
          this.scrollManager.fetchAndReloadWithLimit(true, limit);
        }
      });
    }
  };
  
  isShowingVerticalScrollBar(){
    const {verticalScrollBarVisible} = this.state;
    let returnValue = false;
    
    if(this.scrollRef && this.scrollRef.current){
      returnValue = this.scrollRef.current.scrollHeight > this.scrollRef.current.clientHeight;
    }
    if(returnValue !== verticalScrollBarVisible){
      this.setState({verticalScrollBarVisible:returnValue});
    }
    return returnValue;
  }
  
  isShowingHorizontalScrollBar(){
    let returnValue = false;
    
    if(this.scrollRef && this.scrollRef.current){
      returnValue = this.scrollRef.current.scrollWidth > this.scrollRef.current.clientWidth;
    }
    return returnValue;
  }
  
  menuWidth(){
    const {menuItemsArray, addColumnButtonClicked} = this.props;
    let returnValue = 0;
    
    if((menuItemsArray && menuItemsArray.length > 0) || addColumnButtonClicked){
      returnValue = menuButtonWidth;
      
      if(this.isShowingVerticalScrollBar()){
        returnValue += 20;
      }
    }
    return returnValue;
  }
  
  // Render
  
  render() {
    const {id, menuCancel, columnsArray, customMenuView, explanationKey, menuItemsArray, addButtonClicked, backButtonClicked,
      addColumnButtonClicked, limit = 10, backKey = 'BACK_PROPER_CAPITALIZED', showTotal = true, headerView = null,
      showBorder = false, canSortList = false, subHeaderView = null, objectsFilter = null, objectsReduce = null,
      showSearchBar = true, searchBarPlaceHolderKey = 'TAP_TO_SEARCH', totalObjectsKey = 'TOTAL', clampFirstColumn = false,
      fixedObjectsArray = null, sectionHeadersArray = [], borderWidth = 0.5, logToConsole = false, preventLeadingSpacesForSearch = false} = this.props;
    const {total, width, searching, fetchFailed, selectedRow, errorMessage, objectsArray, searchString, selectedColumn,
      selectedObject, menuAnchorElement, openSectionsArray, verticalScrollBarVisible} = this.state;
    
    let totalWidth = width;
    let finalWidth = width;
    
    if(verticalScrollBarVisible){
      finalWidth = width - 10;
    }
    let titleObjectsArray = [];
    let columnConfigurationsArray = [];
    
    if(Array.isArray(columnsArray)){
      totalWidth = 0;
      let weightSum = this.weightSumForColumnsArray(columnsArray);
      
      for(let index = 0; index < columnsArray.length; index += 1){
        let column = columnsArray[index];
        let columnWidth = this.widthForColumn(finalWidth, column, weightSum);
        titleObjectsArray.push({key:column.columnNameKey, width:columnWidth, description:column.description, customView:column.customTitleView, customTitle:column.customTitle});
        columnConfigurationsArray.push({type:column.type, width:columnWidth, select:column.select, disabled:column.disabled,
          dateFormat:column.dateFormat, handleSave:column.handleSave, optionsArray:column.optionsArray, csvFormatter:column.csvFormatter,
          templateCell:column.templateCell, valueFormatter:column.valueFormatter, propertiesArray:column.propertiesArray});
        totalWidth = totalWidth + columnWidth;
      }
      if(Boolean(menuItemsArray)){
        totalWidth = totalWidth + menuButtonWidth;
      }
    }
    else{
      if(logToConsole){
        console.log('DynamicList (render): The props columnsArray:', columnsArray, 'is not an array!');
      }
    }
    
    const shouldClampFirstColumn = clampFirstColumn && (totalWidth > finalWidth);
    const filteredObjectsArray = this.filteredObjectsArray();
    
    return (
      <div className="dynamic-list-container"
           ref={this.containerRef}>
        {explanationKey ?
          <div className="dynamic-list-explanation-text">
            <Translation>{(t, {i18n}) => t(explanationKey)}</Translation>
          </div>
          :
          null
        }
        
        <div className="dynamic-list-title-cell">
          {backButtonClicked || showTotal || headerView ?
            <div className="dynamic-list-title-header-view">
              {backButtonClicked ?
                <div className="dynamic-list-title-back-button"
                     onClick={() => backButtonClicked()}>
                  {'<'}&nbsp;<Translation>{(t, {i18n}) => t(backKey)}</Translation>
                </div>
                :
                null
              }
              
              {showTotal ?
                <div className="dynamic-list-title">
                  <Translation>{(t, {i18n}) => t(totalObjectsKey)}</Translation> - {(objectsFilter || objectsReduce || Array.isArray(fixedObjectsArray)) ? filteredObjectsArray.length : total}
                </div>
                :
                null
              }
              
              {headerView ?
                <div className="dynamic-list-header-view">
                  {headerView}
                </div>
                :
                null
              }
            </div>
            :
            null
          }
          
          {showSearchBar ?
            <div className="dynamic-list-title-search-container">
              <LabeledDelayedInput className="dynamic-list-title-list-search"
                                   value={searchString}
                                   loading={searching}
                                   handleSave={(aEvent) => {
                                     this.setState({searching:true, searchString:aEvent.target.value}, () => {
                                       this.scrollManager.fetchAndReloadWithLimit(true, limit);
                                     })
                                   }}
                                   placeholder={i18next.t(searchBarPlaceHolderKey)}
                                   showSearchIcon={true}
                                   preventLeadingSpaces={preventLeadingSpacesForSearch}
                                   addButtonClicked={addButtonClicked ?
                                     () => this.setState({selectedRow:-1, selectedObject:null, menuAnchorElement:null}, () => {
                                       addButtonClicked();
                                     })
                                     :
                                     null
                                   }
              />
            </div>
            :
            null
          }
          
          {!showSearchBar && addButtonClicked ?
            <AddCircleOutlineIcon style={{height:'30px', width:'30px', color:'#2D81C9', marginLeft:'5px', marginTop: '2px'}}
                                  onClick={addButtonClicked}
            />
            :
            null
          }
        </div>
        
        {subHeaderView ?
          <div className="dynamic-list-sub-header-view">
            {subHeaderView}
          </div>
          :
          null
        }
        
        <div className="dynamic-list-item-container"
             id={id + '-objects-list'}
             ref={this.scrollRef}>
          {errorMessage && errorMessage.length > 0 ?
            <div className="no-information-text">
              {errorMessage}
            </div>
            :
            <>
              <DynamicListHeader width={totalWidth}
                                 menuWidth={this.menuWidth()}
                                 showBorder={showBorder}
                                 borderWidth={borderWidth}
                                 canSortList={canSortList}
                                 customMenuView={customMenuView}
                                 addButtonClicked={addColumnButtonClicked}
                                 clampFirstColumn={shouldClampFirstColumn}
                                 titleObjectsArray={titleObjectsArray}
                                 initialSelectedColumn={selectedColumn}
                                 onSelectedColumnChanged={this.selectedColumnChanged}
              />
              
              <div className="dynamic-list-outer-scroll-container">
                {fixedObjectsArray ?
                  <div className="dynamic-list-cell-scroll-container">
                    {sectionHeadersArray.length > 0 ?
                      (sectionHeadersArray.map((aSectionHeader, aSectionIndex) =>
                        <div>
                          <div className="dynamic-list-scroll-title"
                               style={{top:39, width:(totalWidth + this.menuWidth())}}>
                            <div className="dynamic-list-scroll-title-clamp">
                              <div className="dynamic-list-scroll-title-text">
                                {i18next.t(aSectionHeader.name)} - ({filteredObjectsArray[aSectionIndex].length + (aSectionHeader.totalOffset ? aSectionHeader.totalOffset : 0)})
                                
                                {openSectionsArray[aSectionIndex] ?
                                  <FaSortDown className="dynamic-list-header-arrow-down"
                                              onClick={() => {
                                                let array = [...openSectionsArray];
                                                array[aSectionIndex] = false;
                                                this.setState({openSectionsArray:array});
                                              }}
                                  />
                                  :
                                  <FaSortUp className="dynamic-list-header-arrow-up"
                                            onClick={() => {
                                              let array = [...openSectionsArray];
                                              array[aSectionIndex] = true;
                                              this.setState({openSectionsArray:array});
                                            }}
                                  />
                                }
                                
                                {aSectionHeader.showDetails !== undefined ?
                                  <LabeledSwitch className="dynamic-list-scroll-title-switch"
                                                 size={'small'}
                                                 label={i18next.t('SHOW_DETAILS')}
                                                 colour={'#FFFFFF'}
                                                 checked={aSectionHeader.showDetails}
                                                 onChange={(aChecked) => aSectionHeader.showDetailsChanged(aChecked)}
                                                 infoClassName=""
                                                 defaultChecked={true}
                                                 switchPosition={'right'}
                                  />
                                  :
                                  null
                                }
                              </div>
                            </div>
                          </div>
                          
                          {openSectionsArray[aSectionIndex] ?
                            filteredObjectsArray[aSectionIndex].map((aObject, aIndex) => this.dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray, aSectionIndex))
                            :
                            null
                          }
                        </div>
                      ))
                      :
                      (filteredObjectsArray.map((aObject, aIndex) => this.dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray, 0)))
                    }
                  </div>
                  :
                  <>
                    <InfiniteScroll style={{overflow:'unset', width:totalWidth + 'px'}}
                                    next={() => this.scrollManager.fetchAndReloadWithLimit(false, limit)}
                                    loader={<Loading loading={this.scrollManager.isLoading()}/>}
                                    hasMore={!this.scrollManager.hasLoadedAll()}
                                    dataLength={this.scrollManager.totalCount()}
                                    scrollableTarget={id + '-objects-list'}>
                      {filteredObjectsArray && filteredObjectsArray.length > 0 ?
                        <div className="dynamic-list-cell-scroll-container">
                          {filteredObjectsArray.map((aObject, aIndex) => this.dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray, 0))}
                        </div>
                        :
                        null
                      }
                    </InfiniteScroll>
                    
                    {fetchFailed ?
                      <LoadFailed retry={() => this.setState({fetchFailed:false}, () => this.scrollManager.fetchAndReloadWithLimit(false, limit))}
                                  message={i18next.t('FETCH_FAILED')}
                      />
                      :
                      null
                    }
                  </>
                }
              </div>
            </>
          }
        </div>
        
        <Menu id={id + '-dynamic-list-menu'}
              open={Boolean(menuAnchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200))}
              anchorEl={menuAnchorElement}
              keepMounted>
          {menuItemsArray && menuItemsArray.length > 0 ?
            (menuItemsArray.map((aMenuItem) => (
              (aMenuItem.isValid === null || aMenuItem.isValid === undefined || aMenuItem.isValid(selectedObject) ?
                  <MenuItem key={id + '-dynamic-list-menu-item-' + aMenuItem.title}
                            style={{position:'relative'}}
                            onClick={(aEvent) => {
                              aEvent.preventDefault();
                              aEvent.stopPropagation();
                              
                              if(aMenuItem.clickAction){
                                aMenuItem.clickAction(selectedObject, selectedRow, aEvent, menuAnchorElement);
                              }
                              this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200))
                            }}>
                    <Translation>{(t, {i18n}) => t(aMenuItem.title)}</Translation>
                  </MenuItem>
                  :
                  null
              )
            )))
            :
            null
          }
          
          <MenuItem key={id + '-dynamic-list-menu-item-cancel'}
                    style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
            
                      if(menuCancel){
                        menuCancel();
                      }
                      this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200));
                    }}>
            <Translation>{(t, {i18n}) => t('CANCEL')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
