import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function createDataQueryComment(dataQueryNoteId, commentText){
  EventTracker.track(EventTypes.CREATE_DATA_QUERY_COMMENT);
  let query = `
  mutation ($createDataQueryCommentMutationInput: CreateDataQueryCommentMutationInput!){
      createDataQueryCommentMutation(input: $createDataQueryCommentMutationInput){
        data_query_comment {
          id
          user_name
          created_at
          updated_at
          comment_text
          data_query_note {
            id
            user_id
            survey_id
            created_at
            updated_at
            description
            is_resolved
            provider_id
            survey_name
            provider_name
            number_of_comments
            survey_response_id
            survey_question_label
            survey_response_detail_id
            latest_comment {
              id
              user_name
              created_at
              comment_text
            }
            recorded_survey_response_detail {
              key
              other
              value
              other_value
              values {
                key
                value
              }
            }
          }
        }
      }
    }
  `;
  
  let operationName = `CreateDataQueryCommentMutationInput`;
  let params = {};

  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['comment_text'] = commentText;
    params['data_query_note_id'] = dataQueryNoteId;
  
    let variables = {
      createDataQueryCommentMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function createDataQueryNote(patientId, description, surveyResponseDetailId, notificationAlert){
  EventTracker.track(EventTypes.CREATE_DATA_QUERY_NOTE);
  let query = `
  mutation ($createDataQueryNoteMutationInput: CreateDataQueryNoteMutationInput!){
        createDataQueryNoteMutation(input: $createDataQueryNoteMutationInput){
          data_query_note {
            id
            user_id
            survey_id
            created_at
            updated_at
            description
            is_resolved
            provider_id
            survey_name
            provider_name
            survey_question_label
            survey_response_detail_id
          }
        }
      }
  `;
  
  let operationName = `CreateDataQueryNoteMutationInput`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['user_id'] = patientId;
    params['description'] = description;
  
    if(notificationAlert === false){
      params['alert'] = false;
    }

    if(surveyResponseDetailId){
      params['survey_response_detail_id'] = surveyResponseDetailId;
    }
    let variables = {
      createDataQueryNoteMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function resolveDataQueryNote(dataQueryNoteId){
  EventTracker.track(EventTypes.RESOLVE_DATA_QUERY_NOTE);
  let query = `
  mutation ($resolveDataQueryNoteMutationInput: ResolveDataQueryNoteMutationInput!){
        resolveDataQueryNoteMutation(input: $resolveDataQueryNoteMutationInput){
          data_query_note {
            id
            user_id
            survey_id
            created_at
            updated_at
            description
            is_resolved
            provider_id
            survey_name
            provider_name
            number_of_comments
            survey_response_id
            survey_question_label
            survey_response_detail_id
            latest_comment {
              id
              user_name
              created_at
              comment_text
            }
            recorded_survey_response_detail {
              key
              other
              value
              other_value
              values {
                key
                value
              }
            }
          }
        }
      }
  `;
  
  let operationName = `ResolveDataQueryNoteMutationInput`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['data_query_note_id'] = dataQueryNoteId;
    
    let variables = {
      resolveDataQueryNoteMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function getDataQueryNote(dataQueryNoteId){
  EventTracker.track(EventTypes.GET_DATA_QUERY_NOTE);
  let query = `
    query getDataQueryNote ($company_id: ID!, $data_query_note_id: ID) {
      data_query_note (company_id: $company_id, data_query_note_id: $data_query_note_id){
        id
        user_id
        survey_id
        created_at
        updated_at
        description
        is_resolved
        provider_id
        survey_name
        provider_name
        number_of_comments
        survey_response_id
        survey_question_label
        survey_response_detail_id
        latest_comment {
          id
          user_name
          created_at
          comment_text
        }
        recorded_survey_response_detail {
          key
          other
          value
          other_value
          values {
            key
            value
          }
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['data_query_note_id'] = dataQueryNoteId;
    return global.api.post("/queries", { query, variables });
  };
}

export function getDataQueryNotes(aPatientId, aGroupId, aLimit, aOffset, aSearchString = null){
  EventTracker.track(EventTypes.GET_DATA_QUERY_NOTES);
  let query = `
    query getDataQueryNotes ($company_id: ID!, $user_id: ID, $group_id: ID, $limit: Int, $offset: Int, $search_string: String) {
      data_query_notes (company_id: $company_id, user_id: $user_id, group_id: $group_id, limit: $limit, offset: $offset, search_string: $search_string){
      data_query_notes {
          id
          user_id
          survey_id
          created_at
          updated_at
          description
          is_resolved
          provider_id
          survey_name
          provider_name
          number_of_comments
          survey_response_id
          survey_question_label
          survey_response_detail_id
          latest_comment {
            id
            user_name
            created_at
            comment_text
          }
          recorded_survey_response_detail {
            key
            other
            value
            other_value
            values {
              key
              value
            }
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aPatientId){
      variables['user_id'] = aPatientId;
    }
    else{
      if(aGroupId){
        variables['group_id'] = aGroupId;
      }
    }
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    return global.api.post("/queries", { query, variables });
  };
}

export function getDataQueryNoteComments(aDataQueryNoteId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.GET_DATA_QUERY_COMMENTS);
  let query = `
    query getDataQueryComments ($company_id: ID!, $data_query_note_id: ID!, $limit: Int, $offset: Int, $search_string: String) {
      data_query_comments_page (company_id: $company_id, data_query_note_id: $data_query_note_id, limit: $limit, offset: $offset, search_string: $search_string){
        data_query_comments{
          id
          user_name
          created_at
          updated_at
          comment_text
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['data_query_note_id'] = aDataQueryNoteId;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables });
  };
}

export function getQuestionForSurveyResponseDetail(surveyId, surveyResponseDetailId, patientId, aSurveyResponseId){
  let graphQLQuery = `
    mutation GetQuestionBeforeKey($getQuestionBeforeKeyInput: GetQuestionBeforeKeyInput!){
      getQuestionBeforeKey(input: $getQuestionBeforeKeyInput){
        full_survey_response {
          progress
          next {
            title
            question {
              key
              label
              type
              choices
              other
              skip
              nested
              min_value
              max_value
              min_warning
              max_warning
              min_label
              max_label
              default_value
              default_values {
                key
                value
              }
              default_other
            }
            survey_response_details {
              id
              key
              skipped
              created_at
              value
              values {
                key
                value
              }
              other
              other_value
              other_label
              skip
            }
          }
          first_and_last_question {
            key
          }
        }
      }
    }
  `;

  let variables = {
    getQuestionBeforeKeyInput: {
      survey_id: surveyId,
      survey_response_detail_id: surveyResponseDetailId,
      user_id: patientId,
      survey_response_id: aSurveyResponseId
    }
  };

  let operationName = "GetQuestionBeforeKey";

  return (dispatch) => {
    return global.api.post("/queries", {query: graphQLQuery, variables, operationName});
  };
}
