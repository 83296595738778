import React from 'react';
import {Translation} from "react-i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {fullDateTimeFormat} from "../../Helpers/DateHelpers";

export default class DataQueryCommentsModal extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {

    };
  }
  
  // Methods
  
  // Render
  
  render(){
    const {isOpen, dataQuery, handleCloseModal, clickOutsideToClose, getDataQueryNoteComments} = this.props;
    
    let columnsArray = [];
    columnsArray.push({key:'user_name', columnWeight:1, columnNameKey:'PROVIDER', propertiesArray:['user_name']});
    columnsArray.push({key:'comment_text', columnWeight:1, columnNameKey:'TEXT', propertiesArray:['comment_text']});
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'CREATED_AT', propertiesArray:['created_at'], dateFormat:fullDateTimeFormat});
  
    return (
      <GeneralModal title={<Translation>{(t, { i18n }) => t('VIEW_DATA_QUERY_COMMENTS')}</Translation>}
                    isOpen={isOpen}
                    handleSave={() => this.handleGroupCodesHistoryDownload()}
                    noTitleHeading={true}
                    handleCloseModal={handleCloseModal}
                    clickOutsideToClose={clickOutsideToClose}>
        <DynamicList id="data-query-comments-modal"
                     didReload={() => this.setState({shouldReload:false})}
                     menuCancel={() => this.setState({selectedDataQuery:null})}
                     columnsArray={columnsArray}
                     fetchObjects={(aLimit, aOffset, aSearchString) => getDataQueryNoteComments(dataQuery && dataQuery.id, aLimit, aOffset, aSearchString)}
                     responseTotalKeysArray={['data', 'data', 'data_query_comments_page', 'total']}
                     responseObjectKeysArray={['data', 'data', 'data_query_comments_page', 'data_query_comments']}
        />
      </GeneralModal>
    );
  }
}
