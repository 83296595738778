import React from 'react';
import './LanguagePicker.scss'
import {Translation} from "react-i18next";
import {BiDotsVerticalRounded} from "react-icons/bi";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "antd/lib/icon";
import Menu from "@material-ui/core/Menu";

export default class LanguagePicker extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      anchorElement:null
    };
    this.hasTranslation = this.hasTranslation.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.getLanguageForTranslation = this.getLanguageForTranslation.bind(this);
  }
  
  // Methods
  
  hasTranslation(aTranslation){
    const { translations } = this.props;
    let returnValue = false;
    
    if(translations && translations.length > 0){
      for(let translation of translations){
        if(translation.locale === aTranslation.locale){
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  }
  
  menuButtonClicked(aEvent){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({anchorElement:aEvent.currentTarget});
  };
  
  getLanguageForTranslation(aTranslation){
    const { allTranslations } = this.props;
    let returnValue = {title:'', display_name:''};
    
    if(allTranslations && allTranslations.length > 0){
      for(let translation of allTranslations){
        if(translation.locale === aTranslation.locale){
          returnValue = translation;
          break;
        }
      }
    }
    return returnValue;
  }
  
  // Render
  
  render() {
    const { isSmall = false, translations, allTranslations, selectTranslation, selectedTranslation, addOrRemoveTranslation } = this.props;
    const { anchorElement } = this.state;
    
    return (
      <div className="language-picker-container">
        {!isSmall ?
          <div className="tool-subtitle-container">
            <div className="tool-subtitle">
              <div className="language-select-description-title">
                <Translation>{(t, {i18n}) => t('AVAILABLE_LANGUAGE')}</Translation>
              </div>
            </div>
            
            <BiDotsVerticalRounded style={{fontSize:'21px', marginTop:'20px', flex:'0 0 20px'}}
                                   onClick={(aEvent) => this.menuButtonClicked(aEvent)}
            />
          </div>
          :
          null
        }
        
        <div className="translation-select-list">
          {translations && translations.length > 0 && translations.map((translation, index) => {
            return (
              <div key={'translation-select-list-cell-' + index}
                   className={'translation-select-button ' + (translation.locale === selectedTranslation.locale ? 'selected' : '')}
                   onClick={() => selectTranslation(translation, index)}>
                <div className="translation-select-title">
                  {this.getLanguageForTranslation(translation).title}
                </div>
  
                {!isSmall ?
                  <div className="translation-select-name">
                    {this.getLanguageForTranslation(translation).display_name}
                  </div>
                  :
                  null
                }
              </div>
            )
          })}
          
          {isSmall ?
            <BiDotsVerticalRounded style={{fontSize:'21px', marginTop:'0px', flex:'0 0 20px'}}
                                   onClick={(aEvent) => this.menuButtonClicked(aEvent)}
            />
            :
            null
          }
        </div>
  
        <Menu id="simple-menu"
              open={Boolean(anchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({anchorElement:null})}
              anchorEl={anchorElement}
              keepMounted>
          {allTranslations && allTranslations.length > 0 && allTranslations.map((translation, index) => {
            return (
              <MenuItem key={'language-picker-menu-' + this.getLanguageForTranslation(translation).title + '-' + index}
                        onClick={() => {
                          addOrRemoveTranslation(translation);
                          this.setState({anchorElement:null});
                        }}>
                <div className="language-dropdown-item">
                  {this.hasTranslation(translation) ?
                    <Icon type="check"/>
                    :
                    <div className="language-dropdown-spacer"/>
                  }
                  
                  <div className="language-dropdown-text">
                    <div className="language-dropdown-title">
                      {this.getLanguageForTranslation(translation).title}
                    </div>
                    
                    <div className="language-dropdown-display-name">
                      {this.getLanguageForTranslation(translation).display_name}
                    </div>
                  </div>
                </div>
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}
