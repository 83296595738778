import React from "react";
import "../ResponsesList/ResponsesList.scss";
import moment from "moment-timezone";
import VitalFloDataPointDetail from "../../Components/VitalFlo/VitalFloDataPointDetail";
import {namedMonthDateFormat, namedMonthDateTimeFormat, momentFromDate} from "../../Helpers/DateHelpers";

export default class ResponsesList extends React.Component {

  buildCurieAiComponent(dataPoint) {
    const timeZone = moment.tz.guess()
    const getByLabel = displayKey =>
      displayKey === 'spo2' ?
        "SpO2" : displayKey.replace('_', ' ')

    const formatDataPointDate = dateString => moment(new Date(dateString)).tz(timeZone || 'America/New_York').format("MM/D @ hh:mm A z")

    return (<React.Fragment>
      <div className='response-date' key={dataPoint.measured_at}>{formatDataPointDate(dataPoint.measured_at)}</div>
      {
        Object.keys(dataPoint).map((displayKey) => {
          if (!['id', 'measured_at'].includes(displayKey)) {
            return (
              <div key={displayKey}>
                <div className='entry-title' style={{ textTransform: 'capitalize' }}>{getByLabel(displayKey)}</div>
                <div key={displayKey} className='objective-name' style={{ fontWeight: 'bold' }}>{dataPoint[displayKey]}</div>
              </div>);
          } else {
            return <></>
          }
        })}
    </React.Fragment>)
  }

   detailViewContent() {
    let { deviceName, activityName, dataPoint } = this.props;

    if (deviceName === 'VitalFlo') {
      return (<VitalFloDataPointDetail dataPoint={dataPoint} />)
    } else if (deviceName === 'CurieAi') {
      return this.buildCurieAiComponent(dataPoint)
    } else {
      this.getKeysForDisplay(deviceName, activityName).map((displayKey) => {
        return this.buildComponentForKey(dataPoint, displayKey);
      })
    }
  }

  render() {
    let { deviceName, activityName, closeEventHandler } = this.props;
    const navBarHeight = 50;
    const titleHeight = 80;
    const responseBoxStyle = deviceName === 'VitalFlo' ? { width: 400, marginLeft: -150, zIndex: 1 } : {}

   return (
      <div className='response-box' style={responseBoxStyle}>
        <div className='title-bar'>
          <div className='quest-title'>{this.convertToSpaceCase(deviceName)} - {this.convertToSpaceCase(activityName)} </div>
          <div className='close-event-handler' onClick={closeEventHandler}>X</div>
        </div>
        <div style={{ height: `calc(100vh - ${navBarHeight + titleHeight}px)`, overflowY: 'auto' }}>
          <div className='objective-container'>
            {
              this.detailViewContent()
            }
          </div>
        </div>
      </div>
    );
  }

  buildComponentForKey(dataPoint, displayKey) {
    let dateTimeKeys = [
      "systemTime",
      "date",
      "day",
      "timestamp",
      "createdAt",
      "egvsRecordedAt",
      "egvsMaxTime",
      "egvsMinTime",
    ];
    if (dateTimeKeys.includes(displayKey)) {
      return (
        <div className="response-date" key={displayKey}>
          {this.formatDataPointDate(dataPoint[displayKey], displayKey)}
        </div>
      );
    } else if (
      dataPoint.hasOwnProperty(displayKey) &&
      dataPoint[displayKey] != null &&
      dataPoint[displayKey] !== ""
    ) {
      return (
        <div key={displayKey}>
          <div className="entry-title">
            {this.convertToSpaceCase(displayKey)}
          </div>
          <div key={displayKey} className="objective-name">
            {dataPoint[displayKey]}
          </div>
        </div>
      );
    }
  }

  getKeysForDisplay(deviceName, activityName) {
    let keyList = [];

    if (deviceName === "dexcom") {
      keyList = [
        "systemTime",
        "eventType",
        "eventSubType",
        "value",
        "unit",
        "egvsRecordedAt",
        "egvs",
        "egvsUnit",
        "trend",
        "trendRate",
        "egvsMaxTime",
        "egvsMax",
        "egvsMinTime",
        "egvsMin",
      ];
      return keyList;
    }
    switch (activityName) {
      case "activities_summary":
        keyList = [
          "date",
          "steps",
          "distance",
          "calories",
          "unit",
          "light",
          "moderate",
          "vigrous",
        ];
        break;
      case "weight":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "height":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "blood_glucose":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "blood_oxygen":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "blood_pressure":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "bmi":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "body_fat":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "hrv_summary":
        keyList = ["timestamp", "unit", "value"];
        break;
      case "heart_rate_summary":
        keyList = ["date", "unit", "minHR", "maxHR", "restingHR"];
        break;
      case "food":
        keyList = [
          "timestamp",
          "name",
          "type",
          "fat",
          "iron",
          "fiber",
          "sugar",
          "sodium",
          "calcium",
          "protein",
          "calories",
          "vitaminA",
          "vitaminC",
          "potassium",
          "cholesterol",
          "carbohydrate",
          "saturatedFat",
          "monounsaturatedFat",
          "polyunsaturatedFat",
        ];
        break;
      case "sleep_summary":
        keyList = ["day", "timeAsleep", "timeAwake"];
        break;
      case "default":
        keyList = ["timestamp", "unit", "value"];
        break;
      default:
    }
    return keyList;
  }

  formatDataPointDate(dateString, formatKey){
    if(formatKey === "date" || formatKey === "day"){
      return momentFromDate(new Date(dateString), namedMonthDateFormat);
    }
    else{
      return momentFromDate(new Date(dateString), namedMonthDateTimeFormat);
    }
  }

  convertToSpaceCase(string) {
    if (string === "activities_summary") {
      return "Steps";
    }
    return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
}
