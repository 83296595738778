import EventTypes from "constants/EventTypes";
import EventTracker from "eventTracker";

export function sendSurveyLink(aEmail, aUserId, aLocale, aSurveyId, aPatientId, aEmailSubject, aEmailDescription, aSurveyResponseId, aSubjectIdentificationMessage){
  EventTracker.track(EventTypes.SEND_SURVEY_LINK);
  let query = `
    mutation SendSurveyLinkMutation($sendSurveyLinkInput: SendSurveyLinkMutationInput!) {
      sendSurveyLink(input: $sendSurveyLinkInput) {
        survey_link {
          id
          created_at
          updated_at
          email_subject
          email_description
          subject_identification_message
          user {
            id
            first_name
            last_name
          }
          survey {
            id
            name
            is_repeatable
          }
          patient {
            id
            first_name
            last_name
          }
          survey_response {
            id
            progress
            complete
          }
          quest {
            id
            title
            survey_version_collection {
              id
            }
          }
          url
        }
      }
    }
  `;

  let operationName = `SendSurveyLink`;
  let params = {};

  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['survey_id'] = aSurveyId;
    params['email_subject'] = aEmailSubject;
    params['email_description'] = aEmailDescription;

    if(aEmail){
      params['email'] = aEmail;
    }
    if(aLocale){
      params['locale'] = aLocale;
    }
    if(aUserId){
      params['user_id'] = aUserId;
    }
    else{
      params['patient_id'] = aPatientId;
    }
    if(aSurveyResponseId){
      params['survey_response_id'] = aSurveyResponseId;
    }
    if(aSubjectIdentificationMessage){
      params['subject_identification_message'] = aSubjectIdentificationMessage;
    }
    let variables = {
      sendSurveyLinkInput:params
    };
    return global.api.post("/queries", {query:query, variables:variables, operationName:operationName});
  };
}

export function getSurveyLinks(aUserId, aPatientId, aLimit, aOffset, aSearchString = null){
  EventTracker.track(EventTypes.FETCH_SURVEY_LINKS);
  let query = `
    query survey_links ($limit: Int, $offset: Int, $user_id: ID, $company_id: ID, $patient_id: ID) {
      survey_links (limit: $limit, offset: $offset, user_id: $user_id, company_id: $company_id, patient_id: $patient_id) {
        survey_links {
          id
          created_at
          updated_at
          expires_at
          email_subject
          email_description
          subject_identification_message
          user {
            id
            first_name
            last_name
            email
          }
          survey {
            id
            name
            is_repeatable
          }
          patient {
            id
            edc_id
            first_name
            last_name
          }
          survey_response {
            id
            progress
            complete
          }
          quest {
            id
            title
            survey_version_collection {
              id
            }
          }
          url
        }
        total
      }
    }
  `;
  let variables = {};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;

    if(aUserId !== null){
      variables['user_id'] = aUserId;
    }
    if(aPatientId !== null){
      variables['patient_id'] = aPatientId;
    }
    if(aSearchString !== null && aSearchString.length > 0){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", {query:query, variables:variables});
  };
}

export function getSurveyLinkById(aId){
  EventTracker.track(EventTypes.FETCH_SURVEY_LINK);
  let query = `
    query survey_link ($survey_link_id: ID, $company_id: ID) {
      survey_link (survey_link_id: $survey_link_id, company_id: $company_id) {
        id
        created_at
        updated_at
        expires_at
        email_subject
        email_description
        subject_identification_message
        user {
          id
          first_name
          last_name
          email
        }
        survey {
          id
          name
          is_repeatable
        }
        patient {
          id
          edc_id
          first_name
          last_name
        }
        survey_response {
          id
          progress
          complete
        }
        quest {
          id
          title
          survey_version_collection {
            id
          }
        }
        url
      }
    }
  `;
  let variables = {};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['survey_link_id'] = aId;
    return global.api.post("/queries", {query:query, variables:variables});
  };
}