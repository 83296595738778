import React from 'react';
import './ScheduleModal.scss';
import Schedule from "../../Components/Schedule/Schedule";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";

const ScheduleModal = ({open, user, close, company, schedule, languages, saveSchedule, fetchSchedule, fetchSchedules, publishSchedule,
                         didUpdateSchedule, saveScheduleTrigger, createPaymentTrigger, removePaymentTrigger,
                         updatePaymentTrigger, removeScheduleTrigger, fetchPatientsInSchedule, fetchAppAdditionalColumns,
                         fetchQuestionsForSurveyById, fetchSurveyVersionCollections, fetchScheduleVersionsForSchedule,
                         fetchQuestionsForSurveyByReference}) => {
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               close={close}
    //               width={'calc(100vw - 80px)'}
    //               height={'calc(100vh - 174px)'}
    //               showCancelButton={false}>
    <GeneralModal width={'calc(100vw - 30px)'}
                  height={'calc(100vh - 30px)'}
                  isOpen={open}
                  maxWidth={1300}
                  maxHeight={800}
                  showFooter={false}
                  showHeader={false}
                  handleCloseModal={() => close()}
                  maxChildrenHeight={800}>
      <Schedule user={user}
                company={company}
                schedule={schedule}
                languages={languages}
                saveSchedule={saveSchedule}
                fetchSchedule={fetchSchedule}
                fetchSchedules={fetchSchedules}
                publishSchedule={publishSchedule}
                didUpdateSchedule={(aSchedule) => didUpdateSchedule(aSchedule)}
                saveScheduleTrigger={saveScheduleTrigger}
                createPaymentTrigger={createPaymentTrigger}
                removePaymentTrigger={removePaymentTrigger}
                updatePaymentTrigger={updatePaymentTrigger}
                removeScheduleTrigger={removeScheduleTrigger}
                fetchPatientsInSchedule={fetchPatientsInSchedule}
                fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                presentedInsideParentModal={true}
                fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
      />
    </GeneralModal>
  );
};

export default ScheduleModal
