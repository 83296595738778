import React from 'react';
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import '../PatientProfileData/PatientProfileData.scss'
import SyncedDevicesDataControls from '../SyncedDevicesDataControls/SyncedDevicesDataControls'
import PatientSyncedDevicesViz from '../PatientSyncedDevicesViz/PatientSyncedDevicesViz'
import CollapsableSection from '../CollapsableSection/CollapsableSection'
import VitalFloPatientCharts from '../../VitalFlo/VitalFloPatientCharts'
import CurieAiPatientCharts from '../../../Custom UI/CurieAi/CurieAiPatientCharts'
import {Translation} from "react-i18next";
import { DEFAULT_COLORS } from 'constants/Colors'
import {roleForCompany} from "../../../Helpers/CompanyHelpers";

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export default class PatientSyncedDevices extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      numberOfDateRangesBack: 0,
      dateRangeSyncedDevices: '1 Month'
    };
    this.setNumberOfDateRangesBack = this.setNumberOfDateRangesBack.bind(this)
    this.vitalFloLink = this.props.patient && `/patients/${this.props.patient.id}/vital_flo`
  }

  componentDidMount(){
    if(this.props.patient && this.props.patient.id){
      this.props.fetchSyncedDevicesData({ userId: this.props.patient.id, from: this.from(), to: this.to() })
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.numberOfDateRangesBack !== this.state.numberOfDateRangesBack
      || prevState.dateRangeSyncedDevices !== this.state.dateRangeSyncedDevices){
      this.props.fetchSyncedDevicesData({ userId: this.props.patient.id, from: this.from(), to: this.to() })
    }
  }

  // Methods

  from(){
    if(this.state.dateRangeSyncedDevices === '1 Month'){
      return this.to().subtract(1, 'months').startOf('day');
    }
    return this.to().subtract(14, 'days').startOf('day');
  }

  to(){
    if(this.state.dateRangeSyncedDevices === '1 Month'){
      return moment().add(this.state.numberOfDateRangesBack, 'months').endOf('day');
    }
    return moment().add(14 * this.state.numberOfDateRangesBack, 'days').endOf('day');
  }

  handleRangeChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  setNumberOfDateRangesBack(value) {
    if(value <= 0){
      this.setState({ numberOfDateRangesBack: value })
    }
  }

  initializeDates() {
    let result = [];
    let day = this.from();

    while(day <= this.to()){
      result.push(day.format(ISO_DATE_FORMAT));
      day = day.clone().add(1, 'd')
    }
    return result
  }

  getDevicesmapping(){
    return {
      'fitbit' : 'Fitbit',
      '54dc427aaa6b4cb7d6202f01' : 'Fitbit',
      'garmin' : 'Garmin',
      'runkeeper' : 'RunKeeper',
      '54dc427aaa6b4cb7d6202f07' : 'RunKeeper',
      'strava' : 'Strava',
      '54dc427aaa6b4cb7d6202f0a' : 'Strava',
      'myfitnesspal' : 'My Fitness Pal',
      '54dc427aaa6b4cb7d6202f10' : 'My Fitness Pal',
      'Map My Fitness' : 'Map My Fitness',
      '54dc427aaa6b4cb7d6202f08' : 'Map My Fitness',
      'googlefit' : 'Google Fit',
      '55dcac1ba2a64df204e3d5a0' : 'Google Fit',
      'withings' : 'Withings',
      '54dc427aaa6b4cb7d6202f04' : 'Withings',
      'healthkit' : 'Apple Health Kit',
      '5541a9678e7192cb4fb34fed' : 'Apple Health Kit',
      'ihealth' : 'iHealth',
      '54dc427aaa6b4cb7d6202f03' : 'iHealth'
    };
  }

  getDataTypeKeysMapping(){
    return {
      'activities_summary': {points :['date', 'steps', 'calories'], unit: "steps and calories"},
      'weight': {points: ['timestamp', 'value'], unit: 'kg'},
      'height': {points: ['timestamp', 'value'], unit: 'mm'},
      'hrv_summary': {points: ['timestamp', 'value'], unit: 'ms'},
      'blood_glucose': {points: ['timestamp','value'], unit: 'mg/dl'},
      'blood_oxygen': {points: ['timestamp', 'value'], unit: 'spO2(%)'},
      'blood_pressure': {points: ['timestamp', 'systolic', 'diastolic'], unit: 'mmHg'},
      'bmi': {points: ['timestamp', 'value'], unit: 'kg/m2'},
      'body_fat': {points: ['timestamp', 'value'], unit: 'percent'},
      'heart_rate_summary': {points: ['date', 'minHR', 'maxHR', 'restingHR'], unit: 'bpm'},
      'food': {points: ['timestamp', 'calories'], unit: 'calories'},
      'sleep_summary': {points: ['day', 'timeAsleep', 'timeAwake', 'timeInBed'], unit: 'minutes'}
    }
  }

  getAdditionalDexcomSeriesNames(data){
    let seriesNames = [];
    data.forEach(function (record){
      let entry = record.entry

      if(entry.eventType && !seriesNames.includes(entry.eventType)){
        seriesNames.push(entry.eventType);
      }
    });
    return seriesNames;
  }

  mapDataByDevice(devicesData){
    let humanApiData = devicesData.human_api_data
    let keyFormat = "MM/DD HH:mm";
    let dataByDevice = {};
    let dataTypesAndKeys = this.getDataTypeKeysMapping();
    let devices = this.getDevicesmapping();
    if(humanApiData && humanApiData.length > 0){
      humanApiData.forEach(function(dataPoint) {
        let data = dataPoint.data;

        if(data){
          if(devices[data.source] && !dataByDevice.hasOwnProperty(devices[data.source])){
            dataByDevice[devices[data.source]] = {};
          }
          if(dataByDevice[devices[data.source]] && !dataByDevice[devices[data.source]].hasOwnProperty(dataPoint.data_type)){
            if(dataTypesAndKeys.hasOwnProperty(dataPoint.data_type)){
              dataByDevice[devices[data.source]][dataPoint.data_type] = {unit: dataTypesAndKeys[dataPoint.data_type]['unit'], data: [], labels: []};
            }
          }
          if(dataByDevice[devices[data.source]][dataPoint.data_type]){
            dataTypesAndKeys[dataPoint.data_type]['points'].forEach(function(point, pointIndex){
              if(pointIndex === 0){
                dataByDevice[devices[data.source]][dataPoint.data_type]['labels'].push(moment(new Date(data[point])).format(keyFormat));
              }
              else{
                if(!dataByDevice[devices[data.source]][dataPoint.data_type]['data'][pointIndex-1]){
                  dataByDevice[devices[data.source]][dataPoint.data_type]['data'][pointIndex-1] = [];
                }
                if(parseInt(data[point]) === 0){
                  dataByDevice[devices[data.source]][dataPoint.data_type]['data'][pointIndex-1].push(null);
                }
                else{
                  dataByDevice[devices[data.source]][dataPoint.data_type]['data'][pointIndex-1].push(data[point]);
                }
              }
            });

            if(!dataByDevice[devices[data.source]][dataPoint.data_type].hasOwnProperty('series')){
              dataByDevice[devices[data.source]][dataPoint.data_type]['series'] = [];
              dataTypesAndKeys[dataPoint.data_type]['points'].forEach( function(point, pointIndex){

                if(pointIndex > 0){
                  dataByDevice[devices[data.source]][dataPoint.data_type]['series'].push(point);
                }
              });
            }
            if(!dataByDevice[devices[data.source]][dataPoint.data_type].hasOwnProperty('raw_entry')){
              dataByDevice[devices[data.source]][dataPoint.data_type]['raw_entry'] = [];
            }
            let datetimeKey = (data.timestamp) ? data.timestamp : ((data.date) ? data.date : ((data.day) ? data.day : data.createdAt))
            data.identifier = devices[data.source] + "-" + dataPoint.data_type + "-" + datetimeKey;
            dataByDevice[devices[data.source]][dataPoint.data_type]['raw_entry'].push(data);
          }
        }
      });
    }
    if(devicesData.dexcom_data && devicesData.dexcom_data.length > 0){
      dataByDevice['dexcom'] = this.mapDexcomData(devicesData.dexcom_data);
    }
    return dataByDevice;
  }

  mapDexcomData(dexcomData){
    let additional_series_name = this.getAdditionalDexcomSeriesNames(dexcomData);
    let result = {
      gathered_data: {},
      labels: [],
      series: [],
      data: [],
      options_for_objective: [],
      options_for_data: [],
      raw_entry: []
    };
    let keyFormat = "MM/DD HH:mm";
    result.series = ['Estimated Glucose Value', 'Min Glucose Value', 'Max Glucose Value'].concat(additional_series_name);
    result.series.forEach(function(name){
      result.gathered_data[name] = [];
    });
    result.emptyLabels = [];

    if(dexcomData){
      for(let dexcomDataIndex = 0; dexcomDataIndex <=  dexcomData.length -1; dexcomDataIndex++){
        let entry = dexcomData[dexcomDataIndex].entry
        result.labels.push(moment(new Date(entry.systemTime)).format(keyFormat));
        result.emptyLabels.push(moment(new Date(entry.systemTime)).format(keyFormat));
        result.series.forEach(function(name){
          if(name === entry.eventType && dexcomData[dexcomDataIndex].entry_type === 'event'){
            result.gathered_data[name][dexcomDataIndex] = parseInt(entry.value);
          }
          else{
            result.gathered_data[name][dexcomDataIndex] = null;
          }
        });

        if(dexcomData[dexcomDataIndex].entry_type === 'event'){
          result.gathered_data['Estimated Glucose Value'][dexcomDataIndex] = entry.egvs;
        }
        else{
          result.gathered_data['Estimated Glucose Value'][dexcomDataIndex] = entry.value;
        }
        result.gathered_data['Max Glucose Value'][dexcomDataIndex] = entry.egvsMax ? entry.egvsMax : null;
        result.gathered_data['Min Glucose Value'][dexcomDataIndex] = entry.egvsMin ? entry.egvsMin : null;
        entry.identifier = "dexcom-egvs-"+entry.systemTime;
        result.raw_entry.push(entry);
      }
    }
    result.series.forEach(function (name){
      if(result.gathered_data[name]){
        result.data.push(result.gathered_data[name]);
      }
    });
    return result;
  }

  // Render

  render() {
    const {company, patient, syncedDevicesData, toggleDataPointsListCollapsed} = this.props;
    let dataByDevices = {};

    if(syncedDevicesData){
      dataByDevices = this.mapDataByDevice(syncedDevicesData)
    }
    const currentRole = roleForCompany(company);
    const syncedDevicesPermissions = ['admin', 'admin_level_3', 'admin_level_4'];

    return (
      <div>
        {syncedDevicesPermissions.includes(currentRole) ? (
          <div>
            <div className="patient-profile-data-graphs">
              <div className="patient-profile-data-controls">
                <SyncedDevicesDataControls numberOfDateRangesBack={this.state.numberOfDateRangesBack}
                                           from={this.from()}
                                           to={this.to()}
                                           set={this.setNumberOfDateRangesBack}
                                           range={this.state.dateRangeSyncedDevices}
                                           handleRangeSelect={this.handleRangeChange}
                />
              </div>
              {Object.keys(dataByDevices).length > 0 &&
              (Object.keys(dataByDevices).map(function(deviceName){
                let emptyData = true;

                if(dataByDevices[deviceName] && Object.keys(dataByDevices[deviceName]).length > 0){
                  emptyData = false
                }
                return (
                  <PatientSyncedDevicesViz key={deviceName + "-" + emptyData}
                                           device={deviceName}
                                           patient={patient}
                                           deviceData={dataByDevices[deviceName]}
                                           emptyData={emptyData}
                                           toggleDataPointsListCollapsed={toggleDataPointsListCollapsed}
                  />
                )
              }))
              }
            </div>
            <div className="flex-column" style={{flex: 1, marginBottom: 50}}>
              {
                syncedDevicesData?.vital_flo_data?.data && (
                  <div className="flex-column" style={{flex: 1}}>
                    <CollapsableSection title="VitalFlo" bgColor="white" headerColor={DEFAULT_COLORS.PANEL_HEAD_BG}>
                      <VitalFloPatientCharts patient={patient}
                                             from={this.from.bind(this)}
                                             to={this.to.bind(this)}
                                             vitalFloData={syncedDevicesData.vital_flo_data}
                                             toggleDataPointsListCollapsed={toggleDataPointsListCollapsed}
                      />
                    </CollapsableSection>
                  </div>)
              }
              {
                syncedDevicesData?.curie_ai_data?.data && (
                  <div className="flex-column" style={{flex: 1}}>
                    <CollapsableSection title="CurieAi" bgColor="white" headerColor={DEFAULT_COLORS.PANEL_HEAD_BG}>
                      <CurieAiPatientCharts patient={patient}
                                            from={this.from.bind(this)}
                                            to={this.to.bind(this)}
                                            curieAiData={syncedDevicesData.curie_ai_data}
                                            toggleDataPointsListCollapsed={toggleDataPointsListCollapsed}
                      />
                    </CollapsableSection>
                  </div>)
              }
            </div>
          </div>)
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('SYNCED_DEVICES_BLOCKED')}</Translation>
          </div>
        }
      </div>
    );
  }
}
