import EventTracker from '../eventTracker'
import EventTypes from '../constants/EventTypes'
import {loadPatientMedications} from "./patient";

export function fetchMedications(aSearchString){
  let query = `
      query getMedications($query:String!) {
        medications(query:$query) {
          drugbank_pcid
          name
          standing
          level
          hits {
            value
          }
          ingredients {
            name
            drug {
              name
              drugbank_id
            }
          }
        }
      }
      `;
  
  return (dispatch) => {
    return global.api.post("/queries", {
      query: query,
      variables: {
        query: aSearchString || ''
      }
    });
  };
}

export function fetchMedicationMatrix(aMedicationId){
  let query = `
      query getMedicationMatrix($query:String!) {
        medicationMatrix(query:$query) {
          concept_id
          generic_options
          products {
            combined_id
            name
            prescribable_name
            medication_ingredients {
              name
              drugbank_id
              strength {
                number
                unit
              }
            }
          }
          strengths {
            field
            value
            product_ids
            strengths
            routes
            dosage_forms
          }
          routes {
            field
            value
            product_ids
            strengths
            routes
            dosage_forms
          }
          dosage_forms {
            field
            value
            product_ids
            strengths
            routes
            dosage_forms
          }
        }
      }
      `;
  
  return (dispatch) => {
    return global.api.post("/queries", {
      query:query,
      variables:{
        query:aMedicationId || ''
      }
    });
  };
}

export function fetchMedicationsWithCountryCode(aQuery, aMedCountryCode, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_MEDICATIONS_WITH_COUNTRY_CODE);
  let query = `
    query getMedications($query: String!, $country_code: String, $limit: Int, $offset: Int){
      medications_page(query: $query, country_code: $country_code, limit: $limit, offset: $offset){
        medications{
          drugbank_pcid
          name
          standing
          level
          hits {
            value
          }
          ingredients {
            name
            drug {
              name
              drugbank_id
            }
          }
        }
        total
      }
    }
  `;
  
  let variables = {};
  
  return () => {
    variables['query'] = aQuery ? aQuery : '';
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['country_code'] = aMedCountryCode;
    return global.api.post("/queries", {query, variables});
  };
}

export function addMedicationToUser({medicationId, userId, extraNotes, questId, reminders, quantity, concept_id, objective_id, strength, route, dosage_form}) {
  EventTracker.track(EventTypes.ADD_MEDICATION_TO_USER)
  let query = `
  mutation ($addMedicationToUserInput: AddMedicationToUserInput!) {
    addMedicationToUser(input: $addMedicationToUserInput) {
      objective {
        id
        medication{
          id
          name
        }
        reminders {
          id
          time
        }
      }
    }
  }
  `;

  let variables = {
    addMedicationToUserInput: {
      medication_id: medicationId,
      user_id: userId,
      quest_id: questId,
      reminders: reminders,
      extra_notes: extraNotes,
      quantity: quantity,
      concept_id: String(concept_id),
      strength:strength,
      route:route,
      dosage_form:dosage_form
    }
  }
  if(objective_id){
    variables.addMedicationToUserInput.objective_id = objective_id
  }
  let operationName = `AddMedicationToUser`

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadPatientMedications(userId))
    })};
}


export function addCustomMedicationToUser({customMedicationName, userId, extraNotes, questId, reminders, quantity, objective_id, strength, route, dosage_form}) {
  EventTracker.track(EventTypes.ADD_CUSTOM_MEDICATION_TO_USER)
  let query = `
  mutation ($addMedicationToUserInput: AddMedicationToUserInput!) {
    addMedicationToUser(input: $addMedicationToUserInput) {
      objective {
        id
        medication{
          id
          name
        }
        reminders {
          id
          time
        }
        medication_administration_date
        medication_dose_amount
        medication_dose_unit
        medication_amount_taken
      }
    }
  }
  `;
  
  let variables = {
    addMedicationToUserInput: {
      medication_name:customMedicationName,
      user_id:userId,
      quest_id:questId,
      reminders:reminders,
      extra_notes:extraNotes,
      quantity:quantity,
      strength:strength,
      route:route,
      dosage_form:dosage_form
    }
  }
  if(objective_id){
    variables.addMedicationToUserInput.objective_id = objective_id
  }
  let operationName = `AddCustomMedicationToUser`

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadPatientMedications(userId))
    })
    };
}
