import React from 'react';
import './Loading.scss'
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import {Translation} from "react-i18next";

const Loading = ({className, id, size, label, style, loading = true, showLabel = true, textPosition = 'top'}) => {
  
  // Render
  
  return (
    (loading ?
        <div className={'loading-container-' + (['left', 'right', 'bottom'].includes(textPosition) ? textPosition : 'top') + ' ' + className}
             id={id}
             style={style}>
          {showLabel ?
            <div className="loading-text">
              {label ?
                label
                :
                <Translation>{(t, {i18n}) => t('LOADING_WITH_ELLIPSIS')}</Translation>
              }
            </div>
            :
            null
          }
          
          <Loader size={['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive'].includes(size) ? size : 'medium'}
                  active={true}
                  inline={true}
          />
        </div>
        :
        null
    )
  );
};

export default Loading;
