import React, {useRef, useState} from "react";
import './closeStudyModals.scss';
import GeneralModal from "Modals/GeneralModal/GeneralModal";
import LabeledTextInput from "Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import WhiteButton from 'Custom UI/Buttons/WhiteButton/WhiteButton';
import BlueButton from 'Custom UI/Buttons/BlueButton/BlueButton';
import SignaturePad from 'react-signature-canvas';
import i18next from "i18next";
import {Translation, withTranslation} from "react-i18next";
import {emailIsValid} from "Helpers/AdminHelpers";
import LabeledErrors from "Custom UI/LabeledInputs/LabeledErrors/LabeledErrors";

const CloseStudyConfirmationModal = ({isOpen, showConfirmation=true, ...props}) => {

  // Instance Variables
  let signaturePadRef = useRef(null);

  const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);
  const [showConfirmationBody, setShowConfirmationBody] = useState(showConfirmation);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [signaturePadError, setSignaturePadError] = useState('');
  const [adminEmailError, setAdminEmailError] = useState('');
  const [adminPasswordError, setAdminPasswordError] = useState('');

  // Methods

  const handleCloseModal = () => {
    const {handleCloseModal} = props;

    if (handleCloseModal){
      handleCloseModal();
    }
    setShowConfirmationBody(false);
  };

  const checkAdminEmailError = () => {
    if (!emailIsValid(adminEmail)){
      setAdminEmailError(i18next.t('INVALID_EMAIL_REQUEST_MESSAGE'))
    }
    else {
      setAdminEmailError('')
    }
  }

  const checkAdminPasswordError = () => {
    if(adminPassword){
      setAdminPasswordError('');
    }
    else {
      setAdminPasswordError(i18next.t('PASSWORD_REQUIRED'));
    }
  }

  const checkSignaturePadError = () => {
    if(isSignaturePadEmpty){
      setSignaturePadError(i18next.t('CONSENT_ALERT'));
    }
    else {
      setSignaturePadError('');
    }
  }

  const handleCloseStudyButtonClick = () => {
    const {closeStudy} = props;

    checkAdminEmailError();
    checkAdminPasswordError();
    checkSignaturePadError();

    if (closeStudy && emailIsValid(adminEmail) && adminPassword && !isSignaturePadEmpty){
      const dataURL = signaturePadRef.current.getCanvas().toDataURL('image/png');
      const binary = atob(dataURL.split(',')[1]);
      const array = [];

      for(let index = 0; index < binary.length; index++){
        array.push(binary.charCodeAt(index));
      }

      const closeoutSignature =  new Blob([new Uint8Array(array)], {type:'image/png'});

      closeStudy(closeoutSignature, adminEmail, adminPassword);
    }
  };


  const handleClearSignaturePad = () => {
    if(signaturePadRef.current && signaturePadRef.current.clear) {
      signaturePadRef.current.clear();
      setSignaturePadError(i18next.t('CONSENT_ALERT'));
    }
    setIsSignaturePadEmpty(true);
  }

  return (
    <GeneralModal maxwidth={567}
                  maxHeight={600}
                  showHeader={true}
                  isOpen={isOpen}
                  noTitleHeading={true}
                  showSaveButton={false}
                  showCancelButton={false}
                  handleCloseModal={handleCloseModal}>
                  {showConfirmationBody ?
                    <div className="close-study-confirmation">
                      <div className="close-study-confirmation-warning">
                        <Translation>{(t, {i18n}) => t('CLOSE_STUDY_CONFIRMATION')}</Translation>
                        &nbsp;
                        <span>
                          <Translation>{(t, {i18n}) => t('CLOSE_STUDY_CONFIRMATION_WARNING')}</Translation>
                        </span>
                      </div>
            
                      <div className="close-study-confirmation-button-container">
                        <BlueButton onClick={() => setShowConfirmationBody(false)}
                                    name={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                        />
            
                        <BlueButton onClick={handleCloseModal}
                                    name={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
                        />
                      </div>
                    </div>
                    :
                    <div className="close-study-auth">
                      <div className="close-study-auth-header">
                        <Translation>{(t, {i18n}) => t('CLOSE_STUDY_HEADER')}</Translation>
                      </div>
                
                      <div className="close-study-auth-body">
                        <LabeledTextInput type={'email'}
                                          value={adminEmail}
                                          label={<Translation>{(t, {i18n}) => t('ADMIN_EMAIL')}</Translation>}
                                          onBlur={checkAdminEmailError}
                                          required={true}
                                          className="close-study-auth-input"
                                          handleSave={(aEvent) => setAdminEmail(aEvent.target.value)}
                                          placeholder={i18next.t('TAP_TO_WRITE')}
                                          errorMessage={adminEmailError}
                        />
                
                        <LabeledTextInput required={true}
                                          type={'password'}
                                          value={adminPassword}
                                          label={<Translation>{(t, {i18n}) => t('ADMIN_PASSWORD')}</Translation>}
                                          onBlur={checkAdminPasswordError}
                                          className="close-study-auth-input"
                                          handleSave={(aEvent) => setAdminPassword(aEvent.target.value)}
                                          placeholder={i18next.t('TAP_TO_WRITE')}
                                          errorMessage={adminPasswordError}
                        />
                      </div>
                
                      <div className="close-study-auth-signature-pad" >
                        <div className="close-study-auth-signature-pad-header">
                          <Translation>{(t, {i18n}) => t('ADMIN_SIGNATURE')}</Translation>*
                        </div>
                
                        <div className="close-study-auth-signature-pad-input-container">
                          <SignaturePad ref={(aRef) => signaturePadRef.current = aRef}
                                        onEnd={() => setSignaturePadError('')}
                                        onBegin={() => setIsSignaturePadEmpty(false)}
                                        canvasProps={{
                                          className:'survey-signature-pad close-study-auth-signature-pad-input',
                                        }}
                                        clearOnResize={false}
                          />

                        <LabeledErrors errorMessage={signaturePadError} />
                        {isSignaturePadEmpty ? 
                            <div className="close-study-auth-signature-pad-input-placeholder">
                              <Translation>{(t, {i18n}) => t('CLICK_TO_SIGN')}</Translation>
                            </div>
                            : null
                          }
                        </div>
                      </div>
                
                      <div className="close-study-auth-button-container">
                        <WhiteButton onClick={handleClearSignaturePad}
                                     name={<Translation>{(t, {i18n}) => t('CLEAR_SIGNATURE')}</Translation>}
                        />
                
                        <BlueButton onClick={handleCloseStudyButtonClick}
                                    name={<Translation>{(t, {i18n}) => t('CLOSE_STUDY')}</Translation>}
                        />
                      </div>
                    </div>
                  }
    </GeneralModal>
  );
};

export default withTranslation()(CloseStudyConfirmationModal);