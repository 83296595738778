import React, {useEffect, useState} from 'react'
import './ManageSchedules.scss'
import TabBar from '../../Custom UI/TabBar/TabBar';
import Schedule from "../../Components/Schedule/Schedule";
import {Translation} from "react-i18next";
import SchedulesList from "../../Lists/SchedulesList/SchedulesList";
import SecondaryHeader from '../../Custom UI/Headers/SecondaryHeader/SecondaryHeader';
import {roleForCompany} from "../../Helpers/CompanyHelpers";

const ManageSchedules = ({user, company, languages, saveSchedule, fetchLocales, fetchSchedule, fetchSchedules, removeSchedule,
                           publishSchedule, saveScheduleTrigger, createPaymentTrigger, fetchScheduleVersion, removePaymentTrigger,
                           updatePaymentTrigger, removeScheduleTrigger, fetchPatientsInSchedule, fetchAppAdditionalColumns,
                           fetchQuestionsForSurveyById, fetchSurveyVersionCollections, fetchScheduleVersionsForSchedule,
                           fetchQuestionsForSurveyByReference}) => {
  
  // Instance Variables
  
  const role = roleForCompany(company);
  const createScheduleTabPermissions = ['admin', 'admin_level_2', 'admin_level_4'];
  
  const [tabBarTabsArray] = useState([]);
  const [tabBarKey, setTabBarKey] = useState('SCHEDULES');
  const [hasSetTabFromParams, setHasSetTabFromParams] = useState(false);
  
  if(!hasSetTabFromParams){
    setHasSetTabFromParams(true);
    const paramTab = new URLSearchParams(window.location.search).get('tab');
    
    // TODO: Set params tab accordingly.
    // if(paramTab && paramTab >= 0 && paramTab <= 3){
    //   setTabBarKey(Number(paramTab));
    // }
  }
  
  // Methods
  
  useEffect(() => {
    fetchLocales();
  }, []);
  
  useEffect(() => {
    setTabBarTabsArray();
  }, [company]);
  
  const setTabBarTabsArray = () => {
    if(company){
      tabBarTabsArray.length = 0;
      tabBarTabsArray.push({key:'SCHEDULES'});
      
      if(createScheduleTabPermissions.includes(role)){
        tabBarTabsArray.push({key:'CREATE_SCHEDULE'});
      }
    }
  };
  
  if(tabBarTabsArray.length === 0){
    setTabBarTabsArray();
  }
  
  // Render
  
  return (
    <div className='main-panel-inner-container'>
      <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('SCHEDULES') }</Translation>}/>
  
      <TabBar tabBarTabsArray={tabBarTabsArray}
              initialSelectedTabKey={'SCHEDULES'}
              selectedTabKeyChanged={(aKey) => setTabBarKey(aKey)}
      />
      
      {tabBarKey === 'SCHEDULES' ?
        <SchedulesList user={user}
                       company={company}
                       languages={languages}
                       saveSchedule={saveSchedule}
                       fetchSchedule={fetchSchedule}
                       fetchSchedules={fetchSchedules}
                       removeSchedule={removeSchedule}
                       publishSchedule={publishSchedule}
                       saveScheduleTrigger={saveScheduleTrigger}
                       createPaymentTrigger={createPaymentTrigger}
                       fetchScheduleVersion={fetchScheduleVersion}
                       removePaymentTrigger={removePaymentTrigger}
                       updatePaymentTrigger={updatePaymentTrigger}
                       removeScheduleTrigger={removeScheduleTrigger}
                       fetchPatientsInSchedule={fetchPatientsInSchedule}
                       fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                       fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                       fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                       fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                       fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
        />
        :
        null
      }
      
      {tabBarKey === 'CREATE_SCHEDULE' ?
        <Schedule user={user}
                  company={company}
                  languages={languages}
                  showCancel={false}
                  saveSchedule={saveSchedule}
                  fetchSchedule={fetchSchedule}
                  fetchSchedules={fetchSchedules}
                  publishSchedule={publishSchedule}
                  saveScheduleTrigger={saveScheduleTrigger}
                  createPaymentTrigger={createPaymentTrigger}
                  fetchScheduleVersion={fetchScheduleVersion}
                  removePaymentTrigger={removePaymentTrigger}
                  updatePaymentTrigger={updatePaymentTrigger}
                  removeScheduleTrigger={removeScheduleTrigger}
                  fetchPatientsInSchedule={fetchPatientsInSchedule}
                  fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                  fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                  fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                  fetchScheduleVersionsForSchedule={fetchScheduleVersionsForSchedule}
                  fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
        />
        :
        null
      }
    </div>
  )
};

export default ManageSchedules
