import React from 'react';
import './ResponsesList.scss'
import EntryImage from '../../Custom UI/EntryImage/EntryImage';
import MoreLess from '../../Custom UI/MoreLess/MoreLess'
import {ObjectiveConstants} from "../../constants/ObjectiveConstants"
import {QuestConstants} from "../../constants/QuestConstants";
import {EntryConstants} from "../../constants/EntryConstants";
import {EventEmitter} from "../../Components/PatientProfileComponents/EventEmitter/EventEmitter";
import {CustomEventTypes} from "../../constants/CustomEventTypes";
import {uniqBy} from "lodash";
import {Translation} from "react-i18next";
import { momentFromDateTime } from 'Helpers/DateHelpers';

export default class ResponsesList extends React.Component {
    static TakenImage = '/imgs/app/taken.svg';
    static SkippedImage = '/imgs/app/skipped.svg';
    static DailyMoodsHappy = '/imgs/app/daily-moods-happy.svg';
    static DailyMoodsNeutral = '/imgs/app/daily-moods-neutral.svg';
    static DailyMoodsSad = '/imgs/app/daily-moods-sad.svg';

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            quest: null,

        };
    }

    componentDidMount(){
        this.setState({isLoading: true});
        this.props.getFullQuest(this.props.quest.id).then(quest => {
            this.setState({isLoading: false, quest: quest.data.data.quest});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props == null || prevProps == null){

        }
        else if(this.props.quest == null){
            this.setState({isLoading: true, quest: null});
        }
        else if(prevProps.quest == null || this.props.quest.id !== prevProps.quest.id){
            this.setState({quest: null, isLoading: true});
            this.props.getFullQuest(this.props.quest.id).then(quest => {
                this.setState({isLoading: false, quest: quest.data.data.quest});
            });
        }
    }

    render() {
        let { closeEventHandler, objectiveId, showTitle, rotateObjectives } = this.props;
        let { quest } = this.state;

        const navBarHeight = 50;
        const titleHeight = 90;
        let objective = null;
        let entriesArray = null;

        if(quest != null){
            objective = quest.objectives.find(objective => objective.id === objectiveId);

            if (objective != null){
                entriesArray = objective.entries;
                
                if(rotateObjectives && objective != null){
                    entriesArray = quest.objectives.flatMap(objective => objective.entries);
                }
                if(objective.type === ObjectiveConstants.ObjectiveTypeText) {
                    entriesArray = entriesArray.filter((aEntry) => aEntry.text !== null);
                } else {
                    entriesArray = entriesArray.filter((aEntry) => aEntry.value !== null);
                }
            }
        }

        return (
            <div className='response-box'>
                {(this.state.isLoading || objective == null ) &&
                  <div className='title-bar'>
                      <div className='quest-title'>Loading...</div>
                      <div className='close-event-handler' onClick={() => closeEventHandler(quest, objective.id, null, null)}>X</div>

                  </div>
                }
                {!this.state.isLoading && objective != null &&
                <div>
                    <div className='title-bar'>
                        <div className='quest-title'>{quest.title}</div>
                        <div className='close-event-handler' onClick={() => closeEventHandler(quest, objective.id, null, null)}>X</div>
                        {showTitle && <div className='quest-title break'>{objective.name}</div>}
                    </div>
                    <div style={{height: `calc(100vh - ${navBarHeight + titleHeight}px)`, overflowY: 'auto'}}>
                        {
                            this.getResponsesForEntries(entriesArray).map(response => {
                                return this.buildComponentForResponse(quest, objective, response, entriesArray);
                            })
                        }
                    </div>
                </div>
                }
            </div>
        );
    }

    buildComponentForResponse(quest, objective, response, entriesArray) {
        let responseDateComponent = <div className='response-date'>{this.formatResponseDate(response.measured_at)}</div>;
        let entryForResponse = entriesArray.find(entry => entry.response.id === response.id);

        if (entryForResponse && (entryForResponse.hasOwnProperty('text') || entryForResponse.hasOwnProperty('value'))) {
            const objectiveResponseIds = {responseId: response.id, objectiveId: objective.id};
            let entryComponent;

            switch(quest.hsc_visualization){
                case QuestConstants.HSCVisualizationReminderChart:
                    entryComponent = this.buildReminderComponentForEntryInResponse(entryForResponse, objective);
                    break;
                case QuestConstants.HSCVisualizationSymptomTracker:
                    entryComponent = this.buildSymptomComponentForEntryInResponse(entryForResponse);
                    break;
                case QuestConstants.HSCVisualizationLineGraph:
                    const questName = this.getQuestName(quest);
                    if(questName === "Daily Vitals") {
                        entryComponent = this.buildVitalComponentForEntryInResponse(entryForResponse, objective);
                    } else if (questName === "Stool Diary") {
                        entryComponent = this.buildComponentForEntryInResponse(response.id, quest);
                    }
                    break;
                case QuestConstants.HSCVisualizationStackedBarGraph:
                    entryComponent = this.buildDailyMoodsComponentForEntryInResponse(entryForResponse, quest);
                    break;
                default:
                    entryComponent = this.buildComponentForEntryInResponse(response.id, quest);
            }
            return <div className='objective-container' key={response.id}
                    onMouseEnter={() => EventEmitter.dispatch(CustomEventTypes.RESPONSE_HOVERED, objectiveResponseIds)}
                    onMouseLeave={() => EventEmitter.dispatch(CustomEventTypes.RESPONSE_UNHOVERED, objectiveResponseIds)}>
                {responseDateComponent}
                {entryComponent}
            </div>
        }
    }

    buildReminderComponentForEntryInResponse(entry, objective) {
        let status, entryImage;
        let value = entry.value;

        if (value === EntryConstants.EntryTaken) {
            status = (objective.type === ObjectiveConstants.ObjectiveTypeMedication) ? "Taken" : "Completed";
            entryImage = ResponsesList.TakenImage;
        } else {
            if (value === EntryConstants.EntryMissed){
                status = "Missed";
            } else if (value === EntryConstants.EntrySkipped) {
                status = "Skipped";
            }
            entryImage = ResponsesList.SkippedImage;
        }

        return (<div key={entry.id}>
            <div className='reminder-status-wrapper'>
                <img alt="src" src={entryImage} />
                <div className='reminder-status'>
                    { status }
                    {/*{status}*/}
                </div>
            </div>
            {status !== 'Taken' &&
                <div className='entry-text align-with-image'>
                    { entry }
                    {/*{entry.text}*/}
                </div>
            }
        </div>);
    }

    buildSymptomComponentForEntryInResponse(entry) {
        let className;

        if (entry.value < 4) {
            className = 'green';
        } else if (entry.value < 8) {
            className = 'yellow';
        } else {
            className = 'red';
        }

        return (<div className='objective-name' key={entry.id}>
            <div className={className}>{entry.value}</div>
        </div>);
    }

    buildVitalComponentForEntryInResponse(entry, objective) {
        return (
            <div className='objective-name' key={entry.id}>
                <div>{entry.value} {entry.value ? objective?.vital?.storage_unit : ""}</div>
            </div>
        );
    }

    buildDailyMoodsComponentForEntryInResponse(entry, quest) {
        const reasonEntry = quest.objectives[1].entries.filter((aEntry) => aEntry.value !== null).find(e => e.response.id === entry.response.id);
        const answerText = entry.answers.length >= 1 ? entry.answers[0].answer_text : null;

        let dailyMoodsImage;

        if (answerText) {

            if (entry.answers[0].chart_value === 1) {
                dailyMoodsImage = ResponsesList.DailyMoodsSad;
            } else if (entry.answers[0].chart_value === 2) {
                dailyMoodsImage = ResponsesList.DailyMoodsNeutral;
            } else {
                dailyMoodsImage = ResponsesList.DailyMoodsHappy;
            }

            return (<div key={entry.id}>
                <div className="objective-name flex-center">
                    <img alt="" src={dailyMoodsImage} style={{padding: '5px'}}/>
                    { answerText }
                    {/*{answerText}*/}
                </div>
                {reasonEntry && <div className="entry-text align-with-image">{reasonEntry.text}</div>}
            </div>);
        }
    }

    getResponsesForEntries(entries) {
        const responses = entries.map(entry => entry.response).sort((response1, response2) => {
            let response1Date = new Date(response1.measured_at);
            let response2Date = new Date(response2.measured_at);
            return response2Date - response1Date;
        });
        return uniqBy(responses, 'id');
    }

    formatResponseDate(response) {
        return momentFromDateTime(response)
    }

    getQuestName(aQuest) {
       if(aQuest.translations && aQuest.translations.length) {
            const questEnTranslation = aQuest.translations.find(translation => translation.locale === "en")
            if (questEnTranslation) {
                return questEnTranslation.title;
            }
       }
       return aQuest.title; 
    }

    buildComponentForEntryInResponse(responseId, quest) {
        let returnValue = [];

        quest.objectives.forEach(objective => {
            let entry = objective.entries.find(entry => entry.response.id === responseId);

            if(entry != null && objective.type !== ObjectiveConstants.ObjectiveContent){
                if (objective.type === ObjectiveConstants.ObjectiveTypeMultipleChoice) {
                    if (entry.answers.every(answer => answer.answer_text === "")) {
                        return returnValue;
                    }
                    returnValue.push(<div key={entry.id}>
                        <div className='entry-title' dangerouslySetInnerHTML= {{__html: unescape(objective.name)}}></div>
                        {
                            entry.answers.map((answer, index) => {
                                return <div key={index} className='objective-name'>{answer.answer_text}</div>;
                            })
                        }
                    </div>);
                } else if (objective.type === ObjectiveConstants.ObjectiveTypeText || objective.type === ObjectiveConstants.ObjectiveAppointment) {
                    let value = entry.text;

                    if(objective.type === ObjectiveConstants.ObjectiveAppointment){
                        value = this.formatResponseDate(entry?.response?.measured_at);
                    }
                    else if(objective.type === ObjectiveConstants.ObjectiveTypeText && value == null){
                        value = entry.value;
                    }
                    returnValue.push(<div key={entry.id}>
                        <div className='entry-title' dangerouslySetInnerHTML= {{__html: unescape(objective.name)}}></div>
                        <div className='objective-name'>{value}</div>
                    </div>);
                } else if (objective.type === ObjectiveConstants.ObjectiveTypeImage) {

                    returnValue.push(<div key={entry.id}>
                            <div className='entry-title'>{objective.name}</div>
                            <EntryImage
                                key={entry.id}
                                imageId={entry.id}
                                className={'image'}
                                width={200}
                            />
                      </div>);
                }
                else {
                    returnValue.push(<div className='entry-title' key={entry.id}>
                        <div dangerouslySetInnerHTML= {{__html: unescape(objective.name)}}></div>
                        <div className='objective-name'>{entry.value}</div>
                    </div>);
                }
            }else if(objective.type === ObjectiveConstants.ObjectiveContent){
                returnValue.push(
                    <div className='entry-title'>
                        <MoreLess
                        description={objective.name}
                        />
                    </div>
                )
            }
        });
        return returnValue;
    }
}
