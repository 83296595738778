import React, {useEffect} from 'react';
import ToolPreviewObjective from '../ToolPreviewObjective/ToolPreviewObjective'
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './ToolPreview.scss'
import {orderBy} from "lodash";

const ToolPreview = ({tool, getQuestById, selectedTranslation = {title:'English', locale:'en', display_name:'English (US'}, selectedTranslationIndex = 0}) => {
  
  // Instance Variables
  
  const blocks = orderBy(tool.objectives, 'order', 'asc');
  
  // Methods
  
  useEffect(() => {
    if(tool && getQuestById){
      getQuestById(null, tool.id).then((newResult) => {
        // TODO: Enable this call WITHOUT the need for a Patient ID.
      });
    }
  }, [tool, getQuestById]);
  
  // Render
  
  return (
    <div className='tool-preview-container'>
      <div style={{display:'block', height:'50px', backgroundColor:'#36b0b2', color:'#ffffff', fontSize:'1.1em', padding:'1em 2em', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', textAlign:'center'}}>
        <span>{selectedTranslation.title}</span>
      </div>
  
      <PerfectScrollbar style={{height:625, wordBreak:'break-all'}}>
        {selectedTranslation.description ?
          <div style={{fontSize:'1.4em', padding:'1.5em 1.5em 0 1.5em'}}>
            {selectedTranslation.description}
          </div>
          :
          null
        }
    
        <div style={{padding:'2em', minHeight:'400px'}}>
          {blocks.map((objective, index) => {
            return (
              <ToolPreviewObjective key={index}
                                    objective={objective}
                                    selectedTranslationIndex={selectedTranslationIndex}
              />
            )
          })
          }
        </div>
      </PerfectScrollbar>
    </div>
  )
};

export default ToolPreview
