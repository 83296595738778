import React from "react";
import './SurveyBuilderQuestionOther.scss';
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import cloneDeep from "lodash/cloneDeep";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";
import i18n from "../../../../i18n";
import LabeledTextarea from "../../../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";

export default class SurveyBuilderQuestionOther extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const { question, updateQuestion, selectedLanguage, errorForIdTypeAndKey } = this.props;
  
    const labelKey = KeyForTranslation('other_label', selectedLanguage);
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-other-input"
                       label={<Translation>{ (t, { i18n }) => t('OTHER') }</Translation>}
                       checked={question && Boolean(question.other)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
          
                         if(isChecked){
                           updatedQuestion.other = true;
                         }
                         else{
                           delete updatedQuestion['other'];
                           delete updatedQuestion['other_label'];
                           delete updatedQuestion['other_value'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('OTHER_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        {question && Boolean(question.other) ?
          <span>
            <LabeledDelayedInput className="survey-builder-question-other-input-more"
                                 label={i18n.t('OTHER_LABEL') + ' - ' + selectedLanguage.title}
                                 value={question[labelKey] && question[labelKey].length > 0 ? question[labelKey] : ''}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('OTHER_LABEL_EXPLANATION')}</Translation>}
                                 minLength={0}
                                 handleSave={(aEvent) => {
                                   let updatedQuestion = cloneDeep(question);
                                   updatedQuestion[labelKey] = aEvent.target.value;
                                   updateQuestion(updatedQuestion);
                                 }}
                                 warningMessage={errorForIdTypeAndKey(question.id, 'warning', labelKey).message}
                                 popoverPlacement={"left"}
            />
            
            {/*TODO: Add in when support for value is added to the server. */}
            {/*<LabeledDelayedInput className="survey-builder-question-other-input-more"*/}
            {/*                     label={<Translation>{(t, { i18n }) => t('OTHER_VALUE')}</Translation>}*/}
            {/*                     value={question.other_value}*/}
            {/*                     infoBlurb={<Translation>{(t, { i18n }) => t('OTHER_VALUE_EXPLANATION')}</Translation>}*/}
            {/*                     minLength={0}*/}
            {/*                     handleSave={(aEvent) => {*/}
            {/*                       let updatedQuestion = cloneDeep(question);*/}
            {/*                       updatedQuestion.other_value = aEvent.target.value;*/}
            {/*                       updateQuestion(updatedQuestion);*/}
            {/*                     }}*/}
            {/*                     warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'other_value').message}*/}
            {/*                     popoverPlacement={"top"}*/}
            {/*/>*/}
          </span>
          :
          null
        }
      </div>
    )
  }
}
