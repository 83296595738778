import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {fetchPatient} from './patient'

export function addNote({userId, description}) {
  EventTracker.track(EventTypes.ADD_NOTE);
  let query = `
  mutation AddPatientNote($addPatientNoteInput: AddPatientNoteInput!) {
    addPatientNote(input: $addPatientNoteInput) {
      note {
        id
        created_at
        updated_at
        description
        provider_id
        provider_name
      }
    }
  }
  `;

  let variables = {
    addPatientNoteInput: {
      user_id: userId,
      description: description
    }
  };
  
  let operationName = `AddNote`;

  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    }).then((newResult) => {
      dispatch(fetchPatient({userId:userId}));
      return newResult;
    })
    .catch((newError) => {
      return {error:newError.data && newError.data.error}
    })
  }
}

export function getNotes(patient_id, limit, offset) {
  let query = `
    query getNotes ($company_id: ID!, $user_id: ID!, $limit: Int, $offset: Int) {
      notes (company_id: $company_id, user_id: $user_id, limit: $limit, offset: $offset){
        patient_notes {
          id
          created_at
          updated_at
          description
          provider_id
          provider_name
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company &&  parseInt(state.company.company.id);
    variables['user_id'] = parseInt(patient_id);
    variables['limit'] = limit;
    variables['offset'] = offset;
    return global.api.post("/queries", { query, variables });
  };
}

export function updateNote({noteId, description, userId}) {
  EventTracker.track(EventTypes.UPDATE_NOTE);
  let query = `
  mutation UpdatePatientNote($updatePatientNoteInput: UpdatePatientNoteInput!) {
    updatePatientNote(input: $updatePatientNoteInput) {     
      note {
        id
        created_at
        updated_at
        description
        provider_id
        provider_name
      }
    }
  }
  `;
  let variables = { 
    updatePatientNoteInput: {
      id:noteId,
      user_id:userId,
      description:description
    }
  };
  let operationName = `UpdatePatientNote`;

  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((newResult) => {
      dispatch(fetchPatient({userId:userId}));
      return newResult;
    })
    .catch((newError) => {
      return {error:newError.data && newError.data.error}
    })
  };
}

export function removeNote({noteId, userId}) {
  EventTracker.track(EventTypes.REMOVE_NOTE);
  let query = `
  mutation RemovePatientNote($removePatientNoteInput: RemovePatientNoteInput!) {
    removePatientNote(input: $removePatientNoteInput) {     
      note {
        id
        created_at
        updated_at
        description
        provider_id
        provider_name
      }
    }
  }
  `;
  let variables = { 
    removePatientNoteInput: {
      id: noteId
    }
  };
  let operationName = `RemovePatientNote`;

  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((newResult) => {
      dispatch(fetchPatient({userId:userId}));
      return newResult;
    })
    .catch((newError) => {
      return {error:newError.data && newError.data.error}
    })
  };
}
