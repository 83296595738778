import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client";
import {gqlEndPoint} from "./EndPointHelpers";

const URI = gqlEndPoint();

export function createApolloClient(){
  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink({uri:URI, credentials:'include'}),
    new HttpLink({uri:URI}),
  );

  return new ApolloClient({
    credentials: 'include',
    link:httpLink,
    cache:new InMemoryCache()
  });
}
