import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './ReportsList.scss';
import i18next from "i18next";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {currentDateHumanizedWithTimeStamp} from "../../Helpers/DateHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {ReportStatuses, ReportTypes} from "../../constants/ReportTypes";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {CheckCircle} from "@material-ui/icons";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {DEFAULT_COLORS} from "../../constants/Colors";
import ErrorIcon from "@material-ui/icons/Error";
import DynamicList from "../DynamicList/DynamicList";
import Popover from "antd/lib/popover";
import Icon from "antd/lib/icon";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyRemoveReportError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_REMOVING_REPORT', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyDownloadReportError = () => toast(<Translation>{ (t, { i18n }) => t('ERROR_DOWNLOADING_REPORT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyRemoveReportSuccess = () => toast(<Translation>{ (t, { i18n }) => t('SUCCESS_REMOVING_REPORT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyFetchReportDetailsError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_FETCHING_REPORT_DETAILS', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyRetryGeneratingReportError = (aError) => toast(<Translation>{ (t, { i18n }) => t('RETRY_GENERATING_REPORT_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyRetryGeneratingReportSuccess = () => toast(<Translation>{ (t, { i18n }) => t('RETRY_GENERATING_REPORT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoClose);

export default class ReportsList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      intervalId:null,
      shouldReload:false,
      reportToRemove:null,
      selectedReport:null,
      eConsentConfirmationModalOpen:false,
      toolConfirmationModalOpen:false
    };
    this.removeReport = this.removeReport.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.fetchReportDetails = this.fetchReportDetails.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.company?.id !== this.props.company?.id){
      this.setState({shouldReload:true, selectedReport:null, reportToUpdate:null});
    }
  }
  
  // Methods
  
  removeReport(aReport){
    const {removeReport} = this.props;
    
    if(aReport && removeReport){
      this.setState({selectedReport:null, eConsentConfirmationModalOpen:false});
      removeReport(aReport.id).then((newResponse) => {
        this.setState({reportToRemove:aReport});
        notifyRemoveReportSuccess();
      }, (newError) => {
        notifyRemoveReportError(errorMessageFromServerError(newError));
      });
    }
  }
  
  fetchReportDetails(aReport, aCloseMenu){
    if(aReport){
      if(aCloseMenu){
        this.setState({selectedReport:null});
      }
      this.props.fetchReportDetails(aReport.id).then((newResponse) => {
        let report = newResponse && newResponse.data && newResponse.data.data && newResponse.data.data.report;
        this.setState({reportToUpdate:report});
      }, newError => {
        if(aCloseMenu){
          notifyFetchReportDetailsError(errorMessageFromServerError(newError));
        }
      });
    }
  }
  
  getFileExtensionFromReport(aReport){
    let returnValue = '';
    
    if(aReport && aReport.file_url && aReport.file_url.length > 0){
      returnValue = aReport.file_url.split('.').pop().split('?')[0];
    }
    return returnValue;
  }
  
  downloadReport(aReport){
    if(aReport && aReport.file_url && aReport.file_url.length > 0){
      fetch(aReport.file_url, {
        method:"GET",
        headers:{}
      }).then(newResponse => newResponse.blob()).then(newBlob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(newBlob);
        link.download = aReport.report_type + '_' + currentDateHumanizedWithTimeStamp() + '.' + this.getFileExtensionFromReport(aReport);
        link.target = "_blank";
        link.style = "display:none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
      this.setState({selectedReport:null});
    }
    else{
      notifyDownloadReportError();
    }
  }
  
  renderReportStatus(aReport){
    const error = ''; // TODO: When the error is returned from the server, grab it here from aReport.
    
    return (
      <div className="report-cell-status-info">
        {aReport.status === 'completed' ?
          <CheckCircle style={{color:'green', height:'15px', marginTop:'4px'}}/>
          :
          (aReport.status === 'generating' || aReport.status === 'processing' ?
              <AutorenewIcon className="report-loop-animation"
                             style={{color:DEFAULT_COLORS.MAIN_BLUE}}
              />
              :
              <ErrorIcon style={{color:'#E02020', height:'15px', marginTop:'4px'}}/>
          )
        }
        <div>
          <div className="report-cell-status-title">
            {ReportStatuses[aReport.status]} {this.getFileExtensionFromReport(aReport)}
          </div>
        
          {error ?
            <Popover content={error} placement="bottom">
              <div className="report-cell-popover-button">
                <Icon type="ellipsis"/>
              </div>
            </Popover>
            :
            null
          }
        </div>
      </div>
    );
  }
  
  // Render
  
  render() {
    const {selectedReport, shouldReload, reportToUpdate, eConsentConfirmationModalOpen, reportToRemove} = this.state;
    const {company, fetchReports, listContainerHeight, retryGeneratingReport, studyClosed} = this.props;
    
    const reportPermissions = ['admin', 'admin_level_1', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_6', 'admin_level_10'];
    let currentRole = roleForCompany(company);
  
    let columnsArray = [];
    columnsArray.push({key:'created_at', columnWeight:1, dateFormat:' MMM D YYYY, h:mma', columnNameKey:'REPORT_DATE', propertiesArray:['created_at']});
    columnsArray.push({key:'type', columnWeight:1.5, columnNameKey:'TYPE', propertiesArray:['name'], valueFormatter:(aValue, aReport) => {
        let returnValue = ReportTypes[aReport.report_type] || ReportTypes['custom'];
    
        if(aReport.name && aReport.name.length > 0){
          returnValue = aReport.name;
        }
        return returnValue;
      }});
    if(currentRole !== 'admin_level_1') {
      columnsArray.push({key:'type', columnWeight:1.5, columnNameKey:'ADMIN', propertiesArray:['user'], valueFormatter:(aValue) => formatUserName(aValue)});
    }
    columnsArray.push({key:'status', columnWeight:1, columnNameKey:'REPORT_STATUS', templateCell:(aReport) => this.renderReportStatus(aReport)});
  
    let menuItemsArray = [];
    menuItemsArray.push({title:'DOWNLOAD', clickAction:(aSelectedReport) => {
      this.setState({selectedReport:aSelectedReport});
        this.downloadReport(aSelectedReport);
      }, isValid:(aSelectedReport) => aSelectedReport && aSelectedReport.file_url && aSelectedReport.file_url.length > 0});
    menuItemsArray.push({title:'RELOAD', clickAction:(aSelectedReport) => {
        retryGeneratingReport(aSelectedReport.id).then((newResult) => {
          let report = newResult.data.data.retryReportMutation.report;
          this.setState({reportToUpdate:report});
          notifyRetryGeneratingReportSuccess();
        }, (newError) => {
          notifyRetryGeneratingReportError(errorMessageFromServerError(newError));
        });
      }, isValid:(aSelectedReport) => aSelectedReport && !(aSelectedReport.file_url && aSelectedReport.file_url.length > 0)});
    menuItemsArray.push({title:'REMOVE', clickAction:(aSelectedReport) => {
        this.setState({eConsentConfirmationModalOpen:true, selectedReport:aSelectedReport});
      }, isValid: () => !studyClosed});
  
    return (
      <div className="reports-list-page">
        {(reportPermissions.includes(currentRole) || studyClosed) ?
          <div className="reports-search-list-container"
               style={{height:listContainerHeight ? listContainerHeight : '550px'}}>
            <div id="reports-list"
                 className="reports-container">
              <DynamicList id="reports-list"
                           didReload={() => this.setState({shouldReload:false})}
                           reloadCell={(aReport) => this.fetchReportDetails(aReport, false)}
                           menuCancel={() => this.setState({selectedReport:null})}
                           reloadDelay={10000}
                           columnsArray={columnsArray}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchReports(aLimit, aOffset, aSearchString)}
                           shouldReload={shouldReload}
                           isCellLoading={(aReport) => aReport.status === 'generating' || aReport.status === 'pending'}
                           menuItemsArray={menuItemsArray}
                           objectToDelete={reportToRemove}
                           objectToReplace={reportToUpdate}
                           showSearchBar={false}
                           totalObjectsKey='REPORTS_TITLE'
                           finishedDeletingObject={() => this.setState({reportToRemove:null})}
                           responseTotalKeysArray={['data', 'data', 'reports', 'total']}
                           responseObjectKeysArray={['data', 'data', 'reports', 'reports']}
                           finishedReplacingObject={() => this.setState({reportToUpdate:null})}
              />
            </div>
  
            <ConfirmationModal title={i18next.t('REMOVE_REPORT_CONFIRM')}
                               isOpen={eConsentConfirmationModalOpen}
                               reject={() => this.setState({selectedReport:null, eConsentConfirmationModalOpen:false})}
                               confirm={() => this.removeReport(selectedReport)}
            />
          </div>
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('REPORTS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
