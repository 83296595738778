import React from "react";
import './SurveyBuilderDateTimeQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDatePicker from "../../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {isoDateTimeNoTimeZonePickerFormat, isoDateTimeNoTimeZoneFormat, momentFromDate} from "../../../../Helpers/DateHelpers";

export default class SurveyBuilderDateTimeQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, errorForIdTypeAndKey} = this.props;
    
    let maxDate = '';
    let minDate = '';
    
    if(question.max && question.max.value){
      maxDate = momentFromDate(question.max.value, isoDateTimeNoTimeZoneFormat);
    }
    if(question.min && question.min.value){
      minDate = momentFromDate(question.min.value, isoDateTimeNoTimeZoneFormat);
    }
    
    return(
      <div className="survey-builder-date-time-question-cell">
        <LabeledDatePicker className="survey-builder-date-time-input"
                           label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                           value={minDate}
                           maxDate={maxDate}
                           infoBlurb={<Translation>{(t, { i18n }) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                           dateFormat={isoDateTimeNoTimeZonePickerFormat}
                           handleSave={(aDate) => {
                             let updatedQuestion = cloneDeep(question);
                             
                             if(aDate && aDate.isValid()){
                               updatedQuestion.min = {value:aDate.format(isoDateTimeNoTimeZoneFormat)};
                             }
                             else{
                               delete updatedQuestion['min'];
                             }
                             updateQuestion(updatedQuestion);
                           }}
                           isClearable={true}
                           errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_value').message}
                           showTimeSelect
        />
        
        <LabeledDatePicker className="survey-builder-date-time-input"
                           label={<Translation>{ (t, { i18n }) => t('MAX_VALUE') }</Translation>}
                           value={maxDate}
                           minDate={minDate}
                           infoBlurb={<Translation>{(t, { i18n }) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                           dateFormat={isoDateTimeNoTimeZonePickerFormat}
                           handleSave={(aDate) => {
                             let updatedQuestion = cloneDeep(question);
          
                             if(aDate && aDate.isValid()){
                               updatedQuestion.max = {value:aDate.format(isoDateTimeNoTimeZoneFormat)};
                             }
                             else{
                               delete updatedQuestion['max'];
                             }
                             updateQuestion(updatedQuestion);
                           }}
                           isClearable={true}
                           errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_value').message}
                           showTimeSelect
        />
      </div>
    )
  }
}
