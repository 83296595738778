import React from 'react';
import '../AssignSymptomsModal/AssignSymptomsModal.scss'
import "antd/dist/antd.css";
import './AddVirtualAppointmentModal.scss'
import '../../Custom UI/modalTitleCell.scss';
import GeneralModal from "../GeneralModal/GeneralModal";
import AddVirtualAppointment from "../../Components/AddVirtualAppointment/AddVirtualAppointment";

export default class AddVirtualAppointmentModal extends React.Component {

  // Init

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  // Render

  render() {
    let {name, isOpen, handleCloseModal, primaryPatient, virtualAppointment} = this.props;

    return (
      <GeneralModal title={name}
                    isOpen={isOpen}
                    onRequestClose={handleCloseModal}
                    isEditing={virtualAppointment}
                    showSaveButton={false}
                    showCancelButton={false}
                    handleCloseModal={handleCloseModal}>
        <AddVirtualAppointment showSaveButton={true}
                               primaryPatient={primaryPatient}
                               {...this.props}
        />
      </GeneralModal>
    )
  }
}
