import React from 'react';
import { maxBy, minBy } from 'lodash'
import {
  VictoryScatter,
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryTooltip,
  VictoryLabel
} from 'victory'

const VitalChart = ({chartData, height, width, padding, style, labelFontSize}) => {
  let valueLabels = chartData.map((x) => x.value !== null ?  Math.round(x.value).toString() : '')
  let maxValue = maxBy(chartData, (d) => d.value)
  let minValue = minBy(chartData, (d) => d.value)
  let middle = (maxValue && minValue) ? (maxValue.value + minValue.value) / 2 : 0
  return (
    <VictoryChart
      height={height}
      width={width}
      padding={padding}
      style={style}
    >
      <VictoryAxis
        name="axis"
        tickLabelComponent={<VictoryLabel text={(x) => valueLabels[x-1]}/>}
        style={{
          axis: {
            strokeOpacity: 0
          },
          ticks: {
            strokeOpacity: 0
          },
          tickLabels: {fontSize: labelFontSize},
        }}
        scale="time"
      />
      <VictoryGroup
        data={chartData}
        x="date"
        y="value"
      >
        <VictoryArea
          style={{ data: { opacity: 0.4 } }}
          interpolation="natural"
        />
        <VictoryScatter
          labelComponent={
            <VictoryTooltip
              style={{fontSize: labelFontSize}}
            />
          }
          labels={(datum) => { return datum.date + "\n" + datum.value }}
          size={8}
          style={{
            data: { opacity: (d) => d.value === null ? 0 : 1 }
          }}
        />
      </VictoryGroup>
      <VictoryScatter
        data={chartData}
        x="date"
        y={(datum) => datum.value === null ? middle : null}
        labelComponent={
          <VictoryTooltip style={{ fontSize: labelFontSize }} />
        }
        labels={(datum => datum.date)}
        size={8}
        style={{ data: { opacity: (d) => d.value === null ? 0.10 : 0 } }}
      />
    </VictoryChart>
  );
}
export default VitalChart
