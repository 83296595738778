import React from 'react';
import './Add.scss';
import i18next from "i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const Add = ({className, click, titleKey}) => {
  
  // Render
  
  return (
    <div className={'add-container ' + className}>
      <div className='add-cell' onClick={click}>
        <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
  
        {i18next.t(titleKey)}
      </div>
    </div>
  );
};
export default Add;
