import React from "react";
import {translatedOptionsArray} from "../Helpers/Helpers";

export const typesOfCalls = {
  call:'OUTBOUND_CALL',
  email:'OUTBOUND_EMAIL',
  video:'VIDEO_CONFERENCE',
  inbound_call:'INBOUND_CALL',
  inbound_email:'INBOUND_EMAIL'
};

export const inboundDispositionCodes = {
  ae:'AE',
  login:'LOGIN',
  survey:'QUESTIONNAIRE',
  payment:'PAYMENT_IN_CASE_ITS_APPLICABLE',
  removed:'REMOVED_FROM_CALL_LIST',
  withdraw:'WITHDRAW',
  complaint:'COMPLAINT',
  icf_questions:'ICF_QUESTIONS',
  pro_completed:'PRO_COMPLETED_BY_PHONE',
  general_questions:'GENERAL_QUESTIONS'
};

export const outboundDispositionCodes = {
  reminder:'REMINDER_COMPLETED_MESSAGE',
  no_answer:'NO_ANSWER',
  left_message:'NO_ANSWER_LEFT_MESSAGE',
  wrong_number:'WRONG_NUMBER',
  subject_deceased:'SUBJECT_DECEASED',
  survey_over_phone:'QUESTIONNAIRE_COMPLETED_OVER_PHONE',
  requested_call_back:'REQUESTED_A_CALL_BACK'
};

export const callNoteKeys = {
  caller:'CALLER',
  note_text:'EXTRA_NOTE',
  start_time:'CALLER_DATE_AND_TIME',
  survey_name:'SURVEY_NAME',
  call_duration:'CALL_DURATION',
  type_of_visit:'TYPE_OF_VISIT',
  purpose_of_visit:'PURPOSE_OF_VISIT',
  visit_disposition:'VISIT_DISPOSITION'
};

export const allDispositionCodes = {...inboundDispositionCodes, ...outboundDispositionCodes};

export function typesOfCallsOptionsArray(){
  return translatedOptionsArray(typesOfCalls);
}

export function inboundDispositionCodesOptionsArray(){
  return translatedOptionsArray(inboundDispositionCodes);
}

export function outboundDispositionCodesOptionsArray(){
  return translatedOptionsArray(outboundDispositionCodes);
}

export function allDispositionCodesOptionsArray(){
  return translatedOptionsArray(allDispositionCodes);
}

export function callNoteKeysOptionsArray(){
  return translatedOptionsArray(callNoteKeys);
}
