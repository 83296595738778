import React,  { useState } from 'react'
import { PlayArrowSharp } from '@material-ui/icons'
import LabeledTextInput from '../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput'
import LabeledDatePicker from '../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import './ReportRequestDropDown.scss'
import { NOTIFY_OPTS } from 'constants/Notifiers'
import {Translation} from "react-i18next";

const CORE_GROUP_NAME = 'All Patients'

const ReportRequestDropDown =({groupColor, closeReportReqDrop, group, user, company}) => {
  const [reportStartDate, setReportStartDate] = useState(null)
  const [reportEndDate, setReportEndDate] = useState(moment())

  const notifyReportSent = () => toast(
    <Translation>{(t, {i18n}) => t('REPORT_SENT')}</Translation>,
    NOTIFY_OPTS.default)

  const notifyReportFailed = () => toast(
    <Translation>{(t, {i18n}) => t('REPORT_SENT_FAILED_MESSAGE')}</Translation>,
    NOTIFY_OPTS.default)

  const validDates = () => {
    return reportStartDate && reportEndDate && reportEndDate > reportStartDate
  }
  const startDateContent = () => reportStartDate ? (validDates() ? '' : <Translation>{(t, {i18n}) => t('INVALID')}</Translation>) : <Translation>{(t, {i18n}) => t('REQUIRED_LOWERCASE')}</Translation>
  const endDateContent = () => reportEndDate ? '' : <Translation>{(t, {i18n}) => t('REQUIRED_LOWERCASE')}</Translation>

  const userNameValue = () => {
    let returnValue = '';

    if(user){
      if(user.first_name){
        returnValue += user.first_name + ' ';
      }
      if(user.last_name){
        returnValue += user.last_name;
      }
      if(returnValue.length > 0){
        returnValue += ' - ';
      }
      returnValue += user.email;
    }
    return returnValue;
  }

  const reportReqParams = {
    company_id: company.id,
    group_id: group.id,
    start_date: reportStartDate,
    end_date: reportEndDate
  }
  const sendEmailReportReq = () => global.api.post("/api/tool_stats/patients_usage_summary_report", reportReqParams)
                                             .then((result) => notifyReportSent() && closeReportReqDrop())
                                             .catch((error) => notifyReportFailed())

  return (
    <div className="drop-down-menu">
      <PlayArrowSharp className="triangle" />
      <div className="main-container">
        <div className="panel-main-header">
          <div className="left-section">
            <span className="panel-main-header-title-text">
              <Translation>{(t, {i18n}) => t('REPORT')}</Translation>
            </span>
          </div>

          <div className="right-section">
            <span style={{ opacity: validDates() ? 1 : 0.5 , cursor: validDates() ? 'pointer' : 'not-allowed'}} onClick={() =>validDates() ? sendEmailReportReq() : null}>
              <Translation>{(t, {i18n}) => t('SEND_REPORT')}</Translation>
            </span>
            <span className="clickable" onClick={closeReportReqDrop}>
              <Translation>{(t, {i18n}) => t('CLOSE_UPPERCASE')}</Translation>
            </span>
          </div>
        </div>

        <div className="drop-down-body">
          <div className="report-send-details">
            <LabeledTextInput
              disabled={true}
              label={<Translation>{(t, {i18n}) => t('REPORT_RECIPIENT')}</Translation>}
              value={userNameValue()}
            />
            <div>
              <LabeledTextInput
                disabled={true}
                label={<Translation>{(t, {i18n}) => t('SELECTED_GROUP')}</Translation>}/>
              <div className="group-details">
                <div className="circle-container panel-circle">
                  <span style={{ color: groupColor }}>{group.core === true ? CORE_GROUP_NAME[0] : group.name[0]}</span>
                </div>
                <div className="group-details-header">
                  <span>{group.core === true ? CORE_GROUP_NAME : group.name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="report-select-options">
            <div className="select-dates">
              <LabeledDatePicker
                label={<Translation>{(t, {i18n}) => t('START_DATE_PROPER_CAPITALIZED')}</Translation>}
                name="start_date"
                width="200px"
                value={reportStartDate}
                maxDate={moment()}
                infoBlurb={startDateContent()}
                handleSave={date => setReportStartDate(date)}
              />
              <LabeledDatePicker
                label={<Translation>{(t, {i18n}) => t('END_DATE_PROPER_CAPITALIZED')}</Translation>}
                name="start_date"
                width="200px"
                value={reportEndDate}
                maxDate={moment()}
                infoBlurb={endDateContent()}
                handleSave={date => setReportEndDate(date)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportRequestDropDown;
