import {
  FETCH_LOCALES,
  LOAD_LOCALES,
} from '../constants/ActionTypes';

export function fetchLocales() {
  return dispatch => {
    dispatch({
      type: FETCH_LOCALES
    });
    dispatch(loadLocales());
  };
}

export function loadLocales() {
  let query = `
    query getLocales {
      supported_locales {
        locale
        title
        display_name
      }
    }
    `;

  let variables = { }

  return (dispatch) => {
    return global.api.post("/queries", { query, variables }).then((result) => {
      let locales = result.data.data.supported_locales
      dispatch({
        type: LOAD_LOCALES,
        result: locales
      })
    }).catch((error) => {
      dispatch({
        type: LOAD_LOCALES,
        error,
      });
    });
  };
}