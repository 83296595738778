import React from "react";
import './SurveyBuilderSurveyRepeat.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyBuilderSurveyRepeat extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Render
  
  render(){
    const {survey, updateSurvey, questionsArray, errorForIdTypeAndKey } = this.props;
  
    // TODO: Add repeat from question key in other survey. #parent_survey_id_page_#_loop_index_question_key
    const repeatOptionsArray = [
      {value:1, label:i18next.t('CUSTOM')},
      {value:2, label:i18next.t('QUESTION')}
      ];
  
    let questionOptionsArray = [];
    
    for(let question of questionsArray){
      if(['checkbox', 'number', 'radio', 'range', 'rank', 'slider', 'text'].includes(question.type)){
        questionOptionsArray.push({label:question.key, value:question.key});
      }
    }
    // TODO: Detect error is question key gets changed.
    
    let repeatCountOptionsArray = [];
    
    for(let repeatCount = 1; repeatCount <= 20; repeatCount += 1){
      repeatCountOptionsArray.push({label:repeatCount.toString(), value:repeatCount});
    }
    
    const repeats = Boolean(survey && (survey.repeat_count || survey.repeat_count_question_key));
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-survey-repeat-input-switch"
                       label={<Translation>{ (t, { i18n }) => t('SURVEY_REPEATS') }</Translation>}
                       checked={repeats}
                       onChange={(isChecked) => {
                         let updatedSurvey = cloneDeep(survey);
                         
                         if(repeats){
                           if(updatedSurvey.repeat_count){
                             delete updatedSurvey['repeat_count'];
                           }
                           if(updatedSurvey.repeat_count_question_key){
                             delete updatedSurvey['repeat_count_question_key'];
                           }
                         }
                         else{
                           updatedSurvey.repeat_count = 1;
                         }
                         updateSurvey(updatedSurvey);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_REPEATS_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
  
        {repeats ?
          <span>
            <LabeledDropdown className="survey-builder-survey-repeat-input-dropdown"
                             label={<Translation>{ (t, { i18n }) => t('SURVEY_REPEAT_TYPE') }</Translation>}
                             value={repeatOptionsArray.filter(function(option){
                               if(survey.repeat_count){
                                 return option.value === 1;
                               }
                               else{
                                 return option.value === 2;
                               }
                             })}
                             options={repeatOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_REPEAT_TYPE_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => {
                               let updatedSurvey = cloneDeep(survey);
                               
                               if(aOption.value === 1){
                                 if(updatedSurvey.repeat_count_question_key){
                                   delete updatedSurvey['repeat_count_question_key'];
                                 }
                                 if(!updatedSurvey.repeat_count){
                                   updatedSurvey.repeat_count = 1;
                                 }
                               }
                               else if(aOption.value === 2){
                                 if(updatedSurvey.repeat_count){
                                   delete updatedSurvey['repeat_count'];
                                 }
                                 if(!updatedSurvey.repeat_count_question_key){
                                   updatedSurvey.repeat_count_question_key = ' ';
                                 }
                               }
                               updateSurvey(updatedSurvey);
                             }}
                             maxMenuHeight={100}
            />
      
            {survey.repeat_count ?
              <LabeledDropdown className="survey-builder-survey-repeat-input-dropdown"
                               label={<Translation>{ (t, { i18n }) => t('SURVEY_REPEAT_COUNT') }</Translation>}
                               value={repeatCountOptionsArray.filter(function(option){
                                 return option.value === survey.repeat_count;
                               })}
                               options={repeatCountOptionsArray}
                               infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_REPEAT_COUNT_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedSurvey = cloneDeep(survey);
                                 updatedSurvey.repeat_count = aOption.value;
                                 updateSurvey(updatedSurvey);
                               }}
                               errorMessage={errorForIdTypeAndKey(survey.id, 'error', 'repeat_count').message}
                               maxMenuHeight={100}
                               popoverPlacement={"left"}
              />
              :
              <LabeledDropdown className="survey-builder-survey-repeat-input-dropdown"
                               label={<Translation>{ (t, { i18n }) => t('QUESTION_KEY') }</Translation>}
                               value={questionOptionsArray.filter(function(option){
                                 return option.value === survey.repeat_count_question_key;
                               })}
                               options={questionOptionsArray}
                               infoBlurb={<Translation>{(t, { i18n }) => t('QUESTION_KEY_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedSurvey = cloneDeep(survey);
                                 updatedSurvey.repeat_count_question_key = aOption.value;
                                 updateSurvey(updatedSurvey);
                               }}
                               errorMessage={errorForIdTypeAndKey(survey.id, 'error', 'repeat_count_key').message}
                               maxMenuHeight={100}
                               popoverPlacement={"left"}
              />
            }
          </span>
          :
          null
        }
      </div>
    )
  }
}
