import React from "react";
import './SurveyComponent.scss';
import i18n from "../../i18n";
import i18next from "i18next";
import {Translation, withTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Loading from "../../Custom UI/Loading/Loading";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {objectsAreEqual} from "../../Helpers/Helpers";
import SurveySkipCell from "../../Cells/SurveyCells/SurveySkipCell/SurveySkipCell";
import SurveyCheckboxQuestionCell from "../../Cells/SurveyCells/SurveyCheckboxQuestionCell/SurveyCheckboxQuestionCell";
import SurveyConditionQuestionCell from "../../Cells/SurveyCells/SurveyConditionQuestionCell/SurveyConditionQuestionCell";
import SurveyCSVQuestionCell from "../../Cells/SurveyCells/SurveyCSVQuestionCell/SurveyCSVQuestionCell";
import SurveyDateQuestionCell from "../../Cells/SurveyCells/SurveyDateQuestionCell/SurveyDateQuestionCell";
import SurveyDateTimeQuestionCell from "../../Cells/SurveyCells/SurveyDateTimeQuestionCell/SurveyDateTimeQuestionCell";
import SurveyDynamicListQuestionCell from "../../Cells/SurveyCells/SurveyDynamicListQuestionCell/SurveyDynamicListQuestionCell";
import SurveyEConsentQuestionCell from "../../Cells/SurveyCells/SurveyEConsentQuestionCell/SurveyEConsentQuestionCell";
import SurveyImageQuestionCell from "../../Cells/SurveyCells/SurveyImageQuestionCell/SurveyImageQuestionCell";
import SurveyListQuestionCell from "../../Cells/SurveyCells/SurveyListQuestionCell/SurveyListQuestionCell";
import SurveyMedicationQuestionCell from "../../Cells/SurveyCells/SurveyMedicationQuestionCell/SurveyMedicationQuestionCell";
import SurveyMonthYearQuestionCell from "../../Cells/SurveyCells/SurveyMonthYearQuestionCell/SurveyMonthYearQuestionCell";
import SurveyMultiSliderQuestionCell from "../../Cells/SurveyCells/SurveyMultiSliderQuestionCell/SurveyMultiSliderQuestionCell";
import SurveyNumberQuestionCell from "../../Cells/SurveyCells/SurveyNumberQuestionCell/SurveyNumberQuestionCell";
import SurveyPDFQuestionCell from "../../Cells/SurveyCells/SurveyPDFQuestionCell/SurveyPDFQuestionCell";
import SurveyRadioQuestionCell from "../../Cells/SurveyCells/SurveyRadioQuestionCell/SurveyRadioQuestionCell";
import SurveyRangeQuestionCell from "../../Cells/SurveyCells/SurveyRangeQuestionCell/SurveyRangeQuestionCell";
import SurveyRankQuestionCell from "../../Cells/SurveyCells/SurveyRankQuestionCell/SurveyRankQuestionCell";
import SurveySliderQuestionCell from "../../Cells/SurveyCells/SurveySliderQuestionCell/SurveySliderQuestionCell";
import SurveyTextQuestionCell from "../../Cells/SurveyCells/SurveyTextQuestionCell/SurveyTextQuestionCell";
import SurveyTimeQuestionCell from "../../Cells/SurveyCells/SurveyTimeQuestionCell/SurveyTimeQuestionCell";
import SurveyYearQuestionCell from "../../Cells/SurveyCells/SurveyYearQuestionCell/SurveyYearQuestionCell";

const QuestionCells = {
  checkbox:SurveyCheckboxQuestionCell,
  condition:SurveyConditionQuestionCell,
  csv_upload:SurveyCSVQuestionCell,
  date:SurveyDateQuestionCell,
  datetime:SurveyDateTimeQuestionCell,
  dynamic_list:SurveyDynamicListQuestionCell,
  econsent_hsc:SurveyEConsentQuestionCell,
  econsent:SurveyEConsentQuestionCell,
  image_upload:SurveyImageQuestionCell,
  list:SurveyListQuestionCell,
  medication:SurveyMedicationQuestionCell,
  monthyear:SurveyMonthYearQuestionCell,
  multislider:SurveyMultiSliderQuestionCell,
  multi_condition:SurveyConditionQuestionCell,
  multi_medication:SurveyMedicationQuestionCell,
  number:SurveyNumberQuestionCell,
  number_choice:SurveyNumberQuestionCell,
  pdf_upload:SurveyPDFQuestionCell,
  radio:SurveyRadioQuestionCell,
  range:SurveyRangeQuestionCell,
  rank:SurveyRankQuestionCell,
  slider:SurveySliderQuestionCell,
  text:SurveyTextQuestionCell,
  time:SurveyTimeQuestionCell,
  year:SurveyYearQuestionCell
};

class SurveyComponent extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    
    this.state = {
      size:'small',
      begin:false,
      title:'',
      errors:'',
      editable:true,
      response:{},
      progress:0,
      warnings:{},
      isLoading:false,
      pageTitle:'',
      pageNumber:1,
      description:'',
      answersArray:[],
      questionsArray:[],
      currentQuestion:{},
      lastQuestionKey:'',
      firstQuestionKey:'',
      surveyResponseDetails:[]
    };
    this.saveAnswer = this.saveAnswer.bind(this);
    this.beginSurvey = this.beginSurvey.bind(this);
    this.finishSurvey = this.finishSurvey.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.answerModified = this.answerModified.bind(this);
    this.findAnswerForQuestion = this.findAnswerForQuestion.bind(this);
    this.updateAnswerDataQuery = this.updateAnswerDataQuery.bind(this);
    this.renderLabelOnLeftForQuestion = this.renderLabelOnLeftForQuestion.bind(this);
  }
  
  componentDidMount(){
    this.loadSurvey();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.surveyId !== this.props.surveyId){
      this.resetState(true);
    }
  }
  
  // Methods
  
  resetState(aReload){
    this.setState({
      size:'small',
      begin:false,
      title:'',
      errors:'',
      editable:true,
      progress:0,
      response:{},
      warnings:{},
      isLoading:false,
      pageTitle:'',
      pageNumber:1,
      description:'',
      answersArray:[],
      questionsArray:[],
      lastQuestionKey:'',
      currentQuestion:{},
      firstQuestionKey:'',
      surveyResponseDetails:[]
    }, () => {
      if(aReload){
        this.loadSurvey();
      }
    });
  }
  
  loadSurvey(){
    let {t, userId, surveyId, surveyResponseDetailId} = this.props;
    
    if(surveyResponseDetailId){
      this.loadQuestion(false, surveyResponseDetailId);
    }
    else{
      this.props.firstSurvey(surveyId, userId, this.getSurveyResponseDetailId()).then((newResult) => {
        let response = newResult.data.data.getFirstSurveyQuestion.full_survey_response;
        this.loadResponseDetails(response);
      }, (newError) => {
        if(newError && newError.data && newError.data.error){
          this.setState({errors:newError.data.error});
        }
        else {
          this.setState({errors:t('UNKNOWN_ERROR', {i18n})});
        }
      });
    }
  }
  
  first(){
    let {t, userId, surveyId} = this.props;
    this.props.firstSurvey({surveyId:surveyId, userId:userId}).then((result) => {
      this.loadResponseDetails(result.data.data.getFirstSurveyQuestion.full_survey_response);
    }, (error) => {
      if(error != null && error.data != null && error.data.error != null){
        this.setState({errors:error.data.error});
      }
      else{
        this.setState({errors:t('UNKNOWN_ERROR', {i18n})});
      }
    });
  }
  
  loadResponseDetails(response){
    if(response){
      const {defaultValuesForQuestions} = this.props;
      let data = {warnings:{}};
      let answer = {};
      let answersArray = [];
      let questionsArray = [];
      data.response = response;
      data.errors = '';
      
      if(response.progress !== null && !isNaN(parseInt(response.progress, 10))){
        data.progress = parseInt(response.progress, 10);
      }
      if(response.next != null){
        if(response.next.survey_response_details !== null){
          data.surveyResponseDetails = response.next.survey_response_details;
        }
        else{
          data.surveyResponseDetails = [];
        }
        if(response.next.title !== null){
          data.title = response.next.title;
        }
        if(response.next.size !== null){
          data.size = response.next.size;
        }
        else{
          data.size = 'small';
        }
        if(response.next.page_title !== null){
          data.pageTitle = parse(response.next.page_title);
        }
        else{
          data.pageTitle = '';
        }
        if(response.next.questions !== null){
          for(let question of response.next.questions){
            let currentAnswer = {};
            let currentQuestion = question;
            
            if(!question.number_of_columns){
              currentQuestion.number_of_columns = 1;
            }
            if(question.page_number !== null){
              data.pageNumber = question.page_number;
            }
            if(question.label !== null){
              currentQuestion.unparsed_label = question.label;
              currentQuestion.label = parse(question.label);
            }
            if(question.key !== null){
              currentAnswer.question_key = question.key;
            }
            if(question.type !== null){
              currentAnswer.question_type = question.type;
            }
            let surveyResponseDetail = this.findSurveyResponseDetailForKey(question, data.surveyResponseDetails);
            
            if(surveyResponseDetail && surveyResponseDetail.id){
              currentQuestion.surveyResponseDetailId = surveyResponseDetail.id;
            }
            if(defaultValuesForQuestions && defaultValuesForQuestions[currentQuestion.key]){
              const objectForKey = defaultValuesForQuestions[currentQuestion.key];
              
              if(objectForKey){
                if(objectForKey['values']){
                  currentAnswer.values = objectForKey['values'];
                  currentAnswer.prettyValues = this.turnValuesIntoKeyValPairs(objectForKey['values'], currentAnswer.question_type === 'checkbox');
                }
                else{
                  currentAnswer.value = objectForKey['value'];
                }
              }
              // TODO: All the difference question type properties (like other, and skipped) should be handled here.
            }
            answersArray.push(currentAnswer);
            questionsArray.push(currentQuestion);
          }
        }
        if(response.next.survey_response_details !== null){
          let responseDetailsArray = [];
          let orderedResponseDetailsArray = [];
          
          for(let responseDetail of response.next.survey_response_details){
            responseDetailsArray.push(responseDetail);
          }
          for(let answer of answersArray){
            for(let index = 0; index < responseDetailsArray.length; index += 1){
              let responseDetail = responseDetailsArray[index];
              
              if(responseDetail.key === answer.question_key){
                orderedResponseDetailsArray.push(responseDetail);
                responseDetailsArray.splice(index, 1);
                break;
              }
            }
          }
          for(let index = 0; index < answersArray.length; index += 1){
            let answer = answersArray[index];
            let responseDetail = orderedResponseDetailsArray.find(obj => {
              return obj["key"] === answersArray[index]["question_key"]
            }) || {};
            
            if(responseDetail.value !== undefined){
              answer.value = responseDetail.value;
            }
            if(responseDetail.skipped !== undefined){
              answer.skipped = responseDetail.skipped;
              
              if(answer.skipped === true){
                answer.value = null;
                responseDetail.value = null;
              }
            }
            if(responseDetail.values !== undefined){
              answer.values = responseDetail.values;
              answer.prettyValues = this.turnValuesIntoKeyValPairs(responseDetail.values,
                answer.question_type === 'checkbox');
            }
            else{
              answer.values = {};
            }
            if(responseDetail.other !== undefined){
              if(answer.question_type === 'checkbox' && responseDetail.other === true){
                answer.value = 'other';
                responseDetail.value = 'other';
              }
              answer.other = responseDetail.other;
            }
            if(responseDetail.other_value !== undefined){
              answer.other_value = responseDetail.other_value;
            }
            if(responseDetail.created_at !== undefined){
              answer.created_at = responseDetail.created_at;
            }
            answer.data_query = {alert:false, description:''};
            answersArray[index] = answer;
          }
        }
      }
      if(response.first_and_last_question != null){
        if(response.first_and_last_question.first_question != null && response.first_and_last_question.first_question.key != null){
          data.firstQuestionKey = response.first_and_last_question.first_question.key;
        }
        else{
          data.firstQuestionKey = response.first_and_last_question[0].key
        }
        if(response.first_and_last_question.last_question != null && response.first_and_last_question.last_question.key != null){
          data.lastQuestionKey = response.first_and_last_question.last_question.key;
        }
        else{
          data.lastQuestionKey = response.first_and_last_question[1].key
        }
      }
      data.answersArray = answersArray;
      data.questionsArray = questionsArray;
      data.answer = answer;
      this.setState(data);
    }
  }
  
  findSurveyResponseDetailForKey(aKey, aSurveyResponseDetails){
    return aSurveyResponseDetails.find((surveyResponseDetail) => {
      return surveyResponseDetail.key === aKey;
    });
  }
  
  turnValuesIntoKeyValPairs(values, isCheckbox){
    let newValues = [];
    
    if(values != null && values.length > 0){
      values.forEach(value => {
        if(value.key !== null && value.value !== undefined){
          if(isCheckbox){
            newValues.push({[value.key]:value.value === 'true'});
          }
          else {
            newValues.push({[value.key]:value.value});
          }
        }
      });
      return newValues;
    }
  }
  
  turnValuesIntoObjectsBeforeSending(values){
    let valueList = [];
    
    if(values != null){
      Object.keys(values).forEach((key) => {
        valueList.push({key:key, value:values[key].toString()});
      });
    }
    return valueList;
  }
  
  onLastQuestion(){
    let returnValue = false;
    
    if(this.state.questionsArray && this.state.questionsArray.length > 0){
      returnValue = this.state.questionsArray[this.state.questionsArray.length - 1].key === this.state.lastQuestionKey;
    }
    return returnValue;
  }
  
  onFirstQuestion(){
    let returnValue = false;
    
    if(this.state.questionsArray && this.state.questionsArray.length > 0){
      returnValue = this.state.questionsArray[0].key === this.state.firstQuestionKey;
    }
    return returnValue;
  }
  
  shouldShowConfirm = () => {
    return !this.state.errors && Object.values(this.state.warnings).some(a => a.length > 0);
  };
  
  confirmAndNextQuestion = () => {
    const answersArray = [...this.state.answersArray];
    answersArray.forEach((answer, index) => {
      if(this.state.warnings[answer.question_key]){
        answersArray[index].confirmed = true;
      }
    });
    this.setState({answersArray}, this.nextQuestion);
  };
  
  answerModified(aAnswer){
    let returnValue = false;
    let surveyResponseDetail = this.findSurveyResponseDetailForKey(aAnswer.question_key, this.state.surveyResponseDetails);
    
    if(surveyResponseDetail){
      if(surveyResponseDetail.other_value !== undefined){
        if(aAnswer.other_value !== surveyResponseDetail.other_value){
          returnValue = true;
        }
      }
      if(aAnswer.value && surveyResponseDetail.value){
        if(aAnswer.value !== surveyResponseDetail.value){
          returnValue = true;
        }
      }
      if(aAnswer.skipped !== undefined || surveyResponseDetail.skipped !== undefined){
        if(aAnswer.skipped !== surveyResponseDetail.skipped){
          returnValue = true;
        }
      }
      if(aAnswer.values && surveyResponseDetail.values){
        if(aAnswer.values.length > 0 && surveyResponseDetail.values.length > 0){
          if(!objectsAreEqual(aAnswer.values, surveyResponseDetail.values)){
            returnValue = true;
          }
        }
        else if(aAnswer.values.length !== surveyResponseDetail.values.length){
          returnValue = true;
        }
      }
      if(surveyResponseDetail.other){
        if(aAnswer.question_type === 'checkbox' && surveyResponseDetail.other === true){
          if(aAnswer.value !== 'other'){
            returnValue = true;
          }
        }
        if(aAnswer.question_type === 'radio' && surveyResponseDetail.other === true){
          if(aAnswer.value !== 'other'){
            returnValue = true;
          }
        }
        if(aAnswer.other !== surveyResponseDetail.other){
          returnValue = true;
        }
      }
    }
    else{
      returnValue = true;
    }
    return returnValue;
  }
  
  answerWasChanged(){
    let returnValue = false;
    
    for(let answer of this.state.answersArray){
      if(this.answerModified(answer)){
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }
  
  allChangedAnswersHaveDataQueryDescriptions(){
    let returnValue = true;
    
    if(!this.props.isTestResponse){
      for(let answer of this.state.answersArray){
        if(this.findSurveyResponseDetailForKey(answer.question_key, this.state.surveyResponseDetails)){
          if(this.answerModified(answer)){
            if(!(answer.data_query && answer.data_query.description && answer.data_query.description.length > 0)){
              returnValue = false;
              break;
            }
          }
        }
      }
    }
    return returnValue;
  }
  
  nextQuestion(){
    if(!this.state.isLoading){
      this.setState({isLoading:true});
      
      if(!this.props.editable || !this.answerWasChanged()){
        this.loadQuestionAfter();
      }
      else{
        if(this.allChangedAnswersHaveDataQueryDescriptions()){
          this.props.respondToSurvey(
            this.props.surveyId,
            this.getSurveyResponseDetailId(),
            this.answersToSubmit(),
            this.props.userId,
            this.props.adminId,
            this.props.client,
            this.props.isTestResponse
          ).then((result) => {
            let info = result.data.addResponseToSurvey.full_survey_response;
            
            if(info.status === 'complete'|| (info.next && info.next.questions && info.next.questions.length === 0 && info.progress === 100)){
              this.props.onFinish();
            }
            this.loadResponseDetails(info);
            this.setState({isLoading:false});
          }, error => {
            if(error && error.networkError && error.networkError.result && error.networkError.result.error){
              this.setState({errors:error.networkError.result.error, isLoading:false});
            }
            else if(error != null && error.data != null && error.data.error != null){
              this.setState({errors:error.data.error, isLoading:false});
            }
            else{
              let {t} = this.props;
              this.setState({errors:t('UNKNOWN_ERROR', {i18n}), isLoading:false});
            }
          });
        }
        else{
          let {t} = this.props;
          this.setState({errors:t('DATA_QUERY_REASON_IS_MANDATORY', {i18n}), isLoading:false});
        }
      }
    }
  }
  
  hasAnAnswer(){
    let returnValue = true;
    
    for(let answer of this.state.answersArray){
      returnValue = returnValue && (answer != null && (answer.value  || (answer.values && answer.values.length > 0)));
    }
    return returnValue;
  }
  
  previousQuestion(){
    if(!this.state.isLoading){
      this.setState({isLoading:true});
      
      if(!this.props.editable || !this.hasAnAnswer() || !this.answerWasChanged()){
        this.loadQuestionBefore();
      }
      else{
        if(this.allChangedAnswersHaveDataQueryDescriptions()){
          this.props.respondToSurvey(
            this.props.surveyId,
            this.getSurveyResponseDetailId(),
            this.answersToSubmit(),
            this.props.userId,
            this.props.adminId,
            this.props.client,
            this.props.isTestResponse
          ).then(
            result => {
              this.loadQuestionBefore();
            }, error => {
              if(error && error.networkError && error.networkError.result && error.networkError.result.error){
                this.setState({errors:error.networkError.result.error, isLoading:false});
              }
              else if(error != null && error.data != null && error.data.error != null){
                this.setState({errors:error.data.error, isLoading:false});
              }
              else{
                let {t} = this.props;
                this.setState({errors:t('UNKNOWN_ERROR', {i18n}), isLoading:false});
              }
              this.loadQuestionBefore();
            });
        }
        else{
          let {t} = this.props;
          this.setState({errors:t('DATA_QUERY_REASON_IS_MANDATORY', {i18n}), isLoading:false});
        }
      }
    }
  }
  
  loadQuestionAfter(){
    this.loadQuestion(true, null);
  }
  
  loadQuestionBefore(){
    this.loadQuestion(false, null);
  }
  
  loadQuestion(aGetQuestionAfter, aSurveyResponseDetailId){
    let questionKey = null;
    let surveyResponseId = this.getSurveyResponseDetailId();
    
    if(!aSurveyResponseDetailId && this.state.questionsArray && this.state.questionsArray.length > 0){
      questionKey = this.state.questionsArray[0].key;
      
      if(aGetQuestionAfter){
        questionKey = this.state.questionsArray[this.state.questionsArray.length - 1].key;
      }
    }
    this.props.questionBeforeKey(aSurveyResponseDetailId, this.props.surveyId, questionKey, this.props.userId, aGetQuestionAfter, surveyResponseId).then(response => {
      let info = response.data.data.getQuestionBeforeKey.full_survey_response;
      
      if(info.status === 'complete'|| (info.next && info.next.questions && info.next.questions.length === 0 && info.progress === 100)){
        this.props.onFinish();
      }
      else{
        this.loadResponseDetails(info);
      }
      this.setState({isLoading:false});
    }, error => {
      if(error != null && error.data != null && error.data.error != null){
        this.setState({errors:error.data.error, isLoading:false});
      }
      else{
        let {t} = this.props;
        this.setState({errors:t('UNKNOWN_ERROR', {i18n}), isLoading:false});
      }
    });
  }
  
  beginSurvey(){
    this.setState({begin:true});
  }
  
  getSurveyResponseDetailId(){
    let returnValue = null;
    
    if(this.props.surveyResponseId){
      returnValue = this.props.surveyResponseId;
    }
    return returnValue;
  }
  
  finishSurvey(){
    if(!this.state.isLoading){
      this.setState({isLoading:true});
      
      if(!this.props.editable || !this.answerWasChanged()){
        this.props.onFinish();
        this.setState({isLoading:false});
      }
      else{
        if(this.allChangedAnswersHaveDataQueryDescriptions()){
          this.props.respondToSurvey(
            this.props.surveyId,
            this.getSurveyResponseDetailId(),
            this.answersToSubmit(),
            this.props.userId,
            this.props.adminId,
            this.props.client,
            this.props.isTestResponse
          ).then((result) => {
            this.props.onFinish();
            this.setState({isLoading:false});
          }, error => {
            if(error && error.networkError && error.networkError.result && error.networkError.result.error){
              this.setState({errors:error.networkError.result.error, isLoading:false});
            }
            else if(error != null && error.data != null && error.data.error != null){
              this.setState({errors:error.data.error, isLoading:false});
            }
            else{
              let {t} = this.props;
              this.setState({errors:t('UNKNOWN_ERROR', {i18n}), isLoading:false});
            }
          });
        }
        else{
          let {t} = this.props;
          this.setState({errors:t('DATA_QUERY_REASON_IS_MANDATORY', {i18n}), isLoading:false});
        }
      }
    }
  }
  
  saveAnswer(aQuestion, value, values = [], other = null, otherValue = null, shouldTurnValues = true, aFile = null){
    let editedValues = values;
    
    if(shouldTurnValues){
      editedValues = this.turnValuesIntoObjectsBeforeSending(values);
    }
    let answer = this.findAnswerForQuestion(aQuestion);
    answer.value = value;
    answer.values = editedValues;
    answer.other = other;
    answer.other_value = otherValue;
    
    if(aFile){
      answer.file = aFile;
    }
    let answersArray = this.state.answersArray;
    let index = this.state.answersArray.indexOf(answer);
    answersArray[index] = answer;
    this.setState({answersArray:answersArray});
  };
  
  addError = errors => this.setState({errors});
  
  addWarning = (aKey, aWarning) => {
    const warnings = {...this.state.warnings};
    warnings[aKey] = [aWarning].flat();
    this.setState({warnings});
  };
  
  renderLabelOnLeftForQuestion(aQuestion){
    return (aQuestion.label_position === 'left');
  }
  
  findAnswerForQuestion(aQuestion){
    let answer = null;
    
    if(aQuestion !== null && aQuestion !== undefined){
      answer = this.state.answersArray.find((answer) => {
        return answer.question_key === aQuestion.key;
      });
      if(answer){
        if(!answer.value && aQuestion.default_value){
          answer.value = aQuestion.default_value;
        }
        if(!answer.values && aQuestion.default_values && aQuestion.default_values.length > 0){
          answer.values = aQuestion.default_values;
        }
        if((!answer.values || Object.keys(answer.values).length === 0) && (aQuestion.type === "medication" || aQuestion.type === "multi_medication") && aQuestion.default_medications && aQuestion.default_medications.length > 0){
          let parsed_answer = [];
          aQuestion.default_medications.forEach(function(aValue){
            parsed_answer.push({key:aValue.sequence, value:JSON.stringify(aValue.raw_response)})
          });
          answer.values = parsed_answer
        }
        if(answer.values){
          answer.prettyValues = this.turnValuesIntoKeyValPairs(answer.values,answer.question_type === 'checkbox');
        }
        if(!answer.other && aQuestion.default_other){
          answer.other = aQuestion.default_other;
        }
        if(!answer.other_value && aQuestion.default_other_value && aQuestion.default_other_value.length > 0){
          answer.other_value = aQuestion.default_other_value;
        }
      }
    }
    return answer;
  };
  
  updateAnswerDataQuery(aAnswer, aDataQuery){
    if(aAnswer){
      let answersArray = this.state.answersArray;
      let index = answersArray.indexOf(aAnswer);
      
      if(index < answersArray.length){
        if(!aAnswer.data_query){
          aAnswer.data_query = {description:aDataQuery, alert:false};
        }
        else{
          aAnswer.data_query.description = aDataQuery;
        }
        answersArray[index] = aAnswer;
        this.setState({answersArray:answersArray});
      }
    }
  }
  
  questionHasEConsentAnswer = (aQuestion) => {
    let returnValue = false;
    
    if(aQuestion){
      const {type, surveyResponseDetailId} = aQuestion;
      returnValue = ((type === 'econsent' || type === 'econsent_hsc') && surveyResponseDetailId && surveyResponseDetailId.length > 0);
    }
    return returnValue;
  };
  
  cellIsDisabled = (aQuestion) => {
    const {editable} = this.props;
    let returnValue = !editable;
    
    if(this.questionHasEConsentAnswer(aQuestion)){
      returnValue = true;
    }
    return returnValue;
  };
  
  cellForQuestion = (aQuestion) => {
    let returnValue = <Translation>{(t, {i18n}) => t('ERROR')}</Translation>;
    
    if(aQuestion){
      const {editable, getMedraConditions, getDynamicListItems, fetchMedicationsWithCountryCode, getConditions} = this.props;
      const {size} = this.state;
      const {type} = aQuestion;
      const DynamicCell = QuestionCells[type];
      
      if(DynamicCell){
        const answer = this.findAnswerForQuestion(aQuestion);
        let disabled = !editable || answer.skipped;
        
        if(this.questionHasEConsentAnswer(aQuestion)){
          disabled = true;
        }
        returnValue = <DynamicCell size={size}
                                   answer={answer}
                                   onError={this.addError}
                                   onWarning={this.addWarning}
                                   disabled={disabled}
                                   question={aQuestion}
                                   getConditions={getConditions}
                                   onAnswerChange={this.saveAnswer}
                                   getMedraConditions={getMedraConditions}
                                   getDynamicListItems={getDynamicListItems}
                                   fetchMedicationsWithCountryCode={fetchMedicationsWithCountryCode}
        />
      }
      else if(type === 'blank'){
        returnValue = null;
      }
      else{
        returnValue = <Translation>{(t, {i18n}) => t('ERROR_UNKNOWN_SURVEY_QUESTION', {aQuestionType:type})}</Translation>;
      }
    }
    return returnValue;
  };
  
  skipQuestion(aQuestion){
    let answer = this.findAnswerForQuestion(aQuestion);
    answer.skipped = !answer.skipped;
    let answersArray = this.state.answersArray;
    let index = answersArray.indexOf(answer);
    answersArray[index] = answer;
    this.setState({answersArray:answersArray});
  };
  
  answersToSubmit(){
    const {answersArray, surveyResponseDetails} = this.state;
    let returnValue = [];
    
    for(let answer of answersArray){
      if(this.answerModified(answer) || !this.findSurveyResponseDetailForKey(answer.question_key, surveyResponseDetails)){
        returnValue.push(answer);
      }
    }
    return returnValue;
  }
  
  render(){
    const {size, errors, progress, isLoading, warnings, pageTitle, pageNumber, questionsArray, surveyResponseDetails} = this.state;
    const {addDataQuery, editable, company, disableScroll, isTestResponse, showPreviousButton = true} = this.props;
    const currentRole = roleForCompany(company);
    const sensitiveDataAnswerProhibitions = ['admin_level_1'];
    
    let surveySizeClassName = '';
    let lastQuestionType = questionsArray && questionsArray.length > 0 && questionsArray[questionsArray.length - 1].type;
    
    if(size === 'medium'){
      surveySizeClassName = 'survey-question-container-medium';
    }
    else if(size === 'large'){
      surveySizeClassName = 'survey-question-container-large';
    }
    
    return (
      <div className={(disableScroll ? 'survey-question-container-disabled-scroll ' : 'survey-question-container ') + surveySizeClassName}>
        {progress !== null && progress > 0 ?
          <div className="survey-progress-bar-container">
            <div style={{fontWeight:600}}>
              <Translation>{(t, {i18n}) => t('PROGRESS')}</Translation>
            </div>
            
            <div className='progress survey-progress-bar'>
              <div className='progress-bar' style={{width:progress + '%', minWidth:'10%', height:'20px'}}>
                {progress}%
                &nbsp;
                <Translation>{(t, {i18n}) => t('COMPLETE')}</Translation>
              </div>
            </div>
          </div>
          :
          null
        }
        
        {errors && errors !== '' ?
          <div className='error-message-question survey-question-error'>
            {errors}
          </div>
          :
          null
        }
        
        <div className={((errors && errors !== '') ? 'survey-question-inner-container-error' : 'survey-question-inner-container')}>
          <div style={{height:'calc(100%)', overflowY:'hidden'}}>
            <div className={'survey-question-and-buttons-spread survey-question-container-inner'}>
              {!isLoading && pageTitle && pageTitle.length > 0 ?
                <div className="survey-page-title">
                  {pageTitle}
                </div>
                :
                null
              }
              
              {!isLoading && questionsArray && questionsArray.length > 0 ?
                questionsArray.map((question, index) => (
                  <div key={'survey-cell-' + question.label + '-' + index}>
                    {warnings && warnings[question.key] ?
                      <div className={'error-message-question'}>
                        {warnings[question.key].join(', ')}
                      </div>
                      :
                      null
                    }
                    
                    <div className={'question-cell ' + (this.renderLabelOnLeftForQuestion(question) ? 'question-cell-large' : '')}>
                      {question.label ?
                        <div className={'survey-title ' + (this.renderLabelOnLeftForQuestion(question) ? 'survey-label-left ' : '') + (size === 'large' ? 'survey-label-left-large ' : '')}>
                          {question.label}
                        </div>
                        :
                        null
                      }
                      
                      {question.is_sensitive_data_question && sensitiveDataAnswerProhibitions.includes(currentRole) ?
                        <Translation>{(t, {i18n}) => t('PERMISSION_DENIED')}</Translation>
                        :
                        <>
                          {this.cellForQuestion(question)}
                          
                          {question.skip ?
                            <SurveySkipCell answer={this.findAnswerForQuestion(question)}
                                            disabled={this.cellIsDisabled(question)}
                                            question={question}
                                            skipQuestion={() => this.skipQuestion(question)}/>
                            :
                            null
                          }
                        </>
                      }
                    </div>
                    
                    {!isTestResponse ?
                      <>
                        {this.findSurveyResponseDetailForKey(question.key, surveyResponseDetails) && this.answerModified(this.findAnswerForQuestion(question)) ?
                          <div className="survey-labeled-input-modify-reason">
                            <LabeledTextInput label={<Translation>{(t, {i18n}) => t('DATA_QUERY_LABEL')}</Translation>}
                                              value={this.findAnswerForQuestion(question) && this.findAnswerForQuestion(question).data_query ? this.findAnswerForQuestion(question).data_query.description : ''}
                                              handleSave={(aEvent) => this.updateAnswerDataQuery(this.findAnswerForQuestion(question), aEvent.target.value)}
                                              placeholder={i18next.t('MODIFICATION_REASON')}
                            />
                          </div>
                          :
                          null
                        }
                        
                        {editable && this.findSurveyResponseDetailForKey(question.key, surveyResponseDetails) ?
                          <div className={'survey-add-query-button-question-container ' + (this.renderLabelOnLeftForQuestion(question, surveyResponseDetails) ? 'survey-add-query-button-question-container-column' : '')}
                               key={'DataQuery' + question.label + index}
                               onClick={() => addDataQuery(this.findSurveyResponseDetailForKey(question.key, surveyResponseDetails).id)}>
                            <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
                            
                            <Translation>{(t, {i18n}) => t('ADD_A_DATA_QUERY')}</Translation>
                          </div>
                          :
                          (!editable && company.role === 'admin_level_1' && this.findSurveyResponseDetailForKey(question.key, surveyResponseDetails) ?
                              <div className={'survey-add-query-button-question-container ' + (this.renderLabelOnLeftForQuestion(question, surveyResponseDetails) ? 'survey-add-query-button-question-container-column' : '')}
                                   key={'DataQuery' + question.label + index}
                                   onClick={() => addDataQuery(this.findSurveyResponseDetailForKey(question.key, surveyResponseDetails).id)}>
                                <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
                                
                                <Translation>{(t, {i18n}) => t('ADD_A_DATA_QUERY')}</Translation>
                              </div>
                              :
                              null
                          )
                        }
                      </>
                      :
                      null
                    }
                  </div>
                ))
                :
                null
              }
              
              <Loading className="survey-loading-spinner"
                       loading={isLoading}
              />
              
              {(lastQuestionType === 'date' || lastQuestionType === 'datetime' || lastQuestionType === 'month' || lastQuestionType === 'year' || lastQuestionType === 'monthyear') ?
                <div style={{height:'250px', width:'100%'}}/>
                :
                <div style={{height:'100px', width:'100%'}}/>
              }
            </div>
          </div>
          
          <div className={'survey-button-container'}>
            <div className={'survey-page-count'}>
              {pageNumber}
            </div>
            
            {showPreviousButton && !this.onFirstQuestion() ?
              <button className={'survey-button'}
                      onClick={() => this.previousQuestion()}>
                <Translation>{(t, {i18n}) => t('PREVIOUS_BUTTON')}</Translation>
              </button>
              :
              null
            }
            
            {this.onLastQuestion() ?
              <button className={'survey-button green-button'}
                      onClick={() => this.finishSurvey()}>
                <Translation>{(t, {i18n}) => t('FINISH')}</Translation>
              </button>
              :
              this.shouldShowConfirm() ?
                <button className={'survey-button green-button'}
                        onClick={this.confirmAndNextQuestion}>
                  <Translation>{(t, {i18n}) => t('CONFIRM_BUTTON')}</Translation>
                </button>
                :
                <button className={'survey-button green-button'}
                        onClick={() => this.nextQuestion()}>
                  <Translation>{(t, {i18n}) => t('NEXT_PROPER_CAPITALIZED')}</Translation>
                </button>
            }
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(SurveyComponent)
