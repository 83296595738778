import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

// Export Functions

export function fetchTriggers(aLimit, aOffset, aSearchString, aGroupId, aPatientId){
  EventTracker.track(EventTypes.FETCH_TRIGGERS);
  let query = `
  query getTriggers ($company_id: ID!, $limit: Int, $offset: Int) {
      triggers (company_id: $company_id, limit: $limit, offset: $offset){
          triggers {
            id
            created_at
            updated_at
            type
            is_repeatable
            is_reminder
            send_to_company_admins
            start_offset
            start_offset_unit
            end_offset
            end_offset_unit
            minimum_duration_between_events
            minimum_duration_between_events_unit
            hour_of_day
            maximum_hour_of_day
            minimum_value
            maximum_value
            keyword
            score_name
            user_country_name
            alert_type
            additional_app_column_name
            survey_consent_question_keyword
            target_id
            target_type
            target_name
            secondary_target_id
            secondary_target_type
            secondary_target_name
            notification_type
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    addKeyAndValueToParams('group_id', aGroupId, variables);
    addKeyAndValueToParams('patient_id', aPatientId, variables);
    addKeyAndValueToParams('search_string', aSearchString, variables);
    return global.api.post("/queries", { query, variables })
  };
}

export function removeTrigger(aTriggerId){
  EventTracker.track(EventTypes.REMOVE_TRIGGER);
  let query = `
  mutation RemoveTrigger ($removeTriggerMutationInput: RemoveTriggerMutationInput!) {
        removeTrigger (input: $removeTriggerMutationInput){
          trigger {
            id
          }
        }
      }
  `;
  let operationName = `RemoveTriggerMutationInput`;
  
  return (dispatch, getState) => {
    let state = getState();
    let variables = {
      removeTriggerMutationInput: {
        id: parseInt(aTriggerId),
        company_id: state.company && state.company.company && state.company.company.id
      }
    };
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function createTrigger(aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
                              aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
                              aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName,
                              aUserCountryName, aAlertType, aAdditionalAppColumnName, aSurveyConsentQuestionKeyword,
                              aTargetId, aSecondaryTargetId, aNotificationType){
  EventTracker.track(EventTypes.CREATE_TRIGGER);
  return modifyTrigger(null, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
    aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit, aHourOfDay,
    aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName, aUserCountryName, aAlertType,
    aAdditionalAppColumnName, aSurveyConsentQuestionKeyword, aTargetId, aSecondaryTargetId, aNotificationType);
}

export function updateTrigger(aId, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
                              aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
                              aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName,
                              aUserCountryName, aAlertType, aAdditionalAppColumnName, aSurveyConsentQuestionKeyword,
                              aTargetId, aSecondaryTargetId, aNotificationType){
  EventTracker.track(EventTypes.UPDATE_TRIGGER);
  return modifyTrigger(aId, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
    aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit, aHourOfDay,
    aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName, aUserCountryName, aAlertType,
    aAdditionalAppColumnName, aSurveyConsentQuestionKeyword, aTargetId, aSecondaryTargetId, aNotificationType);
}

export function fetchSurveyTriggers(aLimit, aOffset, aSearchString){
  return getSurveyTriggers(aLimit, aOffset, aSearchString);
}

export function fetchUserTriggers(aLimit, aOffset, aUserEmail, aType){
  return getUserTriggers(aLimit, aOffset, aUserEmail, aType);
}

export function getSurveyTriggers(aLimit, aOffset, aSearchString){
  let query = `
    query getSurveyConfigurableTriggers($company_id: ID!, $limit: Int, $offset: Int, $search_string: String) {
      survey_configurable_triggers(company_id: $company_id, limit: $limit, offset: $offset, search_string: $search_string) {
          triggers {
            value
            operation
            survey_name
            trigger_type
            question_key
            survey_reference
          }
          total
        }
    }
  `;
  let variables = {};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function getUserTriggers( aLimit, aOffset, eUserEmail, aType ) {
  let query = `
    query getSurveyConfigurableTriggers($company_id: ID!, $user_email: String, $limit: Int, $offset: Int, $search_string: String, $type: String) {
      sent_user_notifications(company_id: $company_id, user_email: $user_email, limit: $limit, offset: $offset, search_string: $search_string, type: $type) {
        notifications {
          start_date
          end_date
          visibility
          created_at
          quest_name
          last_triggered_at
          last_error_message
          target_type
          alert_type
          trigger_keyword
          tt_id
        } 
        total 
      }
    }
  `;
  let variables = {};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['user_email'] = eUserEmail;
    variables['type'] = aType;
    return global.api.post("/queries", { query, variables })
  };
}

// Helper Functions

function modifyTrigger(aId, aType, aIsRepeatable, aIsReminder, aSendToCompanyAdmins, aStartOffset, aStartOffsetUnit,
                       aEndOffset, aEndOffsetUnit, aMinimumDurationBetweenEvents, aMinimumDurationBetweenEventsUnit,
                       aHourOfDay, aMaximumHourOfDay, aMinimumValue, aMaximumValue, aKeyword, aScoreName,
                       aUserCountryName, aAlertType, aAdditionalAppColumnName, aSurveyConsentQuestionKeyword, aTargetId,
                       aSecondaryTargetId, aNotificationType){
  let query = `
  mutation AddUpdateTrigger($addUpdateTriggerMutationInput: AddUpdateTriggerMutationInput!) {
        addUpdateTrigger(input: $addUpdateTriggerMutationInput) {
          trigger {
            id
            created_at
            updated_at
            type
            is_repeatable
            is_reminder
            send_to_company_admins
            start_offset
            start_offset_unit
            end_offset
            end_offset_unit
            minimum_duration_between_events
            minimum_duration_between_events_unit
            hour_of_day
            maximum_hour_of_day
            minimum_value
            maximum_value
            keyword
            score_name
            user_country_name
            alert_type
            additional_app_column_name
            survey_consent_question_keyword
            target_id
            target_type
            target_name
            secondary_target_id
            secondary_target_type
            secondary_target_name
            notification_type
          }
        }
      }
  `;
  
  return (dispatch, getState) => {
    let state = getState();
    let params = {};
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    addKeyAndValueToParams('id', aId, params);
    addKeyAndValueToParams('type', aType, params);
    addKeyAndValueToParams('is_repeatable', aIsRepeatable, params);
    addKeyAndValueToParams('is_reminder', aIsReminder, params);
    addKeyAndValueToParams('send_to_company_admins', aSendToCompanyAdmins, params);
    addKeyAndValueToParams('start_offset', aStartOffset && parseInt(aStartOffset), params);
    addKeyAndValueToParams('start_offset_unit', aStartOffsetUnit, params);
    addKeyAndValueToParams('end_offset', aEndOffset && parseInt(aEndOffset), params);
    addKeyAndValueToParams('end_offset_unit', aEndOffsetUnit, params);
    addKeyAndValueToParams('minimum_duration_between_events', aMinimumDurationBetweenEvents, params);
    addKeyAndValueToParams('minimum_duration_between_events_unit', aMinimumDurationBetweenEventsUnit, params);
    addKeyAndValueToParams('hour_of_day', aHourOfDay, params);
    addKeyAndValueToParams('maximum_hour_of_day', aMaximumHourOfDay, params);
    addKeyAndValueToParams('minimum_value', aMinimumValue, params);
    addKeyAndValueToParams('maximum_value', aMaximumValue, params);
    addKeyAndValueToParams('keyword', aKeyword, params);
    addKeyAndValueToParams('score_name', aScoreName, params);
    addKeyAndValueToParams('user_country_name', aUserCountryName, params);
    addKeyAndValueToParams('alert_type', aAlertType, params);
    addKeyAndValueToParams('additional_app_column_name', aAdditionalAppColumnName, params);
    addKeyAndValueToParams('survey_consent_question_keyword', aSurveyConsentQuestionKeyword, params);
    addKeyAndValueToParams('target_id', parseInt(aTargetId), params);
    addKeyAndValueToParams('secondary_target_id', aSecondaryTargetId, params);
    addKeyAndValueToParams('notification_type', aNotificationType, params);
    
    let variables = {
      addUpdateTriggerMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName:'AddUpdateTrigger' });
  };
}

function addKeyAndValueToParams(aKey, aValue, aParams){
  if(aKey && aValue && aParams){
    aParams[aKey] = aValue;
  }
}
