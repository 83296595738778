import Login from '../Pages/Login/Login'
import actions from 'actions';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
  return {
    user:state.current && state.current.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    login:(aEmail, aPassword, aLocale, aOtp, aPhoneNumber, aSignInToken) => {
      return dispatch(actions.login(aEmail, aPassword, aLocale, aOtp, aPhoneNumber, aSignInToken));
    },
    loadUser:() => {
      return dispatch(actions.loadUser());
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
