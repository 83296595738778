import React from 'react';
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import ToolBlockDetails from '../ToolBlockDetails/ToolBlockDetails'
import BlockAdvancedSettings from '../BlockAdvancedSettings/BlockAdvancedSettings'
import { flow } from 'lodash'
import { IoIosReorder } from 'react-icons/io'
import {Translation, withTranslation} from "react-i18next";
import i18n from "../../../../i18n";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {Switch} from "antd";
import '../../../../Modals/ToolModal/ToolModal.scss';
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

const blockSource = {
  beginDrag(props) {
    return {
      block: props.block,
      index: props.index
    }
  },

  endDrag(monitor) {
    if (!monitor.didDrop()) {
      return
    }
  }
}

const blockTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveBlock(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex
  },
}

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class ToolBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolType: '',
      inputRequired: false
    }
    if(props.block){
      this.state.toolType = props.block.type;
      this.state.inputRequired = props.block.required;
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(!this.props.block.type) {
      if (prevProps.block.type !== this.props.block.type) {
        this.setState({toolType: ''});
      }
    }
  }

  handleSelect(aOption) {
    this.setState({toolType:aOption.value, inputRequired: false})
    let value = aOption.value
    this.props.resetBlock(value, this.props.index)
  }

  handleSwitchChange(aKey, aValue){
    this.setState({inputRequired: aValue})
    let index = this.props.index;
    this.props.updateBlock({[aKey]: aValue}, index);
  }

  render() {
    const { t, menuButtonClicked } = this.props;
    const { blockTypes, isDragging, connectDragSource, connectDropTarget, block, index, updateBlock, selectedTranslationIndex} = this.props
    let objectiveOptions = Object.keys(blockTypes).map((key) => {
      let objective = blockTypes[key]
      return {label: i18n.t(objective.label), value: key}
    })
    let hasAdvanced = block.type === 'RoutineObjective'
    return connectDropTarget(

        <div key={index} style={{opacity: isDragging ? 0.5 : 1, border: '1px solid #A8A8A8', borderRadius: '10px', marginBottom: '20px', marginRight: '20px'}}>

            <div className="tools-modal-title-cell">
              {connectDragSource(
                <div style={{padding: '5px', cursor: 'move'}}>
                  <IoIosReorder style={{fontSize: '21px', color: '#4A4A4A'}} />
                </div>)}
              <div style={{display: 'flex', width: 600}}>
                <div style={{width: '100%', color: '#A8A8A8',fontSize: 14, fontWeight: 500, paddingLeft: 40, paddingBottom: '5px'}}>
                  <LabeledDropDown className="manage-tools-dropdown"
                                   name={index}
                                   value={this.state.toolType && objectiveOptions.filter(option => {
                                     return this.state.toolType === option.value
                                   })}
                                   handleSave={this.handleSelect}
                                   placeholder={t('CHOOSE_A_BLOCK_TYPE', {i18n})}
                                   options={objectiveOptions}
                  />
                </div>


              </div>
              <BiDotsVerticalRounded style={{fontSize: '21px', color: '#4A4A4A', marginLeft: 'auto'}}
                                     onClick={(aEvent) => menuButtonClicked(aEvent)}
              />
            </div>


          {Object.keys(blockTypes).includes(block.type) || block.type === "" ?
              <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>

                <div>
                  { block.type !== "" &&
                    <ToolBlockDetails
                      block={block}
                      index={index}
                      updateBlock={updateBlock}
                      blockTypes={blockTypes}
                      selectedTranslationIndex={selectedTranslationIndex}
                    />
                  }
                </div>
                {hasAdvanced &&
                    <div>
                      <BlockAdvancedSettings
                        block={block}
                        updateBlock={updateBlock}
                        index={index}
                      />
                    </div>
                }

                { block.type !== "" &&
                  <div className="tool-input-switch-required">
                    <Translation>{(t) => t('INPUT_REQUIRED')}</Translation>
                    <Switch checked={this.state.inputRequired || false} onChange={(aValue) => this.handleSwitchChange('required', aValue)}/>
                  </div>
                }
              </div>

              :
              <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                <div style={{width: '100%'}}>{block.type}</div>
                <div className="tool-input-switch-required">
                  <Translation>{ (t) => t('INPUT_REQUIRED') }</Translation>
                  <Switch checked={this.state.inputRequired || false} onChange={(aValue) => this.handleSwitchChange('required', aValue)}/>
                </div>
              </div>

          }

          {/*<div style={{display: 'flex', flexDirection: 'column', padding: '5px'}}>*/}
          {/*  <RemoveItem*/}
          {/*      title={<Translation>{ (t, { i18n }) => t('REMOVE_BLOCK')}</Translation>}*/}
          {/*    removeFunc={removeBlock}*/}
          {/*    removeId={index}*/}
          {/*  />*/}
          {/*  <div className="clickable" onClick={() => duplicateBlock(block,index)} style={{fontSize: '12px'}}>*/}
          {/*    <i className="ion-ios7-copy-outline" style={{fontSize: '18px', marginRight: '5px'}}/>*/}
          {/*    <Translation>{ (t, { i18n }) => t('DUPLICATE_BLOCK') }</Translation>*/}
          {/*  </div>*/}
          {/*</div>*/}


        </div>
      )
  }
}

export default flow(
  DropTarget('block', blockTarget, collectTarget),
  DragSource('block', blockSource, collectSource),
  withTranslation()
)(ToolBlock)
