import React from "react";
import './AdminHistory.scss'
import i18n from "i18next";
import moment from "moment";
import TabBar from "../../Custom UI/TabBar/TabBar";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {Translation, withTranslation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import LabeledDelayedInput from "Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import i18next from "i18next";

class AdminHistory extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      upToDate:null,
      userIdFilter:null,
      tabBarKey:'AUDIT_LOG',
      showChanges:false,
      shouldReload:false,
      historiesToShowChangesFor:[]
    };
    this.renderChanges = this.renderChanges.bind(this);
    this.renderUpdatedBy = this.renderUpdatedBy.bind(this);
    this.renderUpdatingAction = this.renderUpdatingAction.bind(this);
  }
  
  // Methods
  
  renderUpdatingAction(aEventType, aHistory){
    let returnValue = '';
    
    if(aEventType && aHistory){
      const translationKey = aEventType === 'destroy' ? 'DESTROYED' : aEventType.toUpperCase() + 'D';
      returnValue = i18n.t(translationKey) + ' ' + aHistory.item_type + ' (ID: ' + aHistory.id + ')';
    }
    return returnValue;
  }
  
  renderUpdatedBy(aValue){
    let returnValue = '';
    
    if(aValue){
      if(aValue.first_name != null){
        returnValue = aValue.first_name;
      }
      if(aValue.last_name != null){
        returnValue += ' ' + aValue.last_name;
      }
    }
    
    if(this.props.studyClosed) {
      returnValue = 'ID: ' + aValue.id;
    } else {
      returnValue += ' - ID: ' + aValue.id;
    }
    return returnValue;
  }
  
  renderChanges(aHistory){
    const {historiesToShowChangesFor} = this.state;
    let historiesArray = [];
    
    if(aHistory && aHistory.change_list){
      const maxLength = 111;
      const changeList = aHistory.change_list;
      
      for(let change of changeList){
        let newValue = '';
        let oldValue = '';
        
        if(change.new_value){
          newValue = change.new_value.toString();
          
          if(newValue.length > maxLength){
            newValue = newValue.substring(0, maxLength - 3) + '...';
          }
        }
        if(change.old_value){
          oldValue = change.old_value.toString();
          
          if(oldValue.length > maxLength){
            oldValue = oldValue.substring(0, maxLength - 3) + '...';
          }
        }
        historiesArray.push({key:change.key, old_value:oldValue, new_value:newValue});
      }
    }
    const shortenedHistoriesArray = historiesArray.slice(0, 2);
    const shouldShowSwitch = shortenedHistoriesArray.length < historiesArray.length;
    
    return (
      <div>
        {Array.isArray(historiesToShowChangesFor) && historiesToShowChangesFor.includes(aHistory.id) ?
          <div>
            {historiesArray.map((change, index) => {
                return (
                  <div key={index}
                       style={{fontSize:12, width:'100%', wordBreak:'break-all', wordWrap:'break-word'}}>
                    <Translation>{(t, {i18n}) => t('CHANGED_KEY', {change})}</Translation>
                  </div>
                )
              }
            )}
            
            {shouldShowSwitch ?
              <div style={{fontSize:12, fontWeight:400}}
                   onClick={() => {
                     const index = historiesToShowChangesFor.indexOf(aHistory.id);
                     let newHistoriesToShowChangesFor = historiesToShowChangesFor;
                
                     if(index > -1){
                       newHistoriesToShowChangesFor.splice(index, 1);
                     }
                     this.setState({historiesToShowChangesFor:newHistoriesToShowChangesFor});
                   }}>
                <Translation>{(t, {i18n}) => t('SHOW_LESS')}</Translation>
              </div>
              :
              null
            }
          </div>
          :
          <div>
            {shortenedHistoriesArray.map((change, index) => {
                return (
                  <div key={index}
                       style={{fontSize:12, width:'100%', wordBreak:'break-all', wordWrap:'break-word'}}>
                    <Translation>{(t, {i18n}) => t('CHANGED_KEY', {change})}</Translation>
                  </div>
                )
              }
            )}
            
            {shouldShowSwitch ?
              <div style={{fontSize:12, fontWeight:400}}
                   onClick={() => {
                     let newHistoriesToShowChangesFor = historiesToShowChangesFor;
                     newHistoriesToShowChangesFor.push(aHistory.id);
                     this.setState({historiesToShowChangesFor:newHistoriesToShowChangesFor});
                   }}>
                <Translation>{(t, {i18n}) => t('SHOW_MORE')}</Translation>
              </div>
              :
              null
            }
          </div>
        }
      </div>
    );
  }

  // Render

  render(){
    const {userId, company, currentRole, getAdminHistories, studyClosed, showPatientSearch} = this.props;
    const {loading, upToDate, userIdFilter, tabBarKey, shouldReload} = this.state;
    const companyRole = roleForCompany(company);
    const adminHistoriesPermissions = ['admin_level_4', 'admin'];
    
    let columnsArray = [];
    columnsArray.push({key:'action', columnWeight:2, columnNameKey:'ACTION', propertiesArray:['event_type'], valueFormatter:(aEventType, aHistory) => this.renderUpdatingAction(aEventType, aHistory)});
    columnsArray.push({key:'updated_at', columnWeight:1, columnNameKey:'UPDATED_AT', propertiesArray:['edited_at'], dateFormat:'YYYY-MM-DD, h:mm:ss a'});
    
    if(!userId){
      columnsArray.push({key:'updated_by', columnWeight:1, columnNameKey:'UPDATED_BY', propertiesArray:['edited_by'], valueFormatter:(aValue) => this.renderUpdatedBy(aValue)});
    }
    columnsArray.push({key:'changes', columnWeight:3, columnNameKey:'CHANGES', templateCell:(aHistory) => this.renderChanges(aHistory)});
    
    return (
      <div>
        {(adminHistoriesPermissions.includes(currentRole) || adminHistoriesPermissions.includes(companyRole) || studyClosed ) ?
          <div className="main-panel-inner-container">
            {/*TODO: This shouldn't be done this way. The list should be moved to its own component, and a*/}
            {/*      ManageAdminHistories Page should be added, with the list as a subcomponent, like the other pages.*/}
            {!userId ?
              <div>
                <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('AUDIT_LOG') }</Translation>}/>
                
                <TabBar tabBarTabsArray={[{key:'AUDIT_LOG'}]}
                        initialSelectedTabKey={'AUDIT_LOG'}
                        selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
                />
              </div>
              :
              null
            }
            
            {tabBarKey === 'AUDIT_LOG' ?
              <div className="audit-history-container">
                <div className="audit-history-list-container"
                     id='admin-histories-list'>
                  <DynamicList id="admin-history-list"
                               didFetch={() => this.setState({loading:false})}
                               didReload={() => this.setState({shouldReload:false})}
                               showTotal={false}
                               headerView={
                                <div className={showPatientSearch ? "admin-history-header-container": ""}>
                                  <div className="audit-history-search-input-container">
                                    <LabeledDatePicker className="audit-history-search-input"
                                                        label={i18next.t('SELECT_DATE_TO_SEARCH_FROM')}
                                                        value={upToDate}
                                                        loading={loading}
                                                        maxDate={moment()}
                                                        disabled={loading}
                                                        isClearable={true}
                                                        handleSave={(aDate) => this.setState({loading:true, upToDate:aDate, shouldReload:true})}
                                    />
                                  </div>

                                  {showPatientSearch ?
                                    <div className="admin-history-search-by-specific-patient-input-container">
                                      <LabeledDelayedInput className="admin-history-search-by-specific-patient-input"
                                                           label={i18next.t('SEARCH_BY_SPECIFIC_PATIENT') }
                                                           value={userIdFilter}
                                                           debounceTimeout={300}
                                                           minLength={1}
                                                           placeholder={i18n.t('SEARCH_PATIENT')}
                                                           clearButtonClicked={() => this.setState({userIdFilter:'', shouldReload:true})}
                                                           handleSave={(aEvent) => this.setState({shouldReload:true, userIdFilter:aEvent.target.value})}
                                      />
                                    </div>
                                    : null
                                  }
                                </div>
                               }
                               columnsArray={columnsArray}
                               fetchObjects={(aLimit, aOffset) => getAdminHistories(userId, upToDate, aLimit, aOffset, userIdFilter)}
                               shouldReload={shouldReload}
                               showSearchBar={false}
                               explanationKey={'AUDIT_LOG_TEXT'}
                               minColumnWidth={200}
                               responseTotalKeysArray={['data', 'data', 'admin_history_page', 'total']}
                               responseObjectKeysArray={['data', 'data', 'admin_history_page', 'admin_histories']}
                  />
                </div>
              </div>
              :
              null
            }
          </div>
          :
          <div>
            <Translation>{ (t, { i18n }) => t('CONTACT_ADMIN')}</Translation>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(AdminHistory);