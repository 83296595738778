import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchPaymentTriggers(aLimit, aOffset, aSearchString) {
  EventTracker.track(EventTypes.FETCH_PAYMENT_TRIGGERS);
  return getPaymentTriggers(null, aLimit, aOffset, aSearchString);
}

export function fetchPaymentTriggersForGroup(aGroupId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_PAYMENT_TRIGGERS_FOR_GROUP);
  return getPaymentTriggers(aGroupId, aLimit, aOffset, aSearchString);
}

function getPaymentTriggers(aGroupId, aLimit, aOffset, aSearchString){
  let query = `
    query getHyperwalletPaymentTriggers ($company_id: ID!, $group_id: ID, $limit: Int, $offset: Int, $search_string: String) {
      hyperwallet_payment_triggers (company_id: $company_id, group_id: $group_id, limit: $limit, offset: $offset, search_string: $search_string){
        hyperwallet_payment_triggers{
          id
          title
          amount
          end_at
          currency
          start_at
          target_id
          is_enabled
          target_type
          target_name
          target_action
          limit_per_user
          action_criteria
          number_of_payments
          manual_verification_required
          additional_target_attributes {
            target_expected_value
            target_question_keyword
          }
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchGroupsForPaymentTrigger( hyperwallet_payment_trigger_id, limit, offset ) {
  let query = `
    query getHyperwalletGroupPaymentTriggers ($hyperwallet_payment_trigger_id: ID!, $limit: Int, $offset: Int) {
      hyperwallet_group_payment_triggers (hyperwallet_payment_trigger_id: $hyperwallet_payment_trigger_id, limit: $limit, offset: $offset){
          hyperwallet_group_payment_triggers {
            group {
              id
              locale
              name
            }
            is_enabled
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, _) => {
    variables['hyperwallet_payment_trigger_id'] = hyperwallet_payment_trigger_id;
    variables['limit'] = limit;
    variables['offset'] = offset;
    return global.api.post("/queries", { query, variables });
  };
}

export function updateHyperwalletGroupPaymentTrigger(isEnabled, groupId, triggerId) {
  let query = `
  mutation UpdateHyperwalletGroupPaymentTrigger($updateHyperwalletGroupPaymentTriggerMutationInput: UpdateHyperwalletGroupPaymentTriggerMutationInput!) {
        updateHyperwalletGroupPaymentTrigger(input: $updateHyperwalletGroupPaymentTriggerMutationInput) {
          hyperwallet_group_payment_trigger {
            group {
              id
              locale
              name
            }
            is_enabled
          }
        }
      }
  `;
  
  let variables = {
    updateHyperwalletGroupPaymentTriggerMutationInput: {
      is_enabled: isEnabled,
      hyperwallet_payment_trigger_id: triggerId,
      group_id: groupId
    }
  };
  let operationName = `AddUserToGroup`;
  
  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function removePaymentTrigger(aPaymentTriggerId) {
  EventTracker.track(EventTypes.REMOVE_PAYMENT_TRIGGER);
  let query = `
      mutation RemoveHyperwalletPaymentTrigger ($removeHyperwalletPaymentTriggerMutationInput: RemoveHyperwalletPaymentTriggerMutationInput!) {
        removeHyperwalletPaymentTrigger (input: $removeHyperwalletPaymentTriggerMutationInput){
          hyperwallet_payment_trigger {
            id
          }
        }
      }
  `;
  
  let operationName = `RemoveHyperwalletPaymentTrigger`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['id'] = aPaymentTriggerId;
    
    let variables = {
      removeHyperwalletPaymentTriggerMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function createPaymentTrigger(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser,
                                     aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword){
  EventTracker.track(EventTypes.CREATE_PAYMENT_TRIGGER);
  return modifyPaymentTrigger(null, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword);
}

export function updatePaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser,
                                     aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword){
  EventTracker.track(EventTypes.UPDATE_PAYMENT_TRIGGER);
  return modifyPaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword);
}

function modifyPaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser,
                              aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword){
  let query = `
    mutation SaveHyperwalletPaymentTrigger ($saveHyperwalletPaymentTriggerMutationInput: SaveHyperwalletPaymentTriggerMutationInput!) {
      saveHyperwalletPaymentTrigger (input: $saveHyperwalletPaymentTriggerMutationInput){
        hyperwallet_payment_trigger {
          id
          title
          amount
          end_at
          currency
          start_at
          target_id
          is_enabled
          target_type
          target_name
          target_action
          limit_per_user
          action_criteria
          number_of_payments
          manual_verification_required
          additional_target_attributes {
            target_expected_value
            target_question_keyword
          }
        }
      }
    }
  `;
  
  let operationName = `SaveHyperwalletPaymentTrigger`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aId){
      params['id'] = aId;
    }
    params['title'] = aTitle;
    params['amount'] = aAmount;
    params['end_at'] = aEndAt;
    params['currency'] = aCurrency;
    params['start_at'] = aStartAt;
    params['target_id'] = aTargetId;
    params['is_enabled'] = aIsEnabled;
    params['target_type'] = aTargetType;
    params['target_action'] = aTargetAction;
    params['limit_per_user'] = aLimitPerUser;
    params['manual_verification_required'] = aManualVerificationRequired;
    
    if(aTargetExpectedValue && aTargetQuestionKeyword){
      params['additional_target_attributes'] = {
        target_expected_value:aTargetExpectedValue,
        target_question_keyword:aTargetQuestionKeyword
      };
    }
    let variables = {
      saveHyperwalletPaymentTriggerMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}
