import {
  SIDEBAR_EXPANDED,
  SIDEBAR_COLLAPSED
} from '../constants/SidebarTypes';

const initialState = {
  is_expanded: false
}

function sidebar(state=initialState, action) {
  switch(action.type) {
    case SIDEBAR_EXPANDED:
      return {
        ...state,
        is_expanded: true
      };
    case SIDEBAR_COLLAPSED:
      return {
        ...state,
        is_expanded: false
      };
    default:
      return state;
  }
}

export default sidebar;