import React, { Component } from 'react';
import moment from 'moment'
import LineChart from '../../Components/VitalFlo/charts/LineChart'

const DATA_TYPES = ["spo2", "pulse", "health_summary"]
const LABELNAMES = {
  "spo2": "SpO2",
  "pulse": "Pulse",
  "health_summary": "Health Summary"
}


class CurieAiPatientCharts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      numberOfDateRangesBack: 0,
      dataPoints: {},
      fetchedDataPoints: {},
      latestDataPointDate: null
    }
  }

  mapCurieAiData(curieAiData){
    let translatedData = {
      "spo2": [],
      "pulse": [],
      "health_summary": []
    }

    let dataPoints = curieAiData.data

    const timeZone = moment.tz.guess()
    if(dataPoints?.length > 0) {
      dataPoints.forEach((reading) => (
        DATA_TYPES.forEach((type) => (
          translatedData[`${type}`].push({
            x: moment(reading.measured_at).tz(timeZone || 'America/New_York').format('lll z'),
            y: type === "health_summary" ? 1 : parseInt(reading[`${type}`]),
            health_summary: type === "health_summary" ? reading[`${type}`] : null
          })
        ))


      ))
    }





    this.setState({ dataPoints: translatedData,  fetchedDataPoints: curieAiData, latestDataPointDate: curieAiData?.latest?.measured_at })
  }

  showDataPointDetailView(index){
    this.props.toggleDataPointsListCollapsed('CurieAi', 'Health Summary',  this.state.fetchedDataPoints?.data?.[index])
  }

  componentDidMount(){
    this.mapCurieAiData(this.props.curieAiData)
  }

  render() {
    return (
      <React.Fragment>
          <div className='flex-column' style={{flex: 1, padding: "0 30px"}}>
            { this.state.dataPoints &&
              (
                DATA_TYPES.map((type) => (
                <div className="subsection-container flex-column" style={{marginTop: 20, marginBottom: 20}} key={type}>
                  <h4> { LABELNAMES[`${type}`] }</h4>
                  <LineChart
                    graphType={type}
                    dataPoints={this.state.dataPoints[`${type}`]}
                    latestDataPointDate={this.state.latestDataPointDate}
                    startDate={this.props.from}
                    endDate={this.props.to}
                    showDataPointDetailView={this.showDataPointDetailView.bind(this)}
                  />
                </div>
              )))
            }
          </div>
      </React.Fragment>
    );
  }
}

export default CurieAiPatientCharts;
