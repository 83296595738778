import React from 'react';
import "antd/dist/antd.css";
import './ToolScheduleModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import i18next from "i18next";
import {isoDateTimeNoTimeZoneFormat, isoDateTimeNoTimeZonePickerFormat} from "../../Helpers/DateHelpers";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import moment from 'moment';
import {allowedWriteColumnOptionsArray} from "../../constants/SurveyBuilderTypes";

export default class ToolScheduleModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      endDate:null,
      duration:null,
      startDate:null,
      frequency:null,
      durationUnit:null,
      frequencyUnit:null,
      selectedValue:null,
      startDateTable:null,
      startDateColumn:null
    };
    if(props.tool && props.tool.quest_schedule){
      this.state.endDate = props.tool.quest_schedule.end_date;
      this.state.duration = props.tool.quest_schedule.enable_duration;
      this.state.frequency = props.tool.quest_schedule.enable_frequency;
      this.state.startDate = props.tool.quest_schedule.start_date;
      this.state.durationUnit = props.tool.quest_schedule.enable_duration_unit;
      this.state.frequencyUnit = props.tool.quest_schedule.enable_frequency_unit;
      this.state.startDateTable = props.tool.quest_schedule.start_date_table;
      this.state.startDateColumn = props.tool.quest_schedule.start_date_column;
    }
  }
  
  // Methods
  
  // Render
  
  render() {
    const {isOpen, errorMessage, handleFinish, handleCloseModal} = this.props;
    const {endDate, duration, startDate, frequency, durationUnit, frequencyUnit, startDateTable, startDateColumn, selectedValue} = this.state;
    
    const unitOptionsArray = [
      {value:'day', label:i18next.t('DAY')},
      {value:'month', label:i18next.t('MONTH_PROPER_CAPITALIZED')},
      {value:'year', label:i18next.t('YEAR')}
    ];
  
    const tableOptionsArray = [
      {label:'User', value:'User'},
      {label:'AdditionalAppColumn', value:'AdditionalAppColumn'}
    ];
  
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('TOOL_SCHEDULE') }</Translation>}
                    width={'600px'}
                    height={'420px'}
                    isOpen={isOpen}
                    maxHeight={525}
                    isEditing={false}
                    handleSave={() => handleFinish(startDate, endDate, duration, frequency, durationUnit, frequencyUnit, startDateTable, startDateColumn)}
                    showFooter={true}
                    showHeader={true}
                    errorMessage={errorMessage}
                    noTitleHeading={true}
                    showSaveButton={true}
                    containsDropDown={true}
                    handleCloseModal={() => handleCloseModal()}>
        <div className="tool-schedule-modal">
          <div className="tool-schedule-modal-dual-input-container">
            <LabeledDatePicker className="tool-schedule-modal-date-input"
                               bold={true}
                               label={<Translation>{ (t, { i18n }) => t('START_DATE_PROPER_CAPITALIZED') }</Translation>}
                               value={startDate}
                               minDate={moment().toDate()}
                               infoBlurb={<Translation>{(t, { i18n }) => t('START_DATE_EXPLANATION')}</Translation>}
                               dateFormat={isoDateTimeNoTimeZonePickerFormat}
                               handleSave={(aDate) => this.setState({startDate:aDate.format(isoDateTimeNoTimeZoneFormat)})}
                               isClearable={true}
            />
  
            <LabeledDatePicker className="tool-schedule-modal-date-input"
                               bold={true}
                               label={<Translation>{ (t, { i18n }) => t('END_DATE_PROPER_CAPITALIZED') }</Translation>}
                               value={endDate}
                               minDate={moment().toDate()}
                               infoBlurb={<Translation>{(t, { i18n }) => t('END_DATE_EXPLANATION')}</Translation>}
                               dateFormat={isoDateTimeNoTimeZonePickerFormat}
                               handleSave={(aDate) => this.setState({endDate:aDate.format(isoDateTimeNoTimeZoneFormat)})}
                               isClearable={true}
            />
          </div>
          
          <div className="tool-schedule-modal-dual-input-container">
            <LabeledTextInput className="tool-schedule-modal-text-input"
                              type={'number'}
                              label={<Translation>{(t, { i18n }) => t('DURATION')}</Translation>}
                              value={duration}
                              minValue={'0'}
                              infoBlurb={<Translation>{(t, { i18n }) => t('DURATION_EXPLANATION')}</Translation>}
                              handleSave={(aEvent) => aEvent.target.value >= 0 || aEvent.target.value.length === 0 ? this.setState({duration:aEvent.target.value}) : null}
            />
    
            <LabeledDropDown className="tool-schedule-modal-dropdown"
                             label={<Translation>{ (t, { i18n }) => t('DURATION_UNIT') }</Translation>}
                             value={unitOptionsArray.filter(function(aOption){
                               return aOption.value === durationUnit;
                             })}
                             options={unitOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('DURATION_UNIT_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => this.setState({durationUnit:aOption.value})}
            />
          </div>
          
          <div className="tool-schedule-modal-dual-input-container">
            <LabeledTextInput className="tool-schedule-modal-text-input"
                              type={'number'}
                              label={<Translation>{(t, { i18n }) => t('FREQUENCY')}</Translation>}
                              value={frequency}
                              minValue={'0'}
                              infoBlurb={<Translation>{(t, { i18n }) => t('FREQUENCY_EXPLANATION')}</Translation>}
                              handleSave={(aEvent) => aEvent.target.value >= 0 || aEvent.target.value.length === 0 ? this.setState({frequency:aEvent.target.value}) : null}
            />
    
            <LabeledDropDown className="tool-schedule-modal-dropdown"
                             label={<Translation>{ (t, { i18n }) => t('FREQUENCY_UNIT') }</Translation>}
                             value={unitOptionsArray.filter(function(aOption){
                               return aOption.value === frequencyUnit;
                             })}
                             options={unitOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('FREQUENCY_UNIT_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => this.setState({frequencyUnit:aOption.value})}
            />
          </div>
    
          <div className="tool-schedule-modal-dual-input-container">
            <LabeledDropDown className="tool-schedule-modal-dropdown"
                             label={<Translation>{(t, {i18n}) => t('START_DATE_TABLE')}</Translation>}
                             value={tableOptionsArray.filter(option => {
                               return option.value === startDateTable
                             })}
                             options={tableOptionsArray}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('START_DATE_TABLE_EXPLANATION') }</Translation>}
                             handleSave={(aOption) => {
                               if(startDateTable !== aOption.value){
                                 this.setState({startDateTable:aOption.value, startDateColumn:null})
                               }
                             }}
            />
    
            <LabeledDropDown className="tool-schedule-modal-dropdown"
                             label={<Translation>{(t, {i18n}) => t('START_DATE_COLUMN')}</Translation>}
                             value={selectedValue}
                             infoBlurb={<Translation>{(t, {i18n}) => t('START_DATE_COLUMN_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => this.setState({startDateColumn:aOption.name, selectedValue:aOption})}
                             fetchObjects={(aLimit, aOffset, aSearchString) => this.props.fetchAppAdditionalColumns(aLimit, aOffset, aSearchString)}
                             optionsArrayFormatter={(aArray) => {
                               let columnOptionsArray = [];
  
                               if(startDateTable === 'AdditionalAppColumn'){
                                 for(let additionalAppColumn of aArray){
                                   columnOptionsArray.push({value:additionalAppColumn.name, label:additionalAppColumn.name + ' - (' + additionalAppColumn.name_type + ')', type:additionalAppColumn.name_type, id:additionalAppColumn.id});
                                 }
                               }
                               else{
                                 columnOptionsArray = allowedWriteColumnOptionsArray(startDateTable);
                               }
                               return columnOptionsArray;
                             }}
                             valuePropertiesArray={['name']}
                             responseTotalKeysArray={['data', 'data', 'additional_app_columns', 'total']}
                             responseObjectKeysArray={['data', 'data', 'additional_app_columns', 'additional_app_columns']}
            />
          </div>
        </div>
      </GeneralModal>
    )
  }
}
