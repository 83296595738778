import React from 'react';
import './Info.scss';
import {Popover} from 'antd';
import {InfoOutlined} from '@material-ui/icons';
import {DEFAULT_COLORS} from "../../constants/Colors";

const Info = ({content, message, children, className, overlayClassName, width = 250, trigger = 'hover', popoverPlacement = 'top'}) => {
  
  // Instances Variables
  
  const body = () => {
    return (
      children ?
        children
        :
        <InfoOutlined className={'info-icon ' + className}
                      style={{height:'20px', width:'20px'}}
        />
    );
  };
  
  // Render
  
  return (
    content || message ?
      <Popover overlayStyle={{zIndex:99999999}}
               trigger={trigger}
               content={content ?
                 content
                 :
                 <div style={{
                   color:DEFAULT_COLORS.PANEL_GRAY,
                   width:(message.length > 200 ? Math.max(width, 400) : width),
                   whiteSpace:'pre-wrap'
                 }}>
                   {message}
                 </div>
               }
               placement={popoverPlacement}
               overlayClassName={'popover ' + overlayClassName}>
        {body()}
      </Popover>
      :
      body()
  );
};
export default Info;
