import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {gql} from "@apollo/client";

export function fetchSurveyById(aSurveyId){
  EventTracker.track(EventTypes.FETCH_SURVEY);
  let query = `
    query survey ($company_id: ID!, $id: ID) {
      survey (company_id: $company_id, id: $id) {
        id
        name
        data
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['id'] = aSurveyId;
    return global.api.post("/queries", { query, variables })
  };
}

export const SurveyLockedState = {
  Unlocked:'unlocked',
  AdminsOnly:'admins_only',
  Locked:'locked'
};

export function updateSurveyResponseLockState(aSurveyResponseId, aLockedState){
  let graphQLQuery =
    `mutation ($updateSurveyResponseLockStateMutationInput: UpdateSurveyResponseLockStateMutationInput!){
        updateSurveyResponseLockState(input: $updateSurveyResponseLockStateMutationInput){
          survey_response {
            id
            lock_state
          }
        }
      }
   `;
  
  return (dispatch, getState) => {
    let state = getState();
    let operationName = 'UpdateSurveyResponseLockState';
    let variables = {
      updateSurveyResponseLockStateMutationInput: {
        company_id: state.company && state.company.company && state.company.company.id,
        survey_response_id: aSurveyResponseId,
        lock_state: aLockedState
      }
    };
    return global.api.post("/queries", {query: graphQLQuery, variables, operationName});
  };
}

export function respondToSurvey(aSurveyId, aSurveyResponseId, aAnswers, aUserId, aAdminId, aClient, aIsTestResponse){
  let graphQLQuery =
    gql`mutation AddResponseToSurvey($addResponseToSurveyInput: AddResponseToSurveyInput!) {
       addResponseToSurvey(input: $addResponseToSurveyInput){
         full_survey_response {
           id
           progress
           status
           created_at
           updated_at
           next {
             title
             page_title
             size
             description
             questions {
               key
               page_number
               answer_label_position
               label_position
               extra_questions
               required_questions
               label
               type
               choices
               other
               skip
               other_label
               nested
               min_value
               max_value
               min_warning
               max_warning
               min_label
               max_label
               uncertain
               large_text_area
               dynamic_list_id
               is_sensitive_data_question
               list {
                 label
                 value
               }
               step
               js_regex
               validation
               number_of_columns
               default_value
               default_values {
                 key
                 value
               }
               default_other
               default_medications
               country_code
              }
              survey_response_details {
                id
                key
                skipped
                created_at
                value
                values {
                  key
                  value
                }
                other
                other_value
                other_label
                skip
              }
            }
            first_and_last_question {
              key
            }
          }
        }
      }
   `;

  for(let answer of aAnswers){
    if(answer.data_query){
      if(answer.data_query.description){
        if(answer.data_query.description.length === 0){
          delete answer["data_query"];
        }
      }
      else{
        delete answer["data_query"];
      }
    }
    if(answer.prettyValues != null){
      delete answer["prettyValues"];
    }
    if(answer.created_at != null){
      delete answer["created_at"];
    }
    if(answer.__typename != null){
      delete answer["__typename"];
    }
    if(answer.values){
      if(answer.values.length > 0){
        answer.values.forEach(value => {
          Object.keys(value).forEach(key => {
            if(key !== 'key' && key !== 'value'){
              delete value[key];
            }
          });
        });
      }
      else{
        delete answer["values"];
      }
    }
  }
  return (dispatch, getState) => {
    let state = getState();
  
    let variables = {
      addResponseToSurveyInput: {
        company_id: state.company && state.company.company && state.company.company.id,
        survey_id: aSurveyId,
        answers: aAnswers,
        user_id: aUserId,
        admin_id: aAdminId,
        is_test_response: aIsTestResponse
      }
    };
    if(aSurveyResponseId){
      variables['addResponseToSurveyInput']['survey_response_id'] = aSurveyResponseId;
    }
    return aClient
    .mutate({
      mutation:graphQLQuery,
      variables:variables,
      context:{ hasUpload:true }
    });
  };
}

export function questionBeforeKey(aSurveyResponseDetailId, aSurveyId, aQuestionKey, aUserId, aGetQuestionAfter, aSurveyResponseId){
  let graphQLQuery = `
    mutation GetQuestionBeforeKey($getQuestionBeforeKeyInput: GetQuestionBeforeKeyInput!){
      getQuestionBeforeKey(input: $getQuestionBeforeKeyInput){
          full_survey_response {
            progress
            next {
              title
              page_title
              size
              description
              questions {
                key
                page_number
                answer_label_position
                label_position
                extra_questions
                required_questions
                label
                type
                choices
                other
                skip
                other_label
                nested
                min_value
                max_value
                min_warning
                max_warning
                min_label
                max_label
                uncertain
                large_text_area
                dynamic_list_id
                is_sensitive_data_question
                list {
                  label
                  value
                }
                step
                js_regex
                validation
                number_of_columns
                default_value
                default_values {
                  key
                  value
                }
                default_other
                default_medications
                country_code
              }
              survey_response_details {
                id
                key
                skipped
                created_at
                value
                values {
                  key
                  value
                }
                other
                other_value
                other_label
                skip
              }
            }
            first_and_last_question {
              key
            }
          }
        }
      }
  `;
  
  let operationName = "GetQuestionBeforeKey";
  
  return (dispatch, getState) => {
    let state = getState();
  
    let variables = {
      getQuestionBeforeKeyInput: {
        company_id: state.company && state.company.company && state.company.company.id,
        survey_id: aSurveyId,
        question_key: aQuestionKey,
        user_id: aUserId,
        get_question_after_key: aGetQuestionAfter,
        survey_response_id: aSurveyResponseId
      }
    };
    if(aSurveyResponseDetailId){
      variables = {
        getQuestionBeforeKeyInput: {
          company_id: state.company && state.company.company && state.company.company.id,
          survey_response_detail_id: aSurveyResponseDetailId
        }
      };
    }
    // TODO: Add locale to data.
    return global.api.post("/queries", {query: graphQLQuery, variables, operationName});
  };
}

export function firstSurvey(aSurveyId, aUserId, aSurveyResponseId){
  let graphQLQuery = `
    mutation GetFirstSurveyQuestion($getFirstSurveyQuestionInput: GetFirstSurveyQuestionInput!){
      getFirstSurveyQuestion(input: $getFirstSurveyQuestionInput){
          full_survey_response {
            progress
            next {
              title
              page_title
              size
              description
              questions {
                key
                page_number
                answer_label_position
                label_position
                extra_questions
                required_questions
                label
                type
                choices
                other
                other_label
                skip
                nested
                min_value
                max_value
                min_warning
                max_warning
                min_label
                max_label
                uncertain
                large_text_area
                dynamic_list_id
                is_sensitive_data_question
                list {
                  label
                  value
                }
                step
                js_regex
                validation
                number_of_columns
                default_value
                default_values {
                  key
                  value
                }
                default_other
                default_medications
                country_code
              }
              survey_response_details {
                id
                key
                skipped
                created_at
                value
                values {
                  key
                  value
                }
                other
                other_value
                other_label
                skip
              }
            }
            first_and_last_question {
              key
            }
          }
        }
      }
  `;
  
  return (dispatch, getState) => {
    let state = getState();
    let operationName = 'GetFirstSurveyQuestion';
  
    let variables = {
      getFirstSurveyQuestionInput: {
        company_id: state.company && state.company.company && state.company.company.id,
        survey_id: aSurveyId,
        user_id: aUserId,
        survey_response_id: aSurveyResponseId
      }
    };
    return global.api.post("/queries", {query: graphQLQuery, variables, operationName});
  };
}
