import React from 'react';
import "antd/dist/antd.css";
import './GroupCodesHistoryModal.scss';
import { Translation } from "react-i18next";
import groupColorPicker from 'utils/ColorPicker';
import GeneralModal from "../GeneralModal/GeneralModal";
import "../GeneralModal/GeneralModal.scss"
import DynamicList from "../../Lists/DynamicList/DynamicList";
import i18n from "../../i18n";
import {momentFromDate, namedMonthDayFormat} from "../../Helpers/DateHelpers";

export default class GroupCodesHistoryModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      group: this.props.group,
      loading: false,
    };
  }
  
  // Methods
  
  handleGroupCodesHistoryDownload = () => {
    this.props.downloadGroupCodeHistory(this.props.group.id, this.props.company.id)
  };
  
  // Render
  
  render() {
    const {isOpen, handleCloseModal, fetchCodesForGroup} = this.props;
    const {loading, group} = this.state;
    
    let columnsArray = [];
    columnsArray.push({key:'duration', columnWeight:2, columnNameKey:'DURATION', propertiesArray:['valid_from'], valueFormatter:(aValue, aCode) => {
        const validUntil = aCode.valid_till ? momentFromDate(aCode.valid_till, namedMonthDayFormat) : i18n.t('CURRENT');
        return momentFromDate(aValue, namedMonthDayFormat) + ' - ' + validUntil;
      }});
    columnsArray.push({key:'code', columnWeight:1, columnNameKey:'CODE', propertiesArray:['code']});
    columnsArray.push({key:'users_count', columnWeight:1, columnNameKey:'USED', propertiesArray:['users_count'], valueFormatter:(aValue) => {
        let returnValue = aValue;
        
        if(aValue < 2){
          returnValue = aValue + ' ' + i18n.t('TIME_LOWERCASE');
        }
        else if(aValue > 1){
          returnValue = aValue + ' ' + i18n.t('TIMES');
        }
        return returnValue;
      }});
    return (
      <GeneralModal title={<Translation>{(t, { i18n }) => t('INVITATION_CODE_HISTORY')}</Translation>}
                    isOpen={isOpen}
                    loading={loading}
                    handleSave={() => this.handleGroupCodesHistoryDownload()}
                    showSaveButton={true}
                    width={'500px'}
                    maxWidth={'500px'}
                    saveButtonTitle={<Translation>{(t, {i18n}) => t('DOWNLOAD_GROUP_CODES_HISTORY')}</Translation>}
                    handleCloseModal={() => handleCloseModal()}
                    showCancelButton={true}
                    noTitleHeading={true}>
        {group && group.name ?
          <div className="group-user-title-info full-width">
            <div className="group-user-title-circle" style={{color:groupColorPicker(group.id)}}>
              {group.name.length > 0 ? group.name.charAt(0).toUpperCase() : ''}
            </div>
            <div className="group-user-title-text">{group.name}</div>
          </div>
          :
          null
        }
        
        <div className="group-codes-history-modal-dual-input-container">
          <DynamicList id="group-codes-list"
                       showTotal={false}
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset) => fetchCodesForGroup(group ? group.id : null, aOffset, aLimit, true)}
                       showSearchBar={false}
                       minColumnWidth={100}
                       responseTotalKeysArray={['data', 'data', 'group_codes', 'total']}
                       responseObjectKeysArray={['data', 'data', 'group_codes', 'group_codes']}
          />
        </div>
      </GeneralModal>
    )
  }
}
