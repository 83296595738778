import React from 'react';
import './LoadFailed.scss'
import {Translation} from "react-i18next";
import BlueButton from "../Buttons/BlueButton/BlueButton";

const LoadFailed = ({className, retry, message}) => (
  <div className={'load-failed-container ' + className}>
      <div className="load-failed-text">
        {message ?
          message
          :
          <Translation>{(t, {i18n}) => t('LOAD_FAILED')}</Translation>
        }
      </div>
    
      <BlueButton name={<Translation>{(t, {i18n}) => t('RETRY')}</Translation>}
                  onClick={retry}
      />
  </div>
);

export default LoadFailed;
