import React, {useState} from 'react';
import '../LabeledDelayedInput/LabeledDelayedInput.scss';
import CallButton from "../../Buttons/CallButton/CallButton";
import {DebounceInput} from "react-debounce-input";
import parsePhoneNumber from "libphonenumber-js";
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import './LabeledPhoneNumberInput.scss';
import i18next from "i18next";

const LabeledPhoneNumberInput = ({className, bold, call, value, label, onBlur, onFocus, disabled, required, infoBlurb,
                                   handleSave, placeholder, callDisabled, errorMessage, warningMessage, descriptionLabel,
                                   menuButtonClicked, prefix = 'Phone: ', loading = false, countryCode = 'US',
                                   debounceTimeout = 500, popoverPlacement = "top"}) => {
  
  // Instance Variables
  
  const [inputRef, setInputRef] = useState(null);
  const [showInputCoverButton, setShowInputCoverButton] = useState(true);
  
  const inputBlurred = (aEvent) => {
    setShowInputCoverButton(true);
    
    if(onBlur){
      onBlur(aEvent);
    }
  };
  
  const callButtonPressed = () => {
    if(isValidPhoneNumber(value)){
      call(value);
    }
    else{
      handleSave(value, false, i18next.t('CALL_ERROR', {aPhoneNumber:value ? value : i18next.t('NO_PHONE_NUMBER')}));
    }
  };
  
  const focusInputClicked = (aEvent) => {
    inputRef.focus();
    setShowInputCoverButton(false);
    
    if(onFocus){
      onFocus(aEvent);
    }
  };
  
  const isValidPhoneNumber = (aPhoneNumber) => {
    let returnValue = false;
    const phoneNumber = parsePhoneNumber(prefix + aPhoneNumber, countryCode);
    
    if(phoneNumber){
      returnValue = phoneNumber.isValid();
    }
    return returnValue;
  };
  
  const validateBeforeSave = (aEvent) => {
    let newValue = aEvent.target.value;
    const reg = /^\d*\+?\d*$/;

    if(newValue && (!reg.test(newValue) || !isValidPhoneNumber(newValue))){
      handleSave(newValue, false, i18next.t('NOT_A_VALID_PHONE_NUMBER', {aPhoneNumber:newValue}));
    }
    else{
      handleSave(newValue, true, null);
    }
  };
  
  // Render
  
  return (
    <div className={'labeled-phone-number-input-container ' + className}>
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={descriptionLabel}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <div className="labeled-phone-number-input-cell">
        {showInputCoverButton ?
          <button style={{position:'absolute', height:'39px', width:'calc(100% - 30px)', left:0, top:0, opacity:0}}
                  onClick={focusInputClicked}>
          </button>
          :
          null
        }
        
        <DebounceInput className={(disabled ? 'text-input-field-inactive' : '') + (errorMessage ? 'text-input-field-error' : (warningMessage ? 'text-input-field-warning' : ''))}
                       value={value}
                       onBlur={inputBlurred}
                       inputRef={(input) => setInputRef(input)}
                       onChange={validateBeforeSave}
                       minLength={2}
                       placeholder={placeholder}
                       debounceTimeout={debounceTimeout}
        />
        
        <Loading className="labeled-phone-number-input-loading"
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />
        
        <CallButton className="labeled-phone-number-input-call-button"
                    click={callButtonPressed}
                    disabled={!isValidPhoneNumber(value) || callDisabled}
        />
      </div>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};
export default LabeledPhoneNumberInput;
