import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../Sidebar.scss';
import ReactTooltip from 'react-tooltip'
import {Translation} from "react-i18next";

const ROOT_PATH = "/manage/";

class ManageNavItem extends React.PureComponent {
  
  render() {
    const { sidebarExpanded, title, path, active, manageNavItemClass, iconComponent } = this.props;
    const reactToolTipId = `tool-tip-manage-${ title ? title : path }`;
    let activeClass = '';
    
    if(active){
      activeClass = 'sidebar-manage-nav-item-active';
    }
    return (
      <div className={`sidebar-manage-nav-item-outer-container ${activeClass}`}>
        <div data-tip data-for={reactToolTipId}>
          <Link className={`manage-container-list-item-style ${manageNavItemClass} ` + activeClass}
                to={`${ROOT_PATH + path}`}>
            { sidebarExpanded && iconComponent }
            
            { sidebarExpanded &&
            <span className={'manage-nav-item-heading ' + activeClass}>
              { title ? <Translation>{ (t, { i18n }) => t(title)}</Translation> : path}
            </span>
            }
          </Link>
        </div>
        <ReactTooltip id={reactToolTipId} place='right' type='dark' disable={sidebarExpanded}>
          <span className="manage-nav-tool-tip">
            { title ? <Translation>{ (t, { i18n }) => t(title)}</Translation> : path }
          </span>
        </ReactTooltip>
      </div>
    );
  }
}

export default withRouter(ManageNavItem)
