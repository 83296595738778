import React from 'react';
import './MedicalRecord.scss';
import i18next from "i18next";
import {dataURLtoResponseType} from "../../Helpers/Helpers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {medicalRecordAttachmentOfType, notifyDownloadMedicalRecordError} from "../../Helpers/MedicalRecordHelpers";

export default class MedicalRecord extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      html:null
    };
    this.fetchFile();
  };
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {type, medicalRecord} = this.props;
    
    if(prevProps.type !== type ||
      (medicalRecord && prevProps.medicalRecord === null) ||
      (medicalRecord && prevProps.medicalRecord && medicalRecord.id !== prevProps.medicalRecord.id)){
      this.fetchFile();
    }
  }
  
  // Methods
  
  fetchFile(){
    const {type, medicalRecord} = this.props;
    
    if(medicalRecord){
      if(type === 'html'){
        let attachment = medicalRecordAttachmentOfType(medicalRecord, 'html');
        
        if(attachment && attachment.file_url && attachment.file_url.length > 0){
          dataURLtoResponseType(
            attachment.file_url,
            null,
            (newData) => this.setState({html:newData}),
            (newError) => notifyDownloadMedicalRecordError(type, newError)
          );
        }
      }
    }
  }
  
  // Render
  
  render() {
    const {type, company, className} = this.props;
    const {html} = this.state;
    
    const currentRole = roleForCompany(company);
    const medicalRecordsPermissionsArray = ['admin','admin_level_2' ,'admin_level_3', 'admin_level_4'];
    
    return (
      <div className={'medical-record-container ' + (className ? className : '')}>
        {medicalRecordsPermissionsArray.includes(currentRole) ?
          <>
            {type === 'pdf'?
              <div className="">
                {/*TODO: Add native PDF rendering support.*/}
              </div>
              :
              null
            }
            
            {type === 'json'?
              <div className="">
                {/*TODO: Add native CSV rendering support.*/}
              </div>
              :
              null
            }
            
            {type === 'html'?
              <div className="medical-record-html-view">
                <div dangerouslySetInnerHTML={{__html:html}}/>
              </div>
              :
              null
            }
          </>
          :
          <div className="no-information-text">
            {i18next.t('MEDICAL_RECORDS_BLOCKED')}
          </div>
        }
      </div>
    )
  }
}
