export function getScheduledSurveys(){
  let query = `
    query getSurveys($company_id: ID!){
      scheduled_surveys(company_id: $company_id){
        id
        name
        reference
        is_repeatable
        has_consent_question
        has_sensitive_data_question
      }
    }
  `;
  let variables = {company_id: ''};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id
    return global.api.post("/queries", { query, variables });
  };
}

export function fetchSurveyQuestions(surveyId){
  let query = `
    query survey_questions ($company_id: ID!, $survey_id: ID!) {
      survey_questions (company_id: $company_id, survey_id: $survey_id) {
        key
        label
        type
        choices
      }
    }
  `;
  let variables = {company_id: '', survey_id: ''};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['survey_id'] = surveyId;
    return global.api.post("/queries", { query, variables });
  };
}
