import React from 'react';
import ToolModal from '../../Modals/ToolModal/ToolModal'
import ToolPreviewModal from '../../Modals/ToolPreviewModal/ToolPreviewModal'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { filter, orderBy } from 'lodash'
import { Table } from 'semantic-ui-react'
import ToolSurveyIconTitle from '../../Custom UI/ToolSurveyIconTitle/ToolSurveyIconTitle'
import '../../Custom UI/common.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Translation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from "../../Custom UI/TabBar/TabBar";
import './ManageTools.scss';
import {BiDotsVerticalRounded} from "react-icons/bi";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateTool from "../../Components/Tools/CreateTool/CreateTool";
import {momentFromDateTime} from "../../Helpers/DateHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import SurveyBuilder from "../../Components/SurveyBuilder/SurveyBuilder";
import SurveyVersionCollectionsList from "../../Lists/SurveyVersionCollectionsList/SurveyVersionCollectionsList";
import SurveyListsList from "../../Lists/SurveyListsList/SurveyListsList";
import {withRouter} from "react-router-dom";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../Helpers/CompanyHelpers";
import SurveyToToolModal from "../../Modals/SurveyToToolModal/SurveyToToolModal";
import { cloneDeep } from "lodash"
import ToolScheduleModal from "../../Modals/ToolScheduleModal/ToolScheduleModal";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import i18next from "i18next";

const notifyToolUpdateError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('TOOL_UPDATE_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyToolUpdateSuccess = () => toast(<Translation>{ (t, { i18n }) => t('TOOL_UPDATED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyToolScheduleUpdateError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('TOOL_SCHEDULE_UPDATE_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyToolScheduleUpdateSuccess = () => toast(<Translation>{ (t, { i18n }) => t('TOOL_SCHEDULE_UPDATED') }</Translation>, NOTIFY_OPTS.autoClose);
const sections = {TOOLS:'tools', QUESTIONNAIRES:'questionnaires', ECONSENTS:'eConsents', NEW_TOOL:'newTool', SURVEY_LISTS:'surveyLists'};

class ManageTools extends React.Component {
  
  // Init

  constructor(props) {
    super(props);
    this.state = {
      tabBarKey:'QUESTIONNAIRES',
      paramSection:sections['QUESTIONNAIRES'],
      selectedTool:null,
      scheduleError:null,
      createToolType:'survey',
      selectedCreate:null,
      selectedSurvey:null,
      openNewToolModal:false,
      selectedEConsent:null,
      openEditToolModal:null,
      openPreviewToolModal:null,
      showSurveyToToolModal:false,
      showToolScheduleModal:false,
      toolMenuAnchorElement:null,
      createMenuAnchorElement:null,
      surveyMenuAnchorElement:null,
      toolConfirmationModalOpen:false,
      eConsentsMenuAnchorElement:null,
      eConsentConfirmationModalOpen:false
    };
    this.openNewToolModal = this.openNewToolModal.bind(this);
    this.closeNewToolModal = this.closeNewToolModal.bind(this);
    this.openEditToolModal = this.openEditToolModal.bind(this);
    this.closeEditToolModal = this.closeEditToolModal.bind(this);
    this.openPreviewToolModal = this.openPreviewToolModal.bind(this);
    this.closePreviewToolModal = this.closePreviewToolModal.bind(this);
    this.removeToolFromCompany = this.removeToolFromCompany.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toolMenuButtonClicked = this.toolMenuButtonClicked.bind(this);
    this.surveyMenuButtonClicked = this.surveyMenuButtonClicked.bind(this);
    this.eConsentsMenuButtonClicked = this.eConsentsMenuButtonClicked.bind(this);
    this.createMenuButtonClicked = this.createMenuButtonClicked.bind(this);
  }

  componentDidMount(){
    this.props.fetchTools();
    this.props.fetchLocales();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const paramSection = new URLSearchParams(window.location.search).get('section');
    
    if(paramSection !== this.state.paramSection){
      for(let key of Object.keys(sections)){
        if(paramSection === sections[key] && this.state.tabBarKey !== key){
          this.setState({paramSection:sections[key], tabBarKey:key});
          break;
        }
      }
    }
  }
  
  // Methods

  openEditToolModal(tool){
    this.setState({openEditToolModal:tool});
    this.closeMenu();
  }

  openPreviewToolModal(tool){
    this.setState({openPreviewToolModal:tool});
    this.closeMenu();
  }

  closeEditToolModal(){
    this.setState({openEditToolModal: null});
  }

  closePreviewToolModal(){
    this.setState({openPreviewToolModal: null})
  }

  openNewToolModal(){
    this.setState({openNewToolModal: true})
  }

  closeNewToolModal(){
    this.setState({openNewToolModal: false})
  }

  removeToolFromCompany(toolId){
    this.props.removeToolFromCompany({toolId});
    this.setState({toolConfirmationModalOpen:false, eConsentConfirmationModalOpen:false}, () => this.closeMenu());
  }

  closeMenu(){
    this.setState({selectedTool:null, selectedSurvey:null, selectedCreate:null, selectedEConsent:null,
      toolMenuAnchorElement:null, createMenuAnchorElement:null, surveyMenuAnchorElement:null, eConsentMenuAnchorElement:null});
  }

  toolMenuButtonClicked(aEvent, aTool){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({toolMenuAnchorElement:aEvent.currentTarget, selectedTool:aTool});
  };

  surveyMenuButtonClicked(aEvent, aSurvey){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({surveyMenuAnchorElement:aEvent.currentTarget, selectedSurvey:aSurvey});
  };

  eConsentsMenuButtonClicked(aEvent, aConsent){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({eConsentMenuAnchorElement:aEvent.currentTarget, selectedEConsent:aConsent});
  }

  createMenuButtonClicked(aEvent, aCreate){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({createMenuAnchorElement:aEvent.currentTarget, selectedCreate:aCreate});
  }
  
  // Render

  render(){
    const {user, tools, client, company, locales, updateTool, addToolToCompany, removeToolFromCompany, fetchDynamicLists,
      saveSurveyVersionCollection, fetchSurveyVersionCollectionById, fetchSurveyVersionCollectionByReference,
      fetchSurveyVersionCollections, fetchQuestionsForSurveyByReference, publishSurveyVersionCollection,
      archiveSurveyVersionCollection, addQuestForSurveyVersionCollection, addSurveyList, fetchSurveyLists,
      updateSurveyList, fetchSurveyListById, fetchSurveyListByReference, fetchSurveysForSurveyVersionCollection,
      validateRubularRegex, fetchSurveyById, fetchTools, history, fetchAppAdditionalColumns, updateToolSchedule,
      fetchDynamicListById, fetchDynamicListByReference} = this.props;
    const {tabBarKey, createToolType, selectedTool, scheduleError, showSurveyToToolModal, showToolScheduleModal,
      toolMenuAnchorElement, toolConfirmationModalOpen, selectedEConsent, eConsentConfirmationModalOpen} = this.state;
      
    const toolTypesArray = [
        {value:'tool', title:'SELF_CARE_TOOL', description:'TOOL_TYPE_DESCRIPTION'},
        {value:'survey', title:'SURVEY_TYPE_TITLE', description:'SURVEY_TYPE_DESCRIPTION'}
      ];
  
    const surveyBuilderPermissionsArray = ['admin', 'admin_level_4'];
    let canUseSurveyBuilder = hsFeatureAvailableForCompany(company, 'Survey Builder') || surveyBuilderPermissionsArray.includes(roleForCompany(company));
    
    let pureTools = filter(tools, (quest) => quest.quest_type === "Tool" || quest.quest_type === "Survey");
    let consentTools = orderBy(filter(tools, (quest) => quest.quest_type === "Consent"), ['title'], ['asc']);
    
    let tabBarTabsArray = [{key:'TOOLS'}, {key:'QUESTIONNAIRES'}, {key:'ECONSENTS'}];
    
    if(canUseSurveyBuilder){
      tabBarTabsArray.push({key:'NEW_TOOL'});
      tabBarTabsArray.push({key:'SURVEY_LISTS'});
    }
    
    // TODO: Rebuild section 0, add a filter option, type column, and survey reference column.
    return (
      <div className='main-panel-inner-container'>
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('TOOLS')}</Translation>}/>
  
        <TabBar className="manage-tools-tab-bar"
                tabBarTabsArray={tabBarTabsArray}
                initialSelectedTabKey={'QUESTIONNAIRES'}
                selectedTabKeyChanged={(aKey) => {
                  history.push(history.location.pathname + '?section=' + aKey);
                  this.setState({tabBarKey:aKey});
                }}
        />

        {tabBarKey === 'TOOLS' ?
          <div className="manage-tools-container-tools">
            <div className="manage-tools-text"
                 style={{marginLeft:20}}>
              <Translation>{ (t, { i18n }) => t('TOOLS_DESCRIPTION') }</Translation>
            </div>
            <br/>

            <PerfectScrollbar>
              <div className='manage-tools-inner-container-tools'>
                <Table basic='very' singleLine  unstackable selectable className='table-no-border'>
                  <Table.Header>
                    <Table.Row className='header-row-table'>
                      <Table.HeaderCell width={2}><Translation>{ (t, { i18n }) => t('TOOL_NAME') }</Translation></Table.HeaderCell>
                      <Table.HeaderCell width={2}><Translation>{ (t, { i18n }) => t('DATE_CREATED') }</Translation></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {pureTools && pureTools.map((tool, index) => (
                      <Table.Row className="table-body-row multi-line-row" key={index} onClick={() => this.openPreviewToolModal(tool)}>
                        <Table.Cell className="title-container">
                          <ToolSurveyIconTitle toolTitle={tool.title} toolIconName={tool.icon} style={{color: 'rgb(250,170,23)'}}/>
                        </Table.Cell>
                        <Table.Cell>
                          <div className="manage-tools-table-cell">
                            {momentFromDateTime(tool.created_at)}

                            <BiDotsVerticalRounded className="manage-tools-button"
                                                   onClick={(aEvent) => this.toolMenuButtonClicked(aEvent, tool)}
                                                   style={{fontSize: '21px'}}
                            />
                          </div>
                        </Table.Cell>
                        {/*<Table.Cell>*/}
                        {/*  <div className="table-row-actions" style={{display: "flex"}}>*/}
                        {/*    <div className="clickable preview-container pull-left" onClick={() => this.openPreviewToolModal(tool.id)}>*/}
                        {/*      <RemoveRedEye/>*/}
                        {/*      <span className='preview-title'>*/}
                        {/*  <Translation>{ (t, { i18n }) => t('PREVIEW') }</Translation>*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="clickable preview-container pull-left" onClick={() => this.openEditToolModal(tool.id)}>*/}
                        {/*      <Create/>*/}
                        {/*      <span className='preview-title'>*/}
                        {/*  <Translation>{ (t, { i18n }) => t('EDIT') }</Translation>*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        {/*    <RemoveItem title={<Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>} removeFunc={this.removeToolFromCompany} removeId={tool.id}/>*/}
                        {/*  </div>*/}
                        {/*</Table.Cell>*/}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </PerfectScrollbar>

            <Menu id="simple-menu"
                  anchorEl={toolMenuAnchorElement}
                  keepMounted
                  open={Boolean(toolMenuAnchorElement)}
                  onClose={() => this.closeMenu()}>
              
              {/*TODO: Uncomment once these features are fixed.*/}
              {/*<MenuItem style={{position: 'relative'}} onClick={() => this.openPreviewToolModal(this.state.selectedTool)}>*/}
              {/*  <Translation>{ (t, { i18n }) => t('PREVIEW')}</Translation>*/}
              {/*</MenuItem>*/}
              {/*<MenuItem style={{position: 'relative'}} onClick={() => this.openEditToolModal(this.state.selectedTool)}>*/}
              {/*  <Translation>{ (t, { i18n }) => t('EDIT')}</Translation>*/}
              {/*</MenuItem>*/}
  
              <MenuItem style={{position: 'relative'}} onClick={() => {this.setState({showSurveyToToolModal:true, toolMenuAnchorElement:null})}}>
                <Translation>{ (t, { i18n }) => t('UPDATE')}</Translation>
              </MenuItem>
              
              <MenuItem style={{position: 'relative'}} onClick={() => {this.setState({showToolScheduleModal:true, toolMenuAnchorElement:null})}}>
                <Translation>{ (t, { i18n }) => t('SCHEDULE')}</Translation>
              </MenuItem>
              
              <MenuItem style={{position: 'relative'}} onClick={() => {this.setState({toolConfirmationModalOpen:true, toolMenuAnchorElement:null})}}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
  
            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:selectedTool ? selectedTool.title : ''})}
                               isOpen={toolConfirmationModalOpen}
                               reject={() => this.setState({toolConfirmationModalOpen:false})}
                               confirm={() => this.removeToolFromCompany(selectedTool.id)}
            />
  
            {showSurveyToToolModal ?
              <SurveyToToolModal isOpen={showSurveyToToolModal}
                                 languages={locales}
                                 handleFinish={(aTitle, aHSCConsent, aOnboardingQuest, aTranslationsArray) => {
                                   let tool = cloneDeep(selectedTool);
                                   tool.hsc_consent = aHSCConsent;
                                   tool.onboarding_quest = aOnboardingQuest;
                                   
                                   if(aTranslationsArray){
                                     tool.translations = aTranslationsArray;
                                   }
                                   updateTool({tool}).then((newResponse) => {
                                     this.setState({showSurveyToToolModal:false, selectedTool:null});
                                     notifyToolUpdateSuccess();
                                   }, (newError) => {
                                     let error = '';
  
                                     if(newError && newError.data && newError.data && newError.data.error){
                                       error = newError.data.error;
                                     }
                                     notifyToolUpdateError(error);
                                     this.setState({showSurveyToToolModal:false, selectedTool:null});
                                   });
                                 }}
                                 initialTitle={selectedTool.title}
                                 handleCloseModal={() => this.setState({showSurveyToToolModal:false, selectedTool:null})}
                                 showTranslations={true}
                                 disableTitleInput={true}
                                 initialHSCConsent={selectedTool.hsc_consent}
                                 initialOnboardingQuest={selectedTool.onboarding_quest}
                                 initialToolTranslationsArray={selectedTool.translations}
              />
              :
              null
            }
            
            {showToolScheduleModal ?
              <ToolScheduleModal tool={selectedTool}
                                 isOpen={showToolScheduleModal}
                                 errorMessage={scheduleError}
                                 handleFinish={(aStartDate, aEndDate, aDuration, aFrequency, aDurationUnit, aFrequencyUnit, aTable, aColumn) => {
                                   updateToolSchedule(selectedTool.id, aTable, aColumn, aEndDate, aStartDate, aDuration, aFrequency, aDurationUnit, aFrequencyUnit).then((newResponse) => {
                                     notifyToolScheduleUpdateSuccess();
                                     this.setState({selectedTool:null, scheduleError:null, showToolScheduleModal:false});
                                   }, (newError) => {
                                     let error = '';
  
                                     if(newError && newError.data && newError.data && newError.data.error){
                                       error = newError.data.error;
                                     }
                                     notifyToolScheduleUpdateError(error);
                                     this.setState({scheduleError:error});
                                   });
                                 }}
                                 handleCloseModal={() => this.setState({selectedTool:null, scheduleError:null, showToolScheduleModal:false})}
                                 fetchAppAdditionalColumns={fetchAppAdditionalColumns}
              />
              :
              null
            }
          </div>
          :
          null}

        {tabBarKey === 'QUESTIONNAIRES' ?
          <SurveyVersionCollectionsList className="manage-tools-survey-version-collections-list-container"
                                        user={user}
                                        client={client}
                                        company={company}
                                        languages={locales}
                                        fetchTools={fetchTools}
                                        addSurveyList={addSurveyList}
                                        canEditSurvey={canUseSurveyBuilder}
                                        explanationKey={'QUESTIONNAIRE_VIEW_EXPLANATION'}
                                        fetchSurveyById={fetchSurveyById}
                                        fetchSurveyLists={fetchSurveyLists}
                                        fetchDynamicLists={fetchDynamicLists}
                                        fetchSurveyListById={fetchSurveyListById}
                                        fetchDynamicListById={fetchDynamicListById}
                                        validateRubularRegex={validateRubularRegex}
                                        fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                                        fetchSurveyListByReference={fetchSurveyListByReference}
                                        fetchDynamicListByReference={fetchDynamicListByReference}
                                        saveSurveyVersionCollection={saveSurveyVersionCollection}
                                        fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                        archiveSurveyVersionCollection={archiveSurveyVersionCollection}
                                        publishSurveyVersionCollection={publishSurveyVersionCollection}
                                        fetchSurveyVersionCollectionById={fetchSurveyVersionCollectionById}
                                        addQuestForSurveyVersionCollection={addQuestForSurveyVersionCollection}
                                        fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                                        fetchSurveysForSurveyVersionCollection={fetchSurveysForSurveyVersionCollection}
                                        fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
          />
          :
          null
        }

        {tabBarKey === 'ECONSENTS' ?
          <div className="manage-tools-econsent-container">
            
            <div className="manage-tools-econsent-text">
              <Translation>{ (t, { i18n }) => t('E_CONSENT') }</Translation>
            </div>
            
            <br/>
            <PerfectScrollbar>
              <div className='scroll-table-outer-container'>
                <Table basic='very' singleLine selectable className='table-no-border'>
                  <Table.Header>
                    <Table.Row className='header-row-table'>
                      <Table.HeaderCell width={2}><Translation>{ (t, { i18n }) => t('TOOL_NAME') }</Translation></Table.HeaderCell>
                      {/*<Table.HeaderCell width={3}><Translation>{ (t, { i18n }) => t('ASSIGNED_TO') }</Translation></Table.HeaderCell>*/}
                      <Table.HeaderCell width={2}><Translation>{ (t, { i18n }) => t('DATE_CREATED') }</Translation></Table.HeaderCell>
                      {/*<Table.HeaderCell width={5}></Table.HeaderCell>*/}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {consentTools && consentTools.map((tool, index) => (
                      <Table.Row key={index} className="table-body-row multi-line-row">
                        <Table.Cell className="title-container">
                          <ToolSurveyIconTitle toolTitle={tool.title} toolIconName={tool.icon}/>
                        </Table.Cell>
                        {/*<Table.Cell>*/}
                        {/*  {groupList(tool)}*/}
                        {/*</Table.Cell>*/}
                        <Table.Cell>
                          <div className="manage-tools-table-cell">
                            {momentFromDateTime(tool.created_at)}
                            <BiDotsVerticalRounded className="manage-tools-button"
                                                   onClick={(aEvent) => this.eConsentsMenuButtonClicked(aEvent, tool)}
                                                   style={{fontSize: '21px'}}
                            />
                          </div>
                        </Table.Cell>
                        {/*<Table.Cell>*/}
                        {/*  <div className="table-row-actions" style={{display: "flex"}}>*/}
                        {/*    <div className="clickable preview-container pull-left" onClick={() => this.openPreviewToolModal(tool.id)}>*/}
                        {/*      <RemoveRedEye/>*/}
                        {/*      <span className='preview-title'>*/}
                        {/*  <Translation>{ (t, { i18n }) => t('PREVIEW') }</Translation>*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="clickable preview-container pull-left" onClick={() => this.openEditToolModal(tool.id)}>*/}
                        {/*      <Create/>*/}
                        {/*      <span className='preview-title'>*/}
                        {/*  <Translation>{ (t, { i18n }) => t('EDIT') }</Translation>*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        {/*    <RemoveItem title={<Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>} removeFunc={this.removeToolFromCompany} removeId={tool.id}/>*/}
                        {/*  </div>*/}
                        {/*</Table.Cell>*/}
                        {/*{*/}
                        {/*  this.state.openPreviewToolModal === tool.id &&*/}
                        {/*  <ToolPreviewModal*/}
                        {/*    tool={tool}*/}
                        {/*    isOpen={true}*/}
                        {/*    handleCloseModal={this.closePreviewToolModal}*/}
                        {/*  />*/}
                        {/*}*/}
                        {/*{*/}
                        {/*  this.state.openEditToolModal === tool.id &&*/}
                        {/*  <ToolModal*/}
                        {/*    tool={tool}*/}
                        {/*    isOpen={true}*/}
                        {/*    handleCloseModal={this.closeEditToolModal}*/}
                        {/*    addToolToCompany={addToolToCompany}*/}
                        {/*    removeToolFromCompany={removeToolFromCompany}*/}
                        {/*    updateTool={updateTool}*/}
                        {/*    languages={locales}*/}
                        {/*  />*/}
                        {/*}*/}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </PerfectScrollbar>

            <Menu id="simple-menu"
                  anchorEl={this.state.eConsentMenuAnchorElement}
                  keepMounted
                  open={Boolean(this.state.eConsentMenuAnchorElement)}
                  onClose={() => this.closeMenu()}>
              <MenuItem style={{position: 'relative'}} onClick={() => this.openPreviewToolModal(this.state.selectedEConsent)}>
                <Translation>{ (t, { i18n }) => t('PREVIEW')}</Translation>
              </MenuItem>
              <MenuItem style={{position: 'relative'}} onClick={() => this.openEditToolModal(this.state.selectedEConsent)}>
                <Translation>{ (t, { i18n }) => t('EDIT')}</Translation>
              </MenuItem>
              <MenuItem style={{position: 'relative'}} onClick={() => {this.setState({eConsentConfirmationModalOpen:true})}}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
  
            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:selectedEConsent ? selectedEConsent.title : ''})}
                               isOpen={eConsentConfirmationModalOpen}
                               reject={() => this.setState({eConsentConfirmationModalOpen:false})}
                               confirm={() => this.removeToolFromCompany(selectedEConsent.id)}
            />
          </div>
          :
          null
        }

        {tabBarKey === 'NEW_TOOL' ?
          <div className="manage-survey-tools-builder-container">
            
            {/*TODO: Uncomment when the CreateTool Component is fixed.*/}
            {/*<div className="manage-tools-type-container manage-tools-type-container-survey">*/}
            {/*  <div className="manage-tools-title-blue">*/}
            {/*    <Translation>{ (t, { i18n }) => t('TOOL_TYPE') }</Translation>*/}
            {/*  </div>*/}
            {/*  */}
            {/*  <div className="manage-tools-permission-outer-container">*/}
            {/*    <div className="manage-tools-permission-container">*/}
            {/*      {toolTypesArray.map((toolType, index) => (*/}
            {/*        <div className="manage-tools-permission-cell"*/}
            {/*             key={toolType.title + toolType.value + index}>*/}
            {/*          <UserPermissionCell className="manage-tools-user-permission-cell"*/}
            {/*                              title={toolType.title}*/}
            {/*                              value={toolType.value}*/}
            {/*                              checked={createToolType === toolType.value}*/}
            {/*                              onChange={(aToolType) => this.setState({createToolType:aToolType})}*/}
            {/*                              description={toolType.description}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
  
            {createToolType === 'tool' ?
              <div style={{backgroundColor: 'white'}}>
                <div className="manage-tools-title-blue">
                  <Translation>{ (t, { i18n }) => t('CREATE_TOOL') }</Translation>
                </div>
                
                <CreateTool companyId={company.id}
                            languages={locales}
                            updateTool={updateTool}
                            addToolToCompany={addToolToCompany}
                            removeToolFromCompany={removeToolFromCompany}
                            {...this.props}
                />
              </div>
              :
              <SurveyBuilder user={user}
                             client={client}
                             company={company}
                             languages={locales}
                             addSurveyList={addSurveyList}
                             fetchSurveyById={fetchSurveyById}
                             fetchSurveyLists={fetchSurveyLists}
                             fetchDynamicLists={fetchDynamicLists}
                             fetchSurveyListById={fetchSurveyListById}
                             fetchDynamicListById={fetchDynamicListById}
                             validateRubularRegex={validateRubularRegex}
                             fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                             fetchSurveyListByReference={fetchSurveyListByReference}
                             fetchDynamicListByReference={fetchDynamicListByReference}
                             saveSurveyVersionCollection={saveSurveyVersionCollection}
                             fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                             publishSurveyVersionCollection={publishSurveyVersionCollection}
                             fetchSurveyVersionCollectionById={fetchSurveyVersionCollectionById}
                             fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                             fetchSurveysForSurveyVersionCollection={fetchSurveysForSurveyVersionCollection}
                             fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
              />
            }
            
            <Menu id="simple-menu"
                  anchorEl={this.state.createMenuAnchorElement}
                  keepMounted
                  open={Boolean(this.state.createMenuAnchorElement)}
                  onClose={() => this.closeMenu()}>
              {/*TODO: Implement the Remove Menu Item.*/}
              <MenuItem style={{position:'relative'}}
                        onClick={(aEvent) => {
                          aEvent.preventDefault();
                          aEvent.stopPropagation();
                          this.closeMenu();
                        }}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>

          </div>
          :
          null
        }
  
        {tabBarKey === 'SURVEY_LISTS' ?
          <SurveyListsList className="manage-tools-survey-lists-container"
                           user={user}
                           client={client}
                           company={company}
                           addSurveyList={addSurveyList}
                           fetchSurveyLists={fetchSurveyLists}
                           updateSurveyList={updateSurveyList}
                           fetchSurveyListById={fetchSurveyListById}
                           fetchSurveyListByReference={fetchSurveyListByReference}
          />
          :
          null
        }

        {this.state.openPreviewToolModal !== null ?
          <ToolPreviewModal tool={this.state.openPreviewToolModal}
                            isOpen={this.state.openPreviewToolModal !== null}
                            handleCloseModal={this.closePreviewToolModal}
          />
          :
          null
        }
        
        {this.state.openEditToolModal !== null ?
          <ToolModal tool={this.state.openEditToolModal}
                     isOpen={this.state.openEditToolModal !== null}
                     languages={locales}
                     updateTool={updateTool}
                     addToolToCompany={addToolToCompany}
                     handleCloseModal={this.closeEditToolModal}
                     removeToolFromCompany={removeToolFromCompany}
          />
          :
          null
        }
        
        {this.state.openNewToolModal ?
          <ToolModal isOpen={this.state.openNewToolModal}
                     companyId={this.props.company && this.props.company.id}
                     languages={locales}
                     updateTool={updateTool}
                     handleCloseModal={this.closeNewToolModal}
                     addToolToCompany={addToolToCompany}
                     removeToolFromCompany={removeToolFromCompany}
          />
          :
          null
        }
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(withRouter(ManageTools));
