import {Translation} from "react-i18next";
import React from "react";

export const DispositionCodes = [
 {text:<Translation>{ (t, { i18n }) => t('ACTIVELY_ENROLLED') }</Translation>, value:"actively_enrolled"},
 {text:<Translation>{ (t, { i18n }) => t('DIED') }</Translation>, value:'died'},
 {text:<Translation>{ (t, { i18n }) => t('DISENROLLED_BY_COORDINATOR') }</Translation>, value:'disenrolled_by_coordinator'},
 {text:<Translation>{ (t, { i18n }) => t('DISENROLLED_BY_PATIENT') }</Translation>, value:'disenrolled_by_patient' },
 {text:<Translation>{ (t, { i18n }) => t('STUDY_ENDED') }</Translation>, value:'study_ended'}
];

export const AdditionalDispositionCodes = [
 {text:<Translation>{ (t, { i18n }) => t('SCREEN_FAIL') }</Translation>, value:'screen_fail'}
];
