import React from 'react'
import Menu from "@material-ui/core/Menu";
import Loading from "../../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import {Translation} from "react-i18next";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import {formatUserName} from "../../../Helpers/AdminHelpers";
import AssignSymptomsModal from "../../../Modals/AssignSymptomsModal/AssignSymptomsModal";
import PatientInfoListItem from '../../../Cells/PatientInfoCell/PatientInfoCell'
import {errorMessageFromServerError} from "../../../Helpers/Helpers";
import PatientPanelSubSectionHeadingWithButton from '../PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton'

const notifyRemoveSymptomError = (aError) => toast(<Translation>{ (t, {i18n}) => t('ERROR_REMOVING_SYMPTOM', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class PatientSymptoms extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      symptomsArray:props.patient && props.patient.symptoms ? props.patient.symptoms : [],
      assignSymptoms:false,
      menuAnchorElement:null,
      selectedSymptomId:null,
      assignSymptomsError:''
    };
    this.removeSymptomFromUser = this.removeSymptomFromUser.bind(this);
  }
  
  componentDidMount(){
    this.loadSymptoms();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.symptoms){
      if(this.state.symptomsArray !== this.props.patient.symptoms){
        this.setState({symptomsArray:this.props.patient.symptoms});
      }
    }
  }
  
  // Methods
  
  removeSymptomFromUser = () => {
    const {patient, loadPatientSymptoms, removeSymptomFromUser} = this.props;
    const {symptomsArray, selectedSymptomId} = this.state;
    const index = symptomsArray.map((aSymptom) => {return aSymptom.id}).indexOf(selectedSymptomId);
    
    if(index > -1){
      symptomsArray.splice(index, 1);
    }
    removeSymptomFromUser({userId:patient.id, symptomId:selectedSymptomId}).then((newResult) => {
      loadPatientSymptoms(patient.id);
    }, (newError) => {
      notifyRemoveSymptomError(errorMessageFromServerError(newError));
      loadPatientSymptoms(patient.id);
    });
    this.setState({menuAnchorElement:null, selectedSymptomId:null});
  };
  
  loadSymptoms(){
    const {patient, loadPatientSymptoms} = this.props;
    
    if(patient && loadPatientSymptoms){
      this.setState({loading:true});
      loadPatientSymptoms(patient.id).then((newResult) => {
        this.setState({loading:false, loadFailed:false});
      }, (newError) => {
        this.setState({loading:false, loadFailed:true});
      });
    }
  }
  
  assignPatientSymptom = ({selectedValue, extraNotes, customSymptom}) => {
    const {patient, addSymptomToUser} = this.props;
    this.setState({assignSymptomsError:false});
    
    if(patient && addSymptomToUser){
      addSymptomToUser({userId:patient.id, symptomId:!customSymptom && selectedValue, extraNotes:extraNotes, customSymptom:customSymptom}).then((newResult) => {
        if(newResult && newResult.error){
          this.setState({assignSymptomsError:newResult.error});
        }
        else{
          this.setState({assignSymptoms:false});
        }
      }, (newError) => {
        this.setState({assignSymptoms:false, assignSymptomsError:errorMessageFromServerError(newError)});
      });
    }
  };
  
  // Render
  
  render(){
    const {className, patient, assignSymptomsModalOpen, editSymptomsModalOpen, company, fetchSymptoms} = this.props;
    const {symptomsArray, loading, loadFailed, assignSymptoms, assignSymptomsError} = this.state;
    
    const symptomsPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_10', 'admin_level_11'];
    const currentRole = roleForCompany(company);
    
    let isQuest = false;
    
    if(patient && patient.quests){
      isQuest = patient.quests.filter(data => data.title === 'Symptom Tracker');
    }
    
    return (
      <div className={className}>
        {symptomsPermissions.includes(currentRole) ?
          <div>
            <PatientPanelSubSectionHeadingWithButton addFunc={isQuest && isQuest.length > 0 ? () => this.setState({assignSymptoms:true}) : null}/>
            
            <div className="subsection-element"
                 style={{display:'block', height:'350px', overflowY:'auto'}}>
              {loading ?
                <Loading/>
                :
                (loadFailed ?
                    <LoadFailed retry={() => this.loadSymptoms()}/>
                    :
                    (symptomsArray.length > 0 ?
                        <>
                          {symptomsArray.map((symptom, index) => (
                            <div key={'symptom-cell-' + index + '-' + symptom}
                                 style={{display:'flex', flexDirection:'column'}}>
                              <PatientInfoListItem data={symptom}
                                                   removeId={symptom.id}
                                                   removeFunc={this.removeSymptomFromUser}
                                                   menuButtonClicked={(aEvent, aSymptom) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedSymptomId:aSymptom})}
                                                   editSymptomsModalOpen={editSymptomsModalOpen}
                              />
                            </div>
                          ))}
                        </>
                        :
                        <div className="symptom-no-symptoms-found">
                          <Translation>{ (t, { i18n }) => t('NO_SYMPTOMS_FOUND') }</Translation>
                        </div>
                    )
                )
              }
              
              {isQuest && !(isQuest.length > 0) ?
                <p style={{opacity:0.5}}>
                  <Translation>{(t, {i18n}) => t('SYMPTOM_TRACKER_NOT_ASSIGNED')}</Translation>
                </p>
                :
                null
              }
            </div>
            
            <Menu id="symptoms-menu"
                  open={Boolean(this.state.menuAnchorElement)}
                  onClose={() => this.setState({menuAnchorElement:null, selectedSymptomId:null})}
                  anchorEl={this.state.menuAnchorElement}
                  keepMounted>
              <MenuItem style={{position:'relative'}}
                        onClick={() => this.removeSymptomFromUser()}>
                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('SYMPTOMS_BLOCKED')}</Translation>
          </div>
        }
  
        {patient && assignSymptoms ?
          <AssignSymptomsModal key={'assign-patient-symptoms-modal-' + patient.id.toString()}
                               name={formatUserName(patient)}
                               patientId={patient.id}
                               isOpen={assignSymptoms}
                               fetchSymptoms={fetchSymptoms}
                               handleCloseModal={() => this.setState({assignSymptoms:false})}
                               assignSymptomsError={assignSymptomsError}
                               assignSymptomCallback={this.assignPatientSymptom}
          />
          :
          null
        }
      </div>
    )
  }
}





