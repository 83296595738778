import i18next from "i18next";

export function emailIsValid(aEmail){
  return (aEmail.match('\\S+@\\S+\\.\\S') !== null);
}

export function translatedStringForRole(aRole){
  let returnValue = i18next.t('UNDEFINED');
  
  if(aRole){
    const validRolesArray = ['admin', 'patient', 'provider', 'physician', 'admin_level_1', 'admin_level_2',
      'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_8', 'admin_level_9',
      'admin_level_10', 'admin_level_11', 'admin_level_66'];
    
    if(validRolesArray.includes(aRole)){
      returnValue = i18next.t('' + aRole.toUpperCase());
    }
  }
  return returnValue;
}

export const allAdminRolesArray = ['admin', 'provider', 'physician', 'admin_level_1', 'admin_level_2', 'admin_level_3',
  'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_7', 'admin_level_8', 'admin_level_9', 'admin_level_10',
  'admin_level_11', 'admin_level_66', 'physician'];

export function titleAndDescriptionForAdminRole(aRole){
  let returnValue = {title:'', description:''};
  
  if(aRole && adminRoleTitlesAndDescriptions()[aRole]){
    returnValue = adminRoleTitlesAndDescriptions()[aRole];
  }
  return returnValue;
}

export function adminRoleTitlesAndDescriptions(){
  return {
    admin:{title:'SUPER_ADMIN_LEVEL', description:'SUPER_ADMIN_LEVEL_DESCRIPTION'},
    admin_level_1:{title:'ADMIN_LEVEL_ONE', description:'ADMIN_LEVEL_ONE_DESCRIPTION'},
    admin_level_2:{title:'ADMIN_LEVEL_TWO', description:'ADMIN_LEVEL_TWO_DESCRIPTION'},
    admin_level_3:{title:'ADMIN_LEVEL_THREE', description:'ADMIN_LEVEL_THREE_DESCRIPTION'},
    admin_level_4:{title:'ADMIN_LEVEL_FOUR', description:'ADMIN_LEVEL_FOUR_DESCRIPTION'},
    admin_level_5:{title:'ADMIN_LEVEL_FIVE', description:'ADMIN_LEVEL_FIVE_DESCRIPTION'},
    admin_level_6:{title:'ADMIN_LEVEL_SIX', description:'ADMIN_LEVEL_SIX_DESCRIPTION'},
    admin_level_8:{title:'ADMIN_LEVEL_EIGHT', description:'ADMIN_LEVEL_EIGHT_DESCRIPTION'},
    admin_level_9:{title:'ADMIN_LEVEL_NINE', description:'ADMIN_LEVEL_NINE_DESCRIPTION'},
    admin_level_10:{title:'ADMIN_LEVEL_TEN', description:'ADMIN_LEVEL_TEN_DESCRIPTION'},
    admin_level_11:{title:'ADMIN_LEVEL_ELEVEN', description:'ADMIN_LEVEL_ELEVEN_DESCRIPTION'},
    admin_level_66:{title:'ADMIN_LEVEL_SIXTY_SIX', description:'ADMIN_LEVEL_SIXTY_SIX_DESCRIPTION'}
  };
}

export function getAssignableAdminRolesArray(){
  return [
    {value:'admin_level_1', title:'ADMIN_LEVEL_ONE', description:'ADMIN_LEVEL_ONE_DESCRIPTION'},
    {value:'admin_level_2', title:'ADMIN_LEVEL_TWO', description:'ADMIN_LEVEL_TWO_DESCRIPTION'},
    {value:'admin_level_3', title:'ADMIN_LEVEL_THREE', description:'ADMIN_LEVEL_THREE_DESCRIPTION'},
    {value:'admin_level_4', title:'ADMIN_LEVEL_FOUR', description:'ADMIN_LEVEL_FOUR_DESCRIPTION'},
    {value:'admin_level_5', title:'ADMIN_LEVEL_FIVE', description:'ADMIN_LEVEL_FIVE_DESCRIPTION'},
    {value:'admin_level_6', title:'ADMIN_LEVEL_SIX', description:'ADMIN_LEVEL_SIX_DESCRIPTION'},
    {value:'admin_level_8', title:'ADMIN_LEVEL_EIGHT', description:'ADMIN_LEVEL_EIGHT_DESCRIPTION'},
    {value:'admin_level_9', title:'ADMIN_LEVEL_NINE', description:'ADMIN_LEVEL_NINE_DESCRIPTION'},
    {value:'admin_level_10', title:'ADMIN_LEVEL_TEN', description:'ADMIN_LEVEL_TEN_DESCRIPTION'},
    {value:'admin_level_11', title:'ADMIN_LEVEL_ELEVEN', description:'ADMIN_LEVEL_ELEVEN_DESCRIPTION'},
    {value:'admin_level_66', title:'ADMIN_LEVEL_SIXTY_SIX', description:'ADMIN_LEVEL_SIXTY_SIX_DESCRIPTION'}
  ];
}

export function formatUserName(aUser){
  let returnValue = '';
  
  if(aUser){
    if(aUser.last_name != null){
      returnValue = aUser.first_name + ' ' + aUser.last_name;
    }
    else{
      returnValue = aUser.first_name;
    }
  }
  return returnValue;
}

export function formatUserFullName(aUser){
  let returnValue = '';
  
  if(aUser){
    if(aUser.last_name != null){
      if(aUser.middle_name != null){
        returnValue = aUser.first_name + ' ' + aUser.middle_name + ' ' + aUser.last_name;
      }
      else{
        returnValue = aUser.first_name + ' ' + aUser.last_name;
      }
    }
    else{
      if(aUser.middle_name != null){
        returnValue = aUser.first_name + ' ' + aUser.middle_name;
      }
      else{
        returnValue = aUser.first_name;
      }
    }
  }
  return returnValue;
}
