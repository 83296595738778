import {SurveyTypesArray, SurveyTypeDetailsArray} from "../constants/SurveyBuilderTypes";
import moment from "moment";

export function cellHeightForQuestion(aQuestion, aCellWidth, aCurrentLocale, aLocalesArray){
  let returnValue = 241;
  const verticalCellPadding = 20;
  
  if(aQuestion){
    if(!aQuestion['key'] || aQuestion['key'].length === 0){
      returnValue += 25;
    }
    const labelKey = KeyForTranslation('label', aCurrentLocale);
  
    if(!aQuestion[labelKey] || aQuestion[labelKey].length === 0){
      returnValue += 25;
    }
    if(aQuestion['choices']){
      returnValue += 52;
      
      for(let index = 0; index < aQuestion.choices.length; index += 1){
        let hasError = false;
        let choice = aQuestion.choices[index];
    
        if(!choice.value || choice.value.length === 0){
          hasError = true;
        }
        if(aCurrentLocale.locale !== 'en'){
          const key = KeyForTranslation('label_', aCurrentLocale);
  
          if(!choice[key] || choice[key].length === 0){
            hasError = true;
          }
        }
        else if(!choice.label || choice.label.length === 0){
          hasError = true;
        }
        if(hasError){
          returnValue += 108;
        }
        else{
          returnValue += 83;
        }
      }
    }
    if(aQuestion.type === 'list'){
      returnValue += 73;
    }
    if(aQuestion.type === 'year'){
      returnValue += 73;
    }
    if(aQuestion.type === 'radio'){
      returnValue += 103;
    }
    if(aQuestion.type === 'range'){
      returnValue += 93;
    }
    if(aQuestion.type === 'slider'){
      returnValue += 73;
    }
    if(aQuestion.type === 'number'){
      returnValue += 83;
    }
    if(aQuestion.type === 'econsent'){
      returnValue += 75;
    }
    if(aQuestion.type === 'datetime'){
      returnValue += 73;
    }
    if(aQuestion.type === 'medication'){
      returnValue += 75;
    }
    if(aQuestion.type === 'econsent_hsc'){
      returnValue += 75;
    }
    if(aQuestion.type === 'dynamic_list'){
      returnValue += 83;
    }
    if(aQuestion.type === 'multi_medication'){
      returnValue += 75;
    }
    if(aQuestion.type === 'date' || aQuestion.type === 'monthyear'){
      returnValue += 83 + 83;
    }
    
    let enabledSettingsHeight = 0;
    
    for(let key of ['skip', 'regex', 'db_write', 'required', 'default_value', 'large_text_area']){
      if(aQuestion[key]){
        enabledSettingsHeight += 22;
      }
    }
    if(aQuestion['other'] && Boolean(aQuestion.other)){
      enabledSettingsHeight += 22;
    }
    if(aQuestion['conditions'] && aQuestion['conditions'].length > 0){
      enabledSettingsHeight += 22;
    }
    if(aQuestion['exit_to_conditions'] && aQuestion['exit_to_conditions'].length > 0){
      enabledSettingsHeight += 22;
    }
    if(enabledSettingsHeight > 0){
      enabledSettingsHeight += 34;
    }
    returnValue += enabledSettingsHeight;
  }
  return returnValue;
}

export function cellHeightForSurvey(aSurvey){
  let returnValue = 107;
  
  if(aSurvey){
    if(!aSurvey['survey_reference'] || aSurvey['survey_reference'].length === 0){
      returnValue += 25;
    }
    let enabledSettingsHeight = 0;
    
    if(aSurvey['repeat_count'] && aSurvey['repeat_count'] > 0){
      enabledSettingsHeight += 22;
    }
    if(aSurvey['conditions'] && aSurvey['conditions'].length > 0){
      enabledSettingsHeight += 22;
    }
    if(aSurvey['repeat_count_question_key'] && aSurvey['repeat_count_question_key'].length > 0){
      enabledSettingsHeight += 22;
    }
    if(enabledSettingsHeight > 0){
      enabledSettingsHeight += 23;
    }
    returnValue += enabledSettingsHeight;
  }
  return returnValue;
}

export function cellHeightForPage(aPage, aCellWidth, aCurrentLocale, aLocalesArray){
  let returnValue = 124;
  const verticalPadding = 20;
  
  if(aPage){
    if(aPage.key === 'end' || aPage.key === 'disqualified'){
      returnValue = 155;
  
      if(aPage.questionsArray.length > 0){
        returnValue -= 15;
      }
    }
    else{
      const labelKey = KeyForTranslation('page_title', aCurrentLocale);
  
      if(!aPage[labelKey] || aPage[labelKey].length === 0){
        returnValue += 25;
      }
    }
    let originY = aPage.y + 124;
    
    if(aPage.isSurveyPage){
      if(aPage.surveysArray.length > 0){
        returnValue += verticalPadding;
        
        for(let survey of aPage.surveysArray){
          survey.y = originY;
          survey.height = cellHeightForSurvey(survey);
          originY += survey.height + verticalPadding;
          returnValue += survey.height + verticalPadding;
        }
      }
      else{
        returnValue += verticalPadding;
      }
    }
    else{
      if(aPage.questionsArray.length > 0){
        returnValue += verticalPadding;
        
        for(let question of aPage.questionsArray){
          question.y = originY;
          question.height = cellHeightForQuestion(question, aCellWidth, aCurrentLocale, aLocalesArray);
          originY += question.height + verticalPadding;
          returnValue += question.height + verticalPadding;
        }
      }
      else{
        returnValue += verticalPadding;
      }
    }
    if(aPage.showAddPageButtons){
      returnValue += 71;
    }
  }
  return returnValue;
}

export function computeHeightsForPages(aPagesArray, aCellWidth, aCurrentLocale, aLocalesArray){
  if(aPagesArray && aPagesArray.length > 0){
    let originY = 810;
    
    for(let page of aPagesArray){
      page.y = originY;
      page.height = cellHeightForPage(page, aCellWidth, aCurrentLocale, aLocalesArray);
      originY += page.height;
    }
  }
}

export function languageFromLocale(aLocale){
  let returnValue = 'ENGLISH';
  let languageFromLocale = {ca:'CATALAN', de:'GERMAN', en:'ENGLISH', es:'SPANISH', fi:'FINNISH', fr:'FRENCH', it:'ITALIAN'};
  
  if(aLocale && languageFromLocale[aLocale] !== undefined){
    returnValue = languageFromLocale[aLocale];
  }
  return returnValue;
}

export function isSurveyTypeValid(aSurveyType){
  let returnValue = false;
  
  if(aSurveyType){
    for(let surveyType of SurveyTypesArray){
      if(surveyType === aSurveyType){
        returnValue = true;
        break;
      }
    }
  }
  return returnValue;
}

export function SurveyTypeDetailsForType(aSurveyType){
  let returnValue = {type:'', title:'', description:''};
  
  if(isSurveyTypeValid(aSurveyType)){
    for(let surveyTypeDetails of SurveyTypeDetailsArray){
      if(surveyTypeDetails.type === aSurveyType){
        returnValue = surveyTypeDetails;
        break;
      }
    }
  }
  return returnValue;
}

/**
 * @return {string}
 */
export function SurveyTypeToCamelBack(aSurveyType){
  let returnValue = '';
  
  if(isSurveyTypeValid(aSurveyType)){
    returnValue = aSurveyType.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    returnValue = returnValue[0].toUpperCase() + returnValue.slice(1);
  }
  return returnValue;
}

/**
 * @return {string}
 */
export function ComponentNameForSurveyType(aSurveyType){
  let returnValue = 'SurveyBuilderQuestionCell';
  
  if(isSurveyTypeValid(aSurveyType)){
    returnValue = 'SurveyBuilder' + SurveyTypeToCamelBack(aSurveyType) + 'QuestionCell';
  }
  return returnValue;
}

/**
 * @return {string}
 */
export function KeyForTranslation(aKey, aTranslation){
  let returnValue = aKey;
  
  if(aTranslation && aTranslation.locale !== 'en'){
    returnValue = aKey + '_' + aTranslation.locale;
  }
  return returnValue;
}

/**
 * @return {string}
 */
export function csvStringFromOptionsArray(aOptionsArray){
  let returnValue = '';
  
  if(aOptionsArray && aOptionsArray.length > 0){
    for(let index = 0; index < aOptionsArray.length; index++){
      let option = aOptionsArray[index];
      returnValue += option.value;
      
      if(index < aOptionsArray.length - 1){
        returnValue += ','
      }
    }
  }
  return returnValue;
}

export function objectIsEmpty(aObject){
  return objectIsEmptyExceptForKeys(aObject, []);
}

export function objectIsEmptyExceptForKeys(aObject, aKeysArray){
  let returnValue = true;
  
  for(let key of Object.keys(aObject)){
    if(!aKeysArray.includes(key)){
      if(aObject[key] && aObject[key] !== undefined && aObject[key].toString().length > 0){
        returnValue = false;
        break;
      }
    }
  }
  return returnValue;
}

export function surveyVersionCollectionIsLive(aSurveyVersionCollection){
  return Boolean(aSurveyVersionCollection && aSurveyVersionCollection.current_survey);
}

export function surveyVersionCollectionVersionNumber(aSurveyVersionCollection){
  let returnValue = 1;
  
  if(aSurveyVersionCollection){
    if(aSurveyVersionCollection.current_survey){
      returnValue = aSurveyVersionCollection.current_survey.version_number;
    }
    else if(aSurveyVersionCollection.draft_survey){
      returnValue = aSurveyVersionCollection.draft_survey.version_number;
    }
  }
  return returnValue;
}

export function surveyVersionCollectionLastUpdatedByUser(aSurveyVersionCollection){
  let returnValue = null;
  
  if(aSurveyVersionCollection){
    if(aSurveyVersionCollection.draft_survey){
      returnValue = aSurveyVersionCollection.draft_survey.updated_by_user;
    }
    else if(aSurveyVersionCollection.current_survey){
      returnValue = aSurveyVersionCollection.current_survey.updated_by_user;
    }
  }
  return returnValue;
}

export function surveyVersionCollectionIsLocked(aSurveyVersionCollection, aUser){
  let returnValue = false;
  
  if(aSurveyVersionCollection){
    let lastUpdatedByUser = surveyVersionCollectionLastUpdatedByUser(aSurveyVersionCollection);
    
    if(aSurveyVersionCollection.updated_at && aUser && lastUpdatedByUser && aUser.id.toString() !== lastUpdatedByUser.id.toString()){
      if(moment(aSurveyVersionCollection.updated_at).diff(moment(), 'minutes') > -10){
        returnValue = true;
      }
    }
  }
  return returnValue;
}

