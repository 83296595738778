import React from "react";
import {Translation} from "react-i18next";
import './SurveyYearQuestionCell.scss'
import '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown.scss'
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyYearQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer: null
    };
    
    if(props.answer != null && props.answer.value != null){
      this.state.answer = props.answer.value;
    }
    this.saveAnswer = this.saveAnswer.bind(this);
  }
  
  // Methods
  
  saveAnswer(year){
    this.setState({answer:year.value, selectedValue:year});
    this.props.onAnswerChange(this.props.question, year.value);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer} = this.state;
    const {key, type, max_value, min_value} = question;
    
    let max = parseInt(max_value, 10);
    let min = parseInt(min_value, 10);
    
    if(isNaN(min) || isNaN(max)){
      min = 1850;
      max = 2022;
    }
    let years = [];
    
    for(let i = min; i <= max; i += 1){
      years.push({label:i.toString(), value:i.toString()});
    }
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr />
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_YEAR_INSTRUCTION_TEXT')}</Translation>
        </em>
        
        <hr />
        
        <div className='survey-year-question-cell'>
          <LabeledDropDown value={answer && years.filter(year => year.value === answer)}
                           options={years}
                           disabled={disabled}
                           handleSave={(aOption) => this.saveAnswer(aOption)}
                           placeholder={<Translation>{ (t, { i18n }) => t('SELECT_YEAR')}</Translation>}
                           menuPosition={'portal'}
                           menuPlacement={'bottom'}
          />
        </div>
      </div>
    )
  }
}
