import React from "react";
import './SurveyBuilderQuestionVisibility.scss';
import {Translation} from "react-i18next";
import LabeledInfo from "../../../../Custom UI/LabeledInputs/LabeledInfo/LabeledInfo";
import cloneDeep from "lodash/cloneDeep";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SurveyBuilderConditionCell from "../../../../Cells/SurveyBuilderCells/SurveyBuilderConditionCell/SurveyBuilderConditionCell";

export default class SurveyBuilderQuestionVisibility extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      selectedConditionIndex:-1,
      selectedConditionQuestionIndex:-1
    };
    this.updateCondition = this.updateCondition.bind(this);
  }
  
  // Methods
  
  updateCondition(aQuestion, aIndex, aKeyValuePairsArray){
    let updatedQuestion = cloneDeep(aQuestion);
    let updatedConditionsArray = updatedQuestion.conditions;
    let updatedCondition = updatedConditionsArray[aIndex];
    
    for(let keyValuePair of aKeyValuePairsArray){
      if(keyValuePair.index !== undefined){
        updatedCondition[keyValuePair.key][keyValuePair.index] = keyValuePair.value;
      }
      else{
        updatedCondition[keyValuePair.key] = keyValuePair.value;
      }
    }
    updatedConditionsArray[aIndex] = updatedCondition;
    
    updatedQuestion.conditions = updatedConditionsArray;
    this.props.updateQuestion(updatedQuestion);
  }
  
  // TODO: Move to SurveyBuilderConditionCell?
  questionOptionsForCondition(aCondition){
    let returnValue = [];
    
    if(aCondition && !aCondition.is_table && aCondition && aCondition.survey && aCondition.survey.length){
      if(aCondition.survey === '-1'){
        let { question, questionsArray } = this.props;
        
        for(let currentSurveyQuestion of questionsArray){
          // TODO: Should come before the Question.
          //if(question.key !== currentSurveyQuestion.key){
            returnValue.push({value:currentSurveyQuestion.key, label:currentSurveyQuestion.key, type:currentSurveyQuestion.type});
          //}
        }
      }
      else{
        let selectedSurvey;
  
        for(let survey of this.props.surveysArray){
          if(survey.survey_reference === aCondition.survey){
            selectedSurvey = survey;
            break;
          }
        }
        if(selectedSurvey){
          let addCurrentReference = true;
          
          for(let question of selectedSurvey.questionsArray){
            if(question.key === aCondition.question){
              addCurrentReference = false;
            }
            returnValue.push({value:question.key, label:question.key, type:question.type});
          }
          if(addCurrentReference){
            if(aCondition.question){
              if(Array.isArray(aCondition.question)){
                if(aCondition.question.length === 1){
                  returnValue.push({value:aCondition.question[0], label:aCondition.question[0], type:''});
                }
                else{
                  for(let question of aCondition.question){
                    returnValue.push({value:question[0], label:question[0], type:''});
                  }
                }
              }
              else{
                returnValue.push({value:aCondition.question, label:aCondition.question, type:''});
              }
            }
          }
        }
        else{
          if(aCondition.question){
            if(Array.isArray(aCondition.question)){
              if(aCondition.question.length === 1){
                returnValue.push({value:aCondition.question[0], label:aCondition.question[0], type:''});
              }
              else{
                for(let question of aCondition.question){
                  returnValue.push({value:question[0], label:question[0], type:''});
                }
              }
            }
            else{
              returnValue.push({value:aCondition.question, label:aCondition.question, type:''});
            }
          }
        }
      }
    }
    return returnValue;
  }
  
  menuButtonClicked(aEvent, aConditionIndex, aConditionQuestionIndex = -1){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedConditionIndex:aConditionIndex, selectedConditionQuestionIndex:aConditionQuestionIndex});
  };
  
  // Render
  
  render(){
    const { question, surveysArray, updateQuestion, errorForIdTypeAndKey, fetchAppAdditionalColumns } = this.props;
    const { menuAnchorElement, selectedConditionIndex, selectedConditionQuestionIndex } = this.state;
    
    return(
      <div className="">
        <LabeledInfo className="survey-builder-question-visibility-input"
                     bold={true}
                     label={<Translation>{ (t, { i18n }) => t('VISIBILITY') }</Translation>}
                     infoBlurb={<Translation>{ (t, { i18n }) => t('VISIBILITY_EXPLANATION') }</Translation>}
                     popoverPlacement={"left"}
        />
        
        {question && question.conditions && question.conditions.map((condition, index) => (
          <SurveyBuilderConditionCell key={question.id + '-condition-' + index}
                                      index={index}
                                      question={question}
                                      condition={condition}
                                      surveysArray={surveysArray}
                                      updateCondition={(aQuestion, aIndex, aKeyValuePairsArray) => this.updateCondition(aQuestion, aIndex, aKeyValuePairsArray)}
                                      menuButtonClicked={(aEvent, aConditionQuestionIndex) => this.menuButtonClicked(aEvent, index, aConditionQuestionIndex)}
                                      errorForIdTypeAndKey={errorForIdTypeAndKey}
                                      questionOptionsArray={this.questionOptionsForCondition(condition)}
                                      fetchAppAdditionalColumns={fetchAppAdditionalColumns}
          />
        ))}
        
        <div className="survey-builder-question-visibility-add-button"
             onClick={(aEvent) => {
               aEvent.preventDefault();
               aEvent.stopPropagation();
    
               if(question){
                 let updatedQuestion = cloneDeep(question);
      
                 if(!updatedQuestion.conditions){
                   updatedQuestion.conditions = [];
                 }
                 updatedQuestion.conditions.push({table:'', value:[''], column:'', survey:'-1', is_table:false, survey_loop_index:0, question:[''], operation:'equal', reference:'', question_type:[''], parse_to_age:[false], condition_operator:'and'});
                 updateQuestion(updatedQuestion);
               }
             }}>
          <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
    
          <Translation>{(t, {i18n}) => t('ADD_CONDITION')}</Translation>
        </div>
  
        <Menu id="simple-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({menuAnchorElement:null, selectedConditionIndex:-1, selectedConditionQuestionIndex:-1})}
              anchorEl={menuAnchorElement}
              keepMounted>
          <MenuItem style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
  
                      if(selectedConditionQuestionIndex === -1){
                        if(question){
                          let updatedQuestion = cloneDeep(question);
    
                          if(updatedQuestion.conditions && selectedConditionIndex < updatedQuestion.conditions.length){
                            updatedQuestion.conditions.splice(selectedConditionIndex, 1);
                            updateQuestion(updatedQuestion);
                          }
                        }
                      }
                      else{
                        if(question && question.conditions && selectedConditionIndex < question.conditions.length){
                          let updatedQuestion = cloneDeep(question);
                          let condition = updatedQuestion.conditions[selectedConditionIndex];
    
                          if(condition.question && Array.isArray(condition.question)){
                            if(condition.question.length > 1 && selectedConditionQuestionIndex < condition.question.length){
                              condition.value.splice(selectedConditionQuestionIndex, 1);
                              condition.question.splice(selectedConditionQuestionIndex, 1);
                              
                              if(condition.parse_to_age && selectedConditionQuestionIndex < condition.parse_to_age.length){
                                condition.parse_to_age.splice(selectedConditionQuestionIndex, 1);
                              }
                              if(condition.question_type && selectedConditionQuestionIndex < condition.question_type.length){
                                condition.question_type.splice(selectedConditionQuestionIndex, 1);
                              }
                              updatedQuestion.conditions[selectedConditionIndex] = condition;
                              this.props.updateQuestion(updatedQuestion);
                            }
                          }
                        }
                      }
                      this.setState({menuAnchorElement:null, selectedConditionIndex:-1, selectedConditionQuestionIndex:-1});
                    }}>
            <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
