import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {Translation} from "react-i18next";
import './ProfilePicture.scss';
import BlueButton from "../Buttons/BlueButton/BlueButton";
import Loading from "../Loading/Loading";

const ProfilePicture = ({ imageId, aImageUrl, width, height, style, className, borderStyle, label, editAction, disabled=false, avatarId }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const hiddenFileInput = React.useRef(null);
  
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const getImage = (id) => {
      if(id){
        global.api.get("/api/images/user/"+id+'.jpg', {
          params: {raw: true},
          cancelToken: source.token
        }).then(result => {
          setImageUrl('data:image/png;base64,'+ result.data)
        }).catch(result => {
          if(axios.isCancel(result)){
            console.log('Request canceled', result.message)
          }
          else{
            console.log('Error', result)
          }
          setImageUrl("/imgs/app/avatars/avatar-placeholder.png");
        })
      }
      else{
        setImageUrl("/imgs/app/avatars/avatar-placeholder.png");
      }
    };
    
    if(avatarId){
      setImageUrl(`/imgs/app/avatars/${avatarId}.png`);
    }
    else if(!aImageUrl){
      getImage(imageId);
    }
    
    return () => {
      source.cancel();
    }
    
  }, [aImageUrl, imageId, avatarId]);
  
  const picture = (
    (aImageUrl || imageUrl ?
        <img src={aImageUrl ? aImageUrl : imageUrl}
             width={width || '40'}
             height={height || '40'}
             style={style || {}}
             className={'profile-picture-image-positioning ' + (className || '')}
             alt={<Translation>{(t, {i18n}) => t('PROFILE')}</Translation>}
        />
        :
        null
    )
  );
  
  return (
    <div className={"profile-picture-container-inner"}
         style={borderStyle || {width:width, height:height}}>
      <div className="profile-picture-label">
        {label}
      </div>
      
      <div className="profile-picture"
           style={{width:width, height:height}}>
        {picture ?
          picture
          :
          <Loading style={style || {width:width, height:height}}
                   size={width && width < 100 ? 'tiny' : 'medium'}
                   showLabel={false}
          />
        }
      </div>
      
      {editAction ?
        <div>
          <BlueButton className="profile-picture-button"
                      name={<Translation>{(t, {i18n}) => t('CHANGE')}</Translation>}
                      onClick={() => {hiddenFileInput.current.click();}}
                      disabled={disabled}
          />
          <input style={{display:'none'}}
                 type="file"
                 ref={hiddenFileInput}
                 onChange={editAction}
                 accept="image/x-png, image/jpeg"
          />
        </div>
        :
        null
      }
    </div>
  );
};
export default ProfilePicture
