import React from 'react';
import moment from 'moment-timezone'
import { Switch } from 'antd'
import SectionHeader from "../Headers/SectionHeader/SectionHeader";
import './SetReminder.scss'
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledDropDown from "../LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDatePicker from "../LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {americanDateFormat} from "../../Helpers/DateHelpers";
import LabeledSwitch from "../LabeledInputs/LabeledSwitch/LabeledSwitch";


export default class SetReminder extends React.Component {
  startDateObject = new Date();
  endDateObject = null;
  invalidDateRange = false;

  constructor(props) {
    super(props)
    this.state = {
      repeatType: '',
      numReminders: 0,
      startDate: new Date(),
      endDate: null,
      endDateEnabled: false,
      selectedOption:null
    }
    this.selectRepeatType = this.selectRepeatType.bind(this)
    this.selectNumReminders = this.selectNumReminders.bind(this)
    this.handleReminderDayChange = this.handleReminderDayChange.bind(this)
    this.handleReminderTimeChange = this.handleReminderTimeChange.bind(this)
  }


  selectRepeatType(aOption) {
    this.setState({ repeatType: aOption.value }, () => this.handleChange(aOption.value, this.state.numReminders))
  }

  selectNumReminders(aOption) {
    this.setState({numReminders:aOption.value, selectedOption:aOption}, () => this.handleChange(this.state.repeatType, aOption.value))
  }

  handleChange(repeatType, numReminders) {
    const { startDate, endDate } = this.state;
    let reminders = {}

    if (repeatType === 'as needed') {
      this.props.setRepeatType(repeatType)
      this.props.setReminders(reminders)
    }
    else{
      for (let i = 1; i <= numReminders; i++) {
        reminders['reminder' + i] = {
          repeat: true,
          repeat_type: this.state.repeatType,
          start_date: startDate.toISOString().substring(0, 10),
          end_date: endDate == null ? null : endDate.toISOString().substring(0, 10),
          day_number: repeatType === 'weekly' || repeatType === 'monthly' ? '' : null,
          time: ''
        }
      }
      this.props.setRepeatType(repeatType)
      this.props.setReminders(reminders)
    }
  }

  handleReminderTimeChange(aOption, name) {
    const { startDate, endDate } = this.state;

    let reminderToUpdate = {
      repeat: true,
      repeat_type: this.state.repeatType,
      start_date: startDate.toISOString().substring(0, 10),
      end_date: endDate == null ? null : endDate.toISOString().substring(0, 10),
      time: aOption.value
    }
    let reminder = Object.assign({}, this.props.reminders[name], reminderToUpdate)
    let reminders = Object.assign({}, this.props.reminders, { [name]: reminder })
    this.props.setReminders(reminders)
  }

  handleReminderDayChange(aOption, name) {
    const { startDate, endDate } = this.state;

    let reminderToUpdate = {
      repeat: true,
      repeat_type: this.state.repeatType,
      start_date: startDate.toISOString().substring(0, 10),
      end_date: endDate == null ? null : endDate.toISOString().substring(0, 10),
      day_number: parseInt(aOption.value)
    }
    let reminder = Object.assign({}, this.props.reminders[name], reminderToUpdate)
    let reminders = Object.assign({}, this.props.reminders, { [name]: reminder })
    this.props.setReminders(reminders);
  }

  handleChangeStart = (date) => {
    this.startDateObject = date;

    if(this.endDateObject != null && this.startDateObject != null){
      this.invalidDateRange = this.startDateObject >= this.endDateObject;
      this.props.setInvalidDateRange(this.invalidDateRange);
    }
    if (date != null) {
      this.setState({ startDate: date })
      const { startDate, endDate, repeatType } = this.state;
      let reminders = {}
      let numReminders = this.state.numReminders

      for (var i = 1; i <= numReminders; i++) {
        reminders['reminder' + i] = {
          repeat: true,
          repeat_type: this.state.repeatType,
          start_date: startDate.toISOString().substring(0, 10),
          end_date: endDate == null ? null : endDate.toISOString().substring(0, 10),
          day_number: repeatType === 'weekly' || repeatType === 'monthly' ? '' : null,
          time: this.props.reminders['reminder' + i].time
        }
      }
      this.props.setReminders(reminders)
    }
  }

  handleChangeEnd = (date) => {
    this.setState({ endDate: date })
    this.endDateObject = date;

    const { startDate, endDate, repeatType } = this.state;
    let reminders = {}
    let numReminders = this.state.numReminders

    if(this.endDateObject != null && this.startDateObject != null){
      this.invalidDateRange = this.startDateObject >= this.endDateObject;
      this.props.setInvalidDateRange(this.invalidDateRange);
    }

    for (var i = 1; i <= numReminders; i++) {
      reminders['reminder' + i] = {
        repeat: true,
        repeat_type: this.state.repeatType,
        start_date: startDate.toISOString().substring(0, 10),
        end_date: endDate == null ? null : endDate.toISOString().substring(0, 10),
        day_number: repeatType === 'weekly' || repeatType === 'monthly' ? '' : null,
        time: this.props.reminders['reminder' + i].time
      }
    }
    this.props.setReminders(reminders)
  }

  switchOnChange = (state) => {
    this.setState({ endDateEnabled: state });
    
    if (state === false) {
      this.handleChangeEnd(null);
    }
  }
  
  render() {
    const {selectedOption} = this.state;
    
    let times = []
    let reminderTimeInterval = 15; //minutes interval
    let tt = 0; // start time
    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      let mm = (tt % 60); // getting minutes of the hour in 0-55 format
      // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      let time = null
      
      if (hh > 0 && mm > 0) {
        time = (hh.toString() + mm.toString())
      } else if (hh === 0) {
        time = (mm.toString())
      } else if (mm === 0) {
        time = (hh.toString() + '00')
      }
      times.push({
        value: time.padStart(4, '0'),
        label: convertTime(time)
      })
      tt = tt + reminderTimeInterval;
    }

    let timeOptions = [];
    timeOptions = times.map((time) => {
      return {value:time.value, label:time.label, key:time.value}
      //return (<Option key={time.value} value={time.value}>{time.label}</Option>)
    })
    
    const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    let addReminderTimes = []
    let isEditMode = this.props.reminders.length >0
    if (isEditMode || (this.state.numReminders > 0 && this.state.repeatType !== '')) {
      let dayNumberOptions = (i) => {
        // let dayNumberOptions = <span/>
        if ((isEditMode && this.props.reminders[0].repeat_type === 'weekly') || this.state.repeatType === 'weekly') {
          let dayNumbers = daysOfTheWeek.map((day, index) => {
            return { value: index, label: day }
          })
          let dayNumbersOptions = dayNumbers.map((day) => {
            return {value:day.value, label:day.label, key:day.value}
          })
          return (
            <LabeledDropDown name={'reminder' + i}
                            //  value={isEditMode ? this.props.reminders[i].day_number && dayNumbersOptions.filter(day => {
                            //    return day.value === this.props.reminders[i].day_number
                            //  }): this.props.reminders['reminder' + i].day_number}
                             value={dayNumbersOptions.filter(day => {
                               return day.value === this.props.reminders['reminder' + i].day_number
                             })}
                             style={{ margin: 5 }}
                             handleSave={(value) => { this.handleReminderDayChange(value, 'reminder' + i) }}
                             options={dayNumbersOptions}
            />
            /*<Select
                name={'reminder' + i}
                value={isEditMode ? this.props.reminders[i].day_number : this.props.reminders['reminder' + i].day_number}
                style={{ margin: 5 }}
                onChange={(value) => { this.handleReminderDayChange(value, 'reminder' + i) }}
            >

              {dayNumbers.map((day) => {
                return (
                  <Option key={day.value} value={day.value}>{day.label}</Option>
                )
              })}
            </Select>*/
          )
        }
        else if ((isEditMode && this.props.reminders[0].repeat_type === 'weekly') || this.state.repeatType === 'monthly') {
          // let dayNumbers = [{value: 1, label: 'Day 1'}, {value: 2, label: 'Day 2'}];
          let dayNumbers = []
          for (var j = 1; j <= 30; j++) {
            dayNumbers.push({
              value: j, label: 'Day ' + j
            })
          }
          let dayNumbersOptions = dayNumbers.map((day) => {
            return {value:day.value, label:day.label, key:day.value}
          })
          return (
            // TODO: add values
            <LabeledDropDown name={'reminder' + i}
              //  value={isEditMode ? this.props.reminders[i].day_number && dayNumbersOptions.filter(day => {
              //    return day.value === this.props.reminders[i].day_number
              //  }): this.props.reminders['reminder' + i].day_number}
                             value={dayNumbersOptions.filter(day => {
                               return day.value === this.props.reminders['reminder' + i].day_number
                             })}
                             style={{ margin: 5 }}
                             handleSave={(value) => { this.handleReminderDayChange(value, 'reminder' + i) }}
                             options={dayNumbersOptions}
            />
            /*<Select
                name={'reminder' + i}
                value={isEditMode ? this.props.reminders[i].day_number : this.props.reminders['reminder' + i].day_number}
                style={{ margin: 5 }}
                onChange={(value) => { this.handleReminderDayChange(value, 'reminder' + i) }}
            >
              {dayNumbers.map((day) => {
                return (
                  <Option key={day.value} value={day.value}>{day.label}</Option>
                )
              })}
            </Select>*/
          )
        }
        else {
          return (<span/>)
        }
      }
      let loopStart = isEditMode ? 0 : 1
      let loopCount = isEditMode ? this.props.reminders.length-1 : Object.keys(this.props.reminders).length
      console.log('this.props.reminders',this.props.reminders, loopStart, loopCount)
      for (i = loopStart; i <= loopCount; i++) {
        let index = i;
        addReminderTimes.push(
          <div className='field-group' key={i}>
            <SectionHeader
              title={<Translation>{(t, {i18n}) => t('REMINDER_NUMBER', {i:index})}</Translation>}
              color="transparent" />
            <div className="reminder-input-container">
              <LabeledDropDown name={'reminder' + i}
                               value={isEditMode ? this.props.reminders[i].time : this.props.reminders['reminder' + i].time}
                               style={{ margin: 5 }}
                               placeholder={<Translation>{(t, {i18n}) => t('SELECT_A_TIME')}</Translation>}
                               handleSave={(value) => { this.handleReminderTimeChange(value, 'reminder' + index) }}
                               options={timeOptions}
              />

              {/*<Select className=''
                      name={'reminder' + i}
                      defaultValue={isEditMode ? this.props.reminders[i].time : this.props.reminders['reminder' + i].time}
                      style={{ margin: 5 }}
                      placeholder={<Translation>{(t, {i18n}) => t('SELECT_A_TIME')}</Translation>}
                      onChange={(value) => { this.handleReminderTimeChange(value, 'reminder' + index) }}>
                {timeOptions}
              </Select>*/}
              {isEditMode ? this.props.reminders[i].repeat_type && dayNumberOptions(i) : this.props.reminders['reminder' + i].repeat_type && dayNumberOptions(i)}
            </div>
          </div>
        )
      }
    }

    function convertTime(time) {
      let hour;
      let reminderTime = time;
      if (reminderTime.length === 3) {
        hour = reminderTime.substring(0, 1)
      } else if (reminderTime.length === 4) {
        hour = reminderTime.substring(0, 2)
      }
      else {
        hour = 0;
      }
      let ampm = hour < 12 ? 'am' : 'pm';
      hour = hour % 12 || 12;
      let minutes = reminderTime.slice(-2)
      if (hour === 12 && minutes === '0') {
        reminderTime = '12:00 am'
      } else {
        reminderTime = `${hour}:${minutes} ${ampm}`
      }
      return reminderTime
    }

    let reminderTypes = [
      { value: 0, label: 'daily' },
      { value: 1, label: 'weekly' },
      { value: 2, label: 'monthly' },
      { value: 3, label: 'as needed' }
    ]
    let reminderTypeOptions = [];
    reminderTypeOptions = reminderTypes.map((type) => {
      /*return (<Option key={type.value} value={type.label}>
        {type.label != 'as needed' ? i18next.t('components.common.BlockReminders.' + type.label) :
            i18next.t('AS_NEEDED')}
      </Option>)*/
      return {value:type.label, label:type.label !== 'as needed' ? i18next.t('' + type.label.replace(/[A-Z]/g,"_$&").toUpperCase()) :
          i18next.t('AS_NEEDED'), key:type.value}
    })
    
    return (
      <div>
         <div className="search-symptoms">
            <LabeledDropDown className="assign-medication-input"
                             label={<Translation>{(t, {i18n}) => t('FREQUENCY')}</Translation>}
                             placeholder={isEditMode ? this.props.reminders[0].repeat_type : (this.state.repeatType ? this.state.repeatType : <Translation>{(t, {i18n}) => t('SELECT_PLACEHOLDER')}</Translation>)}
                             handleSave={this.selectRepeatType}
                             options={reminderTypeOptions}
            />
            {/*<Select
              placeholder={isEditMode ? this.props.reminders[0].repeat_type : (this.state.repeatType ? this.state.repeatType : <Translation>{(t, {i18n}) => t('SELECT_PLACEHOLDER')}</Translation>)}
              onChange={this.selectRepeatType}
              // value={isEditMode ? this.props.reminders[0].repeat_type : ''}
            >
              {reminderTypeOptions}
            </Select>*/}
          </div>
        {
          this.state.repeatType !== "as needed" &&
          <>
            <div className="search-symptoms">
              <LabeledDropDown className="assign-medication-input"
                               value={selectedOption}
                               label={<Translation>{(t, {i18n}) => t('HOW_MANY_TIMES')}</Translation>}
                               options={[{value:1,label:1},{value:2,label:2},{value:3,label:3},{value:4,label:4}]}
                               handleSave={this.selectNumReminders}
                               placeholder={<Translation>{this.props.reminders.length>0 ? ()=>this.props.reminders.length : (t, {i18n}) => t('SELECT_NUMBER_PLACEHOLDER') }</Translation>}
              />
    
              {/*<Select
                  placeholder={<Translation>{this.props.reminders.length>0 ? ()=>this.props.reminders.length : (t, {i18n}) => t('SELECT_NUMBER_PLACEHOLDER') }</Translation>}
                  onChange={this.selectNumReminders}
                >
                  <Option value='1'>1</Option>
                  <Option value='2'>2</Option>
                  <Option value='3'>3</Option>
                  <Option value='4'>4</Option>
                </Select>*/}
            </div>
            
              <div className="date-picker-container">
                <LabeledDatePicker className="date-picker"
                                   label={<Translation>{(t, {i18n}) => t('START_DATE')}</Translation>}
                                   value={this.state.startDate ? moment(this.state.startDate) : null}
                                   handleSave={this.handleChangeStart}
                                   dateFormat={americanDateFormat}
                                   bold={true}
                />
                {/*<DatePicker
                  className="date-picker"
                  showTimeSelect
                  defaultValue={this.state.startDate ? moment(this.state.startDate) : null}
                  onChange={this.handleChangeStart}
                  placeholderText={<Translation>{(t, {i18n}) => t('START_DATE_PROPER_CAPITALIZED')}</Translation>}
                  dateFormat="LLL"
                  shouldCloseOnSelect={true}
                  isClearable={true}
                />*/}
                <LabeledSwitch checked={true}
                               disabled={true}
                />
              </div>
            
              <div className="date-picker-container">
                <LabeledDatePicker className="date-picker"
                                   label={<Translation>{(t, {i18n}) => t('END_DATE')}</Translation>}
                                   value={this.state.endDate ? moment(this.state.endDate) : null}
                                   handleSave={this.handleChangeEnd}
                                   dateFormat={americanDateFormat}
                                   disabled={!this.state.endDateEnabled}
                                   bold={true}
                />
                {/*<DatePicker
                  className="date-picker"
                  showTimeSelect
                  defaultValue={this.state.endDate ? moment(this.state.endDate) : null}
                  onChange={this.handleChangeEnd}
                  placeholderText={<Translation>{(t, {i18n}) => t('END_DATE_PROPER_CAPITALIZED')}</Translation>}
                  dateFormat="LLL"
                  disabled={!this.state.endDateEnabled}
                  shouldCloseOnSelect={true}
                  isClearable={true}
                />*/}
                <LabeledSwitch checked={this.state.endDateEnabled}
                               onChange={this.switchOnChange}
                />
              </div>
            
            {addReminderTimes}
            
          </>
        }
      </div>
    )
  }
}

