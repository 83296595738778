import React from "react";
import './SurveyBuilderQuestionDefaults.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {allowedWriteColumns, allowedWriteTableAndCustomOptionsArray, allowedWriteTablesAndCustom} from "../../../../constants/SurveyBuilderTypes";

export default class SurveyBuilderQuestionDefaults extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {question, updateQuestion, errorForIdTypeAndKey, fetchAppAdditionalColumns} = this.props;
    
    // TODO: Add API calls for Survey and Survey Response keys.
    // TODO: This columns aren't there, so fetch from server.
    // const allowedWriteTables = ['Custom', 'User', 'AdditionalAppColumn', 'Survey', 'SurveyResponse'];
    let allowedWriteColumnOptions = [];
    
    for(let table of allowedWriteTablesAndCustom){
      if(question && question.default_value && question.default_value.includes(table)){
        if(allowedWriteColumns[table]){
          for(let column of allowedWriteColumns[table]){
            allowedWriteColumnOptions.push({value:column, label:column});
          }
        }
        else{
          // TODO: allowedWriteColumns does not have the current table.
        }
        break;
      }
    }
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-default-input"
                       label={<Translation>{ (t, { i18n }) => t('DEFAULT_ANSWER') }</Translation>}
                       checked={Boolean(question && question.default_value !== undefined)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
          
                         if(isChecked){
                           updatedQuestion.default_value = '';
                         }
                         else{
                           delete updatedQuestion['default_value'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('DEFAULT_ANSWER_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        {/*TODO: Add section for Default Other (when other option is available for a question).*/}
        {/*TODO: Add section for Default Values (when multiple values are available for a question[key:aKey, value:aValue]).*/}
        
        {question && question.default_value !== undefined ?
          <span>
            <LabeledDropdown className="survey-builder-question-default-input-more"
                             label={<Translation>{ (t, { i18n }) => t('TABLE') }</Translation>}
                             value={allowedWriteTableAndCustomOptionsArray.filter(function(option){
                               return question.default_value.includes(option.value) || (option.value === 'Custom' && !question.default_value.includes('{{'));
                             })}
                             options={allowedWriteTableAndCustomOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('TABLE_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => {
                               let updatedQuestion = cloneDeep(question);
  
                               if(aOption.value === 'Custom'){
                                 updatedQuestion.default_value = '';
                               }
                               else{
                                 updatedQuestion.default_value = '{{' + aOption.value + '}}';
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             maxMenuHeight={150}
                             popoverPlacement={"left"}
            />
            
            {!question.default_value.includes('{{') ?
              <LabeledDelayedInput className="survey-builder-question-default-input-more"
                                   label={<Translation>{ (t, { i18n }) => t('CUSTOM_VALUE') }</Translation>}
                                   value={question.default_value && question.default_value.length > 0 ? question.default_value : ''}
                                   infoBlurb={<Translation>{(t, { i18n }) => t('CUSTOM_VALUE_EXPLANATION')}</Translation>}
                                   minLength={0}
                                   handleSave={(aEvent) => {
                                     let updatedQuestion = cloneDeep(question);
                                     updatedQuestion.default_value = aEvent.target.value;
                                     updateQuestion(updatedQuestion);
                                   }}
                                   warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'default_value').message}
                                   popoverPlacement={"left"}
              />
              :
              null
            }
            
            {allowedWriteColumnOptions.length > 0 ?
              <LabeledDropdown className="survey-builder-question-default-input-more"
                               label={<Translation>{ (t, { i18n }) => t('COLUMN') }</Translation>}
                               value={allowedWriteColumnOptions.filter(function(option){
                                 return question.default_value.includes(option.value);
                               })}
                               options={allowedWriteColumnOptions}
                               infoBlurb={<Translation>{(t, { i18n }) => t('COLUMN_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedQuestion = cloneDeep(question);
                                 
                                 if(!question.default_value.includes('{{')){
                                   question.default_value = aOption.value;
                                 }
                                 
                                 if(aOption.value === 'Custom'){
                                   updatedQuestion.default_value = '';
                                 }
                                 else{
                                   if(updatedQuestion.default_value.includes('.')){
                                     updatedQuestion.default_value = updatedQuestion.default_value.substring(0, updatedQuestion.default_value.indexOf('.'));
                                   }
                                   else{
                                     updatedQuestion.default_value = updatedQuestion.default_value.substring(0, updatedQuestion.default_value.indexOf('}}'));
                                   }
                                   updatedQuestion.default_value = updatedQuestion.default_value + '.' + aOption.value + '}}';
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'default_value').message}
                               maxMenuHeight={150}
                               popoverPlacement={"left"}
              />
              :
              null
            }
          </span>
          :
          null
        }
      </div>
    )
  }
}
