import React from 'react';
import {Translation} from "react-i18next";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {DEFAULT_COLORS} from "../../constants/Colors";
import groupColorPicker from "../../utils/ColorPicker";

export default class PatientInfoCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false
    }
  }

  // TODO: Add remove to menu instead of buttons.

  showRemove() {
    this.setState({showRemove: true})
  }

  hideRemove() {
    this.setState({showRemove: false})
  }

  render() {
    const {data, group, removeId, groupSymptom, menuButtonClicked} = this.props;

    let groupColour = DEFAULT_COLORS.MAIN_BLUE;

    if(group && group.core === false){
      groupColour = groupColorPicker(group.id);
    }
    //*<div className="secondary-header-container" style={{backgroundColor:groupColour}}>

    return (
      <div className="patient-list-item-cell"
           key={data && data.id}>
        {data &&
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className='patient-list-item-title-circle' style={{color:groupColour}}>
            {group && group.id && group.name && group.name.length > 0 ? group.name.charAt(0).toUpperCase() : ''}
          </div>
          <div>
            {data.name}
          </div>

          {data.date_of_diagnosis && data.date_of_diagnosis.split(" ")[0].substring(0,4) !== "0000" ?
            <span className='list-item-name list-item-additional-info'>
              &nbsp;
              <Translation>{ (t, { i18n }) => t('DIAGNOSED_IN_LOWERCASE') }</Translation>
              {data.date_of_diagnosis.split(" ")[0].substring(0,4)}
            </span>
            :
            null
          }
          {data.description ?
            <div className="user-prompt-msg"
                 style={{ marginLeft: '10px' }}>
              {data.description}
            </div>
            :
            null
          }
          
          {groupSymptom ?
            <div className="user-prompt-msg"
                 style={{ marginLeft: '10px' }}>
              {groupSymptom}
            </div>
            :
            null
          }
        </div>
        }
  
        {menuButtonClicked ?
          <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                 onClick={(aEvent) => menuButtonClicked(aEvent, removeId)}
          />
          :
          null
        }
      </div>
    )
  }
}
