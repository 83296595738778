import React from "react";
import './SurveyBuilderChoices.scss';
import Menu from "@material-ui/core/Menu";
import {toast} from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SurveyBuilderChoiceCell from "../../../Cells/SurveyBuilderCells/SurveyBuilderChoiceCell/SurveyBuilderChoiceCell";

const notifyCannotRemoveChoice = () =>  toast(<Translation>{ (t, { i18n }) => t('REMOVE_CHOICE_ERROR') }</Translation>, NOTIFY_OPTS.autoClose);

export default class SurveyBuilderChoices extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      selectedChoiceIndex:-1
    };
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.deleteChoiceAtIndex = this.deleteChoiceAtIndex.bind(this);
  }
  
  // Methods
  
  menuButtonClicked(aEvent, aIndex){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedChoiceIndex:aIndex});
  };
  
  deleteChoiceAtIndex(aIndex){
    const {question, updateQuestion, minimumChoices = 1} = this.props;
    
    if(question && question.choices && aIndex >= 0 && aIndex < question.choices.length){
      if(question.choices.length === minimumChoices){
        notifyCannotRemoveChoice();
      }
      else{
        let updatedQuestion = cloneDeep(question);
        updatedQuestion.choices.splice(aIndex, 1);
        
        if(updatedQuestion.choices.length === 0){
          delete updatedQuestion['choices'];
        }
        updateQuestion(updatedQuestion);
      }
    }
    this.setState({menuAnchorElement:null, selectedChoiceIndex:-1});
  }
  
  // Render
  
  render(){
    const {question, requirements, updateQuestion, selectedLanguage, errorForIdTypeAndKey, showExclusive = true} = this.props;
    const {menuAnchorElement, selectedChoiceIndex} = this.state;
    
    let choicesCount = 0;
    
    if(question && question.choices){
      choicesCount = question.choices.length;
    }
    
    return(
      <div className="survey-builder-choices-cell-outer">
        <div className="survey-builder-choices-title">
          <Translation>{(t, { i18n }) => t('CHOICES')}</Translation> - {choicesCount}
        </div>
        
        {choicesCount > 0 ?
          <div className="survey-builder-radio-question-choices-container">
            {question.choices.map((choice, index) => (
              <div key={'radio-question-cell-' + choice.value + '-' + index}
                   className="survey-builder-radio-question-choices-input-container">
                <SurveyBuilderChoiceCell key={'radio-question-cell-' + choice.value + '-' + index}
                                         index={index}
                                         choice={choice}
                                         question={question}
                                         updateChoice={(aChoice, aKey) => {
                                           let updatedQuestion = cloneDeep(question);
                                           updatedQuestion.choices[index][aKey] = aChoice;
                                           updateQuestion(updatedQuestion);
                                         }}
                                         requirements={requirements}
                                         showExclusive={showExclusive}
                                         updateQuestion={updateQuestion}
                                         selectedLanguage={selectedLanguage}
                                         menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, index)}
                                         errorForIdTypeAndKey={errorForIdTypeAndKey}
                />
              </div>
            ))}
          </div>
          :
          null
        }
        
        <div className="survey-builder-radio-question-add-button"
             onClick={(aEvent) => {
               aEvent.preventDefault();
               aEvent.stopPropagation();
          
               if(question){
                 let updatedQuestion = cloneDeep(question);
            
                 if(!updatedQuestion.choices){
                   updatedQuestion.choices = [];
                 }
                 if(requirements){
                   updatedQuestion.choices.push({label:'', type:'and', value:''});
                 }
                 else{
                   updatedQuestion.choices.push({label:'', value:''});
                 }
                 updateQuestion(updatedQuestion);
               }
             }}>
          <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
          
          <Translation>{(t, {i18n}) => t('ADD_CHOICE')}</Translation>
        </div>
        
        <Menu id="survey-builder-choices-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:100001}}
              onClose={() => this.setState({menuAnchorElement:null, selectedChoiceIndex:-1})}
              anchorEl={menuAnchorElement}
              keepMounted>
          <MenuItem style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      this.deleteChoiceAtIndex(selectedChoiceIndex);
                    }}>
            <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
