import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import TabBar from "../../Custom UI/TabBar/TabBar";
import {Translation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import './ManageSurveyLinks.scss';
import SurveyLinksList from "../../Lists/SurveyLinksList/SurveyLinksList";
import AddDataQueryModal from 'Modals/AddDataQueryModal/AddDataQueryModal';

export default class ManageSurveyLinks extends React.Component {

  // Instance Variables

  containerRef = React.createRef();

  // Init

  constructor(props){
    super(props);
    this.state = {
      tabBarKey:'SURVEY_LINKS',
      surveyLinkId:null,
      assignDataQuery:false,
      assignDataQueryError:'',
      onDataQueryCreate:null,
      selectedDataQuery:'',
      currentSurveyId:null,
      surveyResponseDetailsId:null,
      patientId:null,
      currentSurveyToolTitle:'',
      currentSurveyEditable:false,
      currentSurveyResponseDetailId:null,
      showSurveyResponses:false,
      selectedSurveyResponseId:null,
      closeDrawerAction:null
    };
    this.setPatientId = this.setPatientId.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const surveyLinkId = new URLSearchParams(window.location.search).get('survey_link_id');

    if(this.state.surveyLinkId !== surveyLinkId){
      this.setState({surveyLinkId:surveyLinkId});
    }
  }

  // Methods
  resetDataQueryModal = (aAssignDataQuery, aOnDataQueryCreate, aSurveyResponseDetailsId, aClearPatientId=true) => {
    this.setState({assignDataQueryError:'', assignDataQuery:aAssignDataQuery, onDataQueryCreate:aOnDataQueryCreate, surveyResponseDetailsId:aSurveyResponseDetailsId});

    if(aAssignDataQuery === false){
      this.setState({selectedDataQuery:null});
    }

    if(aClearPatientId) {
      this.setState({patientId:null})
    }
  };

  assignPatientDataQuery = ({description, notificationAlert}) => {
    this.setState({assignDataQueryError:''});
    
    this.props.createDataQueryNote(this.state.patientId, description, this.state.surveyResponseDetailsId, notificationAlert).then(aResponse => {
      if(this.state.onDataQueryCreate !== null){
        let dataQuery = aResponse.data.data.createDataQueryNoteMutation.data_query_note;
        this.state.onDataQueryCreate(dataQuery);
      }
      this.resetDataQueryModal(false, null, null);
      this.props.fetchPatient({userId:this.props.match.params.id})
    });
  };

  setPatientId(aSurveyLink) {
    if(aSurveyLink && aSurveyLink.patient) {
      this.setState({patientId: aSurveyLink.patient.id})
    }
  }


  // Render

  render() {
    const {user, company, getSurveyLinks, getSurveyLinkById, fetchPatient, getQuestById,
      getSurveyResponses, updateSurveyResponseLockState} = this.props;
    const {tabBarKey, assignDataQuery, patientId} = this.state;

    return (
      <div className="manage-survey-links-page">
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('SURVEY_LINKS') }</Translation>}/>

        <div className="manage-survey-links-navigation">
          <TabBar tabBarTabsArray={[{key:'SURVEY_LINKS'}]}
                  initialSelectedTabKey={tabBarKey}
                  selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}/>

          {tabBarKey === 'SURVEY_LINKS' ?
            <div className="manage-survey-links-container">
              <SurveyLinksList user={user}
                               company={company}
                               addDataQuery={(aSurveyResponseDetailsId) => this.resetDataQueryModal(true, null, aSurveyResponseDetailsId, false)}
                               canMakeEntry={true}
                               fetchPatient={fetchPatient}
                               getQuestById={getQuestById}
                               getSurveyLinks={getSurveyLinks}
                               showPatientColumns={true}
                               showLockOption={false}
                               setPatientId={this.setPatientId}
                               manageSurveyLinks={true}
                               getSurveyLinkById={getSurveyLinkById}
                               getSurveyResponses={getSurveyResponses}
                               updateSurveyResponseLockState={updateSurveyResponseLockState}
              />
            </div>
            :
            null
          }

          {assignDataQuery ?
            <AddDataQueryModal key={'add-data-query-modal-' + Math.floor(Math.random() * 10000)}
                              isOpen={assignDataQuery}
                              noteId={this.state.selectedDataQuery && this.state.selectedDataQuery.id}
                              createdAt={this.state.selectedDataQuery && this.state.selectedDataQuery.created_at}
                              description={this.state.selectedDataQuery && this.state.selectedDataQuery.description}
                              assignNoteError={this.state.assignDataQueryError}
                              handleCloseModal={() => this.resetDataQueryModal(false, null, null)}
                              assignNoteCallback={this.assignPatientDataQuery}
                              updateNoteCallback={this.assignPatientDataQuery}
            />
          :
          null
        }
        </div>
      </div>
    )
  }
}
