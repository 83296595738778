import React from "react";
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import {Translation} from "react-i18next";

export default class SurveyRangeQuestionCell extends React.Component{
  
  // Init

  constructor(props){
    super(props);
    this.state = {
      answer: '',
      hidden:false,
    };

    if(props.answer && props.answer.value){
      this.state.answer = props.answer.value;
    }
    this.props.onAnswerChange(this.props.question, this.state.answer);
  }
  
  // Methods

  valueChanged = (event) => {
    this.setState({answer: event.target.value});
    this.props.onAnswerChange(this.props.question, event.target.value);
  };
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer} = this.state;
    const {key, type, hidden} = question;
    const values = [];
    const defaultValue = question && question.default_value;
    
    let maxValue = question.max_value;
    let minValue = question.min_value;
  
    if(maxValue === null || maxValue === undefined){
      maxValue = 5;
      console.log('SurveyRangeQuestionCell (render): No maxValue set.');
    }
    if(minValue === null || minValue === undefined){
      minValue = 1;
      console.log('SurveyRangeQuestionCell (render): No minValue set.');
    }
    for(let i = parseInt(minValue); i <= parseInt(maxValue); i++){
      values.push(i);
    }

    if(!defaultValue && !answer) {
      values.unshift('');
    }
    if(defaultValue && !values.includes(defaultValue)) {
      values.push(defaultValue);
    }
    const selectValues = values.map( value => <option key={value}>{value}</option>);
  
    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        {!hidden ?
          <div>
            <hr />
            
            <em>
              <Translation>{ (t, { i18n }) => t('SURVEY_DROPDOWN_SELECT_INSTRUCTION_TEXT')}</Translation>
            </em>
  
            <hr />
  
            <select className={'survey-string-input'}
                    disabled={disabled}
                    onChange={this.valueChanged}
                    defaultValue={answer}>
              {selectValues}
            </select>
          </div>
          :
          null
        }
      </div>
    );
  }
}
