import EventTracker from 'eventTracker';
import EventTypes from 'constants/EventTypes';
import {loadPatientAllergiesUsers, loadPatientMedicationAllergiesUsers} from './patient';

export function fetchAllergies(aLimit, aOffset, aQuery, aUserId){
  EventTracker.track(EventTypes.FETCH_ALLERGIES);
  let query = `
    query getAllergies($query: String!, $limit: Int, $offset: Int, $user_id: ID){
      allergies_page(query: $query, limit: $limit, offset: $offset, user_id: $user_id){
        allergies{
          id
          allergen
        }
        total
      }
    }
  `;
  let variables = {};
  variables['query'] = aQuery ? aQuery : '';
  variables['limit'] = aLimit;
  variables['offset'] = aOffset;
  variables['user_id'] = aUserId;
  
  return () => {
    return global.api.post("/queries", {query, variables});
  };
}

export function fetchMedicationAllergies(aLimit, aOffset, aQuery, aUserId){
  EventTracker.track(EventTypes.FETCH_MEDICATION_ALLERGIES);
  let query = `
    query getMedicationAllergies($query: String!, $limit: Int, $offset: Int, $user_id: ID) {
      medication_allergies_page(query: $query, limit: $limit, offset: $offset, user_id: $user_id) {
        medication_allergies{
          id
          name
          strength
          drug_bank_product_concept_id
        }
        total
      }
    }
  `;
  let variables = {};
  variables['query'] = aQuery ? aQuery : '';
  variables['limit'] = aLimit;
  variables['offset'] = aOffset;
  variables['user_id'] = aUserId;

  return () => {
    return global.api.post("/queries", {query, variables});
  };
}

export function addAllergy(aUserId, aAllergen, aSeverity, aAllergyId, aId){
  EventTracker.track(EventTypes.ADD_ALLERGY);
  const query = `
  mutation AddPatientAllergy($addPatientAllergyInput: AddPatientAllergyInput!) {
    addPatientAllergy(input: $addPatientAllergyInput) {
      allergies_user {
        id
      }
    }
  }
  `;

  let variables = {
    addPatientAllergyInput:{
      user_id:aUserId,
      allergen:aAllergen,
      severity:aSeverity,
      allergy_id:aAllergyId
    }
  };
  if(aId){
    variables.addPatientAllergyInput.id = aId;
  }
  const operationName = 'AddPatientAllergy';

  return (dispatch) => {
    return global.api.post('/queries', {
      query, variables, operationName
    }).then((newResult) => {
      dispatch(loadPatientAllergiesUsers(aUserId));
    }).catch((error) => {
      return {error:error.data && error.data.error};
    });
  };
}

export function addMedicationAllergy(aUserId, aName, aSeverity, aAllergyId, aId){
  EventTracker.track(EventTypes.ADD_MEDICATION_ALLERGY);
  const query = `
  mutation AddPatientMedicationAllergy($addPatientMedicationAllergyInput: AddPatientMedicationAllergyInput!) {
    addPatientMedicationAllergy(input: $addPatientMedicationAllergyInput) {
      medication_allergies_user {
        id
      }
    }
  }
  `;
  const variables = {
    addPatientMedicationAllergyInput:{
      user_id:aUserId,
      name:aName,
      severity:aSeverity,
      medication_id:aAllergyId
    }
  };
  if(aId){
    variables.addPatientMedicationAllergyInput.id = aId;
  }
  const operationName = 'AddPatientMedicationAllergy';

  return (dispatch) => {
    return global.api.post('/queries', {
      query, variables, operationName
    }).then((newResult) => {
      dispatch(loadPatientMedicationAllergiesUsers(aUserId));
    }).catch((error) => {
      return {error:error.data && error.data.error};
    });
  };
}

export function removeAllergyFromUser(aAllergyId, aUserId){
  EventTracker.track(EventTypes.REMOVE_ALLERGY);
  const query = `
      mutation ($removeAllergyMutationInput: RemoveAllergyMutationInput!) {
        removeAllergy (input: $removeAllergyMutationInput){
          allergy {
            id
            allergen
          }
        }
      }
  `;
  const variables = {
    removeAllergyMutationInput:{
      user_id:aUserId,
      allergy_id:aAllergyId
    }
  };
  const operationName = 'RemoveAllergy';

  return (dispatch) => {
    // eslint-disable-next-line no-undef
    return global.api.post('/queries', {
      query, variables, operationName
    });
  };
}
