import React from "react";
import {Translation} from "react-i18next";
import GetAppIcon from '@material-ui/icons/GetApp';

export default class SurveyCSVQuestionCell extends React.Component{
  
  // Instance Variables
  
  inputRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      file:null,
      csvUrl:null
    };
    if(props.answer != null){
      if(props.answer.value != null){
        this.state.csvUrl = props.answer.value;
      }
      if(props.answer.file != null){
        this.state.file = props.answer.file;
        this.state.csvUrl = URL.createObjectURL(this.state.file);
      }
    }
    this.buttonClicked = this.buttonClicked.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }
  
  // Methods
  
  buttonClicked(){
    this.inputRef.current.click();
  }
  
  handleFileUpload(aEvent){
    let file = aEvent.target.files[0];
    this.setState({file:file});
    this.props.onAnswerChange(this.props.question, this.state.csvUrl, null, null, null, true, file);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {file, csvUrl} = this.state;
    const {key, type} = question;
  
    return(
      <div className="survey-question-file-cell"
           key={'survey-' + type + '-question-cell-' + key}>
        <input type="file"
               ref={this.inputRef}
               onChange={this.handleFileUpload}
               disabled={disabled}
               accept="text/csv"
               style={{display:'none'}}
        />
      
        {file ?
          <p className="survey-question-file-name">
            {file.name}
          </p>
          :
          csvUrl ?
            <div className="survey-question-file-container">
              <a className="survey-question-file-button-upload"
                 target="_blank"
                 rel='noreferrer'
                 href={csvUrl}
                 download="My_File.csv">
                <GetAppIcon style={{fontSize:'20px'}}/>
              
                <Translation>{ (t, { i18n }) => t('DOWNLOAD_CSV')}</Translation>
              </a>
            </div>
            :
            null
        }
        
        <button className={'survey-button green-button survey-question-file-button'}
                disabled={disabled}
                onClick={this.buttonClicked}>
          {file || csvUrl ?
            <Translation>{ (t, { i18n }) => t('CHANGE_CSV')}</Translation>
            :
            <Translation>{ (t, { i18n }) => t('SELECT_CSV')}</Translation>
          }
        </button>
      </div>
    )
  }
}
