import React from "react";
import './ScheduleConditionCell.scss';
import i18next from "i18next";
import LabeledInfo from "../../Custom UI/LabeledInputs/LabeledInfo/LabeledInfo";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {operationOptionsArray} from "../../constants/SurveyBuilderTypes";

const ScheduleConditionCell = ({index, surveys, disabled, condition, updateCondition, menuButtonClicked, questionsForSurveyId,
                                 fetchQuestionForSurveyId, fetchSurveyVersionCollections}) => {
  
  // Instance Variables
  
  const extraOption = surveys && condition && condition.survey_version_collection_id && surveys[condition.survey_version_collection_id] ? {label:surveys[condition.survey_version_collection_id].name, value:condition.survey_version_collection_id} : undefined;
  
  const conditionOperationsArray = [{value:'and', label:'and'}, {value:'or', label:'or'}];
  
  // Render
  
  return (
    <div className="schedule-condition-cell-container"
         key={'schedule-condition-cell-' + '-' + index}>
      <LabeledInfo className="schedule-condition-cell-labeled-info"
                   bold={true}
                   label={i18next.t('CONDITION_NUMBER', {aNumber:(index + 1)})}
                   menuButtonClicked={(aEvent) => menuButtonClicked(aEvent)}
      />
      
      <div className="schedule-condition-cell-type-index">
        <LabeledDropdown className="schedule-condition-cell-input"
                         label={i18next.t('CONDITION_SURVEY')}
                         value={condition.survey_version_collection_id}
                         infoBlurb={i18next.t('CONDITION_SURVEY_EXPLANATION')}
                         handleSave={(aSurvey) => {
                           condition.survey_version_collection_id = aSurvey.reference;
                           updateCondition(condition);
                           fetchQuestionForSurveyId(aSurvey.reference);
                         }}
                         isClearable={true}
                         placeholder={i18next.t('SEARCH_SURVEYS')}
                         fetchObjects={(aLimit, aOffset, aSearchString) => fetchSurveyVersionCollections(aLimit, aOffset, aSearchString)}
                         extraOptionsArray={extraOption !== undefined ? [extraOption] : undefined}
                         saveWithOptionsKey={true}
                         labelPropertiesArray={['name']}
                         valuePropertiesArray={['reference']}
                         optionsArrayFormatter={(aArray) => {
                           let optionsArray = [];
                           
                           for(let surveyVersionCollection of aArray){
                             optionsArray.push({label:surveyVersionCollection.name, value:surveyVersionCollection.reference});
                           }
                           return optionsArray;
                         }}
                         responseTotalKeysArray={['data', 'data', 'survey_version_collections', 'total']}
                         responseObjectKeysArray={['data', 'data', 'survey_version_collections', 'survey_version_collections']}
        />
        
        <LabeledDropDown className="schedule-condition-cell-input"
                         label={i18next.t('SURVEY_CONDITION_QUESTION')}
                         value={questionsForSurveyId(condition.survey_version_collection_id).filter(option => {
                           return option.value === condition.question;
                         })}
                         options={questionsForSurveyId(condition.survey_version_collection_id)}
                         disabled={disabled}
                         infoBlurb={i18next.t('SURVEY_CONDITION_QUESTION_EXPLANATION')}
                         handleSave={(aOption) => {
                           // TODO: Do we need question_type set as well?
                           condition.question = aOption.value;
                           updateCondition(condition);
                         }}
                         warningMessage={condition.question.length === 0 ? i18next.t('MISSING_QUESTION') : null}
                         menuButtonClicked={condition.question && condition.question.length > 1 ?
                           (aEvent) => menuButtonClicked(aEvent)
                           :
                           null
                         }
        />
        
        {!['answered', 'unanswered'].includes(condition.operation) ?
          <LabeledDelayedInput className="schedule-condition-cell-input"
                               label={i18next.t('SURVEY_CONDITION_VALUE')}
                               value={condition.value}
                               disabled={disabled}
                               infoBlurb={i18next.t('SURVEY_CONDITION_VALUE_EXPLANATION')}
                               minLength={0}
                               handleSave={(aEvent) => {
                                 condition.value = aEvent.target.value;
                                 updateCondition(condition);
                               }}
                               errorMessage={condition.value.length === 0 ? i18next.t('MISSING_VALUE') : null}
          />
          :
          null
        }
        
        {!['answered', 'unanswered'].includes(condition.operation) && ['date', 'datetime', 'monthyear'].includes(condition.question_type) ?
          <LabeledSwitch className="schedule-condition-cell-switch-input"
                         label={i18next.t('SURVEY_CONDITION_PARSE_TO_AGE')}
                         checked={condition.parse_to_age}
                         disabled={disabled}
                         onChange={(isChecked) => {
                           condition.parse_to_age = isChecked;
                           updateCondition(condition);
                         }}
                         infoBlurb={i18next.t('SURVEY_CONDITION_PARSE_TO_AGE_EXPLANATION')}
                         switchPosition={'bottom'}
                         checkedChildren={i18next.t('YES_PROPER_CAPITALIZED')}
                         unCheckedChildren={i18next.t('NO_PROPER_CAPITALIZED')}
          />
          :
          null
        }
        
        <LabeledDropDown className="schedule-condition-cell-input schedule-condition-cell-input-first-of-type"
                         label={i18next.t('SURVEY_CONDITION_OPERATION')}
                         value={operationOptionsArray.filter(option => {
                           return option.value === condition.operation;
                         })}
                         options={operationOptionsArray}
                         disabled={disabled}
                         infoBlurb={i18next.t('SURVEY_CONDITION_OPERATION_EXPLANATION')}
                         handleSave={(aOption) => {
                           condition.operation = aOption.value;
                           updateCondition(condition);
                         }}
                         warningMessage={condition.operation.length === 0 ? i18next.t('MISSING_OPERATION') : null}
        />
        
        {index > 0 ?
          <LabeledDropDown className="schedule-condition-cell-input"
                           label={i18next.t('SURVEY_PAIRWISE_CONDITION_OPERATION')}
                           value={conditionOperationsArray.filter(option => {
                             return option.value === condition.condition_operator;
                           })}
                           options={conditionOperationsArray}
                           disabled={disabled}
                           infoBlurb={i18next.t('SURVEY_PAIRWISE_CONDITION_OPERATION_EXPLANATION')}
                           handleSave={(aOption) => {
                             condition.condition_operator = aOption.value;
                             updateCondition(condition);
                           }}
          />
          :
          null
        }
      </div>
      
      {/*TODO: Ignore.*/}
      {/*{multipleValueOperationsArray.includes(condition.operation) ?*/}
      {/*  <div className="schedule-condition-cell-add-button"*/}
      {/*       onClick={() => {*/}
      {/*         if(!disabled){*/}
      {/*           let value = condition.value;*/}
      {/*           let parseToAge = condition.parse_to_age;*/}
      {/*           let questionType = condition.question_type;*/}
      {/*           let questionReference = condition.question;*/}
      {/*      */}
      {/*           if(!value){*/}
      {/*             value = [];*/}
      {/*           }*/}
      {/*           if(!parseToAge){*/}
      {/*             parseToAge = [];*/}
      {/*           }*/}
      {/*           if(!questionType){*/}
      {/*             questionType = [];*/}
      {/*           }*/}
      {/*           if(!questionReference){*/}
      {/*             questionReference = [];*/}
      {/*           }*/}
      {/*           value.push('');*/}
      {/*           parseToAge.push(false);*/}
      {/*           // questionType.push('');*/}
      {/*           questionReference.push('');*/}
      {/*           updateCondition(index, [{key:'value', value:value}, {key:'parse_to_age', value:parseToAge}, {key:'question_type', value:questionType}, {key:'question', value:questionReference}]);*/}
      {/*         }*/}
      {/*       }}>*/}
      {/*    <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>*/}
      {/*    */}
      {/*    {i18next.t('ADD_VALUE')}*/}
      {/*  </div>*/}
      {/*  :*/}
      {/*  null*/}
      {/*}*/}
    
    </div>
  );
};
export default ScheduleConditionCell;
