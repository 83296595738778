import React, { useState, useRef, useEffect } from "react";
import { Translation } from "react-i18next";
import parse from 'html-react-parser';
import DraggableList from "react-draggable-list";
import { AiFillInfoCircle } from "react-icons/ai";
import { BiMenu, BiPlusCircle, BiArrowBack, BiDotsVerticalRounded } from "react-icons/bi";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LabeledDropDown from "Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import './PatientList.scss'
import i18n from "../../i18n";
import i18next from "i18next";

export const AddEditTableColumns = ({tableList, fetchLocales, getSurveys, company, fetchSurveyQuestions, hide = () => {}, changeTableData = () => {}}) => {
  const containerRef = useRef();
  const [isFocused, setIsFocused] = useState();
  const [isError, setIsError] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  const [pendingAnswers, setPendingAnswers] = useState([]);
  const [showSurveyList, setShowSurveyList] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState();
  const [checkboxCheckedState, setCheckboxCheckedState] = useState([]);
  const [listData, setListData] = useState(tableList ? tableList.filter(e => e.id > 0) : []);
  
  useEffect(() => {
    if(listData){
      for(let index = 0; index < listData.length; index += 1){
        listData[index].zIndex = listData.length - index;
      }
    }
  }, [listData, setListData]);
  
  useEffect(() => {
    if(company){
      fetchLocales();
      getSurveys().then(result => {
        let surveyList = [];
        let surveysArray = result && result.data && result.data.data && result.data.data.surveys;
        
        if(surveysArray){
          surveysArray.forEach((aSurvey) => {
            surveyList.push(aSurvey);
          });
        }
        setSurveyList(surveyList);
      });
    }
  }, [company, fetchLocales, getSurveys]);
  
  const handleCancelPressed = () => {
    if(showSurveyList){
      setCheckboxCheckedState([]);
      setShowSurveyList(false);
    }
    else{
      hide();
    }
  };
  
  const handleSavedPressed = () => {
    if(showSurveyList){
      let mergedArray = [...listData, ...checkboxCheckedState];
      
      for(let index = 0; index < mergedArray.length; index += 1){
        mergedArray[index].order = index;
      }
      setListData(mergedArray);
      setCheckboxCheckedState([]);
      setShowSurveyList(false);
    }
    else{
      if(pendingAnswers.length){
        setIsError(true);
      }
      else{
        let firstTab = tableList?.filter(e => e.id <= 0);
        let survey_ids = [];
        let survey_question_mapping = [];
        listData.forEach(e => {
          if(e.id > 0){
            survey_ids.push(e.id);
            
            if(e.surveyQues){
              survey_question_mapping.push({[e.id]:e.surveyQues});
            }
          }
        });
        changeTableData({
          tableData:[...firstTab, ...listData],
          dynamicFields:{survey_ids, survey_question_mapping},
          addTableColumnModalOpen:false
        });
        localStorage.setItem("tableData", JSON.stringify([...firstTab, ...listData]));
        localStorage.setItem("dynamicFields", JSON.stringify({ survey_ids, survey_question_mapping }));
      }
    }
  };
  
  const fetchSurveyData = (survey) => {
    fetchSurveyQuestions(survey.id).then((resp) => {
      let surveyQuesOptions = resp?.data?.data?.survey_questions?.reduce(function (filtered, option){
        if(option.type === 'radio'){
          filtered.push({...option, label:option.label?.replace(/<[^>]*>/g, ''), value:option.key});
        }
        return filtered;
      }, []);
      setCheckboxCheckedState(data => [...data, {name:survey.name, surveyName:survey.name, id:survey.id, surveyOptions:surveyQuesOptions}]);
    })
    .catch(() => {
      setCheckboxCheckedState(data => [...data, {name:survey.name, surveyName:survey.name, id:survey.id}]);
    })
  };
  
  const handleQuesSelect = (aOption, surveyId, index) => {
    let data = [...listData];
    let answers = aOption.choices.map(e => {
      return {
        label: e[1]?.replace(/<[^>]*>/g, ''),
        value: e[0]
      }
    });
    data[index] = { ...data[index], surveyQues: aOption.value, surveyAnswers: answers };
    setPendingAnswers(ans => [...ans, surveyId]);
    setListData([...data]);
  };
  
  const handleAnswerSelect = (aAnswer, surveyId, index) => {
    let data = [...listData];
    let survey_index = pendingAnswers.findIndex(e => e === surveyId);
    
    data[index] = { ...data[index], surveyAns: aAnswer.value };
    setListData([...data]);
    
    // Delete survey id from pending answers array
    if(survey_index > -1){
      let ans_data = [...pendingAnswers];
      ans_data.splice(survey_index, 1);
      setPendingAnswers([...ans_data]);
      
      if(!ans_data.length){
        setIsError(false)
      }
    }
  };
  
  const handleDeletePressed = (surveyId) => {
    let survey_index = pendingAnswers.findIndex(e => e === surveyId);
    let index = listData.findIndex(e => surveyId === e.id);
    let data = [...listData];
    data.splice(index, 1);
    setListData([...data]);
    
    if(survey_index > -1){
      let ans_data = [...pendingAnswers];
      ans_data.splice(survey_index, 1);
      setPendingAnswers([...ans_data]);
      
      if(!ans_data.length){
        setIsError(false)
      }
    }
  };
  
  const getSanitizedOptions = options => {
    return options.map(option => {
      const sanitizedOption = { ...option };
      sanitizedOption.label = option.label && parse(option.label);
      return sanitizedOption;
    });
  };
  
  const renderSurveyQues = (item) => {
    let options = listData?.filter(e => e.id === item.id)[0]?.surveyOptions || [];
    options = getSanitizedOptions(options);
    let index = listData.findIndex(e => item.id === e.id);
    
    return (
      <>
        {options.length ?
          <LabeledDropDown className='manage-survey-dropdown manage-survey-ques-dropdown'
                           label={'Optional - Question'}
                           value={listData?.[index]?.surveyQues && options.filter(function (option) {
                             return option.value === listData?.[index]?.surveyQues;
                           })}
                           options={options}
                           handleSave={(aOption) => handleQuesSelect(aOption, item.id, index)}
                           placeholder={i18next.t('TAP_TO_SELECT')}
                           maxMenuHeight={250}
          />
          :
          <div style={{height: 20}}/>
        }
        
        {listData?.[index]?.surveyQues ?
          <LabeledDropDown className="manage-survey-dropdown"
                           label={'Answer'}
                           value={listData?.[index]?.surveyAns && listData?.[index]?.surveyAnswers.filter(function (ans) {
                             return ans.value === listData?.[index]?.surveyAns;
                           })}
                           options={listData?.[index]?.surveyAnswers}
                           handleSave={(aAnswer) => handleAnswerSelect(aAnswer, item.id, index)}
                           placeholder={i18next.t('TAP_TO_SELECT')}
                           maxMenuHeight={250}
                           errorMessage={pendingAnswers.includes(item.id) && isError && <Translation>{(t, { i18n }) => t('ADMIN_ADD_ANSWER_ERROR')}</Translation>}
          />
          :
          null
        }
      </>
    
    )
  };
  
  const renderSurveyList = () => {
    const isChecked = (aSurvey) => {
      let returnValue = false;
      
      if(aSurvey){
        for(let checkedSurvey of listData){
          if(aSurvey.id === checkedSurvey.id){
            returnValue = true;
            break;
          }
        }
        if(!returnValue){
          for(let checkedSurvey of checkboxCheckedState){
            if(aSurvey.id === checkedSurvey.id){
              returnValue = true;
              break;
            }
          }
        }
      }
      return returnValue;
    };
    
    return (
      <>
        <div>
          <BiArrowBack style={{ fontSize: '21px', verticalAlign: 'middle', marginRight: '5px' }}
                       onClick={() => setShowSurveyList(false)}
          />
          
          <span className="group-user-title-text margin-left-0">
            <Translation>{(t, { i18n }) => t('SELECT_QUESTIONNAIRES')}</Translation>
          </span>
        </div>
        
        <div className="survey-list">
          {surveyList.map((survey) => {
            return (
              listData.some(e => e.id === survey.id) ? <></> :
                <div key={`${survey.id}-container`}>
                  <label className={'custom-input-cell'}
                         key={`${survey.id}-cell`}>
                    <input key={`${survey.id}-input`}
                           type={"checkbox"}
                           name={`${survey.name}^&@${survey.id}`}
                           checked={isChecked(survey)}
                           onChange={() => {
                             let index = checkboxCheckedState.findIndex(e => e.id === survey.id);
                             
                             if(index > -1){
                               let data = [...checkboxCheckedState];
                               data.splice(index, 1);
                               setCheckboxCheckedState([...data]);
                             }
                             else{
                               fetchSurveyData(survey);
                             }
                           }}
                    />
                    
                    <span className={'custom-checkmark'}
                          key={`${survey.id}-checkmark`}
                    />
                    
                    <div className={'inline-text'}
                         key={`${survey.id}-name`}>
                      {survey.name}
                    </div>
                  </label>
                </div>
            )
          })}
        </div>
      </>
    )
  };
  
  const PatientAddEditCard = ({ item, itemSelected, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const [anchorEl, setAnchorEl] = useState(null);
    const translatedNameKeys = {'Status':i18n.t('STATUS'), 'Email Validated':i18n.t('EMAIL_VALIDATED')};
    const translatedStatusKeys = {'Status':i18n.t('ACCOUNT_STATUS'), 'Email Validated':i18n.t('EMAIL_VALIDATION_STATUS')};
    let name = item.surveyName;
    let status = item.name;
    
    if(translatedNameKeys[item.name]){
      name = translatedNameKeys[item.name];
      status = translatedStatusKeys[item.name];
    }
    
    function handleMenuTriggerClick(aEvent){
      setAnchorEl(aEvent.currentTarget);
    }
    
    function handleMenuClose(){
      setAnchorEl(null);
    }
    
    return (
      <div className="add-edit-column"
           key={item.name}
           style={{border:(pendingAnswers.includes(item.id) && isError) ? '1px solid #e02020' : '', paddingBottom:20, zIndex:item.zIndex}}
           onClick={() => {
             if(showDeleteButton){
               setShowDeleteButton(false);
             }
           }}>
        
        <span onMouseUp={() => {
                document.body.style.overflow = "visible";
              }}
              onMouseDown={(e) => {
                document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e) => {
                e.target.style.backgroundColor = "black";
                document.body.style.overflow = "visible";
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                e.target.style.backgroundColor = "blue";
                document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}>
          <BiMenu style={{fontSize:'21px', verticalAlign:'middle', marginRight:'20px'}}
                  fill={'#a8a8a8'}
          />
        </span>
  
        <input className={item.id ? 'add-edit-column-name add-edit-column-width' : 'add-edit-column-name'}
               type="text"
               value={name}
               onBlur={() => setIsFocused()}
               editable={item?.name === 'Status' || item?.name === 'Email Validated' ? 'false' : 'true'}
               onChange={event => {
                 let data = [...listData];
                 let index = listData.findIndex(e => item.id === e.id);
                 data[index] = { ...data[index], surveyName: event.target.value };
                 setListData([...data]);
  
                 if(!isFocused){
                   setIsFocused(item.id);
                 }
               }}
               autoFocus={item.id && isFocused === item.id}
        />
  
        {item.id ?
          <BiDotsVerticalRounded style={{ fontSize: '18px', verticalAlign: 'middle', marginRight: '20px', cursor: 'pointer' }}
                                 height='16px'
                                 width='16px'
                                 fill='#A8A8A8'
                                 onClick={(aEvent) => handleMenuTriggerClick(aEvent)}
          />
          :
          null
        }
  
        <Menu className="add-edit-popover-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose()}>
          <MenuItem onClick={() => handleDeletePressed(item.id)}>
            <Translation>{ (t) => t('DELETE')}</Translation>
          </MenuItem>
        </Menu>
        
        <>
          <div className='add-edit-attached'>
            <Translation>{ (t) => t('ATTACHED')}</Translation>
          </div>
          
          <div className='add-edit-column-status'>
            {status}
          </div>
        </>
        
        {item?.id ? renderSurveyQues(item) : null}
      </div>
    );
  };
  
  return (
    <div className='add-edit-main-container'>
      {showSurveyList ?
        renderSurveyList()
        :
        <>
          <div className='add-edit-header-footer'>
            <span>
              <Translation>{(t, {i18n}) => t('COLUMNS')}</Translation>-{listData ? listData.length : 2}
            </span>
            
            <BiPlusCircle fill={'#2d81c9'}
                          style={{ fontSize: '21px', verticalAlign: 'middle' }}
                          onClick={() => setShowSurveyList(true)}
            />
          </div>
          
          <div className="draggable-list">
            <DraggableList list={listData}
                           itemKey="order"
                           template={PatientAddEditCard}
                           container={() => containerRef.current}
                           onMoveEnd={(newList) => setListData(newList)}
                           unsetZIndex={true}
            />
          </div>
        </>
      }
      
      <div className='add-edit-header-footer'>
        <span style={{ verticalAlign: 'middle', paddingBottom: '10px' }}>
          {isError && !showSurveyList && pendingAnswers.length > 0 ?
            <>
              <AiFillInfoCircle style={{ fontSize: '20px', verticalAlign: 'middle', marginRight: '5px', color: '#e02020' }}/>
    
              <span className="dropdown-error">
                {pendingAnswers.length} error to resolve
              </span>
            </>
            :
            null
          }
        </span>
  
        <span className="add-edit-header-footer">
          <input className="add-edit-button"
                 type={"button"}
                 value={'Cancel'}
                 onClick={handleCancelPressed}
          />
          
          <input className="add-edit-button saveButton"
                 type={"button"}
                 value={'Save'}
                 onClick={handleSavedPressed}
          />
        </span>
      </div>
    </div>
  )
} 
