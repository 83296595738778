export function getAdminHistories(aUserId, aUpToDate, aLimit, aOffset, aPatientIdOrEdcId){
  let query = `
    query getAdminHistories($user_id: ID, $company_id: ID!, $up_to_date: String, $patient_id_or_edc_id: String,  $limit: Int, $offset: Int){
      admin_history_page(user_id: $user_id, company_id: $company_id, up_to_date: $up_to_date, patient_id_or_edc_id: $patient_id_or_edc_id, limit: $limit, offset: $offset){
        admin_histories{
          id
          item_type
          edited_at
          edited_by {
            first_name
            last_name
            id
          }
          event_type
          change_list {
            key
            old_value
            new_value
          }
        }
        total
      }
    }
  `;
  let variables = {user_id:aUserId, up_to_date:aUpToDate, limit:aLimit, offset:aOffset};
  
  if(aPatientIdOrEdcId){
    variables.patient_id_or_edc_id = aPatientIdOrEdcId;
  }
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id
    return global.api.post("/queries", {query, variables});
  };
}
