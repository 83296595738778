import React from 'react';
import styles from './PaymentTriggerCell.scss';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import 'antd/dist/antd.css';
import {Translation} from "react-i18next";
import SmallGroupCell from "../SmallGroupCell/SmallGroupCell";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import Loading from "../../Custom UI/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

export default class PaymentTriggerCell extends React.Component {

  // Instance Variables

  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getGroups(offset, limit),
    success: () => this.updateList(null),
    fail: (error) => this.updateList(error)
  });

  // Init

  constructor(props) {
    super(props);
    this.state = {
      groupWrappersArray:[],
      totalGroups:0
    };
    this.symbolForCurrency = this.symbolForCurrency.bind(this);
    this.updateGroupEnabledState = this.updateGroupEnabledState.bind(this);
  }

  componentDidMount() {
    this.scrollManager.fetch(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.paymentTrigger.id !== this.props.paymentTrigger.id){
      this.setState({ groupWrappersArray:[], totalGroups:0});
      this.scrollManager.fetch(true);
    }
    if(!prevProps.paymentTrigger.id && !prevProps.company && prevProps.company !== this.props.company){
      this.scrollManager.fetch(true);
    }
  }

  // Methods

  updateList(aError){
    this.setState({groupWrappersArray: this.scrollManager.getList()});

    if(aError){
      console.error("PaymentTriggerCell (updateList): Failed with error:", aError);
    }
  }

  getGroups(offset, limit){
    return this.props.fetchGroupsForPaymentTrigger(this.props.paymentTrigger.id, limit, offset).then(result => {
      let groupWrappersArray = [];
      let totalGroups = 0;

      if(result && result.data && result.data.data && result.data.data.hyperwallet_group_payment_triggers){
        groupWrappersArray = result.data.data.hyperwallet_group_payment_triggers.hyperwallet_group_payment_triggers;
        totalGroups = result.data.data.hyperwallet_group_payment_triggers.total;
        this.setState({totalGroups:totalGroups});
      }
      return {objects: groupWrappersArray};
    });
  }

  updateGroupEnabledState(aGroup, isEnabled, aIndex){
    this.props.updateHyperwalletGroupPaymentTrigger(!isEnabled, aGroup.id, this.props.paymentTrigger.id).then(result => {
      if(result && result.data && result.data.data && result.data.data.updateHyperwalletGroupPaymentTrigger) {
        let groupWrapper = result.data.data.updateHyperwalletGroupPaymentTrigger.hyperwallet_group_payment_trigger;
        this.scrollManager.replaceItemAtIndex(groupWrapper, aIndex);
        this.updateList();
      }
    });
  }

  symbolForCurrency(aCurrency){
    let returnValue = '$';

    if(aCurrency === 'EUR'){
      returnValue = '€';
    }
    return returnValue;
  }

  // Render

  render() {
    const { paymentTrigger, menuButtonClicked } = this.props;

    let totalGroups = this.state.totalGroups;
    let groupWrappersArray = this.state.groupWrappersArray;
    let infiniteScrollContainerId = 'payment-trigger-groups-list-' + paymentTrigger.id;

    return (
      <div className={styles.PaymentTriggerCell}>
        <div className="PaymentTriggerCell">
          <div className="payment-trigger-cell-container-first">
            <div className="payment-trigger-cell-title-cell">
              <div>{paymentTrigger.title}</div>
              {/*TODO: Replace with BiDots?*/}
              {menuButtonClicked ?
                <MoreVertOutlinedIcon style={{cursor: 'pointer'}} onClick={(aEvent) => menuButtonClicked(aEvent)}/>
                :
                null
              }
            </div>

            <div className="payment-trigger-info-header">
              <div className="payment-trigger-info-header-cell-large">
                <Translation>{ (t, { i18n }) => t('SCHEDULE') }</Translation>
              </div>
              <div className="payment-trigger-info-header-cell-small">
                <Translation>{ (t, { i18n }) => t('AMOUNT') }</Translation>
              </div>
              <div className="payment-trigger-info-header-cell-small">
                <Translation>{ (t, { i18n }) => t('PAID') }</Translation>
              </div>
            </div>
            <div className="payment-trigger-info-body">
              <div className="payment-trigger-info-body-cell-large">
                <div className="payment-criteria-icon"/>
                <div className="payment-criteria-text">
                  {paymentTrigger.action_criteria}
                </div>
              </div>
              <div className="payment-trigger-info-body-cell-small">
                {this.symbolForCurrency(paymentTrigger.currency) + paymentTrigger.amount + ' ' + paymentTrigger.currency}
              </div>
              <div className="payment-trigger-info-body-cell-small">
                {paymentTrigger.number_of_payments}
              </div>
            </div>

          </div>
          <div className="payment-trigger-cell-container-second">
            <div className="payment-trigger-cell-title-cell">
              <div>
                <Translation>{ (t, { i18n }) => t('ASSIGNED_GROUPS')}</Translation> - {totalGroups}
              </div>
            </div>
            <div className="payment-trigger-group-cell"
                 id={infiniteScrollContainerId}>
              <InfiniteScroll style={{overflowY:"hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget={infiniteScrollContainerId}>
                {groupWrappersArray && groupWrappersArray.length > 0 ?
                  <div>
                    {groupWrappersArray.map((groupWrapper, index) => (
                      <SmallGroupCell group={groupWrapper.group}
                                      isEnabled={groupWrapper.is_enabled}
                                      enableAction={() => this.updateGroupEnabledState(groupWrapper.group, groupWrapper.is_enabled, index)}
                                      key={paymentTrigger.id + index}
                                      {...this.props}
                      />
                    ))}

                  </div>
                  :
                  <div className="no-information-text">
                    <Translation>{ (t, { i18n }) => t('NO_PAYMENT_TRIGGERS')}</Translation>
                  </div>
                }
              </InfiniteScroll>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
