import React, { useState } from 'react';
import './ChangeAdminGroupModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import LabeledMultipleSelectDropDown from "Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";

export default function ChangeAdminGroupModal({user, isOpen, closeModal, groupsArray, assignedGroups, updateAdminGroups}){
  
  // Instance Variables

  const [newGroups, setNewGroups] = useState(assignedGroups);

  const handleGroupsChange = (aEvent) => {
    setNewGroups(aEvent);
  };

  const handleAdminGroupsChangeSubmit = () => {
    updateAdminGroups(user.id, newGroups);
    closeModal();
  };
  
  // Render

  return (
    <GeneralModal title={<Translation>{(t, {i18n}) => t('ADMIN')}</Translation>}
                  isOpen={isOpen}
                  isEditing={true}
                  handleCloseModal={closeModal}
                  handleSave={handleAdminGroupsChangeSubmit}
                  showSaveButton={true}
                  showCancelButton={true}
                  maxHeight={900}
                  containsDropDown={true}>
      <LabeledMultipleSelectDropDown className="change-admin-group-modal-input"
                                     label={<Translation>{ (t, { i18n }) => t('GROUP') }</Translation>}
                                     value={newGroups}
                                     options={groupsArray}
                                     handleSave={handleGroupsChange}
      />
    </GeneralModal>
  )
}
