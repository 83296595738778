import React from 'react';
import './CallNoteHistoriesList.scss';
import i18next from 'i18next';
import DynamicList from "../DynamicList/DynamicList";
import {roleForCompany} from '../../Helpers/CompanyHelpers';
import {fullDateTimeFormat} from "../../Helpers/DateHelpers";
import {callNoteKeys, typesOfCallsOptionsArray, allDispositionCodesOptionsArray} from "../../constants/CallNoteTypes";
import {formatUserName} from "../../Helpers/AdminHelpers";

export default class CallNoteHistoriesList extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const {company, callNote, className, getCallNoteHistories} = this.props;
    
    let columnsArray = [];
    columnsArray.push({key:'caller', columnWeight:1, columnNameKey:'CALLER', propertiesArray:['call_note', 'caller']});
    columnsArray.push({key:'type_of_visit', columnWeight:1, columnNameKey:'TYPE_OF_VISIT', propertiesArray:['call_note', 'type_of_visit'],
      valueFormatter:(aValue) => {
        let returnValue = '';
  
        for(let option of typesOfCallsOptionsArray()){
          if(option.value === aValue){
            returnValue = option.label;
            break;
          }
        }
        return returnValue;
      }});
    columnsArray.push({key:'start_time', columnWeight:1, columnNameKey:'CALLER_DATE_AND_TIME', propertiesArray:['call_note', 'start_time'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'CALL_NOTE_CREATED_ON', propertiesArray:['call_note', 'created_at'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'updated_at', columnWeight:1, columnNameKey:'CALL_NOTE_UPDATED_ON', propertiesArray:['call_note', 'updated_at'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'call_duration', columnWeight:1, columnNameKey:'CALL_DURATION_H', propertiesArray:['call_note', 'call_duration']});
    columnsArray.push({key:'purpose_of_visit', columnWeight:1, columnNameKey:'PURPOSE_OF_VISIT', propertiesArray:['call_note', 'purpose_of_visit']});
    columnsArray.push({key:'visit_disposition', columnWeight:1, columnNameKey:'VISIT_DISPOSITION', propertiesArray:['call_note', 'visit_disposition'],
      valueFormatter:(aValue) => {
        let returnValue = '';
    
        for(let option of allDispositionCodesOptionsArray()){
          if(option.value === aValue){
            returnValue = option.label;
            break;
          }
        }
        return returnValue;
      }});
    columnsArray.push({key:'survey_name', columnWeight:1, columnNameKey:'SURVEY_NAME', propertiesArray:['call_note', 'survey_name']});
    columnsArray.push({key:'note_text', columnWeight:2, canShowMore:true, columnNameKey:'EXTRA_NOTE', propertiesArray:['call_note', 'note_text'],
      valueFormatter:(aNote) => {return aNote}});
    columnsArray.push({key:'change_list', columnWeight:2, canShowMore:true, columnNameKey:'FIELDS_MODIFIED', propertiesArray:['change_list'],
      valueFormatter:(aChangeList) => {
        let returnValue = '';
        
        for(let change of aChangeList){
          if(change.key !== 'updated_at'){
            returnValue += i18next.t(callNoteKeys[change.key]) + ', ';
          }
        }
        if(returnValue.length > 2){
          returnValue = returnValue.substring(0, returnValue.length - 2);
        }
        return returnValue;
      }});
    columnsArray.push({key:'edited_by', columnWeight:1, columnNameKey:'EDITOR_NAME', propertiesArray:['edited_by'],
      valueFormatter:(aUser) => formatUserName(aUser)});
    
    return (
      ['provider', 'admin', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_10'].includes(roleForCompany(company)) ?
        <div className={'call-note-histories-list-outer-scroll-container ' + className}
             id="call-note-histories-list">
          <DynamicList id="call-note-histories-list"
                       limit={7}
                       didReload={() => this.setState({shouldReload:false})}
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset) => getCallNoteHistories(callNote.id, aLimit, aOffset)}
                       showSearchBar={false}
                       minColumnWidth={120}
                       totalObjectsKey='CALL_NOTE_HISTORY'
                       responseTotalKeysArray={['data', 'data', 'telehealth_call_note_histories', 'total']}
                       responseObjectKeysArray={['data', 'data', 'telehealth_call_note_histories', 'admin_histories']}
          />
        </div>
        :
        <div className="call-note-histories-blocked">
          {i18next.t('CALL_NOTE_HISTORY_BLOCKED')}
        </div>
    )
  }
}
