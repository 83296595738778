import React from 'react';
import './PersonalInformation.scss'
import i18next from "i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropDown from '../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown';
import {roleForCompany} from "../../../../Helpers/CompanyHelpers";
import LabeledTextInput from '../../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput';
import {genderOtherError} from "../../../../Helpers/PatientHelpers";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {genderOptionsArray} from "../../../../constants/GenderTypes";
import {Translation, withTranslation} from "react-i18next";

class PersonalInformation extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      gender:props.patient ? props.patient.gender : '',
      patient:props.patient ? props.patient : {},
      birthYear:props.patient ? props.patient.birth_year : '',
      genderOther:props.patient ? props.patient.gender_other : '',
      testAccount:props.patient ? props.patient.test_account : true,
      canTriggerAlerts:props.patient ? props.patient.can_trigger_alerts : true
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleGenderSave = this.handleGenderSave.bind(this);
    this.handleGenderOtherSave = this.handleGenderOtherSave.bind(this);
  }
  
  // Methods
  
  handleSave(aKey, aValue){
    const {patient, updateUser, fetchPatient} = this.props;
    
    if(patient && updateUser && fetchPatient){
      updateUser({id:patient.id, [aKey]:aValue}).then((newResult) => {
        fetchPatient({userId:patient.id});
      });
    }
  }
  
  handleGenderSave(aValue){
    const {genderOther} = this.state;
    
    this.setState({gender:aValue}, () => {
      if(aValue !== 'other' || genderOtherError(aValue, genderOther) === null){
        this.handleSave('gender', aValue);
      }
    });
  }
  
  handleGenderOtherSave(aValue){
    const {gender} = this.state;
    
    this.setState({genderOther:aValue}, () => {
      if(gender === 'other' && genderOtherError(gender, aValue) === null){
        this.handleSave('gender', gender);
        this.handleSave('gender_other', aValue);
      }
    });
  }
  
  // Render
  
  render() {
    const {patient, company} = this.props;
    const {genderOther, gender, birthYear, testAccount, canTriggerAlerts} = this.state;
  
    const currentRole = roleForCompany(company);
    const namePermissions = ['admin_level_2', 'admin_level_4', 'admin'];
    const personalInfoPermissions = ['admin_level_2', 'provider', 'admin_level_3', 'admin_level_4', 'admin', 'admin_level_10'];
  
    const availableGenders = genderOptionsArray();
  
    const currentYear = (new Date()).getFullYear();
    
    let age = null;
  
    if(patient && patient.birth_year){
      age = currentYear - parseInt(patient.birth_year, 10);
    }
    let availableYears = [];
    
    for(let i = 12; i <= 100; i++){
      let year = currentYear - i;
      availableYears.push({value:year, label:year.toString()});
    }
    
    return (
      <div>
        {personalInfoPermissions.includes(currentRole) && patient ? (
            <div className='personal-information-section'>
              {namePermissions.includes(currentRole) ?
                <>
                  <LabeledDelayedInput className="profile-personal-information-input"
                                       label={<Translation>{ (t, { i18n }) => t('FIRST_NAME_PROPER_CAPITALIZED')}</Translation>}
                                       value={patient.first_name}
                                       handleSave={(aEvent) => this.handleSave('first_name', aEvent.target.value)}/>
                  
                  <LabeledDelayedInput className="profile-personal-information-input"
                                       label={<Translation>{ (t, { i18n }) => t('LAST_NAME_PROPER_CAPITALIZED')}</Translation>}
                                       value={patient.last_name}
                                       handleSave={(aEvent) => this.handleSave('last_name', aEvent.target.value)}/>
                </>
                :
                null
              }
              
              <div className='personal-information-bottom-cell'>
                <LabeledDropDown className={gender === 'other' ? 'profile-personal-information-input' : 'profile-personal-information-input-full-width'}
                                 label={i18next.t('SEX')}
                                 value={availableGenders.filter(function(option) {
                                   return option.value === gender;
                                 })}
                                 options={availableGenders}
                                 handleSave={(aOption) => this.handleGenderSave(aOption.value)}
                />
                
                {gender === 'other' ?
                  <LabeledDelayedInput className="profile-personal-information-input"
                                       label={i18next.t('OTHER')}
                                       value={genderOther}
                                       required={true}
                                       minLength={1}
                                       placeholder={i18next.t('TAP_TO_TYPE')}
                                       handleSave={(aEvent) => this.handleGenderOtherSave(aEvent.target.value)}
                                       errorMessage={genderOtherError(gender, genderOther)}
                  />
                  :
                  null
                }
              </div>
              
              <LabeledDropDown className="profile-personal-information-input"
                               label={<Translation>{ (t, { i18n }) => t('BIRTH_YEAR')}</Translation>}
                               value={birthYear && availableYears.filter(function(option) {
                                 return option.value === birthYear;
                               })}
                               options={availableYears}
                               handleSave={(aOption) => this.setState({birthYear:aOption.value}, () => this.handleSave('birth_year', aOption.value))}
              />
              
              <div className="personal-information-bottom-container">
                <div className="personal-information-bottom-cell">
                  <LabeledTextInput className="profile-personal-information-input"
                                    label={<Translation>{ (t, { i18n }) => t('AGE')}</Translation>}
                                    value={patient && patient.birth_year && age}
                                    options={availableYears}
                                    disabled={true}
                                    handleSave={(aOption) => this.handleSave('birth_year', (currentYear - aOption.value).toString())}
                  />
                  
                  <LabeledTextInput className="profile-personal-information-input"
                                    label={<Translation>{ (t, { i18n }) => t('USER_ID')}</Translation>}
                                    value={patient && patient.id}
                                    disabled={true}
                                    handleSave={(aEvent) => this.handleSave('id', aEvent.target.value)}
                  />
                </div>
                
                <div className="personal-information-bottom-switch-cell">
                  <LabeledSwitch className="personal-information-input-switch personal-information-test-account"
                                 label={<Translation>{ (t, { i18n }) => t('TEST_ACCOUNT') }</Translation>}
                                 checked={testAccount}
                                 onChange={(aTestAccount) => this.setState({testAccount:aTestAccount}, () => this.handleSave('test_account', aTestAccount))}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('TEST_ACCOUNT_TOOL_TIP') }</Translation>}
                                 defaultChecked={false}
                                 checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                 unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                  />
                  
                  <LabeledSwitch className="personal-information-input-switch personal-information-patient-alerts"
                                 label={<Translation>{ (t, { i18n }) => t('PATIENT_ALERTS') }</Translation>}
                                 checked={canTriggerAlerts}
                                 onChange={(aCanTriggerAlerts) => this.setState({canTriggerAlerts:aCanTriggerAlerts}, () => this.handleSave('can_trigger_alerts', aCanTriggerAlerts))}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('TRIGGER_ALERTS_TOOL_TIP') }</Translation>}
                                 defaultChecked={true}
                                 checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                 unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                  />
                </div>
              </div>
            </div>
          )
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('PERSONAL_INFORMATION_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(PersonalInformation)
