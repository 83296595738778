import React from 'react';
import "antd/dist/antd.css";
import './TriggerModal.scss';
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import Trigger from "../../Components/Trigger/Trigger";
import i18next from "i18next";

export default class TriggerModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      updateButtonPressed:false
    };
  }
  
  // Render
  
  render() {
    let { tools, isOpen, company, trigger, createTrigger, updateTrigger, handleCloseModal, handleCloseModalAndReload } = this.props;
    let title = (i18next.t('TRIGGER'));
    
    if(trigger && trigger.id && trigger.id.length > 0){
      title += ' (ID: ' + trigger.id + ')';
    }
    return (
      <GeneralModal title={title}
                    width={'calc(100vw - 60px)'}
                    height={'calc(100vh - 60px)'}
                    isOpen={isOpen}
                    maxWidth={700}
                    isEditing={true}
                    maxHeight={550}
                    handleSave={() => this.setState({updateButtonPressed:true})}
                    showSaveButton={true}
                    handleCloseModal={() => handleCloseModal()}
                    showCancelButton={true}>
        <Trigger tools={tools}
                 company={company}
                 updateFailed={() => this.setState({updateButtonPressed:false})}
                 createTrigger={createTrigger}
                 trigger={trigger}
                 updateTrigger={updateTrigger}
                 updateFinished={() => this.setState({updateButtonPressed:false}, () => handleCloseModalAndReload())}
                 hideCreateButton={true}
                 updateButtonPressed={this.state.updateButtonPressed}
        />
      </GeneralModal>
    )
  }
}
