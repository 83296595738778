// This module assigns a color based on the last digit of the groupId id.
// For example groupId of 10 is assigned element 0 from the colorArray
// and groupId of 221 is assigned element 1 from the colorArray
// % 10 gets the last digit from the number
let groupColorPicker = (groupId) => {
	const colorArray = ['#5aa55c','#46b29d', '#540032', '#e37b40', '#f53855', '#f9bc20', '#00ada9', '#ff6444', '#60a65f' ,'#15468b'];
    return colorArray[groupId % 10]
}


export default groupColorPicker

