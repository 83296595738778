import EventTypes from 'constants/EventTypes'
import EventTracker from 'eventTracker'
import {CLEAR_RECEIPT} from "../constants/ActionTypes";

export function loadCompanyNotificationReceipts(aSearchString, aUserId, aGroupId, aPage, aPerPage, aExcludeCleared, aSearchPreferences){
  let query = `
    query ($query: String, $company_id: ID!, $user_id: ID, $group_id: ID, $page: Int, $per_page: Int, $exclude_cleared: Boolean, $alert_type: String){
        company_notification_receipt_page(
          query: $query,
          company_id: $company_id,
          user_id: $user_id,
          group_id: $group_id,
          page: $page,
          per_page: $per_page,
          exclude_cleared: $exclude_cleared,
          alert_type: $alert_type){
            company_notification_receipts {
              id
              message
              created_at
              cleared_at
              is_cleared
              target_id
              target_type
              notes
              user {
                id
                first_name
                last_name
              }
              cleared_by_user {
                first_name
                last_name
              }
              alert_type
              survey_id
              survey_name
              survey_response_id
            }
          total
        }
      }
    `;

  let variables = {
    query: aSearchString,
    user_id: aUserId,
    group_id: aGroupId,
    page: aPage,
    per_page: aPerPage,
    exclude_cleared: aExcludeCleared,
    order_by_ascending: true,
    alert_type: aSearchPreferences.alert_type && aSearchPreferences.alert_type.length > 0 ? aSearchPreferences.alert_type : null
  };
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", {query, variables});
  };
}


export function clearCompanyNotificationReceipt({company_notification_receipt_id, note}){
  EventTracker.track(EventTypes.CLEAR_COMPANY_NOTIFICATION_RECEIPT);
  let query = `
  mutation ($clearCompanyNotificationReceiptInput: ClearCompanyNotificationReceiptInput!){
        clearCompanyNotificationReceipt(input: $clearCompanyNotificationReceiptInput){
          company_notification_receipt {
            id
            message
            cleared_at
            is_cleared
            notes
            user {
              first_name
              last_name
              id
              groups {
                id
              }
            }
            cleared_by_user {
              first_name
              last_name
            }
          }
        }
      }
  `;
  let variables = {
    clearCompanyNotificationReceiptInput: {
      company_notification_receipt_id: company_notification_receipt_id,
      notes: note
    }
  };
  let operationName = `ClearCompanyNotificationReceipt`;

  return (dispatch) => {
    return global.api.post("/queries",
                           {query, variables, operationName}
    ).then((result) => {
      let clearedReceipt = result.data.data.clearCompanyNotificationReceipt.company_notification_receipt;
      dispatch({
        type: CLEAR_RECEIPT,
        result: clearedReceipt
      })
    });
  };
}
