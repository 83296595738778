import React from 'react';
import update from 'immutability-helper'
import ToolBlock from '../ToolBlock/ToolBlock'
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Translation} from "react-i18next";
import ConfirmationModal from "../../../../Modals/ConfirmationModal/ConfirmationModal";
import i18next from "i18next";
import i18n from "../../../../i18n";

const blockTypes = {
  'TextObjective': {label: 'TEXT_INPUT', icon: 'compose',
    default: {type: 'TextObjective', description: '', numbers_only: false}
  },
  'DateObjective': {label: 'DATE_INPUT', icon: 'calendar'},
  'TimeObjective': {label: 'TIME_INPUT', icon: 'ios7-time-outline'},
  'ImageObjective': {label: 'UPLOAD_IMAGE', icon: 'image'},
  'SliderObjective': {label: 'SLIDER_INPUT', icon: 'android-mixer',
    default: {type: 'SliderObjective', description: '', minimum_value: 1, maximum_value: 10, minimum_value_string: 'Worst', maximum_value_string: 'Best'}
  },
  'RoutineObjective': {label: 'ROUTINE_INPUT', icon: 'clipboard'},
  'MultipleChoiceObjective': {label: 'MULTIPLE_CHOICE_QUESTION', icon: 'clipboard',
    default: {type: 'MultipleChoiceObjective', description: '', multiselect: false, answers: [{answer_text: ''}]}
  },
  'ContentObjective': {label: 'PROVIDER_CONTENT'},
  'ConsentObjective': {label: 'E_DASH_CONSENT',
    default: {type: 'ConsentObjective',
      description: '',
      include_consenting_name: false,
      consenting_name_required: false,
      include_consenting_signature: false,
      consenting_signature_required: false,
      include_consent_check: false,
      consent_check_required: false,
    }
  }
};

export default class ToolBlocks extends React.Component {

  constructor(props) {
    super(props);
    
    this.state ={
      selectedToolBlock: null,
      menuAnchorElement:null,
      selectedToolIndex:0,
      confirmationModalOpen:false
    }
    
    this.updateToolBlocks = this.updateToolBlocks.bind(this);
    this.addBlock = this.addBlock.bind(this);
    this.moveBlock = this.moveBlock.bind(this);
    this.removeBlock = this.removeBlock.bind(this);
    this.updateBlock = this.updateBlock.bind(this);
    this.resetBlock = this.resetBlock.bind(this);
    this.resetBlock = this.resetBlock.bind(this);
    this.duplicateBlock = this.duplicateBlock.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
  }

  updateToolBlocks(updatedBlocks) {
    let orderedBlocks = updatedBlocks.map((block, i) => {
      block.order = i;
      return block;
    });
    this.props.updateTool('objectives', orderedBlocks);
  }

  addBlock() {
    let updatedBlocks = [ ...this.props.blocks, {type: "", translations: this.createObjectiveTranslations()}]
    this.updateToolBlocks(updatedBlocks)
  }

  duplicateBlock(block, index) {
    let newBlock = Object.assign({},block,{id: null, order: null});
    let updatedBlocks = [ ...this.props.blocks.slice(0,index+1), newBlock, ...this.props.blocks.slice(index+1) ];
    this.updateToolBlocks(updatedBlocks);
    this.setState({menuAnchorElement:null});
    this.closeMenu();
  }

  resetBlock(type, index) {
    let resetBlock = (blockTypes[type] && blockTypes[type].default) || {type: type, description: ""}
    resetBlock.translations = this.createObjectiveTranslations();

    if(type === 'MultipleChoiceObjective'){
      resetBlock.answers = [{answer_text: '', translations: this.createAnswerTranslations()}];
    }
    let updatedBlocks = [ ...this.props.blocks.slice(0,index), resetBlock, ...this.props.blocks.slice(index+1) ]
    this.updateToolBlocks(updatedBlocks)
  }

  removeBlock(index) {
    let updatedBlocks = this.props.blocks;
    
    if(updatedBlocks[index].id){
      this.props.updateTool('removeObjectiveIds', [...this.props.tool.removeObjectiveIds || [], updatedBlocks[index].id])
    }
    updatedBlocks.splice(index, 1);
    this.updateToolBlocks(updatedBlocks);
    this.setState({menuAnchorElement:null});
  }

  updateBlock(updateObject, index) {
    let updatedBlock = Object.assign({}, this.props.blocks[index], updateObject)
    let updatedBlocks = [ ...this.props.blocks.slice(0,index), updatedBlock, ...this.props.blocks.slice(index+1) ]
    this.updateToolBlocks(updatedBlocks)
  }

  moveBlock(dragIndex, hoverIndex) {
    let { blocks } = this.props
    const dragBlock = blocks[dragIndex]
    let updatedBlocks = update(blocks,
      {$splice: [[dragIndex, 1], [hoverIndex, 0, dragBlock]]}
    )
    this.updateToolBlocks(updatedBlocks)
  }

  createObjectiveTranslations() {
    return this.props.tool.translations.map(translation=>{
      return {
        locale: translation.locale,
        description: '',
        minimum_value_string: '',
        maximum_value_string: '',
        consent_button_title: '',
        consent_check_text: ''
      }
    })
  }

  createAnswerTranslations(){
    return this.props.tool.translations.map(translation=>{
      return {
        locale: translation.locale,
        answer_text: ''
      }
    })
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedToolBlock:null, selectedToolIndex:0});
  }
  
  menuButtonClicked(aEvent, aToolBlock, aIndex){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedToolBlock:aToolBlock, selectedToolIndex:aIndex});
  };

  render() {
    const {blocks, selectedTranslationIndex} = this.props;
    const {selectedToolIndex, confirmationModalOpen} = this.state;
    
    let blockList = blocks.map((block, i) => {
      return (
        <ToolBlock key={i}
                   index={i}
                   block={block}
                   moveBlock={this.moveBlock}
                   removeBlock={this.removeBlock}
                   updateBlock={this.updateBlock}
                   resetBlock={this.resetBlock}
                   blockTypes={blockTypes}
                   duplicateBlock={this.duplicateBlock}
                   selectedTranslationIndex={selectedTranslationIndex}
                   menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, block, i)}
        />
      )
    });

    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
          {blockList}
        </div>
  
        <Menu id="simple-menu"
              anchorEl={this.state.menuAnchorElement}
              keepMounted
              open={Boolean(this.state.menuAnchorElement)}
              onClose={() => this.closeMenu()}>
  
          <MenuItem style={{position: 'relative'}} onClick={() => {this.setState({confirmationModalOpen:true})}}>
            <Translation>{ (t) => t('REMOVE') }</Translation>
          </MenuItem>
          
          <MenuItem style={{position: 'relative'}}  onClick={() => this.duplicateBlock(this.state.selectedToolBlock, this.state.selectedToolIndex)} >
              <Translation>{ (t) => t('DUPLICATE') }</Translation>
          </MenuItem>
        </Menu>
  
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('BLOCK', {i18n})})}
                           isOpen={confirmationModalOpen}
                           reject={() => this.setState({confirmationModalOpen:false})}
                           confirm={() => this.removeBlock(selectedToolIndex)}
        />
      </div>
    )
  }
}
