import React from "react";
import './SurveyBuilderDynamicListQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";

export default class SurveyBuilderDynamicListQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      searchString:'',
      selectedValue:null,
      dynamic_list_name:'',
      dynamic_list_name_ca:'',
      dynamic_list_name_de:'',
      dynamic_list_name_es:'',
      dynamic_list_name_fi:'',
      dynamic_list_name_fr:'',
      dynamic_list_name_it:'',
      dynamic_list_reference:'',
      dynamic_list_reference_ca:'',
      dynamic_list_reference_de:'',
      dynamic_list_reference_es:'',
      dynamic_list_reference_fi:'',
      dynamic_list_reference_fr:'',
      dynamic_list_reference_it:''
    };
    if(props.question){
      const {question, fetchDynamicListByReference, fetchDynamicListById} = props;
      let listKey = KeyForTranslation('dynamic_list_reference', props.selectedLanguage);
      let listIdKey = KeyForTranslation('dynamic_list_id', props.selectedLanguage);

      if(question[listKey] && fetchDynamicListByReference){
        fetchDynamicListByReference(question[listKey]).then((newResult) => {
          let list = newResult.data.data.dynamic_list;
          this.setState({selectedValue:list});
        });
      }
      else if(question[listIdKey] && fetchDynamicListById){
        fetchDynamicListById(question[listIdKey].toString()).then((newResult) => {
          let list = newResult.data.data.dynamic_list;
          this.setState({selectedValue:list});
        });
      }
    }
  }
  
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, fetchDynamicLists, errorForIdTypeAndKey} = this.props;
    const {selectedValue} = this.state;
  
    const dynamicListKey = KeyForTranslation('dynamic_list_reference', selectedLanguage);
    
    return(
      <div className="survey-builder-dynamic-list-question-cell">
        <LabeledDropdown className="survey-builder-dynamic-list-question-input"
                         label={<Translation>{ (t, { i18n }) => t('DYNAMIC_LIST_REFERENCE') }</Translation>}
                         value={selectedValue}
                         infoBlurb={<Translation>{(t, { i18n }) => t('DYNAMIC_LIST_REFERENCE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           let updatedQuestion = cloneDeep(question);
                           updatedQuestion[dynamicListKey] = aOption.reference;
                           updateQuestion(updatedQuestion);
                           this.setState({selectedValue:aOption});
                         }}
                         errorMessage={errorForIdTypeAndKey(question.id, 'error', dynamicListKey).message}
                         fetchObjects={(aLimit, aOffset, aSearchString) => fetchDynamicLists(aLimit, aOffset, aSearchString)}
                         warningMessage={errorForIdTypeAndKey(question.id, 'warning', dynamicListKey).message}
                         labelPropertiesArray={['name']}
                         valuePropertiesArray={['reference']}
                         responseTotalKeysArray={['data', 'data', 'dynamic_list_page', 'total']}
                         responseObjectKeysArray={['data', 'data', 'dynamic_list_page', 'dynamic_lists']}
        />
      </div>
    )
  }
}
