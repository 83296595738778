import React from 'react';
import "antd/dist/antd.css";
import './Article.scss';
import {Translation} from "react-i18next";
import moment from "moment";
import SurveyComponent from "../../containers/SurveyContainer";
import ArticleCell from "../../Cells/ArticleCell/ArticleCell";
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export default class Article extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      selectedArticle:null,
      relatedArticlesArray:[],
      totalRelatedArticles:0
    };
    this.getRelatedArticles = this.getRelatedArticles.bind(this);
  };
  
  componentDidMount(){
    this.getRelatedArticles();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.article !== this.props.article){
      this.setState({selectedArticle:null, relatedArticlesArray:[], totalRelatedArticles:0}, () => {
        this.getRelatedArticles();
      });
    }
  }
  
  // Methods
  
  getRelatedArticles(){
    if(this.props.getRelatedArticles){
      let currentArticle = this.props.article;
  
      if(this.state.selectedArticle){
        currentArticle = this.state.selectedArticle;
      }
      this.props.getRelatedArticles(currentArticle.id).then(newResponse => {
        let data = newResponse.data.data.articles;
        let relatedArticlesArray = data.articles;
        let totalRelatedArticles = data.total;
        this.setState({relatedArticlesArray:relatedArticlesArray, totalRelatedArticles:totalRelatedArticles});
      });
    }
  }
  
  // Render
  
  render() {
    const { selectedArticle, relatedArticlesArray, totalRelatedArticles } = this.state;
    const { user, article, company, className, onFinish } = this.props;
    
    let currentArticle = article;
    
    if(selectedArticle){
      currentArticle = selectedArticle;
    }
    return (
      <div className={"article- " + className}>
        <div className="article-close-button" onClick={onFinish} style={{cursor: 'pointer'}}>
          <CloseOutlinedIcon style={{fontSize: '21px', color: '2D81C9'}} onClick={onFinish}/>
        </div>
    
        <div className="article-header">
          <img className="article-source-logo"
               src={currentArticle.source.logo ? currentArticle.source.logo : '/imgs/app/hsc-logo.png'}
               alt={<Translation>{(t, {i18n}) => t('SPONSORED_LOGO_IMAGE')}</Translation>}
          />
          <div className="article-source">
            {currentArticle.source.name}
          </div>
          {currentArticle.sponsored ?
            <span>
              <span className="article-sponsored-divider">
                •
              </span>
              <span className="article-sponsored">
                <Translation>{(t, {i18n}) => t('SPONSORED')}</Translation>
              </span>
            </span>
            :
            null
          }
        </div>
        
        <span className="article-title-info-cell">
          {currentArticle.completed_on ?
            <span className="article-title-progress-completed">
              <CheckIcon style={{fontSize: '15px', color: 'white'}}/>
            </span>
            :
            null
          }
          <span className="article-title">
            {currentArticle.title}
          </span>
        </span>
        
        <div className="article-created-at">
          {moment(currentArticle.created_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
        </div>
        
        <span className="article-footer">
          <span className="article-views-icon">
           <VisibilityOutlinedIcon style={{fontSize: '19px'}}/>
          </span>
          <span className="article-view-count">
            {currentArticle.views}
          </span>
          {currentArticle.survey_has_questions ?
            <>
              <span className="article-survey_has_questions-icon">
                <AssignmentOutlinedIcon style={{fontSize: '19px'}}/>
              </span>
              <span className="article-survey_has_questions">
                <Translation>{(t, {i18n}) => t('QUESTIONS_INCLUDED')}</Translation>
              </span>
            </>
            :
            null
          }
        </span>
        
        {currentArticle.image ?
          <img className="article-image"
               src={currentArticle.image}
               alt={<Translation>{(t, {i18n}) => t('ARTICLE_IMAGE')}</Translation>}
          />
          :
          null
        }
        
        <div className="article-survey-container">
          <SurveyComponent surveyId={currentArticle.survey_id}
                           userId={user.id}
                           adminId={user.id}
                           company={company}
                           editable={currentArticle.survey_response_id ? true : false}
                           onFinish={onFinish}
                           disableScroll={true}
                           surveyResponse={currentArticle.survey_response_id}/>
        </div>
  
        <div className="article-related-articles-container">
          {relatedArticlesArray && relatedArticlesArray.length > 0 ?
            <div>
              <div style={{marginLeft: '5px'}}>
                <Translation>{(t, {i18n}) => t('RELATED_ARTICLES')}</Translation> - {totalRelatedArticles}
              </div>
              <div className="article-related-articles-cell-container">
                {relatedArticlesArray.map((aArticle, aIndex) => (
                  <ArticleCell article={aArticle}
                               className="article-list-cell"
                               key={'article-article-cell-' + aArticle.id + aIndex}
                               onClick={() => this.setState({selectedArticle:aArticle}, () => {
                                 this.getRelatedArticles();
                               })}
                  />
                ))}
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
