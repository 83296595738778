import React from "react";
import './CallModal.scss';
import '../../Modals/AssignSymptomsModal/AssignSymptomsModal.scss';
import i18next from "i18next";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";

const {Device} = require('twilio-client');

export default class CallModal extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      muted:false,
      notes:'',
      ready:false,
      callSid:null,
      onPhone:false,
      connection:null,
      callFinished:false,
      phoneNumberFrom:props.phoneNumberFrom,
      phoneNumberToCall:props.phoneNumberToCall
    };
    this.resetState = this.resetState.bind(this);
    this.initiateCall = this.initiateCall.bind(this);
    this.handleEndCall = this.handleEndCall.bind(this);
    this.handleToggleMute = this.handleToggleMute.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {isOpen} = this.props;
    
    if(prevProps.isOpen !== isOpen){
      this.resetState();
      
      if(isOpen){
        this.initiateCall();
      }
    }
  }
  
  // Methods
  
  initiateCall(){
    const {adminId, patientId} = this.props;
    
    let self = this;
    this.device = new Device();
    
    global.api.post('/api/telehealth/twilio_phone_call/token', {admin_id:adminId, patient_id:patientId}).then((newResult) => {
      let token = newResult.data.token;
      self.device.setup(token, {enableRingingState:true});
      // NOTE: These are currently not needed when saving a Call Summary. This is left in case it is needed in the future.
      // self.setState({signedPatientId:newResult.data.participant_id, signedRoomId:newResult.data.room_id});
      
      self.device.on('connect',(connection) => {
        if(self.props == null || !self.props.isOpen){
          self.device.disconnectAll();
        }
        else if(self.device != null && self.device.activeConnection() != null){
          self.setState({callSid:self.device.activeConnection().parameters.CallSid, onPhone:true});
        }
      });
      
      self.device.on('ready', () => {
        self.setState({ready:true, muted:false});
        self.device.connect({to:self.state.phoneNumberToCall, from:self.state.phoneNumberFrom})
      });
      
      self.device.on('cancel', () => {
        self.setState({onPhone:false});
      });
      
      self.device.on('disconnect', () => {
        self.setState({onPhone:false, callFinished:true});
      });
    }, (newError) => {
      this.props.closeModal(null, null);
    });
  }
  
  resetState(){
    this.setState({muted:false, notes:'', ready:false, callSid:null, onPhone:false, connection:null,
      callFinished:false, phoneNumberFrom:this.props.phoneNumberFrom, phoneNumberToCall:this.props.phoneNumberToCall});
  }
  
  handleToggleMute(){
    if(this.device.status() === 'busy'){
      let muted = !this.state.muted;
      this.setState({muted:muted});
      this.device.activeConnection().mute(muted);
    }
  }
  
  handleEndCall(){
    const {closeModal} = this.props;
    const {notes, callSid} = this.state;
    this.device.destroy();
    
    if(closeModal){
      closeModal(notes, callSid);
    }
  }
  
  // Render
  
  render(){
    const {isOpen} = this.props;
    const {muted, notes, ready, onPhone, callFinished, phoneNumberToCall} = this.state;
    
    return (
      <GeneralModal title={i18next.t('CALL') + ': ' + phoneNumberToCall}
                    isOpen={isOpen}
                    isEditing={false}
                    handleSave={() => this.handleEndCall()}
                    noTitleHeading={true}
                    showSaveButton={false}
                    handleCloseModal={() => this.handleEndCall()}
                    showCancelButton={false}
                    clickOutsideToClose={true}>
        <div style={{marginBottom:'10px'}}>
          {!onPhone && !ready ?
            <div className="modal-subtitle-text">
              {i18next.t('LOADING_WITH_ELLIPSIS')}
            </div>
            :
            null
          }
          
          {onPhone ?
            <div className="modal-subtitle-text">
              {i18next.t('ON_CALL')}
            </div>
            :
            null
          }
          
          {muted ?
            <div className="modal-subtitle-text">
              {i18next.t('YOU_ARE_MUTED')}
            </div>
            :
            null
          }
        </div>
        
        <LabeledTextarea className="modal-input-textarea"
                         label={i18next.t('NOTES')}
                         value={notes}
                         handleSave={(aEvent) => this.setState({notes:aEvent.target.value})}
                         placeholder={i18next.t('ENTER_NOTES_HERE')}
        />
        
        {onPhone ?
          <div className="modal-body-button-container">
            <GrayButton name={i18next.t('MUTE')}
                        onClick={this.handleToggleMute}
            />
            
            <BlueButton className="modal-body-call-button"
                        name={i18next.t('HANG_UP')}
                        onClick={() => this.device.disconnectAll()}
            />
          </div>
          :
          null
        }
        
        {callFinished ?
          <div className="modal-body-button-container">
            <BlueButton className="modal-body-call-button"
                        name={i18next.t('FINISH')}
                        onClick={() => this.handleEndCall()}
            />
          </div>
          :
          null
        }
      </GeneralModal>
    )
  }
}
