import React from "react";
import Modal from 'react-modal';
import './GeneralModal.scss';
import i18next from "i18next";
import {Translation} from "react-i18next";
import RedButton from "../../Custom UI/Buttons/RedButton/RedButton";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import Loading from "../../Custom UI/Loading/Loading";
import LabeledErrors from "../../Custom UI/LabeledInputs/LabeledErrors/LabeledErrors";
import CloseButton from "../../Custom UI/Buttons/CloseButton/CloseButton";

export default class GeneralModal extends React.Component{
  
  // Render
  
  // TODO: Refactor noTitleHeading (and any other prop not used).
  
  render(){
    const {errorMessage, warningMessage, title, description, remove, isOpen, handleSave, handleCloseModal, isEditing, noTitleHeading,
      showSaveButton, showSaveButtonOnRight, saveButtonTitle, clickOutsideToClose = true, showCancelButton = true,
      children, width, height, maxWidth, maxHeight, showFooter = true, showHeader = true, ariaHideApp = false,
      loading = false, childrenHeight, maxChildrenHeight, containsDropDown, contentLabel = 'Modal', removeButtonTitle = i18next.t('DELETE'),
      presentedInsideParentModal = false, minHeight} = this.props;

    let defaultWidth = 500;
    let defaultHeight = 500;
    let defaultMinHeight = 185;
    let headerHeightPlusFooterHeight = 0;
    
    if(showFooter){
      if(showHeader){
        headerHeightPlusFooterHeight = 70 + 65;
      }
      else{
        headerHeightPlusFooterHeight = 65;
      }
    }
    else if(showHeader){
      headerHeightPlusFooterHeight = 70;
    }
    let errorHeight = 0;
    
    if(errorMessage || warningMessage){
      errorHeight = 40;
      headerHeightPlusFooterHeight = headerHeightPlusFooterHeight + errorHeight;
    }
    let defaultBodyHeight = defaultHeight - headerHeightPlusFooterHeight;
    
    const saveButton =  (<BlueButton className="modal-button"
                                     name={saveButtonTitle ?
                                       saveButtonTitle
                                       :
                                       <Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>
                                     }
                                     onClick={handleSave}
    />);
    
    return (
      <Modal isOpen={isOpen}
             contentLabel={contentLabel}
             ariaHideApp={ariaHideApp}
             onRequestClose={clickOutsideToClose ? handleCloseModal : null}
             style={{
               overlay:{
                 zIndex:(presentedInsideParentModal ? 100001 : 8888),
                 background:'rgba(0, 0, 0, 0.5)'
               },
               content:{
                 top:'50%',
                 left:'50%',
                 color:'#000000',
                 width:width ? width : (defaultWidth.toString() + 'px'),
                 border:0,
                 bottom:'auto',
                 height:height ? height : 'auto',
                 zIndex:9009,
                 padding:'0',
                 maxWidth:maxWidth ? (maxWidth.toString() + 'px') : (defaultWidth.toString() + 'px'),
                 minWidth:'350px',
                 overflow:containsDropDown ? 'visible' : 'hidden',
                 position:'absolute',
                 maxHeight:maxHeight ? (maxHeight.toString() + 'px') : (defaultHeight.toString() + 'px'),
                 minHeight:minHeight ? (minHeight.toString() + 'px') : (defaultMinHeight.toString() + 'px'),
                 transform:'translate(-50%,-50%)',
                 background:'white',
                 borderRadius:'10px',
                 paddingBottom:(!showFooter || (!showCancelButton && !showSaveButton)) ? '0px' : '70px'
               }
             }}>
        {showHeader ?
          <div className="modal-title-cell">
            <div className="modal-title">
              {!noTitleHeading ?
                isEditing ?
                  <div style={{marginRight:'5px'}}>
                    <Translation>{(t, {i18n}) => t('EDIT_SPACE')}</Translation>
                  </div>
                  :
                  <div style={{marginRight:'5px'}}>
                    <Translation>{(t, {i18n}) => t('ADD_SPACE')}</Translation>
                  </div>
                :
                null
              }
              
              {title ?
                <div>
                  {title}
                </div>
                :
                null
              }
              
              {description ?
                <div className='general-modal-description-text'>
                  {description}
                </div>
                :
                null
              }
            </div>
            
            <CloseButton onClick={handleCloseModal}/>
          </div>
          :
          null
        }
        
        <LabeledErrors className="general-modal-errors"
                       errorMessage={errorMessage}
                       warningMessage={warningMessage}
        />
        
        <div className="modal-body"
             style={{
               top:'50px',
               height:childrenHeight ? childrenHeight : (height ? height : 'calc(100% - ' + headerHeightPlusFooterHeight + 'px)'),
               maxWidth:maxWidth ? ((maxWidth).toString() + 'px') : (defaultWidth.toString() + 'px'),
               overflowY:containsDropDown ? 'visible' : 'auto',
               maxHeight:maxChildrenHeight ? maxChildrenHeight : (maxHeight ? ((maxHeight - headerHeightPlusFooterHeight).toString() + 'px') : (defaultBodyHeight.toString() + 'px'))
             }}>
          {children}
        </div>

        {showFooter ?
          <div className="modal-button-container">
            {loading ?
              <Loading className={'modal-loading'}/>
              :
              <>
                {showSaveButton && !showSaveButtonOnRight ?
                  saveButton
                  :
                  null
                }
  
                {remove ?
                  <RedButton className="general-modal-button"
                             name={removeButtonTitle}
                             onClick={remove}
                  />
                  :
                  null
                }
  
                {showCancelButton ?
                  <GrayButton className="modal-button"
                              name={<Translation>{(t, {i18n}) => t('CANCEL')}</Translation>}
                              onClick={handleCloseModal}
                  />
                  :
                  null
                }
  
                {showSaveButton && showSaveButtonOnRight ?
                  saveButton
                  :
                  null
                }
              </>
            }
          </div>
          :
          null
        }
      </Modal>
    )
  }
}
