import actions from 'actions';
import {connect} from 'react-redux';
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";

const mapStateToProps = (state) => {
  return {
    user:state.current && state.current.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword:({email}) => {
      return dispatch(actions.forgotPassword({email}));
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ForgotPassword);
