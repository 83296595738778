import React from 'react';
import BlockReminders from '../../../../Custom UI/BlockReminders/BlockReminders'
import { DEFAULT_COLORS } from 'constants/Colors'
import {Translation} from "react-i18next";
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import i18next from "i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {BiDotsVerticalRounded} from "react-icons/bi";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const settingsGear = '/imgs/app/settings-gear.png';

export default class BlockAdvancedSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newObjectivePoint: {
        entry_value: "",
        points: ""
      },
      menuAnchorElement: null,
      selectedIndex: null
    };
    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleEntryValue = this.handleEntryValue.bind(this)
    this.handlePoints = this.handlePoints.bind(this)
    this.addObjectivePoint = this.addObjectivePoint.bind(this)
    this.removeObjectivePoint = this.removeObjectivePoint.bind(this)
    this.openEntryMenu = this.openEntryMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  toggleOpen() {
    this.setState({open: !this.state.open})
  }

  handleEntryValue(value, name) {
    const key = name
    let newObjectivePoint = {...this.state.newObjectivePoint}
    newObjectivePoint[key] = value
    newObjectivePoint.operator = '=='
    this.setState({newObjectivePoint})
  }

  handlePoints(e) {
    const key = e.target.name
    const value = e.target.value
    let newObjectivePoint = {...this.state.newObjectivePoint}
    newObjectivePoint[key] = parseInt(value)
    this.setState({newObjectivePoint})
  }

  addObjectivePoint() {
    let updatedObjectivePoints = [...this.props.block.objective_points || [], this.state.newObjectivePoint]
    this.props.updateBlock({objective_points: updatedObjectivePoints}, this.props.index)
    this.setState({newObjectivePoint: {entry_value: "", points: ""}})
  }

  removeObjectivePoint(index) {
    let updatedObjectivePoints = this.props.block.objective_points
    let removeObjectivePointId = updatedObjectivePoints[index].id
    updatedObjectivePoints.splice(index, 1)
    let updateObject = {
      objective_points: updatedObjectivePoints
    }
    if (removeObjectivePointId) {
      updateObject.removeObjectivePointIds = [...this.props.block.removeObjectivePointIds || [], removeObjectivePointId]
    }
    this.props.updateBlock(updateObject, this.props.index);
    this.closeMenu();
  }

  openEntryMenu(aEvent, aIndex){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedIndex:aIndex})
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedIndex:null});
  }

  render() {
    const { open, newObjectivePoint } = this.state
    const { block, block: {objective_points, reminders}, index } = this.props

    const ifEntryIsOptions = [
      {label: i18next.t('SELECT_OPTION'), value: ''},
      {label: i18next.t('COMPLETED'), value: '0.0'},
      {label: i18next.t('MISSED'), value: '1.0'}
    ]

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', backgroundColor: open ? '#f6f6f6' : '#ffffff', color: DEFAULT_COLORS.PANEL_GRAY, fontSize: '12px', border: 'solid 1px #e6e6e6', borderRadius: open ? 0 : '0 0 5px 5px', borderTop: 0, paddingLeft: 10, height: 30, alignItems: 'center'}} className='clickable' onClick={this.toggleOpen}>
          <div>
            <img style={{width: '12px', height: '12px', marginRight: '5px'}} src={settingsGear} alt='settings-gear' />
          </div>
          <div style={{flex: 1}}>
            <Translation>{ (t, { i18n }) => t('ADVANCED_SETTINGS') }</Translation>
          </div>
          <div>
            <i className={open ? 'ion-chevron-up' : 'ion-chevron-down'} style={{marginRight: '5px'}}/>
          </div>
        </div>

        {this.state.open &&
            <div style={{display: 'flex', flexDirection: 'column', border: 'solid 1px #e6e6e6', borderRadius: '0 0 5px 5px', borderTop: '0', padding: '10px'}}>

              <div>
                <Translation>{ (t, { i18n }) => t('POINT_SETTINGS') }</Translation>
              </div>

              <div style={{display: 'flex', marginTop: '0.5em', justifyContent: 'center'}}>
                <Translation>{ (t, { i18n }) => t('IF_ENTRY_IS') }</Translation>
                <LabeledDropDown className="block-reminders-dropdown"
                                 value={newObjectivePoint.entry_value && ifEntryIsOptions.filter(option => {
                                   return newObjectivePoint.entry_value === option.value
                                 })}
                                 handleSave={(aOption) => this.handleEntryValue(aOption.value, 'entry_value')}
                                 options={ifEntryIsOptions}
                />
                <Translation>{ (t, { i18n }) => t('THEN_GIVE') }</Translation>
                <input style={{marginLeft: 20, marginRight: 20, maxWidth: '5rem'}} type="number" value={newObjectivePoint.points} name="points" onChange={this.handlePoints} />
                pts.
                <div style={{marginLeft: 'auto'}} className='clickable add-remove-buttons-outer-container'
                  onClick={this.addObjectivePoint}>
                  <AddCircleOutlineIcon style={{height: '20px', width: '20px', color: '#2D81C9'}}/>
                </div>
              </div>

              <div style={{marginTop: '0.5em'}}>
                {objective_points && objective_points.map((objective_point, index) => {
                  return (
                    <div key={index} style={{display: 'flex'}} className='objective-rule-item'>
                      <div>
                        &bull;&nbsp;<Translation>{ (t, { i18n }) => t('IF_ENTRY_IS') }</Translation> {objective_point && parseFloat(objective_point.entry_value) === 0.0 ? 'Completed' : 'Missed'} <Translation>{ (t, { i18n }) => t('THEN_GIVE') }</Translation> {objective_point.points} pts
                      </div>
                      <div style={{marginLeft: 'auto'}}>
                        <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                               aria-controls="simple-menu"
                                               aria-haspopup="true"
                                               onClick={(aEvent) => this.openEntryMenu(aEvent, index)}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div style={{marginTop: '1em'}}>
                <Translation>{ (t, { i18n }) => t('REMINDERS') }</Translation>
              </div>

              <div style={{marginTop: '0.5em'}}>
                <BlockReminders
                  block={block}
                  reminders={reminders}
                  index={index}
                  updateBlock={this.props.updateBlock}
                />
              </div>

          </div>
        }

        <Menu id="simple-menu"
              anchorEl={this.state.menuAnchorElement}
              keepMounted
              open={Boolean(this.state.menuAnchorElement)}
              onClose={() => this.closeMenu()}>
          <MenuItem style={{position: 'relative'}} onClick={() => this.removeObjectivePoint(this.state.selectedIndex)}>
            <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
          </MenuItem>
        </Menu>

      </div>
    )
  }
}
