import React from "react";
import {Translation, withTranslation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import TabBar from "../../Custom UI/TabBar/TabBar";
import i18next from "i18next";
import './ManageCampaigns.scss';
import fileDownload from 'js-file-download'
import parse from 'html-react-parser';
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import Loading from "../../Custom UI/Loading/Loading";
import CampaignListItem from '../../Cells/CampaignCell/CampaignCell'
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import { findIndex, uniq } from 'lodash'
import ReorderList from "../../Lists/ReorderList/ReorderList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {currentDateHumanizedWithTimeStamp} from "../../Helpers/DateHelpers";

const notifyAddCampaignSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('CAMPAIGN_ADDED_SUCCESSFULLY') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCampaignFailure = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('ADD_CAMPAIGN_ERROR', {error: aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportFailed = () => toast("😅 Whoops something went wrong, please try again", NOTIFY_OPTS.default);

class ManageCampaigns extends React.Component {

  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit, aQuery) => this.getCompanyCampaigns(limit, offset, aQuery),
    success: () => this.updateList(),
    fail: (error) => console.error("Error", error)
  });

  constructor(props) {
    super(props);
    this.state = {
      totalPages: 1,
      currentPage: 1,
      currentPagesCampaigns: [],
      selectedCampaign: null,
      isOpen:false,
      tabBarKey:'ALL_CAMPAIGNS',
      checkboxCheckedState: false,
      surveyList: [],
      reorderSurvey: false,
      surveyIds: [],
      campaignQRCode: null,
      newCampaign: {
        title: '',
        description: '',
        utmSource: '',
        utmContent: '',
        utmCampaign: '',
        utmMedium: ''
      }
    };
    
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.viewCampaignQRCode = this.viewCampaignQRCode.bind(this);
    this.openModal = this.openModal.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addCampaign = this.addCampaign.bind(this);
  }

  // Methods
  
  componentDidMount(){
    this.scrollManager.fetch(true);
    this.props.getSurveys().then(result => {
      this.setState({surveyList: result?.data?.data?.surveys});
    });
  }

  updateList(){
    this.setState({currentPagesCampaigns:this.scrollManager.getList()});
  }

  getCompanyCampaigns(aLimit, aOffset, aQuery){
    return this.props.getCompanyCampaigns(aLimit, aOffset, aQuery).then(newResponse => {
      let returnValue = [];
        let data = newResponse.data.data.company_campaigns.company_campaigns;
        let totalDataQueries = newResponse.data.data.company_campaigns.total;
        this.setState({totalPages:totalDataQueries});

        if(data){
          returnValue = data;
        }
      return {objects:returnValue};
    });
  }

  addCampaign(){
      let title = this.state.newCampaign.title
      let description = this.state.newCampaign.description
      let utmSource = this.state.newCampaign.utmSource
      let utmContent = this.state.newCampaign.utmContent
      let utmCampaign = this.state.newCampaign.utmCampaign
      let utmMedium = this.state.newCampaign.utmMedium
      this.props.addCompanyCampaign({title, description, utmSource, utmContent, utmCampaign, utmMedium}).then(result => {
        let error = result?.data?.error;

        if(error){
          this.displayErrorMessage(error);
        }
        else{
          notifyAddCampaignSuccess();
          this.scrollManager.fetch(true);
          this.setState({
            newCampaign: {
              title: '',
              description: '',
              utmSource: '',
              utmContent: '',
              utmCampaign: '',
              utmMedium: ''
            }
          }
          );
        }
      }).catch(error => {
        this.displayErrorMessage(error);
      });
    
  }

  displayErrorMessage(aError){
    let errorMessage = i18next.t('UNKNOWN_ERROR');

    if(aError && aError.data && aError.data.error){
      errorMessage = aError.data.error;
    }
    notifyCampaignFailure(errorMessage);
  }


  handleChange(aEvent) {
    let value = aEvent.target.value;
    let updatedNewCampaign = Object.assign(this.state.newCampaign, {
      [aEvent.target.name]: value
    });
    this.setState({ newCampaign: updatedNewCampaign });
  }

  editCampaign = () => {
    this.props.editCampaign(this.state.selectedCampaign);
  }

  menuButtonClicked(aEvent, aCampaign){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedCampaign:aCampaign, campaignQRCode: aCampaign.qrcode});
  }

  handleCloseModal() {
    this.setState({isOpen: false, campaignQRCode: null});
  }

  viewCampaignQRCode = () => {
    this.openModal();
  }

  openModal() {
    this.setState({isOpen: true});
    this.closeMenu();
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedCampaign:null});
  }

  removeCampaign = () => {
    let userId = this.props.patient.id
    this.props.removeNote({
      noteId: this.state.selectedPatientNote.id,
      userId: userId
    }).then((result=> {
      this.props.fetchPatient({userId: this.props.patient.id})
    }))
    this.closeMenu();
  }

  downloadCampaignsList = () => {
    global.api.get(`/api/companies/${this.props.company.id}/export_campaigns_list`)
    .then((result) => {
      fileDownload(result.data, `campaign_list_${currentDateHumanizedWithTimeStamp()}.csv`);
    })
    .catch((error) => notifyReportFailed());
  };

  downloadAnalyticsReport = () => {
    let reportParams = {
      survey_ids: [...this.state.surveyIds].map((surveyId) => surveyId.id),
    }
    global.api.post(`/api/companies/${this.props.company.id}/export_campaign_analyics`, reportParams)
    .then((result) => {
      fileDownload(result.data, `analytics_report_${currentDateHumanizedWithTimeStamp()}.csv`);
    })
    .catch((error) => notifyReportFailed());
  };

  downloadQrCode = () => {
    let blob = new Blob([this.state.selectedCampaign.qrcode], {type: "image/svg+xml"});
    fileDownload(blob, `qr_code.svg`);
    this.closeMenu();
  }

  
  collectSurveyIds = (aEvent, aName, aId) => {
    let ids = [...this.state.surveyIds];
    let obj = { id:aId, text:aName };
    
    if(parseInt(obj.id) === 0){
      ids = this.checkUncheckAll(aEvent.target.checked)
    }
    else{
      if(aEvent.target.checked){
        ids.push(obj);
      }
      else{
        const index = findIndex(ids, obj);
        
        if(index !== -1){
          ids.splice(index, 1);
        }
      }
      this.modifyCheck(aEvent.target.checked, obj.id)
    }
    this.setState({surveyIds: ids})
  };
  
  modifyCheck = (flag, survey_id) => {
    let obj = { ...this.state.checkboxCheckedState };
    delete obj["0"];
    obj[survey_id] = flag;
    
    if(Object.values(obj).length === this.state.surveyList.length) {
      if (uniq(Object.values(obj)).length === 1){
        obj["0"] = uniq(Object.values(obj))[0]
      }
      else{
        obj["0"] = true
      }
    }
    let objs = { ...obj }
    this.setState({checkboxCheckedState: objs });
  };
  
  checkUncheckAll = (flag) => {
    let obj = { "0": flag };
    let surveyObjCollection = [];
    this.state.surveyList.forEach((survey) => {
      obj[survey.id] = flag;
      
      if(flag){
        let surveyObj = {
          id: survey.id,
          text: survey.name
        };
        surveyObjCollection.push({ ...surveyObj })
      }
    });
    let objs = {...obj}
    this.setState({checkboxCheckedState: objs });
    return surveyObjCollection;
  };

  setSurveyIds = (val) =>{
    this.setState({surveyIds: val})
  }

  setReorderingStatus = (status) => {
    if(status === true){
      this.downloadAnalyticsReport()
    }
    this.setState({reorderSurvey: false})
  }

  setReorderSurvey = (val) => {
    this.setState({reorderSurvey: false})
  }
  
  showReorderList= () => {
    this.setState({reorderSurvey: true})
  }
  // Render

  render() {
    const {tabBarKey, newCampaign, isOpen, checkboxCheckedState, surveyList, surveyIds, showLoading, reorderSurvey, campaignQRCode} = this.state;

    let currentRole = roleForCompany(this.props.company);

    const updatePermissions = ["admin", "admin_level_4", "admin_level_11"];
    const viewPermissions = ["admin", "admin_level_4", "admin_level_11"];

    let tabBarTabsArray = [];

    if(viewPermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'ALL_CAMPAIGNS'});
    }
    if(updatePermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'ADD_CAMPAIGN'});
    }
    if(viewPermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'ANALYTICS_REPORT'});
    }

    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('CAMPAIGNS') }</Translation>}/>
  
        <TabBar tabBarTabsArray={tabBarTabsArray}
                initialSelectedTabKey={'ALL_CAMPAIGNS'}
                selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
        />

        {tabBarKey === 'ALL_CAMPAIGNS' ?
          <div className="manage-campaigns-container">
            <div className="manage-campaigns-button-container">
              <BlueButton name={<Translation>{ (t, { i18n }) => t('CAMPAIGN_LIST') }</Translation>}
                          onClick={this.downloadCampaignsList}
              />
            </div>
            <div className='scrollbar-container scrollbar-data-scroll manage-patient-scroll-container'
                  style={{height: "800px!important"}}
                  id='admin-histories-list-notes'>
              <InfiniteScroll style={{overflowY: "hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="admin-histories-list-notes">
                {this.state.currentPagesCampaigns && this.state.currentPagesCampaigns.map((campaign, index) => {
                    return (
                      <div key={index} className='list-of-things'>
                        <div key={campaign.id} style={{marginRight: '20px', marginLeft: '20px', width: 'calc(100% - 40px)'}}>
                          <CampaignListItem
                            key={campaign.id}
                            data={campaign}
                            removeFunc={this.removeCompanyCampaign}
                            campaignId={campaign.id}
                            editCampaign={this.editCampaign}
                            menuButtonClicked={(aEvent, data) => this.menuButtonClicked(aEvent, data)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </InfiniteScroll>
              <Menu id="simple-menu"
                  anchorEl={this.state.menuAnchorElement}
                  keepMounted
                  open={Boolean(this.state.menuAnchorElement)}
                  onClose={() => this.closeMenu()}>
              <MenuItem style={{position: 'relative'}} onClick={() => {this.viewCampaignQRCode()}}>
                  <Translation>{ (t, { i18n }) => t('VIEW')}</Translation>
              </MenuItem>
              <MenuItem style={{position: 'relative'}} onClick={() => {this.downloadQrCode()}}>
                <Translation>{(t, {i18n}) => t('DOWNLOAD_QR_CODE')}</Translation>
              </MenuItem>
            </Menu>
            
            {campaignQRCode ?
              <GeneralModal title={<Translation>{ (t, { i18n }) => t('QR_CODE') }</Translation>}
                            isOpen={isOpen}
                            maxHeight={600}
                            noTitleHeading={true}
                            handleCloseModal={this.handleCloseModal}>
                <div style={{textAlign: 'center'}}>
                  {parse(campaignQRCode)}
                </div>
              </GeneralModal>
              :
              null
            }
            </div>
            
          </div>
          :
          null
        }

        {tabBarKey === 'ADD_CAMPAIGN' ?
          <div className="manage-patient-container-invite">
            <div className="manage-patient-container-text">
              <Translation>{ (t, { i18n }) => t('ADD_CAMPAIGN') }</Translation>
            </div>
            <br/>
       
            <div>
              <div className="manage-patient-invite-input-container">
                <LabeledTextInput className="manage-patient-invite-input campaign-title"
                                  label={<Translation>{ (t, { i18n }) => t('TITLE') }</Translation>}
                                  placeholder={i18next.t('TITLE')}
                                  name="title"
                                  value={newCampaign.title}
                                  handleSave={this.handleChange}
                />
                <LabeledTextInput className="manage-patient-invite-input campaign-description"
                                  label={<Translation>{ (t, { i18n }) => t('DESCRIPTION') }</Translation>}
                                  placeholder={i18next.t('DESCRIPTION')}
                                  name="description"
                                  value={newCampaign.description}
                                  handleSave={this.handleChange}
                />
              </div>
              <div className="manage-patient-invite-input-container">
                <LabeledTextInput className="manage-patient-invite-input campaign-utm-source"
                                  label={<Translation>{ (t, { i18n }) => t('UTM_SOURCE') }</Translation>}
                                  placeholder={i18next.t('UTM_SOURCE')}
                                  value={newCampaign.utmSource}
                                  handleSave={this.handleChange}
                                  name="utmSource"

                />
                <LabeledTextInput className="manage-patient-invite-input utm-campaign"
                                  label={<Translation>{ (t, { i18n }) => t('UTM_CAMPAIGN') }</Translation>}
                                  placeholder={i18next.t('UTM_CAMPAIGN')}
                                  value={newCampaign.utmCampaign}
                                  handleSave={this.handleChange}
                                  name="utmCampaign"
                />
              </div>
              <div className="manage-patient-invite-input-container">
                <LabeledTextInput className="manage-patient-invite-input campaign-utm-content"
                                  label={<Translation>{ (t, { i18n }) => t('UTM_CONTENT') }</Translation>}
                                  placeholder={i18next.t('UTM_CONTENT')}
                                  value={newCampaign.utmContent}
                                  handleSave={this.handleChange}
                                  name="utmContent"

                />
                <LabeledTextInput className="manage-patient-invite-input campaign-utm-medium"
                                  label={<Translation>{ (t, { i18n }) => t('UTM_MEDIUM') }</Translation>}
                                  placeholder={i18next.t('UTM_MEDIUM')}
                                  value={newCampaign.utmMedium}
                                  handleSave={this.handleChange}
                                  name="utmMedium"
                />
              </div>
            </div>

            <div className="manage-patient-button-container">
              <BlueButton name={<Translation>{ (t, { i18n }) => t('SUBMIT_PROPER_CAPITALIZED') }</Translation>}
                          onClick={this.addCampaign}
              />
            </div>

          </div>
          :
          null
        }

        {tabBarKey === 'ANALYTICS_REPORT' ?
          <>
            <div className="manage-reports-download-container">
              {reorderSurvey && (
                <ReorderList surveyIds={surveyIds}
                            setSurveyIds={this.setSurveyIds}
                            setReorderingStatus={this.setReorderingStatus}
                            surveyReportType={{}}
                            setReorderSurvey={this.setReorderSurvey}
                />
              )
              }
              <div style={{marginLeft : 20}} className="manage-patient-container-text">
                <Translation>{ (t, { i18n }) => t('DOWNLOAD_COMPLETED_SURVEY_DATA') }</Translation>
              </div>
              <br/>
              
              <div className="manage-reports-cells-container">
                <div className="manage-reports-survey-cell-download">
                  <div className="manage-reports-download-surveys-container">
                    <div className="group-user-title-text margin-left-0">
                      <Translation>{(t, {i18n}) => t('SELECT_QUESTIONNAIRES')}</Translation>
                      <div className="extra-padding-top"/>
                      <label className={'custom-input-cell'} key={`select-all-cell`}>
                        <input type={"checkbox"}
                              key={`select-all-input`}
                              name={`select-all^&@0`}
                              checked={checkboxCheckedState["0"]}
                              onChange={(aEvent) => this.collectSurveyIds(aEvent, 'select-all', 0)}
                        />
                        <span key={`select-all-checkmark`} className={'custom-checkmark'}/>
                        <div key={`select-all-name`} className={'inline-text'}>{checkboxCheckedState["0"] ?
                          <Translation>{(t, {i18n}) => t('UNSELECT_ALL')}</Translation> :
                          <Translation>{(t, {i18n}) => t('SELECT_ALL')}</Translation>}</div>
                      </label>
                    </div>
                    
                    {surveyList.map((survey) =>
                      (
                        <div className="manage-reports-download-cell"  key={`${survey.id}-container`}>
                          <label className={'custom-input-cell'} key={`${survey.id}-cell`}>
                            <input type={"checkbox"}
                                  key={`${survey.id}-input`}
                                  name={`${survey.name}^&@${survey.id}`}
                                  checked={checkboxCheckedState[`${survey.id}`]}
                                  onChange={(aEvent) => this.collectSurveyIds(aEvent, survey.name, survey.id)}
                            />
                            <span key={`${survey.id}-checkmark`} className={'custom-checkmark'}/>
                            <div key={`${survey.id}-name`} className={'inline-text'}>{survey.name}</div>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                  {(surveyIds.length > 0) && (showLoading ?
                    <Loading/>
                    :
                    <div className="manage-reports-buttons-container">
                      <div className="manage-buttons-container auto-width">
                        <BlueButton className='download-report-button'
                          name={<Translation>{ (t, { i18n }) => t('REORDER') }</Translation>}
                          onClick={this.showReorderList}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
          :
          null
        }
      </div>
    );
  }
}

export default withTranslation()(ManageCampaigns)
