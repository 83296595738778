import React from "react";
import {Route, Redirect} from 'react-router-dom';

const ProtectedRoute = ({component:Component, studyClosed, exact, path}) => {
  return (
    <Route path={path}
           exact={exact}
           render={(props) => {
             if(studyClosed){
               return <Redirect to="/manage/reports" />;
             }
             return <Component {...props} />;
           }}
    />
  );
};

export default ProtectedRoute;