import React from 'react';
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class MedicationAllergyCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false
    }
  }

  showRemove() {
    this.setState({showRemove: true})
  }

  hideRemove() {
    this.setState({showRemove: false})
  }

  render() {
    const {data, menuButtonClicked} = this.props
    return (
      <div style={{width: '100%', marginBottom: '10px'}} className="list-item" onMouseLeave={() => this.hideRemove()} onMouseEnter={() => this.showRemove()} >
        { data && 
        <div key={data && data.id} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          
          <div className='list-allergy-item-name'>
            <div><p>{data.name}</p></div>
            <div className="list-allergy-severity-item-name" style={{textTransform: 'capitalize'}} >{data.severity}</div>
          </div>
          
          <div>
            <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
            />
          </div>
          
        </div> }
      </div>
    )
  }
}
