import EventTracker from "../eventTracker";
import EventTypes from "../constants/EventTypes";

export function getSurveys(){
  EventTracker.track(EventTypes.GET_SURVEYS);
  
  let query = `
    query getSurveys($company_id: ID!){
      surveys(company_id: $company_id){
        id
        name
        reference
        is_repeatable
        has_consent_question
        has_sensitive_data_question
      }
    }
  `;
  let variables = {company_id: ''};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", { query, variables });
  };
}
