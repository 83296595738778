import React from 'react'
import {Translation} from "react-i18next";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import i18next from "i18next";
import DynamicList from "../../../Lists/DynamicList/DynamicList";
import './PatientNotes.scss';
import {fullDateTimeFormat} from "../../../Helpers/DateHelpers";
import AddPatientNoteModal from "../../../Modals/AddPatientNoteModal/AddPatientNoteModal";
import {errorMessageFromServerError} from "../../../Helpers/Helpers";
import {formatUserName} from "../../../Helpers/AdminHelpers";

export default class PatientNotes extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      noteToAdd:null,
      errorMessage:'',
      noteToDelete:null,
      shouldReload:false,
      selectedNote:null,
      noteToReplace:null,
      confirmationModalOpen:false,
      showAddPatientNoteModal:false
    };
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.patient?.id !== this.props.patient?.id){
      this.setState({shouldReload:true});
    }
  }
  
  // Methods
  
  addNote = ({description}) => {
    const {patient, addNote} = this.props;
    this.setState({errorMessage:''});
    
    if(patient && addNote){
      addNote({userId:patient.id, description:description}).then((newResult) => {
        if(newResult && newResult.error){
          this.setState({errorMessage:newResult.error, selectedNote:null, showAddPatientNoteModal:false});
        }
        else{
          this.setState({errorMessage:'', selectedNote:null, noteToAdd:newResult.data.data.addPatientNote.note, showAddPatientNoteModal:false});
        }
      }, (newError) => {
        this.setState({errorMessage:errorMessageFromServerError(newError), selectedNote:null, showAddPatientNoteModal:false});
      });
    }
  };
  
  removeNote = () => {
    const {patient, removeNote} = this.props;
    const {selectedNote} = this.state;
    
    if(patient && removeNote && selectedNote){
      removeNote({noteId:selectedNote.id, userId:patient.id}).then((newResult) => {
        this.setState({selectedNote:null, noteToDelete:newResult.data.data.removePatientNote.note, confirmationModalOpen:false});
      }, (newError) => {
        this.setState({errorMessage:errorMessageFromServerError(newError), selectedNote:null, confirmationModalOpen:false});
      })
    }
  };
  
  updateNote = ({noteId, description}) => {
    const {patient, updateNote} = this.props;
    this.setState({errorMessage:''});
    
    if(patient && updateNote){
      updateNote({userId:patient.id, noteId:noteId, description:description}).then((newResult) => {
        if(newResult && newResult.error){
          this.setState({errorMessage:newResult.error, selectedNote:null, showAddPatientNoteModal:false});
        }
        else{
          this.setState({errorMessage:'', selectedNote:null, noteToReplace:newResult.data.data.updatePatientNote.note, showAddPatientNoteModal:false});
        }
      }, (newError) => {
        this.setState({errorMessage:errorMessageFromServerError(newError), selectedNote:null, showAddPatientNoteModal:false});
      });
    }
  };
  
  // Render
  
  render() {
    const {company, patient, getNotes} = this.props;
    const {errorMessage, shouldReload, noteToAdd, noteToDelete, selectedNote, noteToReplace, confirmationModalOpen,
      showAddPatientNoteModal} = this.state;
    const notesProhibitions = ['admin_level_1', 'admin_level_8', 'admin_level_9'];
    const currentRole = roleForCompany(company);
    
    let columnsArray = [];
    columnsArray.push({key:'provider_name', columnWeight:1.5, columnNameKey:'PROVIDER', propertiesArray:['provider_name']});
    columnsArray.push({key:'description', columnWeight:1.5, columnNameKey:'DESCRIPTION', propertiesArray:['description']});
    columnsArray.push({key:'updated_at', columnWeight:1.5, columnNameKey:'LAST_UPDATED', propertiesArray:['updated_at'], dateFormat:fullDateTimeFormat});
    columnsArray.push({key:'created_at', columnWeight:1.5, columnNameKey:'CREATED', propertiesArray:['created_at'], dateFormat:fullDateTimeFormat});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'EDIT', clickAction:(aNote) => this.setState({selectedNote:aNote, showAddPatientNoteModal:true})});
    menuItemsArray.push({title:'REMOVE', clickAction:(aNote) => this.setState({selectedNote:aNote, confirmationModalOpen:true})});
    
    return (
      <div key={'patient-notes-' + patient && patient.id}>
        {!notesProhibitions.includes(currentRole) ?
          <div data-testid='add-note-button'>
            <div className='patient-notes-list'
                 id='patient-notes-list-container'>
              <DynamicList id="patient-notes-list"
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={() => this.setState({selectedNote:null})}
                           objectToAdd={noteToAdd}
                           columnsArray={columnsArray}
                           fetchObjects={(aLimit, aOffset) => getNotes(patient && patient.id, aLimit, aOffset)}
                           shouldReload={shouldReload}
                           showSearchBar={false}
                           menuItemsArray={menuItemsArray}
                           minColumnWidth={100}
                           objectToReplace={noteToReplace}
                           objectToDelete={noteToDelete}
                           addButtonClicked={() => this.setState({errorMessage:'', selectedNote:null, showAddPatientNoteModal:true})}
                           responseTotalKeysArray={['data', 'data', 'notes', 'total']}
                           responseObjectKeysArray={['data', 'data', 'notes', 'patient_notes']}
                           finishedAddingObject={() => this.setState({noteToAdd:null})}
                           finishedDeletingObject={() => this.setState({noteToDelete:null})}
                           finishedReplacingObject={() => this.setState({noteToReplace:null})}
              />
            </div>
            
            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('NOTE')})}
                               isOpen={confirmationModalOpen}
                               reject={() => this.setState({confirmationModalOpen:false})}
                               confirm={this.removeNote}
            />
  
            <AddPatientNoteModal name={formatUserName(patient)}
                                 close={() => this.setState({errorMessage:'', selectedNote:null, showAddPatientNoteModal:false})}
                                 isOpen={showAddPatientNoteModal}
                                 noteId={selectedNote && selectedNote.id}
                                 addNote={this.addNote}
                                 updateNote={this.updateNote}
                                 description={selectedNote ? selectedNote.description : null}
                                 errorMessage={errorMessage}
            />
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('PATIENT_NOTES_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )}
}
