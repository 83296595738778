import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {FETCH_PATIENT_FEATURES, FETCH_GROUP_FEATURES} from "../constants/ActionTypes";
import {loadCompany} from "./company";

export function updateCompanyHSFeature(aFeatureId, aEnabled){
  EventTracker.track(EventTypes.UPDATE_COMPANY_HS_FEATURE);
  let query = `
    mutation UpdateFeatureToCompanyMutation($updateFeatureToCompanyInput: UpdateFeatureToCompanyMutationInput!) {
      updateFeatureToCompany(input: $updateFeatureToCompanyInput) {
        company_hs_feature {
          enabled
        }
      }
    }
  `;
  let variables = {
    updateFeatureToCompanyInput:{
      enabled:aEnabled,
      hs_feature_id:aFeatureId
    }
  };
  let operationName = `UpdateFeatureToCompany`
  
  return (dispatch, getState) => {
    let state = getState();
    variables['updateFeatureToCompanyInput']['company_id'] = state.company && state.company.company && state.company.company.id;
    let promise = global.api.post("/queries", {query, variables, operationName});
    promise.then((newResult) => dispatch(loadCompany()));
    return promise;
  }
}

export function updateFeatureToUser(aUserId, aFeatureId, aEnabled){
  EventTracker.track(EventTypes.UPDATE_USER_HS_FEATURE);
  let query = `
    mutation UpdateFeatureToUser($updateFeatureToUserInput: UpdateFeatureToUserInput!) {
      updateFeatureToUser(input: $updateFeatureToUserInput) {
        user_hs_feature {
          enabled
        }
      }
    }
  `;
  let variables = {
    updateFeatureToUserInput:{
      enabled:aEnabled,
      user_id:aUserId,
      hs_feature_id:aFeatureId
    }
  }
  let operationName = `UpdateFeatureToUser`

  return (dispatch) => {
    return global.api.post("/queries", {query, variables, operationName}).then((newResult) => {
      const checked = (newResult && newResult.data && newResult.data.data && newResult.data.data.updateFeatureToUser
        && newResult.data.data.updateFeatureToUser.user_hs_feature.enabled);
      dispatch(fetchPatientFeatures(aFeatureId, checked));
      }, (newError) => {
      return {error:newError && newError.data && newError.data.error}
    })
  }
}

function fetchPatientFeatures(aFeatureId, aFeatureEnabled){
  EventTracker.track(EventTypes.FETCH_USER_HS_FEATURE);
  return dispatch => {
    dispatch({
      type:FETCH_PATIENT_FEATURES,
      result:{
        featureId:aFeatureId,
        featureEnabled:aFeatureEnabled
      }
    });
  };
}

export function updateFeatureToGroup(aGroupId, aFeatureId, aEnabled){
  EventTracker.track(EventTypes.UPDATE_GROUP_HS_FEATURE);
  let query = `
  mutation UpdateFeatureToGroup($updateFeatureToGroupInput: UpdateFeatureToGroupInput!) {
    updateFeatureToGroup(input: $updateFeatureToGroupInput) {
      group_hs_feature {
        enabled
        group_id
        hs_feature_id
      }
    }
  }
  `;
  let variables = {
    updateFeatureToGroupInput:{
      enabled:aEnabled,
      group_id:aGroupId,
      hs_feature_id:aFeatureId
    }
  }
  let operationName = `UpdateFeatureToGroup`;

  return (dispatch) => {
    return global.api.post("/queries", {query, variables, operationName}).then((result) => {
      const groupHsFeature = (result && result.data && result.data.data && result.data.data.updateFeatureToGroup
      && result.data.data.updateFeatureToGroup.group_hs_feature) || {};
      dispatch(fetchGroupFeatures(groupHsFeature.group_id,groupHsFeature.hs_feature_id,groupHsFeature.enabled))})
    .catch((error) => {
      return {error: error.data && error.data.error}
    })
  }
}

function fetchGroupFeatures(aGroupId, aFeatureId, aFeatureEnabled){
  EventTracker.track(EventTypes.FETCH_GROUP_HS_FEATURE);
  return dispatch => {
    dispatch({
      type:FETCH_GROUP_FEATURES,
      result:{
        groupId:aGroupId,
        featureId:aFeatureId,
        featureEnabled:aFeatureEnabled
      }
    });
  };
}
