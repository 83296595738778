import React from 'react';
import './UserPermissionCell.scss';
import 'antd/dist/antd.css';
import {Translation} from "react-i18next";

export default class UserPermissionCell extends React.Component {
  
  render() {
    const { title, description, value, checked, onChange } = this.props;
  
    return (
      <div className="user-permission-cell"
           onClick={() => onChange(value)}>
        <input className="user-permission-button"
               type='radio'
               name='role'
               value={value}
               checked={checked}
               readOnly={true}
        />
        
        <div className="user-permission-cell-text">
          <b><Translation>{ (t, { i18n }) => t(title) }</Translation></b><br/>
          
          <Translation>{ (t, { i18n }) => t(description) }</Translation>
        </div>
      </div>
    )
  }
}
