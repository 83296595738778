import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {BiCog} from "react-icons/bi";
import './PatientPanelSubSectionHeadingWithButton.scss'

const title = (aHeading, aTotal) => {
  let returnValue = aHeading;
  
  if(aTotal){
    returnValue += ' - ' + aTotal;
  }
  return returnValue;
};

const PanelSubSectionHeadingWithButton = ({addFunc, total, heading, className, addButtonAsGear = false, readyToAddItem = true}) => (
  <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px', marginBottom: '10px', marginTop: '20px'}}>
    <div className={'section-headings ' + (className ? className : '')}>
      {title(heading, total)}
    </div>
    {addFunc ?
      (addButtonAsGear ?
          <BiCog style={{ height:'20px', width:'20px', color:'#2D81C9' }}
                 onClick={addFunc}/>
          :
          <AddCircleOutlineIcon style={{ height:'20px', width:'20px' }}
                                className={`${readyToAddItem ? 'colorPrimary': 'colorDisabled'} add-new-call-note-button`}
                                onClick={readyToAddItem ? addFunc : ''}/>
      )
      :
      null
    }
  </div>
);

export default PanelSubSectionHeadingWithButton;
