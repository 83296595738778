import React, {useState} from 'react';
import './AddSurveyModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

const AddSurveyModal = ({open, save, close, excludedSurveys, presentedInsideParentModal, fetchSurveyVersionCollections}) => {
  
  // Instance Variables
  
  const [survey, setSurvey] = useState(null);
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               save={() => save(survey)}
    //               close={close}
    //               width={'400px'}
    //               title={i18next.t('ADD_SURVEY')}
    //               height={'230px'}
    //               saveButtonTitle={i18next.t('SELECT')}
    //               containsDropDown={true}
    //               presentedInsideParentModal={presentedInsideParentModal}>
    <GeneralModal title={i18next.t('ADD_SURVEY')}
                  width={'400px'}
                  height={'260px'}
                  isOpen={open}
                  maxWidth={400}
                  maxHeight={260}
                  handleSave={() => {
                    if(survey){
                      save(survey);
                      setSurvey(null);
                    }
                  }}
                  noTitleHeading={true}
                  showSaveButton={true}
                  saveButtonTitle={i18next.t('SELECT')}
                  containsDropDown={true}
                  handleCloseModal={() => {
                    close();
                    setSurvey(null);
                  }}
                  maxChildrenHeight={210}
                  presentedInsideParentModal={presentedInsideParentModal}>
      <LabeledDropdown className="add-survey-modal-survey-dropdown"
                       placeholder={i18next.t('SEARCH_SURVEYS')}
                       value={survey}
                       handleSave={(aSurvey) => setSurvey(aSurvey)}
                       fetchObjects={(aLimit, aOffset, aSearchString) => fetchSurveyVersionCollections(aLimit, aOffset, aSearchString)}
                       labelPropertiesArray={['name']}
                       valuePropertiesArray={['id']}
                       optionsArrayFormatter={(aArray) => {
                         let optionsArray = [];
    
                         for(let surveyVersionCollection of aArray){
                           let addSurvey = true;
                           
                           for(let excludedSurvey of excludedSurveys){
                             if(surveyVersionCollection.id === excludedSurvey.id){
                               addSurvey = false;
                               break;
                             }
                           }
                           if(addSurvey){
                             optionsArray.push({label:surveyVersionCollection.name, value:surveyVersionCollection.id, key:surveyVersionCollection.id});
                           }
                         }
                         return optionsArray;
                       }}
                       responseTotalKeysArray={['data', 'data', 'survey_version_collections', 'total']}
                       responseObjectKeysArray={['data', 'data', 'survey_version_collections', 'survey_version_collections']}
      />
    </GeneralModal>
  );
};

export default AddSurveyModal
