import React from "react";
import './SurveyBuilderSurveySettings.scss';
import i18next from "i18next";
import CloseIcon from '@material-ui/icons/Close';
import WhiteButton from "../../../Custom UI/Buttons/WhiteButton/WhiteButton";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import SurveyBuilderSurveyRepeat from "./SurveyBuilderSurveyRepeat/SurveyBuilderSurveyRepeat";
import SurveyBuilderQuestionVisibility from "../SurveyBuilderQuestionSettings/SurveyBuilderQuestionVisibility/SurveyBuilderQuestionVisibility";

export default class SurveyBuilderSurveySettings extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      confirmationModalOpen:false
    };
  }
  
  // Render
  
  render(){
    const {close, survey, updateSurvey, deleteSurvey, questionsArray, errorForIdTypeAndKey, surveysArray, fetchAppAdditionalColumns} = this.props;
    const {confirmationModalOpen} = this.state;
    
    return(
      <div className="survey-builder-survey-settings-drawer">
        <div className="survey-builder-survey-settings-header">
          <div className="survey-builder-survey-settings-header-text">
            <div>
              {i18next.t('SETTINGS')}
            </div>
      
            <div>
              {survey.survey_reference}
            </div>
          </div>
    
          <CloseIcon className="survey-builder-survey-settings-drawer-close"
                     onClick={() => close()}
          />
        </div>
        
        <div className="survey-builder-survey-settings-drawer-position">
          <div className="survey-builder-survey-settings-control">
            <SurveyBuilderQuestionVisibility question={survey}
                                             surveysArray={surveysArray}
                                             questionsArray={questionsArray}
                                             updateQuestion={updateSurvey}
                                             errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                             fetchAppAdditionalColumns={fetchAppAdditionalColumns}
            />
          </div>
          
          <div className="survey-builder-survey-settings-control">
            <SurveyBuilderSurveyRepeat survey={survey}
                                       updateSurvey={updateSurvey}
                                       questionsArray={questionsArray}
                                       errorForIdTypeAndKey={errorForIdTypeAndKey}
            />
          </div>
  
          <div className="survey-builder-survey-settings-button-container">
            <WhiteButton className="create-survey-drawer-button"
                         name={i18next.t('DELETE_SURVEY')}
                         onClick={() => deleteSurvey(survey)}
            />
          </div>
          
          <div className="survey-builder-survey-settings-empty-cell"/>
  
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(survey.survey_reference && survey.survey_reference.length > 0 ? survey.survey_reference : i18next.t('SURVEY'))})}
                             isOpen={confirmationModalOpen}
                             reject={() => this.setState({confirmationModalOpen:false})}
                             confirm={() => deleteSurvey(survey)}
          />
        </div>
      </div>
    )
  }
}
