import React from 'react';
import '../../Custom UI/common.scss'

export default class MoreLess extends React.Component{

  componentWillMount() {
    this.setState({
      isOpen: false
    })
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getRenderedItems(description) {
    if (this.state.isOpen) {
      return {__html: unescape(description)};
    }
    return {__html: (description).slice(0, 100)};
  }

  render() {
    const { description } = this.props
    console.log("nitin", description)
    if(description.length > 100){
      return(
        <div>
           <div dangerouslySetInnerHTML={this.getRenderedItems(description)} />
          <a className="more-less" href="#" onClick={this.toggle}>
            {this.state.isOpen ? 'Show less' : 'Show more'}
            </a>
        </div>
      );
    }else{
      return(
        <div dangerouslySetInnerHTML= {{__html: unescape(description)}} />
        );
    }
  }
}
