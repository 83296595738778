import { connect } from 'react-redux';
import actions from 'actions';
import ManageCampaigns from 'Components/ManageCampaigns/ManageCampaigns'

const mapStateToProps = (state, ownProps)=> {
  let company = state.company && state.company.company;
  let error = state.current && state.current.user && state.current.user.error
  return {
    company,
    error,
    path: ownProps.match.path
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateCompanyCampign: ({id, title, description}) => {
      return dispatch(actions.removeUserFromCompany(id, title, description))
    },
    addCompanyCampaign: ({title, description, utmSource, utmCampaign, utmMedium, utmContent}) => {
      return dispatch(actions.addCompanyCampaign({title, description, utmSource, utmCampaign, utmMedium, utmContent}))
    },
    getCompanyCampaigns:(query, limit, offset) => {
      return dispatch(actions.getCompanyCampaigns(query, limit, offset))
    },
    getSurveys:() => {
      return dispatch(actions.getSurveys());
    },
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ManageCampaigns)
