import React from 'react';
import "antd/dist/antd.css";
import './SurveyDrawer.scss';
import {Translation} from "react-i18next";
import SurveyComponent from "../../containers/SurveyContainer";
import SurveyResponses from "../SurveyResponses/SurveyResponses";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Drawer} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {SurveyLockedState} from "../../actions/surveyActions";

export default class SurveyDrawer extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      selectedSurveyResponse:null
    };
  };
  
  // Methods
  
  
  
  // Render
  
  render() {
    const { selectedSurveyResponse } = this.state;
    const { userId, adminId, company, onClose, surveyId, addDataQuery, fetchPatient, onFinishedSurvey, surveyResponseId,
      getSurveyResponses, surveyResponseDetailId, defaultValuesForQuestions, updateSurveyResponseLockState, open = false,
      title = '', anchor = 'right', editable = true, isTestResponse = false, showPreviousButton = true,
      showSurveyResponses = false, showLockOption = true } = this.props;
    
    if(open){
      if(!userId || !adminId || !company || !onClose || !surveyId || !onFinishedSurvey){
        console.log('SurveyDrawer (render): Missing at least 1 required prop. userId:', userId, 'adminId:', adminId, 'company:', company, 'onClose:', onClose, 'surveyId:', surveyId, 'onFinishedSurvey:', onFinishedSurvey);
      }
      if(showSurveyResponses){
        if(!fetchPatient || !getSurveyResponses || !updateSurveyResponseLockState){
          console.log('SurveyDrawer (render): Missing at least 1 required prop when showing responses. fetchPatient:', fetchPatient, 'getSurveyResponses:', getSurveyResponses, 'updateSurveyResponseLockState:', updateSurveyResponseLockState);
        }
      }
    }
    
    return (
      <Drawer style={{zIndex:99999}}
              open={open}
              anchor={anchor}
              onClose={() => {
                this.setState({selectedSurveyResponse:null});
                onClose();
              }}>
        {showSurveyResponses && !selectedSurveyResponse && surveyId ?
          <SurveyResponses title={title}
                           company={company}
                           surveyId={surveyId}
                           patientId={userId}
                           fetchPatient={fetchPatient}
                           onCloseDrawer={() => {
                             this.setState({selectedSurveyResponse:null});
                             onClose();
                            }}
                           showLockOption={showLockOption}
                           getSurveyResponses={getSurveyResponses}
                           selectSurveyResponse={(aSurveyResponse) => this.setState({selectedSurveyResponse:aSurveyResponse})}
                           updateSurveyResponseLockState={updateSurveyResponseLockState}
          />
          :
          <>
            <div className="survey-drawer-title-container">
              <div className="survey-drawer-title-cell">
                {selectedSurveyResponse ?
                  <ArrowBackIcon onClick={() => this.setState({selectedSurveyResponse:null})}/>
                  :
                  null
                }
                
                <div className="survey-drawer-title-text">
                  <div>
                    {title}
                  </div>
                  
                  <div>
                    <div>
                      {selectedSurveyResponse ?
                        <Translation>{ (t, { i18n }) => t('VIEWING_SURVEY_RESPONSE', {aSurveyResponseId:selectedSurveyResponse.id})}</Translation>
                        :
                        <Translation>{ (t, { i18n }) => t('MAKE_AN_ENTRY_TEXT')}</Translation>
                      }
                    </div>
                  </div>
                </div>
              </div>
              
              <CloseIcon onClick={() => {
                this.setState({selectedSurveyResponse:null});
                onClose();
              }}/>
            </div>
            
            {surveyId && surveyId !== 0 ?
              <div className='survey-drawer-survey-component-container'>
                <SurveyComponent userId={userId}
                                 adminId={adminId}
                                 company={company}
                                 editable={editable && (!selectedSurveyResponse || (selectedSurveyResponse.lock_state !== SurveyLockedState.Locked))}
                                 surveyId={selectedSurveyResponse && selectedSurveyResponse.survey ? selectedSurveyResponse.survey.id : surveyId}
                                 onFinish={onFinishedSurvey}
                                 addDataQuery={addDataQuery ? (aSurveyResponseDetailsId) => this.setState({selectedSurveyResponse:null}, () => addDataQuery(aSurveyResponseDetailsId)) : null}
                                 isTestResponse={isTestResponse}
                                 surveyResponseId={selectedSurveyResponse ? selectedSurveyResponse.id : surveyResponseId}
                                 showPreviousButton={showPreviousButton}
                                 surveyResponseDetailId={surveyResponseDetailId}
                                 defaultValuesForQuestions={defaultValuesForQuestions}
                />
              </div>
              :
              null
            }
          </>
        }
      </Drawer>
    )
  }
}
