import React from "react";
import Slider from "rc-slider/lib/Slider";
import {Translation} from "react-i18next";

export default class SurveyMultiSliderQuestionCell extends React.Component {
  
  // Init

  constructor(props){
    super(props);
    this.state = {
      values: {},
      defaultValues: {},
      hideSlider: []
    };

    if(props.answer != null && props.answer.prettyValues != null){
      props.answer.prettyValues.forEach(value => {
        let key = Object.keys(value)[0];
        let defaultHide = value[key] < 0;
        this.state.hideSlider.push(defaultHide);

        if(value[key] == null){
          this.state.defaultValues[key] = null;
        }
        else {
          this.state.values[key] = value[key];
          this.state.defaultValues[key] = value[key];
        }
      });
    }
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.hideMultiSliderAnswer = this.hideMultiSliderAnswer.bind(this);
  }
  
  // Methods
  
  handleSliderChange(key, value){
    let values = this.state.values;
    values[key] = value;
    this.setState({values: values});
    this.props.onAnswerChange(this.props.question, null, values);
  }

  hideMultiSliderAnswer(value, index, minValue){
    let hideSliderArray = this.state.hideSlider;
    let values = this.state.values;
    hideSliderArray[index] = !hideSliderArray[index];

    if(hideSliderArray[index]){
      values[value] = -1;
    }
    else{
      values[value] = minValue;
    }
    this.setState({values: values, hideSlider: hideSliderArray});
    this.props.onAnswerChange(this.props.question, null, values);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {values, hideSlider, defaultValues} = this.state;
    const {key, type, choices} = question;
    
    const maxLabel = question.max_label;
    const minLabel = question.min_label;
    let maxValue = question.max_value;
    let minValue = question.min_value;
  
    if(maxValue === null || maxValue === undefined){
      maxValue = 5;
      console.log('SurveyMultiSliderQuestionCell (render): No maxValue set.');
    }
    if(minValue === null || minValue === undefined){
      minValue = 1;
      console.log('SurveyMultiSliderQuestionCell (render): No minValue set.');
    }
    
    let marks = {};
    
    if(maxLabel === null || maxLabel === undefined){
      marks[maxValue] = maxValue.toString();
    }
    else{
      marks[maxValue] = maxValue + ' (' + maxLabel + ')';
    }
    if(minLabel === null || minLabel === undefined){
      marks[minValue] = minValue.toString();
    }
    else{
      marks[minValue] = minValue + ' (' + minLabel + ')';
    }
    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr />
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_MULTISLIDER_INSTRUCTION_TEXT',
            {minValue: minValue, minLabel: minLabel, maxValue: maxValue, maxLabel: maxLabel})}</Translation>
        </em>
        
        <hr />
        
        {choices && choices.length > 0 &&
          choices.map((choice, index) => {
            return (
              <div key={index} style={{marginTop: '50px', marginBottom: '50px', width: '100%'}}>
                {choice[1]}
                
                {!hideSlider[index] &&
                  <div className='slider-with-bottom-value'>
                    <Slider className='entry-slider'
                            min={parseInt(minValue)}
                            max={parseInt(maxValue)}
                            step={1}
                            marks={marks}
                            disabled={disabled}
                            onChange={(value) => this.handleSliderChange(choice[0], value)}
                            defaultValue={defaultValues[choice[0]] || null}
                    />
                    
                    <span>
                      {/*TODO: Translate.*/}
                      <strong>
                        {values[choice[1]] || "Choose"}
                      </strong>
                    </span>
                  </div>
                }
                
                <div>
                  <label className={'custom-input-cell'}>
                    <input type='checkbox'
                           onClick={() => this.hideMultiSliderAnswer(choice[0], index, minValue)}
                           disabled={disabled}
                           defaultChecked={hideSlider[index]}
                    />
                    
                    <span className={hideSlider[index] ? 'checked' : 'custom-checkmark-radio'}/>
                    
                    <div className={'inline-text'}>
                     <Translation>{ (t, { i18n }) => t('NOT_APPLICABLE')}</Translation>
                    </div>
                  </label>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
