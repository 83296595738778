import EventTracker from "eventTracker";
import EventTypes from "constants/EventTypes";

export function getUserAdditionalAppColumns(aLimit, aOffset, aSearchString, aUserId){
  EventTracker.track(EventTypes.FETCH_USER_ADDITIONAL_APP_COLUMNS);
  
  const query = `
    query additional_app_column_user_joins ($search_string: String, $limit: Int, $offset: Int, $company_id: ID!, $user_id: ID!) {
      additional_app_column_user_joins (search_string: $search_string, limit: $limit, offset: $offset, company_id: $company_id, user_id: $user_id) {
        total
        additional_app_column_user_joins {
          id
          value
          additional_app_column {
            name
          }
          created_at
          updated_at
        }
      }
    }
  `;

  const variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['user_id'] = aUserId;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}