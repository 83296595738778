import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import moment from "moment";
import '../../Components/PatientProfileComponents/ProfileSections/AdditionalInformation/AdditionalInformation.scss';
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from '../../Custom UI/TabBar/TabBar'
import './ManageVirtualAppointments.scss';
import {Edit, RemoveCircleOutline, OndemandVideo} from '@material-ui/icons';
import AddVirtualAppointmentModal from "../../Modals/AddVirtualAppointmentModal/AddVirtualAppointmentModal";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {Translation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VirtualAppointmentsList from "../../Lists/VirtualAppointmentsList/VirtualAppointmentsList";
import AddVirtualAppointment from "../../Components/AddVirtualAppointment/AddVirtualAppointment";
import {momentFromDate} from "../../Helpers/DateHelpers";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {hsFeatureAvailableForCompany} from "../../Helpers/CompanyHelpers";
import i18n from "../../i18n";
import i18next from "i18next";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyRemoveAppointmentError = (aError) => toast(<Translation>{ (t, { i18n }) => t('REMOVE_VIRTUAL_APPOINTMENT_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class ManageVirtualAppointments extends React.Component {
  
  // Instance Variables
  
  calendarRef = React.createRef();
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      eventsArray:null,
      tabBarKey:'APPOINTMENT_LIST',
      menuAnchorElement:null,
      selectedPatientCell:null,
      virtualAppointments:[],
      showConfirmationModal:false,
      addVirtualAppointments:false,
      selectedVirtualAppointment:null
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.renderEventContent = this.renderEventContent.bind(this);
    this.populateEventsArray = this.populateEventsArray.bind(this);
    this.editVirtualAppointment = this.editVirtualAppointment.bind(this);
    this.startVirtualAppointment = this.startVirtualAppointment.bind(this);
    this.removeVirtualAppointment = this.removeVirtualAppointment.bind(this);
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedPatientCell:null});
    this.setState({virtualAppointmentMenuAnchorElement:null, selectedVirtualAppointmentDateCell:null});
  }
  
  virtualAppointmentMenuButtonClicked(aEvent, aVirtualAppointmentDateCell){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({virtualAppointmentMenuAnchorElement:aEvent.currentTarget, selectedVirtualAppointmentDateCell:aVirtualAppointmentDateCell});
  };
  
  // Methods
  
  handleDateClick = (arg) => {
    // TODO: Pass in dummy virtual appointment with start and end time set.
    this.setState({addVirtualAppointments:true, selectedVirtualAppointment:null})
  };
  
  closeAddVirtualAppointmentModal = () => {
    this.calendarRef.current.getApi().refetchEvents();
    this.setState({addVirtualAppointments:false, selectedVirtualAppointment:null});
  };
  
  startVirtualAppointment = (arg, virtualAppointment) => {
    window.open(virtualAppointment.connect_url, "_blank");
  };
  
  editVirtualAppointment = (arg, virtualAppointment) => {
    let selectedVirtualAppointment = this.state.eventsArray.filter(obj => {
      return obj.id === virtualAppointment.uuid;
    });
    if(selectedVirtualAppointment.length > 0){
      this.setState({selectedVirtualAppointment:selectedVirtualAppointment[0], addVirtualAppointments:true});
    }
  };
  
  removeVirtualAppointment = (arg, virtualAppointment) => {
    let selectedVirtualAppointment = this.state.eventsArray.filter(obj => {
      return obj.id === virtualAppointment.uuid;
    });
    if(selectedVirtualAppointment.length > 0){
      this.setState({selectedVirtualAppointment:selectedVirtualAppointment[0], showConfirmationModal:true});
    }
  };
  
  confirmRemoveVirtualAppointment = () => {
    const {removeVirtualAppointment} = this.props;
    const {selectedVirtualAppointment} = this.state;
    
    if(removeVirtualAppointment && selectedVirtualAppointment){
      removeVirtualAppointment({virtualAppointmentId:selectedVirtualAppointment.id}).then((newResult) => {
        this.calendarRef.current.getApi().refetchEvents();
        this.setState({confirmationModalOpen:false, selectedVirtualAppointment:null});
      }, (newError) => {
        notifyRemoveAppointmentError(errorMessageFromServerError(newError));
        this.setState({confirmationModalOpen:false, selectedVirtualAppointment:null});
      });
    }
  };
  
  renderEventContent(eventContent){
    const eventDef = eventContent?.event?._def;
    const title = eventDef?.title;
    let name = eventDef?.extendedProps?.primary_participant && formatUserName(eventDef?.extendedProps?.primary_participant.user);
    let otherParticipants = '';
    let otherParticipantsArray = eventDef?.extendedProps?.secondary_participants;
    
    if(otherParticipantsArray.length > 0){
      otherParticipants = otherParticipantsArray.length.toString();
      otherParticipantsArray.forEach((user) => {
        let name = formatUserName(user);
        
        if(name && name.length > 0){
          otherParticipants += ' ' + name;
        }
      });
    }
    if(eventContent.view.type === 'timeGridDay'){
      return (
        <div className='manage-appointment-cell' style={{
          width: '100%',
          backgroundColor: '#eeeeeee',
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
          overflow: 'hidden'
        }}>
          <div style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
            <b style={{paddingRight: '10px'}}>{title}</b>
            <b style={{paddingRight: '10px'}}>{name}</b>
            <b>{otherParticipants}</b>
            <b style={{paddingLeft: '10px'}}>{eventContent.timeText}</b>
          </div>
          <div className='manage-appointment-hover'>
            <div className='manage-appointment-icon-cell'
                 onClick={() => this.startVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
              <OndemandVideo className='manage-appointment-icon'/>
              <Translation>{ (t, { i18n }) => t('START_CALL') }</Translation>
            </div>
            {!momentFromDate(eventContent.event._def.extendedProps.start_at).isBefore(momentFromDate(moment().add(10, 'minutes'))) ?
              <div className='manage-appointment-icon-cell'
                   onClick={() => this.editVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
                <Edit className='manage-appointment-icon'/>
                <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
              </div>
              :
              null
            }
            <div className='manage-appointment-icon-cell'
                 onClick={() => this.removeVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
              <RemoveCircleOutline className='manage-appointment-icon'/>
              <Translation>{ (t, { i18n }) => t('REMOVE') }</Translation>
            </div>
          </div>
        </div>
      )
    }
    else if(eventContent.view.type === 'timeGridWeek'){
      return (
        <div className='manage-appointment-cell' style={{
          width: '100%',
          backgroundColor: '#eeeeeee',
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          paddingLeft: '5px',
          paddingRight: '0px',
          overflow: 'hidden'
        }}>
          {!momentFromDate(eventContent.event._def.extendedProps.start_at).isBefore(momentFromDate(moment().add(10, 'minutes'))) ?
            <div style={{width: 'calc(100% - 21px)', display: 'flex', overflow: 'auto', height: '18px'}}
                 onClick={() => this.editVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
              <b style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>{eventContent.timeText}</b>
            </div>
            :
            <div style={{width: 'calc(100% - 21px)', display: 'flex', overflow: 'auto', height: '18px'}}>
              <b style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>{eventContent.timeText}</b>
            </div>
          }
          <div className='manage-appointment-hover-week'>
            <div className='manage-appointment-icon-week-cell'
                 onClick={() => this.startVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
              <OndemandVideo className='manage-appointment-icon-week'/>
            </div>
          </div>
        </div>
      )
    }
    else{
      return (
        <div className='manage-appointment-cell' style={{
          width: '100%',
          backgroundColor: '#eeeeeee',
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          paddingLeft: '5px',
          paddingRight: '5px',
          overflow: 'hidden'
          
        }}>
          <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
               onClick={() => this.startVirtualAppointment(arguments, eventContent.event._def.extendedProps)}>
            <b style={{paddingLeft: '5px'}}>{eventContent.timeText}</b>
            <b style={{paddingRight: '5px', maxWidth:'calc(100% - 50px)', overflow: 'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}>{name}</b>
          </div>
        </div>
      )
    }
  }
  
  populateEventsArray(aDateRange, aSuccessCallback, aFailureCallback){
    let duration = moment.duration(momentFromDate(aDateRange.end).diff(momentFromDate(aDateRange.start)));
    let differenceInHours = duration.asHours();
    let limit = 20;
    
    if(differenceInHours > 168){
      limit = 100;
    }
    else if(differenceInHours > 24){
      limit = 50;
    }
    return this.props.getVirtualAppointments(limit, 0, null, aDateRange.end, aDateRange.start, null, null, null).then(result => {
      if(result && result.data && result.data.data && result.data.data.telehealth_virtual_appointment_page){
        let eventsArray = result.data.data.telehealth_virtual_appointment_page.telehealth_virtual_appointments;
        let array = eventsArray.map((virtualAppointment) => {
          virtualAppointment.uuid = virtualAppointment.id;
          virtualAppointment.start = momentFromDate(virtualAppointment.start_at).toDate();
          virtualAppointment.end = momentFromDate(virtualAppointment.end_at).toDate();
          return virtualAppointment;
        });
        this.setState({eventsArray:eventsArray});
        aSuccessCallback(array);
      }
      else{
        this.setState({eventsArray:[]});
      }
    }, (newError) => {
      aFailureCallback(newError);
    });
  }
  
  // Render
  
  render() {
    const {company, patients, searchPatients, fetchPatientsScroll, saveVirtualAppointment} = this.props;
    const {tabBarKey, menuAnchorElement, showConfirmationModal, addVirtualAppointments, selectedVirtualAppointment} = this.state;
    
    return (
      hsFeatureAvailableForCompany(company, 'Virtual Appointments') ?
        <div className='main-panel-inner-container'>
          <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENTS') }</Translation>}/>
          
          <TabBar tabBarTabsArray={[{key:'APPOINTMENT_LIST'}, {key:'CALENDER'}, {key:'CREATE_NEW_APPOINTMENT'}]}
                  initialSelectedTabKey={'APPOINTMENT_LIST'}
                  selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
          />
          
          {tabBarKey === 'APPOINTMENT_LIST' ?
            <div className="manage-appointments-container-list">
              <div className="manage-patient-container-text">
                <Translation>{ (t, { i18n }) => t('APPOINTMENT_LIST_VIEW') }</Translation>
              </div>
              
              <br/>
              
              <VirtualAppointmentsList {...this.props}
                                       editVirtualAppointment={(aVirtualAppointment) => this.setState({addVirtualAppointments:true, selectedVirtualAppointment:aVirtualAppointment})}
                                       scrollContainerClassName={"virtual-appointments-list-all-scroll"}
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'CALENDER' ?
            <div className="manage-appointments-container-list">
              <div className="manage-patient-container-text">
                <Translation>{ (t, { i18n }) => t('SCHEDULE_EXPLANATION') }</Translation>
              </div>
              
              <br/>
              
              <div>
                {/*TODO: Set the locale based on the current admin's settings.*/}
                <FullCalendar ref={this.calendarRef}
                              style={{backgroundColor:'white', height:'100%'}}
                              events={this.populateEventsArray}
                              locale={i18n.language}
                              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                              dateClick={this.handleDateClick}
                              eventsSet={(aArgument) => {}}
                              buttonText={{
                                day: <Translation>{ (t, { i18n }) => t('DAY_LOWERCASE') }</Translation>,
                                week: <Translation>{ (t, { i18n }) => t('WEEK') }</Translation>,
                                month: <Translation>{ (t, { i18n }) => t('MONTH_LOWER_CASE') }</Translation>
                              }}
                              initialView="timeGridDay"
                              eventContent={this.renderEventContent}
                              customButtons={{addVirtualAppointmentButton: {
                                  text: <Translation>{ (t, { i18n }) => t('ADD') }</Translation>,
                                  click: () => {this.setState({addVirtualAppointments:true, selectedVirtualAppointment:null})}
                                }}
                              }
                              headerToolbar={{
                                left: "prev,next,addVirtualAppointmentButton",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay"
                              }}
                              eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'short'
                              }}
                              allDayClassNames={['full-calender-all-day']}
                              allDayText={i18next.t('ALL_DAY')}
                />
                
                {addVirtualAppointments ?
                  <AddVirtualAppointmentModal key={patients}
                                              name={<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENT') }</Translation>}
                                              isOpen={addVirtualAppointments}
                                              patients={patients}
                                              searchPatients={searchPatients}
                                              handleCloseModal={this.closeAddVirtualAppointmentModal}
                                              virtualAppointment={selectedVirtualAppointment}
                                              fetchPatientsScroll={fetchPatientsScroll}
                                              saveVirtualAppointment={saveVirtualAppointment}
                  />
                  :
                  null
                }
                
                <ConfirmationModal title={<Translation>{ (t, { i18n }) => t('REMOVE_VIRTUAL_APPOINTMENT') }</Translation>}
                                   isOpen={showConfirmationModal}
                                   reject={() => this.setState({showConfirmationModal:false, selectedVirtualAppointment:null})}
                                   confirm={this.confirmRemoveVirtualAppointment}
                                   description={<Translation>{ (t, { i18n }) => t('ARE_YOU_SURE') }</Translation>}
                />
              </div>
            </div>
            :
            null
          }
          
          {tabBarKey === 'CREATE_NEW_APPOINTMENT' ?
            <div className="manage-appointments-container-add">
              <div className="manage-patient-container-text-add">
                <Translation>{ (t, { i18n }) => t('SCHEDULE_APPOINTMENTS') }</Translation>
              </div>
              
              <AddVirtualAppointment key={patients}
                                     name={`Virtual Appointment`}
                                     patients={patients}
                                     showSaveButton={true}
                                     fetchPatientsScroll={fetchPatientsScroll}
                                     saveVirtualAppointment={saveVirtualAppointment}
              />
              
              <Menu id="manage-virtual-appointments-menu"
                    open={Boolean(menuAnchorElement)}
                    onClose={() => this.closeMenu()}
                    anchorEl={menuAnchorElement}
                    keepMounted>
                <MenuItem style={{position:'relative'}}
                          onClick={() => this.closeMenu()}>
                  <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
                </MenuItem>
              </Menu>
            </div>
            :
            null
          }
        </div>
        :
        <div className="manage-virtual-appointments-unauthorised-container">
          <Translation>{ (t, { i18n }) => t('UNAUTHORISED') }</Translation>
        </div>
    );
  }
}
