export function questsArrayForCompany(aCompany){
  let returnValue = [];
  
  if(aCompany){
    if(aCompany.core_group && aCompany.core_group.quests){
      returnValue = returnValue.concat(aCompany.core_group.quests);
    }
    if(aCompany.groups){
      for(let group of aCompany.groups){
        if(group && group.quests && group.quests.length > 0){
          let ids = new Set(returnValue.map(quest => quest.id));
          returnValue = returnValue.concat(group.quests.filter(quest => !ids.has(quest.id)));
        }
      }
    }
    returnValue = returnValue.filter(((quest) => quest.quest_type === 'Tool' || quest.quest_type === 'Consent' || quest.quest_type === 'Survey'));
    returnValue = returnValue.sort((a, b) => a.title.localeCompare(b.title));
  }
  return returnValue;
}
