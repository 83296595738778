import React, { useState } from 'react';
import "antd/dist/antd.css";
import { NOTIFY_OPTS } from 'constants/Notifiers'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Translation} from "react-i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import '../GeneralModal/GeneralModal.scss';
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import i18next from "i18next";

export default function ChangePasswordModal({isOpen, closeModal, requiresCurrentPassword, saveButtonTitle, showSaveButtonOnRight, updateCurrentUser, loadUser, userId}) {

  const notifyUpdateSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PASSWORD_UPDATED') }</Translation>, NOTIFY_OPTS.autoClose);
  
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  
  const handleUpdateUserResult = (aResult) => {
    setLoading(false);
    let error = aResult?.data?.error;
  
    if(error){
      setChangePasswordError(error);
    }
    else{
      if(typeof loadUser === "function"){
        loadUser();
      }
      closeModal();
      notifyUpdateSuccess();
      resetToInitial();
    }
  };
  
  const handlePasswordChangeSubmit = () => {
    let passwordAtLeast8InLength = newPassword.length >= 8;
    
    if(requiresCurrentPassword){
      passwordAtLeast8InLength = passwordAtLeast8InLength && confirmNewPassword.length >= 8;
    }
    if(passwordAtLeast8InLength){
      if(newPassword !== confirmNewPassword) {
        setChangePasswordError(<Translation>{(t, { i18n }) => t('NEW_PASSWORD_DOESNT_MATCH_PASSWORD_CONFIRMATION')}</Translation>)
      }
      else{
        setLoading(true);
  
        if(requiresCurrentPassword){
          updateCurrentUser({id:userId, current_password:currentPassword, new_password:newPassword, confirm_new_password:confirmNewPassword})
          .then(newResult => {
            handleUpdateUserResult(newResult);
          });
        }
        else{
          updateCurrentUser(userId, newPassword)
          .then(newResult => {
            handleUpdateUserResult(newResult);
          })
        }
      }
      
    }
    else{
      setChangePasswordError(<Translation>{(t, { i18n }) => t('PLEASE_ENTER_CHARACTERS_FOR_ALL_INPUT_FIELDS')}</Translation>)
    }
  };

  const resetToInitial = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setChangePasswordError("");
  };

  const handleClose = () => {
    resetToInitial();
    closeModal();
  };

  return (
    <GeneralModal isOpen={isOpen}
                  width={'500px'}
                  maxWidth={500}
                  loading={loading}
                  handleCloseModal={handleClose}
                  handleSave={handlePasswordChangeSubmit}
                  title={<Translation>{(t, {i18n}) => t('CHANGE_PASSWORD')}</Translation>}
                  showSaveButton={true}
                  showCancelButton={true}
                  saveButtonTitle={saveButtonTitle}
                  showSaveButtonOnRight={showSaveButtonOnRight}>
      {changePasswordError ?
        <div className='modal-error'>
          {changePasswordError}
        </div>
        :
        null
      }
      
      {requiresCurrentPassword ?
        <LabeledTextInput className="modal-input"
                          type={'password'}
                          autoComplete="new-password"
                          label={<Translation>{(t, {i18n}) => t('CURRENT_PASSWORD')}</Translation>}
                          placeholder={(i18next.t('ENTER_HERE'))}
                          handleSave={(aEvent) => setCurrentPassword(aEvent.target.value)}
                          value={currentPassword}
        />
        :
        null
      }
      
      <div className="modal-dual-input-container">
        <LabeledTextInput className="modal-input-small"
                          type={'password'}
                          autoComplete="new-password"
                          label={<Translation>{(t, {i18n}) => t('NEW_PASSWORD')}</Translation>}
                          placeholder={(i18next.t('AT_LEAST_EIGHT_CHARACTERS'))}
                          value={newPassword}
                          handleSave={(aEvent) => setNewPassword(aEvent.target.value)}
        />
        
        <LabeledTextInput className="modal-input-small"
                          type={'password'}
                          autoComplete="new-password"
                          label={<Translation>{(t, {i18n}) => t('CONFIRM_NEW_PASSWORD')}</Translation>}
                          placeholder={(i18next.t('ENTER_HERE'))}
                          value={confirmNewPassword}
                          handleSave={(aEvent) => setConfirmNewPassword(aEvent.target.value)}
        />
      </div>
    </GeneralModal>
  )
}
