import React from 'react';
import {Translation} from "react-i18next";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const dateFormat = 'MMMM D ddd, YYYY';

const SyncedDevicesDataControls = ({ set, numberOfDateRangesBack, from, to, range, handleRangeSelect}) => (
  <div className='patient-profile-data-graphs-heading-container'>
    <div className='graph-time-container'>
      <ArrowBackIosIcon className='graph-time-control-buttons clickable'
                        onClick={() => set(numberOfDateRangesBack - 1)}
      />
      
      <div className='graph-time-period-info'
           id="synced-devices-period-info">
        {from.format(dateFormat)}
        <Translation>{ (t, { i18n }) => t('SPACED_TO') }</Translation>
        {to.format(dateFormat)}
      </div>
      
      <ArrowForwardIosIcon className='graph-time-control-buttons clickable'
                           onClick={() => set(numberOfDateRangesBack + 1)}
      />
  
      <img src={'../../Double Arrow Black.svg'}
           alt={'Double Arrow'}
           onClick={() => set(numberOfDateRangesBack + 2)}
      />
    </div>
    
    <div className='date-range-select'>
      <label>
        <input className='radio-select'
               name='dateRangeSyncedDevices'
               type='radio'
               value='2 Weeks'
               checked={range === '2 Weeks'}
               onChange={handleRangeSelect}
        />
        
        <p>2 <Translation>{(t, { i18n }) => t('WEEKS')}</Translation></p>
      </label>
      
      <label>
        <input className='radio-select'
               id="synced-devices-period-to"
               name='dateRangeSyncedDevices'
               type='radio'
               value='1 Month'
               checked={range === '1 Month'}
               onChange={handleRangeSelect}
        />
        
        <p>1 <Translation>{(t, { i18n }) => t('MONTH_PROPER_CAPITALIZED')}</Translation></p>
      </label>
    </div>
  </div>
);
export default SyncedDevicesDataControls
