import React, {useEffect} from 'react';
import './closeStudyMessage.scss';
import {Translation, withTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "constants/Notifiers";

const toastId = 'toastId';
const showSuccessToast = (aTime) => toast(<Translation>{(t, {i18n}) => t('CLOSE_STUDY_FINAL_MESSAGE', {time:aTime})}</Translation>, NOTIFY_OPTS.autoCloseWithId(toastId, 1000));
const showErrorToast = (aError) => toast(<Translation>{(t, {i18n}) => t(aError)}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const updateToast = (aTime) => toast.update(toastId, {render:<Translation>{(t, {i18n}) => t('CLOSE_STUDY_FINAL_MESSAGE', {time:aTime})}</Translation>});

const CloseStudyMessage = ({isOpen, closeStudyFailed = false, errorMessage, startTimer, logout}) => {

  // Instance Variables

  // Methods

  const showSuccessMessage = () => {
    let countdownValue = 5;

    showSuccessToast(countdownValue);

    if(logout){
      const logoutInterval = setInterval(() => {
        countdownValue -= 1;

        if(countdownValue === 0){
          logout();
          clearInterval(logoutInterval);
        }
        updateToast(countdownValue);
      }, 1000);
    }
  };

  // Init

  useEffect(() => {
    if(startTimer){
      showSuccessMessage();
    }
    else if(closeStudyFailed){
      showErrorToast(errorMessage);
    }
  }, [startTimer, closeStudyFailed]);

  return (
      isOpen?
        <div className="close-study-message" />
        :
        null
  );
};

export default withTranslation()(CloseStudyMessage);