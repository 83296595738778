import React from 'react';
import {Translation} from "react-i18next";

export default class ChartTitle extends React.Component {
  
  render() {
    let { objective } = this.props;
    let chartTitle = objective?.name;

    if(objective.vital){
      chartTitle = `${chartTitle} (${objective?.vital?.storage_unit})`;
    }
    return (
      <h5 className='graph-heading'>
        { chartTitle }
      </h5>
    );
  }
}
