import React from "react";
import {Translation} from "react-i18next";
import GetAppIcon from '@material-ui/icons/GetApp';

export default class SurveyImageQuestionCell extends React.Component{
  
  // Instance Variables
  
  inputRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      file:null,
      imageUrl:null
    };
    if(props.answer != null){
      if(props.answer.value != null && props.answer.value.length > 5){
        this.state.imageUrl = props.answer.value;
      }
      if(props.answer.file != null){
        this.state.file = props.answer.file;
        this.state.imageUrl = URL.createObjectURL(this.state.file);
      }
    }
    this.buttonClicked = this.buttonClicked.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }
  
  // Methods
  
  buttonClicked(){
    this.inputRef.current.click();
  }
  
  handleFileUpload(aEvent){
    let file = aEvent.target.files[0];
    this.setState({file:file});
    this.props.onAnswerChange(this.props.question, this.state.imageUrl, null, null, null, true, file);
  }
  
  // Render
  
  render(){
    const {size, disabled, question} = this.props;
    const {file, imageUrl} = this.state;
    const {key, type} = question;
    
    let imageClassName = 'survey-question-image-small';
    
    if(size === 'large'){
      imageClassName = 'survey-question-image';
    }
    return(
      <div className="survey-question-image-cell"
           key={'survey-' + type + '-question-cell-' + key}>
        <input type="file"
               ref={this.inputRef}
               onChange={this.handleFileUpload}
               disabled={disabled}
               accept="image/*"
               style={{display:'none'}}
        />
      
        {file ?
          <>
            <p className="survey-question-image-name">
              {file.name}
            </p>
          
            <img className={imageClassName}
                 src={URL.createObjectURL(file)}
                 alt={file.name}/>
          </>
          :
          imageUrl ?
            <div className="survey-question-image-container">
              <img className={imageClassName}
                   alt=""
                   src={imageUrl}/>
            
              <a className="survey-question-image-button-download"
                 target="_blank"
                 rel="noreferrer"
                 href={imageUrl}
                 download="My_Image.png">
                <GetAppIcon style={{fontSize:'20px'}}/>
              
                <Translation>{ (t, { i18n }) => t('DOWNLOAD_IMAGE')}</Translation>
              </a>
            </div>
            :
            null
        }
        <button className={'survey-button green-button'}
                disabled={disabled}
                onClick={this.buttonClicked}>
          {file || imageUrl ?
            <Translation>{ (t, { i18n }) => t('CHANGE_IMAGE')}</Translation>
            :
            <Translation>{ (t, { i18n }) => t('SELECT_IMAGE')}</Translation>
          }
        </button>
      </div>
    )
  }
}
