import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchReports(aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_REPORTS);
  let query = `
  query getReports ($company_id: ID!, $limit: Int, $offset: Int) {
      reports (company_id: $company_id, limit: $limit, offset: $offset){
          reports {
            id
            name
            status
            file_url
            created_at
            report_type
            user {
              id
              last_name
              first_name
            }
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function removeReport(aReportId){
  EventTracker.track(EventTypes.REMOVE_REPORT);
  let query = `
  mutation RemoveReport ($removeReportMutationInput: RemoveReportMutationInput!) {
        removeReport (input: $removeReportMutationInput){
          report {
            id
          }
        }
      }
  `;
  let operationName = `RemoveReport`;
  
  return (dispatch, getState) => {
    let variables = {
      removeReportMutationInput: {
        id: aReportId,
      }
    };
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function fetchReportDetails(aReportId){
  EventTracker.track(EventTypes.FETCH_REPORT);
  let query = `
  query getReport ($company_id: ID!, $report_id: ID) {
      report (company_id: $company_id, report_id: $report_id){
        id
        name
        status
        file_url
        created_at
        report_type
        user { 
          id
          last_name
          first_name
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['report_id'] = aReportId;
    return global.api.post("/queries", { query, variables })
  };
}

export function retryGeneratingReport(aReportId){
  EventTracker.track(EventTypes.RETRY_GENERATING_REPORT);
  let query = `
    mutation RetryReportMutation($retryReportMutationInput: RetryReportMutationInput!) {
      retryReportMutation(input: $retryReportMutationInput) {
        report {
          id
          name
          status
          file_url
          created_at
          report_type
          expected_generated_at
          user {
            id
            last_name
            first_name
          }
        }
      }
    }
  `;
  let params = {};
  let operationName = `RetryReportMutation`;
  
  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['report_id'] = aReportId;
    
    let variables = {
      retryReportMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  }
}
