import React from 'react';
import {ObjectiveConstants} from "../../../constants/ObjectiveConstants";
import ReminderTrackerChart from "./ReminderTrackerChart/ReminderTrackerChart";
import ChartTitle from "./ChartTitle";
import NoReminderChart from "./NoReminderChart/NoReminderChart";
import ReminderScheduleInfo from "./ReminderScheduleInfo";
import i18next from "i18next";

export default class ReminderChartGroup extends React.Component {
  static CollapseUpButton = '/imgs/app/collapse-up-button.svg';
  static ExpandDownButton = '/imgs/app/expand-down-button.svg';

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  render() {
    let { quest, reminderTime, startDate, endDate } = this.props;
    let objectives = this.getObjectivesForReminderTimes(quest, reminderTime);
    return (
      <div>
        <div onClick={() => this.toggleCollapsedState()} className="clickable reminder-group-container">
          <img alt="" src={ this.state.collapsed ? ReminderChartGroup.CollapseUpButton : ReminderChartGroup.ExpandDownButton }/>
          <div className="chart-group-title">
            {this.formatTitle(reminderTime, objectives)}
          </div>
        </div>
        <div>{this.renderChartComponents(objectives, startDate, endDate, reminderTime)}</div>
      </div>
    );
  }

  getObjectivesForReminderTimes(quest, reminderTime){
    let returnValue = null;

    if(reminderTime === null){
      returnValue = quest.objectives.filter(objective => (objective.type === ObjectiveConstants.ObjectiveTypeMedication || objective.type === ObjectiveConstants.ObjectiveTypeRoutine) && objective.reminders.length === 0);
    }
    else{
      returnValue = quest.objectives.filter(objective => (objective.type === ObjectiveConstants.ObjectiveTypeMedication || objective.type === ObjectiveConstants.ObjectiveTypeRoutine) && objective.reminders.find(reminder => reminder.time === reminderTime));
    }
    return Array.from(new Set(returnValue));
  }

  formatTitle(reminderTime, objectives){
    let returnValue = "";
    let numberOfObjectives = objectives.length;

    if(reminderTime === null){
      returnValue = ((objectives[0] && objectives[0].type && objectives[0].type === ObjectiveConstants.ObjectiveTypeMedication) ?
          i18next.t('MEDICATION') : i18next.t('ROUTINE')) +
          i18next.t('DASH_AS_NEEDED_NO_REMINDER') + ` (${numberOfObjectives})`;
    }
    else{
      let hour = parseInt(reminderTime.substring(0, reminderTime.length - 2));
      let minute = parseInt(reminderTime.substring(reminderTime.length - 2));
      let time = new Date();
      time.setHours(hour);
      time.setMinutes(minute);
      returnValue = ((objectives[0] && objectives[0].type && objectives[0].type === ObjectiveConstants.ObjectiveTypeMedication) ?
          i18next.t('MEDICATION') :
          i18next.t('ROUTINE')) + ` - @ ${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} (${numberOfObjectives})`;
    }
    return returnValue;
  }

  renderChartComponents(objectives, startDate, endDate, reminderTime){
    let returnValue = null;
    let { collapsed } = this.state;

    if(collapsed === true){
      returnValue = objectives.map((reminderObjective, index) => {
        let chart = null;

        if(reminderObjective.reminders.length === 0){
          chart = <NoReminderChart
            reminderObjective={reminderObjective}
            startDate={startDate}
            endDate={endDate}
            onClickListener={this.props.onChartClickedListener}
          >
          </NoReminderChart>;
        }
        else if(reminderObjective.reminders.filter(reminder => reminder.time === reminderTime).length > 0){
          chart = <ReminderTrackerChart
            reminderObjective={reminderObjective}
            reminders={reminderObjective.reminders.filter(reminder => reminder.time === reminderTime)}
            startDate={startDate}
            endDate={endDate}
            onClickListener={this.props.onChartClickedListener}
          >
          </ReminderTrackerChart>;
        }
        return (
          <div key={index.toString()}>
            <ChartTitle objective={reminderObjective}></ChartTitle>
            <ReminderScheduleInfo
              reminders={reminderObjective.reminders.sort((reminder1, reminder2) => {
                if (reminder1.day_number && reminder2.day_number) {
                  return reminder1.day_number - reminder2.day_number;
                }
                return 0;
              })}
              />
            <div className="patient-data-chart-container">
              {chart}
            </div>
          </div>
        );
      });
    }
    return returnValue;
  }

  toggleCollapsedState() {
    this.setState({collapsed: !this.state.collapsed})
  }
}
