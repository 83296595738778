import React from 'react';
import './ToolSurveyIconTitle.scss';
import {FaSync, FaApple, FaPlusSquare} from 'react-icons/fa';

const ToolSurveyIconTitle = ({toolIconName, toolTitle, faIcon}) => (
  <div className='title-icon-text-container'>
    <div className='title-icon'>
      <i className={`icon-tools ion-${toolIconName}`}/>
      
      {faIcon === 'Apple' ?
        <FaApple />
        :
        null
      }
  
      {faIcon === 'Sync' ?
        <FaSync />
        :
        null
      }

      {faIcon === 'PlusSquare' ?
        <FaPlusSquare />
        :
        null
      }
    </div>
    
    <div className='title-text'>
      {toolTitle}
    </div>
  </div>
)

export default ToolSurveyIconTitle
