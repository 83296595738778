import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchPayments(aPatientId, aGroupId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_PAYMENTS);
  let query = `
  query getHyperwalletPayments ($company_id: ID!, $user_id: ID, $group_id: ID, $limit: Int, $offset: Int, $search_string: String) {
      hyperwallet_payments (company_id: $company_id, user_id: $user_id, group_id: $group_id, limit: $limit, offset: $offset, search_string: $search_string){
          hyperwallet_payments {
            id
            status
            currency
            amount
            created_at
            updated_at
            client_payment_id
            has_successful_hyperwallet_status
            last_sync_error_message
            payment_type
            user {
              id
              email
              first_name
              last_name
              edc_id
              number_of_unverified_fraud_events
              fraud_statistic {
                probability
                verified_comments
                verification_status
                verified_by_user {
                  id
                  first_name
                  last_name
                }
              }
            }
            verified_by_user {
              id
              email
              first_name
              last_name
            }
            payment_trigger {
              action_criteria
              number_of_payments
            }
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aPatientId){
      variables['user_id'] = aPatientId;
    }
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables })
  };
}

export function createPayment(aPatientId, aAmount, aCurrency){
  EventTracker.track(EventTypes.CREATE_PAYMENT);
  let query = `
  mutation AddHyperwalletPaymentMutation ($addHyperwalletPaymentMutationInput: AddHyperwalletPaymentMutationInput!) {
        addHyperwalletPayment (input: $addHyperwalletPaymentMutationInput){
          hyperwallet_payment {
            id
            status
            currency
            amount
            created_at
            updated_at
            client_payment_id
            has_successful_hyperwallet_status
            last_sync_error_message
            payment_type
            user {
              id
              email
              first_name
              last_name
              edc_id
              number_of_unverified_fraud_events
              fraud_statistic {
                probability
                verified_comments
                verification_status
                verified_by_user {
                  id
                  first_name
                  last_name
                }
              }
            }
            verified_by_user {
              id
              email
              first_name
              last_name
            }
          }
        }
      }
  `;
  
  let operationName = `AddHyperwalletPayment`;
  let params = {};

  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['user_id'] = aPatientId;
    params['amount'] = aAmount;
    params['currency'] = aCurrency;
  
    let variables = {
      addHyperwalletPaymentMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function approvePayment(aPayment){
  EventTracker.track(EventTypes.APPROVE_PAYMENT);
  return verifyPayment(aPayment, 'approved');
}

export function removePayment(aPayment){
  EventTracker.track(EventTypes.REMOVE_PAYMENT);
  return verifyPayment(aPayment, 'rejected');
}

export function verifyPayment(aPayment, aStatus){
  let query = `
  mutation VerifyHyperwalletPaymentMutation ($verifyHyperwalletPaymentMutationInput: VerifyHyperwalletPaymentMutationInput!) {
        verifyHyperwalletPayment (input: $verifyHyperwalletPaymentMutationInput){
          hyperwallet_payment {
            id
            status
            currency
            amount
            created_at
            updated_at
            client_payment_id
            has_successful_hyperwallet_status
            last_sync_error_message
            payment_type
            user {
              id
              email
              first_name
              last_name
              edc_id
              number_of_unverified_fraud_events
              fraud_statistic {
                probability
                verified_comments
                verification_status
                verified_by_user {
                  first_name
                  last_name
                }
              }
            }
            verified_by_user {
              email
              first_name
              last_name
            }
          }
        }
      }
  `;
  
  let operationName = `VerifyHyperwalletPayment`;
  let params = {};
  
  return (dispatch) => {
    params['hyperwallet_payment_id'] = aPayment.id;
    params['status'] = aStatus;
    
    let variables = {
      verifyHyperwalletPaymentMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function retryPayment(aPayment) {
  let query = `
  mutation RetryHyperwalletPaymentMutation ($retryHyperwalletPaymentMutationInput: RetryHyperwalletPaymentMutationInput!) {
        retryHyperwalletPayment (input: $retryHyperwalletPaymentMutationInput){
          hyperwallet_payment {
            id
            status
            currency
            amount
            created_at
            updated_at
            client_payment_id
            has_successful_hyperwallet_status
            last_sync_error_message
            payment_type
            user {
              id
              email
              first_name
              last_name
              edc_id
              number_of_unverified_fraud_events
              fraud_statistic {
                probability
                verified_comments
                verification_status
                verified_by_user {
                  id
                  first_name
                  last_name
                }
              }
            }
            verified_by_user {
              id
              email
              first_name
              last_name
            }
          }
        }
      }
  `;
  
  let operationName = `RetryHyperwalletPayment`;
  let params = {};
  
  return (dispatch) => {
    params['hyperwallet_payment_id'] = aPayment.id;
    
    let variables = {
      retryHyperwalletPaymentMutationInput: params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}
