import React from "react";
import './SurveyBuilderQuestionDBWrite.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {allowedWriteColumnOptionsArray, allowedWriteTableOptionsArray, allowedWriteTables} from "../../../../constants/SurveyBuilderTypes";

export default class SurveyBuilderQuestionDBWrite extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      selectedColumnOption:{}
    };
  }
  
  componentDidMount(){
    const {question} = this.props;
    
    if(question && question.db_write && question.db_write.column){
      this.setState({selectedColumnOption:{name:question.db_write.column}});
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {question} = this.props;
    
    if(!(question && prevProps.question && (question.key === prevProps.question.key))){
      if(question && question.db_write && question.db_write.column){
        this.setState({selectedColumnOption:{name:question.db_write.column}});
      }
      else{
        this.setState({selectedColumnOption:{}});
      }
    }
  }
  
  // Render
  
  render(){
    const {question, updateQuestion, errorForIdTypeAndKey, fetchAppAdditionalColumns} = this.props;
    const {selectedColumnOption} = this.state;
    
    let table = '';
    
    if(question && question.db_write && question.db_write.table && allowedWriteTables.includes(question.db_write.table)){
      table = question.db_write.table;
    }
    
    let writeColumnOptionsArray = [];
    let columnType = '';
    
    if(table !== 'AdditionalAppColumn'){
      writeColumnOptionsArray = allowedWriteColumnOptionsArray(table);
    }
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-db-write-input-switch"
                       label={<Translation>{ (t, { i18n }) => t('DB_WRITE') }</Translation>}
                       checked={Boolean(question && question.db_write)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
          
                         if(isChecked){
                           updatedQuestion.db_write = {table:'', column:'', column_value:''};
                         }
                         else{
                           delete updatedQuestion['db_write'];
            
                           if(updatedQuestion.choices){
                             for(let choice of updatedQuestion.choices){
                               if(choice.db_write_value){
                                 delete choice['db_write_value'];
                               }
                             }
                           }
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('DB_WRITE_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        {question && question.db_write ?
          <span>
            <LabeledDropdown className="survey-builder-question-db-write-input-more"
                             label={<Translation>{ (t, { i18n }) => t('TABLE') }</Translation>}
                             value={allowedWriteTableOptionsArray.filter(function(option){
                               return option.value === question.db_write.table;
                             })}
                             options={allowedWriteTableOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('TABLE_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => {
                               let updatedQuestion = cloneDeep(question);
  
                               if(updatedQuestion.db_write.table !== aOption.value){
                                 updatedQuestion.db_write.column = '';
                               }
                               updatedQuestion.db_write.table = aOption.value;
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'db_write_table').message}
                             maxMenuHeight={100}
                             popoverPlacement={"left"}
            />
            
            {question.db_write.table && question.db_write.table.length > 0 ?
              <>
                <LabeledDropdown className="survey-builder-question-db-write-input-more"
                                 label={<Translation>{ (t, { i18n }) => t('COLUMN') }</Translation>}
                                 value={table !== 'AdditionalAppColumn' ?
                                   writeColumnOptionsArray.filter(function(option){
                                     return option.value === question.db_write.column;
                                   })
                                   :
                                   selectedColumnOption
                                 }
                                 options={table !== 'AdditionalAppColumn' ? writeColumnOptionsArray : null}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('COLUMN_EXPLANATION')}</Translation>}
                                 handleSave={(aOption) => {
                                   let updatedQuestion = cloneDeep(question);
                  
                                   if(table !== 'AdditionalAppColumn'){
                                     updatedQuestion.db_write.column = aOption.value;
                                   }
                                   else{
                                     updatedQuestion.db_write.column = aOption.name;
                                     this.setState({selectedColumnOption:aOption});
                                   }
                                   updateQuestion(updatedQuestion);
                                 }}
                                 fetchObjects={table === 'AdditionalAppColumn' ? (aLimit, aOffset, aSearchString) => fetchAppAdditionalColumns(aLimit, aOffset, aSearchString) : null}
                                 errorMessage={errorForIdTypeAndKey(question.id, 'error', 'db_write_column').message}
                                 menuPosition={'portal'}
                                 maxMenuHeight={100}
                                 popoverPlacement={"left"}
                                 labelPropertiesArray={['name']}
                                 valuePropertiesArray={['name']}
                                 responseTotalKeysArray={['data', 'data', 'additional_app_columns', 'total']}
                                 responseObjectKeysArray={['data', 'data', 'additional_app_columns', 'additional_app_columns']}
                />
                
                <LabeledDelayedInput className="survey-builder-question-db-write-input-more survey-builder-question-db-write-column-value"
                                     label={<Translation>{(t, { i18n }) => t('COLUMN_VALUE')}</Translation>}
                                     value={question.db_write.column_value === 'calculate' ? '' : question.db_write.column_value}
                                     infoBlurb={<Translation>{(t, { i18n }) => t('COLUMN_VALUE_EXPLANATION')}</Translation>}
                                     minLength={0}
                                     handleSave={(aEvent) => {
                                       let updatedQuestion = cloneDeep(question);
                                       updatedQuestion.db_write.column_value = aEvent.target.value;
                                       updateQuestion(updatedQuestion);
                                     }}
                                     disabled={question.db_write.column_value === 'calculate'}
                                     warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'db_write_column_value').message}
                                     popoverPlacement={"left"}
                />
                
                <LabeledSwitch className="survey-builder-question-db-write-input-switch"
                               label={<Translation>{ (t, { i18n }) => t('INCREMENT_BY') }</Translation>}
                               checked={question.db_write.column_value === 'calculate'}
                               onChange={(isChecked) => {
                                 let updatedQuestion = cloneDeep(question);
                  
                                 if(isChecked){
                                   updatedQuestion.db_write.column_value = 'calculate';
                                 }
                                 else{
                                   updatedQuestion.db_write.column_value = '';
                                 }
                                 updateQuestion(updatedQuestion);
                               }}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('INCREMENT_BY_ONE_EXPLANATION') }</Translation>}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               popoverPlacement={"left"}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
                
                {columnType === 'date' ?
                  <LabeledSwitch className="survey-builder-question-db-write-input-switch"
                                 label={<Translation>{(t, {i18n}) => t('PARSE_TO_AGE')}</Translation>}
                                 checked={question.db_write.parse_to === 'age'}
                                 onChange={(isChecked) => {
                                   let updatedQuestion = cloneDeep(question);
                    
                                   if(isChecked){
                                     updatedQuestion.db_write.parse_to = 'age';
                                   }
                                   else{
                                     delete updatedQuestion.db_write['parse_to'];
                                   }
                                   updateQuestion(updatedQuestion);
                                 }}
                                 infoBlurb={<Translation>{(t, {i18n}) => t('PARSE_TO_AGE_EXPLANATION')}</Translation>}
                                 checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                                 popoverPlacement={"left"}
                                 unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
                  />
                  :
                  null
                }
              </>
              :
              null
            }
          </span>
          :
          null
        }
      </div>
    )
  }
}
