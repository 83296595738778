import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Translation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from "../../Custom UI/TabBar/TabBar";
import './ManageGroups.scss';
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import i18next from "i18next";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GroupCell from "../../Cells/GroupCell/GroupCell";
import PatientList from "../../Lists/PatientList/PatientList";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import GroupModal from "../../Modals/GroupModal/GroupModal";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const ManageGroups = ({removeGroup, addGroup, groups, removeUserFromGroup, addUserToGroup, updateGroup, company}) => {

  // Instance Variables

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupCellMenuAnchorElement, setGroupCellMenuAnchorElement] = useState(null);
  const [groupListItemMenuAnchorElement, setGroupListItemMenuAnchorElement] = useState(null);
  const [tabBarKey, setTabBarKey] = useState('GROUP_LIST');
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupLocale, setNewGroupLocale] = useState('');
  const [groupsArray, setGroupsArray] = useState(groups ? groups : []);
  const [filteredGroupsArray, setFilteredGroupsArray] = useState(groups ? groups : []);
  const [searchString, setSearchString] = useState('');
  const [selectedPatientIdsArray, setSelectedPatientIdsArray] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isScrollActive, setIsScrollActive] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  // Init

  useEffect(() => {
    setGroupsArray(groups ? groups : []);
    setFilteredGroupsArray(groups ? groups : []);
  }, [groups]);

  // Methods

  const notifyAddGroupSuccess = () => {toast(<Translation>{ (t, { i18n }) => t('SUCCESS_GROUP_ADDED') }</Translation>, NOTIFY_OPTS.autoClose)};
  const notifyRemoveGroupSuccess = () => {toast(<Translation>{ (t, { i18n }) => t('SUCCESS_GROUP_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose)};

  const handleAddClick = () => {
    if(newGroupName !== '' && newGroupName.trim().length){
      addGroup(newGroupName.trim(), selectedPatientIdsArray, newGroupLocale).then(result => {
        let error = result?.data?.error;
        let group = result?.data?.data?.addGroup?.group;

        if(error){
          alert(error);
        }
        else if(group){
          let tempArray = [group].concat(groupsArray);
          setGroupsArray(tempArray);
        }
        notifyAddGroupSuccess();
      });
      setNewGroupName('');
      setSelectedPatientIdsArray([]);
      setNewGroupLocale('');
      setErrorMessage('');
    }
    else if(newGroupName === ''){
      setErrorMessage(i18next.t('GROUP_NAME_IS_MANDATORY'));
    }
  };

  const removeGroupByID = (groupId) => {
    closeMenu();
    removeGroup(groupId).then(result => {
      notifyRemoveGroupSuccess();
      let tempArray = groupsArray.filter(function(group){
       return group.id !== groupId;
      });
      setGroupsArray(tempArray);
      setConfirmationModalOpen(false);
      filterGroupsBySearchString(searchString, tempArray);
    })
  };

  const closeMenu = () => {
    setSelectedGroup(null);
    setGroupCellMenuAnchorElement(null);

    setGroupListItemMenuAnchorElement(null);
  };

  const groupCellMenuButtonClicked = (aEvent, aGroup) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();

    setSelectedGroup(aGroup);
    setGroupCellMenuAnchorElement(aEvent.currentTarget);
  };

  const handleSearch = (aEvent) => {
    setSearchString(aEvent.target.value);
    filterGroupsBySearchString(aEvent.target.value, groupsArray);
  };

  const filterGroupsBySearchString = (aSearchString, aGroupsArray) => {
    let searchedGroupsArray = aGroupsArray.filter((group) => {return group.name.toLowerCase().includes(aSearchString.toLowerCase())});
    setFilteredGroupsArray(searchedGroupsArray);
  };
  
  const groupWasUpdated = (aGroup) => {
    if(aGroup) {
      for(let index = 0; index < filteredGroupsArray.length; index += 1){
        let group = filteredGroupsArray[index];
    
        if(group.id === aGroup.id){
          filteredGroupsArray[index] = aGroup;
          break;
        }
      }
    }
  };

  const selectPatient = (aPatient) => {
    let patientIdsArray = selectedPatientIdsArray;
    const index = patientIdsArray.indexOf(aPatient.id);

    if(index !== -1){
      patientIdsArray.splice(index, 1);
    }
    else{
      patientIdsArray.push(aPatient.id);
    }
    setSelectedPatientIdsArray([...patientIdsArray]);
  };

  const handleOnScroll = () => {
    if(!isScrollActive){
      setIsScrollActive(true);
    }

    if(scrollTimeout){
      clearTimeout(scrollTimeout);
    }

    const timeout = setTimeout(() => {
      if(isScrollActive){
        setIsScrollActive(false)
      }
    }, 300);
    setScrollTimeout(timeout)
  }

  // let tabBarTitlesArray = [
  //   {title:<Translation>{ (t, { i18n }) => t('GROUP_LIST') }</Translation>, badge:groupsArray.length},
  //   {title:<Translation>{ (t, { i18n }) => t('CREATE_PROPER_CAPITALIZED') }</Translation>, badge:0}];
  
  const availableLanguages = [
    {languageString:(i18next.t('ENGLISH_WITH_FLAG')), languageCode:'en'},
    {languageString:(i18next.t('ITALIAN_WITH_FLAG')), languageCode:'it'},
    {languageString:(i18next.t('FRENCH_WITH_FLAG')), languageCode:'fr'},
    {languageString:(i18next.t('GERMAN_WITH_FLAG')), languageCode:'de'},
    {languageString:(i18next.t('SPANISH_WITH_FLAG')), languageCode:'es'}
  ];
  
  let languageOptions = [];
  
  availableLanguages.map((aLanguage, index) => (
    languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
  ));

  return (
    <div className="main-panel-inner-container">
      <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('GROUPS') }</Translation>}/>
  
      <TabBar tabBarTabsArray={[{key:'GROUP_LIST', badge:groupsArray.length}, {key:'CREATE_PROPER_CAPITALIZED'}]}
              initialSelectedTabKey={'GROUP_LIST'}
              selectedTabKeyChanged={(aKey) => setTabBarKey(aKey)}
      />
      
      {tabBarKey === 'GROUP_LIST' ?
        <div className="manage-groups-container">
          <div style={{marginLeft : 20}} className="manage-patient-container-text">
              <Translation>{ (t, { i18n }) => t('LIST_EXISTING_GROUPS') }</Translation>
          </div>
          
          <div className="manage-group-search">
            <LabeledDelayedInput className="manage-groups-search"
                                 disabled={false}
                                 showSearchIcon={true}
                                 debounceTimeout={500}
                                 minLength={1}
                                 placeholder={i18next.t('TAP_TO_SEARCH')}
                                 value={searchString}
                                 handleSave={handleSearch}

            />
          </div>
          
          <div className="manage-group-cells-container" onScroll={handleOnScroll}>
            <PerfectScrollbar>
              {filteredGroupsArray && filteredGroupsArray.map((group, index) => {
                return (
                  <GroupCell className={''}
                             key={group.id + '-index-' + index}
                             group={group}
                             company={company}
                             loadOnIntersection
                             isScrollActive={isScrollActive}
                             addUserToGroup={addUserToGroup}
                             menuButtonClicked={(aEvent) => groupCellMenuButtonClicked(aEvent, group)}
                             removeUserFromGroup={removeUserFromGroup}
                  />
                )
              })
              }
            </PerfectScrollbar>

            <Menu id="manage-groups-menu"
                  anchorEl={groupCellMenuAnchorElement}
                  keepMounted
                  open={Boolean(groupCellMenuAnchorElement)}
                  onClose={() => closeMenu()}>
              <MenuItem style={{position: 'relative'}} onClick={() => {setShowGroupModal(true); setGroupCellMenuAnchorElement(null);}}>
                <Translation>{ (t, { i18n }) => t('EDIT')}</Translation>
              </MenuItem>
              
              <MenuItem style={{position: 'relative'}}
                        onClick={() => {setConfirmationModalOpen(true)}}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
  
            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:selectedGroup ? selectedGroup.name : ''})}
                               isOpen={confirmationModalOpen}
                               reject={() => setConfirmationModalOpen(false)}
                               confirm={() => removeGroupByID(selectedGroup.id)}
            />
  
            {showGroupModal ?
              <GroupModal group={selectedGroup}
                          isOpen={showGroupModal}
                          canCancel={true}
                          updateGroup={updateGroup}
                          handleCloseModal={(aGroup) => {groupWasUpdated(aGroup); setShowGroupModal(false); closeMenu();}}
              />
              :
              null
            }
          </div>
        </div>
        :
        null
      }

      {tabBarKey === 'CREATE_PROPER_CAPITALIZED' ?
        <div className="manage-groups-container-create">
          <div className="manage-groups-container-create-text">
              <Translation>{ (t, { i18n }) => t('CREATE_GROUP_MANAGE_COHORTS') }</Translation>
          </div>
          
          <div className="manage-groups-create-input-container">
            <LabeledTextInput className="manage-group-name-create"
                              placeholder={i18next.t('NEW_GROUP_NAME')}
                              label={<Translation>{(t, {i18n}) => t('CREATE_NEW_GROUP')}</Translation>}
                              value={newGroupName}
                              handleSave={(aEvent) => {setNewGroupName(aEvent.target.value)}}
                              errorMessage={!newGroupName && errorMessage}
                              required={true}
            />
  
            <LabeledDropDown className="manage-groups-create-dropdown-input"
                             label={<Translation>{ (t, { i18n }) => t('NEW_GROUP_LOCALE')}</Translation>}
                             value={newGroupLocale && languageOptions.filter(function(option){
                               return option.value === newGroupLocale;
                             })}
                             options={languageOptions}
                             handleSave={(aOption) => {setNewGroupLocale(aOption.value)}}
            />
          </div>
          
          <div className="manage-groups-patient-list-container">
            <PatientList height={'calc(100vh - 465px)'}
                         company={company}
                         showLink={true}
                         canSelect={true}
                         selectPatient={selectPatient}
                         selectedPatientIdsArray={selectedPatientIdsArray}
            />
          </div>

          <div className="manage-groups-button-container">
            <BlueButton name={<Translation>{ (t, { i18n }) => t('CREATE_PROPER_CAPITALIZED') }</Translation>}
                        onClick={handleAddClick}
            />
          </div>
          
          <Menu id="simple-menu"
                anchorEl={groupListItemMenuAnchorElement}
                keepMounted
                open={Boolean(groupListItemMenuAnchorElement)}
                onClose={() => closeMenu()}>
            <MenuItem style={{position: 'relative'}} onClick={() => closeMenu()}>
              <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
            </MenuItem>
          </Menu>

        </div>
        :
        null
      }
      </div>
  );
};
export default ManageGroups
