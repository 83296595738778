import {
  FETCH_USERS,
  LOAD_USERS,
  ADD_USER_ERROR,
  LOGOUT,
} from 'constants/ActionTypes';
import {REHYDRATE} from 'redux-persist'

function users(state = [], action) {
  switch(action.type) {
    case REHYDRATE:
      let incoming = action.payload && action.payload.users
      if (incoming && incoming.errors) {
        return {
          ...state,
          errors: null
        }
      }
      return state
    case FETCH_USERS:
      return {
      ...state,
      isLoading: true
    };
    case LOAD_USERS:
      return {
        ...state,
        isLoading: false,
        users: action.result,
        errors: null
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        errors: action.result
      }
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export default users
