import React from "react";
import './SurveyBuilderYearQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyBuilderYearQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion} = this.props;
    
    let maxYearOptionsArray = [];
    let minYearOptionsArray = [];
    
    let maxYear = 2100;
    let minYear = 1850;
    
    if(question.max && question.max.value !== undefined){
      maxYear = Number(question.max.value); // TODO: Make sure it's a number, in bounds!
    }
    if(question.min && question.min.value !== undefined){
      minYear = Number(question.min.value); // TODO: Make sure it's a number, in bounds!
    }
    for(let year = minYear; year <= 2100; year += 1){
      maxYearOptionsArray.push({label:year.toString(), value:year.toString()})
    }
    for(let year = 1850; year <= maxYear; year += 1){
      minYearOptionsArray.push({label:year.toString(), value:year.toString()})
    }
    
    return(
      <div className="survey-builder-year-question-cell">
        <LabeledDropdown className="survey-builder-year-question-input"
                         label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                         value={question.min && minYearOptionsArray.filter(function(option){
                           return option.value === question.min.value;
                         })}
                         options={minYearOptionsArray}
                         infoBlurb={<Translation>{(t, { i18n }) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           let updatedQuestion = cloneDeep(question);
                           updatedQuestion.min = {value:aOption.value};
                           updateQuestion(updatedQuestion);
                         }}
        />
  
        <LabeledDropdown className="survey-builder-year-question-input"
                         label={<Translation>{ (t, { i18n }) => t('MAX_VALUE') }</Translation>}
                         value={question.max && maxYearOptionsArray.filter(function(option){
                           return option.value === question.max.value;
                         })}
                         options={maxYearOptionsArray}
                         infoBlurb={<Translation>{(t, { i18n }) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           let updatedQuestion = cloneDeep(question);
                           updatedQuestion.max = {value:aOption.value};
                           updateQuestion(updatedQuestion);
                         }}
        />
      </div>
    )
  }
}
