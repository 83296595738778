import React from 'react';

const PanelSubSectionHeader = ({ title, groupColor, groupName, coreGroupName, buttonClick, buttonTitle }) => (
  <div>
    <div className='panel-subsection-title-container' style={{display: 'flex',width: '100%',
        justifyContent: 'space-between',}}>
        <div style={{display: 'flex',}}>
            <div className='panel-subsection-title-color-block' style={{background: groupName && groupName === coreGroupName ? '#18405a' : groupColor}}></div>
            <span className='panel-subsection-title'>{title}</span>
        </div>

        <button style={{backgroundColor:'#18405a',fontSize: '11px', display: 'flex', justifyContent: 'center', alignItems: 'center',
            borderRadius: '20px',paddingLeft: '17px', marginRight: '20px',
            paddingRight: '17px', paddingTop:'5px', color: 'white', paddingBottom: '5px', border: 'none'}}
                onClick={(aEvent) => {buttonClick && buttonClick(aEvent)}}>{buttonTitle}</button>
    </div>
  </div>
 )

export default PanelSubSectionHeader;
