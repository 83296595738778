import React from "react";
import './SurveyBuilderQuestionSettings.scss';
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import CloseIcon from '@material-ui/icons/Close';
import WhiteButton from "../../../Custom UI/Buttons/WhiteButton/WhiteButton";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import SurveyBuilderQuestionSkip from "./SurveyBuilderQuestionSkip/SurveyBuilderQuestionSkip";
import SurveyBuilderMultiQuestion from "./SurveyBuilderMultiQuestion/SurveyBuilderMultiQuestion";
import SurveyBuilderQuestionOther from "./SurveyBuilderQuestionOther/SurveyBuilderQuestionOther";
import SurveyBuilderQuestionRegex from "./SurveyBuilderQuestionRegex/SurveyBuilderQuestionRegex";
import SurveyBuilderQuestionDBWrite from "./SurveyBuilderQuestionDBWrite/SurveyBuilderQuestionDBWrite";
import SurveyBuilderQuestionDefaults from "./SurveyBuilderQuestionDefaults/SurveyBuilderQuestionDefaults";
import SurveyBuilderQuestionTriggers from "./SurveyBuilderQuestionTriggers/SurveyBuilderQuestionTriggers";
import SurveyBuilderQuestionBranching from "./SurveyBuilderQuestionBranching/SurveyBuilderQuestionBranching";
import SurveyBuilderQuestionImportant from "./SurveyBuilderQuestionImportant/SurveyBuilderQuestionImportant";
import SurveyBuilderQuestionVisibility from "./SurveyBuilderQuestionVisibility/SurveyBuilderQuestionVisibility";
import SurveyBuilderQuestionSafetyAlerts from "./SurveyBuilderQuestionSafetyAlerts/SurveyBuilderQuestionSafetyAlerts";

export default class SurveyBuilderQuestionSettings extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      confirmationModalOpen:false
    };
    this.saveQuestion = this.saveQuestion.bind(this);
  }
  
  // Methods
  
  saveQuestion(isChecked, aKey){
    const { question, updateQuestion } = this.props;
    let updatedQuestion = cloneDeep(question);
    
    if(isChecked){
      updatedQuestion[aKey] = 'true';
    }
    else{
      delete updatedQuestion[aKey];
    }
    updateQuestion(updatedQuestion);
  }
  
  // Render
  
  render(){
    const {close, question, updateQuestion, deleteQuestion, languagesArray, selectedLanguage, errorForIdTypeAndKey,
      surveysArray, questionsArray, adminAlertEmailsArray, addAdminEmail, removeAdminEmailAtIndex, validateRubularRegex,
      updateAdminEmailAtIndex, fetchAppAdditionalColumns, isInModal = false} = this.props;
    const {confirmationModalOpen} = this.state;
    
    const questionTypeHasRegex = ['text', 'number', 'number_choice'];
    const questionTypeCanBeUncertain = ['date', 'datetime', 'monthyear'];
    const questionTypeAllowsCustomAnswer = ['condition', 'medication', 'multi_condition', 'multi_medication'];
    const questionTypeCanHaveMultipleInputs = ['condition', 'medication', 'multi_condition', 'multi_medication'];
    
    const questionTypeCanSkip = ['date', 'list', 'rank', 'text', 'time', 'year', 'radio', 'range', 'number',
      'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication', 'pdf_upload',
      'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
    
    const questionTypeHasOther = ['date', 'list', 'rank', 'time', 'year', 'radio', 'range', 'slider', 'checkbox',
      'datetime', 'monthyear', 'multislider'];
    
    const questionTypeCanBePHI = ['date', 'list', 'rank', 'text', 'time', 'year', 'radio', 'range', 'number', 'number_choice',
      'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication', 'pdf_upload',
      'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
    
    const questionTypeCanBeHSCConsent = ['date', 'list', 'rank', 'text', 'time', 'year', 'radio', 'range', 'number', 'number_choice',
      'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication', 'pdf_upload',
      'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
    
    const questionTypeCanBranch = ['date', 'list', 'rank', 'text', 'time', 'year', 'radio', 'range', 'number', 'number_choice',
      'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication', 'pdf_upload',
      'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
  
    const questionTypeCanHaveDefaultValue = ['date', 'list', 'rank', 'text', 'time', 'year', 'radio', 'range', 'number',
      'number_choice', 'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication',
      'pdf_upload', 'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
  
    const questionTypeCanDBWrite = ['date', 'list', 'rank', 'text', 'time', 'year', 'blank', 'radio', 'range', 'number',
      'number_choice', 'slider', 'checkbox', 'datetime', 'econsent', 'condition', 'monthyear', 'csv_upload', 'medication',
      'pdf_upload', 'multislider', 'dynamic_list', 'econsent_hsc', 'image_upload', 'multi_condition', 'multi_medication'];
    
    const questionTypeCanBeImportant = ['date', 'list', 'text', 'time', 'year', 'radio', 'range', 'number',
      'slider', 'checkbox', 'datetime', 'condition', 'monthyear', 'medication', 'dynamic_list', 'multi_condition', 'multi_medication'];
    
    let importantFlags = 0;
    let disableImportantFlag = false;
    
    for(let aQuestion of questionsArray){
      if(aQuestion.important){
        importantFlags += 1;
      }
    }
    if(importantFlags >= 6 && !(question && Boolean(question.important))){
      disableImportantFlag = true;
    }
    
    return(
      <div className={isInModal ? "survey-builder-question-settings-drawer-modal" : "survey-builder-question-settings-drawer"}>
        <div className="survey-builder-question-settings-header">
          <div className="survey-builder-question-settings-header-text">
            <div>
              <Translation>{(t, {i18n}) => t('SETTINGS')}</Translation>
            </div>
            
            <div>
              {question.type.toUpperCase()} - {question.key}
            </div>
          </div>
          
          <CloseIcon className="survey-builder-question-settings-drawer-close"
                     onClick={() => close()}
          />
        </div>
        
        <div className={isInModal ? "survey-builder-question-settings-drawer-position-modal" : "survey-builder-question-settings-drawer-position"}>
          <div className="survey-builder-question-settings-controls">
            {questionTypeCanBranch.includes(question.type) ?
              <SurveyBuilderQuestionBranching question={question}
                                              surveysArray={surveysArray}
                                              questionsArray={questionsArray}
                                              updateQuestion={updateQuestion}
                                              errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                              fetchAppAdditionalColumns={fetchAppAdditionalColumns}
              />
              :
              null
            }
            
            <SurveyBuilderQuestionVisibility question={question}
                                             surveysArray={surveysArray}
                                             questionsArray={questionsArray}
                                             updateQuestion={updateQuestion}
                                             errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                             fetchAppAdditionalColumns={fetchAppAdditionalColumns}
            />
            
            <SurveyBuilderQuestionTriggers question={question}
                                           questionsArray={questionsArray}
                                           updateQuestion={updateQuestion}
                                           selectedLanguage={selectedLanguage}
                                           errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
            />
            
            <SurveyBuilderQuestionSafetyAlerts question={question}
                                               saveQuestion={(isChecked, aKey) => this.saveQuestion(isChecked, aKey)}
                                               addAdminEmail={addAdminEmail}
                                               errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                               adminAlertEmailsArray={adminAlertEmailsArray}
                                               removeAdminEmailAtIndex={removeAdminEmailAtIndex}
                                               updateAdminEmailAtIndex={updateAdminEmailAtIndex}
            />
            
            {questionTypeCanBeImportant.includes(question.type) ?
              <SurveyBuilderQuestionImportant disabled={disableImportantFlag}
                                              question={question}
                                              updateQuestion={updateQuestion}
                                              selectedLanguage={selectedLanguage}
                                              errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
              />
              :
              null
            }
            
            <LabeledDelayedInput className="survey-builder-question-regex-input"
                                 label={<Translation>{(t, { i18n }) => t('FORM_ID')}</Translation>}
                                 value={question.form_id && question.form_id.length > 0 ? question.form_id : ''}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('FORM_ID_EXPLANATION')}</Translation>}
                                 minLength={0}
                                 handleSave={(aEvent) => {
                                   let updatedQuestion = cloneDeep(question);
                                   updatedQuestion.form_id = aEvent.target.value;
                                   updateQuestion(updatedQuestion);
                                 }}
                                 popoverPlacement={"left"}
            />
            
            {questionTypeCanHaveDefaultValue.includes(question.type) ?
              <SurveyBuilderQuestionDefaults question={question}
                                             updateQuestion={updateQuestion}
                                             errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                             fetchAppAdditionalColumns={fetchAppAdditionalColumns}
              />
              :
              null
            }
            
            {questionTypeCanHaveMultipleInputs.includes(question.type) ?
              <SurveyBuilderMultiQuestion question={question}
                                          updateQuestion={updateQuestion}
              />
              :
              null
            }
            
            {question.type === 'text' ?
              <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                             label={<Translation>{ (t, { i18n }) => t('LARGE_TEXT_AREA') }</Translation>}
                             checked={Boolean(question && question.large_text_area)}
                             onChange={(isChecked) => this.saveQuestion(isChecked, 'large_text_area')}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('LARGE_TEXT_AREA_EXPLANATION') }</Translation>}
                             checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                             popoverPlacement={"left"}
                             unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
              />
              :
              null
            }
            
            {questionTypeCanBeUncertain.includes(question.type) ?
              <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                             label={<Translation>{ (t, { i18n }) => t('UNCERTAIN') }</Translation>}
                             checked={Boolean(question && question.uncertain)}
                             onChange={(isChecked) => this.saveQuestion(isChecked, 'uncertain')}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('UNCERTAIN_EXPLANATION') }</Translation>}
                             checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                             popoverPlacement={"left"}
                             unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
              />
              :
              null
            }
            
            {questionTypeAllowsCustomAnswer.includes(question.type) ?
              <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                             label={<Translation>{(t, {i18n}) => t('ALLOW_CUSTOM_ANSWER')}</Translation>}
                             checked={Boolean(question && question.allow_custom_answer)}
                             onChange={(isChecked) => this.saveQuestion(isChecked, 'allow_custom_answer')}
                             infoBlurb={<Translation>{(t, {i18n}) => t('ALLOW_CUSTOM_ANSWER_EXPLANATION', {aAnswer:(question.type.includes('multi_') ? question.type.substr(6, question.type.length - 1) : question.type)})}</Translation>}
                             checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                             popoverPlacement={"left"}
                             unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
              />
              :
              null
            }
            
            {questionTypeCanBePHI.includes(question.type) ?
              <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                             label={<Translation>{(t, {i18n}) => t('ANSWER_IS_PHI')}</Translation>}
                             checked={Boolean(question && question.is_sensitive_data_question)}
                             onChange={(isChecked) => this.saveQuestion(isChecked, 'is_sensitive_data_question')}
                             infoBlurb={<Translation>{(t, {i18n}) => t('ANSWER_IS_PHI_EXPLANATION')}</Translation>}
                             checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                             popoverPlacement={"left"}
                             unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
              />
              :
              null
            }
            
            {questionTypeCanBeHSCConsent.includes(question.type) ?
              <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                             label={<Translation>{(t, {i18n}) => t('HSC_CONSENT')}</Translation>}
                             checked={Boolean(question && question.hsc_consent)}
                             onChange={(isChecked) => this.saveQuestion(isChecked, 'hsc_consent')}
                             infoBlurb={<Translation>{(t, {i18n}) => t('HSC_CONSENT_ON_EXPLANATION')}</Translation>}
                             checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                             popoverPlacement={"left"}
                             unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
              />
              :
              null
            }
            
            {questionTypeCanSkip.includes(question.type) ?
              <SurveyBuilderQuestionSkip question={question}
                                         languagesArray={languagesArray}
                                         updateQuestion={updateQuestion}
                                         selectedLanguage={selectedLanguage}
                                         errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
              />
              :
              null
            }
            
            {questionTypeHasOther.includes(question.type) ?
              <SurveyBuilderQuestionOther question={question}
                                          languagesArray={languagesArray}
                                          updateQuestion={updateQuestion}
                                          selectedLanguage={selectedLanguage}
                                          errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
              />
              :
              null
            }
            
            {questionTypeHasRegex.includes(question.type) ?
              <SurveyBuilderQuestionRegex question={question}
                                          updateQuestion={updateQuestion}
                                          errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                          validateRubularRegex={validateRubularRegex}
              />
              :
              null
            }
            
            {questionTypeCanDBWrite.includes(question.type) ?
              <SurveyBuilderQuestionDBWrite question={question}
                                            updateQuestion={updateQuestion}
                                            errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                            fetchAppAdditionalColumns={fetchAppAdditionalColumns}
              />
              :
              null
            }
          </div>
          
          <div className="survey-builder-question-settings-button-container">
            <WhiteButton className="create-survey-drawer-button"
                         name={<Translation>{(t, {i18n}) => t('DELETE_QUESTION')}</Translation>}
                         onClick={() => this.setState({confirmationModalOpen:true})}
            />
          </div>
          
          <div className="survey-builder-question-settings-empty-cell"/>
          
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(question.key && question.key.length > 0 ? question.key : i18next.t('QUESTION'))})}
                             isOpen={confirmationModalOpen}
                             reject={() => this.setState({confirmationModalOpen:false})}
                             confirm={() => deleteQuestion(question)}
          />
        </div>
      </div>
    )
  }
}
