import React from 'react';
import './SelectedButton.scss';

const SelectedButton = ({className, title, selected, onClick}) => (
  <button className={selected ? 'selected-button-selected-container ' : 'selected-button-container ' + className}
          onClick={onClick}>
    <div className={selected ? 'selected-button-selected-circle' : 'selected-button-circle'}/>
    
    <span>{title}</span>
  </button>
);

export default SelectedButton;
