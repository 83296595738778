import React from 'react';
import '../PersonalInformation/PersonalInformation.scss'
import { DispositionCodes, AdditionalDispositionCodes } from "../../../../constants/DispositionCodes";
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDatePicker from "../../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../../constants/Notifiers";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../../../Helpers/CompanyHelpers";

const notifyStudyEndDateValidation = () => toast(<Translation>{ (t, { i18n }) => t('ERROR_STUDY_END') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

class ResearchInformation extends React.Component{

  // Init

  constructor(props){
    super(props);
    this.state = {
      edcId:null,
      siteId:null,
      studyId:null,
      studyEndDate:null,
      studyStartDate:null
    };
    if(props.patient){
      this.state.edcId = props.patient.edc_id;
      this.state.siteId = props.patient.site_id;
      this.state.studyId = props.patient.study_id;
      this.state.studyEndDate = props.patient.study_end_date;
      this.state.studyStartDate = props.patient.study_start_date;
    }
    this.handleSave = this.handleSave.bind(this);
    this.handleTextSave = this.handleTextSave.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleNumberSave = this.handleNumberSave.bind(this);
    this.handleDropDownSave = this.handleDropDownSave.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    let { studyStartDate, studyEndDate } = this.state;
    let { patient } = this.props;
    
    if(patient){
      if(patient.study_start_date && patient.study_start_date !== studyStartDate){
        this.setState({studyStartDate:patient.study_start_date});
      }
      if(patient.study_end_date && patient.study_end_date !== studyEndDate){
        this.setState({studyEndDate:patient.study_end_date});
      }
    }
  }

  // Methods

  handleSave(aValue, aKey){
    let { patient, updateUser, fetchPatient } = this.props;
    
    updateUser({id:patient.id, [aKey]:aValue}).then(newResult => {
      fetchPatient({userId:patient.id});
    });
  }

  handleTextSave(aEvent, aKey){
    this.handleSave(aEvent.target.value, aKey);
  }

  handleNumberSave(aEvent, aKey){
    this.handleSave(parseInt(aEvent.target.value), aKey);
  }

  handleDateChange(aDate, aKey){
    this.handleSave((aDate == null ? null : aDate.toString()), aKey);
  }

  handleDropDownSave(aOption, aKey){
    let currentDate = new Date();
    let studyEndDate = new Date(this.props.patient.study_end_date);

    if(aKey === 'disposition_code' && aOption.value === 'study_ended'){
      if(!studyEndDate || (studyEndDate && studyEndDate > currentDate)){
        notifyStudyEndDateValidation();
      }
      else{
        this.handleSave(aOption.value, aKey);
      }
    }
    else{
      this.handleSave(aOption.value, aKey);
    }
  }

  // Render

  render(){
    const { patient, company } = this.props;
    const { edcId, siteId, studyId, studyEndDate, studyStartDate } = this.state;
    
    let dispositionCodeOptions = DispositionCodes.map(code => {
      return {label:code.text, value:code.value}
    });
    
    const bluecCompanyIds = ['123','80', '88', '3'];
    
    const statusCodeOptionsArray = [
      {value:'Disenrolled by patient', label:(i18next.t('DISENROLLED_BY_PATIENT'))},
      {value:'Completed', label:(i18next.t('COMPLETED'))},
      {value:'Disenrolled by coordinator', label:(i18next.t('DISENROLLED_BY_COORDINATOR'))},
      {value:'Failed Screen', label:(i18next.t('FAILED_SCREEN'))},
      {value:'Eligible', label:(i18next.t('ELIGIBLE'))},
      {value:'Ineligible', label:(i18next.t('INELIGIBLE'))},
      {value:'Enrolled', label:(i18next.t('ENROLLED'))},
      {value:'Screened', label:(i18next.t('SCREENED'))},
      {value:'Discontinued', label:(i18next.t('DISCONTINUED'))},
      {value:'Not Yet Screened', label:(i18next.t('NOT_YET_SCREENED'))},
      {value:'Account Deletion Requested', label:(i18next.t('ACCOUNT_DELETION_REQUESTED'))},
      {value:'Pending', label:(i18next.t('PENDING'))},
      {value:'Registered', label:(i18next.t('REGISTERED'))},
      {value:'Cancelled', label:(i18next.t('CANCELLED'))},
      {value:'Inactive', label:(i18next.t('INACTIVE'))}
    ];
  
    const accountCreatedOption = {value:'Account Created', label:(i18next.t('ACCOUNT_CREATED'))}
    
    if(bluecCompanyIds.includes(company.id)){
      AdditionalDispositionCodes.forEach((code) => {
        dispositionCodeOptions.push({label:code.text, value:code.value})
      })
      statusCodeOptionsArray.push(accountCreatedOption);
    }
    
    const yesOrNo = [
      {value:true, label:(i18next.t('YES_PROPER_CAPITALIZED'))},
      {value:false, label:(i18next.t('NO_PROPER_CAPITALIZED'))}
    ];
    
    const researchInfoPermissions = ["admin_level_1", "admin_level_9"];
    const currentRole = roleForCompany(company);

    return (
      <div>
        {!researchInfoPermissions.includes(currentRole) ?
          <div className='personal-information-section'>
            <LabeledDelayedInput label={<Translation>{(t, {i18n}) => t('EDC_ID_UPPERCASE')}</Translation>}
                                 className="personal-information-input personal-information-edc-id"
                                 type='text'
                                 minLength={1}
                                 value={edcId}
                                 handleSave={(aEvent) => {
                                   this.setState({edcId:aEvent.target.value});
                                   this.handleTextSave(aEvent, 'edc_id');
                                 }}
                                 min={1}
            />
            
            <LabeledDelayedInput label={<Translation>{(t, {i18n}) => t('SITE_ID')}</Translation>}
                                 className="personal-information-input personal-information-site-id"
                                 type='text'
                                 minLength={1}
                                 value={siteId}
                                 handleSave={(aEvent) => {
                                   this.setState({siteId:aEvent.target.value});
                                   this.handleTextSave(aEvent, 'site_id');
                                 }}
                                 min={1}
            />
            
            <div className="research-dual-input-large">
              <LabeledDelayedInput label={<Translation>{(t, {i18n}) => t('STUDY_ID')}</Translation>}
                                   className="personal-information-input personal-information-study-id"
                                   type='text'
                                   minLength={1}
                                   value={studyId}
                                   handleSave={(aEvent) => {
                                     this.setState({studyId:aEvent.target.value});
                                     this.handleTextSave(aEvent, 'study_id');
                                   }}
                                   min={1}
              />
              
              <div className="research-dual-input">
                <LabeledDatePicker className="research-input-small-date"
                                   label={<Translation>{(t, {i18n}) => t('START_DATE_TITLE')}</Translation>}
                                   value={studyStartDate}
                                   maxDate={studyEndDate}
                                   handleSave={(aDate) => {
                                     this.setState({studyStartDate:aDate});
                                     this.handleDateChange(aDate, 'study_start_date');
                                   }}
                />
                
                <LabeledDatePicker className="research-input-small-date"
                                   label={<Translation>{(t, {i18n}) => t('END_DATE_TITLE')}</Translation>}
                                   value={studyEndDate}
                                   minDate={studyStartDate}
                                   handleSave={(aDate) => {
                                     this.setState({studyEndDate:aDate});
                                     this.handleDateChange(aDate, 'study_end_date');
                                   }}
                />
              </div>
            </div>
            
            <LabeledDropDown label={<Translation>{(t, {i18n}) => t('DISPOSITION_CODE')}</Translation>}
                             value={patient && dispositionCodeOptions.filter(function (option) {
                               return option.value === patient.disposition_code;
                             })}
                             className="profile-research-information-disposition-code"
                             options={dispositionCodeOptions}
                             handleSave={(aOption) => this.handleDropDownSave(aOption, 'disposition_code')}
            />
            
            <LabeledDropDown label={<Translation>{(t, {i18n}) => t('CONSENT_CONFIRMED')}</Translation>}
                             value={patient && yesOrNo.filter(function (option) {
                               return option.value === patient.consent_confirmed;
                             })}
                             className="profile-research-information-consent-confirmed"
                             options={yesOrNo}
                             handleSave={(aOption) => this.handleDropDownSave(aOption, 'consent_confirmed')}
            />
            
            <LabeledDropDown label={<Translation>{(t, {i18n}) => t('SUBJECT_STATUS')}</Translation>}
                             value={patient && statusCodeOptionsArray.filter(function (option) {
                               return option.value === patient.subject_status;
                             })}
                             className="profile-research-information-subject-status"
                             options={statusCodeOptionsArray}
                             handleSave={(aOption) => this.handleDropDownSave(aOption, 'subject_status')}
            />
  
            {hsFeatureAvailableForCompany(company, 'Number of Vaccination Doses') ?
              <LabeledDelayedInput label={<Translation>{(t, {i18n}) => t('NUMBER_OF_VACCINATION_ENTRIES')}</Translation>}
                                   value={patient && patient.number_of_vaccination_entries}
                                   disabled={true}
              />
               :
               null
             }
            
            {hsFeatureAvailableForCompany(company, 'Number of Pfizer Vaccination Doses') ?
              <LabeledDelayedInput label={<Translation>{(t, {i18n}) => t('NUMBER_OF_PFIZER_VACCINATION_ENTRIES')}</Translation>}
                                   value={patient && patient.number_of_pfizer_vaccination_entries}
                                   disabled={true}
              />
              :
              null
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('RESEARCH_INFORMATION_BLOCKED')}</Translation>
          </div>
        }
      </div>
    );
  }
}
export default ResearchInformation;
