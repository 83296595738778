import {Translation} from "react-i18next";
import React from "react";

export const ReportNames = {
  patients_survey_report:'PATIENTS_SURVEY_REPORT',
  patient_survey_report:'PATIENT_SURVEY_REPORT',
  patient_consent_report_pdf:'PATIENT_CONSENT_REPORT_PDF',
  data_query_report:'DATA_QUERY_REPORT',
  hyperwallet_payment_report:'PAYMENT_REPORT',
  patients_usage_summary_report:'PATIENTS_USAGE_SUMMARY_REPORT',
  patient_survey_report_pdf:'PATIENT_SURVEY_REPORT_PDF',
  download_survey_reports_for_all_users:'DOWNLOAD_SURVEY_REPORTS_FOR_ALL_USERS',
  download_quipment_reports_for_all_users:'DOWNLOAD_QUIPMENT_REPORTS_FOR_ALL_USERS',
  download_adverse_event_reports_for_all_users:'DOWNLOAD_ADVERSE_EVENT_REPORTS_FOR_ALL_USERS',
  download_sdtm_reports_for_all_users:'DOWNLOAD_SDTM_REPORTS_FOR_ALL_USERS',
  download_participant_reports_for_all_users:'DOWNLOAD_PARTICIPANT_REPORTS_FOR_ALL_USERS',
  download_enrollment_reports_for_all_users:'DOWNLOAD_ENROLLMENT_REPORTS_FOR_ALL_USERS',
  export_by_group:'EXPORT_BY_GROUP',
  export_annotated_survey:'ANNOTATED_SURVEY',
  survey_completion_report:'SURVEY_COMPLETION_REPORT',
  call_center_duty_report:'CALL_CENTER_DUTY_REPORT',
  user_eligibility_report:'USER_ELIGIBILITY_REPORT',
  download_flattened_report:'ALL_DATA_DETAILED_REPORT_BY_COUNTRY',
  medication_report:'MEDICATION_REPORT',
  download_all_company_flattened_report:'ALL_DATA_DETAILED_REPORT_FOR_ALL_COUNTRIES',
  call_logs_report:'CALL_LOGS_REPORT',
  download_sdtm_reports_for_all_companies:'DOWNLOAD_SDTM_REPORTS_FOR_ALL_COMPANIES',
  payment_metrics_report:'PAYMENT_METRICS_REPORT',
  call_center_logistics_and_eligibility_report:'CALL_CENTER_LOGISTICS_AND_ELIGIBILITY_REPORT',
  custom_metrics_report:'CUSTOM_METRICS_REPORT',
  download_ineligible_patients_report:'INELIGIBLE_PATIENTS_REPORT',
  download_all_company_ineligible_patients_report:'INELIGIBLE_PATIENTS_REPORT_FOR_ALL_STUDIES',
  hyperwallet_template:'HYPERWALLET_TEMPLATE',
  vaxpath_eligibility_custom_report:'CUSTOM_VAXPATH_ELIGIBILITY_REPORT',
  vaxpath_eligibility_custom_report_all_sites:'CUSTOM_VAXPATH_ELIGIBILITY_REPORT_ALL_SITES',
  download_participant_stats_reports_for_all_users:'PARTICIPANTS_STATS_REPORT',
  vaxpath_icf_report:'ICF_REPORT_FOR_ALL_COUNTRIES',
  download_eye_dropper_quipment_reports_for_all_users:'SHIPMENT_REPORT',
  all_alerts_report:'ALL_ALERTS_REPORT',
  current_alerts_report:'CURRENT_ALERTS_REPORT',
  vaxpath_specific_milestone_report:'VAXPATH_SPECIFIC_MILESTONE_REPORT',
  vaxpath_visit_completion_status_report:'VAXPATH_VISIT_COMPLETION_STATUS_REPORT',
  patients_tools_report:'PATIENTS_TOOLS_REPORT',
  patients_activity_report:'PATIENTS_ACTIVITY_REPORT',
  abbvie_safety_report:'ABBVIE_SAFETY_REPORT',
  abbvie_ceva_report:'ABBVIE_CEVA_REPORT',
  download_survey_version_collection_reports_for_all_users:'DOWNLOAD_SURVEY_REPORTS_FOR_ALL_USERS',
  export_access_report: 'EXPORT_ACCESS_REPORT',

  medication_report_all_sites:'MEDICATION_REPORT_ALL_SITES',
  call_center_logistics_report:'CALL_CENTER_LOGISTICS_AND_ELIGIBILITY',
  download_all_company_sdtm_report:'SDTM_REPORT_FOR_ALL_COUNTRIES',
  survey_completion_by_group_report:'SURVEY_COMPLETION_BY_GROUP_REPORT',
  vaxpath_icf_report_version_numbers:'ICF_VERSION_NUMBER_REPORT_FOR_ALL_COUNTRIES',
  vaxpath_v4_download_flattened_report:'VAXPATH_V',
  vaxpath_v4_download_all_company_flattened_report:'VAXPATH_V_ALL',
  download_pi_reports_for_all_users:'DOWNLOAD_VAXPATH_PARTICIPANT_REPORTS_FOR_ALL_USERS',
  download_data_transfer_report:'DOWNLOAD_DATA_TRANSFER_REPORT',

  new_download_enrollment_reports_for_all_users:'DOWNLOAD_ENROLLMENT_REPORTS_FOR_ALL_NEW_USERS',
  custom:'CUSTOM'
};

export const ReportTypes = {
  patients_survey_report:<Translation>{ (t, { i18n }) => t(ReportNames.patients_survey_report) }</Translation>,
  patient_survey_report:<Translation>{ (t, { i18n }) => t(ReportNames.patient_survey_report) }</Translation>,
  patient_consent_report_pdf:<Translation>{ (t, { i18n }) => t(ReportNames.patient_consent_report_pdf) }</Translation>,
  data_query_report:<Translation>{ (t, { i18n }) => t(ReportNames.data_query_report) }</Translation>,
  hyperwallet_payment_report:<Translation>{ (t, { i18n }) => t(ReportNames.hyperwallet_payment_report) }</Translation>,
  patients_usage_summary_report:<Translation>{ (t, { i18n }) => t(ReportNames.patients_usage_summary_report) }</Translation>,
  patient_survey_report_pdf:<Translation>{ (t, { i18n }) => t(ReportNames.patient_survey_report_pdf) }</Translation>,
  download_survey_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_survey_reports_for_all_users) }</Translation>,
  download_quipment_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_quipment_reports_for_all_users) }</Translation>,
  download_adverse_event_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_adverse_event_reports_for_all_users) }</Translation>,
  download_sdtm_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_sdtm_reports_for_all_users) }</Translation>,
  download_participant_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_participant_reports_for_all_users) }</Translation>,
  download_enrollment_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_enrollment_reports_for_all_users) }</Translation>,
  export_by_group:<Translation>{ (t, { i18n }) => t(ReportNames.export_by_group) }</Translation>,
  export_annotated_survey:<Translation>{ (t, { i18n }) => t(ReportNames.export_annotated_survey) }</Translation>,
  survey_completion_report:<Translation>{ (t, { i18n }) => t(ReportNames.survey_completion_report) }</Translation>,
  call_center_duty_report:<Translation>{ (t, { i18n }) => t(ReportNames.call_center_duty_report) }</Translation>,
  user_eligibility_report:<Translation>{ (t, { i18n }) => t(ReportNames.user_eligibility_report) }</Translation>,
  download_flattened_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_flattened_report) }</Translation>,
  medication_report:<Translation>{ (t, { i18n }) => t(ReportNames.medication_report) }</Translation>,
  download_all_company_flattened_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_all_company_flattened_report) }</Translation>,
  call_logs_report:<Translation>{ (t, { i18n }) => t(ReportNames.call_logs_report) }</Translation>,
  download_sdtm_reports_for_all_companies:<Translation>{ (t, { i18n }) => t(ReportNames.download_sdtm_reports_for_all_companies) }</Translation>,
  payment_metrics_report:<Translation>{ (t, { i18n }) => t(ReportNames.payment_metrics_report) }</Translation>,
  call_center_logistics_and_eligibility_report:<Translation>{ (t, { i18n }) => t(ReportNames.call_center_logistics_and_eligibility_report) }</Translation>,
  custom_metrics_report:<Translation>{ (t, { i18n }) => t(ReportNames.custom_metrics_report) }</Translation>,
  download_ineligible_patients_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_ineligible_patients_report) }</Translation>,
  download_all_company_ineligible_patients_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_all_company_ineligible_patients_report) }</Translation>,
  hyperwallet_template:<Translation>{ (t, { i18n }) => t(ReportNames.hyperwallet_template) }</Translation>,
  vaxpath_eligibility_custom_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_eligibility_custom_report) }</Translation>,
  vaxpath_eligibility_custom_report_all_sites:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_eligibility_custom_report_all_sites) }</Translation>,
  download_participant_stats_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_participant_stats_reports_for_all_users) }</Translation>,
  vaxpath_icf_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_icf_report) }</Translation>,
  download_eye_dropper_quipment_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_eye_dropper_quipment_reports_for_all_users) }</Translation>,
  all_alerts_report:<Translation>{ (t, { i18n }) => t(ReportNames.all_alerts_report) }</Translation>,
  current_alerts_report:<Translation>{ (t, { i18n }) => t(ReportNames.current_alerts_report) }</Translation>,
  vaxpath_specific_milestone_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_specific_milestone_report) }</Translation>,
  vaxpath_visit_completion_status_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_visit_completion_status_report) }</Translation>,
  patients_tools_report:<Translation>{ (t, { i18n }) => t(ReportNames.patients_tools_report) }</Translation>,
  patients_activity_report:<Translation>{ (t, { i18n }) => t(ReportNames.patients_activity_report) }</Translation>,
  abbvie_safety_report:<Translation>{ (t, { i18n }) => t(ReportNames.abbvie_safety_report) }</Translation>,
  abbvie_ceva_report:<Translation>{ (t, { i18n }) => t(ReportNames.abbvie_ceva_report) }</Translation>,
  download_survey_version_collection_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_survey_version_collection_reports_for_all_users) }</Translation>,
  export_access_report:<Translation>{ (t, { i18n }) => t(ReportNames.export_access_report) }</Translation>,

  medication_report_all_sites:<Translation>{ (t, { i18n }) => t(ReportNames.medication_report_all_sites) }</Translation>,
  call_center_logistics_report:<Translation>{ (t, { i18n }) => t(ReportNames.call_center_logistics_report) }</Translation>,
  download_all_company_sdtm_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_all_company_sdtm_report) }</Translation>,
  survey_completion_by_group_report:<Translation>{ (t, { i18n }) => t(ReportNames.survey_completion_by_group_report) }</Translation>,
  vaxpath_icf_report_version_numbers:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_icf_report_version_numbers) }</Translation>,
  vaxpath_v4_download_flattened_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_v4_download_flattened_report) }</Translation>,
  vaxpath_v4_download_all_company_flattened_report:<Translation>{ (t, { i18n }) => t(ReportNames.vaxpath_v4_download_all_company_flattened_report) }</Translation>,
  download_pi_reports_for_all_users:<Translation>{ (t, { i18n }) => t(ReportNames.download_pi_reports_for_all_users) }</Translation>,
  download_data_transfer_report:<Translation>{ (t, { i18n }) => t(ReportNames.download_data_transfer_report) }</Translation>,

  custom_report_template:<Translation>{ (t, { i18n }) => t(ReportNames.new_download_enrollment_reports_for_all_users) }</Translation>,
  custom:<Translation>{ (t, { i18n }) => t(ReportNames.custom) }</Translation>
};

// TODO: Reports with the type custom_report_template need to use the name of the report instead in the Report Cell.
//       Rebuild these constants when the new Report Builder is ready.

export const ReportStatuses = {
  pending:<Translation>{ (t, { i18n }) => t('PENDING') }</Translation>,
  generating:<Translation>{ (t, { i18n }) => t('GENERATING') }</Translation>,
  failed:<Translation>{ (t, { i18n }) => t('FAILED') }</Translation>,
  completed:<Translation>{ (t, { i18n }) => t('COMPLETED') }</Translation>,
  processing:<Translation>{ (t, { i18n }) => t('GENERATING') }</Translation>
};

// // Rails Enums
//
// enum status: {
//     pending: 0,
//     generating: 1,
//     failed: 2,
//     completed: 3,
//     processing: 4
// }
//
// enum report_type: {
//     patients_survey_report: 0,
//     patient_survey_report: 1,
//     patient_consent_report_pdf: 2,
//     data_query_report: 3,
//     hyperwallet_payment_report: 4,
//     patients_usage_summary_report: 5,
//     patient_survey_report_pdf: 6,
//     download_survey_reports_for_all_users: 7,
//     download_quipment_reports_for_all_users: 8,
//     download_adverse_event_reports_for_all_users: 9,
//     download_sdtm_reports_for_all_users: 10,
//     download_participant_reports_for_all_users: 11,
//     download_enrollment_reports_for_all_users: 12,
//     export_by_group: 13,
//     export_annotated_survey: 14,
//     survey_completion_report: 15,
//     call_center_duty_report: 16,
//     user_eligibility_report: 17,
//     download_flattened_report: 20,
//     medication_report: 21,
//     download_all_company_flattened_report: 22,
//     call_logs_report: 23,
//     download_sdtm_reports_for_all_companies: 24,
//     payment_metrics_report: 25,
//     call_center_logistics_and_eligibility_report: 26,
//     custom_metrics_report: 27,
//     download_ineligible_patients_report: 28,
//     download_all_company_ineligible_patients_report: 29,
//     hyperwallet_template: 30,
//     vaxpath_eligibility_custom_report: 31,
//     vaxpath_eligibility_custom_report_all_sites: 32,
//     download_participant_stats_reports_for_all_users: 33,
//     vaxpath_icf_report: 34,
//     download_eye_dropper_quipment_reports_for_all_users: 35,
//     all_alerts_report: 36,
//     current_alerts_report: 37,
//     vaxpath_specific_milestone_report: 38,
//     patients_tools_report: 39,
//     patients_activity_report: 40,
//     abbvie_safety_report: 41,
//     abbvie_ceva_report: 42,
//     download_survey_version_collection_reports_for_all_users: 43
// }
