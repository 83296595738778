import React from 'react';
import "antd/dist/antd.css";
import './PaymentModal.scss';
import {Translation} from "react-i18next";
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const paymentError = (aError) => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const paymentSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoClose);


export default class PaymentModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      payment:'',
      loading:false,
      paymentAmount:'',
      paymentCurrency:'',
      selectedPatient:null
    };
    if(props.patient){
      this.state.selectedPatient = {value:props.patient.id, label:this.formatPatientName(props.patient)};
    }
  }
  
  // Methods
  
  handleCreatePayment = () => {
    let patientId = -1;
    
    if(this.props.patient){
      patientId = this.props.patient.id;
    }
    else if(this.state.selectedPatient){
      patientId = this.state.selectedPatient.value;
    }
    if(patientId !== -1){
      this.setState({loading:true});
      this.props.createPayment(patientId, parseInt(this.state.paymentAmount), this.state.paymentCurrency).then((newResult) => {
        this.setState({loading:false});
        paymentSuccess();
        this.props.closeModalAndReload();
      }, (newError) => {
        let error = '';
        
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        paymentError(error);
        this.setState({loading:false});
        this.props.closeModalAndReload();
      });
    }
  };
  
  formatPatientName(patient){
    let returnValue = '';
    
    if(patient){
      if(patient.last_name != null){
        returnValue =`${patient.first_name} ${patient.last_name}`;
      }
      else{
        returnValue = patient.first_name;
      }
    }
    return returnValue;
  }
  
  // Render
  
  render() {
    let { isOpen, patient, payment, patientsArray, handleCloseModal } = this.props;
    let { loading, paymentCurrency, selectedPatient } = this.state;
    
    let currencyOptions = [
      {value: 'EUR', label: 'EUR'},
      {value: 'USD', label: 'USD'},
      {value: 'CAD', label: 'CAD'}
    ];
    
    let patientOptions = [];
    
    if(patientsArray){
      patientsArray.map((aPatient, index) => (
        patientOptions.push({value:aPatient.id, label:this.formatPatientName(aPatient)})
      ));
    }
    return (
      <GeneralModal isOpen={isOpen}
                    width={'calc(100vw - 30px)'}
                    maxWidth={400}
                    loading={loading}
                    isEditing={Boolean(payment)}
                    handleSave={() => this.handleCreatePayment()}
                    showSaveButton={true}
                    containsDropDown={true}
                    handleCloseModal={() => handleCloseModal()}
                    showCancelButton={true}
                    title={patient ?
                      <span>
                        <Translation>{ (t, { i18n }) => t('PAYMENT_FOR_PATIENT')}</Translation>
                        
                        <span>
                          &nbsp;{this.formatPatientName(patient)}
                        </span>
                      </span>
                      :
                      <Translation>{ (t, { i18n }) => t('PAYMENT')}</Translation>
                    }>
        {patientsArray && patientsArray.length > 0 ?
          <LabeledDropDown className="settings-text-input-edit"
                           label={<Translation>{ (t, { i18n }) => t('SELECT_PATIENT') }</Translation>}
                           value={selectedPatient && patientOptions.filter(function(option){
                             return option.value === selectedPatient.id;
                           })}
                           options={patientOptions}
                           handleSave={(aOption) => this.dropDownLanguageChanged(aOption.value)}
          />
          :
          null
        }
        
        <div className="payment-modal-amount-input-container">
          <LabeledDropDown className="modal-input-small"
                           label={<Translation>{ (t, { i18n }) => t('CURRENCY')}</Translation>}
                           value={paymentCurrency && currencyOptions.filter(function(option){
                             return option.value === paymentCurrency;
                           })}
                           options={currencyOptions}
                           handleSave={(aOption) => {this.setState({paymentCurrency:aOption.value})}}
          />
          
          <LabeledTextInput className="modal-input-small"
                            label={ <Translation>{ (t, { i18n }) => t('AMOUNT')}</Translation>}
                            type='number'
                            placeholder={i18next.t('TAP_TO_ENTER_LOWERCASE')}
                            value={this.state.paymentAmount}
                            handleSave={(aEvent) => this.setState({paymentAmount:aEvent.target.value})}
                            minValue={'0'}
          />
        </div>
      </GeneralModal>
    )
  }
}
