import React from "react";
import DatePicker from 'react-datepicker';
import moment from 'moment'
import {Translation} from "react-i18next";
import {isoDateFormat, dateTimeFormat, isoDateInputFormat, isoDateTimeFormat} from "../../../Helpers/DateHelpers";

export default class SurveyDateQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:null,
      uncertain:false
    };
    if(props.answer != null && props.answer.value != null && props.answer.value !== undefined){
      this.state.answer = moment(props.answer.value);
      
      if(!this.state.answer.isValid()){
        this.state.answer = null;
      }
      this.state.uncertain = !!props.answer.other;
    }
    else if(props.answer != null && props.answer.value != null ){
      this.state.uncertain = !!props.answer.other;
    }
    this.saveAnswer = this.saveAnswer.bind(this);
  }
  
  // Methods
  
  saveAnswer(aDate){
    let date = moment(aDate);
    this.setState({answer:date});
    
    // TODO: Add Translations.
    if(!this.afterMinDate(date)){
      this.props.onError(`Not in Range: Must be after ${this.minValue()}`);
    }
    else if(!this.beforeMaxDate(date)){
      this.props.onError(`Not in Range: Must be before ${this.maxValue()}`);
    }
    else if(this.inRange){
      this.props.onAnswerChange(this.props.question, date.format(isoDateTimeFormat), null, this.state.uncertain);
    }
  }
  
  setUncertain = () => {
    this.setState({uncertain: !this.state.uncertain});
    this.saveAnswer(this.state.answer)
  };
  
  maxValue(){
    return this.props.question.max_value;
  }
  
  minValue(){
    return this.props.question.min_value;
  }
  
  minDate = () => moment(this.minValue(), isoDateFormat).toDate();
  maxDate = () => moment(this.maxValue(), isoDateFormat).toDate();
  inRange = date => this.afterMinDate(moment(date)) && this.beforeMaxDate(moment(date));
  afterMinDate = date => !this.minValue() || date.isAfter(this.minDate());
  beforeMaxDate = date => !this.maxValue() || date.isSameOrBefore(this.maxDate());
  
  // Render
  
  render() {
    const {disabled, question} = this.props;
    const {answer, uncertain} = this.state;
    const {key, type} = question;
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <br/>
        
        <hr/>
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_DATE_INSTRUCTION_TEXT')}</Translation>
        </em>
        
        <hr/>
        
        <div className="survey-month-year-picker"
             style={{marginTop:'25px'}}>
          <DatePicker dateFormat={isoDateInputFormat}
                      disabled={disabled}
                      value={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      selected={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      placeholder={isoDateInputFormat}
                      onChange={(date) => this.saveAnswer(date)}
                      minDate={!this.minValue() ? moment('1920-01-01 00:00:00', dateTimeFormat).toDate() : this.minDate()}
                      maxDate={!this.maxValue() ? moment().add(1, 'years').toDate() : this.maxDate()}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      popperPlacement="bottom-start"
          />
          
          {uncertain ?
            <div className={'survey-input-cell'}>
              <label className={'custom-input-cell'}>
                <input type={"checkbox"}
                       name={'checkobox-choice'}
                       onChange={this.setUncertain}
                       defaultChecked={uncertain}
                />
                
                <span className={'custom-checkmark'}/>
                
                <div className={'inline-text'}>
                  <Translation>{ (t, { i18n }) => t('EXACT_DATE_NOT_CERTAIN')}</Translation>
                </div>
              </label>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
