import EventTracker from '../eventTracker'
import EventTypes from '../constants/EventTypes'

export function fetchEntries(aUserId, aType = null, aQuestId = null, aLimit = 50, aOffset = 0){
  EventTracker.track(EventTypes.GET_ENTRIES);
  let query = `
    query getEntries ($company_id: ID!, $user_id: ID!, $quest_id: ID, $type: String, $offset: Int, $limit: Int) {
      entries (company_id: $company_id, user_id: $user_id, quest_id: $quest_id, type: $type, offset: $offset, limit: $limit){
        entries {
          id
          type
          created_at
          objective {
            id
            vaccination_objective_index
          }
          vaccination_attributes{
            is_baseline
            date_of_dose
            manufacturer
            injection_site
            vaccine_lot_number
            center_of_vaccination
          }
        }
        total
      }
    }
  `;

  let variables = {  };

  return (dispatch, getState) => {
    const { company } = getState();
    variables['company_id'] = company && company.company && company.company.id;
    variables['limit'] = aLimit;
    variables['user_id'] = aUserId;
    variables['offset'] = aOffset;
    
    if(aType){
      variables['type'] = aType;
    }
    if(aQuestId){
      variables['quest_id'] = aQuestId;
    }
    return global.api.post("/queries", { query, variables });
  };
}
