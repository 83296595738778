import React from 'react';
import "antd/dist/antd.css";
import './SurveyToToolModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LanguagePicker from "../../Components/Tools/CreateTool/LanguagePicker/LanguagePicker";

export default class SurveyToToolModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      title:'',
      hscConsent:false,
      onboardingQuest:false,
      toolTranslations:{
        en:{
          title:'',
          locale:'en',
          label_do:null,
          description:null,
          label_history:null,
          label_description:null
        }},
      translationsArray:[{title:"English", locale:"en", display_name:"English (US)"}],
      selectedTranslation:{title:"English", locale:"en", display_name:"English (US)"}
    };
    if(props.initialTitle){
      this.state.title = props.initialTitle;
    }
    if(props.initialHSCConsent){
      this.state.hscConsent = props.initialHSCConsent;
    }
    if(props.initialOnboardingQuest){
      this.state.onboardingQuest = props.initialOnboardingQuest;
    }
    if(props.initialToolTranslationsArray){
      for(let toolTranslation of props.initialToolTranslationsArray){
        for(let translation of props.languages){
          if(translation.locale === toolTranslation.locale){
            this.addOrRemoveTranslation(translation);
            break;
          }
        }
        this.state.toolTranslations[toolTranslation.locale] = toolTranslation;
      }
    }
    this.addOrRemoveTranslation = this.addOrRemoveTranslation.bind(this);
  }
  
  // Methods
  
  addOrRemoveTranslation(aTranslation){
    let {toolTranslations, translationsArray, selectedTranslation} = this.state;
    
    if(aTranslation.locale !== 'en'){
      if(translationsArray && translationsArray.length > 0){
        let indexToRemove = -1;
        
        for(let index = 0; index < translationsArray.length; index += 1){
          let translation = translationsArray[index];
          
          if(translation.locale === aTranslation.locale){
            indexToRemove = index;
            break;
          }
        }
        if(indexToRemove === -1){
          toolTranslations[aTranslation.locale] = {
            title:'',
            locale:aTranslation.locale,
            label_do:null,
            description:null,
            label_history:null,
            label_description:null
          };
          translationsArray.push(aTranslation);
        }
        else{
          if(selectedTranslation.locale === aTranslation.locale){
            for(let translation of this.props.languages){
              if(translation.locale === 'en'){
                selectedTranslation = translation;
                break;
              }
            }
          }
          delete toolTranslations[aTranslation.locale];
          translationsArray.splice(indexToRemove, 1);
        }
        this.setState({toolTranslations:toolTranslations, translationsArray:translationsArray, selectedTranslation:selectedTranslation});
      }
    }
  }
  
  // Render
  
  render() {
    const { isOpen, languages, handleFinish, handleCloseModal, showTranslations = false, disableTitleInput = false } = this.props;
    const { title, hscConsent, onboardingQuest, toolTranslations, translationsArray, selectedTranslation } = this.state;
    
    let toolTranslationsArray = [];
    
    if(showTranslations && toolTranslations){
      for(let key of Object.keys(toolTranslations)){
        toolTranslationsArray.push(toolTranslations[key]);
      }
    }
    else{
      toolTranslationsArray = null;
    }
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('MAKE_A_TOOL') }</Translation>}
                    width={'300px'}
                    height={showTranslations ? '360px' : '300px'}
                    isOpen={isOpen}
                    maxHeight={500}
                    isEditing={false}
                    handleSave={() => handleFinish(title, hscConsent, onboardingQuest, toolTranslationsArray)}
                    showFooter={true}
                    showHeader={true}
                    noTitleHeading={true}
                    showSaveButton={true}
                    handleCloseModal={() => handleCloseModal()}>
        {showTranslations ?
          <>
            <LabeledTextInput className="survey-to-tool-modal-input-container"
                              label={<Translation>{(t, { i18n }) => t('SURVEY_TOOL_TITLE')}</Translation>}
                              value={toolTranslations ? toolTranslations[selectedTranslation.locale].title : ''}
                              infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_TOOL_TITLE_EXPLANATION')}</Translation>}
                              handleSave={(aEvent) => {
                                let toolTranslation = toolTranslations[selectedTranslation.locale];
                                toolTranslation.title = aEvent.target.value;
                                toolTranslations[selectedTranslation.locale] = toolTranslation;
                                this.setState({toolTranslations:toolTranslations});
                              }}
                              popoverPlacement={"top"}
            />
            
            <div className="survey-to-tool-modal-language-picker-container">
              <LanguagePicker isSmall={true}
                              translations={translationsArray}
                              allTranslations={languages}
                              selectTranslation={(aTranslation) => this.setState({selectedTranslation:aTranslation})}
                              selectedTranslation={selectedTranslation}
                              addOrRemoveTranslation={(aTranslation) => this.addOrRemoveTranslation(aTranslation)}
              />
            </div>
          </>
          :
          <LabeledTextInput className="survey-to-tool-modal-input-container"
                            label={<Translation>{(t, { i18n }) => t('SURVEY_TOOL_TITLE')}</Translation>}
                            value={title}
                            disabled={disableTitleInput}
                            infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_TOOL_TITLE_EXPLANATION')}</Translation>}
                            handleSave={(aEvent) => this.setState({title:aEvent.target.value})}
                            popoverPlacement={"top"}
          />
        }
        
        <LabeledSwitch className="survey-to-tool-modal-switch-container"
                       label={<Translation>{ (t, { i18n }) => t('HSC_CONSENT') }</Translation>}
                       checked={hscConsent}
                       onChange={(isChecked) => this.setState({hscConsent:isChecked})}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('HSC_CONSENT_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"top"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        <LabeledSwitch className="survey-to-tool-modal-switch-container"
                       label={<Translation>{ (t, { i18n }) => t('ONBOARDING_QUEST') }</Translation>}
                       checked={onboardingQuest}
                       onChange={(isChecked) => this.setState({onboardingQuest:isChecked})}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('ONBOARDING_QUEST_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"top"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
      </GeneralModal>
    )
  }
}
