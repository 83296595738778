import React from 'react';
import './LabeledExtraButtons.scss';
import SearchIcon from '@material-ui/icons/Search';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseButton from "../../Buttons/CloseButton/CloseButton";

export function extraButtonsCount(aShowSearchIcon, aAddButtonClicked, aEditButtonClicked, aDeleteButtonClicked){
  let returnValue = 0;
  
  if(aShowSearchIcon){
    returnValue += 1;
  }
  if(aAddButtonClicked){
    returnValue += 1;
  }
  if(aEditButtonClicked){
    returnValue += 1;
  }
  if(aDeleteButtonClicked){
    returnValue += 1;
  }
  return returnValue;
}

const LabeledExtraButtons = ({className, showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked}) => {
  
  // Render
  
  return (
    (extraButtonsCount(showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked) > 0 ?
        <div className={'labeled-extra-buttons-container ' + className}>
          {showSearchIcon ?
            <SearchIcon style={{height:'30px', width:'30px', color:'#2D81C9', marginLeft:'5px'}}/>
            :
            null
          }
  
          {addButtonClicked ?
            <AddCircleOutlineIcon style={{height:'30px', width:'30px', color:'#2D81C9', marginLeft:'5px'}}
                                  onClick={addButtonClicked}
            />
            :
            null
          }
  
          {editButtonClicked ?
            <CreateOutlinedIcon style={{height:'30px', width:'30px', color:'#A8A8A8', marginLeft:'5px'}}
                                onClick={editButtonClicked}
            />
            :
            null
          }
  
          {deleteButtonClicked ?
            <CloseButton onClick={deleteButtonClicked}/>
            :
            null
          }
        </div>
        :
        null
    )
  )
};
export default LabeledExtraButtons;
