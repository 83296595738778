import React from 'react';
import './AssignMedicationsModal.scss'
import "antd/dist/antd.css";
import { Input } from "antd";
import NextButton from '../../Custom UI/Buttons/NextButton/NextButton'
import SetReminder from '../../Custom UI/SetReminder/SetReminder'
import {cloneDeep} from "lodash";
import StepLevel from "../../Custom UI/StepLevel/StepLevel";
import {Translation} from "react-i18next";
import i18n from "../../i18n"
import GeneralModal from "../GeneralModal/GeneralModal";
import "../GeneralModal/GeneralModal.scss"
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import CustomRenderedOption from "../../Custom UI/CustomRenderedOption/CustomRenderedOption";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";

const notifyAddingMedicationError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_ADDING_MEDICATION', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyFetchingMedicationsError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_FETCHING_MEDICATIONS', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyFetchingMedicationMatrixError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_FETCHING_MEDICATION_MATRIX', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const { TextArea } = Input;
const TOTAL_STEPS = 3;

export default class AssignMedicationsModal extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      quantity:0,
      formError:'',
      reminders:{},
      concept_id:'',
      extraNotes:'',
      repeatType:'',
      typedQuery:'',
      medication:null,
      currentStep:0,
      objective_id:props.defaultValue && props.defaultValue.id,
      searchResults:[],
      selectedValue:props.defaultValue,
      selectedConcept:null,
      invalidDateRange:false,
      isButtonDisabled:false,
      isCustomMedication:false,
      customMedicationProductName:''
    };
    this.assignMedication = this.assignMedication.bind(this);
  }
  
  componentDidMount(){
    const { fetchMedicationMatrix, defaultValue } = this.props;
    const { selectedValue } = this.state;
    
    if(selectedValue){
      this.setState({conceptName:selectedValue.medication.name})
      this.setState({quantity:selectedValue.quantity})
      this.setState({extraNotes:selectedValue.description})
      this.setState({reminders:selectedValue.reminders})
      this.setState({strength:defaultValue && defaultValue.medication && defaultValue.medication.strength})
      this.setState({dosage_form:defaultValue && defaultValue.medication && defaultValue.medication.form})
      this.setState({route:defaultValue && defaultValue.medication && defaultValue.medication.route})
      fetchMedicationMatrix(this.props.selectedValue.concept_id).then((newResult) => {
        if(newResult.data && newResult.data.data && newResult.data.data.medicationMatrix){
          this.setState({medicationMatrix:newResult.data.data.medicationMatrix});
          this.setState({medication:newResult.data.data.medicationMatrix.products.filter((entry) => entry.combined_id === this.state.selectedValue.medication.ndc)[0]});
        }}).catch((newError) => {
        if(newError.data && newError.data.error){
          notifyFetchingMedicationMatrixError(newError.data.error);
        }
      });
    }
  }
  
  handleChange = (aOption) => {
    if(!aOption.isSearchString){
      this.setState({selectedValue:aOption.drugbank_pcid, selectedConcept:aOption});
      this.props.fetchMedicationMatrix(aOption.drugbank_pcid.toString()).then((newResult) => {
        if(newResult && newResult.data && newResult.data.data && newResult.data.data.medicationMatrix){
          this.setState({medicationMatrix:newResult.data.data.medicationMatrix});
        }}).catch((newError) => {
        if(newError && newError.data && newError.data.error){
          notifyFetchingMedicationMatrixError(newError.data.error);
        }
      });
    }
    else{
      this.setState({selectedValue:aOption.name, selectedConcept:null, isCustomMedication:true, medicationMatrix:null});
    }
  }
  
  selectMedicationProduct = (aOption) => {
    let medication = this.state.medicationMatrix.products.find(product => product.combined_id === aOption.value);
    this.setState({ medication:medication });
  }

  assignMedication() {
    //todo, enhance
    const { extraNotes, medication, quantity, medicationMatrix, objective_id, strength, route, dosage_form, isCustomMedication, customMedicationProductName } = this.state;
    let reminders = [];

    for(let key in this.state.reminders){
      if(this.state.reminders.hasOwnProperty(key)){
        reminders.push(this.state.reminders[key]);
      }
    }

    const questData = (this.props.patient && this.props.patient.quests) || [];
    let filteredQuests = questData.filter(data => data.title === 'Medication Tracker' && data.visibility);
    let activeMedicationQuest = filteredQuests.length > 0 ? filteredQuests[0] : {};
    let questId = activeMedicationQuest && activeMedicationQuest.id;

    if(isCustomMedication){
      this.props.addCustomMedicationToUser({
        customMedicationName:customMedicationProductName,
        userId:this.props.patient && this.props.patient.id,
        extraNotes:extraNotes,
        questId:questId,
        reminders:reminders,
        quantity:parseInt(quantity),
        objective_id:objective_id,
        strength:strength,
        route:route,
        dosage_form:dosage_form}).catch((error) => {
  
        if(error.data && error.data.error){
          notifyAddingMedicationError(error.data.error);
        }
      });
      this.props.handleCloseModal();
    }
    else{
      this.props.addMedicationToUser({medicationId:medication.combined_id,
        userId:this.props.patient && this.props.patient.id,
        extraNotes:extraNotes,
        questId: questId,
        reminders:reminders, quantity:parseInt(quantity), concept_id:medicationMatrix.concept_id, objective_id:objective_id, strength:strength.value, route:route.value, dosage_form:dosage_form.value}).catch((error) => {
        if(error.data && error.data.error){
          notifyAddingMedicationError(error.data.error);
        }
      });
      this.props.handleCloseModal();
    }
  }

  validateModal(step) {
    let formIsValid = true, formError = "";

    if(step === 0){
      const {strength, dosage_form, quantity, route, medication, customMedicationProductName} = this.state;

      if(!strength || !dosage_form || quantity == null || !route || (!medication && !customMedicationProductName)){
        formError = <Translation>{ (t, { i18n }) => t('FORM_NOT_FILLED_OUT_ERROR') }</Translation>;
        formIsValid = false;
      }
      else if(quantity <= 0){
        formError = <Translation>{ (t, { i18n }) => t('FORM_QUANTITY_ERROR') }</Translation>;
        formIsValid = false;
      }
    }
    else if(step === 1){
      const {reminders, repeatType, invalidDateRange} = this.state;
      if(Object.keys(reminders).length === 0){
        if(repeatType !== "as needed"){
          formError = <Translation>{ (t, { i18n }) => t('FORM_NOT_FILLED_OUT_ERROR') }</Translation>;
          formIsValid = false;
        }
        else if(invalidDateRange === true){
          formError = <Translation>{ (t, { i18n }) => t('FORM_INVALID_DATE_RANGE_ERROR') }</Translation>;
          formIsValid = false;
        }
      }
      else{
        Object.keys(reminders).forEach((key) => {
          const reminder = reminders[key];
          
          if(reminder.day_number === undefined || reminder.day_number === "" || !reminder.time){
            formError = <Translation>{ (t, { i18n }) => t('FORM_NOT_FILLED_OUT_ERROR') }</Translation>;
            formIsValid = false;
          }
          else if(invalidDateRange === true){
            formError = <Translation>{ (t, { i18n }) => t('FORM_INVALID_DATE_RANGE_ERROR') }</Translation>;
            formIsValid = false;
          }
        });
      }
    }
    this.setState({formError:formError});
    return formIsValid;
  }
  
  next() {
    const { currentStep } = this.state;
    
    if(!this.validateModal(currentStep)) {
      return;
    }
    if(currentStep === TOTAL_STEPS){
      this.assignMedication()
      this.setState({isButtonDisabled:true});
    }
    else{
      let state = { currentStep:currentStep + 1 };
      this.setState(state);
    }
  }
  
  back() {
    const { currentStep } = this.state;
    
    if(currentStep > 0){
      this.setState({currentStep:currentStep - 1, formError:"", reminders:{}});
    }
  }
  
  clear() {
    this.setState({route:null, quantity:0, strength:null, formError:"", reminders:{}, medication:null, dosage_form:null});
  }
  
  render() {
    const {isOpen, handleCloseModal, assignMedicationsError, fetchMedicationsWithCountryCode} = this.props;
    const {selectedValue, selectedConcept, extraNotes, currentStep, medicationMatrix,
      strength, dosage_form, route, quantity, repeatType, isCustomMedication,
      customMedicationProductName, medication, form} = this.state;
    let selectedMedication = cloneDeep(medication);
  
    let conceptName = '';
    let ingredients = [];
    let medicationName = null;
    let routeOptionsArray = [];
    let strengthOptionsArray = [];
    let dosageFormOptionsArray = [];
    let medicationProductOptionsArray = [];

    if(selectedConcept && selectedConcept.hits && selectedConcept.hits.length > 0){
      conceptName = selectedConcept.hits[0].value.split("<em>").join("").split("</em>").join("");
    }
    else if(!isCustomMedication){
      conceptName = this.state.selectedValue && this.state.selectedValue.medication && this.state.selectedValue.medication.name;
    }
    if(medicationMatrix){
      let strengths = medicationMatrix.strengths;
      let dosage_forms = medicationMatrix.dosage_forms;
      let routes = medicationMatrix.routes;
      let medicationIds = [];
      let medications = [];
      
      if(strength){
        let strength_form_obj = medicationMatrix.strengths.find(strength_form_obj => strength_form_obj.value === strength.value);
        dosage_forms = dosage_forms.filter(dosage_form => strength_form_obj.dosage_forms.includes(dosage_form.value));
        routes = routes.filter(route => strength_form_obj.routes.includes(route.value));
        medicationIds = strength_form_obj.product_ids;
      }
      if(dosage_form){
        let dosage_form_obj = medicationMatrix.dosage_forms.find(dosage_form_obj => dosage_form_obj.value === dosage_form.value);
        strengths = strengths.filter(strength => dosage_form_obj.strengths.includes(strength.value));
        routes = routes.filter(route => dosage_form_obj.routes.includes(route.value));
        medicationIds = medicationIds.filter(value => dosage_form_obj.product_ids.includes(value));
      }
      if(route){
        let route_obj = medicationMatrix.routes.find(route_obj => route_obj.value === route.value);
        strengths = strengths.filter(strength => route_obj.strengths.includes(strength.value));
        dosage_forms = dosage_forms.filter(dosage_form => route_obj.dosage_forms.includes(dosage_form.value));
        medicationIds = medicationIds.filter((aValue) => route_obj.product_ids.includes(aValue));
      }
      medications = medicationMatrix.products.filter((aValue) => medicationIds.includes(aValue.combined_id));
      medications = medications.sort((a, b) => {
        if(a.name.includes(conceptName) && b.name.includes(conceptName)){
          return 0;
        }
        else if(a.name.includes(conceptName) && !b.name.includes(conceptName)){
          return -1;
        }
        else if(!a.name.includes(conceptName) && b.name.includes(conceptName)){
          return 1;
        }
        else{
          return a.name.localeCompare(b.name);
        }
      });
      
      if(medication === null && medications.length === 1){
        selectedMedication = medications[0];
        this.selectMedicationProduct(selectedMedication.combined_id);
      }
      for(let strength of strengths){
        strengthOptionsArray.push({value:strength.value, label:strength.value, key:strength.value});
      }
      for(let dosageForm of dosage_forms){
        dosageFormOptionsArray.push({value:dosageForm.value, label:dosageForm.value, key:dosageForm.value});
      }
      for(let route of routes){
        routeOptionsArray.push({value:route.value, label:route.value, key:route.value});
      }
      for(let medication of medications){
        medicationProductOptionsArray.push({value:medication.combined_id, label:medication.name, key:medication.combined_id});
      }
    }
    if(isCustomMedication){
      ingredients = [
        <div key='0' className='ingredient'>
          <Translation>{ (t, { i18n }) => t('CUSTOM_MEDICATION_UNKNOWN_INGREDIENTS') }</Translation>
        </div>
      ];
    }
    else if(selectedMedication){
      ingredients = selectedMedication.medication_ingredients.map((ingredient, index) => (
        <li key={index} className='ingredient'>
          {ingredient.name} - {ingredient.strength.number} {ingredient.strength.unit}
        </li>
      ));
      medicationName = {value:selectedMedication.name, label:selectedMedication.name};
    }
    let remindersText = '';
    let reminders = [];
    
    if(this.state.reminders){
      for(let key in this.state.reminders){
        if(this.state.reminders.hasOwnProperty(key)){
          reminders.push(this.state.reminders[key]);
        }
      }
    }
    if(reminders){
      remindersText = this.buildRemindersText(reminders);
    }
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('MEDICATION') }</Translation>}
                    isOpen={isOpen}
                    isEditing={selectedValue}
                    handleSave={() => this.handleSave()}
                    onRequestClose={handleCloseModal}
                    containsDropDown={!selectedValue}
                    handleCloseModal={handleCloseModal}>
        <div className="modal-error">
          {this.state.formError}
        </div>
        
        {!selectedValue &&
          <div className="assign-medication-modal-body-container">
            <div className="modal-subtitle-text">
              <Translation>{ (t, { i18n }) => t('SEARCH_MEDICATION_NAME') }</Translation>
            </div>
            
            {assignMedicationsError && <p className='error-message'>{assignMedicationsError}</p>}
            
            <div className="search-symptoms">
              <LabeledDropDown className="assign-medication-search-medication"
                               value={selectedValue}
                               handleSave={this.handleChange}
                               placeholder={<Translation>{(t, {i18n}) => t('SEARCH_MEDICATION_HERE')}</Translation>}
                               fetchObjects={(aLimit, aOffset, aSearchString) => fetchMedicationsWithCountryCode(aSearchString, null, aLimit, aOffset)}
                               notifyFetchFailed={notifyFetchingMedicationsError}
                               customOptionHeight={55}
                               customOptionRenderer={({focusOption, key, option, selectValue, style}) => {
                                 let returnValue;
  
                                 if(option.isSearchString){
                                   returnValue = (
                                     <CustomRenderedOption style={style}
                                                           option={option}
                                                           innerKey={key}
                                                           labelText={<Translation>{(t, {i18n}) => t('CUSTOM_MEDICATION', {medication:option.label})}</Translation>}
                                                           headerText={<Translation>{(t, {i18n}) => t('ADD_CUSTOM_MEDICATIONS')}</Translation>}
                                                           selectValue={selectValue}
                                                           focusOption={focusOption}
                                     />
                                   );
                                 }
                                 else if(option.type === 'hasCustomHeader'){
                                   returnValue = (
                                     <CustomRenderedOption style={style}
                                                           option={option}
                                                           innerKey={key}
                                                           headerText={<Translation>{(t, {i18n}) => t('ADD_FROM_MEDICATION_SEARCH')}</Translation>}
                                                           selectValue={selectValue}
                                                           focusOption={focusOption}
                                     />
                                   );
                                 }
                                 else{
                                   returnValue = (
                                     <div key={key}
                                          style={style}
                                          onClick={() => selectValue(option)}
                                          onMouseEnter={() => focusOption(option)}>
                                       {option.label}
                                     </div>
                                   );
                                 }
                                 return returnValue;
                               }}
                               labelPropertiesArray={['name']}
                               valuePropertiesArray={['drugbank_pcid']}
                               doNotLoadOnMenuBottom={true}
                               optionsArrayFormatter={(aArray, aIsLabel) => {
                                 let medicationOptionsArray = [];
  
                                 for(let searchResult of aArray){
                                   let name = searchResult.name;
    
                                   if(searchResult.hits && searchResult.hits.length > 0){
                                     name = searchResult.hits[0].value;
                                   }
                                   name = name.split("<em>").join("").split("</em>").join("");
                                   medicationOptionsArray.push({value:searchResult.drugbank_pcid, label:name, key:searchResult.drugbank_pcid});
                                 }
                                 if(!aIsLabel && medicationOptionsArray.length > 0){
                                   medicationOptionsArray[0]['type'] = 'hasCustomHeader';
                                   medicationOptionsArray[0]['height'] = 55;
                                 }
                                 return medicationOptionsArray;
                               }}
                               showSearchStringInList={true}
                               responseTotalKeysArray={['data', 'data', 'medications_page', 'total']}
                               responseObjectKeysArray={['data', 'data', 'medications_page', 'medications']}
              />
            </div>
          </div>
        }
        {selectedValue && (medicationMatrix || isCustomMedication) && currentStep === 0 &&
          <div className="assign-medication-modal-body-container">
            <div className="header-button-container">
              <StepLevel totalSteps={TOTAL_STEPS}
                         currentStep={currentStep}
              />
              
              <NextButton name={<Translation>{ (t, { i18n }) => t('NEXT_UPPERCASE') }</Translation>}
                          onClickfunc={() => this.next()}
              />
            </div>
            
            <div>
              <div className="modal-subtitle-text">
                <Translation>{(t, {i18n}) => t('FILL_IN_THE_DETAILS_OF_THE_MEDICATION')}</Translation>
              </div>
              <div className="assign-medication-modal-medication">
                {conceptName}
              </div>
  
              <div className="search-symptoms">
                {isCustomMedication ?
                  <LabeledTextInput className="assign-medication-input"
                                    label={<Translation>{ (t, { i18n }) => t('STRENGTH') }</Translation>}
                                    value={strength}
                                    handleSave={(aEvent) => this.setState({strength:aEvent.target.value})}
                                    placeholder={i18n.t('TYPE_HERE')}
                  />
                  :
                  <LabeledDropDown className="assign-medication-input"
                                   label={<Translation>{ (t, { i18n }) => t('STRENGTH') }</Translation>}
                                   value={strength}
                                   options={!isCustomMedication && strengthOptionsArray}
                                   handleSave={(aOption) => this.setState({strength:aOption})}
                                   placeholder={<Translation>{(t, {i18n}) => t('CLICK_HERE_TO_SELECT')}</Translation>}
                  />}
              </div>
              
              <div className='field-row'>
                <div className='field-group'>
                  <div className="search-symptoms">
                    {isCustomMedication ?
                      <LabeledTextInput className="assign-medication-input"
                                        label={<Translation>{ (t, { i18n }) => t('FORM') }</Translation>}
                                        value={dosage_form}
                                        handleSave={(aEvent) => this.setState({dosage_form:aEvent.target.value})}
                                        placeholder={i18n.t('TYPE_HERE')}
                      />
                      :
                      <LabeledDropDown className="assign-medication-input"
                                       label={<Translation>{ (t, { i18n }) => t('FORM') }</Translation>}
                                       value={form || (dosage_form ? dosage_form : undefined)}
                                       options={dosageFormOptionsArray}
                                       handleSave={(aOption) => this.setState({dosage_form:aOption})}
                                       placeholder={<Translation>{(t, {i18n}) => t('CLICK_HERE_TO_SELECT')}</Translation>}
                      />
                    }
                  </div>
                </div>
                
                <div className='field-group'>
                  <LabeledTextInput className="assign-medication-input"
                                    type="number"
                                    label={<Translation>{ (t, { i18n }) => t('QUANTITY') }</Translation>}
                                    value={quantity}
                                    handleSave={(aEvent) => this.setState({quantity:aEvent.target.value})}
                                    placeholder={i18n.t('TYPE_HERE')}
                  />
                </div>
              </div>
              
              <div className='field-group'>
                <div className="search-symptoms">
                  {isCustomMedication ?
                    <LabeledTextInput className="assign-medication-input"
                                      label={<Translation>{ (t, { i18n }) => t('ROUTE') }</Translation>}
                                      value={route}
                                      handleSave={(aEvent) => this.setState({route:aEvent.target.value})}
                                      placeholder={i18n.t('TYPE_HERE')}
                    />
                    :
                    <LabeledDropDown className="assign-medication-input"
                                     label={<Translation>{ (t, { i18n }) => t('ROUTE') }</Translation>}
                                     value={route ? route : undefined}
                                     options={routeOptionsArray}
                                     handleSave={(aOption) => this.setState({route:aOption})}
                                     placeholder={<Translation>{(t, {i18n}) => t('CLICK_HERE_TO_SELECT')}</Translation>}
                    />
                  }
                </div>
              </div>
  
              <div className="search-symptoms">
                {isCustomMedication ?
                  <LabeledTextInput className="assign-medication-input"
                                    label={<Translation>{ (t, { i18n }) => t('PRODUCT_NAME') }</Translation>}
                                    value={customMedicationProductName}
                                    handleSave={(aEvent) => {
                                      this.setState({customMedicationProductName:aEvent.target.value});
                                    }}
                                    placeholder={i18n.t('TYPE_HERE')}
                  />
                  :
                  <LabeledDropDown className="assign-medication-input"
                                   value={medicationName}
                                   label={<Translation>{ (t, { i18n }) => t('PRODUCT_NAME') }</Translation>}
                                   options={medicationProductOptionsArray}
                                   handleSave={this.selectMedicationProduct}
                                   placeholder={<Translation>{(t, {i18n}) => t('CLICK_HERE_TO_SELECT')}</Translation>}
                  />
                }
              </div>
              
              <NextButton onClickfunc={() => this.clear()}
                          name={<Translation>{ (t, { i18n }) => t('CLEAR') }</Translation>}
                />
                
              <div className="assign-medication-modal-medication">
                <Translation>{ (t, { i18n }) => t('INGREDIENTS') }</Translation>
              </div>
              
              <div className='ingredients-list'>
                <ul>
                  {ingredients}
                </ul>
              
              </div>
            </div>
          </div>
        }
        {selectedValue && (medicationMatrix || isCustomMedication) && currentStep === 1 &&
          <div className="assign-medication-modal-body-container">
            <div className="header-button-container">
              <NextButton onClickfunc={() => this.back()}
                          name={<Translation>{ (t, { i18n }) => t('BACK_UPPERCASE') }</Translation>}
              />
              
              <StepLevel currentStep={currentStep}
                         totalSteps={TOTAL_STEPS}
              />
              
              <NextButton onClickfunc={() => this.next()}
                          name={<Translation>{ (t, { i18n }) => t('NEXT_UPPERCASE') }</Translation>}
              />
            </div>
            
            <div>
              <div className="modal-subtitle-text">
                <Translation>{(t, {i18n}) => t('SETUP_MEDICATION_REMINDERS', {medication:selectedMedication})}</Translation>
              </div>
              
              <SetReminder key={this.state.reminders.length}
                           reminders={this.state.reminders}
                           setReminders={(aValue) => this.setState({ reminders:aValue })}
                           setRepeatType={(aOption) => this.setState({ repeatType:aOption })}
                           setInvalidDateRange={(aValue) => this.setState({ invalidDateRange:aValue })}
              />
            </div>
          </div>
        }
        {selectedValue && (medicationMatrix || isCustomMedication) && currentStep === 2 &&
          <div className="assign-medication-modal-body-container">
            <div className="header-button-container">
              <NextButton onClickfunc={() => this.back()}
                          name={<Translation>{ (t, { i18n }) => t('BACK_UPPERCASE') }</Translation>}
              />
              
              <StepLevel currentStep={currentStep}
                         totalSteps={TOTAL_STEPS}
              />
              
              <NextButton onClickfunc={() => this.next()}
                          name={<Translation>{ (t, { i18n }) => t('FINISH_UPPERCASE') }</Translation>}
              />
            </div>
            
            <div>
              <div className="modal-subtitle-text">
                {isCustomMedication ?
                  <Translation>{(t, {i18n}) => t('CUSTOM_MEDICATION_ADD_NOTES', {medicationName:customMedicationProductName})}</Translation>
                  :
                  <Translation>{(t, {i18n}) => t('MEDICATION_ADD_NOTES', {medication:selectedMedication})}</Translation>
                }
              </div>
              
                <LabeledTextarea className="assign-medication-input-textarea"
                                 label={<Translation>{ (t, { i18n }) => t('EXTRA_NOTES') }</Translation>}
                                 placeholder={i18n.t('NOTES_PLACEHOLDER')}
                                 value={extraNotes}
                                 onChange={(aEvent) => this.setState({ extraNotes:aEvent.target.value })} />
            </div>
          </div>
        }
        {selectedValue && (medicationMatrix || isCustomMedication) && currentStep === 3 &&
          <div className="assign-medication-modal-body-container">
            <div className="header-button-container">
              <NextButton onClickfunc={() => this.back()}
                          name={<Translation>{ (t, { i18n }) => t('NO_UPPERCASE') }</Translation>}
              />
              
              <StepLevel currentStep={currentStep}
                         totalSteps={TOTAL_STEPS}
              />
              
              <NextButton onClickfunc={() => this.next()}
                          disabled={this.state.isButtonDisabled}
                          name={<Translation>{ (t, { i18n }) => t('YES_UPPERCASE') }</Translation>}
              />
            </div>
            
            <div>
              <div className="assign-medication-modal-medication">
                <Translation>{ (t, { i18n }) => t('IS_THIS_CORRECT') }</Translation>
              </div>
              
              <div className="medication-details">
                <Translation>{(t, {i18n}) => t('TAKE')}</Translation>
                <b>{quantity}</b>
                <b>{dosage_form.value}</b>
                <Translation>{(t, {i18n}) => t('OF')}</Translation>
                <b>
                  {isCustomMedication ?
                  customMedicationProductName + ' '
                  :
                  selectedMedication.name + ' '}
                </b>
                <b>{repeatType}</b>
                <b>{remindersText}</b>.
                <br/>
                <br/>
                {reminders.length !== 0 &&
                  <>
                    <Translation>{(t, {i18n}) => t('START_DATE_PROPER_CAPITALIZED_COLON_INFO', {reminder:reminders[0]})}</Translation>
                    <br/>
                    {reminders[0].end_date &&
                      <div>
                        <Translation>{(t, {i18n}) => t('END_DATE_PROPER_CAPITALIZED_COLON', {reminder:reminders[0]})}</Translation>
                      </div>
                    }
                    <br/>
                  </>
                }
                {extraNotes}
              </div>
            </div>
          </div>
        }
      </GeneralModal>
    )
  }
  
  buildRemindersText(reminders){
    let remindersTexts = reminders.map((reminder, index) => {
      let reminderText = " @ ";
      switch(reminder.repeat_type){
        case "as needed":
          return null;
        case "daily":
          break;
        case "weekly":
          const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          reminderText += daysOfTheWeek[reminder.day_number] + " ";
          break;
        case "monthly":
          reminderText += `${this.formatDateInMonth(reminder.day_number)} day `;
          break;
        default:
          break;
      }
      
      reminderText += this.convertTime(reminder.time);
      return reminderText;
    });
    
    if(remindersTexts.length > 2){
      return remindersTexts.slice(0, remindersTexts.length - 1).join(', ') + " and " + remindersTexts[remindersTexts.length - 1];
    }
    else{
      return remindersTexts.join(' and ');
    }
  }
  
  convertTime(time) {
    let hour;
    let reminderTime = (time && time.toString()) || '';
    
    if(reminderTime.length === 3){
      hour = reminderTime.substring(0, 1)
    }
    else if(reminderTime.length === 4){
      hour = reminderTime.substring(0, 2)
    }
    else{
      hour = 0;
    }
    let ampm = hour < 12 ? 'AM' : 'PM';
    hour = hour % 12 || 12;
    let minutes = reminderTime.slice(-2)
    
    if(hour === 12 && minutes === '0'){
      reminderTime = '12:00 AM'
    }
    else{
      reminderTime = `${hour}:${minutes} ${ampm}`
    }
    return reminderTime
  }
  
  formatDateInMonth(day_number){
    switch(day_number){
      case 0:
        return "1st";
      case 1:
        return "2nd";
      case 2:
        return "3rd";
      default:
        return `${day_number}th`;
    }
  }
}
