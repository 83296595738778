import React from "react";
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import {Translation} from "react-i18next";

export default class SurveyCheckboxQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      hidden: false,
      values: {},
      otherOptionSelected: false,
      otherOptionText: "",
      defaultCheckArray: []
    };
    
    if(props.answer && props.answer.prettyValues){
      props.answer.prettyValues.forEach(value => {
        let key = Object.keys(value)[0];
        this.state.defaultCheckArray.push(key);
        this.state.values[key] = true;
      });
    }
    if(props.answer && props.answer.other != null){
      this.state.otherOptionSelected = true;
    }
    if(props.answer && props.answer.other_value != null){
      this.state.otherOptionText = props.answer.other_value;
    }
    this.updateChoices = this.updateChoices.bind(this);
    this.saveTextAnswer = this.saveTextAnswer.bind(this);
    this.otherOptionClicked = this.otherOptionClicked.bind(this);
    this.resetOtherTextBox = this.resetOtherTextBox.bind(this);
  }
  
  // Methods
  
  otherOptionClicked(){
    this.setState({otherOptionSelected: !this.state.otherOptionSelected});
    
    if(!this.state.otherOptionSelected){
      this.props.onAnswerChange(this.props.question, 'other', this.state.values, false, this.state.otherOptionText);
    }
    else{
      this.setState({answer: this.state.otherOptionSelected, otherOptionText:''});
      this.props.onAnswerChange(this.props.question, null, this.state.values, false);
    }
  }
  
  resetOtherTextBox(event){
    this.setState({otherOptionSelected: false, answer: event.target.value, otherOptionText: ''});
    this.props.onAnswerChange(this.props.question, null, this.state.values, false, null);
  }
  
  saveTextAnswer(event){
    this.setState({otherOptionText: event.target.value, answer: true});
    this.props.onAnswerChange(this.props.question, 'other', this.state.values, true, event.target.value);
  }
  
  updateChoices(event){
    let newValues = this.state.values;
    
    if(event.target.value === 'other' && this.state.otherOptionSelected === true){
      newValues[event.target.value] = false;
    }
    else if(newValues[event.target.value] == null){
      newValues[event.target.value] = true;
    }
    else{
      if(newValues[event.target.value]){
        delete newValues[event.target.value];
      }
      else{
        newValues[event.target.value] = true;
      }
    }
    this.setState({values: newValues});
    
    if(this.state.otherOptionSelected === true){
      this.props.onAnswerChange(this.props.question, 'other', newValues, true, this.state.otherOptionText);
    }
    else{
      this.props.onAnswerChange(this.props.question, null, newValues, false);
    }
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {hidden, otherOptionText, defaultCheckArray, otherOptionSelected} = this.state;
    const {key, type, other, choices} = question;
    
    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        {!hidden ?
          <div>
            <hr />
        
            <em>
              <Translation>{ (t, { i18n }) => t('SURVEY_MULTISELECT_INSTRUCTION_TEXT')}</Translation>
            </em>
        
            <hr />
        
            <div>
              {choices && choices.length > 0 ?
                choices.map((choice, index) => {
                  return (
                    <div className={(disabled ? 'disabled-input-cell ' : '') + 'survey-input-cell'}
                         key={key + index}>
                      <label className={'custom-input-cell'}
                             htmlFor={`checkbox_${key}_${index}`}>
                        <input type={'checkbox'}
                               id={`checkbox_${key}_${index}`}
                               disabled={disabled}
                               onClick={this.updateChoices}
                               value={choice[0]}
                               defaultChecked={defaultCheckArray.includes(choice[0])}
                        />
                    
                        <span className={'custom-checkmark'}/>
                    
                        <div className={'inline-text'}>
                          {choice[1]}
                        </div>
                      </label>
                    </div>
                  );
                })
                :
                null
              }
            </div>
        
            {choices && choices.length > 0 && other ?
              <div className={'survey-input-cell'} >
                <label className={'custom-input-cell'}>
                  <input type={"checkbox"}
                         name={'checkbox-choice'}
                         onChange={this.otherOptionClicked}
                         defaultChecked={otherOptionSelected}
                  />
              
                  <span className={'custom-checkmark'}/>
              
                  <div className={'inline-text'}>
                    {question.other_label ? question.other_label : <Translation>{ (t, { i18n }) => t('OTHER')}</Translation>}
                  </div>
                </label>
            
                <input type={'text'}
                       className={'survey-string-input'}
                       value={otherOptionText}
                       disabled={!otherOptionSelected}
                       onChange={this.saveTextAnswer}/>
              </div>
              :
              null
            }
          </div>
          :
          null
        }
      </div>
    )
  }
}
