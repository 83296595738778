import React from 'react';
import 'antd/dist/antd.css';
import './ArticleCell.scss'
import {Translation} from "react-i18next";
import moment from "moment";
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";

export default class ArticleCell extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const { article, className, onClick } = this.props;
    
    return (
      <div className={"article-cell-container " + className} onClick={onClick}>
        <div className="article-cell-header">
          
          {/*TODO: Format.*/}
          <img className="article-cell-source-logo"
               src={article.source.logo ? article.source.logo : '/imgs/app/hsc-logo.png'}
               alt={<Translation>{(t, {i18n}) => t('SPONSORED_LOGO_IMAGE')}</Translation>}
          />
          <div className="article-cell-source-name">
            {article.source.name}
          </div>
          {article.sponsored ?
            <span>
              <span className="article-cell-sponsored-divider">
                •
              </span>
              <span className="article-cell-sponsored">
                <Translation>{(t, {i18n}) => t('SPONSORED')}</Translation>
              </span>
            </span>
            :
            null
          }
          {article.viewed_on ?
            <div className="article-cell-progress-container">
              {!article.completed_on ?
                <div className="article-cell-progress-completed">
                  <CheckIcon style={{fontSize: '15px'}}/>
                </div>
                :
                <div className="article-cell-progress-indicator">
                  <CircularProgress variant="determinate"
                                    color={'white'}
                                    style={{height: '15px', width: '15px', color: 'white'}}
                                    value={article.progress}/>
                </div>
              }
            </div>
            :
            null
          }
        </div>
        <div className="article-cell-image-container">
          <img className="article-cell-image"
               src={article.thumbnail_image ? article.thumbnail_image : '/imgs/app/hsc-logo.png'}
               alt={<Translation>{(t, {i18n}) => t('ARTICLE_IMAGE')}</Translation>}
          />
        </div>

        <div className="article-cell-info">
          <div className="article-cell-title">
            {article.title}
          </div>
  
          <div className="article-cell-short-description">
            {article.short_description}
          </div>
          
          <div className="article-cell-created-at">
            {moment(article.created_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </div>
        </div>
        <span className="article-cell-footer">
          <span className="article-cell-views-icon">
           <VisibilityOutlinedIcon style={{fontSize: '19px'}}/>
          </span>
          <span className="article-cell-view-count">
            {article.views}
          </span>
          {article.survey_has_questions ?
            <>
              <span className="article-cell-survey_has_questions-icon">
                <AssignmentOutlinedIcon style={{fontSize: '19px'}}/>
              </span>
              <span className="article-cell-survey_has_questions">
                <Translation>{(t, {i18n}) => t('QUESTIONS_INCLUDED')}</Translation>
              </span>
            </>
            :
            null
          }
        </span>
      </div>
    )
  }
}
