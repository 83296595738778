import React from 'react';
import "antd/dist/antd.css";
import './FraudEventsListModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import FraudEventsList from "../../Lists/FraudEventsList/FraudEventsList";

export default class FraudEventsListModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  // Render
  
  render() {
    let { isOpen, handleCloseModal, company, group, patient, fetchFraudEvents, fetchFraudStatistics,
      markFraudEventAsVerified, markFraudEventAsFraudulent, markFraudStatisticAsVerified,
      markFraudStatisticAsFraudulent } = this.props;
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('SPAM_FLAGS')}</Translation>}
                    isOpen={isOpen}
                    maxWidth={5000}
                    maxHeight={3000}
                    width={'calc(100vw - 150px)'}
                    height={'calc(100vh - 150px)'}
                    noTitleHeading={true}
                    showSaveButton={false}
                    showCancelButton={true}
                    handleCloseModal={() => handleCloseModal()}>
        <FraudEventsList height={'calc(100vh - 305px)'}
                         listContainerHeight={'calc(100vh - 265px)'}
                         group={group}
                         company={company}
                         patient={patient}
                         showName={true}
                         fetchFraudEvents={fetchFraudEvents}
                         fetchFraudStatistics={fetchFraudStatistics}
                         markFraudEventAsVerified={markFraudEventAsVerified}
                         markFraudEventAsFraudulent={markFraudEventAsFraudulent}
                         markFraudStatisticAsVerified={markFraudStatisticAsVerified}
                         markFraudStatisticAsFraudulent={markFraudStatisticAsFraudulent}
        />
      </GeneralModal>
    )
  }
}
