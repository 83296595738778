import moment from "moment";
import {LOAD_PATIENTS} from 'constants/ActionTypes';
import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function getVirtualAppointments(aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy){
  EventTracker.track(EventTypes.FETCH_VIRTUAL_APPOINTMENTS);
  let query = `
    query getTelehealthVirtualAppointments ($starting_before: String!, $ending_after: String!, $participant_user_id: Int, $search_string: String, $limit: Int, $offset: Int, $group_id: ID) {
      telehealth_virtual_appointment_page (starting_before: $starting_before, ending_after: $ending_after, participant_user_id: $participant_user_id, search_string: $search_string, limit: $limit, offset: $offset, group_id: $group_id){
         telehealth_virtual_appointments {
            id
            start_at
            end_at
            connect_url
            title
            notes
            twilio_room_sid
            secondary_participants {
              id
              joined_at
              user {
                id
                first_name
                last_name
              }
            }
            primary_participant {
              id
              joined_at
              user {
                id
                first_name
                last_name
              }
            }
         }
         total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['starting_before'] = aStartingBefore;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    if(aEndingAfter){
      variables['ending_after'] = aEndingAfter;
    }
    if(aParticipantUserId){
      variables['participant_user_id'] = aParticipantUserId;
    }
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    if(['start_at', 'end_at', 'created_at'].includes(aOrderBy)){
      variables['order_by'] = aOrderBy;
    }
    return global.api.post("/queries", { query, variables });
  };
}

export function getVideoForVirtualAppointment(aVirtualAppointmentId){
  EventTracker.track(EventTypes.FETCH_VIRTUAL_APPOINTMENT_VIDEO);
  let query = `
    mutation GenerateVirtualAppointmentVideoMutation ($generateVirtualAppointmentVideoMutationInput: GenerateVirtualAppointmentVideoMutationInput!) {
        generateVirtualAppointmentVideo (input: $generateVirtualAppointmentVideoMutationInput){
          telehealth_virtual_appointment_video {
            is_ready
            url
          }
        }
      }
  `;
  let variables = {
    generateVirtualAppointmentVideoMutationInput: {
      id:aVirtualAppointmentId
    }
  };
  let operationName = `GenerateVirtualAppointmentVideoMutation`;
  return () => {
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function searchPatients({query, groupName, advancedVariables }) {
  let graphQLQuery = `
      query findUsers($query: String!, $company_id: ID!, $group_id: ID, $preferred_language: String, $alert_type: String,
                      $virtual_appointment_date: String, $disposition_code: String) {
        users(query: $query, company_id: $company_id, roles: ["patient"], group_id: $group_id, preferred_language: $preferred_language,
              alert_type: $alert_type, virtual_appointment_date: $virtual_appointment_date, disposition_code: $disposition_code) {
          id
          first_name
          last_name
          email
          birth_year
          gender
          image_id
          number_of_uncleared_company_notification_receipts
          groups (group_type: "company", core: false, company_id: $company_id) {
            id
            name
            core
            company_id
            group_type
          }
        }
      }
    `;
  let variables = {};

  if(advancedVariables != null){
    variables = {
      query,
      preferred_language: advancedVariables.preferred_language && advancedVariables.preferred_language.length > 0 ? advancedVariables.preferred_language : null,
      alert_type: advancedVariables.alert_type && advancedVariables.alert_type.length > 0 ? advancedVariables.alert_type : null,
      virtual_appointment_date: advancedVariables.virtual_appointment_date,
      disposition_code: advancedVariables.disposition_code && advancedVariables.disposition_code.length > 0 ? advancedVariables.disposition_code : null
    };
  }
  else {
    variables = {query};
  }

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id
    let group = state.company && state.company.company && state.company.company.groups.find(group => group.name === groupName)
    variables['group_id'] = group && group.id
    return global.api.post("/queries", { query: graphQLQuery, variables }).then((result) => {
      let patients = result.data.data.users
      dispatch({
        type: LOAD_PATIENTS,
        result: patients
      });

    }).catch((error) => {
      dispatch({
        type: LOAD_PATIENTS,
        error,
      });
    });
  };
}

export function saveVirtualAppointment(virtualAppointment){
  EventTracker.track(EventTypes.CREATE_VIRTUAL_APPOINTMENT);
  let query = `
    mutation SaveTelehealthVirtualAppointment ($saveTelehealthVirtualAppointmentInput: SaveTelehealthVirtualAppointmentInput!) {
      saveTelehealthVirtualAppointment (input: $saveTelehealthVirtualAppointmentInput){
        telehealth_virtual_appointment {
          id
          start_at
          end_at
          connect_url
          title
          notes
          twilio_room_sid
          secondary_participants {
            id
            user {
              id
              first_name
              last_name
            }
          }
          primary_participant {
            id
            user {
              id
              first_name
              last_name
            }
          }
        }
      }
    }
  `;
  let variables = {
    saveTelehealthVirtualAppointmentInput: {
      start_at: moment(virtualAppointment.start_at).toISOString(),
      end_at: moment(virtualAppointment.end_at).toISOString()
    }
  };

  if(virtualAppointment.id){
    variables.saveTelehealthVirtualAppointmentInput.id = virtualAppointment.id;
  }
  variables.saveTelehealthVirtualAppointmentInput.title = virtualAppointment.title;
  variables.saveTelehealthVirtualAppointmentInput.notes = virtualAppointment.notes;

  if(virtualAppointment.primary_participant && virtualAppointment.primary_participant.user){
    variables.saveTelehealthVirtualAppointmentInput.primary_user_id = virtualAppointment.primary_participant.user.id;
  }
  if(virtualAppointment.secondary_participants){
    variables.saveTelehealthVirtualAppointmentInput.secondary_user_ids = virtualAppointment.secondary_participants.map(participant => participant.user.id);
  }
  let operationName = `SaveTelehealthVirtualAppointment`;
  return (dispatch, getState) => {
    const { company } = getState();
    variables.saveTelehealthVirtualAppointmentInput.company_id = company && company.company && company.company.id;
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function removeVirtualAppointment({virtualAppointmentId}){
  EventTracker.track(EventTypes.REMOVE_VIRTUAL_APPOINTMENT);
  let query = `
    mutation RemoveTelehealthVirtualAppointment ($removeTelehealthVirtualAppointmentInput: RemoveTelehealthVirtualAppointmentInput!) {
        removeTelehealthVirtualAppointment (input: $removeTelehealthVirtualAppointmentInput){
          empty
        }
    }
  `;
  let variables = {
    removeTelehealthVirtualAppointmentInput:{
      id:virtualAppointmentId
    }
  };
  let operationName = `RemoveTelehealthVirtualAppointment`;
  return (dispatch) => {
    return global.api.post("/queries", { query, variables, operationName });
  }
}
