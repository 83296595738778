import React from 'react';
import '../../Custom UI/common.scss'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import i18next from "i18next";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import './TriggersList.scss';
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import TriggerCell from "../../Cells/TriggerCell/TriggerCell";
import TriggerModal from "../../Modals/TriggerModal/TriggerModal";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

const notifyTriggerRemovalError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_REMOVING_REPORT', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyTriggerRemovalSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_TRIGGER_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose);

export default class TriggersList extends React.Component {
  
  // Instance Variables
  
  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.fetchTriggers(limit, offset),
    success: () => this.updateList(),
    fail: (error) => console.error("Error", error)
  });
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      searchString:'',
      totalTriggers:0,
      triggersArray:[],
      loadingTriggers:[],
      selectedTrigger:null,
      menuAnchorElement:null,
      showMakeATriggerModal:false,
      openRemoveTriggerModal:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.removeTrigger = this.removeTrigger.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
  }
  
  componentDidMount() {
    this.scrollManager.fetch(true);
  }
  
  // Methods
  
  updateList(){
    this.setState({triggersArray:this.scrollManager.getList()});
  }
  
  fetchTriggers(limit, offset){
    return this.props.fetchTriggers(limit, offset, this.state.searchString, null, null).then(response => {
      let data = response.data.data.triggers;
      let newArray = data.triggers;
      let triggersArray = this.state.triggersArray.concat(newArray);
      this.setState({triggersArray:triggersArray, totalTriggers:data.total});
      return {objects:newArray, total:data.total};
    });
  }
  
  static formattedDateTime(timestamp){
    const timeZone = moment.tz.guess();
    return moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('L') + ' @ ' + moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('LT z');
  }
  
  closeMenu(){
    this.setState({openRemoveTriggerModal: null, menuAnchorElement:null, selectedTrigger:null});
  }
  
  menuButtonClicked(aEvent, aTrigger){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedTrigger:aTrigger});
  };
  
  removeTrigger(aTriggerId){
    let triggerToRemove = this.state.selectedTrigger;
    this.props.removeTrigger(aTriggerId).then(newResult => {
      this.setState({isEditing:false});
      this.scrollManager.removedItemAtIndex(this.scrollManager.getIndexOfItem(triggerToRemove));
      this.updateList();
      this.setState({totalTriggers:(this.state.totalTriggers - 1)});
      notifyTriggerRemovalSuccess();
    }, newError => {
      let error = '';
      
      if(newError && newError.data && newError.data && newError.data.error){
        error = newError.data.error;
      }
      notifyTriggerRemovalError(error);
    });
    this.setState({menuAnchorElement:null, selectedTrigger:null, openRemoveTriggerModal:false});
  }
  
  // Render
  
  render() {
    const { tools, company, updateTrigger } = this.props;
    let { searchString, totalTriggers, triggersArray, loadingTriggers, selectedTrigger, menuAnchorElement,
      showMakeATriggerModal, openRemoveTriggerModal } = this.state;
    
    let currentRole = roleForCompany(company);
    let expandedWidthClass = '';
  
    if(currentRole === 'admin'){
      expandedWidthClass = 'trigger-header-expanded';
    }
    const triggerPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_10"];
    const triggerActionPermissions = ["admin", "admin_level_4"];
    
    return (
      <div className="main-panel-inner-container">
        {triggerPermissions.includes(currentRole) ?
          <>
            <div className="trigger-list-title">
              <div>
                <Translation>{(t, {i18n}) => t('TOTAL_TRIGGERS')}</Translation> - {totalTriggers}
              </div>
              
              <LabeledDelayedInput className="trigger-title-list-search"
                                   disabled={false}
                                   showSearchIcon={true}
                                   placeholder={i18next.t('TAP_TO_SEARCH')}
                                   value={searchString}
                                   handleSave={(aEvent) => {
                                     this.setState({searchString:aEvent.target.value}, () => {
                                       this.scrollManager.fetch(true)
                                     })
                                   }}
              />
            </div>
            
            <div className="trigger-header">
              {currentRole === 'admin' ?
                <div className="trigger-list-header">
                  <Translation>{(t, {i18n}) => t('EXTRA')}</Translation>
                </div>
                :
                null
              }
              <div className={'trigger-list-header ' + expandedWidthClass}>
                <Translation>{(t, {i18n}) => t('TYPING')}</Translation>
              </div>
              <div className={'trigger-list-header ' + expandedWidthClass}>
                <Translation>{(t, {i18n}) => t('TIMING')}</Translation>
              </div>
              <div className={'trigger-list-header ' + expandedWidthClass}>
                <Translation>{(t, {i18n}) => t('FLAGS')}</Translation>
              </div>
              <div className={'trigger-list-header-targets ' + expandedWidthClass}>
                <Translation>{(t, {i18n}) => t('TARGETS')}</Translation>
              </div>
            </div>
            
            <div id='triggers-list'
                 className="trigger-list-cell-container">
              <InfiniteScroll style={{overflowY:"hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="triggers-list">
                {triggersArray && triggersArray.length > 0 ?
                  <div>
                    {triggersArray.map((trigger, index) => (
                      <TriggerCell key={trigger.id + index}
                                   role={currentRole}
                                   loading={loadingTriggers[trigger.id]}
                                   trigger={trigger}
                                   menuButtonClicked={triggerActionPermissions.includes(currentRole) ?
                                     (aEvent) => this.menuButtonClicked(aEvent, trigger)
                                     :
                                     null
                                   }
                      />
                    ))}
                    
                    <Menu id="simple-menu"
                          anchorEl={menuAnchorElement}
                          keepMounted
                          open={Boolean(menuAnchorElement)}
                          onClose={() => this.closeMenu()}>
                      <MenuItem
                        onClick={() => this.setState({showMakeATriggerModal:true}, () => this.setState({menuAnchorElement:null}))}>
                        <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
                      </MenuItem>
                      
                      <MenuItem style={{position: 'relative'}}
                              onClick={() => this.setState({openRemoveTriggerModal:true})}>
                              <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                      </MenuItem>
                    </Menu>
                  
                  </div>
                  :
                  <div className="no-information-text">
                    <Translation>{(t, {i18n}) => t('NO_TRIGGERS')}</Translation>
                  </div>
                }
              </InfiniteScroll>
            </div>
          </>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('TRIGGER_ACCESS_BLOCKED')}</Translation>
          </div>
        }
  
        {showMakeATriggerModal ?
          <TriggerModal tools={tools}
                        isOpen={showMakeATriggerModal}
                        company={company}
                        trigger={selectedTrigger}
                        canCancel={true}
                        updateTrigger={updateTrigger}
                        handleCloseModal={() => this.setState({
                          showMakeATriggerModal:false,
                          selectedTrigger:null
                        })}
                        handleCloseModalAndReload={() => this.setState({
                          showMakeATriggerModal:false,
                          selectedTrigger:null
                        }, () => this.scrollManager.fetch(true))}
          />
          :
          null
        }
        
        {openRemoveTriggerModal ?
          <GeneralModal title={<Translation>{(t, {i18n}) => t('REMOVE_TRIGGER')}</Translation>}
                        isOpen={openRemoveTriggerModal}
                        isEditing={false}
                        handleSave={() => this.removeTrigger(selectedTrigger.id)}
                        contentLabel="RemoveTriggerModal"
                        noTitleHeading={true}
                        showSaveButton={true}
                        saveButtonTitle={<Translation>{(t, {i18n}) => t('REMOVE')}</Translation>}
                        handleCloseModal={() => this.closeMenu()}
                        showCancelButton={true}
                        clickOutsideToClose={true}
          >
                    <span className="no-information-text">
                        <Translation>{(t, {i18n}) => t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE')}</Translation>
                        <span>
                          <b>
                            {selectedTrigger ? selectedTrigger.title : null}
                          </b>
                          ?
                        </span>
                    </span>
          </GeneralModal>
          :
          null
        }
      </div>
    )
  }
}
