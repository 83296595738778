import React from 'react';
import i18next from "i18next";
import {toast} from "react-toastify";
import DynamicList from "../DynamicList/DynamicList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyUpdateError = (aName, aError) => toast(i18next.t('COMPANY_HS_FEATURE_UPDATE_ERROR', {aName:aName, aError:aError}), NOTIFY_OPTS.autoClose);
const notifyUpdateSuccess = (aName) => toast(i18next.t('COMPANY_HS_FEATURE_UPDATE_SUCCESS', {aName:aName}), NOTIFY_OPTS.autoClose);

export default class HsFeaturesList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  componentDidMount(){
    const {loadCompany} = this.props;
    
    if(loadCompany){
      loadCompany();
    }
  }
  
  // Methods
  
  
  // Render
  
  render() {
    const {company, className, updateCompanyHSFeature} = this.props;
  
    let columnsArray = [];
    columnsArray.push({key:'featureName', columnWeight:2, columnNameKey:'FEATURE_NAME', propertiesArray:['hs_feature'], valueFormatter:(aHSFeature) => {
        return i18next.t('HS_FEATURE_TITLES.' + aHSFeature.title);
      }});
    columnsArray.push({key:'description', columnWeight:4, columnNameKey:'DESCRIPTION', propertiesArray:['hs_feature'], valueFormatter:(aHSFeature) => {
        return i18next.t('HS_FEATURE_DESCRIPTIONS.' + aHSFeature.title);
      }});
    columnsArray.push({key:'enabled', columnWeight:1, columnNameKey:'ENABLED', type:'switch', propertiesArray:['enabled'], handleSave:(aChecked, aHSFeature) => {
        updateCompanyHSFeature(aHSFeature.hs_feature.id, aChecked).then((newResult) => {
          notifyUpdateSuccess(i18next.t('HS_FEATURE_TITLES.' + aHSFeature.hs_feature.title));
        }, (newError) => {
          notifyUpdateError(errorMessageFromServerError(i18next.t('HS_FEATURE_TITLES.' + aHSFeature.hs_feature.title), newError));
        });
      }});
  
    let hsFeaturesArray = [];
    
    if(company && company.company_hs_features && company.company_hs_features.length > 0){
      for(let feature of company.company_hs_features){
        const hsFeature = feature.hs_feature;
        
        if(hsFeature && hsFeature.editable_in_hsc){
          hsFeaturesArray.push(feature);
        }
      }
    }
    
    return (
      <div className={'hs-features-list-container ' + className}>
        <DynamicList id="hs-features-list"
                     showTotal={false}
                     columnsArray={columnsArray}
                     showSearchBar={false}
                     minColumnWidth={120}
                     fixedObjectsArray={hsFeaturesArray}
        />
      </div>
    )
  }
}
