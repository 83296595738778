import React, { useState } from 'react';
import PatientPanelHeading from '../ProfileSectionHeading/ProfileSectionHeading'

const CollapsableSection = ({title, children, bgColor, alertCount, headerColor, addButtonTitle, addButtonClick}) => {
  const [collapsed, setCollapsed] = useState(true)


  return (
    <div>
      <PatientPanelHeading
        title={title}
        toggleFunc={() => setCollapsed(!collapsed)}
        collapseState={collapsed}
        alertCount={alertCount}
        headerColor={headerColor}
        addButtonTitle={addButtonTitle}
        addButtonClick={addButtonClick}
      />
      { collapsed &&
          <div className="patient-panel-profile-information-editable" style={{backgroundColor: bgColor}}>
            {children}
          </div>
      }
    </div>
  )
}
export default CollapsableSection
