import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './PaymentsList.scss';
import PaymentModal from "../../Modals/PaymentModal/PaymentModal";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import FraudEventsListModal from "../../Modals/FraudEventsListModal/FraudEventsListModal";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import DynamicList from "../DynamicList/DynamicList";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import {statusForPayment} from "../../constants/PaymentStatusTypes";
import Popover from "antd/lib/popover";
import Icon from "antd/lib/icon";
import i18next from "i18next";
import {withRouter} from "react-router-dom";
import {formatUserName} from "../../Helpers/AdminHelpers";

const notifyPaymentRetryError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_RETRYING_PAYMENT', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyPaymentRetrySuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_RETRY_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

const notifyPaymentApprovedError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_APPROVING_PAYMENT', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyPaymentApprovedSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_APPROVED') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

const notifyPaymentRejectedError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_REJECTING_PAYMENT', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyPaymentRejectedSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_REJECTED') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

class PaymentsList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      shouldReload:false,
      selectedPayment:null,
      paymentToReplace:null,
      fraudEventsPatient: null,
      shouldApprovePayment:true,
      openConfirmationModal:false,
      showMakeAPaymentModal:false,
      openFraudEventsListModal:false,
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.retryPayment = this.retryPayment.bind(this);
    this.rejectPayment = this.rejectPayment.bind(this);
    this.approvePayment = this.approvePayment.bind(this);
    this.handleCellClick = this.handleCellClick.bind(this);
    this.renderFraudEvents = this.renderFraudEvents.bind(this);
    this.renderPaymentStatus = this.renderPaymentStatus.bind(this);
    this.renderPaymentDetails = this.renderPaymentDetails.bind(this);
    this.handleFraudEventsClick = this.handleFraudEventsClick.bind(this);
    this.handleFraudEventsModalClose = this.handleFraudEventsModalClose.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.group !== this.props.group){
      this.setState({shouldReload:true, showMakeAPaymentModal:false, selectedPayment:null});
    }
    if(!prevProps.group && !prevProps.company && prevProps.company !== this.props.company){
      this.setState({shouldReload:true, showMakeAPaymentModal:false, selectedPayment:null});
    }
  }
  
  // Methods
  
  retryPayment(aPayment){
    const {retryPayment} = this.props;
    this.closeMenu(true);
    
    if(retryPayment){
      retryPayment(aPayment).then((newResponse) => {
        notifyPaymentRetrySuccess();
        let data = newResponse.data.data.retryHyperwalletPayment;
    
        if(data){
          let payment = data.hyperwallet_payment;
          this.setState({paymentToReplace:payment});
        }
      }, newError => {
        let error = '';
        
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyPaymentRetryError(error);
      });
    }
  }
  
  approvePayment(aPayment){
    const {approvePayment} = this.props;
    this.closeMenu(true);
  
    if(approvePayment){
      approvePayment(aPayment).then((newResponse) => {
        notifyPaymentApprovedSuccess();
        let data = newResponse.data.data.verifyHyperwalletPayment;
    
        if(data){
          let payment = data.hyperwallet_payment;
          this.setState({paymentToReplace:payment});
        }
      }, newError => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyPaymentApprovedError(error);
      });
    }
  }
  
  rejectPayment(aPayment){
    const {removePayment} = this.props;
    this.closeMenu(true);
  
    if(removePayment){
      removePayment(aPayment).then((newResponse) => {
        notifyPaymentRejectedSuccess();
        let data = newResponse.data.data.verifyHyperwalletPayment;
    
        if(data){
          let payment = data.hyperwallet_payment;
          this.setState({paymentToReplace:payment});
        }
      }, newError => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyPaymentRejectedError(error);
      });
    }
  }
  
  closeMenu(aDelay = false){
    if(aDelay){
      setTimeout(() => {
        this.setState({selectedPayment:null});
      }, 1000);
    }
    else{
      this.setState({selectedPayment:null});
    }
  }
  
  handleFraudEventsClick(aPayment) {
    if(aPayment){
      const fraudEventsPatient = Object.assign({}, aPayment.user);
      this.setState({openFraudEventsListModal:true, fraudEventsPatient});
    }
  }
  
  handleFraudEventsModalClose() {
    this.setState({openFraudEventsListModal:false, fraudEventsPatient: null});
    this.closeMenu();
  }
  
  renderPaymentDetails(aPayment, hideName){
    let userName = '';
    
    if(!hideName){
      userName = formatUserName(aPayment.user) + ' - ';
    }
    if(aPayment.user){
      if(aPayment.user.edc_id){
        userName = userName + aPayment.user.edc_id;
      }
      else{
        userName = userName + 'N/A';
      }
    }
    return (
      <div className="payment-cell-info">
        <div style={{fontWeight: '600', marginBottom: '10px'}}>
          {userName}
        </div>
        
        {aPayment.payment_trigger ?
          <div style={{display: 'flex'}}>
            &bull; {aPayment.payment_trigger.action_criteria}
          </div>
          :
          <div style={{display: 'flex'}}>
            <Translation>{ (t, { i18n }) => t('DIRECT_PAYMENT')}</Translation>
          </div>
        }
      </div>
    );
  }
  
  renderPaymentStatus(aPayment){
    let approvedByName = formatUserName(aPayment.verified_by_user);
    
    return (
      <div className='payment-cell-paid-info'>
        <div className="payment-cell-paid">
          <div style={{display: 'flex', alignItems: 'center'}}>
            
            {aPayment.status === 'approved' && aPayment.has_successful_hyperwallet_status ?
              <CheckCircleIcon style={{color: '#1B7F4F', fontSize: '15px', marginRight: '5px'}}/>
              :
              <ErrorIcon style={{color: '#E02020', fontSize: '15px', marginRight: '5px'}}/>
            }
            <div>
              {statusForPayment(aPayment)}
            </div>
          </div>
          <div>
            {aPayment.status === 'approved' ?
              <div className="payment-criteria-text-thin">
                <Translation>{ (t, { i18n }) => t('APPROVED_BY')}</Translation>
                <div>{approvedByName}</div>
              </div>
              :
              null
            }
          </div>
          <div>
            {!aPayment.has_successful_hyperwallet_status && aPayment.last_sync_error_message && aPayment.last_sync_error_message.length > 0 ?
              <Popover content={aPayment.last_sync_error_message} placement="bottom">
                <div className="payment-cell-notes-popover-button">
                  <Icon type="ellipsis" />
                </div>
              </Popover>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  }
  
  renderFraudEvents(aPayment){
    let fraudStatus;
    
    if(aPayment && aPayment.user && aPayment.user.fraud_statistic){
      if(aPayment.user.fraud_statistic.verification_status === 'verified'){
        fraudStatus = i18next.t('VERIFIED');
      }
      else if(aPayment.user.fraud_statistic.verification_status === 'fraudulent'){
        fraudStatus = i18next.t('NOT_VERIFIED');
      }
      else{
        fraudStatus = (aPayment.user.fraud_statistic.probability * 100) + '% ' + i18next.t('PROBABILITY_SPAM');
      }
    }
    
    return (
      <div className='payment-cell-fraud'>
        {aPayment && aPayment.user && aPayment.user.fraud_statistic ?
          <div className="payment-cell-fraud-info-status">
            {aPayment.user.fraud_statistic.verification_status === 'verified' ?
              <CheckCircleIcon style={{color: '#1B7F4F', fontSize: '15px'}}/>
              :
              <ErrorIcon style={{color: '#E02020', fontSize: '15px'}}/>
            }
            {fraudStatus}
            
            {aPayment.user.fraud_statistic.verified_comments ?
              <Popover content={aPayment.user.fraud_statistic.verified_comments} placement="bottom">
                <div className="payment-cell-fraud-popover-button">
                  <Icon type="ellipsis"/>
                </div>
              </Popover>
              :
              null
            }
          </div>
          :
          null
        }
        
        {aPayment && aPayment.user && ((aPayment.user.fraud_statistic && aPayment.user.fraud_statistic.verification_status === 'pending') || !aPayment.user.fraud_statistic ) ?
          <span className="payment-cell-fraud-info-count">
            <div className="payment-cell-fraud-alert">
              {aPayment.user.number_of_unverified_fraud_events}
            </div>
            
            <Translation>{ (t, { i18n }) => t('FRAUD_EVENTS_FLAGS')}</Translation>
          </span>
          :
          null
        }
        
        {aPayment && aPayment.user && aPayment.user.fraud_statistic && aPayment.user.fraud_statistic.verified_by_user ?
          <div className="payment-cell-fraud-info-small">
            <Translation>{ (t, { i18n }) => t('VERIFIED_BY')}</Translation>
            {formatUserName(aPayment.user.fraud_statistic.verified_by_user)}
          </div>
          :
          null
        }
      </div>
    );
  }
  
  handleCellClick = (aPayment) => {
    let patientLink = '#';
    
    if(aPayment && aPayment.user){
      let patientId = aPayment.user.id;
      
      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + patientId;
      }
      else{
        patientLink = '/patients/' + patientId;
      }
      patientLink += '?section=payments';
      this.props.history.push(patientLink);
    }
  };
  // Render
  
  render() {
    const {selectedPayment, showMakeAPaymentModal, shouldApprovePayment, openConfirmationModal,
      openFraudEventsListModal, fraudEventsPatient, shouldReload, paymentToReplace} = this.state;
    const {patient, patientsArray, createPayment, height, company, fetchFraudEvents, fetchFraudStatistics,
      markFraudEventAsVerified, markFraudEventAsFraudulent, markFraudStatisticAsVerified,
      markFraudStatisticAsFraudulent, explanationKey, redirectToPatientProfile, fetchPayments, group} = this.props;
    
    const isPaymentPending = aPayment => {
      let returnValue = false;
      if(aPayment?.status === 'pending') {
        returnValue = true;
      }
      return returnValue;
    }

    const shouldRetryPayment = aPayment => {
      let returnValue = false;
      if(aPayment?.status === 'approved' && !aPayment?.has_successful_hyperwallet_status) {
        returnValue = true;
      }
      return returnValue;
    }
    
    let currentRole = roleForCompany(company);
    const paymentProhibitions = ["admin_level_5", "admin_level_6", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11"];
    const addPaymentPermissions = ["admin"];
    const paymentActionPermissions = ["admin", "admin_level_2", "admin_level_4"];
    
    let columnsArray = [];
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'PAYMENT_DATE', propertiesArray:['created_at']});
    columnsArray.push({key:'user', columnWeight:2, columnNameKey:'PAYMENT_DETAIL', templateCell:(aPayment) => this.renderPaymentDetails(aPayment, currentRole === 'admin_level_1')});
    columnsArray.push({key:'amount', columnWeight:1, columnNameKey:'AMOUNT', propertiesArray:['amount'], valueFormatter:(aAmount, aPayment) => {
        let returnValue = '$';
        
        if(aPayment.currency === 'EUR'){
          returnValue = '€';
        }
        returnValue = returnValue + aAmount + ' ' + aPayment.currency;
        return returnValue;
      }});
    columnsArray.push({key:'status', columnWeight:1, columnNameKey:'PAYMENT_STATUS', templateCell:(aPayment) => this.renderPaymentStatus(aPayment)});
    columnsArray.push({key:'fraud', columnWeight:1, columnNameKey:'FRAUD_PROBABILITY', templateCell:(aPayment) => this.renderFraudEvents(aPayment)});
    columnsArray.push({key:'payment_type', columnWeight:1, columnNameKey:'PAYMENT_SOURCE', propertiesArray:['payment_type']});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'RETRY', clickAction:(aPayment) => {
        this.setState({selectedPayment:aPayment});
        this.retryPayment(aPayment);
      }, isValid:(aPayment) => shouldRetryPayment(aPayment)});
    menuItemsArray.push({title:'APPROVE', clickAction:(aPayment) => this.setState({openConfirmationModal:true, shouldApprovePayment:true, selectedPayment:aPayment}), isValid:(aPayment) => isPaymentPending(aPayment)});
    menuItemsArray.push({title:'REJECT', clickAction:(aPayment) => this.setState({openConfirmationModal:true, shouldApprovePayment:false, selectedPayment:aPayment}), isValid:(aPayment) => isPaymentPending(aPayment)});
    menuItemsArray.push({title:'FRAUD_EVENTS_SPAM_FLAGS', clickAction:(aPayment) => {
        this.setState({selectedPayment:aPayment});
        this.handleFraudEventsClick(aPayment);
      }, isValid: (aPayment) => (isPaymentPending(aPayment) || shouldRetryPayment(aPayment))});
    
    return (
      <div>
        {!paymentProhibitions.includes(currentRole) ?
          <div>
            <div id="payments-list"
                 className="payment-cell-list-container"
                 style={{height: height ? height : '100vh - 275px'}}>
              <DynamicList id="group-codes-list"
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={() => this.closeMenu()}
                           selectCell={redirectToPatientProfile ? (aPayment) => this.handleCellClick(aPayment) : null}
                           columnsArray={columnsArray}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchPayments(patient ? patient.id : null, group ? group.id : null, aLimit, aOffset, aSearchString)}
                           shouldReload={shouldReload}
                           menuItemsArray={paymentActionPermissions.includes(currentRole) ? menuItemsArray : null}
                           minColumnWidth={100}
                           totalObjectsKey='PAYMENT_HISTORY'
                           explanationKey={explanationKey}
                           objectToReplace={paymentToReplace}
                           addButtonClicked={addPaymentPermissions.includes(currentRole) && (patient || patientsArray) ? () => this.setState({showMakeAPaymentModal:true}) : null}
                           responseTotalKeysArray={['data', 'data', 'hyperwallet_payments', 'total']}
                           responseObjectKeysArray={['data', 'data', 'hyperwallet_payments', 'hyperwallet_payments']}
                           finishedReplacingObject={() => this.setState({paymentToReplace:null})}
              />
            </div>
            
            {showMakeAPaymentModal ?
              <PaymentModal isOpen={showMakeAPaymentModal}
                            canCancel={true}
                            patient={patient}
                            patientsArray={patientsArray}
                            createPayment={createPayment}
                            closeModalAndReload={() => this.setState({showMakeAPaymentModal:false, shouldReload:true})}
                            handleCloseModal={() => this.setState({showMakeAPaymentModal:false})}
              />
              :
              null
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('PAYMENTS_BLOCKED')}</Translation>
          </div>
        }
  
        {/*TODO: Replace with the Confirmation Modal.*/}
        {openConfirmationModal ?
          <GeneralModal isOpen={openConfirmationModal}
                        width={'300px'}
                        maxWidth={'500px'}
                        title={shouldApprovePayment ?
                          <Translation>{(t, {i18n}) => t('APPROVE_PAYMENT')}</Translation>
                          :
                          <Translation>{(t, {i18n}) => t('REJECT_PAYMENT')}</Translation>
                        }
                        noTitleHeading={true}
                        isEditing={false}
                        showSaveButton={true}
                        showCancelButton={true}
                        clickOutsideToClose={true}
                        saveButtonTitle={shouldApprovePayment ?
                          <Translation>{(t, {i18n}) => t('APPROVE')}</Translation>
                          :
                          <Translation>{(t, {i18n}) => t('REJECT')}</Translation>
                        }
                        handleSave={() => {
                          if(shouldApprovePayment){
                            this.approvePayment(selectedPayment);
                          }
                          else{
                            this.rejectPayment(selectedPayment);
                          }
                          this.setState({openConfirmationModal:false});
                        }}
                        handleCloseModal={() => this.setState({openConfirmationModal:false}, () => this.closeMenu())}>
            <div className="payments-list-text">
              {shouldApprovePayment ?
                <Translation>{ (t, { i18n }) => t('ARE_YOU_SURE_YOU_WANT_TO_APPROVE')}</Translation>
                :
                <Translation>{ (t, { i18n }) => t('ARE_YOU_SURE_YOU_WANT_TO_REJECT')}</Translation>
              }
              
              <b>{selectedPayment && selectedPayment.payment_trigger ? selectedPayment.payment_trigger.action_criteria : null}</b>?
            </div>
          </GeneralModal>
          :
          null
        }
  
        {openFraudEventsListModal ?
          <FraudEventsListModal isOpen={openFraudEventsListModal}
                                height={'300px'}
                                company={company}
                                patient={fraudEventsPatient}
                                showName={false}
                                handleCloseModal={this.handleFraudEventsModalClose}
                                fetchFraudEvents={fetchFraudEvents}
                                fetchFraudStatistics={fetchFraudStatistics}
                                markFraudEventAsVerified={markFraudEventAsVerified}
                                markFraudEventAsFraudulent={markFraudEventAsFraudulent}
                                markFraudStatisticAsVerified={markFraudStatisticAsVerified}
                                markFraudStatisticAsFraudulent={markFraudStatisticAsFraudulent}>
          </FraudEventsListModal>
          :
          null
        }
      </div>
    )
  }
}

export default withRouter(PaymentsList)
