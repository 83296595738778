import React from "react";
import './SurveyBuilderListQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import BlueButton from "../../../../Custom UI/Buttons/BlueButton/BlueButton";
import {Translation} from "react-i18next";
import LabeledDropdown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import SurveyListModal from "../../../../Modals/SurveyListModal/SurveyListModal";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";

export default class SurveyBuilderListQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      list_name:'',
      list_name_ca:'',
      list_name_de:'',
      list_name_es:'',
      list_name_fi:'',
      list_name_fr:'',
      list_name_it:'',
      selectedValue:null,
      list_reference:'',
      list_reference_ca:'',
      list_reference_de:'',
      list_reference_es:'',
      list_reference_fi:'',
      list_reference_fr:'',
      list_reference_it:'',
      showSurveyListModal:false
    };
    if(props.question){
      const {question, fetchSurveyListByReference, fetchSurveyListById} = props;
      
      let listKey = KeyForTranslation('list_reference', props.selectedLanguage);
      let listIdKey = KeyForTranslation('list_id', props.selectedLanguage);
      
      // TODO: Check if references/id's exist, and pop warning if they don't.
      
      if(question[listKey] && fetchSurveyListByReference){
        fetchSurveyListByReference(question[listKey]).then((newResult) => {
          this.setState({selectedValue:newResult.data.data.survey_list});
        });
      }
      else if(question[listIdKey] && fetchSurveyListById){
        fetchSurveyListById(question[listIdKey].toString()).then((newResult) => {
          this.setState({selectedValue:newResult.data.data.survey_list});
        });
      }
    }
  }
  
  // Render
  
  render(){
    const {client, company, question, updateQuestion, selectedLanguage, errorForIdTypeAndKey, addSurveyList,
      fetchSurveyLists, fetchSurveyListById} = this.props;
    const {showSurveyListModal, selectedValue} = this.state;
    
    const listKey = KeyForTranslation('list_reference', selectedLanguage);
    const listIdKey = KeyForTranslation('list_id', selectedLanguage);
    
    return(
      <div className="survey-builder-list-question-cell">
        <LabeledDropdown className="survey-builder-list-question-cell-input"
                         label={<Translation>{(t, {i18n}) => t('LIST_REFERENCE')}</Translation>}
                         value={selectedValue}
                         infoBlurb={<Translation>{(t, { i18n }) => t('LIST_REFERENCE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           this.setState({selectedValue:aOption});
                           let updatedQuestion = cloneDeep(question);
                           
                           if(aOption.reference){
                             updatedQuestion[listKey] = aOption.reference;
  
                             if(updatedQuestion[listIdKey]){
                               delete updatedQuestion[listIdKey];
                             }
                             
                           }
                           else{
                             updatedQuestion[listIdKey] = aOption.id;
  
                             if(updatedQuestion[listKey]){
                               delete updatedQuestion[listKey];
                             }
                           }
                           updateQuestion(updatedQuestion);
                         }}
                         fetchObjects={(aLimit, aOffset, aSearchString) => this.props.fetchSurveyLists(aLimit, aOffset, aSearchString)}
                         errorMessage={errorForIdTypeAndKey(question.id, 'error', listKey).message}
                         warningMessage={errorForIdTypeAndKey(question.id, 'warning', listKey).message}
                         optionsArrayFormatter={(aArray) => {
                           let listOptionsArray = [];
                           let addCurrentKeyToOptions = true;
  
                           for(let list of aArray){
                             if(list.reference && list.reference.length > 0){
                               if(list.reference === question[listKey]){
                                 addCurrentKeyToOptions = false;
                               }
                               listOptionsArray.push({value:list.reference, label:(list.name && list.name.length > 0 ? list.name : list.reference), id:list.id});
                             }
                             else{
                               if(list.id === question[listIdKey]){
                                 addCurrentKeyToOptions = false;
                               }
                               listOptionsArray.push({value:list.id, label:(list.name && list.name.length > 0 ? list.name : list.id), id:list.id});
                             }
                           }
                           if(addCurrentKeyToOptions){
                             if(question[listKey] && question[listKey].length > 0){
                               listOptionsArray.push({value:question[listKey], label:question[listKey], id:question['list_id']});
                             }
                             else if(question[listIdKey] && question[listIdKey].length > 0){
                               listOptionsArray.push({value:question[listIdKey], label:question[listIdKey], id:question['list_id']});
                             }
                           }
                           return listOptionsArray;
                         }}
                         responseTotalKeysArray={['data', 'data', 'survey_lists', 'total']}
                         responseObjectKeysArray={['data', 'data', 'survey_lists', 'survey_lists']}
        />
        
        <div className="survey-builder-list-question-cell-button-container">
          {/*TODO: Implement the download button.*/}
          {/*{false ?*/}
          {/*  <BlueButton className="survey-builder-list-question-cell-button-second"*/}
          {/*              name={<Translation>{(t, {i18n}) => t('DOWNLOAD')}</Translation>}*/}
          {/*              onClick={() => this.setState({showSurveyListModal: true})}*/}
          {/*  />*/}
          {/*  :*/}
          {/*  null*/}
          {/*}*/}
          
          <BlueButton className="survey-builder-list-question-cell-button-second"
                      name={<Translation>{(t, {i18n}) => t('CREATE_PROPER_CAPITALIZED')}</Translation>}
                      onClick={() => this.setState({showSurveyListModal:true})}
          />

        </div>
        
        {showSurveyListModal ?
          <SurveyListModal client={client}
                           isOpen={showSurveyListModal}
                           company={company}
                           handleFinish={(aSurveyList) => {
                             if(aSurveyList && aSurveyList.reference){
                               let updatedQuestion = cloneDeep(question);
                               updatedQuestion[listKey] = aSurveyList.reference;
                               updateQuestion(updatedQuestion);
                             }
                             this.setState({showSurveyListModal:false})
                           }}
                           addSurveyList={addSurveyList}
                           fetchSurveyLists={fetchSurveyLists}
                           handleCloseModal={() => this.setState({showSurveyListModal:false})}
                           fetchSurveyListById={fetchSurveyListById}
          />
          :
          null
        }
      </div>
    )
  }
}
