import React from 'react';
import "antd/dist/antd.css";
import './AddReportColumnModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export default class AddReportColumnModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  // Render
  
  render() {
    const {isOpen, handleSave, handleClose, fetchPreMadeRelationships} = this.props;
    
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:1.5, columnNameKey:'NAME', propertiesArray:['name']});
    columnsArray.push({key:'relationship_configuration_names', columnWeight:2, columnNameKey:'DESCRIPTION', propertiesArray:['relationship_configuration_names']});
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('ADD_REPORT_COLUMN') }</Translation>}
                    width={'500px'}
                    height={'100%'}
                    isOpen={isOpen}
                    maxWidth={500}
                    isEditing={true}
                    showFooter={false}
                    maxHeight={400}
                    noTitleHeading={true}
                    showSaveButton={false}
                    handleCloseModal={() => handleClose()}
                    showCancelButton={false}>
          <div className="add-report-column-modal-dynamic-list">
            <DynamicList id="add-report-column-modal-dynamic-list"
                         selectCell={(aObject, aRow) => {
                           let column = {};
                           
                           if(aObject){
                             column.custom_header_name = aObject.name;
                             column.relationship_arguments = [];
  
                             for(let relationship of aObject.relationship_configuration_names){
                               column.relationship_arguments.push({name:relationship, model_configuration_name:relationship});
                             }
                           }
                           handleSave(column);
                         }}
                         columnsArray={columnsArray}
                         fetchObjects={(aLimit, aOffset, aSearchString) => fetchPreMadeRelationships(aLimit, aOffset, aSearchString)}
                         showSearchBar={false}
                         minColumnWidth={50}
                         responseTotalKeysArray={['data', 'data', 'pre_made_relationships', 'total']}
                         responseObjectKeysArray={['data', 'data', 'pre_made_relationships', 'pre_made_relationships']}
            />
          </div>
  
          <div className="add-report-column-modal-text">
            <Translation>{(t, {i18n}) => t('OR')}</Translation>
          </div>
  
          <div className="add-report-column-modal-add-configuration-container"
               onClick={(aEvent) => handleSave({})}>
            <AddCircleOutlineIcon style={{height:'17px', width:'17px', color:'#2D81C9', marginRight:'3px'}}/>
            
            <div className="add-report-column-modal-add-configuration-text">
  
              <Translation>
                {(t, {i18n}) => t('ADD_BLANK_CONFIGURATION')}
              </Translation>
            </div>
          </div>
      </GeneralModal>
    )
  }
}
