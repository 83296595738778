import React from 'react';
import './BlueButton.scss';
import {FaSortDown} from 'react-icons/fa'
import Loading from "../../Loading/Loading";

const BlueButton = ({className, id, name, style, onClick, loading = false, disabled = false, showDropDownIcon = false}) => (
  <div className={'blue-button-main-container ' + className}
       id={id}
       style={style}>
    <button className="blue-button-main"
            style={{visibility:loading ? 'hidden' : 'visible'}}
            onClick={onClick}
            disabled={loading || disabled}>
      {name}
      
      {showDropDownIcon ?
        <FaSortDown className="blue-button-icon-down"/>
        :
        null
      }
    </button>
    
    <Loading className="blue-button-loading"
             loading={loading}
             showLabel={false}
    />
  </div>
);

export default BlueButton;
