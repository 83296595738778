import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {loadCompanySymptoms} from './company'
import {loadPatientSymptoms} from './patient'

export function fetchSymptoms(aLimit, aOffset, aQuery, aUserId, aGroupId){
  EventTracker.track(EventTypes.FETCH_SYMPTOMS);
  let query = `
  query getSymptoms ($query: String!, $limit: Int, $offset: Int, $user_id: ID, $group_id: ID){
    symptoms_page(query: $query, limit: $limit, offset: $offset, user_id: $user_id, group_id: $group_id){
      symptoms{
        id
        name
      }
      total
    }
  }
  `;
  let variables = {};
  
  return () => {
    variables['query'] = aQuery ? aQuery : '';
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    
    if(aUserId){
      variables['user_id'] = aUserId;
    }
    
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    return global.api.post("/queries", {query, variables});
  };
}

export function addSymptomToUser({symptomId, userId, extraNotes, customSymptom, objectiveId}){
  EventTracker.track(EventTypes.ADD_SYMPTOM_TO_USER);
  let query = `
  mutation AddSymptom($addSymptomInput: AddSymptomInput!) {
    addSymptom(input: $addSymptomInput) {
      symptom {
        id
      }
    }
  }
  `;
  
  let payload = {
    user_id: userId,
    extra_notes: extraNotes
  };
  if(objectiveId){
    payload.objective_id = objectiveId
  }
  customSymptom ? payload.custom_symptom = customSymptom : payload.symptom_id = symptomId;

  let variables = {
    addSymptomInput: payload
  };
  
  let operationName = `AddSymptom`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadPatientSymptoms(userId));
    })
    .catch((error) => {
      return {error: error.data && error.data.error}
    })
  }
}

export function removeSymptomFromUser({symptomId, userId}){
  EventTracker.track(EventTypes.REMOVE_SYMPTOM_FROM_USER);
  let query = `
  mutation RemoveSymptom($removeSymptomInput: RemoveSymptomInput!) {
    removeSymptom(input: $removeSymptomInput) {
      symptom {
        id
      }
    }
  }
  `;
  let variables = {
    removeSymptomInput: {
      user_id: userId,
      symptom_id: symptomId
    }
  };
  let operationName = `RemoveSymptom`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function addSymptomToGroup({symptomId, groupId, extraNotes, customSymptom}){
  EventTracker.track(EventTypes.ADD_SYMPTOM_TO_GROUP);
  let query = `
  mutation AddSymptomToGroup($addSymptomToGroupInput: AddSymptomToGroupInput!) {
    addSymptomToGroup(input: $addSymptomToGroupInput) {
      symptom {
        id
      }
    }
  }
  `;

  let payload = {
    group_id: groupId,
    extra_notes: extraNotes,
  };
  customSymptom ? payload.custom_symptom = customSymptom : payload.symptom_id = symptomId;

  let variables = {
    addSymptomToGroupInput: payload
  };
  let operationName = `AddSymptomToGroup`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanySymptoms());
    }).catch((error) => {
      return {error: error.data && error.data.error}
    })
  }
}

export function removeSymptomFromGroup({symptomId, groupId}){
  EventTracker.track(EventTypes.REMOVE_SYMPTOM_FROM_GROUP);
  let query = `
  mutation RemoveFromGroupSymptom($removeSymptomFromGroupInput: RemoveSymptomFromGroupInput!) {
    removeSymptomFromGroup(input: $removeSymptomFromGroupInput) {
      symptom {
        id
      }
    }
  }
  `;
  let variables = {
    removeSymptomFromGroupInput: {
      group_id: groupId,
      symptom_id: symptomId
    }
  };
  let operationName = `RemoveSymptomFromGroup`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    }).then((result) => {
      dispatch(loadCompanySymptoms());
    })
  }
}
