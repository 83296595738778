import React from 'react';
import ProfilePicture from '../../Custom UI/ProfilePicture/ProfilePicture'
import './ManagePatientsUsersCell.scss'
import groupColorPicker from 'utils/ColorPicker'
import {BiDotsVerticalRounded} from "react-icons/bi";
import { withRouter } from 'react-router-dom';
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {translatedStringForRole} from "../../Helpers/AdminHelpers";

class ManagePatientsUsersCell extends React.Component {

  // Init

  constructor(props) {
    super(props);
    this.state = {
      showRemove: false
    }
  }

  // Methods

  showRemove() {
    this.setState({ showRemove: true });
  }

  hideRemove() {
    this.setState({ showRemove: false })
  }

  handlePatientCellClick = () => {
    let patientLink = '/patients/' + this.props.user.id;
    this.props.history.push(patientLink);
  };

  // Render

  render() {
    const { user, manageUsers, company, menuButtonClicked } = this.props; // TODO: Add menuButtonClicked to end of props.
    let labels = (<div/>);
    let labelNames = user.groups;
    let saltNumber = Math.floor(((parseInt(user.id) * 19)^3) / 2);
    let currentRole = roleForCompany(company);
    const patientListPermissions = ["admin_level_1"];

    if(labelNames){
      labels = labelNames.map((label) => {
        return (
          <div key={label.id} style={{backgroundColor: groupColorPicker(label.id)}} className='label-container'></div>
        )
      })
    }
    return (
      <div className="manage-patients-user-list-item-cell" onClick={this.handlePatientCellClick}>
        <div className="manage-patient-admin-name">
          <ProfilePicture
            imageId={user.image_id}
            width={40}
            height={40}
            className='profile-pics profile-pic-manage-user'
            style={{ border: 'none', width: '30px!important', height: 'auto', overflow:'hidden' }}
          />
          <div className='manage-patient-contact'>
            {!patientListPermissions.includes(currentRole) && (
              <div className='manage-patient-name'>{user.first_name} {user.last_name}</div>)}
            {!patientListPermissions.includes(currentRole) && (
              <div className='manage-patient-email'>{user.email}</div>)}
          </div>
        </div>

        <div className="patient-permissions-cell">
          {patientListPermissions.includes(currentRole) && (
            <div className='manage-patient-permission'>{user.id}{saltNumber}</div>
          )}
          {manageUsers && (
            <div className='email'>{translatedStringForRole(user.role)}</div>
          )}
          <BiDotsVerticalRounded height="16px"
                                 width="16px"
                                 fill="#A8A8A8"
                                 style={{fontSize: '21px', marginLeft: 'auto'}}
                                 onClick={(aEvent) => menuButtonClicked(aEvent, user)}
          />
        </div>

        <div className='patient-groups-labels-container'>
          {labels}
        </div>

        {/*<AlertIndicator number={user.number_of_uncleared_company_notification_receipts} />*/}
      </div>
    )
  }
}

export default withRouter(ManagePatientsUsersCell);
