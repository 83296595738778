import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function getArticles(aLimit, aOffset, aCompleted, aSearchString, aSelectedArticleTopicIdsArray){
  EventTracker.track(EventTypes.FETCH_ARTICLES);
  let query = `
  query getArticles ($company_id: ID!, $limit: Int, $offset: Int, $completed: Boolean, $search_string: String, $selected_article_topic_ids_array: [ID]) {
      articles (company_id: $company_id, limit: $limit, offset: $offset, completed: $completed, search_string: $search_string, selected_article_topic_ids_array: $selected_article_topic_ids_array){
          articles {
            id
            image
            title
            views
            progress
            sponsored
            survey_id
            viewed_on
            created_at
            updated_at
            completed_on
            language_code
            thumbnail_image
            short_description
            survey_response_id
            survey_has_questions
            source {
              id
              logo
              name
            }
            topics {
              id
              title
            }
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    
    if(aCompleted !== null){
      variables['completed'] = aCompleted;
    }
    if(aSelectedArticleTopicIdsArray && aSelectedArticleTopicIdsArray.length > 0){
      variables['selected_article_topic_ids_array'] = aSelectedArticleTopicIdsArray;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function viewArticle(aArticleId, aUserId){
  EventTracker.track(EventTypes.VIEW_ARTICLE);
  let query = `
  query viewArticle ($company_id: ID!, $user_id: ID, $article_id: ID!) {
      article (company_id: $company_id, user_id: $user_id, article_id: $article_id){
          id
          image
          title
          views
          progress
          sponsored
          survey_id
          viewed_on
          created_at
          updated_at
          completed_on
          language_code
          thumbnail_image
          short_description
          survey_response_id
          survey_has_questions
          source {
              id
              logo
              name
            }
            topics {
              id
              title
            }
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['user_id'] = aUserId;
    variables['article_id'] = aArticleId;
    return global.api.post("/queries", { query, variables })
  };
}

export function getArticleTopics(aUserId, aLimit, aOffset){
  EventTracker.track(EventTypes.FETCH_ARTICLE_TOPICS);
  let query = `
  query getArticleTopics ($company_id: ID!, $user_id: ID, $limit: Int, $offset: Int) {
      article_topics (company_id: $company_id, user_id: $user_id, limit: $limit, offset: $offset){
          article_topics {
            id
            title
            selected
            language_code
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['$user_id'] = aUserId;
    return global.api.post("/queries", { query, variables })
  };
}

export function getRelatedArticles(aArticleId){
  EventTracker.track(EventTypes.FETCH_RELATED_ARTICLES);
  let query = `
  query getRelatedArticles ($company_id: ID!, $article_id: ID) {
      articles (company_id: $company_id, article_id: $article_id){
          articles {
            id
            image
            title
            views
            progress
            sponsored
            survey_id
            viewed_on
            created_at
            updated_at
            completed_on
            language_code
            thumbnail_image
            short_description
            survey_response_id
            survey_has_questions
            source {
              id
              logo
              name
            }
            topics {
              id
              title
            }
          }
          total
        }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['article_id'] = aArticleId;
    return global.api.post("/queries", { query, variables })
  };
}

export function getNewArticlesCount(aUserId){
  EventTracker.track(EventTypes.FETCH_NEW_ARTICLES_COUNT);
  let query = `
  query getNewArticlesCount ($company_id: ID!, $user_id: ID) {
      new_articles_count (company_id: $company_id, user_id: $user_id)
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['$user_id'] = aUserId;
    return global.api.post("/queries", { query, variables })
  };
}
