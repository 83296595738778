import React from 'react';
import './SecondaryHeader.scss';
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import {SaveAltRounded} from "@material-ui/icons";
import groupColorPicker from "../../../utils/ColorPicker";
import {DEFAULT_COLORS} from "../../../constants/Colors";
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";

const SecondaryHeader = ({ title, patient, group, downloadClick, company }) => {

  let groupColour = DEFAULT_COLORS.MAIN_BLUE;

  if(group && group.core === false){
    groupColour = groupColorPicker(group.id);
  }
  const patientNameRestrictions = ['admin_level_1', 'admin_level_5', 'admin_level_6'];

  let currentRole = roleForCompany(company);

  const edcIDString = useTranslation('', {i18n}).t('EDC_ID_UPPERCASE');
  const siteIDString = useTranslation('', {i18n}).t('SITE_ID');
  
  const patientId = (aPatient) => {
    let returnValue = '';
    
    if(aPatient){
      if(aPatient.edc_id){
        if(aPatient.site_id){
          returnValue = `${siteIDString}: ${aPatient.site_id} ${edcIDString}: ${aPatient.edc_id}`;
        }
        else{
          returnValue = `${edcIDString}: ${aPatient.edc_id}`;
        }
      }
      else if(aPatient.site_id){
        returnValue = `${siteIDString}: ${aPatient.site_id}`;
      }
      else{
        returnValue = aPatient.id;
      }
    }
    return returnValue;
  };

  const formattedUserName = (aUser) => {
    let returnValue = '';

    if(aUser){
      if(aUser.last_name != null){
        returnValue = `${aUser.first_name} ${aUser.last_name}`;
      }
      else{
        returnValue = aUser.first_name;
      }
    }
    return returnValue;
  };

  return (
    <div className="secondary-header-container" style={{backgroundColor:groupColour}}>
      {patient && patient.id ?
        <ProfilePicture className='profile-pics profile-pic-patient-cell'
                        width={30}
                        height={30}
                        style={{width: '30px', height: '30px', overflow: 'hidden'}}
                        imageId={!patientNameRestrictions.includes(currentRole) && patient && patient.id}
        />
        :
        null
      }
      {group && group.id && group.name && group.name.length > 0 ?
        <div className="secondary-header-circle">
          {group.name.charAt(0).toUpperCase()}
        </div>
        : null
      }
      {patient && patient.id ?
        !patientNameRestrictions.includes(currentRole) ?
          <div className="secondary-header-title">{formattedUserName(patient)} - {patientId(patient)}</div>
          :
          <div className="secondary-header-title">{patientId(patient)}</div>
        :
        <div className="secondary-header-title">{title}</div>
      }
      {downloadClick ?
        <SaveAltRounded className="secondary-header-download"
                        style={{fontSize: 18}}
                        onClick={downloadClick}/>
        :
        null
      }
    </div>
  )
}
export default SecondaryHeader
