import React from 'react';
import '../AddPatientNoteModal/AddPatientNoteModal.scss'
import "antd/dist/antd.css";
import {Translation} from "react-i18next";
import '../../Custom UI/modalTitleCell.scss';
import GeneralModal from "../GeneralModal/GeneralModal";
import "../GeneralModal/GeneralModal.scss"
import PatientList from "../../Lists/PatientList/PatientList";

export default class AddUsersToGroupModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedPatientIdsArray: [],
            goToNextState: false,
        };
        this.handleSave = this.handleSave.bind(this);
        this.selectPatient = this.selectPatient.bind(this);
    }


    handleSave(){
        this.props.handleSave(this.state.selectedPatientIdsArray);
    }

    selectPatient(aPatient){
        this.props.selectPatient(aPatient);
        this.setState({goToNextState: true});
    };

    render() {
        let {goToNextState} = this.state;
        let {isOpen, company, handleCloseModal, selectedPatientIdsArray, excludedPatients, groupIdsToExclude} = this.props;

        return (
            <GeneralModal title={<Translation>{ (t, { i18n }) => t('PATIENTS_PROPER_CAPITALIZED') }</Translation>}
                          width={650}
                          isOpen={isOpen}
                          maxWidth={650}
                          maxHeight={700}
                          isEditing={false}
                          showSaveButton={goToNextState}
                          handleSave={() => this.handleSave()}
                          handleCloseModal={handleCloseModal}>
                <div style={{marginLeft: 20}}>
                    <div className="manage-groups-patient-list-container">
                        <PatientList height={'calc(100vh - 600px)'}
                                     company={company}
                                     showLink={true}
                                     canSelect={true}
                                     selectPatient={this.selectPatient}
                                     excludedPatients={excludedPatients}
                                     groupIdsToExclude={groupIdsToExclude}
                                     showFraudStatistics={false}
                                     selectedPatientIdsArray={selectedPatientIdsArray}/>
                    </div>
                </div>
            </GeneralModal>
        )
    }
}
