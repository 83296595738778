import {Translation} from "react-i18next";

export const TriggerTypes = [
  // {value:'Trigger::AppointmentTodayTrigger', label:<Translation>{ (t, { i18n }) => t('APPOINTMENT_TODAY_PROPER_CAPITALIZATED') }</Translation>},
  // {value:'Trigger::ConsentObjectiveTrigger', label:<Translation>{ (t, { i18n }) => t('CONSENT_OBJECTIVE') }</Translation>},
  // {value:'Trigger::HealthCareProviderMissingTrigger', label:<Translation>{ (t, { i18n }) => t('HEALTH_CARE_PROVIDER_MISSING') }</Translation>},
  // {value:'Trigger::InactiveSurveyResponseTrigger', label:<Translation>{ (t, { i18n }) => t('INACTIVE_SURVEY_RESPONSE') }</Translation>},
  // {value:'Trigger::ObjectiveValueTrigger', label:<Translation>{ (t, { i18n }) => t('OBJECTIVE_VALUE') }</Translation>},
  // {value:'Trigger::QuestUserJoinAdverseEventTrigger', label:<Translation>{ (t, { i18n }) => t('QUEST_USER_JOIN_ADVERSE_EVENT') }</Translation>},
  // {value:'Trigger::QuestUserJoinEndTrigger', label:<Translation>{ (t, { i18n }) => t('QUEST_USER_JOIN_END') }</Translation>},
  // {value:'Trigger::QuestUserJoinNoResponseTrigger', label:<Translation>{ (t, { i18n }) => t('QUEST_USER_JOIN_NO_RESPONSE') }</Translation>},
  {value:'Trigger::QuestUserJoinStartTrigger', label:<Translation>{ (t, { i18n }) => t('QUEST_USER_JOIN_START') }</Translation>},
  // {value:'Trigger::QuestUserJoinTrigger', label:<Translation>{ (t, { i18n }) => t('QUEST_USER_JOIN') }</Translation>},
  // {value:'Trigger::SurveyScoreTrigger', label:<Translation>{ (t, { i18n }) => t('SURVEY_SCORE') }</Translation>},
  // {value:'Trigger::VirtualAppointmentEndTrigger', label:<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENT_END') }</Translation>},
  // {value:'Trigger::VirtualAppointmentTrigger', label:<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENT') }</Translation>}
];

export const TriggerTypeLabelForValue = (aValue) => {
  let returnValue = '';
  
  if(aValue){
    for(let triggerType of TriggerTypes){
      if(triggerType.value === aValue){
        returnValue = triggerType.label;
        break;
      }
    }
  }
  return returnValue;
};
