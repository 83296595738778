import React, {useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import './ManageReports.scss'
import {Translation} from "react-i18next";
import SecondaryHeader from '../../Custom UI/Headers/SecondaryHeader/SecondaryHeader';
import TabBar from '../../Custom UI/TabBar/TabBar';
import ReportsList from "../../Lists/ReportsList/ReportsList";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../Helpers/CompanyHelpers";
import ScheduledReportsList from "../../Lists/ScheduledReportsList/ScheduledReportsList";
import DownloadQuestionnaireReports from "../../Components/DownloadQuestionnaireReports/DownloadQuestionnaireReports";
import ToolReports from "../../Components/ToolReports/ToolReports";
import NestedSurveyReportsList from "../../Lists/NestedSurveyReportsList/NestedSurveyReportsList";
import ReportTemplatesList from "../../Lists/ReportTemplatesList/ReportTemplatesList";
import ReportBuilder from "../../Components/ReportBuilder/ReportBuilder";

const ManageReports = ({company, locales, fetchLocales, getScheduledSurveys, fetchReports, removeReport, fetchReportDetails,
                         fetchSurveyVersionCollections, saveReportTemplate, fetchReportTemplate, fetchReportTemplates,
                         removeReportTemplate, retryGeneratingReport, duplicateReportTemplate, generateReportFromTemplate,
                         fetchModelConfigurations, fetchColumnConfigurations, fetchFilterConfigurations,
                         fetchPreMadeRelationships, fetchRelationshipConfigurations, studyClosed}) => {
  
  // Instance Variables
  
  const role = roleForCompany(company);
  const reportTemplateTabPermissions = ['admin', 'admin_level_2', 'admin_level_4'];
  const firstAndSecondTabProhibitions = ['admin_level_6', 'admin_level_10']; // Note: If this changes, make sure to change the link in the PatientTools Component.
  
  const [tabBarTabsArray] = useState([]);
  const [tabBarKey, setTabBarKey] = useState('GENERATED_REPORTS');
  const [hasSetTabFromParams, setHasSetTabFromParams] = useState(false);

  if(!hasSetTabFromParams){
    setHasSetTabFromParams(true);
    const paramTab = new URLSearchParams(window.location.search).get('tab');
    
    if(paramTab && paramTab >= 0 && paramTab <= 3){
      setTabBarKey(Number(paramTab));
    }
  }
  
  useEffect(()=> {
    if(company){
      fetchLocales();
    }
  }, [company, fetchLocales]);
  
  // Methods
  
  useEffect(() => {
    setTabBarTabsArray();
  }, [company]);
  
  const setTabBarTabsArray = () => {
    if(company){
      tabBarTabsArray.length = 0;
      
      if(!firstAndSecondTabProhibitions.includes(role) || studyClosed){
        tabBarTabsArray.push({key:'TOOL_REPORTS'});
        tabBarTabsArray.push({key:'DOWNLOAD_QUESTIONNAIRE_REPORTS'});
      }
      tabBarTabsArray.push({key:'SCHEDULED_REPORTS'});
      tabBarTabsArray.push({key:'GENERATED_REPORTS'});
  
      if(hsFeatureAvailableForCompany(company, 'Nested Surveys')){
        tabBarTabsArray.push({key:'NESTED_SURVEYS'});
      }
      if(hsFeatureAvailableForCompany(company, 'Report Builder') && reportTemplateTabPermissions.includes(role)){
        tabBarTabsArray.push({key:'REPORT_TEMPLATES'});
        tabBarTabsArray.push({key:'CREATE_REPORT'});
      }
    }
  };
  
  if(tabBarTabsArray.length === 0){
    setTabBarTabsArray();
  }
  
  // Render
  
  return (
    <div className='main-panel-inner-container'>
      <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('REPORTS') }</Translation>}/>
  
      <TabBar tabBarTabsArray={tabBarTabsArray}
              initialSelectedTabKey={'GENERATED_REPORTS'}
              selectedTabKeyChanged={(aKey) => setTabBarKey(aKey)}
      />
      
      {tabBarKey === 'TOOL_REPORTS' ?
        <ToolReports company={company}
                     locales={locales}
                     studyClosed={studyClosed}
        />
        :
        null
      }
      
      {tabBarKey === 'DOWNLOAD_QUESTIONNAIRE_REPORTS' ?
        <DownloadQuestionnaireReports company={company}
                                      locales={locales}
                                      fetchSurveyVersionCollections = {fetchSurveyVersionCollections}
        />
        :
        null
      }
      
      {tabBarKey === 'SCHEDULED_REPORTS' ?
        <ScheduledReportsList company={company}
                              getScheduledSurveys={getScheduledSurveys}
        />
        :
        null
      }
      
      {tabBarKey === 'GENERATED_REPORTS' ?
        <ReportsList company={company}
                     studyClosed={studyClosed}
                     fetchReports={fetchReports}
                     removeReport={removeReport}
                     fetchReportDetails={fetchReportDetails}
                     listContainerHeight={'100%'}
                     retryGeneratingReport={retryGeneratingReport}
        />
        :
        null
      }
      
      {tabBarKey === 'NESTED_SURVEYS' ?
        <NestedSurveyReportsList company={company}
                                 locales={locales}
                                 fetchSurveyVersionCollections = {fetchSurveyVersionCollections}
        />
        :
        null
      }
      
      {tabBarKey === 'REPORT_TEMPLATES' ?
        <ReportTemplatesList company={company}
                             saveReportTemplate={saveReportTemplate}
                             fetchReportTemplate={fetchReportTemplate}
                             fetchReportTemplates={fetchReportTemplates}
                             removeReportTemplate={removeReportTemplate}
                             duplicateReportTemplate={duplicateReportTemplate}
                             fetchModelConfigurations={fetchModelConfigurations}
                             fetchColumnConfigurations={fetchColumnConfigurations}
                             fetchFilterConfigurations={fetchFilterConfigurations}
                             fetchPreMadeRelationships={fetchPreMadeRelationships}
                             generateReportFromTemplate={generateReportFromTemplate}
                             fetchRelationshipConfigurations={fetchRelationshipConfigurations}
        />
        :
        null
      }
      
      {tabBarKey === 'CREATE_REPORT' ?
        <ReportBuilder company={company}
                       saveReportTemplate={saveReportTemplate}
                       fetchReportTemplate={fetchReportTemplate}
                       duplicateReportTemplate={duplicateReportTemplate}
                       fetchModelConfigurations={fetchModelConfigurations}
                       fetchColumnConfigurations={fetchColumnConfigurations}
                       fetchFilterConfigurations={fetchFilterConfigurations}
                       fetchPreMadeRelationships={fetchPreMadeRelationships}
                       fetchRelationshipConfigurations={fetchRelationshipConfigurations}
        />
        :
        null
      }
    </div>
  )
};
export default ManageReports
