import React from 'react';
import './SendSurveyLinkModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import {emailIsValid} from "../../Helpers/AdminHelpers";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";

export default class SendSurveyLinkModal extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      email:'',
      locale:'en',
      emailBody:'',
      emailSubject:'',
      subjectIdentificationMessage:''
    };
    this.resetState = this.resetState.bind(this);
  }

  // Methods

  resetState(){
    this.setState({email:'', locale:'en', emailBody:'', emailSubject:'', subjectIdentificationMessage:''});
  }

  // Render

  render(){
    const {isOpen, patient, handleFinish, saveButtonTitle, handleCloseModal, requiresEmail = true} = this.props;
    const {email, locale, emailBody, emailSubject, subjectIdentificationMessage} = this.state;

    const physiciansArray = patient ? patient.health_care_provider_informations : [];
    let emailOptionsArray = [];

    if(email && email.length > 0){
      emailOptionsArray.push({label:email, value:email});

      if(physiciansArray && physiciansArray.length > 0){
        for(let physician of physiciansArray){
          if(physician && physician.email && physician.email.length > 0 && physician.email.startsWith(email)){
            emailOptionsArray.push({label:'HCP - ' + physician.email, value:physician.email});
          }
        }
      }
    }
    else{
      if(physiciansArray && physiciansArray.length > 0){
        for(let physician of physiciansArray){
          if(physician && physician.email && physician.email.length > 0){
            emailOptionsArray.push({label:'HCP - ' + physician.email, value:physician.email});
          }
        }
      }
    }
    let english = (i18next.t('ENGLISH_WITH_FLAG'));
    let italian = (i18next.t('ITALIAN_WITH_FLAG'));
    let french = (i18next.t('FRENCH_WITH_FLAG'));
    let german = (i18next.t('GERMAN_WITH_FLAG'));
    let spanish = (i18next.t('SPANISH_WITH_FLAG'));
    let availableLanguages = [
      {languageString:english, languageCode:'en'},
      {languageString:italian, languageCode:'it'},
      {languageString:french, languageCode:'fr'},
      {languageString:german, languageCode:'de'},
      {languageString:spanish, languageCode:'es'}
    ];
    
    let languageOptions = [];
    
    if(availableLanguages){
      availableLanguages.map((aLanguage, index) => (
        languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
      ));
    }
    
    let height = 410;
    let canSave = true;
    let emailErrorMessage = '';
    let emailBodyErrorMessage = '';
    let emailSubjectErrorMessage = '';
    let subjectIdentificationMessageErrorMessage = '';

    if(requiresEmail){
      height = 570;
    }
    if(requiresEmail && !emailIsValid(email)){
      height = height + 20;
      canSave = false;
      emailErrorMessage = i18next.t('INVALID_EMAIL');
    }
    if(requiresEmail && (!subjectIdentificationMessage || subjectIdentificationMessage.length === 0)){
      height = height + 20;
      canSave = false;
      subjectIdentificationMessageErrorMessage = i18next.t('SUBJECT_IDENTIFICATION_MESSAGE_REQUIRED');
    }
    if(!emailBody || emailBody.length === 0){
      height = height + 20;
      canSave = false;
      emailBodyErrorMessage = i18next.t('EMAIL_BODY_IS_REQUIRED');
    }
    if(!emailSubject || emailSubject.length === 0){
      height = height + 20;
      canSave = false;
      emailSubjectErrorMessage = i18next.t('EMAIL_SUBJECT_LINE_REQUIRED');
    }
    return (
      <GeneralModal title={i18next.t('SEND_SURVEY_LINK_EMAIL')}
                    width={'400px'}
                    height={height.toString() + 'px'}
                    isOpen={isOpen}
                    maxHeight={'700px'}
                    isEditing={false}
                    handleSave={() => {
                      handleFinish(email, locale, emailBody, emailSubject, subjectIdentificationMessage);
                      this.resetState();
                    }}
                    showFooter={true}
                    showHeader={true}
                    ariaHideApp={false}
                    contentLabel="Modal"
                    noTitleHeading={true}
                    showSaveButton={canSave}
                    saveButtonTitle={saveButtonTitle}
                    handleCloseModal={() => {
                      handleCloseModal();
                      this.resetState();
                    }}>
        <LabeledDropDown className="send-survey-link-modal-input-locale"
                         label={i18next.t('LOCALE')}
                         value={languageOptions.filter(function(option){
                           return option.value === locale;
                         })}
                         options={languageOptions}
                         infoBlurb={i18next.t('SEND_SURVEY_LINK_LOCALE_EXPLANATION')}
                         handleSave={(aOption) => this.setState({locale:aOption.value})}
        />
        
        {requiresEmail ?
          <>
            <LabeledDropDown className="send-survey-link-modal-input-email"
                             label={i18next.t('EMAIL')}
                             value={emailOptionsArray.filter(function(option){
                               return option.value === email;
                             })}
                             options={emailOptionsArray}
                             infoBlurb={i18next.t('SEND_SURVEY_LINK_EMAIL_EXPLANATION')}
                             handleSave={(aOption) => this.setState({email:aOption.value})}
                             placeholder={i18next.t('EMAIL')}
                             errorMessage={emailErrorMessage}
                             showSearchStringInList={true}
            />

            <LabeledTextInput className="send-survey-link-modal-input-id-message"
                              label={i18next.t('SUBJECT_IDENTIFICATION_MESSAGE')}
                              value={subjectIdentificationMessage}
                              infoBlurb={i18next.t('SUBJECT_IDENTIFICATION_MESSAGE_EXPLANATION')}
                              handleSave={(aEvent) => this.setState({subjectIdentificationMessage:aEvent.target.value})}
                              placeholder={i18next.t('SUBJECT_IDENTIFICATION_MESSAGE')}
                              errorMessage={subjectIdentificationMessageErrorMessage}
            />
          </>
          :
          null
        }

        <LabeledTextInput className="send-survey-link-modal-input-email-subject"
                          label={i18next.t('EMAIL_SUBJECT')}
                          value={emailSubject}
                          infoBlurb={i18next.t('EMAIL_SUBJECT_EXPLANATION')}
                          handleSave={(aEvent) => this.setState({emailSubject:aEvent.target.value})}
                          placeholder={i18next.t('EMAIL_SUBJECT')}
                          errorMessage={emailSubjectErrorMessage}
        />

        <LabeledTextarea className="send-survey-link-modal-input-textarea-email-body"
                         label={i18next.t('EMAIL_BODY')}
                         value={emailBody}
                         infoBlurb={i18next.t('EMAIL_BODY_EXPLANATION')}
                         handleSave={(aEvent) => this.setState({emailBody:aEvent.target.value})}
                         placeholder={i18next.t('EMAIL_BODY')}
                         errorMessage={emailBodyErrorMessage}
        />
        
        {/*TODO: Ignore people who have taken the survey already.*/}
      </GeneralModal>
    );
  }
}
