import React from "react";
import './SurveyBuilderSliderQuestionCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import {objectIsEmpty} from "../../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";

export default class SurveyBuilderSliderQuestionCell extends React.Component{
  
  // Render
  
  render(){
    const {question, updateQuestion, errorForIdTypeAndKey} = this.props;
    
    let maxLabel = '';
    let minLabel = '';
    let maxValue = 5;
    let minValue = 1;
    
    if(question){
      if(question.max){
        maxLabel = question.max.label;
        maxValue = question.max.value;
      }
      if(question.min){
        minLabel = question.min.label;
        minValue = question.min.value;
      }
    }
    // TODO: Control for number of sliders. In side menu.
    
    return(
      <div className="survey-builder-year-question-cell">
        <LabeledDelayedInput className="survey-builder-slider-question-input"
                             type={'number'}
                             label={<Translation>{ (t, { i18n }) => t('MIN_VALUE') }</Translation>}
                             value={minValue}
                             infoBlurb={<Translation>{(t, { i18n }) => t('MIN_VALUE_EXPLANATION')}</Translation>}
                             minLength={0}
                             handleSave={(aEvent) => {
                               let value = Math.min(aEvent.target.value, maxValue);
                               let updatedQuestion = cloneDeep(question);
                               
                               if(updatedQuestion.min){
                                 updatedQuestion.min.value = value;
                               }
                               else{
                                 updatedQuestion.min = {label:'', value:value};
                               }
                               if(objectIsEmpty(updatedQuestion.min)){
                                 delete updatedQuestion['min'];
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_value').message}
        />
        
        <LabeledDelayedInput className="survey-builder-slider-question-input"
                             label={<Translation>{ (t, { i18n }) => t('MIN_LABEL') }</Translation>}
                             value={minLabel}
                             infoBlurb={<Translation>{(t, { i18n }) => t('MIN_LABEL_EXPLANATION')}</Translation>}
                             minLength={0}
                             handleSave={(aEvent) => {
                               let updatedQuestion = cloneDeep(question);
                               
                               if(updatedQuestion.min){
                                 updatedQuestion.min.label = aEvent.target.value;
                               }
                               else{
                                 updatedQuestion.min = {label:aEvent.target.value, value:1};
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'min_label').message}
        />
  
        <LabeledDelayedInput className="survey-builder-slider-question-input"
                             type={'number'}
                             label={<Translation>{ (t, { i18n }) => t('MAX_VALUE') }</Translation>}
                             value={maxValue}
                             infoBlurb={<Translation>{(t, { i18n }) => t('MAX_VALUE_EXPLANATION')}</Translation>}
                             minLength={0}
                             handleSave={(aEvent) => {
                               let value = Math.max(aEvent.target.value, minValue);// TODO: Check this.
                               let updatedQuestion = cloneDeep(question);
  
                               if(updatedQuestion.max){
                                 updatedQuestion.max.value = value;
                               }
                               else{
                                 updatedQuestion.max = {label:'', value:value};
                               }
                               if(objectIsEmpty(updatedQuestion.max)){
                                 delete updatedQuestion['max'];
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_value').message}
        />
        
        <LabeledDelayedInput className="survey-builder-slider-question-input"
                             label={<Translation>{ (t, { i18n }) => t('MAX_LABEL') }</Translation>}
                             value={maxLabel}
                             infoBlurb={<Translation>{(t, { i18n }) => t('MAX_LABEL_EXPLANATION')}</Translation>}
                             minLength={0}
                             handleSave={(aEvent) => {
                               let updatedQuestion = cloneDeep(question);
  
                               if(updatedQuestion.max){
                                 updatedQuestion.max.label = aEvent.target.value;
                               }
                               else{
                                 updatedQuestion.max = {label:aEvent.target.value, value:1};
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'max_label').message}
        />
      </div>
    )
  }
}
