import React from 'react';
import "antd/dist/antd.css";
import './ConfirmationModal.scss';
import {Translation} from "react-i18next";
import i18next from "i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";

export default class ConfirmationModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    }
  }

  // Methods
  
  
  
  // Render
  
  render() {
    const {title, isOpen, minHeight, description, presentedInsideParentModal, width= 300, reject = () => {}, confirm = () => {}} = this.props;
    
    return (
      <GeneralModal title={title ? title : i18next.t('REMOVE')}
                    width={width}
                    isOpen={isOpen}
                    isEditing={false}
                    minHeight={minHeight}
                    handleSave={confirm}
                    onRequestClose={reject}
                    noTitleHeading={true}
                    showSaveButton={false}
                    handleCloseModal={reject}
                    showCancelButton={false}
                    presentedInsideParentModal={presentedInsideParentModal}>
        <div className='confirmation-modal-container'>
          <span className='confirmation-modal-description-text'>
            {description}
          </span>
          
          <div className="confirmation-modal-confirmation-button-container">
            <BlueButton name={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                        onClick={confirm}
            />
            
            <BlueButton name={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                        onClick={reject}
            />
          </div>
        </div>
      </GeneralModal>
    )
  }
}
