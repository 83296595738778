import React, { useState } from 'react';
import '../Login/SignUpLogin.scss';
import {Translation} from "react-i18next";

const ResetPassword = ({ user, resetPassword }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation ] = useState('');
  const resetPasswordToken = new URLSearchParams(window.location.search).get('token');

  const changePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetPassword({password, passwordConfirmation, resetPasswordToken});
  }

  const requiredFieldsFilled = () => {
    return password && passwordConfirmation && resetPasswordToken && !(user && user.success && user.success === true);
  }

  const toSentenceCase = (unformattedString) => {
    var newString = unformattedString.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function(c){ return c.toUpperCase()});
    return newString;
  }

  const errorMessage = user && user.errors ? user.errors[Object.keys(user.errors)[0]][0] : "";
  const errorText = (errorMessage !== '') ? toSentenceCase(Object.keys(user.errors)[0].split("_").join(" ") + " " + errorMessage) : "";
  const successMessage = user && user.message && user.message.length > 0 ? user.message : '';
  const successText = (successMessage !== '') ? toSentenceCase(successMessage) : "";

  return (
    <div className='login-sign-up-container'>
      <div className='login-signup-container-inner'>
        <div className="login-sign-up-logo-head">
          <img src="https://s3.amazonaws.com/hs-app-images/HSC_Logo_75_percent_opacity.png" alt='HSC Logo'/>
          <span>
            <Translation>{ (t, { i18n }) => t('HEALTH_STORYLINES_CREATOR') }</Translation>
          </span>
        </div>
        <p className='login-heading'>
          <Translation>{ (t, { i18n }) => t('MAY_NOW_SET_PASSWORD') }</Translation>
        </p>
        <form className="floating-form">
          <div className="floating-label login-input-width">
            <input name="password"
                   type="password"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                   className="floating-input"
                   placeholder=" "
            />
            <label>
              <Translation>{ (t, { i18n }) => t('PASSWORD') }</Translation>
            </label>
          </div>
          <div className="floating-label login-input-width">
            <input name="passwordConfirmation"
                   type="password"
                   value={passwordConfirmation}
                   onChange={(e) => setPasswordConfirmation(e.target.value)}
                   className="floating-input "
                   placeholder=" "/>
            <label>
              <Translation>{ (t, { i18n }) => t('RETYPE_PASSWORD') }</Translation>
            </label>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a href="../login" id="forgot-password">
              <Translation>{ (t, { i18n }) => t('RETURN_TO_LOGIN') }</Translation>
            </a>
            <button style={{opacity: requiredFieldsFilled() ? 1 : 0.5}}
                    onClick={changePassword}
                    disabled={!requiredFieldsFilled()}
                    className="login-submit-button clickable">
              <Translation>{ (t, { i18n }) => t('SPACED_UPDATE') }</Translation>
            </button>
          </div>
        </form>
        {errorText &&
        <ul className="error-msg">
          { errorText }
        </ul>
        }
        {successText &&
        <ul className="success-msg">
          { successText }
        </ul>
        }
      </div>
    </div>
  );
}
export default ResetPassword
