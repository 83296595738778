import React from "react";
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class SurveyTextQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:''
    };
    if(props.answer !== null && props.answer.value !== null ){
      this.state.answer = props.answer.value;
    }
    this.checkRegex = this.checkRegex.bind(this);
    this.saveAnswer = this.saveAnswer.bind(this);
    this.passedRegex = this.passedRegex.bind(this);
  }
  
  // Methods
  
  checkRegex(){
    return (this.props.question && this.props.question.js_regex);
  }
  
  saveAnswer(aAnswer){
    this.setState({answer:aAnswer});
    this.props.onAnswerChange(this.props.question, aAnswer);
  }
  
  passedRegex(aAnswer){
    let returnValue = false;
    
    if(this.checkRegex() && aAnswer){
      returnValue = (aAnswer.match(this.props.question.js_regex) !== null);
    }
    return returnValue;
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer} = this.state;
    const {key, type, large_text_area} = question;
    
    let regexInputClassName = '';
    
    if(this.checkRegex()){
      if(this.passedRegex(answer)){
        regexInputClassName = 'survey-text-question-pass';
      }
      else{
        regexInputClassName = 'survey-text-question-fail';
      }
    }
    
    return(
      <div className="survey-text-question-container"
           key={'survey-' + type + '-question-cell-' + key}>
        <br/>
        
        {large_text_area ?
          <textarea className={'survey-question-large-text-area ' + regexInputClassName}
                    value={answer}
                    disabled={disabled}
                    onChange={(aEvent) => this.saveAnswer(aEvent.target.value)}
          />
          :
          <input className={'survey-string-input ' + regexInputClassName}
                 type="text"
                 disabled={disabled}
                 onChange={(aEvent) => this.saveAnswer(aEvent.target.value)}
                 defaultValue={answer}
          />
        }
        
        {this.checkRegex() ?
          this.passedRegex(answer) ?
            <CheckCircleIcon style={{color:'#1B7F4F', height:'15px'}}/>
            :
            <ErrorIcon style={{color:'#E02020', height:'15px'}}/>
          :
          null
        }
      </div>
    )
  }
}
