import React from 'react';
import "antd/dist/antd.css";
import './SurveyVersionCollectionModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import SurveyBuilder from "../../Components/SurveyBuilder/SurveyBuilder";

export default class SurveyVersionCollectionModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  // Render
  
  render() {
    const { user, client, isOpen, handleCloseModal, company, languages, fetchDynamicLists, fetchQuestionsForSurveyByReference,
      fetchSurveyVersionCollections, publishSurveyVersionCollection, fetchSurveyVersionCollectionById,
      fetchSurveyVersionCollectionByReference, surveyVersionCollectionId, saveSurveyVersionCollection, fetchSurveyLists,
      fetchSurveyListById, fetchSurveyListByReference, fetchSurveysForSurveyVersionCollection, validateRubularRegex,
      fetchSurveyById, fetchAppAdditionalColumns, readOnly, addSurveyList, fetchDynamicListById, fetchDynamicListByReference} = this.props;
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('PAYMENT_TRIGGER') }</Translation>}
                    width={'calc(100vw - 30px)'}
                    height={'calc(100vh - 30px)'}
                    isOpen={isOpen}
                    maxWidth={1300}
                    maxHeight={800}
                    showFooter={false}
                    showHeader={false}
                    childrenHeight={'calc(100vh + 120px)'}
                    handleCloseModal={() => handleCloseModal()}
                    maxChildrenHeight={950}>
        <SurveyBuilder user={user}
                       client={client}
                       company={company}
                       readOnly={readOnly}
                       isInModal={true}
                       languages={languages}
                       addSurveyList={addSurveyList}
                       fetchSurveyById={fetchSurveyById}
                       fetchSurveyLists={fetchSurveyLists}
                       fetchDynamicLists={fetchDynamicLists}
                       fetchSurveyListById={fetchSurveyListById}
                       fetchDynamicListById={fetchDynamicListById}
                       validateRubularRegex={validateRubularRegex}
                       fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                       surveyVersionCollectionId={surveyVersionCollectionId}
                       fetchSurveyListByReference={fetchSurveyListByReference}
                       fetchDynamicListByReference={fetchDynamicListByReference}
                       saveSurveyVersionCollection={saveSurveyVersionCollection}
                       fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                       publishSurveyVersionCollection={publishSurveyVersionCollection}
                       fetchSurveyVersionCollectionById={fetchSurveyVersionCollectionById}
                       fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                       fetchSurveysForSurveyVersionCollection={fetchSurveysForSurveyVersionCollection}
                       fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
        />
      </GeneralModal>
    )
  }
}
