import React from 'react';
import { Translation } from 'react-i18next';
import { map } from 'lodash'
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from "../../Custom UI/TabBar/TabBar";
import './ManageUsers.scss';
import i18next from "i18next";
import LabeledTextInput from './../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput'
import BlueButton from './../../Custom UI/Buttons/BlueButton/BlueButton';
import UserPermissionCell from "../../Cells/UserPermissionCell/UserPermissionCell";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import ChangeAdminRoleModal from "../../Modals/ChangeAdminRoleModal/ChangeAdminRoleModal";
import ChangeAdminGroupModal from "../../Modals/ChangeAdminGroupModal/ChangeAdminGroupModal";
import LabeledMultipleSelectDropDown from "./../../Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";
import ChangePasswordModal from "../../Modals/ChangePasswordModal/ChangePasswordModal"
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {formatUserName, getAssignableAdminRolesArray, translatedStringForRole} from '../../Helpers/AdminHelpers';
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import ProfilePicture from "../../Custom UI/ProfilePicture/ProfilePicture";
import {momentFromDate} from "../../Helpers/DateHelpers";
import AlertIndicator from "../../Custom UI/AlertIndicator/AlertIndicator";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyAddAdminSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('ADMIN_INVITED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyAddAdminFailure = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('ADD_ADMIN_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyRemoveAdminSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('ADMIN_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyRemoveAdminFailure = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('REMOVE_ADMIN_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPasswordChangeRequestSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SEND_PASSWORD_CHANGED_REQUEST') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPasswordChangeRequestFailure = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('SEND_PASSWORD_CHANGED_REQUEST_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateRoleSuccess = () => toast(<Translation>{ (t, { i18n }) => t('ROLE_UPDATED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateRoleFailure = (aError) => toast(<Translation>{ (t, { i18n }) => t('UPDATE_ROLE_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyUpdateGroupSuccess = () => toast(<Translation>{ (t, { i18n }) => t('GROUPS_UPDATED') }</Translation>, NOTIFY_OPTS.autoClose);

export default class ManageUsers extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      tabBarKey:'ADMIN_LIST',
      loading: false,
      totalNumberOfAdmins: 0,
      newUser: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: null,
        group_ids: []
      },
      group_ids:[],
      shouldReload:false,
      selectedAdmin:null,
      assignedGroups:[],
      confirmPassword:'',
      changeAdminRoleIsOpen:false,
      changeAdminGroupIsOpen:false,
      confirmationModalOpen:false,
      changeAdminPasswordIsOpen:false,
      userToRemove: null
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.renderAdminName = this.renderAdminName.bind(this);
    this.renderAdminGroup = this.renderAdminGroup.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.updateAdminUserRole = this.updateAdminUserRole.bind(this);
    this.handleAdminCellClick = this.handleAdminCellClick.bind(this);
    this.renderAdminPermission = this.renderAdminPermission.bind(this);
    this.updateAdminUserGroups = this.updateAdminUserGroups.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.company !== prevProps.company){
      this.setState({shouldReload:true});
    }
  }
  
  // Methods
  
  removeUser(){
    this.props.removeUser(this.state.selectedAdmin.id).then(() => {
      this.setState({userToRemove: this.state.selectedAdmin, confirmationModalOpen:false}, () => {
        this.setState({selectedAdmin: null})
      });
      notifyRemoveAdminSuccess();
    }, (newError) => {
      notifyRemoveAdminFailure(errorMessageFromServerError(newError));
      this.setState({confirmationModalOpen:false, selectedAdmin:null});
    });
  }
  
  requestPasswordChange(aAdmin){
    let user = {
      id:aAdmin.id,
      password_update_requested:true
    };
    this.props.requestPasswordChange(user).then(() => {
      this.setState({selectedAdmin:null, shouldReload:true});

      notifyPasswordChangeRequestSuccess();
    }, newError => {
      let errorMessage = i18next.t('UNKNOWN_ERROR');
  
      if(newError && newError.data && newError.data.error){
        errorMessage = newError.data.error;
      }
      notifyPasswordChangeRequestFailure(errorMessage);
      this.setState({confirmationModalOpen:false, selectedAdmin:null});
    });
  }
  
  updateAdminUserRole(aUserId, aRole){
    const {updateUserRole} = this.props;
    
    if(updateUserRole){
      updateUserRole({userId:aUserId, role:aRole}).then((newResponse) => {
        this.setState({shouldReload:true, selectedAdmin:null});
        notifyUpdateRoleSuccess();
      }, (newError) => {
        let errorMessage = i18next.t('UNKNOWN_ERROR');
    
        if(newError && newError.data && newError.data.error){
          errorMessage = newError.data.error;
        }
        notifyUpdateRoleFailure(errorMessage);
      });
    }
  }
  
  updateAdminUserGroups(aUserId, groups){
    const {updateUserGroups} = this.props;
    
    if(updateUserGroups){
      let updatedGroups = [];
  
      if(groups && groups.length > 0){
        updatedGroups = map(groups, "value");
      }
      updateUserGroups({userId:aUserId, groups:updatedGroups}).then(() => {
        notifyUpdateGroupSuccess();
        this.setState({shouldReload:true, selectedAdmin:null});
      }).catch(error => {
        this.displayErrorMessage(error);
        this.setState({loading:false});
      });
    }
  }
  
  // UI Methods
  
  closeMenu(){
    this.setState({selectedAdmin:null});
  }
  
  addNewUser(){
    if(this.state.group_ids.length > 0){
      let groups_ids = map(this.state.group_ids, "value");
      let updatedNewUser = Object.assign(this.state.newUser, {group_ids:groups_ids});
      this.setState({newUser: updatedNewUser});
    }
    if(this.state.newUser.password === this.state.confirmPassword){
      if(this.state.newUser.role) {
        this.setState({loading:true});
        this.props.addUser(this.state.newUser).then(result => {
          let error = result?.data?.error;
          
          if(error){
            this.displayErrorMessage(error);
          }
          else{
            notifyAddAdminSuccess();
            this.setState({newUser:{first_name:'', last_name:'', email:'', password:'', role:null}, confirmPassword:'', group_ids: [], shouldReload:true});
          }
          this.setState({loading:false});
        }).catch(error => {
          this.displayErrorMessage(error);
          this.setState({loading:false});
        });
      }
      else{
        notifyAddAdminFailure(i18next.t('ADMIN_LEVEL_IS_MANDATORY'));
      }
    }
    else{
      notifyAddAdminFailure(i18next.t('PASSWORDS_DO_NOT_MATCH'));
    }
  }
  
  displayErrorMessage(aError){
    let errorMessage = i18next.t('UNKNOWN_ERROR');
    
    if(aError && aError.data && aError.data.error){
      errorMessage = aError.data.error;
    }
    notifyAddAdminFailure(errorMessage);
  }
  
  handleSave(aEvent, aKey){
    let updatedNewUser = Object.assign(this.state.newUser, {[aKey]:aEvent.target.value});
    this.setState({newUser: updatedNewUser});
  }
  
  handleRoleChange(aRole){
    let updatedNewUser = Object.assign(this.state.newUser, {role:aRole});
    this.setState({newUser: updatedNewUser});
  }
  
  menuButtonClicked(aAdmin){
    let groups = [];
    aAdmin && aAdmin.groups && aAdmin.groups.forEach(function(aGroup){
      groups.push({value:aGroup.id, label:aGroup.name});
    });
    this.setState({selectedAdmin:aAdmin, assignedGroups:groups});
  }
  
  renderAdminName(aAdmin){
    const permissionsArray = ["admin_level_6"];
    const isCurrentUser = this.props.currentUser?.id === parseInt(aAdmin?.id);
    const currentRole = roleForCompany(this.props.company);
  
    return (
      <div className="admin-cell-info-container">
        <ProfilePicture className="admin-cell-profile-image"
                        imageId={aAdmin.image_id}
                        width={30}
                        height={30}
                        style={{ border: 'none', width: '30px!important', height: 'auto', overflow:'hidden' }}
        />
        
        {!permissionsArray.includes(currentRole) ?
          <div className={(isCurrentUser ? 'admin-cell-is-current-user' : '')}>
            <div>
              {formatUserName(aAdmin)}
            </div>
            
            <div>
              {aAdmin.email}
            </div>
            
            {aAdmin.password_update_requested && (
              <div className="admin-cell-password-change-requested error-message">
                <Translation>{(t, {i18n}) => t('PASSWORD_CHANGE_REQUESTED')}</Translation>
              </div>
            )}
            
            {!aAdmin.password_update_requested && aAdmin.password_changed_at && (
              <div>
                <Translation>{(t, {i18n}) => t('LAST_PASSWORD_RESET', {date:momentFromDate(aAdmin.password_changed_at, 'MM/DD/YYYY @hh:mmA')})}</Translation>
              </div>
            )}
          </div>
          :
          null
        }
      </div>
    );
  }
  
  renderAdminGroup(aAdmin){
    let groupNames = [];
    const isCurrentUser = this.props.currentUser?.id === parseInt(aAdmin?.id);
  
    groupNames = aAdmin?.groups?.map(a => a.name);
    let adminGroups = groupNames.join(", ");
  
    return (
      <div className={(isCurrentUser ? 'admin-cell-is-current-user' : '')} data-testid='admin-cell-permission-level-group'>
        {adminGroups}
      </div>
    );
  }
  
  renderAdminPermission(aAdmin){
    const isCurrentUser = this.props.currentUser?.id === parseInt(aAdmin?.id);
  
    return (
      <div>
        <div className={(isCurrentUser ? 'admin-cell-is-current-user' : '')} data-testid='admin-cell-permission-level-role'>
          {translatedStringForRole(aAdmin.role)}
        </div>
        
        <AlertIndicator number={aAdmin.number_of_uncleared_company_notification_receipts} />
      </div>
    );
  }
  
  handleAdminCellClick = () => {
    // TODO: Enable once the user is downloaded based on User ID.
    //let adminLink = '/manage/admins/' + this.props.user.id;
    //this.props.history.push(adminLink);
  };
  
  // Render
  
  render() {
    const {company, resetPatientsPassword, fetchUsersScroll} = this.props;
    const {newUser, loading, group_ids, tabBarKey, selectedAdmin, changeAdminPasswordIsOpen, confirmationModalOpen,
      changeAdminRoleIsOpen, confirmPassword, changeAdminGroupIsOpen, shouldReload, userToRemove} = this.state;
    
    const adminUpdatePermissions = ['admin', 'admin_level_4'];
    const changePasswordRequestPermissions = ['admin', 'admin_level_2', 'admin_level_4']
    const changePasswordPermissions = ['admin'];
    
    let availableGroups = [];
    let currentRole = roleForCompany(company);
    
    if(company && company.core_group){
      availableGroups.push({value:company.core_group.id, label:company.core_group.name})
    }
    company && company.groups && company.groups.forEach(group => {
      availableGroups.push({
        value:group.id,
        label:group.name
      })
    });

    let assignableAdminRolesArray = getAssignableAdminRolesArray();
  
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:2.5, columnNameKey:'ADMIN_NAME', templateCell:(aAdmin) => this.renderAdminName(aAdmin)});
    columnsArray.push({key:'last_login', columnWeight:1.5, columnNameKey:'LAST_LOGIN', propertiesArray:['last_sign_in_at'], dateFormat:'MM/DD/YYYY @hh:mmA'});
    columnsArray.push({key:'group', columnWeight:1, columnNameKey:'GROUP', templateCell:(aAdmin) => this.renderAdminGroup(aAdmin)});
    columnsArray.push({key:'permission', columnWeight:1, columnNameKey:'PERMISSION', templateCell:(aAdmin) => this.renderAdminPermission(aAdmin)});
  
    let menuItemsArray = [];
    menuItemsArray.push({title:'CHANGE_ROLE', clickAction:(aAdmin) => {
        this.menuButtonClicked(aAdmin);
        this.setState({changeAdminRoleIsOpen:true});
      }, isValid:() => adminUpdatePermissions.includes(currentRole)});
    menuItemsArray.push({title:'SEND_PASSWORD_CHANGE_REQUEST', clickAction:(aAdmin) => {
        this.menuButtonClicked(aAdmin);
        this.requestPasswordChange(aAdmin);
      }, isValid:() => changePasswordRequestPermissions.includes(currentRole)});
    menuItemsArray.push({title:'CHANGE_GROUP', clickAction:(aAdmin) => {
        this.menuButtonClicked(aAdmin);
        this.setState({changeAdminGroupIsOpen:true});
    }, isValid:() => adminUpdatePermissions.includes(currentRole)});
    menuItemsArray.push({title:'CHANGE_ADMIN_PASSWORD', clickAction:(aAdmin) => {
        this.menuButtonClicked(aAdmin);
        this.setState({changeAdminPasswordIsOpen:true});
    }, isValid:() => changePasswordPermissions.includes(currentRole)});
    menuItemsArray.push({title:'REMOVE', clickAction:(aAdmin) => {
        this.menuButtonClicked(aAdmin);
        this.setState({confirmationModalOpen:true});
      }, isValid:() => adminUpdatePermissions.includes(currentRole)});
    
    return (
      <div className='main-panel-inner-container'>
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('ADMIN') }</Translation>}/>
        
        <TabBar tabBarTabsArray={[{key:'ADMIN_LIST'}, {key:'INVITE'}]}
                initialSelectedTabKey={'ADMIN_LIST'}
                selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
        />
        
        {tabBarKey === 'ADMIN_LIST' ?
          <div className='manage-users-admin-container'>
            <div id='users-list'
                 className="manage-admin-cells-container">
              <DynamicList id="admin-list"
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={() => this.setState({selectedGroup:null})}
                           selectCell={currentRole === 'admin' ? this.handleAdminCellClick : null}
                           shouldReload={shouldReload}
                           objectToDelete={userToRemove}
                           columnsArray={columnsArray}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchUsersScroll(aLimit, aOffset, aSearchString)}
                           menuItemsArray={menuItemsArray}
                           minColumnWidth={150}
                           explanationKey={'ADMIN_LIST_DESCRIPTION'}
                           totalObjectsKey='ADMIN_COUNT'
                           clampFirstColumn={true}
                           finishedDeletingObject={() => this.setState({userToRemove:null})}
                           responseTotalKeysArray={['data', 'data', 'user_page', 'total']}
                           responseObjectKeysArray={['data', 'data', 'user_page', 'users']}
              />
             
              <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:formatUserName(selectedAdmin)})}
                                 isOpen={confirmationModalOpen}
                                 reject={() => this.setState({confirmationModalOpen:false})}
                                 confirm={() => this.removeUser()}
              />
              
              {selectedAdmin ?
                <ChangeAdminRoleModal user={selectedAdmin}
                                      isOpen={changeAdminRoleIsOpen}
                                      closeModal={() => this.setState({changeAdminRoleIsOpen:false})}
                                      adminRolesArray={assignableAdminRolesArray}
                                      updateAdminRole={this.updateAdminUserRole}
                />
                :
                null
              }
              
              {selectedAdmin ?
                <ChangeAdminGroupModal user={selectedAdmin}
                                       isOpen={changeAdminGroupIsOpen}
                                       closeModal={() => this.setState({changeAdminGroupIsOpen:false, selectedAdmin:null})}
                                       groupsArray={availableGroups}
                                       assignedGroups={this.state.assignedGroups}
                                       updateAdminGroups={this.updateAdminUserGroups}
                />
                :
                null
              }
              
              {selectedAdmin ?
                <ChangePasswordModal isOpen={changeAdminPasswordIsOpen}
                                     userId={selectedAdmin && selectedAdmin.id}
                                     closeModal={() => this.setState({changeAdminPasswordIsOpen: false})}
                                     updateCurrentUser={resetPatientsPassword}
                                     requiresCurrentPassword={false}
                />
                :
                null
              }
            </div>
          </div>
          :
          null
        }
        
        {tabBarKey === 'INVITE' ?
          <div className="manage-users-invite-container">
            
            <div className="manage-users-admin-invite-text">
              <Translation>{ (t, { i18n }) => t('INVITE_ADMIN') }</Translation>
            </div>
            
            <>
                <LabeledTextInput className="manage-users-admin-invite-input"
                                  label={<Translation>{ (t, { i18n }) => t('EMAIL_ADDRESS') }</Translation>}
                                  value={newUser.email}
                                  handleSave={(aEvent) => this.handleSave(aEvent, 'email')}
                                  placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />
              
              <div className="manage-users-admin-invite-title">
                <Translation>{ (t, { i18n }) => t('PERMISSION') }</Translation>
              </div>
              
              <div className="manage-users-permission-outer-container">
                <div className="manage-users-permission-container">
                  {assignableAdminRolesArray.map((adminRole, index) => (
                    <div className="manage-permission-cell">
                      <UserPermissionCell key={'permission-cell-' + adminRole.title + index}
                                          title={adminRole.title}
                                          value={adminRole.value}
                                          checked={newUser.role === adminRole.value}
                                          onChange={this.handleRoleChange}
                                          description={adminRole.description}
                                          {...this.props}
                      />
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="manage-users-input-cell">
                <LabeledMultipleSelectDropDown className="manage-users-input-group"
                                               label={<Translation>{ (t, { i18n }) => t('GROUP') }</Translation>}
                                               value={group_ids}
                                               onBlur={() => this.setState({dropDownIsOpen:false})}
                                               onFocus={() => this.setState({dropDownIsOpen:true})}
                                               options={availableGroups}
                                               handleSave={(aEvent) => this.setState({group_ids:aEvent})}
                />
              </div>
              
              <div className="manage-users-input-cell">
                <LabeledTextInput className="manage-users-input"
                                  label={<Translation>{ (t, { i18n }) => t('FIRST_NAME_PROPER_CAPITALIZED') }</Translation>}
                                  value={newUser.first_name}
                                  handleSave={(aEvent) => this.handleSave(aEvent, 'first_name')}
                                  placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />
                <LabeledTextInput className="manage-users-input"
                                  label={<Translation>{ (t, { i18n }) => t('LAST_NAME_PROPER_CAPITALIZED') }</Translation>}
                                  value={newUser.last_name}
                                  handleSave={(aEvent) => this.handleSave(aEvent, 'last_name')}
                                  placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />
              </div>
              
              <div className="manage-users-input-cell">
                <LabeledTextInput className="manage-users-input"
                                  type={'password'}
                                  autoComplete="new-password"
                                  label={<Translation>{ (t, { i18n }) => t('PASSWORD') }</Translation>}
                                  value={newUser.password}
                                  handleSave={(aEvent) => this.handleSave(aEvent, 'password')}
                                  placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />
                <LabeledTextInput className="manage-users-input"
                                  type={'password'}
                                  autoComplete="new-password"
                                  label={<Translation>{ (t, { i18n }) => t('CONFIRM_PASSWORD') }</Translation>}
                                  value={confirmPassword}
                                  handleSave={(aEvent) => this.setState({confirmPassword:aEvent.target.value})}
                                  placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                />
              </div>
              
              <div className="manage-user-button-cell">
                {loading ?
                  <Loading/>
                  :
                  <BlueButton className='manage-users-button'
                              name={<Translation>{ (t, { i18n }) => t('INVITE') }</Translation>}
                              onClick={() => this.addNewUser()}
                  />
                }
              </div>
              
              <div className="modal-error"
                   style={{color:"red"}}>
                {this.props.currentUser?.error}
              </div>
            </>
          </div>
          :
          null
        }
      </div>
    )
  }
}
