import React from 'react';
import { Switch } from 'antd';
import './LabeledSwitch.scss';
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import Loading from "../../Loading/Loading";
import {Translation} from "react-i18next";

const LabeledSwitch = ({id, bold, label, checked, disabled, onChange, required, className, infoBlurb, errorMessage,
                         warningMessage, checkedChildren, descriptionLabel, popoverPlacement, menuButtonClicked,
                         unCheckedChildren, loading = false, defaultChecked = false,
                         switchPosition = 'right', showCheckedLabels = true}) => {
  
  // Render
  
  return (
    <div className={"labeled-switch-input-cell " + className}
         id={id}>
      <div className={'labeled-switch-input-container-' + (switchPosition === 'right' ? 'right' : 'bottom')}>
        <LabeledInfo bold={bold}
                     label={label}
                     className={'labeled-switch-labeled-info'}
                     required={required}
                     infoBlurb={infoBlurb}
                     description={descriptionLabel}
                     popoverPlacement={popoverPlacement}
                     menuButtonClicked={menuButtonClicked}
        />
        
        <div className={switchPosition === 'right' ? 'labeled-switch-cell-right' : 'labeled-switch-cell-bottom'}>
          <Loading className="labeled-switch-input-loading"
                   size={'mini'}
                   loading={loading}
                   showLabel={false}
          />
          
          <Switch className={'switch-input-field ' + (disabled ? 'switch-input-field-inactive' : '') + (errorMessage ? 'labeled-switch-input-error' : (warningMessage ? 'labeled-switch-input-warning' : ''))}
                  checked={checked}
                  disabled={disabled}
                  onChange={onChange}
                  defaultChecked={defaultChecked}
                  checkedChildren={showCheckedLabels ? (checkedChildren ? checkedChildren : <Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>) : null}
                  unCheckedChildren={showCheckedLabels ? (unCheckedChildren ? unCheckedChildren : <Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>) : null}
          />
        </div>
      </div>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};
export default LabeledSwitch;
