import React from 'react';
import './MedicalRecordModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import MedicalRecord from "../../Components/MedicalRecord/MedicalRecord";

export default class MedicalRecordModal extends React.Component {
  
  // Render
  
  render() {
    const {open, type, close, company, medicalRecord} = this.props;
    
    return (
      <GeneralModal title={i18next.t('MEDICAL_RECORD', {aName:(medicalRecord && medicalRecord.name? (medicalRecord.name + ' - ' + type) : type)})}
                    width={'calc(100% - 60px)'}
                    height={'500px'}
                    isOpen={open}
                    showFooter={false}
                    showHeader={true}
                    maxWidth={'1024px'}
                    noTitleHeading={true}
                    showSaveButton={false}
                    handleCloseModal={() => close()}
                    showCancelButton={false}
                    maxChildrenHeight={950}>
        <MedicalRecord type={type}
                       company={company}
                       medicalRecord={medicalRecord}
        />
      </GeneralModal>
    )
  }
}
