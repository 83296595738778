import { connect } from 'react-redux';
import actions from 'actions';
import ManageVirtualAppointments from 'Pages/ManageVirtualAppointments/ManageVirtualAppointments'

const mapStateToProps = (state, ownProps) => {
  let timeRange = state.timeRange;
  let virtualAppointments = state.virtualAppointments;
  let patients = state.patients && state.patients.patients;
  let company = state.company && state.company.company;
  return {
    patients,
    company,
    path: ownProps.match.path,
    groupName: ownProps.match.params.group,
    timeRange,
    virtualAppointments
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getVirtualAppointments:(aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy) => {
      return dispatch(actions.getVirtualAppointments(aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy));
    },
    saveVirtualAppointment:(aVirtualAppointment) => {
      return dispatch(actions.saveVirtualAppointment(aVirtualAppointment));
    },
    getVideoForVirtualAppointment:(aVirtualAppointmentId) => {
      return dispatch(actions.getVideoForVirtualAppointment(aVirtualAppointmentId));
    },
    removeVirtualAppointment:({virtualAppointmentId}) => {
      return dispatch(actions.removeVirtualAppointment({virtualAppointmentId}));
    },
    searchPatients:({query}) => {
      dispatch(actions.searchPatients({query}));
    },
    fetchPatientsScroll:(aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude) => {
      return dispatch(actions.fetchPatientsScroll(aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageVirtualAppointments)
