import Settings from "../settings";

export function endPoint(){
  let returnValue = Settings.endpoint;

  if(Settings.environment === 'UAT'){
    returnValue = window.location.origin;
  }
  return returnValue;
}

export function gqlEndPoint(){
  return endPoint() + '/queries';
}

export function environmentName(){
  let returnValue = Settings.environment;
  let origin = window.location.origin;
  
  if(returnValue === 'UAT'){
    origin = origin.replaceAll('creator.', '').replaceAll('https://', '');
    
    if(origin.includes('.')){
      returnValue = origin.substring(0, origin.indexOf('.'));
    }
  }
  return returnValue;
}
