import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'
import {UPDATE_HYPER_WALLET_CREDENTIALS} from "../constants/ActionTypes";
import {UPDATE_TREMENDOUS_CREDENTIALS} from "../constants/ActionTypes";

export function updateHyperWalletCredentials(aProgramToken, aUsername, aPassword, aURL){
  EventTracker.track(EventTypes.UPDATE_HYPER_WALLET_CREDENTIALS);
  let query = `
  mutation UpdateCompanyHyperwalletCredentials($updateCompanyHyperwalletCredentialsMutationInput: UpdateCompanyHyperwalletCredentialsMutationInput!) {
        updateCompanyHyperwalletCredentials(input: $updateCompanyHyperwalletCredentialsMutationInput) {
          company {
            id
            hyperwallet_program_token
            hyperwallet_api_url
            hyperwallet_api_username
            has_hyperwallet_api_password
            has_valid_hyperwallet_credentials
          }
        }
      }
  `;
  
  let operationName = `UpdateCompanyHyperwalletCredentials`;
  let params = {};

  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['hyperwallet_program_token'] = aProgramToken;
    params['hyperwallet_api_username'] = aUsername;
    params['hyperwallet_api_url'] = aURL;
    
    if(aPassword){
      params['hyperwallet_api_password'] = aPassword;
    }
    let variables = {
      updateCompanyHyperwalletCredentialsMutationInput:params
    };
    let promise = global.api.post("/queries", { query, variables, operationName });
    promise.then((newResponse) => {
      let company = newResponse.data.data.updateCompanyHyperwalletCredentials.company;
      dispatch({
        type: UPDATE_HYPER_WALLET_CREDENTIALS,
        result: company
      })
    });
    return promise;
  };
}

export function updateTremendousCredentials(aTremendousApiToken, aTremendousCampaignId, aTremendousFundingSourceId){
  EventTracker.track(EventTypes.UPDATE_TREMENDOUS_CREDENTIALS);
  let query = `
  mutation UpdateCompanyTremendousCredentials($updateCompanyTremendousCredentialsMutationInput: UpdateCompanyTremendousCredentialsMutationInput!) {
        updateCompanyTremendousCredentials(input: $updateCompanyTremendousCredentialsMutationInput) {
          company {
            id
            tremendous_funding_source_id
            tremendous_campaign_id
            tremendous_api_token
            has_valid_tremendous_credentials
          }
        }
      }
  `;

  let operationName = `UpdateCompanyTremendousCredentials`;
  let params = {};

  return (dispatch, getState) => {
    let state = getState();
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['tremendous_funding_source_id'] = aTremendousFundingSourceId;
    params['tremendous_campaign_id'] = aTremendousCampaignId;
    params['tremendous_api_token'] = aTremendousApiToken;
    
    let variables = {
      updateCompanyTremendousCredentialsMutationInput:params
    };
    let promise = global.api.post("/queries", { query, variables, operationName });
    promise.then((newResponse) => {
      let company = newResponse.data.data.updateCompanyTremendousCredentials.company;
      dispatch({
        type: UPDATE_TREMENDOUS_CREDENTIALS,
        result: company
      })
    });
    return promise;
  };
}
