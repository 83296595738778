import React from 'react';
import 'antd/dist/antd.css'
import './ReportTemplatesList.scss';
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {Translation} from "react-i18next";
import ReportTemplateModal from "../../Modals/ReportTemplateModal/ReportTemplateModal";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import GenerateReportModal from "../../Modals/GenerateReportModal/GenerateReportModal";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {isObject} from "../../Helpers/Helpers";

const notifyReportTemplateRemoveError = (aName, aError) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_REMOVED_ERROR', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateRemoveSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_REMOVED', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateGenerationError = (aName, aError) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_GENERATION_ERROR', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateDuplicationError = (aName, aError) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_DUPLICATION_ERROR', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateGenerationSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:aName}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateDuplicationSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_DUPLICATED', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);

export default class ReportTemplatesList extends React.Component {
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      shouldReload:false,
      showConfirmationModal:false,
      deletedReportTemplate:null,
      replacedReportTemplate:null,
      selectedReportTemplate:null,
      showGenerateReportModal:false,
      showReportTemplateModal:false
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {shouldReload, showConfirmationModal, deletedReportTemplate, replacedReportTemplate, selectedReportTemplate,
      showGenerateReportModal, showReportTemplateModal} = this.state;
    const {company, saveReportTemplate, fetchReportTemplate, fetchReportTemplates, removeReportTemplate,
      duplicateReportTemplate, generateReportFromTemplate, fetchModelConfigurations, fetchColumnConfigurations,
      fetchFilterConfigurations, fetchPreMadeRelationships, fetchRelationshipConfigurations} = this.props;
    
    // TODO: Add in defaults when permissions are broken.
    
    const role = roleForCompany(company);
    const permissionsArray = ['admin', 'admin_level_2', 'admin_level_4'];
    
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:1, columnNameKey:'REPORT_NAME', propertiesArray:['name']});
    columnsArray.push({key:'created_at', dateFormat:'YYYY-MM-DD', columnWeight:1, columnNameKey:'CREATED_AT', propertiesArray:['created_at']});
    columnsArray.push({key:'last_generate_arguments', columnWeight:2, columnNameKey:'LAST_GENERATED_ARGUMENTS', propertiesArray:['last_generate_arguments'], valueFormatter:(aValue) => {
        let returnValue = '';
        let keyAndValuesArray = [];
        
        for(let key of Object.keys(aValue)){
          if(key !== 'company_ids'){
            if(isObject(aValue[key])){
              for(let innerKey of Object.keys(aValue[key])){
                keyAndValuesArray.push(innerKey + ': ' + aValue[key][innerKey]);
              }
            }
            else if(aValue[key] !== null && aValue[key] !== 'null'){
              keyAndValuesArray.push(key + ': ' + aValue[key]);
            }
          }
        }
        for(let index = 0; index < keyAndValuesArray.length; index += 1){
          returnValue += keyAndValuesArray[index];
          
          if(index < keyAndValuesArray.length - 1){
            returnValue += ', ';
          }
        }
        return returnValue;
      }});
    columnsArray.push({key:'last_generated_at', dateFormat:'YYYY-MM-DD', columnWeight:1, columnNameKey:'LAST_GENERATED_AT', propertiesArray:['last_generated_at']});
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'EDIT', clickAction:(aObject) => this.setState({selectedReportTemplate:aObject, showReportTemplateModal:true})});
    menuItemsArray.push({title:'GENERATE', clickAction:(aObject) => this.setState({selectedReportTemplate:aObject, showGenerateReportModal:true})});
    menuItemsArray.push({title:'DUPLICATE', clickAction:(aObject) => duplicateReportTemplate(aObject.id).then((newResult) => {
        notifyReportTemplateDuplicationSuccess(aObject.name);
        this.setState({shouldReload:true});
    }, (newError) => notifyReportTemplateDuplicationError(aObject.name, errorMessageFromServerError(newError)))});
    menuItemsArray.push({title:'REMOVE', clickAction:(aObject) => this.setState({selectedReportTemplate:aObject, showConfirmationModal:true})});
    
    return (
      <div className="report-templates-list">
        <DynamicList id="report-builder-columns-list"
                     didReload={() => this.setState({shouldReload:false})}
                     menuCancel={() => this.setState({selectedReportTemplate:null})}
                     columnsArray={columnsArray}
                     fetchObjects={(aLimit, aOffset, aSearchString) => fetchReportTemplates(aLimit, aOffset, aSearchString)}
                     shouldReload={shouldReload}
                     menuItemsArray={menuItemsArray}
                     minColumnWidth={150}
                     objectToDelete={deletedReportTemplate}
                     objectToReplace={replacedReportTemplate}
                     finishedDeletingObject={() => this.setState({deletedReportTemplate:null})}
                     responseTotalKeysArray={['data', 'data', 'templates', 'total']}
                     finishedReplacingObject={() => this.setState({replacedReportTemplate:null})}
                     responseObjectKeysArray={['data', 'data', 'templates', 'templates']}
        />
  
        {showReportTemplateModal ?
          <ReportTemplateModal isOpen={showReportTemplateModal}
                               company={company}
                               reportTemplate={selectedReportTemplate}
                               handleCloseModal={() => this.setState({showReportTemplateModal:false, selectedReportTemplate:null})}
                               saveReportTemplate={saveReportTemplate}
                               fetchReportTemplate={fetchReportTemplate}
                               reportTemplateDidSave={(aReportTemplate) => this.setState({replacedReportTemplate:aReportTemplate})}
                               duplicateReportTemplate={duplicateReportTemplate}
                               fetchModelConfigurations={fetchModelConfigurations}
                               fetchColumnConfigurations={fetchColumnConfigurations}
                               fetchFilterConfigurations={fetchFilterConfigurations}
                               fetchPreMadeRelationships={fetchPreMadeRelationships}
                               fetchRelationshipConfigurations={fetchRelationshipConfigurations}
          />
          :
          null
        }
  
        {showGenerateReportModal ?
          <GenerateReportModal isOpen={showGenerateReportModal}
                               handleSave={(aId, aName, aFilters) => generateReportFromTemplate(aId, aName, aFilters).then((newResult) => {
                                 notifyReportTemplateGenerationSuccess(aName);
                                 let generatedReport = newResult.data;
                                 let reportTemplate = this.state.selectedReportTemplate;
                                 reportTemplate.last_generated_at = generatedReport.last_generated_at;
                                 reportTemplate.last_generate_arguments = generatedReport.last_generate_arguments;
                                 this.setState({showGenerateReportModal:false, replacedReportTemplate:reportTemplate, selectedReportTemplate:null});
                               }, (newError) => {
                                 notifyReportTemplateGenerationError(aName, errorMessageFromServerError(newError));
                                 this.setState({showGenerateReportModal:false, selectedReportTemplate:null});
                               })}
                               handleClose={() => this.setState({showGenerateReportModal:false, selectedReportTemplate:null})}
                               reportTemplate={selectedReportTemplate}
                               fetchReportTemplate={fetchReportTemplate}
          />
          :
          null
        }
        
        <ConfirmationModal title={<Translation>{ (t, { i18n }) => t('CONFIRM_REMOVE_REPORT_TEMPLATE') }</Translation>}
                           isOpen={showConfirmationModal}
                           reject={() => this.setState({showConfirmationModal:false, selectedReportTemplate:null})}
                           confirm={() => {
                             removeReportTemplate(selectedReportTemplate.id).then((newResponse) => {
                               notifyReportTemplateRemoveSuccess(selectedReportTemplate.name);
                               this.setState({showConfirmationModal:false, deletedReportTemplate:selectedReportTemplate, selectedReportTemplate:null});
                             }, (newError) => {
                               notifyReportTemplateRemoveError(selectedReportTemplate.name, errorMessageFromServerError(newError));
                               this.setState({showConfirmationModal:false, selectedReportTemplate:null});
                             });
                           }}
                           description={<Translation>{ (t, { i18n }) => t('CONFIRM_REMOVE_REPORT_TEMPLATE_DESCRIPTION', {aName:selectedReportTemplate ? selectedReportTemplate.name : ''}) }</Translation>}
        />
      </div>
    )
  }
}
