export function getSurveyResponses(aPatientId, aSurveyVersionCollectionId, aLimit, aOffset){
  let query = `
    query getSurveyResponsePage ($company_id: ID!, $user_id: ID!, $survey_id: ID, $survey_version_collection_id: ID, $offset: Int, $limit: Int) {
      survey_response_page (company_id: $company_id, user_id: $user_id, survey_id: $survey_id, survey_version_collection_id: $survey_version_collection_id, offset: $offset, limit: $limit){
          survey_responses {
            id
            admin_id
            complete
            progress
            admin_name
            created_at
            lock_state
            updated_at
            completed_at
            completed_by_admin
            survey {
              id
              name
            }
            important_questions {
              key
              label
              survey_response_detail_value
            }
          }
          total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['user_id'] = aPatientId;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['survey_version_collection_id'] = aSurveyVersionCollectionId;
    return global.api.post("/queries", { query, variables });
  };
}
