import { toast } from 'react-toastify'
export const NOTIFY_OPTS = {
  default:{hideProgressBar:true, position:toast.POSITION.BOTTOM_RIGHT, autoClose:false},
  autoClose:{hideProgressBar:true, position:toast.POSITION.TOP_CENTER, autoClose:2000, pauseOnHover:false},
  defaultFromTop:{hideProgressBar:true, position:toast.POSITION.TOP_CENTER, autoClose:false},
  autoCloseWithId:(aId, aDuration = 3500) => {return {toastId:aId, hideProgressBar:true, position:toast.POSITION.TOP_CENTER, autoClose:aDuration, pauseOnHover:true}},
  defaultWithDuration:{hideProgressBar:true, position:toast.POSITION.BOTTOM_RIGHT, autoClose:5000, pauseOnHover:false},
  autoCloseFiveSeconds:{hideProgressBar:true, position:toast.POSITION.TOP_CENTER, autoClose:5000, pauseOnHover:false},
  autoCloseThreeSeconds:{hideProgressBar:true, position:toast.POSITION.TOP_CENTER, autoClose:3500, pauseOnHover:true}
};
