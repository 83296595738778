import React, {useState} from 'react';
import {Translation} from "react-i18next";


const ImageObjective = ({ objective, selectedTranslationIndex }) => {
    const hiddenFileInput = React.useRef(null);
    const [imageUrl, setImageUrl] = useState(null);

    const picture = (
        (imageUrl ?
            <img className={'profile-picture-image-positioning'}
                alt=''
                 src={imageUrl}
                 width={'100%'}
                 height={'150'}
                 style={{marginTop: 10}}
            />
            :
            null
        )
      );

    const uploadImage = (aEvent) => {
      const file = aEvent.target.files[0];
      setImageUrl(URL.createObjectURL(file))
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <label>
                {objective.translations[selectedTranslationIndex].description}
            </label>
            <button style={{backgroundColor: "orange"}} onClick={() => hiddenFileInput.current.click()}>
                <Translation>{ (t, { i18n }) => t('CHOOSE_FILE') }</Translation>
            </button>
            <input style={{display:'none'}}
                   type="file"
                   ref={hiddenFileInput}
                   onChange={(aEvent) => uploadImage(aEvent)}
                   accept="image/x-png, image/jpeg"
            />
            {picture}
        </div>
    );
}

export default ImageObjective
