import React from 'react';
import './PaymentTrigger.scss';
import moment from 'moment';
import {toast} from "react-toastify";
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {questsArrayForCompany} from "../../Helpers/ToolHelpers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyPaymentTriggerCreateError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_CREATING_PAYMENT_TRIGGER', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPaymentTriggerUpdateError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_UPDATING_PAYMENT_TRIGGER', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyFetchQuestionKeywordsError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_FETCHING_QUESTION_KEYWORDS', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPaymentTriggerCreatedSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_TRIGGER_CREATED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPaymentTriggerUpdatedSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_TRIGGER_UPDATED', {name:aName}) }</Translation>, NOTIFY_OPTS.autoClose);

export default class PaymentTrigger extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      endAt:null,
      limit:'1',
      title:'',
      amount:'',
      saving:false,
      enabled:true,
      startAt:null,
      currency:'EUR',
      targetId:'',
      targetType:'',
      questsArray:[],
      targetAction:'completed',
      targetExpectedValue:'',
      showAdvancedSettings:false,
      questionKeywordsArray:[],
      targetQuestionKeyword:'',
      isFetchingQuestionKeywords:false,
      manualVerificationRequired:true
    };
    if(props.paymentTrigger){
      if(props.paymentTrigger.end_at){
        this.state.endAt = props.paymentTrigger.end_at.substr(0,10);
      }
      if(props.paymentTrigger.start_at){
        this.state.startAt = props.paymentTrigger.start_at.substr(0,10);
      }
      this.state.limit = props.paymentTrigger.limit_per_user;
      this.state.title = props.paymentTrigger.title;
      this.state.amount = props.paymentTrigger.amount;
      this.state.enabled = props.paymentTrigger.is_enabled;
      this.state.currency = props.paymentTrigger.currency;
      this.state.targetId = props.paymentTrigger.target_id;
      this.state.targetType = props.paymentTrigger.target_type;
      this.state.targetAction = props.paymentTrigger.target_action;
      this.state.manualVerificationRequired = props.paymentTrigger.manual_verification_required;
      
      if(props.paymentTrigger.additional_target_attributes){
        this.state.targetExpectedValue = props.paymentTrigger.additional_target_attributes.target_expected_value;
        this.state.targetQuestionKeyword = props.paymentTrigger.additional_target_attributes.target_question_keyword;
      }
    }
    if(props.surveyId){
      this.state.targetType = 'SurveyVersionCollection';
      this.state.targetId = props.surveyId;
    }
    this.handleCreatePaymentTrigger = this.handleCreatePaymentTrigger.bind(this);
    this.handleUpdatePaymentTrigger = this.handleUpdatePaymentTrigger.bind(this);
  };
  
  componentDidMount(){
    this.loadQuestsArray();
    this.getQuestionKeywords();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {company, paymentTrigger, updateButtonPressed} = this.props;
    const {saving, questsArray} = this.state;
    
    if(prevProps.company !== company || (company && questsArray.length === 0 && (prevState.questsArray.length !== questsArray.length))){
      this.loadQuestsArray();
    }
    if(!prevProps.updateButtonPressed && updateButtonPressed && !saving){
      if(paymentTrigger){
        this.handleUpdatePaymentTrigger();
      }
      else{
        this.handleCreatePaymentTrigger();
      }
    }
  }
  
  // Methods
  
  reset(){
    this.setState({
      endAt:null,
      limit:'',
      title:'',
      amount:'',
      saving:false,
      enabled:true,
      startAt:null,
      currency:'EUR',
      targetId:'',
      targetType:'',
      targetAction:'completed',
      showAdvancedSettings:false,
      isFetchingQuestionKeywords:false,
      manualVerificationRequired:true
    });
    // Note: targetAction can be "signed" or "completed", but "signed" only applies to consent objectives. Implement later if required.
  }
  
  getQuestionKeywords(){
    const {targetId, targetType, isFetchingQuestionKeywords} = this.state;
    
    if(targetType === 'SurveyVersionCollection' && targetId && !isFetchingQuestionKeywords){
      this.setState({isFetchingQuestionKeywords:true});
      this.props.fetchQuestionsForSurveyById(targetId).then(newResult => {
        this.setState({questionKeywordsArray:newResult.data.data.survey_questions_for_survey_version_collection, isFetchingQuestionKeywords:false});
      }, newError => {
        let error = '';
    
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        notifyFetchQuestionKeywordsError(error);
        this.setState({isFetchingQuestionKeywords:false});
      });
    }
  }
  
  loadQuestsArray(){
    this.setState({questsArray:questsArrayForCompany(this.props.company)});
  }
  
  handleLimitChange = (aEvent) => {
    if(aEvent.target.value && Number(aEvent.target.value) > 0){
      this.setState({limit:aEvent.target.value});
    }
  };
  
  handleTargetChange = (aOption) => {
    let selectedQuest = this.state.questsArray.filter((quest) => (quest.survey_version_collection ? quest.survey_version_collection.id === aOption.value : quest.id === aOption.value))[0];

    if(selectedQuest){
      if(selectedQuest.survey_version_collection && selectedQuest.survey_version_collection.id){
        this.setState({targetId:selectedQuest.survey_version_collection.id, targetType:'SurveyVersionCollection'}, () => {
          this.getQuestionKeywords();
        });
      }
      else if(selectedQuest.quest_type === 'Tool' || selectedQuest.quest_type === 'Consent' || selectedQuest.quest_type === 'Survey'){
        this.setState({targetId:selectedQuest.id, targetType:'Quest'});
      }
    }
  };
  
  formatDate = (aDate) => {
    let returnValue = '';
    
    if(aDate){
      returnValue = aDate.toString();
    }
    return returnValue;
  };
  
  handleCreatePaymentTrigger(){
    this.setState({saving:true}, () => {
      const {updateFailed, updateFinished, createPaymentTrigger} = this.props;
      const {title, amount, targetId, targetType, targetAction, currency, limit, manualVerificationRequired, enabled,
        startAt, endAt, targetExpectedValue, targetQuestionKeyword} = this.state;
      
      createPaymentTrigger(title, Number(amount), targetId, targetType, targetAction, currency,
        Number(limit), manualVerificationRequired, enabled, this.formatDate(startAt), this.formatDate(endAt), targetExpectedValue, targetQuestionKeyword).then((newResult) => {
        notifyPaymentTriggerCreatedSuccess();
        this.reset();
  
        if(updateFinished){
          updateFinished();
        }
      }, (newError) => {
        notifyPaymentTriggerCreateError(errorMessageFromServerError(newError));
        this.setState({saving:false});
  
        if(updateFailed){
          updateFailed();
        }
      });
    });
  }
  
  handleUpdatePaymentTrigger(){
    this.setState({saving:true}, () => {
      const {updateFailed, paymentTrigger, updateFinished, updatePaymentTrigger} = this.props;
      const {title, amount, targetId, targetType, targetAction, currency, limit, manualVerificationRequired, enabled,
        startAt, endAt, targetExpectedValue, targetQuestionKeyword} = this.state;
      
      updatePaymentTrigger(paymentTrigger.id, title, Number(amount), targetId, targetType,
        targetAction, currency, Number(limit), manualVerificationRequired, enabled, this.formatDate(startAt),
        this.formatDate(endAt), targetExpectedValue, targetQuestionKeyword).then((newResult) => {
        notifyPaymentTriggerUpdatedSuccess(title);
        this.reset();
        
        if(updateFinished){
          updateFinished();
        }
      }, (newError) => {
        notifyPaymentTriggerUpdateError(errorMessageFromServerError(newError));
        this.setState({saving:false});
        
        if(updateFailed){
          updateFailed();
        }
      });
    });
  }
  
  // Render
  
  render() {
    const {company, className, surveysArray, paymentTrigger, hideCreateButton} = this.props;
    const {questsArray, saving, targetId, targetType, startAt, endAt, currency, amount, showAdvancedSettings, enabled,
      limit, title, targetQuestionKeyword, questionKeywordsArray, manualVerificationRequired, isFetchingQuestionKeywords,
      targetExpectedValue} = this.state;
    
    const currentRole = roleForCompany(company);
    const currencyOptionsArray = [{value:'EUR', label:'EUR'}, {value:'USD', label:'USD'}, {value:'CAD', label:'CAD'}];
    const paymentTriggerPermissionsArray = ["admin", "admin_level_4"];
    
    let questOptionsArray = [];
    
    let toolsArray = questsArray;
    
    if(surveysArray && surveysArray.length > 0){
      toolsArray = surveysArray;
    }
    for(let quest of toolsArray){
      let title = quest.title;
      
      if(quest.title === undefined){
        title = quest.name;
      }
      if(quest.survey_version_collection){
        questOptionsArray.push({value:quest.survey_version_collection.id, label:title + ' - ' + quest.id});
      }
      else{
        questOptionsArray.push({value:quest.id, label:title + ' - ' + quest.id});
      }
    }
    
    let questionKeywordOptionsArray = [];
    
    for(let keyword of questionKeywordsArray){
      questionKeywordOptionsArray.push({value:keyword.key, label:keyword.key});
    }
    
    return (
      <div className={'payment-modal ' + className ? className : ''}>
        {paymentTriggerPermissionsArray.includes(currentRole) ?
          <>
            <div className="payment-trigger-large-dual-input">
              <LabeledTextInput className="payment-trigger-input-cell-input-title"
                                label={<Translation>{ (t, { i18n }) => t('PAYMENT_TITLE')}</Translation>}
                                value={title}
                                handleSave={(aEvent) => this.setState({title:aEvent.target.value})}
                                placeholder={i18next.t('TAP_TO_SELECT')}
              />
              
              <div className="payment-trigger-input-criteria">
                <div className="payment-trigger-input-title">
                  <Translation>{ (t, { i18n }) => t('TARGET_CRITERIA')}</Translation>
                </div>
                
                <div className="payment-trigger-text-cell-input">
                  <Translation>{ (t, { i18n }) => t('WHEN_THE')}</Translation>
                  
                  <LabeledDropDown className="payment-trigger-text-input-dropdown modal-input-small"
                                   value={targetId && questOptionsArray.filter(option => {
                                     return option.value === targetId
                                   })}
                                   options={questOptionsArray}
                                   handleSave={this.handleTargetChange}
                  />
                  
                  <Translation>{ (t, { i18n }) => t('IS_COMPLETED')}</Translation>
                </div>
              </div>
            </div>
  
            {targetType === 'SurveyVersionCollection' ?
              <div className="payment-trigger-large-dual-input">
                <LabeledTextInput className="payment-trigger-input-cell-input-text"
                                  label={<Translation>{(t, {i18n}) => t('TARGET_EXPECTED_VALUE')}</Translation>}
                                  value={targetExpectedValue}
                                  infoBlurb={<Translation>{ (t, { i18n }) => t('TARGET_EXPECTED_VALUE_EXPLANATION') }</Translation>}
                                  handleSave={(aEvent) => this.setState({targetExpectedValue:aEvent.target.value})}
                                  placeholder={i18next.t('TAP_TO_SELECT')}
                />
      
                <LabeledDropDown className="payment-trigger-input-cell-input-dropdown"
                                 label={<Translation>{(t, {i18n}) => t('TARGET_QUESTION_KEYWORD')}</Translation>}
                                 value={targetQuestionKeyword && questionKeywordOptionsArray.filter(option => {
                                   return option.value === targetQuestionKeyword
                                 })}
                                 options={questionKeywordOptionsArray}
                                 disabled={isFetchingQuestionKeywords}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('TARGET_QUESTION_KEYWORD_EXPLANATION') }</Translation>}
                                 handleSave={(aOption) => this.setState({targetQuestionKeyword:aOption.value})}
                />
              </div>
              :
              null
            }
            
            <div className="payment-trigger-input-cell-container">
              <div className="payment-trigger-input-cell">
                <LabeledDatePicker className="payment-trigger-input-cell-input-start-date"
                                   label={<Translation>{ (t, { i18n }) => t('START_DATE_PROPER_CAPITALIZED')}</Translation>}
                                   value={startAt}
                                   minDate={moment().toDate()}
                                   handleSave={(aMoment) => this.setState({startAt:aMoment})}
                                   selectedDate={startAt}
                />
                
                <LabeledDatePicker className="payment-trigger-input-cell-input-end-date"
                                   label={<Translation>{ (t, { i18n }) => t('END_DATE_PROPER_CAPITALIZED')}</Translation>}
                                   value={endAt}
                                   minDate={moment().toDate()}
                                   handleSave={(aMoment) => this.setState({endAt:aMoment})}
                                   selectedDate={endAt}
                />
              </div>
              
              <div className="payment-trigger-input-cell">
                <LabeledDropDown className="payment-trigger-input-cell-input-value-currency"
                                 label={<Translation>{ (t, { i18n }) => t('CURRENCY')}</Translation>}
                                 value={currency && currencyOptionsArray.filter(option => {
                                   return option.value === currency
                                 })}
                                 options={currencyOptionsArray}
                                 handleSave={(aOption) => this.setState({currency:aOption.value})}
                />
                
                <LabeledTextInput className="payment-trigger-input-cell-input-value-amount"
                                  type={'number'}
                                  label={<Translation>{ (t, { i18n }) => t('AMOUNT')}</Translation>}
                                  value={amount}
                                  minValue={'0'}
                                  handleSave={(aEvent) => this.setState({amount:aEvent.target.value})}
                                  placeholder={i18next.t('TAP_TO_ENTER_LOWERCASE')}
                />
              </div>
            </div>
            
            {showAdvancedSettings ?
              <div className="payment-trigger-advanced-settings">
                <BlueButton className="payment-trigger-button"
                            name={<Translation>{(t, {i18n}) => t('HIDE_ADVANCED')}</Translation>}
                            onClick={() => this.setState({showAdvancedSettings:false})}
                />
                
                <div className="payment-trigger-advanced-settings-input-cell">
                  <LabeledTextInput className="payment-trigger-advanced-settings-input"
                                    type={'number'}
                                    label={<Translation>{(t, {i18n}) => t('LIMIT')}</Translation>}
                                    value={limit}
                                    minValue={'1'}
                                    handleSave={this.handleLimitChange}
                                    placeholder={i18next.t('TAP_TO_ENTER_LOWERCASE')}
                  />
  
                  <LabeledSwitch className="payment-trigger-advanced-settings-input-switch"
                                 label={<Translation>{ (t, { i18n }) => t('ENABLE_NOW') }</Translation>}
                                 checked={enabled}
                                 onChange={(isChecked) => this.setState({enabled:isChecked})}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('ENABLE_NOW_EXPLANATION') }</Translation>}
                                 defaultChecked={true}
                                 checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                 popoverPlacement={"top"}
                                 unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                  />
  
                  <LabeledSwitch className="payment-trigger-advanced-settings-input-switch"
                                 label={<Translation>{ (t, { i18n }) => t('MANUAL_VERIFICATION') }</Translation>}
                                 checked={manualVerificationRequired}
                                 onChange={(isChecked) => this.setState({manualVerificationRequired:isChecked})}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('LONG_TEXT_STRING') }</Translation>}
                                 defaultChecked={true}
                                 checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                 popoverPlacement={"top"}
                                 unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                  />
                </div>
              </div>
              :
              <BlueButton className="payment-trigger-button payment-trigger-advanced"
                          name={<Translation>{(t, {i18n}) => t('ADVANCED')}</Translation>}
                          onClick={() => this.setState({showAdvancedSettings:true})}
              />
            }
            
            {!hideCreateButton ?
              <div className="payment-button-container">
                {saving ?
                  <Loading style={{width:40, height:0}}/>
                  :
                  (paymentTrigger ?
                    <BlueButton className="payment-trigger-button payment-trigger-advanced"
                                name={<Translation>{(t, {i18n}) => t('UPDATE')}</Translation>}
                                onClick={() => this.handleUpdatePaymentTrigger()}
                    />
                    :
                    <BlueButton className="payment-trigger-button payment-trigger-advanced"
                                name={<Translation>{(t, {i18n}) => t('CREATE_PROPER_CAPITALIZED')}</Translation>}
                                onClick={() => this.handleCreatePaymentTrigger()}
                    />
                  )
                }
              </div>
              :
              null
            }
          </>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('PAYMENT_TRIGGERS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
