import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './FraudEventsList.scss';
import i18next from "i18next";
import Popper from "@material-ui/core/Popper";
import ClearNotificationReceiptDropDown from "../../Custom UI/DropDowns/ClearNotificationReceiptDropDown/ClearNotificationReceiptDropDown";
import {CheckCircle} from "@material-ui/icons";
import ErrorIcon from '@material-ui/icons/Error';
import Popover from "antd/lib/popover";
import Icon from "antd/lib/icon";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import DynamicList from "../DynamicList/DynamicList";
import {FraudEventTypes} from "../../constants/FraudEventTypes";
import {withRouter} from "react-router-dom";
import {formatUserName} from "../../Helpers/AdminHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";

class FraudEventsList extends React.Component {

  // Instance Variables


  // Init

  constructor(props){
    super(props);
    this.state = {
      verifyFraud:false,
      shouldReload:false,
      fraudStatistics:{},
      totalFraudEvents:0,
      fraudEventToUpdate:null,
      selectedFraudEvent:null,
      commentAnchorElement:null,
      unverifiedFraudEvents:0
    };
    this.handleCellClick = this.handleCellClick.bind(this);
    this.verifyFraudEvent = this.verifyFraudEvent.bind(this);
    this.verifyFraudStatistics = this.verifyFraudStatistics.bind(this);
    this.renderFraudEventStatus = this.renderFraudEventStatus.bind(this);
  }

  componentDidMount(){
    this.getFraudStatistics();
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.patient !== this.props.patient || prevProps.group !== this.props.group || prevProps.company !== this.props.company){
      this.resetState();
      this.getFraudStatistics();
    }
  }

  // Methods

  resetState(){
    this.setState({
      verifyFraud:false,
      shouldReload:true,
      fraudStatistics:{},
      totalFraudEvents:0,
      menuAnchorElement:null,
      selectedFraudEvent:null,
      commentAnchorElement:null,
      unverifiedFraudEvents:0
    });
  }

  getFraudStatistics(){
    if(this.props.patient){
      this.props.fetchFraudStatistics(this.props.patient.id).then(newResponse => {
        let user = newResponse.data.data.user;
        this.setState({fraudStatistics:user.fraud_statistic, unverifiedFraudEvents:user.number_of_unverified_fraud_events});
      });
      // this.renderTotal();
    }
  }

  verifyFraudEvent(aFraudEvent, aComment){
    const {markFraudEventAsVerified, setUnverifiedFraudEvents} = this.props;
    this.setState({menuAnchorElement:null, selectedFraudEvent:null, commentAnchorElement:null});

    if(markFraudEventAsVerified){
      markFraudEventAsVerified(aFraudEvent.id, aComment).then(newResponse => {
        const fraudEvent = newResponse.data.data.verifyFraudEvent.fraud_event;
        const unverifiedFraudEvents = Math.max((this.state.unverifiedFraudEvents - 1), 0);
        this.setState({unverifiedFraudEvents:unverifiedFraudEvents, fraudEventToUpdate:fraudEvent});

        if(setUnverifiedFraudEvents){
          setUnverifiedFraudEvents(unverifiedFraudEvents);
        }
      }, newError => {

      });
    }
    else{
      console.log('markFraudEventAsVerified is not a function.', markFraudEventAsVerified);
    }
  }

  verifyFraudStatistics(aFraudStatistics, aFraudEvent, aComment){
    const {markFraudStatisticAsVerified, markFraudStatisticAsFraudulent} = this.props;
    let fraudStatisticsId;

    if(aFraudStatistics && aFraudStatistics.id){
      fraudStatisticsId = aFraudStatistics.id;
    }
    else if(aFraudEvent && aFraudEvent.user && aFraudEvent.user.fraud_statistic){
      fraudStatisticsId = aFraudEvent.user.fraud_statistic.id;
    }
    this.setState({menuAnchorElement:null, selectedFraudEvent:null, commentAnchorElement:null});

    if(this.state.verifyFraud){
      if(markFraudStatisticAsVerified){
        markFraudStatisticAsVerified(fraudStatisticsId, aComment).then(newResponse => {
          let fraudStatistics = newResponse.data.data.verifyFraudStatistic.fraud_statistic;

          if(aFraudStatistics && aFraudStatistics.id){
            this.setState({fraudStatistics:fraudStatistics});
          } else{
            aFraudEvent.user.fraud_statistic = fraudStatistics;
            this.setState({shouldReload:true});
            // this.renderTotal();
          }
        }, newError => {

        });
      }
    }
    else{
      if(markFraudStatisticAsFraudulent){
        markFraudStatisticAsFraudulent(fraudStatisticsId, aComment).then(newResponse => {
          let fraudStatistics = newResponse.data.data.verifyFraudStatistic.fraud_statistic;

          if(aFraudStatistics && aFraudStatistics.id){
            this.setState({fraudStatistics:fraudStatistics});
          } else{
            aFraudEvent.user.fraud_statistic = fraudStatistics;
            this.setState({shouldReload:true});
            // this.renderTotal();
          }
        }, newError => {

        });
      }
    }
  }

  handleCellClick(aObject){
    let patientLink = '#';

    if(aObject && aObject.user){
      let patientId = aObject.user.id;

      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + patientId;
      }
      else{
        patientLink = '/patients/' + patientId;
      }
      patientLink += '?section=spam';
      this.props.history.push(patientLink);
    }
  }

  renderFraudEventStatus(aFraudEvent){
    let fraudStatus = '';
    let isVerified = false;
    let verifiedByUser;
    let verifiedComments;

    if(aFraudEvent && aFraudEvent.user && aFraudEvent.user.fraud_statistic){
      if(aFraudEvent.user.fraud_statistic.verification_status === 'verified'){
        isVerified = true;
        fraudStatus = i18next.t('VERIFIED');
      }
      else if(aFraudEvent.user.fraud_statistic.verification_status === 'fraudulent'){
        fraudStatus = i18next.t('NOT_VERIFIED');
      }
      verifiedByUser = aFraudEvent.user.fraud_statistic.verified_by_user;
      verifiedComments = aFraudEvent.user.fraud_statistic.verified_comments;
    }
    else{
      fraudStatus = i18next.t('PENDING');

      if(aFraudEvent.verification_status === 'verified'){
        isVerified = true;
        fraudStatus = i18next.t('VERIFIED');
      }
      else if(aFraudEvent.verification_status === 'fraudulent'){
        fraudStatus = i18next.t('FRAUDULENT');
      }
      verifiedByUser = aFraudEvent.verified_by_user;
      verifiedComments = aFraudEvent.verified_comments;
    }

    return (
      <div>
        {fraudStatus && fraudStatus.length > 0 ?
          <div className="fraud-event-cell-alert-status">
            {isVerified ?
              <CheckCircle className="checkmark" style={{color:'green', marginTop:'3px'}}/>
              :
              <ErrorIcon style={{color:'#E02020', fontSize:'17px'}}/>
            }

            <span>
              {fraudStatus}
            </span>
          </div>
          :
          null
        }

        {verifiedByUser ?
          <div>
            <div>
              {formatUserName(verifiedByUser)}
            </div>

            {verifiedComments ?
              <Popover content={verifiedComments} placement="bottom">
                <div className="fraud-event-cell-popover-button">
                  <Icon type="ellipsis" />
                </div>
              </Popover>
              :
              null
            }
          </div>
          :
          null
        }
      </div>
    );
  }

  isFraudEventVerificationPending = (aFraudEvent) => {
    if(this.props.patient) {
      return aFraudEvent?.verification_status === 'pending';
    }
    return aFraudEvent?.user?.fraud_statistic?.verification_status === 'pending';
  }

  formatVerifiedByUserName = (aUser) => {
    let returnValue = formatUserName(aUser);
    if(returnValue) {
      returnValue = 'by ' + returnValue;
    }
    return returnValue;
  }

  // Render

  render() {
    const { verifyFraud, fraudStatistics, selectedFraudEvent, commentAnchorElement,
      shouldReload, fraudEventToUpdate, totalFraudEvents, unverifiedFraudEvents} = this.state;
    const {patient, company, height, showName, explanationKey, listContainerHeight, redirectToPatientProfile, group, fetchFraudEvents} = this.props;

    let fraudStatus = i18next.t('PENDING');

    if(fraudStatistics && fraudStatistics.verification_status === 'verified'){
      fraudStatus = i18next.t('VERIFIED');
    }
    else if(fraudStatistics && fraudStatistics.verification_status === 'fraudulent'){
      fraudStatus = i18next.t('NOT_VERIFIED');
    }

    const fraudPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4"];
    const fraudActionPermissions = ["admin", "admin_level_2", "admin_level_3", "admin_level_4"];
    const fraudEventDetailsPermissions = ["admin", "admin_level_4"];
    let currentRole = roleForCompany(company);
    let shouldShowName = currentRole !== 'admin_level_1' && showName;
    let shouldShowDetails = fraudEventDetailsPermissions.includes(currentRole);

    let columnsArray = [];

    if(shouldShowName && !patient){
      columnsArray.push({key:'user', columnWeight:1, columnNameKey:'FRAUD_EVENT_NAME', propertiesArray:['user'], valueFormatter:(aValue) => formatUserName(aValue)});
    }
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'FRAUD_EVENT_DATE', propertiesArray:['created_at'], dateFormat:'MMM D YYYY, h:mma'});
    columnsArray.push({key:'event_type', columnWeight:1, columnNameKey:'FRAUD_EVENT', propertiesArray:['event_type'], valueFormatter:(aValue) => FraudEventTypes[aValue]});

    if(shouldShowDetails){
      columnsArray.push({key:'string_value', columnWeight:1, columnNameKey:'FRAUD_EVENT_DETAIL', propertiesArray:['string_value']});
    }
    columnsArray.push({key:'event_status', columnWeight:1, columnNameKey:'FRAUD_EVENT_STATUS', templateCell:(aFraudEvent) => this.renderFraudEventStatus(aFraudEvent)});

    const menuItemsArray = [];
    menuItemsArray.push({title:'RESOLVE', clickAction:(aObject, aRow, aEvent, aMenuAnchorElement) => this.setState({selectedFraudEvent:aObject, commentAnchorElement:aMenuAnchorElement}), isValid:(aFraudEvent) => patient && this.isFraudEventVerificationPending(aFraudEvent)});
    menuItemsArray.push({title:'MARK_PATIENT_AS_VERIFIED', clickAction:(aObject, aRow, aEvent, aMenuAnchorElement) => this.setState({selectedFraudEvent:aObject, commentAnchorElement:aMenuAnchorElement, verifyFraud:true}), isValid:(aFraudEvent) => !patient && this.isFraudEventVerificationPending(aFraudEvent)})
    menuItemsArray.push({title:'MARK_PATIENT_AS_NOT_VERIFIED', clickAction:(aObject, aRow, aEvent, aMenuAnchorElement) => this.setState({selectedFraudEvent:aObject, commentAnchorElement:aMenuAnchorElement, verifyFraud:false}), isValid:(aFraudEvent) => !patient && this.isFraudEventVerificationPending(aFraudEvent)})

    let total = totalFraudEvents;

    if(patient){
      total = unverifiedFraudEvents + '/' + totalFraudEvents;
    }

    return (
      <div>
        {fraudPermissions.includes(currentRole) ?
          <div>
            <div className="fraud-events-title-cell">
              <div className="fraud-events-search-status">

                {fraudStatistics && fraudStatistics.id ?
                  <>
                    {fraudStatistics.verification_status === 'verified' ?
                      <CheckCircle className="checkmark" style={{color:'green'}}/>
                      :
                      <ErrorIcon style={{color:'#E02020', height:'15px', marginBottom:'3px'}}/>
                    }

                    <span>
                      {fraudStatus}
                    </span>

                    {fraudStatistics.verification_status === 'pending' ?
                      <BiDotsVerticalRounded style={{fontSize: '21px', marginLeft: 'auto'}}
                                             onClick={fraudActionPermissions.includes(currentRole) ?
                                               (aEvent) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedFraudEvent:null})
                                               :
                                               null
                                             }
                      />
                      :
                      null
                    }

                    {fraudStatistics.verified_comments ?
                      <Popover content={fraudStatistics.verified_comments} placement="bottom">
                        <div className="fraud-events-title-popover-button">
                          <Icon type="ellipsis"/>
                        </div>
                      </Popover>
                      :
                      null
                    }
                  </>
                  :
                  null
                }

                {fraudStatistics && fraudStatistics.verified_by_user ?
                  <div className="fraud-events-search-popover-cell">
                    &nbsp;<div>{this.formatVerifiedByUserName(fraudStatistics.verified_by_user)}</div>
                  </div>
                  :
                  null
                }
              </div>
            </div>

            <div className="fraud-events-search-list-container"
                 style={{height:listContainerHeight ? listContainerHeight : '365px'}}>

              {patient ?
                <div className="fraud-events-search-container">
                  <div className="fraud-events-search-title">
                    <Translation>{(t, {i18n}) => t('FRAUD_STATISTICS')}</Translation>
                  </div>

                  <div className="fraud-events-search-inputs">
                    {shouldShowName && patient ?
                      <LabeledTextInput className="fraud-events-name"
                                           disabled={true}
                                           label={i18next.t('PATIENT_NAME')}
                                           value={formatUserName(patient)}
                      />
                      :
                      null
                    }

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('AVERAGE_SECONDS_PER_SURVEY_QUESTION')}
                                         value={fraudStatistics ? fraudStatistics.average_seconds_per_survey_question : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('NUMBER_OF_USERS_WITH_THE_SAME_IP_ADDRESS')}
                                         value={fraudStatistics ? fraudStatistics.number_of_users_with_the_same_ip_address : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('NUMBER_OF_USERS_WITH_THE_SAME_PRIMARY_CONTACT_NUMBER')}
                                         value={fraudStatistics ? fraudStatistics.number_of_users_with_the_same_primary_contact_number : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('NUMBER_OF_USERS_WITH_THE_SAME_DEVICE')}
                                         value={fraudStatistics ? fraudStatistics.number_of_users_with_the_same_device : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('IP_ADDRESS_STATE_MATCHES_ADDRESS')}
                                         value={fraudStatistics ? fraudStatistics.ip_address_state_matches_address : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('IP_ADDRESS_CITY_MATCHES_ADDRESS')}
                                         value={fraudStatistics ? fraudStatistics.ip_address_city_matches_address : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('PRIMARY_CONTACT_NUMBER_CALLER_ID_IS_AVAILABLE')}
                                         value={fraudStatistics ? fraudStatistics.primary_contact_number_caller_id_available : ''}
                    />

                    <LabeledTextInput className="fraud-events-name"
                                         disabled={true}
                                         label={i18next.t('PRIMARY_CONTACT_NUMBER_CALLER_ID_MATCH')}
                                         value={fraudStatistics ? fraudStatistics.primary_contact_number_caller_id_match : ''}
                    />
                  </div>
                </div>
                :
                null
              }

              <div className="fraud-events-container">
                <div id="fraud-events-list"
                     style={{height:height ? height : '309x'}}>
                  <DynamicList id="fraud-events-list"
                               showTotal={false}
                               didReload={() => this.setState({shouldReload:false})}
                               menuCancel={() => this.setState({selectedFraudEvent:null})}
                               selectCell={redirectToPatientProfile ? (aObject) => this.handleCellClick(aObject) : null}
                               columnsArray={columnsArray}
                               headerView={
                                 <div className="fraud-events-title">
                                   <Translation>{(t, {i18n}) => t('FRAUD_EVENTS_TITLE')}</Translation> - {total}
                                 </div>
                               }
                               fetchObjects={(aLimit, aOffset, aSearchString) => fetchFraudEvents(patient? patient.id : null, group ? group.id : null, aLimit, aOffset, aSearchString)}
                               shouldReload={shouldReload}
                               explanationKey={explanationKey}
                               menuItemsArray={fraudActionPermissions.includes(currentRole) ? menuItemsArray : null}
                               objectToReplace={fraudEventToUpdate}
                               totalObjects={(aTotal) => this.setState({totalFraudEvents:aTotal})}
                               responseTotalKeysArray={['data', 'data', 'fraud_events', 'total']}
                               responseObjectKeysArray={['data', 'data', 'fraud_events', 'fraud_events']}
                               finishedReplacingObject={() => this.setState({fraudEventToUpdate:null})}
                  />
                </div>
              </div>
            </div>

            {commentAnchorElement ?
              <Popper id="fraud-event-list-comment-popper"
                      style={{zIndex:9999}}
                      open={Boolean(commentAnchorElement)}
                      anchorEl={commentAnchorElement}
              >
                <ClearNotificationReceiptDropDown title={selectedFraudEvent && patient ?
                  <Translation>{(t, {i18n}) => t('MARK_AS_RESOLVED')}</Translation>
                  :
                  (verifyFraud ?
                      <Translation>{(t, {i18n}) => t('MARK_PATIENT_AS_VERIFIED')}</Translation>
                      :
                      <Translation>{(t, {i18n}) => t('MARK_PATIENT_AS_NOT_VERIFIED')}</Translation>
                  )
                }
                                                  clearReceipt={(aComment) => {selectedFraudEvent && patient ?
                                                    this.verifyFraudEvent(selectedFraudEvent, aComment)
                                                    :
                                                    this.verifyFraudStatistics(fraudStatistics, selectedFraudEvent, aComment)
                                                  }}
                                                  closeDropdown={() => this.setState({menuAnchorElement:null, selectedFraudEvent:null, commentAnchorElement:null})}
                                                  addPositioning={Boolean(selectedFraudEvent)}
                />
              </Popper>
              :
              null
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('FRAUD_EVENTS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(FraudEventsList)
