import React from 'react';
import './CloseButton.scss';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const CloseButton = ({className, id, style, onClick}) => (
  <div className={'close-button-main-container ' + className}
       id={id}
       style={style}>
    <CancelOutlinedIcon style={{color:'#2D81C9'}}
                        onClick={onClick}
    />
  </div>
);

export default CloseButton;
