export const DEFAULT_COLORS = {
  MAIN_BLUE: '#2D81C9',
  PANEL_GRAY: '#9b9b9b',
  // PANEL_HEAD_BG: '#16405b'
  PANEL_HEAD_BG: '#E33966',
  PRIMARY_WHITE: '#ffffff',
  LIGHT_GRAY: '#eeeeee',
  PRIMARY_SECTION_TITLE: '#4a4a4a',
  
  PINK: '#E33966',
  ORANGE: '#FAA919',
  TEAL: '#36818E',
}
