import React from 'react';
import './StackedBarChart.scss'
import BaseChart from "../BaseChart/BaseChart";
import ReactToolTip from "react-tooltip";
import ResponsesList from "../../../../Lists/ResponsesList/ResponsesList";
import {Translation} from "react-i18next";

export default class StackedBarChart extends BaseChart {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      date: '',
      dataPoint: {}
    };
  }
  generateChartData(entries, startDate, endDate, onClickListener){
    let objectiveId = this.props.objectiveId;
    let dataPoints = [];
    let xAxisTickLabels = [];
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    let uniqueChartColourValuePairs = [];

    if(entries != null){
      entries.forEach(entry => {
        entry.answers.forEach(answer => {
          if(answer.chart_colour && answer.chart_value){
            let existingChartColourValuePair = uniqueChartColourValuePairs.find(chartColourValuePair => {
              return chartColourValuePair.chartColour === answer.chart_colour && chartColourValuePair.chartValue === answer.chart_value;
            });

            if(!existingChartColourValuePair){
              uniqueChartColourValuePairs.push({chartColour: answer.chart_colour, chartValue: answer.chart_value});
            }
          }
        });
      });
    }
    let uniqueChartColours = uniqueChartColourValuePairs.sort((pair1, pair2) => pair1.chartValue - pair2.chartValue).map(chartColourValuePair => chartColourValuePair.chartColour);

    while(currentDate < endDate){
      let dataPoint = {total: 0, responseIds: []};

      uniqueChartColours.forEach(colour => {
        dataPoint[colour] = 0;
      });

      if(entries != null){
        entries.forEach(entry => {
          let measuredAt = new Date(entry.response.measured_at);

          if(measuredAt.getDate() === currentDate.getDate()
            && measuredAt.getMonth() === currentDate.getMonth()
            && measuredAt.getFullYear() === currentDate.getFullYear()){
            dataPoint.responseIds.push(entry.response.id);
            dataPoint.objectiveId = objectiveId;

            entry.answers.forEach(answer => {
              if(answer.chart_colour){
                dataPoint[answer.chart_colour] += 1;
                dataPoint.total += 1;
              }
            });
          }
        });
      }
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      dataPoints.push(dataPoint);
    }
    let datasets = uniqueChartColours.map(colour => {
      return {
        backgroundColor: colour,
        data: dataPoints.map((dataPoint, index) => {
          let value = 0;

          if(dataPoint.total > 0){
            value = dataPoint[colour] / dataPoint.total;
          }
          return value;
        })
      }
    });
    this.setState({ dataPoints: dataPoints});
    return {
      type: 'bar',
      data: {
        labels: xAxisTickLabels,
        datasets: datasets
      },
      options: {
        onClick: (event) => {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseIds[0],
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            const chart = this.chartRef.current;

            if (!chart) {
              return;
            }

            if (tooltipModel.opacity === 0) {
              this.setState({
                showTooltip: false
              });
              return;
            }

            if (!this.state.showTooltip) {
              const dataPoint = dataPoints[tooltipModel.dataPoints[0].index];
              this.setState({
                showTooltip: true,
                dataPoint: dataPoint,
                date: xAxisTickLabels[tooltipModel.dataPoints[0].index]
              });
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              padding: 15,
              autoSkip: false
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: true,
            stacked: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              min: 0,
              max: 1,
              stepSize: 0.5,
              padding: 10,
              callback: function(value, index, values) {
                return (value * 100).toFixed(0) + '%';
              }
            }
          }]
        }
      }
    }
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.chart.update();
      }
    });
    const {objectiveId} = this.props;
    const {showTooltip, dataPoint, date} = this.state;
    const footer = 'click to view details';
    const body = Object.keys(dataPoint).reduce((elements, key, index) => {
      const total = dataPoint[key];

      if (total === 0) {
        return elements;
      }
      let imageSrc;

      switch(key){
        case "#f34d33":
          imageSrc = ResponsesList.DailyMoodsSad;
          break;
        case "#eeb500":
          imageSrc = ResponsesList.DailyMoodsNeutral;
          break;
        case "#24895b":
          imageSrc = ResponsesList.DailyMoodsHappy;
          break;
        default:
          return elements;
      }
      let element = (<div key={index}>
        <img alt="" src={imageSrc} style={{padding: '5px 5px 5px 0'}}/>
        {total}
      </div>);
      elements.unshift(element);
      return elements;
    }, []);
    return (
      <div>
        <canvas ref={this.chartRef}
                data-tip data-for={`${objectiveId}`}
        />
        {
          showTooltip &&
          <ReactToolTip id={`${objectiveId}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>
                {date}
              </div>
              <div className='tooltip-body'>
                {body}
              </div>
              <div className='tooltip-footer no-padding-top-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
