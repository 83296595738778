import React from "react";
import './SurveyBuilderQuestionRegex.scss';
import {Translation} from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";

export default class SurveyBuilderQuestionRegex extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const { question, updateQuestion, errorForIdTypeAndKey, validateRubularRegex } = this.props;
    
    return(
      <div className="survey-builder-question-regex">
        <LabeledDelayedInput className="survey-builder-question-regex-input"
                             label={<Translation>{(t, { i18n }) => t('REGEX')}</Translation>}
                             value={question.regex && question.regex.length > 0 ? question.regex : ''}
                             infoBlurb={<Translation>{(t, { i18n }) => t('REGEX_EXPLANATION')}</Translation>}
                             minLength={0}
                             handleSave={(aEvent) => {
                               let updatedQuestion = cloneDeep(question);
                               updatedQuestion.regex = aEvent.target.value;
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'regex').message}
                             popoverPlacement={"left"}
        />
      </div>
    )
  }
}
