import React, {useEffect, useState, useRef} from 'react';
import "antd/dist/antd.css";
import '../../Pages/ManageReports/ManageReports.scss';
import {Translation} from "react-i18next";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import ReorderList from "../../Lists/ReorderList/ReorderList";
import {findIndex, uniq} from "lodash";
import i18n from "../../i18n";
import {currentDateHumanizedWithoutTimeStamp, currentDateHumanizedWithTimeStamp} from "../../Helpers/DateHelpers";
import './DownloadQuestionnaireReports.scss'
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import InfiniteScroll from "react-infinite-scroll-component";
import cloneDeep from "lodash/cloneDeep";

const DownloadQuestionnaireReports = props => {
  
  // Instance Variables
  
  const {company, locales, fetchSurveyVersionCollections} = props;
  const notifyReportGenerationSuccess = (report) => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:report}) }</Translation>, NOTIFY_OPTS.autoClose);
  const notifyReportFailed = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_FAILED_TOAST') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
  const [surveyIds, setSurveyIds] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [reorderSurvey, setReorderSurvey] = useState(false);
  const [reorderingStatus, setReorderingStatus] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [surveyReportType, setSurveyReportType] = useState({value:null, label:null});
  const [downloadDataReport, setDownloadDataReport] = useState({download:false, type:null});
  const [availableLanguages, setAvailableLanguages] = useState({});
  const [languageListVisible, setLanguageListVisible] = useState(false);
  const [downloadListVisible, setDownloadListVisible] = useState(false);
  const [checkboxCheckedState, setCheckboxCheckedState] = useState({});
  const [includeTestPatients, setIncludeTestPatients] = useState(false);
  const [surveyVersionCollectionsArray, setSurveyVersionCollectionsArray] = useState([]);
  const [totalSurveyVersionCollections, setTotalSurveyVersionCollections] = useState(0);
  
  let surveyReportTypes = [
    {value:0, label:<Translation>{ (t, { i18n }) => t('BLANK_QUESTIONNAIRE_FORMATTED_PDF') }</Translation>},
    {value:1, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_ANNOTATED_REPORT_AS_PDF_ACTION') }</Translation>},
    {value:20, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_ANNOTATED_REPORT_AS_PDF_ACTION_VERTICAL') }</Translation>},
    {value:2, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_ANNOTATED_REPORT_AS_CSV_ACTION') }</Translation>},
    {value:3, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_STREAM_ACTION') }</Translation>},
    {value:4, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_STREAM_CSV_ACTION') }</Translation>},
    {value:5, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_HUMAN_ACTION') }</Translation>},
    {value:6, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_HUMAN_CSV_ACTION') }</Translation>},
    {value:17, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_STREAM_ACTION_VERTICAL') }</Translation>},
    {value:18, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_STREAM_CSV_ACTION_VERTICAL') }</Translation>},
    {value:12, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_SAS_FILE') }</Translation>},
    {value:19, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_SAS_FILE_VERTICAL') }</Translation>},
    {value:13, label:<Translation>{ (t, { i18n }) => t('DOWNLOAD_DATA_REPORT_AS_SPSS_FILE') }</Translation>},
    {value:14, label:<Translation>{ (t, { i18n }) => t('CUSTOM_METRICS_REPORT') }</Translation>},
    {value:15, label:<Translation>{ (t, { i18n }) => t('MEDICATION_REPORT_CSV') }</Translation>},
    {value:16, label:<Translation>{ (t, { i18n }) => t('MEDICATION_REPORT_SAS') }</Translation>}
  ];
  
  const scrollManagerRef = useRef(null);
  const {current:scrollManager} = scrollManagerRef;
  
  // Methods
  
  const updateList = () => {
    let manager = scrollManager;
    
    if(manager === null || manager === undefined){
      manager = scrollManagerRef.current;
    }
    setSurveyVersionCollectionsArray(cloneDeep(manager.getList()));
  };
  
  function getSurveyVersionCollections(limit, offset){
    return fetchSurveyVersionCollections(limit, offset, null, false, false, null).then(newResponse => {
      let data = newResponse.data.data.survey_version_collections;
      let surveyVersionCollectionsArray = data.survey_version_collections;
      let total = data.total;
      setTotalSurveyVersionCollections(total);
      return {objects:surveyVersionCollectionsArray, total:total};
    }, (newError) => {
    
    });
  }

  useEffect(()=> {
    if(downloadDataReport.download && surveyIds.length !== 0){
      // TODO: Add a date range to the params.
      
      const reportParams = {
        company_id: company.id,
        survey_ids: [...surveyIds].map((surveyId) => surveyId.id),
        type: downloadDataReport.type,
        generate_in_background:'true',
        include_test_patients: includeTestPatients,
        verticalize_questions: downloadDataReport.verticalize ? "true" : "false"
      };
      setShowLoading(true);
      let surveyNames = '';
      
      for(let surveyId of surveyIds){
        for(let survey of surveyVersionCollectionsArray){
          if(survey.id === surveyId.id){
            surveyNames = surveyNames + '_' + survey.name;
            break;
          }
        }
      }
      global.api.post('/api/surveys/download_survey_version_collection_reports_for_all_users', reportParams, { responseType: 'blob' } )
      .then((result) => {
        let fileName = `data_report_${company.name}_${currentDateHumanizedWithoutTimeStamp()}${surveyNames}`;
        
        if(fileName.length > 200){
          fileName = fileName.substr(0, 200);
        }
        fileName = `${fileName}.zip`;
        notifyReportGenerationSuccess(fileName);
        
        setShowLoading(false);
        setDownloadDataReport({ download: false, type: null });
      })
      .catch((error) => {
        notifyReportFailed();
        setShowLoading(false);
        setDownloadDataReport({ download: false, type: null });
      })
    }
  }, [downloadDataReport, surveyIds, company, includeTestPatients, surveyVersionCollectionsArray]);
  
  useEffect(()=> {
    if(!reorderingStatus) return;
    downloadSurveyReport();
    // ! By adding downloadSurveyReport to the dep array, there would be
    // ! too many re renders. That function needs to be re written.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderingStatus]);
  
  useEffect(()=> {
    if(locales){
      let langOptions = {};
      
      locales.forEach(function(locale){
        langOptions[locale.locale] = locale.title
      });
      setAvailableLanguages(langOptions)
    }
  }, [locales]);

  useEffect(() => {
    if(checkboxCheckedState["0"]) {
      checkUncheckAll(checkboxCheckedState["0"]);
    }
  }, [surveyVersionCollectionsArray]);
  
  function downloadSurveyAs(format){
    const surveyReportRequestParams = {
      language: selectedLanguage,
      company_id: company.id,
      generate_in_background: 'true',
      survey_ids: [...surveyIds].map((surveyId) => surveyId.id),
      include_test_patients: includeTestPatients
    };

    global.api.post(`/api/survey_version_collections/export_by_group/${format}`, surveyReportRequestParams)
    .then((result) => {
      notifyReportGenerationSuccess(i18n.t('REPORTS'));
    })
    .catch((error) => notifyReportFailed());
  }
  
  function downloadAnnotatedSurveyAs(format, aVerticalizedData = false){
    const surveyReportRequestParams = {
      download_format: format,
      language: selectedLanguage,
      company_id: company.id,
      generate_in_background: 'true',
      survey_ids: [...surveyIds].map((surveyId) => surveyId.id),
      include_test_patients: includeTestPatients,
      for_verticalized_data: aVerticalizedData ? 'true' : 'false'
    };

    global.api.post(`/api/survey_version_collections/export_annotated_surveys`, surveyReportRequestParams)
    .then((result) => {
      notifyReportGenerationSuccess(i18n.t('REPORTS'));
    })
    .catch((error) => notifyReportFailed());
  }
  
  function downloadCustomMetricsReport(){
    const surveyReportRequestParams = {
      language: selectedLanguage,
      survey_version_collection_ids: [...surveyIds].map((surveyId) => surveyId.id),
      company_id: company.id,
      generate_in_background: 'true',
      include_test_patients: includeTestPatients
    };
    
    global.api.post(`/api/tool_stats/custom_metrics_report`, surveyReportRequestParams)
    .then((result) => {
      notifyReportGenerationSuccess(i18n.t('CUSTOM_METRICS_REPORT'));
    })
    .catch((error) => notifyReportFailed());
  }
  
  function downloadMedicationReport(format){
    const surveyReportRequestParams = {
      download_format: format,
      language: selectedLanguage,
      survey_version_collection_ids: [...surveyIds].map((surveyId) => surveyId.id),
      company_id: company.id,
      generate_in_background: 'true',
      include_test_patients: includeTestPatients
    };
    
    global.api.post(`/api/tool_stats/medication_report`, surveyReportRequestParams)
    .then((result) => {
      let key = 'DOWNLOAD_MEDICATION_REPORT_AS_' + format.toUpperCase();
      notifyReportGenerationSuccess(i18n.t(key));
    })
    .catch((error) => notifyReportFailed());
  }
  
  const modifyCheck = (flag, survey_id) => {
    let obj = { ...checkboxCheckedState };
    obj[survey_id] = flag;
    
    if(Object.values(obj).length === surveyVersionCollectionsArray.length) {
      if(uniq(Object.values(obj)).length === 1){
        obj["0"] = uniq(Object.values(obj))[0]
      }
      else{
        obj["0"] = true
      }
    }
    setCheckboxCheckedState({ ...obj });
  };
  
  const changeTestPatientSetting = () => {
    setIncludeTestPatients(!includeTestPatients)
  };
  
  const checkUncheckAll = (flag) => {
    let obj = { "0": flag };
    let surveyObjCollection = [];
    surveyVersionCollectionsArray.forEach((survey) => {
      obj[survey.id] = flag;
      
      if(flag){
        let surveyObj = {id:survey.id, text:survey.name};
        surveyObjCollection.push({ ...surveyObj })
      }
    });
    setCheckboxCheckedState({ ...obj });
    return surveyObjCollection;
  };
  
  const collectSurveyIds = (aEvent, aName, aId) => {
    setLanguageListVisible(false);
    setDownloadListVisible(false);
    let ids = [...surveyIds];
    let obj = { id:aId, text:aName };
    
    if(parseInt(obj.id) === 0){
      ids = checkUncheckAll(aEvent.target.checked)
    }
    else{
      if(aEvent.target.checked){
        ids.push(obj);
      }
      else{
        const index = findIndex(ids, obj);
        
        if(index !== -1){
          ids.splice(index, 1);
        }
      }
      modifyCheck(aEvent.target.checked, obj.id)
    }
    setSurveyIds(ids)
  };
  
  const reorderSurveyReport = (aEvent) => {
    setReorderSurvey(true);
    const [reportType] = surveyReportTypes.filter((report) =>
      report.value === aEvent.target.value
    );
    setDownloadListVisible(false);
    setSurveyReportType(reportType);
  };
  
  // TODO: This function will cause several re renders and should be re written.
  const downloadSurveyReport = () => {
    switch (surveyReportType.value){
      case 0:
        downloadSurveyAs('pdf');
        break;
      case 1:
        downloadAnnotatedSurveyAs('pdf');
        break;
      case 2:
        downloadAnnotatedSurveyAs('csv');
        break;
      case 3:
        setDownloadDataReport({ download: true, type: 'ascii' });
        break;
      case 4:
        setDownloadDataReport({ download: true, type: 'ascii-csv' });
        break;
      case 5:
        setDownloadDataReport({ download: true, type: 'non-ascii' });
        break;
      case 6:
        setDownloadDataReport({ download: true, type: 'non-ascii-csv' });
        break;
      case 12:
        downloadAnnotatedSurveyAs('sas');
        break;
      case 13:
        downloadAnnotatedSurveyAs('spss');
        break;
      case 14:
        downloadCustomMetricsReport();
        break;
      case 15:
        downloadMedicationReport('csv');
        break;
      case 16:
        downloadMedicationReport('sas');
        break;
      case 17:
        setDownloadDataReport({ download: true, type: 'ascii', verticalize: true });
        break;
      case 18:
        setDownloadDataReport({ download: true, type: 'ascii-csv', verticalize: true });
        break;
      case 19:
        downloadAnnotatedSurveyAs('sas', true);
        break;
      case 20:
        downloadAnnotatedSurveyAs('pdf', true);
        break;

      default:
        break;
    }
    setDownloadListVisible(false);
    setReorderingStatus(false);
    setReorderSurvey(false)
  };
  
  // Render
  
  return(
    <>
      <InfiniteScrollManager ref={scrollManagerRef}
                             fail={(error) => console.error('DownloadQuestionnaireReports (InfiniteScrollManager): Failed with error:', error)}
                             limit={20}
                             success={() => updateList()}
                             getItems={(offset, limit) => getSurveyVersionCollections(limit, offset)}
                             fetchImmediately={true}
      />
      
      <div className="manage-questionnaire-reports-download-container">
        {reorderSurvey ?
          <ReorderList surveyIds={surveyIds}
                       setSurveyIds={setSurveyIds}
                       surveyReportType={surveyReportType}
                       setReorderingStatus={setReorderingStatus}
                       setReorderSurvey={setReorderSurvey}
          />
          :
          null
        }
        <div style={{marginLeft : 20}} className="manage-patient-container-text">
          <Translation>{ (t, { i18n }) => t('DOWNLOAD_COMPLETED_SURVEY_DATA') }</Translation>
        </div>
        <br/>
        
        <div className="manage-reports-cells-container">
          <div className="manage-reports-survey-cell-download">
            <div className="download-questionnaire-reports-container">
              <div className="group-user-title-text margin-left-0">
                <div className="download-questionnaire-reports-title">
                  <Translation>{(t, {i18n}) => t('SELECT_QUESTIONNAIRES')}</Translation>  - {totalSurveyVersionCollections}
                </div>
                
                <label className={'custom-input-cell'}
                       key={`select-all-cell`}>
                  <input type={"checkbox"}
                         key={`select-all-input`}
                         name={`select-all^&@0`}
                         checked={checkboxCheckedState["0"]}
                         onChange={(aEvent) => collectSurveyIds(aEvent, 'select-all', 0)}
                  />
                  
                  <span className={'custom-checkmark'}
                        key={`select-all-checkmark`}/>
                  
                  <div className={'inline-text'}
                       key={`select-all-name`}>
                    {checkboxCheckedState["0"] ?
                      <Translation>{(t, {i18n}) => t('UNSELECT_ALL')}</Translation>
                      :
                      <Translation>{(t, {i18n}) => t('SELECT_ALL')}</Translation>}
                  </div>
                </label>
              </div>
              
              {scrollManager ?
                <div className="download-questionnaire-reports-outer-scroll-container"
                     id="download-questionnaire-reports-survey-version-collection-list">
                  <InfiniteScroll style={{overflowY:"hidden"}}
                                  next={() => scrollManager.fetch(false)}
                                  loader={<Loading loading={scrollManager.isLoading()}/>}
                                  hasMore={!scrollManager.hasLoadedAll()}
                                  dataLength={scrollManager.totalCount()}
                                  scrollableTarget="download-questionnaire-reports-survey-version-collection-list">
                    {surveyVersionCollectionsArray && surveyVersionCollectionsArray.length > 0 ?
                      <div className="download-questionnaire-reports-cell-container"
                           style={{height: '100%'}}>
                        {surveyVersionCollectionsArray.map((aSurveyVersionCollection, aIndex) => (
                          <div className="manage-reports-download-cell"
                               key={`${aSurveyVersionCollection.id}-container`}>
                            <label className={'custom-input-cell'}
                                   key={`${aSurveyVersionCollection.id}-cell`}>
                              <input type={"checkbox"}
                                     key={`${aSurveyVersionCollection.id}-input`}
                                     name={`${aSurveyVersionCollection.name}^&@${aSurveyVersionCollection.id}`}
                                     checked={checkboxCheckedState[`${aSurveyVersionCollection.id}`]}
                                     onChange={(aEvent) => collectSurveyIds(aEvent, aSurveyVersionCollection.name, aSurveyVersionCollection.id)}
                              />
                  
                              <span className={`custom-checkmark ${checkboxCheckedState[aSurveyVersionCollection.id] ? 'is-checked' : ''}`}
                                    key={`${aSurveyVersionCollection.id}-checkmark`}
                              />
                  
                              <div className={'inline-text'}
                                   key={`${aSurveyVersionCollection.id}-name`}>
                                {aSurveyVersionCollection.name}
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                      :
                      null
                    }
                  </InfiniteScroll>
                </div>
                :
                null
              }
            </div>
            
            <div className="manage-reports-buttons-container">
              <label className={'custom-input-cell'}>
                <input className="patient-user-input"
                       type='checkbox'
                       value={includeTestPatients}
                       checked={includeTestPatients}
                       onChange={changeTestPatientSetting}
                />
                
                <span className={'custom-checkmark'}/>
                
                <div className={'inline-text'}>
                  <Translation>{(t, {i18n}) => t('INCLUDE_TEST_PATIENT')}</Translation>
                </div>
              </label>
              
              {(surveyIds.length > 0) ?
                (showLoading ?
                    <Loading className="download-questionnaire-reports-loading-spinner"/>
                    :
                    <>
                        <BlueButton className='download-questionnaire-report-button'
                                    name={availableLanguages[selectedLanguage]}
                                    onClick={() => {
                                      setDownloadListVisible(false);
                                      setLanguageListVisible(!languageListVisible);
                                    }}
                                    showDropDownIcon={true}
                        />
                        
                        {languageListVisible ?
                          <ul className="download-questionnaire-reports-languages-dropdown-list">
                            {availableLanguages ?
                              Object.keys(availableLanguages).map((key) => (
                                <li key={key}
                                    value={key}
                                    onClick={() => {
                                      setLanguageListVisible(!languageListVisible);
                                      setSelectedLanguage(key)
                                    }}>
                                  {availableLanguages[key]}
                                </li>
                              ))
                              :
                              null
                            }
                          </ul>
                          :
                          null
                        }

                        <BlueButton className='download-questionnaire-report-button'
                                    name={<Translation>{ (t, { i18n }) => t('DOWNLOAD') }</Translation>}
                                    onClick={() => {
                                      setLanguageListVisible(false);
                                      setDownloadListVisible(!downloadListVisible);
                                    }}
                                    showDropDownIcon={true}
                        />
                        
                        {downloadListVisible ?
                          <ul className="download-questionnaire-report-dropdown-list">
                            {surveyReportTypes ?
                              surveyReportTypes.map((report_type) => (
                                <li key={report_type.value}
                                    value={report_type.value}
                                    onClick={(aEvent) => reorderSurveyReport(aEvent)}>
                                  {report_type.label}
                                </li>
                              ))
                              :
                              null
                            }
                          </ul>
                          :
                          null
                        }
                    </>
                )
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};
export default DownloadQuestionnaireReports
