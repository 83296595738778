import actions from 'actions';
import {connect} from 'react-redux';
import ManagePatients from 'Pages/ManagePatients/ManagePatients';

const mapStateToProps = (state, ownProps)=> {
  return {
    path:ownProps.match.path,
    error:state.current && state.current.user && state.current.user.error,
    company:state.company && state.company.company,
    patients:state.patients && state.patients.patients
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteUserToCompany:(aUserId, aGroupId, aRemoveFromDefaultCompany) => {
      return dispatch(actions.inviteUserToCompany(aUserId, aGroupId, aRemoveFromDefaultCompany)).then((newResult) => {
        dispatch(actions.fetchPatients());
      })
    },
    resendInviteToUser:(companyInviteId) => {
        return dispatch(actions.resendInviteToUser(companyInviteId));
    },
    removeUserFromCompany:(userId) => {
      return dispatch(actions.removeUserFromCompany(userId)).then((newResult) => {
        dispatch(actions.fetchPatients());
      })
    },
    addUser:(newUser) => {
      return dispatch(actions.addUser(newUser)).then((newResult) => {
        dispatch(actions.fetchPatients());
      })
    },
    uploadPatientsCSV:(aFile) => {
      return dispatch(actions.uploadPatientsCSV(aFile)).then((newResult) => {
        dispatch(actions.fetchPatients());
      })
    },
    companyInviteSearch:(aSearchString, companyId) => {
      return dispatch(actions.companyInviteSearch(aSearchString, companyId));
    },
    getSurveys:() => {
      return dispatch(actions.getSurveys());
    },
    fetchLocales:() => {
      return dispatch(actions.fetchLocales())
    },
    fetchSurveyQuestions:(aSurveyId) => {
      return dispatch(actions.fetchSurveyQuestions(aSurveyId));
    },
    groupsWithCode:() => {
      return dispatch(actions.groupsWithCode());
    },
    fetchCodesForGroup:(groupId, limit, offset, active) => {
      return dispatch(actions.fetchCodesForGroup(groupId, limit, offset, active));
    },
    createGroupCode:(groupId) => {
      return dispatch(actions.createGroupCode(groupId));
    },
    removeGroupCode:(groupCodeId) => {
      return dispatch(actions.removeGroupCode(groupCodeId));
    },
    findUserByEmail:(aEmail) => {
      return dispatch(actions.findUserByEmail(aEmail));
    },
    downloadGroupCodeHistory:(aGroupId, aCompanyId) => {
      return dispatch(actions.downloadGroupCodeHistory(aGroupId, aCompanyId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePatients);
