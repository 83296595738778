import React from 'react'
import {Translation} from "react-i18next";
import i18n from "../../i18n";
import '../../Cells/DataQueryCell/DataQueryCell.scss';
import './DataQueriesList.scss';
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import DynamicList from "../DynamicList/DynamicList";
import CommentIcon from "@material-ui/icons/Comment";
import {withRouter} from "react-router-dom";
import DataQueryCommentsModal from "../../Modals/DataQueryCommentsModal/DataQueryCommentsModal";
import {momentFromDate} from "../../Helpers/DateHelpers";
import {stripHTML} from 'Helpers/Helpers';

class DataQueriesList extends React.Component {

  // Instance Variables
  
  
  // Init

  constructor(props){
    super(props);

    this.state = {
      showComments:false,
      noteToUpdate:null,
      commentsArray:[],
      currentComment:'',
      selectedDataQuery:null,
      addCommentAnchorElement:null,
      dataQueriesToShowCommentFor:null
    };
    this.addNewDataQuery = this.addNewDataQuery.bind(this);
    this.handleCellClick = this.handleCellClick.bind(this);
    this.addButtonClicked = this.addButtonClicked.bind(this);
    this.resolveDataQuery = this.resolveDataQuery.bind(this);
    this.answerForDataQuery = this.answerForDataQuery.bind(this);
    this.saveCommentClicked = this.saveCommentClicked.bind(this);
    this.cancelCommentClicked = this.cancelCommentClicked.bind(this);
    this.renderDataQueryDates = this.renderDataQueryDates.bind(this);
    this.addCommentToDataQuery = this.addCommentToDataQuery.bind(this);
    this.renderDataQueryStatus = this.renderDataQueryStatus.bind(this);
    this.renderDataQueryComments = this.renderDataQueryComments.bind(this);
    this.showQuestionForDataQuery = this.showQuestionForDataQuery.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {isDrawerOpen} = this.props;
    
    if(prevProps.patient !== this.props.patient){
      this.setState({shouldReload:true});
    }
    if(prevProps.isDrawerOpen !== isDrawerOpen && !isDrawerOpen) {
      this.setState({shouldReload: true})
    }
  }
  
  // Methods

  resolveDataQuery(aDataQuery){
    this.props.resolveDataQueryNote(aDataQuery.id).then(aResponse => {
      let dataQueryNote = aResponse.data.data.resolveDataQueryNoteMutation.data_query_note;

      if(dataQueryNote && dataQueryNote.id){
        this.setState({noteToUpdate:dataQueryNote}, () => this.cancelCommentClicked());
      }
    });
  }

  saveCommentClicked(){
    const {createDataQueryComment} = this.props;
    const {currentComment, selectedDataQuery} = this.state;
    
    createDataQueryComment(selectedDataQuery.id, currentComment).then((newResult) => {
      this.setState({noteToUpdate:newResult.data.data.createDataQueryCommentMutation.data_query_comment.data_query_note}, () => this.cancelCommentClicked());
    }, (aError) => {
      this.cancelCommentClicked();
    });
  }

  cancelCommentClicked(){
    this.setState({selectedDataQuery:null, currentComment:'', addCommentAnchorElement:null});
  }

  addCommentToDataQuery(aDataQuery, aMenuAnchorElement){
    this.setState({selectedDataQuery:aDataQuery, currentComment:'', addCommentAnchorElement:aMenuAnchorElement});
  }

  showQuestionForDataQuery(aDataQuery){
    this.props.showQuestionForDataQuery(aDataQuery);
    this.setState({selectedDataQuery:null, currentComment:'', addCommentAnchorElement:null});
  }

  addNewDataQuery(){
    this.setState({shouldReload:true});
  }

  addButtonClicked(){
    this.props.addNoteModalOpen(this.addNewDataQuery);
  }
  
  handleCellClick(aDataQuery){
    let patientLink = '#';
    
    if(aDataQuery){
      let patientId = aDataQuery.user_id;
      
      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + patientId;
      }
      else{
        patientLink = '/patients/' + patientId;
      }
      //patientLink += '?section=data_query&id=' + this.props.dataQuery.id;
      patientLink += '?section=data_query';
      this.props.history.push(patientLink);
    }
  };
  
  renderDataQueryDates(aDataQuery){
    const dateFormat = 'MM/DD/YYYY [@]h:mma';
    
    return (
      aDataQuery ?
        <div className="data-query-dates-column">
          <div className="data-query-date-title-answered">
            <Translation>{(t, {i18n}) => t('CREATED')}</Translation>
          </div>
    
          <div className="">
            {momentFromDate(aDataQuery.created_at, dateFormat)}
          </div>
    
          <div className="data-query-date-title-resolved">
            <Translation>{(t, {i18n}) => t('LAST_UPDATED')}</Translation>
          </div>
    
          <div className="">
            {momentFromDate(aDataQuery.updated_at, dateFormat)}
          </div>
        </div>
        :
        null
    );
  }
  
  answerForDataQuery(aDataQuery){
    let returnValue = '';
  
    if(aDataQuery && aDataQuery.recorded_survey_response_detail && aDataQuery.survey_response_detail_id > 0){
      if(aDataQuery.recorded_survey_response_detail.value){
        returnValue = aDataQuery.recorded_survey_response_detail.value;
      }
      else if(Array.isArray(aDataQuery.recorded_survey_response_detail.values)){
        aDataQuery.recorded_survey_response_detail.values.forEach((response) => returnValue += response.key);
      }
    }

    if(returnValue === '--skipped--') {
      returnValue = i18n.t('SKIPPED')
    }
    return returnValue;
  }
  
  renderDataQueryStatus(aDataQuery){
    return (
      aDataQuery.is_resolved ?
        <div className="data-query-resolution-status">
          <CheckCircleIcon style={{color:'#1B7F4F', height:'20px'}}/>
          
          <div>
            <Translation>{(t, {i18n}) => t('RESOLVED')}</Translation>
          </div>
        </div>
        :
        <div className="data-query-resolution-status">
          <ErrorIcon style={{color:aDataQuery.number_of_comments > 0 ? '#FAA919' : '#E02020', height:'20px'}}/>
          
          {aDataQuery.number_of_comments > 0 ?
            <div>
              <Translation>{(t, {i18n}) => t('ANSWERED')}</Translation>
            </div>
            :
            <div>
              <Translation>{(t, {i18n}) => t('OPENED')}</Translation>
            </div>
          }
        </div>
    );
  }
  
  renderDataQueryComments(aDataQuery){
    return (
      <div>
        <div className="data-query-comment-count-cell">
          <CommentIcon style={{height:'20px', width:'20px', color:'#4A4A4A'}}/>
    
          <div className="data-query-comment-count-text">
            &nbsp;{aDataQuery.number_of_comments}
          </div>
        </div>
      
        {aDataQuery.number_of_comments > 1 ?
          <div className="data-query-comments-title"
               onClick={(aEvent) => {
                 aEvent.stopPropagation();
                 aEvent.preventDefault();
                 this.setState({showComments:true, selectedDataQuery:aDataQuery});
               }}>
            <Translation>{(t, {i18n}) => t('SHOW_COMMENTS')}</Translation>
          </div>
          :
          null
        }
      </div>
    );
  }
  
  // Render

  render() {
    const {group, height, patient, company, explanationKey, showAddDataQuery, getDataQueryNotes, getDataQueryNoteComments} = this.props ;
    const {shouldReload, noteToUpdate, addCommentAnchorElement, showComments, selectedDataQuery} = this.state;
    
    const dataQueryPermissions = ['admin_level_8', 'admin_level_9'];
    const viewAndEditPermissions = ['admin_level_1', 'admin_level_10'];
    const currentRole = roleForCompany(company);
  
    let columnsArray = [];
    columnsArray.push({key:'provider_name', columnWeight:1, columnNameKey:'RECORDER', propertiesArray:['provider_name']});
    columnsArray.push({key:'status', columnWeight:1, columnNameKey:'STATUS', templateCell:(aDataQuery) => this.renderDataQueryStatus(aDataQuery)});
    columnsArray.push({key:'description', columnWeight:1, columnNameKey:'DESCRIPTION', propertiesArray:['description']});
    columnsArray.push({key:'survey_name', columnWeight:1, columnNameKey:'NAME', propertiesArray:['survey_name']});
    columnsArray.push({key:'survey_question_label', columnWeight:2, columnNameKey:'SURVEY_QUESTION_LABEL', propertiesArray:['survey_question_label'], valueFormatter:(aValue) => stripHTML(aValue)});
    columnsArray.push({key:'answer', columnWeight:1.5, columnNameKey:'ANSWER', propertiesArray:['recorded_survey_response_detail'], valueFormatter:(aValue, aDataQuery) => this.answerForDataQuery(aDataQuery)});
    columnsArray.push({key:'created_at', columnWeight:2, columnNameKey:'CREATED_AND_UPDATED', templateCell:(aDataQuery) => this.renderDataQueryDates(aDataQuery)});
    columnsArray.push({key:'comments', columnWeight:1.5, columnNameKey:'COMMENTS', templateCell:(aDataQuery) => this.renderDataQueryComments(aDataQuery)});
    columnsArray.push({key:'latest_comment', columnWeight:1, columnNameKey:'LATEST_REPLY', propertiesArray:['latest_comment'], valueFormatter:(aValue) => aValue && aValue.comment_text});
  
    let menuItemsArray = [];
    menuItemsArray.push({title:'VIEW_AND_EDIT', clickAction:(aDataQuery) => {
        this.setState({selectedDataQuery:aDataQuery});
        this.showQuestionForDataQuery(aDataQuery);
    }, isValid:(aDataQuery) => aDataQuery && !aDataQuery.is_resolved && aDataQuery.survey_response_detail_id > 0 && !viewAndEditPermissions.includes(currentRole)});
    menuItemsArray.push({title:'RESOLVE', clickAction:(aDataQuery) => {
        this.setState({selectedDataQuery:aDataQuery});
        this.resolveDataQuery(aDataQuery);
    }, isValid:(aDataQuery) => aDataQuery && !aDataQuery.is_resolved && aDataQuery.number_of_comments > 0});
    menuItemsArray.push({title:'ANSWER', clickAction:(aDataQuery, aRow, aEvent, aMenuAnchorElement) => {
        this.setState({selectedDataQuery:aDataQuery});
        this.addCommentToDataQuery(aDataQuery, aMenuAnchorElement);
    }, isValid:(aDataQuery) => aDataQuery && !aDataQuery.is_resolved});
    
    return (
      <div>
        {!dataQueryPermissions.includes(currentRole) ?
          <div className='data-queries-list-container'
               style={{height:height ? height : "800px!important", overflowY: "auto"}}
               id='admin-histories-list'>
            <DynamicList id="data-queries-list"
                         didReload={() => this.setState({shouldReload:false})}
                         menuCancel={() => this.setState({selectedDataQuery:null})}
                         selectCell={(aDataQuery) => this.handleCellClick(aDataQuery)}
                         columnsArray={columnsArray}
                         fetchObjects={(aLimit, aOffset, aSearchString) => getDataQueryNotes(patient && patient.id, group && group.id, aLimit, aOffset, aSearchString)}
                         shouldReload={shouldReload}
                         explanationKey={explanationKey}
                         minColumnWidth={100}
                         menuItemsArray={menuItemsArray}
                         objectToReplace={noteToUpdate}
                         totalObjectsKey='DATA_QUERIES'
                         addButtonClicked={showAddDataQuery ? () => this.addButtonClicked() : null}
                         responseTotalKeysArray={['data', 'data', 'data_query_notes', 'total']}
                         responseObjectKeysArray={['data', 'data', 'data_query_notes', 'data_query_notes']}
                         finishedReplacingObject={() => this.setState({noteToUpdate:null})}
                         preventLeadingSpacesForSearch
            />
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('DATA_QUERIES_BLOCKED')}</Translation>
          </div>
        }

        <Popper id="addCommentPopper"
                open={Boolean(addCommentAnchorElement)}
                style={{zIndex:3}}
                anchorEl={addCommentAnchorElement}
                transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}
                  timeout={350}>
              <div style={{backgroundColor: 'white', width: '250px', padding: '20px', paddingRight:'0', borderRadius: '10px', boxShadow: ' 0px 0px 5px 2px rgba(0,0,0,0.25)  '}}>
                <LabeledTextarea className="data-query-popover-textarea"
                                 placeholder={i18n.t('ADD_SPACE')}
                                 value={this.state.currentComment}
                                 handleSave={(aValue) => this.setState({currentComment:aValue.target.value})}
                />
                
                <div className="popper-button-container">
                  <GrayButton name={<Translation>{ (t, { i18n }) => t('CANCEL')}</Translation>}
                              onClick={() => this.cancelCommentClicked()}/>
                  <BlueButton name={<Translation>{ (t, { i18n }) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                              onClick={() => this.saveCommentClicked()}/>
                </div>
              </div>

            </Fade>
          )}
        </Popper>
  
        <DataQueryCommentsModal isOpen={showComments}
                                dataQuery={selectedDataQuery}
                                handleCloseModal={() => this.setState({showComments:false, selectedDataQuery:null})}
                                getDataQueryNoteComments={getDataQueryNoteComments}
        />
      </div>
    )}
}

export default withRouter(DataQueriesList);
