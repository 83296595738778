import React from 'react';
import MarkdownView from 'react-showdown';
import './ConsentObjective.scss'
import {Translation} from "react-i18next";

export default class ConsentObjective extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConsentFields: false
    }
  }

  render() {
    let objective = this.props.objective;
    let selectedTranslationIndex = this.props.selectedTranslationIndex;
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div>{objective.translations[selectedTranslationIndex].description}</div>
        {this.renderInnerComponent()}
      </div>
    )
  }

  renderInnerComponent(){
    let returnValue = null;
    let objective = this.props.objective;
    let selectedTranslationIndex = this.props.selectedTranslationIndex;

    if(this.state.showConsentFields){
      returnValue = this.renderConsentFields();
    }
    else{
      returnValue = (
        <button className="show-consent-fields-button" onClick={() => this.setState({showConsentFields: true})}>
          {objective.translations[selectedTranslationIndex].consent_button_title}
        </button>
      );
    }
    return returnValue;
  }

  renderConsentFields(){
    let nameComponent = null;
    let signatureComponent = null;
    let consentCheckComponent = null;
    let objective = this.props.objective;
    let selectedTranslationIndex = this.props.selectedTranslationIndex;

    if(objective.include_consenting_name){
      nameComponent = (
        <div className="consent-name-container">
          <div className="consent-name-label-container">
            <label>
              <Translation>{ (t, { i18n }) => t('YOUR_NAME') }</Translation>
              { objective.consenting_name_required &&
              <span>*</span>
              }
            </label>
            <label>
              *<Translation>{ (t, { i18n }) => t('REQUIRED_PROPER_CAPITALIZED_WITH_SPACE') }</Translation>
            </label>
          </div>
          <input className="consent-name-input" type="text"/>
        </div>
      )
    }

    if(objective.include_consenting_signature){
      signatureComponent = (
        <div>
          <label className="consent-signature-label">
            <Translation>{ (t, { i18n }) => t('SIGNATURE') }</Translation>
            { objective.consenting_signature_required &&
            <span>*</span>
            }
          </label>
          <div className="consent-signature-placeholder">
          </div>
        </div>
      )
    }

    if(objective.include_consent_check){
      let consentCheckText = "";

      if(objective.translations[selectedTranslationIndex].consent_check_text){
        consentCheckText = objective.translations[selectedTranslationIndex].consent_check_text;
      }

      if(objective.consent_check_required){
        consentCheckText = `${consentCheckText} *`;
      }
      consentCheckComponent = (
        <div className="consent-check-container">
          <input className="consent-check-checkbox" type="checkbox"/>
          <MarkdownView
            markdown={consentCheckText}
          />
        </div>
      )
    }
    return (
      <div className="consent-fields-container">
        {nameComponent}
        {signatureComponent}
        {consentCheckComponent}
      </div>
    );
  }
}
