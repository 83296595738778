import React, {useState, useCallback, useRef, useEffect} from 'react';
import './DynamicListHeader.scss'
import Info from "../Info/Info";
import {Translation} from "react-i18next";
import {FaSortUp, FaSortDown} from 'react-icons/fa'

const settingsGear = '/imgs/app/settings-gear.png';

const DynamicListHeader = ({width, customMenuView, addButtonClicked, titleObjectsArray, onSelectedColumnChanged, menuWidth = 0,
                             showBorder = false, className = '', canSortList = false, clampFirstColumn = false,
                             initialSelectedColumn = 0, borderWidth = 0.5}) => {
  
  // Instance Variables
  
  const [ascending, setAscending] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(initialSelectedColumn);
  
  // Methods
  
  const handleSelectedColumnChanged = useCallback((aColumn) => {
    if(aColumn !== selectedColumn){
      setAscending(false);
      setSelectedColumn(aColumn);
      
      if(onSelectedColumnChanged){
        onSelectedColumnChanged(aColumn, false);
      }
    }
    else{
      if(onSelectedColumnChanged){
        onSelectedColumnChanged(aColumn, !ascending);
      }
      setAscending(!ascending);
    }
  }, [ascending, selectedColumn, onSelectedColumnChanged]);
  
  const flexForWidth = (aColumnWidth) => {
    return '0 0 ' + aColumnWidth.toString() + 'px';
  };
  
  // Render
  
  return (
    <div className={'dynamic-list-header-container ' + className}
         style={{width:width + 'px'}}>
      {titleObjectsArray.map((titleObject, index) => (
        <div className={'dynamic-list-header-title-cell ' + ((index === selectedColumn) ? 'dynamic-list-header-title-cell-selected ' : '')  + (clampFirstColumn ? 'dynamic-list-header-clamped ' : '') + (showBorder ? 'dynamic-list-header-border ' : '')}
             style={{padding:titleObject.customView ? 0 : '10px', paddingLeft:titleObject.customView ? 0 : (index === 0 ? '20px' : '10px'), width:titleObject.width, flex:flexForWidth(titleObject.width), borderWidth:showBorder ? borderWidth : 1.0, borderTopWidth:showBorder ? borderWidth : 0, borderLeftWidth:index === 0 ? borderWidth : 0, borderRightWidth:showBorder ? (clampFirstColumn && index === 0 ? 2 * borderWidth : borderWidth) : 0}}
             key={titleObject.key + '-' + index}
             onClick={(aEvent) => {
               if(!titleObject.customView){
                 handleSelectedColumnChanged(index);
               }
             }}>
          <span className="dynamic-list-header-title">
            {titleObject.customView ?
              <div className="dynamic-list-header-custom-view-container">
                {titleObject.customView}
              </div>
              :
              (titleObject.customTitle ?
                  titleObject.customTitle
                  :
                  <Translation>{ (t, { i18n }) => t(titleObject.key) }</Translation>
              )
            }
  
            {titleObject.description ?
              <Info className="dynamic-list-header-info"
                    message={titleObject.description}
              />
              :
              null
            }
  
            {canSortList ?
              <>
                {ascending && selectedColumn === index ?
                  <FaSortUp className="dynamic-list-header-arrow-up"/>
                  :
                  <FaSortDown className="dynamic-list-header-arrow-down"/>
                }
              </>
              :
              null
            }
          </span>
        </div>
      ))}
      
      {menuWidth > 0 ?
        customMenuView ?
          customMenuView
          :
          addButtonClicked ?
            <div className={showBorder ? 'dynamic-list-header-menu-border' : 'dynamic-list-header-menu-space'}
                 style={{width:menuWidth + 'px', borderWidth:showBorder ? borderWidth : 1.0, borderTopWidth:borderWidth, borderLeftWidth:0, borderRightWidth:borderWidth}}
                 onClick={(aEvent) => {
                   aEvent.preventDefault();
                   aEvent.stopPropagation();
                   addButtonClicked(aEvent);
                 }}>
              <img src={settingsGear}
                   alt={'Settings'}
                   style={{width:'20px', height:'20px'}}
              />
            </div>
            :
            <div className={showBorder ? 'dynamic-list-header-menu-border' : 'dynamic-list-header-menu-space'}
                 style={{width:menuWidth + 'px', borderWidth:showBorder ? borderWidth : 1.0}}
            />
        :
        null
      }
    </div>
  )
};

export default DynamicListHeader
