import React from 'react';
import {Translation} from "react-i18next";
import i18n from "../../i18n";
import LabeledDropDown from "../LabeledInputs/LabeledDropDown/LabeledDropDown";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {BiDotsVerticalRounded} from "react-icons/bi";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const reminderTypes = [
  {value: 0, label: 'daily'},
  {value: 1, label: 'weekly'},
  {value: 2, label:'monthly'},
]

export default class BlockReminders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newReminder: {
        repeat: true,
        repeat_type: '',
        time: '',
        day_number: null
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.addReminder = this.addReminder.bind(this)
    this.removeReminder = this.removeReminder.bind(this)
    this.openReminderMenu = this.openReminderMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleChange(value, name) {
    const key = name
    if (key === 'day_number') {
      value = parseInt(value)
    }
    let updatedNewReminder = Object.assign({}, this.state.newReminder, {[key]: value})
    this.setState({newReminder: updatedNewReminder})
  }

  addReminder() {
    let updatedReminders = [...this.props.reminders || [], this.state.newReminder]
    this.props.updateBlock({reminders: updatedReminders}, this.props.index)
    this.setState({newReminder: {repeat_type: "", time: ""}})
  }

  removeReminder(index) {
    let updatedReminders = this.props.reminders
    let removeReminderId = updatedReminders[index].id
    updatedReminders.splice(index, 1)
    let updateObject = {
      reminders: updatedReminders
    }
    if (removeReminderId) {
      updateObject.removeReminderIds = [...this.props.block.removeReminderIds || [], removeReminderId]
    }
    this.props.updateBlock(updateObject, this.props.index)
  }

  openReminderMenu(aEvent, aIndex){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedIndex:aIndex})
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedIndex:null});
  }

  render() {
    const { reminders } = this.props
    const { newReminder } = this.state
    let times = []
    let reminderTimeInterval = 15; //minutes interval
    let tt = 0; // start time
    //loop to increment the time and push results in array
    for (var i=0;tt<24*60; i++) {
      let hh = Math.floor(tt/60); // getting hours of day in 0-24 format
      let mm = (tt%60); // getting minutes of the hour in 0-55 format
      // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      let time = null
      if (hh > 0 && mm > 0 ) {
        time = (hh.toString() + mm.toString())
      } else if (hh === 0){
        time = (mm.toString())
      } else if (mm === 0){
        time = (hh.toString() + '00')
      }
      times.push({
        value: time,
        label: convertTime(time)
      })
      tt = tt + reminderTimeInterval;
    }

    const daysOfTheWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]

    let convertDayNumber = (reminder) => {
      if (reminder.repeat_type === 'weekly') {
        return (
          <span>
            - {daysOfTheWeek[reminder.day_number-1]}
          </span>
        )
      } else if (reminder.repeat_type === 'monthly') {
        return (
          <span >
            - {reminder.day_number}
          </span>
        )
      } else if (reminder.repeat_type === 'interval') {
        return (
          <span >
            - every {reminder.day_number} days
          </span>
        )
      } else {
        return ''
      }
    }

    function convertTime(time) {
      let hour;
      let reminderTime = time;
      if (reminderTime.length === 3) {
        hour = reminderTime.substring(0,1)
      } else if (reminderTime.length === 4) {
        hour = reminderTime.substring(0,2)
      }
      let ampm = hour < 12 ? 'am' : 'pm';
      hour = hour % 12 || 12;
      let minutes = String(reminderTime).slice(-2);

      if (hour === 12 && minutes === '0') {
        reminderTime = '12:00 am'
      } else {
        reminderTime = `${hour}:${minutes} ${ampm}`
      }
      return reminderTime
    }

    let reminderTypeOptions = [];
    reminderTypeOptions = reminderTypes.map((reminderType) => {
      return {label:  i18n.t(reminderType.label), value: reminderType.value.toString() }
    })

    let dayNumbers = (repeat_type) => {
      let result = null
      if (repeat_type === 'weekly') {
        result = daysOfTheWeek.map((day, index) => {
          return {value: index+1, label: day}
        })
      } else if (repeat_type === 'monthly') {
        result = []
        for (var j=1; j <= 30; j++) {
          result.push({
            value: j, label: 'Day ' + j
          })
        }
      }
      return result
    }

    return (
      <div>
      <div style={{display: 'flex'}}>
        <Translation>{(t, {i18n}) => t('SET_REMINDER')}</Translation>
        <LabeledDropDown className="block-reminders-dropdown"
                         name="repeat_type"
                         value={newReminder.repeat_type && reminderTypeOptions.filter(option => {
                           return newReminder.repeat_type === option.label
                         })}
                         options={reminderTypeOptions}
                         handleSave={(aOption) => this.handleChange(aOption.label, 'repeat_type')}
        />
        <Translation>{(t, {i18n}) => t('AT')}</Translation>
        <LabeledDropDown className="block-reminders-dropdown"
                         name="time"
                         value={newReminder.time && times.filter(time => {
                           return newReminder.time === time.value
                         })}
                         options={times}
                         handleSave={(aOption) => this.handleChange(aOption.value, 'time')}
        />
        { ['weekly', 'monthly'].includes(newReminder.repeat_type) &&
          <div>
            <Translation>{(t, {i18n}) => t('ON')}</Translation>
          </div>
        }
        { ['weekly', 'monthly'].includes(newReminder.repeat_type) &&
              <LabeledDropDown className="block-reminders-dropdown"
                               name="day_number"
                               value={(newReminder.day_number && dayNumbers(newReminder.repeat_type).filter(option => {
                                 return newReminder.day_number === option.value
                               })) || ''}
                               options={dayNumbers(newReminder.repeat_type)}
                               handleSave={(aOption) => this.handleChange(aOption.value, 'day_number')}
              />
        }
        <div style={{marginLeft: 'auto'}} className='clickable add-remove-buttons-outer-container'
          onClick={this.addReminder}>
          <AddCircleOutlineIcon style={{height: '20px', width: '20px', color: '#2D81C9'}}/>
        </div>
      </div>

      <div style={{marginTop: '0.5em'}}>
        {reminders && reminders.map((reminder, index) => {
          let reminderTypeCaseFixed = reminder.repeat_type && (i18n.t('' + reminder.repeat_type.replace(/[A-Z]/g,"_$&").toUpperCase()).charAt(0).toUpperCase() + i18n.t('' + reminder.repeat_type.replace(/[A-Z]/g,"_$&").toUpperCase()).slice(1))
          return (
            <div key={index} style={{display: 'flex'}} className='objective-rule-item'>
              <div>
                &bull;&nbsp;<Translation>{(t, {i18n}) => t('REMINDER')}</Translation>
                #{index+1}: {reminderTypeCaseFixed} {convertDayNumber(reminder)} @ {convertTime(reminder.time)}
              </div>
              <div style={{marginLeft: 'auto'}}>
                <BiDotsVerticalRounded style={{fontSize: '21px'}}
                                       aria-controls="simple-menu"
                                       aria-haspopup="true"
                                       onClick={(aEvent) => this.openReminderMenu(aEvent, index)}
                />
              </div>
            </div>
          )
        })}
      </div>

      <Menu id="simple-menu"
            anchorEl={this.state.menuAnchorElement}
            keepMounted
            open={Boolean(this.state.menuAnchorElement)}
            onClose={() => this.closeMenu()}>
        <MenuItem style={{position: 'relative'}} onClick={() => this.removeReminder(this.state.selectedIndex)}>
          <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
        </MenuItem>
      </Menu>

    </div>
    )
  }
}

