import React from 'react';
import {BiDotsVerticalRounded} from 'react-icons/bi';

export default class AllergyCell extends React.Component {

  // Instance Variables

  // Init

  constructor(props){
    super(props);
    this.state = {
      showRemove:false
    };
  }

  // Methods

  showRemove(){
    this.setState({showRemove:true});
  }

  hideRemove(){
    this.setState({showRemove:false});
  }

  // Render

  render(){
    const { data, menuButtonClicked} = this.props;
    return (
      <div className="list-item"
           style={{width:'100%', marginBottom:'10px'}}
           onMouseEnter={() => this.showRemove()}
           onMouseLeave={() => this.hideRemove()}>
        { data &&
        <div key={data && data.id} style={{display:'flex', justifyContent:'space-between', width:'100%'}}>

          <div className='list-allergy-item-name'>
            <div><p>{data.allergen}</p></div>
            <div className="list-allergy-severity-item-name" style={{textTransform:'capitalize'}} >{data.severity}</div>
          </div>

          <div>
            <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
            />
          </div>

        </div> }
      </div>
    );
  }

}
