import React from 'react';
import './GroupCell.scss';
import 'antd/dist/antd.css';
import {Translation, withTranslation} from "react-i18next";
import PatientCell from '../PatientCell/PatientCell'
import {BiDotsVerticalRounded} from "react-icons/bi";
import groupColorPicker from 'utils/ColorPicker';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import {fetchPatientsScroll} from 'actions/patients';
import {connect} from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {DEFAULT_COLORS} from "../../constants/Colors";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Custom UI/Loading/Loading";
import AddUsersToGroupModal from "../../Modals/AddUsersToGroupModal/AddUsersToGroupModal";
import i18next from "i18next";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyRemoveGroupSuccess = () => {toast(<Translation>{ (t, { i18n }) => t('REMOVED_USER_GROUP') }</Translation>, NOTIFY_OPTS.autoClose)};
const notifyRemovedGroupFailure = (aError) => {toast(<Translation>{ (t, { i18n }) => t('GROUP_REMOVE_USER_ERROR', {error:aError}) }</Translation>, NOTIFY_OPTS.autoClose);} 
const notifyAddUserToGroupError = (numberOfErrors) => {toast(<Translation>{ (t, { i18n }) => t('GROUP_ADD_USER_ERROR', {number: numberOfErrors}) }</Translation>, NOTIFY_OPTS.autoClose)};
const notifyAddUserToGroupSuccess = (numberOfSuccesses) => {toast(<Translation>{ (t, { i18n }) => t('ADDED_USER_GROUP', {number: numberOfSuccesses}) }</Translation>, NOTIFY_OPTS.autoClose)};

class GroupCell extends React.Component {

  // Instance Variables

  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getPatients(offset, limit),
    success: () => this.updateList(null),
    fail: (error) => this.updateList(error),
    limit: 20
  });

  groupCellContainerObserver = null;
  groupCellContainerRef = React.createRef();

  // Init

  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      addUsers:false,
      patients:[],
      totalPatients:0,
      selectedPatient:null,
      menuAnchorElement:null,
      confirmationModalOpen:false,
      isIntersecting:false,
      patientsFetched:false,
      selectedPatientIdsArray:[]
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.selectPatient = this.selectPatient.bind(this);
    this.addUsersToGroup = this.addUsersToGroup.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.openAddUsersModal = this.openAddUsersModal.bind(this);
    this.closeAddUsersModal = this.closeAddUsersModal.bind(this);
    this.removeSelectedPatient = this.removeSelectedPatient.bind(this);
  }

  componentDidMount() {
    this.groupCellContainerObserver = new IntersectionObserver(([aEvent]) => {
      if(aEvent.isIntersecting && !this.state.isIntersecting){
        this.setState({isIntersecting:true});
      }
      else if(!aEvent.isIntersecting && this.state.isIntersecting){
        this.setState({isIntersecting: false});
      }
    },
    {
      threshold: [1]
    });
    this.groupCellContainerObserver.observe(this.groupCellContainerRef.current)
  }

  componentWillUnmount(){
    if(this.groupCellContainerRef && this.groupCellContainerRef.current){
      this.groupCellContainerObserver.unobserve(this.groupCellContainerRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.group.name !== this.props.group.name){
      this.setState({searchTerm:'', advancedVariables:{}, anchorEl:null});
      this.scrollManager.fetch(true);
    }
    if(!prevProps.group.name && !prevProps.company && prevProps.company !== this.props.company){
      this.scrollManager.fetch(true);
    }
    if(!this.state.patientsFetched && this.state.isIntersecting && !this.props.isScrollActive){
      this.scrollManager.fetch(true);
      this.setState({patientsFetched:true});
      this.groupCellContainerObserver.unobserve(this.groupCellContainerRef.current);
    }
  }

  // Methods

  updateList(aError){
    this.setState({patients:this.scrollManager.getList()});

    if(aError){
      console.error("GroupCell (updateList): Failed with error:", aError);
    }
  }

  getPatients(offset, limit){
    this.setState({loading:true});
    return this.props.fetchPatientsScroll('', this.props.group.name, limit, offset).then((newResult) => {
      let usersArray = [];
      let totalPatients = 0;
      
      if(newResult && newResult.data && newResult.data.data && newResult.data.data.user_page){
        usersArray = newResult.data.data.user_page.users;
        totalPatients = newResult.data.data.user_page.total;
      }
      this.setState({loading:false, totalPatients:totalPatients});
      return {objects:usersArray};
    }, (newError) => {
      this.setState({loading:false}, () => this.updateList(newError));
    });
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedPatient:null});
  }

  removeSelectedPatient(){
    this.props.removeUserFromGroup(this.props.group.id, this.state.selectedPatient.id).then((newResult) => {
      notifyRemoveGroupSuccess();
      this.scrollManager.fetch(true);
    }, (newError) => {
      notifyRemovedGroupFailure(errorMessageFromServerError(newError));
    });
    this.setState({confirmationModalOpen:false});
    this.closeMenu();
  }

  menuButtonClicked(aEvent, aPatient){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedPatient:aPatient});
  };

  openAddUsersModal(){
    this.setState({addUsers:true, group:this.props.group});
  }

  closeAddUsersModal(){
    this.setState({addUsers:false, selectedPatientIdsArray:[]});
  }

  selectPatient(aPatient){
    let patientIdsArray = this.state.selectedPatientIdsArray;
    const index = patientIdsArray.indexOf(aPatient.id);

    if(index !== -1){
      patientIdsArray.splice(index, 1);
    }
    else{
      patientIdsArray.push(aPatient.id);
    }
    this.setState({ selectedPatientIdsArray: [...patientIdsArray]});
  };

  addUsersToGroup(){
    const addUserPromises = [];
    
    for(let selectedPatientId of this.state.selectedPatientIdsArray){
      addUserPromises.push(this.props.addUserToGroup({userId:selectedPatientId, groupId:this.props.group.id}));
    }
    Promise.allSettled(addUserPromises).then(results => {
      const rejectedResults = results.filter(({status}) => status === 'rejected');
      const fulfilledResults = results.filter(({status}) => status === 'fulfilled');
      
      if(rejectedResults.length){
        notifyAddUserToGroupError(rejectedResults.length)
      }
      if(fulfilledResults.length){
        notifyAddUserToGroupSuccess(fulfilledResults.length);
      }
    });
    this.closeAddUsersModal();
    setTimeout(() => this.scrollManager.fetch(true), 300);
  }
  
  getGroupIdsToExclude = () => {
    const {group} = this.props;
    const returnValue = [];

    if(group && group.id){
      returnValue.push(group.id);
    }
    return returnValue;
  };

  // Render

  render() {
    const {group, company, menuButtonClicked, loadOnIntersection = false} = this.props;
    const {loading, addUsers, patients, selectedPatient, menuAnchorElement, confirmationModalOpen,
      selectedPatientIdsArray, isIntersecting} = this.state;
  
    const availableLanguages = [
      {languageString:(i18next.t('ENGLISH_WITH_FLAG')), languageCode:'en'},
      {languageString:(i18next.t('ITALIAN_WITH_FLAG')), languageCode:'it'},
      {languageString:(i18next.t('FRENCH_WITH_FLAG')), languageCode:'fr'},
      {languageString:(i18next.t('GERMAN_WITH_FLAG')), languageCode:'de'},
      {languageString:(i18next.t('SPANISH_WITH_FLAG')), languageCode:'es'}
    ];
  
    let language = '';
    
    for(let availableLanguage of availableLanguages){
      if(availableLanguage.languageCode === group.locale){
        language = availableLanguage.languageString;
        break;
      }
    }
    let totalPatients = this.state.totalPatients;
    let groupColour = DEFAULT_COLORS.MAIN_BLUE;

    if(group && group.core === false){
      groupColour = groupColorPicker(group.id);
    }
    return (
      <div className="group-user-cell" ref={this.groupCellContainerRef}>
        <div className="group-user-title-container">
          <div className="group-user-title-info">
            <div className="group-user-title-circle"
                 style={{color:groupColour}}>
              {group && group.id && group.name && group.name.length > 0 ? group.name.charAt(0).toUpperCase() : ''}
            </div>
            
            <div className="group-user-title-group-name">
              {group.name}
            </div>
            
            {language.length > 0 ?
              <div className="group-user-title-group-user-locale">
                {language}
              </div>
              :
              null
            }
          </div>
          
          <BiDotsVerticalRounded fill="#A8A8A8"
                                 style={{cursor:'pointer', fontSize:'21px', height:'20px', width:'20px', flex:'0 0 20px'}}
                                 onClick={(aEvent) => menuButtonClicked(aEvent)}

          />
        </div>
        
        <div className="group-user-count-container">
          <div className="patient-user-count">
              <Translation>{(t, {i18n}) => t('TOTAL_PATIENTS_DASH')}</Translation>
            {totalPatients}
          </div>
          
          <AddCircleOutlineIcon style={{color:'#2D81C9', height:'20px', width:'20px'}}
                                onClick={() => this.openAddUsersModal()}
          />
        </div>
        
        {!loadOnIntersection || (loadOnIntersection && isIntersecting) ? 
          <div className="group-patient-cell-container-outer"
              id={'group-cell-patients-list-' + group.id}>
            <InfiniteScroll next={() => this.scrollManager.fetch(false)}
                            style={{overflowY:'hidden'}}
                            loader={<Loading size={'small'} loading={loading}/>}
                            hasMore={!this.scrollManager.hasLoadedAll()}
                            dataLength={() => this.scrollManager.length()}
                            scrollableTarget={'group-cell-patients-list-' + group.id}>
              <div className="group-patient-cell-container">
                {(patients && patients.map((patient, index) => {
                  return (
                    <PatientCell className="group-patient-user-cell"
                                id={'group-patient-cell-' + group.id + '-' + patient.id + '-' + index}
                                key={'group-patient-cell-' + group.id + '-' + patient.id + '-' + index}
                                company={company}
                                patient={patient}
                                menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, patient)}
                    />
                  )}))}
              </div>
            </InfiniteScroll>
          </div>
        : null
        }

        <Menu id={'group-cell-menu-' + group.id}
              anchorEl={menuAnchorElement}
              keepMounted
              open={Boolean(menuAnchorElement)}
              onClose={() => this.closeMenu()}>
          <MenuItem style={{position:'relative'}}
                    onClick={() => this.setState({confirmationModalOpen:true})}>
            <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
          </MenuItem>
        </Menu>

        <AddUsersToGroupModal isOpen={addUsers}
                              company={company}
                              handleSave={this.addUsersToGroup}
                              selectPatient={this.selectPatient}
                              excludedPatients={patients}
                              handleCloseModal={() => this.closeAddUsersModal()}
                              groupIdsToExclude={this.getGroupIdsToExclude()}
                              selectedPatientIdsArray={selectedPatientIdsArray}
        />
  
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:formatUserName(selectedPatient)})}
                           isOpen={confirmationModalOpen}
                           reject={() => this.setState({confirmationModalOpen:false})}
                           confirm={() => this.removeSelectedPatient()}
        />
      </div>
    )
  }
}

function mapStateToProps(){
  return{};
}
export default connect (mapStateToProps, {fetchPatientsScroll}) (withTranslation()(GroupCell))
