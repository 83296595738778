import React from 'react';
import './VirtualAppointmentsList.scss';
import {Translation} from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Loading from "../../Custom UI/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import VirtualAppointmentDateCell from "../../Cells/VirtualAppointmentDateCell/VirtualAppointmentDateCell";
import moment from "moment";
import PatientPanelSubSectionHeadingWithButton from "../../Components/PatientProfileComponents/PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton";
import AddVirtualAppointmentModal from "../../Modals/AddVirtualAppointmentModal/AddVirtualAppointmentModal";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../Helpers/CompanyHelpers";
import {momentFromDate} from "../../Helpers/DateHelpers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import i18next from "i18next";
import ConfirmationModal from "../../../src/Modals/ConfirmationModal/ConfirmationModal";

const notifyVideoError = (aError) => toast(<Translation>{ (t, { i18n }) => t('RECORDING_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyVideoProcessing = () => toast(<Translation>{ (t, { i18n }) => t('RECORDING_PROCESSING') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyVideoDownloading = () => toast(<Translation>{ (t, { i18n }) => t('RECORDING_DOWNLOADING') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyRemoveAppointmentError = (aError) => toast(<Translation>{ (t, { i18n }) => t('REMOVE_VIRTUAL_APPOINTMENT_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyVideoGenerationStarted = () => toast(<Translation>{ (t, { i18n }) => t('RECORDING_GENERATION_STARTED') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyRemoveAppointmentSuccess = () => toast(<Translation>{ (t, { i18n }) => t('REMOVE_VIRTUAL_APPOINTMENT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class VirtualAppointmentsList extends React.Component {
  
  // Instance Variables
  
  _isMounted = false;
  
  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getVirtualAppointments(offset, limit),
    success: () => this.updateList(null),
    fail: (error) => this.updateList(error)
  });
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      showLoader:false,
      isGeneratingVideo:false,
      confirmationModalOpen:false,
      addVirtualAppointments:false,
      totalVirtualAppointments:0,
      virtualAppointmentsArray:[],
      selectedVirtualAppointment:null,
      virtualAppointmentMenuAnchorElement:null
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.areAppointmentsOfSameDay = this.areAppointmentsOfSameDay.bind(this);
    this.removeVirtualAppointment = this.removeVirtualAppointment.bind(this);
    this.getVideoForVirtualAppointment = this.getVideoForVirtualAppointment.bind(this);
  }
  
  componentDidMount() {
    this._isMounted = true;
    this.scrollManager.fetch(true);
    this.setState({showLoader:true});
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.company && prevProps.company !== this.props.company){
      this.scrollManager.fetch(true);
    }
  }
  
  // Methods
  
  updateList(aError){
    this.setState({showLoader:false});
    
    if(this._isMounted){
      this.setState({virtualAppointmentsArray: this.scrollManager.getList()});
      
      if(aError){
        console.error('VirtualAppointmentsList (updateList): Failed with error:', aError);
      }
    }
  }
  
  getVirtualAppointments(offset, limit){
    let patientId = null;
    let groupId = null;
    
    if(this.props.patient && this.props.patient.id){
      patientId = parseInt(this.props.patient.id);
    }
    if(this.props.group && this.props.group.id){
      groupId = this.props.group.id;
    }
    return this.props.getVirtualAppointments(limit, offset, null, moment().add(7,'d').toString(), moment().subtract(7,'d').toString(), patientId, groupId, null).then(result => {
      let virtualAppointmentsArray = [];
      let totalVirtualAppointments = 0;
      
      if(result && result.data && result.data.data && result.data.data.telehealth_virtual_appointment_page){
        virtualAppointmentsArray = result.data.data.telehealth_virtual_appointment_page.telehealth_virtual_appointments;
        totalVirtualAppointments = result.data.data.telehealth_virtual_appointment_page.total;
        
        if(this._isMounted){
          this.setState({totalVirtualAppointments:totalVirtualAppointments});
        }
      }
      return {objects:virtualAppointmentsArray};
    });
  }
  
  getVideoForVirtualAppointment(aVirtualAppointment){
    const {getVideoForVirtualAppointment} = this.props;
    const {isGeneratingVideo} = this.state;
    this.closeMenu();
    
    if(isGeneratingVideo){
      notifyVideoGenerationStarted();
    }
    else if(getVideoForVirtualAppointment){
      notifyVideoGenerationStarted();
      this.setState({isGeneratingVideo:true});
      getVideoForVirtualAppointment(aVirtualAppointment.id).then((newResult) => {
        if(newResult && newResult.data && newResult.data.data && newResult.data.data.generateVirtualAppointmentVideo && newResult.data.data.generateVirtualAppointmentVideo.telehealth_virtual_appointment_video){
          let videoResult = newResult.data.data.generateVirtualAppointmentVideo.telehealth_virtual_appointment_video;
      
          if(videoResult.is_ready && videoResult.url && videoResult.url.length > 0){
            setTimeout(() => {
              notifyVideoDownloading();
              window.open(videoResult.url, "_blank");
            }, 3000);
          }
          else{
            setTimeout(() => notifyVideoProcessing(), 3000);
          }
          this.setState({isGeneratingVideo:false});
        }
      }, (newError) => {
        setTimeout(() => notifyVideoError(errorMessageFromServerError(newError)), 3000);
        this.setState({isGeneratingVideo:false});
      });
    }
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedPatientCell:null, virtualAppointmentMenuAnchorElement:null});
  }
  
  menuButtonClicked(aEvent, aVirtualAppointment){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({virtualAppointmentMenuAnchorElement:aEvent.currentTarget, selectedVirtualAppointment:aVirtualAppointment});
  };
  
  areAppointmentsOfSameDay(aFirstAppointment, aSecondAppointment){
    let returnValue = false;
    
    if(aFirstAppointment && aSecondAppointment){
      let firstDate = momentFromDate(aFirstAppointment.start_at);
      let secondDate = momentFromDate(aSecondAppointment.start_at);
      
      if(firstDate.isValid() && secondDate.isValid()){
        returnValue = (firstDate.format('L') === secondDate.format('L'));
      }
    }
    return returnValue;
  }
  
  removeVirtualAppointment(){
    const {removeVirtualAppointment} = this.props;
    const {selectedVirtualAppointment} = this.state;
    
    if(removeVirtualAppointment && selectedVirtualAppointment){
      removeVirtualAppointment({virtualAppointmentId:selectedVirtualAppointment.id}).then((newResult) => {
        this.scrollManager.fetch(true);
        this.setState({confirmationModalOpen:false, selectedVirtualAppointment:null});
        notifyRemoveAppointmentSuccess();
      }, (newError) => {
        notifyRemoveAppointmentError(errorMessageFromServerError(newError));
        this.setState({confirmationModalOpen:false, selectedVirtualAppointment:null});
      });
    }
    this.closeMenu();
  }
  
  // Render
  
  render(){
    const {scrollContainerClassName, height, company, fetchPatientsScroll, patients, patient, searchPatients,
      saveVirtualAppointment, redirectToPatientProfile, canAddVirtualAppointment = false} = this.props;
    const {showLoader, isGeneratingVideo, addVirtualAppointments, virtualAppointmentsArray, selectedVirtualAppointment,
      confirmationModalOpen, virtualAppointmentMenuAnchorElement} = this.state;
    
    const virtualAppointmentsProhibitions = ['admin_level_1', 'admin_level_8', 'admin_level_9'];
    const virtualAppointmentRecordingsPermissions = ['admin', 'admin_level_2', 'admin_level_4'];
    const currentRole = roleForCompany(company);
    
    return (
      showLoader ?
        <Loading/>
        :
        <div>
          {!virtualAppointmentsProhibitions.includes(currentRole) ?
            <div id="virtual-appointments-list">
              {canAddVirtualAppointment ?
                <PatientPanelSubSectionHeadingWithButton addFunc={() => this.setState({addVirtualAppointments:true})}/>
                :
                null
              }
              
              <InfiniteScroll style={{overflowY: "hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="virtual-appointments-list">
                {virtualAppointmentsArray && virtualAppointmentsArray.length > 0 ?
                  <div className={"virtual-appointments-list-scroll " + scrollContainerClassName}
                       style={{height:height ? height : 'calc(100vh - 242px'}}>
                    {virtualAppointmentsArray.map((virtualAppointment, index) => (
                      <VirtualAppointmentDateCell key={virtualAppointment.id + index}
                                                  role={currentRole}
                                                  showDate={index === 0 ? true : !this.areAppointmentsOfSameDay(virtualAppointmentsArray[index - 1], virtualAppointment)}
                                                  menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, virtualAppointment)}
                                                  virtualAppointment={virtualAppointment}
                                                  redirectToPatientProfile={redirectToPatientProfile}
                      />
                    ))}
                  </div>
                  :
                  <div className="no-information-text">
                    <Translation>{(t, {i18n}) => t('NO_SCHEDULED')}</Translation>
                  </div>
                }
              </InfiniteScroll>
              
              <Menu id="virtual-appointments-list-menu"
                    keepMounted
                    onClose={() => this.closeMenu()}
                    anchorEl={virtualAppointmentMenuAnchorElement}
                    open={Boolean(virtualAppointmentMenuAnchorElement)}>
                {selectedVirtualAppointment && momentFromDate(selectedVirtualAppointment.end_at).isAfter(momentFromDate(moment())) ?
                  <MenuItem style={{position:'relative'}}
                            onClick={() => {
                              window.open(selectedVirtualAppointment.connect_url, "_blank");
                              this.closeMenu();
                            }}>
                    <Translation>{ (t, { i18n }) => t('START_CALL') }</Translation>
                  </MenuItem>
                  :
                  null
                }
                
                {selectedVirtualAppointment && !momentFromDate(selectedVirtualAppointment.start_at).isBefore(momentFromDate(moment().add(10, 'minutes'))) ?
                  <MenuItem style={{position:'relative'}}
                            onClick={() => this.setState({addVirtualAppointments:true}, () => this.closeMenu())}>
                    <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
                  </MenuItem>
                  :
                  null
                }
  
                {virtualAppointmentRecordingsPermissions.includes(currentRole)
                && hsFeatureAvailableForCompany(company, 'Virtual Appointment Recording')
                && selectedVirtualAppointment
                && !isGeneratingVideo
                && moment(selectedVirtualAppointment.end_at).diff(moment(), 'minutes') < 0 ?
                  <MenuItem style={{position:'relative'}}
                            onClick={() => this.getVideoForVirtualAppointment(selectedVirtualAppointment)}>
                    <Translation>{(t, {i18n}) => t('VIEW_RECORDING')}</Translation>
                  </MenuItem>
                  :
                  null
                }
                
                <MenuItem style={{position: 'relative'}}
                          onClick={() => this.setState({confirmationModalOpen: true})}>
                  <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                </MenuItem>
              </Menu>
              
              {addVirtualAppointments ?
                <AddVirtualAppointmentModal key={patients}
                                            name={<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENT') }</Translation>}
                                            isOpen={addVirtualAppointments}
                                            reload={() => this.scrollManager.fetch(true)}
                                            patients={patients}
                                            primaryPatient={patient}
                                            searchPatients={searchPatients}
                                            handleCloseModal={() => this.setState({addVirtualAppointments:false, selectedVirtualAppointment:null})}
                                            virtualAppointment={selectedVirtualAppointment}
                                            fetchPatientsScroll={fetchPatientsScroll}
                                            saveVirtualAppointment={saveVirtualAppointment}
                                            isEditVirtualAppointment={true}
                />
                :
                null
              }
            </div>
            :
            <div className="no-information-text">
              <Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENTS_BLOCKED')}</Translation>
            </div>
          }
          
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('VIRTUAL_APPOINTMENT')})}
                             isOpen={confirmationModalOpen}
                             reject={() => this.setState({confirmationModalOpen:false})}
                             confirm={() => this.removeVirtualAppointment()}
          />
        </div>
    );
  }
}
