import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Translation} from "react-i18next";
import {dateTimeFormat, isoDateTimeFormat} from "../../../Helpers/DateHelpers";

export default class SurveyDateTimeQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:null,
      uncertain:false
    };
    
    if(props.answer !== null && props.answer.value !== null && props.answer.value !== undefined){
      this.state.answer = moment(props.answer.value);
    }
    if(props.answer && props.answer.other !== null){
      this.state.uncertain = props.answer.other;
    }
    this.saveAnswer = this.saveAnswer.bind(this);
    this.setUncertain = this.setUncertain.bind(this);
  }
  
  // Methods
  
  saveAnswer = (aTime) => {
    let time = moment(aTime);
    this.setState({answer: time });
    
    // TODO: Add translations.
    if(!this.afterMinDate(time)){
      this.props.onError(`Not in Range: Must be after ${this.minValue()}`);
    }
    else if (!this.beforeMaxDate(time)){
      this.props.onError(`Not in Range: Must be before ${this.maxValue()}`);
    }
    else if(this.inRange){
      this.props.onAnswerChange(this.props.question, time.format(isoDateTimeFormat), null, this.state.uncertain);
    }
  };
  
  setUncertain(){
    this.props.onAnswerChange(this.state.answer.format(isoDateTimeFormat), null, !this.state.uncertain);
    this.setState({uncertain:!this.state.uncertain});
  }
  
  maxValue(){
    return this.props.question.max_value;
  }
  
  minValue(){
    return this.props.question.min_value;
  }
  
  minDate = () => moment(this.minValue(), dateTimeFormat).toDate();
  maxDate = () => moment(this.maxValue(), dateTimeFormat).toDate();
  inRange = time => this.afterMinDate(moment(time)) && this.beforeMaxDate(moment(time));
  afterMinDate = time => !this.minValue() || time.isAfter(this.minDate());
  beforeMaxDate = time => !this.maxValue() || time.isSameOrBefore(this.maxDate());
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer, uncertain} = this.state;
    const {key, type} = question;
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr />
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_DATETIME_INSTRUCTION_TEXT')}</Translation>
        </em>
        
        <hr />
        
        <div style={{marginTop: '25px'}}>
          <DatePicker showTimeSelect
                      dateFormat={dateTimeFormat}
                      timeFormat='HH:mm'
                      disabled={disabled}
                      value={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      selected={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      placeholder={dateTimeFormat}
                      onChange={(date) => this.saveAnswer(date)}
                      minDate={this.minDate()}
                      maxDate={this.maxDate()}
                      showMonthDropdown
                      showYearDropdown
          />
          
          {uncertain ?
            <div className={'survey-input-cell'}>
              <label className={'custom-input-cell'}>
                <input type={"checkbox"}
                       name={'checkbox-choice'}
                       onChange={this.setUncertain}
                       defaultChecked={uncertain}
                />
                
                <span className={'custom-checkmark'}/>
                
                <div className={'inline-text'}>
                  <Translation>{ (t, { i18n }) => t('EXACT_DATE_NOT_CERTAIN')}</Translation>
                </div>
              </label>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
