import React, { useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import './LabeledDatePicker.scss';
import {setHours, setMinutes} from "date-fns";
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";

const LabeledDatePicker = ({className, value, dateFormat, label, required, handleSave, maxDate, minDate, disabled,
                             filterDate, filterTime, showTimeSelect = false, popperPlacement, errorMessage, isClearable = false, showMonthDropdown = false,
                             showYearDropdown = false, showPopperArrow = false, maxTime, minTime, infoBlurb, popoverPlacement = "right",
                             warningMessage, timeCaption, bold = true, loading = false, menuButtonClicked, descriptionLabel}) => {
  
  // Instance Variables
  
  const initialDate = value ? moment(value).toDate() : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  
  const handleChange = (aDate) => {
    if(!filterTime || !aDate || filterTime(aDate)){
      setSelectedDate(aDate);
      let date = moment(aDate);
      handleSave(date);
    }
  };
  
  // Render
  
  return (
    <div className={'labeled-datepicker-input-container ' + className}>
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={descriptionLabel}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <div className="labeled-datepicker-input-cell">
        <DatePicker className={(disabled ? 'labeled-datepicker-input-field-inactive ' : '') + (value && selectedDate ? '' : 'labeled-datepicker-empty-colour-grey ') + (errorMessage ? 'labeled-datepicker-input-error' : (warningMessage ? 'labeled-datepicker-input-warning' : ''))}
                    maxDate={maxDate && moment(maxDate).toDate()}
                    minDate={minDate && moment(minDate).toDate()}
                    maxTime={maxTime ? moment(maxTime).toDate() : setHours(setMinutes(new Date(), 59), 23)}
                    minTime={minTime ? moment(minTime).toDate() : setHours(setMinutes(new Date(), 0), 0)}
                    disabled={disabled}
                    onChange={handleChange}
                    selected={value && selectedDate ? moment(selectedDate).toDate() : false}
                    dateFormat={dateFormat ? dateFormat : "yyyy/MM/dd"}
                    filterDate={filterDate}
                    filterTime={filterTime}
                    timeFormat={showTimeSelect ? "HH:mm" : null}
                    isClearable={disabled ? false : isClearable}
                    timeCaption={timeCaption}
                    dropdownMode="select"
                    showTimeSelect={showTimeSelect}
                    placeholderText={i18next.t('SELECT_DATE')}
                    popperPlacement={popperPlacement ? popperPlacement : 'bottom-start'}
                    showPopperArrow={showPopperArrow}
                    showYearDropdown={showYearDropdown}
                    showMonthDropdown={showMonthDropdown}
        />
        
        <Loading className="labeled-datepicker-loading"
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />
      </div>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};
export default withTranslation()(LabeledDatePicker);
