import React from 'react';
import PatientPanelSubSectionHeadingWithButton from '../PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton'
import {Translation} from "react-i18next";
import '../../../Pages/PatientProfile/PatientProfile.scss';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Loading from "../../../Custom UI/Loading/Loading";
import MedicationCell from "../../../Cells/MedicationCell/MedicationCell";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";

const notifyRemoveMedicationError = (aError) => toast(<Translation>{ (t, {i18n}) => t('ERROR_REMOVING_MEDICATION', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class PatientProfileMedications extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      reminders:{},
      loadFailed:false,
      medicationsArray:[],
      menuAnchorElement:null,
      selectedMedication: null,
      medicationToAddToUser:'',
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.removeMedicationFromUser = this.removeMedicationFromUser.bind(this);
  }
  
  componentDidMount(){
    const {patient, loadPatientMinifiedQuests} = this.props;
    loadPatientMinifiedQuests && loadPatientMinifiedQuests(patient?.id).then(() => {
      this.loadMedications();
    })
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.medications){
      if(this.state.medicationsArray !== this.props.patient.medications){
        this.setState({medicationsArray:this.props.patient.medications});
      }
    }
  }
  
  // Methods
  
  loadMedications(){
    this.props.loadPatientMedications(this.props.patient.id).then(newResult => {
      this.setState({ loading:false, loadFailed:false });
    }, (newError) => {
      this.setState({ loading:false, loadFailed:true });
    });
  }
  
  removeMedicationFromUser = (objectiveId) => {
    const userId = this.props.patient.id;
    const { medicationsArray } = this.state;
    const index = medicationsArray.map((aMedication) => {return aMedication.id}).indexOf(objectiveId);
    
    if(index > -1){
      medicationsArray.splice(index, 1);
    }
    this.props.removeMedicationFromUser(objectiveId, userId).then(newResult => {
      this.props.loadPatientMedications(userId);
    }, newError => {
      let error = '';
      
      if(newError && newError.data && newError.data && newError.data.error){
        error = newError.data.error;
      }
      notifyRemoveMedicationError(error);
      this.props.loadPatientMedications(userId);
    })
    this.closeMenu()
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedMedication:null});
  }
  
  menuButtonClicked(aEvent, aMedication){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedMedication:aMedication});
  };
  
  render() {
    const { medicationsArray, loading, loadFailed } = this.state;
    const { assignMedicationsModalOpen, company } = this.props
    
    const questData = (this.props && this.props.patient && this.props.patient.quests) || [];
    let isQuest = questData.filter(data => data.title === 'Medication Tracker' && data.visibility);
    const medicationPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_10', 'admin_level_11'];
    let currentRole = roleForCompany(company);
    
    return (
      <div>
        {medicationPermissions.includes(currentRole) ?
          <div>
            <PatientPanelSubSectionHeadingWithButton addFunc={assignMedicationsModalOpen} readyToAddItem={isQuest.length > 0 ? true : false}/>
            
            <div className='list-outer-container'>
              <div className='list-inner-container'>
                {loading ?
                  <Loading/>
                  :
                  (loadFailed ?
                      <LoadFailed retry={() => this.loadMedications()}/>
                      :
                      (medicationsArray.length > 0 ?
                          <>
                            {medicationsArray.map((medication, index) => (
                              <div key={'medication-' + index + '-' + medication.id}>
                                <MedicationCell medication={medication}
                                                menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, medication)}
                                />
                              </div>
                            ))}
                          </>
                          :
                          <div className="medication-no-medications-found">
                            <Translation>{ (t, { i18n }) => t('NO_MEDICATIONS_FOUND') }</Translation>
                          </div>
                      )
                  )}
                
                {!loading && isQuest && !(isQuest.length > 0) ?
                  <p style={{opacity: 0.5 , margin : '20px 20px 10px'}}>
                    <Translation>{(t, {i18n}) => t('MEDICATION_TRACKER_NOT_ASSIGNED')}</Translation>
                  </p>
                  :
                  null
                }
              </div>
            </div>
            
            <Menu id="simple-menu"
                  open={Boolean(this.state.menuAnchorElement)}
                  anchorEl={this.state.menuAnchorElement}
                  onClose={() => this.closeMenu()}
                  keepMounted>
              <MenuItem style={{position: 'relative'}}
                        onClick={() => this.removeMedicationFromUser(this.state.selectedMedication.id)}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('MEDICATIONS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
