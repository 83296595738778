import React from 'react';
import './HealthCareProviderInformationList.scss'
import {toast} from "react-toastify";
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import LoadFailed from "../../Custom UI/LoadFailed/LoadFailed";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {formatUserName} from "../../Helpers/AdminHelpers";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import HealthCareProviderInformationCell from "../../Cells/HealthCareProviderInformationCell/HealthCareProviderInformationCell";

const notifyHCPInfoRemoveSuccess = (aName) => toast(i18next.t('HCP_REMOVE_SUCCESS', {aName:aName}), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyHCPInfoRemoveFailure = (aName, aError) => toast(i18next.t('HCP_REMOVE_FAILURE', {aName:aName, aError:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);

export default class HealthCareProviderInformationList extends React.Component {
  
  // Instance Variables
  
  scrollToBottomRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      healthCareProviderInformation:props.patient && props.patient.health_care_provider_informations ? props.patient.health_care_provider_informations : []
    };
    this.loadPatientHealthCareProviders = this.loadPatientHealthCareProviders.bind(this);
    this.addHealthCareProviderInformation = this.addHealthCareProviderInformation.bind(this);
  }
  
  componentDidMount(){
    this.loadPatientHealthCareProviders();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {patient} = this.props;
    
    if(patient && patient.health_care_provider_informations){
      if(this.state.healthCareProviderInformation !== patient.health_care_provider_informations){
        this.setState({healthCareProviderInformation:patient.health_care_provider_informations});
      }
    }
  }
  
  // Methods
  
  addHealthCareProviderInformation(){
    let healthCareProviderInformation = this.state.healthCareProviderInformation;
    healthCareProviderInformation.push({});
    this.setState({healthCareProviderInformation:healthCareProviderInformation}, () => this.scrollToBottomRef.scrollIntoView({behavior:'smooth', block:'nearest'}));
  }
  
  loadPatientHealthCareProviders(){
    const {patient, loadPatientHCPContacts} = this.props;
    
    if(patient && loadPatientHCPContacts){
      this.setState({loading:true});
      loadPatientHCPContacts(Number(patient.id)).then((newResult) => {
        this.setState({loading:false, loadFailed:false});
      }, (newError) => {
        this.setState({loading:false, loadFailed:true});
      })
    }
  }
  
  // Render
  
  render() {
    const {patient, addHealthCareProviderInformationToUser, removeHealthCareProviderInformationFromUser} = this.props;
    const {loading, loadFailed, healthCareProviderInformation} = this.state;
    
    return (
      <div className="groups-outer-container">
        <div className="patient-profile-title-alt">
          {i18next.t('HEALTH_CARE_PROVIDER_INFORMATION')}
          
          <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9'}}
                                onClick={this.addHealthCareProviderInformation}
          />
        </div>
        
        <div className="profile-alternative-contact-list">
          {loading ?
            <Loading/>
            :
            (loadFailed ?
              <LoadFailed retry={() => this.loadPatientHealthCareProviders()}/>
              :
              (patient && healthCareProviderInformation.length > 0 ?
                  <>
                    {healthCareProviderInformation.map((healthCareProvider, index) => (
                      <div className='healthcare-provider-information-section-container' key={'health-care-provider-information' + index + (healthCareProvider.id ? healthCareProvider.id : "new")}>
                        <div className='healthcare-provider-information-section-title'>
                          {i18next.t('HEALTH_CARE_PROVIDER') + ' - ' + (index + 1).toString()}
                        </div>
                        
                        <HealthCareProviderInformationCell patient={patient}
                                                           healthCareProviderInformation={healthCareProvider}
                                                           removeHealthCareProviderInformation={() => {
                                                             if(patient && removeHealthCareProviderInformationFromUser){
                                                               this.setState({healthCareProviderInformation:healthCareProviderInformation}, () => {
                                                                 removeHealthCareProviderInformationFromUser(healthCareProvider, patient.id).then((newResult) => {
                                                                   notifyHCPInfoRemoveSuccess(formatUserName(healthCareProvider));
                                                                   healthCareProviderInformation.splice(index, 1);
                                                                 }, (newError) => {
                                                                   notifyHCPInfoRemoveFailure(formatUserName(healthCareProvider), errorMessageFromServerError(newError));
                                                                 });
                                                               });
                                                             }
                                                           }}
                                                           addHealthCareProviderInformationToUser={addHealthCareProviderInformationToUser}
                        />
                      </div>
                    ))}
                    
                    <div style={{width:'1px', height:'1px'}}
                         ref={(aRef) => this.scrollToBottomRef = aRef}
                    />
                  </>
                  :
                  <div className="health-care-provider-list-no-health-care-providers-found">
                    {i18next.t('NO_HEALTH_CARE_PROVIDERS')}
                  </div>
              ))}
        </div>
      </div>
    )
  }
}
