import React from "react";
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import i18next from "i18next";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class SurveyNumberQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:'',
      choice:null,
      hidden:false,
      otherValue:null
    };
    if(props.answer && props.answer.value){
      this.state.answer = props.answer.value;
    }
    if(props.answer && props.answer.values && props.answer.values.length > 0){
      this.state.otherValue = props.answer.values[0].value;
    }
    if(props.answer && props.answer.other_value){
      let values = JSON.parse(props.answer.other_value.replaceAll("=>", ":"));
      
      if(Array.isArray(values) || (typeof values === 'object' && values[0] !== undefined)){
        this.state.choice = values[0].value;
      }
      else if(typeof values === 'object' && values.value !== undefined){
        this.state.choice = values.value;
      }
      else if(typeof values === 'string' || typeof values === 'number'){
        this.state.choice = values.toString();
      }
    }
    this.checkRegex = this.checkRegex.bind(this);
    this.saveChoice = this.saveChoice.bind(this);
    this.passedRegex = this.passedRegex.bind(this);
    this.otherOptionClicked = this.otherOptionClicked.bind(this);
  }
  
  // Getters
  
  answerValues(){
    let returnValue = null;
    
    if(this.state.choice){
      returnValue = {value:this.state.choice};
    }
    return returnValue;
  }
  
  // Methods
  
  checkRegex(){
    return (this.props.question && this.props.question.js_regex);
  }
  
  saveAnswer = (aEvent) => {
    const answer = aEvent.target.value;
    
    if(answer !== null){
      const {onError, question, onWarning, onAnswerChange} = this.props;
      this.setState({answer:answer}, () => {
        onAnswerChange(question, answer, this.answerValues(), null, this.state.choice);
      });
      onError('');
      onWarning(question.key, []);
      let maxValue = question.max_value;
      let minValue = question.min_value;
      let maxWarning= question.max_warning;
      let minWarning = question.min_warning;
      
      if(minValue && Number(answer) < minValue){
        onError(i18next.t('MUST_BE_GREATER_THAN', {minValue:minValue}));
      }
      else if(minWarning && Number(answer) < minWarning){
        onWarning(question.key, i18next.t('CONFIRM_LESS_THAN', {minWarning:minWarning}));
      }
      else if(maxValue && Number(answer) > maxValue){
        onError(i18next.t('MUST_BE_LESS_THAN', {maxValue:maxValue}));
      }
      else if(maxWarning && Number(answer) > maxWarning){
        onWarning(question.key, i18next.t('CONFIRM_GREATER_THAN', {maxWarning:maxWarning}));
      }
    }
  };
  
  passedRegex(aAnswer){
    let returnValue = false;
    
    if(this.checkRegex()){
      returnValue = (aAnswer.match(this.props.question.js_regex) !== null);
    }
    return returnValue;
  }
  
  saveChoice(aOptionValue){
    this.setState({choice:aOptionValue}, () => {
      this.props.onAnswerChange(this.props.question, this.state.answer, this.answerValues(), null, aOptionValue);
    });
  }
  
  otherOptionClicked(){
    let newOtherValue = !this.state.otherValue;
    this.setState({otherValue:newOtherValue, answer:null}, () => {
      this.props.onAnswerChange(this.props.question, null, this.answerValues(), newOtherValue);
    });
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer, choice, otherValue} = this.state;
    const {key, step, type, other, choices} = question;
    
    let choiceOptions = [];
    let adjacentClassName = 'survey-number-question-container';
    
    if(choices && choices.length > 0){
      for(let choice of choices){
        if(choice.length > 0){
          choiceOptions.push({label:choice[1], value:choice[0]});
        }
      }
      adjacentClassName = 'survey-number-question-container-small';
    }
    let regexInputClassName = '';
    
    if(this.checkRegex()){
      if(this.passedRegex(answer)){
        regexInputClassName = 'survey-text-question-pass';
      }
      else{
        regexInputClassName = 'survey-text-question-fail';
      }
    }
    
    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr />
        
        <div>
          <em>
            {i18next.t('SURVEY_NUMBER_INSTRUCTION_TEXT')}
          </em>
          
          {this.checkRegex() ?
            this.passedRegex(answer) ?
              <CheckCircleIcon style={{color:'#1B7F4F', height:'15px', marginLeft:'10px'}}/>
              :
              <ErrorIcon style={{color:'#E02020', height:'15px', marginLeft:'10px'}}/>
            :
            null
          }
        </div>
        
        <hr />
        
        {!other ?
          <div className={adjacentClassName}>
            <input className={regexInputClassName}
                   step={step}
                   type={'number'}
                   disabled={disabled}
                   onChange={this.saveAnswer}
                   defaultValue={answer}
            />
            
            {choiceOptions && choiceOptions.length > 0 ?
              <select style={{top:'auto', height:'39px', color:'#4A4A4A', bottom:'100%', paddingLeft:'5px', width:'calc(50% - 0px)', border:'1px solid #A8A8A8', fontSize:'14px'}}
                      value={choice}
                      disabled={disabled}
                      onChange={(aEvent) => this.saveChoice(aEvent.target.value)}>
                <option key="blank" value={''}></option>
                
                {choiceOptions.map((choice) => {
                  return (
                    <option key={choice.value} value={choice.value}>{choice.label}</option>
                  )
                })}
              </select>
              :
              null
            }
          </div>
          :
          null
        }
        
        {other ?
          <div>
            <label className={'custom-input-cell'}
                   htmlFor={'other-input-' + key}>
              <input id={'other-input-' + key}
                     type={'checkbox'}
                     disabled={disabled}
                     onChange={() => this.otherOptionClicked()}
                     defaultChecked={otherValue}
              />
              
              <span className={'custom-checkmark'}/>
              
              <div className={'inline-text'}>
                {i18next.t('OTHER')}
              </div>
            </label>
          </div>
          :
          null
        }
      </div>
    )
  }
}
