import moment from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import i18n from "../i18n";

export const isoDateFormat = 'YYYY-MM-DD';
export const isoDateInputFormat = 'yyyy-MM-dd';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const monthYearFormat = 'YYYY-MM';
export const lowerCaseMonthYearFormat = 'yyyy-MM';
export const isoDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZZ';
export const isoDateTimePickerFormat = 'yyyy-MM-ddTHH:mm:ss';
export const isoDateTimeNoTimeZoneFormat = 'YYYY-MM-DDTHH:mm:ss';
export const isoDateTimeNoTimeZonePickerFormat = 'yyyy-MM-dd HH:mm:ss';
export const fullDateTimeFormat = 'llll';
export const namedDayDateFormat = 'ddd, MM/DD YYYY';
export const namedMonthDateFormat = 'DD-MMM-YY';
export const namedMonthDayFormat = 'MM/DD/YYYY';
export const americanDateTimeFormat = "MMMM d, yyyy h:mm aa";
export const americanDateFormat = "MMMM d, yyyy";
export const namedMonthDateTimeFormat = 'DD-MMM-YY @ HH:mm';

export function momentFromDate(aDate, aFormat){
  let returnValue = moment(aDate, 'YYYY-MM-DD HH:mm:ss Z').locale(i18n.language);
  
  if(returnValue && returnValue.isValid && returnValue.isValid()){
    if(aFormat){
      returnValue = moment(aDate, 'YYYY-MM-DD HH:mm:ss Z').locale(i18n.language).format(aFormat);
    }
  }
  else {
    returnValue = null;
  }
  return returnValue;
}

export function momentFromDateTime(aTimestamp){
  let timeZone = 'America/New_York';
  let returnValue = null;
  let timeZoneGuess = moment.tz.guess();
  
  if(timeZoneGuess){
    timeZone = timeZoneGuess;
  }
  if(aTimestamp && moment(aTimestamp.ISO_8601).isValid()){
    returnValue = momentFromDate(aTimestamp)?.tz(timeZone).locale(i18n.language).format('L') + ' @ ' + momentFromDate(aTimestamp)?.tz(timeZone).locale(i18n.language).format('LT z');
  }
  return returnValue;
}

export function momentFromUTCDate(aDate){
  let returnValue = null;
  
  if(aDate){
    const date = new Date(aDate);
    
    if(date){
      returnValue  = momentFromDateTime(date);
    }
  }
  return returnValue;
}

export function currentDateHumanizedWithTimeStamp(){
  let now = moment();
  return `${now.locale(i18n.language).format('LL').split(/, | /).join('_') + '_' + now.locale(i18n.language).format('x')}`
}

export function currentDateHumanizedWithoutTimeStamp(){
  let now = moment();
  return `${now.locale(i18n.language).format('LL').split(/, | /).join('_')}`
}

export function timeToMinutes(aTime){
  let returnValue = 0;
  
  if(aTime){
    const segmentsArray = aTime.split(':');
    
    if(segmentsArray.length === 3){
      returnValue += Number(segmentsArray[0]) * 60;
      returnValue += Number(segmentsArray[1]);
      returnValue += Number(segmentsArray[2]) / 60;
    }
  }
  return returnValue;
}

export function minutesToTime(aMinutes){
  let returnValue = '';
  
  if(aMinutes){
    let hours = Math.floor(aMinutes / 60);
    let minutes = aMinutes - (hours * 60);
    
    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    
    if(hoursString.length === 1){
      hoursString = '0' + hoursString;
    }
    if(minutesString.length === 1){
      minutesString = '0' + minutesString;
    }
    returnValue = hoursString + ':' + minutesString + ':00';
  }
  return returnValue;
}
