import {translatedOptionsArray} from "../Helpers/Helpers";

export const ScheduleColours = {
  empty:'#00000000',
  missed:'#E02021',
  included:'#2D81C9',
  upcoming:'#C0C0C0',
  completed:'#447f00',
  incomplete:'#AC77EC',
  surveyEnding:'#F7B700'
}

export const ScheduleTriggerTypes = {
  start:0,
  end:1,
  general:2
}

export const ScheduleSections = {
  settings:0,
  attached:1,
  additionalTriggers:2,
  paymentTriggers:3,
  surveyTriggers:4
}

export const ScheduleOffsetUnits = {
  minute:'MINUTE',
  hour:'HOUR',
  day:'DAY',
  week:'WEEK',
  month:'MONTH',
  year:'YEAR'
}

export const ScheduleTriggerEventTypeValues = {
  is_time_based:'is_time_based',
  is_condition_based:'is_condition_based'
};

export const ScheduleTriggerEventTypes = {
  is_time_based:'TIME_BASED',
  is_condition_based:'CONDITION_BASED'
};

export const ScheduleTriggerFireEventTypes = {
  admin_alert:'SCHEDULE_FIRE_EVENT_TYPE_ADMIN_ALERT',
  disable_survey:'SCHEDULE_FIRE_EVENT_TYPE_DISABLE_SURVEY',
  patient_notification:'SCHEDULE_FIRE_EVENT_TYPE_PATIENT_NOTIFICATION',
  set_survey_end_date:'SCHEDULE_FIRE_EVENT_TYPE_SET_SURVEY_END_DATE',
  set_survey_start_date:'SCHEDULE_FIRE_EVENT_TYPE_SET_SURVEY_START_DATE',
  survey_ending_admin_alert:'SCHEDULE_FIRE_EVENT_TYPE_SURVEY_ENDING_ADMIN_ALERT',
  survey_ending_notification:'SCHEDULE_FIRE_EVENT_TYPE_SURVEY_ENDING_NOTIFICATION',
  survey_starting_admin_alert:'SCHEDULE_FIRE_EVENT_TYPE_SURVEY_STARTING_ADMIN_ALERT',
  survey_starting_notification:'SCHEDULE_FIRE_EVENT_TYPE_SURVEY_STARTING_NOTIFICATION'
};

export const ScheduleTriggerFireEventTypeValues = {
  admin_alert:'admin_alert',
  disable_survey:'disable_survey',
  patient_notification:'patient_notification',
  set_survey_end_date:'set_survey_end_date',
  set_survey_start_date:'set_survey_start_date',
  survey_ending_notification:'survey_ending_notification',
  survey_starting_notification:'survey_starting_notification'
};

export const ScheduleTriggerConditionEventTypes = {
  added_to_group:'SCHEDULE_TRIGGER_CONDITION_ADDED_TO_GROUP',
  completed_survey:'SCHEDULE_TRIGGER_CONDITION_COMPLETED_SURVEY',
  survey_start_date_set:'SCHEDULE_TRIGGER_CONDITION_SURVEY_START_DATE_SET'
};

export const ScheduleTriggerTimeBasedEventTypes = {
  variable_set:'SCHEDULE_TRIGGER_TIME_EVENT_TYPE_VARIABLE_SET',
  survey_end_date_set:'SCHEDULE_TRIGGER_TIME_EVENT_TYPE_SURVEY_END_DATE_SET',
  survey_start_date_set:'SCHEDULE_TRIGGER_TIME_EVENT_TYPE_SURVEY_START_DATE_SET'
};

export const ScheduleTriggerFireExtrasReferenceTypes = {
  additional_app_column:'ADDITIONAL_APP_COLUMN',
  survey_question:'SURVEY_QUESTION'
};

export function scheduleTriggerEventOptionsArray(){
  return translatedOptionsArray(ScheduleTriggerEventTypes);
}

export function scheduleTriggerOffsetUnitOptionsArray(){
  return translatedOptionsArray(ScheduleOffsetUnits);
}

export function scheduleTriggerFireEventTypesOptionsArray(){
  return translatedOptionsArray(ScheduleTriggerFireEventTypes);
}

export function scheduleTriggerConditionEventTypesOptionsArray(){
  return translatedOptionsArray(ScheduleTriggerConditionEventTypes);
}

export function scheduleTriggerTimeBasedEventTypesOptionsArray(){
  return translatedOptionsArray(ScheduleTriggerTimeBasedEventTypes);
}

export function scheduleTriggerFireExtrasReferenceTypesOptionsArray(){
  return translatedOptionsArray(ScheduleTriggerFireExtrasReferenceTypes);
}
