import React from 'react';
import "antd/dist/antd.css";
import './SurveyList.scss';
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {downloadSurveyList} from "../../Helpers/SurveyListHelpers";

const notifySurveyListCreateError = (aName, aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_CREATING_SURVEY_LIST', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySurveyListUpdateError = (aName, aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_UPDATING_SURVEY_LIST', {aName:aName, aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySurveyListCreatedSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('SURVEY_LIST_CREATED', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySurveyListUpdatedSuccess = (aName) => toast(<Translation>{ (t, { i18n }) => t('SURVEY_LIST_UPDATED', {aName:aName}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySurveyListCannotSaveError = (aMissingField) => toast(<Translation>{ (t, { i18n }) => t('SURVEY_LIST_CANNOT_SAVE_ERROR', {aMissingField:aMissingField}) }</Translation>, NOTIFY_OPTS.autoClose);

export default class SurveyList extends React.Component {
  
  // Instance Variables
  
  inputRef = React.createRef();
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      data:null,
      name:'',
      saving:false,
      fileName:'',
      reference:''
    };
    if(props.surveyList){
      this.state.data = true;
      this.state.name = props.surveyList.name;
      this.state.reference = props.surveyList.reference;
    }
    this.createSurveyList = this.createSurveyList.bind(this);
    this.updateSurveyList = this.updateSurveyList.bind(this);
    this.uploadSurveyListCSV = this.uploadSurveyListCSV.bind(this);
  };
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(!prevProps.saveButtonPressed && this.props.saveButtonPressed && !this.state.saving){
      if(this.props.surveyList && this.props.surveyList.id){
        this.updateSurveyList();
      }
      else{
        this.createSurveyList();
      }
    }
  }
  
  // Methods
  
  canSave(){
    const {data, name, saving, reference} = this.state;
    
    let returnValue = true;
    
    if(saving || !data || !(name && name.length > 0) || !(reference && reference.length > 0)){
      returnValue = false;
    }
    return returnValue;
  }
  
  missingField(){
    const {data, name, reference} = this.state;
  
    let returnValue = '';
  
    if(!data){
      returnValue = 'File';
    }
    else if(!(name && name.length > 0)){
      returnValue = 'Name';
    }
    else if(!(reference && reference.length > 0)){
      returnValue = 'Reference';
    }
    return returnValue;
  }
  
  createSurveyList(){
    const { data, name, saving, reference } = this.state;
    const { client, saveFailed, saveFinished } = this.props;
    
    if(!saving && this.canSave()){
      this.setState({saving:true}, () => {
        this.props.addSurveyList(data, name, reference, client).then((newResult) => {
          notifySurveyListCreatedSuccess(name);
          this.setState({saving:false});
  
          if(saveFinished){
            saveFinished(newResult.data.saveSurveyListMutation.survey_list);
          }
        }, newError => {
          let error = '';
      
          if(newError && newError.data && newError.data.error){
            error = newError.data.error;
          }
          notifySurveyListCreateError(name, error);
          this.setState({saving:false});
  
          if(saveFailed){
            saveFailed();
          }
        });
      });
    }
    else{
      if(saveFailed){
        saveFailed();
      }
      notifySurveyListCannotSaveError(this.missingField());
    }
  }
  
  updateSurveyList(){
    const { data, name, saving, reference } = this.state;
    const { client, surveyList, saveFailed, saveFinished } = this.props;
    
    if(!saving && this.canSave() && surveyList){
      this.setState({saving:true}, () => {
        this.props.updateSurveyList(surveyList.id, data, name, reference, client).then((newResult) => {
          notifySurveyListUpdatedSuccess(name);
          this.setState({saving:false});
        
          if(saveFinished){
            saveFinished(newResult.data.saveSurveyListMutation.survey_list);
          }
        }, (newError) => {
          let error = '';
        
          if(newError && newError.data && newError.data.error){
            error = newError.data.error;
          }
          notifySurveyListUpdateError(name, error);
          this.setState({saving:false});
        
          if(saveFailed){
            saveFailed();
          }
        });
      });
    }
    else{
      if(saveFailed){
        saveFailed();
      }
      notifySurveyListCannotSaveError(this.missingField());
    }
  }
  
  uploadSurveyListCSV(aEvent){
    const file = aEvent.target.files[0];
    this.setState({data:file, fileName:file.name});
    // const reader = new FileReader();
    // reader.readAsText(file);
    //
    // reader.onload = (aFileReadEvent) => {
    //   this.setState({data:aFileReadEvent.target.result, fileName:file.name});
    // }
  }
  
  // Render
  
  render() {
    const { name, saving, fileName, reference } = this.state;
    const { company, className, surveyList, hideSaveButton, fetchSurveyListById } = this.props;
    
    const surveyListPermissionsArray = ["admin", "admin_level_4"];
    const currentRole = roleForCompany(company);
    let enabled = true;
    
    if(surveyList){
      enabled = surveyList.is_editable;
    }
    return (
      <div className={'survey-list-modal ' + className ? className : ''}>
        {surveyListPermissionsArray.includes(currentRole) ?
          <>
            <div className="survey-list-input-container">
              <LabeledTextInput className="survey-list-input-cell-input"
                                label={<Translation>{(t, {i18n}) => t('SURVEY_LIST_NAME')}</Translation>}
                                value={name}
                                disabled={!enabled}
                                infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_LIST_NAME_EXPLANATION') }</Translation>}
                                handleSave={(aEvent) => this.setState({name:aEvent.target.value})}
                                placeholder={i18next.t('TAP_TO_SELECT')}
              />
              
              <LabeledTextInput className="survey-list-input-cell-input"
                                label={<Translation>{(t, {i18n}) => t('SURVEY_LIST_REFERENCE')}</Translation>}
                                value={reference}
                                disabled={!enabled}
                                infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_LIST_REFERENCE_EXPLANATION') }</Translation>}
                                handleSave={(aEvent) => this.setState({reference:aEvent.target.value})}
                                placeholder={i18next.t('TAP_TO_SELECT')}
              />
            </div>
  
            <input type="file"
                   ref={this.inputRef}
                   onChange={(aEvent) => this.uploadSurveyListCSV(aEvent)}
                   accept="text/csv"
                   style={{display:'none'}}
            />
  
            {fileName && fileName.length > 0 ?
              <div className="survey-list-diagnosis-upload-name">
                {fileName}
              </div>
              :
              null
            }
            
            <div className="survey-list-modal-button-container">
              <BlueButton className="survey-list-button-upload"
                          name={<Translation>{(t, {i18n}) => t('UPLOAD')}</Translation>}
                          onClick={() => this.inputRef.current.click()}
              />
  
              {surveyList ?
                <BlueButton className="survey-list-button-download"
                            name={<Translation>{(t, {i18n}) => t('DOWNLOAD')}</Translation>}
                            onClick={() => downloadSurveyList(surveyList, fetchSurveyListById)}
                />
                :
                null
              }
            </div>
            
            {!hideSaveButton ?
              <div className="survey-list-button-container">
                {saving ?
                  <Loading style={{width:40, height:0}}/>
                  :
                  (surveyList ?
                      <BlueButton className="survey-list-button"
                                  name={<Translation>{(t, {i18n}) => t('UPDATE')}</Translation>}
                                  onClick={() => this.updateSurveyList()}
                                  disabled={!enabled}
                      />
                      :
                      <BlueButton className="survey-list-button"
                                  name={<Translation>{(t, {i18n}) => t('CREATE_PROPER_CAPITALIZED')}</Translation>}
                                  onClick={() => this.createSurveyList()}
                                  disabled={!enabled}
                      />
                  )
                }
              </div>
              :
              null
            }
          </>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('SURVEY_LISTS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
