import React from 'react';
import 'antd/dist/antd.css'
import './ReportBuilder.scss';
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {arelComparatorOptionsArray} from "../../constants/ReportBuilderTypes";
import RelationshipConfigurationsModal from "../../Modals/RelationshipConfigurationsModal/RelationshipConfigurationsModal";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {railsDateOptionsArray} from "../../constants/DateTypes";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddReportColumnModal from "../../Modals/AddReportColumnModal/AddReportColumnModal";
import {errorMessageFromServerError} from "../../Helpers/Helpers";

const notifyReportColumnRemoved = () => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_COLUMN_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportFilterRemoved = () => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_FILTER_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateSaveError = (aError) => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_SAVE_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportTemplateSaveSuccess = () => toast(<Translation>{ (t, { i18n }) => t('REPORT_TEMPLATE_SAVED') }</Translation>, NOTIFY_OPTS.autoClose);

export default class ReportBuilder extends React.Component {
  
  // Instance Variables
  
  uploadInputRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      isSaving:false,
      reportTemplate:{
        id:null,
        name:'',
        model:{
          columns:[],
          filters:[],
          model_configuration_name:''
        }
      },
      selectedColumn:null,
      selectedFilter:null,
      menuAnchorElement:null,
      columnConfigurations:{},
      filterConfigurations:{},
      showConfirmationModal:false,
      modelConfigurationsArray:[],
      showAddReportColumnModal:false,
      selectedRelationshipConfiguration:null
    };
    if(props.reportTemplate && props.reportTemplate.id){
      this.state.reportTemplate = props.reportTemplate;
      
      if(this.state.reportTemplate.model === undefined || this.state.reportTemplate.model === null){
        this.state.reportTemplate.model = {
          columns:[],
          filters:[],
          model_configuration_name:''
        };
      }
    }
    this.propertyForColumn = this.propertyForColumn.bind(this);
    this.uploadReportTemplate = this.uploadReportTemplate.bind(this);
    this.downloadReportTemplate = this.downloadReportTemplate.bind(this);
    this.fetchConfigurationsArrays = this.fetchConfigurationsArrays.bind(this);
    this.templateCellForRelationship = this.templateCellForRelationship.bind(this);
    this.targetModelNameForColumnOrFilter = this.targetModelNameForColumnOrFilter.bind(this);
  }
  
  componentDidMount(){
    const {reportTemplate, fetchReportTemplate, fetchModelConfigurations} = this.props;
    
    fetchModelConfigurations(50, 0, '').then((newResult) => {
      this.setState({modelConfigurationsArray:newResult.data.data.model_configurations.model_configurations});
    }, (newError) => {
    
    });
    
    if(reportTemplate && reportTemplate.id && fetchReportTemplate){
      this.setState({isSaving:true});
      fetchReportTemplate(reportTemplate.id).then((newResult) => {
        this.setState({isSaving:false, reportTemplate:newResult.data.data.template}, () => {
          this.fetchConfigurationsArrays();
        });
      }, (newError) => {
        this.setState({isSaving:false});
      });
    }
  }
  
  // Methods
  
  propertyForColumn(aColumn, aKey){
    const {columnConfigurations} = this.state;
    let returnValue = '';
    
    if(aColumn){
      let name = this.targetModelNameForColumnOrFilter(aColumn);
      let columnConfigurationsArray = columnConfigurations[name];
      
      if(columnConfigurationsArray && columnConfigurationsArray.length > 0){
        for(let columnConfiguration of columnConfigurationsArray){
          if(aColumn.column_configuration_name === columnConfiguration.name){
            returnValue = columnConfiguration[aKey];
            break;
          }
        }
      }
    }
    return returnValue;
  }
  
  uploadReportTemplate(aEvent){
    if(aEvent && aEvent.target && aEvent.target.files && aEvent.target.files.length > 0){
      const reader = new FileReader();
      reader.readAsText(aEvent.target.files[0]);
      
      reader.onload = (aFileReadEvent) => {
        const {reportTemplate} = this.state;
        let newReportTemplate = JSON.parse(aFileReadEvent.target.result);
        
        for(let key of Object.keys(newReportTemplate)){
          if(!['name', 'model', 'created_at', 'updated_at', 'last_generated_at', 'last_generate_arguments'].includes(key)){
            delete newReportTemplate[key];
          }
        }
        if(newReportTemplate.model){
          for(let key of Object.keys(newReportTemplate.model)){
            if(!['id', 'columns', 'filters', 'model_configuration_name'].includes(key)){
              delete newReportTemplate.model[key];
            }
          }
          if(Array.isArray(newReportTemplate.model.columns)){
            for(let column of newReportTemplate.model.columns){
              for(let key of Object.keys(column)){
                if(key === 'id'){
                  // TODO: Handle.
                }
                else if(!['date_format', 'description', 'custom_header_name', 'relationship_arguments', 'column_configuration_name'].includes(key)){
                  delete column[key];
                }
              }
              if(Array.isArray(column.relationship_arguments)){
                for(let relationship of column.relationship_arguments){
                  for(let key of Object.keys(relationship)){
                    if(!['name', 'arguments', 'model_configuration_name', 'model_configuration_display_name'].includes(key)){
                      delete relationship[key];
                    }
                  }
                }
              }
            }
          }
          if(Array.isArray(newReportTemplate.model.filters)){
            for(let filter of newReportTemplate.model.filters){
              for(let key of Object.keys(filter)){
                if(key === 'id'){
                  // TODO: Handle.
                }
                else if(!['keyword', 'rhs_value', 'arel_comparator', 'relationship_arguments', 'filter_configuration_name'].includes(key)){
                  delete filter[key];
                }
              }
              if(Array.isArray(filter.relationship_arguments)){
                for(let relationship of filter.relationship_arguments){
                  for(let key of Object.keys(relationship)){
                    if(!['name', 'arguments', 'model_configuration_name', 'model_configuration_display_name'].includes(key)){
                      delete relationship[key];
                    }
                  }
                }
              }
            }
          }
        }
        if(reportTemplate && reportTemplate.id){
          newReportTemplate.id = reportTemplate.id;
        }
        this.setState({reportTemplate:newReportTemplate}, () => {
          this.fetchConfigurationsArrays();
        });
      }
    }
  }
  
  downloadReportTemplate(){
    const {reportTemplate} = this.state;
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + JSON.stringify(reportTemplate));
    element.setAttribute('download', reportTemplate.name ? reportTemplate.name + '.txt' : 'reportTemplate.txt');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  
  targetModelNameForColumnOrFilter(aColumnOrFilter){
    const {reportTemplate} = this.state;
    let returnValue = reportTemplate.model.model_configuration_name;
    
    if(aColumnOrFilter && aColumnOrFilter.relationship_arguments && aColumnOrFilter.relationship_arguments.length > 0){
      for(let relationshipArgument of aColumnOrFilter.relationship_arguments){
        if(relationshipArgument.model_configuration_name && relationshipArgument.model_configuration_name.length > 0){
          returnValue = relationshipArgument.model_configuration_name;
        }
      }
    }
    return returnValue;
  }
  
  fetchConfigurationsArrays(){
    const {reportTemplate} = this.state;
    const {fetchColumnConfigurations, fetchFilterConfigurations} = this.props;
    
    let currentColumnConfigurationNames = Object.keys(this.state.columnConfigurations);
    let newColumnConfigurationNamesArray = [];
    
    if(!currentColumnConfigurationNames.includes(reportTemplate.model.model_configuration_name)){
      newColumnConfigurationNamesArray = [reportTemplate.model.model_configuration_name];
    }
    for(let column of reportTemplate.model.columns){
      if(column.relationship_arguments && column.relationship_arguments.length > 0){
        for(let relationshipArgument of column.relationship_arguments){
          if(relationshipArgument.model_configuration_name && relationshipArgument.model_configuration_name.length > 0){
            let modelConfigurationName = relationshipArgument.model_configuration_name;
            
            if(!currentColumnConfigurationNames.includes(modelConfigurationName)){
              newColumnConfigurationNamesArray.push(modelConfigurationName);
            }
          }
        }
      }
    }
    for(let modelConfigurationName of newColumnConfigurationNamesArray){
      fetchColumnConfigurations(50, 0, null, modelConfigurationName).then((newResult) => {
        let {columnConfigurations} = this.state;
        columnConfigurations[modelConfigurationName] = newResult.data.data.column_configurations.column_configurations;
        this.setState({columnConfigurations:columnConfigurations});
      });
    }
    let currentFilterConfigurationNames = Object.keys(this.state.filterConfigurations);
    let newFilterConfigurationNamesArray = [];
  
    if(!currentFilterConfigurationNames.includes(reportTemplate.model.model_configuration_name)){
      newFilterConfigurationNamesArray = [reportTemplate.model.model_configuration_name];
    }
    for(let filter of reportTemplate.model.filters){
      if(filter.relationship_arguments && filter.relationship_arguments.length > 0){
        for(let relationshipArgument of filter.relationship_arguments){
          if(relationshipArgument.model_configuration_name && relationshipArgument.model_configuration_name.length > 0){
            let modelConfigurationName = relationshipArgument.model_configuration_name;
            
            if(!currentFilterConfigurationNames.includes(modelConfigurationName)){
              newFilterConfigurationNamesArray.push(modelConfigurationName);
            }
          }
        }
      }
    }
    for(let modelConfigurationName of newFilterConfigurationNamesArray){
      fetchFilterConfigurations(50, 0, null, modelConfigurationName).then((newResult) => {
        let {filterConfigurations} = this.state;
        filterConfigurations[modelConfigurationName] = newResult.data.data.filter_configurations.filter_configurations;
        this.setState({filterConfigurations:filterConfigurations});
      });
    }
  }
  
  templateCellForRelationship(aObject, aRow, aType){
    const {reportTemplate} = this.state;
    
    let returnValue = null;
    let selectedRelationshipConfiguration = {row:aRow, type:aType, array:reportTemplate.model[aType][aRow].relationship_arguments};
    let relationshipArguments = null;
  
    if(aObject){
      relationshipArguments = aObject.relationship_arguments;
    }
    if(Array.isArray(relationshipArguments) && (relationshipArguments.length > 0)){
      let concatenatedRelationships = '';
    
      for(let index = 0; index < relationshipArguments.length; index += 1){
        let argument = relationshipArguments[index];
        concatenatedRelationships += argument.name;
      
        if(argument.arguments){
          for(let key of Object.keys(argument.arguments)){
            concatenatedRelationships += ' (' + key + ':' + argument.arguments[key] + ')';
          }
        }
        if(index < relationshipArguments.length - 1){
          concatenatedRelationships += ' > ';
        }
      }
      returnValue = (
        <div onClick={(aEvent) => this.setState({selectedRelationshipConfiguration:selectedRelationshipConfiguration})}>
          {concatenatedRelationships}
        </div>
      );
    }
    else{
      returnValue = (
        <div className="report-builder-add-relationship-container"
             onClick={(aEvent) => this.setState({selectedRelationshipConfiguration:selectedRelationshipConfiguration})}>
          <AddCircleOutlineIcon style={{height:'17px', width:'17px', color:'#282828', marginRight:'3px'}}/>
        
          <Translation>{(t, {i18n}) => t('ADD')}</Translation>
        </div>
      );
    }
    return returnValue;
  }
  
  // Render
  
  render(){
    const {isSaving, reportTemplate, selectedColumn, selectedFilter, menuAnchorElement, columnConfigurations,
      filterConfigurations, showConfirmationModal, modelConfigurationsArray, showAddReportColumnModal,
      selectedRelationshipConfiguration} = this.state;
    const {company, saveReportTemplate, reportTemplateDidSave, fetchRelationshipConfigurations, fetchPreMadeRelationships,
      isModal = false} = this.props;
  
    // TODO: Add in defaults when permissions are broken.
    const role = roleForCompany(company);
    const permissionsArray = ['admin', 'admin_level_2', 'admin_level_4'];
    
    let modelOptionsArray = [];
    
    for(let modelConfiguration of modelConfigurationsArray){
      modelOptionsArray.push({label:modelConfiguration.name, value:modelConfiguration.name});
    }
    let columnConfigurationOptions = {};
    
    for(let key of Object.keys(columnConfigurations)){
      let columnConfigurationOptionsArray = [];
      
      for(let columnConfiguration of columnConfigurations[key]){
        columnConfigurationOptionsArray.push({label:columnConfiguration.display_name, value:columnConfiguration.name});
      }
      columnConfigurationOptions[key] = columnConfigurationOptionsArray;
    }
    
    let filterConfigurationOptions = {};
    
    for(let key of Object.keys(filterConfigurations)){
      let filterConfigurationOptionsArray = [];
      
      for(let filterConfiguration of filterConfigurations[key]){
        filterConfigurationOptionsArray.push({label:filterConfiguration.display_name, value:filterConfiguration.name});
      }
      filterConfigurationOptions[key] = filterConfigurationOptionsArray;
    }
    
    let columnsArray = [];
    columnsArray.push({key:'custom_header_name', columnWeight:1, columnNameKey:'HEADER_NAME', propertiesArray:['custom_header_name'], handleSave:(aEvent, aObject, aRow) => {
        reportTemplate.model.columns[aRow].custom_header_name = aEvent.target.value;
        this.setState({reportTemplate:reportTemplate});
      }});
    columnsArray.push({key:'description', columnWeight:2, columnNameKey:'DESCRIPTION', propertiesArray:['description'], handleSave:(aEvent, aObject, aRow) => {
        reportTemplate.model.columns[aRow].description = aEvent.target.value;
        this.setState({reportTemplate:reportTemplate});
      }});
    columnsArray.push({key:'column_configuration_name', columnWeight:2, columnNameKey:'COLUMN', propertiesArray:['column_configuration_name'],
      optionsArray:(aObject, aRow) => {
        let column = reportTemplate.model.columns[aRow];
        let name = this.targetModelNameForColumnOrFilter(column);
        return columnConfigurationOptions[name];
      },
      handleSave:(aValue, aObject, aRow) => {
        reportTemplate.model.columns[aRow].column_configuration_name = aValue;
        
        if(!reportTemplate.model.columns[aRow].custom_header_name || reportTemplate.model.columns[aRow].custom_header_name.length === 0){
          reportTemplate.model.columns[aRow].custom_header_name = aValue;
        }
        this.setState({reportTemplate:reportTemplate});
      }});
    columnsArray.push({key:'type', columnWeight:1, columnNameKey:'TYPE', propertiesArray:['column_configuration_name'], valueFormatter:(aValue, aObject, aRow) => {
        let column = reportTemplate.model.columns[aRow];
        return this.propertyForColumn(column, 'data_type');
      }});
    columnsArray.push({key:'date_format', columnWeight:1.5, columnNameKey:'DATE_FORMAT', propertiesArray:['date_format'],
      disabled:(aObject, aRow) => {
        let returnValue = true;
        let column = reportTemplate.model.columns[aRow];
        let type = this.propertyForColumn(column, 'data_type');
        
        if(type === 'date' || type === 'datetime'){
          returnValue = false;
        }
        return returnValue;
      },
      optionsArray:(aObject, aRow) => {
        let returnValue = null;
        let column = reportTemplate.model.columns[aRow];
        let type = this.propertyForColumn(column, 'data_type');
        
        if(type === 'date' || type === 'datetime'){
          returnValue = railsDateOptionsArray;
        }
        return returnValue;
      },
      handleSave:(aValue, aObject, aRow) => {
        reportTemplate.model.columns[aRow].date_format = aValue;
        this.setState({reportTemplate:reportTemplate});
      }});
    columnsArray.push({key:'multiple_columns', description:i18next.t('REPORT_BUILDER_MULTIPLE_COLUMNS_DESCRIPTION'),  columnWeight:0.5, columnNameKey:'MULTIPLE_COLUMNS', propertiesArray:['multiple_columns'], valueFormatter:(aValue, aObject, aRow) => {
        let returnValue = '';
        let column = reportTemplate.model.columns[aRow];
        
        if(this.propertyForColumn(column, 'multiple_columns')){
          returnValue = i18next.t('YES_PROPER_CAPITALIZED');
        }
        return returnValue;
      }});
    columnsArray.push({key:'relationship_arguments', columnWeight:2.5, columnNameKey:'RELATIONSHIP_CONFIGURATIONS',
      propertiesArray:['relationship_arguments'], templateCell:(aObject, aRow) => this.templateCellForRelationship(aObject, aRow, 'columns')});
    
    let filterColumnsArray = [];
    filterColumnsArray.push({key:'filter_configuration_name', columnWeight:2, columnNameKey:'FILTER', propertiesArray:['filter_configuration_name'],
      optionsArray:(aObject, aRow) => {
        let filter = reportTemplate.model.filters[aRow];
        let name = this.targetModelNameForColumnOrFilter(filter);
        return filterConfigurationOptions[name];
      },
      handleSave:(aValue, aObject, aRow) => {
        reportTemplate.model.filters[aRow].filter_configuration_name = aValue;
        this.setState({reportTemplate:reportTemplate});
      }});
    filterColumnsArray.push({key:'type', columnWeight:1, columnNameKey:'TYPE', propertiesArray:['filter_configuration_name'], valueFormatter:(aValue, aObject, aRow) => {
        let returnValue = '';
        let filter = reportTemplate.model.filters[aRow];
        let name = this.targetModelNameForColumnOrFilter(filter);
        let filterConfigurationsArray = filterConfigurations[name];
        
        if(filterConfigurationsArray && filterConfigurationsArray.length > 0){
          for(let filterConfiguration of filterConfigurationsArray){
            if(aValue === filterConfiguration.name){
              returnValue = filterConfiguration.data_type;
              break;
            }
          }
        }
        return returnValue;
      }});
    filterColumnsArray.push({key:'keyword', description:i18next.t('FILTER_KEYWORD_DESCRIPTION'), columnWeight:1, columnNameKey:'KEYWORD', propertiesArray:['keyword'], handleSave:(aEvent, aObject, aRow) => {
        reportTemplate.model.filters[aRow].keyword = aEvent.target.value;
        this.setState({reportTemplate:reportTemplate});
      }});
    filterColumnsArray.push({key:'arel_comparator', columnWeight:1, columnNameKey:'COMPARATOR', propertiesArray:['arel_comparator'], optionsArray:() => arelComparatorOptionsArray, handleSave:(aValue, aObject, aRow) => {
        reportTemplate.model.filters[aRow].arel_comparator = aValue;
        this.setState({reportTemplate:reportTemplate});
      }});
    filterColumnsArray.push({key:'rhs_value', columnWeight:1, columnNameKey:'VALUE', propertiesArray:['rhs_value'], handleSave:(aEvent, aObject, aRow) => {
        reportTemplate.model.filters[aRow].rhs_value = aEvent.target.value;
        this.setState({reportTemplate:reportTemplate});
      }});
    filterColumnsArray.push({key:'relationship_arguments', columnWeight:2.5, columnNameKey:'RELATIONSHIP_CONFIGURATIONS',
      propertiesArray:['relationship_arguments'], templateCell:(aObject, aRow) => this.templateCellForRelationship(aObject, aRow, 'filters')});
    
    let canChangeModel = true;
    
    if(reportTemplate && reportTemplate.model){
      if(reportTemplate.model.model_configuration_name && reportTemplate.model.model_configuration_name.length > 0){
        if(reportTemplate.model.columns && reportTemplate.model.columns.length > 0){
          canChangeModel = false;
        }
        if(reportTemplate.model.filters && reportTemplate.model.filters.length > 0){
          canChangeModel = false;
        }
      }
    }
    return (
      <div className={isModal ? 'report-builder-container-modal' : 'report-builder-container'}>
        <div className="report-builder-header-container">
          <LabeledDelayedInput className="report-builder-header-delayed-input"
                               label={<Translation>{(t, {i18n}) => t('REPORT_NAME')}</Translation>}
                               value={reportTemplate.name}
                               handleSave={(aEvent) => {
                                 reportTemplate.name = aEvent.target.value;
                                 this.setState({reportTemplate:reportTemplate});
                               }}
                               placeholder={i18next.t('REPORT_NAME')}
          />
          
          <LabeledDropDown className="report-builder-header-input"
                           label={<Translation>{(t, {i18n}) => t('MODEL_CONFIGURATION')}</Translation>}
                           value={reportTemplate.model && modelOptionsArray.filter(function(option){
                             return option.value === reportTemplate.model.model_configuration_name;
                           })}
                           options={modelOptionsArray}
                           disabled={!canChangeModel}
                           infoBlurb={<Translation>{ (t, { i18n }) => t('MODEL_CONFIGURATION_EXPLANATION') }</Translation>}
                           handleSave={(aOption) => {
                             reportTemplate.model.model_configuration_name = aOption.value;
                             this.fetchConfigurationsArrays();
                             this.setState({reportTemplate:reportTemplate});
                           }}
                           placeholder={i18next.t('MODEL_CONFIGURATION')}
          />
  
          <div className="report-builder-header-spinner-button-cell">
            <BlueButton className="report-builder-header-button-save"
                        name={<Translation>{(t, {i18n}) => t('SAVE')}</Translation>}
                        loading={isSaving}
                        onClick={() => {
                          this.setState({isSaving:true});
                          saveReportTemplate(reportTemplate).then((newResult) => {
                            notifyReportTemplateSaveSuccess();
                            let reportTemplate = newResult.data.data.saveTemplate.template;
        
                            if(reportTemplateDidSave){
                              reportTemplateDidSave(reportTemplate);
                            }
                            this.setState({isSaving:false, reportTemplate:reportTemplate});
                          }, (newError) => {
                            notifyReportTemplateSaveError(errorMessageFromServerError(newError));
                            this.setState({isSaving:false});
                          })
                        }}
            />
    
            <BiDotsVerticalRounded style={{cursor:'pointer', fontSize:'21px', marginLeft:'auto', height:'20px', width:'20px', flex:'0 0 20px', marginRight:'10px', marginTop:'10px'}}
                                   onClick={(aEvent) => {
                                     aEvent.preventDefault();
                                     aEvent.stopPropagation();
                                     this.setState({menuAnchorElement:aEvent.currentTarget});
                                   }}
            />
          </div>
        </div>
        
        <div className="report-builder-dynamic-columns-list">
          <DynamicList id="report-builder-columns-list"
                       columnsArray={columnsArray}
                       showSearchBar={false}
                       menuItemsArray={[{title:'REMOVE', clickAction:(aObject) => this.setState({selectedColumn:aObject, selectedFilter:null, showConfirmationModal:true})}]}
                       minColumnWidth={150}
                       addButtonClicked={reportTemplate.model.model_configuration_name.length > 0 ?
                         (aEvent) => this.setState({showAddReportColumnModal:true})
                         :
                         null
                       }
                       fixedObjectsArray={reportTemplate.model.columns}
          />
        </div>
        
        <div className="report-builder-dynamic-filters-list">
          <DynamicList id="report-builder-filters-list"
                       columnsArray={filterColumnsArray}
                       showSearchBar={false}
                       menuItemsArray={[{title:'REMOVE', clickAction:(aObject) => this.setState({selectedFilter:aObject, selectedColumn:null, showConfirmationModal:true})}]}
                       minColumnWidth={150}
                       addButtonClicked={reportTemplate.model.model_configuration_name.length > 0 ?
                         (aEvent) => {
                           reportTemplate.model.filters.push({});
                           this.setState({reportTemplate:reportTemplate});
                         }
                         :
                         null
                       }
                       fixedObjectsArray={reportTemplate.model.filters}
          />
        </div>
        
        {Boolean(selectedRelationshipConfiguration) ?
          <RelationshipConfigurationsModal key={'report-builder-relationship-configuration-names-modal'}
                                           isOpen={Boolean(selectedRelationshipConfiguration)}
                                           showArguments={selectedRelationshipConfiguration.type !== 'filters'}
                                           addConfiguration={() => {
                                             let array = reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments;
            
                                             if(!Array.isArray(array)){
                                               array = [{name:'', arguments:{}}];
                                             }
                                             else{
                                               array.push({name:'', arguments:{}});
                                             }
                                             reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments = array;
                                             selectedRelationshipConfiguration.array = reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments;
  
                                             if(selectedRelationshipConfiguration.type === 'columns'){
                                               this.fetchConfigurationsArrays();
                                             }
                                             this.setState({reportTemplate:reportTemplate, selectedRelationshipConfiguration:selectedRelationshipConfiguration});
                                           }}
                                           handleCloseModal={() => this.setState({selectedRelationshipConfiguration:null})}
                                           removeLastConfiguration={() => {
                                             reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments.splice(-1);
                                             selectedRelationshipConfiguration.array = reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments;
                                             this.setState({reportTemplate:reportTemplate, selectedRelationshipConfiguration:selectedRelationshipConfiguration});
                                           }}
                                           updateLastConfiguration={(aArgument) => {
                                             let arrayLength = reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments.length;
                                             reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments[arrayLength - 1] = aArgument;
                                             selectedRelationshipConfiguration.array = reportTemplate.model[selectedRelationshipConfiguration.type][selectedRelationshipConfiguration.row].relationship_arguments;
                                             
                                             if(selectedRelationshipConfiguration.type === 'columns'){
                                               this.fetchConfigurationsArrays();
                                             }
                                             this.setState({reportTemplate:reportTemplate, selectedRelationshipConfiguration:selectedRelationshipConfiguration});
                                           }}
                                           modelConfigurationName={reportTemplate.model.model_configuration_name}
                                           relationshipArgumentsArray={selectedRelationshipConfiguration.array}
                                           fetchRelationshipConfigurations={fetchRelationshipConfigurations}
          />
          :
          null
        }
        
        <ConfirmationModal title={<Translation>{ (t, { i18n }) => t(selectedColumn ? 'REMOVE_REPORT_TEMPLATE_COLUMN_CONFIGURATION_TITLE' : 'REMOVE_REPORT_TEMPLATE_FILTER_CONFIGURATION_TITLE') }</Translation>}
                           isOpen={showConfirmationModal}
                           reject={() => this.setState({showConfirmationModal:false, selectedColumn:null, selectedFilter:null})}
                           confirm={() => {
                             if(selectedColumn){
                               for(let index = 0; index < reportTemplate.model.columns.length; index += 1){
                                 let column = reportTemplate.model.columns[index];
              
                                 if(column.id === selectedColumn.id){
                                   reportTemplate.model.columns.splice(index, 1);
                                   break;
                                 }
                               }
                               notifyReportColumnRemoved();
                             }
                             else{
                               for(let index = 0; index < reportTemplate.model.filters.length; index += 1){
                                 let filter = reportTemplate.model.filters[index];
              
                                 if(filter.id === selectedFilter.id){
                                   reportTemplate.model.filters.splice(index, 1);
                                   break;
                                 }
                               }
                               notifyReportFilterRemoved();
                             }
                             this.setState({reportTemplate:reportTemplate, showConfirmationModal:false, selectedColumn:null, selectedFilter:null});
                           }}
                           description={<Translation>{ (t, { i18n }) => t('REMOVE_REPORT_TEMPLATE_CONFIGURATION_DESCRIPTION', {aName:(selectedColumn ? selectedColumn.custom_header_name : (selectedFilter ? selectedFilter.keyword : ''))}) }</Translation>}
        />
        
        {showAddReportColumnModal ?
          <AddReportColumnModal isOpen={showAddReportColumnModal}
                                handleSave={(aConfiguration) => {
                                  reportTemplate.model.columns.push(aConfiguration);
                                  this.fetchConfigurationsArrays();
                                  this.setState({reportTemplate:reportTemplate, showAddReportColumnModal:false});
                                }}
                                handleClose={() => this.setState({showAddReportColumnModal: false})}
                                fetchPreMadeRelationships={(aLimit, aOffset, aSearchString) => fetchPreMadeRelationships(aLimit, aOffset, aSearchString, reportTemplate.model.model_configuration_name)}
          />
          :
          null
        }
        
        <input type="file"
               ref={this.uploadInputRef}
               onChange={(aEvent) => this.uploadReportTemplate(aEvent)}
               accept="txt/yml/yaml"
               style={{display:'none'}}
        />
        
        <Menu id="dynamic-list-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({menuAnchorElement:null})}
              anchorEl={menuAnchorElement}
              keepMounted>
          <MenuItem key={'report-builder-menu-item-upload'}
                    style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      this.uploadInputRef.current.click();
                      this.setState({menuAnchorElement:null});
                    }}>
            <Translation>{(t, {i18n}) => t('UPLOAD')}</Translation>
          </MenuItem>
          
          <MenuItem key={'report-builder-menu-item-download'}
                    style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      this.downloadReportTemplate();
                      this.setState({menuAnchorElement:null});
                    }}>
            <Translation>{(t, {i18n}) => t('DOWNLOAD')}</Translation>
          </MenuItem>
          
          <MenuItem key={'report-builder-menu-item-cancel'}
                    style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      this.setState({menuAnchorElement:null});
                    }}>
            <Translation>{(t, {i18n}) => t('CANCEL')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
