const EventTypes = {
  UPDATE_TOOL: 'Update Tool',
  UPDATE_USER_TOOL: 'Update User Tool',
  ADD_TOOL_TO_COMPANY: 'Add Tool To Company',
  UPDATE_COMPANY: 'Update Company',
  REMOVE_TOOL_FROM_COMPANY: 'Remove Tool From Company',
  ADD_OBJECTIVE: 'Add Objective',
  REMOVE_OBJECTIVE: 'Remove Objective',
  ADD_GROUP: 'Add Group',
  REMOVE_GROUP: 'Remove Group',
  ADD_TOOLS_TO_GROUP: 'Add Tools To Group',
  REMOVE_TOOL_FROM_GROUP: 'Remove Tool From Group',
  ADD_OBJECTIVE_TO_TOOL: 'Add Objective To Tool',
  ADD_POINTS_TO_OBJECTIVE: 'Add Points To Objective',
  REMOVE_POINTS_FROM_OBJECTIVE: 'Remove Points From Objective',
  ADD_USER_TO_GROUP: 'Add User To Group',
  FETCH_MEDICATIONS_WITH_COUNTRY_CODE: 'Fetch Medications with Country Code',
  ADD_MEDICATION_TO_USER: 'ADD Medication To User',
  ADD_CUSTOM_MEDICATION_TO_USER: 'ADD Custom Medication To User',
  REMOVE_MEDICATION_FROM_USER: 'Remove Medication From User',
  REMOVE_CONDITION_FROM_USER: 'Remove Condition From User',
  REMOVE_USER_FROM_GROUP: 'Remove User From Group',
  ADD_USER: 'Add User',
  REMOVE_USER: 'Remove User',
  UPDATE_USER: 'Update User',
  INVITE_USER: 'Invite User',
  ADD_USER_TO_COMPANY: 'Add User To Company',
  REMOVE_USER_FROM_COMPANY: 'Remove User From Company',
  ADD_CONDITION_TO_USER: 'Add Condition To User',
  FETCH_SYMPTOMS: 'Fetch Symptoms',
  ADD_SYMPTOM_TO_USER: 'Add Symptom To User',
  REMOVE_TOOL_FROM_USER: 'Remove Tool From User',
  REMOVE_SYMPTOM_FROM_USER: 'Remove Symptom From User',
  ADD_SYMPTOM_TO_GROUP: 'Add Symptom To Group',
  REMOVE_SYMPTOM_FROM_GROUP: 'Remove Symptom From Group',
  LOGIN: 'Login',
  SIGNUP: 'Signup',
  LOGOUT: 'Logout',
  CLEAR_COMPANY_NOTIFICATION_RECEIPT: 'Clear Company Notification Receipt',
  ADD_NOTE: 'Add Note',
  ADD_ALLERGY: 'Add Allergy',
  ADD_MEDICATION_ALLERGY: 'Add Medication Allergy',
  REMOVE_ALLERGY: 'Remove Allergy',
  REMOVE_MEDICATION_ALLERGY: 'Remove Medication Allergy',
  UPDATE_NOTE: 'Update Note',
  REMOVE_NOTE: 'Remove Note',
  FETCH_PAYMENTS: 'Fetch Payments',
  CREATE_PAYMENT: 'Create Payment',
  REMOVE_PAYMENT: 'Remove Payment',
  APPROVE_PAYMENT: 'Approve Payment',
  FETCH_HYPER_WALLET_CREDENTIALS: 'Fetch HyperWallet Credentials',
  UPDATE_HYPER_WALLET_CREDENTIALS: 'Update HyperWallet Credentials',
  FETCH_TREMENDOUS_CREDENTIALS: 'Fetch Tremendous Credentials',
  UPDATE_TREMENDOUS_CREDENTIALS: 'Update Tremendous Credentials',
  FETCH_PAYMENT_TRIGGERS: 'Fetch Payment Triggers',
  FETCH_PAYMENT_TRIGGERS_FOR_GROUP: 'Fetch Payment Triggers For Group',
  CREATE_PAYMENT_TRIGGER: 'Create Payment Trigger',
  REMOVE_PAYMENT_TRIGGER: 'Remove Payment Trigger',
  UPDATE_PAYMENT_TRIGGER: 'Update Payment Trigger',
  VIEW_ARTICLE: 'View Article',
  FETCH_ARTICLES: 'Fetch Articles',
  FETCH_ALLERGIES: 'Fetch Allergies',
  FETCH_MEDICATION_ALLERGIES: 'Fetch Medication Allergies',
  FETCH_ARTICLE_TOPICS: 'Fetch Article Topics',
  FETCH_RELATED_ARTICLES: 'Fetch Related Articles',
  FETCH_NEW_ARTICLES_COUNT: 'Fetch New Articles Count',
  FETCH_FRAUD_EVENTS: 'Fetch Fraud Events',
  FETCH_FRAUD_STATISTICS: 'Fetch Fraud Statistics',
  MARK_FRAUD_EVENT_AS_VERIFIED: 'Mark Fraud Event As Verified',
  MARK_FRAUD_EVENT_AS_FRAUDULENT: 'Mark Fraud Event As Fraudulent',
  MARK_FRAUD_STATISTIC_AS_VERIFIED: 'Mark Fraud Statistic As Verified',
  MARK_FRAUD_STATISTIC_AS_FRAUDULENT: 'Mark Fraud Statistic As Fraudulent',
  UPDATE_GROUP: 'Update Group',
  FETCH_REPORT: 'Fetch Report',
  FETCH_REPORTS: 'Fetch Reports',
  REMOVE_REPORT: 'Remove Report',
  FETCH_TRIGGERS: 'Fetch Triggers',
  FETCH_TRIGGERS_FOR_USER: 'Fetch Triggers For User',
  FETCH_TRIGGERS_FOR_GROUP: 'Fetch Triggers For Group',
  CREATE_TRIGGER: 'Create Trigger',
  REMOVE_TRIGGER: 'Remove Trigger',
  UPDATE_TRIGGER: 'Update Trigger',
  FETCH_ADMIN_HISTORIES: 'Fetch Admin Histories',
  SAVE_SURVEY_VERSION_COLLECTION: 'Save Survey Version Collection',
  ARCHIVE_SURVEY_VERSION_COLLECTION: 'Archive Survey Version Collection',
  UNARCHIVE_SURVEY_VERSION_COLLECTION: 'Unarchive Survey Version Collection',
  PUBLISH_SURVEY_VERSION_COLLECTION: 'Publish Survey Version Collection',
  ADD_QUEST_FOR_SURVEY_VERSION_COLLECTION: 'Add Quest For Survey Version Collection',
  ADD_SURVEY_LIST: 'Add Survey List',
  FETCH_SURVEY: 'Fetch Survey',
  UPDATE_SURVEY_LIST: 'Update Survey List',
  FETCH_SURVEY_LIST: 'Fetch Survey List',
  FETCH_SURVEY_LISTS: 'Fetch Survey Lists',
  FETCH_DYNAMIC_LIST: 'Fetch Dynamic List',
  FETCH_DYNAMIC_LISTS: 'Fetch Dynamic Lists',
  VALIDATE_RUBULAR_REGEX: 'Validate Rubular Regex',
  FETCH_DYNAMIC_LIST_ITEMS: 'Fetch Dynamic List Items',
  FETCH_QUESTS:'Fetch Quests',
  FETCH_QUESTIONS_FOR_SURVEY: 'Fetch Questions For Survey',
  FETCH_SURVEY_VERSION_COLLECTION: 'Fetch Survey Version Collection',
  FETCH_SURVEY_VERSION_COLLECTIONS: 'Fetch Survey Version Collections',
  FETCH_SURVEYS_FOR_SURVEY_VERSION_COLLECTION: 'Fetch Surveys for Survey Version Collection',
  FETCH_APP_ADDITIONAL_COLUMNS: 'Fetch App Additional Columns',
  GET_SURVEYS: 'Get Surveys',
  GET_ENTRIES: 'Get Entries',
  FIND_USER_BY_EMAIL: 'Find User by Email',
  DOWNLOAD_GROUP_CODE_HISTORY: 'Download Group Code History',
  CREATE_REPORT_TEMPLATE: 'Create Report Template',
  UPDATE_REPORT_TEMPLATE: 'Update Report Template',
  REMOVE_REPORT_TEMPLATE: 'Remove Report Template',
  FETCH_REPORT_TEMPLATE: 'Fetch Report Template',
  GENERATE_REPORT_TEMPLATE: 'Generate Report Template',
  DUPLICATE_REPORT_TEMPLATE: 'Duplicate Report Template',
  FETCH_REPORT_TEMPLATES: 'Fetch Report Templates',
  FETCH_MODEL_CONFIGURATIONS: 'Fetch Model Configurations',
  FETCH_COLUMN_CONFIGURATIONS: 'Fetch Column Configurations',
  FETCH_FILTER_CONFIGURATIONS: 'Fetch Filter Configurations',
  FETCH_RELATIONSHIP_CONFIGURATIONS: 'Fetch Relationship Configurations',
  FETCH_PRE_MADE_RELATIONSHIPS: 'Fetch Pre Made Relationships',
  GET_DATA_QUERY_COMMENTS: 'Get Data Query Comments',
  GET_DATA_QUERY_NOTE: 'Get Data Query Note',
  GET_DATA_QUERY_NOTES: 'Get Data Query Notes',
  CREATE_DATA_QUERY_NOTE: 'Create Data Query Note',
  RESOLVE_DATA_QUERY_NOTE: 'Resolve Data Query Note',
  CREATE_DATA_QUERY_COMMENT: 'Create Data Query Comment',
  FETCH_VIRTUAL_APPOINTMENTS:'Fetch Virtual Appointments',
  FETCH_VIRTUAL_APPOINTMENT_VIDEO:'Fetch Virtual Appointment Video',
  CREATE_VIRTUAL_APPOINTMENT:'Create Virtual Appointment',
  REMOVE_VIRTUAL_APPOINTMENT:'Remove Virtual Appointment',
  RETRY_GENERATING_REPORT:'Retry Generating Report',
  FETCH_USER_HS_FEATURE:'Fetch User HS Feature',
  FETCH_GROUP_HS_FEATURE:'Fetch Group HS Feature',
  UPDATE_USER_HS_FEATURE:'Update User HS Feature',
  UPDATE_GROUP_HS_FEATURE:'Update Group HS Feature',
  UPDATE_COMPANY_HS_FEATURE:'Update Company HS Feature',
  CLOSE_STUDY:'Close Study',
  FETCH_CALL_NOTES:"Fetch Call Notes",
  FETCH_HISTORIES_FOR_CALL_NOTE:"Fetch Histories For Call Note",
  CREATE_CALL_NOTE:"Create Call Note",
  UPDATE_CALL_NOTE:"Update Call Note",
  CORE_GROUP_MISSING:"Core Group Missing",
  GET_MEDICAL_RECORDS: 'Get Medical Records',
  DOWNLOAD_MEDICAL_RECORD: 'Download Medical Record',
  SEND_SURVEY_LINK:'Send Survey Link',
  FETCH_SURVEY_LINKS:'Fetch Survey Links',
  FETCH_SURVEY_LINK:'Fetch Survey Link',
  FETCH_SURVEY_LINK_FAILURES:'Fetch Survey Link Failures',
  FETCH_SURVEY_LINK_BATCH:'Fetch Survey Link Batch',
  FETCH_SURVEY_LINK_BATCHES:'Fetch Survey Link Batches',
  SEND_SURVEY_LINK_TO_GROUP:'Send Survey Link to Group',
  SEND_SURVEY_LINK_BATCH:'Send Survey Link Batch',
  RESEND_SURVEY_LINK_BATCH:'Resend Survey Link Batch',
  SEND_SMS:'Send SMS',
  RESEND_SMS:'Resend SMS',
  FETCH_SMS:'Fetch SMS',
  FETCH_SMSES:'Fetch SMSes',
  FETCH_SMS_FAILURES:'Fetch SMS Failures',
  FETCH_SMS_BATCH:'Fetch Survey Link Batch',
  FETCH_SMS_BATCHES:'Fetch Survey Link Batches',
  SEND_SMS_BATCH:'Send SMS Batch',
  RESEND_SMS_BATCH:'Resend SMS Batch',
  FETCH_SCHEDULE: 'Fetch Schedule',
  FETCH_SCHEDULES: 'Fetch Schedules',
  FETCH_SCHEDULE_VERSION: 'Fetch Schedule Version',
  FETCH_SCHEDULE_VERSIONS: 'Fetch Schedule Versions',
  FETCH_PATIENTS_IN_SCHEDULE: 'Fetch Patient In Schedule',
  CREATE_SCHEDULE: 'Create Schedule',
  UPDATE_SCHEDULE: 'Update Schedule',
  CREATE_SCHEDULE_TRIGGER: 'Create Schedule Trigger',
  UPDATE_SCHEDULE_Trigger: 'Update Schedule Trigger',
  REMOVE_SCHEDULE: 'Remove Schedule',
  REMOVE_SCHEDULE_TRIGGER: 'Remove Schedule Trigger',
  PUBLISH_SCHEDULE: 'Publish Schedule',
  FETCH_USER_ADDITIONAL_APP_COLUMNS: 'Fetch User Additional App Columns'
};

export default EventTypes;
