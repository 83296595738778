import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import TabBar from "../../Custom UI/TabBar/TabBar";
import {Translation} from "react-i18next";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import './ManageTriggers.scss';
import TriggersList from "../../Lists/TriggersList/TriggersList";
import SurveyTriggersList from "../../Lists/SurveyTriggersList/SurveyTriggersList";
import UserTriggersList from "../../Lists/UserTriggersList/UserTriggersList";
import Trigger from "../../Components/Trigger/Trigger";

export default class ManageTriggers extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      tabBarKey:'TRIGGERS',
      paymentTriggers:[],
      totalPaymentTriggers:0
    }
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    const {tools, company, fetchTriggers, removeTrigger, createTrigger, updateTrigger, fetchSurveyTriggers, fetchUserTriggers} = this.props;
    const {tabBarKey} = this.state;
    
    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('TRIGGERS') }</Translation>}/>
        
        <div className="main-panel-inner-container-secondary">
          <TabBar tabBarTabsArray={[{key:'TRIGGERS'}, {key:'NEW_TRIGGER'}, {key:'SURVEY_TRIGGERS'}, {key:'USER_TRIGGERS'}]}
                  initialSelectedTabKey={'TRIGGERS'}
                  selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
          />
          
          {tabBarKey === 'TRIGGERS' ?
            <div className="manage-triggers-list-container">
              <TriggersList tools={tools}
                            company={company}
                            createTrigger={createTrigger}
                            fetchTriggers={fetchTriggers}
                            removeTrigger={removeTrigger}
                            updateTrigger={updateTrigger}
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'NEW_TRIGGER' ?
            <div className="manage-new-trigger-container">
              <Trigger className={'manage-trigger-large'}
                       tools={tools}
                       company={company}
                       innerClassName={'manage-trigger-large-dual-input'}
                       createTrigger={createTrigger}
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'SURVEY_TRIGGERS' ?
            <div className="manage-triggers-survey-list-container">
              <SurveyTriggersList company={company}
                                  fetchSurveyTriggers={fetchSurveyTriggers}
              />
            </div>
            :
            null
          }
          
          {tabBarKey === 'USER_TRIGGERS' ?
            <div className="manage-triggers-user-list-container">
              <UserTriggersList company={company}
                                fetchUserTriggers={fetchUserTriggers}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
