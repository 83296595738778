import {Component} from 'react';
import React from 'react';
import Chart from "chart.js";
import {EventEmitter} from "../../EventEmitter/EventEmitter";
import {CustomEventTypes} from "../../../../constants/CustomEventTypes";

export default class BaseChart extends Component {
  chart;
  chartRef = React.createRef();
  constructor(props) {
    super(props);
    EventEmitter.subscribe(CustomEventTypes.RESPONSE_HOVERED,
      objectiveResponseIds => this.highlightResponse(objectiveResponseIds, '#eeeeee'));
    EventEmitter.subscribe(CustomEventTypes.RESPONSE_UNHOVERED,
      objectiveResponseIds => this.removeHighlightResponse(objectiveResponseIds));
  }

  componentDidMount() {
    let { entries, startDate, endDate, onClickListener } = this.props;
    const chartContext = this.chartRef.current.getContext("2d");
    this.chart = new Chart(chartContext, this.generateChartData(entries, startDate, endDate, onClickListener));
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const prevEntries = prevProps.entries;
    let { entries, startDate, endDate, onClickListener } = this.props;

    if (this.dateRangeChanged(prevProps.startDate, prevProps.endDate, startDate, endDate)
      || this.entriesChanged(prevEntries, entries)) {
      this.chart.destroy();
      const chartContext = this.chartRef.current.getContext("2d");
      this.chart = new Chart(chartContext, this.generateChartData(entries, startDate, endDate, onClickListener));
    }
  }

  entriesChanged(prevEntries, entries){
    if((prevEntries == null && entries != null) || (prevEntries != null && entries == null)){
      return true;
    }
    else if(prevEntries == null && entries == null){
      return false;
    }

    if(prevEntries.length === entries.length){
      for(let i = 0; i < prevEntries.length; i++){
        if((prevEntries[i].response_ids == null && prevEntries[i].response_ids == null) || (prevEntries[i].response_ids.length === 0)){
          // continue;
        }
        else if(prevEntries[i].response_ids == null || prevEntries[i].response_ids == null){
          return true;
        }
        else if (prevEntries[i].response_ids[0] !== entries[i].response_ids[0]) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  dateRangeChanged(prevStartDate, prevEndDate, startDate, endDate){
    return prevStartDate.getTime() !== startDate.getTime() || prevEndDate.getTime() !== endDate.getTime();
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.chart.update();
      }
    });
    return (
      <div>
        <canvas ref={this.chartRef}/>
      </div>
    )
  }

  generateChartData(entries, startDate, endDate){
    return {
      type: 'bar',
      data: {
        datasets: [{
          data: []
        }]
      }
    }
  }

  buildXAxisTickLabel(currentDate) {
    const today = new Date();

    if (today.getDate() === currentDate.getDate()
      && today.getMonth() === currentDate.getMonth()
      && today.getFullYear() === currentDate.getFullYear()) {
      return "Today";
    } else {
      const dateString = (currentDate.getMonth() + 1).toString() + "/" + currentDate.getDate().toString();

      if (today.getDay() === currentDate.getDay()) {
        const dayString = currentDate.toLocaleDateString("en-US", {weekday: 'long'}).substr(0,3);
        return `${dayString}, ${dateString}`;
      } else {
        return dateString;
      }
    }
  }

  highlightResponse = (objectiveResponseIds, color) => {
    const {responseId, objectiveId} = objectiveResponseIds;
    const meta = this.chart && this.chart.getDatasetMeta(0);
    const { dataPoints } = this.state;

    if (dataPoints !== undefined) {
      let index = null;

      dataPoints.forEach((dataPoint, i) => {
        if (dataPoint.responseIds &&
          dataPoint.responseIds.indexOf(responseId.toString()) !== -1 &&
          dataPoint.objectiveId === objectiveId) {
          index = i;
        }
      });

      if (index !== null) {
        const xMin = dataPoints[index].x === undefined ? index : dataPoints[index].x;
        this.chart.options.annotation = {
          annotations: [{
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: "x-axis-0",
            xMin: xMin - 0.5,
            xMax: xMin + 0.5,
            backgroundColor: color,
            borderColor: color
          }]
        };
      }
      this.chart.update();
    } else if (meta) {
      const dataPoints = meta.controller._data.filter(dataPoint => {
        return dataPoint.responseId === responseId.toString() && dataPoint.objectiveId === objectiveId;
      });

      if (dataPoints.length !== 0){
        const dataPoint = dataPoints[0];
        this.chart.options.annotation = {
          annotations: [{
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: "x-axis-0",
            xMin: dataPoint.x - 0.5,
            xMax: dataPoint.x + 0.5,
            backgroundColor: color,
            borderColor: color
          }]
        };
        this.chart.update();
      }
    }
  };

  removeHighlightResponse = (objectiveResponseIds) => {
    const {responseId, objectiveId} = objectiveResponseIds;
    const meta = this.chart.getDatasetMeta(0);
    let dataPoints = [];

    if (this.state.dataPoints !== undefined) {
      dataPoints = this.state.dataPoints.filter(dataPoint => {
        if (dataPoint.responseIds &&
          dataPoint.responseIds.indexOf(responseId.toString()) !== -1 &&
          dataPoint.objectiveId === objectiveId) {
          return true;
        }
        return false;
      });
    } else if (meta) {
      dataPoints = meta.controller._data.filter(dataPoint => {
        return dataPoint.responseId === responseId && dataPoint.objectiveId === objectiveId
      });
    }

    if (dataPoints.length !== 0) {
      this.chart.options.annotation = {};
      this.chart.update();
    }
  };

  formatResponseDate(response, yearFlag) {
    let responseDate = new Date(response);
    let responseHour = responseDate.getHours() % 12;

    if (responseHour === 0) {
      responseHour = 12;
    }
    let amPmValue = "am";

    if (responseDate.getHours() >= 12) {
      amPmValue = "pm";
    }

    // Appended 0 to minutes for values less than 10
    return `${responseDate.getMonth() + 1}/${responseDate.getDate()}${(yearFlag ? "/"+responseDate.getFullYear():"")} @ ${responseHour}:${('0' + responseDate.getMinutes()).slice(-2)} ${amPmValue}`;
  }

  resizePoints = () => {
    if (this.chartRef.current) {
      const baseWidth = 700;
      const {clientWidth} = this.chartRef.current;
      let newRadius = (clientWidth/baseWidth)* 5;
      let imageRadius = (clientWidth/baseWidth)* 20;

      if (newRadius < 5) {
        newRadius = 5;
      } else if (newRadius > 7.5) {
        newRadius = 7.5;
      }

      if (imageRadius < 20) {
        imageRadius = 20;
      } else if (imageRadius > 30) {
        imageRadius = 30;
      }
      this.chart.config.data.datasets[0].pointRadius = newRadius;
      this.chart.config.data.datasets[0].pointHoverRadius = newRadius;
      if (this.chart.config.data.datasets[0].pointStyle) {
        this.chart.config.data.datasets[0].pointStyle.forEach(style => {
          if (style) {
            style.height = imageRadius;
            style.width = imageRadius;
          }
        })
      }
    }
  };
}
