import React from 'react';
import "antd/dist/antd.css";
import './RelationshipConfigurationsModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import i18next from "i18next";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {firstKeyValuePairForObject} from "../../Helpers/Helpers";

export default class RelationshipConfigurationsModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      relationshipConfigurations:{}
    };
    this.canAddRelationship = this.canAddRelationship.bind(this);
    this.configurationAtReverseIndex = this.configurationAtReverseIndex.bind(this);
    this.fetchLatestRelationshipConfigurations = this.fetchLatestRelationshipConfigurations.bind(this);
  }
  
  componentDidMount(){
    this.fetchLatestRelationshipConfigurations();
  }
  
  // Methods
  
  canAddRelationship(){
    const {relationshipConfigurations} = this.state;
    const lastConfiguration = this.configurationAtReverseIndex(0);
    
    let returnValue = false;
    
    if(lastConfiguration && relationshipConfigurations && relationshipConfigurations[lastConfiguration.model_configuration_name]){
      returnValue = relationshipConfigurations[lastConfiguration.model_configuration_name].length > 0;
    }
    else if(!lastConfiguration || lastConfiguration.name !== ''){
      returnValue = true;
    }
    return returnValue;
  }
  
  configurationAtReverseIndex(aIndex){
    const {relationshipArgumentsArray} = this.props;
    
    let returnValue = null;
    
    if(relationshipArgumentsArray && relationshipArgumentsArray.length > aIndex){
      returnValue = relationshipArgumentsArray[relationshipArgumentsArray.length - (aIndex + 1)];
    }
    return returnValue;
  }
  
  fetchLatestRelationshipConfigurations(){
    const {modelConfigurationName, fetchRelationshipConfigurations} = this.props;
    const last = this.configurationAtReverseIndex(0);
    const secondLast = this.configurationAtReverseIndex(1);
    
    let configurationNamesArray = [modelConfigurationName];
    
    for(let configuration of [last, secondLast]){
      if(configuration && configuration.model_configuration_name && configuration.model_configuration_name.length > 0){
        if(!configurationNamesArray.includes(configuration.model_configuration_name)){
          configurationNamesArray.push(configuration.model_configuration_name);
        }
      }
    }
    for(let configurationName of configurationNamesArray){
      fetchRelationshipConfigurations(50,0,null, configurationName).then((newResult) => {
        let {relationshipConfigurations} = this.state;
        relationshipConfigurations[configurationName] = newResult.data.data.relationship_configurations.relationship_configurations;
        this.setState({relationshipConfigurations:relationshipConfigurations});
      });
    }
  }
  
  // Render
  
  render() {
    const {isOpen, addConfiguration, handleCloseModal, modelConfigurationName, removeLastConfiguration,
      updateLastConfiguration, relationshipArgumentsArray, showArguments = true} = this.props;
    const {relationshipConfigurations} = this.state;
    
    let relationshipConfigurationOptions = {};
    
    for(let key of Object.keys(relationshipConfigurations)){
      let relationshipConfigurationOptionsArray = [];
      
      for(let relationshipConfiguration of relationshipConfigurations[key]){
        relationshipConfigurationOptionsArray.push({label:relationshipConfiguration.display_name, value:relationshipConfiguration.name, model_configuration_name:relationshipConfiguration.related_model_configuration_name});
      }
      relationshipConfigurationOptions[key] = relationshipConfigurationOptionsArray;
    }
    let last = this.configurationAtReverseIndex(0);
    let secondLast = this.configurationAtReverseIndex(1);
    
    let keysArray = [];
    let optionsArray = [];
    let keyOptionsArray = [];
    
    if(secondLast && secondLast.model_configuration_name && secondLast.model_configuration_name.length > 0 && relationshipConfigurationOptions[secondLast.model_configuration_name]){
      keysArray = relationshipConfigurations[secondLast.model_configuration_name];
      optionsArray = relationshipConfigurationOptions[secondLast.model_configuration_name];
    }
    else if(modelConfigurationName && relationshipConfigurationOptions[modelConfigurationName]){
      keysArray = relationshipConfigurations[modelConfigurationName];
      optionsArray = relationshipConfigurationOptions[modelConfigurationName];
    }
    if(optionsArray && optionsArray.length > 0){
      if(last && last.name && last.name.length > 0){
        for(let configuration of keysArray){
          if(configuration.name === last.name){
            for(let argumentConfiguration of configuration.argument_configurations){
              keyOptionsArray.push({label:argumentConfiguration.display_name, value:argumentConfiguration.name});
            }
            break;
          }
        }
      }
    }
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('RELATIONSHIP_CONFIGURATIONS') }</Translation>}
                    width={'calc(100vw - 30px)'}
                    height={'calc(100vh - 30px)'}
                    isOpen={isOpen}
                    maxWidth={700}
                    maxHeight={500}
                    showFooter={false}
                    showHeader={true}
                    childrenHeight={'calc(100vh + 120px)'}
                    handleCloseModal={() => handleCloseModal()}
                    maxChildrenHeight={950}>
        <div className="relationship-configuration-names-modal-body">
          {relationshipArgumentsArray && relationshipArgumentsArray.length > 0 ?
            (relationshipArgumentsArray.map((relationshipArgument, index) => (
              (index < relationshipArgumentsArray.length - 1 ?
                  <div className="relationship-configuration-names-modal-filter-cell-config-options"
                       key={'relationship-configurations-' + index}>
                    <LabeledDelayedInput className="relationship-configuration-names-modal-header-delayed-input"
                                         label={index === 0 ? <Translation>{(t, {i18n}) => t('MODEL_CONFIGURATION')}</Translation> : null}
                                         value={relationshipArgument.name}
                                         disabled={true}
                                         required={index === 0}
                    />
                    
                    {showArguments ?
                      <div className="relationship-configuration-names-modal-show-config-options-container">
                        <LabeledDelayedInput className="relationship-configuration-names-modal-show-arguments-dropdown"
                                             label={index === 0 ? <Translation>{(t, {i18n}) => t('PROPERTY_KEY')}</Translation> : null}
                                             value={relationshipArgument.arguments ? firstKeyValuePairForObject(relationshipArgument.arguments).key : ''}
                                             disabled={true}
                        />
                        
                        <LabeledDelayedInput className="relationship-configuration-names-modal-show-arguments-delayed-input"
                                             label={index === 0 ? <Translation>{(t, {i18n}) => t('VALUE')}</Translation> : null}
                                             value={relationshipArgument.arguments ? firstKeyValuePairForObject(relationshipArgument.arguments).value : ''}
                                             disabled={true}
                        />
                        
                        <div className="relationship-configuration-names-modal-empty-button"/>
                      </div>
                      :
                      null
                    }
                  </div>
                  :
                  <div className="relationship-configuration-names-modal-filter-cell"
                       key={'relationship-configurations-' + index}>
                    <LabeledDropDown className="relationship-configuration-names-modal-configuration-name-input"
                                     label={index === 0 ? <Translation>{(t, {i18n}) => t('MODEL_CONFIGURATION')}</Translation> : null}
                                     value={relationshipArgument && optionsArray.filter(function(option){
                                       return option.value === relationshipArgument.name;
                                     })}
                                     options={optionsArray}
                                     handleSave={(aOption) => {
                                       if(relationshipArgument.name !== aOption.value){
                                         relationshipArgument.name = aOption.value;
                                         relationshipArgument.model_configuration_name = aOption.model_configuration_name;
                                         relationshipArgument.arguments = {};
                                         updateLastConfiguration(relationshipArgument);
                        
                                         setTimeout(() => {
                                           this.fetchLatestRelationshipConfigurations();
                                         }, 10);
                                       }
                                     }}
                                     placeholder={i18next.t('RELATIONSHIP_CONFIGURATION')}
                                     deleteButtonClicked={!showArguments ? () => {
                                       removeLastConfiguration();
                                       setTimeout(() => {
                                         this.fetchLatestRelationshipConfigurations();
                                       }, 10);
                                     }
                                     :
                                     null
                                     }
                    />
                    
                    {showArguments ?
                      <div className="relationship-configuration-names-modal-show-arguments-container">
                        <LabeledDropDown className="relationship-configuration-names-modal-show-arguments-dropdown"
                                         label={index === 0 ? <Translation>{(t, {i18n}) => t('PROPERTY_KEY')}</Translation> : null}
                                         value={relationshipArgument && keyOptionsArray.filter(function(option){
                                           return option.value === firstKeyValuePairForObject(relationshipArgument.arguments).key;
                                         })}
                                         options={keyOptionsArray}
                                         disabled={!relationshipArgument.name || relationshipArgument.name.length === 0}
                                         handleSave={(aOption) => {
                                           let value = firstKeyValuePairForObject(relationshipArgument.arguments).value;
                                           relationshipArgument.arguments = {};
                                           relationshipArgument.arguments[aOption.value] = value;
                                           updateLastConfiguration(relationshipArgument);
                                         }}
                                         placeholder={i18next.t('PROPERTY_KEY')}
                        />
                        
                        <LabeledDelayedInput className="relationship-configuration-names-modal-show-arguments-delayed-input"
                                             label={index === 0 ? <Translation>{(t, {i18n}) => t('VALUE')}</Translation> : null}
                                             value={relationshipArgument.arguments ? firstKeyValuePairForObject(relationshipArgument.arguments).value : ''}
                                             disabled={!firstKeyValuePairForObject(relationshipArgument.arguments).key|| firstKeyValuePairForObject(relationshipArgument.arguments).key.length === 0}
                                             handleSave={(aEvent) => {
                                               relationshipArgument.arguments[firstKeyValuePairForObject(relationshipArgument.arguments).key] = aEvent.target.value;
                                               updateLastConfiguration(relationshipArgument);
                                             }}
                                             placeholder={i18next.t('VALUE')}
                                             deleteButtonClicked={() => {
                                               removeLastConfiguration();
                                               setTimeout(() => {
                                                 this.fetchLatestRelationshipConfigurations();
                                               }, 10);
                                             }}
                        />
                      </div>
                      :
                      null
                    }
                  </div>
              )
            )))
            :
            null
          }
          
          {this.canAddRelationship() ?
            <BlueButton className="relationship-configuration-names-modal-save"
                        name={<Translation>{(t, {i18n}) => t('ADD')}</Translation>}
                        onClick={() => {
                          addConfiguration();
                          setTimeout(() => {
                            this.fetchLatestRelationshipConfigurations();
                          }, 10);
                        }}
            />
            :
            null
          }
        </div>
      </GeneralModal>
    )
  }
}
