import {
  SIDEBAR_COLLAPSED,
  SIDEBAR_EXPANDED
} from '../constants/SidebarTypes';

export function sidebarExpanded() {
  return {type: SIDEBAR_EXPANDED};
}

export function sidebarCollapsed() {
  return {type: SIDEBAR_COLLAPSED};
}