import React from 'react';
import "./HealthCareProviderInformationCell.scss";
import {toast} from "react-toastify";
import i18next from "i18next";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {stringContainsNumber} from "../../Helpers/Helpers";
import LabeledPhoneNumberInput from "../../Custom UI/LabeledInputs/LabeledPhoneNumberInput/LabeledPhoneNumberInput";

const notifyInvalidEmail = () => toast(i18next.t('INVALID_EMAIL_REQUEST_MESSAGE'), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyIncompleteForm = (aMessage) => toast(i18next.t('INCOMPLETE_FORM', {field:aMessage}), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyHealthCareProviderFailed = (aMessage) => toast(aMessage, NOTIFY_OPTS.autoClose);
const notifyHealthCareProviderSuccessful = (aMessage) => toast(i18next.t('ADD_HEALTH_CARE_PROVIDER_SUCCESSFUL', {field:aMessage}), NOTIFY_OPTS.autoClose);

export default class HealthCareProviderInformationCell extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      nameError:{},
      phoneError:null,
      buttonDisabled:false,
      currentContactForm:props.healthCareProviderInformation ? props.healthCareProviderInformation : {},
      confirmationModalOpen:false
    };
    this.handleSave = this.handleSave.bind(this);
    this.checkValidForm = this.checkValidForm.bind(this);
    this.handleDropDownSave = this.handleDropDownSave.bind(this);
    this.handlePhoneNumberSave = this.handlePhoneNumberSave.bind(this);
    this.saveHealthCareProviderInformationItem = this.saveHealthCareProviderInformationItem.bind(this);
  }

  // Methods

  onPhoneNumberError(errorMessage){
    this.setState({phoneError:errorMessage});
  }
  
  handlePhoneNumberSave(aPhoneNumber){
    let form = this.state.currentContactForm;
    form['phone_number'] = aPhoneNumber;
    this.setState({currentContactForm:form, buttonDisabled:false});
  }

  handleSave(aEvent, aKey){
    let form = this.state.currentContactForm;
    form[aKey] = aEvent.target.value;
    this.setState({currentContactForm:form, buttonDisabled:false});
  }

  handleDropDownSave(aOption, aKey){
    let form = this.state.currentContactForm;
    form[aKey] = aOption.value;
    this.setState({currentContactForm:form, buttonDisabled:false});
  }
  
  checkValidForm(){
    const form = this.state.currentContactForm;
    const validEmailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/;
    let returnValue = true;
    let invalidEmailMessage = '';
    
    if((form.email && !form.email.match(validEmailFormat))){
      returnValue = false;
      invalidEmailMessage = i18next.t('INVALID_EMAIL_REQUEST_MESSAGE');
    }
    if(!(form.first_name && form.first_name !== '' && form.last_name && form.last_name !== '' &&
      form.email && form.email !== '' && form.phone_number && form.phone_number !== '' &&
      form.physician_specialty && form.role && form.facility_name && form.facility_name !== '')){
      returnValue = false;
      notifyIncompleteForm(invalidEmailMessage);
    }
    else if(!returnValue){
      notifyInvalidEmail();
    }
    return returnValue;
  }
  
  saveHealthCareProviderInformationItem(){
    const {patient, healthCareProviderInformation, addHealthCareProviderInformationToUser} = this.props;
    const {buttonDisabled, currentContactForm} = this.state;
    
    if(!buttonDisabled){
      this.setState({buttonDisabled:true});
      
      if(patient != null && patient.id != null && addHealthCareProviderInformationToUser){
        if(this.checkValidForm()){
          addHealthCareProviderInformationToUser(currentContactForm, patient.id).then((newResult) => {
            if(healthCareProviderInformation && healthCareProviderInformation.id){
              notifyHealthCareProviderSuccessful(i18next.t('UPDATED'));
            }
            else{
              notifyHealthCareProviderSuccessful(i18next.t('ADDED'))
            }
          }, (newError) => {
            if(healthCareProviderInformation && healthCareProviderInformation.id){
              notifyHealthCareProviderFailed(i18next.t('UPDATED'));
            }
            else{
              notifyHealthCareProviderFailed(i18next.t('ADDED'));
            }
          });
        }
      }
      else{
        console.error("ERROR: Patient not found");
      }
    }
  }
  
  // Render

  render(){
    const {healthCareProviderInformation, removeHealthCareProviderInformation, patient} = this.props;
    const {phoneError, buttonDisabled, confirmationModalOpen, currentContactForm} = this.state;
    
    let specialties = [
      {label:(i18next.t('ANESTHESIOLOGIST')), value:'Anesthesiologist'},
      {label:(i18next.t('CARDIOLOGIST')), value:'Cardiologist'},
      {label:(i18next.t('DERMATOLOGIST')), value:'Dermatologist'},
      {label:(i18next.t('ENDOCRINOLOGIST')), value:'Endocrinologist'},
      {label:(i18next.t('FAMILY_PHYSICIAN')), value:'Family Physician'},
      {label:(i18next.t('GASTROENTEROLOGIST')), value:'Gastroenterologist'},
      {label:(i18next.t('INFECTIOUS_DISEASE_PHYSICIAN')), value:'Infectious Disease Physician'},
      {label:(i18next.t('INTERNAL_MEDICINE_PHYSICIAN')), value:'Internal Medicine Physician'},
      {label:(i18next.t('NEPHROLOGIST')), value:'Nephrologist'},
      {label:(i18next.t('NEUROLOGIST')), value:'Neurologist'},
      {label:(i18next.t('OBSTETRICIAN')), value:'Obstetrician/Gynecologist (OB/GYN)'},
      {label:(i18next.t('ONCOLOGIST')), value:'Oncologist'},
      {label:(i18next.t('OPHTHALMOLOGIST')), value:'Ophthalmologist'},
      {label:(i18next.t('OTOLARYNGOLOGIST')), value:'Otolaryngologist'},
      {label:(i18next.t('PEDIATRICIAN')), value:'Pediatrician'},
      {label:(i18next.t('PHYSICIAN_EXECUTIVE')), value:'Physician Executive'},
      {label:(i18next.t('PSYCHIATRIST')), value:'Psychiatrist'},
      {label:(i18next.t('PULMONOLOGIST')), value:'Pulmonologist'},
      {label:(i18next.t('RADIOLOGIST')), value:'Radiologist'},
      {label:(i18next.t('SURGEON')),value:'Surgeon'}];

    let roles = [
      {label:(i18next.t('NURSE')), value:'Nurse'},
      {label:(i18next.t('NURSE_MIDWIFE')), value:'Nurse Midwife'},
      {label:(i18next.t('PHARMACIST')), value:'Pharmacist'},
      {label:(i18next.t('PHYSICIAN')), value:'Physician'},
      {label:(i18next.t('PHYSICIANS_ASSISTANT')), value:'Physician’s Assistant'},
      {label:(i18next.t('REGISTERED_NURSE_PRACTIONER')), value:'Registered Nurse Practioner'}
    ];

    const reg = /[^A-Za-z]/;

    return (<div className='alternative-contact-section  alternative-contact-cell-labeled-phone-number-input'>
        <LabeledTextInput className="health-care-provider-information-input"
                          label={i18next.t('FACILITY_NAME')}
                          value={currentContactForm.facility_name}
                          required={true}
                          handleSave={(aEvent) => this.handleSave(aEvent, 'facility_name')}
                          errorMessage={(reg.test(currentContactForm.facility_name)) ? i18next.t('NUMBER_IN_FACILITY_NAME_ERROR') : ''}
        />
    
        <LabeledPhoneNumberInput className="health-care-provider-information-input"
                                 label={i18next.t('PHONE_NUMBER')}
                                 value={currentContactForm.phone_number}
                                 required={true}
                                 handleSave={(aPhoneNumber, aIsValid, aError) => {
                                   this.handlePhoneNumberSave(aPhoneNumber);
                                   this.onPhoneNumberError(aError);
                                 }}
                                 errorMessage={phoneError}
                                 countryCode={patient.country_code ? patient.country_code :'US'}
        />
    
        <LabeledTextInput className="health-care-provider-information-input"
                          label={i18next.t('FIRST_NAME_PROPER_CAPITALIZED')}
                          value={currentContactForm.first_name}
                          required={true}
                          handleSave={(aEvent) => this.handleSave(aEvent, 'first_name')}
                          errorMessage={stringContainsNumber(currentContactForm.first_name) ? i18next.t('NUMBER_IN_FIRST_NAME_ERROR') : ''}
        />

        <LabeledTextInput className="health-care-provider-information-input"
                          label={i18next.t('LAST_NAME_PROPER_CAPITALIZED')}
                          value={currentContactForm.last_name}
                          required={true}
                          handleSave={(aEvent) => this.handleSave(aEvent, 'last_name')}
                          errorMessage={stringContainsNumber(currentContactForm.last_name) ? i18next.t('NUMBER_IN_LAST_NAME_ERROR') : ''}
        />

        <LabeledDropDown className="health-care-provider-information-input"
                         label={i18next.t('ROLE')}
                         value={currentContactForm && roles.filter(function(option) {
                           return option.value === currentContactForm.role;
                         })}
                         required={true}
                         options={roles}
                         handleSave={(aOption) => this.handleDropDownSave(aOption, 'role')}
        />

        <LabeledDropDown className="health-care-provider-information-input"
                         label={i18next.t('PHYSICIAN_SPECIALTY')}
                         value={currentContactForm && specialties.filter(function(option) {
                           return option.value === currentContactForm.physician_specialty;
                         })}
                         required={true}
                         options={specialties}
                         handleSave={(aOption) => this.handleDropDownSave(aOption, 'physician_specialty')}
        />

        <LabeledTextInput className="health-care-provider-information-input"
                          label={i18next.t('EMAIL')}
                          value={currentContactForm.email}
                          required={true}
                          handleSave={(aEvent) => this.handleSave(aEvent, 'email')}
        />

        <GrayButton className="button-margin"
                    name={i18next.t('REMOVE')}
                    onClick={() => {this.setState({confirmationModalOpen:true})}}
        />
        
        <BlueButton className="button-popover"
                    name={i18next.t('SAVE_PROPER_CAPITALIZED')}
                    onClick={this.saveHealthCareProviderInformationItem}
                    disabled={buttonDisabled || stringContainsNumber(currentContactForm.first_name) || stringContainsNumber(healthCareProviderInformation.last_name)}
        />
        
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('HEALTH_CARE_PROVIDER_INFORMATION')})}
                           isOpen={confirmationModalOpen}
                           reject={() => this.setState({confirmationModalOpen:false})}
                           confirm={() => {
                                            removeHealthCareProviderInformation();
                                            this.setState({confirmationModalOpen:false});
                          }}
        />
      </div>
    )
  }
}
