import React from 'react';
import {Translation} from "react-i18next";
import i18next from "i18next";
import LabeledTextInput from "../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";

const ToolBlockSliderCell = ({block, handleBlockChange, selectedTranslationIndex}) => (
  <div style={{display: 'flex'}}>
    <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>

      <LabeledTextInput placeholder={i18next.t('VALUE_STAR')}
                        label={<Translation>{ (t, { i18n }) => t('MINIMUM_VALUE') }</Translation>}
                        name={'minimum_value'}
                        value={block.minimum_value || ''}
                        handleSave={handleBlockChange}
      />
      <LabeledTextInput placeholder={i18next.t('VALUE_STAR')}
                        label={<Translation>{ (t, { i18n }) => t('MINIMUM_VALUE_LABEL') }</Translation>}
                        name={'minimum_value_string'}
                        value={block.translations[selectedTranslationIndex].minimum_value_string || ''}
                        handleSave={handleBlockChange}
      />

      {/*<input className="rounded-input" style={{width: '100%', marginBottom: '5px'}} type="number"*/}
      {/*       placeholder={i18next.t('VALUE_STAR')}*/}
      {/*       name="minimum_value" value={block.minimum_value || ''} onChange={handleBlockChange} />*/}
      {/*       */}
      {/*<input className="rounded-input" style={{width: '100%'}} type="text"*/}
      {/*       placeholder={i18next.t('LABEL_')}*/}
      {/*       name="minimum_value_string" value={block.translations[selectedTranslationIndex].minimum_value_string || ''} onChange={handleBlockChange} />*/}

    </div>
    <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
      <LabeledTextInput placeholder={i18next.t('VALUE_STAR')}
                        label={<Translation>{ (t, { i18n }) => t('MAXIMUM_VALUE') }</Translation>}
                        name={'maximum_value'}
                        value={block.maximum_value || ''}
                        handleSave={handleBlockChange}
      />
      <LabeledTextInput placeholder={i18next.t('VALUE_STAR')}
                        label={<Translation>{ (t, { i18n }) => t('MINIMUM_VALUE_LABEL') }</Translation>}
                        name={'maximum_value_string'}
                        value={block.translations[selectedTranslationIndex].maximum_value_string || ''}
                        handleSave={handleBlockChange}
      />
      {/*<input className="rounded-input" style={{width: '100%', marginBottom: '5px'}} type="number"*/}
      {/*       placeholder={i18next.t('VALUE_STAR')}*/}
      {/*       name="maximum_value" value={block.maximum_value || ''} onChange={handleBlockChange} />*/}
      {/*       */}
      {/*<input className="rounded-input" style={{width: '100%'}} type="text"*/}
      {/*       placeholder={i18next.t('LABEL_')}*/}
      {/*       name="maximum_value_string" value={block.translations[selectedTranslationIndex].maximum_value_string || ''} onChange={handleBlockChange} />*/}
    </div>
  </div>
)

export default ToolBlockSliderCell
