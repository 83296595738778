import React from 'react';
import './PatientList.scss'
import {connect} from 'react-redux';
import { find, debounce, get } from 'lodash';
import PatientListItem from '../../Cells/PatientListItemCell/PatientListItem';
import {Translation, withTranslation} from "react-i18next";
import Popper from "@material-ui/core/Popper";
import PatientSearchForm from "./PatientSearchForm/PatientSearchForm";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchPatientsScroll } from 'actions/patients';
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import Loading from "../../Custom UI/Loading/Loading";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import { AddEditTableColumns } from './PatientAddEditCard';
import { withRouter } from 'react-router-dom';
import { INVITE_STATUS } from 'constants/ClientConstants';
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import DynamicList from "../DynamicList/DynamicList";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import i18next from "i18next";
import {formatUserName} from "../../Helpers/AdminHelpers";

class PatientList extends React.Component {

  // Instance Variables

  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getPatients(offset, limit),
    success: () => this.updateList(null),
    fail: (error) => this.updateList(error)
  });

  // Init

  constructor(props) {
    super(props);
    this.state = {
      isRemovingPatient:false,
      windowHeight:0,
      width:0,
      searchTerm:'',
      anchorEl:null,
      arrowRef:null,
      advancedSearchTerms:{},
      patients:[],
      loadingPatients:true,
      totalPatients:0,
      adminsArray:[],
      selectedPatientListItem:null,
      menuAnchorElement:null,
      limit:10,
      confirmationModalOpen:false,
      foundInvites:[],
      userToRemove:null,
      currentFilter: {
        name: 'Profile',
        asc: true
      },
      tableData:[
        {
          id:-1,
          name:'Profile',
          order: 0,
        },
        {
          id:-2,
          name:'Status',
          order:1,
        },
        {
          id:-3,
          name:'Email Validated',
          order:2,
        },
      ],
      addTableColumnModalOpen:false,
      shouldReload:false,
      dynamicFields: {
        survey_ids: [],
        survey_question_mapping: []
      },
      progress_break_down: {}
    };
    this.patientSearchInputRef = React.createRef();

    if(props.user && props.user.search_preference){
      this.state.advancedSearchTerms = props.user.search_preference;
    }
    
    if(props.limit){
      this.state.limit = props.limit;
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.userSearch = debounce(this.userSearch, 500);
    this.advancedSearch = this.advancedSearch.bind(this);
    this.handlePopperClose = this.handlePopperClose.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.findDmConversation = this.findDmConversation.bind(this);
    this.handleSearchPatient = this.handleSearchPatient.bind(this);
    this.renderPatientStatus = this.renderPatientStatus.bind(this);
    this.isFilteringBySearchTerms = this.isFilteringBySearchTerms.bind(this);
    this.renderPatientEmailStatus = this.renderPatientEmailStatus.bind(this);
    this.renderPatientSurveyStatus = this.renderPatientSurveyStatus.bind(this);
    this.handlePopperOnSearchClick = this.handlePopperOnSearchClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount(){
    let data = JSON.parse(localStorage.getItem("tableData"));
    let dynamic_survey_fields = JSON.parse(localStorage.getItem("dynamicFields"));
    
    if(data?.length){
      this.setState({tableData: data, dynamicFields: dynamic_survey_fields})
    }
  }
 
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  componentDidMount(){
    this.scrollManager.reloadWithLimit(this.state.limit);
    this.companyInviteSearch();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {limit} = this.state;
    
    if(this.props.user && prevProps.user){
      if(this.props.user !== prevProps.user){
        this.setState({shouldReload:true, searchTerm:'', advancedVariables:this.props.user.search_preference, anchorEl: null}, () => this.scrollManager.reloadWithLimit(limit));
      }
      else if(this.props.user.search_preference !== prevProps.user.search_preference){
        this.setState({shouldReload:true, searchTerm:'', advancedVariables:this.props.user.search_preference, anchorEl: null}, () => this.scrollManager.reloadWithLimit(limit));
      }
    }
    if(prevProps.groupName !== this.props.groupName){
      this.setState({shouldReload:true, searchTerm:'', advancedVariables:{}, anchorEl:null}, () => this.scrollManager.reloadWithLimit(limit));
    }
    if(!prevProps.groupName && !prevProps.company && prevProps.company !== this.props.company){
      this.scrollManager.reloadWithLimit(limit);
      this.setState({shouldReload:true});
    }
    if(prevState.currentFilter.name !== this.state.currentFilter.name || prevState.currentFilter.asc !== this.state.currentFilter.asc){
      this.setState({shouldReload:true});
    }
    if(this.props.showAdvancedSearch && prevProps.offset !== this.props.offset) {
      this.setState({anchorEl:null});
      if(this.patientSearchInputRef.current) {
        this.patientSearchInputRef.current.blur();
      }
    }
  }
  
  // Methods
  
  updateWindowDimensions(){
    this.setState({ width: window.innerWidth, windowHeight: window.innerHeight });
  }
  
  updateList(aError){
    this.setState({patients:this.scrollManager.getList()});

    if(aError){
      console.error("PatientList (updateList): Failed with error:", aError);
    }
  }

  companyInviteSearch(query){
    const { companyInviteSearch } = this.props;
    
    if(companyInviteSearch){
      companyInviteSearch(query, this.props.company.id).then((newResult) => {
        const invites = get(newResult, 'data.data.company_invites');
        
        if(invites){
          this.setState({foundInvites:invites});
        }
      });
    }
  }

  notifyResentSuccessful = () =>  toast(<Translation>{(t, {i18n}) => t('INVITE_RESENT_SUCCESSFULLY')}</Translation>, NOTIFY_OPTS.autoClose);
  notifyResentUnsuccessful = () => toast(<Translation>{(t, {i18n}) => t('INVITE_RESEND_UNSUCCESSFUL')}</Translation>, NOTIFY_OPTS.autoClose);
  notifyPatientRemoveSuccess = () => toast(<Translation>{(t, { i18n }) => t('REMOVE_PATIENT_SUCCESS')}</Translation>, NOTIFY_OPTS.autoClose);
  notifyError = (message) => toast(message, NOTIFY_OPTS.autoClose);

  getPatients(offset, limit){
    const {groupName, fromManagePatient, groupIdsToExclude, fetchPatientsScroll} = this.props;
    const {searchTerm, dynamicFields} = this.state;
    this.setState({loadingPatients:true});
    
    const {name, asc} = this.state.currentFilter;
    const {advancedSearchTerms} = this.state;
    let filteredSurveysArray = this.state.tableData.filter(e => e.name === name);
    let survey_data = filteredSurveysArray && filteredSurveysArray.length > 0 ? filteredSurveysArray[0] : {};
    
    return fetchPatientsScroll(
      searchTerm,
      groupName,
      limit,
      offset,
      name === 'Status' ? 'invite_status' : name === 'Email Validated' ? 'email_verified' : null,
      asc,
      advancedSearchTerms && advancedSearchTerms.preferred_language && advancedSearchTerms.preferred_language.length > 0 ? advancedSearchTerms.preferred_language : null,
      advancedSearchTerms && advancedSearchTerms.alert_type && advancedSearchTerms.alert_type.length > 0 ? advancedSearchTerms.alert_type : null,
      advancedSearchTerms && advancedSearchTerms.virtual_appointment_date,
      advancedSearchTerms && advancedSearchTerms.disposition_code && advancedSearchTerms.disposition_code.length > 0 ? advancedSearchTerms.disposition_code : null,
      advancedSearchTerms && advancedSearchTerms.search_by_id,
      !survey_data.surveyQues && survey_data.id ? parseInt(survey_data.id) : null,
      survey_data.surveyQues || null,
      dynamicFields,
      survey_data.surveyAns,
      fromManagePatient,
      groupIdsToExclude
    ).then((newResult) => {
      let usersArray = [];
      let totalPatients = 0;
      
      if(newResult && newResult.data && newResult.data.data && newResult.data.data.user_page){
        usersArray = newResult.data.data.user_page.users;
        totalPatients = newResult.data.data.user_page.total;
        this.setState({progress_break_down:newResult.data.data.user_page.progress_break_down});
      }
      this.setState({totalPatients:totalPatients, loadingPatients:false});
      return {objects:usersArray};
    }, (newError) => {
      this.setState({loadingPatients:false}, () => this.updateList(newError));
    });
  }

  handleSearchPatient(aEvent){
    const {searchTerm, anchorEl, loadingPatients} = this.state;
    
    if(this.props.showAdvancedSearch && !anchorEl){
      this.setState({anchorEl:aEvent.target});
    }

    if(!loadingPatients){
      if(aEvent.target.value !== searchTerm){
        this.setState({searchTerm:aEvent.target.value}, () => {
          this.userSearch();
        });
      }
    }
  }
  
  userSearch(){
    this.scrollManager.reloadWithLimit(this.state.limit);
  }

  advancedSearch(query){
    if(!query){
      query = {};
    }
    this.setState({ searchTerm:(this.state.searchTerm == null ? '' : this.state.searchTerm), advancedSearchTerms:query, anchorEl: null}, () => {
      this.props.updateSearchPreferences(query);
      this.userSearch();
      this.setState({shouldReload:true});
    });
  }

  findDmConversation(conversations, patient) {
    return find(conversations, (conversation) => {
      return (conversation.dm_user && (conversation.dm_user.id === parseInt(patient.id)))
    })
  }
  
  handlePopperOnSearchClick = (aElement) => {
    if(this.state.anchorEl === null){
      this.setState({ anchorEl:aElement });
    }
    else{
      this.setState({ anchorEl: null });
    }
  };

  handlePopperClose = (shouldClear) => {
    let newState = {anchorEl: null};

    if(shouldClear){
      newState = {searchTerm:'', advancedSearchTerms:{}, anchorEl: null};
      this.props.updateSearchPreferences({});
    }
    this.setState(newState, () => {
      this.userSearch();
      this.setState({shouldReload:true});
    });
  };

  setArrowRef = (aEvent) => {
    this.setState({arrowRef:aEvent});
  };

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedDataQuery:null});
  }

  menuButtonClicked(aEvent, aPatientListItem){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedPatientListItem:aPatientListItem});
  };
  
  isFilteringBySearchTerms(){
    let advancedSearchTerms = this.state.advancedSearchTerms;
    return advancedSearchTerms
      && ((advancedSearchTerms.search_by_id && advancedSearchTerms.search_by_id.length > 0)
      || (advancedSearchTerms.alert_type && advancedSearchTerms.alert_type.length > 0)
      || (advancedSearchTerms.disposition_code && advancedSearchTerms.disposition_code.length > 0)
      || (advancedSearchTerms.preferred_language && advancedSearchTerms.preferred_language.length > 0)
      || (advancedSearchTerms.virtual_appointment_date));
  }

  resendInvitationToUser = () => {
    const { resendInviteToUser } = this.props;
    const { selectedPatientListItem, foundInvites } = this.state;

    if(selectedPatientListItem){
      const companyInvite = foundInvites.find(invite => invite.email === selectedPatientListItem.email);
      
      if(companyInvite && companyInvite.id){
        resendInviteToUser(companyInvite.id).then(() => {
          this.notifyResentSuccessful();
        }).catch(() => {
          this.notifyResentUnsuccessful();
        });
      }
      else{
        this.notifyResentUnsuccessful();
      }
    }
    this.closeMenu();
  };
  
  renderPatientEmailStatus(aObject){
    return (
      <div>
        {aObject.invite_status === 'Active' &&
          <div className='circle'
               style={{
                 backgroundColor:aObject.email_verified ? '#2d81c9' : '#e02020',
                 width:'8px'
               }}
          />
        }
        
        {aObject.invite_status === 'Active' ?
          <span>
            {<Translation>{(t, {i18n}) => t((aObject.email_verified ? 'VERIFIED' : 'UNVERIFIED'))}</Translation>}
          </span>
          :
          null
        }
      </div>
    );
  }
  
  renderPatientStatus(aObject){
    return (
      <div>
        <div className='circle'
             style={{
               width:'8px',
               backgroundColor:aObject.invite_status === 'Active' ? '#66b033' : aObject.invite_status === 'Pending' ? '#f89117' : '#e02020'
             }}
        />
        
        <span>
            {<Translation>{(t, {i18n}) => t(aObject.invite_status.toUpperCase())}</Translation>}
        </span>
      </div>
    );
  }
  
  renderPatientSurveyStatus(aPatient, tableInformation, index){
    const {surveyAnswers, surveyAns, id} = tableInformation;
  
    if(aPatient?.dynamic_fields?.findIndex(e => e.survey_id === id) > -1){
      return (
        <div>
          {aPatient.invite_status === 'Active' ?
            <div className='circle'
                 style={{backgroundColor:(aPatient?.dynamic_fields[index]?.answer && aPatient?.dynamic_fields[index]?.answer === surveyAns) || aPatient?.dynamic_fields[index]?.complete ? '#2d81c9' : '#e02020'}}
            />
            :
            null
          }
          
          {aPatient.invite_status === 'Active' ?
            <span className='table-content'>
              {aPatient?.dynamic_fields[index]?.answer ?
                surveyAnswers.filter(e => e.value === aPatient?.dynamic_fields[index]?.answer)?.[0]?.label
                :
                (aPatient?.dynamic_fields[index]?.complete ? 'Completed' : 'Incomplete')
              }
            </span>
          :
          null
          }
        </div>
      );
    }
  }
  
  // Render

  render() {
    const {groupName, selectPatient, height, showLink, user, showAdvancedSearch, showFraudStatistics = true,
      selectedPatientIdsArray, canSelect, company, excludedPatients, showHeader, showRemove, showEmail,
      fetchLocales, getSurveys, fetchSurveyQuestions, offset, fromManagePatient, fetchPatientsScroll, totalObjectsKey,
      removeUserFromCompany} = this.props;
    const {anchorEl, arrowRef, patients, searchTerm, loadingPatients, windowHeight, advancedSearchTerms, userToRemove,
      selectedPatientListItem, currentFilter, shouldReload, tableData, totalPatients, addTableColumnModalOpen,
      confirmationModalOpen, isRemovingPatient} = this.state;
    
    const isSurveyQues = tableData.filter(e => e.name === currentFilter?.name)[0]?.surveyQues;
    
    const handlePatientCellClick = (patient) => {
      if(showLink){
        let patientLink = '#';

        if(groupName){
          let groupSlug = groupName.replaceAll(' ', '_');
          patientLink = '/groups/' + groupSlug + '/patients/' + patient.id;
        }
        else{
          patientLink = '/patients/' + patient.id;
        }
        this.props.history.push(patientLink);
      }
      else{
        selectPatient(patient);
      }
    };

    const getPatientListItem = (aPatient, aIndex) => {
      return (
          <PatientListItem key={'patient-list-item' + aPatient.id + '-' + aIndex}
                           style={{marginTop: '10px'}}
                           company={company}
                           patient={aPatient}
                           selected={selectedPatientIdsArray ? selectedPatientIdsArray.includes(aPatient.id) : false}
                           showLink={showLink}
                           canSelect={canSelect}
                           groupName={groupName}
                           showEmail={showEmail}
                           showHeader={showHeader}
                           showRemove={showRemove}
                           selectPatient={selectPatient}
                           menuButtonClicked={this.menuButtonClicked}
                           showFraudStatistics={showFraudStatistics}

      />)
    };
    
    const renderProgressBar = (progress) => {
      const {name} = this.state.currentFilter;
      const total = name === 'Status' ? progress.active + progress.pending + progress.rejected : 
                    name === 'Email Validated' ? progress.verified + progress.unverified :
                    isSurveyQues ? progress.matched + progress.unmatched : progress.complete + progress.incomplete;

      const activeProgress = progress.active / total;
      const pendingProgress = progress.pending / total;
      const rejectedProgress = progress.rejected / total;
      const matchedProgress = progress.matched / total;
      const verifiedProgress = progress.verified / total;
      const unverifiedProgress = progress.unverified / total;
      const incompleteProgress = progress.incomplete / total;
      const unmatchedProgress = progress.unmatched / total;
      const completeProgress = progress.complete / total;
      
      return(
        name === 'Status' ?
          <div style={{width:'100%'}}>
            <span style={{width: `${activeProgress * 100}%`, backgroundColor: '#66b033', height: '10px', display: 'inline-block'}}/>
            <span style={{width: `${pendingProgress * 100}%`, backgroundColor: '#f89117', height: '10px', display: 'inline-block'}}/>
            <span style={{width: `${rejectedProgress * 100}%`, backgroundColor: '#e02020', height: '10px', display: 'inline-block'}}/>
          </div> :
          <>
            <span
              style={{
                height: '10px',
                display: 'inline-block',
                backgroundColor: '#2d81c9',
                width: `${(name === 'Email Validated' ? verifiedProgress : isSurveyQues ? matchedProgress : completeProgress) * 100}%`,
              }}/>
            <span
              style={{
                height: '10px',
                display: 'inline-block',
                backgroundColor: '#e02020',
                width: `${(name === 'Email Validated' ? unverifiedProgress : isSurveyQues ? unmatchedProgress : incompleteProgress) * 100}%`,
              }}/>
          </>
      )
    };
    
    const renderBreakdownTags = (aKey, aValue) => {
      let backgroundColour = '#2d81c9';
      let colours = {Active:'#66b033', Pending:'#f89117', Rejected:'#e02020', Incomplete:'#e02020', Unmatched:'#e02020', Unverified:'#e02020'};
      
      if(colours[aKey] !== undefined){
        backgroundColour = colours[aKey];
      }
      return(
        <>
          <span className='progress-bar-legend-circle'
                style={{backgroundColor:backgroundColour}}
          />
          
          <span className='progress-breakdown-count'>
            {i18next.t(aKey.toUpperCase())}: {aValue}
          </span>
        </>
      )
    };

    let searchBarPlaceHolderKey = 'SEARCH_PATIENT';
    
    if(this.isFilteringBySearchTerms()){
      searchBarPlaceHolderKey = 'PREFERENCE_FILTER_ENABLED';
    }

    let availableHeight = windowHeight - 275 + offset;
    let popperStyle = {maxHeight: availableHeight};
    
    let filteredSurveysArray = tableData.filter(e => e.name === currentFilter.name)[0];
    let survey_data = filteredSurveysArray && filteredSurveysArray.length > 0 ? filteredSurveysArray[0] : {};
    
    let columnsArray = [];
    columnsArray.push({key:'code', columnWeight:2.5, columnNameKey:'PROFILE', templateCell:(aObject) => {
        return (
          <div>
            <div>
              {aObject.first_name}  {aObject.last_name}
            </div>
            
            <div>
              {aObject.email}
            </div>
            
            <div>
              {aObject.site_id ? `${aObject.site_id} / ` : ''} {aObject.edc_id ?? ''}
            </div>
          </div>
        );
      }});
    
    for(let index = 1; index < tableData.length; index++){
      if(tableData[index].name === 'Email Validated'){
        columnsArray.push({key:'email_verified', columnWeight:2, columnNameKey:'EMAIL_VALIDATED', templateCell:(aObject) => this.renderPatientEmailStatus(aObject)});
      }
      else if(tableData[index].name === 'Status'){
        columnsArray.push({key:'invite_status', columnWeight:1, columnNameKey:'STATUS', propertiesArray:['invite_status'], templateCell:(aObject) => this.renderPatientStatus(aObject)});
      }
      else{
        const columnName = tableData[index].name;
        columnsArray.push({key:columnName, columnWeight:columnName.length > 15 ? 2 : 1, columnNameKey:tableData[index].name, templateCell:(aPatient) => this.renderPatientSurveyStatus(aPatient, tableData[index], index)});
      }
    }
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'DELETE', clickAction:(aPatient) => this.setState({confirmationModalOpen:true, selectedPatientListItem:aPatient}), isValid:(aPatient) => aPatient && aPatient.invite_status === INVITE_STATUS.ACTIVE});
    menuItemsArray.push({title:'RESEND', clickAction:(aPatient) => this.setState({selectedPatientListItem:aPatient}, () => this.resendInvitationToUser()),
      isValid:(aPatient) => aPatient && aPatient.invite_status === INVITE_STATUS.PENDING});
    
    return (
      <div className="patient-list">
        <GeneralModal isOpen={addTableColumnModalOpen}
                      showHeader={false}
                      showFooter={false}
                      width={550}
                      maxWidth={550}
                      showCancelButton={false}
                      handleCloseModal={() => this.setState({addTableColumnModalOpen:false})}
                      clickOutsideToClose={true}>
          <AddEditTableColumns hide={() => this.setState({addTableColumnModalOpen:false})}
                               company={company}
                               tableList={tableData}
                               getSurveys={getSurveys}
                               fetchLocales={fetchLocales}
                               changeTableData={(params) => {
                                 params['shouldReload'] = true;
                                 this.setState(params);
                               }}
                               fetchSurveyQuestions={fetchSurveyQuestions}
          />
        </GeneralModal>
        
        <div className="patient-list-container">
          {!fromManagePatient ?
            <div className="patient-list-title-cell">
              <div id='patient-list-total-patients'>
                <Translation>{ (t, { i18n }) => t('TOTAL_PATIENTS') }</Translation> - {totalPatients}
              </div>
              
              <LabeledDelayedInput className="patient-list-input-small"
                                   name={groupName}
                                   value={searchTerm}
                                   onClick={showAdvancedSearch ? this.handlePopperOnSearchClick : null}
                                   minLength={0}
                                   handleSave={this.handleSearchPatient}
                                   placeholder={i18next.t(searchBarPlaceHolderKey)}
                                   showSearchIcon={true}
                                   ref={this.patientSearchInputRef}
              />
            </div>
            :
            null
          }
  
          {!canSelect && showHeader ?
            <div className="patient-group-search-results-list">
              <div className="patient-list-header">
                <div className="patient-list-header-cell-large">
                  <Translation>{(t, {i18n}) => t('NAME')}</Translation>
                </div>
        
                <div className="patient-list-header-cell-small-dual">
                  <div>
                    <Translation>{(t, {i18n}) => t('LAST_USED')}</Translation>
                  </div>
          
                  <div>
                    <Translation>{(t, {i18n}) => t('ALERTS')}</Translation>
                  </div>
                </div>
        
                <div className="patient-list-header-cell-small">
                  <Translation>{(t, {i18n}) => t('FRAUD_EVENTS_SPAM_SCORE')}</Translation>
                </div>
              </div>
            </div>
            :
            null
          }
  
          <div className={fromManagePatient ? "manage-patients-dynamic-list-container" : "patient-list-cell-container"}
               style={{height:height ? height : '100vh - 275px', marginTop:0}}
               id='patients-list'>
          {fromManagePatient ?
              <DynamicList id="patient-list"
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={(aPatientListItem) => this.setState({selectedPatientListItem:aPatientListItem})}
                           selectCell={(aPatient) => aPatient && aPatient.invite_status === INVITE_STATUS.ACTIVE && handlePatientCellClick(aPatient)}
                           canSortList={true}
                           extraObject={(aObject) => this.setState({progress_break_down:aObject})}
                           columnsArray={columnsArray}
                           shouldReload={shouldReload}
                           objectToDelete={userToRemove}
                           finishedDeletingObject={() => this.setState({userToRemove:null})}
                           fetchObjects={(aLimit, aOffset, aSearchString, aSelectedColumn, aIsAscending) => {
                             let name = '';
    
                             if(aSelectedColumn < tableData.length){
                               name = tableData[aSelectedColumn]['name'];
                             }
                             this.setState({currentFilter:{name:name, asc:aIsAscending}});
                             return fetchPatientsScroll(aSearchString, null, aLimit, aOffset,
                               name === 'Status' ? 'invite_status' : name === 'Email Validated' ? 'email_verified' : null,
                               aIsAscending,
                               advancedSearchTerms && advancedSearchTerms.preferred_language && advancedSearchTerms.preferred_language.length > 0 ? advancedSearchTerms.preferred_language : null,
                               advancedSearchTerms && advancedSearchTerms.alert_type && advancedSearchTerms.alert_type.length > 0 ? advancedSearchTerms.alert_type : null,
                               advancedSearchTerms && advancedSearchTerms.virtual_appointment_date,
                               advancedSearchTerms && advancedSearchTerms.disposition_code && advancedSearchTerms.disposition_code.length > 0 ? advancedSearchTerms.disposition_code : null,
                               advancedSearchTerms && advancedSearchTerms.search_by_id,
                               !survey_data.surveyQues && survey_data.id ? parseInt(survey_data.id) : null,
                               survey_data.surveyQues || null,
                               this.state.dynamicFields,
                               survey_data.surveyAns,
                               this.props.fromManagePatient,
                               this.props.groupIdsToExclude);
                           }}
                           subHeaderView={
                             currentFilter.name !== 'Profile' ?
                               <div className="progress-breakdown">
                                 <div className='progress-breakdown-title'>
                                   {i18next.t('PATIENT_BREAKDOWN', {aColumnName:currentFilter.name})}
                                 </div>
        
                                 <div>
                                   {renderProgressBar(this.state.progress_break_down)}
                                 </div>
        
                                 <div className="progress-bar-legend">
                                   {currentFilter.name === 'Status' ?
                                     <>
                                       {renderBreakdownTags('Active', this.state.progress_break_down.active)}
                                       {renderBreakdownTags('Pending', this.state.progress_break_down.pending)}
                                       {renderBreakdownTags('Rejected', this.state.progress_break_down.rejected)}
                                     </>
                                     :
                                     this.state.currentFilter.name === 'Email Validated' ?
                                       <>
                                         {renderBreakdownTags('Verified', this.state.progress_break_down.verified)}
                                         {renderBreakdownTags('Unverified', this.state.progress_break_down.unverified)}
                                       </>
                                       : isSurveyQues ?
                                       <>
                                         {renderBreakdownTags('Matched', this.state.progress_break_down.matched)}
                                         {renderBreakdownTags('Unmatched', this.state.progress_break_down.unmatched)}
                                       </>
                                       :
                                       <>
                                         {renderBreakdownTags('Complete', this.state.progress_break_down.complete)}
                                         {renderBreakdownTags('Incomplete', this.state.progress_break_down.incomplete)}
                                       </>
                                   }
                                 </div>
                               </div>
                               :
                               null
                           }
                           showSearchBar={true}
                           menuItemsArray={menuItemsArray}
                           minColumnWidth={100}
                           clampFirstColumn={true}
                           addColumnButtonClicked={() => this.setState({addTableColumnModalOpen:true})}
                           responseTotalKeysArray={['data', 'data', 'user_page', 'total']}
                           responseObjectKeysArray={['data', 'data', 'user_page', 'users']}
                           responseExtraObjectKeysArray={['data', 'data', 'user_page', 'progress_break_down']}
                           searchBarPlaceHolderKey={searchBarPlaceHolderKey}
                           showBorder={true}
                           totalObjectsKey={totalObjectsKey}
              />
              :
              <InfiniteScroll style={{overflowY:"hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="patients-list">
                {patients && patients.length > 0 ?
                  <>
                    {patients.map((patient, index) => (
                      excludedPatients ?
                        (
                          !excludedPatients.some(exclusion => exclusion.id === patient.id) ?
                            getPatientListItem(patient, index)
                            :
                            null
                        )
                        :
                        getPatientListItem(patient, index)
                    ))}
                  </>
                  :
                  !loadingPatients ?
                    <div className="no-information-text">
                      <Translation>{ (t, { i18n }) => t('NO_PATIENTS_FOUND')}</Translation>
                    </div>
                    :
                    null
                }
              </InfiniteScroll>
            }
          </div>
  
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:formatUserName(selectedPatientListItem)})}
                             isOpen={confirmationModalOpen}
                             reject={() => this.setState({confirmationModalOpen:false, selectedPatientListItem:null})}
                             confirm={() => this.setState({isRemovingPatient:true}, () =>
                               removeUserFromCompany(this.state.selectedPatientListItem.id).then((newResult) => {
                                 const {limit, selectedPatientListItem} = this.state;
                                 this.closeMenu();
                                 this.notifyPatientRemoveSuccess();
                                 this.scrollManager.reloadWithLimit(limit);
                                 this.setState({userToRemove:selectedPatientListItem, isRemovingPatient:false, confirmationModalOpen:false, selectedPatientListItem:null});
                               }, (newError) => {
                                 this.setState({userToRemove:selectedPatientListItem, isRemovingPatient:false, confirmationModalOpen:false, selectedPatientListItem:null});
                               }))}
                             loading={isRemovingPatient}
          />
          
          <Popper className='patient-search-popper'
                  style={popperStyle}
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={anchorEl}
                  placement={'bottom'}
                  modifiers={{arrow:{element:arrowRef, enabled:true}}}>
            {this.state.anchorEl && (<span ref={this.setArrowRef} className={'arrow'}/>)}
            
            <PatientSearchForm onClosePressed={this.handlePopperClose}
                               onSubmitPressed={this.advancedSearch}
                               user={user}
                               searchTerms={this.state.advancedSearchTerms}
            />
          </Popper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(){
  return{};
}
export default connect (mapStateToProps, {fetchPatientsScroll}) (withTranslation()(withRouter(PatientList)))
