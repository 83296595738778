import React from "react";
import Slider from "rc-slider/lib/Slider";
import {Translation} from "react-i18next";

export default class SurveySliderQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:null,
      hidden:false,
      hideSlider:false
    };
    if(props.answer !== null && props.answer.value !== null){
      this.state.answer = props.answer.value;
    }
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }
  
  // Methods

  handleSliderChange(value){
    this.props.onAnswerChange(this.props.question, value.toString());
    this.setState({answer: value.toString()});
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer} = this.state;
    const {key, type} = question;
    
    const maxLabel = question.max_label;
    const minLabel = question.min_label;
    let maxValue = parseInt(question.max_value);
    let minValue = parseInt(question.min_value);
  
    if(maxValue === null || maxValue === undefined || isNaN(maxValue)){
      maxValue = 5;
      console.log('SurveySliderQuestionCell (render): No maxValue set.');
    }
    if(minValue === null || minValue === undefined || isNaN(minValue)){
      minValue = 1;
      console.log('SurveySliderQuestionCell (render): No minValue set.');
    }
    
    let marks = {};
    let noMaxLabel = false;
    let instructionTextKey = 'SURVEY_SINGLE_SLIDER_INSTRUCTION_TEXT';
    
    if(maxLabel === null || maxLabel === undefined || maxLabel.length === 0){
      marks[maxValue] = maxValue.toString();
      noMaxLabel = true;
    }
    else{
      marks[maxValue] = maxValue + ' (' + maxLabel + ')';
    }
    if(minLabel === null || minLabel === undefined || minLabel.length === 0){
      marks[minValue] = minValue.toString();
      
      if(noMaxLabel){
        instructionTextKey = 'SURVEY_SINGLE_SLIDER_INSTRUCTION_TEXT_NO_LABELS';
      }
    }
    else{
      marks[minValue] = minValue + ' (' + minLabel + ')';
    }

    return (
      <div key={'survey-' + type + '-question-cell-' + key}>
        <hr />
        
        <em>
          <Translation>{ (t, { i18n }) => t(instructionTextKey, {minValue:minValue, minLabel:minLabel, maxValue:maxValue, maxLabel:maxLabel})}</Translation>
        </em>
        
        <hr />
        
        <div className='slider-with-bottom-value'>
          <Slider className='entry-slider'
                  min={minValue}
                  max={maxValue}
                  step={1}
                  marks={marks}
                  value={answer ? Number(answer) : null}
                  disabled={disabled}
                  onChange={this.handleSliderChange}
          />
          
          <span>
            {/*TODO: Add Translation.*/}
            <strong>
              {answer || "Choose"}
            </strong>
          </span>
        </div>
      </div>
    )
  }
}
