import React from "react";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import './SurveyDynamicListQuestionCell.scss'

export default class SurveyDynamicListQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:null,
      selectedValue:null
    };
    if(props.answer !== null && props.answer.value !== null ){
      this.state.answer = props.answer.value;
    }
    this.handleChange = this.handleChange.bind(this);
  }
  
  // Methods
  
  handleChange(aOption){
    const {question, onAnswerChange} = this.props;
    
    let answer = aOption.description;
    this.setState({answer:answer, selectedValue:aOption});
    onAnswerChange(question, answer);
  }
  
  // Render
  
  render(){
    const {disabled, question, getDynamicListItems} = this.props;
    const {selectedValue} = this.state;
    const {key, type} = question;
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <div key={'SurveyDynamicListQuestionCell' + question.key}
             className='survey-dynamic-list-question-cell'>
          <LabeledDropDown value={selectedValue}
                           disabled={disabled}
                           handleSave={(aOption) => this.handleChange(aOption)}
                           fetchObjects={(aLimit, aOffset, aSearchString) => getDynamicListItems(parseInt(this.props.question.dynamic_list_id), aLimit, aOffset, aSearchString)}
                           menuPosition={'portal'}
                           menuPlacement={'bottom'}
                           labelPropertiesArray={['description']}
                           valuePropertiesArray={['description']}
                           responseTotalKeysArray={['data', 'data', 'dynamic_list_items_page', 'total']}
                           responseObjectKeysArray={['data', 'data', 'dynamic_list_items_page', 'dynamic_list_items']}
          />
        </div>
      </div>
    )
  }
}
