import React from 'react';
import ToolPreviewModal from '../../Modals/ToolPreviewModal/ToolPreviewModal'
import PanelSubSectionHeader from '../../Custom UI/Headers/PanelSubSectionHeader/PanelSubSectionHeader'
import {Table} from 'semantic-ui-react'
import ToolSurveyIconTitle from '../../Custom UI/ToolSurveyIconTitle/ToolSurveyIconTitle'
import {filter, orderBy} from 'lodash'
import AssignNewToolSurveyDropDown from '../../Custom UI/DropDowns/AssignNewToolSurveyDropDown/AssignNewToolSurveyDropDown'
import PerfectScollBar from 'react-perfect-scrollbar'
import {getSectionTitle} from 'utils/Titles'
import '../../Custom UI/common.scss'
import {Translation, withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../../i18n";
import {Switch} from 'antd'
import {BiDotsVerticalRounded} from "react-icons/bi";
import '../../Pages/GroupSummary/GroupSummary.scss';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PanelSubSectionHeadingWithButton from '../../Components/PatientProfileComponents/PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton';
import PatientPanelSubSectionHeadingWithButton
  from "../../Components/PatientProfileComponents/PatientPanelSubSectionHeadingWithButton/PatientPanelSubSectionHeadingWithButton";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {momentFromDateTime, momentFromUTCDate} from "../../Helpers/DateHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import { surveyFilterOptionsArray } from 'constants/SurveyFilterOptions';

const notifyGroupSettingOverwrite = () => toast(<Translation>{(t, {i18n}) => t('NOTIFY_GROUP_SETTING_OVERWRITE')}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifySurveyFilterModification = (aToolType, aIsSuccess) => {
  const successNotificationKeys = {
    [surveyFilterOptionsArray.ECONSENTS]: 'ECONSENT_REMOVED_SUCCESS',
    [surveyFilterOptionsArray.QUESTIONNAIRES]: 'QUESTIONNAIRE_REMOVED_SUCCESS',
    [surveyFilterOptionsArray.TOOLS]: 'TOOL_REMOVED_SUCCESS'
  }
  const failureNotificationKeys = {
    [surveyFilterOptionsArray.ECONSENTS]: 'ECONSENT_REMOVED_FAILURE',
    [surveyFilterOptionsArray.QUESTIONNAIRES]: 'QUESTIONNAIRE_REMOVED_FAILURE',
    [surveyFilterOptionsArray.TOOLS]: 'TOOL_REMOVED_FAILURE'
  }
  const notificationKey = aIsSuccess ? successNotificationKeys[aToolType] : failureNotificationKeys[aToolType];

  if(notificationKey){
    toast(<Translation>{(t, {i18n}) => t(notificationKey)}</Translation>, NOTIFY_OPTS.default)
  }
}

const HS_FEATURE_TRANSLATION_KEYS = {
  HumanAPIEMR: 'HUMAN_API_EMR',
  SyncaDevice: 'SYNC_A_DEVICE',
  AppleHealth: 'APPLE_HEALTH'
}

const HS_FEATURE_ICONS = {
  HumanAPIEMR: 'PlusSquare',
  SyncaDevice: 'Sync',
  AppleHealth: 'Apple'
}

class GroupSummaryToolSelection extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      quests: (props.group && props.group.quests) || [],
      selectedTool:null,
      selectedSurvey:null,
      selectedFeature:null,
      selectedEConsent:null,
      toolsToAddToGroup:[],
      openChainSurveyModal:false,
      openPreviewToolModal:null,
      toolMenuAnchorElement:null,
      surveyMenuAnchorElement:null,
      featureMenuAnchorElement:null,
      openAddGroupToolDropDown:false,
      toolConfirmationModalOpen:false,
      eConsentsMenuAnchorElement:null,
      openAddGroupSurveyDropDown:false,
      openAddGroupEConsentDropDown:false,
      eConsentConfirmationModalOpen:false,
      adminOnlySurveyMenuAnchorElement:null,
      toggleEMRConfirmation:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleEMRIntegration = this.toggleEMRIntegration.bind(this);
    this.featureSwitchOnChange = this.featureSwitchOnChange.bind(this);
    this.toolMenuButtonClicked = this.toolMenuButtonClicked.bind(this);
    this.surveyMenuButtonClicked = this.surveyMenuButtonClicked.bind(this);
    this.featureMenuButtonClicked = this.featureMenuButtonClicked.bind(this);
    this.eConsentsMenuButtonClicked = this.eConsentsMenuButtonClicked.bind(this);
    this.adminOnlysurveyMenuButtonClicked = this.adminOnlysurveyMenuButtonClicked.bind(this);
  }

  componentDidUpdate(prevProps) {
    const prevQuests = (prevProps.group && prevProps.group.quests) || [];
    const updatedQuests = (this.props.group && this.props.group.quests) || [];

    if(prevQuests.length !== updatedQuests.length){
      this.setState({quests: updatedQuests});
    }
  }

  toggleEMRIntegration(aFeature, aGroupId){
    this.setState({toggleEMRConfirmation:false, selectedFeature:null});
    this.props.updateFeatureToGroup(aGroupId, aFeature.id, !aFeature.enabled);
    notifyGroupSettingOverwrite();
  }

  featureSwitchOnChange(aChecked, aEvent, aFeature){
    const {group, updateFeatureToGroup} = this.props;
    
    if(aFeature.keyword === 'HumanAPIEMR'){
      return this.setState({toggleEMRConfirmation:true, selectedFeature:aFeature});
    }
    else{
      notifyGroupSettingOverwrite();
    }
    updateFeatureToGroup(group.id, aFeature.id, aChecked);
  }
  
  closeMenu(){
    this.setState({toolMenuAnchorElement:null, selectedTool:null});
    this.setState({surveyMenuAnchorElement:null, selectedSurvey:null});
    this.setState({adminOnlySurveyMenuAnchorElement:null, selectedSurvey:null});
    this.setState({eConsentMenuAnchorElement:null, selectedEConsent:null});
    this.setState({featureMenuAnchorElement:null, selectedFeature:null})
  }
  
  toolMenuButtonClicked(aEvent, aTool){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({toolMenuAnchorElement:aEvent.currentTarget, selectedTool:aTool});
  };
  
  surveyMenuButtonClicked(aEvent, aSurvey){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({surveyMenuAnchorElement:aEvent.currentTarget, selectedSurvey:aSurvey});
  };
  
  adminOnlysurveyMenuButtonClicked(aEvent, aSurvey){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({adminOnlySurveyMenuAnchorElement:aEvent.currentTarget, selectedSurvey:aSurvey});
  };
  
  eConsentsMenuButtonClicked(aEvent, aConsent){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({eConsentMenuAnchorElement:aEvent.currentTarget, selectedEConsent:aConsent});
  }
  
  featureMenuButtonClicked(aEvent, aFeature){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({featureMenuAnchorElement:aEvent.currentTarget, selectedFeature:aFeature});
  }
  
  removeToolFromGroup(questId, groupId){
    const { removeToolFromGroup , toolType } = this.props;
    const questToRemove = this.state.quests.find(quest => quest.id === questId);
    const filteredQuests = this.state.quests.filter(quest => quest.id !== questId);
    this.setState({quests: filteredQuests});
    
    removeToolFromGroup({
      questId:questId,
      groupId:groupId
    })
    .then(() => {
      notifySurveyFilterModification(toolType, true);
    }).catch((error) => {
      notifySurveyFilterModification(toolType, false);
      this.setState({quests: [...filteredQuests, questToRemove]});
    });
    
    this.setState({toolConfirmationModalOpen:false, eConsentConfirmationModalOpen:false});
    this.closeMenu();
  }
  
  render() {
    const {group, company, groupColor, addToolsToGroup, fetchCompanyAndPatients, t, toolDropDown,
      setToolDropDown, toolType, fetchQuests, getQuestById} = this.props;
    const {selectedTool, selectedEConsent, openPreviewToolModal, toolConfirmationModalOpen,
      eConsentConfirmationModalOpen, quests, toggleEMRConfirmation, selectedFeature} = this.state;
    
    const groupConsentString = t('GROUP_ECONSENT', {i18n});
  
    const addPermissions = ["admin", "admin_level_4"];
    const assignedToolsPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const assignedConsentPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_5", "admin_level_10"];
    const assignedSurveysPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const assignedFeaturesPermissions = ["admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const assignedSurveyDeletionPermissions = ["admin", "admin_level_4"];
    
    let pureTools = filter(quests, (quest) => quest.quest_type === 'Tool');
    let surveyTools = orderBy(filter(quests, (quest) => quest.quest_type === 'Survey'), ['title'], ['asc']);
    let patientSurveyTools = orderBy(filter(surveyTools, (quest) => quest.admin_only === false), ['title'], ['asc']);
    let adminOnlySurveyTools = orderBy(filter(surveyTools, (quest) => quest.admin_only === true), ['title'], ['asc']);
    let eConsentTools = orderBy(filter(quests, (quest) => quest.quest_type === 'Consent'), ['title'], ['asc']);
    let features = group && group.hs_features;
    const currentRole = roleForCompany(company);
    let showFeatures = (!toolType || toolType === 'Features');
    let showSurvey = (!toolType || toolType === 'Survey');
    let showTools = (!toolType || toolType === 'Tools');
    let showConsent = (!toolType || toolType === 'Consent');
    
    let patientSurveyToolsSectionHeader = t('PATIENT_ONLY_GROUP_QUESTIONNAIRES');
    let adminOnlySurveyToolsSectionHeader = t('ADMIN_ONLY_GROUP_QUESTIONNAIRES');
    
    // TODO: Refactor this with Dynamic Lists. Also, separate each section into it's own file.
    
    return (
      <div className='tool-search-plus-tool-list'>
        {showFeatures ? (
            <div>
              <div style={{marginLeft:20}} className="manage-patient-container-text">
                <Translation>{(t, {i18n}) => t('FEATURES_EXPLANATION')}</Translation>
              </div>
              {assignedFeaturesPermissions.includes(currentRole) ?
                (<div>
                  <PatientPanelSubSectionHeadingWithButton heading={getSectionTitle(i18next.t('GROUP_FEATURES'))}/>
                  
                  <PerfectScollBar>
                    <div className='scroll-table-outer-container'>
                      <Table basic='very' singleLine unstackable selectable className='table-no-border'>
                        <Table.Header>
                          <Table.Row className='header-row-table'>
                            <Table.HeaderCell width={2}><Translation>{(t, {i18n}) => t('FEATURE_NAME')}</Translation></Table.HeaderCell>
                            <Table.HeaderCell width={1}><Translation>{(t, {i18n}) => t('ENABLED')}</Translation></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        
                        {features && features.length > 0 &&
                        (<Table.Body>
                          {features.map((feature) => (
                            <Table.Row key={feature.id} className="table-body-row">
                              <Table.Cell className="title-container">
                                <ToolSurveyIconTitle toolTitle={<Translation>{ (t, { i18n }) => t(HS_FEATURE_TRANSLATION_KEYS[feature.keyword]) }</Translation>} faIcon={HS_FEATURE_ICONS[feature.keyword]} />
                              </Table.Cell>
                              <Table.Cell>
                                <div className="group-tools-end-cell">
                                  <Switch name={feature.id}
                                          checked={feature.enabled}
                                          onChange={(aChecked, aEvent) => this.featureSwitchOnChange(aChecked, aEvent, feature)}
                                          disabled={!addPermissions.includes(currentRole)}
                                          checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                          unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                                  />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>)
                        }

                        {toggleEMRConfirmation ? (
                          <ConfirmationModal title={i18next.t(selectedFeature.enabled ? 'DISABLE_EMR_CONFIRMATION': 'ENABLE_EMR_CONFIRMATION')}
                            isOpen={toggleEMRConfirmation}
                            width={422}
                            minHeight={150}
                            reject={() => this.setState({toggleEMRConfirmation:false, selectedFeature:null})}
                            confirm={() => this.toggleEMRIntegration(selectedFeature, group.id)}
                          />
                          ) 
                          : null}
                      </Table>
                    </div>
                  </PerfectScollBar>
                </div>)
                :
                <PanelSubSectionHeader
                  groupColor={groupColor}
                  title={getSectionTitle(i18next.t('GROUP_FEATURES_BLOCKED'))}
                />
              }
            
            </div>
          )
          :null}
        
        {showConsent ? (
            <div>
              <div style={{marginLeft:20}} className="manage-patient-container-text">
                <Translation>{(t, {i18n}) => t('E_CONSENTS')}</Translation>
              </div>
              {assignedConsentPermissions.includes(currentRole) ? (
                  <div>
                    <div>
                      <PanelSubSectionHeadingWithButton addFunc={
                        (addPermissions.includes(currentRole) ?
                            () => setToolDropDown({
                              openAddGroupEConsentDropDown:(toolDropDown ? !toolDropDown.openAddGroupEConsentDropDown : false),
                              openAddGroupToolDropDown:false,
                              openAddGroupSurveyDropDown:false,
                              openAddGroupSurveySecondDropDown:false
                            })
                            :
                            null
                        )
                      }
                                                        total={eConsentTools.length}
                                                        heading={groupConsentString}
                                                        className="panel-subsection-title"
                      />
                      {
                        toolDropDown && toolDropDown.openAddGroupEConsentDropDown &&
                        <AssignNewToolSurveyDropDown type={'Consent'}
                                                     group={group}
                                                     isOpen={toolDropDown.openAddGroupEConsentDropDown}
                                                     company={company}
                                                     closeDropDown={() => setToolDropDown({...toolDropDown, openAddGroupEConsentDropDown: false})}
                                                     assignedQuests={eConsentTools}
                                                     addToolsToGroup={addToolsToGroup}
                                                     fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                     fetchQuests={fetchQuests}
                        />
                      }
                    </div>
                    <PerfectScollBar>
                      <div className='scroll-table-outer-container'>
                        {eConsentTools.length > 0 ?
                          <Table basic='very' singleLine unstackable selectable className='table-no-border'>
                            <Table.Header>
                              <Table.Row className='header-row-table'>
                                
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('E_CONSENT_NAME')}</Translation>
                                </Table.HeaderCell>
                                
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('DATE_CREATED')}</Translation>
                                </Table.HeaderCell>
                              
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {eConsentTools.map((tool) => (
                                <Table.Row key={tool.id} className="table-body-row multi-line-row">
                                  <Table.Cell className="title-container">
                                    <ToolSurveyIconTitle toolTitle={tool.title} toolIconName={tool.icon} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div  className="group-tools-end-cell">
                                      {momentFromUTCDate(tool.assigned_to_group_on)}
                                      <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                                             onClick={(aEvent) => this.eConsentsMenuButtonClicked(aEvent, tool)}
                                      />
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                            
                            <Menu id="simple-menu"
                                  open={Boolean(this.state.eConsentMenuAnchorElement)}
                                  onClose={() => this.closeMenu()}
                                  anchorEl={this.state.eConsentMenuAnchorElement}
                                  keepMounted>
                              
                              <MenuItem style={{position:'relative'}}
                                        onClick={() => {this.setState({eConsentConfirmationModalOpen:true})}}>
                                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                              </MenuItem>
                            </Menu>
  
                            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedEConsent ? selectedEConsent.title : '')})}
                                               isOpen={eConsentConfirmationModalOpen}
                                               reject={() => this.setState({eConsentConfirmationModalOpen:false})}
                                               confirm={() => this.removeToolFromGroup(selectedEConsent.id, group.id)}
                            />
                          </Table>
                          :
                          <div className="user-prompt-msg subsection-container">
                            <Translation>{(t, {i18n}) => t('NO_GROUP_CONSENT_ASSIGNED')}</Translation>
                          </div>
                        }
                      </div>
                    </PerfectScollBar>
                  </div>)
                :
                <PanelSubSectionHeader
                  groupColor={groupColor}
                  title={getSectionTitle(i18next.t('GROUP_ECONSENT_BLOCKED'))}
                />
              }
            </div>
          )
          :null}
        
        {showSurvey ? (
            <div>
              <div style={{marginLeft:20}} className="manage-patient-container-text">
                <Translation>{(t, {i18n}) => t('QUESTIONNAIRES_EXPLANATION')}</Translation>
              </div>
              {assignedSurveysPermissions.includes(currentRole) ? (
                  <div>
                    <div>
                      <PanelSubSectionHeadingWithButton addFunc={
                        (addPermissions.includes(currentRole) ?
                            () => setToolDropDown({
                              openAddGroupSurveyDropDown:(toolDropDown ? !toolDropDown.openAddGroupSurveyDropDown : false),
                              openAddGroupToolDropDown:false,
                              openAddGroupEConsentDropDown:false,
                              openAddGroupSurveySecondDropDown:false
                            })
                            :
                            null
                        )
                      }
                                                        total={patientSurveyTools.length}
                                                        heading={patientSurveyToolsSectionHeader}
                                                        className="panel-subsection-title"
                      />
                      
                      {toolDropDown && toolDropDown.openAddGroupSurveyDropDown &&
                      <AssignNewToolSurveyDropDown type={'Survey'}
                                                   group={group}
                                                   isOpen={toolDropDown.openAddGroupSurveyDropDown}
                                                   company={company}
                                                   closeDropDown={() => setToolDropDown({...toolDropDown, openAddGroupSurveyDropDown:false})}
                                                   assignedQuests={surveyTools}
                                                   addToolsToGroup={addToolsToGroup}
                                                   fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                   fetchQuests={fetchQuests}
                      />
                      }
                    </div>
                    <PerfectScollBar>
                      <div className='scroll-table-outer-container'>
                        {patientSurveyTools.length > 0 ? <Table basic='very' singleLine unstackable selectable className='table-no-border'>
                            <Table.Header>
                              <Table.Row className='header-row-table'>
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('QUESTIONNAIRE_NAME')}</Translation>
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('DATE_CREATED')}</Translation>
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {patientSurveyTools.map((tool) => (
                                <Table.Row key={tool.id} className="table-body-row multi-line-row">
                                  <Table.Cell className="title-container">
                                    <ToolSurveyIconTitle toolTitle={tool.title} toolIconName={tool.icon} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div className="group-tools-end-cell">
                                      {momentFromUTCDate(tool.assigned_to_group_on)}
                                      {assignedSurveyDeletionPermissions.includes(currentRole) ? (
                                          <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                                                 onClick={(aEvent) => this.surveyMenuButtonClicked(aEvent, tool)}
                                          />
                                        )
                                        :null
                                      }
                                    </div>
                                  </Table.Cell>
                                  
                                </Table.Row>
                              ))}
                            </Table.Body>
                            <Menu id="simple-menu"
                                  open={Boolean(this.state.surveyMenuAnchorElement)}
                                  onClose={() => this.closeMenu()}
                                  anchorEl={this.state.surveyMenuAnchorElement}
                                  className={Boolean(this.state.surveyMenuAnchorElement) ? 'is-open' : ''}
                                  keepMounted>
                              {assignedSurveyDeletionPermissions.includes(currentRole) ? (
                                  <MenuItem style={{position:'relative'}}
                                            className="survey-tool-remove-option"
                                            onClick={() => this.removeToolFromGroup(this.state.selectedSurvey.id, this.props.group.id)}>
                                    <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                                  </MenuItem>
                                )
                                :null
                              }
                            </Menu>
                          </Table>
                          :
                          <div className="user-prompt-msg subsection-container">
                            <Translation>{(t, {i18n}) => t('NO_SURVEYS_ASSIGNED')}</Translation>
                          </div>
                        }
                      </div>
                    </PerfectScollBar>
                    
                    <div>
                      <PanelSubSectionHeadingWithButton addFunc={
                        (addPermissions.includes(currentRole) ?
                            () => setToolDropDown({
                              openAddGroupSurveySecondDropDown:
                                (toolDropDown ? !toolDropDown.openAddGroupSurveySecondDropDown : false),
                              openAddGroupToolDropDown:false,
                              openAddGroupEConsentDropDown:false,
                              openAddGroupSurveyDropDown:false})
                            :
                            null
                        )
                      }
                                                        total={adminOnlySurveyTools.length}
                                                        heading={adminOnlySurveyToolsSectionHeader}
                                                        className="panel-subsection-title"
                      />
                      {
                        toolDropDown && toolDropDown.openAddGroupSurveySecondDropDown &&
                        <AssignNewToolSurveyDropDown type={'Survey'}
                                                     group={group}
                                                     isOpen={toolDropDown.openAddGroupSurveySecondDropDown}
                                                     company={company}
                                                     closeDropDown={() => setToolDropDown({...toolDropDown, openAddGroupSurveySecondDropDown:false})}
                                                     assignedQuests={surveyTools}
                                                     addToolsToGroup={addToolsToGroup}
                                                     fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                     fetchQuests={fetchQuests}
                        />
                      }
                    </div>
                    <PerfectScollBar>
                      <div className='scroll-table-outer-container'>
                        {adminOnlySurveyTools.length > 0 ?
                          <Table basic='very' singleLine unstackable selectable className='table-no-border'>
                            <Table.Header>
                              <Table.Row className='header-row-table'>
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('QUESTIONNAIRE_NAME')}</Translation>
                                </Table.HeaderCell>
                                
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('DATE_CREATED')}</Translation>
                                </Table.HeaderCell>
                              
                              </Table.Row>
                            </Table.Header>
                            
                            <Table.Body>
                              {adminOnlySurveyTools.map((tool) => (
                                <Table.Row key={tool.id} className="table-body-row multi-line-row">
                                  <Table.Cell className="title-container">
                                    <ToolSurveyIconTitle toolTitle={tool.title} toolIconName={tool.icon} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div className="group-tools-end-cell">
                                      {momentFromUTCDate(tool.assigned_to_group_on)}
                                      {assignedSurveyDeletionPermissions.includes(currentRole) ? (
                                          <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                                                 onClick={(aEvent) => this.adminOnlysurveyMenuButtonClicked(aEvent, tool)}
                                          />
                                        )
                                        :null
                                      }
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                            
                            <Menu id="simple-menu"
                                  open={Boolean(this.state.adminOnlySurveyMenuAnchorElement)}
                                  onClose={() => this.closeMenu()}
                                  anchorEl={this.state.adminOnlySurveyMenuAnchorElement}
                                  keepMounted>
                              {assignedSurveyDeletionPermissions.includes(currentRole) ? (
                                  <MenuItem style={{position:'relative'}} onClick={() => this.removeToolFromGroup(this.state.selectedSurvey.id, this.props.group.id)}>
                                    <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                                  </MenuItem>
                                )
                                :null
                              }
                            </Menu>
                          </Table>
                          :
                          <div className="user-prompt-msg subsection-container">
                            <Translation>{(t, {i18n}) => t('NO_SURVEYS_ASSIGNED')}</Translation>
                          </div>
                        }
                      </div>
                    </PerfectScollBar>
                  </div>
                ) :
                <PanelSubSectionHeader
                  groupColor={groupColor}
                  title={getSectionTitle(i18next.t('GROUP_QUESTIONNAIRES_BLOCKED'))}
                />}
            </div>
          )
          :null}
        
        {showTools ? (
            <div>
              <div style={{marginLeft:20}} className="manage-patient-container-text">
                <Translation>{(t, {i18n}) => t('AVAILABLE_TOOLS_EXPLANATION')}</Translation>
              </div>
              {assignedToolsPermissions.includes(currentRole) ? (
                  <div className='tool-search-container'>
                    <PanelSubSectionHeadingWithButton addFunc={
                      (addPermissions.includes(currentRole) ?
                          () => {
                            setToolDropDown({
                              openAddGroupToolDropDown:(toolDropDown ? !toolDropDown.openAddGroupToolDropDown : false),
                              openAddGroupSurveyDropDown:false,
                              openAddGroupEConsentDropDown:false,
                              openAddGroupSurveySecondDropDown:false
                            })
                          }
                          :
                          null
                      )
                    }
                                                      total={pureTools.length}
                                                      heading={i18n.t("GROUP_TOOLS")}
                                                      className="panel-subsection-title"
                    />
                    
                    {toolDropDown && toolDropDown.openAddGroupToolDropDown &&
                    <AssignNewToolSurveyDropDown type={'Tool'}
                                                 group={group}
                                                 isOpen={toolDropDown.openAddGroupToolDropDown}
                                                 company={company}
                                                 closeDropDown={() => setToolDropDown({...toolDropDown, openAddGroupToolDropDown:false})}
                                                 assignedQuests={pureTools}
                                                 addToolsToGroup={addToolsToGroup}
                                                 fetchCompanyAndPatients={fetchCompanyAndPatients}
                                                 fetchQuests={fetchQuests}
                    />
                    }
                    <PerfectScollBar>
                      <div className='scroll-table-outer-container'>
                        {pureTools.length > 0 ? <Table basic='very' singleLine unstackable selectable className='table-no-border'>
                            <Table.Header>
                              <Table.Row className='header-row-table'>
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('TOOL_NAME')}</Translation>
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                  <Translation>{(t, {i18n}) => t('DATE_CREATED')}</Translation>
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {pureTools.map((tool) => (
                                <Table.Row key={tool.id} className="table-body-row multi-line-row">
                                  <Table.Cell className="title-container clickable"
                                              onClick={() => this.setState({openPreviewToolModal:null})}>
                                              {/*TODO: Re-enable once the Tool Preview Modal is fixed.*/}
                                              {/*onClick={() => this.setState({openPreviewToolModal:tool.id})}>*/}
                                    <ToolSurveyIconTitle toolTitle={tool.title}
                                                         toolIconName={tool.icon}
                                    />
                                  </Table.Cell>
                                  
                                  <Table.Cell>
                                    <div className="group-tools-end-cell">
                                      {momentFromUTCDate(tool.assigned_to_group_on)}
                                      <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                                             onClick={(aEvent) => this.toolMenuButtonClicked(aEvent, tool)}
                                      />
                                    </div>
                                  </Table.Cell>
                                  
                                  {openPreviewToolModal === tool.id ?
                                    <ToolPreviewModal tool={tool}
                                                      isOpen={true}
                                                      getQuestById={getQuestById}
                                                      handleCloseModal={() => this.setState({openPreviewToolModal:null})}
                                    />
                                    :
                                    null
                                  }
                                </Table.Row>
                              ))}
                            </Table.Body>
                            
                            <Menu id="simple-menu"
                                  open={Boolean(this.state.toolMenuAnchorElement)}
                                  onClose={() => this.closeMenu()}
                                  anchorEl={this.state.toolMenuAnchorElement}
                                  keepMounted>
                              
                              <MenuItem style={{position: 'relative'}}
                                        onClick={() => {this.setState({toolConfirmationModalOpen:true})}}>
                                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                              </MenuItem>
                            </Menu>
    
                            <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedTool ? selectedTool.title : '')})}
                                               isOpen={toolConfirmationModalOpen}
                                               reject={() => this.setState({toolConfirmationModalOpen:false})}
                                               confirm={() => this.removeToolFromGroup(selectedTool.id, group.id)}
                            />
                          </Table>
                          :
                          <div className="user-prompt-msg subsection-container">
                            <Translation>{(t, {i18n}) => t('NO_TOOLS_ASSIGNED')}</Translation>
                          </div>}
                      </div>
                    </PerfectScollBar>
                  </div>
                )
                :
                <PanelSubSectionHeader title={getSectionTitle(i18next.t('GROUP_TOOLS_BLOCKED'))}
                                       groupColor={groupColor}
                />
              }
            </div>
          )
          :null
        }
      </div>
    )
  }
}

export default withTranslation()(GroupSummaryToolSelection)
