import React from 'react';
import { DEFAULT_COLORS } from '../../../constants/Colors'
import './NextButtons.scss'
import {Button} from "antd";


const NextButton = ({onClickfunc, name}) => (
  <Button className={'clickable next-button ' + (name === "NEXT" ? 'add-medication-next-button' : '')} style={{color: DEFAULT_COLORS.PANEL_HEAD_BG, display: 'inline-flex', alignItems: 'center', zIndex: 3, width: 90, marginLeft: 20, marginRight: 20}} onClick={onClickfunc}>
    <div style={{fontSize: 12}}>{name}</div>
  </Button>
)

export default NextButton;
