import React, {useState} from 'react';
import Select from 'react-virtualized-select';
import './MultipleSelectDropDown.scss'
import './../VirtualizedDropDown/VirtualizedDropDown.scss'
import '../../../Custom UI/common.scss';
import classNames from "classnames";

const MultipleSelectDropDown = ({onChange, options, value, menuPlacement, menu, onMenuOpen, onMenuClose, disabled,
                                  isClearable, onFocus, onBlur, placeholder, errorMessage, warningMessage, menuPortalTarget}) => {
  
  const [optionsArray, setOptionsArray] = useState([]);
  
  const selectFocused = () => {
    setOptionsArray(options);
    
    if(onMenuOpen){
      onMenuOpen();
    }
    if(onFocus){
      onFocus();
    }
  };
  
  const selectBlurred = (aEvent) => {
    if(onBlur){
      onBlur(aEvent);
    }
  };
  
  // TODO: Enable once the package is properly installed.
  const animatedComponents = null;// makeAnimated();
  
  return (
    <Select className={classNames({
      'labeled-dropdown':true,
      'virtualized-multiple-select-dropdown':true,
      'labeled-dropdown-disabled':disabled,
      'labeled-dropdown-input-error':errorMessage,
      'labeled-dropdown-input-warning':warningMessage,
      'labeled-dropdown-empty':!(value && value.length)
    })}
            menu={menu}
            multi={true}
            value={value}
            onBlur={selectBlurred}
            style={{zIndex:7}}
            onFocus={selectFocused}
            options={optionsArray}
            disabled={disabled}
            onChange={onChange}
            clearable={isClearable}
            searchable={false}
            onMenuClose={onMenuClose}
            placeholder={placeholder}
            menuPosition={'fixed'}
            menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
            backspaceRemoves={false}
            menuPortalTarget={menuPortalTarget}
            menuShouldScrollIntoView={false}
    />
  )};
export default MultipleSelectDropDown;
