import React from 'react';
import "antd/dist/antd.css";
import './ArticleModal.scss';
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import Article from "../../Components/Article/Article";

export default class ArticleModal extends React.Component {
  
  // Initialize
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  componentDidMount() {
    if(this.props.viewArticle){
      this.props.viewArticle(this.props.article.id).then(newResponse => {
        let article = newResponse.data.data.article;
      
        if(article && this.props.updateViewedOnForArticle){
          this.props.updateViewedOnForArticle(article);
        }
      });
    }
  }
  
  // Methods
  
  
  
  // Render
  
  render() {
    let { user, isOpen, article, company, handleCloseModal, getRelatedArticles } = this.props;
    
    return (
      <GeneralModal isOpen={isOpen}
                    maxWidth={5000}
                    maxHeight={3000}
                    width={'calc(100vw - 60px)'}
                    height={'calc(100vh - 60px)'}
                    showFooter={false}
                    showCancelButton={false}
                    showSaveButton={false}
                    showHeader={false}
                    handleCloseModal={() => handleCloseModal()}>
        <Article user={user}
                 article={article}
                 company={company}
                 className={'article-modal-cell'}
                 onFinish={() => handleCloseModal()}
                 getRelatedArticles={getRelatedArticles}
        />
      </GeneralModal>
    )
  }
}
