import React from 'react';
import MultipleSelectDropDown from "../../../Custom UI/DropDowns/MultipleSelectDropdown/MultipleSelectDropDown";
import './LabeledMultipleSelectDropDown.scss';
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import LabeledExtraButtons, {extraButtonsCount} from "../LabeledExtraButtons/LabeledExtraButtons";

const LabeledMultipleSelectDropDown = ({className, onSearch, name, value, label, required, options, disabled, errorMessage,
                                         handleSave, menuPlacement, showSearchIcon, addButtonClicked, onFocus, onBlur,
                                         isClearable = false, customOption, placeholder, infoBlurb, popoverPlacement = "top",
                                         warningMessage, bold, descriptionLabel, menuButtonClicked, editButtonClicked,
                                         deleteButtonClicked, loading = false}) => {
  
  // Instance Variables
  
  const onChange = (aOption) => {
    if(aOption){
      handleSave(aOption);
    }
  };
  
  // Render
  
  return (
    <div className={'labeled-multiple-select-input-container ' + className}>
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={descriptionLabel}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <span className="labeled-multiple-select-input-cell">
        <div className="labeled-multiple-select-input-dropdown">
          <div style={{width:'100%', top:'auto', bottom:'100%'}}>
            <MultipleSelectDropDown name={name}
                                    value={value}
                                    onBlur={onBlur}
                                    styles={{ menuPortal: base => ({ ...base, zIndex:9997, minHeight: '37px' }) }}
                                    onFocus={() => {
                                      if(onFocus){
                                        onFocus();
                                      }
                                    }}
                                    options={options}
                                    disabled={disabled}
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    isClearable={isClearable}
                                    placeholder={placeholder}
                                    customOption={customOption}
                                    menuPosition={'portal'}
                                    menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
                                    menuPortalTarget={document.body}
            />
          </div>
        </div>
        
        <Loading className="labeled-multiple-select-input-loading"
                 style={{right:(extraButtonsCount(showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked) * 35 + 30).toString() +'px'}}
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />
  
        <LabeledExtraButtons showSearchIcon={showSearchIcon}
                             addButtonClicked={addButtonClicked}
                             editButtonClicked={editButtonClicked}
                             deleteButtonClicked={deleteButtonClicked}
        />
      </span>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};
export default LabeledMultipleSelectDropDown;
