import React from 'react';
import 'antd/dist/antd.css'
import './VaccinationEntriesList.scss';
import DynamicList from "../DynamicList/DynamicList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import SurveyDrawer from "../../Components/SurveyDrawer/SurveyDrawer";
import moment from "moment";
import {momentFromDate} from "../../Helpers/DateHelpers";
import {Translation} from "react-i18next";

export default class VaccinationEntriesList extends React.Component {
  
  // Instance Variables
  
  surveyReferencesArray = ['VAXPATH_vaxtrack_1', 'VAXPATH_vaxtrack_2', 'VAXPATH_vaxtrack_3', 'VAXPATH_vaxtrack_4', 'VAXPATH_vaxtrack_5'];
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      total:-1,
      surveys:{},
      nextSurvey:{
        id:-1,
        name:'',
        reference:''
      },
      isDrawerOpen:false,
      shouldReload:false,
      selectedEntry:null
    };
    this.fetchSurveyWithReference = this.fetchSurveyWithReference.bind(this);
  }
  
  // Methods
  
  fetchSurveyWithReference(aReference, aIsNextSurvey){
    const {surveys} = this.state;
    const {fetchSurveyVersionCollectionByReference} = this.props;
    
    if(fetchSurveyVersionCollectionByReference && this.surveyReferencesArray.includes(aReference) && (surveys[aReference] === undefined || surveys[aReference] === null)){
      fetchSurveyVersionCollectionByReference(aReference).then((newResponse) => {
        let surveyVersionCollection = newResponse.data.data.survey_version_collection;
        let survey = {id:-1, name:'', reference:''};
      
        if(surveyVersionCollection && surveyVersionCollection.current_survey){
          survey = {id:surveyVersionCollection.current_survey.id, name:surveyVersionCollection.current_survey.name, reference:aReference};
          surveys[aReference] = survey;
        }
        if(aIsNextSurvey){
          this.setState({surveys:surveys, nextSurvey:survey});
        }
        else{
          this.setState({surveys:surveys});
        }
      }, (newError) => {
      
      });
    }
  }
  
  // Render
  
  render(){
    const {total, surveys, nextSurvey, isDrawerOpen, shouldReload, selectedEntry} = this.state;
    const {admin, company, patient, fetchEntries} = this.props;
  
    const role = roleForCompany(company);
    const editProhibitions = ['admin_level_1', 'admin_level_10'];
  
    let columnsArray = [];
    columnsArray.push({key:'objective.vaccination_objective_index', columnWeight:1, columnNameKey:'DOSE_NUMBER', propertiesArray:['objective', 'vaccination_objective_index'], valueFormatter:(aValue) => {return aValue + 1}});
    columnsArray.push({key:'vaccination_attributes.is_baseline', columnWeight:1, columnNameKey:'BASELINE', propertiesArray:['vaccination_attributes', 'is_baseline']});
    columnsArray.push({key:'vaccination_attributes.manufacturer', columnWeight:2, columnNameKey:'MANUFACTURER', propertiesArray:['vaccination_attributes', 'manufacturer']});
    columnsArray.push({key:'vaccination_attributes.center_of_vaccination', columnWeight:2, columnNameKey:'CENTER_OF_VACCINATION', propertiesArray:['vaccination_attributes', 'center_of_vaccination']});
    columnsArray.push({key:'vaccination_attributes.vaccine_lot_number', columnWeight:1, columnNameKey:'VACCINE_LOT_NUMBER', propertiesArray:['vaccination_attributes', 'vaccine_lot_number']});
    columnsArray.push({key:'vaccination_attributes.injection_site', columnWeight:1, columnNameKey:'INJECTION_SITE', propertiesArray:['vaccination_attributes', 'injection_site'],
      valueFormatter:(aValue) => {
        let returnValue = aValue;
        
        if(aValue === 'leftArm' || aValue === 'rightArm'){
          returnValue = <Translation>{(t, {i18n}) => t('INJECTION_SITE_OPTIONS.' + aValue)}</Translation>;
        }
        return returnValue;
      }});
    columnsArray.push({key:'vaccination_attributes.date_of_dose', dateFormat:'YYYY-MM-DD', columnWeight:1, columnNameKey:'DATE_OF_DOSE', propertiesArray:['vaccination_attributes', 'date_of_dose']});
    columnsArray.push({key:'created_at', dateFormat:'YYYY-MM-DD', columnWeight:1, columnNameKey:'CREATED_AT', propertiesArray:['created_at']});
  
    let menuItemsArray = [{title:'EDIT', clickAction:(aObject) => this.setState({isDrawerOpen:true, selectedEntry:aObject})}];
    
    let currentSurvey = nextSurvey;
    let defaultValuesForQuestions = {};
    
    if(selectedEntry){
      if(selectedEntry.objective){
        currentSurvey = surveys[this.surveyReferencesArray[selectedEntry.objective.vaccination_objective_index]];
      }
      if(selectedEntry.vaccination_attributes){
        let manufacturer = null;
  
        if(selectedEntry.vaccination_attributes.manufacturer && selectedEntry.vaccination_attributes.manufacturer.length > 0){
          manufacturer = '3';
        }
        let manufacturersArray = [{value:'1', label:'AstraZeneca'}, {value:'2', label:'Johnson & Johnson'},
          {value:'3', label:'Moderna'}, {value:'4', label:'Novavax'}, {value:'5', label:'PfizerBioNTech'},
          {value:'6', label:'Sanofi and GlaxoSmithKline'}, {value:'7', label:'Other'}, {value:'8', label:'Don\'t know'}];
  
        for(let manufacturerObject of manufacturersArray){
          if(manufacturerObject.label.includes(selectedEntry.vaccination_attributes.manufacturer)){
            manufacturer = manufacturerObject.value;
            break;
          }
        }
  
        let area = null;
        
        if(selectedEntry.vaccination_attributes.injection_site && selectedEntry.vaccination_attributes.injection_site.length > 0){
          area = '3';
        }
        let areasArray = [{value:'1', label:'left arm'}, {value:'2', label:'right arm'}, {value:'3', label:'other'}];
  
        for(let areaObject of areasArray){
          if(areaObject.label.includes(selectedEntry.vaccination_attributes.injection_site)){
            area = areaObject.value;
            break;
          }
        }
        defaultValuesForQuestions['vax_date'] = {value:selectedEntry.vaccination_attributes.date_of_dose};
        defaultValuesForQuestions['other_area'] = {value:selectedEntry.vaccination_attributes.injection_site};
        defaultValuesForQuestions['vax_center'] = {value:selectedEntry.vaccination_attributes.center_of_vaccination};
        defaultValuesForQuestions['vax_manufacturer'] = {value:manufacturer};
        defaultValuesForQuestions['vaccination_area'] = {value:area};
        defaultValuesForQuestions['vaccine_lotnumber'] = {value:selectedEntry.vaccination_attributes.vaccine_lot_number};
        defaultValuesForQuestions['other_manufacturer'] = {value:selectedEntry.vaccination_attributes.manufacturer};
        defaultValuesForQuestions['vaccine_lot_number'] = {value:selectedEntry.vaccination_attributes.vaccine_lot_number};
      }
    }
    
    return (
      <div className="vaccination-entries-list-container">
        <DynamicList id="vaccination-entries-list"
                     didReload={() => this.setState({shouldReload:false})}
                     menuCancel={() => this.setState({selectedEntry:null})}
                     columnsArray={columnsArray}
                     fetchObjects={(aLimit, aOffset) => fetchEntries(patient.id, 'VaccinationEntry', null, aLimit, aOffset)}
                     shouldReload={shouldReload}
                     totalObjects={(aTotal) => {
                       if(total !== aTotal){
                         this.setState({total:aTotal}, () => {
                           if(aTotal > 0){
                             for(let index = 0; index <= aTotal; index += 1){
                               this.fetchSurveyWithReference(this.surveyReferencesArray[index], (index === aTotal) && (aTotal < this.surveyReferencesArray.length));
                             }
                           }
                           else{
                             this.fetchSurveyWithReference(this.surveyReferencesArray[0], true);
                           }
                         })
                       }
                     }}
                     objectsReduce={(aObjectsArray, aObject) => {
                       if(aObject && aObject.objective){
                         const object = aObjectsArray[aObject.objective.id];
  
                         if(object){
                           if(moment.duration(momentFromDate(aObject.created_at).diff(momentFromDate(object.created_at))) > 0){
                             if(object.vaccination_attributes.is_baseline === true || object.vaccination_attributes.is_baseline === false){
                               if(aObject.vaccination_attributes.is_baseline === null || aObject.vaccination_attributes.is_baseline === undefined){
                                 aObject.vaccination_attributes.is_baseline = object.vaccination_attributes.is_baseline;
                               }
                             }
                             aObjectsArray[aObject.objective.id] = aObject;
                           }
                           else if(aObject.vaccination_attributes.is_baseline === true || aObject.vaccination_attributes.is_baseline === false){
                             if(object.vaccination_attributes.is_baseline === null || object.vaccination_attributes.is_baseline === undefined){
                               object.vaccination_attributes.is_baseline = aObject.vaccination_attributes.is_baseline;
                               aObjectsArray[aObject.objective.id] = object;
                             }
                           }
                         }
                         else{
                           aObjectsArray[aObject.objective.id] = aObject;
                         }
                       }
                       return aObjectsArray;
                     }}
                     showSearchBar={false}
                     menuItemsArray={menuItemsArray}
                     minColumnWidth={100}
                     addButtonClicked={nextSurvey.id !== -1 ? (aEvent) => this.setState({isDrawerOpen:true, selectedEntry:null}) : null}
                     responseTotalKeysArray={['data', 'data', 'entries', 'total']}
                     responseObjectKeysArray={['data', 'data', 'entries', 'entries']}
        />
        
        <SurveyDrawer open={isDrawerOpen}
                      title={currentSurvey.name}
                      userId={patient.id}
                      adminId={admin.id}
                      company={company}
                      onClose={() => this.setState({isDrawerOpen:false})}
                      editable={!editProhibitions.includes(role)}
                      surveyId={currentSurvey.id}
                      onFinishedSurvey={() => this.setState({isDrawerOpen:false, shouldReload:true})}
                      defaultValuesForQuestions={defaultValuesForQuestions}
        />
      </div>
    )
  }
}
