import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function addConditionToUser({groupId, dateOfDiagnosis, userId}){
  EventTracker.track(EventTypes.ADD_CONDITION_TO_USER);
  let query = `
  mutation AddCondition($addConditionInput: AddConditionInput!) {
    addCondition(input: $addConditionInput) {
      condition {
        id
      }
    }
  }
  `;
  let date = new Date();
  date.setFullYear(dateOfDiagnosis);
  let variables = {
    addConditionInput: {
      user_id: userId,
      group_id: groupId,
      date_of_diagnosis: date
    }
  };
  let operationName = `AddCondition`;

  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    })
  }
}

export function getConditions(aSearchString, aLimit, aOffset){
  let query = `
    query getConditions ($query: String!, $limit: Int, $offset: Int){
      conditions_page(query: $query, limit: $limit, offset: $offset){
        conditions{
          id
          name
        }
        total
      }
    }
    `;
  let variables = {};
  
  return (dispatch) => {
    variables['query'] = aSearchString ? aSearchString : '';
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    return global.api.post("/queries", { query, variables });
  };
}

export function getMedraConditions(aLimit, aOffset, aSearchString){
  let query = `
    query getMeddras ($search_string: String, $limit: Int, $offset: Int) {
        meddras (search_string: $search_string, limit: $limit, offset: $offset){
            pt_name
            id
        }
      }
    `;
  let variables = {};
  
  return (dispatch) => {
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['search_string'] = aSearchString;
    return global.api.post("/queries", { query, variables });
  };
}
