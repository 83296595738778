import React from "react";
import './CallSummaryModal.scss';
import moment from "moment";
import i18next from "i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {formatUserName} from "../../Helpers/AdminHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {questsArrayForCompany} from "../../Helpers/ToolHelpers";
import {minutesToTime, timeToMinutes, isoDateTimeFormat, americanDateTimeFormat} from "../../Helpers/DateHelpers";
import {typesOfCallsOptionsArray, inboundDispositionCodesOptionsArray, outboundDispositionCodesOptionsArray} from "../../constants/CallNoteTypes";

export default class CallSummaryModal extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      notes:props.noteText ? props.noteText : '',
      caller:formatUserName(props.user),
      maxTime:new Date(),
      duration:'',
      attendees:props.attendees ? props.attendees : formatUserName(props.patient),
      errorText:null,
      surveyName:'',
      questsArray:[],
      type_of_visit:props.typeOfVisit ? props.typeOfVisit : '',
      visit_purpose:null,
      caller_date_time:null,
      durationInMinutes:null,
      visit_disposition:null
    };
    if(props.callNote){
      this.state.notes = props.callNote.note_text;
      this.state.caller = props.callNote.caller;
      this.state.duration = props.callNote.duration;
      this.state.attendees = props.callNote.attendees;
      this.state.surveyName = props.callNote.survey_name;
      this.state.type_of_visit = props.callNote.type_of_visit;
      this.state.visit_purpose = props.callNote.purpose_of_visit;
      this.state.caller_date_time = props.callNote.start_time ? moment(props.callNote.start_time) : null;
      this.state.durationInMinutes = timeToMinutes(props.callNote.call_duration);
      this.state.visit_disposition = props.callNote.visit_disposition;
    }
    this.handleSave = this.handleSave.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCancelModal = this.handleCancelModal.bind(this);
    this.allowPositiveNumerical = this.allowPositiveNumerical.bind(this);
  };
  
  componentDidMount(){
    if(this.props.callSid){
      let loading_i18n = (i18next.t('LOADING_WITH_ELLIPSIS'));
      this.setState({caller_date_time:loading_i18n, duration:loading_i18n}, () => {
        this.requestCallDetailsForSid();
      });
    }
    this.loadQuestsArray();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {callSid, company} = this.props;
    
    if(prevProps.callSid !== callSid){
      this.requestCallDetailsForSid();
    }
    if(prevProps.company && company && prevProps.company.id !== company.id){
      this.loadQuestsArray();
    }
  }
  
  // Methods
  
  loadQuestsArray(){
    this.setState({questsArray:questsArrayForCompany(this.props.company)});
  }
  
  requestCallDetailsForSid(){
    const {callSid} = this.props;

    if(callSid && callSid.length > 0){
      global.api.post('/api/telehealth/twilio_phone_call/get_call_info', {call_sid:callSid}).then((newResult) => {
        this.setState({duration:newResult.data.duration, caller_date_time:moment(newResult.data.caller_date_time)});
      });
    }
  }
  
  handleDateChange(aDate) {
    let currentTime = moment(new Date());
    this.setState({caller_date_time:((aDate < currentTime) ? aDate : currentTime)});
    
    if(aDate.isSame(currentTime, 'day')){
      this.setState({maxTime:currentTime});
    }
    else{
      this.setState({maxTime:null});
    }
  }
  
  handleSave(){
    const {callSid, patient, callNote, closeModal, addCallNote} = this.props;
    const {notes, duration, surveyName, type_of_visit, visit_purpose, caller_date_time, durationInMinutes,
      visit_disposition} = this.state;
  
    let newCallNote = {};
    newCallNote.id = callNote ? callNote.id : null;
    newCallNote.note_text = notes;
    newCallNote.patient_id = patient ? patient.id : null;
    newCallNote.start_time = caller_date_time ? caller_date_time.format(isoDateTimeFormat) : null;
    newCallNote.survey_name = surveyName;
    newCallNote.call_duration = durationInMinutes ? minutesToTime(durationInMinutes) : duration;
    newCallNote.type_of_visit = type_of_visit;
    newCallNote.twilio_call_sid = callSid;
    newCallNote.purpose_of_visit = visit_purpose;
    newCallNote.visit_disposition = visit_disposition;
    addCallNote(newCallNote);
    this.setState({notes:'', surveyName:'', visit_purpose:null, type_of_visit:'', caller_date_time:null, durationInMinutes:null, visit_disposition:null, callSummaryIsOpen:false});
    
    if(closeModal){
      closeModal();
    }
  }
  
  handleCloseModal(){
    if(!this.state.type_of_visit){
      this.setState({errorText:i18next.t('TYPE_OF_VISIT_ERROR')});
    }
    else if(!this.state.caller_date_time){
      this.setState({errorText:i18next.t('CALLER_DATE_AND_TIME_ERROR')});
    }
    else if(!this.state.visit_disposition){
      this.setState({errorText:i18next.t('VISIT_DISPOSITION_ERROR')});
    }
    else{
      this.handleSave();
    }
  }
  
  handleCancelModal(){
    if(this.props.canCancel){
      this.props.closeModal();
    }
    else{
      this.handleCloseModal();
    }
  }
  
  allowPositiveNumerical(value) {
    return value.replace(/\D*/g,"")
  }
  
  // Render
  
  render(){
    const {isOpen, callSid, callNote, canCancel, editable} = this.props;
    const {notes, caller, maxTime, duration, attendees, errorText, surveyName, questsArray, visit_purpose,
      visit_disposition, caller_date_time, durationInMinutes, type_of_visit} = this.state;

    let questOptionsArray = [];
    
    for(let quest of questsArray){
      questOptionsArray.push({value:quest.id, label:quest.title + ' - ' + quest.id});
    }
    
    let dispositionCodesArray = outboundDispositionCodesOptionsArray();
  
    if(type_of_visit.includes('inbound')){
      dispositionCodesArray = inboundDispositionCodesOptionsArray();
    }
    
    return (
      <GeneralModal title={i18next.t('CALL_SUMMARY')}
                    width={'500px'}
                    isOpen={isOpen}
                    maxWidth={'500px'}
                    isEditing={Boolean(callNote)}
                    handleSave={() => this.handleCloseModal()}
                    showSaveButton={true}
                    errorMessage={errorText}
                    containsDropdown={true}
                    handleCloseModal={() => this.handleCancelModal()}
                    showCancelButton={canCancel}
                    clickOutsideToClose={canCancel}>
        <div className="call-summary-modal-dual-input-container">
          <LabeledTextInput className="call-summary-modal-caller"
                            label={i18next.t('CALLER')}
                            value={caller}
                            disabled={true}
                            required={true}
                            handleSave={(aEvent) => this.setState({caller:aEvent.target.value})}
          />

          <LabeledTextInput className="call-summary-modal-attendees"
                            label={i18next.t('ATTENDEES')}
                            value={attendees}
                            disabled={true}
                            required={true}
                            handleSave={(aEvent) => this.setState({attendees:aEvent.target.value})}
          />
        </div>

        <div className="call-summary-modal-dual-input-container">
          <LabeledDropDown className="call-summary-modal-type-of-visit"
                           label={i18next.t('TYPE_OF_VISIT')}
                           value={type_of_visit && typesOfCallsOptionsArray().filter(function(option) {
                             return option.value === type_of_visit;
                           })}
                           options={typesOfCallsOptionsArray()}
                           disabled={!editable}
                           required={true}
                           handleSave={(aOption) => this.setState({type_of_visit:aOption.value, visit_disposition:null, errorText:null})}
          />
          
          <LabeledTextInput className="call-summary-modal-purpose-of-visit"
                            label={i18next.t('PURPOSE_OF_VISIT')}
                            value={visit_purpose}
                            handleSave={(aEvent) => this.setState({visit_purpose:aEvent.target.value})}
          />
        </div>

        <div className="call-summary-modal-dual-input-container">
          {!callSid ?
            <>
              <LabeledDatePicker className="call-summary-modal-date-time"
                                 label={i18next.t('CALLER_DATE_AND_TIME')}
                                 value={caller_date_time}
                                 maxDate={moment()}
                                 maxTime={maxTime}
                                 required={true}
                                 dateFormat={americanDateTimeFormat}
                                 handleSave={(aDate) => this.handleDateChange(aDate)}
                                 showTimeSelect={true}
              />

              <LabeledTextInput className="call-summary-modal-call-duration-in-minutes"
                                label={i18next.t('CALL_DURATION_MINUTES')}
                                value={durationInMinutes}
                                disabled={!editable}
                                sanitizer={this.allowPositiveNumerical}
                                handleSave={(aEvent) => this.setState({durationInMinutes:aEvent.target.value})}
              />
            </>
            :
            <>
              <LabeledTextInput className="call-summary-modal-date-time"
                                label={i18next.t('CALLER_DATE_AND_TIME')}
                                value={caller_date_time}
                                disabled={true}
                                required={true}
              />

              <LabeledTextInput className="call-summary-modal-call-duration"
                                label={i18next.t('CALL_DURATION_H')}
                                value={duration}
                                disabled={true}
                                handleSave={(aEvent) => this.setState({duration:aEvent.target.value})}
              />
            </>
          }
        </div>

        <div className="call-summary-modal-dual-input-container">
          <LabeledDropDown className="call-summary-modal-visit-desposition"
                           label={i18next.t('VISIT_DISPOSITION_REQUIRED')}
                           value={visit_disposition && dispositionCodesArray.filter(function(option) {
                             return option.value === visit_disposition;
                           })}
                           options={dispositionCodesArray}
                           required={true}
                           handleSave={(aOption) => this.setState({visit_disposition:aOption.value, errorText:null})}
          />
          
          <LabeledDropDown className="call-summary-modal-survey-name"
                           label={i18next.t('SURVEY_NAME')}
                           value={surveyName && questOptionsArray.filter(option => {
                             return option.label === surveyName
                           })}
                           options={questOptionsArray}
                           required={false}
                           handleSave={(aOption) => this.setState({surveyName:aOption.label, errorText:null})}
                           customOptionHeight={(aOption) => aOption.label.length > 60 ? 75 : aOption.label.length > 30 ? 55 : 35}
          />
        </div>

        <LabeledTextarea className="call-summary-modal-notes-optional"
                         label={i18next.t('NOTES_OPTIONAL')}
                         value={notes}
                         handleSave={(aEvent) => this.setState({notes:aEvent.target.value})}
        />
      </GeneralModal>
    )
  }
}
