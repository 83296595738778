import React from 'react';
import i18next from "i18next";

const TimeObjective = ({ objective, selectedTranslationIndex }) => {

    let times = []
    let reminderTimeInterval = 15; //minutes interval
    let startTime = 0;
    //loop to increment the time and push results in array
    for (let i = 0; startTime < 24 * 60; i++) {
        let hour = Math.floor(startTime / 60); // getting hours of day in 0-24 format
        let minute = (startTime % 60); // getting minutes of the hour in 0-55 format
        let time = null

        if (hour > 0 && minute > 0) {
            time = (hour.toString() + minute.toString())
        } else if (hour === 0) {
            time = (minute.toString())
        } else if (minute === 0) {
            time = (hour.toString() + '00')
        }
        times.push({
            value: time.padStart(4, '0'),
            label: convertTime(time)
        })
        startTime = startTime + reminderTimeInterval;
    }

    function convertTime(time) {
        let hour;
        let reminderTime = time;
        if (reminderTime.length === 3) {
            hour = reminderTime.substring(0, 1)
        } else if (reminderTime.length === 4) {
            hour = reminderTime.substring(0, 2)
        }
        else {
            hour = 0;
        }
        let amOrPm = hour < 12 ? 'am' : 'pm';
        hour = hour % 12 || 12;
        let minutes = reminderTime.slice(-2)
        if (hour === 12 && minutes === '0') {
            reminderTime = '12:00 am'
        } else {
            reminderTime = `${hour}:${minutes} ${amOrPm}`
        }
        return reminderTime
    }

    let timeOptions = [];
    timeOptions = times.map((time) => {
        return (<option key={time.value} value={time.value}>{time.label}</option>)
    })

    return (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <label>
      {objective.translations[selectedTranslationIndex].description}
    </label>
    <select>
      <option value>
          {i18next.t('CLICK_TO_SET_TIME')}
      </option>
        {timeOptions}
    </select>
  </div>
)};

export default TimeObjective
