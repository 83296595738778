import React from "react";
import {Translation} from "react-i18next";

export const reportTypesArray = ['ascii', 'ascii-csv', 'non-ascii', 'non-ascii-csv'];
export const arelComparatorsArray = ['eq', 'not_eq', 'gt', 'lt', 'gteq', 'lteq', 'matches', 'in'];

export const arelComparatorOptionsArray = [
  {label:<Translation>{(t, {i18n}) => t('EQUAL')}</Translation>, value:'eq'},
  {label:<Translation>{(t, {i18n}) => t('NOT_EQUAL')}</Translation>, value:'not_eq'},
  {label:<Translation>{(t, {i18n}) => t('GREATER_THAN')}</Translation>, value:'gt'},
  {label:<Translation>{(t, {i18n}) => t('LESS_THAN')}</Translation>, value:'lt'},
  {label:<Translation>{(t, {i18n}) => t('GREATER_THAN_OR_EQUAL_TO')}</Translation>, value:'gteq'},
  {label:<Translation>{(t, {i18n}) => t('LESS_THAN_OR_EQUAL_TO')}</Translation>, value:'lteq'},
  {label:<Translation>{(t, {i18n}) => t('MATCHES')}</Translation>, value:'matches'},
  {label:<Translation>{(t, {i18n}) => t('IN')}</Translation>, value:'in'}
];
