import React from 'react';
import './ToolModal.scss'
import { cloneDeep } from 'lodash';
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../../i18n";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import "../../Modals/GeneralModal/GeneralModal.scss";
import CreateTool from "../../Components/Tools/CreateTool/CreateTool";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";


class ToolModal extends React.Component {

  // Init

  constructor(props) {
    super(props);

    const defaultTool = {
      title: "",
      description: "",
      icon_bg_color: '#E33966',
      icon: "medkit",
      company_id: this.props.companyId,
      objectives: [{type: "",
        translations: [{locale: this.props.languages[0].locale}]}],
      translations: [{
        locale: this.props.languages[0].locale,
        title: "",
        description: "",
      }],
      selectedToolLanguage: null,
      languageToolAnchorElement:null
    };
    this.languageToolButtonClicked = this.languageToolButtonClicked.bind(this);
    
    let tool = this.props.tool || defaultTool;
    this.orderQuestTranslations(tool);
    this.state = {
      tool: tool,
      selectedTranslation: tool.translations[0],
      selectedTranslationIndex: 0,
      selectedLanguage: null,
      selectedToolId: null,
      removeTranslationIndex:null,
      toolConfirmationModalOpen:false,
      languageConfirmationModalOpen:false,
      goToNextState: false
    };
    this.saveTool = this.saveTool.bind(this);
    this.updateTool = this.updateTool.bind(this);
    this.hasLanguage = this.hasLanguage.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.selectTranslation = this.selectTranslation.bind(this);
    this.removeToolFromCompany = this.removeToolFromCompany.bind(this);
    this.updateToolTranslation = this.updateToolTranslation.bind(this);
  }

  updateTool(key, value) {
    this.setState({tool: Object.assign(this.state.tool, {[key]: value})})
  }

  updateToolTranslation(key, value) {
    this.setState({selectedTranslation: Object.assign(this.state.selectedTranslation, {[key]: value})})
  }

  saveTool() {
    if (this.props.tool) {
      this.props.updateTool({tool: this.state.tool}).then(result => {
        this.props.handleCloseModal()
      }, errorResponse => {
        this.handleErrorResponse(errorResponse);
      })
    } else {
      this.props.addToolToCompany({tool: this.state.tool}).then(result => {
        this.props.handleCloseModal()
      }, errorResponse => {
        this.handleErrorResponse(errorResponse);
      })
    }
  }

  handleCloseModal(){
    const defaultTool = {
      title: "",
      description: "",
      icon_bg_color: '',
      icon: "",
      company_id: this.props.companyId,
      objectives: [{type: "",
        translations: [{locale: this.props.languages[0].locale}]}],
      translations: [{
        locale: this.props.languages[0].locale,
        title: "",
        description: "",
      }],
      selectedToolLanguage: null,
      languageToolAnchorElement:null,
    };

    this.setState({tool: defaultTool, selectedTranslation: defaultTool.translations[0]});
    this.props.handleCloseModal();
  }

  handleErrorResponse(errorResponse){
    const { t } = this.props;
    let alertMessage = t('TOOL_NOT_SAVED', {i18n});

    if(errorResponse.data && errorResponse.data.error){
      alertMessage += errorResponse.data.error;
    }
    alert(alertMessage);
  }

  removeToolFromCompany = (toolId) => {
    this.setState({toolConfirmationModalOpen:true});
    this.setState({selectedToolId:toolId});
  };

  selectTranslation(translation, index){
    this.setState({selectedTranslation: translation, selectedTranslationIndex: index})
  }

  hasLanguage(language) {
    if(this.state.tool.translations && language){
      for(let i = 0; i < this.state.tool.translations.length; i++){
        if(this.state.tool.translations[i].locale === language.locale){
          return true;
        }
      }
    }
    return false;
  }
  
  removeSelectedLanguage(){
    let translationIndex = this.state.removeTranslationIndex;
    let tool = cloneDeep(this.state.tool);
    
    tool.translations.splice(translationIndex, 1);
    
    if(tool.objectives){
      for(let objectiveIndex = 0; objectiveIndex < tool.objectives.length; objectiveIndex++){
        let objective = tool.objectives[objectiveIndex];
        objective.translations.splice(translationIndex, 1);
        
        if(objective.answers){
          for(let answerIndex = 0; answerIndex < objective.answers.length; answerIndex++){
            let answer = objective.answers[answerIndex];
            answer.translations.splice(translationIndex, 1);
          }
        }
      }
    }
    this.setState({tool: tool, selectedTranslation: tool.translations[0], selectedTranslationIndex: 0});
  }
  
  toggleLanguage(language){
    if(language && (language.locale !== 'en')){
      let translationIndex = -1;
      let tool = cloneDeep(this.state.tool);

      for(let i = 0; i < tool.translations.length; i++){
        if(tool.translations[i].locale === language.locale){
          translationIndex = i;
          break;
        }
      }

      if(translationIndex >= 0){
        this.setState({selectedLanguage:language, removeTranslationIndex:translationIndex, languageConfirmationModalOpen:true});
      }
      else{
        tool.translations.push({
                                locale: language.locale,
                                title: '',
                                description: ''
                              });
        for(let objectiveIndex = 0; objectiveIndex < tool.objectives.length; objectiveIndex++){
          let objective = tool.objectives[objectiveIndex];
          objective.translations.push({locale: language.locale,
                                        description: '',
                                        minimum_value_string: '',
                                        maximum_value_string: ''});

          if(objective.answers){
            for(let answerIndex = 0; answerIndex < objective.answers.length; answerIndex++){
              let answer = objective.answers[answerIndex];
              answer.translations.push({locale: language.locale,
                                         answer_text: ''});
            }
          }
        }
        this.setState({tool: tool, selectedTranslation: tool.translations[tool.translations.length - 1], selectedTranslationIndex: tool.translations.length - 1});
      }
    }
  }

  orderQuestTranslations(quest){
    if(quest && quest.translations){
      let localesOrder = quest.translations.map(translation=>{
        return translation.locale;
      });
      let assignTranslation = (object)=>{
        if(object){
          let newTranslations = localesOrder.map(locale=>{
            return {locale: locale};
          });
          if(object.translations){
            newTranslations.forEach(newTranslation=>{
              let oldTranslation = object.translations.find(translation=>translation.locale === newTranslation.locale);
              if(oldTranslation){
                Object.assign(newTranslation, oldTranslation);
              }
            });
          }
          object.translations = newTranslations;
        }
      };
      if(quest.objectives){
        quest.objectives.forEach(objective=>{
          assignTranslation(objective);

          if(objective.answers){
            assignTranslation(objective.answers);
          }
        });
      }
    }
  }
  
  languageToolButtonClicked(aEvent, aLanguageTool){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({languageToolAnchorElement:aEvent.currentTarget, selectedToolLanguage:aLanguageTool});
  };

  render() {
    const {isOpen, removeToolFromCompany} = this.props;
    const {tool, selectedToolId, selectedLanguage, toolConfirmationModalOpen, languageConfirmationModalOpen} = this.state;

    return (
      <>
        <GeneralModal isOpen={isOpen}
                      handleCloseModal={this.handleCloseModal}
                      title={tool.id ? i18next.t('EDIT_TOOL') : i18next.t('CREATE_A_NEW_TOOL')}
                      isEditing={false}
                      showSaveButton={this.state.goToNextState}
                      handleSave={() => this.saveTool()}>
          <CreateTool {...this.props}
                      tool={tool}
                      updateTool={this.updateTool}
                      updateToolTranslation={this.updateToolTranslation}
                      changeGoToNextState={() => this.setState({goToNextState: true})}
          />
        </GeneralModal>
        
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('TOOL', {i18n})})}
                           isOpen={toolConfirmationModalOpen}
                           reject={() => this.setState({toolConfirmationModalOpen:false})}
                           confirm={() => removeToolFromCompany({toolId:selectedToolId}).then(result => {this.handleCloseModal()})}
        />
  
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedLanguage ? `${selectedLanguage.title} (${selectedLanguage.display_name})` : '')})}
                           isOpen={languageConfirmationModalOpen}
                           reject={() => this.setState({languageConfirmationModalOpen:false})}
                           confirm={() => this.removeSelectedLanguage()}
        />
      </>
    )
  }
}

export default withTranslation()(ToolModal)
