import DynamicList from "Lists/DynamicList/DynamicList";
import { useState } from "react";
import './AdditionalAppColumnsList.scss';

const AdditionalAppColumnsList = ({className, patient, getUserAdditionalAppColumns}) => {
  const [shouldReload, setShouldReload] = useState(false);

  const columnsArray = [];
  columnsArray.push({key:'column_name', columnWeight:1, columnNameKey:'COLUMN_NAME', propertiesArray:['additional_app_column', 'name']});
  columnsArray.push({key:'column_value', columnWeight:3, columnNameKey:'COLUMN_VALUE', propertiesArray:['value']});

  return (
    <div className="additional-app-columns-container" >
      <DynamicList id="additional-app-columns-list"
                   didReload={() => setShouldReload(false)}
                   showTotal={false}
                   columnsArray={columnsArray}
                   fetchObjects={(aLimit, aOffset, aSearchString) => getUserAdditionalAppColumns(aLimit, aOffset, aSearchString, patient && patient.id)}
                   shouldReload={shouldReload}
                   showSearchBar
                   minColumnWidth={200}
                   responseTotalKeysArray={['data', 'data', 'additional_app_column_user_joins', 'total']}
                   responseObjectKeysArray={['data', 'data', 'additional_app_column_user_joins', 'additional_app_column_user_joins']}
    />
    </div>
  )
}

export default AdditionalAppColumnsList;