import React from 'react';
import './SurveyBuilder.scss';
import i18n from "../../i18n";
import Menu from "@material-ui/core/Menu";
import {toast} from "react-toastify";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {debounce} from "lodash";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import WhiteButton from "../../Custom UI/Buttons/WhiteButton/WhiteButton";
import SurveyDrawer from "../SurveyDrawer/SurveyDrawer";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LanguagePicker from "../Tools/CreateTool/LanguagePicker/LanguagePicker";
import {animateScroll} from 'react-scroll';
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import SurveyBuilderErrors from "./SurveyBuilderErrors/SurveyBuilderErrors";
import {momentFromDateTime} from "../../Helpers/DateHelpers";
import SurveyBuilderPageCell from "../../Cells/SurveyBuilderCells/SurveyBuilderPageCell/SurveyBuilderPageCell";
import SurveyBuilderReorderList from "../../Lists/SurveyBuilderReorderList/SurveyBuilderReorderList";
import SurveyBuilderSurveySettings from "./SurveyBuilderSurveySettings/SurveyBuilderSurveySettings";
import SurveyBuilderQuestionSettings from "./SurveyBuilderQuestionSettings/SurveyBuilderQuestionSettings";
import {emailIsValid, formatUserName} from "../../Helpers/AdminHelpers";
import SurveyBuilderQuestionSelectModal from "../../Modals/SurveyBuilderQuestionSelectModal/SurveyBuilderQuestionSelectModal";
import {allowedWriteTables, operationsArray} from "../../constants/SurveyBuilderTypes";
import {detectErrorsForPagesArray, errorForIdTypeAndKey} from "../../Helpers/SurveyBuilderErrorHelpers";
import {objectIsEmpty, isSurveyTypeValid, KeyForTranslation, languageFromLocale, computeHeightsForPages} from "../../Helpers/SurveyBuilderHelpers";

const yaml = require('js-yaml');

const notifyPublishError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('PUBLISH_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCannotPreview = () =>  toast(<Translation>{ (t, { i18n }) => t('CANNOT_PREVIEW_ERRORS_PRESENT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCannotPublish = () =>  toast(<Translation>{ (t, { i18n }) => t('CANNOT_PUBLISH_ERRORS_PRESENT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPublishSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SURVEY_SAVED') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyYAMLFormatError = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('YAML_FORMAT_ERROR', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyCannotDeleteLastPage = () =>  toast(<Translation>{ (t, { i18n }) => t('CANNOT_DELETE_LAST_PAGE') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyConvertOldFormatSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('CONVERT_OLD_FORMAT_SUCCESS') }</Translation>, NOTIFY_OPTS.autoClose);

export default class SurveyBuilder extends React.Component {
  
  // Instance Variables
  
  scrollRef = React.createRef();
  stickyRef = React.createRef();
  uploadYAMLInputRef = React.createRef();
  stickyObserver;
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      title:'',
      errors:{},
      header:'',
      title_ca:'',
      title_de:'',
      title_es:'',
      title_fi:'',
      title_fr:'',
      title_it:'',
      isSticky:false,
      largeSize:false,
      pagesArray:[],
      surveyName:'',
      description:'',
      description_ca:'',
      description_de:'',
      description_es:'',
      description_fi:'',
      description_fr:'',
      description_it:'',
      reorderType:null,
      isPublishing:false,
      scrollBounds:{x:0, y:0, width:1024, height:0},
      savingAsDraft:false,
      surveyKeyword:'',
      surveyKeywordTaken:false,
      confirmationModalOpen:false,
      shouldSaveWhenFinished:false,
      surveyVersionCollection:{},
      selectedSurveyVersion:1,
      repeatingSurvey:false,
      termsAndPrivacySurvey:false,
      adminSurvey:false,
      isDrawerOpen:false,
      selectedSurvey:null,
      selectedQuestion:null,
      unrecognizedKeys:'',
      selectedPage:null,
      selectedPageIndex:-1,
      pageMenuAnchorElement:null,
      adminAlertEmailsArray:[],
      showQuestionSelectModal:false,
      pastSurveyVersionsArray:[],
      selectedTranslation:{
        title:"English",
        locale:"en",
        display_name:"English (US)"
      },
      translationsArray:[{
        title:"English",
        locale:"en",
        display_name:"English (US)"
      }]
    };
    this.preview = this.preview.bind(this);
    this.publish = this.publish.bind(this);
    this.addError = this.addError.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.closeMenus = this.closeMenus.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.uploadYAML = this.uploadYAML.bind(this);
    this.deleteSurvey = this.deleteSurvey.bind(this);
    this.downloadYAML = this.downloadYAML.bind(this);
    this.updateSurvey = this.updateSurvey.bind(this);
    this.pageForSurvey = this.pageForSurvey.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.scrollToRefWithId = this.scrollToRefWithId.bind(this);
    this.addPageAfterPageIndex = this.addPageAfterPageIndex.bind(this);
    this.addSurveyToPageAtIndex = this.addSurveyToPageAtIndex.bind(this);
    this.updateUnrecognizedKeys = this.updateUnrecognizedKeys.bind(this);
    this.moveSurveyFromPageToPage = this.moveSurveyFromPageToPage.bind(this);
    this.moveQuestionFromPageToPage = this.moveQuestionFromPageToPage.bind(this);
    this.addQuestionOfTypeToPageAtIndex = this.addQuestionOfTypeToPageAtIndex.bind(this);
    this.updateUnrecognizedKeys = debounce(this.updateUnrecognizedKeys, 1000);
  };
  
  componentDidMount(){
    if('SURVEY_NAME' === i18next.t('SURVEY_NAME')){
      setTimeout(() => {
        this.loadSurvey();
      }, 500);
    }
    else{
      this.loadSurvey();
    }
    this.stickyObserver = new IntersectionObserver(
      ([aEvent]) => {
        this.setState({isSticky:aEvent.intersectionRatio < 1 && aEvent.boundingClientRect.y < 300});
      },
      {
        threshold:[1]
      }
    );
    this.stickyObserver.observe(this.stickyRef);
  }
  
  componentWillUnmount(){
    this.stickyObserver.unobserve(this.stickyRef);
  }
  
  // Helper Methods
  
  newPage(aKey = ''){
    const {languages} = this.props;
    
    let undeletableKeysArray = ['end', 'disqualified'];
    let returnValue = {id:Math.random() * 1000000, key:aKey, isSurveyPage:false, surveysArray:[], questionsArray:[], canBeDeleted:!undeletableKeysArray.includes(aKey), showAddPageButtons:!undeletableKeysArray.includes(aKey), page_title:''};
    
    if(languages){
      for(let language of languages){
        if(language.locale !== 'en'){
          returnValue['page_title_' + language.locale] = '';
        }
      }
    }
    return returnValue;
  }
  
  newQuestion(aType = 'blank', aKey = ''){
    const {languages} = this.props;
    
    let returnValue = {id:Math.random() * 1000000, label:'', key:aKey, type:''};
    
    if(isSurveyTypeValid(aType)){
      returnValue.type = aType;
    }
    else{
      returnValue.type = 'blank';
    }
    if(languages){
      for(let language of languages){
        if(language.locale !== 'en'){
          returnValue['label_' + language.locale] = '';
        }
      }
    }
    if(['checkbox', 'radio', 'rank'].includes(aType)){
      returnValue.choices = [{label:'', value:''}];
    }
    else if(aType === 'number_choice'){
      returnValue.choices = [{label:'', type:'and', value:''}];
    }
    return returnValue;
  }
  
  newSurvey(aKey){
    return {id:Math.random() * 1000000, key:aKey, error:'', survey_reference:'', isAValidKeyword:false, isAValidSurveyReference:false};
  }
  
  // Methods
  
  detectErrors(){
    const {errors, surveyName, surveyKeyword, unrecognizedKeys, surveyKeywordTaken, adminAlertEmailsArray} = this.state;
    
    if(!surveyName || surveyName.length === 0){
      this.addError('survey', 'survey_name', 'error', 'Survey', 'SURVEY_NAME_MISSING');
    }
    if(!surveyKeyword || surveyKeyword.length === 0){
      this.addError('survey', 'survey_keyword', 'error', 'Survey', 'SURVEY_KEYWORD_MISSING');
    }
    if(surveyKeywordTaken){
      this.addError('survey', 'survey_keyword', 'error', 'Survey', 'SURVEY_KEYWORD_ALREADY_TAKEN');
    }
    if(unrecognizedKeys && unrecognizedKeys.length > 0){
      try{
        let parsedObject = yaml.load(unrecognizedKeys, 'utf8');
        
        if(typeof parsedObject !== 'object'){
          this.addError('survey', 'unrecognizedKeys','error', 'Survey', 'INCORRECT_YAML_FORMAT');
        }
      }
      catch(error){
        console.log(error);
        this.addError('survey', 'unrecognizedKeys','error', 'Survey', 'INCORRECT_YAML_FORMAT');
      }
    }
    let firstAlertQuestion = this.firstAlertQuestion();
    
    if(firstAlertQuestion){
      errors[firstAlertQuestion.id] = [];
      this.setState({errors:errors}, () => {
        let adminEmailsAreValid = (adminAlertEmailsArray.length > 0);
        
        for(let index = 0; index < adminAlertEmailsArray.length; index++){
          let email = adminAlertEmailsArray[index];
          
          if(!emailIsValid(email)){
            adminEmailsAreValid = false;
            this.addError(firstAlertQuestion.id, 'admin_email_' + index, 'error', 'Question', 'INVALID_EMAIL');
          }
        }
        if(!adminEmailsAreValid){
          this.addError(firstAlertQuestion.id, 'admin_emails_missing', 'error', 'Question', 'ADMIN_EMAILS_REQUIRED');
        }
      });
    }
  }
  
  detectWarnings(){
    const {translationsArray} = this.state;
    
    for(let translation of translationsArray){
      let key = KeyForTranslation('title', translation);
      
      if(!this.state[key] || this.state[key].length === 0){
        this.addError('survey', key, 'warning', 'Survey', '' + languageFromLocale(translation.locale) + '_TITLE_MISSING');
      }
    }
  }
  
  firstAlertQuestion(){
    let returnValue = null;
    
    for(let page of this.state.pagesArray){
      if(page.isSurveyPage){
      
      }
      else{
        for(let question of page.questionsArray){
          if(question.safety_alert_question){
            returnValue = question;
            break;
          }
        }
      }
      if(returnValue){
        break;
      }
    }
    return returnValue;
  }
  
  checkAndUpdate(aShouldSaveAsDraft = false){
    const {validateRubularRegex} = this.props;
    const {errors, pagesArray, scrollBounds, translationsArray, selectedTranslation} = this.state;
    this.detectErrors();
    this.detectWarnings();
    
    detectErrorsForPagesArray(pagesArray, errors, translationsArray, validateRubularRegex);
    computeHeightsForPages(pagesArray, scrollBounds.width, selectedTranslation, translationsArray);
    this.setState({errors:errors});
    
    if(aShouldSaveAsDraft){
      this.saveAsDraft(false);
    }
  }
  
  reloadSurveyErrors(){
    let errors = this.state.errors;
    errors['survey'] = [];
    this.setState({errors:errors}, () => {
      this.checkAndUpdate(true);
    });
  }
  
  updateUnrecognizedKeys(){
    this.reloadSurveyErrors();
  }
  
  addSurveyToPageAtIndex(aIndex){
    let {errors, pagesArray} = this.state;
    
    if(aIndex < pagesArray.length){
      let page = pagesArray[aIndex];
      page.surveysArray.push(this.newSurvey('Survey' + (page.surveysArray.length + 1)));
      pagesArray[aIndex] = page;
      errors[page.id] = [];
      this.setState({errors:errors, pagesArray:pagesArray});
    }
  }
  
  addQuestionOfTypeToPageAtIndex(aType, aIndex){
    let {errors, pagesArray} = this.state;
    
    if(aIndex < pagesArray.length){
      let page = pagesArray[aIndex];
      let question = this.newQuestion(aType, '');
      
      if(page.key === 'end' || page.key === 'disqualified'){
        question.exit_to_type = page.key;
      }
      page.questionsArray.push(question);
      errors[page.id] = [];
      pagesArray[aIndex] = page;
      this.setState({errors:errors, pagesArray:pagesArray, selectedQuestion:question}, () => {
        this.checkAndUpdate(true);
      });
    }
  }
  
  addPageAfterPageIndex(aIndex, aSurveyPage){
    let {errors, pagesArray} = this.state;
    let page = this.newPage('Page' + (aIndex + 2));
    page.isSurveyPage = aSurveyPage;
    
    if(aSurveyPage){
      page.surveysArray.push(this.newSurvey('Survey1'));
    }
    pagesArray.splice(aIndex + 1, 0, page);
    
    for(let index = aIndex + 2; index < pagesArray.length; index += 1){
      let pageToRename = pagesArray[index];
      
      if(pageToRename.canBeDeleted){
        pageToRename.key = 'Page' + (index + 1);
        pagesArray[index] = pageToRename;
      }
    }
    errors[page.id] = [];
    this.setState({errors:errors, pagesArray:pagesArray}, () => {
      this.checkAndUpdate(true);
    });
  }
  
  closeMenus(){
    this.setState({pageMenuAnchorElement:null, selectedPage:null, showQuestionSelectModal:false, confirmationModalOpen:false, selectedPageIndex:-1});
  }
  
  deletePage(aPage){
    if(aPage && aPage.canBeDeleted){
      let pagesArray = this.state.pagesArray;
      
      if(pagesArray.length > 3){
        let shouldRename = false;
        let indexToSplice = -1;
        
        for(let index = 0; index < pagesArray.length; index += 1){
          let page = pagesArray[index];
          
          if(shouldRename){
            if(page.canBeDeleted){
              page.key = 'Page' + index;
              pagesArray[index] = page;
            }
          }
          else{
            if(aPage.id === page.id){
              indexToSplice = index;
              shouldRename = true;
            }
          }
        }
        if(indexToSplice !== -1){
          pagesArray.splice(indexToSplice, 1);
          this.setState({pagesArray:pagesArray}, () => {
            this.checkAndUpdate(true);
          });
        }
      }
      else{
        notifyCannotDeleteLastPage();
      }
    }
    this.closeMenus();
  }
  
  checkForDuplicateQuestionKeysForQuestion(aQuestion, aOriginalKey){
    let {errors, pagesArray} = this.state;
    let numberOfQuestionsWithOriginalKey = 0;
    
    for(let page of pagesArray){
      for(let question of page.questionsArray){
        if(question.key === aOriginalKey && question.id !== aQuestion.id){
          numberOfQuestionsWithOriginalKey = numberOfQuestionsWithOriginalKey + 1;
        }
      }
    }
    if(numberOfQuestionsWithOriginalKey < 2){
      for(let page of pagesArray){
        for(let question of page.questionsArray){
          if(question.key === aOriginalKey){
            let errorsArray = [];
            
            for(let existingError of errors[question.id]){
              if(existingError.messageKey !== 'DUPLICATE_QUESTION_KEY'){
                errorsArray.push(existingError);
              }
            }
            errors[question.id] = errorsArray;
          }
        }
      }
    }
    this.setState({errors:errors}, () => {
      for(let page of pagesArray){
        for(let question of page.questionsArray){
          if(question.id !== aQuestion.id){
            if(question.key === aQuestion.key){
              this.addError(question.id, 'key','error', 'Survey', 'DUPLICATE_QUESTION_KEY', {aKey:question.key});
              this.addError(aQuestion.id, 'key','error', 'Survey', 'DUPLICATE_QUESTION_KEY',  {aKey:aQuestion.key});
            }
          }
        }
      }
    });
  }
  
  updateQuestion(aQuestion, aPage){
    if(aPage && aQuestion){
      let {errors, pagesArray, selectedQuestion} = this.state;
      let originalKey = null;
      
      for(let index = 0; index < pagesArray.length; index += 1){
        let hasReplacedQuestion = false;
        let page = pagesArray[index];
        
        if(page.id === aPage.id){
          for(let questionIndex = 0; questionIndex < page.questionsArray.length; questionIndex += 1){
            let question = page.questionsArray[questionIndex];
            
            if(question.id === aQuestion.id){
              originalKey = question.key;
              page.questionsArray[questionIndex] = aQuestion;
              pagesArray[index] = page;
              errors[question.id] = [];
              hasReplacedQuestion = true;
              
              if(selectedQuestion && selectedQuestion.id === aQuestion.id){
                selectedQuestion = aQuestion;
              }
              break;
            }
          }
        }
        if(hasReplacedQuestion){
          break;
        }
      }
      this.setState({errors:errors, pagesArray:pagesArray, selectedQuestion:selectedQuestion}, () => {
        this.checkForDuplicateQuestionKeysForQuestion(aQuestion, originalKey);
        this.checkAndUpdate(true);
      });
    }
  }
  
  updateSurvey(aSurvey, aPage){
    if(aPage && aSurvey){
      let {errors, pagesArray, selectedSurvey} = this.state;
      
      for(let index = 0; index < pagesArray.length; index += 1){
        let hasReplacedQuestion = false;
        let page = pagesArray[index];
        
        if(page.id === aPage.id){
          for(let surveyIndex = 0; surveyIndex < page.surveysArray.length; surveyIndex += 1){
            let survey = page.surveysArray[surveyIndex];
            
            if(survey.id === aSurvey.id){
              page.surveysArray[surveyIndex] = aSurvey;
              pagesArray[index] = page;
              errors[survey.id] = [];
              hasReplacedQuestion = true;
              
              if(selectedSurvey && selectedSurvey.id === aSurvey.id){
                selectedSurvey = aSurvey;
              }
              break;
            }
          }
        }
        if(hasReplacedQuestion){
          break;
        }
      }
      this.setState({errors:errors, pagesArray:pagesArray, selectedSurvey:selectedSurvey}, () => {
        this.checkAndUpdate(true);
      });
    }
  }
  
  surveyHasErrors(){
    let returnValue = false;
    const {errors} = this.state;
    
    for(let key of Object.keys(errors)){
      for(let error of errors[key]){
        if(error.type === 'error'){
          returnValue = true;
          break;
        }
      }
      if(returnValue){
        break;
      }
    }
    return returnValue;
  }
  
  formattedConditionsArray(aConditionsArray){
    let returnValue = [];
    
    for(let condition of aConditionsArray){
      let newCondition = {};
      
      if(condition.is_table){
        newCondition.table = condition.table;
        newCondition.column = condition.column;
      }
      else{
        if(condition.survey === '-1'){
          if(condition.question){
            if(Array.isArray(condition.question)){
              if(condition.question.length === 1){
                newCondition.question = condition.question[0];
              }
              else{
                newCondition.question = condition.question;
              }
            }
            else{
              newCondition.question = condition.question;
            }
          }
        }
        else{
          if(condition.question){
            if(Array.isArray(condition.question)){
              if(condition.question.length === 1){
                if(condition.question[0].startsWith(condition.survey) || condition.survey === null || condition.survey === undefined || condition.survey === '-1'){
                  newCondition.question = condition.question[0];
                }
                else{
                  newCondition.question = condition.survey + '_' + condition.question[0];
                }
              }
              else{
                let questionsArray = [];
                
                for(let question of condition.question){
                  if(question.startsWith(condition.survey) || condition.survey === null || condition.survey === undefined || condition.survey === '-1'){
                    questionsArray.push(question);
                  }
                  else{
                    questionsArray.push(condition.survey + '_' + question);
                  }
                }
                newCondition.question = questionsArray;
              }
            }
            else{
              if(condition.question[0].startsWith(condition.survey) || condition.survey === null || condition.survey === undefined || condition.survey === '-1'){
                newCondition.question = condition.question;
              }
              else{
                newCondition.question = condition.survey + '_' + condition.question;
              }
            }
          }
        }
      }
      if(condition.value){
        if(Array.isArray(condition.value)){
          if(condition.value.length === 1){
            newCondition.value = condition.value[0];
          }
          else{
            newCondition.value = condition.value;
          }
        }
        else{
          newCondition.value = condition.value;
        }
      }
      if(condition.parse_to_age){
        if(Array.isArray(condition.parse_to_age)){
          if(condition.parse_to_age.length === 1){
            if(condition.parse_to_age[0]){
              newCondition.parse_to_age = condition.parse_to_age[0];
            }
          }
          else{
            let addParseToAge = false;
            
            for(let parseToAge of condition.parse_to_age){
              if(parseToAge){
                addParseToAge = true;
                break;
              }
            }
            if(addParseToAge){
              newCondition.parse_to_age = condition.parse_to_age;
            }
          }
        }
        else{
          newCondition.parse_to_age = condition.parse_to_age;
        }
      }
      newCondition.operation = condition.operation;
      
      if(condition.condition_operator && condition.condition_operator !== 'and'){
        newCondition.condition_operator = condition.condition_operator;
      }
      returnValue.push(newCondition);
    }
    return returnValue;
  }
  
  generateYAML(){
    const {header, largeSize, pagesArray, adminAlertEmailsArray} = this.state;
    let validPageKeysArray = ['size', 'pages', 'header', 'surveys', 'questions', 'description', 'admin_emails'];
    let returnValue = {};
    
    for(let translation of this.state.translationsArray){
      for(let name of ['title', 'description']){
        let key = KeyForTranslation(name, translation);
        validPageKeysArray.push(key);
        
        if(this.state[key] || this.state[key].length > 0){
          returnValue[key] = this.state[key];
        }
      }
      validPageKeysArray.push(KeyForTranslation('page_titles', translation));
    }
    if(largeSize){
      returnValue.size = 'large';
    }
    if(header && header.length > 0){
      returnValue.header = header;
    }
    if(adminAlertEmailsArray && adminAlertEmailsArray.length > 0){
      returnValue.admin_emails = adminAlertEmailsArray;
    }
    
    try{
      let parsedObject = yaml.load(this.state.unrecognizedKeys, 'utf8');
      
      if(typeof parsedObject === 'object'){
        for(let key of Object.keys(parsedObject)){
          if(!validPageKeysArray.includes(key)){
            returnValue[key] = parsedObject[key];
          }
        }
      }
    }
    catch(error){
      console.log(error);
    }
    
    returnValue.pages = {};
    // TODO: Delete empty keys, like column_value.
    const invalidPageKeysArray = ['id', 'key', 'isSurveyPage', 'surveysArray', 'questionsArray', 'showAddPageButtons', 'canBeDeleted'];
    
    for(let page of pagesArray){
      returnValue.pages[page.key] = {};
      
      for(let key of Object.keys(page)){
        if(!invalidPageKeysArray.includes(key)){
          if(page[key] && page[key].length > 0){
            returnValue.pages[page.key][key] = page[key];
          }
        }
      }
      if(page.isSurveyPage && page.surveysArray.length > 0){
        let surveys = {};
        // TODO: Update.
        const invalidSurveyKeysArray = ['id', 'key', 'isSurveyPage', 'surveysArray', 'questionsArray', 'showAddPageButtons', 'canBeDeleted', 'doesNotExist'];
        
        for(let survey of page.surveysArray){
          surveys[survey.key] = {};
          
          for(let key of Object.keys(survey)){
            if((key === 'conditions' || key === 'exit_to_conditions') && survey[key]){
              if(surveys[survey.key]){
                let conditionsArray = this.formattedConditionsArray(survey[key]);
                
                if(conditionsArray && conditionsArray.length > 0){
                  surveys[survey.key][key] = conditionsArray;
                }
              }
            }
            else if(!invalidSurveyKeysArray.includes(key)){
              if(survey[key]){
                surveys[survey.key][key] = survey[key];
              }
            }
          }
          if(objectIsEmpty(surveys[survey.key])){
            delete surveys[survey.key];
          }
        }
        returnValue.pages[page.key].surveys = surveys;
        
        if(objectIsEmpty(returnValue.pages[page.key].surveys)){
          delete returnValue.pages[page.key].surveys;
        }
      }
      else if(page.questionsArray.length > 0){
        let questions = {};
        // TODO: Update.
        let invalidQuestionKeysArray = ['y', 'id', 'key', 'width', 'height'];
        
        for(let index = 0; index < page.questionsArray.length; index += 1){
          let question = page.questionsArray[index];
          let questionKey = question.key;
          
          if(questionKey === undefined || questionKey.length === 0){
            questionKey = page.key + '-question-' + (index + 1);
          }
          questions[questionKey] = {};
          
          for(let key of Object.keys(question)){
            if((key === 'conditions' || key === 'exit_to_conditions') && question[key]){
              if(questions[questionKey]){
                let conditionsArray = this.formattedConditionsArray(question[key]);
                
                if(conditionsArray && conditionsArray.length > 0){
                  questions[questionKey][key] = conditionsArray;
                }
              }
            }
            else if(!invalidQuestionKeysArray.includes(key)){
              if(question[key]){
                questions[questionKey][key] = question[key];
              }
            }
          }
        }
        if(page.key === 'end' || page.key === 'disqualified'){
          let questionKeys = Object.keys(questions);
          
          for(let index = 0; index < questionKeys.length; index += 1){
            let key = questionKeys[index];
            returnValue.pages[page.key + (index + 1)] = {questions:{[key]:questions[key]}};
          }
        }
        else{
          returnValue.pages[page.key].questions = questions;
        }
      }
      if(objectIsEmpty(returnValue.pages[page.key])){
        delete returnValue.pages[page.key];
      }
    }
    let yamlString = yaml.dump(returnValue, { quotingType:'"', forceQuotes:true });
    console.log(yamlString); // TODO: Remove once debugged.
    return yamlString;
  }
  
  saveAsDraft(aPublishWhenFinished = false){
    const {surveyVersionCollection, surveyName, surveyKeyword, repeatingSurvey, savingAsDraft, adminSurvey, termsAndPrivacySurvey} = this.state;
    
    if(savingAsDraft){
      this.setState({shouldSaveWhenFinished:true});
    }
    else{
      let dataYAML = this.generateYAML();
      console.log(dataYAML);
      
      if(surveyName && surveyName.length > 0 && dataYAML && dataYAML.length > 0){
        this.setState({savingAsDraft:true});
        let surveyVersionCollectionId = null;
        
        if(surveyVersionCollection){
          surveyVersionCollectionId = surveyVersionCollection.id;
        }
        this.props.saveSurveyVersionCollection(surveyVersionCollectionId, surveyName, dataYAML, surveyKeyword, repeatingSurvey, adminSurvey, termsAndPrivacySurvey).then((newResponse) => {
          const { shouldSaveWhenFinished, pastSurveyVersionsArray } = this.state;
          let surveyVersionCollection = newResponse.data.data.saveSurveyVersionCollectionMutation.survey_version_collection;
          let addDraftSurveyToPastSurveys = true;
          
          for(let survey of pastSurveyVersionsArray){
            if(survey.id === surveyVersionCollection.draft_survey.id){
              addDraftSurveyToPastSurveys = false;
              break;
            }
          }
          if(addDraftSurveyToPastSurveys){
            pastSurveyVersionsArray.push(surveyVersionCollection.draft_survey);
          }
          this.setState({savingAsDraft:false, shouldSaveWhenFinished:false, selectedSurveyVersion:surveyVersionCollection.draft_survey.version_number, surveyVersionCollection:surveyVersionCollection, pastSurveyVersionsArray:pastSurveyVersionsArray}, () => {
            if(shouldSaveWhenFinished){
              this.saveAsDraft(aPublishWhenFinished);
            }
            else if(aPublishWhenFinished){
              this.publish();
            }
          });
        }, (newError) => {
          this.setState({savingAsDraft:false, shouldSaveWhenFinished:false});
        });
      }
    }
  }
  
  publish(){
    if(this.surveyHasErrors()){
      notifyCannotPublish();
    }
    else{
      const { surveyVersionCollection } = this.state;
      
      if(surveyVersionCollection.draft_survey){
        this.setState({isPublishing:true});
        this.props.publishSurveyVersionCollection(surveyVersionCollection.id).then((newResponse) => {
          this.setState({isPublishing:false});
          notifyPublishSuccess();
        }, (newError) => {
          this.setState({isPublishing:false});
          
          let error = '';
          
          if(newError && newError.data && newError.data && newError.data.error){
            error = newError.data.error;
          }
          notifyPublishError(error);
        });
      }
      else{
        this.saveAsDraft(true);
      }
    }
  }
  
  preview(){
    if(this.surveyHasErrors()){
      notifyCannotPreview();
    }
    else{
      this.setState({isDrawerOpen:true});
    }
  }
  
  formatQuestionChoices(aQuestion){
    let choices = aQuestion['choices'];
    
    if(choices && choices.length > 0){
      let formattedChoices = [];
      
      for(let index = 0; index < choices.length; index += 1){
        let choice = choices[index];
        
        if(typeof choice === 'string'){
          formattedChoices[index] = {label:choice, value:choice};
        }
        else{
          let keysArray = Object.keys(choice);
          
          if(keysArray && keysArray.length > 0){
            if(keysArray.includes('value') || keysArray.includes('label') || keysArray.includes('type')){
              let filteredChoice = {};
              
              if(keysArray.includes('value')){
                filteredChoice['value'] = choice['value'];
              }
              if(keysArray.includes('type') && ['and', 'or', 'xor'].includes(choice['type'])){
                filteredChoice['type'] = choice['type'];
              }
              for(let language of this.props.languages){
                let labelKey = KeyForTranslation('label', language);
                
                if(keysArray.includes(labelKey)){
                  filteredChoice[labelKey] = choice[labelKey];
                }
              }
              formattedChoices[index] = filteredChoice;
            }
            else{
              let firstKey = keysArray[0];
              formattedChoices[index] = {label:choice[firstKey], value:firstKey};
            }
          }
        }
      }
      aQuestion['choices'] = formattedChoices;
    }
  }
  
  formatQuestionConditions(aQuestion, aKey, aSurveysArray, aQuestionsArray){
    let conditions = aQuestion[aKey];
    
    // TODO: Questions can be an array?
    if(conditions && conditions.length > 0){
      let formattedConditions = [];
      
      for(let index = 0; index < conditions.length; index += 1){
        let condition = conditions[index];
        let formattedCondition = {table:'', value:[''], column:'', survey:'-1', is_table:false, survey_loop_index:0, question:[''], operation:'equal', reference:'', question_type:[''], parse_to_age:[false], condition_operator:'and'};
        
        if(condition.value){
          if(Array.isArray(condition.value)){
            formattedCondition.value = condition.value;
          }
          else{
            formattedCondition.value = [condition.value];
          }
        }
        if(condition.condition_operator === 'or'){
          formattedCondition.condition_operator = condition.condition_operator;
        }
        if(condition.parse_to_age){
          if(Array.isArray(condition.parse_to_age)){
            formattedCondition.parse_to_age = condition.parse_to_age;
          }
          else{
            formattedCondition.parse_to_age = [true];
          }
        }
        if(operationsArray.includes(condition.operation)){
          formattedCondition.operation = condition.operation;
        }
        if(allowedWriteTables.includes(condition.table)){
          formattedCondition.table = condition.table;
          formattedCondition.is_table = true;
          formattedCondition.column = condition.column;
        }
        else if(condition.question){
          let questionReferencesArray = [];
          
          if(Array.isArray(condition.question)){
            questionReferencesArray = condition.question;
          }
          else{
            questionReferencesArray = [condition.question];
          }
          let survey = null;
          let surveyReference = null;
          
          for(let questionReference of questionReferencesArray){
            for(let survey of aSurveysArray){
              if(questionReference.startsWith(survey.key)){
                formattedCondition.survey = survey.key;
                formattedCondition.reference = survey.survey_reference;
                // TODO: This will require a fetch of the above survey reference to ensure the question key is in that
                //       survey, and an error log if it's not.
                questionReference.slice(survey.key.length + 1);
                break;
              }
            }
            if(survey !== null){
              break;
            }
          }
          if(survey !== null){
            formattedCondition.survey = survey;
            formattedCondition.reference = surveyReference;
          }
          let questionsArray = [];
          let questionTypesArray = [];
          
          for(let questionReference of questionReferencesArray){
            if(survey !== null){
              questionReference.slice(survey.length + 1);
            }
            let didFindQuestionKey = false;
            
            for(let question of aQuestionsArray){
              if(questionReference === question.key){
                didFindQuestionKey = true;
                questionsArray.push(question.key);
                questionTypesArray.push(question.type);
                
                if(question.key.length < questionReference.length){
                  questionReference.slice(question.key.length + 1);
                  
                  if(questionReference.length > 0){
                    // TODO: Add to Survey Builder Condition Cell.
                    formattedCondition.survey_loop_index = Number(questionReference);
                  }
                }
                break;
              }
            }
            if(!didFindQuestionKey){
              questionsArray.push(questionReference);
              questionTypesArray.push('');
            }
          }
          formattedCondition.question = questionsArray;
          formattedCondition.question_type = questionTypesArray;
          
          let arrays = [formattedCondition.question, formattedCondition.question_type, formattedCondition.value, formattedCondition.parse_to_age];
          let maxLength = 0;
          
          for(let array of arrays){
            if(array.length > maxLength){
              maxLength = array.length;
            }
          }
          for(let array of arrays){
            if(array.length < maxLength){
              let startLength = array.length;
              
              for(let count = 0; count < (maxLength - startLength); count += 1){
                if(array === formattedCondition.parse_to_age){
                  array.push(false);
                }
                else{
                  array.push('');
                }
              }
            }
          }
        }
        // TODO: Add in error messages for each case.
        formattedConditions[index] = formattedCondition;
      }
      aQuestion[aKey] = formattedConditions;
    }
  }
  
  formatQuestion(aQuestion, aSurveysArray, aQuestionsArray){
    this.formatQuestionChoices(aQuestion);
    this.formatQuestionConditions(aQuestion, 'conditions', aSurveysArray, aQuestionsArray);
    this.formatQuestionConditions(aQuestion, 'exit_to_conditions', aSurveysArray, aQuestionsArray);
    // TODO: Remove all unused keys from Question based on Type.
  }
  
  loadAndParseYAML(aYAML){
    const {languages} = this.props;
    
    try{
      let parsedObject = yaml.load(aYAML, 'utf8');
      let recognizedKeysArray = ['size', 'pages', 'title', 'header', 'questions', 'description', 'admin_emails'];
      
      for(let translation of languages){
        if(translation.locale !== 'en'){
          recognizedKeysArray.push('title_' + translation.locale);
          recognizedKeysArray.push('description_' + translation.locale);
        }
      }
      let isNewFormat = true;
      let state = {};
      let languageKeys = {};
      let unrecognizedKeys = {};
      
      for(let key of Object.keys(parsedObject)){
        if(recognizedKeysArray.includes(key)){
          if(key === 'questions'){
            isNewFormat = false;
          }
          else if(key === 'pages'){
            isNewFormat = true;
          }
          else if(key === 'header'){
            state.header = parsedObject[key];
          }
          else if(key === 'size'){
            state.largeSize = (parsedObject[key] === 'large');
          }
          else if(key === 'admin_emails'){
            state.adminAlertEmailsArray = parsedObject[key];
          }
          else{
            if(key.includes('title_')){
              languageKeys[key] = true;
            }
            state[key] = parsedObject[key];
          }
        }
        else{
          unrecognizedKeys[key] = parsedObject[key];// TODO: Finish implementing unrecognizedKeys for Survey, Pages, Questions [formatQuestion], and Nested Surveys.
        }
      }
      let pagesArray = [];
      
      if(isNewFormat){
        let pageIndex = 1;
        let endQuestionsArray = [];
        let disqualifiedQuestionsArray = [];
        
        for(let key of Object.keys(parsedObject.pages)){
          let page = this.newPage('Page' + pageIndex);
          let parsedPage = parsedObject.pages[key];
          
          for(let pageKey of Object.keys(parsedPage)){
            if(pageKey === 'questions'){
              let questionsArray = [];
              let parsedQuestions = parsedPage['questions'];
              
              for(let questionKey of Object.keys(parsedQuestions)){
                let isEndQuestion = false;
                let isDisqualificationQuestion = false;
                let question = this.newQuestion('blank', questionKey);
                let mergedQuestion = {...question, ...parsedQuestions[questionKey]};
                
                for(let questionPropertyKey of Object.keys(mergedQuestion)){
                  if(questionPropertyKey.includes('label_') && mergedQuestion[questionPropertyKey]){
                    languageKeys[questionPropertyKey] = true;
                  }
                  if(mergedQuestion.type === 'dynamic_list' && questionPropertyKey.includes('dynamic_list_reference_')){
                    languageKeys[questionPropertyKey] = true;
                  }
                  if(questionPropertyKey === 'choices'){
                    for(let choice of mergedQuestion.choices){
                      for(let choicePropertyKey of Object.keys(choice)){
                        if(choicePropertyKey.includes('label_')){
                          languageKeys[choicePropertyKey] = true;
                        }
                      }
                    }
                  }
                  if(questionPropertyKey === 'exit_to_type'){
                    if(mergedQuestion[questionPropertyKey] === 'end'){
                      isEndQuestion = true;
                    }
                    else if(mergedQuestion[questionPropertyKey] === 'disqualified'){
                      isDisqualificationQuestion = true;
                    }
                  }
                }
                if(isEndQuestion){
                  endQuestionsArray.push(mergedQuestion);
                }
                else if(isDisqualificationQuestion){
                  disqualifiedQuestionsArray.push(mergedQuestion);
                }
                else{
                  questionsArray.push(mergedQuestion);
                }
              }
              if(questionsArray.length > 0){
                page.questionsArray = questionsArray;
              }
            }
            else if(pageKey === 'surveys'){
              let surveysArray = [];
              let parsedSurveys = parsedPage['surveys'];
              
              for(let surveyKey of Object.keys(parsedSurveys)){
                surveysArray.push({...this.newSurvey(surveyKey), ...parsedSurveys[surveyKey]});
              }
              page.isSurveyPage = true;
              page.surveysArray = surveysArray;
            }
            else{
              if(pageKey.includes('page_title_') ){
                languageKeys[pageKey] = true;
              }
              page[pageKey] = parsedPage[pageKey];
            }
          }
          let hasTitle = false;
          
          for(let language of languages){
            let pageTitleKey = KeyForTranslation('page_title', language);
            
            if(page[pageTitleKey] && page[pageTitleKey].length > 0){
              hasTitle = true;
              break;
            }
          }
          if(hasTitle || page.questionsArray.length > 0 || page.surveysArray.length > 0){
            pagesArray.push(page);
            pageIndex += 1;
          }
        }
        let endPage = this.newPage('end');
        endPage.questionsArray = endQuestionsArray;
        pagesArray.push(endPage);
        
        let disqualifiedPage = this.newPage('disqualified');
        disqualifiedPage.questionsArray = disqualifiedQuestionsArray;
        pagesArray.push(disqualifiedPage);
      }
      else{
        let pages = {};
        let currentPageNumber = 0;
        let pageTitlesArray = parsedObject['page_titles'];
        let parsedQuestions = parsedObject['questions'];
        let endQuestionsArray = [];
        let disqualifiedQuestionsArray = [];
        
        for(let questionKey of Object.keys(parsedQuestions)){
          let question = this.newQuestion('blank', questionKey);
          let mergedQuestion = {...question, ...parsedQuestions[questionKey]};
          let isEndQuestion = false;
          let isDisqualificationQuestion = false;
          
          // TODO: Move to separate function.
          // TODO: If type monthyear, add detected labels to languageKeys.
          for(let questionPropertyKey of Object.keys(mergedQuestion)){
            if(questionPropertyKey.includes('label_') && mergedQuestion[questionPropertyKey]){
              languageKeys[questionPropertyKey] = true;
            }
            if(mergedQuestion.type === 'dynamic_list' && questionPropertyKey.includes('dynamic_list_reference_')){
              languageKeys[questionPropertyKey] = true;
            }
            if(questionPropertyKey === 'choices'){
              for(let choice of mergedQuestion.choices){
                for(let choicePropertyKey of Object.keys(choice)){
                  if(choicePropertyKey.includes('label_')){
                    languageKeys[choicePropertyKey] = true;
                  }
                }
              }
            }
            if(questionPropertyKey === 'exit_to_type'){
              if(mergedQuestion[questionPropertyKey] === 'end'){
                isEndQuestion = true;
              }
              else if(mergedQuestion[questionPropertyKey] === 'disqualified'){
                isDisqualificationQuestion = true;
              }
            }
          }
          if(isEndQuestion){
            endQuestionsArray.push(mergedQuestion);
          }
          else if(isDisqualificationQuestion){
            disqualifiedQuestionsArray.push(mergedQuestion);
          }
          else{
            let page = null;
            let pageNumber = mergedQuestion['page_number'];
            
            if(pageNumber){
              pageNumber = Number(pageNumber);
              delete mergedQuestion['page_number'];
            }
            else{
              pageNumber = currentPageNumber + 1;
            }
            if(pages[pageNumber]){
              page = pages[pageNumber];
            }
            else{
              page = this.newPage('Page' + pageNumber);
            }
            if(pageTitlesArray && (pageNumber - 1) < pageTitlesArray.length){
              page['page_title'] = pageTitlesArray[(pageNumber - 1)];
            }
            page.questionsArray.push(mergedQuestion);
            currentPageNumber = pageNumber;
            pages[pageNumber] = page;
          }
        }
        let pageIndex = 1;
        
        for(let pageKey of Object.keys(pages).sort(function (a, b) { return a - b; })){
          if(pageIndex < Number(pageKey)){
            while(pageIndex < Number(pageKey)){
              pagesArray.push(this.newPage('Page' + pageIndex));
              pageIndex = pageIndex + 1;
            }
          }
          pagesArray.push(pages[pageKey]);
          pageIndex = pageIndex + 1;
        }
        
        let endPage = this.newPage('end');
        endPage.questionsArray = endQuestionsArray;
        pagesArray.push(endPage);
        
        let disqualifiedPage = this.newPage('disqualified');
        disqualifiedPage.questionsArray = disqualifiedQuestionsArray;
        pagesArray.push(disqualifiedPage);
        
        notifyConvertOldFormatSuccess();
      }
      
      let surveysArray = [];
      let questionsArray = [];
      
      for(let page of pagesArray){
        if(page.isSurveyPage){
          for(let survey of page.surveysArray){
            surveysArray.push(survey);
          }
        }
        else{
          for(let question of page.questionsArray){
            questionsArray.push(question);
          }
        }
      }
      
      for(let page of pagesArray){
        if(page.isSurveyPage){
          for(let survey of page.surveysArray){
            this.formatQuestion(survey, surveysArray, questionsArray);
          }
        }
        else{
          for(let question of page.questionsArray){
            this.formatQuestion(question, surveysArray, questionsArray);
          }
        }
      }
      
      let translationsArray = [{
        title:"English",
        locale:"en",
        display_name:"English (US)"
      }];
      
      for(let translation of languages){
        if(translation.locale !== 'en'){
          for(let languageKey of Object.keys(languageKeys)){
            if(languageKey.includes('_' + translation.locale)){
              let alreadyIncluded = false;
              
              for(let includedTranslation of translationsArray){
                if(includedTranslation.locale === translation.locale){
                  alreadyIncluded = true;
                  break;
                }
              }
              if(!alreadyIncluded){
                translationsArray.push(translation);
              }
            }
          }
        }
      }
      let unrecognizedKeysString = '';
      
      if(!objectIsEmpty(unrecognizedKeys)){
        unrecognizedKeysString = yaml.dump(unrecognizedKeys, { quotingType:'"', forceQuotes:true });
      }
      let scrollBounds = this.state.scrollBounds;
      scrollBounds.height = 0;
      state.scrollBounds = scrollBounds;
      this.setState(state, () => {
        this.setState({
          errors:{},
          pagesArray:pagesArray,
          scrollBounds:scrollBounds,
          translationsArray:translationsArray,
          unrecognizedKeys:unrecognizedKeysString}, () => {
          this.checkAndUpdate(false);
        });
      });
    }
    catch(error){
      console.log(error);
      notifyYAMLFormatError(error.message);
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(error.message));
      element.setAttribute('download', error.name + '.txt');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
  
  loadSurvey(){
    const { surveyVersionCollectionId, fetchSurveyVersionCollectionById, fetchSurveysForSurveyVersionCollection } = this.props;
    
    if(surveyVersionCollectionId){
      // TODO: Move this to the Infinite Scrolling DropDown once it's completed.
      fetchSurveysForSurveyVersionCollection(surveyVersionCollectionId, 100, 0, '').then((newResponse) => {
        let pastSurveyVersionsArray = newResponse.data.data.surveys_in_survey_version_collection.surveys;
        let selectedSurveyVersion = pastSurveyVersionsArray[0].version_number;
        this.setState({selectedSurveyVersion:selectedSurveyVersion, pastSurveyVersionsArray:pastSurveyVersionsArray});
      }, (newError) => {
        // TODO: Add error handling.
      });
      
      fetchSurveyVersionCollectionById(surveyVersionCollectionId).then(((newResponse) => {
        let surveyVersionCollection = newResponse.data.data.survey_version_collection;
        
        if(surveyVersionCollection){
          let survey = surveyVersionCollection.draft_survey;
          
          if(!survey){
            survey = surveyVersionCollection.current_survey;
          }
          this.setState({
            surveyName:surveyVersionCollection.name,
            adminSurvey:Boolean(survey.admin_only),
            surveyKeyword:surveyVersionCollection.reference,
            repeatingSurvey:Boolean(survey.is_repeatable),
            termsAndPrivacySurvey:Boolean(survey.terms_and_privacy),
            selectedSurveyVersion:survey.version_number,
            surveyVersionCollection:surveyVersionCollection
          }, () => {
            this.loadAndParseYAML(survey.data);
          });
        }
        else{
          // TODO: Error message.
        }
      }));
    }
    else{
      this.createNewSurvey();
    }
  }
  
  createNewSurvey(){
    let pagesArray = [];
    
    let introPage = this.newPage('Page1');
    introPage.questionsArray.push(this.newQuestion('blank', 'intro'));
    pagesArray.push(introPage);
    
    let endPage = this.newPage('end');
    pagesArray.push(endPage);
    
    let disqualifiedPage = this.newPage('disqualified');
    pagesArray.push(disqualifiedPage);
    
    this.setState({pagesArray:pagesArray, errors:{}}, () => {
      this.checkAndUpdate(false);
    });
  }
  
  uploadYAML(aEvent){
    if(aEvent && aEvent.target && aEvent.target.files && aEvent.target.files.length > 0){
      const reader = new FileReader();
      reader.readAsText(aEvent.target.files[0]);
      
      reader.onload = (aFileReadEvent) => {
        this.loadAndParseYAML(aFileReadEvent.target.result);
        // TODO: Reset selected language to English.
      }
    }
  }
  
  downloadYAML(){
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.generateYAML()));
    element.setAttribute('download', this.state.surveyName + '.yaml');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  
  deleteSurvey(aSurvey){
    if(aSurvey){
      let {errors, pagesArray} = this.state;
      
      for(let index = 0; index < pagesArray.length; index += 1){
        let hasReplacedSurvey = false;
        let page = pagesArray[index];
        
        if(page.isSurveyPage){
          for(let surveyIndex = 0; surveyIndex < page.surveysArray.length; surveyIndex += 1){
            let survey = page.surveysArray[surveyIndex];
            
            if(survey.id === aSurvey.id){
              page.surveysArray.splice(surveyIndex, 1);
              pagesArray[index] = page;
              errors[page.id] = [];
              errors[survey.id] = [];
              hasReplacedSurvey = true;
              break;
            }
          }
          if(hasReplacedSurvey){
            break;
          }
        }
      }
      this.setState({errors:errors, pagesArray:pagesArray, selectedSurvey:null}, () => {
        this.checkAndUpdate(true);
      });
    }
  }
  
  deleteQuestion(aQuestion){
    if(aQuestion){
      let {errors, pagesArray} = this.state;
      
      for(let index = 0; index < pagesArray.length; index += 1){
        let hasReplacedQuestion = false;
        let page = pagesArray[index];
        
        for(let questionIndex = 0; questionIndex < page.questionsArray.length; questionIndex += 1){
          let question = page.questionsArray[questionIndex];
          
          if(question.id === aQuestion.id){
            page.questionsArray.splice(questionIndex, 1);
            pagesArray[index] = page;
            errors[page.id] = [];
            errors[question.id] = [];
            hasReplacedQuestion = true;
            break;
          }
        }
        if(hasReplacedQuestion){
          break;
        }
      }
      this.setState({errors:errors, pagesArray:pagesArray, selectedQuestion:null}, () => {
        this.checkAndUpdate(true);
      });
    }
  }
  
  scrollToRefWithId(aId){
    const {pagesArray} = this.state;
    let scrollY = -1;
    
    if(aId === 'survey'){
      scrollY = 50;
    }
    else{
      for(const page of pagesArray){
        if(page.id === aId){
          scrollY = page.y;
        }
        else if(page.isSurveyPage){
          for(const survey of page.surveysArray){
            if(survey.id === aId){
              scrollY = survey.y;
              break;
            }
          }
        }
        else{
          for(const question of page.questionsArray){
            if(question.id === aId){
              scrollY = question.y;
              break;
            }
          }
        }
        if(scrollY >= 0){
          break;
        }
      }
    }
    if(scrollY >= 0){
      animateScroll.scrollTo(scrollY, {delay:0, smooth:'linear', duration:10, horizontal:false, containerId:'survey-builder-scroll-container-id', ignoreCancelEvents:true});
    }
  }
  
  updatePage(aPage){
    if(aPage){
      let {errors, pagesArray} = this.state;
      
      for(let index = 0; index < pagesArray.length; index += 1){
        let page = pagesArray[index];
        
        if(page.id === aPage.id){
          pagesArray[index] = aPage;
          errors[page.id] = [];
          this.setState({errors:errors, pagesArray:pagesArray}, () => {
            this.checkAndUpdate(true);
          });
          break;
        }
      }
    }
  }
  
  addError(aId, aKey, aType, aTitle, aMessageKey, aMessageKeyParams = {}){
    if(aId && aKey && aType && aTitle && aMessageKey){
      let error = {id:aId, key:aKey, type:aType, title:aTitle, message:i18next.t(aMessageKey, aMessageKeyParams), messageKey:aMessageKey};
      let errors = this.state.errors;
      let errorsArray = errors[aId];
      
      if(errorsArray){
        let duplicateFound = false;
        
        for(let existingError of errorsArray){
          if(error.id === existingError.id &&
            error.key === existingError.key &&
            error.type === existingError.type &&
            error.title === existingError.title &&
            error.messageKey === existingError.messageKey){
            duplicateFound = true;
            break;
          }
        }
        if(!duplicateFound){
          errorsArray.push(error);
        }
      }
      else{
        errorsArray = [error];
      }
      errors[aId] = errorsArray;
      this.setState({errors:errors});
    }
  }
  
  pageForSurvey(aSurvey){
    let returnValue = null;
    
    if(aSurvey){
      for(let page of this.state.pagesArray){
        for(let survey of page.surveysArray){
          if(survey.id === aSurvey.id){
            returnValue = page;
            break;
          }
        }
        if(returnValue !== null){
          break;
        }
      }
    }
    return returnValue;
  }
  
  pageForQuestion(aQuestion){
    let returnValue = null;
    
    if(aQuestion){
      for(let page of this.state.pagesArray){
        for(let question of page.questionsArray){
          if(question.id === aQuestion.id){
            returnValue = page;
            break;
          }
        }
        if(returnValue !== null){
          break;
        }
      }
    }
    return returnValue;
  }
  
  addOrRemoveTranslation(aTranslation){
    let {translationsArray, selectedTranslation} = this.state;
    
    if(aTranslation.locale !== 'en'){
      if(translationsArray && translationsArray.length > 0){
        let indexToRemove = -1;
        
        for(let index = 0; index < translationsArray.length; index += 1){
          let translation = translationsArray[index];
          
          if(translation.locale === aTranslation.locale){
            indexToRemove = index;
            break;
          }
        }
        if(indexToRemove === -1){
          translationsArray.push(aTranslation);
        }
        else{
          if(selectedTranslation.locale === aTranslation.locale){
            for(let translation of this.props.languages){
              if(translation.locale === 'en'){
                selectedTranslation = translation;
                break;
              }
            }
          }
          translationsArray.splice(indexToRemove, 1);
        }
        this.setState({errors:{}, translationsArray:translationsArray, selectedTranslation:selectedTranslation}, () => {
          this.checkAndUpdate(true);
        });
      }
    }
  }
  
  updateSurveyReferenceKey(aKey){
    this.props.fetchSurveyVersionCollectionByReference(aKey).then((newResponse) => {
      let surveyVersionCollection = newResponse.data.data.survey_version_collection;
      let surveyKeywordTaken = false;
      
      if(surveyVersionCollection){
        // TODO: Check ID, and first create.
        // TODO: Server not checking company id? Shouldn't default company ones be added?
        surveyKeywordTaken = true;
      }
      let errors = this.state.errors;
      errors['survey'] = [];
      this.setState({errors:errors, surveyKeyword:aKey, surveyKeywordTaken:surveyKeywordTaken}, () => {
        this.checkAndUpdate(true);
      })
    }, (newError) => {
    
    });
  }
  
  moveSurveyFromPageToPage(aSurvey, aFromPage, aToPage){
    if(aSurvey && aFromPage && aToPage && aFromPage.isSurveyPage && aToPage.isSurveyPage){
      if(aFromPage.surveysArray && aFromPage.surveysArray.includes(aSurvey)){
        let {errors, pagesArray} = this.state;
        
        for(let index = 0; index < pagesArray.length; index += 1){
          let page = pagesArray[index];
          
          if(page.id === aFromPage.id){
            for(let surveyIndex = 0; surveyIndex < page.surveysArray.length; surveyIndex += 1){
              let survey = page.surveysArray[surveyIndex];
              
              if(survey.id === aSurvey.id){
                page.surveysArray.splice(surveyIndex, 1);
                pagesArray[index] = page;
                errors[page.id] = [];
                break;
              }
            }
          }
          if(page.id === aToPage.id){
            page.surveysArray.push(aSurvey);
            pagesArray[index] = page;
            errors[page.id] = [];
          }
        }
        this.setState({errors:errors, pagesArray:pagesArray}, () => {
          this.checkAndUpdate(true);
        });
      }
    }
  }
  
  moveQuestionFromPageToPage(aQuestion, aFromPage, aToPage){
    if(aQuestion && aFromPage && aToPage && !aFromPage.isSurveyPage && !aToPage.isSurveyPage){
      if(aFromPage.questionsArray && aFromPage.questionsArray.includes(aQuestion)){
        let {errors, pagesArray} = this.state;
        
        for(let index = 0; index < pagesArray.length; index += 1){
          let page = pagesArray[index];
          
          if(page.id === aFromPage.id){
            for(let questionIndex = 0; questionIndex < page.questionsArray.length; questionIndex += 1){
              let question = page.questionsArray[questionIndex];
              
              if(question.id === aQuestion.id){
                page.questionsArray.splice(questionIndex, 1);
                errors[page.id] = [];
                pagesArray[index] = page;
                break;
              }
            }
          }
          if(page.id === aToPage.id){
            if(aToPage.key === 'end' || aToPage.key === 'disqualified'){
              aQuestion.exit_to_type = page.key;
            }
            else if(aQuestion.exit_to_type || aQuestion.exit_to_type !== undefined){
              delete aQuestion['exit_to_type'];
            }
            page.questionsArray.push(aQuestion);
            errors[page.id] = [];
            pagesArray[index] = page;
          }
        }
        this.setState({errors:errors, pagesArray:pagesArray}, () => {
          this.checkAndUpdate(true);
        });
      }
    }
  }
  
  onScroll(aEvent){
    if(this.scrollRef && this.scrollRef.current && this.scrollRef.current.getBoundingClientRect()){
      if(aEvent && aEvent.currentTarget && aEvent.currentTarget.getBoundingClientRect()){
        const bounds = this.scrollRef.current.getBoundingClientRect();
        this.setState({scrollBounds:{x:0, y:180 - bounds.y, width:bounds.width, height:aEvent.currentTarget.getBoundingClientRect().height}});
      }
    }
  }
  
  // Render
  
  render() {
    const {user, client, company, languages, surveyVersionCollectionId, fetchSurveyVersionCollections, fetchSurveyVersionCollectionById,
      fetchSurveyVersionCollectionByReference, fetchQuestionsForSurveyByReference, fetchDynamicLists, fetchDynamicListByReference,
      fetchSurveyLists, fetchSurveyListById, fetchSurveyListByReference, validateRubularRegex, fetchSurveyById, addSurveyList,
      fetchAppAdditionalColumns, fetchDynamicListById, readOnly = false, isInModal = false} = this.props;
    const {errors, confirmationModalOpen, surveyName, surveyKeyword, isDrawerOpen, largeSize, pastSurveyVersionsArray,
      selectedTranslation, selectedSurveyVersion, repeatingSurvey, termsAndPrivacySurvey, adminSurvey, pagesArray,
      selectedQuestion, selectedPage, pageMenuAnchorElement, showQuestionSelectModal, selectedPageIndex, translationsArray,
      savingAsDraft, isPublishing, isSticky, selectedSurvey, surveyVersionCollection, adminAlertEmailsArray, header,
      unrecognizedKeys, reorderType, scrollBounds} = this.state;
    
    let pastVersionOptionsArray = [];
    
    for(let survey of pastSurveyVersionsArray){
      let adminName = formatUserName(survey.updated_by_user);
      let surveyUpdatedAt = momentFromDateTime(survey.updated_at);
      
      if(survey.published_by_user){
        adminName = formatUserName(survey.published_by_user);
      }
      pastVersionOptionsArray.push({id:survey.id, label:survey.version_number + ' - ' + surveyUpdatedAt + ' by ' + adminName, value:survey.version_number});
    }
    let pageOptionsArray = [{value:'total', label:i18next.t('TOTAL_SURVEY_PAGES') + ' - ' + pagesArray.length}];
    let questionsArray = [];
    let surveysArray = [];
    
    for(let page of pagesArray){
      pageOptionsArray.push({value:page.id, label:page.key + ' - ' + page.page_title});
      
      if(page.isSurveyPage){
        for(let survey of page.surveysArray){
          surveysArray.push(survey);
        }
      }
      else{
        for(let question of page.questionsArray){
          let label = question.type.toUpperCase();
          
          if(question.key && question.key.length > 0){
            label = question.key;
            questionsArray.push(question);
          }
          pageOptionsArray.push({value:question.id, label:'Q: ' + label});
        }
      }
    }
    let labelKey = KeyForTranslation('title', selectedTranslation);
    let descriptionKey = KeyForTranslation('description', selectedTranslation);
    
    let survey = null;
    
    if(surveyVersionCollection){
      survey = surveyVersionCollection.current_survey;
      
      if(!survey){
        survey = surveyVersionCollection.draft_survey;
      }
    }
    
    return (
      <div className="survey-builder-scroll-container"
           id="survey-builder-scroll-container-id"
           onScroll={(aEvent) => this.onScroll(aEvent)}>
        <div className="survey-builder-container"
             id="survey-builder-container-id"
             ref={this.scrollRef}>
          <div className="survey-builder-title-cell">
            <div className="survey-builder-title-blue">
              {surveyVersionCollectionId && surveyVersionCollectionId.length > 0 ?
                <Translation>{(t, {i18n}) => t('EDIT_SURVEY_TOOL')}</Translation>
                :
                <Translation>{(t, {i18n}) => t('CREATE_SURVEY_TOOL')}</Translation>
              }
            </div>
            
            <div className="survey-builder-title-button-container">
              <input type="file"
                     ref={this.uploadYAMLInputRef}
                     onChange={(aEvent) => this.uploadYAML(aEvent)}
                     accept="txt/yml/yaml"
                     style={{display:'none'}}
              />
              
              <BlueButton className="survey-builder-title-button"
                          name={<Translation>{(t, {i18n}) => t('UPLOAD')}</Translation>}
                          onClick={() => this.uploadYAMLInputRef.current.click()}
              />
              
              <BlueButton className="survey-builder-title-button"
                          name={<Translation>{(t, {i18n}) => t('DOWNLOAD')}</Translation>}
                          onClick={() => this.downloadYAML()}
              />
              
              <div className="survey-builder-title-loading-spinner">
                <BlueButton className="survey-builder-title-button"
                            name={<Translation>{(t, {i18n}) => t('PUBLISH')}</Translation>}
                            loading={isPublishing}
                            onClick={() => this.publish()}
                />
              </div>
            </div>
          </div>
          
          <div className="survey-builder-info-container">
            <LabeledDelayedInput className="survey-builder-input"
                                 label={<Translation>{(t, { i18n }) => t('SURVEY_NAME')}</Translation>}
                                 value={surveyName}
                                 required={true}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_NAME_EXPLANATION')}</Translation>}
                                 minLength={1}
                                 handleSave={(aEvent) => {
                                   this.setState({surveyName:aEvent.target.value}, () => {
                                     this.reloadSurveyErrors();
                                   })
                                 }}
                                 errorMessage={errorForIdTypeAndKey(errors, 'survey', 'error','survey_name').message}
            />
            
            <LabeledDelayedInput className="survey-builder-input"
                                 label={i18next.t('SURVEY_TITLE') + ' - ' + selectedTranslation.display_name}
                                 value={this.state[labelKey]}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_TITLE_EXPLANATION')}</Translation>}
                                 minLength={1}
                                 handleSave={(aEvent) => {
                                   this.setState({[labelKey]:aEvent.target.value}, () => {
                                     this.reloadSurveyErrors();
                                   });
                                 }}
                                 warningMessage={errorForIdTypeAndKey(errors, 'survey', 'warning', labelKey).message}
            />
            
            <LabeledDelayedInput className="survey-builder-input"
                                 label={<Translation>{(t, { i18n }) => t('SURVEY_KEYWORD')}</Translation>}
                                 value={surveyKeyword}
                                 required={true}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_KEYWORD_EXPLANATION')}</Translation>}
                                 minLength={1}
                                 handleSave={(aEvent) => this.updateSurveyReferenceKey(aEvent.target.value)}
                                 errorMessage={errorForIdTypeAndKey(errors, 'survey', 'error','survey_keyword').message}
            />
            
            <LabeledDropdown className="survey-builder-input"
                             label={<Translation>{ (t, { i18n }) => t('SURVEY_VERSION') }</Translation>}
                             value={pastVersionOptionsArray.filter(function(option){
                               return option.value === selectedSurveyVersion;
                             })}
                             options={pastVersionOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_VERSION_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => this.setState({selectedSurveyVersion:aOption.value}, () => {
                               fetchSurveyById(aOption.id).then((newResponse) => {
                                 this.loadAndParseYAML(newResponse.data.data.survey.data);
                               }, (newError) => {
                               });
                             })}
            />
            
            <LabeledDelayedInput className="survey-builder-input-header"
                                 label={<Translation>{(t, { i18n }) => t('REPORT_HEADER')}</Translation>}
                                 value={header}
                                 infoBlurb={<Translation>{(t, { i18n }) => t('HEADER_EXPLANATION')}</Translation>}
                                 minLength={1}
                                 handleSave={(aEvent) => {
                                   this.setState({header:aEvent.target.value}, () => {
                                     this.reloadSurveyErrors();
                                   })
                                 }}
            />
          </div>
          
          {roleForCompany(company) === 'admin' ?
            <div className="survey-builder-question-textarea-container">
              <LabeledTextarea className="survey-builder-input-textarea"
                               label={<Translation>{(t, {i18n}) => t('UNRECOGNIZED_KEYS')}</Translation>}
                               value={unrecognizedKeys}
                               infoBlurb={<Translation>{(t, {i18n}) => t('UNRECOGNIZED_KEYS_EXPLANATION')}</Translation>}
                               handleSave={(aEvent) => this.setState({unrecognizedKeys:aEvent.target.value}, () => {
                                 this.updateUnrecognizedKeys();
                               })}
                               placeholder={i18n.t('UNRECOGNIZED_KEYS')}
                               errorMessage={errorForIdTypeAndKey(errors, 'survey', 'error', 'unrecognizedKeys').message}
                               popoverPlacement={'right'}
              />
              
              <LabeledTextarea className="survey-builder-input-textarea"
                               label={i18next.t('DESCRIPTION') + ' - ' + selectedTranslation.display_name}
                               value={this.state[descriptionKey]}
                               minLength={1}
                               infoBlurb={<Translation>{(t, {i18n}) => t('DESCRIPTION_EXPLANATION')}</Translation>}
                               handleSave={(aEvent) => {
                                 this.setState({[descriptionKey]:aEvent.target.value}, () => {
                                   this.reloadSurveyErrors();
                                 });
                               }}
                               placeholder={i18n.t('DESCRIPTION')}
                               popoverPlacement={'right'}
              />
            </div>
            :
            null
          }
          
          <div className="survey-builder-settings-container">
            <div className="survey-builder-subtitle">
              <Translation>{(t, {i18n}) => t('SURVEY_SETTINGS')}</Translation>
            </div>
            
            <div className="survey-builder-switch-container">
              <div className="survey-builder-switch-cell">
                <LabeledSwitch className="survey-builder-input-switch"
                               label={<Translation>{ (t, { i18n }) => t('REPEATING_SURVEY') }</Translation>}
                               checked={repeatingSurvey}
                               onChange={(isChecked) => this.setState({repeatingSurvey:isChecked}, () => {
                                 this.reloadSurveyErrors();
                               })}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('REPEATING_SURVEY_EXPLANATION') }</Translation>}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
                
                <LabeledSwitch className="survey-builder-input-switch"
                               label={<Translation>{ (t, { i18n }) => t('LARGE_SIZE') }</Translation>}
                               checked={largeSize}
                               onChange={(isChecked) => this.setState({largeSize:isChecked}, () => {
                                 this.reloadSurveyErrors();
                               })}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('LARGE_SIZE_EXPLANATION') }</Translation>}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
                
                <LabeledSwitch className="survey-builder-input-switch"
                               label={<Translation>{ (t, { i18n }) => t('TERMS_PRIVACY') }</Translation>}
                               checked={termsAndPrivacySurvey}
                               onChange={(isChecked) => this.setState({termsAndPrivacySurvey:isChecked}, () => {
                                 this.reloadSurveyErrors();
                               })}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('TERMS_AND_PRIVACY_SURVEY_EXPLANATION') }</Translation>}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
                
                <LabeledSwitch className="survey-builder-input-switch"
                               label={<Translation>{ (t, { i18n }) => t('ADMIN_SURVEY') }</Translation>}
                               checked={adminSurvey}
                               onChange={(isChecked) => this.setState({adminSurvey:isChecked}, () => {
                                 this.reloadSurveyErrors();
                               })}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('ADMIN_SURVEY_EXPLANATION') }</Translation>}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
              </div>
            </div>
          </div>
          
          <div className="survey-builder-language-nav-cell"
               ref={(aRef) => this.stickyRef = aRef}>
            <div className="survey-builder-language-picker-container">
              {/*<div>*/}
              {/*  Y: {scrollBounds.y} W: {scrollBounds.width} H: {scrollBounds.height}*/}
              {/*</div>*/}
              
              <LanguagePicker isSmall={isSticky}
                              translations={translationsArray}
                              allTranslations={languages}
                              selectTranslation={(aTranslation) => this.setState({selectedTranslation:aTranslation})}
                              selectedTranslation={selectedTranslation}
                              addOrRemoveTranslation={(aTranslation) => this.addOrRemoveTranslation(aTranslation)}
              />
              
              {reorderType && reorderType.length > 0 ?
                <div className="survey-builder-button-container">
                  <div className="survey-builder-language-picker-preview">
                    {reorderType === 'question' ?
                      <BlueButton className="survey-builder-title-button"
                                  name={<Translation>{(t, {i18n}) => t('EDIT_SURVEY_ORDER')}</Translation>}
                                  onClick={() => this.setState({reorderType:'survey'})}
                      />
                      :
                      (reorderType === 'survey' ?
                          <BlueButton className="survey-builder-title-button"
                                      name={<Translation>{(t, {i18n}) => t('EDIT_PAGE_ORDER')}</Translation>}
                                      onClick={() => this.setState({reorderType:'page'})}
                          />
                          :
                          <BlueButton className="survey-builder-title-button"
                                      name={<Translation>{(t, {i18n}) => t('EDIT_QUESTION_ORDER')}</Translation>}
                                      onClick={() => this.setState({reorderType:'question'})}
                          />
                      )
                    }
                    
                    <WhiteButton className="survey-builder-title-button"
                                 name={<Translation>{(t, {i18n}) => t('CANCEL')}</Translation>}
                                 onClick={() => this.setState({reorderType:null}, () => this.checkAndUpdate(true))}
                    />
                  </div>
                </div>
                :
                <div className="survey-builder-button-container">
                  <div className="survey-builder-language-picker-preview">
                    <WhiteButton className="survey-builder-title-button"
                                 name={<Translation>{(t, {i18n}) => t('EDIT_ORDER')}</Translation>}
                                 onClick={() => this.setState({reorderType:'question'})}
                    />
                  </div>
                  
                  <div className="survey-builder-language-picker-preview">
                    <WhiteButton className="survey-builder-title-button"
                                 name={<Translation>{(t, {i18n}) => t('PREVIEW')}</Translation>}
                                 loading={savingAsDraft}
                                 onClick={() => this.preview()}
                    />
                  </div>
                </div>
              }
            </div>
            
            <div className="survey-builder-navigation-container">
              <LabeledDropdown className="survey-builder-input-page-options"
                               value={pageOptionsArray[0]}
                               options={pageOptionsArray}
                               handleSave={(aOption) => this.scrollToRefWithId(aOption.value)}
              />
              
              <SurveyBuilderErrors errors={errors}
                                   scrollToRefWithId={(aId) => this.scrollToRefWithId(aId)}
              />
            </div>
          </div>
          
          <div className="survey-builder-cell-container-outer">
            {isSticky ?
              <div className='survey-builder-cell-empty-sticky-cell-small'/>
              :
              <div className="survey-builder-cell-empty-sticky-cell"/>
            }
            
            {reorderType && reorderType.length > 0 ?
              <SurveyBuilderReorderList isInModal={isInModal}
                                        pagesArray={pagesArray}
                                        reorderType={reorderType}
                                        updatePagesArray={(aPagesArray) => this.setState({pagesArray:aPagesArray})}
              />
              :
              <div className="survey-builder-cell-container">
                <div className={'survey-builder-page-cells-container ' + (selectedSurvey || selectedQuestion ? 'survey-builder-page-cells-container-open-drawer' : '')}>
                  {pagesArray.map((page, index) => (
                    <div key={page.id}>
                      {scrollBounds.height === 0 || (page.y + page.height) < scrollBounds.y - 500 || (scrollBounds.y + scrollBounds.height) < page.y - 500 ?
                        <div style={{height:page.height}}/>
                        :
                        <SurveyBuilderPageCell page={page}
                                               client={client}
                                               company={company}
                                               pageIndex={index}
                                               pagesArray={pagesArray}
                                               updatePage={(aPage) => this.updatePage(aPage)}
                                               scrollBounds={scrollBounds}
                                               selectSurvey={(aSurvey) => this.setState({selectedSurvey:aSurvey, selectedQuestion:null})}
                                               updateSurvey={(aSurvey) => this.updateSurvey(aSurvey, page)}
                                               addSurveyList={addSurveyList}
                                               isDraggingPage={false}
                                               selectedSurvey={selectedSurvey}
                                               selectQuestion={(aQuestion) => this.setState({selectedSurvey:null, selectedQuestion:aQuestion})}
                                               updateQuestion={(aQuestion) => this.updateQuestion(aQuestion, page)}
                                               addSurveyToPage={() => this.addSurveyToPageAtIndex(index)}
                                               fetchSurveyLists={fetchSurveyLists}
                                               selectedLanguage={selectedTranslation}
                                               selectedQuestion={selectedQuestion}
                                               addQuestionToPage={() => this.setState({selectedPageIndex:index, showQuestionSelectModal:true})}
                                               fetchDynamicLists={fetchDynamicLists}
                                               menuButtonClicked={page.canBeDeleted ?
                                                 (aEvent) => {
                                                   aEvent.preventDefault();
                                                   aEvent.stopPropagation();
                                                   this.setState({pageMenuAnchorElement:aEvent.currentTarget, selectedPage:page});
                                                 }
                                                 :
                                                 null}
                                               translationsArray={translationsArray}
                                               fetchSurveyListById={fetchSurveyListById}
                                               errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(errors, aId, aType, aKey)}
                                               validateRubularRegex={validateRubularRegex}
                                               addSurveyPageAfterPage={() => this.addPageAfterPageIndex(index, true)}
                                               addQuestionPageAfterPage={() => this.addPageAfterPageIndex(index, false)}
                                               moveSurveyFromPageToPage={(aSurvey, aFromPage, aToPage) => this.moveSurveyFromPageToPage(aSurvey, aFromPage, aToPage)}
                                               moveQuestionFromPageToPage={(aQuestion, aFromPage, aToPage) => this.moveQuestionFromPageToPage(aQuestion, aFromPage, aToPage)}
                                               fetchSurveyListByReference={fetchSurveyListByReference}
                                               fetchDynamicListByReference={fetchDynamicListByReference}
                                               fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                               fetchSurveyVersionCollectionById={fetchSurveyVersionCollectionById}
                                               fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                                               fetchSurveyVersionCollectionByReference={fetchSurveyVersionCollectionByReference}
                        />
                      }
                    </div>
                  ))}
                </div>
                
                {/*TODO: Look into reducing mounted Menu's.*/}
                <Menu id="survey-builder-menu"
                      open={Boolean(pageMenuAnchorElement)}
                      style={{zIndex:9999}}
                      onClose={() => this.closeMenus()}
                      anchorEl={pageMenuAnchorElement}
                      keepMounted>
                  <MenuItem style={{position:'relative'}}
                            onClick={() => this.setState({pageMenuAnchorElement:null, confirmationModalOpen:true})}>
                    <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                  </MenuItem>
                </Menu>
                
                {Boolean(selectedSurvey) ?
                  <SurveyBuilderSurveySettings close={() => this.setState({selectedSurvey:null})}
                                               survey={selectedSurvey}
                                               isInModal={isInModal}
                                               deleteSurvey={(aSurvey) => this.deleteSurvey(aSurvey)}
                                               surveysArray={surveysArray}
                                               updateSurvey={(aSurvey) => this.updateSurvey(aSurvey, this.pageForSurvey(aSurvey))}
                                               questionsArray={questionsArray}
                                               errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(errors, aId, aType, aKey)}
                                               fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                  />
                  :
                  null
                }
                
                {Boolean(selectedQuestion) ?
                  <SurveyBuilderQuestionSettings close={() => this.setState({selectedQuestion:null})}
                                                 question={selectedQuestion}
                                                 readOnly={readOnly}
                                                 isInModal={isInModal}
                                                 surveysArray={surveysArray}
                                                 addAdminEmail={() => {
                                                   let array = adminAlertEmailsArray;
                                                   array.push('');
                                                   this.setState({adminAlertEmailsArray:array}, () => {
                                                     this.reloadSurveyErrors();
                                                   })
                                                 }}
                                                 deleteQuestion={(aQuestion) => this.deleteQuestion(aQuestion)}
                                                 languagesArray={languages}
                                                 questionsArray={questionsArray}
                                                 updateQuestion={(aQuestion) => this.updateQuestion(aQuestion, this.pageForQuestion(aQuestion))}
                                                 selectedLanguage={selectedTranslation}
                                                 errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(errors, aId, aType, aKey)}
                                                 validateRubularRegex={validateRubularRegex}
                                                 adminAlertEmailsArray={adminAlertEmailsArray}
                                                 removeAdminEmailAtIndex={(aIndex) => {
                                                   if(aIndex < adminAlertEmailsArray.length){
                                                     let array = adminAlertEmailsArray;
                                                     array.splice(aIndex, 1);
                                                     this.setState({adminAlertEmailsArray:array}, () => {
                                                       this.reloadSurveyErrors();
                                                     });
                                                   }
                                                 }}
                                                 updateAdminEmailAtIndex={(aEmail, aIndex) => {
                                                   if(aIndex < adminAlertEmailsArray.length){
                                                     let array = adminAlertEmailsArray;
                                                     array[aIndex] = aEmail;
                                                     this.setState({adminAlertEmailsArray:array}, () => {
                                                       this.reloadSurveyErrors();
                                                     });
                                                   }
                                                 }}
                                                 fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                  />
                  :
                  null
                }
                
                {showQuestionSelectModal ?
                  <SurveyBuilderQuestionSelectModal isOpen={showQuestionSelectModal}
                                                    company={company}
                                                    handleCloseModal={() => this.setState({showQuestionSelectModal:false}, () => this.closeMenus())}
                                                    addQuestionWithType={(aQuestionType) => {
                                                      this.addQuestionOfTypeToPageAtIndex(aQuestionType, selectedPageIndex);
                                                      this.closeMenus()
                                                    }}
                  />
                  :
                  null
                }
                
                <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedPage ? selectedPage.key : i18next.t('PAGE'))})}
                                   isOpen={confirmationModalOpen}
                                   reject={() => this.closeMenus()}
                                   confirm={() => this.deletePage(selectedPage)}
                />
                
                <SurveyDrawer open={isDrawerOpen}
                              title={surveyName}
                              userId={user && user.id}
                              adminId={user && user.id}
                              company={company}
                              onClose={() => this.setState({isDrawerOpen:false})}
                              surveyId={survey ? survey.id : 0}
                              isTestResponse={true}
                              onFinishedSurvey={() => this.setState({isDrawerOpen:false})}
                />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
