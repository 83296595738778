import React from "react";
import './SurveyBuilderQuestionBranching.scss';
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import cloneDeep from "lodash/cloneDeep";
import SurveyBuilderConditionCell from "../../../../Cells/SurveyBuilderCells/SurveyBuilderConditionCell/SurveyBuilderConditionCell";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";

export default class SurveyBuilderQuestionBranching extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      selectedConditionIndex:-1
    };
  }
  
  // Methods
  
  updateCondition(aQuestion, aIndex, aKeyValuePairsArray){
    let updatedQuestion = cloneDeep(aQuestion);
    let updatedConditionsArray = updatedQuestion.exit_to_conditions;
    
    let updatedCondition = updatedConditionsArray[aIndex];
    
    for(let keyValuePair of aKeyValuePairsArray){
      if(keyValuePair.index !== undefined){
        updatedCondition[keyValuePair.key][keyValuePair.index] = keyValuePair.value;
      }
      else{
        updatedCondition[keyValuePair.key] = keyValuePair.value;
      }
    }
    updatedConditionsArray[aIndex] = updatedCondition;
    
    updatedQuestion.exit_to_conditions = updatedConditionsArray;
    this.props.updateQuestion(updatedQuestion);
  }
  
  // TODO: Move to SurveyBuilderConditionCell?
  questionOptionsForCondition(aCondition){
    let returnValue = [];
    
    if(aCondition && !aCondition.is_table && aCondition && aCondition.survey && aCondition.survey.length){
      if(aCondition.survey === '-1'){
        let { question, questionsArray } = this.props;
        
        for(let currentSurveyQuestion of questionsArray){
          // TODO: Should come before the Question.
          // TODO: Error if the same key as the question!
          //if(question.key !== currentSurveyQuestion.key){
            returnValue.push({value:currentSurveyQuestion.key, label:currentSurveyQuestion.key, type:currentSurveyQuestion.type});
          //}
        }
      }
      else{
        let selectedSurvey;
        
        for(let survey of this.props.surveysArray){
          if(survey.survey_reference === aCondition.survey){
            selectedSurvey = survey;
            break;
          }
        }
        if(selectedSurvey){
          for(let question of selectedSurvey.questionsArray){
            returnValue.push({value:question.key, label:question.key, type:question.type});
          }
        }
      }
    }
    return returnValue;
  }
  
  menuButtonClicked(aEvent, aConditionIndex){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedConditionIndex:aConditionIndex});
  };
  
  // Render
  
  render(){
    const { question, surveysArray, questionsArray, updateQuestion, errorForIdTypeAndKey, fetchAppAdditionalColumns } = this.props;
    const { menuAnchorElement, selectedConditionIndex } = this.state;
    
    // TODO: Add in exit types.
    let exitQuestionsArray = this.questionOptionsForCondition({survey:'-1'});
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-db-write-input-switch"
                       label={<Translation>{ (t, { i18n }) => t('BRANCHING_LOGIC') }</Translation>}
                       checked={Boolean(question && question.exit_to_conditions)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
    
                         if(isChecked){
                           updatedQuestion.exit_to_key = '';
                           updatedQuestion.exit_to_conditions = [];
                         }
                         else{
                           delete updatedQuestion['exit_to_key'];
                           delete updatedQuestion['exit_to_conditions'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('BRANCHING_LOGIC_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        {question && question.exit_to_conditions ?
          <div>
            <LabeledDropDown className="survey-builder-question-db-write-input-more"
                             label={<Translation>{(t, {i18n}) => t('EXIT_TO_KEY')}</Translation>}
                             value={exitQuestionsArray.filter(option => {
                               return option.value === question.exit_to_key
                             })}
                             options={exitQuestionsArray}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('EXIT_TO_KEY_EXPLANATION') }</Translation>}
                             handleSave={(aOption) => {
                               let updatedQuestion = cloneDeep(question);
                               updatedQuestion.exit_to_key = aOption.value;
                               updateQuestion(updatedQuestion);                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'exit_to_key').message}
                             popoverPlacement={"left"}
            />
            
            {question.exit_to_conditions.map((condition, index) => (
              <SurveyBuilderConditionCell key={question.id + '-condition-' + index}
                                          type={'exit_to_'}
                                          index={index}
                                          question={question}
                                          condition={condition}
                                          surveysArray={surveysArray}
                                          updateCondition={(aQuestion, aIndex, aKeyValuePairsArray) => this.updateCondition(aQuestion, aIndex, aKeyValuePairsArray)}
                                          menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, index)}
                                          errorForIdTypeAndKey={errorForIdTypeAndKey}
                                          questionOptionsArray={this.questionOptionsForCondition(condition)}
                                          fetchAppAdditionalColumns={fetchAppAdditionalColumns}
              />
            ))}
  
            <div className="survey-builder-question-visibility-add-button"
                 onClick={(aEvent) => {
                   aEvent.preventDefault();
                   aEvent.stopPropagation();
    
                   if(question){
                     let updatedQuestion = cloneDeep(question);
      
                     if(!updatedQuestion.exit_to_conditions){
                       updatedQuestion.exit_to_conditions = [];
                     }
                     updatedQuestion.exit_to_conditions.push({table:'', value:[''], column:'', survey:'-1', is_table:false, survey_loop_index:0, question:[''], operation:'equal', reference:'', question_type:[''], parse_to_age:[false], condition_operator:'and'});
                     updateQuestion(updatedQuestion);
                   }
                 }}>
              <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
    
              <Translation>{(t, {i18n}) => t('ADD_CONDITION')}</Translation>
            </div>
  
            <Menu id="simple-menu"
                  open={Boolean(menuAnchorElement)}
                  style={{zIndex:9999}}
                  onClose={() => this.setState({menuAnchorElement:null, selectedConditionIndex:-1})}
                  anchorEl={menuAnchorElement}
                  keepMounted>
              <MenuItem style={{position:'relative'}}
                        onClick={(aEvent) => {
                          aEvent.preventDefault();
                          aEvent.stopPropagation();
                          
                          if(question){
                            let updatedQuestion = cloneDeep(question);
        
                            if(updatedQuestion.exit_to_conditions && selectedConditionIndex < updatedQuestion.exit_to_conditions.length){
                              updatedQuestion.exit_to_conditions.splice(selectedConditionIndex, 1);
                              updateQuestion(updatedQuestion);
                            }
                          }
                          this.setState({menuAnchorElement:null, selectedConditionIndex:-1});
                        }}>
                <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
          </div>
          :
          null
        }
      </div>
    )
  }
}
