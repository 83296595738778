import React from 'react';
import { withRouter } from 'react-router-dom';
import ProfilePicture from '../../Custom UI/ProfilePicture/ProfilePicture';
import './PatientListItem.scss'
import AlertIndicator from "../../Custom UI/AlertIndicator/AlertIndicator";
import {BiDotsVerticalRounded} from "react-icons/bi";
import {Translation} from "react-i18next";
import Popover from "antd/lib/popover";
import Icon from "antd/lib/icon";
import i18next from "i18next";
import ErrorIcon from '@material-ui/icons/Error';
import {CheckCircle} from "@material-ui/icons";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

class PatientListItem extends React.Component{
  
  showSelected = (path) => {
    let currentPath = this.props.location.pathname;
    return (path === decodeURIComponent(currentPath))
  };
  
  formatPatientName(aPatient){
    let returnValue = '';
    
    if(aPatient){
      if(aPatient.last_name != null){
        returnValue =`${aPatient.first_name} ${aPatient.last_name}`;
      }
      else{
        returnValue = aPatient.first_name;
      }
    }
    return returnValue;
  }
  
  extractGroupNames(aPatient){
    let groupNames = [];
    groupNames = aPatient?.groups?.map(a => a.name);
    return groupNames.join(", ");
  }
  
  handlePatientCellClick = (arg) => {
    if(this.props.showLink){
      let patientLink = '#';
      
      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + this.props.patient.id;
      }
      else{
        patientLink = '/patients/' + this.props.patient.id;
      }
      this.props.history.push(patientLink);
    }
    else{
      this.props.selectPatient(this.props.patient);
    }
  };
  
  render() {
    let { patient, selected, selectPatient, canSelect, index, company, showHeader, showRemove, showEmail,
      menuButtonClicked, showFraudStatistics = true } = this.props;
    
    let name = this.formatPatientName(patient);
    const patientPermissions = ["admin_level_1"];
    let currentRole = roleForCompany(company);
    let patientGroups = this.extractGroupNames(patient);
    
    if(patientPermissions.includes(currentRole) || !currentRole){
      if(patient && patient.id){
        // let saltNumber = Math.floor(((parseInt(patient.id) * 19)^3) / 2);
        // let temp_number = patient.id + saltNumber.toString();
        name = patient.edc_id || patient.id;
      }
      else{
        name = (i18next.t('NAME_REDACTED'));
      }
    }
    let fraudStatus;
    let isVerified = false;
    
    if(patient && patient.fraud_statistic){
      if(patient.fraud_statistic.verification_status === 'verified'){
        isVerified = true;
        fraudStatus = i18next.t('VERIFIED');
      }
      else if(patient.fraud_statistic.verification_status === 'fraudulent'){
        fraudStatus = i18next.t('NOT_VERIFIED');
      }
      else{
        isVerified = (patient.fraud_statistic.probability < 0.5);
        fraudStatus = (patient.fraud_statistic.probability * 100) + '% ' + i18next.t('PROBABILITY_SPAM');
      }
    }
    return (
      <div style={{width: '100%', display: 'flex'}}>
        <div className='patient-list-item-cell'
             style={showEmail ? {padding: 0} : null}
             onClick={!canSelect ? this.handlePatientCellClick : null}>
          <div className='patient-list-avatar'>
            <ProfilePicture key={patient.image_id}
                            className={'patient-list-icon'}
                            width={40}
                            height={40}
            />
            
            <div className='patient-groups-labels-container'>
              <div className={showEmail ? 'manage-patient-name' : ''}>
                {name}
              </div>
              
              <div className='patient-list-patient-email'>
                {patientGroups}
              </div>
              
              {showEmail ?
                <div className='patient-list-patient-email'>
                  {patient.email}
                </div>
                :
                null
              }
            </div>
          </div>
          
          <div className="patient-list-item-date">
            {canSelect ?
              <div className="patient-list-item-selected">
                <div className={'patient-list-survey-input-cell'}
                     key={index}>
                  <label className={'patient-list-custom-input-cell'}>
                    <input type={"checkbox"}
                           name={'radio-choice'}
                           value={selected}
                           onChange={(aValue) => {selectPatient(patient)}}
                           defaultChecked={false}/>
                           
                    <span className={selected  ? 'patient-list-checked' : 'patient-list-custom-checkmark-radio'}/>
                  </label>
                </div>
              </div>
              :
              (showHeader ?
                <div className="patient-list-item-alert-info">
                  {/*TODO: String this!*/}
                  <div className="patient-list-date">
                    {patient && patient.last_sign_in_at ? patient.last_sign_in_at : 'N/A'}
                  </div>
                  <div className="patient-list-item-alert">
                    <AlertIndicator number={patient.number_of_uncleared_company_notification_receipts}/>
                  </div>
                </div>
                :
                null)
            }
          </div>
  
          {showFraudStatistics ?
            <div className='patient-list-fraud-info'>
              {patient && patient.fraud_statistic ?
                <span className="patient-list-fraud-info-status">
                  {isVerified ?
                    <CheckCircle className="checkmark"
                                 style={{color:'green'}}
                    />
                    :
                    <ErrorIcon style={{color:'#E02020', height:'20px', fontSize:'19px'}}/>
                  }
                  
                  {fraudStatus}
          
                  {patient.fraud_statistic.verified_comments ?
                    <Popover content={patient.fraud_statistic.verified_comments} placement="bottom">
                      <div className="patient-list-fraud-popover-button">
                        <Icon type="ellipsis"/>
                      </div>
                    </Popover>
                    :
                    null
                  }
                </span>
                :
                null
              }
      
              {patient && ((patient.fraud_statistic && patient.fraud_statistic.verification_status === 'pending') || !patient.fraud_statistic) ?
                <span className="patient-list-fraud-info-count">
                  {patient.number_of_unverified_fraud_events > 0 ?
                    <div className="patient-list-fraud-alert">
                      {patient.number_of_unverified_fraud_events}
                    </div>
                    :
                    <div>
                      0&nbsp;
                    </div>
                  }
                  <Translation>{(t, {i18n}) => t('FRAUD_EVENTS_FLAGS')}</Translation>
                </span>
                :
                null
              }
      
              {patient && patient.fraud_statistic && patient.fraud_statistic.verified_by_user ?
                <div className="patient-list-fraud-info-small">
                  <Translation>{(t, {i18n}) => t('VERIFIED_BY')}</Translation>
                  {this.formatPatientName(patient.fraud_statistic.verified_by_user)}
                </div>
                :
                null
              }
            </div>
            :
            null
          }
        
        </div>
        {showRemove ?
          <div className="patient-permissions-cell">
            <BiDotsVerticalRounded height="16px"
                                   width="16px"
                                   fill="#A8A8A8"
                                   style={{fontSize: '21px', marginLeft: 'auto'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent, patient)}
            />
          </div>
          :
          null
        }
      </div>
    );
  }
}

export default withRouter(PatientListItem)
