import React from 'react';
import './ProfileSectionHeading.scss'
import AlertIndicator from "../../../Custom UI/AlertIndicator/AlertIndicator";
import {AddCircleOutline} from "@material-ui/icons";
import {Translation} from "react-i18next";

const collapseUpDarkButton = '/imgs/app/collapse-up-button.svg';
const expandDownDarkButton = '/imgs/app/expand-down-button.svg';
const collapseUpWhiteButton = '/imgs/app/white-collapse-up-button.svg';
const expandDownWhiteButton = '/imgs/app/white-expand-down-button.svg';

const ProfileSectionHeading = ({ title, toggleFunc, collapseState, alertCount, headerColor, addButtonTitle, addButtonClick}) => {
  const customHeaderBg = headerColor ? { backgroundColor: headerColor } : {}
  const collapseUpButton = headerColor ? collapseUpWhiteButton : collapseUpDarkButton
  const expandDownButton = headerColor ? expandDownWhiteButton : expandDownDarkButton
  const headerFontColor = headerColor ? { color: '#FFFF'} : {}

  return(<div className='patient-panel-headings-container' style={ customHeaderBg }>
    <div className='section-control-button header-layout'
      onClick={() => toggleFunc()}>
      <div className="header-layout-inner">
        <img src={ collapseState ? collapseUpButton : expandDownButton } alt="collapseUpButton"/>
        <div style={ headerFontColor }>
          {title}
        </div>
        {(alertCount != null && alertCount > 0) &&
        <AlertIndicator
            number={alertCount}/>
        }
      </div>
      <div>
        {addButtonTitle ?
            <div className="clickable add-note-button" onClick={(aEvent) => {addButtonClick(aEvent)}}>
              <AddCircleOutline className="header-add-icon"/>
              <Translation>{(t, {i18n}) => t(addButtonTitle)}</Translation>
            </div> : null
        }
      </div>
    </div>
  </div>)
}

export default ProfileSectionHeading
