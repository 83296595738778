import React from 'react';
import './LabeledTextarea.scss';
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import Loading from "../../Loading/Loading";

const LabeledTextarea = ({className, bold, name, value, label, handleSave, disabled, errorMessage, infoBlurb,
                           maxLength, placeholder, required, descriptionLabel, warningMessage, menuButtonClicked,
                           popoverPlacement = "top", loading = false}) => (
  <div className={'labeled-textarea-input-container ' + className}>
    <LabeledInfo bold={bold}
                 label={label}
                 required={required}
                 infoBlurb={infoBlurb}
                 description={descriptionLabel}
                 popoverPlacement={popoverPlacement}
                 menuButtonClicked={menuButtonClicked}
    />
    
    <div className="labeled-textarea-cell">
      <textarea className={'textarea-field ' + (disabled ? 'text-input-field-inactive' : '') + (errorMessage ? 'labeled-textarea-input-error' : (warningMessage ? 'labeled-textarea-input-warning' : ''))}
                style={{minHeight:'2.5em'}}
                name={name}
                value={(value && value.toString()) || ''}
                disabled={disabled}
                onChange={(aEvent) => handleSave(aEvent)}
                maxLength={maxLength}
                placeholder={placeholder}
      />
      
      <Loading className="labeled-textarea-input-loading"
               size={'mini'}
               loading={loading}
               showLabel={false}
      />
    </div>
    
    <div className="labeled-textarea-labeled-errors-container">
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  </div>
);
export default LabeledTextarea;
