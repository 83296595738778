import React from 'react';
import { groupBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import './PatientProfileData.scss'
import PatientProfileDataControls from '../PatientProfileDataControls/PatientProfileDataControls'
import PatientProfileToolViz from '../PatientProfileToolViz/PatientProfileToolViz'
import VitalChart from '../charts/VitalChart'
import {Translation} from "react-i18next";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import {momentFromDate} from "../../../Helpers/DateHelpers";
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export default class PatientProfileData extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      numberOfDateRangesBack: 0,
      dateRange: '1 Month',
      quests: []
    };
    this.supportedObjectives = ['MedicationObjective', 'SymptomObjective', 'MultipleChoiceObjective', 'VitalObjective', 'RoutineObjective', 'SliderObjective', 'TextObjective', 'HealthyDoseObjective'];
    this.loadMoreQuests = this.loadMoreQuests.bind(this);
    this.setNumberOfDateRangesBack = this.setNumberOfDateRangesBack.bind(this);
  }
  
  componentDidMount() {
    if(this.props.patient && this.props.patient.id){
      this.props.fetchPatientData({ userId: this.props.patient.id, from: this.from(), to: this.to(), objectiveTypes: this.supportedObjectives });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(prevState.numberOfDateRangesBack !== this.state.numberOfDateRangesBack || prevState.dateRange !== this.state.dateRange){
      this.props.fetchPatientData({ userId: this.props.patient.id, from: this.from(), to: this.to(), objectiveTypes: this.supportedObjectives });
      this.setState({quests: []});
    }
    else if(this.props.patientData != null && this.props.patientData.quests != null && this.props.patient != null){
      if(this.state.quests.length === 0 && this.props.patientData.quests.length > 0){
        this.setState({quests: this.props.patientData.quests}, () => {
          this.loadMoreQuests();
        });
      }
    }
  }
  
  // Methods
  
  loadMoreQuests(){
    const limit = 5;
    
    if(this.props.patient != null && this.props.patient.id != null){
      this.props.fetchMoreQuests({
        userId: this.props.patient.id,
        from: this.from(),
        to: this.to(),
        objectiveTypes: this.supportedObjectives,
        questOffset: this.state.quests.length,
        questLimit: limit
      })
      .then(data => {
        let returnedQuests = data.data.data.user.quests;
        
        if(returnedQuests.length > 0){
          let quests = this.state.quests.filter(quest => !returnedQuests.find(returnedQuest => returnedQuest.id === quest.id));
          quests.push(...returnedQuests);
          this.setState({quests: quests}, () => {
            if(returnedQuests.length === limit){
              this.loadMoreQuests();
            }
          });
        }
      });
    }
  }
  
  from() {
    if(this.state.dateRange === '1 Month'){
      return this.to().subtract(1, 'months').startOf('day');
    }
    return this.to().subtract(14, 'days').startOf('day');
  }
  
  to(){
    if(this.state.dateRange === '1 Month'){
      return moment().add(this.state.numberOfDateRangesBack, 'months').endOf('day');
    }
    return moment().add(14 * this.state.numberOfDateRangesBack, 'days').endOf('day');
  }
  
  handleRangeChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };
  
  setNumberOfDateRangesBack(value) {
    if(value <= 0){
      this.setState({ numberOfDateRangesBack: value })
    }
  }
  
  initializeDates() {
    let result = [];
    let day = this.from();
    
    while(day <= this.to()){
      result.push(day.format(ISO_DATE_FORMAT));
      day = day.clone().add(1, 'd')
    }
    return result
  }
  
  // Render
  
  render() {
    const { patient, patientData, toggleResponsesList, company, getFullQuest } = this.props;
    const { quests } = this.state;
    
    let questsWithSupportedObjectives = quests;
    let validicRoutineData = patientData && patientData.validicUser && patientData.validicUser.validic_routine_data;
    let validicRoutineChartData = [];
    
    if(validicRoutineData){
      let groupedValidicRoutineData = groupBy(validicRoutineData, (entry) => {
        return momentFromDate(entry.timestamp, 'YYYY-MM-DD');
      });
      let dates = this.initializeDates();
      dates.forEach((date) => {
        let value = null;
        let data = groupedValidicRoutineData[date];
        
        if(data && data[0]){
          value = data[0].steps
        }
        validicRoutineChartData.push({ date, value })
      })
    }
    const currentRole = roleForCompany(company);
    const patientDataPermissions = ["admin", "admin_level_3", "admin_level_4"];
    
    return (
      <div>
        {patientDataPermissions.includes(currentRole) ? (
            <div className="patient-profile-data-graphs">
              <div className="patient-profile-data-controls">
                <PatientProfileDataControls
                  numberOfDateRangesBack={this.state.numberOfDateRangesBack}
                  from={this.from()}
                  to={this.to()}
                  set={this.setNumberOfDateRangesBack}
                  range={this.state.dateRange}
                  handleRangeSelect={this.handleRangeChange}
                />
              </div>
              <div className="patient-profile-tool-viz-container">
                {questsWithSupportedObjectives.length > 0 &&
                (questsWithSupportedObjectives.map((quest) => {
                  let emptyData = true;
    
                  if(quest.objectives){
                    quest.objectives.forEach(objective => {
                      if(objective.entries && objective.entries.length > 0){
                        emptyData = false
                      }
                    });
                  }
                  return (
                    <PatientProfileToolViz
                      key={quest.id + "-" + this.state.numberOfDateRangesBack + "-" + emptyData}
                      quest={quest}
                      patient={patient}
                      from={this.from()}
                      to={this.to()}
                      numberOfDateRangesBack={this.state.numberOfDateRangesBack}
                      emptyData={emptyData}
                      toggleResponsesList={toggleResponsesList}
                      getFullQuest={(questId, patientId) => getFullQuest(questId, this.to(), this.from(), patientId)}
                    />
                  )
                }))
                }
              </div>

              {
                validicRoutineChartData.length > 0 &&
                <div key={'validicRoutineChart-' + this.state.numberOfDateRangesBack}>
                  <h4><Translation>{ (t, { i18n }) => t('DEVICE_DATA') }</Translation></h4>
                  <h5><Translation>{ (t, { i18n }) => t('STEPS') }</Translation></h5>
                  <VitalChart
                    chartData={validicRoutineChartData}
                  />
                </div>
              }
            </div>
          )
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('PATIENT_DATA_BLOCKED')}</Translation>
          </div>
        }
      </div>
    );
  }
}
