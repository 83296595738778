import React from 'react';
import {Translation, withTranslation} from "react-i18next";
import AlternativeContactCell from "../../Cells/AlternativeContactCell/AlternativeContactCell";
import './AlternativeContactList.scss'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Loading from "../../Custom UI/Loading/Loading";
import LoadFailed from "../../Custom UI/LoadFailed/LoadFailed";
import {toast} from "react-toastify";
import i18next from "i18next";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyAltContactInfoRemoveSuccess = () => toast(i18next.t('ALTERNATIVE_CONTACT_REMOVE_SUCCESS'), NOTIFY_OPTS.autoCloseFiveSeconds);

class AlternativeContactList extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      additionalContacts:[]
    };
    this.loadPatientContacts = this.loadPatientContacts.bind(this);
    this.addAlternativeContact = this.addAlternativeContact.bind(this);
    this.removeAlternativeContact = this.removeAlternativeContact.bind(this);
    
    if(this.props.patient && this.props.patient.additional_contacts){
      this.state.additionalContacts = this.props.patient.additional_contacts;
    }
  }
  
  componentDidMount(){
    this.loadPatientContacts();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.patient && this.props.patient.additional_contacts){
      if(this.state.additionalContacts !== this.props.patient.additional_contacts){
        this.setState({additionalContacts:this.props.patient.additional_contacts});
      }
    }
  }
  
  // Methods
  
  addAlternativeContact(){
    let currentContactArray = this.state.additionalContacts;
    currentContactArray.push({});
    this.setState({additionalContacts:currentContactArray});
  }
  
  removeAlternativeContact(aContact){
    let currentContactArray = this.state.additionalContacts;
    const index = currentContactArray.findIndex(contact => contact?.id === aContact?.id);
    
    if(index > -1){
      currentContactArray.splice(index, 1);
    }
    this.setState({additionalContacts:currentContactArray}, () => {
      this.props.removeAlternativeContact(aContact);
      notifyAltContactInfoRemoveSuccess();
    });
  }
  
  loadPatientContacts(){
    this.setState({loading:true});
    this.props.loadPatientContacts(Number(this.props.patient.id)).then(newResult => {
      this.setState({loading:false, loadFailed:false});
    }, (newError) => {
      this.setState({loading:false, loadFailed:true});
    });
  }
  
  // Render
  
  render() {
    const {patient, saveAlternativeContactItem} = this.props;
    const {loading, loadFailed} = this.state;
    
    return (
      <div className="groups-outer-container">
        <div className="patient-profile-title-alt">
          <Translation>{(t, {i18n}) => t('ALTERNATIVE_CONTACT_INFORMATION')}</Translation>
          
          <AddCircleOutlineIcon className="add-alternative-contact-button"
                                style={{height:'20px', width:'20px', color:'#2D81C9' }}
                                onClick={this.addAlternativeContact}/>
        </div>
        
        <div className="profile-alternative-contact-list">
          {loading ?
            <Loading/>
            :
            (loadFailed ?
              <LoadFailed retry={() => this.loadPatientContacts()}/>
              :
              (patient && this.state.additionalContacts.length > 0 ?
                  <>
                    {this.state.additionalContacts.map((alternativeContact, index) => (
                      <div className="alternative-contact-section-container"
                           key={'alternative-contact-' + index + '-' + (alternativeContact.id ? alternativeContact.id : "new")}>
                        <div className="alternative-contact-section-title">
                          <Translation>{(t, {i18n}) => t('ALTERNATIVE_CONTACT')}</Translation> {index + 1}
                        </div>
                        
                        <AlternativeContactCell patient={patient}
                                                alternativeContact={alternativeContact}
                                                removeAlternativeContact={this.removeAlternativeContact}
                                                saveAlternativeContactItem={saveAlternativeContactItem}/>
                      </div>
                    ))}
                  </>
                  :
                  <div className="alternative-contact-list-no-alternative-contacts-found">
                    <Translation>{(t, {i18n}) => t('NO_ALTERNATIVE_CONTACTS')}</Translation>
                  </div>
              ))}
        </div>
      </div>
    )
  }
}

export default withTranslation()(AlternativeContactList)
