import {
  LOAD_TOOLS
} from 'constants/ActionTypes';

function tools(state = [], action) {
  switch(action.type) {
    case LOAD_TOOLS:
      return {
        ...state,
        tools: action.result
      }
    default:
      return state;
  }
}

export default tools
