import React from 'react';
import '../AddPatientNoteModal/AddPatientNoteModal.scss'
import "antd/dist/antd.css";
import {Switch} from "antd";
import {Translation} from "react-i18next";
import i18n from "../../i18n";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import '../../Custom UI/modalTitleCell.scss';
import GeneralModal from "../GeneralModal/GeneralModal";
import "../GeneralModal/GeneralModal.scss"
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";
import {fullDateTimeFormat, momentFromDate} from "../../Helpers/DateHelpers";


export default class AddDataQueryModal extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      noteId:this.props.noteId,
      description:this.props.description,
      goToNextState:false,
      notificationAlert:true,
      selectedAlertTypeValue:'opened_data_query_note'
    };
    this.handleSave = this.handleSave.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.filterAlertOptions = this.filterAlertOptions.bind(this);
    this.handleDataQueryNoteChange = this.handleDataQueryNoteChange.bind(this);
    this.assignPatientDataQueryNote = this.assignPatientDataQueryNote.bind(this);
    this.updatePatientDataQueryNote = this.updatePatientDataQueryNote.bind(this);
  }

  // Methods
  
  handleDataQueryNoteChange = (aEvent) => {
    this.setState({description:aEvent.target.value});

    if(aEvent.target.value === ''){
      this.setState({goToNextState:false});
    }
    else{
      this.setState({goToNextState:true});
    }
  }

  handleSave(){
    if(this.props.noteId){
      this.updatePatientDataQueryNote();
    }
    else{
      this.assignPatientDataQueryNote();
    }
  }

  toggleSwitch(){
    this.setState({notificationAlert:!this.state.notificationAlert});
  }

  assignPatientDataQueryNote(){
    const {description, notificationAlert} = this.state;
    const {assignNoteCallback} = this.props;
    assignNoteCallback({description:description, notificationAlert:notificationAlert});
  }

  updatePatientDataQueryNote(){
    const {description, noteId, notificationAlert} = this.state;
    const {updateNoteCallback} = this.props;
    updateNoteCallback({description:description, noteId:noteId, notificationAlert:notificationAlert});
  }

  filterAlertOptions(aOption){
    return aOption.value === this.state.selectedAlertTypeValue;
  }

  // Render
  
  render() {
    let {goToNextState, description} = this.state;
    let {isOpen, handleCloseModal, assignNoteError, createdAt} = this.props;

    // TODO: Pull from AlertTypes.
    let alertTypes = [
      {value:'opened_data_query_note', label:<Translation>{(t, {i18n}) => t('OPENED_DATA_QUERY')}</Translation>}
    ];
    return (
      <GeneralModal title={<Translation>{(t, {i18n}) => t('DATA_QUERY')}</Translation>}
                    isOpen={isOpen}
                    maxHeight={700}
                    handleSave={() => this.handleSave()}
                    errorMessage={assignNoteError}
                    noTitleHeading={true}
                    showSaveButton={goToNextState}
                    containsDropDown={true}
                    handleCloseModal={handleCloseModal}
                    showSaveButtonOnRight={true}>
        <LabeledTextarea className="modal-input-textarea"
                         label={i18n.t('DESCRIPTION')}
                         value={description}
                         handleSave={this.handleDataQueryNoteChange}
                         placeholder={i18n.t('ADD_DESCRIPTION_HERE')}
        />

        <LabeledDropDown className="modal-input"
                         options={alertTypes}
                         value={alertTypes.filter((aOption) => this.filterAlertOptions(aOption))}
                         label={<Translation>{(t, {i18n}) => t('ALERT_TYPE')}</Translation>}
                         handleSave={(aOption) => this.setState({selectedAlertTypeValue:aOption.value})}
        />

        {createdAt ?
          <span className="user-prompt-msg">
            <Translation>{(t, {i18n}) => t('CREATED')}</Translation> - {momentFromDate(createdAt, fullDateTimeFormat)}
          </span>
          :
          null
        }
        
        <div className="modal-subtitle-text">
          <Translation>{(t, {i18n}) => t('ALERT')}</Translation>
        </div>

        <div className="modal-switch-container">
          <Switch checked={this.state.notificationAlert}
                  onChange={this.toggleSwitch}
                  checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                  unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
          />
        </div>
      </GeneralModal>
    )
  }
}
