import {translatedOptionsArray} from "../Helpers/Helpers";

export const MethodsOfContact = {
  Call:'CALL',
  Email:'EMAIL'
};

export function methodsOfContactOptionsArray(){
  return translatedOptionsArray(MethodsOfContact);
}

export const NotificationTypes = {
  text:'SMS',
  email:'EMAIL',
  textAndEmail:'TEXT_AND_EMAIL'
};

export const NotificationTypeValues = {
  text:['text'],
  email:['email'],
  textAndEmail:['text', 'email']
};

export function notificationTypeOptionsArray(){
  return translatedOptionsArray(NotificationTypes);
}
