import React, { useState } from "react";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { Reorder } from '@material-ui/icons'
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import GrayButton from "../../Custom UI/Buttons/GrayButton/GrayButton";

const ReorderList = props => {
    const [collection, setCollection] = useState([...props.surveyIds]);
    const { surveyReportType, setReorderingStatus, setSurveyIds, setReorderSurvey } = props;

    const SortableItem = SortableElement(({value}) =>
        <li className="list-style-none"  key={`${value.id}-container`}>
            <label className={'custom-input-cell'} key={`${value.id}-cell`}>
                <Reorder/>
                <div key={`${value.id}-name`} className={'inline-text'}>{value.text}</div>
            </label>
        </li>
    );

    const SortableList = SortableContainer(({items}) => {
        return (
            <ul className={'ul-padding-left-20'}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} />
                ))}
            </ul>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        setCollection(arrayMove(collection, oldIndex, newIndex));
    };

    return (
        <>
            <div className="manage-reports-download-list-container">
                <div className="manage-reports-cells-container">
                    <div className="group-survey-cell">
                        <div className="group-user-title-container">
                          {/*TODO: Add Translations.*/}
                            <div className="group-user-title-text">Download {surveyReportType.label} Questionnaires</div>
                        </div>
                        <h3 className={'ul-padding-left-20 extra-padding-top'}>Questionnaires Order</h3>
                        <div className="extra-padding-top"/>
                        <SortableList items={collection} onSortEnd={onSortEnd} />
                    </div>
                </div>
                <div className="manage-buttons-container">
                    <GrayButton className='download-report-button'
                                onClick={() => setReorderSurvey(false)}
                                name="Cancel"
                    />
                    <BlueButton className='download-report-button'
                                onClick={() => {
                                    setSurveyIds([...collection]);
                                    setReorderingStatus(true)
                                }}
                                name="Download"
                    />
                </div>
            </div>
        </>
    )
}
export default ReorderList
