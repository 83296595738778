import React from "react";
import './SurveyBuilderMultiQuestion.scss';
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import cloneDeep from "lodash/cloneDeep";

export default class SurveyBuilderMultiQuestion extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const { question, updateQuestion } = this.props;
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-multi-question-input-switch"
                       label={<Translation>{ (t, { i18n }) => t('MULTIPLE_INPUTS') }</Translation>}
                       checked={question.type.includes('multi')}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
                         
                         if(question.type.includes('multi')){
                           if(question.type.includes('multi_')){
                             updatedQuestion.type = updatedQuestion.type.replaceAll('multi_', '');
                           }
                           else{
                             updatedQuestion.type = updatedQuestion.type.replaceAll('multi', '');
                           }
                         }
                         else{
                           if(question.type.includes('slider')){
                             updatedQuestion.type = 'multi' + updatedQuestion.type;
                           }
                           else{
                             updatedQuestion.type = 'multi_' + updatedQuestion.type;
                           }
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('MULTIPLE_INPUTS_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}/>
      </div>
    )
  }
}
