import React from 'react';
import './RedButton.scss';
import { FaSortDown } from 'react-icons/fa'

const RedButton = ({className, onClick, name, icon, disable}) => (
  <div className="red-button-main-container">
    <button className={'red-button-main ' + className}
            onClick={onClick}
            disabled={disable}>
      {name}
      
      {icon ?
        <FaSortDown className="button-icon"/>
        :
        null
      }
    </button>
  </div>
);

export default RedButton;
