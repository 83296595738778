import React from 'react';
import './LabeledDropDown.scss';
import './../../common.scss'
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import LabeledExtraButtons, {extraButtonsCount} from "../LabeledExtraButtons/LabeledExtraButtons";
import VirtualizedDropDown from "../../DropDowns/VirtualizedDropDown/VirtualizedDropDown";

const LabeledDropDown = ({className, bold, onSearch, value, label, required, options, disabled, errorMessage, handleSave,
                           showSearchIcon, addButtonClicked, onFocus, onBlur, isClearable = false, deleteButtonClicked,
                           placeholder, onMenuScrollToBottom, infoBlurb, popoverPlacement = 'top', warningMessage,
                           description, menuButtonClicked, responseObjectKeysArray, fetchObjects,
                           menuPosition, menuPlacement, menuPortalTarget, responseTotalKeysArray, labelPropertiesArray,
                           valuePropertiesArray, editButtonClicked, ascending, inputValue, showSearchStringInList,
                           notifyFetchFailed, optionsArrayFormatter, customOptionRenderer, customOptionHeight, extraOptionsArray,
                           doNotLoadOnMenuBottom, maxMenuHeight, optionFormatter, loading = false, saveWithOptionsKey = false}) => {
  
  // Instance Variables
  
  const onChange = (aOption) => {
    if(!aOption){
      handleSave({value:null, label:null});
    }
    else{
      handleSave(aOption);
    }
    document.activeElement.blur();
  };
  
  // Render
  
  return (
    <div className={'labeled-dropdown-input-container ' + className} >
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={description}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <span className="labeled-dropdown-input-cell">
        <div className="labeled-dropdown-container"
             onClick={(aEvent) => aEvent.stopPropagation()}>
          <VirtualizedDropDown value={value}
                               onBlur={onBlur}
                               onFocus={onFocus}
                               options={options}
                               disabled={disabled}
                               onChange={onChange}
                               onSearch={onSearch}
                               ascending={ascending}
                               inputValue={inputValue}
                               isClearable={isClearable}
                               placeholder={placeholder}
                               fetchObjects={fetchObjects}
                               errorMessage={errorMessage}
                               menuPosition={menuPosition}
                               maxMenuHeight={maxMenuHeight}
                               menuPlacement={menuPlacement}
                               warningMessage={warningMessage}
                               optionFormatter={optionFormatter}
                               menuPortalTarget={menuPortalTarget}
                               extraOptionsArray={extraOptionsArray}
                               notifyFetchFailed={notifyFetchFailed}
                               customOptionHeight={customOptionHeight}
                               saveWithOptionsKey={saveWithOptionsKey}
                               customOptionRenderer={customOptionRenderer}
                               labelPropertiesArray={labelPropertiesArray}
                               onMenuScrollToBottom={onMenuScrollToBottom}
                               valuePropertiesArray={valuePropertiesArray}
                               doNotLoadOnMenuBottom={doNotLoadOnMenuBottom}
                               optionsArrayFormatter={optionsArrayFormatter}
                               showSearchStringInList={showSearchStringInList}
                               responseTotalKeysArray={responseTotalKeysArray}
                               responseObjectKeysArray={responseObjectKeysArray}
          />
        </div>
        
        <Loading className="labeled-dropdown-input-loading"
                 style={{right:(extraButtonsCount(showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked) * 35 + 30).toString() +'px'}}
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />
        
        <LabeledExtraButtons showSearchIcon={showSearchIcon}
                             addButtonClicked={addButtonClicked}
                             editButtonClicked={editButtonClicked}
                             deleteButtonClicked={deleteButtonClicked}
        />
      </span>
      
      <LabeledErrors className="labeled-input-errors"
                     errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};
export default LabeledDropDown;
