import React, {useEffect, useState} from 'react';
import "antd/dist/antd.css";
import '../../Pages/ManageReports/ManageReports.scss';
import './ToolReports.scss';
import {Translation} from "react-i18next";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import moment from 'moment';
import {filter, orderBy, unionBy} from "lodash";
import fileDownload from "js-file-download";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {ReportTypes} from "../../constants/ReportTypes";
import Loading from "../../Custom UI/Loading/Loading";
import {currentDateHumanizedWithTimeStamp, isoDateFormat, momentFromDate} from "../../Helpers/DateHelpers";

const notifyDownloadGenerated = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_DOWNLOAD_GENERATED_TOAST') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyReportGenerationSuccess = (report) => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:report}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportFailed = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_FAILED_TOAST') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

const ToolReports = props => {
  
  // Instance Variables
  
  const {company, locales, studyClosed} = props;
  const PATIENT_CALL_LABEL = "Patient Call Logs";
  const PATIENT_TOOL_LABEL = "Patient Tools";
  const MEDICATION_TRACKER_LABEL = "Medication Tracker";
  const VACCINATION_TRACKER_LABEL = "Vaccination Tracker";
  const PAYMENT_QUERY_LABEL = "Payments";
  const PATIENT_ACTIVITY_LABEL = "Patient Activity";
  const PATIENT_DATA_QUERY_LABEL = "Patient Data Query";
  const reportTypesWithDatesArray = [PATIENT_CALL_LABEL, PATIENT_TOOL_LABEL, MEDICATION_TRACKER_LABEL, VACCINATION_TRACKER_LABEL,
    PAYMENT_QUERY_LABEL, PATIENT_ACTIVITY_LABEL, PATIENT_DATA_QUERY_LABEL];
  const PERMISSIONS = {
    patientCallReport: ["admin_level_2", "admin_level_3", "admin_level_4", "admin"],
    patientToolReport: ["admin_level_3", "admin_level_4", "admin"],
    patientQueryReport: ["admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin"],
    patientActivityReport: ["admin_level_3", "admin_level_4", "admin"],
    patientDataQueryReport: ["admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin"],
    patientQuestionnairesReport: ["admin_level_3", "admin_level_4", "admin"]
  };
  const OPTIONS = [
    {value:PATIENT_ACTIVITY_LABEL, label:<Translation>{ (t, { i18n }) => t('PATIENT_ACTIVITY') }</Translation>, permissions: PERMISSIONS.patientActivityReport},
    {value:PATIENT_TOOL_LABEL, label:<Translation>{ (t, { i18n }) => t('PATIENT_TOOLS') }</Translation>, permissions: PERMISSIONS.patientToolReport},
    {value:MEDICATION_TRACKER_LABEL, label:<Translation>{ (t, { i18n }) => t('MEDICATION_TRACKER') }</Translation>, permissions: PERMISSIONS.patientToolReport},
    {value:VACCINATION_TRACKER_LABEL, label:<Translation>{ (t, { i18n }) => t('VACCINATION_TRACKER') }</Translation>, permissions: PERMISSIONS.patientToolReport},
    {value:PATIENT_DATA_QUERY_LABEL, label:<Translation>{ (t, { i18n }) => t('PATIENT_DATA_QUERIES') }</Translation>, permissions: PERMISSIONS.patientDataQueryReport},
    {value:PATIENT_CALL_LABEL, label:<Translation>{ (t, { i18n }) => t('PATIENT_CALL_LOGS') }</Translation>, permissions: PERMISSIONS.patientCallReport},
    {value:PAYMENT_QUERY_LABEL, label:<Translation>{ (t, { i18n }) => t('PAYMENTS') }</Translation>, permissions: PERMISSIONS.patientQueryReport}
  ]
  const [reportEndDate, setReportEndDate] = useState(momentFromDate(new Date(), isoDateFormat));
  const [reportStartDate, setReportStartDate] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [availableLanguages, setAvailableLanguages] = useState({});
  const [languageListVisible, setLanguageListVisible] = useState(false);
  const [reportTypeChosenValue, setReportTypeChosenValue] = useState(null);
  const [reportGroupChosenValue, setReportGroupChosenValue] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [includeTestPatients, setIncludeTestPatients] = useState(false);
  const groups = company ? company.groups : [];
  const core_group = company ? company.core_group : null;
  const tools = (groups.length > 1) ? unionBy(core_group.quests.concat(groups.map( (g) => g.quests )).flat(), 'id') : core_group ? core_group.quests : null;
  const surveyTools = orderBy(tools && filter(tools, (tool) => tool.quest_type === "Survey"), ['title'], ['asc']);
  const role = roleForCompany(company);
  
  let optionsArray = [];
  OPTIONS.forEach(option => {
    if(option.permissions.includes(role) || studyClosed) {
      delete option.permissions;
      optionsArray.push(option);
    }
  })

  let groupOptions = [];
  
  if(core_group){
    groupOptions.push({value:core_group.id, label:<Translation>{ (t, { i18n }) => t('ALL_PATIENTS') }</Translation>})
  }
  let filtered_groups = groups;
  
  if(filtered_groups){
    filtered_groups.map((aGroup, index) => (
      groupOptions.push({value:aGroup.id, label:aGroup.name})
    ));
  }
  let surveyOptionsArray = optionsArray;
  
  surveyOptionsArray.push({
    type:'header',
    label:<Translation>{ (t, { i18n }) => t('QUESTIONNAIRE_RESPONSE') }</Translation>,
  });
  if(surveyTools){
    surveyTools.forEach((aTool) => {
      let addTool = true;
      
      if(aTool && aTool.survey && aTool.survey.has_sensitive_data_question){
        if(!PERMISSIONS.patientQuestionnairesReport.includes(role)){
          addTool = false;
        }
      }
      if(addTool){
        surveyOptionsArray.push({value:aTool.id, label:aTool.title});
      }
    });
  }
  
  // Methods
  
  useEffect(()=> {
    if(locales){
      let langOptions = {};
      
      locales && locales.forEach(function(locale){
        langOptions[locale.locale] = locale.title
      });
      setAvailableLanguages(langOptions)
    }
  }, [locales]);
  
  const paymentsReport = () => {
    const dataManagerReqParams = {
      company_id:company.id,
      group_id:reportGroupChosenValue,
      start_date:reportStartDate,
      end_date:reportEndDate,
      generate_in_background:'true',
      include_test_patients: includeTestPatients
    };
    global.api.post("/api/tool_stats/hyperwallet_payment_report", dataManagerReqParams).then(result =>  {
      let fileName = ReportTypes.hyperwallet_payment_report;
      notifyDownloadGenerated(fileName);
    }).catch((error) => notifyReportFailed())
  };
  
  const changeTestPatientSetting = () => {
    setIncludeTestPatients(!includeTestPatients)
  }
  
  const dataQueryReport = () => {
    const dataManagerReqParams = {
      company_id:company.id,
      group_id:reportGroupChosenValue,
      start_date:reportStartDate,
      end_date:reportEndDate,
      generate_in_background:'true',
      include_test_patients: includeTestPatients
    };
    global.api.post("/api/tool_stats/data_query_report", dataManagerReqParams).then(result =>  {
      let fileName = ReportTypes.data_query_report;
      notifyDownloadGenerated(fileName);
    }).catch((error) => notifyReportFailed())
  };
  
  const requestEconsentReport = () => {
    const reportReqParams = {
      company_id:company.id,
      group_id:reportGroupChosenValue,
      start_date:reportStartDate,
      end_date:reportEndDate,
      download:true,
      language:selectedLanguage,
      generate_in_background:'true',
      include_test_patients: includeTestPatients
    };
    global.api.post("/api/tool_stats/patients_usage_summary_report", reportReqParams)
    .then((result) => {
      let fileName = ReportTypes.patients_usage_summary_report;
      notifyDownloadGenerated(fileName);
    })
    .catch((error) => notifyReportFailed());
  };
  
  const requestPatientsSurveyReport = () => {
    const surveyQuestId = reportTypeChosenValue;
    const reportReqParams = {
      company_id:company.id,
      group_id:reportGroupChosenValue,
      quest_id:surveyQuestId,
      language:selectedLanguage,
      generate_in_background:'true',
      include_test_patients: includeTestPatients
    };
    global.api.post("/api/tool_stats/patients_survey_report", reportReqParams)
    .then((result) => {
      let fileName = ReportTypes.patients_survey_report;
      notifyReportGenerationSuccess(fileName);
    })
    .catch((error) => notifyReportFailed())
  };
  
  const requestPatientsActivityReport = () => {
    const reportReqParams = {
      company_id:company.id,
      group_id:reportGroupChosenValue,
      start_date:reportStartDate,
      end_date:reportEndDate,
      download:false,
      generate_in_background:'true',
      include_test_patients: includeTestPatients
    };
    global.api.post("/api/tool_stats/patients_usage_summary_report", reportReqParams)
    .then((result) => {
      let fileName = ReportTypes.patients_usage_summary_report;
      notifyDownloadGenerated(fileName);
    })
    .catch((error) => notifyReportFailed());
  };
  
  function downloadReport(){
    reportTypeChosenValue === PATIENT_ACTIVITY_LABEL ? requestPatientsActivityReport() :( reportTypeChosenValue === PATIENT_TOOL_LABEL ? requestPatientsSurveyReport() : requestEconsentReport())
  }
  
  function downloadSurveyAs(format){
    const surveyQuestId = reportTypeChosenValue;
    const surveyReportRequestParams = {
      group_ids:reportGroupChosenValue,
      quest_id:surveyQuestId,
      language:selectedLanguage,
      survey_ids:[],
      generate_in_background:'true',
      company_id: company.id,
      include_test_patients: includeTestPatients
    };
    const config = (format === 'pdf') ? { responseType: 'blob' } : null;
    setShowLoading(true);
    
    global.api.post(`/api/surveys/export_by_group/${format}`, surveyReportRequestParams, config).then((result) => {
      if(format === 'spss' || format === 'sas'){
        format = 'txt';
      }
      const surveyToolTitle = surveyTools.find( ({id}) => id === surveyQuestId )?.title?.split(' ')?.join('_')?.toLowerCase();
      notifyReportGenerationSuccess(surveyToolTitle);
      setShowLoading(false);
    }).catch(error => {
      setShowLoading(false);
      notifyReportFailed();
    });
  }
  
  function downloadDefaultToolsReport(){
    downloadToolsReport(false, false);
  }

  function downloadMedicationTrackerToolsReport(){
    downloadToolsReport(true, false);
  }
  
  function downloadVaccinationTrackerToolsReport(){
    downloadToolsReport(false, true);
  }

  function downloadToolsReport(aOnlyMedicationTracker, aOnlyVaccinationTracker){
    const surveyReportRequestParams = {
      only_medication_tracker: aOnlyMedicationTracker ? "true" : "false",
      only_vaccination_card: aOnlyVaccinationTracker ? "true" : "false",
      group_ids: reportGroupChosenValue,
      include_test_patients: includeTestPatients
    };
    global.api.post(`/api/groups/tools_report?&start_date=${reportStartDate}&end_date=${reportEndDate}`, surveyReportRequestParams)
    .then((result) => {
      fileDownload(result.data, `toolsReport${currentDateHumanizedWithTimeStamp()}.csv`);
    }).catch(error => notifyReportFailed());
  }

  function handleChangeReportGroup(value){
    value.includes(core_group.id) ? setReportGroupChosenValue(core_group.id) : setReportGroupChosenValue(value)
  }
  
  function downloadPatientCallLogsReport(){
    const surveyReportRequestParams = {
      group_ids: reportGroupChosenValue,
      start_date: reportStartDate,
      end_date: reportEndDate,
      generate_in_background: 'true',
      company_id: company.id,
      include_test_patients: includeTestPatients
    };
    global.api.post(`/api/companies/${company.id}/call_logs_report?&start_date=${reportStartDate}&end_date=${reportEndDate}`, surveyReportRequestParams)
    .then((result) => {
      notifyDownloadGenerated();
    }).catch(error => notifyReportFailed());
  }
  
  // Render
  
  let actionButtons;
  
  if(reportTypeChosenValue === PATIENT_ACTIVITY_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT') }</Translation>}
                                onClick={downloadReport}
    />
  }
  else if(reportTypeChosenValue === PATIENT_TOOL_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={downloadDefaultToolsReport}
    />
  }
  else if(reportTypeChosenValue === MEDICATION_TRACKER_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={downloadMedicationTrackerToolsReport}
    />
  }
  else if(reportTypeChosenValue === VACCINATION_TRACKER_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={downloadVaccinationTrackerToolsReport}
    />
  }
  else if(reportTypeChosenValue === PATIENT_DATA_QUERY_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={dataQueryReport}
    />
  }
  else if(reportTypeChosenValue === PAYMENT_QUERY_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={paymentsReport}
    />
  }
  else if(reportTypeChosenValue === PATIENT_CALL_LABEL){
    actionButtons = <BlueButton className='download-report-button'
                                name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                                onClick={downloadPatientCallLogsReport}
    />
  }
  else{
    actionButtons = <>
      <BlueButton className='download-report-button'
                  name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_CSV') }</Translation>}
                  onClick={() => downloadSurveyAs('csv', false)}
      />
      <BlueButton className='download-report-button'
                  name={<Translation>{ (t, { i18n }) => t('EXPORT_SPSS_LABEL_SCRIPT') }</Translation>}
                  onClick={() => downloadSurveyAs('spss', false)}
      />
      <BlueButton className='download-report-button'
                  name={<Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT_AS_SAS') }</Translation>}
                  onClick={() => downloadSurveyAs('sas', false)}
      />
    </>
  }
  
  return(
    <div className="manage-reports-container">
      <div className="tool-reports-description">
        <Translation>{ (t, { i18n }) => t('GENERATE_REPORT') }</Translation>
      </div>
      
      <br/>
      
      <LabeledDropDown className="manage-reports-dropdown"
                       label={<Translation>{(t, {i18n}) => t('REPORT_TYPE')}</Translation>}
                       value={reportTypeChosenValue && surveyOptionsArray.filter(function (option) {
                         return option.value === reportTypeChosenValue;
                       })}
                       options={surveyOptionsArray}
                       handleSave={(aOption) => setReportTypeChosenValue(aOption.value)}
                       placeholder={<Translation>{(t, {i18n}) => t('PATIENT_TOOLS')}</Translation>}
                       maxMenuHeight={250}
      />
      
      {reportTypesWithDatesArray.includes(reportTypeChosenValue) ?
        <div className="manage-reports-date-container">
          <LabeledDatePicker className="tool-reports-datepicker"
                             name="upToDate"
                             label={<Translation>{(t, {i18n}) => t('REPORT_START_DATE_')}</Translation>}
                             value={reportStartDate}
                             maxDate={reportEndDate ? reportEndDate : moment()}
                             handleSave={(date) => setReportStartDate(momentFromDate(date, isoDateFormat))}
          />
          
          <LabeledDatePicker className="tool-reports-datepicker"
                             name="upToDate"
                             label={<Translation>{(t, {i18n}) => t('REPORT_END_DATE_')}</Translation>}
                             value={reportEndDate}
                             maxDate={moment()}
                             minDate={reportStartDate}
                             handleSave={(date) => setReportEndDate(momentFromDate(date, isoDateFormat))}
          />
        </div>
        :
        null
      }
      
      {(reportTypeChosenValue || (reportStartDate && reportEndDate)) ?
        <LabeledDropDown className="manage-reports-dropdown-group"
                         label={<Translation>{(t, {i18n}) => t('REPORT_GROUP_')}</Translation>}
                         value={reportGroupChosenValue && groupOptions.filter(function (option) {
                           return option.value === reportGroupChosenValue;
                         })}
                         options={groupOptions}
                         handleSave={(aOption) => handleChangeReportGroup(aOption.value)}
                         maxMenuHeight={100}
        />
        :
        null
      }
      
      <div className="manage-reports-buttons-container">
        {reportTypeChosenValue ?
          <label className={'custom-input-cell'}>
            <input className="patient-user-input"
                   type='checkbox'
                   value={includeTestPatients}
                   checked={includeTestPatients}
                   onClick={changeTestPatientSetting}
                   defaultChecked={includeTestPatients}
            />
            
            <span className={'custom-checkmark'}/>
            
            <div className={'inline-text'}>
              <Translation>{(t, {i18n}) => t('INCLUDE_TEST_PATIENT')}</Translation>
            </div>
          </label>
          :
          null
        }
        
        {(!reportTypesWithDatesArray.includes(reportTypeChosenValue) || reportStartDate) && reportGroupChosenValue && reportGroupChosenValue.length > 0 ?
          <div className="report-button-dropdown-container">
            <BlueButton className='download-report-button'
                        name={availableLanguages[selectedLanguage]}
                        onClick={() => setLanguageListVisible(!languageListVisible)}
                        showDropDownIcon={true}
            />
            
            {languageListVisible ?
              <ul className="report-dropdown-list">
                {availableLanguages ?
                  Object.keys(availableLanguages).map((key) => (
                    <li key={key}
                        value={key}
                        onClick={() => {
                          setLanguageListVisible(!languageListVisible);
                          setSelectedLanguage(key)
                        }}>
                      {availableLanguages[key]}
                    </li>
                  ))
                  :
                  null
                }
              </ul>
              :
              null
            }
            
            {showLoading ?
              <Loading className="manage-reports-loading"/>
              :
              (actionButtons)
            }
          </div>
          :
          null
        }
      </div>
    </div>
  )
}
export default ToolReports
