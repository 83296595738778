import { connect } from 'react-redux';
import actions from 'actions';
import ManageReports from 'Pages/ManageReports/ManageReports'

const mapStateToProps = (state, ownProps)=> {

  return {
    company:state.company && state.company.company,
    locales:state.locales && state.locales.locales
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSurveyVersionCollections:(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField) => {
      return dispatch(actions.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField));
    },
    fetchLocales:() => {
      dispatch(actions.fetchLocales())
    },
    getScheduledSurveys:() => {
      return dispatch(actions.getScheduledSurveys());
    },
    fetchReports:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchReports(aLimit, aOffset, aSearchString));
    },
    removeReport:(aReportId) => {
      return dispatch(actions.removeReport(aReportId));
    },
    fetchReportDetails:(aReportId) => {
      return dispatch(actions.fetchReportDetails(aReportId));
    },
    saveReportTemplate:(aReportTemplate) => {
      return dispatch(actions.saveReportTemplate(aReportTemplate));
    },
    fetchReportTemplate:(aReportTemplateId) => {
      return dispatch(actions.fetchReportTemplate(aReportTemplateId));
    },
    removeReportTemplate:(aReportTemplateId) => {
      return dispatch(actions.removeReportTemplate(aReportTemplateId));
    },
    fetchReportTemplates:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchReportTemplates(aLimit, aOffset, aSearchString));
    },
    duplicateReportTemplate:(aReportTemplateId) => {
      return dispatch(actions.duplicateReportTemplate(aReportTemplateId));
    },
    generateReportFromTemplate:(aReportTemplateId, aName, aFilters) => {
      return dispatch(actions.generateReportFromTemplate(aReportTemplateId, aName, aFilters));
    },
    fetchModelConfigurations:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchModelConfigurations(aLimit, aOffset, aSearchString));
    },
    fetchColumnConfigurations:(aLimit, aOffset, aSearchString, aModelConfigurationName) => {
      return dispatch(actions.fetchColumnConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName));
    },
    fetchFilterConfigurations:(aLimit, aOffset, aSearchString, aModelConfigurationName) => {
      return dispatch(actions.fetchFilterConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName));
    },
    fetchPreMadeRelationships:(aLimit, aOffset, aSearchString, aModelConfigurationName) => {
      return dispatch(actions.fetchPreMadeRelationships(aLimit, aOffset, aSearchString, aModelConfigurationName));
    },
    fetchRelationshipConfigurations:(aLimit, aOffset, aSearchString, aModelConfigurationName) => {
      return dispatch(actions.fetchRelationshipConfigurations(aLimit, aOffset, aSearchString, aModelConfigurationName));
    },
    retryGeneratingReport:(aReportId) => {
      return dispatch(actions.retryGeneratingReport(aReportId));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageReports)
