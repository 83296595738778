import React from 'react';
import {Translation} from "react-i18next";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const PatientProfileDataControls = ({ set, numberOfDateRangesBack, from, to, range, handleRangeSelect}) => {
  
  // Instance Variables
  
  const dateFormat = 'MMMM D ddd, yyyy';
  
  // Render
  
  return (
    <div className='patient-profile-data-graphs-heading-container'>
      <div className='graph-time-container'>
        <ArrowBackIosIcon className='graph-time-control-buttons clickable'
                          onClick={() => set(numberOfDateRangesBack - 1)}
        />
        <div className='graph-time-period-info'>
          {from.format(dateFormat)}
          <Translation>{(t, {i18n}) => t('SPACED_TO')}</Translation>
          {to.format(dateFormat)}
        </div>
        <ArrowForwardIosIcon className='graph-time-control-buttons clickable'
                             onClick={() => set(numberOfDateRangesBack + 1)}
        />
        
        <img src={'../../Double Arrow Black.svg'}
             alt={'Double Arrow'}
             onClick={() => set(numberOfDateRangesBack + 2)}
        />
      </div>
      <div className='date-range-select'>
        <label>
          <input type='radio' value='2 Weeks'
                 className='radio-select'
                 checked={range === '2 Weeks'}
                 name='dateRange'
                 onChange={handleRangeSelect}
          />
          <p>
            <Translation>{(t, {i18n}) => t('TWO_WEEKS')}</Translation>
          </p>
        </label>
        <label>
          <input type='radio' value='1 Month'
                 className='radio-select'
                 checked={range === '1 Month'}
                 name='dateRange'
                 onChange={handleRangeSelect}
          />
          <p>
            <Translation>{(t, {i18n}) => t('ONE_MONTH')}</Translation>
          </p>
        </label>
      </div>
    </div>
  )
};
export default PatientProfileDataControls
