import React, {useEffect, useRef, useState} from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import '../../Pages/ManageReports/ManageReports.scss';
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {findIndex} from "lodash";
import {ReportNames, ReportTypes} from "../../constants/ReportTypes";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {hsFeatureAvailableForCompany, roleForCompany} from "../../Helpers/CompanyHelpers";

const notifyReportFailed = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_FAILED_TOAST') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyReportGenerationSuccess = (report) => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:report}) }</Translation>, NOTIFY_OPTS.autoClose);

const ScheduledReportsList = props => {
  
  // Instance Variables
  
  const {company, getScheduledSurveys} = props;
  const [showLoading, setShowLoading] = useState(false);
  const [downloadListVisible, setDownloadListVisible] = useState(false);
  const [scheduledSurveyList, setScheduledSurveyList] = useState([]);
  const [selectedScheduledReportSurveyId, setSelectedScheduledReportSurveyId] = useState(0);
  const [includeTestPatients, setIncludeTestPatients] = useState(false);
  const [downloadQuipmentReport, setDownloadQuipmentReport] = useState({
    download:false,
    type:null
  });
  const role = roleForCompany(company);
  const coveCompanyIds = useRef(['125','81']);
  const NOMLCompanyIds = useRef(['103']);
  const bluecCompanyIds = useRef(['123','80']);
  const eyeDropperCompanyIds = useRef(['89', '139']);
  const vaxpathCompanyIds = useRef(['126','128','127','130','77','86','85']);
  const abbvieCompanyIds = useRef(['87', '138']);
  const downloadAllCompanySdtmPermissions = useRef(["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4"]);
  const patientCallCentreReportPermissions = useRef(["admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin"]);
  const downloadAllCompanyFlattenedPermissions = useRef(["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4"]);
  const downloadParticipantsStatsReportPermissions = useRef(["admin_level_7", "admin_level_4", "admin"]);
  const downloadAllCompanyIneligiblePatientsReportPermissions = useRef(["admin_level_2", "admin_level_3", "admin_level_4", "admin"]);
  const downloadAllCompanyPiReportPermissions = useRef(["admin_level_2", "admin_level_1", "admin_level_4", "admin"]);

  // Methods
  
  useEffect(()=> {
    if(company){
      getScheduledSurveys().then(result => {
        let scheduledList = [];
        
        result.data.data.scheduled_surveys.forEach((obj) => {
          if(role !== 'admin_level_1'){
            let quipment_index = findIndex(scheduledList, { id:`quipment_report_${company.id}`, name:`Quipment Report` });
            let eye_dropper_quipment_index = findIndex(scheduledList, { id:`eye_dropper_quipment_report_${company.id}`, name:`Shipment Report` });

            if(eyeDropperCompanyIds.current.includes(company.id)){
              if(eye_dropper_quipment_index === -1){
                scheduledList.push({ id:`eye_dropper_quipment_report_${company.id}`, name:`Shipment Report` });
              }
            }else{
              if(quipment_index === -1){
                scheduledList.push({ id:`quipment_report_${company.id}`, name:`Quipment Report` });
              }
            }
          }
        });
        // TODO: Add translations.
        // TODO: Add configuration at the company level to turn these on or off.
        if(!abbvieCompanyIds.current.includes(company.id)){
          scheduledList.push(
              { id:`adverse_event_${company.id}`, name:'Adverse Event Report' },
              { id:`sdtm_${company.id}`, name:'SDTM Report' },
              { id:`enrollment_${company.id}`, name:ReportTypes.download_enrollment_reports_for_all_users }
          );
          if(hsFeatureAvailableForCompany(company, 'Show Report Builder Reports')){
            scheduledList.push({id:`new_enrollment_${company.id}`, name:ReportTypes.custom_report_template});
          }
        }

        scheduledList.push(
          { id:`survey_completion_${company.id}`, name:ReportTypes.survey_completion_report },
          { id:`download_flattened_report_${company.id}`, name:ReportTypes.download_flattened_report },
          { id:`medication_report_${company.id}`, name:ReportTypes.medication_report }
        );
  
        if(!coveCompanyIds.current.includes(company.id)){
          scheduledList.push(
            { id:`survey_completion_by_group_${company.id}`, name:ReportTypes.survey_completion_by_group_report }
          );
        }
        if(bluecCompanyIds.current.includes(company.id)){
          scheduledList.push(
            {id:`payment_metrics_report_${company.id}`, name:ReportTypes.payment_metrics_report}
          );
        }
        if(patientCallCentreReportPermissions.current.includes(role)){
          scheduledList.push(
            {id:`all_alerts_report_${company.id}`, name:ReportTypes.all_alerts_report},
            {id:`current_alerts_report_${company.id}`, name:ReportTypes.current_alerts_report},
            {id:`export_access_report_${company.id}`, name:ReportTypes.export_access_report}
          );

          if(bluecCompanyIds.current.includes(company.id)){
            scheduledList.push(
              {id:`call_center_logistics_and_eligibility_report_${company.id}`, name:ReportTypes.call_center_logistics_and_eligibility_report}
            );
          }
        }
        if(NOMLCompanyIds.current.includes(company.id)){
          if(downloadParticipantsStatsReportPermissions.current.includes(role)){
            scheduledList.push(
              { id:`download_participant_stats_report_${company.id}`, name:ReportTypes.download_participant_stats_reports_for_all_users }
            )
          }
        }
        if(vaxpathCompanyIds.current.includes(company.id)){
          scheduledList.push(
            { id:`participant_${company.id}`, name: 'Participant Report' },
            { id:`download_ineligible_patients_report_${company.id}`, name:ReportTypes.download_ineligible_patients_report },
            { id:`vaxpath_eligibility_custom_report_${company.id}`, name:ReportTypes.vaxpath_eligibility_custom_report },
            { id:`vaxpath_eligibility_custom_report_all_sites_${company.id}`, name:ReportTypes.vaxpath_eligibility_custom_report_all_sites },
            { id:`vaxpath_icf_report_${company.id}`, name:ReportTypes.vaxpath_icf_report },
            { id:`vaxpath_icf_report_version_numbers_${company.id}`, name:ReportTypes.vaxpath_icf_report_version_numbers },
            { id:`vaxpath_specific_milestone_report_${company.id}`, name:ReportTypes.vaxpath_specific_milestone_report },
            { id:`vaxpath_visit_completion_status_report_${company.id}`, name:ReportTypes.vaxpath_visit_completion_status_report },
            { id:`vaxpath_v4_download_flattened_report_${company.id}`, name:ReportTypes.vaxpath_v4_download_flattened_report },
            { id:`vaxpath_v4_download_all_company_flattened_report_${company.id}`, name:ReportTypes.vaxpath_v4_download_all_company_flattened_report },
            { id:`medication_report_all_sites_${company.id}`, name:ReportTypes.medication_report_all_sites }
          );
          if(downloadAllCompanyIneligiblePatientsReportPermissions.current.includes(role)) {
            scheduledList.push(
              { id: `download_all_company_ineligible_patients_report_${company.id}`, name: ReportTypes.download_all_company_ineligible_patients_report }
            );
          }

          if(downloadAllCompanyPiReportPermissions.current.includes(role)) {
            scheduledList.push(
              { id:`download_pi_reports_for_all_users_${company.id}`, name:ReportTypes.download_pi_reports_for_all_users }
            );
          }
        }
        if(downloadAllCompanyFlattenedPermissions.current.includes(role)){
          scheduledList.push(
            { id:`download_data_transfer_report_${company.id}`, name:ReportTypes.download_data_transfer_report }
          );
        }
        if(!coveCompanyIds.current.includes(company.id) && downloadAllCompanyFlattenedPermissions.current.includes(role)){
          scheduledList.push(
            { id:`download_all_company_flattened_report_${company.id}`, name:ReportTypes.download_all_company_flattened_report }
          );
        }
        if(!coveCompanyIds.current.includes(company.id) && downloadAllCompanySdtmPermissions.current.includes(role)){
          scheduledList.push(
            { id:`download_all_company_sdtm_report_${company.id}`, name:ReportTypes.download_all_company_sdtm_report }
          );
        }
        if(abbvieCompanyIds.current.includes(company.id) && downloadAllCompanyFlattenedPermissions.current.includes(role)){
          scheduledList.push(
            { id:`download_abbvie_ceva_report_for_all_users_${company.id}`, name:ReportTypes.abbvie_ceva_report },
            { id:`download_abbvie_safety_report_for_all_users_${company.id}`, name:ReportTypes.abbvie_safety_report }
          );
        }
        if(patientCallCentreReportPermissions.current.includes(role)){
          scheduledList.push(
            { id:`call_center_duty_${company.id}`, name:ReportTypes.call_center_duty_report }
          );
        }
        setScheduledSurveyList(scheduledList);
      });
    }
  }, [company, getScheduledSurveys, role]);
  
  useEffect(()=> {
    if(downloadQuipmentReport.download && selectedScheduledReportSurveyId !== 0) {
      
      let url = '/api/surveys/download_quipment_reports_for_all_users';
      let fileName = i18next.t(ReportNames.download_quipment_reports_for_all_users);
      // TODO: Add a date range to the params.
      let reportParams = {
        company_id:company.id,
        survey_ids:[selectedScheduledReportSurveyId],
        type:downloadQuipmentReport.type,
        generate_in_background:'true',
        include_test_patients:includeTestPatients
      };
      if(selectedScheduledReportSurveyId.includes('eye_dropper_quipment_report_')){
        url = '/api/surveys/download_eye_dropper_quipment_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_eye_dropper_quipment_reports_for_all_users);
        // TODO: Add a date range to the params.
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('adverse_event_')){
        url = '/api/surveys/download_adverse_event_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_adverse_event_reports_for_all_users);
        // TODO: Add a date range to the params.
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_participant_stats_report_')){
        // TODO: Check this is the right route.
        url = '/api/surveys/download_participant_stats_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_participant_stats_reports_for_all_users);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_all_company_sdtm_')){
        url = '/api/surveys/download_sdtm_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_sdtm_reports_for_all_companies);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'false',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('sdtm_')){
        url = '/api/surveys/download_sdtm_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_sdtm_reports_for_all_users);
        // TODO: Add a date range to the params.
        let type = 'ascii';
        
        if(downloadQuipmentReport.type === 'non-ascii-csv'){
          type = 'non-ascii-csv';
        }
        reportParams = {
          company_id:company.id,
          type:`${type}`,
          generate_in_background:'true',
          scope_responses_to_company:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('participant_')){
        url = '/api/surveys/download_participant_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_participant_reports_for_all_users);
        // TODO: Add a date range to the params.
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('enrollment_')){
        if(selectedScheduledReportSurveyId.includes('new')){
          url = '/api/reports/reports';
          fileName = i18next.t(ReportNames.new_download_enrollment_reports_for_all_users);
          // TODO: Add a date range to the params.
          reportParams = {
            company_id:company.id,
            type:'non-ascii-csv',
            template_name:'download_enrollment_reports_for_all_users',
            generate_in_background:'true',
            include_test_patients:includeTestPatients
          };
        }
        else{
          url = '/api/surveys/download_enrollment_reports_for_all_users';
          fileName = i18next.t(ReportNames.download_enrollment_reports_for_all_users);
          // TODO: Add a date range to the params.
          reportParams = {
            company_id: company.id,
            type: 'non-ascii-csv',
            generate_in_background: 'true',
            include_test_patients: includeTestPatients
          };
        }
      }
      else if(selectedScheduledReportSurveyId.includes('survey_completion_by_group_')){
        url = '/api/tool_stats/survey_completion_report';
        fileName = i18next.t(ReportNames.survey_completion_by_group_report);
        // TODO: Add a date range to the params.
        reportParams = {
          company_id:company.id,
          group_by_groups:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('survey_completion_')){
        url = '/api/tool_stats/survey_completion_report';
        fileName = i18next.t(ReportNames.survey_completion_report);
        // TODO: Add a date range to the params.
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_v4_download_flattened_')){
        url = '/api/surveys/download_flattened_report';
        fileName = i18next.t(ReportNames.download_flattened_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company: 'true',
          include_test_patients:includeTestPatients,
          version: 5
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_v4_download_all_company_flattened_report_')){
        url = '/api/surveys/download_flattened_report';
        fileName = i18next.t(ReportNames.download_all_company_flattened_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'false',
          include_test_patients:includeTestPatients,
          version: 5
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_flattened_')){
        url = '/api/surveys/download_flattened_report';
        fileName = i18next.t(ReportNames.download_flattened_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company: 'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_all_company_flattened_report_')){
        url = '/api/surveys/download_flattened_report';
        fileName = i18next.t(ReportNames.download_all_company_flattened_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'false',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_data_transfer_report_')){
        url = '/api/surveys/download_data_transfer_report';
        fileName = i18next.t(ReportNames.download_data_transfer_report);
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('medication_report_all_sites_')){
        url = '/api/tool_stats/medication_report';
        fileName = i18next.t(ReportNames.medication_report_all_sites);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'false',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('medication_report_')){
        url = '/api/tool_stats/medication_report';
        fileName = i18next.t(ReportNames.medication_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_ineligible_patients_report_')){
        // TODO: Check this is the right route.
        url = '/api/surveys/download_inleligible_patients_report';
        fileName = i18next.t(ReportNames.download_ineligible_patients_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_all_company_ineligible_patients_report_')){
        // TODO: Check this is the right route.
        url = '/api/surveys/download_inleligible_patients_report';
        fileName = i18next.t(ReportNames.download_all_company_ineligible_patients_report);
        reportParams = {
          company_id:company.id,
          type:'non-ascii-csv',
          generate_in_background:'true',
          scope_responses_to_company:'false',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('call_center_duty_')){
        url = '/api/tool_stats/call_center_duty_report';
        fileName = 'Call_Center_Duty_Report';
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('call_center_logistics_and_eligibility_report_')){
        url = '/api/tool_stats/call_center_logistics_and_eligibility_report';
        fileName = i18next.t(ReportNames.call_center_logistics_and_eligibility_report);
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('payment_metrics_report_')){
        url = '/api/tool_stats/payment_metrics_report';
        fileName = i18next.t(ReportNames.payment_metrics_report);
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_eligibility_custom_report_all_sites_')){
        url = '/api/tool_stats/vaxpath_eligibility_custom_report';
        fileName = i18next.t(ReportNames.vaxpath_eligibility_custom_report_all_sites);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'false',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_eligibility_custom_report_')){
        url = '/api/tool_stats/vaxpath_eligibility_custom_report';
        fileName = i18next.t(ReportNames.vaxpath_eligibility_custom_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_icf_report_version_numbers_')){
        url = '/api/tool_stats/vaxpath_icf_report';
        fileName = i18next.t(ReportNames.vaxpath_icf_report_version_numbers);
        
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients,
          include_es_version_numbers:'true'
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_icf_report_')){
        url = '/api/tool_stats/vaxpath_icf_report';
        fileName = i18next.t(ReportNames.vaxpath_icf_report);
        
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_specific_milestone_report_')){
        url = '/api/tool_stats/vaxpath_specific_milestone_report';
        fileName = i18next.t(ReportNames.vaxpath_specific_milestone_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('vaxpath_visit_completion_status_report_')){
        url = '/api/reports/vaxpath_visit_completion_status_report';
        fileName = i18next.t(ReportNames.vaxpath_visit_completion_status_report);
        reportParams = {
          company_id:company.id,
          template_name:'download_visit_completion_status_reports',
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('call_logs_report_')){
        url = `/api/companies/${company.id}/call_logs_report`;
        fileName = i18next.t(ReportNames.call_logs_report);
        reportParams = {
          company_id:company.id,
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('all_alerts_report_')){
        url = '/api/tool_stats/all_alerts_report';
        fileName = i18next.t(ReportNames.all_alerts_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true'
        };
      }
      else if(selectedScheduledReportSurveyId.includes('current_alerts_report_')){
        url = '/api/tool_stats/current_alerts_report';
        fileName = i18next.t(ReportNames.current_alerts_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true'
        };
      }
      else if(selectedScheduledReportSurveyId.includes('export_access_report_')){
        url = '/api/tool_stats/export_access_report';
        fileName = i18next.t(ReportNames.export_access_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true'
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_abbvie_ceva_report_for_all_users_')){
        url = '/api/surveys/download_abbvie_report_for_all_users';
        fileName = i18next.t(ReportNames.abbvie_ceva_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          type: 'ceva',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_abbvie_safety_report_for_all_users_')){
        url = '/api/surveys/download_abbvie_report_for_all_users';
        fileName = i18next.t(ReportNames.abbvie_safety_report);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          type: 'safety',
          include_test_patients:includeTestPatients
        };
      }
      else if(selectedScheduledReportSurveyId.includes('download_pi_reports_for_all_users_')){
        url = '/api/surveys/download_pi_reports_for_all_users';
        fileName = i18next.t(ReportNames.download_pi_reports_for_all_users);
        reportParams = {
          company_id:company.id,
          scope_responses_to_company:'true',
          generate_in_background:'true',
          include_test_patients:includeTestPatients
        };
      }
      setShowLoading(true);
      global.api.post(url, reportParams)
        .then((result) => {
          notifyReportGenerationSuccess(fileName);
          setShowLoading(false);
          setDownloadQuipmentReport({ download: false, type: null });
        })
        .catch((error) => {
          notifyReportFailed();
          setShowLoading(false);
          setDownloadQuipmentReport({ download: false, type: null });
        });
    }
  }, [downloadQuipmentReport, selectedScheduledReportSurveyId, company, includeTestPatients]);

  const changeTestPatientSetting = () => {
    setIncludeTestPatients(!includeTestPatients)
  };
  
// Render
  
  return (
    <div className="manage-scheduled-reports-download-container">
      <div style={{marginLeft:20}}
           className="manage-patient-container-text">
        <Translation>{ (t, { i18n }) => t('DOWNLOAD_SCHEDULED_SURVEY_DATA') }</Translation>
      </div>
      
      <br/>
      
      <div className="manage-reports-cells-container">
        <div className="manage-reports-survey-cell-download">
          <div className="manage-scheduled-reports-container">
            <div className="group-user-title-text margin-left-0">
              <Translation>{(t, {i18n}) => t('SELECT_REPORT')}</Translation>
            </div>
            <div className="scheduled-reports-list-cell-container">
              {scheduledSurveyList.map((survey) =>
                (
                  <div className="manage-reports-download-cell"
                       key={`${survey.id}-container`}>
                    <label className={'custom-input-cell'}
                           key={`${survey.id}-cell`}>
                      <input type="radio"
                             key={`${survey.id}-input`}
                             name="Scheduled-Report-Radio-Inputs"
                             checked={survey.id === selectedScheduledReportSurveyId}
                             onChange={() => setSelectedScheduledReportSurveyId(survey.id)}
                      />
                      
                      <span className={'custom-checkmark'}
                            data-testId={survey.id === selectedScheduledReportSurveyId ? 'is-checked': ''}
                            key={`${survey.id}-checkmark`}
                      />
                      
                      <div className={'inline-text'}
                           key={`${survey.id}-name`}>
                        {survey.name}
                      </div>
                    </label>
                  </div>
                )
              )}
            </div>
          </div>
          
          <div className="manage-reports-buttons-container">
            {setSelectedScheduledReportSurveyId !== 0 ?
              <label className={'custom-input-cell'}>
                <input className="patient-user-input"
                       type='checkbox'
                       value={includeTestPatients}
                       checked={includeTestPatients}
                       onClick={changeTestPatientSetting}
                />
                
                <span className={'custom-checkmark'}/>
                
                <div className={'inline-text'}>
                  <Translation>{(t, {i18n}) => t('INCLUDE_TEST_PATIENT')}</Translation>
                </div>
              </label>
              :
              null
            }
            
            {(selectedScheduledReportSurveyId !== 0) ?
              (showLoading ?
                  <Loading className="manage-reports-loading"/>
                  :
                  <>
                    <div className="manage-buttons-container auto-width">
                      <BlueButton className='download-report-button'
                                  name={<Translation>{ (t, { i18n }) => t('DOWNLOAD') }</Translation>}
                                  onClick={() => { setDownloadQuipmentReport({ download:true, type:'non-ascii-csv' }) }}
                      />
                    
                      {/* {downloadListVisible ?
                          <ul className="manage-report-dropdown-list">
                            <li key="Scheduled-Report-Download-As-Txt"
                                onClick={() => {
                                  setDownloadQuipmentReport({ download:true, type:'non-ascii' });
                                  setDownloadListVisible(false);
                                }}>
                              <Translation>{ (t, { i18n }) => t('DOWNLOAD_AS_TXT') }</Translation>
                            </li>
                  
                            <li key="Scheduled-Report-Download-As-Csv"
                                onClick={() => {
                                  setDownloadQuipmentReport({ download:true, type:'non-ascii-csv' });
                                  setDownloadListVisible(false);
                                }}>
                              <Translation>{ (t, { i18n }) => t('DOWNLOAD_AS_CSV') }</Translation>
                            </li>
                          </ul>
                          :
                          null
                        } */}
                    </div>
                  </>
              )
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  )
};
export default ScheduledReportsList
