import React from "react";
import './CallButton.scss'
import i18next from "i18next";
import Info from "../../Info/Info";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

const CallButton = ({id, className, click, height, disabled}) => {
  
  // Render
  
  return (
    <Info width={80}
          message={disabled ? null : i18next.t('CALL_BUTTON_ENABLED')}>
      <div className={'call-button-container ' + (disabled ? 'call-button-container-disabled ' : '') + className}
           id={id}
           onClick={!disabled ? click : null}>
        <CallOutlinedIcon className='material-icons'
                          style={{height:height || '30px', width: '30px' }}
        />
      </div>
    </Info>
  );
}
export default CallButton;
