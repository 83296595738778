import i18next from "i18next";
import {KeyForTranslation, languageFromLocale} from "./SurveyBuilderHelpers";
import {triggerTypesRequiringCustomMessages, triggerTypesRequiringAlertTypes, multipleValueOperationsArray} from "../constants/SurveyBuilderTypes";

// Methods

//checkForDuplicateQuestionKeysForQuestion

export function errorForIdTypeAndKey(aErrors, aId, aType, aKey){
  let returnValue = {};
  
  if(aErrors && aId && aType && aKey){
    let errorsArray = aErrors[aId];
    
    if(errorsArray){
      for(let error of errorsArray){
        if(error.key === aKey && error.type === aType){
          returnValue = error;
          break;
        }
      }
    }
  }
  return returnValue;
}

export function addSurveyError(aErrors, aId, aKey, aType, aTitle, aMessageKey, aMessageKeyParams = {}){
  if(aId && aKey && aType && aTitle && aMessageKey){
    let error = {id:aId, key:aKey, type:aType, title:aTitle, message:i18next.t(aMessageKey, aMessageKeyParams), messageKey:aMessageKey};
    let errorsArray = aErrors[aId];
    
    if(errorsArray){
      let duplicateFound = false;
      
      for(let existingError of errorsArray){
        if(error.id === existingError.id &&
          error.key === existingError.key &&
          error.type === existingError.type &&
          error.title === existingError.title &&
          error.messageKey === existingError.messageKey){
          duplicateFound = true;
          break;
        }
      }
      if(!duplicateFound){
        errorsArray.push(error);
      }
    }
    else{
      errorsArray = [error];
    }
    aErrors[aId] = errorsArray;
  }
}

export function detectErrorsForPagesArray(aPagesArray, aErrors, aTranslationsArray, aValidateRubularRegex){
  if(aPagesArray && aErrors && aTranslationsArray && aValidateRubularRegex){
    for(let page of aPagesArray){
      detectErrorsForPage(page, aErrors, aTranslationsArray);
      
      if(page.isSurveyPage){
        for(let survey of page.surveysArray){
          detectErrorsForSurvey(survey, aErrors, aTranslationsArray);
        }
      }
      else{
        for(let question of page.questionsArray){
          detectErrorsForQuestion(question, aErrors, aTranslationsArray, aValidateRubularRegex);
        }
      }
    }
  }
}

export function detectErrorsForPage(aPage, aErrors, aTranslationsArray){
  detectWarningsForPage(aPage, aErrors, aTranslationsArray);
}

export function detectErrorsForSurvey(aSurvey, aErrors, aTranslationsArray){
  if(aSurvey && aErrors && aTranslationsArray){
    let title = 'Survey';
  
    if(aSurvey.survey_reference && aSurvey.survey_reference.length > 0){
      title = 'S: ' + aSurvey.survey_reference;
    }
    if(!aSurvey.survey_reference || aSurvey.survey_reference.length === 0){
      addSurveyError(aErrors, aSurvey.id, 'survey_reference', 'error', title, 'SURVEY_REFERENCE_REQUIRED');
    }
    if(aSurvey.choices){
      for(let index = 0; index < aSurvey.choices.length; index += 1){
        let choice = aSurvey.choices[index];
      
        if(!choice.value || choice.value.length === 0){
          addSurveyError(aErrors, aSurvey.id, 'choice_value_' + index, 'error', title, 'CHOICE_VALUE_REQUIRED');
        }
        if(!choice.label || choice.label.length === 0){
          addSurveyError(aErrors, aSurvey.id, 'choice_label_' + index, 'error', title, 'CHOICE_LABEL_MISSING');
        }
        for(let translation of aTranslationsArray){
          if(translation.locale !== 'en'){
            let key = 'label_' + translation.locale;
          
            if(!choice[key] || choice[key].length === 0){
              addSurveyError(aErrors, aSurvey.id, 'choice_label_' + translation.locale + '_' + index, 'error', title, languageFromLocale(translation.locale) + '_CHOICE_LABEL_MISSING');
            }
          }
        }
      }
    }
    if(aSurvey.repeat_count){
      if(aSurvey.repeat_count_question_key){
        // TODO: Add translation for SURVEY_REPEAT_COUNT_OR_QUESTION_KEY_ERROR.
        addSurveyError(aErrors, aSurvey.id, 'repeat_count', 'error', title, 'SURVEY_REPEAT_COUNT_OR_QUESTION_KEY_ERROR');
        addSurveyError(aErrors, aSurvey.id, 'repeat_count_key', 'error', title, 'SURVEY_REPEAT_COUNT_OR_QUESTION_KEY_ERROR');
      }
      else if(aSurvey.repeat_count < 1 || aSurvey.repeat_count > 20){
        // TODO: Add translation for SURVEY_REPEAT_COUNT_ERROR.
        addSurveyError(aErrors, aSurvey.id, 'repeat_count', 'error', title, 'SURVEY_REPEAT_COUNT_ERROR');
      }
    }
    if(aSurvey.repeat_count_question_key){
      // TODO: Check if this key exists in the given survey.
      // TODO: Add translation for SURVEY_REPEAT_COUNT_QUESTION_KEY_ERROR.
      // addSurveyError(aErrors, aSurvey.id, 'repeat_count_key', 'error', title, 'SURVEY_REPEAT_COUNT_QUESTION_KEY_ERROR');
    }
  }
  detectWarningsForSurvey(aSurvey, aErrors, aTranslationsArray);
}

export function detectErrorsForQuestion(aQuestion, aErrors, aTranslationsArray, aValidateRubularRegex){
  if(aQuestion && aErrors){
    let title = 'Question';
    
    if(!aQuestion['key'] || aQuestion['key'].length === 0){
      addSurveyError(aErrors, aQuestion.id, 'key', 'error', title, 'QUESTION_KEY_REQUIRED');
    }
    else{
      title = 'Q: ' + aQuestion.key;
    }
    if(!aQuestion['label'] || aQuestion['label'].length === 0){
      addSurveyError(aErrors, aQuestion.id, 'label', 'error', title, 'ENGLISH_TITLE_REQUIRED');
    }
    if(aQuestion.db_write){
      if(aQuestion.db_write.table.length === 0){
        addSurveyError(aErrors, aQuestion.id, 'db_write_table', 'error', title, 'TABLE_REQUIRED');
      }
      if(aQuestion.db_write.column.length === 0){
        addSurveyError(aErrors, aQuestion.id, 'db_write_column', 'error', title, 'COLUMN_REQUIRED');
      }
    }
    if(aQuestion.regex){
      aValidateRubularRegex(aQuestion.regex).then((newResult) => {
        if(!newResult.data.data.validate_regex.is_valid){
          addSurveyError(aErrors, aQuestion.id, 'regex', 'error', title, 'RUBULAR_REGEX_INVALID');
        }
      }, (newError) => {
        // TODO: Handle error.
      });
    }
    if(aQuestion.trigger){
      if(!aQuestion.trigger.type || aQuestion.trigger.type.length === 0){
        addSurveyError(aErrors, aQuestion.id, 'trigger_type', 'error', title, 'TYPE_REQUIRED');
      }
      else{
        if(triggerTypesRequiringAlertTypes.includes(aQuestion.trigger.type) && (!aQuestion.trigger.alert_type || aQuestion.trigger.alert_type.length === 0)){
          addSurveyError(aErrors, aQuestion.id, 'trigger_alert_type', 'error', title, 'ALERT_TYPE_REQUIRED');
        }
        if(!aQuestion.trigger.operation || aQuestion.trigger.operation.length === 0){
          addSurveyError(aErrors, aQuestion.id, 'trigger_operation', 'error', title, 'OPERATION_REQUIRED');
        }
        else{
          if(multipleValueOperationsArray.includes(aQuestion.trigger.operation)){
            if(Array.isArray(aQuestion.trigger.value) && aQuestion.trigger.value.length > 0){
              for(let index = 0; index < aQuestion.trigger.value.length; index += 1){
                if(!aQuestion.trigger.value[index] || aQuestion.trigger.value[index].length === 0){
                  addSurveyError(aErrors, aQuestion.id, 'trigger_value_' + index.toString(), 'error', title, 'VALUE_REQUIRED');
                }
              }
            }
          }
          else{
            if(!aQuestion.trigger.value || aQuestion.trigger.value.length === 0){
              addSurveyError(aErrors, aQuestion.id, 'trigger_value', 'error', title, 'VALUE_REQUIRED');
            }
          }
        }
        if(triggerTypesRequiringCustomMessages.includes(aQuestion.trigger.type)){
          if(!aQuestion.trigger['custom_message'] || aQuestion.trigger['custom_message'].length === 0){
            addSurveyError(aErrors, aQuestion.id, 'trigger_custom_message', 'error', title, 'ENGLISH_CUSTOM_MESSAGE_REQUIRED');
          }
        }
      }
    }
    if(aQuestion.important){
      if(!aQuestion.important.label || aQuestion.important.label.length === 0){
        addSurveyError(aErrors, aQuestion.id, 'important_label', 'error', title, 'IMPORTANT_LABEL_REQUIRED');
      }
    }
    if(aQuestion.exit_to_conditions){
      if(!aQuestion.exit_to_key || aQuestion.exit_to_key.length === 0){
        addSurveyError(aErrors, aQuestion.id, 'exit_to_key', 'error', title, 'EXIT_KEY_REQUIRED');
      }
    }
    if(aQuestion.choices){
      for(let index = 0; index < aQuestion.choices.length; index += 1){
        let choice = aQuestion.choices[index];
      
        if(!choice.value || choice.value.length === 0){
          addSurveyError(aErrors, aQuestion.id, 'choice_value_' + index, 'error', title, 'CHOICE_VALUE_REQUIRED');
        }
        if(!choice.label || choice.label.length === 0){
          addSurveyError(aErrors, aQuestion.id, 'choice_label_' + index, 'error', title, 'CHOICE_LABEL_MISSING');
        }
        for(let translation of aTranslationsArray){
          if(translation.locale !== 'en'){
            let key = 'label_' + translation.locale;
            
            if(!choice[key] || choice[key].length === 0){
              addSurveyError(aErrors, aQuestion.id, 'choice_label_' + translation.locale + '_' + index, 'error', title, languageFromLocale(translation.locale) + '_CHOICE_LABEL_MISSING');
            }
          }
        }
      }
      if(aQuestion.type === 'list'){
        if(!aQuestion['list_reference'] || aQuestion['list_reference'].length === 0){
          addSurveyError(aErrors, aQuestion.id, 'list_reference', 'error', title, 'ENGLISH_SURVEY_LIST_REFERENCE_REQUIRED');
        }
      }
      if(aQuestion.type === 'dynamic_list'){
        if(!aQuestion['dynamic_list_reference'] || aQuestion['dynamic_list_reference'].length === 0){
          // TODO: Add custom error for Dynamic Lists.
          addSurveyError(aErrors, aQuestion.id, 'dynamic_list_reference', 'error', title, 'ENGLISH_SURVEY_LIST_REFERENCE_REQUIRED');
        }
      }
    }
    detectWarningsForQuestion(aQuestion, aErrors, aTranslationsArray);
  }
}

// Helper Methods

function detectWarningsForPage(aPage, aErrors, aTranslationsArray){
  if(aPage && aErrors && aTranslationsArray){
    if(aPage.isSurveyPage){
      if(aPage.surveysArray.length === 0){
        addSurveyError(aErrors, aPage.id, 'emptyPage', 'warning', 'P: ' + aPage.key, 'SURVEY_PAGE_EMPTY_ERROR');
      }
    }
    else{
      if(aPage.questionsArray.length === 0){
        addSurveyError(aErrors, aPage.id, 'emptyPage', 'warning', 'P: ' + aPage.key, 'QUESTION_PAGE_EMPTY_ERROR');
      }
    }
    if(aPage.key !== 'end' && aPage.key !== 'disqualified'){
      for(let translation of aTranslationsArray){
        let key = KeyForTranslation('page_title', translation);
        
        if(!aPage[key] || aPage[key].length === 0){
          addSurveyError(aErrors, aPage.id, key, 'warning', 'P: ' + aPage.key, languageFromLocale(translation.locale) + '_TITLE_MISSING');
        }
      }
    }
  }
}

function detectWarningsForSurvey(aSurvey, aErrors, aTranslationsArray){

}

function detectWarningsForQuestion(aQuestion, aErrors, aTranslationsArray){
  if(aQuestion){
    let title = 'Question';
    
    if(aQuestion.key && aQuestion.key.length > 0){
      title = 'Q: ' + aQuestion.key;
    }
    for(let translation of aTranslationsArray){
      if(translation.locale !== 'en'){
        let key = 'label_' + translation.locale;
        
        if(!aQuestion[key] || aQuestion[key].length === 0){
          addSurveyError(aErrors, aQuestion.id, key, 'warning', title, languageFromLocale(translation.locale) + '_TITLE_MISSING');
        }
      }
    }
    // TODO: Add warning if branching goes to question on the same page.
    
    if(aQuestion.default_value && aQuestion.default_value.length === 0){
      addSurveyError(aErrors, aQuestion.id, 'default_value', 'warning', title, 'DEFAULT_VALUE_MISSING');
    }
    if(aQuestion.skip){
      if(!aQuestion.skip['label'] || aQuestion.skip['label'].length === 0){
        addSurveyError(aErrors, aQuestion.id, 'skip_label', 'warning', title, 'ENGLISH_SKIP_LABEL_MISSING');
      }
      if(!aQuestion.skip['value'] || aQuestion.skip['value'].length === 0){
        addSurveyError(aErrors, aQuestion.id, 'skip_value', 'warning', title, 'SKIP_VALUE_MISSING');
      }
      // TODO: Uncomment when Skip Labels support translations.
      // TODO: Add in translations for each language as well.
      // for(let translation of aTranslationsArray){
      //   if(translation.locale !== 'en'){
      //     let key = 'label_' + translation.locale;
      //
      //     if(!aQuestion.skip[key] || aQuestion.skip[key].length === 0){
      //       addSurveyError(aErrors, aQuestion, 'skip_' + key, 'warning', title, languageFromLocale(translation.locale) + '_SKIP_LABEL_MISSING');
      //     }
      //   }
      // }
    }
    if(aQuestion.other){
      for(let translation of aTranslationsArray){
        let key = KeyForTranslation('other_label', translation);
        
        if(!aQuestion[key] || aQuestion[key].length === 0){
          addSurveyError(aErrors, aQuestion.id, key, 'warning', title, languageFromLocale(translation.locale) + '_OTHER_LABEL_MISSING');
        }
      }
      // TODO: Add in when support for value is added to the server.
      // TODO: Add OTHER_VALUE_MISSING translation.
      // if(!aQuestion.other_value || aQuestion.other_value.length === 0){
      //   addSurveyError(aErrors, aQuestion.id, 'other_value', 'warning', title, 'OTHER_VALUE_MISSING');
      // }
    }
    for(let conditionKey of ['conditions', 'exit_to_conditions']){
      if(aQuestion[conditionKey]){
        for(let index = 0; index < aQuestion[conditionKey].length; index += 1){
          let condition = aQuestion[conditionKey][index];
          let errorKeyPrefix = conditionKey.slice(0, -1) + '_' + index.toString();
  
          if(condition.is_table){
            if(!condition.table || condition.table.length === 0){
              addSurveyError(aErrors, aQuestion.id, errorKeyPrefix + '_table', 'warning', title, 'TABLE_MISSING');
            }
            if(!condition.column || condition.column.length === 0){
              addSurveyError(aErrors, aQuestion.id, errorKeyPrefix + '_column', 'warning', title, 'MISSING_COLUMN');
            }
          }
          else{
            if(!condition.question || condition.question.length === 0){
              addSurveyError(aErrors, aQuestion.id, errorKeyPrefix + '_question', 'warning', title, 'MISSING_QUESTION');
            }
          }
          if(!condition.operation || condition.operation.length === 0){
            addSurveyError(aErrors, aQuestion.id, errorKeyPrefix + '_operation', 'warning', title, 'MISSING_OPERATION');
          }
          if(!condition.value || condition.value.length === 0){
            addSurveyError(aErrors, aQuestion.id, errorKeyPrefix + '_value', 'warning', title, 'MISSING_VALUE');
          }
        }
      }
    }
    if(aQuestion.trigger){
      if(triggerTypesRequiringCustomMessages.includes(aQuestion.trigger.type)){
        for(let translation of aTranslationsArray){
          if(translation.locale !== 'en'){
            let key = KeyForTranslation('trigger_custom_message', translation);
            
            if(!aQuestion.trigger[key] || aQuestion.trigger[key].length === 0){
              // TODO: Add these translations.
              addSurveyError(aErrors, aQuestion.id, key, 'warning', title, languageFromLocale(translation.locale) + '_CUSTOM_MESSAGE_MISSING');
            }
          }
        }
      }
    }
    if(aQuestion.important){
      for(let translation of aTranslationsArray){
        if(translation.locale !== 'en'){
          let key = KeyForTranslation('label', translation);
          if(!aQuestion.important[key] || aQuestion.important[key].length === 0){
            // TODO: Add these translations.
            addSurveyError(aErrors, aQuestion.id, 'important_' + key, 'warning', title,  'IMPORTANT_LABEL_MISSING_' + languageFromLocale(translation.locale));
          }
        }
      }
    }
    if(aQuestion.type === 'rank'){
      if(aQuestion.choices){
        for(let index = 0; index < aQuestion.choices.length; index += 1){
          let choice = aQuestion.choices[index];
      
          if(!choice.label || choice.label.length === 0){
            addSurveyError(aErrors, aQuestion.id, 'choice-label-' + index, 'warning', title, 'CHOICE_LABEL_MISSING');
          }
          if(!choice.value || choice.value.length === 0){
            addSurveyError(aErrors, aQuestion.id, 'choice-value-' + index, 'warning', title, 'CHOICE_VALUE_REQUIRED');
          }
        }
      }
    }
    if(aQuestion.type === 'list'){
      for(let translation of aTranslationsArray){
        if(translation.locale !== 'en'){
          const key = KeyForTranslation('list_reference', translation);
          const listIdKey = KeyForTranslation('list_id', translation);
  
          if(!aQuestion[key] || aQuestion[key].length === 0){
            if(!aQuestion[listIdKey] || aQuestion[listIdKey].length === 0){
              // TODO: Add these translations.
              addSurveyError(aErrors, aQuestion.id, key, 'warning', title, '' + languageFromLocale(translation.locale) + '_LIST_REFERENCE_MISSING');
            }
          }
        }
      }
    }
    if(aQuestion.type === 'dynamic_list'){
      for(let translation of aTranslationsArray){
        if(translation.locale !== 'en'){
          const key = KeyForTranslation('dynamic_list_reference', translation);
          
          if(!aQuestion[key] || aQuestion[key].length === 0){
            // TODO: Add these translations.
            addSurveyError(aErrors, aQuestion.id, key, 'warning', title, '' + languageFromLocale(translation.locale) + '_REFERENCE_MISSING');
          }
        }
      }
    }
  }
}
