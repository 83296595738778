import React from 'react';
import './SurveyLinksList.scss';
import {toast} from "react-toastify";
import i18next from "i18next";
import i18n from "../../i18n";
import DynamicList from "../DynamicList/DynamicList";
import SurveyDrawer from "../../Components/SurveyDrawer/SurveyDrawer";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {errorMessageFromServerError} from "../../Helpers/Helpers";
import { currentDateHumanizedWithoutTimeStamp, momentFromDate } from 'Helpers/DateHelpers';
import moment from 'moment';
import LabeledInfo from 'Custom UI/LabeledInputs/LabeledInfo/LabeledInfo';
import { Translation, withTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';

const notifyReportFailed = () => toast(<Translation>{(t, {i18n}) => t('REPORT_SENT_FAILED_MESSAGE')}</Translation>, NOTIFY_OPTS.default);
const notifySurveyLinkFetchError = (aError) => toast(i18next.t('SURVEY_LINK_NOT_FOUND', {aError:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyReportGenerationSuccess = (report, aTabKey) => toast(<span><Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:report}) }</Translation> <a target={'_blank'} rel={'noreferrer'} href={'/manage/reports?tab=' + aTabKey}><Translation>{(t, {i18n}) => t('CLICK_HERE')}</Translation></a></span>, NOTIFY_OPTS.defaultFromTop);
class SurveyLinksList extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      surveyLinkId:null,
      isDrawerOpen:false,
      shouldReload:false,
      objectToReplace:null,
      selectedSurveyLink:null,
      showSurveyResponses:false,
    };
    this.closeSurveyDrawer = this.closeSurveyDrawer.bind(this);
    this.hasSurveyLinkExpired = this.hasSurveyLinkExpired.bind(this);
    this.renderProgress = this.renderProgress.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {surveyLinkId, getSurveyLinkById} = this.props;

    if(surveyLinkId && surveyLinkId.length > 0 && getSurveyLinkById && surveyLinkId !== this.state.surveyLinkId){
      this.setState({surveyLinkId:surveyLinkId});
      getSurveyLinkById(surveyLinkId).then((newResponse) => {
        let selectedSurveyLink = newResponse.data.data.survey_link;
        this.setState({isDrawerOpen:true, selectedSurveyLink:selectedSurveyLink});
      }, (newError) => {
        notifySurveyLinkFetchError(errorMessageFromServerError(newError));
      });
    }
  }

  // Methods

  closeSurveyDrawer(){
    const {selectedSurveyLink} = this.state;
    const {getSurveyLinkById} = this.props;
    this.setState({isDrawerOpen:false, showSurveyResponses:false});

    getSurveyLinkById(selectedSurveyLink.id).then((newResponse) => {
      let selectedSurveyLink = newResponse.data.data.survey_link;
      this.setState({objectToReplace:selectedSurveyLink, selectedSurveyLink:null});
    }, (newError) => {
      notifySurveyLinkFetchError(errorMessageFromServerError(newError));
      this.setState({selectedSurveyLink:null});
    });
  }

  hasSurveyLinkExpired(aSuveyLink) {
    if(aSuveyLink && aSuveyLink.expires_at) {
      return momentFromDate(aSuveyLink.expires_at) < moment();
    }
  }

  isSurveyComplete(aSurveyLink) {
    return aSurveyLink && aSurveyLink.survey_response && aSurveyLink.survey_response.complete;
  }

  renderProgress(aSurveyLink) {
    const progress = (aSurveyLink && aSurveyLink.survey_response && aSurveyLink.survey_response.progress) || 0;
    const progressPercentage = progress + '%';
    
    if(progress !== 100 && this.hasSurveyLinkExpired(aSurveyLink)) {
      return (
        <LabeledInfo className="survey-link-progress"
                     bold={false}
                     label={progressPercentage}
                     infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_LINK_HAS_EXPIRED')}</Translation>}
                     popoverPlacement='right'
        />
      )
    }
    else if(this.isSurveyComplete(aSurveyLink)) {
      return (
        <div className='survey-complete'>
          <span>{ progressPercentage }</span> &nbsp;
          <img alt='adminLocked'
                   src='/imgs/app/admin-locked.svg'
                   className="survey-version-collection-cell-locked-image"
                   style={{width:'20px', height:'20px'}}
              />
        </div>
      )
    }

    return progressPercentage;
  }

  patientId = (aPatient) => {
    const {t} = this.props;
    let returnValue = '';
    let edcIDString = t('EDC_ID_UPPERCASE', {i18n});
    let siteIDString = t('SITE_ID', {i18n});
    
    if(aPatient){
      if(aPatient.edc_id){
        if(aPatient.site_id) {
          returnValue = `${siteIDString}: ${aPatient.site_id} ${edcIDString}: ${aPatient.edc_id}`;
        }
        else{
          returnValue = `${edcIDString}: ${aPatient.edc_id}`;
        }
      }
      else{
        returnValue = aPatient.id;
      }
    }
    return returnValue;
  };
  
  requestPatientSurveyReportCSV = (aSurveyLink) => {
    const {company} = this.props;
    
    const reportReqParams = {
      user_id:aSurveyLink.patient.id,
      quest_id:aSurveyLink.quest.id,
      company_id:company && company.id
    };
    global.api.post("/api/tool_stats/patient_survey_version_collection_report", reportReqParams, {responseType:'blob'})
    .then((result) =>
      fileDownload(result.data, `survey_responses_${aSurveyLink.quest.title}_${currentDateHumanizedWithoutTimeStamp()}_${this.patientId(aSurveyLink.patient)}.zip`))
    .catch((error) => notifyReportFailed());
  };

  requestPatientSurveyReportPDF = (aSurveyLink) => {
    const {company} = this.props;
    
    const reportReqParams = {
      user_id:aSurveyLink.patient.id,
      quest_id:aSurveyLink.quest.id,
      company_id:company && company.id,
      generate_in_background:'true'
    };
    global.api.post("/api/tool_stats/patient_survey_version_collection_report_pdf", reportReqParams, {responseType: 'blob'})
    .then((result) => {
      let fileName = `survey_responses_${aSurveyLink.quest.title}_${currentDateHumanizedWithoutTimeStamp()}_${this.patientId(aSurveyLink.patient)}.zip`;
      notifyReportGenerationSuccess(fileName, 'GENERATED_REPORTS');
    })
    .catch((error) => notifyReportFailed());
  };

  getSurveyId() {
    const { showSurveyResponses, selectedSurveyLink } = this.state;
    if(selectedSurveyLink && showSurveyResponses) {
      return selectedSurveyLink.quest && selectedSurveyLink.quest.survey_version_collection && selectedSurveyLink.quest.survey_version_collection.id
    }

    return selectedSurveyLink.survey && selectedSurveyLink.survey.id
  }

  addDataQuery(aSurveyResponseDetailsId) {
    this.closeSurveyDrawer();
    this.props.addDataQuery(aSurveyResponseDetailsId)
  }

  // Render

  render(){
    const {user, company, getSurveyLinks, backButtonClicked, patient,
      canMakeEntry = false, showPatientColumns = false, getSurveyResponses, fetchPatient,
      updateSurveyResponseLockState, setPatientId, showLockOption = true, manageSurveyLinks = false} = this.props;
    const {isDrawerOpen, shouldReload, objectToReplace, selectedSurveyLink, showSurveyResponses} = this.state;

    const role = roleForCompany(company);

    let menuItemsArray = [];

    if(canMakeEntry){
      menuItemsArray.push({
        title:'MAKE_AN_ENTRY_TEXT',
        clickAction:(aSurveyLink) => this.setState({isDrawerOpen:true, selectedSurveyLink:aSurveyLink}),
        isValid: (aSurveyLink) => aSurveyLink && (!aSurveyLink.survey_response || !aSurveyLink.survey_response.complete) && !this.hasSurveyLinkExpired(aSurveyLink)
      });
    }

    if(patient) {
      menuItemsArray.push({title:'DOWNLOAD_REPORT_CSV', clickAction:(aSurveyLink) => {
        this.requestPatientSurveyReportCSV(aSurveyLink);
      }, isValid:(aSurveyLink) => this.isSurveyComplete(aSurveyLink)});
  
      menuItemsArray.push({title:'DOWNLOAD_REPORT_PDF', clickAction:(aSurveyLink) => {
        this.requestPatientSurveyReportPDF(aSurveyLink);
      }, isValid:(aSurveyLink) => this.isSurveyComplete(aSurveyLink)});
    }

    menuItemsArray.push({title:'VIEW_SURVEY_RESPONSES', clickAction:(aSurveyLink) => {
      this.setState({selectedSurveyLink:aSurveyLink, isDrawerOpen:true, showSurveyResponses: true});
      if(setPatientId) {
        setPatientId(aSurveyLink);
      }
    }});

    let columnsArray = [{key:'survey.name', columnWeight:2, columnNameKey:'SURVEY_NAME', propertiesArray:['survey', 'name']}];
    if(showPatientColumns) {
      columnsArray.push({key:'patient.subject_id', columnWeight:1, columnNameKey:'SUBJECT_USER_ID', propertiesArray:['patient', 'id']});
      columnsArray.push({key:'patient.edc_id', columnWeight:1, columnNameKey:'SUBJECT_EDC_ID', propertiesArray:['patient', 'edc_id']});
    }
    else {
      columnsArray.push({key:'user', columnWeight:3, columnNameKey:'ADMIN_RECIPIENT', propertiesArray:['user'], valueFormatter: (aUser) => {
        return (aUser.first_name || aUser.last_name) ? formatUserName(aUser) : aUser.email;
      }});
    }
    columnsArray.push({key:'survey_response.progress', columnWeight:1, columnNameKey:'PROGRESS', templateCell: (aSurveyLink) => this.renderProgress(aSurveyLink)});
    columnsArray.push({key:'created_at', dateFormat:'MM/DD/YYYY [@]h:mma', columnWeight:1, columnNameKey:'CREATED_AT', propertiesArray:['created_at']});

    return (
      <div className="survey-links-list-container" style={{height: manageSurveyLinks ? '556px' : '381px'}}>
        <DynamicList id="survey-links-list"
                     company={company}
                     didReload={() => this.setState({shouldReload:false})}
                     columnsArray={columnsArray}
                     fetchObjects={(aLimit, aOffset) => getSurveyLinks(!patient ? user.id : null, patient && patient.id, aLimit, aOffset)}
                     shouldReload={shouldReload}
                     showSearchBar={false}
                     menuItemsArray={menuItemsArray.length > 0 ? menuItemsArray : null}
                     minColumnWidth={100}
                     objectToReplace={objectToReplace}
                     totalObjectsKey={'TOTAL_SURVEY_LINKS'}
                     clampFirstColumn={false}
                     backButtonClicked={backButtonClicked}
                     responseTotalKeysArray={['data', 'data', 'survey_links', 'total']}
                     finishedReplacingObject={() => this.setState({objectToReplace:null})}
                     responseObjectKeysArray={['data', 'data', 'survey_links', 'survey_links']}
        />

        {selectedSurveyLink && selectedSurveyLink.survey && selectedSurveyLink.patient ?
          <SurveyDrawer open={isDrawerOpen}
                        title={selectedSurveyLink.survey.name}
                        userId={selectedSurveyLink.patient.id}
                        adminId={user.id}
                        company={company}
                        onClose={() => this.closeSurveyDrawer()}
                        surveyId={this.getSurveyId()}
                        addDataQuery={(aSurveyResponseDetailsId) => this.addDataQuery(aSurveyResponseDetailsId)}
                        fetchPatient={fetchPatient}
                        onFinishedSurvey={() => this.closeSurveyDrawer()}
                        surveyResponseId={selectedSurveyLink.survey_response ? selectedSurveyLink.survey_response.id : null}
                        getSurveyResponses={getSurveyResponses}
                        showPreviousButton={false}
                        showLockOption={showLockOption}
                        showSurveyResponses={showSurveyResponses}
                        updateSurveyResponseLockState={updateSurveyResponseLockState}
          />
          :
          null
        }
      </div>
    );
  }
}

export default withTranslation()(SurveyLinksList)