import React from 'react';
import './SurveyConditionQuestionCell.scss';
import '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown.scss';
import i18next from 'i18next';
import LabeledDropDown from '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown';

export default class SurveyConditionQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      valuesArray:[{}],
      selectedValuesArray:[]
    };
    if(props.answer && props.answer.prettyValues){
      let valuesArray = [];
      let selectedValuesArray = [];
      props.answer.prettyValues.forEach(value => {
        let keys = Object.keys(value);
        
        if(keys && keys.length > 0){
          keys.forEach(key => {
            let index = parseInt(key);
            let condition = JSON.parse(value[key]);
            valuesArray[index] = {value:condition.id};
            selectedValuesArray[index] = {key:key, value:value[key]};
          });
        }
        this.state.valuesArray = valuesArray;
        this.state.selectedValuesArray = selectedValuesArray;
      });
    }
    this.handleChange = this.handleChange.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.removeCondition= this.removeCondition.bind(this);
  }

  // Methods

  handleChange(aIndex, aOption){
    const {question, onAnswerChange} = this.props;
    const {valuesArray, selectedValuesArray} = this.state;
    valuesArray[aIndex] = {value:aOption.id};
    selectedValuesArray[aIndex] = {key:aIndex.toString(), value:JSON.stringify(aOption)};
    this.setState({selectedValuesArray:selectedValuesArray, valuesArray:valuesArray});
    onAnswerChange(question, null, selectedValuesArray, null, null, false);
  }

  addCondition(){
    const {valuesArray, selectedValuesArray} = this.state;
    valuesArray.push({});
    selectedValuesArray.push({});
    this.setState({valuesArray:valuesArray, selectedValuesArray:selectedValuesArray});
  }

  removeCondition(){
    const {valuesArray, selectedValuesArray} = this.state;
    
    if(valuesArray && valuesArray.length > 0){
      valuesArray.splice(-1, 1);
      selectedValuesArray.splice(-1, 1);
  
      if(valuesArray.length === 0){
        valuesArray.push({});
        selectedValuesArray.push({});
      }
      this.setState({valuesArray:valuesArray, selectedValuesArray:selectedValuesArray});
    }
  }
  
  // Render
  
  render(){
    const {disabled, question, getConditions} = this.props;
    const {valuesArray, selectedValuesArray} = this.state;
    const {key, type} = question;
    
    return(
      <div key={'survey-' + type + '-question-cell-' + key}
           className='survey-condition-question-cell'>
        {valuesArray && valuesArray.length > 0 ?
          valuesArray.map((value, index) => (
            <div key={'SurveyMedicationQuestionCell' + question.key + index}>
              <LabeledDropDown value={selectedValuesArray[index] && selectedValuesArray[index].value && JSON.parse(selectedValuesArray[index].value)}
                               disabled={disabled}
                               handleSave={(aOption) => this.handleChange(index, aOption)}
                               isClearable={true}
                               fetchObjects={(aLimit, aOffset, aSearchString) => getConditions(aSearchString, aLimit, aOffset)}
                               menuPosition={'portal'}
                               menuPlacement={'bottom'}
                               labelPropertiesArray={['name']}
                               valuePropertiesArray={['id']}
                               responseTotalKeysArray={['data', 'data', 'conditions_page', 'total']}
                               responseObjectKeysArray={['data', 'data', 'conditions_page', 'conditions']}
              />
            </div>
          ))
          :
          null
        }
        
        {question.type === 'multi_condition' ?
          <div className="survey-medication-button-container">
            <button className="survey-button-add-medication"
                    onClick={() => this.addCondition()}>
              {i18next.t('ADD_CONDITION')}
            </button>
            
            <button className="survey-button-remove-medication"
                    onClick={() => this.removeCondition()}>
              {i18next.t('REMOVE_CONDITION')}
            </button>
          </div>
          :
          null
        }
      </div>
    )
  }
}
