import React, {useEffect, useState} from 'react';
import './ScheduleTriggerModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import ScheduleTrigger from "../../Components/ScheduleTrigger/ScheduleTrigger";
import {ScheduleTriggerFireEventTypeValues, ScheduleTriggerTypes} from "../../constants/ScheduleTriggerTypes";

const ScheduleTriggerModal = ({open, save, close, survey, company, trigger, disabled, surveyId, languages, triggerType, scheduleName,
                                surveysArray, removeScheduleTrigger, fetchAppAdditionalColumns, presentedInsideParentModal,
                                fetchQuestionsForSurveyById, fetchSurveyVersionCollections, fetchQuestionsForSurveyByReference}) => {
  
  // Instance Variables
  
  const [saveButtonPressed, setSaveButtonPressed] = useState(false);
  const titles = {[ScheduleTriggerTypes.general]:'ALERT_PARAMETERS', [ScheduleTriggerFireEventTypeValues.set_survey_start_date]:'START_TRIGGER', [ScheduleTriggerFireEventTypeValues.set_survey_end_date]:'END_TRIGGER'};
  
  // Effects
  
  useEffect(() => {
    if(open === false){
      setSaveButtonPressed(false);
    }
  }, [open]);
  
  // Render
  
  return (
    // <GeneralModal open={open}
    //               save={() => setSaveButtonPressed(true)}
    //               close={close}
    //               title={triggerType === ScheduleTriggerTypes.general ? i18next.t('ALERT_PARAMETERS') : ''}
    //               width={'1000px'}
    //               height={'800px'}
    //               loading={saveButtonPressed}
    //               description={triggerType === ScheduleTriggerTypes.start ? i18next.t('SCHEDULE_START_TRIGGER_DESCRIPTION', {aName:scheduleName}) : (triggerType === ScheduleTriggerTypes.end ? i18next.t('SCHEDULE_END_TRIGGER_DESCRIPTION', {aName:scheduleName}) : '')}
    //               containsDropDown={true}
    //               presentedInsideParentModal={presentedInsideParentModal}>
    <GeneralModal title={i18next.t(titles[triggerType]) + (disabled ? ' (' + i18next.t('READONLY') + ')' : '')}
                  width={'calc(100% - 40px)'}
                  height={'calc(100% - 90px)'}
                  isOpen={open}
                  loading={saveButtonPressed}
                  maxWidth={1500}
                  maxHeight={800}
                  handleSave={() => setSaveButtonPressed(true)}
                  description={triggerType === ScheduleTriggerFireEventTypeValues.set_survey_start_date ? i18next.t('SCHEDULE_START_TRIGGER_DESCRIPTION', {aName:scheduleName}) : (triggerType === ScheduleTriggerFireEventTypeValues.set_survey_end_date ? i18next.t('SCHEDULE_END_TRIGGER_DESCRIPTION', {aName:scheduleName}) : '')}
                  noTitleHeading={true}
                  showSaveButton={!disabled}
                  containsDropDown={true}
                  handleCloseModal={() => close()}
                  presentedInsideParentModal={presentedInsideParentModal}>
      {open ?
        <ScheduleTrigger save={save}
                         survey={survey}
                         company={company}
                         trigger={trigger}
                         disabled={disabled}
                         surveyId={surveyId}
                         languages={languages}
                         triggerType={triggerType}
                         surveysArray={surveysArray}
                         finishedSaving={(aClose) => {
                           setSaveButtonPressed(false);
                           
                           if(aClose){
                             close();
                           }
                         }}
                         saveButtonPressed={saveButtonPressed}
                         removeScheduleTrigger={removeScheduleTrigger}
                         fetchAppAdditionalColumns={fetchAppAdditionalColumns}
                         fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                         fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                         fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
        />
        :
        null
      }
    </GeneralModal>
  );
};
export default ScheduleTriggerModal;
