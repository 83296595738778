import React from 'react';
import {errorMessageFromServerError} from "../Helpers/Helpers";

export default class InfiniteScrollManager extends React.Component{
  
  // Instance Variables
  
  list = [];
  limit = 10;
  loading = false;
  loadedAll = false;
  shouldLoadOnSuccess = false;
  
  // Getters
  
  getList(){
    return this.list;
  }
  
  isLoading(){
    return this.loading;
  }
  
  totalCount(){
    return this.list.length;
  }
  
  hasLoadedAll(){
    return this.loadedAll;
  }
  
  // Setters
  
  setList(aList){
    this.list = aList;
    this.loading = false;
    this.loadedAll = false;
    this.shouldLoadOnSuccess = false;
  }
  
  // Init
  
  constructor(props){
    super(props);
    
    if(props.limit && props.limit > 0){
      this.limit = props.limit;
    }
    this.fetch = this.fetch.bind(this);
    this.getList = this.getList.bind(this);
    this.setList = this.setList.bind(this);
    
    if(props.fetchImmediately){
      this.fetch(true);
    }
  }
  
  // Methods
  
  fetch(aReload = false){
    this.fetchAndReloadWithLimit(aReload, this.limit);
  }
  
  fetchWithLimit(aLimit){
    this.fetchAndReloadWithLimit(false, aLimit);
  }
  
  reloadWithLimit(aLimit){
    this.fetchAndReloadWithLimit(true, aLimit);
  }
  
  fetchAndReloadWithLimit(aReload, aLimit){
    const {fail, success, ignoreSequentialLoadRequests = false} = this.props;
    let limit = this.limit;
    
    if(aLimit > 0){
      limit = aLimit;
    }
    if((this.isLoading() === false) && (this.hasLoadedAll() === false || aReload)){
      if(aReload){
        this.loadedAll = false;
        this.setList([]);
      }
      this.loading = true;
      this.props.getItems(this.getList().length, limit).then((newResult) => {
        this.loading = false;
        
        if(newResult){
          let objects = newResult.objects;
          this.list.push(...objects);
          
          if(objects){
            this.loadedAll = (newResult.objects.length < limit);
          }
          else{
            this.loadedAll = true;
          }
        }
        else{
          this.loadedAll = true;
        }
        if(success){
          success();
        }
        if(this.shouldLoadOnSuccess){
          this.shouldLoadOnSuccess = false;
          this.fetchAndReloadWithLimit(aReload, aLimit);
        }
      }, (newError) => {
        this.loading = false;
        
        if(fail){
          fail(errorMessageFromServerError(newError));
        }
      });
    }
    else if(!ignoreSequentialLoadRequests){
      this.shouldLoadOnSuccess = (this.hasLoadedAll() === false);
    }
  }
  
  insertItem(aItem, aIndex = null){
    if(aIndex >= 0 && aIndex < this.totalCount()){
      this.list.splice(aIndex, 0, aItem);
    }
    else{
      this.list.push(aItem);
    }
  }
  
  removeItemMatchingProperty(aItem, aProperty){
    if(this.list.length > 0){
      if(this.list[0][aProperty] !== undefined){
        for(let index = 0; index < this.list.length; index += 1){
          if(this.list[index][aProperty] === aItem[aProperty]){
            this.removeItemAtIndex(index);
            break;
          }
        }
      }
      else{
        console.log('InfiniteScrollManager (removeItemMatchingProperty): Property:', aProperty, 'does not exist on items in the list:', this.list[0]);
      }
    }
  }
  
  removeItem(aItem){
    let index = this.getIndexOfItem(aItem);
    
    if(index >= 0 && index < this.totalCount()){
      this.removeItemAtIndex(index);
    }
    else{
      console.log('InfiniteScrollManager (removeItem): Cannot remove item:', aItem, 'as it does not belong to the list!');
    }
  }
  
  removeItemAtIndex(aIndex){
    let returnValue = null;
    
    if(aIndex < this.totalCount()){
      let item = this.list.splice(aIndex, 1);
      returnValue = item[0];
    }
    else{
      console.log('InfiniteScrollManager (removeItemAtIndex): Cannot remove item at index:', aIndex, 'as the list only has', this.totalCount(), 'elements!');
    }
    return returnValue;
  }
  
  replaceItem(aItem){
    this.replaceItemMatchingProperty(aItem, 'id');
  }
  
  replaceItemAtIndex(aItem, aIndex){
    if(aIndex < this.totalCount()){
      this.list[aIndex] = aItem;
    }
    else{
      console.log('InfiniteScrollManager (replaceItemAtIndex): Cannot replace item at index:', aIndex, 'as the list only has', this.totalCount(), 'elements!');
    }
  }
  
  replaceItemMatchingProperty(aItem, aProperty){
    if(this.list.length > 0){
      if(this.list[0][aProperty] !== undefined){
        for(let index = 0; index < this.list.length; index += 1){
          if(this.list[index][aProperty] === aItem[aProperty]){
            this.replaceItemAtIndex(aItem, index);
            break;
          }
        }
      }
      else{
        console.log('InfiniteScrollManager (replaceItemMatchingProperty): Property:', aProperty, 'does not exist on items in the list:', this.list[0]);
      }
    }
  }
  
  getIndexOfItem(aItem){
    return this.list.findIndex((item) => item === aItem);
  }
  
  // Render
  
  render(){
    return null
  }
}
