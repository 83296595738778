import {toast} from "react-toastify";
import i18next from "i18next";
import EventTypes from "../constants/EventTypes";
import EventTracker from "../eventTracker";
import fileDownload from "js-file-download";
import {NOTIFY_OPTS} from "../constants/Notifiers";
import {dataURLtoResponseType, errorMessageFromServerError, isObject, jsonToCSV} from "./Helpers";

export const notifyDownloadMedicalRecordError = (aType, aError) =>  toast(i18next.t('MEDICAL_RECORD_DOWNLOAD_ERROR', {aType:aType, aError:aError}), NOTIFY_OPTS.autoClose);

export function downloadMedicalRecord(aMedicalRecord, aType, aAdmin, aPatient){
  if(aMedicalRecord){
    if(aType === 'pdf'){
      EventTracker.track(EventTypes.DOWNLOAD_MEDICAL_RECORD, {type:'pdf', adminId:(aAdmin ? aAdmin.id : null), patientId:(aPatient ? aPatient.id : null), medicalRecordID:aMedicalRecord.id});
      let attachment = medicalRecordAttachmentOfType(aMedicalRecord, aType);
      
      if(attachment && attachment.file_url && attachment.file_url.length > 0){
        dataURLtoResponseType(
          attachment.file_url,
          'blob',
          (newData) => fileDownload(newData, 'MedicalRecord-' + aMedicalRecord.report_name + '.pdf', 'pdf'),
          (newError) => notifyDownloadMedicalRecordError('PDF', errorMessageFromServerError(newError))
        );
      }
    }
    else if(isObject(aMedicalRecord.json_data)){
      if(aType === 'csv'){
        EventTracker.track(EventTypes.DOWNLOAD_MEDICAL_RECORD, {type:'csv', adminId:(aAdmin ? aAdmin.id : null), patientId:(aPatient ? aPatient.id : null), medicalRecordID:aMedicalRecord.id});
        fileDownload(jsonToCSV(aMedicalRecord.json_data), 'MedicalRecord-' + aMedicalRecord.report_name + '.csv', 'text/csv');
      }
      else if(aType === 'json'){
        EventTracker.track(EventTypes.DOWNLOAD_MEDICAL_RECORD, {type:'json', adminId:(aAdmin ? aAdmin.id : null), patientId:(aPatient ? aPatient.id : null), medicalRecordID:aMedicalRecord.id});
        fileDownload(JSON.stringify(aMedicalRecord.json_data), 'MedicalRecord-' + aMedicalRecord.report_name + '.json', 'application/json');
      }
    }
  }
}

export function medicalRecordAttachmentOfType(aMedicalRecord, aType){
  let returnValue = null;
  
  if(aMedicalRecord && aMedicalRecord.attachments && Array.isArray(aMedicalRecord.attachments)){
    for(let attachment of aMedicalRecord.attachments){
      if(attachment.file_type === aType){
        returnValue = attachment;
        break;
      }
    }
  }
  return returnValue;
}


export function medicalRecordContainsAttachmentOfType(aMedicalRecord, aType){
  return medicalRecordAttachmentOfType(aMedicalRecord, aType) !== null;
}
