import React from 'react';
import './LineChart.scss'
import BaseChart from "../BaseChart/BaseChart";
import ReactToolTip from "react-tooltip";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import {Translation} from "react-i18next";
import { TOOLTIP_TYPES } from 'constants/ChartConstants';
export default class LineChart extends BaseChart {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: ''
    };
  }

  generateChartData(entries, startDate, endDate, onClickListener){
    let minYValue = this.props.minYValue;
    let maxYValue = this.props.maxYValue;
    const hideMinYValue = this.props.hideMinYValue;
    let objectiveId = this.props.objectiveId;
    let dataPoints = [];
    let xAxisTickLabels = [];
    let currentDateIndex = 0;
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    let yAxisTicks = {
      suggestedMin: null
    };

    if(minYValue){
      yAxisTicks.suggestedMin = minYValue;
    }

    if(maxYValue){
      yAxisTicks.suggestedMax = maxYValue;
    }

    while(currentDate < endDate){
      let entriesOnCurrentDate = entries.filter((entry) => {
        let measuredAt = new Date(entry.response.measured_at);
        return (entry.value || entry.text) && measuredAt.getDate() === currentDate.getDate()
          && measuredAt.getMonth() === currentDate.getMonth()
          && measuredAt.getFullYear() === currentDate.getFullYear();
      });

      if(entriesOnCurrentDate.length > 0){
        let totalValue = entriesOnCurrentDate.reduce((result, entry) => result + parseInt(entry.value || entry.text), 0);
        let yValue;
        if(this.props.tooltipType === TOOLTIP_TYPES.TOTAL) {
          yValue = totalValue;
        } else {
          yValue = entriesOnCurrentDate.reduce((prev, entry) => {
            let value = parseInt(entry.value || entry.text);
            return value > prev ? value : prev;
          }, 0);
        }
          
        
        dataPoints.push({
          x: currentDateIndex + 0.5,
          y: yValue,
          numberOfEntries: entriesOnCurrentDate.length,
          responseIds: entriesOnCurrentDate.map(entry => entry.response.id),
          objectiveId: objectiveId,
          averageValue: totalValue / entriesOnCurrentDate.length,
          totalValue: totalValue
        });
      }
      else{
        dataPoints.push({
          x: currentDateIndex + 0.5,
          y: hideMinYValue ? "N/A": yAxisTicks.suggestedMin,
          numberOfEntries: 0
        });
      }
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      currentDateIndex++;
    }
    this.setState({ dataPoints: dataPoints});
    return {
      type: 'scatter',
      plugins: [{
        afterDraw: function(chartInstance) {
          let meta = chartInstance.getDatasetMeta(0);

          if(meta){
            let context = chartInstance.ctx;
            let xOffset = 0;
            if(meta.controller._xScale._labelItems.length > 1){
              xOffset = (meta.controller._xScale._labelItems[1].x - meta.controller._xScale._labelItems[0].x) * 0.55;
            }
            meta.controller._xScale._labelItems.forEach((labelItem, index) => {
              context.save();
              context.translate(labelItem.x + xOffset, labelItem.y);
              context.rotate(labelItem.rotation);
              context.textAlign = "right";
              context.font = labelItem.font.string;
              context.fillStyle = "#000000";
              context.fillText(labelItem.label, labelItem.textOffset, 0);
              context.restore();
            });
          }
        }
      }, ChartAnnotation],
      data: {
        labels: xAxisTickLabels,
        datasets: [{
          spanGaps: false,
          backgroundColor: 'rgba(45, 129, 201, 0.5)',
          borderColor: '#2d81c9',
          borderWidth: 2,
          fillOpacity: 0.5,
          data: dataPoints,
          showLine: true
        }]
      },
      options: {
        onClick: (event) => {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseIds[0],
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        tooltips: this.getTooltipForChart(dataPoints, xAxisTickLabels),
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: false,
              min: 0,
              max: currentDateIndex,
              stepSize: 1,
              fontColor: 'transparent',
              padding: 15,
              autoSkip: false,
              callback: function(value, index, values) {
                let returnValue = "";

                if(value < xAxisTickLabels.length && value >= 0){
                  returnValue = `${xAxisTickLabels[Math.floor(value)]}`;
                }
                return returnValue;
              }
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              ...yAxisTicks,
              padding: 10
            }
          }]
        }
      }
    }
  }

  getTooltipForChart(aDataPoints, aXAxisTickLabels) {
    const tooltipType = this.props.tooltipType || TOOLTIP_TYPES.AVERAGE;
    
    return {
      enabled: false,
      custom: (tooltipModel) => {
        const chart = this.chartRef.current;

        if (!chart) {
          return;
        }

        if (tooltipModel.opacity === 0) {
          this.setState({
            showTooltip: false
          });
          return;
        }

        if (!this.state.showTooltip) {
          let { numberOfEntries, y, averageValue, totalValue } = aDataPoints[tooltipModel.dataPoints[0].index];
          let text = '';
  
          if (numberOfEntries === 0) {
            text = 'No Entry';
          } else {
            if(tooltipType === TOOLTIP_TYPES.AVERAGE) {
              text = `Highest: ${y} \nAvg: ${averageValue.toFixed(3)} \nEntries: ${numberOfEntries}`;
            } else if (tooltipType === TOOLTIP_TYPES.TOTAL) {
              text = `${this.props.tooltipText}: ${totalValue} \nEntries: ${numberOfEntries}`;
            }
          }

          this.setState({
            showTooltip: true,
            text: text,
            date: aXAxisTickLabels[tooltipModel.dataPoints[0].index]
          });
        }
      }
    }
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.chart.update();
      }
    });
    const {objectiveId} = this.props;
    const {showTooltip, date, text} = this.state;
    const footer = text === 'No Entry' ? null : 'click to view details';

    return (
      <div>
        <canvas ref={this.chartRef}
                data-tip data-for={`${objectiveId}`}
        />
        {
          showTooltip &&
          <ReactToolTip id={`${objectiveId}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>{date}</div>
              <div className='tooltip-body flex-center'>
                { text }
              </div>
              <div className='tooltip-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
