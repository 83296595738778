import actions from 'actions';
import {connect} from 'react-redux';
import ResetPassword from '../Pages/ResetPassword/ResetPassword'

const mapStateToProps = (state) => {
  return {
    user:state.current && state.current.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword:({password, passwordConfirmation, resetPasswordToken}) => {
      dispatch(actions.resetPassword({password, passwordConfirmation, resetPasswordToken}));
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ResetPassword);
