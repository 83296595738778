import React, {useState} from 'react';
import moment from "moment";
import DatePicker from "react-datepicker";
import i18next from "i18next";
import '../../ToolPreview/ToolPreview.scss'


const DateObjective = ({ objective, selectedTranslationIndex }) => {
    const [date, setDate] = useState(null);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <label>
                {objective.translations[selectedTranslationIndex].description}
            </label>
            <div style={{alignItems: "center", width: '100%'}}>
                <DatePicker className="tool-preview-date-objective"
                            selected={date ? date.toDate() : moment().toDate()}
                            onChange={(aDate) => setDate(moment(aDate))}
                            placeholderText={i18next.t('CLICK_TO_SET_DATE')}
                            dateFormat={"yyyy/MM/dd"}
                />
            </div>

        </div>
    );
}

export default DateObjective
