import React from 'react';
import './EntryIndicatorChart.scss'
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import BaseChart from "../BaseChart/BaseChart";
import ReactToolTip from "react-tooltip";
import {uniq} from "lodash";
import {Translation} from "react-i18next";

export default class EntryIndicatorChart extends BaseChart {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: ''
    };
  }

  generateChartData(entries, startDate, endDate, onClickListener){
    let dataPoints = [];
    let objectiveId = this.props.objectiveId;
    let xAxisTickLabels = [];
    let currentDateIndex = 0;
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);

    while(currentDate < endDate){
      let entriesOnCurrentDate = entries.filter((entry) => {
        let measuredAt = new Date(entry.response.measured_at);
        return measuredAt.getDate() === currentDate.getDate()
          && measuredAt.getMonth() === currentDate.getMonth()
          && measuredAt.getFullYear() === currentDate.getFullYear();
      });

      if(entriesOnCurrentDate.length > 0){
        const responseIdsOnCurrentDate = uniq(entriesOnCurrentDate.map(entry => entry.response.id));
        dataPoints.push({
          x: currentDateIndex + 0.5,
          y: 0,
          numberOfEntries: responseIdsOnCurrentDate.length,
          responseIds: responseIdsOnCurrentDate,
          objectiveId: objectiveId
        });
      }
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      currentDateIndex++;
    }
    this.setState({ dataPoints: dataPoints})
    return {
      type: 'scatter',
      plugins: [{
        afterDraw: function(chartInstance) {
          let meta = chartInstance.getDatasetMeta(0);

          if(meta){
            let context = chartInstance.ctx;
            let xOffset = 0;

            if(meta.controller._xScale._labelItems.length > 1){
              xOffset = (meta.controller._xScale._labelItems[1].x - meta.controller._xScale._labelItems[0].x) * 0.55;
            }

            meta.controller._xScale._labelItems.forEach((labelItem, index) => {
              context.save();
              context.translate(labelItem.x + xOffset, labelItem.y);
              context.rotate(labelItem.rotation);
              context.textAlign = "right";
              context.font = labelItem.font.string;
              context.fillStyle = "#000000";
              context.fillText(labelItem.label, labelItem.textOffset, 0);
              context.restore();
            });
          }
        }
      }, ChartAnnotation],
      data: {
        datasets: [{
          backgroundColor: '#24895b',
          pointRadius: 7.5,
          pointHoverRadius: 7.5,
          data: dataPoints
        }]
      },
      options: {
        onClick: function(event) {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseIds[0],
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            const chart = this.chartRef.current;

            if (!chart) {
              return;
            }

            if (tooltipModel.opacity === 0) {
              this.setState({
                showTooltip: false
              });
              return;
            }

            if (!this.state.showTooltip) {
              let dataPoint = dataPoints[tooltipModel.dataPoints[0].index];
              let numberOfEntries = dataPoint.numberOfEntries;
              let text = '';

              if (numberOfEntries === 0) {
                text = 'No Entry';
              } else if(numberOfEntries === 1){
                text = `${numberOfEntries} Entry`;
              }
              else{
                text = `${numberOfEntries} Entries`;
              }
              this.setState({
                showTooltip: true,
                text: text,
                date: xAxisTickLabels[dataPoint.x - 0.5]
              });
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: true,
              tickMarkLength: 0
            },
            ticks: {
              beginAtZero: false,
              min: 0,
              max: currentDateIndex,
              stepSize: 1,
              fontColor: 'transparent',
              padding: 20,
              autoSkip: false,
              callback: function(value, index, values) {
                let returnValue = "";

                if(value < xAxisTickLabels.length && value >= 0){
                  returnValue = `${xAxisTickLabels[Math.floor(value)]}`;
                }
                return returnValue;
              }
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: false,
            gridLines: {
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              min: -1,
              max: 1,
            }
          }]
        }
      }
    }
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.resizePoints();
        this.chart.update();
      }
    });
    const {objectiveId} = this.props;
    const {showTooltip, date, text} = this.state;
    const footer = text === 'No Entry' ? null : 'click to view details';

    return (
      <div>
        <canvas ref={this.chartRef}
                data-tip data-for={`${objectiveId}`}
        />
        {
          showTooltip &&
          <ReactToolTip id={`${objectiveId}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>{date}</div>
              <div className='tooltip-body flex-center'>
                { text }
              </div>
              <div className='tooltip-footer no-padding-top-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
