import EventTypes from '../constants/EventTypes'
import EventTracker from '../eventTracker'

export function fetchMedicalRecords(aPatientId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.GET_MEDICAL_RECORDS);
  let query = `
    query getMedicalRecords ($company_id: ID!, $patient_id: ID!, $search_string: String, $offset: Int, $limit: Int) {
      medical_records (company_id: $company_id, patient_id: $patient_id, search_string: $search_string, offset: $offset, limit: $limit){
        medical_records {
          id
          json_data
          report_id
          created_at
          updated_at
          report_name
          attachments {
            id
            file_url
            file_type
            created_at
            updated_at
          }
          organization {
            name
            organization_id
          }
        }
        total
      }
    }
  `;
  
  let variables = {};
  
  return (dispatch, getState) => {
    const {company} = getState();
    variables['company_id'] = company && company.company && company.company.id;
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    variables['patient_id'] = aPatientId;
    
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    return global.api.post("/queries", { query, variables });
  };
}
