import React from 'react';
import {AlertTypes} from "../../../constants/AlertTypes";
import moment from 'moment';
import {Translation} from "react-i18next";
import i18next from "i18next";
import {DispositionCodes} from '../../../constants/DispositionCodes'
import GrayButton from "../../../Custom UI/Buttons/GrayButton/GrayButton";
import BlueButton from "../../../Custom UI/Buttons/BlueButton/BlueButton";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDatePicker from "../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import ReactDOM from 'react-dom';
import LabeledMultipleSelectDropDown from "../../../Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";
import "../PatientList.scss";

class PatientSearchForm extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      dropDownIsOpen:false,
      advancedSearchTerms:{}
    };
    if(props.searchTerms != null){
      Object.keys(props.searchTerms).forEach(key => {
        this.state.advancedSearchTerms[key] = props.searchTerms[key];
      })
    }
    if(props.user && props.user.search_preference != null){
      Object.keys(props.user.search_preference).forEach(key => {
        this.state.advancedSearchTerms[key] = props.user.search_preference[key];
      })
    }
    this.onClosePressed = this.onClosePressed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleAlertTypeChange = this.handleAlertTypeChange.bind(this);
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  // Methods

  handleClickOutside = (aEvent) => {
    const domNode = ReactDOM.findDOMNode(this);

    if(!domNode || !domNode.contains(aEvent.target)){
      if(!this.state.dropDownIsOpen){
        this.onClosePressed();
      }
    }
  };

  onClearPressed = () => {
    this.setState({advancedSearchTerms: {}});
    this.props.onClosePressed(true);
  };

  onClosePressed = () => {
    this.props.onClosePressed(false);
  };

  handleInputChange(event){
    let newSearchTerms = this.state.advancedSearchTerms;

    if(event.target.value != null && event.target.value.trim().length > 0){
      newSearchTerms['name'] = event.target.value;
    }
    else{
      delete newSearchTerms['name'];
    }
    this.setState({advancedSearchTerms:newSearchTerms});
  }

  handleSelectChange(value, key){
    if(value != null && key != null){
      let newSearchTerms = this.state.advancedSearchTerms;
      newSearchTerms[key] = value;
      this.setState({advancedSearchTerms:newSearchTerms});
    }
    else if(key != null){
      let newSearchTerms = this.state.advancedSearchTerms;
      delete newSearchTerms[key];
      this.setState({advancedSearchTerms:newSearchTerms});
    }
  }

  handleDateChange(date){
    if(date != null){
      let newSearchTerms = this.state.advancedSearchTerms;
      newSearchTerms.virtual_appointment_date = date.format("ddd MMM DD YYYY HH:mm:ss ZZ");
      this.setState({advancedSearchTerms:newSearchTerms});
    }
    else{
      let newSearchTerms = this.state.advancedSearchTerms;
      delete newSearchTerms.virtual_appointment_date;
      this.setState({advancedSearchTerms:newSearchTerms});
    }
  }

  submit = () => {
    this.props.user.search_preference = this.state.advancedSearchTerms;
    this.props.user.search_preference.query = this.state.advancedSearchTerms.name;
    this.props.onSubmitPressed(this.state.advancedSearchTerms);
    this.props.onClosePressed(false);
  };

  noFiltersApplied = () => {
    return Object.keys(this.state.advancedSearchTerms).length === 0
  };

  handleAlertTypeChange(aOptionsArray){
    let selectedOptions = '';

    for(let index = 0; index < aOptionsArray.length; index += 1){
      let option = aOptionsArray[index];
      selectedOptions += option.value.toString();

      if(index < (aOptionsArray.length - 1)){
        selectedOptions += ',';
      }
    }
    if(selectedOptions.length === 0){
      selectedOptions = null;
    }
    let newSearchTerms = this.state.advancedSearchTerms;
    newSearchTerms.alert_type = selectedOptions;
    this.setState({advancedSearchTerms:newSearchTerms});
  }

  // Render

  render(){
    let english = (i18next.t('ENGLISH_WITH_FLAG'));
    let italian = (i18next.t('ITALIAN_WITH_FLAG'));
    let french = (i18next.t('FRENCH_WITH_FLAG'));
    let german = (i18next.t('GERMAN_WITH_FLAG'));
    let spanish = (i18next.t('SPANISH_WITH_FLAG'));
    let availableLanguages = [{languageString: english, languageCode: 'en'},
      {languageString: italian, languageCode: 'it'},
      {languageString: french, languageCode: 'fr'},
      {languageString: german, languageCode: 'de'},
      {languageString: spanish, languageCode: 'es'}];

    let languageOptions = [];

    if(availableLanguages){
      availableLanguages.map((aLanguage, index) => (
        languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
      ));
    }
    let currentLanguage = this.state.advancedSearchTerms.preferred_language;

    let alertOptions = [];

    AlertTypes.forEach((alert) => {
      alertOptions.push({value:alert.value, label:alert.text});
    });

    let currentAlertTypes = this.state.advancedSearchTerms.alert_type;

    let selectedAlertOptionsArray = [];

    if(currentAlertTypes){
      for(let alertType of currentAlertTypes.toString().split(',')){
        let alertOption = alertOptions.filter(function(option){
          return option.value.toString() === alertType;
        });

        if(alertOption.length > 0){
          selectedAlertOptionsArray.push(alertOption[0]);
        }
      }
    }

    let searchByIdOptions = [
      {value: "user_id", label: <Translation>{ (t, { i18n }) => t('USER_ID') }</Translation>},
      {value: "edc_id", label: <Translation>{ (t, { i18n }) => t('EDC_ID') }</Translation>}
    ];

    let dispositionCodeOptions = [];

    DispositionCodes.forEach((code) => {
      dispositionCodeOptions.push({value:code.value, label:code.text});
    });

    let currentDispositionCode = this.state.advancedSearchTerms.disposition_code;
    let currentSearchByIdOption = this.state.advancedSearchTerms.search_by_id;
    
    return (
      <div className='patient-list-popover-search-container'>
        <LabeledDropDown className="patient-list-popover-search-input"
                         label={<Translation>{ (t, { i18n }) => t('USER_EDC_ID') }</Translation>}
                         value={searchByIdOptions.filter(function(option){
                          return option.value === currentSearchByIdOption;
                        })}
                         handleSave={(aOption) => this.handleSelectChange(aOption.value, 'search_by_id')}
                         options={searchByIdOptions}
                         onFocus={() => this.setState({dropDownIsOpen:true})}
                         onBlur={() => this.setState({dropDownIsOpen:false})}
        />
        <LabeledDropDown className="patient-list-popover-search-input"
                         label={<Translation>{ (t, { i18n }) => t('PREFERRED_LANGUAGE') }</Translation>}
                         value={currentLanguage && languageOptions.filter(function(option){
                           return option.value === currentLanguage;
                         })}
                         handleSave={(aOption) => this.handleSelectChange(aOption.value, 'preferred_language')}
                         options={languageOptions}
                         onFocus={() => this.setState({dropDownIsOpen:true})}
                         onBlur={() => this.setState({dropDownIsOpen:false})}
        />
        
        <LabeledMultipleSelectDropDown className="patient-list-popover-search-input"
                                       label={<Translation>{ (t, { i18n }) => t('ALERT_TYPE') }</Translation>}
                                       value={selectedAlertOptionsArray}
                                       onBlur={() => this.setState({dropDownIsOpen:false})}
                                       onFocus={() => this.setState({dropDownIsOpen:true})}
                                       options={alertOptions}
                                       handleSave={(aOptionsArray) => this.handleAlertTypeChange(aOptionsArray)}
                                       placeholder={<Translation>{ (t, { i18n }) => t('SELECT_ALERT_TYPE') }</Translation>}
        />

        <LabeledDatePicker className="patient-list-popover-search-input"
                           handleSave={date => this.handleDateChange(date)}
                           value={this.state.advancedSearchTerms && this.state.advancedSearchTerms.virtual_appointment_date &&
                           moment(this.state.advancedSearchTerms.virtual_appointment_date)}
                           label={<Translation>{ (t, { i18n }) => t('VIRTUAL_APPOINTMENT_DATE') }</Translation>}
        />

        <LabeledDropDown className="patient-list-popover-search-input"
                         label={<Translation>{ (t, { i18n }) => t('DISPOSITION_CODE') }</Translation>}
                         value={dispositionCodeOptions.filter(function(option){
                           return option.value === currentDispositionCode;
                         })}
                         handleSave={(aOption) => this.handleSelectChange(aOption.value, 'disposition_code')}
                         options={dispositionCodeOptions}
                         onFocus={() => this.setState({dropDownIsOpen:true})}
                         onBlur={() => this.setState({dropDownIsOpen:false})}
        />

        <div className='button-container-popover'>
          <GrayButton className="button-popover"
                      onClick={this.noFiltersApplied() ? this.onClosePressed : this.onClearPressed}
                      name={this.noFiltersApplied() ?
                        <Translation>{(t, {i18n}) => t('CANCEL')}</Translation>
                        :
                        <Translation>{(t, {i18n}) => t('CLEAR')}</Translation>
                      }
          />
          <BlueButton onClick={this.submit}
                      name={<Translation>{ (t, { i18n }) => t('APPLY') }</Translation>}
          />

        </div>
      </div>
    )
  }

}
export default PatientSearchForm
