import React from 'react';
import ToolInformation from './ToolInformation/ToolInformation'
import ToolBlocks from './ToolBlocks/ToolBlocks'
import { orderBy } from 'lodash'
import '../../../Modals/ToolModal/ToolModal.scss'
import LanguagePicker from "./LanguagePicker/LanguagePicker";
import './ToolLanguageDropdown/ToolLanguageDropdown.scss';
import { cloneDeep } from 'lodash';
import {Translation, withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../../../i18n";
import "../../../Modals/GeneralModal/GeneralModal.scss";
import "./CreateTool.scss"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BlueButton from "../../../Custom UI/Buttons/BlueButton/BlueButton";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";

const notifyToolCreated = () => toast(<Translation>{ (t, { i18n }) => t('SUCCESSFULLY_CREATED_TOOL') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyToolEditedSuccess = () => toast(<Translation>{ (t, { i18n }) => t('SUCCESSFULLY_EDITED_TOOL') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

class CreateTool extends React.Component {
  constructor(props) {
    super(props);

    const defaultTool = {
      title: "",
      description: "",
      icon_bg_color: '',
      icon: "",
      company_id: this.props.companyId,
      objectives: [{type: "",
        translations: [{locale: this.props.languages[0].locale}]}],
      translations: [{
        locale: this.props.languages[0].locale,
        title: "",
        description: "",
      }],
      selectedToolLanguage: null,
      languageToolAnchorElement:null,
    };
    let tool = this.props.tool || defaultTool;
    this.orderQuestTranslations(tool);
    this.state = {
      tool: tool,
      selectedTranslation: tool.translations[0],
      selectedTranslationIndex: 0,
      selectedLanguage: null,
      selectedToolId: null,
      removeTranslationIndex:null,
      toolConfirmationModalOpen:false,
      languageConfirmationModalOpen:false,
      disable:true
    };
    this.saveTool = this.saveTool.bind(this);
    this.updateTool = this.updateTool.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.selectTranslation = this.selectTranslation.bind(this);
    this.removeToolFromCompany = this.removeToolFromCompany.bind(this);
    this.updateToolTranslation = this.updateToolTranslation.bind(this);
  }

  updateTool(key, value) {
    if(this.props.changeGoToNextState){
      this.props.changeGoToNextState();
    }
    if(this.props.updateTool){
      this.props.updateTool(key, value);
    }
    this.setState({tool: Object.assign(this.state.tool, {[key]: value}), disable: false})
  }

  updateToolTranslation(key, value) {
    if(this.props.changeGoToNextState){
      this.props.changeGoToNextState();
    }
    if(this.props.updateToolTranslation) {
      this.props.updateToolTranslation(key, value);
    }
    this.setState({selectedTranslation: Object.assign(this.state.selectedTranslation, {[key]: value})})
  }

  saveTool() {
    this.setState({disable: true});
    const defaultTool = {
      title: "",
      description: "",
      icon_bg_color: '',
      icon: "",
      company_id: this.props.companyId,
      objectives: [{type: "",
        translations: [{locale: this.props.languages[0].locale}]}],
      translations: [{
        locale: this.props.languages[0].locale,
        title: "",
        description: "",
      }],
      selectedToolLanguage: null,
      languageToolAnchorElement:null,
    };

    if(this.props.tool){
      this.props.updateTool({tool: this.state.tool}).then(result => {
        if(this.props.handleCloseModal){
          this.props.handleCloseModal();
        }
        this.setState({tool: defaultTool, selectedTranslation: defaultTool.translations[0]})
        this.orderQuestTranslations(this.state.tool);
        notifyToolEditedSuccess();
      }, errorResponse => {
        this.handleErrorResponse(errorResponse);
      })
    }
    else{
      this.props.addToolToCompany({tool: this.state.tool}).then(result => {
        if(this.props.handleCloseModal){
          this.props.handleCloseModal();
        }
        this.setState({tool: defaultTool, selectedTranslation: defaultTool.translations[0]})
        this.orderQuestTranslations(this.state.tool);
        notifyToolCreated();
      }, errorResponse => {
        this.handleErrorResponse(errorResponse);
      })
    }
  }

  handleErrorResponse(errorResponse){
    const { t } = this.props;
    let alertMessage = t('TOOL_NOT_SAVED', {i18n});

    if(errorResponse.data && errorResponse.data.error){
      alertMessage += errorResponse.data.error;
    }
    alert(alertMessage);
  }

  removeToolFromCompany = (toolId) => {
    this.setState({toolConfirmationModalOpen:true});
    this.setState({selectedToolId:toolId});
  };

  selectTranslation(translation, index){
    this.setState({selectedTranslation: translation, selectedTranslationIndex: index})
  }

  removeSelectedLanguage(){
    let translationIndex = this.state.removeTranslationIndex;
    let tool = cloneDeep(this.state.tool);
    
      tool.translations.splice(translationIndex, 1);
    
      if(tool.objectives){
        for(let objectiveIndex = 0; objectiveIndex < tool.objectives.length; objectiveIndex++){
          let objective = tool.objectives[objectiveIndex];
          objective.translations.splice(translationIndex, 1);
        
          if(objective.answers){
            for(let answerIndex = 0; answerIndex < objective.answers.length; answerIndex++){
              let answer = objective.answers[answerIndex];
              answer.translations.splice(translationIndex, 1);
            }
          }
        }
      }
      this.setState({tool: tool, selectedTranslation: tool.translations[0], selectedTranslationIndex: 0});
  }
  
  toggleLanguage(language){
    if(language && (language.locale !== 'en')){
      let translationIndex = -1;
      let tool = cloneDeep(this.state.tool);

      for(let i = 0; i < tool.translations.length; i++){
        if(tool.translations[i].locale === language.locale){
          translationIndex = i;
          break;
        }
      }
      if(translationIndex >= 0){
        this.setState({selectedLanguage:language, removeTranslationIndex:translationIndex, languageConfirmationModalOpen:true});
      }
      else{
        tool.translations.push({locale:language.locale,
                                title:'',
                                description:''
                              });
        for(let objectiveIndex = 0; objectiveIndex < tool.objectives.length; objectiveIndex++){
          let objective = tool.objectives[objectiveIndex];
          objective.translations.push({locale: language.locale,
                                        description: '',
                                        minimum_value_string: '',
                                        maximum_value_string: ''});

          if(objective.answers){
            for(let answerIndex = 0; answerIndex < objective.answers.length; answerIndex++){
              let answer = objective.answers[answerIndex];
              answer.translations.push({locale: language.locale,
                                         answer_text: ''});
            }
          }
        }
        this.setState({tool: tool, selectedTranslation: tool.translations[tool.translations.length - 1], selectedTranslationIndex: tool.translations.length - 1});
      }
    }
  }

  orderQuestTranslations(quest){
    if(quest && quest.translations){
      let localesOrder = quest.translations.map(translation=>{
        return translation.locale;
      });
      let assignTranslation = (object)=>{
        if(object){
          let newTranslations = localesOrder.map(locale=>{
            return {locale: locale};
          });
          if(object.translations){
            newTranslations.forEach(newTranslation=>{
              let oldTranslation = object.translations.find(translation=>translation.locale === newTranslation.locale);
              if(oldTranslation){
                Object.assign(newTranslation, oldTranslation);
              }
            });
          }
          object.translations = newTranslations;
        }
      };
      if(quest.objectives){
        quest.objectives.forEach(objective=>{
          assignTranslation(objective);

          if(objective.answers){
            assignTranslation(objective.answers);
          }
        });
      }
    }
  }

  addNewToolBlock(){
    let newObjective = {
      type: "",
      translations: this.state.tool.objectives[0].translations
    };
    let newTool = cloneDeep(this.state.tool);
    newTool.objectives.push(newObjective);
    this.setState({tool: newTool});
  }

  render() {
    const {tool, selectedLanguage, selectedTranslation, selectedTranslationIndex, toolConfirmationModalOpen,
      languageConfirmationModalOpen} = this.state;
    const {languages, handleCloseModal, removeToolFromCompany} = this.props;
    
    const blocks = orderBy(tool.objectives, 'order', 'asc');
    
    return (
        <div>
          <div className="modal-cell-title">
              <div style={{marginBottom: 10}}>
                <Translation>{ (t, { i18n }) => t('TOOL_INFORMATION')}</Translation>
              </div>
          </div>

          <ToolInformation
            dispatch={this.props.dispatch}
            tool={tool}
            updateTool={this.updateTool}
            updateToolTranslation={this.updateToolTranslation}
            selectedTranslation={selectedTranslation}
          />

          <div className="modal-cell-title">
              <Translation>{ (t, { i18n }) => t('TOOL_LANGUAGE')}</Translation>
          </div>

          <div className="tool-language-picker-container">
            <LanguagePicker translations={tool.translations}
                            allTranslations={languages}
                            selectTranslation={(aTranslation, aIndex) => this.setState({selectedTranslation:aTranslation, selectedTranslationIndex:aIndex})}
                            selectedTranslation={selectedTranslation}
                            addOrRemoveTranslation={(aTranslation) => this.toggleLanguage(aTranslation)}/>
          </div>

          <div className="modal-cell-title">
            <div style={{flexDirection: 'column'}}>
              <div>
                <Translation>{ (t, { i18n }) => t('TOOL_BLOCKS')}</Translation>
              </div>
              <div className="tool-blocks-subtitle">
                <Translation>{ (t, { i18n }) => t('TOOL_BLOCKS')}</Translation><span> - {blocks.length}</span>
              </div>
            </div>
            <AddCircleOutlineIcon style={{height: '20px', width: '20px', color: '#2D81C9'}} onClick={() => this.addNewToolBlock()}/>
          </div>

          <div style={{marginLeft: '20px'}}>
            <ToolBlocks
              blocks={blocks}
              tool={tool}
              updateTool={this.updateTool}
              selectedTranslation={selectedTranslation}
              selectedTranslationIndex={selectedTranslationIndex}
            />
          </div>

          {!this.props.handleCloseModal ?
            <div className="create-tool-button-container">
              <BlueButton className="modal-button"
                          name={<Translation>{(t, {i18n}) => t('SAVE_PROPER_CAPITALIZED')}</Translation>}
                          onClick={this.saveTool}
                          disabled={this.state.disable}
              />
            </div>
            :
            null
          }

              {/*<div className="modal-subtitle-text">*/}
              {/*  <Translation>{ (t, { i18n }) => t('STEP_THREE')}</Translation>*/}
              {/*</div>*/}

          {/*<div className='tool-preview-container-edit-create' style={{width: '35%', backgroundColor: '#eee', borderLeft: '1px solid rgb(230, 230, 230)'}}>*/}
          {/*    <span className='tool-preview-container-edit-create-heading'>*/}
          {/*      <Translation>{ (t, { i18n }) => t('TOOL_PREVIEW') }</Translation>*/}
          {/*    </span>*/}
          {/*    <ToolPreview*/}
          {/*      blocks={blocks}*/}
          {/*      tool={tool}*/}
          {/*      selectedTranslation={selectedTranslation}*/}
          {/*      selectedTranslationIndex={selectedTranslationIndex}*/}
          {/*    />*/}
          {/*</div>*/}
  
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:i18next.t('TOOL', {i18n})})}
                             isOpen={toolConfirmationModalOpen}
                             reject={() => {this.setState({toolConfirmationModalOpen:false})}}
                             confirm={() => {removeToolFromCompany({toolId:this.state.selectedToolId}).then(result => {handleCloseModal()})}}
          />
          
          {/* TODO: Move to Language Picker?*/}
          <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedLanguage ? `${selectedLanguage.title} (${selectedLanguage.display_name})` : '')})}
                             isOpen={languageConfirmationModalOpen}
                             reject={() => this.setState({languageConfirmationModalOpen:false})}
                             confirm={() => this.removeSelectedLanguage()}
          />
        </div>
    )
  }
}

export default withTranslation()(CreateTool)
