import {connect} from "react-redux";
import actions from 'actions';
import AdminHistory from "../Pages/AdminHistory/AdminHistory";

const mapStateToProps = (state)=> {
  return {
    user: state.current && state.current.user,
    company: state.company && state.company.company
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminHistories:(aUserId, aUpToDate, aLimit, aOffset, aPatientIdOrEdcId) => {
      return dispatch(actions.getAdminHistories(aUserId, aUpToDate, aLimit, aOffset, aPatientIdOrEdcId));
    }
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(AdminHistory);