import React from 'react';
import './LabeledInfo.scss';
import Info from "../../Info/Info";
import {BiDotsVerticalRounded} from "react-icons/bi";

const LabeledInfo = ({id, label, className, infoBlurb, description, menuButtonClicked, bold = true,
                       required = false, popoverPlacement = 'top'}) => {
  
  // Render
  
  return (
    label ?
      <div className={'labeled-info-container ' + className}
           id={id}>
        <div className="labeled-info-title">
          <div className={bold ? 'labeled-info-title-bold' : ''}>
            {label} {required ? ' *' : ''}
          </div>
          
          {infoBlurb ?
            <Info message={infoBlurb}
                  popoverPlacement={popoverPlacement}
            />
            :
            null
          }
          
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize:'21px', marginLeft:'auto'}}
                                   onClick={(aEvent) => {
                                     aEvent.preventDefault();
                                     aEvent.stopPropagation();
                                     menuButtonClicked(aEvent)
                                   }}
            />
            :
            null
            
          }
        </div>
        
        {description ?
          <div className="labeled-info-description">
            {description}
          </div>
          :
          null
        }
      </div>
      :
      null
  )
};
export default LabeledInfo;
