import actions from 'actions';
import { connect } from 'react-redux';
import ManageArticles from "../Pages/ManageArticles/ManageArticles";

const mapStateToProps = (state, ownProps)=> {
  return {
    path: ownProps.match.path,
    user: state.current && state.current.user,
    company: state.company && state.company.company
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getArticles:(aLimit, aOffset, aCompleted, aSearchString, aSelectedArticleTopicIdsArray) => {
      return dispatch(actions.getArticles(aLimit, aOffset, aCompleted, aSearchString, aSelectedArticleTopicIdsArray))
    },
    viewArticle:(aArticleId, aUserId) => {
      return dispatch(actions.viewArticle(aArticleId, aUserId))
    },
    getArticleTopics:(aUserId, aLimit, aOffset) => {
      return dispatch(actions.getArticleTopics(aUserId, aLimit, aOffset))
    },
    getRelatedArticles:(aArticleId) => {
      return dispatch(actions.getRelatedArticles(aArticleId))
    },
    getNewArticlesCount:(aUserId) => {
      return dispatch(actions.getNewArticlesCount(aUserId))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageArticles)
