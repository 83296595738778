import React, {useState, useCallback, useEffect} from 'react';
import './TabBar.scss'
import {Translation} from "react-i18next";

const TabBar = ({tabBarTabsArray, initialSelectedTabKey, selectedTabKeyChanged, className = '', headingTitle = null}) => {
  
  // Instance Variables
  const [selectedTabKey, setSelectedTabKey] = useState(initialSelectedTabKey);
  
  // Methods
  
  const handleSelectedTabChanged = useCallback((aKey) => {
    setSelectedTabKey(aKey);
    selectedTabKeyChanged(aKey);
  }, [selectedTabKeyChanged]);

  useEffect(() => {
    setSelectedTabKey(initialSelectedTabKey);
  }, [initialSelectedTabKey])
  
  // Render
  
  return (
    <div className={className + " tab-controller-background"}>
      <div className="tab-controller-top-padding"/>
      
      {headingTitle ?
        <div className="tab-header">
          {headingTitle}
        </div>
        :
        null
      }
      
      <div className={"tab-controller " +  (headingTitle ? "tab-controller-header" : "")}>
        {tabBarTabsArray.map((aTab, index) => (
          <div className={"tab-container " + ((aTab.key === selectedTabKey) ? "tab-container-selected" : "")}
               key={'tab-bar-' + aTab.key + '-' + index}
               onClick={() => handleSelectedTabChanged(aTab.key)}>
            
            <div className="tab-cover-left tab-cover-left-safari-only"/>
            
            <div className="tab-cover-right tab-cover-right-safari-only"/>
            
            <div className="tab-title">
              <Translation>{ (t, { i18n }) => t(aTab.key) }</Translation>
            </div>
            
            {aTab.badge && aTab.badge > 0 ?
              <div className="tab-container-alert">
                {aTab.badge}
              </div>
              :
              aTab.badge === -1 ?
                <div className="tab-container-alert">
                  !
                </div>
                :
                null
            }
          </div>
        ))}
      </div>
    </div>
  )
};

export default TabBar
