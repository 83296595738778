import React from 'react';
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './ManageArticles.scss';
import ArticlesList from "../../Lists/ArticlesList/ArticlesList";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from "../../Custom UI/TabBar/TabBar";

export default class ManageArticles extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
      tabBarKey:'FEED'
    }
  }

  // Methods



  // Render

  render() {
    const {user, company, getArticles, viewArticle, getArticleTopics, getRelatedArticles} = this.props;
    const {tabBarKey} = this.state;

    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('TITLE') }</Translation>}/>
        
        <div className="main-panel-inner-container-secondary">
          <TabBar tabBarTabsArray={[{key:'FEED'}, {key:'COMPLETED_FEED'}]}
                  initialSelectedTabKey={'FEED'}
                  selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
          />
          
          {tabBarKey === 'FEED' ?
            <div className="manage-articles-container">
              <ArticlesList user={user}
                            height={400}
                            company={company}
                            completed={false}
                            getArticles={getArticles}
                            viewArticle={viewArticle}
                            getArticleTopics={getArticleTopics}
                            getRelatedArticles={getRelatedArticles}/>
            </div>
            :
            null
          }

          {tabBarKey === 'COMPLETED_FEED' ?
            <div className="manage-articles-container">
              <ArticlesList user={user}
                            height={400}
                            company={company}
                            completed={true}
                            getArticles={getArticles}
                            viewArticle={viewArticle}
                            getArticleTopics={getArticleTopics}
                            getRelatedArticles={getRelatedArticles}/>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}
