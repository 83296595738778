import React, {useEffect, useState, useRef} from 'react';
import "antd/dist/antd.css";
import '../../Pages/ManageReports/ManageReports.scss';
import {Translation} from "react-i18next";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import {findIndex, uniq} from "lodash";
import i18n from "../../i18n";
import './NestedSurveyReportsList.scss'
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import InfiniteScroll from "react-infinite-scroll-component";
import cloneDeep from "lodash/cloneDeep";

const NestedSurveyReportsList = props => {
  
  // Instance Variables
  
  const {company, locales, fetchSurveyVersionCollections} = props;
  
  const [surveyIds, setSurveyIds] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [availableLanguages, setAvailableLanguages] = useState({});
  const [languageListVisible, setLanguageListVisible] = useState(false);
  const [checkboxCheckedState, setCheckboxCheckedState] = useState({});
  const [includeTestPatients, setIncludeTestPatients] = useState(false);
  const [surveyVersionCollectionsArray, setSurveyVersionCollectionsArray] = useState([]);
  const [totalSurveyVersionCollections, setTotalSurveyVersionCollections] = useState(0);
  
  const scrollManagerRef = useRef(null);
  const {current:scrollManager} = scrollManagerRef;
  
  // Methods
  
  const notifyReportGenerationSuccess = (aReport) => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:aReport}) }</Translation>, NOTIFY_OPTS.autoClose);
  const notifyReportFailed = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_FAILED_TOAST') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);
  
  const updateList = () => {
    let manager = scrollManager;
  
    if(manager === null || manager === undefined){
      manager = scrollManagerRef.current;
    }
    setSurveyVersionCollectionsArray(cloneDeep(manager.getList()));
  };
  
  function getSurveyVersionCollections(limit, offset){
    return fetchSurveyVersionCollections(limit, offset, null, false, false, null).then((newResponse) => {
      let data = newResponse.data.data.survey_version_collections;
      let surveyVersionCollectionsArray = data.survey_version_collections;
      let total = data.total;
      setTotalSurveyVersionCollections(total);
      return {objects:surveyVersionCollectionsArray, total:total};
    }, (newError) => {
    
    });
  }
  
  useEffect(()=> {
    if(locales){
      let langOptions = {};
      
      locales.forEach(function(locale){
        langOptions[locale.locale] = locale.title
      });
      setAvailableLanguages(langOptions)
    }
  }, [locales]);
  
  function downloadSurvey(){
    if(!showLoading){
      setShowLoading(true);
      let name = 'Nested Survey Report';
      
      if(surveyIds && surveyIds.length > 0){
        if(surveyIds.length === 1){
          name = surveyIds[0].text + ' Report';
        }
        else{
          name = 'Multiple Nested Surveys Report';
        }
      }
      const params = {
        name:name,
        language:selectedLanguage,
        company_id:company.id,
        template_name:'nested_survey_report',
        survey_version_collection_ids:[...surveyIds].map((surveyVersionCollection) => surveyVersionCollection.id),
        filter_values:{
          test_account:includeTestPatients ? [false, null, true] : [false, null]
        }
      };
      
      global.api.post('/api/reports/survey_version_collection_reports', params)
      .then((newResponse) => {
        notifyReportGenerationSuccess(i18n.t('REPORTS'));
        setShowLoading(false);
      })
      .catch((newError) => {
        notifyReportFailed();
        setShowLoading(false);
      });
    }
  }
  
  const modifyCheck = (flag, survey_id) => {
    let obj = { ...checkboxCheckedState };
    delete obj["0"];
    obj[survey_id] = flag;
    
    if(Object.values(obj).length === surveyVersionCollectionsArray.length){
      if(uniq(Object.values(obj)).length === 1){
        obj["0"] = uniq(Object.values(obj))[0]
      }
      else{
        obj["0"] = true
      }
    }
    setCheckboxCheckedState({ ...obj });
  };
  
  const checkUncheckAll = (flag) => {
    let obj = { "0": flag };
    let surveyObjCollection = [];
    surveyVersionCollectionsArray.forEach((surveyVersionCollection) => {
      obj[surveyVersionCollection.id] = flag;
      
      if(flag){
        let surveyObj = {id:surveyVersionCollection.id, text:surveyVersionCollection.name};
        surveyObjCollection.push({ ...surveyObj })
      }
    });
    setCheckboxCheckedState({ ...obj });
    return surveyObjCollection;
  };
  
  const collectSurveyIds = (aEvent, aName, aId) => {
    setLanguageListVisible(false);
    let ids = [...surveyIds];
    let obj = {id:aId, text:aName};
    
    if(parseInt(obj.id) === 0){
      ids = checkUncheckAll(aEvent.target.checked)
    }
    else{
      if(aEvent.target.checked){
        ids.push(obj);
      }
      else{
        const index = findIndex(ids, obj);
        
        if(index !== -1){
          ids.splice(index, 1);
        }
      }
      modifyCheck(aEvent.target.checked, obj.id)
    }
    setSurveyIds(ids)
  };
  
  // Render
  
  return(
    <>
      <InfiniteScrollManager ref={scrollManagerRef}
                             fail={(aError) => console.error("NestedSurveyReportsList (InfiniteScrollManager): Failed with error:", aError)}
                             limit={20}
                             success={(aList) => updateList(aList)}
                             getItems={(offset, limit) => getSurveyVersionCollections(limit, offset)}
                             fetchImmediately={true}
      />
      
      <div className="manage-questionnaire-reports-download-container">
        <div className="manage-reports-cells-container">
          <div className="manage-reports-survey-cell-download">
            <div className="download-questionnaire-reports-container">
              <div className="group-user-title-text margin-left-0">
                <div className="download-questionnaire-reports-title">
                  <Translation>{(t, {i18n}) => t('NESTED_SURVEYS')}</Translation>  - {totalSurveyVersionCollections}
                </div>
                
                <label className={'custom-input-cell'}
                       key={`select-all-cell`}>
                  <input type={"checkbox"}
                         key={`select-all-input`}
                         name={`select-all^&@0`}
                         checked={checkboxCheckedState["0"]}
                         onChange={(aEvent) => collectSurveyIds(aEvent, 'select-all', 0)}
                  />
                  
                  <span className={'custom-checkmark'}
                        key={`select-all-checkmark`}/>
                  
                  <div className={'inline-text'}
                       key={`select-all-name`}>
                    {checkboxCheckedState["0"] ?
                      <Translation>{(t, {i18n}) => t('UNSELECT_ALL')}</Translation>
                      :
                      <Translation>{(t, {i18n}) => t('SELECT_ALL')}</Translation>}
                  </div>
                </label>
              </div>
              
              {scrollManager ?
                <div className="download-questionnaire-reports-outer-scroll-container"
                     id="download-questionnaire-reports-survey-version-collection-list">
                  <InfiniteScroll style={{overflowY: "hidden"}}
                                  next={() => scrollManager.fetch(false)}
                                  loader={<Loading loading={scrollManager.isLoading()}/>}
                                  hasMore={!scrollManager.hasLoadedAll()}
                                  dataLength={scrollManager.totalCount()}
                                  scrollableTarget="download-questionnaire-reports-survey-version-collection-list">
                    {surveyVersionCollectionsArray && surveyVersionCollectionsArray.length > 0 ?
                      <div className="download-questionnaire-reports-cell-container"
                           style={{height: '100%'}}>
                        {surveyVersionCollectionsArray.map((aSurveyVersionCollection, aIndex) => (
                          <div className="manage-reports-download-cell"
                               key={`${aSurveyVersionCollection.id}-container`}>
                            <label className={'custom-input-cell'}
                                   key={`${aSurveyVersionCollection.id}-cell`}>
                              <input type={"checkbox"}
                                     key={`${aSurveyVersionCollection.id}-input`}
                                     name={`${aSurveyVersionCollection.name}^&@${aSurveyVersionCollection.id}`}
                                     checked={checkboxCheckedState[`${aSurveyVersionCollection.id}`]}
                                     onChange={(aEvent) => collectSurveyIds(aEvent, aSurveyVersionCollection.name, aSurveyVersionCollection.id)}
                              />
                  
                              <span className={`custom-checkmark ${checkboxCheckedState[aSurveyVersionCollection.id] ? 'is-checked' : ''}`}
                                    key={`${aSurveyVersionCollection.id}-checkmark`}
                              />
                  
                              <div className={'inline-text'}
                                   key={`${aSurveyVersionCollection.id}-name`}>
                                {aSurveyVersionCollection.name}
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                      :
                      null
                    }
                  </InfiniteScroll>
                </div>
                :
                null
              }
            </div>
            
            <div className="manage-reports-buttons-container">
              <label className={'custom-input-cell'}>
                <input className="patient-user-input"
                       type='checkbox'
                       value={includeTestPatients}
                       checked={includeTestPatients}
                       onChange={() => setIncludeTestPatients(!includeTestPatients)}
                />
                
                <span className={'custom-checkmark'}/>
                
                <div className={'inline-text'}>
                  <Translation>{(t, {i18n}) => t('INCLUDE_TEST_PATIENT')}</Translation>
                </div>
              </label>
              
              {(surveyIds.length > 0) ?
                (showLoading ?
                    <Loading className="download-questionnaire-reports-loading-spinner"/>
                    :
                    <>
                        <BlueButton className="nested-survey-report-button"
                                    name={availableLanguages[selectedLanguage]}
                                    onClick={() => {
                                      setLanguageListVisible(!languageListVisible);
                                    }}
                                    showDropDownIcon={true}
                        />
                        
                        {languageListVisible ?
                          <ul className="report-dropdown-list">
                            {availableLanguages ?
                              Object.keys(availableLanguages).map((key) => (
                                <li key={key}
                                    value={key}
                                    onClick={() => {
                                      setLanguageListVisible(!languageListVisible);
                                      setSelectedLanguage(key)
                                    }}>
                                  {availableLanguages[key]}
                                </li>
                              ))
                              :
                              null
                            }
                          </ul>
                          :
                          null
                        }
                      
                        <BlueButton className="nested-survey-report-button"
                                    name={<Translation>{ (t, { i18n }) => t('DOWNLOAD') }</Translation>}
                                    onClick={() => {
                                      setLanguageListVisible(false);
                                      downloadSurvey();
                                    }}
                        />
                    </>
                )
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};
export default NestedSurveyReportsList
