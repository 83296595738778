import React from 'react';
import  './VirtualAppointmentDateCell.scss';
import 'antd/dist/antd.css';
import {Translation} from "react-i18next";
import {BiDotsVerticalRounded} from "react-icons/bi";
import moment from "moment";
import i18next from "i18next";
import {withRouter} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {momentFromDate, namedDayDateFormat} from "../../Helpers/DateHelpers";

class VirtualAppointmentDateCell extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
    this.formatUserName = this.formatUserName.bind(this);
  }
  
  // Methods
  
  formatUserName(aUser){
    let returnValue = '';
    
    if(aUser){
      if(aUser.last_name != null){
        returnValue =`${aUser.first_name} ${aUser.last_name}`;
      }
      else{
        returnValue = aUser.first_name;
      }
    }
    return returnValue;
  }
  
  handleCellClick = () => {
    let patientLink = '#';
    
    if(this.props.virtualAppointment && this.props.virtualAppointment.primary_participant && this.props.virtualAppointment.primary_participant.user){
      let patientId = this.props.virtualAppointment.primary_participant.user.id;
      
      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + patientId;
      }
      else{
        patientLink = '/patients/' + patientId;
      }
      //patientLink += '?section=appointments&id=' + this.props.virtualAppointment.id;
      patientLink += '?section=appointments';
      this.props.history.push(patientLink);
    }
  };
  
  // Render
  
  render() {
    const {role, virtualAppointment, showDate, menuButtonClicked, redirectToPatientProfile} = this.props;
  
    let day = '';
    let notes = '';
    let title = '';
    let timeRange = '';
    let namesArray = [];
    
    if(virtualAppointment){
      let today = moment();
      let startDate = momentFromDate(virtualAppointment.start_at);
      let endDate = momentFromDate(virtualAppointment.end_at);

      day = startDate.format(namedDayDateFormat);

      if(today.format(namedDayDateFormat) === day){
        day = (i18next.t('TODAY'));
      }
      if((startDate < today) && (today < endDate)){
        timeRange = (i18next.t('NOW'));
      }
      else{
        timeRange += startDate.format('h:mm');

        if(startDate.format('a') !== endDate.format('a')){
          timeRange += startDate.format(' a')
        }
        timeRange += ' - ';
        timeRange += endDate.format('h:mm a');
      }
      notes = virtualAppointment.notes;
      title = virtualAppointment.title;
      
      if(virtualAppointment.primary_participant && virtualAppointment.primary_participant.user){
        namesArray.push(this.formatUserName(virtualAppointment.primary_participant.user));
      }
      if(virtualAppointment.secondary_participants){
        for(let participant of virtualAppointment.secondary_participants){
          namesArray.push(this.formatUserName(participant.user));
        }
      }
    }
    
    return (
      <div className="virtual-date-cell" onClick={redirectToPatientProfile ? this.handleCellClick : null}>
        {showDate ?
          <div className="virtual-date-title">
            {day}
          </div>
          :
          null
        }
        
        <div className="virtual-appointment-info-cell">
          <div className="virtual-appointment-info-time">
            {timeRange}
          </div>
          
          <div className="virtual-appointment-info">
            <div className="virtual-appointment-title">
              {title}
            </div>
            
            {role === 'admin' && virtualAppointment.twilio_room_sid ?
              <>
                <div className="virtual-appointment-sid"
                     data-tip
                     data-for="registerTip"
                     data-event={'click'}
                     data-event-off={'mouseleave'}
                     onClick={(aEvent) => {aEvent.stopPropagation(); navigator.clipboard.writeText(virtualAppointment.twilio_room_sid)}}>
                  {virtualAppointment.twilio_room_sid}
                </div>
                <ReactTooltip id="registerTip"
                              place="top"
                              effect="solid"
                              isCapture="false"
                              delayShow={200}
                              delayHide={1500}>
                  <Translation>{ (t, { i18n }) => t('COPIED_TO_CLIPBOARD') }</Translation>
                </ReactTooltip>
              </>
              :
              null
            }
            
            <div className="virtual-appointment-participants">
              {namesArray && namesArray.length > 0 ?
                namesArray.map((name, index) => (
                  <div className="virtual-appointment-participant-cell"
                       key={'participantCell' + name + index}>
                    <div className="virtual-appointment-participant-cell-circle"/>
                    
                    <div className="virtual-appointment-participant-cell-name">
                      {name}
                    </div>
                  </div>
                ))
                :
                null
              }
              
            </div>
            
            <div className="virtual-appointment-notes">
              {notes && notes.length > 0 ?
                notes
                :
                <Translation>{ (t, { i18n }) => t('BLANK_NOTES') }</Translation>
              }
            </div>
          </div>
          
          <div>
            <BiDotsVerticalRounded className="virtual-appointment-dots-button"
                                 fill="#A8A8A8"
                                 style={{fontSize: '21px'}}
                                 width="16px"
                                 height="16px"
                                 onClick={(aEvent) => {aEvent.stopPropagation(); menuButtonClicked(aEvent)}}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(VirtualAppointmentDateCell)
