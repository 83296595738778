import {Translation} from "react-i18next";
import React from "react";
import i18next from "i18next";

export const PaymentStatusKeys = {
  failed:'FAILED',
  pending:'PENDING',
  approved:'PAID',
  rejected:'REJECTED',
  paid_outside_system:'PAID_OUTSIDE_SYSTEM'
};

export const PaymentStatusTranslations = {
  failed:<Translation>{ (t, { i18n }) => t(PaymentStatusKeys.failed) }</Translation>,
  pending:<Translation>{ (t, { i18n }) => t(PaymentStatusKeys.pending) }</Translation>,
  approved:<Translation>{ (t, { i18n }) => t(PaymentStatusKeys.approved) }</Translation>,
  rejected:<Translation>{ (t, { i18n }) => t(PaymentStatusKeys.rejected) }</Translation>,
  paid_outside_system:<Translation>{ (t, { i18n }) => t(PaymentStatusKeys.paid_outside_system) }</Translation>
};

export const paymentStatusOptionsArray = [
  {label:PaymentStatusTranslations.failed, value:'failed'},
  {label:PaymentStatusTranslations.pending, value:'pending'},
  {label:PaymentStatusTranslations.approved, value:'approved'},
  {label:PaymentStatusTranslations.rejected, value:'rejected'},
  {label:PaymentStatusTranslations.paid_outside_system, value:'paid_outside_system'}
];

export const statusForPaymentIsValid = (aPayment) => {
  let returnValue = false;
  
  if(aPayment){
    returnValue = ['failed', 'pending', 'approved', 'rejected', 'paid_outside_system'].includes(aPayment.status);
  }
  return returnValue;
};

export const statusForPayment = (aPayment) => {
  let returnValue = i18next.t(PaymentStatusKeys.pending);
  
  if(statusForPaymentIsValid(aPayment)){
    if(aPayment.status === 'approved'){
      if(aPayment.has_successful_hyperwallet_status){
        returnValue = i18next.t(PaymentStatusKeys.approved);
      }
      else{
        returnValue = i18next.t(PaymentStatusKeys.failed);
      }
    }
    else if(aPayment.status === 'rejected'){
      returnValue = i18next.t(PaymentStatusKeys.rejected);
    }
    else if(aPayment.status === 'paid_outside_system'){
      returnValue = i18next.t(PaymentStatusKeys.paid_outside_system);
    }
  }
  return returnValue;
};
