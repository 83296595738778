import React from 'react';
import './DynamicListCell.scss'
import moment from 'moment'
import i18next from "i18next";
import Loading from "../../Custom UI/Loading/Loading";
import ErrorIcon from '@material-ui/icons/Error';
import {CheckCircle} from "@material-ui/icons";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {momentFromDate} from "../../Helpers/DateHelpers";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {setHours, setMinutes} from "date-fns";
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class DynamicListCell extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      reloadingCell:{}
    };
    this.reload = this.reload.bind(this);
    this.flexForWidth = this.flexForWidth.bind(this);
  }
  
  // Methods
  
  reload(aObject, aRow){
    const {reload, reloadDelay = 60000} = this.props;
    const {reloadingCell} = this.state;
    
    if(reload && !reloadingCell[aRow]){
      reloadingCell[aRow] = true;
      this.setState({reloadingCell:reloadingCell});
      setTimeout(() => {
        reloadingCell[aRow] = false;
        this.setState({reloadingCell:reloadingCell});
        reload(aObject, aRow);
      }, reloadDelay);
    }
    return true;
  }
  
  flexForWidth(aColumnWidth){
    return '0 0 ' + aColumnWidth.toString() + 'px';
  }
  
  // Render
  
  render() {
    const {row, width, object, section, isLoading, selectCell, columnsArray, isSelectedRow, selectedColumn,
      clampFirstColumn = true, menuButtonClicked, valueForProperties, showMenu = true, showBorder = false, borderWidth = 0.5} = this.props;
    // TODO: Sarah, check class names.
    return (
      <div className={'dynamic-list-cell ' + (isSelectedRow ? 'dynamic-list-cell-selected-row' : '')}
           style={{width:width + 'px'}}
           onClick={selectCell ? () => selectCell() : null}>
        {columnsArray.map((column, index) => (
          <div className={'dynamic-list-cell-text ' + (clampFirstColumn ? 'dynamic-list-cell-clamped ' : '') + (selectedColumn === index ? 'dynamic-list-cell-selected ' : '') + (showBorder ? 'dynamic-list-cell-border ': '')}
               id={'dynamic-list-cell-' + row + '-' + index}
               key={'dynamic-cell-column-key-' + (column.propertiesArray && column.propertiesArray.length > 0 ? valueForProperties(column.propertiesArray) : '') + '-' + index}
               style={{width:column.width, flex:this.flexForWidth(column.width), borderLeftWidth:index === 0 ? borderWidth : 0, borderRightWidth:clampFirstColumn && index === 0 ? 2 * borderWidth : borderWidth, borderBottomWidth:borderWidth}}
               onClick={column.select ? () => column.select(object, row) : null}>
            {column.handleSave && (!column.disabled || !column.disabled(object, row)) ?
              column.type === 'switch' ?
                <LabeledSwitch checked={valueForProperties(column.propertiesArray) === 'true' || valueForProperties(column.propertiesArray) === true}
                               onChange={(aChecked) => column.handleSave(aChecked, object, row)}
                               defaultChecked={false}
                />
                :
                column.optionsArray && column.optionsArray(object, row) ?
                  <LabeledDropDown className="dynamic-list-cell-labeled-dropdown"
                                   value={column.optionsArray(object, row).filter(function(option){
                                     return option.value === valueForProperties(column.propertiesArray);
                                   })}
                                   options={column.optionsArray(object, row)}
                                   handleSave={(aOption) => column.handleSave(aOption.value, object, row)}
                                   isClearable={false}
                                   placeholder={column.placeholder}
                  />
                  :
                  column.dateFormat && column.dateFormat.length > 0 ?
                    <LabeledDatePicker className="dynamic-list-cell-labeled-datepicker"
                                       label={i18next.t('START_DATE_PROPER_CAPITALIZED')}
                                       value={valueForProperties(column.propertiesArray) ? valueForProperties(column.propertiesArray) : false}
                                       maxDate={column.maxDate ? moment(column.maxDate).toDate() : null}
                                       maxTime={column.maxTime ? moment(column.maxTime).toDate() : setHours(setMinutes(new Date(), 59), 23)}
                                       minDate={column.minDate ? moment(column.minDate).toDate() : null}
                                       minTime={column.minTime ? moment(column.minTime).toDate() : setHours(setMinutes(new Date(), 0), 0)}
                                       dateFormat={column.dateFormat}
                                       handleSave={(aDate) => column.handleSave(aDate, object, row)}
                                       isClearable={true}
                                       timeCaption={i18next.t('TIME_PROPER_CAPITALIZED')}
                                       showTimeSelect={column.dateFormat.includes('HH')}
                                       placeholderText={i18next.t('SELECT_DATE')}
                                       popperPlacement={'bottom-start'}
                                       showPopperArrow={true}
                                       showYearDropdown={true}
                                       showMonthDropdown={true}
                    />
                    :
                    <LabeledDelayedInput value={valueForProperties(column.propertiesArray)}
                                         autoFocus="autoFocus"
                                         minLength={0}
                                         handleSave={(aEvent) => {
                                           aEvent.preventDefault();
                                           aEvent.stopPropagation();
                                           column.handleSave(aEvent, object, row);
                                         }}
                                         placeholder={column.placeholder}
                    />
              :
              column.dateFormat && column.dateFormat.length > 0 ?
                momentFromDate(valueForProperties(column.propertiesArray), column.dateFormat)
                :
                column.templateCell ?
                  <div className="dynamic-list-cell-template">
                    {column.templateCell(object, row, section)}
                  </div>
                  :
                  column.valueFormatter ?
                    column.valueFormatter(valueForProperties(column.propertiesArray), object, row, section)
                    :
                    valueForProperties(column.propertiesArray) === true ?
                      <CheckCircle style={{color:'green', height:'15px', marginTop:'4px'}}/>
                      :
                      <div className="dynamic-list-cell-properties-array">
                        {valueForProperties(column.propertiesArray) === false ?
                          <ErrorIcon style={{color:'#E02020', height:'15px', marginTop:'4px'}}/>
                          :
                          valueForProperties(column.propertiesArray)
                        }
                      </div>
            }
          </div>
        ))}
        
        {menuButtonClicked || !showMenu ?
          <div className={'dynamic-list-cell-loading-container ' + (showBorder ? 'dynamic-list-cell-border ': '')}
               style={{borderBottomWidth:borderWidth, borderRightWidth:borderWidth}}>
            {showMenu ?
              (isLoading && isLoading(object, row) && this.reload(object, row) ?
                  <Loading size={'tiny'}
                           showLabel={false}
                  />
                  :
                  <BiDotsVerticalRounded style={{cursor:'pointer', fontSize:'21px', height:'20px', width:(20 + borderWidth).toString() + 'px', flex:'0 0 20px', marginLeft:5 - borderWidth, marginRight:5 - borderWidth}}
                                         onClick={(aEvent) => {
                                           aEvent.preventDefault();
                                           aEvent.stopPropagation();
                                           menuButtonClicked(aEvent);
                                         }}
                  />
              )
              :
              null
            }
          </div>
          :
          null
        }
      </div>
    )
  }
}
