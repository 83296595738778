import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

const EntryImage = ({ imageId, width, style, className, borderStyle }) => {
  const [imageUrl, setImageUrl] = useState(null)


  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const getImage = (id) => {
      if (id) {
        global.api.get("/api/images/entry/"+id+'.jpg', {
          params: {raw: true},
          cancelToken: source.token
        }).then(result => {
          setImageUrl('data:image/png;base64,'+ result.data) 
        }).catch(result => {
          if (axios.isCancel(result)) {
            console.log('Request canceled', result.message)
          } else {
            console.log('Error', result)
          }
        })
      }
    }
    getImage(imageId)
    return () => {
      source.cancel()
    }
  }, [imageId])

  const picture = (
    imageUrl && <img
      src={imageUrl}
      width={width || '40'}
      style={style || {}}
      className={className || ''}
      alt='' 
    />
  )

  return (
    <div style={borderStyle || {}}>
      {picture ||
      <Segment style={style || {}}>
        <Dimmer active>
          <Loader />
        </Dimmer>
      </Segment>}
    </div>
  );
}
export default EntryImage
