import { combineReducers } from 'redux'
import current from './current';
import patients from './patients';
import company from './company';
import tools from './tools';
import users from './users';
import locales from './locales';
import sidebar from './sidebar';
import { routerReducer } from 'react-router-redux'

export default combineReducers({
  current,
  patients,
  company,
  tools,
  users,
  locales,
  sidebar,
  routing: routerReducer
})
