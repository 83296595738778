import React from 'react';
import './WhiteButton.scss'
import {FaSortDown} from 'react-icons/fa'
import Loading from "../../Loading/Loading";

const WhiteButton = ({className, id, name, style, onClick, loading = false, disabled = false, showDropDownIcon = false}) => (
  <div className={'white-button-main-container ' + className}
       id={id}
       style={style}>
    <button className="white-button-main"
            style={{visibility:loading ? 'hidden' : 'visible'}}
            onClick={onClick}
            disabled={loading || disabled}>
      {name}
    
      {showDropDownIcon ?
        <FaSortDown className="white-button-icon-down"/>
        :
        null
      }
    </button>
  
    <Loading className="white-button-loading"
             loading={loading}
             showLabel={false}
    />
  </div>
);

export default WhiteButton;
