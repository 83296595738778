import React, {useState, useEffect} from 'react';
import './SurveyBuilderReorderList.scss';
import SurveyBuilderReorderCell from "../../Cells/SurveyBuilderCells/SurveyBuilderReorderCell/SurveyBuilderReorderCell";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";

// TODO: Move these classes from the Survey Builder SCSS to here.

const SortableReorderCell = SortableElement(({pageOrQuestion, menuButtonClicked}) => {
  return (
    <li key={'survey-builder-reorder-cell-' + pageOrQuestion.type + '-' + pageOrQuestion.id}>
      <SurveyBuilderReorderCell pageOrQuestion={pageOrQuestion}
                                menuButtonClicked={menuButtonClicked}
      />
    </li>
  )
});

const SortableReorderCellList = SortableContainer(({menuButtonClicked, reorderedModelsArray}) => {
  return (
    <ul className="survey-builder-page-cells-container">
      {reorderedModelsArray.map((pageOrQuestion, index) => (
        <SortableReorderCell key={'survey-builder-reorder-cell-' + pageOrQuestion.type + '-' + pageOrQuestion.id}
                             index={index}
                             disabled={!pageOrQuestion.canBeReordered}
                             pageOrQuestion={pageOrQuestion}
                             menuButtonClicked={menuButtonClicked}
        />
      ))}
    </ul>
  );
});

const SurveyBuilderReorderList = ({isInModal, pagesArray, reorderType, updatePagesArray}) => {
  
  // Instance Variables
  
  const [shouldReorder, setShouldReorder] = useState(true);
  const [reorderedModelsArray, setReorderedModelsArray] = useState([]);
  
  // Methods
  
  useEffect(() => {
    let modelsArray = [];
    
    if(reorderType || shouldReorder){
      if(reorderType === 'page'){
        for(let pageIndex = 0; pageIndex < pagesArray.length; pageIndex += 1){
          let page = pagesArray[pageIndex];
          modelsArray.push({id:page.id, key:page.key, type:'page', pageIndex:pageIndex, exitToType:'', isSurveyPage:page.isSurveyPage, questionType:'', canBeReordered:page.canBeDeleted});
        }
      }
      else if(reorderType === 'survey'){
        for(let pageIndex = 0; pageIndex < pagesArray.length; pageIndex += 1){
          let page = pagesArray[pageIndex];
          
          if(page.isSurveyPage){
            modelsArray.push({id:page.id, key:page.key, type:'page', pageIndex:pageIndex, isSurveyPage:true, questionType:'', canBeReordered:false});
            
            for(let surveyIndex = 0; surveyIndex < page.surveysArray.length; surveyIndex += 1){
              let survey = page.surveysArray[surveyIndex];
              modelsArray.push({id:survey.id, key:survey.key, type:'survey', pageIndex:pageIndex, surveyIndex:surveyIndex, isSurveyPage:true, questionType:'', canBeReordered:true});
            }
          }
        }
      }
      else if(reorderType === 'question'){
        for(let pageIndex = 0; pageIndex < pagesArray.length; pageIndex += 1){
          let page = pagesArray[pageIndex];
          
          if(!page.isSurveyPage){
            modelsArray.push({id:page.id, key:page.key, type:'page', pageIndex:pageIndex, isSurveyPage:false, questionType:'', canBeReordered:false});
            
            for(let questionIndex = 0; questionIndex < page.questionsArray.length; questionIndex += 1){
              let question = page.questionsArray[questionIndex];
              modelsArray.push({id:question.id, key:question.key, type:'question', pageIndex:pageIndex, isSurveyPage:false, questionType:question.type, questionIndex:questionIndex, canBeReordered:true});
            }
          }
        }
      }
      setShouldReorder(false);
      setReorderedModelsArray(modelsArray);
    }
  }, [pagesArray, reorderType, shouldReorder, setShouldReorder, setReorderedModelsArray]);
  
  // Render
  
  return (
    <div className="survey-builder-reorder-cell-container">
      <SortableReorderCellList key={'survey-builder-reorder-list'}
                               lockAxis={'y'}
                               onSortEnd={({oldIndex, newIndex}) => {
                                 if(reorderType === 'page'){
                                   let newPageOrQuestion = reorderedModelsArray[newIndex];
          
                                   if(newPageOrQuestion.canBeReordered){
                                     let newPagesArray = arrayMove(pagesArray, oldIndex, newIndex);
            
                                     for(let index = 0; index < newPagesArray.length; index += 1){
                                       let page = newPagesArray[index];
              
                                       if(page.canBeDeleted){
                                         page.key = 'Page' + (index + 1);
                                       }
                                     }
                                     updatePagesArray(newPagesArray);
                                   }
                                 }
                                 else if(reorderType === 'survey'){
                                   let newModel = reorderedModelsArray[newIndex];
                                   let oldModel = reorderedModelsArray[oldIndex];
          
                                   if(newModel.type === 'page'){
                                     let oldPage = pagesArray[oldModel.pageIndex];
                                     let survey = oldPage.surveysArray[oldModel.surveyIndex];
            
                                     if(newIndex === 0){
                                       let newPage = pagesArray[newModel.pageIndex];
                                       newPage.surveysArray.splice(0, 0, survey);
                                     }
                                     else{
                                       if(newIndex > oldIndex){
                                         let newPage = pagesArray[newModel.pageIndex];
                                         newPage.surveysArray.splice(0, 0, survey);
                                       }
                                       else{
                                         let newPage = pagesArray[newModel.pageIndex - 1];
                                         newPage.surveysArray.push(survey);
                                       }
                                     }
                                     oldPage.surveysArray.splice(oldModel.surveyIndex, 1);
                                   }
                                   else{
                                     let newPage = pagesArray[newModel.pageIndex];
                                     let oldPage = pagesArray[oldModel.pageIndex];
            
                                     let survey = oldPage.surveysArray[oldModel.surveyIndex];
                                     oldPage.surveysArray.splice(oldModel.surveyIndex, 1);
                                     newPage.surveysArray.splice(newModel.surveyIndex, 0, survey);
                                   }
                                   updatePagesArray(pagesArray);
                                 }
                                 else if(reorderType === 'question'){
                                   let newModel = reorderedModelsArray[newIndex];
                                   let oldModel = reorderedModelsArray[oldIndex];
          
                                   if(newModel.type === 'page'){
                                     let oldPage = pagesArray[oldModel.pageIndex];
                                     let question = oldPage.questionsArray[oldModel.questionIndex];
                                     oldPage.questionsArray.splice(oldModel.questionIndex, 1);
            
                                     if(newIndex === 0){
                                       let newPage = pagesArray[newModel.pageIndex];
              
                                       if(newPage.key === 'end' || newPage.key === 'disqualified'){
                                         question.exit_to_type = newPage.key;
                                       }
                                       else{
                                         if(question.exit_to_type && question.exit_to_type.length > 0){
                                           delete question['exit_to_type'];
                                         }
                                       }
                                       newPage.questionsArray.splice(0, 0, question);
                                     }
                                     else{
                                       if(newIndex > oldIndex){
                                         let newPage = pagesArray[newModel.pageIndex];
                
                                         if(newPage.key === 'end' || newPage.key === 'disqualified'){
                                           question.exit_to_type = newPage.key;
                                         }
                                         else{
                                           if(question.exit_to_type && question.exit_to_type.length > 0){
                                             delete question['exit_to_type'];
                                           }
                                         }
                                         newPage.questionsArray.splice(0, 0, question);
                                       }
                                       else{
                                         let newPage = pagesArray[newModel.pageIndex - 1];
                
                                         if(newPage.key === 'end' || newPage.key === 'disqualified'){
                                           question.exit_to_type = newPage.key;
                                         }
                                         else{
                                           if(question.exit_to_type && question.exit_to_type.length > 0){
                                             delete question['exit_to_type'];
                                           }
                                         }
                                         newPage.questionsArray.push(question);
                                       }
                                     }
                                   }
                                   else{
                                     let newPage = pagesArray[newModel.pageIndex];
                                     let oldPage = pagesArray[oldModel.pageIndex];
                                     let question = oldPage.questionsArray[oldModel.questionIndex];
            
                                     if(oldPage.key === 'end' || oldPage.key === 'disqualified'){
                                       delete question['exit_to_type'];
                                     }
                                     if(newPage.key === 'end' || newPage.key === 'disqualified'){
                                       question.exit_to_type = newPage.key;
                                     }
                                     oldPage.questionsArray.splice(oldModel.questionIndex, 1);
                                     newPage.questionsArray.splice(newModel.questionIndex, 0, question);
                                   }
                                   updatePagesArray(pagesArray);
                                   setShouldReorder(true);
                                 }
                               }}
                               pressDelay={100}
                               helperClass={'survey-builder-sortable-pages-list' + (isInModal ? '-modal' : '')}
                               getContainer={() => document.getElementById('survey-builder-container-id')}
                               menuButtonClicked={() => {
                                 // TODO: What should go here?
                               }}
                               reorderedModelsArray={reorderedModelsArray}
      />
    </div>
  );
};

export default SurveyBuilderReorderList
