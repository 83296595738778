import React from 'react';
import "antd/dist/antd.css";
import {Translation} from "react-i18next";
import GeneralModal from "../GeneralModal/GeneralModal";
import '../GeneralModal/GeneralModal.scss';
import './SurveyBuilderQuestionSelectModal.scss';
import UserPermissionCell from "../../Cells/UserPermissionCell/UserPermissionCell";
import {SurveyTypeDetailsArray} from "../../constants/SurveyBuilderTypes";

export default class SurveyBuilderQuestionSelectModal extends React.Component {
  
  // Render
  
  render() {
    const {isOpen, addQuestionWithType, handleCloseModal} = this.props;
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('ADD_QUESTION')}</Translation>}
                    width={'600px'}
                    maxWidth={'600px'}
                    minWidth={'600px'}
                    height={'600px'}
                    isOpen={isOpen}
                    isEditing={false}
                    showFooter={false}
                    onRequestClose={handleCloseModal}
                    noTitleHeading={true}
                    showSaveButton={false}
                    handleCloseModal={handleCloseModal}
                    showCancelButton={false}>
        <div className='create-survey-question-select-modal'>
          {SurveyTypeDetailsArray.map((surveyTypeDetails) => (
            <UserPermissionCell className="create-survey-question-select-cell"
                                key={'survey-type-details-' + surveyTypeDetails.type}
                                title={surveyTypeDetails.title}
                                value={surveyTypeDetails.type}
                                onChange={(aSurveyType) => addQuestionWithType(aSurveyType)}
                                description={surveyTypeDetails.description}
                                hideCheckbox={true}
            />
          ))}
        </div>
      </GeneralModal>
    )
  }
}
