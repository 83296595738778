import React, { Component } from "react";
import moment from "moment";
import LineChart from "./charts/LineChart";
import { groupBy, map, maxBy } from "lodash";
import { DATA_TYPES, LABELNAMES } from "./constants";

class VitalFloPatientCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfDateRangesBack: 0,
      dataPoints: {},
      fetchedDataPoints: {},
      detailViewVisible: false,
      selectedDataPoint: {},
      latestDataPointDate: null
    };
  }

  mapVitalFloData(vitalFloData) {
    let translatedData = {
      pefr: [],
      fev1: [],
      fvc: [],
      fev6: [],
      fef2575: [],
    };

    let dataPoints = vitalFloData.data;
    if (dataPoints?.length > 0) {
      let groupedByMinutes = groupBy(dataPoints, (data) =>
        moment(data.dbdate).seconds(0).toISOString()
      );
      let HighestPefrDataPointsPerMinute = map(groupedByMinutes, (value) =>
        maxBy(value, (dataPoint) => dataPoint["pefr"])
      );
      const timeZone = moment.tz.guess();

      HighestPefrDataPointsPerMinute.forEach((reading) => {
        DATA_TYPES.forEach((type) => {
          translatedData[`${type}`].push({
            x: moment(reading.dbdate)
              .tz(timeZone || "America/New_York")
              .format("lll z"),
            y: reading[`${type}`],
          });
        });
      });
    }

    this.setState({
      dataPoints: translatedData,
      fetchedDataPoints: vitalFloData,
      latestDataPointDate: vitalFloData?.latest?.dbdate
    });
  }

  showDataPointDetailView(index) {
    this.props.toggleDataPointsListCollapsed(
      "VitalFlo",
      "Spirometry Reading",
      this.state.fetchedDataPoints?.data?.[index]
    );
  }

  closeDataPointDetailView(index) {
    this.setState({ detailViewVisible: false });
  }

  componentDidMount() {
    this.mapVitalFloData(this.props.vitalFloData);
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex-column" style={{ flex: 1, padding: "0 30px" }}>
          {this.state.dataPoints &&
            DATA_TYPES.map((type) => (
              <div
                className="subsection-container flex-column"
                style={{ marginTop: 20, marginBottom: 20 }}
                key={type}
              >
                <h4> {LABELNAMES[`${type}`]}</h4>
                <LineChart
                  title={type}
                  dataPoints={this.state.dataPoints[`${type}`]}
                  startDate={this.props.from}
                  endDate={this.props.to}
                  latestDataPointDate={this.state.latestDataPointDate}
                  showDataPointDetailView={this.showDataPointDetailView.bind(
                    this
                  )}
                />
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default VitalFloPatientCharts;
