import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import LabeledDropDown from '../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown';
import i18next from "i18next";
import './UserTriggersList.scss';
import DynamicList from "../DynamicList/DynamicList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

export default class UserTriggersList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      email:null,
      selectedUser:null,
      shouldReload:false,
      processingStatus: "all"
    };
  }
  
  // Methods
  
  
  // Render
  
  render() {
    const {company, fetchUserTriggers, fetchPatientsScroll} = this.props;
    const {processingStatus, shouldReload, email, selectedUser} = this.state;
    
    let currentRole = roleForCompany(company);
    let processingStatuses =  [
      {label: (i18next.t('ALL')), value: 'all'},
      {label: (i18next.t('PROCESSED')), value: 'processed'},
      {label: (i18next.t('PENDING')), value: 'pending'}];
    
    const triggerPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_10"];
    
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:1, columnNameKey:'QUEST_NAME', templateCell:(aTrigger) => {
        return (
          <div>
            {aTrigger.trigger_keyword} - {aTrigger.quest_name}
          </div>
        );
      }});
    columnsArray.push({key:'alert_type', columnWeight:1, columnNameKey:'ALERT_TYPE', propertiesArray:['alert_type']});
    columnsArray.push({key:'last_triggered_at', columnWeight:1, columnNameKey:'PROCESSED_ON', propertiesArray:['last_triggered_at']});
    columnsArray.push({key:'last_error_message', columnWeight:1, columnNameKey:'LAST_ERROR_MESSAGE', propertiesArray:['last_error_message']});
    columnsArray.push({key:'admin_only', columnWeight:1, columnNameKey:'ADMIN_ONLY', propertiesArray:['admin_only']});
    
    return (
      <div className="main-panel-inner-container">
        {triggerPermissions.includes(currentRole) ?
          <>
            {selectedUser === null ?
              <div className="user-triggers-select-patient-dropdown">
                <LabeledDropDown id="user-triggers-list-patient-list-dropdown"
                                 label={<Translation>{(t, {i18n}) => t('SELECT_PATIENT_TO_SEARCH_FOR')}</Translation>}
                                 value={selectedUser}
                                 handleSave={(aOption) => {
                                   if(aOption){
                                     this.setState({email:aOption.email, selectedUser:aOption});
                                   }
                                 }}
                                 fetchObjects={(aLimit, aOffset, aSearchString) => fetchPatientsScroll(aSearchString, null, aLimit, aOffset)}
                                 responseTotalKeysArray={['data', 'data', 'user_page', 'total']}
                                 responseObjectKeysArray={['data', 'data', 'user_page', 'users']}
                                 customOptionFormatter={(aOption) => {
                                   return {id:aOption.id, value:aOption.id, label:aOption.first_name + ' ' + aOption.last_name}
                                 }}
                />
              </div>
              :
              null
            }
            
            {email && email.length > 0 ?
              <div id='triggers-list'
                   className="user-trigger-list-cell-container">
                <DynamicList id="user-triggers-list"
                             didReload={() => this.setState({shouldReload:false})}
                             headerView={
                               <div style={{display:'flex'}}>
                                 <LabeledDropDown name="processing_status"
                                                  value={processingStatus && processingStatuses.filter(function(option){
                                                    return option.value === processingStatus;
                                                  })}
                                                  options={processingStatuses}
                                                  handleSave={(aOption) => {
                                                    this.setState({processingStatus:aOption.value, shouldReload:true})
                                                  }}
                                 />
  
                                 <LabeledDropDown id="user-triggers-list-patient-list-dropdown-in-dynamic-list"
                                                  value={selectedUser}
                                                  handleSave={(aOption) => {
                                                    if(aOption){
                                                      this.setState({email:aOption.email, selectedUser:aOption});
                                                    }
                                                  }}
                                                  fetchObjects={(aLimit, aOffset, aSearchString) => fetchPatientsScroll(aSearchString, null, aLimit, aOffset)}
                                                  responseTotalKeysArray={['data', 'data', 'user_page', 'total']}
                                                  responseObjectKeysArray={['data', 'data', 'user_page', 'users']}
                                                  customOptionFormatter={(aOption) => {
                                                    return {id:aOption.id, value:aOption.id, label:aOption.first_name + ' ' + aOption.last_name}
                                                  }}
                                 />
                               </div>
                             }
                             shouldReload={shouldReload}
                             columnsArray={columnsArray}
                             fetchObjects={(aLimit, aOffset) => fetchUserTriggers(aLimit, aOffset, email, processingStatus)}
                             showSearchBar={false}
                             totalObjectsKey='TOTAL_TRIGGERS'
                             searchBarPlaceholder='ENTER_USER_EMAIL'
                             responseTotalKeysArray={['data', 'data', 'sent_user_notifications', 'total']}
                             responseObjectKeysArray={['data', 'data', 'sent_user_notifications', 'notifications']}
                />
              </div>
              :
              null
            }
          </>
          :
          <div className="no-information-text">
            <Translation>{(t) => t('TRIGGER_ACCESS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
