import React from "react";
import './SurveyBuilderSurveyCell.scss';
import cloneDeep from "lodash/cloneDeep";
import {Translation} from "react-i18next";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class SurveyBuilderSurveyCell extends React.Component{
  
  containerRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      isFetching:false,
      surveyReference:'',
      selectedReference:null,
      shouldFetchWhenFinished:false,
      surveyVersionCollection:{},
      isFetchingQuestionKeywords:false
    };
  }
  
  componentDidMount(){
    const {survey, fetchSurveyVersionCollections} = this.props;
  
    if(survey && survey.survey_reference && survey.survey_reference.length > 0){
      this.fetchQuestionKeywords(survey.survey_reference);
      fetchSurveyVersionCollections(5, 0, survey.survey_reference, false, false, 'reference').then((newResult) => {
        const survey_version_collections = newResult.data.data.survey_version_collections.survey_version_collections;
        
        if(survey_version_collections){
          this.setState({selectedReference:survey_version_collections[0]});
        }
      });
    }
  }
  
  // Methods
  
  fetchQuestionKeywords(aReference){
    const {survey, addError, updateSurvey, fetchQuestionsForSurveyByReference} = this.props;
    const {isFetchingQuestionKeywords} = this.state;
    
    if(!isFetchingQuestionKeywords && survey && (survey.questionKeysArray === undefined || survey.questionKeysArray === null)){
      this.setState({isFetchingQuestionKeywords:true});
      fetchQuestionsForSurveyByReference(aReference).then(newResult => {
        let updatedSurvey = cloneDeep(survey);
        updatedSurvey.doesNotExist = false;
        updatedSurvey.questionKeysArray = newResult.data.data.survey_questions;
        updateSurvey(updatedSurvey);
        this.setState({isFetchingQuestionKeywords:false});
      }, (newError) => {
        let error = '';
      
        if(newError && newError.data && newError.data && newError.data.error){
          error = newError.data.error;
        }
        let updatedSurvey = cloneDeep(survey);
        updatedSurvey.doesNotExist = true;
        updateSurvey(updatedSurvey);
  
        let title = 'Survey';
  
        if(survey.survey_reference && survey.survey_reference.length > 0){
          title = 'S: ' + survey.survey_reference;
        }
        addError(survey.id, 'survey_reference', 'error', title, 'SURVEY_REFERENCE_DOES_NOT_EXIST');
        this.setState({isFetchingQuestionKeywords:false});
      });
    }
  }
  
  // Render
  
  render(){
    const {errorForIdTypeAndKey, survey, updateSurvey, isSelectedSurvey, menuButtonClicked} = this.props;
    const {selectedReference} = this.state;
    
    let enabledSettingsArray = [];
    
    if(survey.repeat_count !== undefined){
      if(survey.repeat_count > 1){
        enabledSettingsArray.push({key:'SURVEY_REPEAT_COUNT_MESSAGE', parameters:{aCount:survey.repeat_count}});
      }
      else{
        enabledSettingsArray.push({key:'SURVEY_REPEAT_COUNTS_MESSAGE', parameters:{aCount:survey.repeat_count}});
      }
    }
    if(survey.repeat_count_question_key !== undefined){
      enabledSettingsArray.push({key:'REPEAT_SURVEY_BASED_ON_ANSWER_TO_QUESTION', parameters:{aQuestionKey:survey.repeat_count_question_key}});
    }
    if(survey.condition){
      enabledSettingsArray.push('CONDITIONS');
    }
  
    let containerHeight = 0;
  
    if(this.containerRef && this.containerRef.current && this.containerRef.current.getBoundingClientRect()){
      containerHeight = this.containerRef.current.getBoundingClientRect().height;
    }
    
    // TODO: Add in DB read editor (https://sandbox.healthstorylines.com/console/#/survey/272).
    
    return(
      <div className={'survey-builder-survey-cell ' + (isSelectedSurvey ? 'survey-builder-survey-cell-selected' : '')}
           ref={this.containerRef}>
        <span className="survey-builder-survey-header">
          <LabeledDropDown className="survey-builder-survey-input-title"
                           // label={'y:' + survey.y + ' h:' + survey.height + ' c:' + containerHeight + ' = ' + (survey.height === containerHeight ? 'YES' : 'NO')}
                           value={selectedReference}
                           infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_REFERENCE_EXPLANATION')}</Translation>}
                           handleSave={(aOption) => {
                             if(survey.survey_reference !== aOption.reference){
                               let updatedSurvey = cloneDeep(survey);
                               updatedSurvey.survey_reference = aOption.reference;
                               updatedSurvey.doesNotExist = false;
                               
                               if(updatedSurvey.questionKeysArray){
                                 delete updatedSurvey['questionKeysArray'];
                               }
                               updateSurvey(updatedSurvey);
                             }
                             this.setState({surveyReference:aOption.reference, selectedReference:aOption}, () => {
                               this.fetchQuestionKeywords(aOption.reference);
                             });
                           }}
                           placeholder={<Translation>{(t, {i18n}) => t('SURVEY_REFERENCE')}</Translation>}
                           errorMessage={errorForIdTypeAndKey(survey.id, 'error', 'survey_reference').message}
                           fetchObjects={(aLimit, aOffset, aSearchString) => this.props.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, false, false, 'reference')}
                           labelPropertiesArray={['reference']}
                           valuePropertiesArray={['reference']}
                           responseTotalKeysArray={['data', 'data', 'survey_version_collections', 'total']}
                           responseObjectKeysArray={['data', 'data', 'survey_version_collections', 'survey_version_collections']}
          />
  
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize:'21px', alignSelf: 'baseline'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
            />
            :
            null
          }
        </span>
  
        {enabledSettingsArray.length > 0 ?
          <div className="survey-builder-survey-enabled-settings-container">
            <div className="survey-builder-survey-enabled-settings-title">
              <Translation>{(t, {i18n}) => t('ENABLED_SETTINGS')}</Translation>
            </div>
      
            {enabledSettingsArray.map((setting, index) => (
              <div className="survey-builder-survey-enabled-settings-array"
                   key={'survey-enabled-settings-' + setting.key + '-' + index}>
                • <Translation>{(t, {i18n}) => t(setting.key, setting.parameters)}</Translation>
              </div>
            ))}
          </div>
          :
          null
        }
      </div>
    )
  }
}
