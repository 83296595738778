import {LOGIN, FORGOT, LOGOUT, SIGNUP, LOGIN_ERROR, FORGOT_ERROR, LOAD_PATIENT, SIGNUP_ERROR, RESET_PASSWORD,
  PERMITTED_ITEMS, LOAD_CURRENT_USER, LOAD_PATIENT_DATA, LOAD_PATIENT_ERROR, SET_CURRENT_PATIENT, RESET_PASSWORD_ERROR,
  SET_CURRENT_MESSAGES, FETCH_PATIENT_FEATURES, LOAD_SYNCED_DEVICE_DATA, SET_CURRENT_CONVERSATION,
  LOAD_PATIENT_DATA_FOR_KEY, UPDATE_CURRENT_USER_ERROR, LOAD_MINIFIED_PATIENT_DATA_FOR_KEY} from 'constants/ActionTypes';
import {REHYDRATE} from 'redux-persist';
import cloneDeep from "lodash/cloneDeep";
import EventTracker from "../eventTracker";

// type Action = { type: string };
//
// export type State = {
//   user: Object,
//   patient: Object,
//   permittedItems: Object,
// };

const initialState = {
  user:{},
  patient:{},
  permittedItems:[],
  patientLoadError:null
};

function current(state = initialState, action){
  switch(action.type){
    case REHYDRATE:
      let incoming = action.payload && action.payload.current;
      
      // if(incoming && incoming.user && incoming.user.id){
      //   EventTracker.identify(incoming.user.id);
      // }
      if(incoming && incoming.user && (incoming.user.errors || incoming.user.error)){
        return {
          ...state,
          user:{}
        };
      }
      return state;
    case LOGIN:
      return {
        ...state,
        user:action.result
      };
    case LOGIN_ERROR:
      return {
        ...state,
        user:action.result
      };
    case FORGOT:
      return {
        ...state,
        user:action.result
      };
    case FORGOT_ERROR:
      return {
        ...state,
        user:action.result
      };
    case LOGOUT:
      EventTracker.identify('', []);
      return {};
    case LOAD_CURRENT_USER:
      return {
        ...state,
        user:action.result
      };
    case UPDATE_CURRENT_USER_ERROR:
      return {
        ...state,
        user:{
          ...state.user,
          error:action.result
        }
      };
    case SET_CURRENT_PATIENT:
      return {
        ...state,
        patient:action.patient
      };
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        conversation:action.data
      };
    case SET_CURRENT_MESSAGES:
      return {
        ...state,
        conversation:{...state.conversation, messages:action.data}
      };
    case LOAD_PATIENT:
      return {
        ...state,
        patient:action.data
      };
    case LOAD_PATIENT_ERROR:
      return {
        ...state,
        patientLoadError:action.error
      };
    case LOAD_PATIENT_DATA:
      return {
        ...state,
        patientData:action.data
      };
    case SIGNUP:
      return {
        ...state,
        user:action.result
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        user:action.result
      };
    case LOAD_SYNCED_DEVICE_DATA:
      return {
        ...state,
        syncedDevicesData:action.data
      };
    case RESET_PASSWORD:
      return {
        ...state,
        user:action.result
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        user:action.result
      };
    case "@@router/LOCATION_CHANGE":
      if(state.user && state.user.message !== '' && action.payload.location.pathname === "/forgot"){
        return {
          initialState
        };
      }
      else{
        return state;
      }
    case PERMITTED_ITEMS:
      return {
        ...state,
        permittedItems:action.payload
      };
    case FETCH_PATIENT_FEATURES:
      let hsFeatures = cloneDeep(state.patient.hs_features);
      let hsFeatureId = action.result.featureId.toString();
      let featureEnabled = action.result.featureEnabled;
      
      for(let index = 0; index < hsFeatures.length; index++){
        if(hsFeatures[index].id.toString() === hsFeatureId){
          hsFeatures[index].enabled = featureEnabled;
        }
      }
      return {
        ...state,
        patient:{
          ...state.patient,
          hs_features:hsFeatures
        }
      };
    case LOAD_PATIENT_DATA_FOR_KEY:
      return {
        ...state,
        patient:{
          ...state.patient,
          [action.key]:action.data
        }
      };
    case LOAD_MINIFIED_PATIENT_DATA_FOR_KEY:
      let minifiedPatient = {};
      minifiedPatient[action.key] = action.data;
      
      return {
        ...state,
        patient:{
          ...state.patient,
          ...minifiedPatient
        }
      };
    default:
      return state;
  }
}

export default current;
