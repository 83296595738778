import React from "react";
import './SurveyBuilderQuestionSafetyAlerts.scss';
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";

export default class SurveyBuilderQuestionSafetyAlerts extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      selectedIndex:-1
    };
  }
  
  // Methods
  
  menuButtonClicked(aEvent, aIndex){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({selectedIndex:aIndex, menuAnchorElement:aEvent.currentTarget});
  };
  
  // Render
  
  render(){
    const { question, saveQuestion, errorForIdTypeAndKey, adminAlertEmailsArray, addAdminEmail, updateAdminEmailAtIndex,
      removeAdminEmailAtIndex } = this.props;
    const { selectedIndex, menuAnchorElement } = this.state;
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-settings-controls-input-switch"
                       label={<Translation>{ (t, { i18n }) => t('SAFETY_ALERT') }</Translation>}
                       checked={Boolean(question && question.safety_alert_question)}
                       onChange={(isChecked) => saveQuestion(isChecked, 'safety_alert_question')}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('SAFETY_ALERT_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                       errorMessage={errorForIdTypeAndKey(question.id, 'error', 'admin_emails_missing').message}
        />
  
        {Boolean(question && question.safety_alert_question) ?
          <>
            {adminAlertEmailsArray && adminAlertEmailsArray.map((email, index) => (
              <LabeledDelayedInput className="survey-builder-question-other-input-more"
                                   key={'admin_email_input_' + index}
                                   label={<Translation>{(t, {i18n}) => t('ADMIN_EMAIL')}</Translation>}
                                   value={email}
                                   infoBlurb={<Translation>{(t, {i18n}) => t('ADMIN_EMAIL_EXPLANATION')}</Translation>}
                                   minLength={0}
                                   handleSave={(aEvent) => updateAdminEmailAtIndex(aEvent.target.value, index)}
                                   errorMessage={errorForIdTypeAndKey(question.id, 'error', 'admin_email_' + index).message}
                                   popoverPlacement={"left"}
                                   menuButtonClicked={(aEvent) => this.menuButtonClicked(aEvent, index)}
              />
            ))}
  
            <div className="survey-builder-question-visibility-add-button"
                 onClick={() => addAdminEmail()}>
              <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
    
              <Translation>{(t, {i18n}) => t('ADD_ADMIN_EMAIL')}</Translation>
            </div>
          </>
          :
          null
        }
  
        <Menu id="simple-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:100001}}
              onClose={() => this.setState({menuAnchorElement:null, selectedIndex:-1})}
              anchorEl={menuAnchorElement}
              keepMounted>
          <MenuItem style={{position:'relative'}}
                    onClick={(aEvent) => {
                      aEvent.preventDefault();
                      aEvent.stopPropagation();
                      removeAdminEmailAtIndex(selectedIndex);
                      this.setState({menuAnchorElement:null, selectedIndex:-1});
                    }}>
            <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
