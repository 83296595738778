import {LOAD_PATIENT, FETCH_PATIENT, LOAD_PATIENT_DATA, FETCH_PATIENT_DATA, LOAD_PATIENT_ERROR, LOAD_SYNCED_DEVICE_DATA,
  LOAD_PATIENT_DATA_FOR_KEY, LOAD_MINIFIED_PATIENT_DATA_FOR_KEY} from 'constants/ActionTypes';
import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function resetPatientsPassword(aUserId, aPassword) {
  let graphQLQuery = `
  mutation ($updatePatientPasswordInput: UpdatePatientPasswordMutationInput!){
        updatePatientPassword(input: $updatePatientPasswordInput){
          user {
            id
          }
        }
      }
  `;

  return (dispatch, getState) => {
    let state = getState();
    let operationName = 'updatePatientPasswordInput';
    let variables = {
      updatePatientPasswordInput: {
        company_id: state.company && state.company.company && state.company.company.id,
        user_id: aUserId,
        password: aPassword
      }
    };
    return global.api.post("/queries", {query: graphQLQuery, variables, operationName});
  };
}

export function addAlternativeContactToUser({alternativeContact, userId}) {
  let graphQLQuery = `
    mutation AddAlternativeContactToUser($addAlternativeContactToUserInput: AddAlternativeContactToUserInput!) {
      addAlternativeContactToUser(input: $addAlternativeContactToUserInput) {
        additional_contact {
          id
          first_name
          middle_initial
          last_name
          primary_phone_number
          alternative_phone_number
          email
          preferred_language
          relationship_to_patient
          contact_time_preference
        }
      }
    }`;

  let variables = {
    addAlternativeContactToUserInput: {
      user_id:userId
    }
  };
  Object.keys(alternativeContact).forEach(key => {
    variables.addAlternativeContactToUserInput[key] = alternativeContact[key]
  });
  
  let operationName = "AddAlternativeContactToUser";
  
  return (dispatch) => {
    let promise = global.api.post("/queries", {query:graphQLQuery, variables, operationName});
    promise.then((newResult) => dispatch(loadPatientContacts(userId)));
    return promise;
  };
}

export function addHealthCareProviderInformationToUser(aHealthCareProviderInformation, aUserId){
  let graphQLQuery = `
    mutation AddHealthCareProviderInformationToUser($addHealthCareProviderInformationToUserInput: AddHealthCareProviderInformationToUserInput!) {
      addHealthCareProviderInformationToUser(input: $addHealthCareProviderInformationToUserInput) {
        health_care_provider_information {
          id
          first_name
          last_name
          role
          phone_number
          email
          user_id
          physician_specialty
          facility_name
        }
      }
    }`;

  let variables = {
    addHealthCareProviderInformationToUserInput: {
      user_id:aUserId
    }
  };
  Object.keys(aHealthCareProviderInformation).forEach((key) => {
    variables.addHealthCareProviderInformationToUserInput[key] = aHealthCareProviderInformation[key]
  });
  
  let operationName = "AddHealthCareProviderInformationToUser";
  
  return (dispatch) => {
    let promise = global.api.post("/queries", {query:graphQLQuery, variables, operationName});
    promise.then((newResult) => dispatch(loadPatientHCPContacts(aUserId)));
    return promise;
  };
}

export function removeAlternativeContactFromUser({alternativeContact, userId}){
  let graphQLQuery = `
    mutation RemoveAlternativeContactFromUser($removeAlternativeContactFromUserInput: RemoveAlternativeContactFromUserInput!) {
      removeAlternativeContactFromUser(input: $removeAlternativeContactFromUserInput) {
        additional_contact {
          id
          first_name
          middle_initial
          last_name
          primary_phone_number
          alternative_phone_number
          email
          preferred_language
          relationship_to_patient
          contact_time_preference
        }
      }
    }`;

  let variables = {
    removeAlternativeContactFromUserInput: {
      user_id:userId
    }
  };

  Object.keys(alternativeContact).forEach(key => {
    variables.removeAlternativeContactFromUserInput[key] = alternativeContact[key]
  });

  let operationName = "RemoveAlternativeContactFromUser";
  
  return (dispatch) => {
    let promise = global.api.post("/queries", {query:graphQLQuery, variables, operationName});
    promise.then((newResult) => dispatch(loadPatientContacts(userId)));
    return promise;
  };
}

export function removeHealthCareProviderInformationFromUser(aHealthCareProviderInformation, aUserId){
  let graphQLQuery = `
    mutation RemoveHealthCareProviderInformationFromUser($removeHealthCareProviderInformationFromUserInput: RemoveHealthCareProviderInformationFromUserInput!) {
      removeHealthCareProviderInformationFromUser(input: $removeHealthCareProviderInformationFromUserInput) {
        health_care_provider_information {
          id
          first_name
          last_name
          role
          phone_number
          email
          user_id
          physician_specialty
          facility_name
        }
      }
    }`;

  let variables = {
    removeHealthCareProviderInformationFromUserInput: {
      user_id:aUserId
    }
  };
  Object.keys(aHealthCareProviderInformation).forEach((key) => {
    variables.removeHealthCareProviderInformationFromUserInput[key] = aHealthCareProviderInformation[key]
  });
  
  let operationName = "RemoveHealthCareProviderInformationFromUser";
  return (dispatch) => {
    let promise = global.api.post("/queries", {query:graphQLQuery, variables, operationName});
    promise.then((newResult) => dispatch(loadPatientHCPContacts(aUserId)));
    return promise;
  };
}

export function removeMedicationFromUser(objectiveId, userId){
  EventTracker.track(EventTypes.REMOVE_MEDICATION_FROM_USER);
  let query = `
  mutation RemoveMedicationFromUser($removeMedicationFromUserInput: RemoveMedicationFromUserInput!) {
    removeMedicationFromUser(input: $removeMedicationFromUserInput) {
      objective {
        id
        description
        type
        medication {
          id
          name
        }
        reminders {
          id
          note
          time
          repeat
          repeat_type
        }
      }
    }
  }
  `;
  let variables = {
    removeMedicationFromUserInput: {
      user_id: userId,
      objective_id: objectiveId
    }
  };
  let operationName = `RemoveMedicationFromUser`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function removeConditionFromUser(groupId, userId){
  EventTracker.track(EventTypes.REMOVE_CONDITION_FROM_USER);
  let query = `
  mutation RemoveCondition($removeConditionInput: RemoveConditionInput!) {
    removeCondition(input: $removeConditionInput) {
      condition {
        id
        name
      }
    }
  }
  `;
  let variables = {
    removeConditionInput: {
      user_id: userId,
      group_id: groupId
    }
  };
  let operationName = `RemoveCondition`;

  return (dispatch) => {
    return global.api.post("/queries", {
      query, variables, operationName
    })
  }
}

export function fetchPatient({userId}) {
  return dispatch => {
    dispatch({
      type: FETCH_PATIENT
    });
    dispatch(loadPatient({userId}));
  };
}

export function loadPatient({userId}) {
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      email
      edc_id
      gender
      address
      site_id
      image_id
      study_id
      last_name
      birth_year
      first_name
      hs_features
      country_code
      gender_other
      test_account
      contact_method
      email_verified
      study_end_date
      subject_status
      disposition_code
      study_start_date
      consent_confirmed
      translator_needed
      can_trigger_alerts
      preferred_language
      alternative_contact
      active_surveys_count
      primary_contact_number
      completed_surveys_count
      contact_time_preference
      incomplete_survey_count
      notification_preference
      secondary_contact_number
      preferred_method_of_contact
      number_of_vaccination_entries
      number_of_unverified_fraud_events
      number_of_pfizer_vaccination_entries
      number_of_uncleared_company_notification_receipts
      survey_responses{
        survey{
          name
        }
        complete
      }
      human_api_user{
        id
        hsc_reports_access_consent
        last_reports_data_sync_requested_at
        has_reports_data_sync_completed
      }
    }
  }
  `;
  let variables = {userId};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", { query, variables }).then((result) => {
      if(result.data.data.user){
        dispatch({
          type: LOAD_PATIENT,
          data: result.data.data.user
        });
      } else {
        dispatch({
          type: LOAD_PATIENT,
          data: {}
        })
      }

    }).catch((error) => {
      dispatch({
        type: LOAD_PATIENT_ERROR,
        error,
      });
    });
  };
}

export function loadPatientDataWithKeyAndQuery(aPatientId, aKey, aQuery, aType = LOAD_PATIENT_DATA_FOR_KEY){
  let variables = {userId:aPatientId};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    let promise = global.api.post("/queries", { query:aQuery, variables });
    promise.then((result) => {
      if(result.data.data.user){
        dispatch({
          type:aType,
          key:aKey,
          data:result.data.data.user[aKey]
        });
      }
      else{
        dispatch({
          type:aType,
          key:aKey,
          data:{}
        })
      }
    }).catch((error) => {
      dispatch({
        type:LOAD_PATIENT_ERROR,
        error,
      });
    });
    return promise;
  };
}

export function loadPatientFullQuests(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      quests {
        id
        title
        icon
        icon_bg_color
        start_date
        end_date
        quest_type
        admin_only
        visibility
        quest_order
        has_trigger_quests
        tool_last_used
        hsc_consent
        has_hsc_consent_question
        survey {
          id
          name
          is_repeatable
          has_consent_question
          has_sensitive_data_question
        }
        survey_version_collection {
          id
          name
        }
        quest_schedule {
          id
          end_date
          start_date
          created_at
          updated_at
          enable_frequency
          enable_frequency_unit
          enable_duration
          enable_duration_unit
          start_date_table
          start_date_column
        }
        survey_responses {
          id
          progress
          complete
          updated_at
          completed_at
          admin_id
          completed_by_admin
          admin_name
          lock_state
          survey {
            id
            name
          }
        }
        objectives {
          survey_id
          type
        }
        groups {
          id
          name
        }
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'quests', query);
}

export function loadPatientMinifiedQuests(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      quests {
        id
        title
        start_date
        end_date
        quest_type
        admin_only
        visibility
        quest_order
        has_trigger_quests
        hsc_consent
        has_hsc_consent_question
        tool_last_used
        survey {
          id
          name
          is_repeatable
          has_consent_question
          has_sensitive_data_question
        }
        survey_version_collection {
          id
          name
        }
        quest_schedule {
          id
          end_date
          start_date
          created_at
          updated_at
          enable_frequency
          enable_frequency_unit
          enable_duration
          enable_duration_unit
          start_date_table
          start_date_column
        }
        survey_responses {
          id
          progress
          complete
          updated_at
          completed_at
          admin_id
          completed_by_admin
          admin_name
          lock_state
          survey {
            id
            name
          }
        }
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'quests', query, LOAD_MINIFIED_PATIENT_DATA_FOR_KEY);
}

export function loadPatientContacts(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      additional_contacts {
        id
        first_name
        middle_initial
        last_name
        primary_phone_number
        alternative_phone_number
        email
        preferred_language
        relationship_to_patient
        contact_time_preference
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'additional_contacts', query);
}

export function loadPatientMedicationQuests(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      medication_quest {
        id
        title
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'medication_quest', query);
}

export function loadPatientHCPContacts(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      health_care_provider_informations {
        id
        first_name
        last_name
        role
        phone_number
        email
        user_id
        physician_specialty
        facility_name
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'health_care_provider_informations', query);
}

export function loadPatientAllergiesUsers(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      allergies_users {
        id
        allergen
        allergy_id
        user_id
        severity
        created_at
        updated_at
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'allergies_users', query);
}

export function loadPatientSymptoms(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      symptoms {
        id
        symptom_objective_id
        name
        description
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'symptoms', query);
}

export function loadPatientMedications(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      medications {
        id
        description
        concept_id
        quantity
        medication {
          id
          name
          ndc
          strength
          form
          route
        }
        reminders {
          id
          note
          time
          repeat
          repeat_type
          day_number
        }
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'medications', query);
}

export function loadPatientConditions(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      conditions {
        id
        name
        date_of_diagnosis
        group_id
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'conditions', query);
}

export function loadPatientGroups(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      groups (group_type: "company", core: false, company_id: $company_id) {
        id
        name
        core
        company_id
        group_type
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'groups', query);
}

export function loadPatientMedicationAllergiesUsers(aPatientId){
  let query = `
  query getPatientData($userId: ID!, $company_id: ID) {
    user(id: $userId, company_id: $company_id) {
      id
      medication_allergies_users {
        id
        name
        medication_id
        user_id
        severity
        created_at
        updated_at
      }
    }
  }
  `;
  return loadPatientDataWithKeyAndQuery(aPatientId, 'medication_allergies_users', query);
}

export function fetchSyncedDevicesData({userId, from, to}) {
  return dispatch => {
    dispatch({
      type: LOAD_SYNCED_DEVICE_DATA
    });
    dispatch(loadSyncedDevicesData({userId, from, to}));
  };
}

export function loadSyncedDevicesData({userId, from, to}) {
  let query = `
  query loadSyncedDevicesData($userId: ID!, $company_id: ID, $from: String, $to: String) {
    user(id: $userId, company_id: $company_id) {
      human_api_data (from: $from, to: $to){
        data
        data_type
      }
      dexcom_data (from: $from, to: $to){
        entry
        entry_type
      }
      vital_flo_data{
        latest {
          id
          pefr
          fev1
          fvc
          fev6
          fef2575
          symptom
          dbdate
          user
        }
        data (from: $from, to: $to){
          id
          pefr
          fev1
          fvc
          fev6
          fef2575
          symptom
          dbdate
          user
        }
      }
      curie_ai_data{
        latest {
          id
          first_name
          last_name
          age
          gender
          zone
          health_summary
          spo2
          pulse
          measured_at
        }
        data (from: $from, to: $to){
          id
          first_name
          last_name
          age
          gender
          zone
          health_summary
          spo2
          pulse
          measured_at
        }
      }
    }
  }
  `;

  let variables = {userId, from, to}

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id
    return global.api.post("/queries", { query, variables }).then((result) => {
      dispatch({
        type: LOAD_SYNCED_DEVICE_DATA,
        data: result.data.data.user
      });

    }).catch((error) => {
      dispatch({
        type: LOAD_SYNCED_DEVICE_DATA,
        error,
      });
    });
  };
}

export function fetchPatientData({userId, from, to, objectiveTypes}) {
  return dispatch => {
    dispatch({
      type: FETCH_PATIENT_DATA
    });
    dispatch(loadPatientData({userId, from, to, objectiveTypes}));
  };
}

export function loadPatientData({userId, from, to, objectiveTypes}) {
  let query = `
  query getPatientData($userId: ID!, $from: String, $to: String, $company_id: ID, $objective_types: [String], $quest_limit: Int, $quest_offset: Int) {
    user(id: $userId, company_id: $company_id) {
      id
      quests (objective_types: $objective_types, quest_limit: $quest_limit, quest_offset: $quest_offset) {
        id
        title
        admin_only
        quest_type
        visibility
        icon_bg_color
        hsc_visualization
        rotate_objectives
        has_trigger_quests
        translations {
          title
          locale
          label_do
          description
          label_history
          label_description
        }
        objectives {
          id
          name
          type
          minimum_value
          maximum_value
          is_primary_hsc_visualization
          entries(from: $from, to: $to) {
           id
           value
           text
           reminder_time
           response {
            id
            measured_at
           }
           answers {
            id
            answer_text
            chart_value
            chart_colour
           }
          }
        }
      }
      allergies_users {
        id
        user_id
        allergen
        severity
        created_at
        updated_at
        allergy_id
      }
      medication_allergies_users {
        id
        name
        user_id
        severity
        created_at
        updated_at
        medication_id
      }
      hs_features
    }
  }
  `;
  let variables = {userId, from, to, objective_types: objectiveTypes, quest_limit: 5, quest_offset: 0};

  return (dispatch, getState) => {
    var state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", { query, variables }).then((result) => {
      dispatch({
        type: LOAD_PATIENT_DATA,
        data: result.data.data.user
      });

    }).catch((error) => {
      dispatch({
        type: LOAD_PATIENT_DATA,
        error,
      });
    });
  };
}

export function getAndReturnPatientData({userId, from, to, objectiveTypes, questOffset, questLimit}){
  let query = `
  query getPatientData($userId: ID!, $from: String, $to: String, $company_id: ID, $objective_types: [String], $quest_limit: Int, $quest_offset: Int) {
    user(id: $userId, company_id: $company_id) {
      id
      quests (objective_types: $objective_types, quest_limit: $quest_limit, quest_offset: $quest_offset) {
        id
        title
        icon_bg_color
        hsc_visualization
        rotate_objectives
        has_trigger_quests
        objectives {
          id
          name
          type
          is_primary_hsc_visualization
          minimum_value
          maximum_value
          entries(from: $from, to: $to) {
           id
           value
           text
           reminder_time
           response {
            id
            measured_at
           }
           answers {
            id
            answer_text
            chart_value
            chart_colour
           }
          }
        }
      }
    }
  }
  `;
  let variables = {userId, from, to, objective_types: objectiveTypes, quest_limit: questLimit, quest_offset: questOffset};

  return (dispatch, getState) => {
    var state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", {query, variables});
  }
}

export function findUserByEmail(aEmail){
  EventTracker.track(EventTypes.FIND_USER_BY_EMAIL);
  let query = `
    query findUserByEmail($email: String!) {
      user(email: $email) {
        id
        email
      }
    }
  `;
  let variables = {};
  
  return () => {
    variables['email'] = aEmail;
    return global.api.post("/queries", {query, variables});
  };
}

export function fetchQuestsByPatientId(aUserId, aLimit, aOffset, aIsAdminOnly, aSearchString, aQuestType){
  let query = `
    query getUserQuests($user_id: ID!, $company_id: ID!, $limit: Int, $offset: Int, $admin_only: Boolean, $search_string: String, $quest_type: String) {
      user_quests(user_id: $user_id, company_id: $company_id, limit: $limit, offset: $offset, admin_only: $admin_only, search_string: $search_string, quest_type: $quest_type) {
        quests {
          id
          title
          icon
          icon_bg_color
          start_date
          end_date
          quest_type
          admin_only
          visibility
          quest_order
          has_trigger_quests
          tool_last_used
          hsc_consent
          has_hsc_consent_question
          survey {
            id
            name
            is_repeatable
            has_consent_question
            has_sensitive_data_question
          }
          survey_version_collection {
            id
            name
          }
          quest_schedule {
            id
            end_date
            start_date
            created_at
            updated_at
            enable_frequency
            enable_frequency_unit
            enable_duration
            enable_duration_unit
            start_date_table
            start_date_column
          }
          survey_responses {
            id
            progress
            complete
            updated_at
            completed_at
            admin_id
            completed_by_admin
            admin_name
            lock_state
            survey {
              id
              name
            }
          }
          objectives {
            survey_id
            type
          }
          groups {
            id
            name
          }
        }
        total
      }
    }
  `;
  let variables = {};
  variables['limit'] = aLimit;
  variables['offset'] = aOffset;
  variables['user_id'] = aUserId;
  variables['admin_only'] = aIsAdminOnly;
  variables['quest_type'] = aQuestType;
  variables['search_string'] = aSearchString;

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", {query, variables});
  };
}


