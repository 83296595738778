import React from "react";
import './ManagePatients.scss';
import isEmail from 'validator/lib/isEmail';
import TabBar from "../../Custom UI/TabBar/TabBar";
import i18next from "i18next";
import {toast} from "react-toastify";
import Loading from "../../Custom UI/Loading/Loading";
import {CSVLink} from 'react-csv'
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";
import PatientList from "../../Lists/PatientList/PatientList";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import LabeledSwitch from "../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import GroupCodesList from "../../Lists/GroupCodesList/GroupCodesList";
import LabeledDropdown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import SecondaryHeader from "../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {genderOtherError} from "../../Helpers/PatientHelpers";
import ManagePatientsList from "../../Lists/ManagePatientsList/ManagePatientsList";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import {genderOptionsArray} from "../../constants/GenderTypes";
import {MINIMUM_PASSWORD_LENGTH} from "constants/ClientConstants";
import {Translation, withTranslation} from "react-i18next";

const defaultExistingUserState = {
  id:null,
  email:'',
  group_id:null
};

const defaultNewUserState = {
  role:'patient',
  email:'',
  gender:'',
  group_id:'',
  password:'',
  last_name:'',
  birth_year:'',
  first_name:''
};

const notifyCSVUploadSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SUCCESSFULLY_UPLOADED_PATIENT_CSV') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyCSVUploadError = (errorMessage) =>  toast(errorMessage);
const notifyAddPatientSuccess = () =>  toast(<Translation>{ (t, { i18n }) => t('SUCCESSFULLY_ADDED_PATIENT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyInvitePatientSuccess = () => toast(<Translation>{ (t, { i18n }) => t('INVITE_SENT_SUCCESSFULLY') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyAddPatientFailure = (aError) =>  toast(<Translation>{ (t, { i18n }) => t('ADD_PATIENT_ERROR', {error: aError}) }</Translation>, NOTIFY_OPTS.autoClose);

class ManagePatients extends React.Component {
  
  // Instance Variables
  
  hiddenFileInputRef = React.createRef();
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      csvFile:null,
      loading:false,
      newUser:defaultNewUserState,
      userFound:null,
      tabBarKey:'PATIENT_LIST',
      searchEmail:'',
      existingUser:defaultExistingUserState,
      searchingUser:false,
      confirmPassword:'',
      removeFromDefaultCompany:true
    };
    if(props.existingUser){
      this.setState({existingUser:{email:props.existingUser.email, id:props.existingUser.id}});
    }
    if(roleForCompany(props.company) === 'admin_level_8'){
      this.state.tabBarKey = 'INVITE_PATIENT';
    }
    this.addUser = this.addUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.csvSaveHandler = this.csvSaveHandler.bind(this);
    this.inviteUserToCompany = this.inviteUserToCompany.bind(this);
    this.handleUserEmailSearch = this.handleUserEmailSearch.bind(this);
    this.handleExistingUserGroupSelectChange = this.handleExistingUserGroupSelectChange.bind(this);
  }
  
  // Methods
  
  inviteUserToCompany() {
    const {existingUser, removeFromDefaultCompany} = this.state;
    this.props.inviteUserToCompany(existingUser.id, existingUser.group_id, removeFromDefaultCompany).then(result => {
      let error = result?.data?.error;
      
      if(error){
        this.displayErrorMessage(error);
      }
      else{
        notifyInvitePatientSuccess();
        this.setState({ existingUser: { email: '', id: null }, userFound: null, searchEmail: '' });
      }
    }).catch(error => {
      this.displayErrorMessage(error);
    });
  }
  
  addUser(){
    const {newUser, confirmPassword} = this.state;
    
    if(newUser.group_id === undefined){
      notifyAddPatientFailure(i18next.t('GROUP_IS_MANDATORY'));
    }
    else{
      if(newUser.password === confirmPassword){
        let user = Object.assign({}, newUser);
        user.birth_year = parseInt(newUser.birth_year);
        user.group_id = parseInt(newUser.group_id);
        this.setState({loading:true});
        this.props.addUser(user).then((newResult) => {
          let error = newResult?.data?.error;
          
          if(error){
            this.displayErrorMessage(error);
          }
          else{
            notifyAddPatientSuccess();
            this.setState({userFound:null, confirmPassword:'', newUser:defaultNewUserState, existingUser:defaultExistingUserState, searchEmail:'', loading:false});
          }
        }, (newError) => {
          this.setState({loading:false});
          this.displayErrorMessage(newError);
        });
      }
      else{
        notifyAddPatientFailure(i18next.t('PASSWORDS_DO_NOT_MATCH'));
      }
    }
  }
  
  displayErrorMessage(aError){
    let errorMessage = i18next.t('UNKNOWN_ERROR');
    
    if(aError && aError.data && aError.data.error){
      errorMessage = aError.data.error;
    }
    notifyAddPatientFailure(errorMessage);
  }
  
  handleChange(aEvent, aName){
    let value = aEvent.target.value;
    
    if(value === '' || value.trim()){
      if(aEvent.target.getAttribute('format') === 'number'){
        value = parseInt(aEvent.target.value);
      }
      let updatedNewUser = Object.assign(this.state.newUser, {[aName]:value});
      this.setState({newUser:updatedNewUser});
    }
  }
  
  handleSelectChange(value, key) {
    let updatedNewUser = Object.assign(this.state.newUser, {
      [key]: value
    });
    this.setState({ newUser:updatedNewUser });
  }
  
  handleExistingUserGroupSelectChange(aGroupId) {
    let existingUser = this.state.existingUser;
    existingUser.group_id = aGroupId;
    this.setState({ existingUser:existingUser });
  }
  
  handleUserEmailSearch(aEvent) {
    const {findUserByEmail} = this.props;
    let email = aEvent.target.value;
    
    if(isEmail(email)){
      if(findUserByEmail){
        findUserByEmail(email).then((newResult) => {
          let user = newResult.data.data.user;
          
          if(user && user.email && user.id){
            this.setState({userFound:true, searchingUser:false, existingUser:{email:user.email, id:user.id}});
          }
          else{
            this.setState({userFound:false, searchingUser:false, existingUser:defaultExistingUserState, newUser:{...defaultNewUserState, email}});
          }
        }, (newError) => {
          this.displayErrorMessage(newError);
          this.setState({searchingUser:false, userFound:null });
        });
        this.setState({userFound:null, searchingUser:true, searchEmail:email});
      }
    }
    else{
      this.setState({userFound:null, existingUser:defaultExistingUserState, searchEmail:'', searchingUser:false});
    }
  }
  
  csvUploadHandler = (aEvent) => {
    const file = aEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      this.setState({csvFile:file});
    }
  };
  
  csvSaveHandler(){
    const {csvFile} = this.state;
    
    if(csvFile !== null){
      this.setState({isUploadingCSV:true});
      this.props.uploadPatientsCSV(csvFile).then((newResult) => {
        notifyCSVUploadSuccess();
        this.setState({csvFile:null, isUploadingCSV:false});
      }).catch((newError) => {
        let errorMessage = i18next.t('UNKNOWN_ERROR');
        
        if(newError && newError.data && newError.data.errors && Array.isArray(newError.data.errors)){
          errorMessage = newError.data.errors.join(", ");
        }
        notifyCSVUploadError(errorMessage);
        this.setState({csvFile:null, isUploadingCSV:false});
      });
    }
  }
  
  getGroupIdValidationError = () => {
    const {newUser} = this.state;
    let returnValue = null;
    
    if(newUser && !newUser.group_id) {
      returnValue = i18next.t('GROUP_IS_MANDATORY');
    }
    return returnValue;
  };
  
  getFirstNameValidationError = () => {
    const {newUser} = this.state;
    let returnValue = null;
    
    if(newUser && !newUser.first_name) {
      returnValue = i18next.t('FIRST_NAME_REQUIRED');
    }
    return returnValue;
  }
  
  getPasswordValidationError = () => {
    const {newUser} = this.state;
    let returnValue = null;
    
    if(newUser && (!newUser.password || newUser.password.length < MINIMUM_PASSWORD_LENGTH)) {
      returnValue = i18next.t('PASSWORD_MIN_LENGTH_ERROR');
    }
    return returnValue;
  };
  
  getConfirmPasswordValidationError = () => {
    const {newUser, confirmPassword} = this.state;
    let returnValue = null;
    
    if(newUser && newUser.password && newUser.password !== confirmPassword) {
      returnValue = i18next.t('PASSWORDS_DO_NOT_MATCH');
    }
    return returnValue;
  };
  
  isInvitePatientFormValid = () => {
    const {newUser} = this.state;
    
    return !this.getGroupIdValidationError() &&
      !this.getFirstNameValidationError() &&
      !this.getPasswordValidationError() &&
      !this.getConfirmPasswordValidationError() &&
      newUser &&
      !genderOtherError(newUser.gender, newUser.gender_other);
  };
  
  shouldShowInvitePatientButton = () => {
    const {searchEmail, searchingUser, existingUser} = this.state;
    return searchEmail &&
      !searchingUser &&
      (existingUser.id || this.isInvitePatientFormValid());
  };
  
  // Render
  
  render() {
    const {company, user, patients, removeUserFromCompany, resendInviteToUser, path, t, companyInviteSearch, fetchLocales,
      getSurveys, fetchSurveyQuestions, fetchCodesForGroup, removeGroupCode, createGroupCode, downloadGroupCodeHistory} = this.props;
    const {userFound, searchingUser, newUser, existingUser, loading, tabBarKey, removeFromDefaultCompany, searchEmail, csvFile,
      confirmPassword, isUploadingCSV} = this.state;
    
    let availableYears = [];
    const currentYear = (new Date()).getFullYear();
    let availableGroups = [];
    
    for(let i = 12; i <= 100; i++){
      let year = (new Date()).getFullYear() - i;
      availableYears.push({value:year, label:year});
    }
    const availableGenders = genderOptionsArray();
    
    if(company && company.core_group){
      availableGroups.push({value: company.core_group.id, label: company.core_group.name, id: company.core_group.id, name: company.core_group.name })
    }
    company && company.groups && company.groups.forEach(grp => {
      availableGroups.push({
        id:grp.id,
        name:grp.name,
        label:grp.name,
        value:grp.id
      })
    });
    
    const currentRole = roleForCompany(company);
    
    const invitePermissions = ['admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin', 'admin_level_8', 'admin_level_10'];
    const viewPermissions = ['admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin', 'admin_level_10'];
    const importPermissions = ['admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin', 'admin_level_10'];
    
    let tabBarTabsArray = [];
    
    if(viewPermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'PATIENT_LIST'});
    }
    if(invitePermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'INVITE_PATIENT'});
    }
    if(importPermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'CSV_IMPORT'});
    }
    let listType = '';
    let showPatientList = false;
    
    if(viewPermissions.includes(currentRole)){
      if(tabBarKey === 'PATIENT_LIST'){
        listType = 'active';
        showPatientList = true;
      }
    }
    const csvData = [
      ['Email', 'First Name', 'Last Name', 'Gender', 'Birth Year', 'Password', 'EDC_ID', 'Provide Consent'],
      ['john.smith@selfcarecatalysts.com', 'John', 'Smith', 'male', '1956', 'Passw0rd', '10001', 'true'],
      ['jane.doe@selfcarecatalysts.com', 'Jane', 'Doe', 'female', '1959', 'Passw0rd', '10002', 'false'],
      ['a.n.other@selfcarecatalysts.com', 'A. N.', 'Other', 'other', '1971', 'Passw0rd', '10003', 'true']
    ];
    
    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t, { i18n }) => t('PATIENTS_PROPER_CAPITALIZED') }</Translation>}/>
        
        <TabBar tabBarTabsArray={tabBarTabsArray}
                initialSelectedTabKey={tabBarKey}
                selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}/>
        {showPatientList?
          <div className="manage-patients-patient-list-container">
            {listType === 'active' ?
              <div className="manage-patients-list-container">
                <PatientList showLink={true}
                             showAdvancedSearch={false}
                             user={user}
                             removeUserFromCompany={removeUserFromCompany}
                             company={company}
                             showHeader={false}
                             showRemove={true}
                             showEmail={true}
                             limit={15}
                             getSurveys={getSurveys}
                             fetchLocales={fetchLocales}
                             fetchSurveyQuestions={fetchSurveyQuestions}
                             fromManagePatient={true}
                             resendInviteToUser={resendInviteToUser}
                             companyInviteSearch={companyInviteSearch}
                             totalObjectsKey='TOTAL_PATIENTS'
                />
              </div>
              :
              <ManagePatientsList key={patients}
                                  type={listType}
                                  company={company}
                                  patients={patients}
                                  removeUserFromCompany={removeUserFromCompany}
                                  resendInviteToUser={resendInviteToUser}
                                  path={path}
                                  companyInviteSearch={companyInviteSearch}
              />
            }
          </div>
          :
          null
        }
        
        {tabBarKey === 'INVITE_PATIENT' ?
          <div className="manage-patient-container-invite">
            <div className="manage-patient-invite-container-text">
              <Translation>{ (t, { i18n }) => t('INVITE_PATIENT_TO_PROGRAM') }</Translation>
            </div>
            
            <LabeledDelayedInput className="manage-patient-invite-input-email-search"
                                 label={i18next.t('INVITATION_VIA_EMAIL')}
                                 value={searchEmail}
                                 minLength={6}
                                 handleSave={this.handleUserEmailSearch}
                                 placeholder={i18next.t('TYPE_AN_EMAIL_ADDRESS')}
                                 debounceTimeout={750}
            />
            
            <span className='manage-patient-no-user-container'>
              {userFound !== null ?
                userFound === true ?
                  <Translation>{(t, {i18n}) => t('EXISTING_PATIENT_FOUND')}</Translation>
                  :
                  <Translation>{(t, {i18n}) => t('USER_NOT_FOUND')}</Translation>
                :
                null
              }
              
              <Loading loading={searchingUser}/>
            </span>
  
            {userFound !== null && userFound !== true ?
              <div>
                <div className="manage-patient-invite-input-container">
                  <LabeledDropdown className="manage-patient-invite-group-input"
                                   label={i18next.t('GROUP')}
                                   value={newUser && availableGroups.filter(function(option) {
                                     return option.value === newUser.group_id;
                                   })}
                                   options={availableGroups}
                                   required={true}
                                   handleSave={(aOption) => this.handleSelectChange(aOption.value, 'group_id')}
                                   errorMessage={this.getGroupIdValidationError()}
                  />
                </div>
                
                <div className="manage-patient-invite-input-container">
                  <LabeledTextInput className="manage-patient-invite-patient-input"
                                    label={i18next.t('FIRST_NAME_PROPER_CAPITALIZED')}
                                    value={newUser.first_name}
                                    required={true}
                                    handleSave={(aEvent) => this.handleChange(aEvent, 'first_name')}
                                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                                    errorMessage={this.getFirstNameValidationError()}
                  />
                  
                  <LabeledTextInput className="manage-patient-invite-patient-input"
                                    label={i18next.t('LAST_NAME_PROPER_CAPITALIZED')}
                                    value={newUser.last_name}
                                    handleSave={(aEvent) => this.handleChange(aEvent, 'last_name')}
                                    placeholder={i18next.t('TAP_TO_ENTER_PROPER_CAPITALIZED')}
                  />
                </div>
                
                <div className="manage-patient-invite-input-container">
                    <div className={newUser.gender === 'other' ? 'manage-patient-invite-input-sub-container' : 'manage-patient-invite-patient-input'}>
                    <LabeledDropdown className={newUser.gender === 'other' ? 'manage-patient-invite-patient-input' : ''}
                                     label={i18next.t('SEX')}
                                     value={newUser && availableGenders.filter(function(option) {
                                       return option.value === newUser.gender;
                                     })}
                                     options={availableGenders}
                                     handleSave={(aOption) => this.handleSelectChange(aOption.value, 'gender')}
                    />
    
                    {newUser.gender === 'other' ?
                      <LabeledTextInput className="manage-patient-invite-patient-input manage-patient-invite-other-input"
                                        label={i18next.t('OTHER')}
                                        value={newUser.gender_other}
                                        required={true}
                                        handleSave={(aEvent) => this.handleChange(aEvent, 'gender_other')}
                                        placeholder={i18next.t('TAP_TO_TYPE')}
                                        errorMessage={genderOtherError(newUser.gender, newUser.gender_other)}
                      />
                      :
                      null
                    }
                  </div>
                  
                  <LabeledDropdown className="manage-patient-invite-patient-input"
                                   label={<Translation>{ (t, { i18n }) => t('YEAR_OF_BIRTH') }</Translation>}
                                   value={newUser && availableYears.filter(function(option) {
                                     return option.value === newUser.birth_year;
                                   })}
                                   options={availableYears}
                                   handleSave={(value) => this.handleSelectChange(value.value, 'birth_year')}
                  />
                </div>
                
                <div className="manage-patient-invite-input-container">
                  <LabeledTextInput className="manage-patient-invite-patient-input"
                                    type={'password'}
                                    label={i18next.t('PASSWORD')}
                                    value={newUser.password}
                                    required={true}
                                    handleSave={(aEvent) => this.handleChange(aEvent, 'password')}
                                    placeholder={i18next.t('AT_LEAST_EIGHT_CHARACTERS')}
                                    autoComplete="new-password"
                                    errorMessage={this.getPasswordValidationError()}
                  />
                  
                  <LabeledTextInput className="manage-patient-invite-patient-input"
                                    type={'password'}
                                    label={i18next.t('CONFIRM_PASSWORD')}
                                    value={confirmPassword}
                                    required={true}
                                    handleSave={(aEvent) => this.setState({confirmPassword:aEvent.target.value})}
                                    placeholder={i18next.t('AT_LEAST_EIGHT_CHARACTERS')}
                                    autoComplete="new-password"
                                    errorMessage={this.getConfirmPasswordValidationError()}
                  />
                </div>
              </div>
              :
              null
            }
            
            {userFound !== null && userFound === true ?
              <div className="manage-patient-invite-input-container">
                <LabeledDropdown className="manage-patient-invite-patient-input"
                                 label={<Translation>{ (t, { i18n }) => t('GROUP') }</Translation>}
                                 value={existingUser && availableGroups.filter(function(option) {
                                   return option.value === existingUser.group_id;
                                 })}
                                 options={availableGroups}
                                 handleSave={ (value) => this.handleExistingUserGroupSelectChange(value.value)}
                />
                
                <LabeledSwitch className="manage-patient-invite-patient-input"
                               label={<Translation>{ (t, { i18n }) => t('REMOVE_FROM_DEFAULT_COMPANY') }</Translation>}
                               checked={removeFromDefaultCompany}
                               onChange={(isChecked) => this.setState({removeFromDefaultCompany:isChecked})}
                               infoBlurb={<Translation>{ (t, { i18n }) => t('REMOVE_FROM_DEFAULT_COMPANY_INFO') }</Translation>}
                               defaultChecked={true}
                               checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                               unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                />
              </div>
              :
              null
            }
            
            {this.shouldShowInvitePatientButton() ?
              <div className="manage-patient-button-container">
                <BlueButton name={<Translation>{ (t, { i18n }) => t('INVITE') }</Translation>}
                            loading={loading}
                            onClick={(userFound !== null && userFound === true) ? this.inviteUserToCompany : this.addUser}
                />
              </div>
              :
              null
            }
            
            <hr style={{backgroundColor:"rgb(236, 239, 241)", marginRight:"20px"}}/>
            
            <div className="group-codes-container-text medium-blue">
              <Translation>{ (t, { i18n }) => t('INVITE_CODE') }</Translation>
            </div>
            
            <div className="patient-group-search-results-list">
              <GroupCodesList company={company}
                              groupList={availableGroups}
                              createGroupCode={createGroupCode}
                              removeGroupCode={removeGroupCode}
                              fetchCodesForGroup={fetchCodesForGroup}
                              downloadGroupCodeHistory={downloadGroupCodeHistory}
              />
            </div>
          </div>
          :
          null
        }
        
        {tabBarKey === 'CSV_IMPORT' ?
          <div className="manage-patient-container-import">
            <div className="manage-patient-import-csv-text">
              <Translation>{ (t, { i18n }) => t('CSV_IMPORT_DESCRIPTION') }</Translation>
              
              <CSVLink style={{color:'#428bca'}}
                       data={csvData}
                       uFEFF={false}
                       target='_blank'
                       filename={'example_patient_import_format.csv'}
                       enclosingCharacter={''}>
                <Translation>{ (t, { i18n }) => t('DOWNLOAD_EXAMPLE_CSV_FORMAT') }</Translation>
              </CSVLink>
            </div>
            
            <br/>
            
            <div className="manage-patient-import-title">
              <Translation>{ (t, { i18n }) => t('IMPORT_CSV') }</Translation>
            </div>
            
            <LabeledTextInput className="manage-patient-import-csv-input"
                              placeholder={i18next.t('INPUT_PLACEHOLDER')}
                              disabled={true}
                              value={csvFile !== null ? csvFile.name : ''}
            />
            
            <div className="manage-patient-import-button-container">
              {csvFile !== null ?
                <BlueButton name={<Translation>{(t, {i18n}) => t('UPLOAD')}</Translation>}
                            onClick={() => this.csvSaveHandler()}
                            disabled={isUploadingCSV}
                />
                :
                <BlueButton name={<Translation>{(t, {i18n}) => t('SELECT')}</Translation>}
                            onClick={() => this.hiddenFileInputRef.current.click()}
                />
              }
            </div>
            
            <input style={{display:'none'}}
                   ref={this.hiddenFileInputRef}
                   type="file"
                   accept=".csv"
                   onChange={(aEvent) => this.csvUploadHandler(aEvent)}
            />
          </div>
          :
          null
        }
        <div className="manage-patients-bottom-margin">
          <div className="manage-patients-bottom-text">
            Health StoryLines Creator
          </div>
          
          <img src="https://images.squarespace-cdn.com/content/5ac390a74eddeca15cdd719b/1524254970148-SB1AJPKHFRZNJJZ1F4N1/HSCreator_logo_100.png?content-type=image%2Fpng" alt='HSC Logo'
               height={40}
               width={40}
          />
          
          <div className="manage-patients-bottom-text">
            {currentYear} Self Care Catalysts
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ManagePatients)
