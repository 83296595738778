import React from 'react';
import {Translation} from 'react-i18next';
import {BiDotsVerticalRounded} from "react-icons/bi";
import i18next from "i18next";

export default class MedicationCell extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  // Methods
  
  formatTime = (aTime) => {
    let returnValue = '';
    
    if(aTime){
      let hour = 0;
  
      if(aTime.length === 3){
        hour = aTime.substring(0, 1)
      }
      else if(aTime.length === 4){
        hour = aTime.substring(0, 2)
      }
      let ampm = hour < 12 ? 'am' : 'pm';
      hour = hour % 12 || 12;
      let minutes = aTime.slice(-2)
  
      if(hour === 12 && minutes === '0'){
        returnValue = '12:00 am'
      }
      else{
        returnValue = `${hour}:${minutes} ${ampm}`
      }
    }
    return returnValue;
  }
  
  dayNumberForReminder = (aReminder) => {
    let returnValue = '';
    
    if(aReminder.repeat_type === 'weekly'){
      const daysOfTheWeek = [
        i18next.t('MONDAYS'),
        i18next.t('TUESDAYS'),
        i18next.t('WEDNESDAYS'),
        i18next.t('THURSDAYS'),
        i18next.t('FRIDAYS'),
        i18next.t('SATURDAYS'),
        i18next.t('SUNDAYS')
      ];
      returnValue = '- ' + daysOfTheWeek[aReminder.day_number - 1];
    }
    else if(aReminder.repeat_type === 'monthly'){
      returnValue = '- ' + aReminder.day_number;
    }
    else if(aReminder.repeat_type === 'interval'){
      returnValue = i18next.t('EVERY_DAYS', { reminder: aReminder });
    }
    return returnValue;
  }
  
  // Render
  
  render(){
    const { medication, menuButtonClicked } = this.props;
    
    return (
      <div className='medications-list-item'>
        <div style={{marginRight: 10}}>
          <div className='medications-type'>{medication.medication.name}</div>
          
          <div className='medications-reminders'>
            {medication.reminders && medication.reminders.length !== 0 ?
              <>
                {medication.reminders.map((reminder, index) => (
                  <span key={reminder.id}
                        className='list-item-name list-item-additional-info'>
                  &nbsp;{reminder.repeat_type && (reminder.repeat_type.charAt(0).toUpperCase() + reminder.repeat_type.slice(1))} {this.dayNumberForReminder(reminder)} @ {this.formatTime(reminder.time)}{index < (medication.reminders.length - 1) ? "," : ""}
                </span>
                ))}
              </>
              :
              <span className='list-item-name list-item-additional-info'>
              &nbsp;
                <Translation>{(t, {i18n}) => t('AS_NEEDED_NO_REMINDER')}</Translation>
            </span>
            }
          </div>
        </div>
        <BiDotsVerticalRounded style={{fontSize: '21px'}}
                               onClick={(aEvent) => menuButtonClicked(aEvent)}
        />
      </div>
    );
  }
}