import React from 'react';
import './AddPatientNoteModal.scss'
import '../../Custom UI/modalTitleCell.scss';
import "antd/dist/antd.css";
import "../GeneralModal/GeneralModal.scss"
import GeneralModal from "../GeneralModal/GeneralModal";
import {Translation} from "react-i18next";
import i18n from "../../i18n";
import LabeledTextarea from "../../Custom UI/LabeledInputs/LabeledTextarea/LabeledTextarea";

export default class AddPatientNoteModal extends React.Component {
  
  // Init

  constructor(props){
    super(props);
    this.state = {
      description:this.props.description
    };
    this.handleSave = this.handleSave.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.description !== this.props.description || (prevProps.isOpen === false && this.props.isOpen)){
      this.setState({description:this.props.description});
    }
  }
  
  // Methods

  handleSave(){
    const {description} = this.state;
    const {noteId, addNote, updateNote} = this.props;
    
    if(noteId && updateNote){
      updateNote({description:description, noteId:noteId});
    }
    else if(addNote){
      addNote({description:description});
    }
    else{
      console.log('AddPatientNoteModal (handleSave): Missing props assignNoteCallback and updateNoteCallback.');
    }
  }
  
  // Render

  render() {
    const {name, close, isOpen, noteId, errorMessage} = this.props;
    const {description} = this.state;

    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('PATIENT_NOTE') }</Translation>}
                    isOpen={isOpen}
                    maxHeight={700}
                    isEditing={Boolean(noteId)}
                    handleSave={() => this.handleSave()}
                    showSaveButton={description && description.length > 0}
                    handleCloseModal={close}>
        <div className="modal-subtitle-text">
          {name}
        </div>
        
        {errorMessage ?
          <p className='error-message'>
            {errorMessage}
          </p>
          :
          null
        }
        
        <LabeledTextarea className="modal-input-textarea"
                         value={description}
                         handleSave={(aEvent) => this.setState({description:aEvent.target.value})}
                         placeholder={i18n.t('ADD_DESCRIPTION_HERE')}
        />
      </GeneralModal>
    )
  }
}
