import React from 'react';
import "antd/dist/antd.css";
import './GroupModal.scss';
import {Translation} from "react-i18next";
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyGroupUpdateError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_UPDATING_GROUP', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyGroupUpdateSuccess = (aGroupName) => toast(<Translation>{ (t, { i18n }) => t('GROUP_UPDATED_SUCCESS', {groupName:aGroupName}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class GroupModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      groupName:'',
      groupLocale:'en'
    };
  }
  
  componentDidMount(){
    if(this.props.group){
      this.setState({groupName:this.props.group.name, groupLocale:this.props.group.locale});
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.group !== this.props.group && this.props.group){
      this.setState({groupName:this.props.group.name, groupLocale:this.props.group.locale});
    }
  }
  
  // Methods
  
  updateGroup = () => {
    this.props.updateGroup(this.props.group.id, this.state.groupName, this.state.groupLocale).then((newResponse) => {
      let group = newResponse.data.data.updateGroup.group;
      notifyGroupUpdateSuccess(group.name);
      this.props.handleCloseModal(group);
    }, (aError) => {
      notifyGroupUpdateError(aError);
    });
  };
  
  // Render
  
  render() {
    let { groupName, groupLocale } = this.state;
    let { isOpen, handleCloseModal } = this.props;
    
    const availableLanguages = [
      {languageString:(i18next.t('ENGLISH_WITH_FLAG')), languageCode:'en'},
      {languageString:(i18next.t('ITALIAN_WITH_FLAG')), languageCode:'it'},
      {languageString:(i18next.t('FRENCH_WITH_FLAG')), languageCode:'fr'},
      {languageString:(i18next.t('GERMAN_WITH_FLAG')), languageCode:'de'},
      {languageString:(i18next.t('SPANISH_WITH_FLAG')), languageCode:'es'}
    ];
    
    let languageOptions = [];
    
    availableLanguages.map((aLanguage, index) => (
      languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
    ));
    
    return (
      <GeneralModal title={<span>{groupName}</span>}
                    isOpen={isOpen}
                    isEditing={true}
                    showSaveButton={true}
                    showCancelButton={true}
                    handleSave={() => this.updateGroup()}
                    containsDropDown={true}
                    handleCloseModal={() => handleCloseModal(null)}>
          <LabeledTextInput className="modal-input"
                            label={<Translation>{ (t, { i18n }) => t('NAME')}</Translation>}
                            value={groupName}
                            handleSave={(aEvent) => this.setState({groupName:aEvent.target.value})}
                            placeholder={i18next.t('NAME')}
          />
          
          <LabeledDropDown className="modal-input"
                           label={<Translation>{ (t, { i18n }) => t('LOCALE')}</Translation>}
                           value={groupLocale && languageOptions.filter(function(option){
                             return option.value === groupLocale;
                           })}
                           options={languageOptions}
                           handleSave={(aOption) => {this.setState({groupLocale:aOption.value})}}
          />
      
      </GeneralModal>
    )
  }
}
