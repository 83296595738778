import React from "react";
import i18next from "i18next";
import SignaturePad from 'react-signature-canvas';
import {Translation} from "react-i18next";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextInput from "../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {localDataURLtoBlob} from "../../../Helpers/Helpers";
import {momentFromDateTime} from "../../../Helpers/DateHelpers";

export default class SurveyEConsentQuestionCell extends React.Component{
  
  // Instance Variables
  
  signaturePadRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      file:null,
      imageUrl:null,
      lastName:'',
      firstName:'',
      isEditing:false,
      signature:null,
      isSubmitted:false,
      relationship:'',
      acceptedTerms:false
    };
    if(props.answer != null){
      if(props.answer.value != null){
        this.state.imageUrl = props.answer.value;
      }
      if(props.answer.file != null){
        this.state.file = props.answer.file;
        this.state.imageUrl = URL.createObjectURL(this.state.file);
      }
      if(props.answer.prettyValues){
        let parsedValues = {};
        props.answer.prettyValues.forEach(value => {
          let keys = Object.keys(value);
          
          if(keys && keys.length > 0){
            keys.forEach(key => {
              parsedValues[key] = value[key];
            });
          }
        });
        this.state.lastName = parsedValues['last_name'];
        this.state.firstName = parsedValues['first_name'];
        this.state.relationship = parsedValues['relationship'];
        this.state.acceptedTerms = parsedValues['accepted_terms'];
      }
    }
    this.showField = this.showField.bind(this);
    this.fieldRequired = this.fieldRequired.bind(this);
    this.controlsDisabled = this.controlsDisabled.bind(this);
    this.clearButtonClicked = this.clearButtonClicked.bind(this);
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
    this.eConsentFieldIsFilledIn = this.eConsentFieldIsFilledIn.bind(this);
    this.eConsentQuestionIsEditable = this.eConsentQuestionIsEditable.bind(this);
  }
  
  // Methods
  
  canSubmit(){
    let returnValue = ((this.signaturePadRef && !this.signaturePadRef.isEmpty())|| (this.state.imageUrl && this.state.imageUrl.length > 0));
    
    if(this.fieldRequired('agreement')){
      returnValue = returnValue && this.state.acceptedTerms;
    }
    if(this.fieldRequired('first_name')){
      returnValue = returnValue && (this.state.firstName && this.state.firstName.length > 0);
    }
    if(this.fieldRequired('last_name')){
      returnValue = returnValue && (this.state.lastName && this.state.lastName.length > 0);
    }
    if(this.fieldRequired('relationship')){
      returnValue = returnValue && (this.state.relationship && this.state.relationship.length > 0);
    }
    return returnValue;
  }
  
  submitButtonClicked(){
    if(this.canSubmit() && !this.state.isSubmitted){
      let image = localDataURLtoBlob(this.signaturePadRef.getCanvas().toDataURL('image/png'));
      this.setState({signature:image, isSubmitted:true});
      let valuesArray = [];
      valuesArray[0] = {key:'last_name', value:this.state.lastName};
      valuesArray[1] = {key:'first_name', value:this.state.firstName};
      valuesArray[2] = {key:'accepted_terms', value:this.state.acceptedTerms.toString()};
      valuesArray[3] = {key:'relationship', value:this.state.relationship};
      valuesArray[4] = {key:'question_label', value:this.props.question.unparsed_label};
      this.props.onAnswerChange(this.props.question, this.state.imageUrl, valuesArray, null, null, false, image);
    }
  }
  
  clearButtonClicked(){
    this.setState({imageUrl:null});
    this.signaturePadRef.clear();
  }
  
  updateSignatureOnOffState(){
    if(this.signaturePadRef && this.signaturePadRef.off && this.signaturePadRef.on){
      if(this.controlsDisabled()){
        this.signaturePadRef.off();
      }
      else{
        this.signaturePadRef.on();
      }
    }
  }
  
  showField(aField){
    const {question} = this.props;
    let returnValue = this.fieldRequired(aField);
    
    if(question && question.extra_questions){
      returnValue = returnValue || question.extra_questions.includes(aField);
    }
    return returnValue;
  }
  
  fieldRequired(aField){
    const {question} = this.props;
    let returnValue = false;
    
    if(question && question.required_questions){
      returnValue = question.required_questions.includes(aField);
    }
    return returnValue;
  }
  
  setSignatureRef = (aRef) => {
    this.signaturePadRef = aRef;
    this.updateSignatureOnOffState();
  };
  
  eConsentFieldIsFilledIn(aQuestion, aAnswer, aFieldName, aValueName){
    let returnValue = false;
    
    if(aQuestion.required_questions.includes(aFieldName)){
      if(aAnswer.values[aValueName].length > 0 || Boolean(aAnswer.values[aValueName])){
        returnValue = true;
      }
    }
    else{
      returnValue = true;
    }
    return returnValue;
  }
  
  eConsentQuestionIsEditable(aQuestion, aAnswer){
    let returnValue = true;
    
    if(aQuestion && aQuestion.surveyResponseDetailId){
      if(aAnswer.value){
        if(aAnswer.values['first_name'].length > 0){
          if(aAnswer.values['last_name'].length > 0){
            if(this.eConsentFieldIsFilledIn(aQuestion, aAnswer, 'relationship', 'relationship')){
              if(this.eConsentFieldIsFilledIn(aQuestion, aAnswer, 'agreement', 'accepted_terms')){
                returnValue = false;
              }
            }
          }
        }
      }
    }
    return returnValue;
  };
  
  controlsDisabled(){
    const {answer, disabled, question} = this.props;
    const {isEditing, imageUrl} = this.state;
    const imageUrlExists = (!isEditing ? imageUrl : null);
    return (disabled || (question && question.type === 'econsent') || imageUrlExists || !this.eConsentQuestionIsEditable(question, answer));
  }
  
  // Render
  
  render(){
    const {size, answer, question} = this.props;
    const {lastName, firstName, isEditing, isSubmitted, acceptedTerms} = this.state;
    const {key, type} = question;
    
    this.updateSignatureOnOffState();
    
    let relationship = this.state.relationship;
    let relationshipOptions = [
      {label:(i18next.t('WITNESS')), value:'witness'},
      {label:(i18next.t('GUARDIAN')), value:'guardian'},
      {label:(i18next.t('PHYSICIAN')), value:'physician'},
      {label:(i18next.t('PARENT')), value:'parent'},
      {label:(i18next.t('CARETAKER')), value:'caretaker'},
      {label:(i18next.t('LAWYER')), value:'lawyer'},
      {label:(i18next.t('OTHER')), value:'other'}
    ];
    
    let imageUrl = (!isEditing ? this.state.imageUrl : null);
    let createdAt = answer.created_at;
    const disableControls = this.controlsDisabled();
    
    let canvasWidth = '390px';
    let canvasHeight = '117px';
    
    if(size === 'large'){
      canvasWidth = '500px';
      canvasHeight = '150px';
    }
    
    return(
      <div className="survey-question-econsent-cell"
           key={'survey-' + type + '-question-cell-' + key}>
        {createdAt ?
          <LabeledTextInput className="settings-text-input-large"
                            label={<Translation>{(t, {i18n}) => t('DATE_AND_TIME')}</Translation>}
                            value={momentFromDateTime(createdAt)}
                            disabled={true}
          />
          :
          null
        }
        
        {this.showField('first_name') ?
          <LabeledTextInput className="settings-text-input-large"
                            label={<Translation>{(t, {i18n}) => t('FIRST_NAME_PROPER_CAPITALIZED')}</Translation>}
                            value={firstName}
                            disabled={disableControls}
                            required={this.fieldRequired('first_name')}
                            handleSave={(aEvent) => this.setState({firstName: aEvent.target.value})}
          />
          :
          null
        }
        
        {this.showField('last_name') ?
          <LabeledTextInput className="settings-text-input-large"
                            value={lastName}
                            disabled={disableControls}
                            required={this.fieldRequired('last_name')}
                            handleSave={(aEvent) => this.setState({lastName: aEvent.target.value})}
                            label={<Translation>{(t, {i18n}) => t('LAST_NAME_PROPER_CAPITALIZED')}</Translation>}
          />
          :
          null
        }
        
        {this.showField('relationship') ?
          <LabeledDropDown className="settings-text-input-large"
                           label={<Translation>{ (t, { i18n }) => t('RELATIONSHIP') }</Translation>}
                           disabled={disableControls}
                           required={this.fieldRequired('relationship')}
                           value={relationship && relationshipOptions.filter(function(option){
                             return option.value === relationship;
                           })}
                           options={relationshipOptions}
                           handleSave={(aOption) => this.setState({relationship:aOption.value})}
          />
          :
          null
        }
        
        <div className="survey-question-text-title">
          <Translation>{ (t, { i18n }) => t('SIGNATURE')}</Translation>
        </div>
        
        {imageUrl ?
          <div className="survey-question-signature-container">
            <img src={imageUrl} className="survey-signature-pad-image" alt={'signature'} style={{width:canvasWidth, height:canvasHeight}}/>
          </div>
          :
          <SignaturePad canvasProps={{className:'survey-signature-pad', style:{width:canvasWidth, height:canvasHeight}}}
                        ref={this.setSignatureRef}
          />
        }
        
        {/*TODO: Add required for checkbox label.*/}
        {this.showField('agreement') ?
          <label className={'custom-input-cell survey-signature-checkbox-container'}
                 htmlFor={`e_consent_accept_terms_${question.key}`}>
            <input type={'checkbox'}
                   id={`e_consent_accept_terms_${question.key}`}
                   disabled={disableControls}
                   onChange={() => this.setState({acceptedTerms: !this.state.acceptedTerms})}
                   checked={acceptedTerms}
            />
            <span className={'custom-checkmark'}/>
            
            <div className={'inline-text'}>
              <Translation>{(t, {i18n}) => t('ACCEPT_TERMS')}</Translation>
            </div>
          </label>
          :
          null
        }
        
        {!disableControls ?
          <div className="survey-signature-button-container">
            <button className={'survey-button survey-signature-button'}
                    disabled={disableControls}
                    onClick={this.clearButtonClicked}>
              <Translation>{(t, {i18n}) => t('CLEAR')}</Translation>
            </button>
            <button className={'survey-button green-button survey-signature-button'}
                    disabled={disableControls}
                    onClick={imageUrl ? () => this.setState({isEditing:true}) : this.submitButtonClicked}>
              {imageUrl ?
                <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
                :
                (isSubmitted ?
                    <Translation>{(t, {i18n}) => t('SUBMITTED')}</Translation>
                    :
                    <Translation>{(t, {i18n}) => t('SUBMIT_PROPER_CAPITALIZED')}</Translation>
                )
              }
            </button>
          </div>
          :
          null
        }
      </div>
    )
  }
}
