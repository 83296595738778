import React from "react";
import './SurveyBuilderRankQuestionCell.scss';
import Menu from "@material-ui/core/Menu";
import {toast} from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import cloneDeep from "lodash/cloneDeep";
import {NOTIFY_OPTS} from "../../../../constants/Notifiers";
import {Translation} from "react-i18next";
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {BiDotsVerticalRounded} from "react-icons/bi";
import SurveyBuilderChoices from "../../../../Components/SurveyBuilder/SurveyBuilderChoices/SurveyBuilderChoices";

const notifyCannotRemoveChoice = () =>  toast(<Translation>{ (t, { i18n }) => t('REMOVE_CHOICE_ERROR') }</Translation>, NOTIFY_OPTS.autoClose);

export default class SurveyBuilderRankQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      questionId:-1,
      numberOfChoices:1,
      menuAnchorElement:null,
      selectedChoiceIndex:-1
    };
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.deleteChoiceAtIndex = this.deleteChoiceAtIndex.bind(this);
  }
  
  // Methods
  
  menuButtonClicked(aEvent, aIndex){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedChoiceIndex:aIndex});
  };
  
  deleteChoiceAtIndex(aIndex){
    const {question, updateQuestion} = this.props;
    
    if(question && question.choices && aIndex < question.choices.length){
      if(question.choices.length === 1){
        notifyCannotRemoveChoice();
      }
      else{
        let updatedQuestion = cloneDeep(question);
        updatedQuestion.choices.splice(aIndex, 1);
        updateQuestion(updatedQuestion);
      }
    }
    this.setState({menuAnchorElement:null, selectedChoiceIndex:-1});
  }
  // Render
  
  render(){
    const {question, updateQuestion, selectedLanguage, translationsArray, errorForIdTypeAndKey} = this.props;
  
    // const {menuAnchorElement, selectedChoiceIndex} = this.state;
    //
    // let choicesCount = 0;
    //
    // if(question && question.choices){
    //   choicesCount = question.choices.length;
    // }
    
    return(
      <div className="survey-builder-rank-choices-cell">
        <SurveyBuilderChoices question={question}
                              showExclusive={false}
                              updateQuestion={updateQuestion}
                              selectedLanguage={selectedLanguage}
                              translationsArray={translationsArray}
                              errorForIdTypeAndKey={errorForIdTypeAndKey}
        />
        
        {/*<b>*/}
        {/*  <Translation>{(t, { i18n }) => t('CHOICES')}</Translation> - {choicesCount}*/}
        {/*</b>*/}
        
        {/*{choicesCount > 0 ?*/}
        {/*  <div>*/}
        {/*    {question.choices.map((choice, index) => (*/}
        {/*      <div key={'radio-question-cell-' + choice.value + '-' + index}*/}
        {/*           className="survey-builder-rank-choices-input-container">*/}
        {/*        <LabeledDelayedInput className="survey-builder-rank-input"*/}
        {/*                             label={<Translation>{ (t, { i18n }) => t('CHOICE_LABEL') }</Translation>}*/}
        {/*                             value={question.choices[index].label}*/}
        {/*                             infoBlurb={<Translation>{(t, { i18n }) => t('CHOICE_LABEL_EXPLANATION')}</Translation>}*/}
        {/*                             minLength={1}*/}
        {/*                             handleSave={(aEvent) => {*/}
        {/*                               let updatedQuestion = cloneDeep(question);*/}
        {/*                               updatedQuestion.choices[index].label = aEvent.target.value;*/}
        {/*                               updateQuestion(updatedQuestion);*/}
        {/*                             }}*/}
        {/*                             warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'choice-label-' + index).message}*/}
        {/*        />*/}
        {/*  */}
        {/*        <LabeledDelayedInput className="survey-builder-rank-input"*/}
        {/*                             label={<Translation>{ (t, { i18n }) => t('CHOICE_VALUE') }</Translation>}*/}
        {/*                             value={question.choices[index].value}*/}
        {/*                             infoBlurb={<Translation>{(t, { i18n }) => t('CHOICE_VALUE_EXPLANATION')}</Translation>}*/}
        {/*                             minLength={1}*/}
        {/*                             handleSave={(aEvent) => {*/}
        {/*                               let updatedQuestion = cloneDeep(question);*/}
        {/*                               updatedQuestion.choices[index].value = aEvent.target.value;*/}
        {/*                               updateQuestion(updatedQuestion);*/}
        {/*                             }}*/}
        {/*                             warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'choice-value-' + index).message}*/}
        {/*        />*/}
        {/*  */}
        {/*        <BiDotsVerticalRounded style={{fontSize: '21px'}}*/}
        {/*                               className="survey-builder-rank-menu-button"*/}
        {/*                               onClick={(aEvent) => this.menuButtonClicked(aEvent, index)}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  :*/}
        {/*  null*/}
        {/*}*/}
        
        {/*<div className="survey-builder-rank-add-button"*/}
        {/*     onClick={(aEvent) => {*/}
        {/*       aEvent.preventDefault();*/}
        {/*       aEvent.stopPropagation();*/}
        
        {/*       if(question){*/}
        {/*         let updatedQuestion = cloneDeep(question);*/}
        
        {/*         if(!updatedQuestion.choices){*/}
        {/*           updatedQuestion.choices = [];*/}
        {/*         }*/}
        {/*         updatedQuestion.choices.push({label:'', value:''});*/}
        {/*         updateQuestion(updatedQuestion);*/}
        {/*       }*/}
        {/*     }}>*/}
        {/*  <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>*/}
        
        {/*  <Translation>{(t, {i18n}) => t('ADD_CHOICE')}</Translation>*/}
        {/*</div>*/}
        
        {/*<Menu id="survey-builder-rank-question-cell-menu"*/}
        {/*      open={Boolean(menuAnchorElement)}*/}
        {/*      onClose={() => this.setState({menuAnchorElement:null, selectedChoiceIndex:-1})}*/}
        {/*      anchorEl={menuAnchorElement}*/}
        {/*      keepMounted>*/}
        {/*  <MenuItem style={{position:'relative'}}*/}
        {/*            onClick={(aEvent) => {*/}
        {/*              aEvent.preventDefault();*/}
        {/*              aEvent.stopPropagation();*/}
        {/*              this.deleteChoiceAtIndex(selectedChoiceIndex);*/}
        {/*            }}>*/}
        {/*    <Translation>{ (t, { i18n }) => t('REMOVE')}</Translation>*/}
        {/*  </MenuItem>*/}
        {/*</Menu>*/}
      </div>
    )
  }
}
