import { connect } from 'react-redux';
import actions from 'actions';
import ManageGroups from 'Pages/ManageGroups/ManageGroups'

const mapStateToProps = (state, ownProps)=> {
  let patients = state.patients && state.patients.patients;
  let groups = state.company && state.company.company && state.company.company.groups;
  let company = state.company && state.company.company;

  return {
    patients,
    company,
    groups
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addGroup:(aName, aUserIds, aLocale) => {
      return dispatch(actions.addGroup(aName, aUserIds, aLocale)).then(result => {
        dispatch(actions.fetchCompanyAndPatients({}))
      })
    },
    removeGroup: (groupId) => {
      return dispatch(actions.removeGroup(groupId)).then(result => {
        dispatch(actions.fetchCompanyAndPatients({}))
      })
    },
    removeUserFromGroup: (groupId, userId) => {
      return dispatch(actions.removeUserFromGroup({groupId, userId})).then(result => {
        dispatch(actions.fetchCompanyAndPatients({}))
      })
    },
    addUserToGroup: ({groupId, userId}) => {
      return dispatch(actions.addUserToGroup({groupId, userId}))
    },
    updateGroup:(aGroupId, aName, aLocale) => {
      return dispatch(actions.updateGroup(aGroupId, aName, aLocale));
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ManageGroups)
