import React, {useState} from 'react';
import {Switch} from 'antd';
import {Translation, withTranslation} from "react-i18next";
import LabeledInfo from 'Custom UI/LabeledInputs/LabeledInfo/LabeledInfo';
import CloseStudyMessage from 'Components/CloseStudyMessage/CloseStudyMessage';
import CloseStudyConfirmationModal from 'Modals/CloseStudyModals/CloseStudyConfirmationModal';

const CloseStudy = (props) => {

  // Instance Variables

  const [startTimer, setStartTimer] = useState(false);
  const [checked, setChecked] = useState(props.studyClosed);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [closeStudyFailed, setCloseStudyFailed] = useState(false);
  const [closeStudyErrorMessage, setCloseStudyErrorMessage] = useState(null);
  const [openStudyConfirmationModal, setOpenStudyConfirmationModal] = useState(false);

  // Methods

  const closeStudy = (aCloseoutSignature, aAdminEmail, aAdminPassword) => {
    const {closeStudy} = props;

    if(closeStudy){
      closeStudy(aCloseoutSignature, aAdminEmail, aAdminPassword).then(() => {
        handleCloseStudyConfirmationModal();
        showFinalMessage();
      }, (newError) => {
        handleCloseStudyConfirmationModal();
        showErrorMessage(newError.networkError?.result?.error);
      });
    }
  };

  const showErrorMessage = (aError) => {
    
    if(isIncorrectCredentialsError(aError)){
      setCloseStudyErrorMessage('INCORRECT_CREDENTIALS')
    } else {
      setCloseStudyErrorMessage('CLOSE_STUDY_TRY_AGAIN')
    }

    setCloseStudyFailed(true);
    setOpenMessageModal(true);

    setTimeout(() => {
      if(isIncorrectCredentialsError(aError)){
        setChecked(true);
        setOpenStudyConfirmationModal(true);
      }
      setOpenMessageModal(false);
      setShowConfirmation(false);
    }, 3000);
  };

  const showFinalMessage = () => {
    setCloseStudyFailed(false);
    setOpenMessageModal(true);
    setStartTimer(true);
  };

  const handleCloseStudySwitch = (checked, event) => {
    setChecked(checked);
    setShowConfirmation(checked);
    setOpenStudyConfirmationModal(true);
  };

  const handleCloseStudyConfirmationModal = () => {
    setShowConfirmation(false);
    setOpenStudyConfirmationModal(false);
    setChecked(false);
  };

  const isIncorrectCredentialsError = (aError) => {
    return aError === 'Incorrect Credentials'
  }

  return (
    <div className="close-study">
      <div className="close-study-separator" />

      <div className="close-study-content">
        <Translation>{(t, {i18n}) => t('CLOSE_STUDY_CONTENT_1')}</Translation>
        
        <br />

        <Translation>{(t, {i18n}) => t('CLOSE_STUDY_CONTENT_2')}</Translation>
      </div>

      <div className="close-study-switch">
        <LabeledInfo bold={true}
                     popoverPlacement={"right"}
                     label={<Translation>{(t, {i18n}) => t('CLOSE_STUDY')}</Translation>}
                     infoBlurb={<Translation>{(t, {i18n}) => t('CLOSE_STUDY_INFO')}</Translation>}
        />

        <Switch checked={checked}
                disabled={props.studyClosed}
                onChange={handleCloseStudySwitch}
                checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
        />
      </div>

      {openStudyConfirmationModal ?
        <CloseStudyConfirmationModal isOpen={openStudyConfirmationModal}
                                     closeStudy={closeStudy}
                                     showConfirmation={showConfirmation}
                                     handleCloseModal={handleCloseStudyConfirmationModal}
        />
      :
      null
      }

      {openMessageModal ?
        <CloseStudyMessage isOpen={openMessageModal}
                           closeStudyFailed={closeStudyFailed}
                           errorMessage={closeStudyErrorMessage}
                           startTimer={startTimer}
                           logout={props.logout}
        />
        :
        null
      }
    </div>
  );
};

export default withTranslation()(CloseStudy);