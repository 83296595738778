import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table } from "semantic-ui-react";
import { DATA_TYPES, LABELNAMES } from "./constants";
// import { SAMPLE_DATA_POINTS } from './testConstants' // Only for testing remove when other data points are received TO DO: Remove when feature is tested with real data
import { Line } from "react-chartjs-2";
import { orderBy } from "lodash";
import "./VitalFloDataPointDetail.scss";

const GRAPH_TYPES = {
  flowVtime: "Flow/Time",
  flowVvolume: "Flow/Volume",
  volumeVtime: "Volume/Time",
};

const buildDetailChart = (selectedDataPoints, selectedGraph) => {
  let xAxes =
    selectedGraph !== GRAPH_TYPES.flowVvolume
      ? [
          {
            type: "time",
            time: {
              unit: "second",
              displayFormats: {
                second: "s",
              },
            },
          },
        ]
      : [
          {
            type: "linear",
          },
        ];
  return (
    <div key={selectedGraph}>
      <Line
        data={{
          datasets: [
            {
              borderColor: "#2D81C9",
              fill: false,
              data: selectedDataPoints,
              pointRadius: 0,
            },
          ],
        }}
        height={300}
        options={{
          elements: {
            line: {
              borderJoinStyle: "round",
            },
          },
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                display: true,
              },
            ],
            xAxes,
          },
        }}
      />
    </div>
  );
};

const timeZone = moment.tz.guess();

const formatDataPointDate = (dateString) =>
  moment(new Date(dateString))
    .tz(timeZone || "America/New_York")
    .format("MM/D @ hh:mm A z");

let navElementStyle = {
  padding: "6px 15px",
  cursor: "pointer",
  border: "solid 1px #2d81c9",
  fontWeight: "bold",
  height: "fit-content",
};

const VitalFloDataPointDetail = ({ dataPoint }) => {
  const [selectedGraph, setSelectedGraph] = useState(GRAPH_TYPES.flowVtime);
  const [categorizedDataPoints, setCategorizedDataPoints] = useState(null);

  useEffect(() => {
    async function loadSyncedDevicesData({ spirometrySessionId }) {
      let query = `
      query getVitalFloFlowPoints ($spirometrySessionId: Int!) {
        vital_flo_flow_points (spirometry_session_id: $spirometrySessionId) {
          id
          time
          value
          volume
          dbdate
          spirometry
        }
      }
      `;

      let variables = { spirometrySessionId };
      global.api.post("/queries", { query, variables }).then((result) => {
        let rawDataPoints = result?.data?.data?.vital_flo_flow_points; // || SAMPLE_DATA_POINTS TO DO: Remove when feature is tested with real data

        if (rawDataPoints && rawDataPoints.length > 0) {
          let flowVTimePoints = orderBy(
            rawDataPoints.map((el) => ({
              x: moment(new Date(el.time * 1000)),
              y: el.value,
            })),
            ["x"]
          );

          let volumeVtimePoints = orderBy(
            rawDataPoints.map((el) => ({
              x: moment(new Date(el.time * 1000)),
              y: el.volume,
            })),
            ["x"]
          );

          let flowVvolumePoints = orderBy(
            rawDataPoints.map((el) => ({
              x: el.volume,
              y: el.value,
            })),
            ["x"]
          );

          setCategorizedDataPoints({
            [`${GRAPH_TYPES.flowVtime}`]: flowVTimePoints,
            [`${GRAPH_TYPES.volumeVtime}`]: volumeVtimePoints,
            [`${GRAPH_TYPES.flowVvolume}`]: flowVvolumePoints,
          });
        } else {
          setCategorizedDataPoints(null);
        }
      });
    }

    loadSyncedDevicesData({ spirometrySessionId: dataPoint.id });
  }, [dataPoint.id]);

  return (
    <div className="flex-column">
      <div className="response-date" key={dataPoint.dbdate}>
        {formatDataPointDate(dataPoint.dbdate)}
      </div>
      {categorizedDataPoints && (
        <div style={{ padding: 20 }}>
          <div className="flex-row">
            <p
              className={`firstVitalFloNavItem baseVitalFloDetailNavItem ${
                selectedGraph === GRAPH_TYPES.flowVtime
                  ? "selectedVitalFlowDetailNavItem"
                  : "unSelectedVitalFlowDetailNavItem"
              }`}
              onClick={() => setSelectedGraph(GRAPH_TYPES.flowVtime)}
            >
              {GRAPH_TYPES.flowVtime}
            </p>
            <p
              className={`baseVitalFloDetailNavItem ${
                selectedGraph === GRAPH_TYPES.volumeVtime
                  ? "selectedVitalFlowDetailNavItem"
                  : "unSelectedVitalFlowDetailNavItem"
              }`}
              style={navElementStyle}
              onClick={() => setSelectedGraph(GRAPH_TYPES.volumeVtime)}
            >
              {GRAPH_TYPES.volumeVtime}
            </p>
            <p
              className={`lastVitalFloNavItem baseVitalFloDetailNavItem ${
                selectedGraph === GRAPH_TYPES.flowVvolume
                  ? "selectedVitalFlowDetailNavItem"
                  : "unSelectedVitalFlowDetailNavItem"
              }`}
              style={navElementStyle}
              onClick={() => setSelectedGraph(GRAPH_TYPES.flowVvolume)}
            >
              {GRAPH_TYPES.flowVvolume}
            </p>
          </div>
          {buildDetailChart(
            categorizedDataPoints[`${selectedGraph}`],
            selectedGraph
          )}
        </div>
      )}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell style={{ fontWeight: "bold" }}>Best</Table.Cell>
          </Table.Row>
        </Table.Header>
        {DATA_TYPES.map((type) => (
          <Table.Row>
            <Table.Cell style={{ fontWeight: "bold" }}>
              {LABELNAMES[`${type}`]}
            </Table.Cell>
            <Table.Cell>{dataPoint[`${type}`]}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </div>
  );
};

export default VitalFloDataPointDetail;