import React from 'react';
import './PaymentTriggerModal.scss';
import i18next from "i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import PaymentTrigger from "../../Components/PaymentTrigger/PaymentTrigger";

export default class PaymentTriggerModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      updateButtonPressed:false
    };
  }
  
  // Render
  
  render() {
    const {isOpen, surveyId, surveysArray, paymentTrigger, handleCloseModal, handleCloseModalAndReload, fetchQuestionsForSurveyById} = this.props;
    const {updateButtonPressed} = this.state;
    
    return (
      <GeneralModal title={i18next.t('PAYMENT_TRIGGER')}
                    width={'calc(100vw - 60px)'}
                    height={'calc(100vh - 60px)'}
                    isOpen={isOpen}
                    maxWidth={1000}
                    isEditing={paymentTrigger !== null && paymentTrigger !== undefined}
                    maxHeight={600}
                    handleSave={() => this.setState({updateButtonPressed:true})}
                    showSaveButton={true}
                    handleCloseModal={() => handleCloseModal()}
                    showCancelButton={true}>
        <PaymentTrigger surveyId={surveyId}
                        surveysArray={surveysArray}
                        updateFailed={() => this.setState({updateButtonPressed:false})}
                        paymentTrigger={paymentTrigger}
                        updateFinished={() => this.setState({updateButtonPressed:false}, () => handleCloseModalAndReload())}
                        hideCreateButton={true}
                        updateButtonPressed={updateButtonPressed}
                        fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
                        {...this.props}
        />
      </GeneralModal>
    )
  }
}
