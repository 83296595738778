import React, {useState} from 'react'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../ReportRequestDropDown/ReportRequestDropDown.scss'
import './AssignNewToolSurveyDropDown.scss'
import {NOTIFY_OPTS} from 'constants/Notifiers'
import {Translation} from "react-i18next";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import GeneralModal from "../../../Modals/GeneralModal/GeneralModal";
import i18n from "../../../i18n";

const AssignNewToolSurveyDropDown =({closeDropDown, group, type, isOpen, user, company, addToolsToGroup, fetchCompanyAndPatients, assignedQuests, fetchQuests}) => {
  
  let [selectedTool, setSelectedTool] = useState(undefined);
  
  const notifyToolAssigned = () => toast(
    <Translation>{(t, {i18n}) => t('TOOL_ASSIGNED_SUCCESS', {type:type})}</Translation>,
    NOTIFY_OPTS.default);
  
  const notifyToolFailed = () => toast(
    <Translation>{(t, {i18n}) => t('TOOL_ASSIGNED_FAILURE')}</Translation>,
    NOTIFY_OPTS.default);
  
  const notifyFetchQuestsFailed = (aType, aError) => toast(
    <Translation>{(t, {i18n}) => t('ERROR_FETCHING_QUESTS', {type:aType, error:aError})}</Translation>,
    NOTIFY_OPTS.autoCloseFiveSeconds);
  
  const addToolsToGroupHelper = (value) => {
    addToolsToGroup({
      groupId:group.id,
      questIds:[value]
    })
    .then((newResponse) => {
      notifyToolAssigned() && setSelectedTool(undefined);
      fetchCompanyAndPatients({groupName:group.name});
      closeDropDown();
    }, (newError) => {
      notifyToolFailed();
    });
  };
  
  const sendToolAssignReq = (value) => group ? addToolsToGroupHelper(value) : null;
  
  const handleChange = (aOption) => setSelectedTool(aOption);
  
  return (
    <GeneralModal title={i18n.t('ASSIGN_PROPER_CAPITALIZED') + ' ' + i18n.t(type === "Consent" ? "ECONSENT" : type.toUpperCase())}
                  isOpen={isOpen}
                  maxHeight={700}
                  handleSave={() => sendToolAssignReq(selectedTool.id)}
                  noTitleHeading={true}
                  showSaveButton={selectedTool}
                  containsDropDown={true}
                  handleCloseModal={closeDropDown}>
      <LabeledDropDown className="modal-input"
                       style={{width:'100%'}}
                       value={selectedTool}
                       handleSave={handleChange}
                       placeholder={<Translation>{(t, {i18n}) => t('SEARCH_TYPE_HERE', {type:type})}</Translation>}
                       fetchObjects={(aLimit, aOffset, aSearchString) => fetchQuests(company.id, type, aSearchString, group ? group.id : null, aLimit, aOffset)}
                       notifyFetchFailed={notifyFetchQuestsFailed}
                       labelPropertiesArray={['title']}
                       valuePropertiesArray={['id']}
                       responseTotalKeysArray={['data', 'data', 'quests_page', 'total']}
                       responseObjectKeysArray={['data', 'data', 'quests_page', 'quests']}
      />
    </GeneralModal>
  )
};
export default AssignNewToolSurveyDropDown;
