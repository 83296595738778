import EventTracker from 'eventTracker';
import EventTypes from 'constants/EventTypes';

export function removeMedicationAllergyFromUser(aMedicationId, aUserId){
  EventTracker.track(EventTypes.REMOVE_MEDICATION_ALLERGY);
  const query = `
      mutation ($removeMedicationAllergyMutationInput: RemoveMedicationAllergyMutationInput!) {
        removeMedicationAllergy (input: $removeMedicationAllergyMutationInput){
          medication {
            id
            name
          }
        }
      }
  `;
  const variables = {
    removeMedicationAllergyMutationInput:{
      user_id:aUserId,
      medication_id:aMedicationId
    }
  };
  const operationName = 'RemoveMedicationAllergy';

  return (dispatch) => {
    // eslint-disable-next-line no-undef
    return global.api.post('/queries', {
      query, variables, operationName
    });
  };
}
