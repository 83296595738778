import React from "react";
import './SurveyBuilderConditionCell.scss';
import i18next from "i18next";
import LabeledInfo from "../../../Custom UI/LabeledInputs/LabeledInfo/LabeledInfo";
import {Translation} from "react-i18next";
import LabeledSwitch from "../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {allowedWriteColumnOptionsArray, allowedWriteTableOptionsArray, allowedWriteTables, multipleValueOperationsArray, operationOptionsArray} from "../../../constants/SurveyBuilderTypes";

export default class SurveyBuilderConditionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      selectedValue:null,
      additionalAppColumnsArray:[]
    };
    
    if(props.condition && props.condition.column){
      props.fetchAppAdditionalColumns(10, 0, props.condition.column).then((newResult) => {
        let condition = newResult.data.data.additional_app_columns.additional_app_columns;

        if(condition){
          this.setState({selectedValue:condition[0]});
        }
      });
    }
  }
  
  // Render
  
  render(){
    const {errorForIdTypeAndKey, index, question, condition, surveysArray, updateCondition, menuButtonClicked,
      questionOptionsArray, type = ''} = this.props;
    const {selectedValue} = this.state;
    
    const conditionOperationsArray = [{value:'and', label:'and'}, {value:'or', label:'or'}];
    const parseToAgeQuestionTypesArray = ['date', 'datetime', 'monthyear'];
    
    let surveyOptionsArray = [{value:'-2', label:i18next.t('TABLE')}, {value:'-1', label:i18next.t('CURRENT_SURVEY')}];
    
    for(let survey of surveysArray){
      if(survey.key && survey.key.length > 0 && survey.survey_reference && survey.survey_reference.length > 0){
        surveyOptionsArray.push({value:survey.key, label:survey.survey_reference});
      }
    }
    
    return(
      <div className="survey-builder-condition-cell-container">
        <LabeledInfo className="survey-builder-condition-cell-labeled-info"
                     bold={true}
                     label={<Translation>{ (t, { i18n }) => t('CONDITION_NUMBER', {aNumber:(index + 1)}) }</Translation>}
                     menuButtonClicked={(aEvent) => menuButtonClicked(aEvent)}
        />
        
        {/*TODO: Error Message should only be when a Survey Reference is missing.*/}
        <LabeledDropDown className="survey-builder-condition-cell-input survey-builder-condition-cell-input-condition-reference"
                         label={<Translation>{ (t, { i18n }) => t('SURVEY_CONDITION_REFERENCE') }</Translation>}
                         value={surveyOptionsArray.filter(function(aOption){
                           return aOption.value === (condition.is_table ? '-2' : condition.survey);
                         })}
                         options={surveyOptionsArray}
                         infoBlurb={<Translation>{(t, { i18n }) => t('SURVEY_CONDITION_REFERENCE_EXPLANATION')}</Translation>}
                         handleSave={(aOption) => {
                           if(aOption.value === '-2'){
                             updateCondition(question, index, [{key:'survey', value:''}, {key:'reference', value:''}, {key:'is_table', value:true}]);
                           }
                           else{
                             updateCondition(question, index, [{key:'survey', value:aOption.value}, {key:'reference', value:aOption.label}, {key:'is_table', value:false}]);
                           }
                         }}
                         errorMessage={errorForIdTypeAndKey(question.id, 'error', 'visibility_condition_survey').message}
                         maxMenuHeight={100}
                         popoverPlacement={"left"}
        />
        
        {condition.is_table ?
          <div className="survey-builder-condition-cell-is-table">
            <LabeledDropDown className="survey-builder-condition-cell-input"
                             label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_TABLE')}</Translation>}
                             value={allowedWriteTableOptionsArray.filter(option => {
                               return option.value === condition.table
                             })}
                             options={allowedWriteTableOptionsArray}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_CONDITION_TABLE_EXPLANATION') }</Translation>}
                             handleSave={(aOption) => updateCondition(question, index, [{key:'table', value:aOption.value}])}
                             warningMessage={errorForIdTypeAndKey(question.id, 'warning', type + 'condition_' + index + '_table').message}
                             popoverPlacement={"left"}
            />
            
            {allowedWriteTables.includes(condition.table) ?
              <LabeledDropDown className="survey-builder-condition-cell-input"
                               label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_COLUMN')}</Translation>}
                               value={selectedValue}
                               infoBlurb={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_COLUMN_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 updateCondition(question, index, [{key:'column', value:aOption.name}]);
                                 this.setState({selectedValue:aOption});
                               }}
                               warningMessage={errorForIdTypeAndKey(question.id, 'warning', type + 'condition_' + index + '_column').message}
                               popoverPlacement={"left"}
                               fetchObjects={(aLimit, aOffset, aSearchString) => this.props.fetchAppAdditionalColumns(aLimit, aOffset, aSearchString)}
                               optionsArrayFormatter={(aArray) => {
                                 let writeColumnOptionsArray = [];
                
                                 if(condition && condition.is_table){
                                   if(condition.table === 'AdditionalAppColumn'){
                                     let addCurrentKeyToOptions = true;
                    
                                     for(let additionalAppColumn of aArray){
                                       if(condition.column === additionalAppColumn.name){
                                         addCurrentKeyToOptions = false;
                                       }
                                       writeColumnOptionsArray.push({value:additionalAppColumn.name, label:additionalAppColumn.name + ' - (' + additionalAppColumn.name_type + ')', type:additionalAppColumn.name_type, id:additionalAppColumn.id});
                                     }
                                     if(addCurrentKeyToOptions){
                                       if(condition.column){
                                         writeColumnOptionsArray.push({value:condition.column, label:condition.column, type:''});
                                       }
                                     }
                                   }
                                   else{
                                     writeColumnOptionsArray = allowedWriteColumnOptionsArray(condition.table);
                                   }
                                 }
                                 return writeColumnOptionsArray;
                               }}
                               valuePropertiesArray={['name']}
                               responseTotalKeysArray={['data', 'data', 'additional_app_columns', 'total']}
                               responseObjectKeysArray={['data', 'data', 'additional_app_columns', 'additional_app_columns']}
              />
              :
              null
            }
            
            <LabeledDelayedInput className="survey-builder-condition-cell-input"
                                 label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_VALUE')}</Translation>}
                                 value={condition.value && condition.value.length > 0 ? condition.value[0] : ''}
                                 infoBlurb={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_VALUE_EXPLANATION')}</Translation>}
                                 minLength={0}
                                 handleSave={(aEvent) => updateCondition(question, index, [{key:'value', value:aEvent.target.value, index:0}])}
                                 errorMessage={errorForIdTypeAndKey(question.id, 'error', type + 'condition_' + index + '_value_' + 0).message}
                                 popoverPlacement={"left"}
            />
          </div>
          :
          <>
            {condition.question.map((questionReference, aIndex) => (
              <div className="survey-builder-condition-cell-type-index"
                   key={question.id + '-condition-question-' + type + '-' + index + '-' + aIndex}>
                <LabeledDropDown className="survey-builder-condition-cell-input"
                                 label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_QUESTION')}</Translation>}
                                 value={questionOptionsArray.filter(option => {
                                   return option.value === questionReference
                                 })}
                                 options={questionOptionsArray}
                                 infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_CONDITION_QUESTION_EXPLANATION') }</Translation>}
                                 handleSave={(aOption) => updateCondition(question, index, [{key:'question', value:aOption.value, index:aIndex}, {key:'question_type', value:aOption.type, index:aIndex}])}
                                 warningMessage={errorForIdTypeAndKey(question.id, 'warning', type + 'condition_' + index + '_question_' + aIndex).message}
                                 popoverPlacement={"left"}
                                 menuButtonClicked={condition.question && condition.question.length > 1 ?
                                   (aEvent) => menuButtonClicked(aEvent, aIndex)
                                   :
                                   null
                                 }
                />
                
                <LabeledDelayedInput className="survey-builder-condition-cell-input"
                                     label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_VALUE')}</Translation>}
                                     value={condition.value[aIndex] && condition.value[aIndex].length > 0 ? condition.value[aIndex] : ''}
                                     infoBlurb={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_VALUE_EXPLANATION')}</Translation>}
                                     minLength={0}
                                     handleSave={(aEvent) => updateCondition(question, index, [{key:'value', value:aEvent.target.value, index:aIndex}])}
                                     errorMessage={errorForIdTypeAndKey(question.id, 'error', type + 'condition_' + index + '_value_' + aIndex).message}
                                     popoverPlacement={"left"}
                />
                
                {parseToAgeQuestionTypesArray.includes(condition.question_type) ?
                  <LabeledSwitch className="survey-builder-condition-cell-switch-input"
                                 label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_PARSE_TO_AGE')}</Translation>}
                                 checked={Boolean(condition.parse_to_age[aIndex])}
                                 onChange={(isChecked) => updateCondition(question, index, [{key:'parse_to_age', value:isChecked, index:aIndex}])}
                                 infoBlurb={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_PARSE_TO_AGE_EXPLANATION')}</Translation>}
                                 checkedChildren={<Translation>{(t, {i18n}) => t('YES_PROPER_CAPITALIZED')}</Translation>}
                                 popoverPlacement={"left"}
                                 unCheckedChildren={<Translation>{(t, {i18n}) => t('NO_PROPER_CAPITALIZED')}</Translation>}
                  />
                  :
                  null
                }
              </div>
            ))}
            
            {multipleValueOperationsArray.includes(condition.operation) ?
              <div className="survey-builder-condition-cell-add-button"
                   onClick={() => {
                     let value = condition.value;
                     let parseToAge = condition.parse_to_age;
                     let questionType = condition.question_type;
                     let questionReference = condition.question;
                
                     if(!value){
                       value = [];
                     }
                     if(!parseToAge){
                       parseToAge = [];
                     }
                     if(!questionType){
                       questionType = [];
                     }
                     if(!questionReference){
                       questionReference = [];
                     }
                     value.push('');
                     parseToAge.push(false);
                     questionType.push('');
                     questionReference.push('');
                     updateCondition(question, index, [{key:'value', value:value}, {key:'parse_to_age', value:parseToAge}, {key:'question_type', value:questionType}, {key:'question', value:questionReference}]);
                   }}>
                <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
                
                <Translation>{(t, {i18n}) => t('ADD_VALUE')}</Translation>
              </div>
              :
              null
            }
          </>
        }
        
        <LabeledDropDown className="survey-builder-condition-cell-input survey-builder-condition-cell-input-first-of-type"
                         label={<Translation>{(t, {i18n}) => t('SURVEY_CONDITION_OPERATION')}</Translation>}
                         value={operationOptionsArray.filter(option => {
                           return option.value === condition.operation
                         })}
                         options={operationOptionsArray}
                         infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_CONDITION_OPERATION_EXPLANATION') }</Translation>}
                         handleSave={(aOption) => updateCondition(question, index, [{key:'operation', value:aOption.value}])}
                         warningMessage={errorForIdTypeAndKey(question.id, 'warning', type + 'condition_' + index + '_operation').message}
                         popoverPlacement={"left"}
        />
        
        {index > 0 ?
          <LabeledDropDown className="survey-builder-condition-cell-input"
                           label={<Translation>{(t, {i18n}) => t('SURVEY_PAIRWISE_CONDITION_OPERATION')}</Translation>}
                           value={conditionOperationsArray.filter(option => {
                             return option.value === condition.condition_operator
                           })}
                           options={conditionOperationsArray}
                           infoBlurb={<Translation>{ (t, { i18n }) => t('SURVEY_PAIRWISE_CONDITION_OPERATION_EXPLANATION') }</Translation>}
                           handleSave={(aOption) => updateCondition(question, index, [{key:'condition_operator', value:aOption.value}])}
                           popoverPlacement={"left"}
          />
          :
          null
        }
      </div>
    )
  }
}
