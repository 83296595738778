import React from "react";
import './SurveyRadioQuestionCell.scss';
import '../../../Components/SurveyComponent/SurveyComponent.scss';
import i18next from "i18next";

export default class SurveyRadioQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:'',
      hidden:false,
      otherOptionText:'',
      otherOptionSelected:false
    };
    
    if(props.answer && props.answer.value != null){
      this.state.answer = props.answer.value;
    }
    if(props.answer && props.answer.other != null){
      this.state.otherOptionSelected = true;
    }
    if(props.answer && props.answer.other_value != null){
      this.state.otherOptionText = props.answer.other_value;
    }
    this.saveTextAnswer = this.saveTextAnswer.bind(this);
    this.resetOtherTextBox = this.resetOtherTextBox.bind(this);
    this.otherOptionClicked = this.otherOptionClicked.bind(this);
  }
  
  // Methods
  
  saveTextAnswer(aEvent){
    this.setState({otherOptionText:aEvent.target.value, answer:'other'});
    this.props.onAnswerChange(this.props.question, 'other', null, true, aEvent.target.value);
  }
  
  otherOptionClicked(){
    this.setState({otherOptionSelected:!this.state.otherOptionSelected});
    this.props.onAnswerChange(this.props.question, 'other');
  }
  
  resetOtherTextBox(aEvent){
    this.setState({otherOptionSelected:false, answer:aEvent.target.value, otherOptionText:''});
    this.props.onAnswerChange(this.props.question, aEvent.target.value);
  }
  
  // Render
  
  render(){
    const {disabled, question} = this.props;
    const {answer, otherOptionText, otherOptionSelected} = this.state;
    const {key, type, other, choices, other_label, label_position, number_of_columns, answer_label_position} = question;
    
    let containerClassName = '';
    
    if(label_position === 'left'){
      containerClassName = 'answer-cell-large ';
    }
    
    let labelPosition = label_position;
    let numberOfColumns = number_of_columns;
    
    let answerLabelPosition = answer_label_position;
    let columnClassName = '';
    let otherClassName = '';
    let answerClassName = 'inline-text-radio-input-right-text';
    let renderInstructionText = true;
    let renderLabelUnderInput = false;
    let labelPositionClassName = '';
    
    if(answerLabelPosition === 'bottom'){
      renderLabelUnderInput = true;
      answerClassName = 'inline-text-radio-input-bottom-text';
      otherClassName = 'survey-input-radio-other-spacing-five';
      labelPositionClassName = 'survey-radio-cell-label-bottom';
    }
    if(numberOfColumns === 2){
      columnClassName = 'survey-input-radio-two-column';
    }
    else if(numberOfColumns === 3){
      if(answerLabelPosition === 'bottom'){
        otherClassName = 'survey-input-radio-other-spacing-three';
      }
      columnClassName = 'survey-input-radio-three-column';
    }
    else if(numberOfColumns === 4){
      if(answerLabelPosition === 'bottom'){
        otherClassName = 'survey-input-radio-other-spacing-four';
      }
      columnClassName = 'survey-input-radio-four-column';
    }
    else if(numberOfColumns > 4){
      if(answerLabelPosition === 'bottom'){
        otherClassName = 'survey-input-radio-other-spacing-five';
      }
      columnClassName = 'survey-input-radio-five-column';
      answerClassName = 'inline-text-radio-input-bottom-text';
      labelPositionClassName = 'survey-radio-cell-label-bottom';
      renderLabelUnderInput = true;
    }
    if(labelPosition === 'left'){
      renderInstructionText = false;
      labelPositionClassName = 'survey-radio-cell-label-left';
    }
    
    return (
      <div className={containerClassName}
           key={'survey-' + type + '-question-cell-' + key}>
        {renderInstructionText ?
          <>
            <hr />
            
            <em>
              {i18next.t('SURVEY_SINGLE_SELECT_INSTRUCTION_TEXT')}
            </em>
            
            <hr />
          </>
          :
          null
        }
        
        <div className={'survey-input-radio-container'}>
          {choices && choices.length > 0 ?
            choices.map((choice, index) => {
              return (
                <div className={(disabled ? 'disabled-input-cell ' : '') +  'survey-input-radio-cell ' + columnClassName} key={index}>
                  <label className={'custom-input-cell ' + labelPositionClassName}>
                    <input type={"radio"}
                           name={'radio-choice'}
                           value={choice[0]}
                           disabled={disabled}
                           checked={choice[0] === answer}
                           onChange={this.resetOtherTextBox}
                    />
                    
                    {!renderLabelUnderInput ?
                      <>
                        <div className={answerClassName}>
                          {choice[1]}
                        </div>
                        
                        <span className={choice[0] === answer && !otherOptionSelected ? 'checked' : 'custom-checkmark-radio'}/>
                      </>
                      :
                      <span className={choice[0] === answer && !otherOptionSelected ? 'checked checked-column' : 'custom-checkmark-radio custom-checkmark-radio-column'}/>
                    }
                  </label>
                  
                  {renderLabelUnderInput ?
                    <div className={'inline-text-radio-input inline-text-radio-input-column'}>
                      {choice[1]}
                    </div>
                    :
                    null
                  }
                </div>
              );
            })
            :
            null
          }
        </div>
        
        <div>
          {choices && choices.length > 0 && other ?
            <div className={'survey-input-radio-cell survey-input-radio-other ' + otherClassName}
                 key={'SurveyRadioOther'}>
              <label className={'custom-input-cell survey-radio-question-cell-other-input ' + labelPositionClassName}>
                <input type={"radio"}
                       name={'radio-choice'}
                       checked={otherOptionSelected}
                       disabled={disabled}
                       onChange={this.otherOptionClicked}
                />
                
                {answerLabelPosition === 'bottom' ?
                  <span className={otherOptionSelected ? 'checked survey-other-checked-column' : 'custom-checkmark-radio survey-other-checked-column'}/>
                  :
                  <span className={otherOptionSelected ? 'checked survey-other-checked-row' : 'custom-checkmark-radio survey-other-checked-row'}/>
                }
              
              </label>
              
              <div className="inline-text-radio-input-other">
                {other_label ? other_label : i18next.t('OTHER')}
              </div>
              
              <input type={'text'}
                     className={'survey-string-input-other'}
                     value={otherOptionText}
                     disabled={disabled || !otherOptionSelected}
                     onChange={this.saveTextAnswer}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    );
  }
}
