import React from 'react';
import './SurveyListsList.scss';
import DynamicList from "../DynamicList/DynamicList";
import SurveyListModal from "../../Modals/SurveyListModal/SurveyListModal";
import {downloadSurveyList} from "../../Helpers/SurveyListHelpers";

export default class SurveyListsList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      shouldReload:false,
      selectedSurveyList:null,
      showSurveyListModal:false
    };
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(!prevProps.company && prevProps.company !== this.props.company){
      this.setState({selectedSurveyList:null, shouldReload:true});
    }
  }
  
  // Methods
  
  
  // Render
  
  render() {
    const {client, company, className, addSurveyList, updateSurveyList, fetchSurveyListById, fetchSurveyListByReference,
      fetchSurveyLists} = this.props;
    const {selectedSurveyList, showSurveyListModal, shouldReload} = this.state;
  
    let columnsArray = [];
    columnsArray.push({key:'name', columnWeight:1, columnNameKey:'SURVEY_LIST_NAME', propertiesArray:['name']});
    columnsArray.push({key:'reference', columnWeight:1, columnNameKey:'SURVEY_LIST_REFERENCE', propertiesArray:['reference']});
    columnsArray.push({key:'updated_at', dateFormat:'MMM D YYYY, h:mma', columnWeight:1, columnNameKey:'SURVEY_LIST_DATE', propertiesArray:['updated_at']});
  
    let menuItemsArray = [{title:'DOWNLOAD', clickAction:(aSelectedSurveyList) => {
        this.setState({selectedSurveyList:aSelectedSurveyList});
        downloadSurveyList(aSelectedSurveyList, fetchSurveyListById);
      }}];
    menuItemsArray.push({title:'EDIT', clickAction:(aSelectedSurveyList) => {
      this.setState({selectedSurveyList:aSelectedSurveyList, showSurveyListModal:true});
    }, isValid:(aSelectedSurveyList) => aSelectedSurveyList && aSelectedSurveyList.is_editable});
  
    return (
      <div className={'survey-lists-container ' + className}>
        <DynamicList id="survey-lists-list"
                     didReload={() => this.setState({shouldReload:false})}
                     menuCancel={() => this.setState({selectedSurveyList:null})}
                     columnsArray={columnsArray}
                     fetchObjects={(aLimit, aOffset, aSearchString) => fetchSurveyLists(aLimit, aOffset, aSearchString)}
                     shouldReload={shouldReload}
                     menuItemsArray={menuItemsArray}
                     totalObjectsKey='TOTAL_SURVEY_LISTS'
                     addButtonClicked={() => this.setState({selectedSurveyList:null, showSurveyListModal:true})}
                     responseTotalKeysArray={['data', 'data', 'survey_lists', 'total']}
                     responseObjectKeysArray={['data', 'data', 'survey_lists', 'survey_lists']}
        />
  
        {showSurveyListModal ?
          <SurveyListModal client={client}
                           isOpen={showSurveyListModal}
                           company={company}
                           surveyList={selectedSurveyList}
                           handleFinish={() => this.setState({showSurveyListModal:false, selectedSurveyList:null, shouldReload:true})}
                           addSurveyList={addSurveyList}
                           handleCloseModal={() => this.setState({showSurveyListModal:false, selectedSurveyList:null})}
                           updateSurveyList={updateSurveyList}
                           fetchSurveyListById={fetchSurveyListById}
                           fetchSurveyListByReference={fetchSurveyListByReference}
          />
          :
          null
        }
      </div>
    )
  }
}
