import React from "react";
import DatePicker from "react-datepicker";
import moment from 'moment'
import {Translation} from "react-i18next";
import {monthYearFormat} from "../../../Helpers/DateHelpers";

const DATE_PICKER_FORMAT = 'yyyy-MM';

export default class SurveyMonthYearQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      answer:null,
      uncertain:false
    };
    
    if(props.answer != null && props.answer.value != null){
      this.state.answer = moment(props.answer.value);
      this.state.uncertain = !!props.answer.other;
    }
    this.saveAnswer = this.saveAnswer.bind(this);
  }
  
  // Methods
  
  saveAnswer(aDate){
    let date = moment(aDate);
    this.setState({answer:date});
    
    // TODO: Add translations.
    if(!this.afterMinDate(date)){
      this.props.onError(`Not in Range: Must be after ${this.minValue()}`);
    }
    else if(!this.beforeMaxDate(date)){
      this.props.onError(`Not in Range: Must be before ${this.maxValue()}`);
    }
    else if(this.inRange){
      let month = (date.month() + 1).toString();
      
      if(month.length === 1){
        month = '0' + month;
      }
      this.props.onAnswerChange(this.props.question, date.year() + '-' + month, null, this.state.uncertain);
    }
  }
  
  setUncertain = () => {
    this.setState({uncertain:!this.state.uncertain});
    this.saveAnswer(this.state.answer)
  };
  
  maxValue(){
    return this.props.question.max_value;
  }
  
  minValue(){
    return this.props.question.min_value;
  }
  
  minDate = () => moment(this.minValue(), DATE_PICKER_FORMAT).toDate();
  maxDate = () => moment(this.maxValue(), DATE_PICKER_FORMAT).toDate();
  inRange = date => this.afterMinDate(moment(date)) && this.beforeMaxDate(moment(date));
  afterMinDate = date => !this.minValue() || date.isAfter(this.minDate());
  beforeMaxDate = date => !this.maxValue() || date.isSameOrBefore(this.maxDate());
  
  // Render
  
  render() {
    const {disabled, question} = this.props;
    const {answer, uncertain} = this.state;
    const {key, type} = question;

    return(
      <div key={'survey-' + type + '-question-cell-' + key}>
        <br/>
        
        <hr />
        
        <em>
          <Translation>{ (t, { i18n }) => t('SURVEY_MONTH_YEAR_INSTRUCTION_TEXT')}</Translation>
        </em>
        
        <hr />
        
        <div className="survey-month-year-picker-cell" style={{marginTop: '25px'}}>
          <DatePicker dateFormat={DATE_PICKER_FORMAT}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      scrollableYearDropdown
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      disabled={disabled}
                      value={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      selected={answer !== null && answer !== undefined && answer.isValid() ? answer.toDate() : null}
                      placeholder={monthYearFormat}
                      onChange={(date) => this.saveAnswer(date)}
                      minDate={!this.minValue() ? moment('1850-01-01 00:00:00', 'yyyy-MM-dd hh:mm:ss').toDate() : this.minDate()}
                      maxDate={!this.maxValue() ? moment().add(1, 'years').toDate() : this.maxDate()}
                      popperPlacement="bottom-start"
          />
          
          {uncertain  && <div className={'survey-input-cell'} >
            <label className={'custom-input-cell'}>
              <input type={"checkbox"}
                     name={'checkbox-choice'}
                     onChange={this.setUncertain}
                     defaultChecked={uncertain}
              />
              
              <span className={'custom-checkmark'}/>
              
              <div className={'inline-text'}>
                <Translation>{ (t, { i18n }) => t('EXACT_DATE_UNKNOWN')}</Translation>
              </div>
            </label>
          </div>
          }
        </div>
      </div>
    )
  }
}
