import React from 'react';
import '../../Custom UI/common.scss'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import './SurveyResponses.scss';
import InfiniteScroll from "react-infinite-scroll-component";
import SurveyResponseCell from "../../Cells/SurveyResponseCell/SurveyResponseCell";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import Loading from "../../Custom UI/Loading/Loading";
import {SurveyLockedState} from "../../actions/surveyActions";
import CloseIcon from '@material-ui/icons/Close';
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import {currentDateHumanizedWithoutTimeStamp} from "../../Helpers/DateHelpers";
import fileDownload from "js-file-download";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyDownloadGenerated = () => toast(<Translation>{ (t, { i18n }) => t('REPORT_SENT_TO_ADMIN_EMAIL') }</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class SurveyResponses extends React.Component {
  
  // Instance Variables
  
  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getSurveyResponses(limit, offset),
    success: () => this.updateList(),
    fail: (error) => console.error('SurveyResponses (InfiniteScrollManager): Failed with error:', error)
  });
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      surveyResponsesArray:[],
      totalSurveyResponses:0,
      selectedSurveyResponse:null,
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.lockSurveyResponse = this.lockSurveyResponse.bind(this);
    this.emailReportToPatient = this.emailReportToPatient.bind(this);
  }
  
  componentDidMount(){
    this.scrollManager.fetch(true);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.surveyId !== this.props.surveyId){
      this.setState({ surveyResponsesArray:[], totalSurveyResponses:0, menuAnchorElement:null, selectedSurveyResponse:null});
      this.scrollManager.fetch(true);
    }
    else if(prevProps.patientId !== this.props.patientId){
      this.setState({ surveyResponsesArray:[], totalSurveyResponses:0, menuAnchorElement:null, selectedSurveyResponse:null});
      this.scrollManager.fetch(true);
    }
    else if(prevProps.company !== this.props.company){
      this.setState({ surveyResponsesArray:[], totalSurveyResponses:0, menuAnchorElement:null, selectedSurveyResponse:null});
      this.scrollManager.fetch(true);
    }
  }
  
  // Methods
  
  updateList(){
    this.setState({surveyResponsesArray:this.scrollManager.getList()});
  }
  
  getSurveyResponses(limit, offset){
    return this.props.getSurveyResponses(this.props.patientId, Number(this.props.surveyId), limit, offset).then((newResponse) => {
      let data = newResponse.data.data.survey_response_page;
      let surveyResponsesArray = data.survey_responses;
      let totalSurveyResponses = data.total;
      this.setState({totalSurveyResponses:totalSurveyResponses});
      return {objects:surveyResponsesArray, total:totalSurveyResponses};
    });
  }
  
  static formattedDateTime(timestamp){
    const timeZone = moment.tz.guess();
    return moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('L') + ' @ ' + moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('LT z')
  }
  
  lockSurveyResponse(aSurveyResponse){
    this.props.updateSurveyResponseLockState(aSurveyResponse.id, SurveyLockedState.Locked).then(result => {
      aSurveyResponse.lock_state = result.data.data.updateSurveyResponseLockState.survey_response.lock_state;
      this.props.fetchPatient({ userId: this.props.patient.id })
    });
    this.setState({menuAnchorElement:null, selectedSurvey:null});
  };
  
  downloadReport(aSurveyResponse){
    const reportReqParams = {
      company_id: this.props.company.id,
      user_id: this.props.patientId,
      survey_response_id: aSurveyResponse.id
    };
    global.api.post("/api/tool_stats/patient_survey_report_pdf", reportReqParams, {responseType: 'blob'})
    .then((result) => {
      let fileName = `survey_response_${currentDateHumanizedWithoutTimeStamp()}.pdf`;
      //   notifyReportGenerationSuccess(fileName, tabIndex);
      fileDownload(result.data, fileName, 'application/pdf');
    })
  }
  
  emailReportToPatient(aSurveyResponse){
    const reportReqParams = {
      company_id: this.props.company.id,
      user_id: this.props.patientId,
      survey_response_id: aSurveyResponse.id
    };
    global.api.post("/api/tool_stats/send_patient_survey_report_pdf", reportReqParams, {responseType: 'blob'})
    .then((result) => {
      notifyDownloadGenerated();
    })
  }
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedSurveyResponse:null});
  }
  
  // Render
  
  render() {
    const { title, height, company, onCloseDrawer, selectSurveyResponse, showLockOption = true } = this.props;
    const { menuAnchorElement, totalSurveyResponses, surveyResponsesArray, selectedSurveyResponse } = this.state;
    
    const surveyResponsesProhibitions = ["admin_level_8"];
    const currentRole = roleForCompany(company);
    
    return (
      <div className="survey-response-container">
        {!surveyResponsesProhibitions.includes(currentRole) ?
          <div>
            <div className="survey-list-title">
              <div>
                <div>
                  <b>{title}</b>
                </div>
                <Translation>{(t, {i18n}) => t('SURVEY_RESPONSES')}</Translation> - {totalSurveyResponses}
              </div>
              <CloseIcon onClick={onCloseDrawer} />
            </div>
            
            <div id="payments-list"
                 className="survey-response-cell-list-container"
                 style={{height: height ? height : '100vh - 275px'}}>
              <InfiniteScroll style={{overflowY: "hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="payments-list">
                
                {surveyResponsesArray && surveyResponsesArray.length > 0 ?
                  <div>
                    {surveyResponsesArray.map((surveyResponse, index) => (
                      <SurveyResponseCell key={surveyResponse.id + index}
                                          onClick={() => selectSurveyResponse(surveyResponse)}
                                          surveyResponse={surveyResponse}
                                          menuButtonClicked={(aEvent) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedSurveyResponse:surveyResponse})}
                      />
                    ))}
                  </div>
                  :
                  <div className="no-information-text">
                    <Translation>{ (t, { i18n }) => t('NO_SURVEY_RESPONSES')}</Translation>
                  </div>
                }
              </InfiniteScroll>
            </div>
            
            <Menu id="survey-responses-menu"
                  open={Boolean(menuAnchorElement)}
                  style={{zIndex:99999}}
                  onClose={() => {this.closeMenu()}}
                  anchorEl={menuAnchorElement}
                  keepMounted>
              {showLockOption && selectedSurveyResponse && selectedSurveyResponse.lock_state !== SurveyLockedState.Locked ?
                <MenuItem style={{position:'relative'}}
                          onClick={() => {
                            this.lockSurvey(selectedSurveyResponse);
                            this.closeMenu();
                          }}>
                  <Translation>{ (t, { i18n }) => t('LOCK')}</Translation>
                </MenuItem>
                :
                null
              }
              
              <MenuItem style={{position:'relative'}}
                        onClick={() => {
                          this.downloadReport(selectedSurveyResponse);
                          this.closeMenu();
                        }}>
                <Translation>{ (t, { i18n }) => t('DOWNLOAD_REPORT')}</Translation>
              </MenuItem>
              
              <MenuItem style={{position:'relative'}}
                        onClick={() => {
                          this.emailReportToPatient(selectedSurveyResponse);
                          this.closeMenu();
                        }}>
                <Translation>{ (t, { i18n }) => t('EMAIL_REPORT_TO_PATIENT')}</Translation>
              </MenuItem>
            </Menu>
          </div>
          :
          <div className="no-information-text">
            <Translation>{ (t, { i18n }) => t('NO_ADMIN_PERMISSION')}</Translation>
          </div>
        }
      </div>
    )
  }
}
