import React from 'react';
import PatientInfoListItem from '../../../Cells/PatientInfoCell/PatientInfoCell'
import {Translation, withTranslation} from "react-i18next";
import i18next from "i18next";
import '../../../Pages/PatientProfile/PatientProfile.scss';
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Loading from "../../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../../constants/Notifiers";
import LoadFailed from "../../../Custom UI/LoadFailed/LoadFailed";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";

const notifyRemoveConditionError = (aError) => toast(<Translation>{ (t, {i18n}) => t('ERROR_REMOVING_CONDITION', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyRemoveConditionSuccess = (aError) => toast(<Translation>{ (t, {i18n}) => t('SUCCESS_REMOVING_CONDITION', {error:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

class PatientProfileConditions extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      dateOfDiagnosis:'',
      loadingConditions:false,
      menuAnchorElement:null,
      selectedCondition:null,
      conditionLoadFailed:false,
      selectedConditionId:null,
      conditionToAddToUser:'',
      invalidFormSubmission:false,
      patientConditionsArray:[],
      loadingPatientConditions:false,
      patientConditionLoadFailed:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.addConditionToUser = this.addConditionToUser.bind(this);
    
    if(this.props.patient && this.props.patient.conditions){
      this.state.patientConditionsArray = this.props.patient.conditions;
    }
  }
  
  componentDidMount(){
    if(this.state.patientConditionsArray.length === 0){
      this.loadPatientConditions();
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if(this.props.patient && this.props.patient.conditions){
      if(this.state.patientConditionsArray !== this.props.patient.conditions){
        this.setState({patientConditionsArray:this.props.patient.conditions});
      }
    }
  }
  
  // Methods
  
  loadPatientConditions(){
    this.setState({ loadingPatientConditions:true });
    this.props.loadPatientConditions(this.props.patient.id).then((newResult) => {
      this.setState({ loadingPatientConditions:false, patientConditionLoadFailed:false });
    }, (newError) => {
      this.setState({ loadingPatientConditions:false, patientConditionLoadFailed:true });
    });
  }
  
  addConditionToUser(){
    let groupId = this.state.conditionToAddToUser;
    let dateOfDiagnosis = this.state.dateOfDiagnosis;
    let userId = this.props.patient.id;
    
    if(!groupId){
      this.setState({invalidFormSubmission: true})
    }
    else{
      this.props.addConditionToUser({
        groupId: groupId,
        dateOfDiagnosis: dateOfDiagnosis,
        userId: userId
      }).then(result => {
        this.props.loadPatientConditions(userId);
      }).catch(newError => {
        alert(newError.data.error);
      })
      this.setState({conditionToAddToUser:'', dateOfDiagnosis:'', invalidFormSubmission:false, selectedCondition:null})
    }
  }
  
  removeConditionFromUser = () => {
    const userId = this.props.patient.id;
    const { selectedConditionId, patientConditionsArray } = this.state;
    const index = patientConditionsArray.map((aCondition) => {return aCondition.group_id}).indexOf(selectedConditionId);
    
    if(index > -1){
      patientConditionsArray.splice(index, 1);
    }
    this.props.removeConditionFromUser(selectedConditionId, userId).then(newResult => {
      notifyRemoveConditionSuccess();
      this.props.loadPatientConditions(userId);
    }, newError => {
      let error = '';
      
      if(newError && newError.data && newError.data && newError.data.error){
        error = newError.data.error;
      }
      notifyRemoveConditionError(error);
      this.props.loadPatientConditions(userId);
    });
    this.closeMenu();
  };
  
  closeMenu(){
    this.setState({menuAnchorElement:null, selectedConditionId:null});
  }
  
  // Render
  
  render() {
    const {company, patient, getConditions} = this.props;
    const {menuAnchorElement, dateOfDiagnosis, loadingConditions, conditionLoadFailed,
      invalidFormSubmission, patientConditionsArray, loadingPatientConditions,
      patientConditionLoadFailed, selectedCondition} = this.state;
    
    const conditionsPermissions = ['admin', 'provider', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_5', 'admin_level_6', 'admin_level_10', 'admin_level_11'];
    let currentRole = roleForCompany(company);
    let availableYears = [];
    availableYears.push({year:"0000", label:"None"}) ;
    
    for(let index = 0; index <= 100; index++){
      let year = (new Date()).getFullYear() - index;
      availableYears.push({year:year, label:year})
    }
    let dateOfDiagnosisOptions = Object.entries(availableYears).map(([key,value])=>{
      return {value:value.year, label:value.label, key:value.year}
    });
    
    return (
      <div>
        {conditionsPermissions.includes(currentRole) ?
          <div style={{width:'calc(100% - 40px', marginLeft:'20px', marginRight:'20px'}}>
            <div style={{display:'flex' }}>
              {loadingConditions ?
                <Loading className="conditions-dropdown-loading-container"/>
                :
                (conditionLoadFailed ?
                    <LoadFailed retry={() => this.loadConditions()}
                                message={i18next.t('CONDITION_LOAD_FAILED')}
                    />
                    :
                    <LabeledDropDown className="patient-profile-conditions-input"
                                     value={selectedCondition}
                                     handleSave={(aOption) => this.setState({conditionToAddToUser:aOption.id, selectedCondition:aOption})}
                                     fetchObjects={(aLimit, aOffset, aSearchString) => getConditions(aSearchString, aLimit, aOffset)}
                                     notifyFetchFailed={() => this.setState({conditionLoadFailed:true})}
                                     labelPropertiesArray={['name']}
                                     valuePropertiesArray={['id']}
                                     responseTotalKeysArray={['data', 'data', 'conditions_page', 'total']}
                                     responseObjectKeysArray={['data', 'data', 'conditions_page', 'conditions']}
                    />
                )}
  
              <div className="conditions-inner-cell">
                <LabeledDropDown className="patient-profile-conditions-year"
                                 label={<Translation>{ (t, { i18n }) => t('DIAGNOSED_IN') }</Translation>}
                                 value={dateOfDiagnosis && dateOfDiagnosisOptions.filter(option => {
                                   return dateOfDiagnosis === option.value
                                 })}
                                 options={dateOfDiagnosisOptions}
                                 handleSave={(aOption) => this.setState({dateOfDiagnosis:aOption.value})}
                                 placeholder={i18next.t('SELECT_YEAR')}
                />
    
                <AddCircleOutlineIcon onClick={this.addConditionToUser}
                                      style={{
                                        height: '20px',
                                        width: '20px',
                                        color: '#2D81C9',
                                      }}/>
              </div>
            </div>
            
            <div className='list-outer-container'>
              {invalidFormSubmission ?
                <p className="error-message">
                  *
                  <Translation>{(t, {i18n}) => t('SELECT_VALID_CONDITION_OR_DIAGNOSED')}</Translation>
                </p>
                :
                null
              }
              <div className='list-inner-container'>
                {loadingPatientConditions ?
                  <Loading/>
                  :
                  (patientConditionLoadFailed ?
                      <LoadFailed retry={() => this.loadPatientConditions()}
                                  message={i18next.t('PATIENT_CONDITION_LOAD_FAILED')}
                      />
                      :
                      (patient && patientConditionsArray.length > 0 ?
                          <>
                            {patientConditionsArray.map((condition, index) => (
                              <div key={'condition-' + index + '-' + condition.id}>
                                <PatientInfoListItem data={condition}
                                                     removeId={condition.group_id}
                                                     removeFunc={this.removeConditionFromUser}
                                                     menuButtonClicked={(aEvent, aConditionGroup) => this.setState({menuAnchorElement:aEvent.currentTarget, selectedConditionId:aConditionGroup})}
                                />
                              </div>
                            ))}
                          </>
                          :
                          <div className="condition-no-conditions-found">
                            <Translation>{(t, {i18n}) => t('NO_CONDITIONS')}</Translation>
                          </div>
                      )
                  )}
              </div>
            </div>
            
            <Menu id="simple-menu"
                  open={Boolean(menuAnchorElement)}
                  onClose={() => this.closeMenu()}
                  anchorEl={menuAnchorElement}
                  keepMounted>
              <MenuItem style={{position:'relative'}}
                        onClick={(aEvent) => {
                          aEvent.preventDefault();
                          aEvent.stopPropagation();
                          this.removeConditionFromUser();
                        }}>
                <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
              </MenuItem>
            </Menu>
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('CONDITIONS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(PatientProfileConditions)
