import React from 'react';
import '../../Custom UI/common.scss'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import PaymentTriggerModal from "../../Modals/PaymentTriggerModal/PaymentTriggerModal";
import LabeledDelayedInput from "../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import i18next from "i18next";
import PaymentTriggerCell from "../../Cells/PaymentTriggerCell/PaymentTriggerCell";
import InfiniteScrollManager from "../../managers/InfiniteScrollManager";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../Custom UI/Loading/Loading";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import './PaymentTriggersList.scss';
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

const notifyPaymentTriggerRemovalError = () => toast(<Translation>{ (t, { i18n }) => t('ERROR_REMOVING_PAYMENT_TRIGGER') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyPaymentTriggerRemovalSuccess = () => toast(<Translation>{ (t, { i18n }) => t('PAYMENT_TRIGGER_REMOVED') }</Translation>, NOTIFY_OPTS.autoClose);

export default class PaymentTriggersList extends React.Component {
  
  // Instance Variables
  
  scrollManager = new InfiniteScrollManager({
    getItems: (offset, limit) => this.getPaymentTriggers(limit, offset),
    success: () => this.updateList(),
    fail: (error) => console.error("Error", error)
  });
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      paymentTriggersArray:[],
      totalPaymentTriggers:0,
      searchString:'',
      showMakeAPaymentTriggerModal:false,
      selectedPaymentTrigger:null,
      menuAnchorElement:null,
      openRemoveTriggerModal:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.removePaymentTrigger = this.removePaymentTrigger.bind(this);
  }
  
  componentDidMount() {
    this.scrollManager.fetch(true);
  }
  
  // Methods
  
  updateList(){
    this.setState({paymentTriggersArray:this.scrollManager.getList()});
  }
  
  getPaymentTriggers(limit, offset){
    return this.props.fetchPaymentTriggers(limit, offset, this.state.searchString).then(response => {
      let data = response.data.data.hyperwallet_payment_triggers;
      let newArray = data.hyperwallet_payment_triggers;
      let paymentTriggersArray = this.state.paymentTriggersArray.concat(newArray);
      this.setState({paymentTriggersArray:paymentTriggersArray, totalPaymentTriggers:data.total});
      return {objects:newArray, total:data.total};
    });
  }
  
  static formattedDateTime(timestamp){
    const timeZone = moment.tz.guess();
    return moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('L') + ' @ ' + moment(timestamp, 'YYYY-MM-DD HH:mm:ss Z').tz(timeZone || 'America/New_York').format('LT z');
  }
  
  closeMenu(){
    this.setState({openRemoveTriggerModal: null, menuAnchorElement:null, selectedPaymentTrigger:null});
  }
  
  menuButtonClicked(aEvent, aPaymentTrigger){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedPaymentTrigger:aPaymentTrigger});
  };
  
  removePaymentTrigger(aPaymentTriggerId){
    let paymentTriggerToRemove = this.state.selectedPaymentTrigger;
    this.props.removePaymentTrigger(aPaymentTriggerId).then(result => {
      this.setState({isEditing:false});
      this.scrollManager.removeItemAtIndex(this.scrollManager.getIndexOfItem(paymentTriggerToRemove));
      this.updateList();
      this.setState({totalPaymentTriggers:(this.state.totalPaymentTriggers - 1)});
      notifyPaymentTriggerRemovalSuccess();
    }, error => {
      notifyPaymentTriggerRemovalError();
    });
    this.setState({menuAnchorElement:null, selectedPaymentTrigger:null, openRemoveTriggerModal:false});
  }
  
  // Render
  
  render() {
    const { tools, company, fetchQuestionsForSurveyById } = this.props;
    const { menuAnchorElement, openRemoveTriggerModal, selectedPaymentTrigger, paymentTriggersArray, totalPaymentTriggers,
      showMakeAPaymentTriggerModal } = this.state;
    
    let currentRole = roleForCompany(company);
    const paymentTriggerPermissions = ["admin", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4"];
    const paymentTriggerActionPermissions = ["admin", "admin_level_4"];
    
    return (
      <div className="main-panel-inner-container">
        {paymentTriggerPermissions.includes(currentRole) ?
          <>
            <div className="payment-list-title">
              <div>
                <Translation>{(t, {i18n}) => t('TOTAL_PAYMENT_TRIGGERS')}</Translation> - {totalPaymentTriggers}
              </div>
              
              <LabeledDelayedInput className="payment-title-list-search"
                                   disabled={false}
                                   showSearchIcon={true}
                                   placeholder={i18next.t('TAP_TO_SEARCH')}
                                   value={this.state.searchString}
                                   handleSave={(aEvent) => {
                                     this.setState({searchString: aEvent.target.value}, () => {
                                       this.scrollManager.fetch(true)
                                     })
                                   }}
              />
            </div>
            
            <div className="payment-trigger-list-cell-container" id='payment-triggers-list'>
              <InfiniteScroll style={{overflowY: "hidden"}}
                              next={() => this.scrollManager.fetch(false)}
                              loader={<Loading loading={this.scrollManager.isLoading()}/>}
                              hasMore={!this.scrollManager.hasLoadedAll()}
                              dataLength={() => this.scrollManager.length()}
                              scrollableTarget="payment-triggers-list">
                {paymentTriggersArray && paymentTriggersArray.length > 0 ?
                  <div>
                    {paymentTriggersArray.map((paymentTrigger, index) => (
                      <PaymentTriggerCell paymentTrigger={paymentTrigger}
                                          key={paymentTrigger.id + index}
                                          {...this.props}
                                          menuButtonClicked={paymentTriggerActionPermissions.includes(currentRole) ?
                                            (aEvent) => this.menuButtonClicked(aEvent, paymentTrigger)
                                            :
                                            null
                                          }
                      />
                    ))}
                    
                    <Menu id="simple-menu"
                          anchorEl={menuAnchorElement}
                          keepMounted
                          open={Boolean(menuAnchorElement)}
                          onClose={() => this.closeMenu()}>
                      <MenuItem onClick={() => this.setState({showMakeAPaymentTriggerModal:true, menuAnchorElement:null})}>
                        <Translation>{(t, {i18n}) => t('EDIT')}</Translation>
                      </MenuItem>
                      
                      <MenuItem style={{position:'relative'}}
                                onClick={(aEvent) => {
                                  aEvent.preventDefault();
                                  aEvent.stopPropagation();
                                  this.setState({openRemoveTriggerModal:true, menuAnchorElement:null});
                                }}>
                        <Translation>{(t, {i18n}) => t('REMOVE')}</Translation>
                      </MenuItem>
                    </Menu>
                  </div>
                  :
                  <div className="no-information-text">
                    <Translation>{(t, {i18n}) => t('NO_PAYMENT_TRIGGERS')}</Translation>
                  </div>
                }
              </InfiniteScroll>
            </div>
          </>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n}) => t('PAYMENT_TRIGGERS_BLOCKED')}</Translation>
          </div>
        }
        
        {showMakeAPaymentTriggerModal ?
          <PaymentTriggerModal isOpen={showMakeAPaymentTriggerModal}
                               tools={tools}
                               company={company}
                               canCancel={true}
                               paymentTrigger={selectedPaymentTrigger}
                               handleCloseModal={() => this.setState({showMakeAPaymentTriggerModal:false, selectedPaymentTrigger:null})}
                               updatePaymentTrigger={this.props.updatePaymentTrigger}
                               handleCloseModalAndReload={() => this.setState({showMakeAPaymentTriggerModal:false, selectedPaymentTrigger:null}, () => this.scrollManager.fetch(true))}
                               fetchQuestionsForSurveyById={fetchQuestionsForSurveyById}
          />
          :
          null
        }
        
        {/*TODO: Replace with the Confirmation Modal.*/}
        {openRemoveTriggerModal ?
          <GeneralModal isOpen={openRemoveTriggerModal}
                        title={<Translation>{(t, {i18n}) => t('REMOVE_PAYMENT_TRIGGER')}</Translation>}
                        noTitleHeading={true}
                        isEditing={false}
                        showSaveButton={true}
                        showCancelButton={true}
                        clickOutsideToClose={true}
                        saveButtonTitle={<Translation>{(t, {i18n}) => t('REMOVE')}</Translation>}
                        handleSave={() => this.removePaymentTrigger(selectedPaymentTrigger.id)}
                        handleCloseModal={() => this.closeMenu()}>
            <span className="no-information-text trigger-remove-payment">
              <Translation>{(t, {i18n}) => t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE')}</Translation>
              
              <span><b>{selectedPaymentTrigger ? selectedPaymentTrigger.title : null}</b>?</span>
            </span>
          </GeneralModal>
          :
          null
        }
      </div>
    )
  }
}
