import React from "react";
import './SurveyBuilderQuestionTriggers.scss';
import {Translation} from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import LabeledDropDown from "../../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LabeledDelayedInput from "../../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import LabeledSwitch from "../../../../Custom UI/LabeledInputs/LabeledSwitch/LabeledSwitch";
import {surveyTriggerTypes, operationOptionsArray, multipleValueOperationsArray, triggerTypesRequiringAlertTypes, triggerTypesRequiringCustomMessages} from "../../../../constants/SurveyBuilderTypes";
import {AlertTypes} from "../../../../constants/AlertTypes";
import {KeyForTranslation} from "../../../../Helpers/SurveyBuilderHelpers";
import {snakeCaseToCapitalized} from "../../../../Helpers/Helpers";

export default class SurveyBuilderQuestionTriggers extends React.Component{
  
  // Instance Variables
  
  
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
    
    };
  }
  
  // Methods
  
  
  
  // Render
  
  render(){
    const {question, selectedLanguage, updateQuestion, errorForIdTypeAndKey} = this.props;
    
    let surveyTriggerTypeOptionsArray = [];
    
    for(let surveyTriggerType of surveyTriggerTypes){
      surveyTriggerTypeOptionsArray.push({value:surveyTriggerType, label:snakeCaseToCapitalized(surveyTriggerType)});
    }
    
    let alertTypeOptionsArray = [];
    
    for(let alert of AlertTypes){
      alertTypeOptionsArray.push({value:alert.value, label:alert.text});
    }
  
    const customMessageKey = KeyForTranslation('custom_message', selectedLanguage);
    
    return(
      <div className="">
        <LabeledSwitch className="survey-builder-question-other-input"
                       label={<Translation>{ (t, { i18n }) => t('TRIGGER') }</Translation>}
                       checked={Boolean(question.trigger)}
                       onChange={(isChecked) => {
                         let updatedQuestion = cloneDeep(question);
  
                         if(isChecked){
                           updatedQuestion.trigger = {};
                         }
                         else{
                           delete updatedQuestion['trigger'];
                         }
                         updateQuestion(updatedQuestion);
                       }}
                       infoBlurb={<Translation>{ (t, { i18n }) => t('TRIGGER_EXPLANATION') }</Translation>}
                       checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                       popoverPlacement={"left"}
                       unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
        />
        
        {question && question.trigger ?
          <>
            <LabeledDropDown className="survey-builder-question-db-write-input-more"
                             label={<Translation>{ (t, { i18n }) => t('TYPE_PROPER_CAPITALIZED') }</Translation>}
                             value={surveyTriggerTypeOptionsArray.filter(function(aOption){
                               return aOption.value === question.trigger.type;
                             })}
                             options={surveyTriggerTypeOptionsArray}
                             infoBlurb={<Translation>{(t, { i18n }) => t('TRIGGER_TYPE_EXPLANATION')}</Translation>}
                             handleSave={(aOption) => {
                               let updatedQuestion = cloneDeep(question);
                               updatedQuestion.trigger.type = aOption.value;
                               
                               if(!triggerTypesRequiringAlertTypes.includes(question.trigger.type) && updatedQuestion.trigger.alert_type){
                                 delete question.trigger['alert_type'];
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_type').message}
                             maxMenuHeight={100}
                             popoverPlacement={"left"}
            />

            {triggerTypesRequiringAlertTypes.includes(question.trigger.type) ?
              <LabeledDropDown className="survey-builder-question-db-write-input-more"
                               label={<Translation>{(t, {i18n}) => t('ALERT_TYPE')}</Translation>}
                               value={alertTypeOptionsArray.filter(option => {
                                 return option.value === question.trigger.alert_type
                               })}
                               options={operationOptionsArray}
                               infoBlurb={<Translation>{(t, {i18n}) => t('TRIGGER_ALERT_TYPE_EXPLANATION')}</Translation>}
                               handleSave={(aOption) => {
                                 let updatedQuestion = cloneDeep(question);
                                 updatedQuestion.trigger.alert_type = aOption.value;
                                 updateQuestion(updatedQuestion);
                               }}
                               errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_alert_type').message}
                               popoverPlacement={"left"}
              />
              :
              null
            }

            <LabeledDropDown className="survey-builder-question-db-write-input-more"
                             label={<Translation>{(t, {i18n}) => t('OPERATION')}</Translation>}
                             value={operationOptionsArray.filter(option => {
                               return option.value === question.trigger.operation
                             })}
                             options={operationOptionsArray}
                             infoBlurb={<Translation>{ (t, { i18n }) => t('TRIGGER_OPERATION_EXPLANATION') }</Translation>}
                             handleSave={(aOption) => {
                               let updatedQuestion = cloneDeep(question);
                               updatedQuestion.trigger.operation = aOption.value;
                               
                               if(question.trigger.value){
                                 if(multipleValueOperationsArray.includes(aOption.value)){
                                   if(!Array.isArray(question.trigger.value)){
                                     updatedQuestion.trigger.value = [question.trigger.value];
                                   }
                                 }
                                 else{
                                   if(Array.isArray(question.trigger.value) && question.trigger.value.length > 0){
                                     updatedQuestion.trigger.value = question.trigger.value[0];
                                   }
                                 }
                               }
                               updateQuestion(updatedQuestion);
                             }}
                             errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_operation').message}
                             popoverPlacement={"left"}
            />
            
            {multipleValueOperationsArray.includes(question.trigger.operation) ?
              <>
                {question.trigger.value && question.trigger.value.length > 0 ?
                  question.trigger.value.map((value, index) => (
                    <div key={question.id + '-trigger-value-' + index}>
                      <LabeledDelayedInput className="survey-builder-question-trigger-input-value"
                                           label={<Translation>{(t, {i18n}) => t('VALUE')}</Translation>}
                                           value={value && value.length > 0 ? value : ''}
                                           infoBlurb={
                                             <Translation>{(t, {i18n}) => t('TRIGGER_VALUE_EXPLANATION')}</Translation>}
                                           minLength={0}
                                           handleSave={(aEvent) => {
                                             let updatedQuestion = cloneDeep(question);
                                             updatedQuestion.trigger.value[index] = aEvent.target.value;
                                             updateQuestion(updatedQuestion);
                                           }}
                                           errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_value_' + index.toString()).message}
                                           popoverPlacement={"left"}
                                           deleteButtonClicked={() => {
                                             let updatedQuestion = cloneDeep(question);
                                             updatedQuestion.trigger.value.splice(index, 1);
                                             updateQuestion(updatedQuestion);
                                           }}
                      />
                    </div>
                  ))
                  :
                  null
                }
                
                <div className="survey-builder-question-visibility-add-button"
                     onClick={() => {
                       let updatedQuestion = cloneDeep(question);
  
                       if(!updatedQuestion.trigger.value){
                         updatedQuestion.trigger.value = [];
                       }
                       updatedQuestion.trigger.value.push('');
                       updateQuestion(updatedQuestion);
                     }}>
                  <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
    
                  <Translation>{(t, {i18n}) => t('ADD_VALUE')}</Translation>
                </div>
              </>
              :
              <LabeledDelayedInput className="survey-builder-question-other-input-more"
                                   label={<Translation>{(t, { i18n }) => t('VALUE')}</Translation>}
                                   value={question.trigger.value && question.trigger.value.length > 0 ? question.trigger.value : ''}
                                   infoBlurb={<Translation>{(t, { i18n }) => t('TRIGGER_VALUE_EXPLANATION')}</Translation>}
                                   minLength={0}
                                   handleSave={(aEvent) => {
                                     let updatedQuestion = cloneDeep(question);
                                     updatedQuestion.trigger.value = aEvent.target.value;
                                     updateQuestion(updatedQuestion);
                                   }}
                                   errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_value').message}
                                   popoverPlacement={"left"}
              />
            }

            {triggerTypesRequiringCustomMessages.includes(question.trigger.type) ?
              <LabeledDelayedInput className="survey-builder-question-other-input-more"
                                   label={<Translation>{(t, {i18n}) => t('TRIGGER_CUSTOM_MESSAGE', {aLanguage:selectedLanguage.locale})}</Translation>}
                                   value={question.trigger[customMessageKey] && question.trigger[customMessageKey].length > 0 ? question.trigger[customMessageKey] : ''}
                                   infoBlurb={<Translation>{(t, {i18n}) => t('TRIGGER_CUSTOM_MESSAGE_EXPLANATION')}</Translation>}
                                   minLength={0}
                                   handleSave={(aEvent) => {
                                     let updatedQuestion = cloneDeep(question);
                                     updatedQuestion.trigger[customMessageKey] = aEvent.target.value;
                                     updateQuestion(updatedQuestion);
                                   }}
                                   errorMessage={errorForIdTypeAndKey(question.id, 'error', 'trigger_' + customMessageKey).message}
                                   warningMessage={errorForIdTypeAndKey(question.id, 'warning', 'trigger_' + customMessageKey).message}
                                   popoverPlacement={"left"}
              />
              :
              null
            }
          </>
          :
          null
        }
      </div>
    )
  }
}
