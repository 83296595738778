import {LOAD_USERS, FETCH_USERS} from 'constants/ActionTypes';
import {loadCompany} from './company'
import {allAdminRolesArray} from '../Helpers/AdminHelpers';

export function fetchUsers() {
  return (dispatch) => {
    dispatch({type:FETCH_USERS});
    dispatch(loadUsers());
  };
}

export function fetchUsersScroll(aLimit, aOffset, aSearchString) {
  let graphQLQuery = `
     query findUsers($query: String, $company_id: ID!, $offset: Int, $limit: Int, $order_by_ascending: Boolean) {
       user_page(query: $query, company_id: $company_id, roles: ["${allAdminRolesArray.join('", "')}"] , offset: $offset, limit: $limit, order_by_ascending: $order_by_ascending) {
          users {
            id
            first_name
            last_name
            email
            birth_year
            gender
            image_id
            role
            number_of_uncleared_company_notification_receipts
            last_sign_in_at
            password_update_requested
            password_changed_at
            edc_id
            groups {
              id
              name
            }
          }
          total
        }
      }
    `;
  let variables = {limit:aLimit, offset:aOffset};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    variables['query'] = aSearchString;
    variables['order_by_ascending'] = true;
    return global.api.post("/queries", { query: graphQLQuery, variables });
  };
}

export function loadUsers() {
  let graphQLQuery = `
     query findUsers($company_id: ID!) {
       users(company_id: $company_id, roles: ["admin","provider","admin_level_3","admin_level_4","admin_level_5","admin_level_1","admin_level_2", "admin_level_8", "admin_level_9", "admin_level_10", "admin_level_11", "admin_level_66"]) {
          id
          first_name
          last_name
          email
          birth_year
          gender
          image_id
          role
          number_of_uncleared_company_notification_receipts
          edc_id
        }
      }
    `;
  let variables = {};

  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    let returnValue;

    if(variables['company_id']){
      returnValue = global.api.post("/queries", { query: graphQLQuery, variables }).then((result) => {
        let users = result.data.data.users;
        dispatch({
          type: LOAD_USERS,
          result: users
        });
      }).catch((error) => {
        dispatch({
          type: LOAD_USERS,
          error,
        });
      });
    }
    else{
      returnValue = dispatch(loadCompany()).then(() => {
        global.api.post("/queries", { query: graphQLQuery, variables }).then((result) => {
          let users = result.data.data.users;
          dispatch({
            type: LOAD_USERS,
            result: users
          });
        }).catch((error) => {
          dispatch({
            type: LOAD_USERS,
            error,
          });
        });
      })
    }
    return returnValue;
  };
}
