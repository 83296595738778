import React from 'react';
import './ReminderTrackerChart.scss'
import Chart from "chart.js";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import BaseChart from "../BaseChart/BaseChart";
import {ReminderConstants} from "../../../../constants/ReminderConstants";
import {EntryConstants} from "../../../../constants/EntryConstants";
import {ObjectiveConstants} from "../../../../constants/ObjectiveConstants";
import ReactToolTip from 'react-tooltip';
import ResponsesList from "../../../../Lists/ResponsesList/ResponsesList";
import {Translation} from "react-i18next";
import {momentFromDate} from "../../../../Helpers/DateHelpers";

export default class ReminderTrackerChart extends BaseChart {
  constructor(props){
    super(props);
    this.state = {
      showTooltip: false,
      text: '',
      date: '',
      responseId: ''
    };
  }

  componentDidMount() {
    let { reminderObjective, startDate, endDate, onClickListener, reminders } = this.props;
    const chartContext = this.chartRef.current.getContext("2d");
    this.chart = new Chart(chartContext, this.generateChartData(reminderObjective, startDate, endDate, onClickListener, reminders));
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const prevEntries = prevProps.reminderObjective.entries;
    let { reminderObjective, startDate, endDate, onClickListener, reminders } = this.props;

    if (this.dateRangeChanged(prevProps.startDate, prevProps.endDate, startDate, endDate)
      || this.entriesChanged(prevEntries, reminderObjective.entries)) {
      const chartContext = this.chartRef.current.getContext("2d");
      this.chart.destroy();
      this.chart = new Chart(chartContext, this.generateChartData(reminderObjective, startDate, endDate, onClickListener, reminders));
    }
  }

  generateChartData(reminderObjective, startDate, endDate, onClickListener, reminders){
    let dataPoints = [];
    let pointStyles = [];
    let xAxisTickLabels = [];
    let currentDateIndex = 0;
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    let tooltipValue = "";
    let skippedImage = new Image();
    let takenImage = new Image();
    takenImage.src = ResponsesList.TakenImage;
    skippedImage.src = ResponsesList.SkippedImage;

    while(currentDate < endDate){
      let entryOnCurrentDate = (reminderObjective == null || reminderObjective.entries == null) ? null :
        reminderObjective.entries.find((entry) => {
          let entry_time = entry.reminder_time ? entry.reminder_time : entry.response.measured_at;
          let measuredAt = new Date(entry.response.measured_at);
          let corresponding_reminder = momentFromDate(entry_time, 'HHmm');
          if(entry_time && parseInt(reminders[0].time) !== parseInt(corresponding_reminder)){
            return;
          }
          return measuredAt.getDate() === currentDate.getDate()
            && measuredAt.getMonth() === currentDate.getMonth()
            && measuredAt.getFullYear() === currentDate.getFullYear();
        });

      // TODO: Add Translations and Colour updates.
      if(entryOnCurrentDate != null){
        if(entryOnCurrentDate.value === EntryConstants.EntryTaken){
          if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeMedication){
            tooltipValue = "Taken";
          }
          else if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeRoutine){
            tooltipValue = "Completed";
          }
          dataPoints.push({
            tooltip: tooltipValue,
            colour: '#24895b',
            x: currentDateIndex + 0.5,
            y: 0,
            responseId: entryOnCurrentDate.response.id,
            objectiveId: reminderObjective.id
          });
          pointStyles.push(takenImage);
        }
        else{
          if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeMedication){
            tooltipValue = "Skipped";
          }
          else if(reminderObjective.type === ObjectiveConstants.ObjectiveTypeRoutine){
            tooltipValue = "Missed";
          }
          dataPoints.push({
            tooltip: tooltipValue,
            colour: '#f34d33',
            x: currentDateIndex + 0.5,
            y: 0,
            responseId: entryOnCurrentDate.response.id,
            objectiveId: reminderObjective.id
          });
          pointStyles.push(skippedImage);
        }
      }
      else if(this.shouldTakeOnDate(currentDate, reminders)){
        dataPoints.push({
          tooltip: 'Entry Missing',
          colour: '#eeeeee',
          x: currentDateIndex + 0.5,
          y: 0
        });
        pointStyles.push("");//No image if it is a missing entry
      }
      else{
        dataPoints.push({
          tooltip: 'Not Scheduled',
          colour: '#FFFFFF',
          x: currentDateIndex + 0.5,
          y: 0,
          borderColour: '#eeeeee'
        });
        pointStyles.push("");//No image
      }
      xAxisTickLabels.push(this.buildXAxisTickLabel(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      currentDateIndex++;
    }
    return {
      type: 'scatter',
      plugins: [{
        afterDraw: function(chartInstance) {
          let meta = chartInstance.getDatasetMeta(0);

          if(meta){
            let context = chartInstance.ctx;
            let xOffset = 0;

            if(meta.controller._xScale._labelItems.length > 1){
              xOffset = (meta.controller._xScale._labelItems[1].x - meta.controller._xScale._labelItems[0].x) * 0.55;
            }
            meta.controller._xScale._labelItems.forEach((labelItem, index) => {
              context.save();
              context.translate(labelItem.x + xOffset, labelItem.y);
              context.rotate(labelItem.rotation);
              context.textAlign = "right";
              context.font = labelItem.font.string;
              context.fillStyle = "#000000";
              context.fillText(labelItem.label, labelItem.textOffset, 0);
              context.restore();
            });
          }
        }
      }, ChartAnnotation],
      data: {
        datasets: [{
          pointBackgroundColor: dataPoints.map(dataPoint => dataPoint.colour),
          pointRadius: 7.5,
          pointHoverRadius: 7.5,
          pointStyle: pointStyles,
          data: dataPoints,
          pointBorderColor: dataPoints.map(dataPoint => {
            if (dataPoint.borderColour) {
              return dataPoint.borderColour;
            }
            return dataPoint.colour;
          })
        }]
      },
      options: {
        onClick: (event) => {
          let elements = this.chart.getElementAtEvent(event);

          if(elements.length > 0){
            let index = elements[0]._index;

            if(index >= 0 && index < dataPoints.length){
              const dataPoint = dataPoints[index];
              onClickListener({
                responseId: dataPoint.responseId,
                objectiveId: dataPoint.objectiveId
              });
            }
          }
        },
        spanGaps: true,
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            const chart = this.chartRef.current;

            if(!chart){
              return;
            }
            if(tooltipModel.opacity === 0){
              this.setState({
                showTooltip: false
              });
              return;
            }
            if(!this.state.showTooltip){
              const dataPoint = dataPoints[tooltipModel.dataPoints[0].index];
              this.setState({
                showTooltip: true,
                text: dataPoint.tooltip,
                responseId: dataPoint.responseId,
                date: xAxisTickLabels[tooltipModel.dataPoints[0].index]
              });
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: '#eeeeee',
              color: '#eeeeee',
              drawBorder: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: false,
              min: 0,
              max: currentDateIndex,
              stepSize: 1,
              fontColor: 'transparent',
              padding: 20,
              autoSkip: false,
              callback: function(value, index, values) {
                let returnValue = "";

                if(value < xAxisTickLabels.length && value >= 0){
                  returnValue = `${xAxisTickLabels[Math.floor(value)]}`;
                }
                return returnValue;
              }
            },
            id: 'x-axis-0'
          }],
          yAxes: [{
            display: false,
            gridLines: {
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              min: -1,
              max: 1,
            }
          }]
        }
      }
    }
  }

  shouldTakeOnDate(date, reminders){
    let reminderForDate = reminders.find(reminder => {
      if(reminder.repeat_type === ReminderConstants.ReminderTypeDaily){
        return true;
      }
      else if(reminder.repeat_type === ReminderConstants.ReminderTypeWeekly){
        return date.getDay() === ((reminder.day_number + 1) % 7);
      }
      else{
        return date.getDate() === reminder.day_number;
      }
    });
    return reminderForDate!= null;
  }

  render() {
    setTimeout(() => {
      if(this.chart){
        this.chart.resize();
        this.resizePoints();
        this.chart.update();
      }
    });
    const {reminderObjective, reminders} = this.props;
    const {showTooltip, responseId, date, text} = this.state;
    const takenImage = ResponsesList.TakenImage;
    const skippedImage = ResponsesList.SkippedImage;

    const responses = reminderObjective.entries
      .map(entry => entry.response)
      .filter(response => response.id === responseId);

    const formatDate = responses.length === 0 ? date : this.formatResponseDate(responses[0].measured_at);
    const footer = responses.length === 0 ? null : 'click to view details';
    const imageSrc = responses.length === 0 ? null : ['Skipped', 'Missed'].includes(text) ? skippedImage : takenImage;

    return (
      <div>
        <canvas ref={this.chartRef}
                data-tip data-for={`${reminderObjective.id}-${reminders[0].time}`}
        />
        {
          showTooltip &&
          <ReactToolTip id={`${reminderObjective.id}-${reminders[0].time}`}
                        effect='float'
          >
            <div className='tooltip-container'>
              <div className='tooltip-date'>{formatDate}</div>
              <div className='tooltip-body flex-center'>
                {imageSrc && <img alt="" src={imageSrc}/>}
                { text }
              </div>
              <div className='tooltip-footer match-image no-padding-top-footer'>
                { footer }
              </div>
            </div>
          </ReactToolTip>
        }
      </div>
    )
  }
}
