import React from 'react';
import ToolBlockAnswer from '../../../../Cells/ToolCells/ToolBlockAnswerCell/ToolBlockAnswerCell'
import update from 'immutability-helper'
import {Translation} from "react-i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

export default class ToolBlockAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchorElement: null,
      selectedAnswer: null
    }
    this.handleAnswerChange = this.handleAnswerChange.bind(this)
    this.removeAnswerImage = this.removeAnswerImage.bind(this)
    this.updateAnswerImage = this.updateAnswerImage.bind(this)
    this.addAnswer = this.addAnswer.bind(this)
    this.removeAnswer = this.removeAnswer.bind(this)
    this.updateAnswer = this.updateAnswer.bind(this)
    this.moveAnswer = this.moveAnswer.bind(this)
    this.openAnswerMenu = this.openAnswerMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  updateToolBlockAnswer(updateObject) {
    updateObject.answers.map((answer, i) => {
      answer.order = i
      return answer
    })
    this.props.updateBlock(updateObject, this.props.index)
  }

  handleAnswerChange(e) {
    const key = 'answer_text'
    const value = e.target.value
    const index = parseInt(e.target.name)
    this.updateAnswer(key, value, index, true)
  }

  removeAnswerImage(index) {
    this.updateAnswer('image_data', null, index)
    this.updateAnswer('has_image', false, index)
  }

  updateAnswerImage(data, index) {
    this.updateAnswer('image_data', data, index)
    this.updateAnswer('has_image', true, index)
  }

  addAnswer() {
    let updatedAnswers = [ ...this.props.answers, {answer_text: "", translations: this.createAnswerTranslations()}]
    this.updateToolBlockAnswer({answers: updatedAnswers})
  }

  createAnswerTranslations(){
    return this.props.block.translations.map(translation=>{
      return {
        locale: translation.locale,
        answer_text: ''
      }
    })
  }

  removeAnswer(index) {
    let updatedAnswers = this.props.answers
    let removeAnswerId = updatedAnswers[index].id
    updatedAnswers.splice(index, 1)
    let updateObject = {
      answers: updatedAnswers
    }
    if (removeAnswerId) {
      updateObject.removeAnswerIds = [...this.props.block.removeAnswerIds || [], removeAnswerId]
    }
    this.updateToolBlockAnswer(updateObject);
    this.closeMenu();
  }

  updateAnswer(key, value, index, isAnswerText = false) {
    let updatedAnswer = Object.assign({}, this.props.answers[index], {[key]: value})

    if(isAnswerText){
      updatedAnswer.translations[this.props.selectedTranslationIndex].answer_text = value;
    }
    let updatedAnswers = [ ...this.props.answers.slice(0,index), updatedAnswer, ...this.props.answers.slice(index+1) ]
    this.updateToolBlockAnswer({answers: updatedAnswers})
  }

  moveAnswer(dragIndex, hoverIndex) {
    let { answers } = this.props
    const dragAnswer = answers[dragIndex]
    let updatedAnswers = update(answers,
      {$splice: [[dragIndex, 1], [hoverIndex, 0, dragAnswer]]}
    )
    this.updateToolBlockAnswer({answers: updatedAnswers})
  }

  openAnswerMenu(aEvent, aIndex){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedAnswer:aIndex})
  }

  closeMenu(){
    this.setState({menuAnchorElement:null, selectedAnswer:null})
  }

  render() {
    const { answers, selectedTranslationIndex } = this.props
    return (
      <div>
        <div style={{display: 'flex', marginLeft: '10px', fontSize: 14, justifyContent: 'space-between', alignItems: 'center', marginRight: '20px'}}>
          <div style={{fontSize: '14px', fontWeight: '600', color: '#4a4a4a'}}>
            <Translation>{ (t, { i18n }) => t('MULTISELECT') }</Translation>
          </div>
          <AddCircleOutlineIcon style={{height: '20px', width: '20px', color: '#2D81C9'}} onClick={this.addAnswer}/>
        </div>
        {answers && answers.length > 0 && answers.map((answer, i) => {
          return (
            <ToolBlockAnswer
              key={i}
              answer={answer}
              index={i}
              handleAnswerChange={this.handleAnswerChange}
              removeAnswer={this.removeAnswer}
              moveAnswer={this.moveAnswer}
              removeAnswerImage={this.removeAnswerImage}
              updateAnswerImage={this.updateAnswerImage}
              openMenu={this.openAnswerMenu}
              selectedTranslationIndex={selectedTranslationIndex}
            />
          )
        })}
        <Menu id="simple-menu"
              anchorEl={this.state.menuAnchorElement}
              keepMounted
              open={Boolean(this.state.menuAnchorElement)}
              onClose={() => this.closeMenu()}>
          <MenuItem style={{position: 'relative', color: 'red'}} onClick={() => this.removeAnswer(this.state.selectedAnswer)}>
            <Translation>{ (t, { i18n }) => t('DELETE')}</Translation>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}
