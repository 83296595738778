import React from 'react';
import './SurveyResponseCell.scss'
import i18next from "i18next";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {SurveyLockedState} from "../../actions/surveyActions";
import {momentFromDateTime} from "../../Helpers/DateHelpers";
import {BiDotsVerticalRounded} from "react-icons/bi";

export default class SurveyResponseCell extends React.Component {
  
  // Render
  
  render() {
    const {surveyResponse, menuButtonClicked, className, onClick} = this.props;
    
    let lockedImageAlt = 'locked';
    let lockedSourcePath = '/imgs/app/locked.svg';
    let importantLabelClassName = 'survey-response-cell-important-label-third';
    
    if(surveyResponse){
      if(surveyResponse.progress < 100 || surveyResponse.lock_state === SurveyLockedState.Unlocked){
        lockedImageAlt = 'unlocked';
        lockedSourcePath = '/imgs/app/unlocked.svg';
      }
      else if(surveyResponse.lock_state === SurveyLockedState.AdminsOnly){
        lockedImageAlt = 'adminLocked';
        lockedSourcePath = '/imgs/app/admin-locked.svg';
      }
      if(surveyResponse.important_questions && surveyResponse.important_questions.length === 1){
        importantLabelClassName = 'survey-response-cell-important-label-full';
      }
      else if(surveyResponse.important_questions && (surveyResponse.important_questions.length === 2 || surveyResponse.important_questions.length === 4)){
        importantLabelClassName = 'survey-response-cell-important-label-half';
      }
    }
    
    return (
      <div className={'survey-response-cell ' + className}
           onClick={() => onClick()}>
        <div className="survey-response-cell-title-cell">
          <img src={lockedSourcePath}
               alt={lockedImageAlt}
               style={{width:'25px', height:'25px'}}
          />
          
          <div className="survey-response-cell-id">
            {surveyResponse.title}
          </div>
        </div>
  
        {surveyResponse.important_questions && surveyResponse.important_questions.length > 0 ?
          <div className='survey-response-cell-important-container'>
            {surveyResponse.important_questions.map((importantField, index) => (
              <LabeledTextInput className={importantLabelClassName}
                                label={importantField.label}
                                value={importantField.survey_response_detail_value}
                                disabled={true}
              />
            ))}
          </div>
          :
          null
        }
        
        <div className="survey-response-cell-date-container">
          {surveyResponse.completed_by_admin ?
            <div className="survey-response-cell-date survey-response-completed-by">
              <b>
                {i18next.t('COMPLETED_BY_ADMIN')}&nbsp;
              </b>
              
              {surveyResponse.admin_name}
            </div>
            :
            null
          }
          
          <div className="survey-response-cell-date survey-response-start-date">
            <b>
              {i18next.t('START_DATE_PROPER_CAPITALIZED_COLON')}&nbsp;
            </b>
            
            {momentFromDateTime(surveyResponse.created_at)}
          </div>
          
          <div className="survey-response-cell-date survey-response-updated-at">
            <b>
              {i18next.t('UPDATED_DATE')}&nbsp;
            </b>
            
            {momentFromDateTime(surveyResponse.updated_at)}
          </div>
        </div>
        
        <div className="survey-response-edit-button">
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize:'21px'}}
                                   fill="#A8A8A8"
                                   onClick={(aEvent) => {
                                     aEvent.preventDefault();
                                     aEvent.stopPropagation();
                                     menuButtonClicked(aEvent);
                                   }}
            />
            :
            null
          }
        </div>
      </div>
    )
  }
}
