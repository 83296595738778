import React from 'react';
import './SurveyMedicationQuestionCell.scss';
import '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown.scss';
import moment from 'moment';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import {NOTIFY_OPTS} from '../../../constants/Notifiers';
import LabeledDropDown from '../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown';
import LabeledDatePicker from '../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker';
import LabeledDelayedInput from '../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput';
import CustomRenderedOption from '../../../Custom UI/CustomRenderedOption/CustomRenderedOption';

const notifyMedicationLoadFailed = (aError) => toast(i18next.t('ERROR_FETCHING_MEDICATION_QUESTIONS', {error:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);

export default class SurveyMedicationQuestionCell extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      valuesArray:[{}],
      medCountryCode:null,
      typedQueriesArray:[],
      searchResultsArray:[],
      selectedValuesArray:[],
      selectedConceptsArray:[],
      medicationDetailsArray:[{}]
    };
    
    if(props.question && props.question.country_code){
      this.state.medCountryCode = props.question.country_code;
    }
    if(props.answer && props.answer.prettyValues){
      let valuesArray = [];
      let selectedValuesArray = [];
      let selectedConceptsArray = [];
      let medicationDetailsArray = [];
      props.answer.prettyValues.forEach(value => {
        let keys = Object.keys(value);
        
        if(keys && keys.length > 0){
          keys.forEach(key => {
            let index = parseInt(key);
            let concept = JSON.parse(value[key]);
            
            if(!concept.drugbank_pcid){
              concept.name = concept.hits && concept.hits[0] && concept.hits[0].value
              concept.drugbank_pcid = concept.hits && concept.hits[0] && concept.hits[0].value
            }
            valuesArray[index] = {value:concept.drugbank_pcid};
            selectedValuesArray[index] = {key:key.toString(), value:value[key]};
            selectedConceptsArray[index] = concept;
            
            let medicationDetails = {};
            medicationDetails['medication_indication'] = concept.medication_indication;
            medicationDetails['medication_dose'] = concept.medication_dose;
            medicationDetails['medication_unit'] = concept.medication_unit;
            medicationDetails['medication_frequency'] = concept.medication_frequency;
            medicationDetails['medication_frequency_other'] = concept.medication_frequency_other;
            medicationDetails['medication_route'] = concept.medication_route;
            medicationDetails['medication_route_other'] = concept.medication_route_other;
            medicationDetails['medication_start_date'] = concept.medication_start_date;
            medicationDetails['medication_ongoing'] = concept.medication_ongoing;
            medicationDetails['medication_end_date'] = concept.medication_end_date;
            medicationDetails['medication_reason'] = concept.medication_reason;
            medicationDetailsArray[index] = medicationDetails;
          });
        }
        this.state.valuesArray = valuesArray;
        this.state.selectedValuesArray = selectedValuesArray;
        this.state.selectedConceptsArray = selectedConceptsArray;
        this.state.medicationDetailsArray = medicationDetailsArray;
      });
    }
    this.showField = this.showField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addMedication = this.addMedication.bind(this);
    this.fieldRequired = this.fieldRequired.bind(this);
    this.removeMedication = this.removeMedication.bind(this);
    this.handleMedicationDetailsChange = this.handleMedicationDetailsChange.bind(this);
  }
  
  // Methods
  
  showField(aField){
    const {question} = this.props;
    let returnValue = this.fieldRequired(aField);
    
    if(question && question.extra_questions){
      returnValue = returnValue || question.extra_questions.includes(aField);
    }
    return returnValue;
  }
  
  handleChange(aIndex, aOption){
    const {question, onAnswerChange} = this.props;
    const {valuesArray, selectedValuesArray, selectedConceptsArray, medicationDetailsArray} = this.state;
    
    if(aOption === undefined){
      aOption = {hits:[aOption]};
    }
    let medicationDetails = this.state.medicationDetailsArray[aIndex];
    let mergedMedication = {...aOption,...medicationDetails};
    selectedConceptsArray[aIndex] = aOption;
    valuesArray[aIndex] = {value:aOption.value};
    selectedValuesArray[aIndex] = {key:aIndex.toString(), value:JSON.stringify(mergedMedication)};
    
    this.setState({selectedValuesArray:selectedValuesArray, valuesArray:valuesArray, selectedConceptsArray:selectedConceptsArray});
    onAnswerChange(question, null, selectedValuesArray, null, null, false);
  }
  
  handleMedicationDetailsChange(aIndex, aValue, aKey){
    const {question, onAnswerChange} = this.props;
    const {selectedValuesArray, selectedConceptsArray, medicationDetailsArray} = this.state;
    
    let medicationDetails = medicationDetailsArray[aIndex];
    medicationDetails[aKey] = aValue;
    medicationDetailsArray[aIndex] = medicationDetails;
    
    let selectedConcept = selectedConceptsArray[aIndex];
    let mergedMedication = {...selectedConcept,...medicationDetails};
    
    selectedValuesArray[aIndex] = {key:aIndex.toString(), value:JSON.stringify(mergedMedication)};
    
    this.setState({selectedValuesArray:selectedValuesArray, medicationDetailsArray:medicationDetailsArray});
    onAnswerChange(question, null, selectedValuesArray, null, null, false);
  }
  
  addMedication(){
    const {valuesArray, typedQueriesArray, searchResultsArray, selectedValuesArray, selectedConceptsArray, medicationDetailsArray} = this.state;
    
    valuesArray.push({});
    typedQueriesArray.push('');
    searchResultsArray.push([]);
    selectedValuesArray.push({});
    selectedConceptsArray.push({});
    medicationDetailsArray.push({});
    this.setState({
      valuesArray:valuesArray,
      typedQueriesArray:typedQueriesArray,
      searchResultsArray:searchResultsArray,
      selectedValuesArray:selectedValuesArray,
      selectedConceptsArray:selectedConceptsArray,
      medicationDetailsArray:medicationDetailsArray
    });
  }
  
  fieldRequired(aField){
    const {question} = this.props;
    let returnValue = false;
    
    if(question && question.required_questions){
      returnValue = question.required_questions.includes(aField);
    }
    return returnValue;
  }
  
  removeMedication(){
    const {valuesArray, typedQueriesArray, searchResultsArray, selectedValuesArray, selectedConceptsArray, medicationDetailsArray} = this.state;
    
    if(valuesArray && Array.isArray(valuesArray) && valuesArray.length > 0){
      valuesArray.splice(-1, 1);
      typedQueriesArray.splice(-1, 1);
      searchResultsArray.splice(-1, 1);
      selectedValuesArray.splice(-1, 1);
      selectedConceptsArray.splice(-1, 1);
      medicationDetailsArray.splice(-1, 1);
      
      if(valuesArray.length === 0){
        valuesArray.push({});
        typedQueriesArray.push('');
        searchResultsArray.push({});
        selectedValuesArray.push({});
        medicationDetailsArray.push({});
      }
      this.setState({
        valuesArray:valuesArray,
        typedQueriesArray:typedQueriesArray,
        searchResultsArray:searchResultsArray,
        selectedValuesArray:selectedValuesArray,
        selectedConceptsArray:selectedConceptsArray,
        medicationDetailsArray:medicationDetailsArray
      });
      this.props.onAnswerChange(this.props.question, null, selectedValuesArray, null, null, false);
    }
  }
  
  // Render
  
  render(){
    const {disabled, question, fetchMedicationsWithCountryCode} = this.props;
    const {valuesArray, selectedConceptsArray, medicationDetailsArray, medCountryCode, selectedValuesArray} = this.state;
    const {key, type} = question;
    
    const regex = /(<([^>]+)>)/ig;
  
    let medicationFrequencyValuesArray = [
      'once',
      'onceADay',
      'twiceADay',
      'threeTimesADay',
      'fourTimesADay',
      'everyOtherDay',
      'weekly',
      'everyTwoWeeks',
      'everyThreeWeeks',
      'monthly',
      'asNeeded',
      'other'
    ];
    
    let medicationFrequencyOptionsArray = [];
    
    medicationFrequencyValuesArray.map((aValue) => (
      medicationFrequencyOptionsArray.push({value:aValue, label:i18next.t('MEDICATION_FREQUENCY_OPTIONS.' + aValue)})
    ));
    
    let medicationRouteValuesArray = [
      'intramuscular',
      'intravenous',
      'ophthalmic',
      'oral',
      'nasal',
      'rectal',
      'respiratory (Inhalation)',
      'subcutaneous',
      'sublingual',
      'topical',
      'transdermal',
      'vaginal',
      'unknown',
      'other'
    ];
    
    let medicationRouteOptionsArray = [];
    
    medicationRouteValuesArray.map((aValue) => (
      medicationRouteOptionsArray.push({value:aValue, label:i18next.t('MEDICATION_ROUTE_OPTIONS.' + aValue)})
    ));
    
    let medicationContinueValuesArray = ['yes', 'no'];
    
    let medicationContinueOptionsArray = [];
    
    medicationContinueValuesArray.map((aValue) => (
      medicationContinueOptionsArray.push({value:aValue, label:i18next.t('CONFIRMATION_OPTIONS.' + aValue)})
    ));
    
    return(
      <div className='survey-medication-question-cell'
        key={'survey-' + type + '-question-cell-' + key}>
        {valuesArray && valuesArray.length > 0 ?
          valuesArray.map((value, index) => (
            <div key={'survey-' + type + '-question-cell-' + key + '-value-index-' + index}>
              <div className="labeled-input-heading">
                {i18next.t('NAME_OF_MEDICATION')}
              </div>
              
              <div key={'SurveyMedicationQuestionCell' + question.key + index}>
                <LabeledDropDown value={selectedValuesArray[index] && selectedValuesArray[index].value && JSON.parse(selectedValuesArray[index].value)}
                                 disabled={disabled}
                                 handleSave={(aOption) => this.handleChange(index, aOption)}
                                 placeholder={i18next.t('SEARCH_MEDICATION_HERE')}
                                 fetchObjects={(aLimit, aOffset, aSearchString) => fetchMedicationsWithCountryCode(aSearchString, medCountryCode, aLimit, aOffset)}
                                 menuPosition={'portal'}
                                 menuPlacement={'bottom'}
                                 notifyFetchFailed={notifyMedicationLoadFailed}
                                 optionsArrayFormatter={(aArray) => {
                                   let options = [];
                                   
                                   for(let medication of aArray){
                                     let value = medication.name;
      
                                       if(medication.hits && Object.keys(medication.hits).length > 0){
                                         value = medication.hits[0].value;
                                       }
                                     options.push({value:medication.drugbank_pcid, label:value && value.replace(regex, '')});
                                   }
                                   if(options.length){
                                     options[0]['type'] = 'hasCustomHeader';
                                     options[0]['height'] = 55;
                                   }
                                   return options;
                                 }}
                                 customOptionHeight={55}
                                 customOptionRenderer={({focusOption, key, option, selectValue, style}) => {
                                   let returnValue;
                                   
                                   if(option.isSearchString){
                                     returnValue = (
                                       <CustomRenderedOption style={style}
                                                             option={option}
                                                             innerKey={key}
                                                             labelText={i18next.t('CUSTOM_MEDICATION', {medication:option.label})}
                                                             headerText={i18next.t('ADD_CUSTOM_MEDICATIONS')}
                                                             selectValue={selectValue}
                                                             focusOption={focusOption}
                                       />
                                     );
                                   }
                                   else if(option.type === 'hasCustomHeader'){
                                     returnValue = (
                                       <CustomRenderedOption style={style}
                                                             option={option}
                                                             innerKey={key}
                                                             headerText={i18next.t('ADD_FROM_MEDICATION_SEARCH')}
                                                             selectValue={selectValue}
                                                             focusOption={focusOption}
                                       />
                                     );
                                   }
                                   else{
                                     returnValue = (
                                       <div key={key}
                                            style={style}
                                            onClick={() => selectValue(option)}
                                            onMouseEnter={() => focusOption(option)}>
                                         {option.label}
                                       </div>
                                     );
                                   }
                                   return returnValue;
                                 }}
                                 labelPropertiesArray={['name']}
                                 valuePropertiesArray={['drugbank_pcid']}
                                 doNotLoadOnMenuBottom={true}
                                 showSearchStringInList={true}
                                 responseTotalKeysArray={['data', 'data', 'medications_page', 'total']}
                                 responseObjectKeysArray={['data', 'data', 'medications_page', 'medications']}
                />
              </div>
              
              {selectedConceptsArray[index] && selectedConceptsArray[index].name ?
                <div className="survey-medication-question-cell-details-container"
                     key={'SurveyMedicationQuestionCell-Details-' + question.key + index}>
                  {this.showField('indication') ?
                    <LabeledDelayedInput className="survey-medication-input"
                                         label={i18next.t('MEDICATION_INDICATION')}
                                         value={medicationDetailsArray[index].medication_indication}
                                         disabled={disabled}
                                         required={this.fieldRequired('indication')}
                                         handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_indication')}
                                         debounceTimeout={200}
                    />
                    :
                    null
                  }
                  
                  <div className="medication-dual-input">
                    {this.showField('dose') ?
                      <LabeledDelayedInput className="survey-medication-input-small"
                                           label={i18next.t('MEDICATION_DOSE')}
                                           value={medicationDetailsArray[index].medication_dose}
                                           disabled={disabled}
                                           required={this.fieldRequired('dose')}
                                           handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_dose')}
                                           debounceTimeout={200}
                      />
                      :
                      null
                    }
                    
                    {this.showField('unit') ?
                      <LabeledDelayedInput className="survey-medication-input-small"
                                           value={medicationDetailsArray[index].medication_unit}
                                           label={i18next.t('MEDICATION_UNIT')}
                                           disabled={disabled}
                                           required={this.fieldRequired('unit')}
                                           handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_unit')}
                                           debounceTimeout={200}
                      />
                      :
                      null
                    }
                  </div>
                  
                  {this.showField('frequency') ?
                    <LabeledDropDown className="survey-medication-input"
                                     label={i18next.t('FREQUENCY')}
                                     value={medicationDetailsArray[index].medication_frequency && medicationFrequencyOptionsArray.filter(function (option) {
                                       return option.value === medicationDetailsArray[index].medication_frequency;
                                     })}
                                     options={medicationFrequencyOptionsArray}
                                     disabled={disabled}
                                     required={this.fieldRequired('frequency')}
                                     handleSave={(aOption) => this.handleMedicationDetailsChange(index, aOption.value, 'medication_frequency')}
                    />
                    :
                    null
                  }
                  
                  {medicationDetailsArray[index].medication_frequency === 'other' ?
                    <LabeledDelayedInput className="survey-medication-input"
                                         label={i18next.t('MEDICATION_FREQUENCY_OTHER')}
                                         value={medicationDetailsArray[index].medication_frequency_other}
                                         disabled={disabled}
                                         required={this.fieldRequired('frequency')}
                                         handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_frequency_other')}
                                         debounceTimeout={200}
                    />
                    :
                    null
                  }
                  
                  {this.showField('route') ?
                    <LabeledDropDown className="survey-medication-input"
                                     label={i18next.t('MEDICATION_ROUTE')}
                                     value={medicationDetailsArray[index].medication_route && medicationRouteOptionsArray.filter(function (option) {
                                       return option.value === medicationDetailsArray[index].medication_route;
                                     })}
                                     options={medicationRouteOptionsArray}
                                     disabled={disabled}
                                     required={this.fieldRequired('route')}
                                     handleSave={(aOption) => this.handleMedicationDetailsChange(index, aOption.value, 'medication_route')}
                    />
                    :
                    null
                  }
                  
                  {medicationDetailsArray[index].medication_route === 'other' ?
                    <LabeledDelayedInput className="survey-medication-input"
                                         label={i18next.t('MEDICATION_ROUTE_OTHER')}
                                         value={medicationDetailsArray[index].medication_route_other}
                                         disabled={disabled}
                                         required={this.fieldRequired('route')}
                                         handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_route_other')}
                                         debounceTimeout={200}
                    />
                    :
                    null
                  }
                  
                  <div className="medication-third-input">
                    {this.showField('start_date') ?
                      <LabeledDatePicker className="survey-medication-input-third"
                                         label={i18next.t('MEDICATION_START_DATE')}
                                         value={medicationDetailsArray[index].medication_start_date}
                                         maxDate={moment()}
                                         disabled={disabled}
                                         required={this.fieldRequired('start_date')}
                                         handleSave={(aDate) => this.handleMedicationDetailsChange(index, aDate, 'medication_start_date')}
                                         isClearable={true}
                                         popperPlacement={'top-start'}
                                         showYearDropdown={true}
                                         showMonthDropdown={true}
                      />
                      :
                      null
                    }
                    
                    {this.showField('ongoing') ?
                      <LabeledDropDown className="survey-medication-input-third"
                                       label={i18next.t('MEDICATION_ONGOING')}
                                       value={medicationDetailsArray[index].medication_ongoing && medicationContinueOptionsArray.filter(function (option) {
                                         return option.value === medicationDetailsArray[index].medication_ongoing;
                                       })}
                                       options={medicationContinueOptionsArray}
                                       disabled={disabled}
                                       required={this.fieldRequired('ongoing')}
                                       handleSave={(aOption) => this.handleMedicationDetailsChange(index, aOption.value, 'medication_ongoing')}
                      />
                      :
                      null
                    }
                    
                    {medicationDetailsArray[index].medication_ongoing === 'no' ?
                      <LabeledDatePicker className="survey-medication-input-third"
                                         label={i18next.t('MEDICATION_END_DATE')}
                                         value={medicationDetailsArray[index].medication_end_date}
                                         maxDate={moment()}
                                         minDate={medicationDetailsArray[index].medication_start_date}
                                         disabled={disabled}
                                         required={this.fieldRequired('end_date')}
                                         handleSave={(aDate) => this.handleMedicationDetailsChange(index, aDate, 'medication_end_date')}
                                         isClearable={true}
                                         popperPlacement={'top-end'}
                                         showPopperArrow={false}
                                         showYearDropdown={true}
                                         showMonthDropdown={true}
                      />
                      :
                      null
                    }
                  </div>
                  
                  {medicationDetailsArray[index].medication_end_date ?
                    <LabeledDelayedInput className="survey-medication-input"
                                         label={i18next.t('MEDICATION_STOPPED_REASON')}
                                         value={medicationDetailsArray[index].medication_reason}
                                         disabled={disabled}
                                         required={this.fieldRequired('reason')}
                                         handleSave={(aEvent) => this.handleMedicationDetailsChange(index, aEvent.target.value, 'medication_reason')}
                                         debounceTimeout={200}
                    />
                    :
                    null
                  }
                </div>
                :
                null
              }
            
            </div>
          ))
          :
          null
        }
        
        {question.type === 'multi_medication' && !disabled ?
          <div key={'SurveyMedicationQuestionCell-Buttons' + question.key}
               className="survey-medication-button-container">
            <button className="survey-button-add-medication"
                    onClick={() => this.addMedication()}>
              {i18next.t('ADD_MEDICATION')}
            </button>
            
            {valuesArray && valuesArray.length > 1 ?
              <button className="survey-button-remove-medication"
                      onClick={() => this.removeMedication()}>
                {i18next.t('REMOVE_MEDICATION')}
              </button>
              :
              null
            }
          </div>
          :
          null
        }
      </div>
    )
  }
}
