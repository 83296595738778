import React from 'react'
import PanelSubSectionHeader from "../../../Custom UI/Headers/PanelSubSectionHeader/PanelSubSectionHeader"
import ProfilePicture from '../../../Custom UI/ProfilePicture/ProfilePicture'
import LabeledTextInput from '../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput'
import '../ManageSettings/ManageSettings.scss'
import { DEFAULT_COLORS } from 'constants/Colors'
import 'react-toastify/dist/ReactToastify.css'
import {Translation, withTranslation} from "react-i18next";


class ManageUserSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {}
        }
    }

    componentDidMount() {
         this.props.findUserById(this.props.userId).then(user => {
             this.setState({user: user.data.data.user});
         });
    }

    render() {
        return (
            <div className='profile-left-side'>
                <PanelSubSectionHeader groupColor={DEFAULT_COLORS.PANEL_GRAY}
                                       title={<Translation>{ (t, { i18n }) => t('PROFILE_HEADER') }</Translation>}
                />
                <div className="subsection-container">
                    <div className="my-profile-row">
                        <div className='profile-pic-container'>
                            <div className="flex-column">
                        <span className="subsection-heading">
                          <Translation>{ (t, { i18n }) => t('PROFILE_PICTURE_HEADER') }</Translation>
                        </span>
                                <ProfilePicture
                                    imageId={(this.state.user && this.state.user.image_id) || null}
                                    width={150}
                                    height={150}
                                    className='profile-pics'
                                    style={{ border: 'none', width: '150px', height: '150px' }}
                                    borderStyle={{ border: 'solid 1px #9b9b9b' }}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <LabeledTextInput width={250}
                                                  name="first_name"
                                                  value={this.state.user && this.state.user.first_name}
                                                  handleSave={this.handleSave}
                                                  disabled={true}
                                                  label={<Translation>{ (t, { i18n }) => t('FIRST_NAME_PROPER_CAPITALIZED') }</Translation>}
                                />
                                <LabeledTextInput width={250}
                                                  name="last_name"
                                                  value={this.state.user && this.state.user.last_name}
                                                  handleSave={this.handleSave}
                                                  disabled={true}
                                                  label={<Translation>{ (t, { i18n }) => t('LAST_NAME_PROPER_CAPITALIZED') }</Translation>}
                                />
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <LabeledTextInput width={250}
                                                  type="email"
                                                  name="email"
                                                  value={this.state.user && this.state.user.email}
                                                  disabled={true}
                                                  label={<Translation>{ (t, { i18n }) => t('EMAIL_ADDRESS') }</Translation>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ManageUserSettings)
