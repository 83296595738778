import React from 'react';
import TextObjective from '../Objectives/TextObjective/TextObjective'
import DateObjective from '../Objectives/DateObjective/DateObjective'
import TimeObjective from '../Objectives/TimeObjective/TimeObjective'
import ImageObjective from '../Objectives/ImageObjective/ImageObjective'
import SliderObjective from '../Objectives/SliderObjective/SliderObjective'
import RoutineObjective from '../Objectives/RoutineObjective/RoutineObjective'
import MultipleChoiceObjective from '../Objectives/MultipleChoiceObjective/MultipleChoiceObjective'
import ContentObjective from '../Objectives/ContentObjective/ContentObjective'
import ConsentObjective from "../Objectives/ConsentObjective/ConsentObjective";

const ToolPreviewObjective = ({objective, selectedTranslationIndex}) => {
  const objectiveComponents = {
    'TextObjective': TextObjective,
    'DateObjective': DateObjective,
    'TimeObjective': TimeObjective,
    'ImageObjective': ImageObjective,
    'SliderObjective': SliderObjective,
    'RoutineObjective': RoutineObjective,
    'MultipleChoiceObjective': MultipleChoiceObjective,
    'ContentObjective': ContentObjective,
    'ConsentObjective': ConsentObjective,
  }
  const Objective = objectiveComponents[objective.type]

  return (
    <div style={{marginBottom: '1.5em'}}>
      {Objective &&
          <Objective objective={objective}
                     selectedTranslationIndex={selectedTranslationIndex}/>
      }
    </div>
  )
}

export default ToolPreviewObjective
