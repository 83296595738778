import React from 'react';
import '../../Custom UI/common.scss'
import './CompanyNotificationReceipts.scss'
import ClearNotificationReceiptDropDown from "../../Custom UI/DropDowns/ClearNotificationReceiptDropDown/ClearNotificationReceiptDropDown";
import {Translation} from "react-i18next";
import Popper from "@material-ui/core/Popper";
import LabeledMultipleSelectDropDown from "../../Custom UI/LabeledInputs/LabeledMultipleSelectDropDown/LabeledMultipleSelectDropDown";
import {AlertTypes} from "../../constants/AlertTypes";
import MarkdownView from "react-showdown";
import {formatUserName} from "../../Helpers/AdminHelpers";
import {CheckCircle} from "@material-ui/icons";
import Popover from "antd/lib/popover";
import Icon from "antd/lib/icon";
import DynamicList from "../../Lists/DynamicList/DynamicList";
import {withRouter} from "react-router-dom";
import {labelForAlertType} from "../../Helpers/Helpers";

// TODO: Rename to AlertsList.
class CompanyNotificationReceipts extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      shouldReload:false,
      excludeCleared:false,
      menuAnchorElement:null,
      advancedSearchTerms:{},
      selectedNotification:null,
      alertToRemove:null,
      clearDropdownReceiptId:null,
      commentPopperAnchorElement:null
    };
    if(props.user && props.user.search_preference != null){
      Object.keys(props.user.search_preference).forEach(key => {
        this.state.advancedSearchTerms[key] = props.user.search_preference[key];
      })
    }
    this.clearReceipt = this.clearReceipt.bind(this);
    this.closeDropDowns = this.closeDropDowns.bind(this);
    this.openClearDropdown = this.openClearDropdown.bind(this);
    this.closeCommentPopper = this.closeCommentPopper.bind(this);
    this.renderAlertMessage = this.renderAlertMessage.bind(this);
    this.alertContainsSurvey = this.alertContainsSurvey.bind(this);
    this.toggleExcludeCleared = this.toggleExcludeCleared.bind(this);
    this.renderAlertClearedBy = this.renderAlertClearedBy.bind(this);
    this.handleAlertTypeChange = this.handleAlertTypeChange.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if(!this.props.patient && prevProps.group !== this.props.group){
      this.setState({shouldReload:true});
    }
  }
  
  // Methods
  
  closeCommentPopper(){
    this.setState({commentPopperAnchorElement:null, clearDropdownReceiptId:null});
  }
  
  toggleExcludeCleared() {
    this.setState({excludeCleared:!this.state.excludeCleared});
  }
  
  clearReceipt(note){
    const {clearCompanyNotificationReceipt} = this.props;
    const {selectedNotification} = this.state;
    this.closeCommentPopper();
    
    let company_notification_receipt_id = selectedNotification && selectedNotification.id;
    clearCompanyNotificationReceipt({company_notification_receipt_id, note}).then((newResult) => {
      const {company, patient, updateHandler} = this.props;
      
      if(company && company.number_of_uncleared_company_notification_receipts){
        company.number_of_uncleared_company_notification_receipts--;
      }
      if(updateHandler){
        updateHandler();
      }
      if(patient){
        if(patient.number_of_uncleared_company_notification_receipts){
          patient.number_of_uncleared_company_notification_receipts--;
        }
        this.setState({shouldReload:true})
      }
      else{
        this.setState({alertToRemove:selectedNotification});
      }
    })
  }
  
  openClearDropdown(companyNotificationReceipt, aMenuAnchorElement){
    this.setState({clearDropdownReceiptId:companyNotificationReceipt.id, commentPopperAnchorElement:aMenuAnchorElement});
  }
  
  closeDropDowns(){
    this.setState({clearDropdownReceiptId:null});
  }
  
  alertContainsSurvey(aAlert){
    return aAlert && aAlert.survey_id && aAlert.alert_type !== "mrf_alert" && aAlert.survey_id.length > 0;
  }
  
  handleAlertTypeChange(aOptionsArray){
    let selectedOptions = '';
    
    for(let index = 0; index < aOptionsArray.length; index += 1){
      let option = aOptionsArray[index];
      selectedOptions += option.value.toString();
      
      if(index < (aOptionsArray.length - 1)){
        selectedOptions += ',';
      }
    }
    if(selectedOptions.length === 0){
      selectedOptions = null;
    }
    let newSearchTerms = this.state.advancedSearchTerms;
    newSearchTerms.alert_type = selectedOptions;
    this.setState({advancedSearchTerms:newSearchTerms}, () => {
      this.props.updateSearchPreferences(this.state.advancedSearchTerms);
      this.setState({shouldReload:true});
    });
  }
  
  renderAlertMessage(aAlert){
    return (
      <MarkdownView markdown={aAlert.message}
                    options={{openLinksInNewWindow:true, emoji:true}}
      />
    );
  }
  
  renderAlertClearedBy(aAlert){
    let returnValue = null;
    
    if(aAlert && aAlert.cleared_by_user){
      returnValue = (
        <div style={{display:'flex', alignItems:'center'}}>
          <CheckCircle className="checkmark" style={{color:'green'}} />
          
          <div>
            {formatUserName(aAlert.cleared_by_user)}
          </div>
          
          {/*TODO: Replace with the Info Component.*/}
          {aAlert.notes ?
            <Popover content={aAlert.notes} placement="bottom">
              <div className="notes-popover-button">
                <Icon type="ellipsis"/>
              </div>
            </Popover>
            :
            null
          }
        </div>
      );
    }
    return returnValue;
  }
  
  handleCellClick(aAlert){
    let patientLink = '#';
    
    if(aAlert && aAlert.user){
      let patientId = aAlert.user.id;
      
      if(this.props.groupName){
        let groupSlug = this.props.groupName.replaceAll(' ', '_');
        patientLink = '/groups/' + groupSlug + '/patients/' + patientId;
      }
      else{
        patientLink = '/patients/' + patientId;
      }
      if(aAlert.alert_type === "mrf_alert"){
        patientLink += '?section=questionnaire';
      }
      else{
        patientLink += '?section=alert';
      }
      //patientLink += '?section=alert&id=' + this.props.alert.id;
      this.props.history.push(patientLink);
    }
  }
  
  shouldRedirectToPatientProfile(aAlert){
    const {onAlertClick, redirectToPatientProfile} = this.props;
    return (onAlertClick ? (this.alertContainsSurvey(aAlert) ? false : redirectToPatientProfile) : redirectToPatientProfile);
  }
  // Render
  
  render() {
    const {clearDropdownReceiptId, advancedSearchTerms, shouldReload, alertToRemove, height} = this.state;
    const {group, patient, scrollableClassName, onAlertClick, explanationKey, loadCompanyNotificationReceipts, showClearedByColumn = false} = this.props;
    
    let alertOptions = [];
    
    AlertTypes.forEach((alert) => {
      alertOptions.push({value:alert.value, label:alert.text});
    });
    
    let currentAlertTypes = advancedSearchTerms.alert_type;
    
    let selectedAlertOptionsArray = [];
    
    if(currentAlertTypes){
      for(let alertType of currentAlertTypes.toString().split(',')){
        let alertOption = alertOptions.filter(function(option){
          return option.value.toString() === alertType;
        });
        
        if(alertOption.length > 0){
          selectedAlertOptionsArray.push(alertOption[0]);
        }
      }
    }
    let columnsArray = [];
    
    if(!patient){
      columnsArray.push({key:'user', columnWeight:1, columnNameKey:'PATIENT_NAME', propertiesArray:['user'], valueFormatter:(aUser) => formatUserName(aUser)});
    }
    columnsArray.push({key:'message', columnWeight:2, columnNameKey:'MESSAGE', templateCell:(aAlert) => this.renderAlertMessage(aAlert)});
    columnsArray.push({key:'alert_type', columnWeight:1, columnNameKey:'SUBJECT_ALERT_TYPE', propertiesArray:['alert_type'], valueFormatter:(aAlertType) => labelForAlertType(aAlertType)});
    columnsArray.push({key:'created_at', columnWeight:1, columnNameKey:'CREATED_AT', propertiesArray:['created_at'], dateFormat:'MM/DD/YYYY [@]h:mma'});
    
    if(showClearedByColumn){
      columnsArray.push({key:'cleared_by_user', columnWeight:1, columnNameKey:'CLEARED_BY', templateCell:(aAlert) => this.renderAlertClearedBy(aAlert, !patient)});
    }
    
    let menuItemsArray = [];
    menuItemsArray.push({title:'CLEAR', clickAction:(aNotification, aRow, aEvent, aMenuAnchorElement) => {
        this.setState({selectedNotification:aNotification});
        this.openClearDropdown(aNotification, aMenuAnchorElement);
      },isValid:(aNotification) => aNotification && !aNotification.cleared_by_user});
    
    // TODO: Add this option in when Alerts support multiple comments.
    // menuItemsArray.push({title:'ADD_COMMENT', clickAction:(aNotification) => {
    //     this.setState({selectedNotification:aNotification});
    //     this.openSurvey(aNotification);
    //   }, isValid:(aNotification) => aNotification && aNotification.target_type === 'Note'});
    
    return (
      <div>
        <div className={'alert-cell-container' + scrollableClassName ? scrollableClassName : ''}
             style={{height:height ? height : "auto"}}
             id="alerts-list">
          <DynamicList id="active-alerts-list"
                       didReload={() => this.setState({shouldReload:false})}
                       menuCancel={() => this.setState({selectedFraudEvent:null})}
                       selectCell={(aAlert) => (this.shouldRedirectToPatientProfile(aAlert) ? this.handleCellClick(aAlert) : (onAlertClick ? onAlertClick(aAlert) : null))}
                       headerView={group ?
                         <LabeledMultipleSelectDropDown className="company-notification-alert-input"
                                                        value={selectedAlertOptionsArray}
                                                        options={alertOptions}
                                                        placeholder={<Translation>{ (t, { i18n }) => t('SELECT_ALERT_TYPE') }</Translation>}
                                                        handleSave={(aOptionsArray) => this.handleAlertTypeChange(aOptionsArray)}
                         />
                         :
                         null
                       }
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset, aSearchString) => loadCompanyNotificationReceipts(aSearchString, patient ? patient.id : null, group ? group.id : null, aOffset / aLimit + 1, aLimit, !(patient && patient.id), advancedSearchTerms)}
                       shouldReload={shouldReload}
                       menuItemsArray={menuItemsArray}
                       objectToDelete={alertToRemove}
                       totalObjectsKey='PATIENT_ALERTS'
                       explanationKey={explanationKey}
                       finishedDeletingObject={() => this.setState({alertToRemove:null})}
                       responseTotalKeysArray={['data', 'data', 'company_notification_receipt_page', 'total']}
                       responseObjectKeysArray={['data', 'data', 'company_notification_receipt_page', 'company_notification_receipts']}
          />
        </div>
        
        {clearDropdownReceiptId ?
          <Popper id="alert-comment-popper"
                  open={Boolean(this.state.commentPopperAnchorElement)}
                  style={{zIndex: 4}}
                  anchorEl={this.state.commentPopperAnchorElement}>
            <ClearNotificationReceiptDropDown clearReceipt={(note) => {this.clearReceipt(note)}}
                                              closeDropdown={this.closeDropDowns}
                                              addPositioning={true}
            />
          </Popper>
          :
          null
        }
      </div>
    )
  }
}

export default withRouter(CompanyNotificationReceipts);
