import actions from 'actions';
import {connect} from 'react-redux';
import ManageSettings from 'Pages/Settings/ManageSettings/ManageSettings'

const mapStateToProps = (state, ownProps) => {
  return {
    user:state.current && state.current.user,
    groups:state.company && state.company.company && state.company.company.groups,
    company:state.company && state.company.company,
    studyClosed:state.company && state.company.company && state.company.company.status === 'closed'
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentUser:(user) => {
      return dispatch(actions.updateCurrentUser(user));
    },
    updateCurrentUserImage:(file) => {
      return dispatch(actions.updateCurrentUserImage(file));
    },
    updateCompanyLogoImage:(file, aThemeId) => {
      return dispatch(actions.updateCompanyLogoImage(file, aThemeId));
    },
    fetchUsers:() => {
      dispatch(actions.fetchUsers());
    },
    loadUser:() => {
      return dispatch(actions.loadUser());
    },
    logout:() => {
      dispatch(actions.logout());
    },
    closeStudy:(aCloseoutSignature, aAdminEmail, aAdminPassword) => {
      return dispatch(actions.closeStudy(aCloseoutSignature, aAdminEmail, aAdminPassword));
    },
    findUserById:(id) => {
      return dispatch(actions.findUserById(id));
    },
    updateCompany:(aCompanyId, aStudyName, aLocale, aLastEnrolmentDate, aPaymentType, aHSCTimeoutInMinutes) => {
      return dispatch(actions.updateCompany(aCompanyId, aStudyName, aLocale, aLastEnrolmentDate, aPaymentType, aHSCTimeoutInMinutes));
    },
    loadCompany:() => {
      return dispatch(actions.loadCompany());
    },
    updateCompanyHSFeature:(aFeatureId, aEnabled) => {
      return dispatch(actions.updateCompanyHSFeature(aFeatureId, aEnabled));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSettings);
