import * as current from './current';
import * as admin from './admin';
import * as admins from './admins';
import * as patient from './patient';
import * as patients from './patients';
import * as conditions from './conditions';
import * as notes from './notes';
import * as medications from './medications';
import * as companyNotificationReceipts from './company-notification-receipts';
import * as virtualAppointments from './virtual_appointments';
import * as AdminHistoryActions from './AdminHistoryActions';
import * as surveyActions from "./surveyActions";
import * as surveyResponses from "./surveyResponses";
import * as paymentActions from "./payments";
import * as paymentTriggerActions from "./paymentTriggers";
import * as paymentCredentialActions from "./paymentCredentials";
import * as dataQueryActions from "./dataQueries";
import * as groups from './groups';
import * as surveys from './surveys';
import * as scheduledSurveys from './scheduledSurveys';
import * as company from './company';
import * as tools from './tools';
import * as symptoms from './symptoms';
import * as locales from './locales';
import * as hsFeatures from './hs_feature';
import * as telehealthActions from './telehealthActions';
import * as articles from './articles';
import * as campaigns from './campaigns';
import * as fraudEvents from './fraudEvents';
import * as allergies from './allergies';
import * as medicationAllergies from './medicationAllergies';
import * as reports from './reports';
import * as triggers from './triggers';
import * as surveyBuilder from './surveyBuilder';
import * as surveyLists from './surveyLists';
import * as dynamicLists from './dynamicLists';
import * as entries from './entries';
import * as reportTemplates from './reportTemplates';
import * as sidebar from './sidebar';
import * as medicalRecords from './medicalRecords';
import * as surveyLinks from './surveyLinks';
import * as schedules from './schedules';
import * as additionalAppColumns from './additionalAppColumnUserJoins';
// import * as conversations from './conversations';

const Actions = {
  ...current,
  ...admin,
  ...admins,
  ...patient,
  ...patients,
  ...conditions,
  ...medications,
  // ...conversations,
  ...groups,
  ...company,
  ...tools,
  ...symptoms,
  ...locales,
  ...companyNotificationReceipts,
  ...notes,
  ...virtualAppointments,
  ...telehealthActions,
  ...notes,
  ...AdminHistoryActions,
  ...surveyActions,
  ...surveyResponses,
  ...hsFeatures,
  ...paymentActions,
  ...paymentTriggerActions,
  ...paymentCredentialActions,
  ...dataQueryActions,
  ...surveys,
  ...scheduledSurveys,
  ...articles,
  ...campaigns,
  ...fraudEvents,
  ...allergies,
  ...medicationAllergies,
  ...reports,
  ...triggers,
  ...surveyLists,
  ...dynamicLists,
  ...surveyBuilder,
  ...entries,
  ...reportTemplates,
  ...sidebar,
  ...surveyLinks,
  ...schedules,
  ...additionalAppColumns,
  ...medicalRecords
};

export default Actions;
