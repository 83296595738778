import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import './SurveyTriggersList.scss';
import DynamicList from "../DynamicList/DynamicList";
import {roleForCompany} from "../../Helpers/CompanyHelpers";

export default class SurveyTriggersList extends React.Component {
  
  // Instance Variables
  
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  
  
  // Methods
  
  
  // Render
  
  render() {
    const {company, fetchSurveyTriggers} = this.props;
    
    const currentRole = roleForCompany(company);
    const triggerPermissions = ['admin', 'admin_level_1', 'admin_level_2', 'admin_level_3', 'admin_level_4', 'admin_level_10'];
  
    let columnsArray = [];
    columnsArray.push({key:'survey_name', columnWeight:1, columnNameKey:'SURVEY_NAME', propertiesArray:['survey_name']});
    columnsArray.push({key:'survey_reference', columnWeight:1, columnNameKey:'SURVEY_REFERENCE_SPACED', propertiesArray:['survey_reference']});
    columnsArray.push({key:'question_key', columnWeight:1, columnNameKey:'QUESTION_KEY_SPACED', propertiesArray:['question_key']});
    columnsArray.push({key:'trigger_type', columnWeight:1, columnNameKey:'TRIGGER_TYPE', propertiesArray:['trigger_type']});
    columnsArray.push({key:'conditions', columnWeight:1, columnNameKey:'CONDITIONS', propertiesArray:['operation'], valueFormatter:(aValue, aTrigger) => {
      return aValue + ' ' + aTrigger.value;
      }});
    
    return (
      <div className="main-panel-inner-container">
        {triggerPermissions.includes(currentRole) ?
          <div className="survey-trigger-list-container"
               id="survey-triggers-list">
            <DynamicList id="survey-triggers-list"
                         columnsArray={columnsArray}
                         fetchObjects={(aLimit, aOffset, aSearchString) => fetchSurveyTriggers(aLimit, aOffset, aSearchString, null, null)}
                         totalObjectsKey='TOTAL_TRIGGERS'
                         responseTotalKeysArray={['data', 'data', 'survey_configurable_triggers', 'total']}
                         responseObjectKeysArray={['data', 'data', 'survey_configurable_triggers', 'triggers']}
            />
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t) => t('TRIGGER_ACCESS_BLOCKED')}</Translation>
          </div>
        }
      </div>
    )
  }
}
