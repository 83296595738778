import React from 'react';
import '../../Custom UI/common.scss'
import 'antd/dist/antd.css'
import {Translation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import './GroupCodesList.scss';
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import {roleForCompany} from "../../Helpers/CompanyHelpers";
import fileDownload from "js-file-download";
import {currentDateHumanizedWithTimeStamp} from "../../Helpers/DateHelpers";
import GroupCodesHistoryModal from 'Modals/GroupCodesHistoryModal/GroupCodesHistoryModal';
import DynamicList from "../DynamicList/DynamicList";
import i18n from "../../i18n";
import {objectsAreEqual, errorMessageFromServerError} from "../../Helpers/Helpers";

const groupCodeDownloadError = (aError) => toast(<Translation>{(t, { i18n }) => t('GROUP_CODE_DOWNLOAD_ERROR', {error: aError})}</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const groupCodeDownloadSuccess = () => toast(<Translation>{(t, { i18n }) => t('GROUP_CODE_DOWNLOAD_SUCCESS')}</Translation>, NOTIFY_OPTS.autoClose);
const notifyGroupCodeCreateError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_CREATING_GROUP_CODE', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyGroupCodeRemoveError = (aError) => toast(<Translation>{ (t, { i18n }) => t('ERROR_REMOVING_GROUP_CODE', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyGroupCodeCreateSuccess = () => toast(<Translation>{ (t, { i18n }) => t('GROUP_CODE_CREATE_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);
const notifyGroupCodeRemoveSuccess = () => toast(<Translation>{ (t, { i18n }) => t('NOTIFY_GROUP_CODE_DEACTIVATION_SUCCESS') }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

export default class GroupCodesList extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      group:this.props.group,
      shouldReload:false,
      selectedGroup:this.props.group,
      menuAnchorElement:null,
      selectedGroupCode:null,
      openGroupCodesModal:false,
      openConfirmationModal:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.createGroupCode = this.createGroupCode.bind(this);
    this.removeGroupCode = this.removeGroupCode.bind(this);
    this.menuButtonClicked = this.menuButtonClicked.bind(this);
    this.downloadGroupCodeHistory = this.downloadGroupCodeHistory.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.group && this.props.group && !objectsAreEqual(prevProps.group, this.props.group)){
      this.setState({selectedGroup:this.props.group, menuAnchorElement:null, selectedGroupCode:null, openGroupCodesModal:false, openConfirmationModal:false, shouldReload:true});
    }
  }
  
  // Methods
  
  createGroupCode(){
    this.closeMenu(true);
    this.props.createGroupCode(this.state.selectedGroup.id).then((newResult) => {
      notifyGroupCodeCreateSuccess();
      this.setState({shouldReload:true})
    }, (newError) => {
      notifyGroupCodeCreateError(errorMessageFromServerError(newError));
    });
  }
  
  removeGroupCode(aGroupCode){
    this.closeMenu();
    this.props.removeGroupCode(aGroupCode.id).then((newResult) => {
      notifyGroupCodeRemoveSuccess();
      this.setState({shouldReload:true})
    }, (newError) => {
      notifyGroupCodeRemoveError(errorMessageFromServerError(newError));
    });
  }
  
  closeMenu(aDelay = false){
    this.setState({menuAnchorElement:null}, () => {
      if(aDelay){
        setTimeout(() => {
          this.setState({selectedGroupCode:null, selectedGroup: null, openGroupCodesModal: false, GroupCodesHistoryModal: false, openConfirmationModal: false});
        }, 225);
      }
      else{
        this.setState({selectedGroupCode:null, selectedGroup: null, openGroupCodesModal: false, GroupCodesHistoryModal: false, openConfirmationModal: false});
      }
    });
  }
  
  menuButtonClicked(aEvent, aGroupCode, aGroup){
    this.setState({menuAnchorElement:aEvent.currentTarget, selectedGroupCode:aGroupCode, selectedGroup:aGroup});
  };
  
  downloadGroupCodeHistory(groupId, companyId){
    const {downloadGroupCodeHistory} = this.props;
    this.closeMenu();
    
    if(downloadGroupCodeHistory){
      downloadGroupCodeHistory(groupId, companyId).then((newResult) => {
        groupCodeDownloadSuccess();
        fileDownload(newResult.data, `group_code_history_report_${currentDateHumanizedWithTimeStamp()}.csv`);
      }, (newError) => {
        groupCodeDownloadError(errorMessageFromServerError(newError));
      });
    }
  }
  
  // Render
  
  render() {
    const {selectedGroupCode, selectedGroup, menuAnchorElement, openConfirmationModal, openGroupCodesModal, shouldReload} = this.state;
    const {company, fetchCodesForGroup, group, groupList} = this.props;
    
    const groupCodeProhibitions = ['provider', 'admin_level_1', 'admin_level_7', 'admin_level_9', 'admin_level_11'];
    
    const currentRole = roleForCompany(company);
    
    let columnsArray = [];
    columnsArray.push({key:'code', columnWeight:1, columnNameKey:'CODE', propertiesArray:['code']});
    
    if(!group){
      columnsArray.push({key:'group.name', columnWeight:1, columnNameKey:'GROUP', propertiesArray:['group', 'name']});
    }
    columnsArray.push({key:'users_count', columnWeight:1, columnNameKey:'USED', propertiesArray:['users_count'], valueFormatter:(aValue) => {
        let returnValue = aValue;
        
        if(aValue < 2){
          returnValue = aValue + ' ' + i18n.t('TIME_LOWERCASE');
        }
        else if(aValue > 1){
          returnValue = aValue + ' ' + i18n.t('TIMES');
        }
        return returnValue;
      }});
    columnsArray.push({key:'last_used', columnWeight:1, columnNameKey:'LAST_USED', propertiesArray:['last_used']});
    
    let menuItemsArray = [{title:'REMOVE', clickAction:(aObject) => this.setState({openConfirmationModal:true, selectedGroupCode:aObject})}];
    return (
      <div>
        {!groupCodeProhibitions.includes(currentRole) ?
          <div className={group ? "group-codes-list-container" : "manage-patients-group-codes-list-container"}>
            <DynamicList id="group-codes-list"
                         didReload={() => this.setState({shouldReload:false})}
                         menuCancel={() => this.setState({selectedGroup:null})}
                         shouldReload={shouldReload}
                         columnsArray={columnsArray}
                         fetchObjects={(aLimit, aOffset) => fetchCodesForGroup(group ? group.id : null, aOffset, aLimit)}
                         showSearchBar={false}
                         menuItemsArray={menuItemsArray}
                         minColumnWidth={100}
                         addButtonClicked={(aEvent) => {
                           aEvent.stopPropagation();
                           this.menuButtonClicked(aEvent, null, group);
                         }}
                         responseTotalKeysArray={['data', 'data', 'group_codes', 'total']}
                         responseObjectKeysArray={['data', 'data', 'group_codes', 'group_codes']}
            />
            
            {!openConfirmationModal && !openGroupCodesModal &&
              <Menu id="group-codes-list-menu"
                    open={Boolean(menuAnchorElement)}
                    anchorEl={menuAnchorElement}
                    onClose={() => this.closeMenu(true)}
                    keepMounted>
                {selectedGroup ?
                  <div>
                    <MenuItem onClick={() => this.createGroupCode(selectedGroup.id)}>
                      <Translation>{(t, {i18n}) => t('CREATE_GROUP_CODE')}</Translation>
                    </MenuItem>
                    
                    <MenuItem onClick={() => this.setState({openGroupCodesModal:true})}>
                      <Translation>{(t, {i18n}) => t('VIEW_GROUP_CODES_HISTORY')}</Translation>
                    </MenuItem>
                    
                    <MenuItem onClick={() => this.downloadGroupCodeHistory(selectedGroup.id, company.id)}>
                      <Translation>{(t, {i18n}) => t('DOWNLOAD_GROUP_CODES_HISTORY')}</Translation>
                    </MenuItem>
                  </div>
                  :
                  <div>
                    {groupList ?
                      <div>
                        <p style={{paddingLeft:16, paddingRight:16, fontSize:16, marginBottom:3}}><Translation>{(t, {i18n}) => t('SELECT_GROUP')}</Translation> </p>
                        
                        {groupList.map((group) => {
                          return (
                            <MenuItem onClick={(aEvent) => {
                              aEvent.stopPropagation();
                              this.menuButtonClicked(aEvent, null, group);
                            }}>
                              {company && company.core_group && company.core_group.id === group.id ? <Translation>{(t, {i18n}) => t('ALL_PATIENTS')}</Translation> : group.name}
                            </MenuItem>
                          );
                        })}
                      </div>
                      :
                      <Translation>{(t, {i18n}) => t('NO_GROUPS')}</Translation>
                    }
                  </div>
                }
              </Menu>
            }
          </div>
          :
          <div className="no-information-text">
            <Translation>{(t, {i18n }) => t('GROUP_CODES_BLOCKED')}</Translation>
          </div>
        }
        
        {/*TODO: Use the Confirmation Modal instead.*/}
        {openConfirmationModal ?
          <GeneralModal title={<Translation>{(t, {i18n}) => t('CONFIRM_REMOVE_CODE')}</Translation>}
                        width={'300px'}
                        isOpen={openConfirmationModal}
                        isEditing={false}
                        handleSave={() => {
                          this.removeGroupCode(selectedGroupCode);
                          this.setState({openConfirmationModal:false});
                        }}
                        noTitleHeading={true}
                        showSaveButton={true}
                        saveButtonTitle={<Translation>{(t, {i18n}) => t('REMOVE')}</Translation>}
                        handleCloseModal={() => this.closeMenu()}
                        showCancelButton={true}
                        clickOutsideToClose={true}>
          </GeneralModal>
          :
          null
        }
        
        {openGroupCodesModal ?
          <GroupCodesHistoryModal group={selectedGroup}
                                  width={'auto'}
                                  isOpen={openGroupCodesModal}
                                  company={company}
                                  maxWidth={'500px'}
                                  handleCloseModal={this.closeMenu}
                                  fetchCodesForGroup={fetchCodesForGroup}
                                  downloadGroupCodeHistory={this.downloadGroupCodeHistory}
          />
          :
          null
        }
      </div>
    )
  }
}
