import moment from "moment/moment";
import {ScheduleTriggerConditionEventTypes, ScheduleTriggerFireEventTypes, ScheduleTriggerTimeBasedEventTypes} from "../constants/ScheduleTriggerTypes";

export function detailViewForTrigger(aTrigger, aClick){
  let returnValue = null;
  
  if(aTrigger){
    // TODO: Add messages.
  }
  
  return (
    <div style={{width:'100%', height:'50px', display:'flex'}}
         onClick={aClick}>
      {returnValue}
    </div>
  );
}

export function detailViewForEndTrigger(aTrigger, aClick){
  let returnValue = null;
  
  if(aTrigger){
    // TODO: Add messages.
  }
  
  return (
    <div style={{width:'100%', height:'50px', display:'flex'}}
         onClick={aClick}>
      {returnValue}
    </div>
  );
}

export function detailViewForStartTrigger(aTrigger, aClick){
  let returnValue = null;
  
  if(aTrigger){
    // TODO: Add messages.
  }
  
  return (
    <div style={{width:'100%', height:'50px', display:'flex'}}
         onClick={aClick}>
      {returnValue}
    </div>
  );
}

export function detailViewForPaymentTrigger(aTrigger, aClick){
  let returnValue = null;
  
  if(aTrigger){
  
  }
  
  return (
    <div style={{width:'100%', height:'50px', display:'flex'}}
         onClick={aClick}>
      {returnValue}
    </div>
  );
}

export function translationKeyForTriggerType(aTriggerType){
  let returnValue = '';
  
  if(aTriggerType){
    for(let key of Object.keys(ScheduleTriggerFireEventTypes)){
      if(key === aTriggerType){
        returnValue = ScheduleTriggerFireEventTypes[aTriggerType];
      }
    }
    for(let key of Object.keys(ScheduleTriggerConditionEventTypes)){
      if(key === aTriggerType){
        returnValue = ScheduleTriggerConditionEventTypes[aTriggerType];
      }
    }
    for(let key of Object.keys(ScheduleTriggerTimeBasedEventTypes)){
      if(key === aTriggerType){
        returnValue = ScheduleTriggerTimeBasedEventTypes[aTriggerType];
      }
    }
  }
  return returnValue;
}

export function scheduleVersionNumber(aSchedule, aDraft = true){
  let returnValue = 0;
  
  if(aSchedule){
    if(aDraft){
      if(aSchedule.draft_schedule_version){
        returnValue = aSchedule.draft_schedule_version.version_number;
      }
    }
    else{
      if(aSchedule.current_schedule_version){
        returnValue = aSchedule.current_schedule_version.version_number;
      }
    }
  }
  return returnValue;
}

export function scheduleLastUpdatedByUser(aSchedule){
  let returnValue = null;
  
  if(aSchedule){
    if(aSchedule.draft_schedule_version){
      returnValue = aSchedule.draft_schedule_version.updated_by_user;
    }
    else if(aSchedule.current_schedule_version){
      returnValue = aSchedule.current_schedule_version.updated_by_user;
    }
  }
  return returnValue;
}

export function scheduleIsLocked(aSchedule, aUser){
  let returnValue = false;
  
  if(aSchedule){
    let lastUpdatedByUser = scheduleLastUpdatedByUser(aSchedule);
    
    if(aSchedule.updated_at && aUser && lastUpdatedByUser && aUser.id.toString() !== lastUpdatedByUser.id.toString()){
      if(moment(aSchedule.updated_at).diff(moment(), 'minutes') > -10){
        returnValue = true;
      }
    }
  }
  return returnValue;
}
