import React from 'react';
import './SurveyBuilderPageCell.scss';
import Menu from "@material-ui/core/Menu";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {Translation} from "react-i18next";
import LabeledTextInput from "../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import {KeyForTranslation} from "../../../Helpers/SurveyBuilderHelpers";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {BiDotsVerticalRounded} from "react-icons/bi";
import SurveyBuilderSurveyCell from "../SurveyBuilderSurveyCell/SurveyBuilderSurveyCell";
import SurveyBuilderQuestionCell from "../SurveyBuilderQuestionCell/SurveyBuilderQuestionCell";

export default class  SurveyBuilderPageCell extends React.Component{
  
  // Instance Variables
  
  containerRef = React.createRef();
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      menuAnchorElement:null,
      menuSelectedSurvey:null,
      menuSelectedQuestion:null
    };
  }
  
  // Render
  
  render(){
    const {page, client, selectedLanguage, selectQuestion, selectSurvey, updateSurvey, updateQuestion, selectedSurvey, selectedQuestion,
      menuButtonClicked, updatePage, addQuestionPageAfterPage, addSurveyPageAfterPage, addQuestionToPage, translationsArray,
      addSurveyToPage, errorForIdTypeAndKey, fetchSurveyVersionCollections,
      fetchDynamicLists, fetchDynamicListByReference, addSurveyList, scrollBounds,
      fetchSurveyLists, fetchSurveyListById, fetchSurveyListByReference, company, validateRubularRegex, fetchQuestionsForSurveyByReference,
      moveSurveyFromPageToPage, moveQuestionFromPageToPage, pagesArray, fetchDynamicListById} = this.props;
    const {menuAnchorElement, menuSelectedSurvey, menuSelectedQuestion} = this.state;
    
    const labelKey = KeyForTranslation('page_title', selectedLanguage);
    
    let moveToPageOptionsArray = [];
    
    for(let moveToPage of pagesArray){
      if(moveToPage.id !== page.id && moveToPage.isSurveyPage === page.isSurveyPage){
        moveToPageOptionsArray.push(moveToPage);
      }
    }
    
    let containerHeight = 0;
  
    if(this.containerRef && this.containerRef.current && this.containerRef.current.getBoundingClientRect()){
      containerHeight = this.containerRef.current.getBoundingClientRect().height;
    }
    
    return(
      <div className="survey-builder-page-cell-container"
           ref={this.containerRef}>
        <div className="survey-builder-page-cell">
          <span className="survey-builder-page-header">
            <LabeledTextInput className="survey-builder-page-input"
                              value={page.key}
                              // value={page.key + ' y:' + page.y + ' h:' + page.height + ' c:' + (containerHeight + 20) + ' = ' + (page.height === (containerHeight + 20) ? 'YES' : 'NO')}
                              disabled={true}
                              warningMessage={errorForIdTypeAndKey(page.id, 'warning', 'emptyPage').message}
            />
  
            {page.key !== 'end' && page.key !== 'disqualified' ?
              <LabeledDelayedInput className="survey-builder-page-input-title"
                                   label={i18next.t('PAGE_TITLE') + ' - ' + selectedLanguage.display_name}
                                   value={page[labelKey]}
                                   infoBlurb={<Translation>{(t, { i18n }) => t('PAGE_TITLE_EXPLANATION')}</Translation>}
                                   minLength={0}
                                   handleSave={(aEvent) => {
                                     page[labelKey] = aEvent.target.value;
                                     updatePage(page);
                                   }}
                                   warningMessage={errorForIdTypeAndKey(page.id, 'warning', labelKey).message}
              />
              :
              null
            }
  
            {menuButtonClicked ?
              <BiDotsVerticalRounded style={{fontSize:'21px', alignSelf:'baseline'}}
                                     onClick={(aEvent) => menuButtonClicked(aEvent)}
              />
              :
              null
            }
          </span>
  
          {page.isSurveyPage ?
            <>
              {page.surveysArray.map((survey, index) => (
                <div key={survey.id + '-' + index}
                     onClick={() => selectSurvey(survey)}>
                  {(survey.y + survey.height) < scrollBounds.y - 500 || (scrollBounds.y + scrollBounds.height) < survey.y - 500 ?
                    <div style={{height:(survey.height + 20)}}/>
                    :
                    <SurveyBuilderSurveyCell key={'survey-builder-survey-cell-' + survey.id + '-' + index}
                                             survey={survey}
                                             updateSurvey={updateSurvey}
                                             isSelectedSurvey={Boolean(selectedSurvey) && survey.id === selectedSurvey.id}
                                             menuButtonClicked={(aEvent) => {
                                               aEvent.preventDefault();
                                               aEvent.stopPropagation();
                                               this.setState({menuAnchorElement:aEvent.currentTarget, menuSelectedSurvey:survey, menuSelectedQuestion:null});
                                             }}
                                             errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                             fetchSurveyVersionCollections={fetchSurveyVersionCollections}
                                             fetchQuestionsForSurveyByReference={fetchQuestionsForSurveyByReference}
                    />
                  }
                </div>
              ))}
      
              <div className="survey-builder-page-add-button"
                   onClick={() => addSurveyToPage(page)}>
                <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
        
                <Translation>{(t, {i18n}) => t('ADD_SURVEY')}</Translation>
              </div>
            </>
            :
            <>
              {page.questionsArray.map((question, index) => (
                <div key={question.id + '-' + index}
                     onClick={() => selectQuestion(question)}>
                  {(question.y + question.height) < scrollBounds.y - 500 || (scrollBounds.y + scrollBounds.height) < question.y - 500 ?
                    <div style={{height:(question.height + 20)}}/>
                    :
                    <SurveyBuilderQuestionCell key={'survey-builder-question-cell-' + question.type + '-' + index}
                                               client={client}
                                               company={company}
                                               question={question}
                                               addSurveyList={addSurveyList}
                                               selectQuestion={selectQuestion}
                                               updateQuestion={updateQuestion}
                                               fetchSurveyLists={fetchSurveyLists}
                                               selectedLanguage={selectedLanguage}
                                               fetchDynamicLists={fetchDynamicLists}
                                               menuButtonClicked={(aEvent) => {
                                                 aEvent.preventDefault();
                                                 aEvent.stopPropagation();
                                                 this.setState({menuAnchorElement:aEvent.currentTarget, menuSelectedSurvey:null, menuSelectedQuestion:question});
                                               }}
                                               translationsArray={translationsArray}
                                               isSelectedQuestion={Boolean(selectedQuestion) && question.id === selectedQuestion.id}
                                               fetchSurveyListById={fetchSurveyListById}
                                               errorForIdTypeAndKey={(aId, aType, aKey) => errorForIdTypeAndKey(aId, aType, aKey)}
                                               fetchDynamicListById={fetchDynamicListById}
                                               validateRubularRegex={validateRubularRegex}
                                               fetchSurveyListByReference={fetchSurveyListByReference}
                                               fetchDynamicListByReference={fetchDynamicListByReference}
                    />
                  }
                </div>
              ))}
      
              <div className="survey-builder-page-add-button"
                   onClick={(aEvent) => {
                     aEvent.preventDefault();
                     aEvent.stopPropagation();
                     addQuestionToPage(page);
                   }}>
                <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
        
                <Translation>{(t, {i18n}) => t('ADD_QUESTION')}</Translation>
              </div>
            </>
          }
        </div>
        
        {page.showAddPageButtons ?
          <div className="survey-builder-page-button-container">
            <div className="survey-builder-page-button-add"
                 onClick={(aEvent) => {
                   aEvent.preventDefault();
                   aEvent.stopPropagation();
                   addQuestionPageAfterPage();
                 }}>
              <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
              
              <Translation>{(t, {i18n}) => t('ADD_QUESTION_PAGE')}</Translation>
            </div>
            
            <div className="survey-builder-page-button-add"
                 onClick={() => addSurveyPageAfterPage()}>
              <AddCircleOutlineIcon style={{height:'20px', width:'20px', color:'#2D81C9', marginRight:'5px'}}/>
              
              <Translation>{(t, {i18n}) => t('ADD_SURVEY_PAGE')}</Translation>
            </div>
          </div>
          :
          null
        }
        
        <Menu id="survey-builder-page-cell-menu"
              open={Boolean(menuAnchorElement)}
              style={{zIndex:9999}}
              onClose={() => this.setState({menuAnchorElement:null, menuSelectedSurvey:null, menuSelectedQuestion:null})}
              anchorEl={menuAnchorElement}
              keepMounted>
          {moveToPageOptionsArray.map((aToPage) => (
            <MenuItem key={'survey-page-cell-menu-item-move-to-page-' + aToPage.id}
                      style={{position:'relative'}}
                      onClick={(aEvent) => {
                        aEvent.preventDefault();
                        aEvent.stopPropagation();
              
                        if(selectedSurvey){
                          moveSurveyFromPageToPage(menuSelectedSurvey, page, aToPage);
                        }
                        else{
                          moveQuestionFromPageToPage(menuSelectedQuestion, page, aToPage);
                        }
                        this.setState({menuAnchorElement:null, menuSelectedSurvey:null, menuSelectedQuestion:null});
                      }}>
              <Translation>{(t, {i18n}) => t('MOVE_TO_PAGE', {aPage:aToPage.key})}</Translation>
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}
