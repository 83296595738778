import React from 'react';

const TextObjective = ({ objective, selectedTranslationIndex }) => (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <label>
      {objective.translations[selectedTranslationIndex].description}
    </label>
    { objective.numbers_only &&
        <input type="number" />
    }
    { !objective.numbers_only &&
        <textarea />
    }
  </div>
);

export default TextObjective
