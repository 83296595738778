import React, {useCallback, useEffect, useRef, useState} from 'react';
import Select from 'react-virtualized-select';
import './VirtualizedDropDown.scss';
import './../../LabeledInputs/LabeledDropDown/LabeledDropDown.scss';
import InfiniteScrollManager from "../../../managers/InfiniteScrollManager";
import debounce from 'lodash/debounce'
import classNames from "classnames";
import {Translation} from "react-i18next";

function optionRenderer({focusOption, key, option, selectValue, style}){
  let returnValue = null;
  
  if(option.type === 'header'){
    if(option.label !== null){
      returnValue = (
        <div key={key}
             style={{
               ...style,
               left:'0px',
               color:'rgb(153, 153, 153)',
               width:'calc(100% - 2px)',
               cursor:'default',
               display:'flex',
               fontSize:'11px',
               boxSizing:'border-box',
               alignItems:'center',
               fontWeight:500,
               paddingLeft:'12px',
               marginBottom:'0.25em',
               paddingRight:'12px',
               textTransform:'uppercase',
               backgroundColor:'#FFFFFF'
             }}>
          {option.label}
        </div>
      );
    }
  }
  else{
    returnValue = (
      <div key={key}
           style={style}
           onClick={() => selectValue(option)}
           onMouseEnter={() => focusOption(option)}>
        {option.label}
      </div>
    );
  }
  return returnValue;
}

const VirtualizedDropDown = ({onChange, onSearch, options, value, onMenuOpen, onMenuClose, onMenuScrollToBottom,
                               disabled, isClearable, onFocus, onBlur, placeholder, responseObjectKeysArray,
                               fetchObjects, totalObjects, objectsFilter, objectsReduce, responseTotalKeysArray, labelPropertiesArray,
                               valuePropertiesArray, warningMessage, errorMessage, customOptionRenderer, notifyFetchFailed,
                               multi, menuPosition, menuPlacement, showSearchStringInList, optionsArrayFormatter, extraOptionsArray,
                               customOptionHeight, doNotLoadOnMenuBottom, maxMenuHeight, optionFormatter, saveWithOptionsKey = false}) => {
  const [total, setTotal] = useState(0);
  const [rerender, setRerender] = useState(false);
  const [optionsArray, setOptionsArray] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [initialOptions, setInitialOptions] = useState(null);
  const [configuredInitialOptions, setConfiguredInitialOptions] = useState(null);
  
  const customOptionHeightsSelectRef = useRef(null);
  const scrollManagerRef = useRef(null);
  const {current:scrollManager} = scrollManagerRef;
  
  useEffect(() => {
    if(fetchObjects){
      selectFocused();
    }
  }, []);
  
  useEffect(() => {
    setSelectedValue(configureLabel(value));
  }, [value]);
  
  useEffect(() => {
    if(onSearch){
      onSearch(searchString);
    }
    if(!fetchObjects && showSearchStringInList && searchString && searchString.length > 0 && showSearchStringInList){
      let optionsList = [{value:searchString, label:searchString, isSearchString:true}];
      
      if(Array.isArray(options)){
        optionsList.push(...options);
      }
      setOptionsArray(optionsList);
    }
  }, [searchString]);
  
  // Methods
  
  const configureOptions = (aArray, aIsLabel = false) => {
    let returnValue = [];
    
    if(!aIsLabel && searchString && searchString.length > 0 && showSearchStringInList){
      returnValue.push({value:searchString, label:searchString, isSearchString:true, type:'hasCustomHeader', height:customOptionHeight});
    }
    if(fetchObjects){
      if(optionsArrayFormatter){
        let customConfiguredArray = optionsArrayFormatter(aArray, aIsLabel);
        
        if(customConfiguredArray && customConfiguredArray.length){
          returnValue.push(...customConfiguredArray);
        }
      }
      else{
        if(Array.isArray(labelPropertiesArray) && Array.isArray(valuePropertiesArray)){
          if(Array.isArray(aArray)){
            for(let index = 0; index < aArray.length; index++){
              if(optionFormatter){
                returnValue.push(optionFormatter(aArray[index]));
              }
              else{
                let label = aArray[index];
                let value = aArray[index];
                
                for(let index = 0; index < labelPropertiesArray.length; index += 1){
                  let property = labelPropertiesArray[index];
                  label = label[property];
                }
                for(let index = 0; index < valuePropertiesArray.length; index += 1){
                  let property = valuePropertiesArray[index];
                  value = value[property];
                }
                let configuredOption = {label:label, value:value, id:aArray[index].id};
                
                if(index === 0 && showSearchStringInList){
                  configuredOption['type'] = 'hasCustomHeader';
                  configuredOption['height'] = customOptionHeight;
                }
                returnValue.push(configuredOption);
              }
            }
          }
        }
        else{
          console.log('VirtualizedDropDown (getObjects): Missing props labelPropertiesArray:', labelPropertiesArray, 'and/or valuePropertiesArray:', valuePropertiesArray, '.');
          return Promise.resolve('');
        }
      }
    }
    return returnValue;
  };
  
  const valueForProperties = (aObject, aPropertiesArray) => {
    let returnValue = '';
    
    if(aObject){
      if(Array.isArray(aPropertiesArray)){
        let cascadedObject = aObject;
        
        for(let index = 0; index < aPropertiesArray.length; index += 1){
          let property = aPropertiesArray[index];
          cascadedObject = cascadedObject[property];
          
          if(cascadedObject === null || cascadedObject === undefined){
            if(index < (aPropertiesArray.length - 1)){
              console.log('VirtualizedDropDown (valueForProperties): Properties Array:', aPropertiesArray, 'at index:', index, 'has an invalid property:', property, '.');
            }
            cascadedObject = null;
            break;
          }
        }
        if(cascadedObject !== null && cascadedObject !== undefined){
          if(Array.isArray(cascadedObject)){
            returnValue = cascadedObject;
          }
        }
      }
      else{
        console.log('VirtualizedDropDown (valueForProperties): Properties Array provided:', aPropertiesArray, 'is not an array.');
      }
    }
    else{
      console.log('VirtualizedDropDown (valueForProperties): Object provided:', aObject, 'is invalid.');
    }
    return returnValue;
  };
  
  const getObjects = (aLimit, aOffset) => {
    if(fetchObjects && responseTotalKeysArray && responseObjectKeysArray){
      return fetchObjects(aLimit, aOffset, searchString).then((newResponse) => {
        let total = valueForProperties(newResponse, responseTotalKeysArray);
        let objectsArray = valueForProperties(newResponse, responseObjectKeysArray);
        setTotal(total.toInt);
        
        if(totalObjects && !(objectsFilter || objectsReduce)){
          totalObjects(total);
        }
        return {objects:objectsArray, total:total};
      }, (newError) => {
        return {objects:[], total:0};
      });
    }
    else{
      console.log('VirtualizedDropDown (getObjects): Missing props fetchObjects:', fetchObjects, 'and/or responseTotalKeysArray:', responseTotalKeysArray, 'and/or responseObjectKeysArray:', responseObjectKeysArray, '.');
      return Promise.resolve('');
    }
  };
  
  const updateObjectsArray = () => {
    if(scrollManager){
      let optionsList = configureOptions(scrollManager.getList());
      setOptionsArray([...optionsList]);
      
      if(!initialOptions){
        setInitialOptions([...scrollManager.getList()]);
        setConfiguredInitialOptions([...optionsList]);
      }
    }
  };
  
  const selectFocused = () => {
    if(fetchObjects){
      if(scrollManager){
        if(initialOptions && configuredInitialOptions){
          scrollManager.setList(initialOptions);
          setOptionsArray([...configuredInitialOptions]);
        }
        else{
          scrollManager.fetch(true);
        }
      }
      else{
        setRerender(!rerender);
        setTimeout(() => {
          scrollManagerRef.current.fetch(true);
        }, 250);
      }
    }
    else{
      setOptionsArray(options);
    }
    if(onMenuOpen){
      onMenuOpen();
    }
    if(onFocus){
      onFocus();
    }
  };
  
  const selectBlurred = (aEvent) => {
    if(onBlur){
      onBlur(aEvent);
    }
    if(onMenuClose){
      onMenuClose();
    }
  };
  
  const loadMoreOptions = () => {
    if(scrollManager){
      scrollManager.fetch(false);
    }
    if(onMenuScrollToBottom){
      onMenuScrollToBottom();
    }
    // if(customOptionHeightsSelectRef && customOptionHeightsSelectRef.current){
    //   customOptionHeightsSelectRef.current.recomputeOptionHeights();
    // }
  };
  
  const handleSearch = useCallback(debounce((aSearchString) => {
    setSearchString(aSearchString);
    
    if(fetchObjects && scrollManager){
      if(aSearchString === '' && configuredInitialOptions && initialOptions){
        setOptionsArray(configuredInitialOptions);
        scrollManager.setList([...initialOptions]);
      }
      else{
        if(scrollManager){
          scrollManager.fetch(true);
        }
      }
    }
    // if(customOptionHeightsSelectRef && customOptionHeightsSelectRef.current){
    //   customOptionHeightsSelectRef.current.recomputeOptionHeights();
    // }
  }, 500), [scrollManager]);
  
  const configureLabel = (aLabel) => {
    if(aLabel){
      if(Array.isArray(aLabel)){
        return aLabel[0];
      }
      else if(!fetchObjects){
        return aLabel;
      }
      else if(fetchObjects && saveWithOptionsKey){
        let returnValue = aLabel;
        
        for(let option of optionsArray){
          if(option.value === aLabel){
            returnValue = option;
            break;
          }
        }
        return returnValue;
      }
      else{
        return configureOptions([aLabel], true)[0];
      }
    }
  };
  
  const handleChange = (aOption, type) => {
    if(aOption){
      let selectedOption = aOption;
      
      if(fetchObjects && scrollManager){
        if(!aOption.isSearchString){
          if(aOption.id){
            selectedOption = scrollManager.getList().find((object) => {
              return object.id === aOption.id;
            });
          }
          else if(Array.isArray(valuePropertiesArray)){
            selectedOption = scrollManager.getList().find((object) => {
              let value = object;
              
              for(let index = 0; index < valuePropertiesArray.length; index += 1){
                let property = valuePropertiesArray[index];
                value = value[property];
              }
              return value === aOption.value;
            });
          }
        }
        else{
          let newOption = {
            isSearchString:true
          };
          
          for(let index = 0; index < valuePropertiesArray.length - 1; index += 1){
            let property = valuePropertiesArray[index];
            newOption[property] = {};
          }
          newOption[valuePropertiesArray[valuePropertiesArray.length - 1]] = aOption.value;
          
          if(labelPropertiesArray && Array.isArray(labelPropertiesArray)){
            for(let index = 0; index < labelPropertiesArray.length - 1; index += 1){
              let property = labelPropertiesArray[index];
              newOption[property] = {};
            }
            newOption[labelPropertiesArray[labelPropertiesArray.length - 1]] = aOption.label;
          }
          selectedOption = newOption;
        }
      }
      if(onChange){
        onChange(selectedOption);
      }
    }
    else{
      if(onChange){
        onChange(null);
      }
    }
  };
  
  const valueFromPropertiesArray = (aObject, aPropertiesArray) => {
    let returnValue = aObject;
    
    if(valuePropertiesArray && valuePropertiesArray.length > 0){
      for(let index = 0; index < aPropertiesArray.length; index += 1){
        let property = aPropertiesArray[index];
        returnValue = returnValue[property];
      }
    }
    return returnValue;
  };
  
  const allOptions = () => {
    let returnValue = [...optionsArray];
    
    if(extraOptionsArray){
      for(let extraOption of extraOptionsArray){
        let addOption = true;
        
        for(let option of optionsArray){
          if(extraOption.value === option.value){
            addOption = false;
            break;
          }
        }
        if(addOption){
          returnValue.push(extraOption);
        }
      }
    }
    return returnValue;
  }
  
  return (
    <>
      <InfiniteScrollManager ref={scrollManagerRef}
                             fail={notifyFetchFailed ? notifyFetchFailed : (aError) => console.error('VirtualizedDropdown (InfiniteScrollManager): Failed with error:', aError)}
                             success={() => updateObjectsArray()}
                             getItems={(offset, limit) => getObjects(limit, offset)}
                             fetchImmediately={false}
                             ignoreSequentialLoadRequests={true}
      />
      
      <Select className={classNames({
        'labeled-dropdown':true,
        'virtualized-dropdown':true,
        'labeled-dropdown-disabled':disabled,
        'labeled-dropdown-input-error':errorMessage,
        'labeled-dropdown-input-warning':warningMessage,
        'labeled-dropdown-empty':!(value && (Array.isArray(value) ? value.length : true))
      })}
              ref={customOptionHeightsSelectRef}
              multi={multi}
              style={{zIndex:7}}
              value={selectedValue}
              onBlur={selectBlurred}
              onFocus={selectFocused}
              options={extraOptionsArray ? allOptions() : optionsArray}
              disabled={disabled}
              onChange={handleChange}
              clearable={isClearable}
              maxHeight={maxMenuHeight ? maxMenuHeight : 120}
              searchable={true}
              placeholder={placeholder ? placeholder : <Translation>{(t, {i18n}) => t('SELECT_ELLIPSIS')}</Translation>}
              filterOption={() => true}
              menuPosition={menuPosition ? menuPosition : 'absolute'}
              optionHeight={({option}) => option.height ? option.height : (option.type === 'header' ? 20 : 35)}
              menuPlacement={menuPlacement}
              onInputChange={handleSearch}
              optionRenderer={customOptionRenderer ? customOptionRenderer : optionRenderer}
              backspaceRemoves={false}
              menuPortalTarget={document.body}
              resetInputOnBlur={false}
              resetInputOnSelect={false}
              closeMenuOnScroll={(aEvent) => {
                let parentContainerClassNames = [
                  'add-edit-main-container',
                  'patient-profile-container',
                  'patient-summary-container-short',
                  'profile-alternative-contact-list'
                ];
                return aEvent.target && parentContainerClassNames.some((classname) => aEvent.target.classList.contains(classname));
              }}
              onMenuScrollToBottom={fetchObjects && !doNotLoadOnMenuBottom ? loadMoreOptions : null}
              menuShouldScrollIntoView={false}
      />
    </>
  )};
export default VirtualizedDropDown;
