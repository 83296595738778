import React from 'react';
import actions from 'actions';
import {connect} from 'react-redux';
import {removeNote} from "../actions/notes";
import PatientProfile from "../Pages/PatientProfile/PatientProfile";

class PatientProfileContainer extends React.Component {
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.props.clearCurrentPatient();
      
      if(this.props.match.params.id && this.props.match.params.id.length > 0){
        this.props.fetchPatient({userId:this.props.match.params.id});
      }
    }
  }
  
  componentDidMount() {
    this.props.clearCurrentPatient();
    
    if(this.props.match.params.id && this.props.match.params.id.length > 0){
      this.props.fetchPatient({userId:this.props.match.params.id});
    }
  }
  
  componentWillUnmount() {
    this.props.clearCurrentPatient();
  }
  
  render() {
    const {patient} = this.props;
    return (
      <PatientProfile key={patient && patient.id}
                      {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps)=> {
  return {
    timeRange:state.timeRange,
    virtualAppointments:state.virtualAppointments,
    patient:state.current && state.current.patient,
    patients:state.patients && state.patients.patients,
    patientData:state.current && state.current.patientData,
    patientLoadError:state.current && state.current.patientLoadError,
    syncedDevicesData:state.current && state.current.syncedDevicesData,
    user:state.current && state.current.user,
    company:state.company && state.company.company,
    groups:state.company && state.company.company && state.company.company.groups,
    groupName:ownProps.match.params.group
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser:(user) => {
      return dispatch(actions.updateUser(user));
    },
    resetPatientsPassword:(aUserId, aPassword) => {
      return dispatch(actions.resetPatientsPassword(aUserId, aPassword));
    },
    updateUserTool:({userTool}) => {
      return dispatch(actions.updateUserTool({userTool}));
    },
    removeToolFromUser:({userId, toolId}) => {
      return dispatch(actions.removeToolFromUser({userId, toolId}));
    },
    fetchAllergies:(aLimit, aOffset, aQuery, aUserId) => {
      return dispatch(actions.fetchAllergies(aLimit, aOffset, aQuery, aUserId));
    },
    fetchMedicationAllergies:(aLimit, aOffset, aQuery, aUserId) => {
      return dispatch(actions.fetchMedicationAllergies(aLimit, aOffset, aQuery, aUserId));
    },
    fetchPatient:({userId}) => {
      dispatch(actions.fetchPatient({userId}));
    },
    fetchPatientData:({userId, from, to, objectiveTypes}) => {
      dispatch(actions.fetchPatientData({userId, from, to, objectiveTypes}));
    },
    fetchPatients:({groupName}) => {
      dispatch(actions.fetchPatients({groupName}));
    },
    fetchCompanyAndPatients:({groupName}) => {
      dispatch(actions.fetchCompanyAndPatients({groupName}));
    },
    fetchMedicationsWithCountryCode:(aQuery, aMedCountryCode, aLimit, aOffset) => {
      return dispatch(actions.fetchMedicationsWithCountryCode(aQuery, aMedCountryCode, aLimit, aOffset));
    },
    fetchMedicationMatrix:(aMedicationId) => {
      return dispatch(actions.fetchMedicationMatrix(aMedicationId));
    },
    clearCurrentPatient:() => {
      dispatch(actions.clearCurrentPatient());
    },
    addConditionToUser:({groupId, dateOfDiagnosis, userId}) => {
      return dispatch(actions.addConditionToUser({groupId, dateOfDiagnosis, userId}));
    },
    removeConditionFromUser:(groupId, patientId) => {
      return dispatch(actions.removeConditionFromUser(groupId, patientId));
    },
    getConditions:(aSearchString, aLimit, aOffset) => {
      return dispatch(actions.getConditions(aSearchString, aLimit, aOffset));
    },
    addSymptomToUser:({symptomId, userId, extraNotes, customSymptom, objectiveId}) => {
      return dispatch(actions.addSymptomToUser({symptomId, userId, extraNotes, customSymptom, objectiveId}));
    },
    addAllergy:(aUserId, aAllergen, aSeverity, aAllergyId, aId) => {
      return dispatch(actions.addAllergy(aUserId, aAllergen, aSeverity, aAllergyId, aId));
    },
    addMedicationAllergy: (aUserId, aName, aSeverity, aAllergyId, aId) => {
      return dispatch(actions.addMedicationAllergy(aUserId, aName, aSeverity, aAllergyId, aId));
    },
    removeAllergyFromUser:({aAllergyId, aUserId}) => {
      return dispatch(actions.removeAllergyFromUser(aAllergyId, aUserId));
    },
    removeMedicationAllergyFromUser:({aMedicationAllergyId, aUserId}) => {
      return dispatch(actions.removeMedicationAllergyFromUser(aMedicationAllergyId, aUserId));
    },
    removeSymptomFromUser:({symptomId, userId}) => {
      return dispatch(actions.removeSymptomFromUser({symptomId, userId}));
    },
    addUserToGroup:({groupId, userId}) => {
      return dispatch(actions.addUserToGroup({groupId, userId}));
    },
    removeUserFromGroup:({groupId, userId}) => {
      return dispatch(actions.removeUserFromGroup({groupId, userId}));
    },
    addMedicationToUser:({medicationId, userId, questId, reminders, extraNotes, quantity, concept_id, objective_id, strength, route, dosage_form, customMedicationName}) => {
      return dispatch(actions.addMedicationToUser({medicationId, userId, questId, reminders, extraNotes, quantity, concept_id, objective_id, strength, route, dosage_form, customMedicationName}));
    },
    addCustomMedicationToUser:(customMedicationName, userId, extraNotes, questId, reminders, quantity, objective_id, strength, route, dosage_form) => {
      return dispatch(actions.addCustomMedicationToUser(customMedicationName, userId, extraNotes, questId, reminders, quantity, objective_id, strength, route, dosage_form));
    },
    removeMedicationFromUser:(objectiveId, patientId) => {
      return dispatch(actions.removeMedicationFromUser(objectiveId, patientId));
    },
    addNote:({userId, description}) => {
      return dispatch(actions.addNote({userId, description}));
    },
    updateFeatureToUser:(aUserId, aFeatureId, aStatus) => {
      return dispatch(actions.updateFeatureToUser(aUserId, aFeatureId, aStatus));
    },
    updateNote:(userId, description, noteId) => {
      return dispatch(actions.updateNote(userId, description, noteId));
    },
    removeNote:({noteId, userId}) => {
      return dispatch(removeNote({noteId:noteId, userId:userId}));
    },
    loadCompanyNotificationReceipts:(aSearchString, aUserId, aGroupId, aPage, aPerPage, aExcludeCleared, aSearchPreferences) => {
      return dispatch(actions.loadCompanyNotificationReceipts(aSearchString, aUserId, aGroupId, aPage, aPerPage, aExcludeCleared, aSearchPreferences));
    },
    clearCompanyNotificationReceipt:({company_notification_receipt_id, note}) => {
      return dispatch(actions.clearCompanyNotificationReceipt({company_notification_receipt_id, note}));
    },
    fetchSyncedDevicesData:({userId, from, to}) => {
      dispatch(actions.fetchSyncedDevicesData({userId, from, to}));
    },
    getVirtualAppointments:(aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy) => {
      return dispatch(actions.getVirtualAppointments( aLimit, aOffset, aSearchString, aStartingBefore, aEndingAfter, aParticipantUserId, aGroupId, aOrderBy));
    },
    saveVirtualAppointment:(virtualAppointment) => {
      return dispatch(actions.saveVirtualAppointment(virtualAppointment));
    },
    removeVirtualAppointment:({virtualAppointmentId}) => {
      return dispatch(actions.removeVirtualAppointment({virtualAppointmentId}));
    },
    searchPatients:({query}) => {
      dispatch(actions.searchPatients({query}));
    },
    addAlternativeContactToUser:({alternativeContact, userId}) => {
      return dispatch(actions.addAlternativeContactToUser({alternativeContact, userId}));
    },
    removeAlternativeContactFromUser:({alternativeContact, userId}) => {
      dispatch(actions.removeAlternativeContactFromUser({alternativeContact, userId}));
    },
    addHealthCareProviderInformationToUser:(aHealthCareProviderInformation, aUserId) => {
      return dispatch(actions.addHealthCareProviderInformationToUser(aHealthCareProviderInformation, aUserId));
    },
    removeHealthCareProviderInformationFromUser:(aHealthCareProviderInformation, aUserId) => {
      return dispatch(actions.removeHealthCareProviderInformationFromUser(aHealthCareProviderInformation, aUserId));
    },
    addCallNote:(aNote) => {
      return dispatch(actions.addCallNote(aNote));
    },
    getCallNotes:(aUserId, aLimit, aOffset) => {
      return dispatch(actions.getCallNotes(aUserId, aLimit, aOffset));
    },
    getCallNoteHistories:(aCallNoteId, aLimit, aOffset) => {
      return dispatch(actions.getCallNoteHistories(aCallNoteId, aLimit, aOffset));
    },
    getFullQuest:({questId, userId, to, from}) => {
      return dispatch(actions.getFullQuest({questId, userId, to, from}));
    },
    getAndReturnPatientData:({userId, from, to, objectiveTypes, questOffset, questLimit}) => {
      return dispatch(actions.getAndReturnPatientData({userId, from, to, objectiveTypes, questOffset, questLimit}));
    },
    createDataQueryNote:(patientId, description, surveyResponseDetailId, notificationAlert) => {
      return dispatch(actions.createDataQueryNote(patientId, description, surveyResponseDetailId, notificationAlert));
    },
    createDataQueryComment:(dataQueryNoteId, commentText) => {
      return dispatch(actions.createDataQueryComment(dataQueryNoteId, commentText));
    },
    getDataQueryNote:(dataQueryNoteId) => {
      return dispatch(actions.getDataQueryNote(dataQueryNoteId));
    },
    getDataQueryNotes:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getDataQueryNotes(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    getNotes:(patient_id, limit, offset) => {
      return dispatch(actions.getNotes(patient_id, limit, offset));
    },
    getDataQueryNoteComments:(aDataQueryNoteId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getDataQueryNoteComments(aDataQueryNoteId, aLimit, aOffset, aSearchString));
    },
    resolveDataQueryNote:(dataQueryNoteId) => {
      return dispatch(actions.resolveDataQueryNote(dataQueryNoteId));
    },
    getQuestionForSurveyResponseDetail:(surveyId, surveyResponseDetailId, patientId, aSurveyResponseId) => {
      return dispatch(actions.getQuestionForSurveyResponseDetail(surveyId, surveyResponseDetailId, patientId, aSurveyResponseId));
    },
    updateSurveyResponseLockState:(aSurveyResponseId, aLockedState) => {
      return dispatch(actions.updateSurveyResponseLockState(aSurveyResponseId, aLockedState));
    },
    retryPayment:(aPayment) => {
      return dispatch(actions.retryPayment(aPayment));
    },
    fetchPayments:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchPayments(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    createPayment:(aPatientID, aAmount, aCurrency) => {
      return dispatch(actions.createPayment(aPatientID, aAmount, aCurrency));
    },
    approvePayment:(payment) => {
      return dispatch(actions.approvePayment(payment));
    },
    removePayment:(payment) => {
      return dispatch(actions.removePayment(payment));
    },
    fetchPatientsScroll:(aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude) => {
      return dispatch(actions.fetchPatientsScroll(aQuery, aGroupName, aLimit, aOffset, aOrderBy, aOrderByAscending, aPreferredLanguage, aAlertType, aVirtualAppointmentDate, aDispositionCode, aSearchByIdType, aSurveyIdForSorting, aQuestionKeyForSorting, aDynamicFields, aAnswerKeyForSorting, aIncludeAll, aGroupIdsToExclude));
    },
    getSurveyResponses:(aPatientId, aSurveyVersionCollectionId, aLimit, aOffset) => {
      return dispatch(actions.getSurveyResponses(aPatientId, aSurveyVersionCollectionId, aLimit, aOffset));
    },
    fetchFraudEvents:(aPatientId, aGroupId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchFraudEvents(aPatientId, aGroupId, aLimit, aOffset, aSearchString));
    },
    fetchFraudStatistics:(aPatientId) => {
      return dispatch(actions.fetchFraudStatistics(aPatientId));
    },
    markFraudEventAsVerified:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsVerified(aFraudEventId, aComment));
    },
    markFraudEventAsFraudulent:(aFraudEventId, aComment) => {
      return dispatch(actions.markFraudEventAsFraudulent(aFraudEventId, aComment));
    },
    markFraudStatisticAsVerified:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsVerified(aFraudStatisticId, aComment));
    },
    markFraudStatisticAsFraudulent:(aFraudStatisticId, aComment) => {
      return dispatch(actions.markFraudStatisticAsFraudulent(aFraudStatisticId, aComment));
    },
    loadPatientContacts:(aPatientId) => {
      return dispatch(actions.loadPatientContacts(aPatientId));
    },
    loadPatientHCPContacts:(aPatientId) => {
      return dispatch(actions.loadPatientHCPContacts(aPatientId));
    },
    loadPatientAllergiesUsers:(aPatientId) => {
      return dispatch(actions.loadPatientAllergiesUsers(aPatientId));
    },
    loadPatientSymptoms:(aPatientId) => {
      return dispatch(actions.loadPatientSymptoms(aPatientId));
    },
    loadPatientMedications:(aPatientId) => {
      return dispatch(actions.loadPatientMedications(aPatientId));
    },
    loadPatientConditions:(aPatientId) => {
      return dispatch(actions.loadPatientConditions(aPatientId));
    },
    loadPatientGroups:(aPatientId) => {
      return dispatch(actions.loadPatientGroups(aPatientId));
    },
    loadPatientMedicationAllergiesUsers:(aPatientId) => {
      return dispatch(actions.loadPatientMedicationAllergiesUsers(aPatientId));
    },
    loadPatientFullQuests:(aPatientId) => {
      return dispatch(actions.loadPatientFullQuests(aPatientId));
    },
    loadPatientMinifiedQuests:(aPatientId) => {
      return dispatch(actions.loadPatientMinifiedQuests(aPatientId));
    },
    fetchEntries:(aUserId, aType, aQuestId, aLimit, aOffset) => {
      return dispatch(actions.fetchEntries(aUserId, aType, aQuestId, aLimit, aOffset));
    },
    fetchSurveyVersionCollectionByReference:(aReference) => {
      return dispatch(actions.fetchSurveyVersionCollectionByReference(aReference));
    },
    fetchQuestsByPatientId:(aUserId, aLimit, aOffset, aIsAdminOnly, aSearchString, aQuestType) => {
      return dispatch(actions.fetchQuestsByPatientId(aUserId, aLimit, aOffset, aIsAdminOnly, aSearchString, aQuestType));
    },
    getQuestById:(aUserId, aQuestId) => {
      return dispatch(actions.getQuestById(aUserId, aQuestId));
    },
    fetchSymptoms:(aLimit, aOffset, aQuery, aUserId) => {
      return dispatch(actions.fetchSymptoms(aLimit, aOffset, aQuery, aUserId));
    },
    getVideoForVirtualAppointment:(aVirtualAppointmentId) => {
      return dispatch(actions.getVideoForVirtualAppointment(aVirtualAppointmentId));
    },
    fetchMedicalRecords:(aPatientId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchMedicalRecords(aPatientId, aLimit, aOffset, aSearchString));
    },
    sendSurveyLink:(aEmail, aUserId, aLocale, aSurveyId, aPatientId, aEmailSubject, aEmailDescription, aSurveyResponseId, aSubjectIdentificationMessage) => {
      return dispatch(actions.sendSurveyLink(aEmail, aUserId, aLocale, aSurveyId, aPatientId, aEmailSubject, aEmailDescription, aSurveyResponseId, aSubjectIdentificationMessage));
    },
    getSurveyLinks:(aUserId, aLimit, aOffset, aSearchString) => {
      return dispatch(actions.getSurveyLinks(aUserId, aLimit, aOffset, aSearchString));
    },
    getSurveyLinkById:(aId) => {
      return dispatch(actions.getSurveyLinkById(aId));
    },
    saveSchedule:(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove) => {
      return dispatch(actions.saveSchedule(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove));
    },
    fetchSchedule:(aScheduleId, aUserId) => {
      return dispatch(actions.fetchSchedule(aScheduleId, aUserId = null));
    },
    fetchSchedules:(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved) => {
      return dispatch(actions.fetchSchedules(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved));
    },
    removeSchedule:(aScheduleId) => {
      return dispatch(actions.removeSchedule(aScheduleId));
    },
    publishSchedule:(aScheduleId) => {
      return dispatch(actions.publishSchedule(aScheduleId));
    },
    saveScheduleTrigger:(aScheduleId, aScheduleTrigger) => {
      return dispatch(actions.saveScheduleTrigger(aScheduleId, aScheduleTrigger));
    },
    createPaymentTrigger:(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.createPaymentTrigger(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    fetchScheduleVersion:(aScheduleVersionId) => {
      return dispatch(actions.fetchScheduleVersion(aScheduleVersionId));
    },
    removePaymentTrigger:(aPaymentTriggerId) => {
      return dispatch(actions.removePaymentTrigger(aPaymentTriggerId));
    },
    updatePaymentTrigger:(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.updatePaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    removeScheduleTrigger:(aScheduleId, aScheduleTriggerId) => {
      return dispatch(actions.removeScheduleTrigger(aScheduleId, aScheduleTriggerId));
    },
    fetchPatientsInSchedule:(aLimit, aOffset, aSearchString, aScheduleId) => {
      return dispatch(actions.fetchPatientsInSchedule(aLimit, aOffset, aSearchString, aScheduleId));
    },
    fetchQuestionsForSurveyById:(aSurveyId) => {
      return dispatch(actions.fetchQuestionsForSurveyById(aSurveyId));
    },
    fetchSurveyVersionCollections:(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField) => {
      return dispatch(actions.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField));
    },
    fetchScheduleVersionsForSchedule:(aScheduleId, aLimit, aOffset) => {
      return dispatch(actions.fetchScheduleVersionsForSchedule(aScheduleId, aLimit, aOffset));
    },
    fetchQuestionsForSurveyByReference:(aReference) => {
      return dispatch(actions.fetchQuestionsForSurveyByReference(aReference));
    },
    getUserAdditionalAppColumns:(aLimit, aOffset, aSearchString, aUserId) => {
      return dispatch(actions.getUserAdditionalAppColumns(aLimit, aOffset, aSearchString, aUserId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfileContainer);
