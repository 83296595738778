import React from 'react';
import './Separator.scss'

const Separator = ({id, label, className = '', colour = '#EEEEEE', margin = '20px'}) => {
  
  // Render
  
  return (
    label && label.length > 0 ?
      <div className={'separator-container ' + className}
           id={id}>
        <div className="separator-dividing-line-text"
             style={{marginTop:margin, marginBottom:margin, backgroundColor:colour}}
        />
        
        <div className="separator-dividing-text">
          {label}
        </div>
  
        <div className="separator-dividing-line-text"
             style={{marginTop:margin, marginBottom:margin, backgroundColor:colour}}
        />
      </div>
      :
      <div className={'separator-dividing-line ' + className}
           style={{marginTop:margin, marginBottom:margin, backgroundColor:colour}}
           id={id}
      />
  );
};
export default Separator;
