import React from 'react';
import './ScheduleTriggerExtras.scss';
import moment from "moment";
import i18next from "i18next";
import Separator from "../../Custom UI/Separator/Separator";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import LabeledTextInput from "../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput";
import LabeledDatePicker from "../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import {isoDateTimeNoTimeZonePickerFormat} from "../../Helpers/DateHelpers";
import {scheduleTriggerOffsetUnitOptionsArray, scheduleTriggerFireExtrasReferenceTypesOptionsArray} from "../../constants/ScheduleTriggerTypes";

export default class ScheduleTriggerExtras extends React.Component {
  
  // Render
  
  render(){
    const {save, surveys, triggerExtras, surveyQuestions, fetchQuestionForSurveyId, fetchAppAdditionalColumns, fetchSurveyVersionCollections, disabled = false} = this.props;
    
    let referenceQuestionOptionsArray = [];
    
    if(triggerExtras && triggerExtras.reference_date_type === 'survey_question' &&
      triggerExtras.reference_date_survey_version_collection_id &&
      triggerExtras.reference_date_survey_version_collection_id.length > 0 &&
      surveyQuestions[triggerExtras.reference_date_survey_version_collection_id]){
      for(let question of surveyQuestions[triggerExtras.reference_date_survey_version_collection_id]){
        referenceQuestionOptionsArray.push({label:question.key + ' - (' + question.type + ')', value:question.key});
      }
    }
    
    const extraOption = surveys && triggerExtras && triggerExtras.reference_date_survey_version_collection_id && surveys[triggerExtras.reference_date_survey_version_collection_id] ? {label:surveys[triggerExtras.reference_date_survey_version_collection_id].name, value:triggerExtras.reference_date_survey_version_collection_id} : undefined;
    
    return (
      <div className="schedule-trigger-extras-input-cell">
          <Separator margin={'10px'}/>
          
          <div className="schedule-trigger-extras-cell">
            <LabeledTextInput className="schedule-trigger-extras-delayed-input"
                              label={i18next.t('ALERT_OFFSET')}
                              value={triggerExtras.offset}
                              disabled={disabled}
                              infoBlurb={i18next.t('ALERT_OFFSET_EXPLANATION')}
                              handleSave={(aEvent) => {
                                triggerExtras.offset = aEvent.target.value;
                                save(triggerExtras);
                              }}
                              placeholder={i18next.t('OFFSET')}
            />
            
            <LabeledDropDown className="schedule-trigger-extras-dropdown-small"
                             label={i18next.t('ALERT_OFFSET_UNIT')}
                             value={scheduleTriggerOffsetUnitOptionsArray().filter(option => {
                               return option.value === triggerExtras.offset_unit;
                             })}
                             options={scheduleTriggerOffsetUnitOptionsArray()}
                             disabled={disabled}
                             infoBlurb={i18next.t('ALERT_OFFSET_UNIT_EXPLANATION')}
                             handleSave={(aOption) => {
                               triggerExtras.offset_unit = aOption.value;
                               save(triggerExtras);
                             }}
                             isClearable={true}
                             placeholder={i18next.t('ALERT_OFFSET_UNIT')}
            />
            
            <LabeledDatePicker className="schedule-trigger-extras-dropdown-small"
                               label={i18next.t('ALERT_SPECIFIC_DATE')}
                               value={triggerExtras.specific_date}
                               minDate={moment()}
                               disabled={disabled}
                               dateFormat={isoDateTimeNoTimeZonePickerFormat}
                               handleSave={(aDate) => {
                                 triggerExtras.specific_date = aDate;
                                 save(triggerExtras);
                               }}
                               isClearable={true}
                               timeCaption={i18next.t('TIME_PROPER_CAPITALIZED')}
                               showTimeSelect={true}
                               placeholderText={i18next.t('SELECT_DATE')}
                               popperPlacement={'bottom-start'}
                               showPopperArrow={true}
                               showYearDropdown={true}
                               showMonthDropdown={true}
            />
          </div>
          
          <div className="schedule-trigger-extras-cell-start">
            <LabeledDropDown className="schedule-trigger-extras-dropdown-small-icon"
                             label={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_TYPE')}
                             value={scheduleTriggerFireExtrasReferenceTypesOptionsArray().filter(option => {
                               return option.value === triggerExtras.reference_date_type;
                             })}
                             options={scheduleTriggerFireExtrasReferenceTypesOptionsArray()}
                             disabled={disabled}
                             infoBlurb={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_TYPE_EXPLANATION')}
                             handleSave={(aOption) => {
                               if(triggerExtras.reference_date_type !== aOption.value){
                                 triggerExtras.reference_date_type = aOption.value;
                                 triggerExtras.reference_date_value = null;
                                 save(triggerExtras);
                               }
                             }}
                             isClearable={true}
                             placeholder={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_TYPE')}
            />
            
            {triggerExtras.reference_date_type === 'additional_app_column' ?
              <LabeledDropDown className="schedule-trigger-extras-dropdown-small"
                               label={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE')}
                               value={triggerExtras.reference_date_value}
                               infoBlurb={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE_ADDITIONAL_COLUMN_EXPLANATION')}
                               handleSave={(aOption) => {
                                 triggerExtras.reference_date_value = aOption.name;
                                 save(triggerExtras);
                               }}
                               isClearable={true}
                               fetchObjects={(aLimit, aOffset, aSearchString) => fetchAppAdditionalColumns(aLimit, aOffset, aSearchString)}
                               saveWithOptionsKey={true}
                               optionsArrayFormatter={(aArray) => {
                                 let returnValue = [];
                                 let addCurrentKeyToOptions = true;
                                 
                                 for(let additionalAppColumn of aArray){
                                   if(triggerExtras.reference_date_value === additionalAppColumn.name){
                                     addCurrentKeyToOptions = false;
                                   }
                                   returnValue.push({value:additionalAppColumn.name, label:additionalAppColumn.name + ' - (' + additionalAppColumn.name_type + ')', type:additionalAppColumn.name_type, id:additionalAppColumn.id});
                                 }
                                 if(addCurrentKeyToOptions && triggerExtras.reference_date_value !== null && triggerExtras.reference_date_value !== undefined){
                                   returnValue.push({value:triggerExtras.reference_date_value, label:triggerExtras.reference_date_value, type:''});
                                 }
                                 return returnValue;
                               }}
                               responseTotalKeysArray={['data', 'data', 'additional_app_columns', 'total']}
                               responseObjectKeysArray={['data', 'data', 'additional_app_columns', 'additional_app_columns']}
              />
              :
              null
            }
            
            {triggerExtras.reference_date_type === 'survey_question' ?
              <div className="schedule-trigger-extras-cell-inner-survey">
                <LabeledDropDown className="schedule-trigger-extras-dropdown-inner"
                                 label={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_SURVEY')}
                                 value={triggerExtras.reference_date_survey_version_collection_id}
                                 infoBlurb={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_SURVEY_EXPLANATION')}
                                 handleSave={(aSurvey) => {
                                   triggerExtras.reference_date_survey_version_collection_id = aSurvey.value;
                                   fetchQuestionForSurveyId(aSurvey.value);
                                   save(triggerExtras);
                                 }}
                                 isClearable={true}
                                 fetchObjects={(aLimit, aOffset, aSearchString) => fetchSurveyVersionCollections(aLimit, aOffset, aSearchString)}
                                 extraOptionsArray={extraOption !== undefined ? [extraOption] : undefined}
                                 saveWithOptionsKey={true}
                                 optionsArrayFormatter={(aArray) => {
                                   let optionsArray = [];
                                   
                                   for(let surveyVersionCollection of aArray){
                                     optionsArray.push({label:surveyVersionCollection.name, value:surveyVersionCollection.reference});
                                   }
                                   return optionsArray;
                                 }}
                                 responseTotalKeysArray={['data', 'data', 'survey_version_collections', 'total']}
                                 responseObjectKeysArray={['data', 'data', 'survey_version_collections', 'survey_version_collections']}
                />
                
                <LabeledDropDown className="schedule-trigger-extras-dropdown-inner"
                                 label={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE')}
                                 value={referenceQuestionOptionsArray.filter(option => {
                                   return option.value === triggerExtras.reference_date_value;
                                 })}
                                 options={referenceQuestionOptionsArray}
                                 disabled={disabled}
                                 infoBlurb={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE_SURVEY_KEYWORD_EXPLANATION')}
                                 handleSave={(aOption) => {
                                   if(triggerExtras.reference_date_value !== aOption.value){
                                     triggerExtras.reference_date_value = aOption.value;
                                     save(triggerExtras);
                                   }
                                 }}
                                 isClearable={true}
                                 placeholder={i18next.t('SCHEDULE_TRIGGER_FIRE_EXTRAS_REFERENCE_VALUE')}
                />
              </div>
              :
              null
            }
          </div>
        </div>
    )
  }
}
