import React from 'react';
import '../../Modals/AddPatientNoteModal/AddPatientNoteModal.scss'
import "antd/dist/antd.css";
import {Translation} from "react-i18next";
import LabeledDropDown from "../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import '../../Custom UI/modalTitleCell.scss';
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import "../../Modals/GeneralModal/GeneralModal.scss"
import i18n from "../../i18n";
import {toast} from "react-toastify";
import {NOTIFY_OPTS} from "../../constants/Notifiers";

const notifyAllergyLoadFailed = (aError) => toast(<Translation>{(t, {i18n}) => t('ERROR_FETCHING_ALLERGY', {error:aError})}</Translation>, NOTIFY_OPTS.autoCloseFiveSeconds);

export default class AssignAllergyModal extends React.Component {
  
  constructor(props){
    super(props)
    this.state = {
      allergen:'',
      severity:'',
      allergy_id:-1,
      goToNextState:false,
      selectedValue:null
    };
    
    if(this.props.allergy){
      this.state.allergen = this.props.allergy.allergen;
      this.state.severity = this.props.allergy.severity;
      this.state.allergy_id = this.props.allergy.allergy_id;
      this.state.selectedValue = this.props.allergy;
    }
    this.filterAlertOptions = this.filterAlertOptions.bind(this);
    this.assignPatientAllergy = this.assignPatientAllergy.bind(this);
  }
  
  // Methods
  
  assignPatientAllergy(){
    const {allergen, severity, allergy_id} = this.state;
    const {assignAllergyCallback} = this.props;
    const id = this.props.allergy ? this.props.allergy.id : null;
    assignAllergyCallback(allergen, severity, allergy_id, id);
  }
  
  filterAlertOptions(aOption){
    return aOption.value === this.state.severity;
  }
  
  render() {
    let {goToNextState, severity, selectedValue} = this.state;
    let {isOpen, handleCloseModal, allergy, patient, fetchAllergies} = this.props;
    
    let severityLists = [
      {value:"mild", label:"Mild"},
      {value:"moderate", label:"Moderate"},
      {value:"severe", label:"Severe"}
    ];
    
    return (
      <GeneralModal title={<Translation>{(t, {i18n}) => t('ALLERGIES')}</Translation>}
                    isOpen={isOpen}
                    maxHeight={700}
                    isEditing={allergy}
                    handleSave={() => this.assignPatientAllergy()}
                    showSaveButton={goToNextState}
                    containsDropDown={true}
                    handleCloseModal={handleCloseModal}>
        <LabeledDropDown className="modal-input"
                         label={i18n.t('ALLERGY')}
                         value={selectedValue}
                         searchable={true}
                         handleSave={(aOption) => {
                           this.setState({allergen:aOption.allergen, allergy_id:aOption.id, selectedValue:aOption});
          
                           if(this.state.severity !== ''){
                             this.setState({goToNextState:true});
                           }
                         }}
                         fetchObjects={(aLimit, aOffset, aSearchString) => fetchAllergies(aLimit, aOffset, aSearchString, patient.id)}
                         notifyFetchFailed={notifyAllergyLoadFailed}
                         labelPropertiesArray={['allergen']}
                         valuePropertiesArray={['id']}
                         responseTotalKeysArray={['data', 'data', 'allergies_page', 'total']}
                         responseObjectKeysArray={['data', 'data', 'allergies_page', 'allergies']}
        />
        
        <LabeledDropDown className="modal-input"
                         label={i18n.t('SEVERITY')}
                         value={(allergy || severity !== '') && severityLists.filter((aOption) => this.filterAlertOptions(aOption))}
                         options={severityLists}
                         handleSave={(aOption) => {
                           this.setState({severity:aOption.value});
          
                           if(this.state.allergen !== ''){
                             this.setState({goToNextState:true});
                           }
                         }}
        />
      </GeneralModal>
    )
  }
}
