import {Translation} from "react-i18next";

export const FraudEventTypes = {
  survey_completed_fast:<Translation>{ (t, { i18n }) => t('SURVEY_COMPLETED_FAST') }</Translation>,
  non_unique_primary_contact_number:<Translation>{ (t, { i18n }) => t('NON_UNIQUE_PRIMARY_CONTACT_NUMBER') }</Translation>,
  non_unique_ip_address:<Translation>{ (t, { i18n }) => t('NON_UNIQUE_IP_ADDRESS') }</Translation>,
  no_ip_on_sign_up:<Translation>{ (t, { i18n }) => t('NO_IP_ON_SIGN_UP') }</Translation>,
  ip_address_city_mismatch:<Translation>{ (t, { i18n }) => t('IP_ADDRESS_CITY_MISMATCH') }</Translation>,
  ip_address_state_mismatch:<Translation>{ (t, { i18n }) => t('IP_ADDRESS_STATE_MISMATCH') }</Translation>,
  ip_address_zip_mismatch:<Translation>{ (t, { i18n }) => t('IP_ADDRESS_ZIP_MISMATCH') }</Translation>,
  provider_type:<Translation>{ (t, { i18n }) => t('PROVIDER_TYPE') }</Translation>,
  name_mismatch_with_provider:<Translation>{ (t, { i18n }) => t('NAME_MISMATCH_WITH_PROVIDER') }</Translation>,
  non_unique_device_identifier:<Translation>{ (t, { i18n }) => t('NON_UNIQUE_DEVICE_IDENTIFIER') }</Translation>,
  duplicate_name:<Translation>{ (t, { i18n }) => t('DUPLICATE_NAME') }</Translation>,
  duplicate_address:<Translation>{ (t, { i18n }) => t('DUPLICATE_ADDRESS') }</Translation>
};

// survey_completed_fast: 0,
//   non_unique_primary_contact_number: 1,
//   non_unique_ip_address: 2,
//   no_ip_on_sign_up: 3,
//   ip_address_city_mismatch: 4,
//   ip_address_state_mismatch: 5,
//   ip_address_zip_mismatch: 6,
//   provider_type: 7,
//   name_mismatch_with_provider: 8,
//   non_unique_device_identifier: 9
