import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function fetchFraudEvents(aPatientId, aGroupId, aLimit, aOffset, aSearchString){
  EventTracker.track(EventTypes.FETCH_FRAUD_EVENTS);
  let query = `
  query getFraudEvents ($company_id: ID!, $user_id: ID, $group_id: ID, $limit: Int, $offset: Int, $search_string: String) {
      fraud_events (company_id: $company_id, user_id: $user_id, group_id: $group_id, limit: $limit, offset: $offset, search_string: $search_string){
          fraud_events {
            id
            event_type
            verified_comments
            verification_status
            target_id
            target_type
            target_name
            string_value
            created_at
            user {
              id
              first_name
              last_name
              edc_id
              fraud_statistic {
                id
                verified_comments
                verification_status
                probability
                verified_by_user {
                  first_name
                  last_name
                }
              }
            }
            verified_by_user {
              first_name
              last_name
            }
          }
          total
        }
    }
  `;
  if(aPatientId){
    query = `
  query getFraudEvents ($company_id: ID!, $user_id: ID, $group_id: ID, $limit: Int, $offset: Int, $search_string: String) {
      fraud_events (company_id: $company_id, user_id: $user_id, group_id: $group_id, limit: $limit, offset: $offset, search_string: $search_string){
          fraud_events {
            id
            event_type
            verified_comments
            verification_status
            target_id
            target_type
            target_name
            string_value
            created_at
            verified_by_user {
              first_name
              last_name
            }
          }
          total
        }
    }
  `;
  }
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aPatientId){
      variables['user_id'] = aPatientId;
    }
    if(aGroupId){
      variables['group_id'] = aGroupId;
    }
    if(aSearchString){
      variables['search_string'] = aSearchString;
    }
    variables['limit'] = aLimit;
    variables['offset'] = aOffset;
    return global.api.post("/queries", { query, variables })
  };
}

export function fetchFraudStatistics(aPatientId){
  EventTracker.track(EventTypes.FETCH_FRAUD_STATISTICS);
  let query = `
  query getPatient ($userId: ID!) {
      user (id: $userId) {
        number_of_unverified_fraud_events
        fraud_statistic {
          id
          verified_comments
          verification_status
          probability
          average_seconds_per_survey_question
          number_of_users_with_the_same_ip_address
          number_of_users_with_the_same_primary_contact_number
          ip_address_state_matches_address
          ip_address_city_matches_address
          primary_contact_number_caller_id_available
          primary_contact_number_caller_id_match
          number_of_users_with_the_same_device
          verified_by_user {
            first_name
            last_name
          }
        }
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    
    if(aPatientId){
      variables['userId'] = aPatientId;
    }
    return global.api.post("/queries", { query, variables })
  };
}

export function markFraudEventAsVerified(aFraudEventId, aComment){
  EventTracker.track(EventTypes.MARK_FRAUD_EVENT_AS_VERIFIED);
  return markFraudEventAsStatus(aFraudEventId, aComment, 'verified');
}

export function markFraudEventAsFraudulent(aFraudEventId, aComment){
  EventTracker.track(EventTypes.MARK_FRAUD_EVENT_AS_FRAUDULENT);
  return markFraudEventAsStatus(aFraudEventId, aComment, 'fraudulent');
}

export function markFraudEventAsStatus(aFraudEventId, aComment, aStatus){
  let query = `
    mutation VerifyFraudEventMutation ($verifyFraudEventMutationInput: VerifyFraudEventMutationInput!) {
        verifyFraudEvent (input: $verifyFraudEventMutationInput){
          fraud_event {
            id
            event_type
            verified_comments
            verification_status
            target_id
            target_type
            target_name
            string_value
            created_at
            updated_at
            user {
              id
              first_name
              last_name
            }
            verified_by_user {
              first_name
              last_name
            }
          }
        }
      }
  `;
  
  let operationName = `verifyFraudEvent`;
  let params = {};
  
  return (dispatch) => {
    params['fraud_event_id'] = aFraudEventId;
    params['verification_status'] = aStatus;
    params['verified_comments'] = aComment;
    
    let variables = {
      verifyFraudEventMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}

export function markFraudStatisticAsVerified(aFraudStatisticId, aComment){
  EventTracker.track(EventTypes.MARK_FRAUD_STATISTIC_AS_VERIFIED);
  return markFraudStatisticAsStatus(aFraudStatisticId, aComment, 'verified');
}

export function markFraudStatisticAsFraudulent(aFraudStatisticId, aComment){
  EventTracker.track(EventTypes.MARK_FRAUD_STATISTIC_AS_FRAUDULENT);
  return markFraudStatisticAsStatus(aFraudStatisticId, aComment, 'fraudulent');
}

export function markFraudStatisticAsStatus(aFraudStatisticId, aComment, aStatus){
  let query = `
    mutation VerifyFraudStatisticMutation ($verifyFraudStatisticMutationInput: VerifyFraudStatisticMutationInput!) {
        verifyFraudStatistic (input: $verifyFraudStatisticMutationInput){
          fraud_statistic {
            id
            verified_comments
            verification_status
            probability
            average_seconds_per_survey_question
            number_of_users_with_the_same_ip_address
            number_of_users_with_the_same_primary_contact_number
            ip_address_state_matches_address
            ip_address_city_matches_address
            primary_contact_number_caller_id_available
            primary_contact_number_caller_id_match
            verified_by_user {
              first_name
              last_name
            }
          }
        }
      }
  `;
  
  let operationName = `verifyFraudEvent`;
  let params = {};
  
  return (dispatch) => {
    params['fraud_statistic_id'] = aFraudStatisticId;
    params['verification_status'] = aStatus;
    params['verified_comments'] = aComment;
    
    let variables = {
      verifyFraudStatisticMutationInput:params
    };
    return global.api.post("/queries", { query, variables, operationName });
  };
}
