import React from 'react';
import "antd/dist/antd.css";
import './SurveyListModal.scss';
import {Translation} from "react-i18next";
import GeneralModal from "../../Modals/GeneralModal/GeneralModal";
import SurveyList from "../../Components/SurveyList/SurveyList";

export default class SurveyListModal extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      saveButtonPressed:false
    };
  }
  
  // Render
  
  render() {
    const { client, company, isOpen, surveyList, handleFinish, handleCloseModal, addSurveyList, updateSurveyList,
      fetchSurveyListById } = this.props;
    const { saveButtonPressed } = this.state;
    
    const isEditing = surveyList !== undefined && surveyList !== null;
    
    return (
      <GeneralModal title={<Translation>{ (t, { i18n }) => t('SURVEY_LIST') }</Translation>}
                    width={'400px'}
                    height={'auto'}
                    isOpen={isOpen}
                    isEditing={isEditing}
                    handleSave={() => this.setState({saveButtonPressed:true})}
                    showFooter={true}
                    showHeader={true}
                    showSaveButton={true}
                    handleCloseModal={() => handleCloseModal()}
                    showCancelButton={false}
                    maxChildrenHeight={950}>
        <SurveyList client={client}
                    company={company}
                    saveFailed={() => this.setState({saveButtonPressed:false})}
                    surveyList={surveyList}
                    saveFinished={(aSurveyList) => this.setState({saveButtonPressed:false}, () => handleFinish(aSurveyList))}
                    addSurveyList={addSurveyList}
                    hideSaveButton={true}
                    updateSurveyList={updateSurveyList}
                    saveButtonPressed={saveButtonPressed}
                    fetchSurveyListById={fetchSurveyListById}
        />
      </GeneralModal>
    )
  }
}
