import EventTracker from 'eventTracker'
import EventTypes from 'constants/EventTypes'

export function addCompanyCampaign({title, description, utmSource, utmContent, utmMedium, utmCampaign}) {
  EventTracker.track(EventTypes.ADD_NOTE)
  let query = `
  mutation AddCompanyCampaign($addCompanyCampaignInput: AddCompanyCampaignInput!) {
    addCompanyCampaign(input: $addCompanyCampaignInput) {
      company_campaign {
        id
        title
        description
        qrcode
      }
    }
  }
  `;

  let variables = {
    addCompanyCampaignInput: {
      description: description,
      title: title,
      utm_campaign: utmCampaign,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_content: utmContent
    }
  }
  
  let operationName = `AddCompanyCampaign`

  return (dispatch, getState) => {
    let state = getState();
    variables["addCompanyCampaignInput"]["company_id"] = state.company && state.company.company &&  parseInt(state.company.company.id);

    return global.api.post("/queries", { 
      query, variables, operationName 
    })
  }
}

export function getCompanyCampaigns(limit, offset, aQuery) {
  let query = `
    query getCompanyCapaigns ($company_id: ID!, $query: String, $limit: Int, $offset: Int) {
      company_campaigns (company_id: $company_id, query: $query, limit: $limit, offset: $offset){
        company_campaigns {
          id
          description
          title
          utm_source
          utm_content
          utm_medium
          utm_campaign
          shortened_url
          qrcode
        }
        total
      }
    }
  `;
  let variables = {};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company &&  parseInt(state.company.company.id);
    variables['query'] = aQuery;
    variables['limit'] = limit;
    variables['offset'] = offset;
    return global.api.post("/queries", { query, variables });
  };
}

export function updateCompanyCampaign({id, description, title}) {
  EventTracker.track(EventTypes.UPDATE_NOTE)
  let query = `
  mutation UpdateCompanyCampaign($updateCompanyCampaignInput: UpdateCompanyCampaignInput!) {
    updateCompanyCampaign(input: $updateCompanyCampaignInput) {     
      company_campaign {
        id
        title
        description
        qrcode
      }
    }
  }
  `;

  let variables = {
      description: description,
      title: title,
      id: id
  }
  let operationName = `UpdateCompanyCampaign`

  return (dispatch, getState) => {
    let state = getState();
    variables["addCompanyCampaignInput"]["company_id"] = state.company && state.company.company &&  parseInt(state.company.company.id);
  
    return global.api.post("/queries",
      { query, variables, operationName }
    )
  };
}

