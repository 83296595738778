import React from 'react';
import 'antd/dist/antd.css'
import './AccountDeactivated.scss';
import '../../Custom UI/common.scss'
import {Translation} from "react-i18next";
import BlueButton from "../../Custom UI/Buttons/BlueButton/BlueButton";

export default class AccountDeactivated extends React.Component {

  // Init

  constructor(props){
    super(props);
    this.state = {
    
    }
  }

  // Methods
  
  

  // Render

  render() {
    const {logout} = this.props;

    return (
      <div className="account-deactivated-page">
        <div className="account-deactivated-text">
          <Translation>{ (t, { i18n }) => t('ACCOUNT_DEACTIVATED')}</Translation>
        </div>
  
        <BlueButton className="account-deactivated-button"
                    name={<Translation>{(t, {i18n}) => t('LOGOUT')}</Translation>}
                    onClick={logout}>
        </BlueButton>
      </div>
    )
  }
}
