import {
  FETCH_TOOLS,
  LOAD_TOOLS,
} from 'constants/ActionTypes';
import {loadCompany, loadCompanyQuests} from './company'
import EventTracker from '../eventTracker'
import EventTypes from '../constants/EventTypes'
import { cloneDeep, pick } from "lodash"
import {fetchPatient, fetchPatientData} from "./patient";

export function fetchTools() {
  return dispatch => {
    dispatch({
      type: FETCH_TOOLS
    });
    dispatch(loadTools());
  };
}

export function loadTools() {
  let query = `
    query getTools ($company_id: ID!) {
      quests (company_id: $company_id){
          id
          title
          description
          creator_id
          parent_id
          root_quest_id
          icon_bg_color
          icon
          start_time
          end_time
          created_at
          quest_type
          hsc_consent
          has_hsc_consent_question
          onboarding_quest
          translations {
            locale
            title
            description
            label_description
            label_do
            label_history
          }
          groups {
            id
            name
          }
          survey {
            id
            name
            is_repeatable
            has_consent_question
            has_sensitive_data_question
          }
          survey_version_collection {
            id
            name
          }
          quest_schedule {
            id
            end_date
            start_date
            created_at
            updated_at
            enable_frequency
            enable_frequency_unit
            enable_duration
            enable_duration_unit
            start_date_table
            start_date_column
          }
          objectives {
            id
            name
            type
            description
            order
            required
            multiselect
            numbers_only
            minimum_value
            minimum_value_string
            maximum_value
            maximum_value_string
            consent_button_title
            consent_check_text
            include_consenting_name
            consenting_name_required
            include_consenting_signature
            consenting_signature_required
            include_consent_check
            consent_check_required
            translations {
              locale
              name
              description
              maximum_value_string
              minimum_value_string
              consent_button_title
              consent_check_text
            } 
            answers {
              id
              answer_text
              order
              has_image
              translations {
                locale
                answer_text
                answer_explanation
              } 
            }
            objective_points {
              id
              entry_value
              operator
              points
            }
            reminders {
              id
              note
              time
              repeat
              repeat_type
              day_number
            }
          }
        }
    }
    `;

  let variables = {  };

  return (dispatch, getState) => {
    const { company } = getState();
    variables['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries", { query, variables }).then((result) => {
      let tools = result.data.data.quests;
      dispatch({
        type: LOAD_TOOLS,
        result: tools
      })
    }).catch((error) => {
      dispatch({
        type: LOAD_TOOLS,
        error,
      });
    });
  };
}

export function updateTool({tool}) {
  EventTracker.track(EventTypes.UPDATE_TOOL);
  let sanitizedTool = pick(tool, ['id', 'removeObjectiveIds', 'objectives', 'icon', 'icon_bg_color', 'start_time', 'end_time', 'translations', 'hsc_consent', 'onboarding_quest']);
  let query = `
  mutation UpdateTool($updateQuestInput: UpdateQuestInput!) {
    updateQuest (input: $updateQuestInput) {
      quest {
        id
        title
        start_time
        end_time
        hsc_consent
        has_hsc_consent_question
        onboarding_quest
        translations {
          locale
          title
          description
          label_description
          label_do
          label_history
        }
      }
    }
  }
  `;
  let toolCopy = cloneDeep(sanitizedTool);
  let variables = {
    updateQuestInput: toolCopy
  };
  let operationName = `UpdateTool`;
  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    }).then((result) => {
      dispatch(loadTools())
    });
  }
}

export function updateUserTool({userTool}) {
  EventTracker.track(EventTypes.UPDATE_USER_TOOL);
  let query = `
  mutation UpdateQuestUserJoin($updateQuestUserJoinInput: UpdateQuestUserJoinInput!) {
    updateQuestUserJoin (input: $updateQuestUserJoinInput) {
      quest_user_join {
        id
        user_id
        quest_id
        start_date
        end_date
        visibility
        timezone
      }
    }
  }
  `;

  let variables = {
    updateQuestUserJoinInput: {
      quest_user_join: userTool
    }
  };
  let operationName = `UpdateQuestUserJoin`;

  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    });
  }
}


export function addToolToCompany({tool}) {
  EventTracker.track(EventTypes.ADD_TOOL_TO_COMPANY);
  let sanitizedTool = pick(tool, ['objectives', 'icon', 'icon_bg_color', 'start_time', 'end_time', 'translations', 'company_id']);
  let query = `
    mutation AddTool ($addToolToCompanyInput: AddToolToCompanyInput!) {
      addToolToCompany (input: $addToolToCompanyInput){
        quest {
          id
        }
      }
    }
    `;
  let variables = {
    addToolToCompanyInput: sanitizedTool
  };
  return (dispatch, getState) => {
    return global.api.post("/queries", { query, variables }).then((result) => {
      dispatch(loadTools())
    });
  };
}

export function removeToolFromCompany({toolId}) {
  EventTracker.track(EventTypes.REMOVE_TOOL_FROM_COMPANY)
  let query = `
    mutation RemoveToolFromCompany ($removeToolFromCompanyInput: RemoveToolFromCompanyInput!) {
      removeToolFromCompany (input: $removeToolFromCompanyInput){
        quest {
          id
          title
        }
      }
    }
    `;

  let variables = {
    removeToolFromCompanyInput: {
      quest_id: toolId,
    }
  };

  return (dispatch, getState) => {
    const { company } = getState();
    variables['removeToolFromCompanyInput']['company_id'] = company.company.id;
    return global.api.post("/queries", { query, variables }).then((result) => {
      dispatch(loadTools())
    }).catch((error) => {
    });
  };
}

export function addObjective({questId, name, type, reminders}) {
  EventTracker.track(EventTypes.ADD_OBJECTIVE);
  let query = `
  mutation AddObjectiveToQuest($addObjectiveToQuestInput: AddObjectiveToQuestInput!) {
    addObjectiveToQuest (input: $addObjectiveToQuestInput) {
      objective {
        id
        name
        group_quest_id
        type
      }
    }
  }
  `;

  let variables = {
    addObjectiveToQuestInput: {
      quest_id: questId,
      name,
      type,
      reminders
    }
  };
  let operationName = `AddObjectiveToQuest`;

  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    }).then((result) => {
      dispatch(loadCompany());
      dispatch(loadCompanyQuests());
    }).catch((error) => {
    })
  }
}

export function removeObjectiveFromTool({objectiveId}) {
  EventTracker.track(EventTypes.REMOVE_OBJECTIVE);
  let query = `
  mutation RemoveObjective($removeObjectiveInput: RemoveObjectiveInput!) {
    removeObjective (input: $removeObjectiveInput) {
      objective {
        id
        name
        group_quest_id
        type
      }
    }
  }
  `;

  let variables = {
    removeObjectiveInput: {
      objective_id: objectiveId
    }
  };
  let operationName = `RemoveObjective`;

  return (dispatch) => {
    return global.api.post("/queries", { 
      query, variables, operationName 
    }).then((result) => {
      dispatch(loadCompany());
      dispatch(loadCompanyQuests());
    }).catch((error) => {
    })
  }
}

export function addEntry({objectiveId, questId, userId, value, measuredAt}) {
  let query= `
  mutation AddEntry ($addEntryInput: AddEntryInput!) {
    addEntry (input: $addEntryInput) {
      entry {
        id
        type
        text
        value
        response {
          id
          measured_at
        }
      }
    }
  }
  `;
  let variables = {
    addEntryInput: {
      objective_id: objectiveId,
      quest_id: questId,
      user_id: userId,
      value,
      measured_at: measuredAt
    }
  }
  
  let operationName = `AddEntry`
  
  return (dispatch, getState) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((result) => {
      dispatch(fetchPatientData({userId}));
    })
  };
}

export function removeToolFromUser({userId, toolId}) {
  EventTracker.track(EventTypes.REMOVE_TOOL_FROM_USER);
  let query = `
  mutation RemoveToolFromUser($removeToolFromUserInput: RemoveToolFromUserInput!) {
    removeToolFromUser(input: $removeToolFromUserInput) {
      quest {
        id
        title
      }
    }
  }
  `;
  let variables = {
    removeToolFromUserInput: {
      user_id: userId,
      quest_id: toolId
    }
  };
  let operationName = `RemoveToolFromUser`;
  
  return (dispatch) => {
    return global.api.post("/queries",
      { query, variables, operationName }
    ).then((result) => {
      dispatch(fetchPatient({userId}));
    }).catch((error) => {
    });
  };
}

export function getFullQuest({questId, userId, to, from }){
  let query = `
    query getQuest($questId: ID!, $userId: ID, $company_id: ID!, $from: String, $to: String){
      quest(id: $questId, user_id: $userId, company_id: $company_id){
        id
        icon
        title
        end_date
        admin_only
        quest_type
        start_date
        visibility
        hsc_consent
        quest_order
        icon_bg_color
        tool_last_used
        hsc_visualization
        rotate_objectives
        has_trigger_quests
        has_hsc_consent_question
        translations {
          title
          locale
          label_do
          description
          label_history
          label_description
        }
        survey {
          id
          name
          is_repeatable
          has_consent_question
          has_sensitive_data_question
        }
        survey_version_collection {
          id
          name
        }
        quest_schedule {
          id
          end_date
          created_at
          start_date
          updated_at
          enable_duration
          enable_frequency
          start_date_table
          start_date_column
          enable_duration_unit
          enable_frequency_unit
        }
        objectives {
          id
          name
          type
          numbers_only
          maximum_value
          minimum_value
          is_primary_hsc_visualization
          reminders {
            id
            note
            time
            repeat
            day_number
            repeat_type
          }
          vital {
            name
            units
            storage_unit
            normalized_name
          }
          entries(from: $from, to: $to) {
            id
            text
            value
            reminder_time
            response {
              id
              measured_at
            }
            answers {
              id
              image_data
              answer_text
              chart_value
              chart_colour
            }
          }
        }
      }
    }
  `;
  
  let variables = {questId, userId, to, from};
  
  return (dispatch, getState) => {
    let state = getState();
    variables['company_id'] = state.company && state.company.company && state.company.company.id;
    return global.api.post("/queries", { query, variables });
  }
}

export function updateToolSchedule(aToolId, aTable, aColumn, aEndDate, aStartDate, aDuration, aFrequency, aDurationUnit, aFrequencyUnit){
  EventTracker.track(EventTypes.UPDATE_TOOL_SCHEDULE);
  let query = `
    mutation SaveQuestSchedule ($saveQuestScheduleMutationInput: SaveQuestScheduleMutationInput!) {
      saveQuestSchedule (input: $saveQuestScheduleMutationInput){
        quest_schedule {
          id
          end_date
          start_date
          created_at
          updated_at
          enable_frequency
          enable_frequency_unit
          enable_duration
          enable_duration_unit
          start_date_table
          start_date_column
        }
      }
    }
  `;
  let operationName = `SaveQuestScheduleMutation`;
  let params = {};
  
  return (dispatch, getState) => {
    let state = getState();
    params['quest_id'] = aToolId;
    params['company_id'] = state.company && state.company.company && state.company.company.id;
    params['end_date'] = aEndDate;
    params['start_date'] = aStartDate;
    params['enable_duration'] = Number(aDuration);
    params['enable_frequency'] = Number(aFrequency);
    params['start_date_table'] = aTable;
    params['start_date_column'] = aColumn;
    params['enable_duration_unit'] = aDurationUnit;
    params['enable_frequency_unit'] = aFrequencyUnit;
    
    let variables = {
      saveQuestScheduleMutationInput:params
    };
    return global.api.post("/queries", {query, variables, operationName});
  };
}

export function getQuestById(aUserId, aQuestId) {
  let query = `
    query getQuest ($company_id: ID!, $id: ID!, $user_id: ID) {
      quest (company_id: $company_id, id: $id, user_id: $user_id){
        id
        title
        icon
        end_date
        admin_only
        start_date
        quest_type
        visibility
        hsc_consent
        quest_order
        icon_bg_color
        tool_last_used
        has_trigger_quests
        has_hsc_consent_question
        translations {
          title
          locale
          label_do
          description
          label_history
          label_description
        }
        survey {
          id
          name
          is_repeatable
          has_consent_question
          has_sensitive_data_question
        }
        survey_version_collection {
          id
          name
        }
        quest_schedule {
          id
          end_date
          created_at
          start_date
          updated_at
          enable_duration
          enable_frequency
          start_date_table
          start_date_column
          enable_duration_unit
          enable_frequency_unit
        }
        survey_responses {
          id
          admin_id
          complete
          progress
          admin_name
          lock_state
          updated_at
          completed_at
          completed_by_admin
          survey {
            id
            name
          }
        }
        objectives {
          type
          survey_id
        }
        groups {
          id
          name
        }
      }
    }
    `;
  
  let variables = {};
  variables['id'] = aQuestId;
  variables['user_id'] = aUserId;
  
  return (dispatch, getState) => {
    const {company} = getState();
    variables['company_id'] = company && company.company && company.company.id;
    return global.api.post("/queries", {query, variables});
  };
}
