import React from "react";
import './SurveyBuilderReorderCell.scss';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {BiDotsVerticalRounded} from "react-icons/bi";

const SurveyBuilderReorderCell = ({menuButtonClicked, pageOrQuestion = {}}) => {
  
  // Render
  
  return (
    <div className="survey-builder-reorder-outer-cell">
      {!(pageOrQuestion.type === 'page') ?
        <div className="survey-builder-reorder-indent"> > </div>
        :
        null
      }
      
      <div className="survey-builder-reorder-cell">
        <div className="survey-builder-reorder-cell-title-container">
          <DragHandleIcon className="survey-builder-reorder-cell-drag-handle"/>
          
          <div className="survey-builder-reorder-cell-title">
            {pageOrQuestion.key + (pageOrQuestion.questionType && pageOrQuestion.questionType.length > 0 ? ' - ' + pageOrQuestion.questionType : '')}
          </div>
        </div>
        
        <div className="survey-builder-reorder-cell-edit-container">
          {menuButtonClicked ?
            <BiDotsVerticalRounded style={{fontSize:'21px', alignSelf:'baseline', marginLeft:'10px', color:'#4A4A4A'}}
                                   onClick={(aEvent) => menuButtonClicked(aEvent)}
            />
            :
            null
          }
        </div>
      </div>
    </div>
  );
};

export default SurveyBuilderReorderCell
