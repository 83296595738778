import React from 'react';
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const SliderObjective = ({ objective, selectedTranslationIndex }) => (
  <div style={{display: 'flex', flexDirection: 'column', marginBottom: '1em'}}>
    <label>
      {objective.translations[selectedTranslationIndex].description}
    </label>
    <Slider
      defaultValue={0}
      min={objective.minimum_value}
      max={objective.maximum_value}
      marks={{
        [objective.minimum_value]: {
          label: objective.minimum_value + ' ('+objective.translations[selectedTranslationIndex].minimum_value_string+')',
          style: {marginTop: '5px', width: 'auto', marginLeft: 'auto'}
        },
        [objective.maximum_value]: {
          label: objective.maximum_value + ' ('+objective.translations[selectedTranslationIndex].maximum_value_string+')',
          style: {marginTop: '5px', right: '0%', left: 'auto', width: 'auto', marginLeft: 'auto'}
        }
      }}
      trackStyle={{height: '1em', borderRadius: 0}}
      railStyle={{height: '1em', borderRadius: 0}}
      dotStyle={{display: 'none'}}
      handleStyle={{
        height: '1.5em',
        width: '0.5em',
        borderRadius: '4px',
        backgroundColor: '#31C8A3',
        border: '1px solid #2CB492'
      }}
    />
  </div>
);
export default SliderObjective
